import * as React from 'react';
import { Modal, Alert, Button } from 'react-bootstrap';
import Checkbox from '../../common/Checkbox';
import { Tag } from '../../common/Tag';

interface ITaxCaddyIntegrationPopupProps {
    showState: boolean;
    onHide: () => void;  
    onSaveButtonClick: (event: any) => void;
    handleByPassManualVerificationChange: (event: any) => void;
    handleIsDirectDeliveryToTaxCaddy: (event: any) => void;
    isByPassManualVerification: boolean;
    isDirectDeliveryToTaxCaddy: boolean;
    onChangeTagValue(e: any, firmIds: string[]): void;
    firmIds: string[];
    alert: string;
    message: string;
    onValidate(key: string[]): void;
    isValidFirmKey: boolean;
}

export class TaxCaddyIntegrationPopup extends React.Component<ITaxCaddyIntegrationPopupProps, {}> {
    public render() {               
        return (
            <div>
                <Modal className="taxCaddy-reporting-channel" show={this.props.showState} onHide={this.props.onHide}>
                    <Modal.Header closeButton data-test-auto="F23D555E-F77A-4C2B-9E6C-69FD352411AC">
                        <Modal.Title>TaxCaddy Reporting Channel Settings</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {
                            this.props.firmIds.length < 1 || !this.props.isValidFirmKey ?
                                (<Alert variant={this.props.alert} >
                                    <i className='fas fa-exclamation-triangle' style={{ marginRight: '5px' }}></i>
                                    {this.props.message}
                                </Alert>) : null
                        }                     
                        <div>
                            <div>
                                <div>
                                    <div className="marB05" style={{ fontWeight: "bold" }}>Firm Keys</div>
                                    <Tag listOfTagsInput={this.props.firmIds} onChangeTagValue={this.props.onChangeTagValue}
                                        placeHolder="Type here to add new FirmId" onValidate={this.props.onValidate} />
                                </div>  
                                <div className="marL0 marT10">
                                    <Checkbox
                                        id={"DirectDeliverToTaxCaddy"}
                                        checkedCheckbox={this.props.isDirectDeliveryToTaxCaddy}
                                        onChangeCheckbox={this.props.handleIsDirectDeliveryToTaxCaddy}
                                        textCheckbox={"Request E-Signatures in TaxCaddy instead of SafeSend Returns"}
                                        dataTestAuto="7A82E2D5-8A32-463E-A890-EC6343B476D1"
                                    />
                                </div> 
                                <div className="marL0 marT10">
                                    <Checkbox
                                        id={"BypassManualVerification"}
                                        checkedCheckbox={this.props.isByPassManualVerification}
                                        onChangeCheckbox={this.props.handleByPassManualVerificationChange}
                                        textCheckbox={"Bypass manual verification if there is an exact match of the taxpayer SSN"}
                                        dataTestAuto="FE4484EE-4DEA-4729-85D2-4E58F72F7DFB"
                                    />
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant='default'
                            onClick={this.props.onHide}
                            data-test-auto="8E1F98C7-7E9C-4389-85B0-D8A6F8EA0191"
                        >
                            <i className='fas fa-times'></i>Cancel
                        </Button>
                        <Button
                            variant='info'
                            onClick={this.props.onSaveButtonClick}
                            data-test-auto="E75D7131-DBFD-4A38-8096-80C18A1321EC"
                        >
                            <i className='fas fa-save'></i>Save
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}