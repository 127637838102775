import * as React from "react";
import { Modal } from "react-bootstrap";
import { Button, ProgressBar } from "react-bootstrap";
import { DocumentUploadDropzoneComponent } from "../../UploadDocument/DocumentUploadDropzoneComponent";
import {
    UploadFunctions,
    removeSpecialCharacters,
    GetFileMagicNumber,
    validateFileSize
} from "@sssuite-js-packages/file-utility";
import { getFileSize } from "../../../helper/HelperFunctions";
import { InvoiceReplaceConstants, UploadtaxReturnConstants } from "../../../helper/Constants";
import { IPdfDocumentFacade } from "../../../../Core/Utilities/PdfDocumentFacade";
import { ITaxReturn, DocumentGroups, IGroup, IFormBase, TaxFormType, IInvoice } from "../../../common/TaxReturn";
import { VenusNotifier } from "../../../helper/VenusNotifier";
import { IPdfDocumentDictionary } from "../../../../store/pdf/PdfStore";
import { PDFJSStatic, PDFDocumentProxy } from "pdfjs-dist";
import { Guid } from "../../../../Core/Utilities/Guid";
import { validatePdfFileContent } from "../../../helper/Validations";
import { CurrencyTextBox } from "../../../common/controls/CurrencyTextBox";
const PDFJS: PDFJSStatic = require("pdfjs-dist");
import { API_BASE_URL } from "../../../../utils/constants";
import { logger } from "../../../../routes/LoggedIn";
import { generateBase64 } from "../../../../Core/ViewModels/Common/PdfHelper";
import UploadDocumentModalWarning from "../../UploadDocument/UploadDocumentModalWarning";

export interface InvoiceUploadData {
    name: string;
    progressBar?: number;
    size?: string;
    status?: string;
    sasGuid?: string;
    disableInput: boolean;
}

interface IReplaceInvoiceProps {
    taxReturn: ITaxReturn;
    updateTaxDocument: (taxDocument: ITaxReturn) => void;
    pdfDocuments: IPdfDocumentDictionary;
    docId: number;
    onShow: boolean;
    onHide(): void;
    invoiceAmount: number;
    updateSendWithoutInvoice: () => void;
}
interface IReplaceInvoiceState {
    config: any;
    amount: number;
    bookmarkName: string;
    invoiceUploadData: InvoiceUploadData;
}
let lastPage = 0;

export class InvoiceReplaceModal extends React.Component<IReplaceInvoiceProps, IReplaceInvoiceState> {
    PDFDocument: PDFDocumentProxy = require("pdfjs-dist");
    constructor(props: IReplaceInvoiceProps) {
        super(props);
        this.state = {
            config: {
                iconFiletypes: [".pdf"],
                showFiletypeIcon: true,
                postUrl: "no-url"
            },
            amount: 0,
            bookmarkName: "",
            invoiceUploadData: { disableInput: true } as InvoiceUploadData
        };

        this.handleClose = this.handleClose.bind(this);
        this.OnSubmitReplaceInvoice = this.OnSubmitReplaceInvoice.bind(this);
        this.OnChangeBookmarkName = this.OnChangeBookmarkName.bind(this);
        this.onBlurAmount = this.onBlurAmount.bind(this);
        this.uploadProgressCallback = this.uploadProgressCallback.bind(this);
        this.uploadCompleteCallback = this.uploadCompleteCallback.bind(this);
        this.uploadFailureCallback = this.uploadFailureCallback.bind(this);
    }

    OnChangeBookmarkName(event: any) {
        this.setState({ bookmarkName: event.target.value });
    }

    onBlurAmount(value: number) {
        this.setState({ amount: value });
    }

    OnSubmitReplaceInvoice() {
        if (isNaN(this.state.amount)) {
            VenusNotifier.Warning(InvoiceReplaceConstants.InvoiceAmountWarning, "");
        } else if (this.state.bookmarkName.trim() == "") {
            VenusNotifier.Warning(InvoiceReplaceConstants.BookmarkTextWarning, "");
        } else {
            let documentFacade: IPdfDocumentFacade = this.props.pdfDocuments[this.props.docId].document as IPdfDocumentFacade;
            let currentLastPage = documentFacade?.getLastPage();
            if (lastPage < currentLastPage) {
                let invoice = this.props.taxReturn.formGroups.find((x) => x.group == DocumentGroups.Invoice);
                if (!invoice) {
                    invoice = {} as IGroup;
                    invoice.group = DocumentGroups.Invoice;
                    invoice.forms = [] as IFormBase[];
                    this.props.taxReturn.formGroups.push(invoice);
                }

                let deletedForms: IGroup = this.props.taxReturn.formGroups.find(
                    (x) => x.group == DocumentGroups.Deleted
                ) as IGroup;
                if (!deletedForms) {
                    deletedForms = {} as IGroup;
                    deletedForms.group = DocumentGroups.Deleted;
                    deletedForms.forms = [] as IFormBase[];
                    this.props.taxReturn.formGroups.push(deletedForms);
                }
                if (!deletedForms.forms) {
                    deletedForms.forms = [] as IFormBase[];
                }
                for (let i = 0; i < invoice.forms.length; i++) {
                    deletedForms.forms.push({ ...invoice.forms[i] });
                }

                invoice.forms = [] as IFormBase[];
                let bookmarkName = this.state.bookmarkName;
                let count = 1;
                for (let i = lastPage + 1; i <= currentLastPage; i++) {
                    let invBookmark: IInvoice = {} as IInvoice;
                    invBookmark.bookmark = bookmarkName;
                    invBookmark.formName = "Invoice";
                    invBookmark.pageNo = [i];
                    invBookmark.formType = TaxFormType.Invoice;

                    invoice.forms.push(invBookmark);
                }
                this.props.taxReturn.invoiceAmount = this.state.amount;
                this.props.updateSendWithoutInvoice();
                logger.trackTrace(`TabInvoices --> onSubmitReplaceInvoice executes`, {
                    DocumentId: this.props.taxReturn.id?.toString(),
                    DocumentGuid: this.props.taxReturn.documentGuid
                });
            } else {
                VenusNotifier.Error("Unsupported Document", "");
            }
            this.handleClose();
        }
    }

    handleClose() {
        this.reset();
        this.props.onHide();
    }

    djsConfig = {
        uploadMultiple: false,
        maxFiles: 1,
        addRemoveLinks: true,
        acceptedFiles: "application/pdf",
        headers: { "Access-Control-Allow-Origin": "*", "x-ms-blob-type": "BlockBlob" },
        previewsContainer: false,
        autoProcessQueue: false
    };

    eventHandlers = {
        init: () => {
            this.reset();
        },

        addedfiles: (files: any) => {
            let _self = this;
            let file = files[0];

            if (validateFileSize(file)) {
                GetFileMagicNumber(file).then((result) => {
                    if (file.type !== "application/pdf" || !validatePdfFileContent(result)) {
                        VenusNotifier.Warning(InvoiceReplaceConstants.NonPdfFileWarning, "Warning");
                        return;
                    }
                    this.uploadProgressCallback(5, file); //calculation of progress implementation is pending
                    let uploadHelperFunctions = new UploadFunctions();
                    const fileGuid = Guid.newGuid().toString();
                    let param =
                        "?documentGuid=" +
                        _self.props.taxReturn.documentGuid +
                        "&fileName=" +
                        escape(fileGuid) +
                        "&taxYear=" +
                        _self.props.taxReturn.taxYear +
                        "&uploadType=invoice";
                    fetch(API_BASE_URL + "api/ProcessReturn/GetUploadSasUrlAsync" + param, { credentials: "include" })
                        .then((resp) => resp.json())
                        .then(function (data) {
                            PDFJS.getDocument(URL.createObjectURL(file)).then(function (pdf) {
                                let documentFacade: IPdfDocumentFacade = _self.props.pdfDocuments[_self.props.docId]
                                    .document as IPdfDocumentFacade;
                                lastPage = documentFacade?.getLastPage();
                                generateBase64(URL.createObjectURL(file)).then((base64data) => {
                                    if (pdf.numPages > 0) documentFacade.append(pdf, fileGuid, base64data);
                                    else {
                                        return;
                                    }
                                });
                            });
                            let invUploadData = {
                                name: removeSpecialCharacters(file.name),
                                progressBar: 0,
                                size: getFileSize(file?.size),
                                status: "Uploading",
                                sasGuid: data.guid,
                                disableInput: true
                            } as InvoiceUploadData;
                            _self.setState({ invoiceUploadData: invUploadData }, () =>
                                uploadHelperFunctions.uploadFile(
                                    file,
                                    data,
                                    invUploadData.name,
                                    _self.uploadProgressCallback,
                                    _self.uploadCompleteCallback,
                                    _self.uploadFailureCallback
                                )
                            );
                        })
                        .catch((error: any) => {
                            console.log(error);
                        });
                });
            }
        },
        maxfilesexceeded: (file: any) => {
            VenusNotifier.Warning(InvoiceReplaceConstants.MultipleFileWarning, "Warning");
        }
    };

    public uploadProgressCallback(percent: number, fileToUpload: any) {
        var invUploadData = this.state.invoiceUploadData;
        if (percent > 90) {
            invUploadData.progressBar = 90;
        } else {
            invUploadData.progressBar = percent;
        }
        this.setState({ invoiceUploadData: invUploadData });
    }

    public uploadCompleteCallback = (fileToUpload: any) => {
        var invUploadData = this.state.invoiceUploadData;
        invUploadData.progressBar = 100;
        invUploadData.status = "Uploaded";
        invUploadData.disableInput = false;
        this.setState({ invoiceUploadData: invUploadData });
    };

    //Implementation of this callback will be done later
    public uploadFailureCallback = (fileToUpload: any) => {
        console.log("upload failed for file:", fileToUpload.fileName);
    };

    private reset = () => {
        this.setState({
            amount: 0,
            bookmarkName: "",
            invoiceUploadData: { disableInput: true } as InvoiceUploadData
        });
    };

    public render() {
        return (
            <div>
                <Modal className="replace-invoice-modal overlayPopup" show={this.props.onShow} onHide={this.handleClose}>
                    <Modal.Header closeButton data-test-auto="9DFD99E6-5A5C-4A9A-B40F-975AB2D109B5">
                        <Modal.Title style={{ display: "block" }}>
                            {" "}
                            <i className="fa fa-upload" style={{ marginRight: "5px" }}></i>Replace Invoices
                            <UploadDocumentModalWarning warningMessages={[UploadtaxReturnConstants.PdfDocumentUploadWarning]} />
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="marR10">
                            <ProgressBar variant="success" now={this.state.invoiceUploadData.progressBar} />
                        </div>
                        <div className="row" style={{ paddingTop: "11px" }}>
                            <div className="col-sm-5 col-md-5 col-xl-5 text-center">
                                <div className="divUploadZone">
                                    <DocumentUploadDropzoneComponent
                                        componentConfig={this.state.config}
                                        djsConfig={this.djsConfig}
                                        eventHandlers={this.eventHandlers}
                                        autoTestId={"580759D2-231B-4A82-A490-B56D053820E0"}
                                    />
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-6 col-xl-6">
                                <div className="row">
                                    <div className="col-sm-3 col-md-3 col-xl-3">
                                        <div className="marB10 padT07">Bookmark Name</div>
                                    </div>
                                    <div className="col-sm-9 col-md-9 col-xl-9">
                                        <div className="marB10">
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={this.state.bookmarkName}
                                                onChange={this.OnChangeBookmarkName}
                                                disabled={this.state.invoiceUploadData.disableInput}
                                                placeholder="Bookmark"
                                                data-lpignore="true"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-3 col-md-3 col-xl-3">
                                        <div className="marB10 padT07">Amount</div>
                                    </div>
                                    <div className="col-sm-9 col-md-9 col-xl-9">
                                        <div className="marB10">
                                            <CurrencyTextBox
                                                value={this.state.amount}
                                                placeholder="Enter amount"
                                                onBlur={this.onBlurAmount}
                                                disabled={this.state.invoiceUploadData.disableInput}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="default"
                            onClick={this.handleClose}
                            data-test-auto="B1C823D3-8D5E-4B9B-9D59-11F3A0D9BEED"
                        >
                            <i className="fas fa-times"></i>Cancel
                        </Button>
                        <Button
                            variant="info"
                            onClick={this.OnSubmitReplaceInvoice}
                            disabled={this.state.invoiceUploadData.disableInput}
                            data-test-auto="5D0B9B03-68DE-47E7-8681-6EDBD54E005B"
                        >
                            <i className="fas fa-save"></i>Save
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}
export default InvoiceReplaceModal;
