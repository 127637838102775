import * as React from 'react';
import { getCurrentDate } from '../../../helper/HelperFunctions';
import { ISignatureControl, SignatureControlRole, SignatureControlTypes, SignatureGroupType } from '../../TaxReturn';
import { IEROSigner, ISigner } from '../ProcessReturnModels';
import { getControlIcon, getControlText } from './SignatureHelper';
import { ICheckBoxControl, IRadioButtonControl,ChoosableControlData } from '../AdditionalEsignDocuments/AdditionalEsignDocument';

export interface SignatureControlProps {
    control: ISignatureControl
    selectedSigner: ISigner
    selectedEROSigner: IEROSigner
    focusedGroup: SignatureGroupType
    isAssignedToLoggedinUser: boolean
    onControlRemove(control: ISignatureControl): void
    showControlPropertyPopup(control: ISignatureControl): void
    style: React.CSSProperties
}

export class SignatureControl2 extends React.Component<SignatureControlProps, {}> {
    public render() {
        const selectedSignStyle = this.getSelectedSignStyle()
        const eroSignHighlightStyle =
            this.props.focusedGroup && this.props.focusedGroup === SignatureGroupType.Stamp ? 'ero-sign-highlight' : ''

        let controlText = getControlText(this.props.control.type || SignatureControlTypes.None)
        let icon = getControlIcon(this.props.control.type || SignatureControlTypes.None)

        const controlContent =
            this.props.control.type === SignatureControlTypes.RadioButton 
                || this.props.control.type === SignatureControlTypes.CheckBox? (
                this.choosableControlGroup(this.props.control,selectedSignStyle)
            ) : (
                <div className={'draggable-signature-control ' + selectedSignStyle} style={this.props.style}>
                    <i className={icon} style={{ padding: '5px', background: '#f0f0f1' }}></i>{' '}
                    {controlText}
                    {this.getControlActions(this.props.control)}
                </div>
            )

        const eroStampConentSignature = (
            <div
                className={'draggable-signature-control esignEROStamp ' + eroSignHighlightStyle}
                style={this.props.style}
            >
                {this.props.selectedEROSigner.signPath.length > 1 ? (
                    <img
                        src={this.props.selectedEROSigner.signPath}
                        max-width="200px"
                        max-height={this.props.style.height}
                        draggable="false"
                    />
                ) : null}
                <span
                    onClick={() => {
                        this.props.onControlRemove(this.props.control)
                    }}
                    aria-disabled={!this.props.isAssignedToLoggedinUser}
                    className="remove-handle glyphicon glyphicon-remove"
                    style={{ cursor: 'pointer' }}
                ></span>
            </div>
        )

        const eroStampConentDate = (
            <div
                className={'draggable-signature-control esignEROStamp ' + eroSignHighlightStyle}
                style={this.props.style}
            >
                <label>{getCurrentDate()}</label>
                <span
                    onClick={() => {
                        this.props.onControlRemove(this.props.control)
                    }}
                    aria-disabled={!this.props.isAssignedToLoggedinUser}
                    className="remove-handle glyphicon glyphicon-remove"
                    style={{ cursor: 'pointer' }}
                ></span>
            </div>
        )

        const eroStampContent =
            this.props.control.type === SignatureControlTypes.Date ? eroStampConentDate : eroStampConentSignature

        return this.props.control.signatureControlRole === SignatureControlRole.ERO ? eroStampContent : controlContent
    }

    private getControlActions = (control:ISignatureControl) => {
        switch(control.type){
            case SignatureControlTypes.Text:
            case SignatureControlTypes.CheckBox:
                case SignatureControlTypes.RadioButton:
                    return <><span
                        onClick={() => {
                            this.props.showControlPropertyPopup(this.props.control)
                        }}
                        aria-disabled={!this.props.isAssignedToLoggedinUser}
                        className="edit-handle fas fa-edit"
                        style={{ cursor: 'pointer' }}
                    ></span>
                    <span
                        onClick={() => {
                            this.props.onControlRemove(this.props.control)
                        }}
                        aria-disabled={!this.props.isAssignedToLoggedinUser}
                        className="remove-handle glyphicon glyphicon-remove"
                        style={{ cursor: 'pointer' }}
                    ></span></>
                default:
                    return <>
                        <span
                            onClick={() => {
                                this.props.onControlRemove(this.props.control)
                            }}
                            aria-disabled={!this.props.isAssignedToLoggedinUser}
                            className="remove-handle glyphicon glyphicon-remove"
                            style={{ cursor: 'pointer' }}
                        ></span>
                    </>
        }
    }

    private choosableControlGroup = (control:ISignatureControl, selectedSignStyle:any) => {
        let choosableControl= control as IRadioButtonControl|ICheckBoxControl;
        return  <div id={'choosable_control_' + control.controlGuid}  data-id={control.controlGuid}
            data-signerstyle = {selectedSignStyle}
            className={'choosable-signature-control'} 
            style={{
                height: "100%",
                width:  "100%",
            }}>
            {this.createChoosableControls(choosableControl.type,control.controlGuid, choosableControl.items)}
            {this.getControlActions(control)}
           <div className={"radio-group-control-footer"}>
                <button id={control.controlGuid + '_draggable-btn'} 
                        className={"btn btn-default btn-xs draggable-btn-control"}>
                    <span className={"fas fa-arrows-alt"}></span>
                </button>
            </div>
        </div>
    }

    private createChoosableControls = (type:SignatureControlTypes,controlId:string, data: 
        ChoosableControlData[]) => {

        let controls: any = [];

        let icon = getControlIcon(type || SignatureControlTypes.None)
    
        data.forEach(function (control: ChoosableControlData) {
            controls.push(<div id={control.id} key={"key_" + control.id}
                data-id={control.id}  data-controlguid={controlId} className={"radio-btn-container choosable-control-item"} style={{
                    left: control.left + "px", top: control.top + "px"
            }}>
                 <i className={icon} style={{ padding: '2px', background: '#f0f0f1' }}></i>
          </div>);
        });
        return controls;
      }

    private getSelectedSignStyle(): string {
        if (
            this.props.focusedGroup &&
            this.props.focusedGroup === SignatureGroupType.Esign &&
            this.props.control.signatureControlRole === this.props.selectedSigner.role
        ) {
            switch (this.props.control.signatureControlRole) {
                case SignatureControlRole.Taxpayer:
                    return 'taxpayer-sign-highlight'
                case SignatureControlRole.Spouse:
                    return 'spouse-sign-highlight'
                case SignatureControlRole.PartnerShip:
                    return 'partnership-sign-highlight'
            }
        }
        return ''
    }
}
