import { addTask } from "domain-task";
import { Action, Reducer } from "redux";
import { AppThunkAction } from "../";
import {
    ITaxReturn,
    TaxCaddyLookupResultModel,
    initialTaxCaddyDeliveryViewModel,
    TaxCaddyLookupResult,
    EngagementType,
    IDocumentAccess,
    IDocumentTransaction,
    ISignerModel,
    IAccessCode,
    ClientTypes,
    IDownloadableDocuments,
    DocumentEvent,
    DocumentGroups,
    DocumentStatus,
    DeliveryMode,
    initialTaxCaddyLookupResultModel,
    updateTaxFormType,
    IAccessCodeViewModel,
    IDocumentSettings,
    TaxDocumentRevisionStatus,
    ITaxDocumentRevision,
    ITaxDocumentStatusViewModel,
    IDocumentAccessSaveModel,
    ITaxingAuthority,
    IUserTransaction,
    ITaxDocumentAccessViewModel,
    ITaxDocumentViewModel
} from "../../components/common/TaxReturn";
import { DisplayDownloadFile } from "../../components/common/DisplayDownloadFile";
import { ResetArchivedReturnsAction } from "../reports/archive/ArchivedTaxDocumentStore";
import { actionTypes } from "../../types/ActionTypes";
import { handleResponse, extractDocumentIds, handleBlob, handleBlobwithFileName, extractFailedDocumentIds } from "../Library";
import { IColumnValues } from "../../components/settings/GeneralSettings";
import * as CustomColumnStore from "../../store/common/CustomColumnStore";
import * as DeliveredReturnsStore from "../../store/reports/DeliveredReturnsStore";
import * as Notification from "../common/NotificationStore";
import * as Constants from "../../components/helper/Constants";
import { encodeGroupInfoDataHtmlTags, getCurrentDate } from "../../components/helper/HelperFunctions";
import { ISubDocument, SubDocType } from "../../Core/Utilities/PdfDocumentFacade";
import {
    IShareHolder,
    processReturnActionEndPoints,
    userErrorKey
} from "../../components/common/ProcessReturnModal/ProcessReturnModels";
import { ResetPdfAction } from "../pdf/PdfStore";
import { HideLoader } from "../../components/helper/Loader";
import { IGroupInfo } from "../../store/groupedreturns/GroupedReturnProcessState";
import {
    ReceiveGroupedTaxDocumentAction,
    ReceiveGroupedDocumentStatusAction,
    UpdateGroupedTaxDocumentStatusAction,
    UpdateSendGroupedReturnsDocumentStatusUpdate
} from "../groupedreturns/GroupedTaxDocumentStore";
import {
    ResetDeliveredGroupedReturnsAction,
    RefreshGroupedReturnsTaxDocumentRecallAction
} from "../reports/GroupedReturns/DeliveredGroupedReturns/DeliveredGroupedReturnsStore";
import { IReportProblemDetails } from "../../components/common/ReportProblem/ReportProblemModel";
import { StatusType } from "../common/NotificationStore";
import { getUserTimeZone } from "../../components/helper/UserHelperFunctions";
import * as GroupedReturnsStore from "../../store/groupedreturns/GroupedTaxDocumentStore";
import { API_BASE_URL } from "../../utils/constants";
import { logger } from "../../routes/LoggedIn";
import * as CRSHelper from "../../../src/components/helper/CRSHelper";
import { encodeTaxDocumentHtmlTags, decodeTaxDocumentHtmlTags } from "../../../src/components/helper/HelperFunctions";
import { IGroupedReturnsTransactionViewModel } from "../../store/reports/GroupedReturns/DeliveredGroupedReturns/DeliveredGroupedReturnsState";

export enum TaxReturnSource {
    TaxDocument,
    UserAssignments,
    CompanyAssignments,
    DeliveredReturns,
    InUseReturns,
    QuickReport
}

export interface ResetTaxReturnsAction {
    type: actionTypes.RESET_TAX_RETURNS;
}

export interface ResetDeliveredReturnsAction {
    type: actionTypes.RESET_DELIVERED_RETURNS;
}

export interface RequestTaxDocumentAction {
    type: actionTypes.REQUEST_TAX_DOCUMENT;
    id: number;
}

export interface ReceiveTaxDocumentAction {
    type: actionTypes.RECEIVE_TAX_DOCUMENT;
    id: number;
    taxDocument: ITaxReturn;
    source: TaxReturnSource;
    isEditClientInfoRequest?: boolean;
}

export interface ReceiveTaxDocumentsAction {
    type: actionTypes.RECEIVE_TAX_DOCUMENTS;
    taxDocuments: ITaxReturn[];
    source: TaxReturnSource;
}

export interface UpdateTaxDocumentAction {
    type: actionTypes.UPDATE_TAX_DOCUMENT;
    id: number;
    taxDocument: ITaxReturn;
}

export interface SaveTaxDocumentAction {
    type: actionTypes.UPDATE_TAX_DOCUMENT;
    id: number;
    taxDocument: ITaxReturn;
}

export interface DeleteTaxDocumentAction {
    type: actionTypes.DELETE_TAX_DOCUMENT;
    ids: number[];
}

export interface DeleteDeliveredTaxDocumentAction {
    type: actionTypes.DELETE_DELIVERED_TAX_DOCUMENT;
    ids: number[];
}

export interface ArchiveTaxDocumentAction {
    type: actionTypes.ARCHIVE_TAX_DOCUMENT;
    ids: number[];
}

export interface RecallTaxDocumentAction {
    type: actionTypes.RECALL_TAX_DOCUMENT;
    taxDocument: ITaxReturn;
}

export interface AssignTaxDocumentAction {
    type: actionTypes.ASSIGN_TAX_DOCUMENT;
    taxDocument: ITaxReturn;
}

export interface ChangeStatusTaxDocumentAction {
    type: actionTypes.ASSIGN_TAX_DOCUMENT;
    taxDocument: ITaxReturn;
}

export interface RequestAssignUserAction {
    type: actionTypes.REQUEST_ASSIGN_USER;
    id: number;
}

export interface ReceiveAssignUserAction {
    type: actionTypes.RECEIVE_ASSIGN_USER;
    id: number;
    taxDocument: ITaxReturn;
}

export interface RequestDocumentAccessAction {
    type: actionTypes.REQUEST_TAX_DOCUMENT_ACCESS;
    ids: number[];
}

export interface ErrorTaxDocumentAction {
    type: actionTypes.ERROR_TAX_DOCUMENT;
    ids: number[];
    message: string;
}

export interface RequestClientTrackingAction {
    type: actionTypes.REQUEST_TAX_DOCUMENT_CLIENT_TRACKING;
    id: number;
}

export interface ReceiveClientTrackingAction {
    type: actionTypes.RECEIVE_TAX_DOCUMENT_CLIENT_TRACKING;
    id: number;
    clientTracking: IDocumentTransaction[];
}

export interface RequestInternalUserTrackingAction {
    type: actionTypes.REQUEST_INTERNAL_USER_TRACKING;
    id: number;
}

export interface ReceiveInternalUserTrackingAction {
    type: actionTypes.RECEIVE_INTERNAL_USER_TRACKING;
    documentId: number;
    userEvents: IUserTransaction[];
}

export interface ReceiveControllerTrackingAction {
    type: actionTypes.RECEIVE_CONTROLLER_TRACKING;
    documentId: number;
    controllerEvents: IGroupedReturnsTransactionViewModel[];
}

export interface UpdateTaxDocumentCustomColumnValueAction {
    type: actionTypes.UPDATE_DOCUMENT_CUSTOM_COLUMN_VALUE;
    id: number;
    customColumn: string;
}

export interface SendReminderTaxDocumentAction {
    type: actionTypes.SEND_REMINDER_TAX_DOCUMENT;
    id: number;
}

export interface RequestSignedDetailsAction {
    type: actionTypes.REQUEST_TAX_DOCUMENT_SIGNED_DETAILS;
    id: number;
}

export interface ReceiveSignedDetailsAction {
    type: actionTypes.RECEIVE_TAX_DOCUMENT_SIGNED_DETAILS;
    id: number;
    signedDetails: ISignerModel[];
}

export interface ReceiveDocumentStatusAction {
    type: actionTypes.RECEIVE_TAX_DOCUMENT_STATUS;
    id: number;
    documentStatus: DocumentStatus;
}

export interface CheckTaxDocumentExistAction {
    type: actionTypes.CHECK_TAXDOCUMENT_EXIST;
    id: number;
    engatementType: EngagementType;
    taxyear: number;
    result: boolean;
}

export interface RequestAccessCodeAction {
    type: actionTypes.REQUEST_TAX_DOCUMENT_ACCESS_CODE;
    id: number;
}

export interface ReceiveAccessCodeAction {
    type: actionTypes.RECEIVE_TAX_DOCUMENT_ACCESS_CODE;
    id: number;
    accessCode: IAccessCodeViewModel;
}

export interface RequestDownloadableDocumentsAction {
    type: actionTypes.REQUEST_TAX_DOCUMENT_DOWNLOADABLE_DOCUMENTS;
    id: number;
}

export interface ReceiveDownloadableDocumentsAction {
    type: actionTypes.RECEIVE_TAX_DOCUMENT_DOWNLOADABLE_DOCUMENTS;
    id: number;
    downloadableDocuments: IDownloadableDocuments[];
}

export interface RequestDownloadableEfileFormsAction {
    type: actionTypes.REQUEST_TAX_DOCUMENT_DOWNLOADABLE_EFILE_FORMS;
    id: number;
}

export interface ReceiveDownloadableEfileFormsAction {
    type: actionTypes.RECEIVE_TAX_DOCUMENT_DOWNLOADABLE_EFILE_FORMS;
    id: number;
    downloadableEfileForms: IDownloadableDocuments[];
}

export interface GenerateOTPAction {
    type: actionTypes.GENERATE_OTP_TAX_DOCUMENT;
    id: number;
    clientType: ClientTypes;
    clientGUID: string;
    otp: string;
    createdOn: Date;
}

export interface ClearAccessCodeAction {
    type: actionTypes.GENERATE_OTP_TAX_DOCUMENT;
    id: number;
}

export interface RequestDownloadHistoryAction {
    type: actionTypes.REQUEST_TAX_DOCUMENT_DOWNLOAD_HISTORY;
    id: number;
}

export interface ReceiveDownloadHistoryAction {
    type: actionTypes.RECEIVE_TAX_DOCUMENT_DOWNLOAD_HISTORY;
    id: number;
    downloadHistory: IDocumentTransaction[];
}

export interface MakeAvailableTaxDocumentAction {
    type: actionTypes.MAKE_AVAILABLE_INUSE_TAX_DOCUMENT;
    ids: number[];
}

export interface SendToEROAction {
    type: actionTypes.SEND_TO_ERO_TAX_DOCUMENT;
}

export interface SendForReviewAction {
    type: actionTypes.SEND_FOR_REVIEW_TAX_DOCUMENT;
}

export interface DeliverToClientAction {
    type: actionTypes.DELIVER_TAX_DOCUMENT;
}
export interface DeliverToControllerAction {
    type: actionTypes.DELIVER_TAX_DOCUMENT_TO_CONTROLLER;
}

export interface ApproveForDeliveryAction {
    type: actionTypes.APPROVE_FOR_DELIVERY_TAX_DOCUMENT;
}

export interface MarkAsReadyForDeliveryDocumentAction {
    type: actionTypes.MARK_AS_READY_FOR_DELIVERY;
    id: number;
}

export interface K1Instruction {
    type: actionTypes.K1_INSTRUCTION_DETAIL;
    id: number;
    fileName: string;
    isDefaultK1: boolean;
}

export interface IProcessReturnViewModel {
    taxDocument: ITaxReturn;
    parts: ISubDocument[];
    taxCaddyDeliveryViewModel?: TaxCaddyLookupResult;
    isK1Replaced: boolean;
    isK1Restored: boolean;
    isMFJChanged: boolean;
    clientType?: ClientTypes;
    groupInfo?: IGroupInfo;
    isK1ShareholderUpdated?: boolean;
    updatedShareHolders?: IShareHolder[];
}

export interface IProcessGroupedTaxDocumentViewModel {
    taxDocument: ITaxReturn;
    parts: ISubDocument[];
    isK1Replaced: boolean;
    isK1Restored: boolean;
    isMFJChanged: boolean;
    clientType?: ClientTypes;
    groupInfo?: IGroupInfo;
    taxDocumentsForDelivery?: ISelectedDocumentForDeliveryViewModel[];
    isK1ShareholderUpdated?: boolean;
    updatedShareHolders?: IShareHolder[];
    isReturnMarkedReadyForDelivery?: boolean; 
    isMarkAsReadyForDeliveryChanged?: boolean
}

export interface IRevisedProcessReturnViewModel {
    taxDocument: ITaxReturn;
    parts: ISubDocument[];
    revisionDocuments?: ITaxDocumentRevision[];
    groupId?: number;
}

export interface ISelectedDocumentForDeliveryViewModel {
    taxDocumentId: number;
    documentSettings: IDocumentSettings;
    engagementType: EngagementType;
}

export interface IProcessReturnSubDocument {
    path: string;
    pageMap: number[];
}

export interface GetTaxCaddyLookupDetails {
    type: actionTypes.REQUEST_TAXCADDY_LOOKUP_DETAILS;
    taxcaddyLookupDetails: TaxCaddyLookupResultModel;
    id: number;
}

export interface ClearTaxCaddyLookupDetails {
    type: actionTypes.CLEAR_TAXCADDY_LOOKUP_DETAILS;
    id: number;
}

export interface ResetTaxDocument {
    type: actionTypes.RESET_TAX_DOCUMENT;
    id: number;
}

export interface UpdateDocumentInitialStatus {
    type: actionTypes.UPDATE_DOCUMENT_INITIAL_STATUS;
}

export interface NotifyTaxDocument {
    type: actionTypes.NOTIFY_TAX_DOCUMENT;
}

export interface RestoreArchivedTaxDocumentAction {
    type: actionTypes.RESTORE_ARCHIVE_TAX_DOCUMENT;
    ids: number[];
}

export interface MissedRecognizedEventForTaxDocumentAction {
    type: actionTypes.SET_MISSED_RECOGNIZED_EVENT_FOR_TAX_DOCUMENT;
    id: number;
}

export interface ResetMissedRecognizedEventForTaxDocumentAction {
    type: actionTypes.RESET_MISSED_RECOGNIZED_EVENT_FOR_TAX_DOCUMENT;
    id: number;
}

type KnownAction =
    | RequestTaxDocumentAction
    | ReceiveTaxDocumentAction
    | UpdateTaxDocumentAction
    | SaveTaxDocumentAction
    | DeleteTaxDocumentAction
    | DeleteDeliveredTaxDocumentAction
    | ArchiveTaxDocumentAction
    | RestoreArchivedTaxDocumentAction
    | RecallTaxDocumentAction
    | AssignTaxDocumentAction
    | ChangeStatusTaxDocumentAction
    | RequestAssignUserAction
    | ReceiveAssignUserAction
    | RequestDocumentAccessAction
    | ErrorTaxDocumentAction
    | RequestClientTrackingAction
    | ReceiveClientTrackingAction
    | RequestDownloadHistoryAction
    | ReceiveDownloadHistoryAction
    | RequestAccessCodeAction
    | ReceiveAccessCodeAction
    | GenerateOTPAction
    | SendReminderTaxDocumentAction
    | RequestSignedDetailsAction
    | ReceiveSignedDetailsAction
    | ReceiveGroupedDocumentStatusAction
    | ReceiveDocumentStatusAction
    | CheckTaxDocumentExistAction
    | RequestDownloadableDocumentsAction
    | ReceiveDownloadableDocumentsAction
    | RequestDownloadableEfileFormsAction
    | ReceiveDownloadableEfileFormsAction
    | ResetTaxReturnsAction
    | ResetDeliveredReturnsAction
    | ResetArchivedReturnsAction
    | MakeAvailableTaxDocumentAction
    | SendToEROAction
    | SendForReviewAction
    | DeliverToClientAction
    | ApproveForDeliveryAction
    | Notification.NotificationAction
    | K1Instruction
    | GetTaxCaddyLookupDetails
    | ClearTaxCaddyLookupDetails
    | ResetTaxDocument
    | UpdateDocumentInitialStatus
    | NotifyTaxDocument
    | ResetPdfAction
    | ReceiveGroupedTaxDocumentAction
    | DeliverToControllerAction
    | ResetDeliveredGroupedReturnsAction
    | UpdateGroupedTaxDocumentStatusAction
    | UpdateSendGroupedReturnsDocumentStatusUpdate
    | RefreshGroupedReturnsTaxDocumentRecallAction
    | MissedRecognizedEventForTaxDocumentAction
    | ResetMissedRecognizedEventForTaxDocumentAction
    | MarkAsReadyForDeliveryDocumentAction
    | RequestInternalUserTrackingAction
    | ReceiveInternalUserTrackingAction
    | ReceiveControllerTrackingAction;

type DispatchActions =
    | RequestTaxDocumentAction
    | ReceiveTaxDocumentAction
    | ReceiveTaxDocumentsAction
    | DeleteTaxDocumentAction
    | DeleteDeliveredTaxDocumentAction
    | ArchiveTaxDocumentAction
    | RecallTaxDocumentAction
    | ErrorTaxDocumentAction
    | RequestClientTrackingAction
    | ReceiveClientTrackingAction
    | ReceiveDownloadHistoryAction
    | ReceiveAccessCodeAction
    | RequestSignedDetailsAction
    | ReceiveDocumentStatusAction
    | GenerateOTPAction
    | ReceiveSignedDetailsAction
    | ReceiveDownloadableDocumentsAction
    | ReceiveDownloadableEfileFormsAction
    | MakeAvailableTaxDocumentAction
    | K1Instruction
    | GetTaxCaddyLookupDetails
    | ClearTaxCaddyLookupDetails
    | MarkAsReadyForDeliveryDocumentAction
    | RequestAssignUserAction
    | ReceiveAssignUserAction
    | ResetTaxDocument
    | ReceiveInternalUserTrackingAction
    | ReceiveControllerTrackingAction;

type Actions = MissedRecognizedEventForTaxDocumentAction | ResetMissedRecognizedEventForTaxDocumentAction;

export interface ITaxDocumentDictionary {
    [index: number]: {
        taxReturn: ITaxReturn;
        isLoading: boolean;
        error: boolean;
        message: string;
        isFullyLoaded: boolean;
        fileName?: string;
        isDefaultK1?: boolean;
    };
}

export interface IMissingTaxDocumentState {
    ids: number[];
}

export function documentsToUpdate(store: ITaxDocumentDictionary, ids: number[]) {
    let newList: number[] = [];
    ids.map((id, i) => {
        if (!store[id].taxReturn.documentAccess) {
            newList.push(id);
        }
    });
    return newList;
}

export const actionCreators = {
    requestTaxDocument:
        (
            id: number,
            force: boolean = false,
            requestTaxPayerView?: boolean,
            clientType?: ClientTypes,
            callback?: (data: any) => void,
            isEditClientInfoRequest?: boolean,
            resourceId: string = ""
        ): AppThunkAction<KnownAction> =>
        (dispatch, getState) => {
            let state = getState();
            if (isEditClientInfoRequest === undefined) {
                isEditClientInfoRequest = false;
            }
            if (
                force ||
                !state.taxDocuments[id] ||
                !state.taxDocuments[id].taxReturn ||
                state.taxDocuments[id].taxReturn.id === 0
            ) {
                const fetchTask = fetch(API_BASE_URL + "api/TaxDocument/" + id + "/" + isEditClientInfoRequest, {
                    method: "GET",
                    credentials: "include"
                })
                    .then(handleResponse)
                    .then((response) => response as Promise<ITaxReturn>)
                    .then((data) => {
                        updateTaxFormType(data);
                        dispatch({
                            type: actionTypes.RECEIVE_TAX_DOCUMENT,
                            id: id,
                            taxDocument: data,
                            source: TaxReturnSource.TaxDocument,
                            isEditClientInfoRequest: isEditClientInfoRequest
                        });
                        if (requestTaxPayerView) {
                            let action: any = DeliveredReturnsStore.actionCreators.generateTaxpayerView(
                                data,
                                clientType,
                                resourceId
                            );
                            dispatch(action);
                        }
                        if (callback) {
                            callback(data);
                        }
                    })
                    .catch((error) => {
                        dispatch({ type: actionTypes.ERROR_TAX_DOCUMENT, ids: [id], message: error });
                    });
                addTask(fetchTask);
            }
        },

    updateTaxDocument:
        (taxReturn: ITaxReturn, callback?: () => void): AppThunkAction<KnownAction> =>
        (dispatch, getState) => {
            dispatch({
                type: actionTypes.RECEIVE_TAX_DOCUMENT,
                id: taxReturn.id,
                taxDocument: taxReturn,
                source: TaxReturnSource.TaxDocument
            });
            if (callback) {
                callback();
            }
        },
    requestGroupedTaxDocumentById:
        (
            id: number,
            force: boolean = false,
            requestTaxPayerView?: boolean,
            clientType?: ClientTypes,
            callback?: (data: any) => void,
            isEditClientInfoRequest?: boolean,
            resourceId: string = ""
        ): AppThunkAction<KnownAction> =>
        (dispatch, getState) => {
            let state = getState();
            if (isEditClientInfoRequest === undefined) {
                isEditClientInfoRequest = false;
            }
            if (
                force ||
                !state.taxDocuments[id] ||
                !state.taxDocuments[id].taxReturn ||
                state.taxDocuments[id].taxReturn.id === 0
            ) {
                const fetchTask = fetch(API_BASE_URL + "api/GroupedTaxDocument/" + id + "/" + isEditClientInfoRequest, {
                    method: "GET",
                    credentials: "include"
                })
                    .then(handleResponse)
                    .then((response) => response as Promise<ITaxReturn>)
                    .then((data) => {
                        //when ungrouped tax document is requested, data is empty object.
                        if (data.id) {
                            updateTaxFormType(data);
                            dispatch({
                                type: actionTypes.RECEIVE_TAX_DOCUMENT,
                                id: id,
                                taxDocument: data,
                                source: TaxReturnSource.TaxDocument,
                                isEditClientInfoRequest: isEditClientInfoRequest
                            });
                            if (requestTaxPayerView) {
                                let action: any = DeliveredReturnsStore.actionCreators.generateTaxpayerView(
                                    data,
                                    clientType,
                                    resourceId
                                );
                                dispatch(action);
                            }
                            if (callback) {
                                callback(data);
                            }
                        }
                    })
                    .catch((error) => {
                        dispatch({ type: actionTypes.ERROR_TAX_DOCUMENT, ids: [id], message: error });
                    });
                addTask(fetchTask);
            }
        },
    markAsReadyForDocumentDelivery:
        (id: number, successCallback?: (id: number) => void, resourceId?: string): AppThunkAction<KnownAction> =>
        (dispatch, getState) => {
            const fetchTask = fetch(API_BASE_URL + "api/GroupedTaxDocument/MarkReturnAsReadyForDeliveryAsync/" + id, {
                method: "GET",
                credentials: "include",
                headers: {
                    "X-Resource-Id": !resourceId ? "" : resourceId
                }
            })
                .then(handleResponse)
                .then(() => {
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: Constants.GroupedTaxDocumentConstants.StatusMessage.MarkAsReadyForDeliverySuccess,
                        statusType: StatusType.Success
                    });
                    if (successCallback) {
                        successCallback(id);
                    }
                })
                .catch((error) => {
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: Constants.GroupedTaxDocumentConstants.StatusMessage.MarkAsReadyForDeliveryError,
                        statusType: StatusType.Error
                    });
                });
            addTask(fetchTask);
        },
    saveTaxDocument:
        (
            taxReturn: ITaxReturn,
            customColumn?: IColumnValues,
            callback?: (data?: any) => void,
            emailCallback?: (id: number, isMailSent: boolean, clientGUIDs: string[], signerInfo?: ISignerModel) => void,
            isMailSent?: boolean,
            clientGUIDs?: string[],
            isEditDeliveredClientInfoRequest?: boolean,
            signerInfo?: ISignerModel,
            resourceId: string = ""
        ): AppThunkAction<KnownAction> =>
        (dispatch, getState) => {
            encodeTaxDocumentHtmlTags(taxReturn);

            const formData = new FormData();
            let taxReturnData = JSON.stringify(taxReturn);
            formData.append("taxDocument", taxReturnData);
            formData.append(
                "isEditDeliveredClientInfoRequest",
                isEditDeliveredClientInfoRequest ? JSON.stringify(isEditDeliveredClientInfoRequest) : "false"
            );
            let options: any = {
                method: "PUT",
                credentials: "include",
                headers: {
                    Accept: "application/json",
                    "X-Resource-Id": resourceId,
                    RequestVerificationToken: (document.getElementById("RequestVerificationToken") as HTMLInputElement).value
                },
                body: formData
            };
            const fetchTask = fetch(API_BASE_URL + "api/TaxDocument", options)
                .then(handleResponse)
                .then(() => {
                    if (customColumn) {
                        let action: any = CustomColumnStore.actionCreators.updateCustomColumnValue(
                            customColumn,
                            false,
                            resourceId
                        );
                        dispatch(action);
                    }

                    dispatch({
                        type: actionTypes.RECEIVE_TAX_DOCUMENT,
                        id: taxReturn.id,
                        taxDocument: taxReturn,
                        source: TaxReturnSource.TaxDocument
                    });
                    callback && callback(taxReturn);
                    emailCallback &&
                        ((isMailSent && clientGUIDs) || signerInfo !== undefined) &&
                        emailCallback(taxReturn.id, isMailSent, clientGUIDs, signerInfo);
                })
                .catch((error) => {
                    dispatch({ type: actionTypes.ERROR_TAX_DOCUMENT, ids: [taxReturn.id], message: error });
                    callback && callback(taxReturn);
                });
            addTask(fetchTask);
        },

    deleteTaxDocument:
        (ids: number[], onSuccess: () => void, resourceId: string = ""): AppThunkAction<KnownAction> =>
        (dispatch, getState) => {
            const fetchTask = fetch(API_BASE_URL + "api/TaxDocument", {
                method: "DELETE",
                credentials: "include",
                headers: {
                    Accept: "application/json, text/plain, */*",
                    "Content-Type": "application/json; charset=utf-8",
                    RequestVerificationToken: (document.getElementById("RequestVerificationToken") as HTMLInputElement).value,
                    "X-Resource-Id": resourceId
                },
                body: JSON.stringify(ids)
            })
                .then(handleResponse)
                .then(() => {
                    dispatch({ type: actionTypes.DELETE_TAX_DOCUMENT, ids: ids });
                    onSuccess();
                })
                .catch((error) => {
                    dispatch({ type: actionTypes.ERROR_TAX_DOCUMENT, ids: ids, message: error.statusText });
                });
            addTask(fetchTask);
        },

    changeOfficeLocation:
        (
            ids: number[],
            locationId: number,
            contactPerson: number,
            onSuccess: () => void,
            onError: () => void,
            resourceId: string = ""
        ): AppThunkAction<KnownAction> =>
        (dispatch, getState) => {
            const fetchTask = fetch(API_BASE_URL + "api/TaxDocument/change-office-location", {
                method: "PUT",
                credentials: "include",
                headers: {
                    Accept: "application/json, text/plain, */*",
                    "Content-Type": "application/json; charset=utf-8",
                    RequestVerificationToken: (document.getElementById("RequestVerificationToken") as HTMLInputElement).value,
                    "X-Resource-Id": resourceId
                },
                body: JSON.stringify({
                    documents: ids,
                    location: locationId,
                    contactPerson: contactPerson
                })
            })
                .then(handleResponse)
                .then(() => {
                    onSuccess();
                })
                .catch((error) => {
                    onError();
                });
            addTask(fetchTask);
        },

    deleteDeliveredTaxDocument:
        (ids: number[], onSuccess: () => void, resourceId: string = ""): AppThunkAction<KnownAction> =>
        (dispatch, getState) => {
            const fetchTask = fetch(API_BASE_URL + "api/TaxDocument/DeleteDeliveredReturn", {
                method: "DELETE",
                credentials: "include",
                headers: {
                    Accept: "application/json, text/plain, */*",
                    "X-Resource-Id": resourceId,
                    "Content-Type": "application/json; charset=utf-8",
                    RequestVerificationToken: (document.getElementById("RequestVerificationToken") as HTMLInputElement).value
                },
                body: JSON.stringify(ids)
            })
                .then(handleResponse)
                .then(() => {
                    dispatch({ type: actionTypes.DELETE_DELIVERED_TAX_DOCUMENT, ids: ids });
                    dispatch({ type: actionTypes.RESET_DELIVERED_GROUPED_RETURNS });
                    onSuccess();
                    logger.trackTrace(`return deleted successfully for documentIds: ${ids?.toString()}`);
                })
                .catch((error) => {
                    dispatch({ type: actionTypes.ERROR_TAX_DOCUMENT, ids: ids, message: error.statusText });
                });
            addTask(fetchTask);
        },

    deleteGroupedDeliveredTaxDocument:
        (ids: number[], onSuccess: () => void, resourceId: string = ""): AppThunkAction<KnownAction> =>
        (dispatch, getState) => {
            //TODO: need to move to Groupreturn Store
            const fetchTask = fetch(API_BASE_URL + "api/GroupedTaxDocument/DeleteGroupedDeliveredReturn", {
                method: "DELETE",
                credentials: "include",
                headers: {
                    Accept: "application/json, text/plain, */*",
                    "Content-Type": "application/json; charset=utf-8",
                    "X-Resource-Id": resourceId,
                    RequestVerificationToken: (document.getElementById("RequestVerificationToken") as HTMLInputElement).value
                },
                body: JSON.stringify(ids)
            })
                .then(handleResponse)
                .then(() => {
                    dispatch({ type: actionTypes.DELETE_DELIVERED_TAX_DOCUMENT, ids: ids });
                    dispatch({ type: actionTypes.RESET_DELIVERED_GROUPED_RETURNS });
                    onSuccess();
                })
                .catch((error) => {
                    dispatch({ type: actionTypes.ERROR_TAX_DOCUMENT, ids: ids, message: error.statusText });
                });
            addTask(fetchTask);
        },

    archiveTaxDocument:
        (model: ITaxDocumentViewModel[], callback: () => void, resourcedId: string = ""): AppThunkAction<KnownAction> =>
        (dispatch, getState) => {
            let documentAccesses = JSON.stringify(model);
            const ids = model.map((doc) => doc.documentId);
            const fetchTask = fetch(API_BASE_URL + "api/Reports/DeliveredReturns/", {
                method: "DELETE",
                credentials: "include",
                headers: {
                    Accept: "application/json, text/plain, */*",
                    "Content-Type": "application/json; charset=utf-8",
                    "X-Resource-Id": resourcedId,
                    RequestVerificationToken: (document.getElementById("RequestVerificationToken") as HTMLInputElement).value
                },
                body: documentAccesses
            })
                .then(handleResponse)
                .then(() => {
                    dispatch({ type: actionTypes.ARCHIVE_TAX_DOCUMENT, ids: ids });
                    dispatch({ type: actionTypes.RESET_ARCHIVE_RETURNS });
                    dispatch({ type: actionTypes.RESET_DELIVERED_RETURNS });
                    logger.trackTrace(`archiveTaxDocument successfully for documentIds: ${ids?.toString()}`);
                    callback();
                })
                .catch((error) => {
                    dispatch({ type: actionTypes.ERROR_TAX_DOCUMENT, ids: extractFailedDocumentIds(model), message: error });
                });
            addTask(fetchTask);
        },

    restoreArchivedTaxDocument:
        (model: ITaxDocumentViewModel[], callback: () => void, resourceId: string = ""): AppThunkAction<KnownAction> =>
        (dispatch, getState) => {
            let documentAccesses = JSON.stringify(model);
            const fetchTask = fetch(API_BASE_URL + "api/Reports/DeliveredReturns/RestoreTaxReturns/", {
                method: "PUT",
                credentials: "include",
                headers: {
                    Accept: "application/json, text/plain, */*",
                    "X-Resource-Id": resourceId,
                    "Content-Type": "application/json; charset=utf-8",
                    RequestVerificationToken: (document.getElementById("RequestVerificationToken") as HTMLInputElement).value
                },
                body: documentAccesses
            })
                .then(handleResponse)
                .then(() => {
                    dispatch({ type: actionTypes.RESET_DELIVERED_RETURNS });
                    logger.trackTrace(`restoreArchivedTaxDocument successfully for documentIds: ${model?.toString()}`);
                    callback();
                })
                .catch((error) => {
                    dispatch({ type: actionTypes.ERROR_TAX_DOCUMENT, ids: extractFailedDocumentIds(model), message: error });
                });
            addTask(fetchTask);
        },
    recallTaxDocument:
        (
            taxReturn: ITaxReturn,
            successCallback: () => void,
            errorCallback?: () => void,
            groupId?: number,
            resourceId: string = ""
        ): AppThunkAction<KnownAction> =>
        (dispatch, getState) => {
            encodeTaxDocumentHtmlTags(taxReturn);

            const formData = new FormData();
            let taxReturnData = JSON.stringify(taxReturn);
            formData.append("taxDocument", taxReturnData);
            const fetchTask = fetch(API_BASE_URL + "api/TaxDocument/RecallReturn/", {
                method: "PUT",
                credentials: "include",
                body: formData,
                headers: {
                    RequestVerificationToken: (document.getElementById("RequestVerificationToken") as HTMLInputElement).value,
                    "X-Resource-Id": resourceId
                }
            })
                .then(handleResponse)
                .then(() => {
                    dispatch({ type: actionTypes.RECALL_TAX_DOCUMENT, taxDocument: taxReturn });
                    dispatch({ type: actionTypes.RESET_TAX_RETURNS });
                    dispatch({ type: actionTypes.RESET_DELIVERED_RETURNS });
                    dispatch({ type: actionTypes.RESET_PDF_OBJECTS, id: taxReturn.id });
                    if (groupId)
                        dispatch({
                            type: actionTypes.REFRESH_GROUPEDRETURN_TAX_DOCUMENT_RECALLACTION,
                            data: taxReturn,
                            groupId: groupId
                        });
                    logger.trackTrace(
                        `Return recalled successfully for documentId: ${taxReturn.id?.toString()} and documentGuid: ${
                            taxReturn.documentGuid
                        }`,
                        { DocumentId: taxReturn.id?.toString(), DocumentGuid: taxReturn.documentGuid }
                    );
                    successCallback();
                })
                .catch((error) => {
                    if (errorCallback) {
                        errorCallback();
                    }
                    dispatch({ type: actionTypes.ERROR_TAX_DOCUMENT, ids: [taxReturn.id], message: error });
                });
            addTask(fetchTask);
        },

    reprocessTaxDocument:
        (taxReturn: ITaxReturn, callback: (id: number) => void, resourceId: string = ""): AppThunkAction<KnownAction> =>
        (dispatch, getState) => {
            encodeTaxDocumentHtmlTags(taxReturn);

            const formData = new FormData();
            let taxReturnData = JSON.stringify(taxReturn);
            formData.append("taxDocument", taxReturnData);
            const fetchTask = fetch(API_BASE_URL + "api/TaxDocument/ReprocessReturn/", {
                method: "PUT",
                credentials: "include",
                body: formData,
                headers: {
                    RequestVerificationToken: (document.getElementById("RequestVerificationToken") as HTMLInputElement).value,
                    "X-Resource-Id": resourceId
                }
            })
                .then(handleResponse)
                .then(() => {
                    callback(taxReturn.id);
                    dispatch({
                        type: actionTypes.RECEIVE_TAX_DOCUMENT,
                        id: taxReturn.id,
                        taxDocument: taxReturn,
                        source: TaxReturnSource.TaxDocument
                    });
                })
                .catch((error) => {
                    dispatch({ type: actionTypes.ERROR_TAX_DOCUMENT, ids: [taxReturn.id], message: error });
                });
            addTask(fetchTask);
        },

    AssignTaxDocument:
        (taxReturn: ITaxReturn): AppThunkAction<KnownAction> =>
        (dispatch, getState) => {
            encodeTaxDocumentHtmlTags(taxReturn);

            const formData = new FormData();
            let taxReturnData = JSON.stringify(taxReturn);
            formData.append("taxDocument", taxReturnData);
            let options: any = {
                method: "PUT",
                credentials: "include",
                headers: {
                    Accept: "application/json",
                    RequestVerificationToken: (document.getElementById("RequestVerificationToken") as HTMLInputElement).value
                },
                body: formData
            };
            const fetchTask = fetch(API_BASE_URL + "api/TaxDocument/AssignUser", options)
                .then(handleResponse)
                .then(() => {
                    dispatch({ type: actionTypes.RECEIVE_ASSIGN_USER, id: taxReturn.id, taxDocument: taxReturn });
                })
                .catch((error) => {
                    dispatch({ type: actionTypes.ERROR_TAX_DOCUMENT, ids: [taxReturn.id], message: error });
                });
            addTask(fetchTask);
            dispatch({ type: actionTypes.REQUEST_ASSIGN_USER, id: taxReturn.id });
        },

    ChangeStatusTaxDocument:
        (taxReturn: ITaxReturn, callback?: () => void, resourceId: string = ""): AppThunkAction<KnownAction> =>
        (dispatch, getState) => {
            encodeTaxDocumentHtmlTags(taxReturn);

            const formData = new FormData();
            let taxReturnData = JSON.stringify(taxReturn);
            formData.append("taxDocument", taxReturnData);
            let options: any = {
                method: "PUT",
                credentials: "include",
                headers: {
                    Accept: "application/json",
                    "X-Resource-Id": resourceId,
                    RequestVerificationToken: (document.getElementById("RequestVerificationToken") as HTMLInputElement).value
                },
                body: formData
            };
            const fetchTask = fetch(API_BASE_URL + "api/TaxDocument/UpdateChangeStatus", options)
                .then(handleResponse)
                .then(() => {
                    dispatch({ type: actionTypes.RECEIVE_ASSIGN_USER, id: taxReturn.id, taxDocument: taxReturn });
                    callback && callback();
                })
                .catch((error) => {
                    dispatch({ type: actionTypes.ERROR_TAX_DOCUMENT, ids: [taxReturn.id], message: error });
                });
            addTask(fetchTask);
            dispatch({ type: actionTypes.REQUEST_ASSIGN_USER, id: taxReturn.id });
        },

    getTaxDocumentsAccessMaps:
        (
            ids: number[],
            force: boolean = false,
            callback?: (result: IDocumentAccess) => void,
            resourceId: string = ""
        ): AppThunkAction<KnownAction> =>
        (dispatch, getState) => {
            let state = getState();
            let newIds = force ? ids : documentsToUpdate(state.taxDocuments, ids);
            if (force || newIds.length > 0) {
                let queryString: string = "";
                newIds.map((id: number, index: number) => {
                    if (index === 0) {
                        queryString = "?documents=" + id;
                    } else {
                        queryString += "&documents=" + id;
                    }
                });
                const fetchTask = fetch(API_BASE_URL + "api/document-access" + queryString, {
                    credentials: "include",
                    headers: { "X-Resource-Id": resourceId }
                })
                    .then(handleResponse)
                    .then((json) => json as Promise<IDocumentAccess>)
                    .then((data) => {
                        callback && callback(data);
                    })
                    .catch((error) => {
                        dispatch({ type: actionTypes.ERROR_TAX_DOCUMENT, ids: newIds, message: error });
                    });
                addTask(fetchTask);
            }
        },

    getDocumentAccess:
        (
            accessMaps: ITaxDocumentViewModel[],
            force: boolean = false,
            callback?: (result: IDocumentAccess) => void,
            resourceId: string = "",
            errorCallback?: (error: any) => void
        ): AppThunkAction<KnownAction> =>
        (dispatch, getState) => {
            let documentAccesses = JSON.stringify(accessMaps);
            if (force) {
                let options: any = {
                    method: "POST",
                    credentials: "include",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json; charset=utf-8",
                        "X-Resource-Id": resourceId,
                        RequestVerificationToken: (document.getElementById("RequestVerificationToken") as HTMLInputElement).value
                    },
                    body: documentAccesses
                };
                const fetchTask = fetch(API_BASE_URL + "api/tax-return-action-handler/document-access", options)
                    .then(handleResponse)
                    .then((json) => json as Promise<IDocumentAccess>)
                    .then((data) => {
                        callback && callback(data);
                    })
                    .catch((error) => {
                        errorCallback && errorCallback(error);
                        dispatch({
                            type: actionTypes.ERROR_TAX_DOCUMENT,
                            ids: extractFailedDocumentIds(accessMaps),
                            message: error
                        });
                    });
                addTask(fetchTask);
            }
        },

    setTaxDocumentsAccessMaps:
        (accessMaps: ITaxDocumentAccessViewModel, callback?: () => void, resourceId: string = ""): AppThunkAction<KnownAction> =>
        (dispatch, getState) => {
            let documentAccesses = JSON.stringify(accessMaps);
            let options: any = {
                method: "PUT",
                credentials: "include",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json; charset=utf-8",
                    "X-Resource-Id": resourceId,
                    RequestVerificationToken: (document.getElementById("RequestVerificationToken") as HTMLInputElement).value
                },
                body: documentAccesses
            };
            const fetchTask = fetch(API_BASE_URL + "api/tax-return-action-handler", options)
                .then(handleResponse)
                .then((response) => {
                    callback && callback();
                })
                .catch((error) => {
                    dispatch({ type: actionTypes.ERROR_TAX_DOCUMENT, ids: extractDocumentIds(accessMaps), message: error });
                });
            addTask(fetchTask);
        },

    requestTaxDocumentClientTracking:
        (id: number, resourceId: string = ""): AppThunkAction<KnownAction> =>
        (dispatch, getState) => {
            const fetchTask = fetch(API_BASE_URL + "api/ClientTracking/" + id, {
                method: "GET",
                credentials: "include",
                headers: {
                    "X-Resource-Id": resourceId
                }
            })
                .then(handleResponse)
                .then((response) => response as Promise<IDocumentTransaction[]>)
                .then((data) => {
                    dispatch({ type: actionTypes.RECEIVE_TAX_DOCUMENT_CLIENT_TRACKING, id: id, clientTracking: data });
                })
                .catch((error) => {
                    dispatch({ type: actionTypes.ERROR_TAX_DOCUMENT, ids: [id], message: error });
                });
            addTask(fetchTask);
        },

    requestInternalUserTracking:
        (documentId: number, resourceId: string = ""): AppThunkAction<KnownAction> =>
        (dispatch, getState) => {
            const fetchTask = fetch(API_BASE_URL + "api/UserTracking/" + documentId, {
                method: "GET",
                credentials: "include",
                headers: {
                    "X-Resource-Id": resourceId
                }
            })
                .then(handleResponse)
                .then((response) => response as Promise<IUserTransaction[]>)
                .then((data) => {
                    dispatch({ type: actionTypes.RECEIVE_INTERNAL_USER_TRACKING, documentId: documentId, userEvents: data });
                })
                .catch((error) => {
                    dispatch({ type: actionTypes.ERROR_TAX_DOCUMENT, ids: [documentId], message: error });
                });
            addTask(fetchTask);
        },

    requestControllerTracking:
        (documentId: number, groupId: number, callback?: () => void, resourceId: string = ""): AppThunkAction<KnownAction> =>
        (dispatch, getState) => {
            const fetchTask = fetch(API_BASE_URL + "api/GroupLevelActions/GetClientTrackingAsync?groupId=" + groupId, {
                method: "GET",
                credentials: "include",
                headers: {
                    "X-Resource-Id": resourceId
                }
            })
                .then(handleResponse)
                .then((response) => response as Promise<IGroupedReturnsTransactionViewModel[]>)
                .then((data) => {
                    dispatch({ type: actionTypes.RECEIVE_CONTROLLER_TRACKING, documentId: documentId, controllerEvents: data });
                    if (callback) {
                        callback();
                    }
                    logger.trackTrace(`requestControllerTracking success for document id: ${documentId} and groupId: ${groupId}`);
                })
                .catch((error) => {
                    logger.trackTrace(`requestControllerTracking failed for document id: ${documentId} and groupId: ${groupId}`);
                    dispatch({ type: actionTypes.ERROR_TAX_DOCUMENT, ids: [documentId], message: error });
                });
            addTask(fetchTask);
        },

    requestSignedEvents:
        (id: number): AppThunkAction<KnownAction> =>
        (dispatch, getState) => {
            const fetchTask = fetch(API_BASE_URL + "api/ClientTracking/GetSignedEvents/" + id, {
                method: "GET",
                credentials: "include"
            })
                .then(handleResponse)
                .then((response) => response as Promise<IDocumentTransaction[]>)
                .then((data) => {
                    dispatch({ type: actionTypes.RECEIVE_TAX_DOCUMENT_CLIENT_TRACKING, id: id, clientTracking: data });
                })
                .catch((error) => {
                    dispatch({ type: actionTypes.ERROR_TAX_DOCUMENT, ids: [id], message: error });
                });
            addTask(fetchTask);
        },

    requestTaxDocumentAccessCode:
        (
            id: number,
            requestClientEvents?: boolean,
            callback?: () => void,
            resourceId: string = ""
        ): AppThunkAction<KnownAction> =>
        (dispatch, getState) => {
            const fetchTask = fetch(API_BASE_URL + "api/OTP/" + id + "/" + requestClientEvents, {
                method: "GET",
                credentials: "include",
                headers: { "X-Resource-Id": resourceId }
            })
                .then(handleResponse)
                .then((response) => response as Promise<IAccessCodeViewModel>)
                .then((data) => {
                    dispatch({ type: actionTypes.RECEIVE_TAX_DOCUMENT_ACCESS_CODE, id: id, accessCode: data });
                    if (callback) {
                        callback();
                    }
                })
                .catch((error) => {
                    dispatch({ type: actionTypes.ERROR_TAX_DOCUMENT, ids: [id], message: error });
                });
            addTask(fetchTask);
        },

    reportTaxDocumentProblem:
        (problemDetails: IReportProblemDetails, callback?: () => void, resourceId: string = ""): AppThunkAction<KnownAction> =>
        (dispatch, getState) => {
            const fetchTask = fetch(API_BASE_URL + "api/SupportIssue/SaveReportedProblem/", {
                method: "POST",
                credentials: "include",
                body: CRSHelper.encodeSpecialCharactersOnly(JSON.stringify(problemDetails)),
                headers: {
                    Accept: "application/json, text/plain, */*",
                    "X-Resource-Id": resourceId,
                    "Content-Type": "application/json; charset=utf-8",
                    RequestVerificationToken: (document.getElementById("RequestVerificationToken") as HTMLInputElement).value
                }
            })
                .then(handleResponse)
                .then((response) => {
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: Constants.ReportProblemConstants.SuccessMessage + response,
                        statusType: Notification.StatusType.Success
                    });
                    if (callback) {
                        callback();
                    }
                    logger.trackTrace(
                        `reportTaxDocumentProblem saved successfully for documentIds: ${problemDetails.documentId?.toString()} and clientId: ${
                            problemDetails.clientId
                        }`,
                        { DocumentId: problemDetails.documentId?.toString(), ClientId: problemDetails.clientId }
                    );
                })
                .catch((error) => {
                    //if (errorCallback) {
                    //    errorCallback();
                    //}

                    dispatch({ type: actionTypes.ERROR_TAX_DOCUMENT, ids: [problemDetails.documentId], message: error });
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: Constants.ReportProblemConstants.ErrorMessage,
                        statusType: Notification.StatusType.Error
                    });
                });
            addTask(fetchTask);
        },

    sendReminder:
        (documents: ITaxDocumentViewModel[], resourceId: string = ""): AppThunkAction<KnownAction> =>
        (dispatch, getState) => {
            const fetchTask = fetch(API_BASE_URL + "api/tax-return-action-handler/send-signing-reminder", {
                method: "POST",
                credentials: "include",
                body: JSON.stringify(documents),
                headers: {
                    Accept: "application/json, text/plain, */*",
                    "Content-Type": "application/json; charset=utf-8",
                    RequestVerificationToken: (document.getElementById("RequestVerificationToken") as HTMLInputElement).value,
                    "X-Resource-Id": resourceId
                }
            })
                .then(handleResponse)
                .then((response) => {
                    documents.map((item, index) => {
                        dispatch({ type: actionTypes.SEND_REMINDER_TAX_DOCUMENT, id: item.documentId });
                    });
                    logger.trackTrace(`send reminder sent successfully for documentIds: ${JSON.stringify(documents)}`);
                })
                .catch((error) => {
                    dispatch({ type: actionTypes.ERROR_TAX_DOCUMENT, ids: extractFailedDocumentIds(documents), message: error });
                });
            addTask(fetchTask);
        },

    cancelSigningReminder:
        (taxDocument: ITaxReturn, resourceId: string = ""): AppThunkAction<KnownAction> =>
        (dispatch, getState) => {
            encodeTaxDocumentHtmlTags(taxDocument);
            const formData = new FormData();
            formData.append("taxDocument", JSON.stringify(taxDocument));
            const fetchTask = fetch(API_BASE_URL + "api/Reminder/CancelSigningReminder/", {
                method: "POST",
                credentials: "include",
                body: formData,
                headers: {
                    Accept: "application/json",
                    RequestVerificationToken: (document.getElementById("RequestVerificationToken") as HTMLInputElement).value,
                    "X-Resource-Id": resourceId
                }
            })
                .then(handleResponse)
                .then((response) => {
                    logger.trackTrace(
                        `cancelSigningReminder called successfully for documentId: ${taxDocument.id?.toString()} and documentguid: ${
                            taxDocument.documentGuid
                        }`,
                        { DocumentId: taxDocument.id?.toString(), DocumentGuid: taxDocument.documentGuid }
                    );
                })
                .catch((error) => {
                    dispatch({ type: actionTypes.ERROR_TAX_DOCUMENT, ids: [taxDocument.id], message: error });
                });
            addTask(fetchTask);
        },

    requestSignedDetails:
        (id: number, callback?: () => void, resourceId: string = ""): AppThunkAction<KnownAction> =>
        (dispatch, getState) => {
            const fetchTask = fetch(API_BASE_URL + "api/TaxDocument/GetSignedDetailsAsync/" + id, {
                method: "GET",
                credentials: "include",
                headers: { "X-Resource-Id": resourceId }
            })
                .then(handleResponse)
                .then((response) => response as Promise<ISignerModel[]>)
                .then((data) => {
                    dispatch({ type: actionTypes.RECEIVE_TAX_DOCUMENT_SIGNED_DETAILS, id: id, signedDetails: data });
                    if (callback) {
                        callback();
                    }
                })
                .catch((error) => {
                    dispatch({ type: actionTypes.ERROR_TAX_DOCUMENT, ids: [id], message: error });
                });
            addTask(fetchTask);
        },

    requestDocumentStatus:
        (id: number, callback: () => void, isGroupedReturnState: boolean = false): AppThunkAction<KnownAction> =>
        (dispatch, getState) => {
            const fetchTask = fetch(API_BASE_URL + "api/TaxDocument/GetDocumentStatus/" + id, {
                method: "GET",
                credentials: "include"
            })
                .then(handleResponse)
                .then((response) => response as Promise<DocumentStatus>)
                .then((data) => {
                    dispatch({ type: actionTypes.RECEIVE_TAX_DOCUMENT_STATUS, id: id, documentStatus: data });
                    callback();
                    if (isGroupedReturnState) {
                        dispatch({ type: actionTypes.RECEIVE_GROUPED_TAX_DOCUMENT_STATUS, id: id, documentStatus: data });

                        dispatch({
                            type: actionTypes.UPDATE_GROUPED_TAX_DOCUMENT_STATUS,
                            ids: [id],
                            status: data
                        });
                        dispatch({
                            type: actionTypes.UPDATE_SEND_GROUPED_DOCUMENT_STATUS,
                            ids: [id],
                            status: data
                        });
                    }
                })
                .catch((error) => {
                    dispatch({ type: actionTypes.ERROR_TAX_DOCUMENT, ids: [id], message: error });
                });
            addTask(fetchTask);
        },

    requestGroupedDocumentStatus:
        (id: number, callback: (status: any) => void): AppThunkAction<KnownAction> =>
        (dispatch, getState) => {
            const fetchTask = fetch(API_BASE_URL + "api/TaxDocument/GetDocumentStatus/" + id, {
                method: "GET",
                credentials: "include"
            })
                .then(handleResponse)
                .then((response) => response as Promise<DocumentStatus>)
                .then((data) => {
                    dispatch({ type: actionTypes.RECEIVE_GROUPED_TAX_DOCUMENT_STATUS, id: id, documentStatus: data });

                    dispatch({
                        type: actionTypes.UPDATE_GROUPED_TAX_DOCUMENT_STATUS,
                        ids: [id],
                        status: data
                    });

                    dispatch({
                        type: actionTypes.UPDATE_SEND_GROUPED_DOCUMENT_STATUS,
                        ids: [id],
                        status: data
                    });

                    callback(data);
                })
                .catch((error) => {
                    dispatch({ type: actionTypes.ERROR_TAX_DOCUMENT, ids: [id], message: error });
                });
            addTask(fetchTask);
        },

    checkDuplicateTaxReturn:
        (
            id: number,
            engagementType: EngagementType,
            taxyear: number,
            callback: (result: boolean) => void
        ): AppThunkAction<KnownAction> =>
        (dispatch, getState) => {
            const fetchTask = fetch(
                API_BASE_URL +
                    "api/TaxDocument/CheckDuplicateTaxReturn/?id=" +
                    id +
                    "&engagementType=" +
                    engagementType?.toString() +
                    "&taxYear=" +
                    taxyear,
                {
                    method: "GET",
                    credentials: "include"
                }
            )
                .then(handleResponse)
                .then((response) => response as Promise<boolean>)
                .then((data) => {
                    callback(data);
                })
                .catch((error) => {
                    dispatch({ type: actionTypes.ERROR_TAX_DOCUMENT, ids: [engagementType], message: error });
                });
            addTask(fetchTask);
        },

    requestUserToEnableVoucherReminder:
        (taxDocument: ITaxReturn, callback: (result: boolean) => void): AppThunkAction<KnownAction> =>
        (dispatch, getState) => {
            encodeTaxDocumentHtmlTags(taxDocument);
            const formData = new FormData();
            formData.append("taxDocument", JSON.stringify(taxDocument));
            const fetchTask = fetch(API_BASE_URL + "api/TaxDocument/IsAllowUserToEnableVoucherReminderAsync/", {
                method: "PUT",
                credentials: "include",
                body: formData,
                headers: {
                    Accept: "application/json",
                    RequestVerificationToken: (document.getElementById("RequestVerificationToken") as HTMLInputElement).value,
                }
            })
                .then(handleResponse)
                .then((response) => response as Promise<boolean>)
                .then((data) => {
                    callback(data);
                })
                .catch((error) => {
                    dispatch({ type: actionTypes.ERROR_TAX_DOCUMENT, ids: [taxDocument.id], message: error });
                });
            addTask(fetchTask);
        },

    resendAccessLink:
        (id: number, model: ISignerModel, resourceId: string = ""): AppThunkAction<KnownAction> =>
        (dispatch, getState) => {
            const fetchTask = fetch(API_BASE_URL + "api/TaxDocumentMailEvent/ResendAccessLink/", {
                method: "POST",
                credentials: "include",
                body: JSON.stringify(model),
                headers: {
                    Accept: "application/json, text/plain, */*",
                    "X-Resource-Id": resourceId,
                    "Content-Type": "application/json; charset=utf-8",
                    RequestVerificationToken: (document.getElementById("RequestVerificationToken") as HTMLInputElement).value
                }
            })
                .then(handleResponse)
                .then((response) => {
                    dispatch({ type: actionTypes.REQUEST_TAX_DOCUMENT_SIGNED_DETAILS, id: id });
                    logger.trackTrace(`resend Access Link sent successfully for documentId: ${id?.toString()}`, {
                        DocumentId: id?.toString()
                    });
                })
                .catch((error) => {
                    dispatch({ type: actionTypes.ERROR_TAX_DOCUMENT, ids: [id], message: error });
                });
            addTask(fetchTask);
        },

    sendClientInfoMail:
        (documentId: number, clientGUIDs: string[], resourceId: string = ""): AppThunkAction<KnownAction> =>
        (dispatch, getState) => {
            fetch(API_BASE_URL + "api/TaxDocumentMailEvent/SendClientInfoMail/", {
                method: "POST",
                credentials: "include",
                body: JSON.stringify({ documentId: documentId, clientGUIDs: clientGUIDs }),
                headers: {
                    Accept: "application/json, text/plain, */*",
                    "X-Resource-Id": resourceId,
                    "Content-Type": "application/json; charset=utf-8",
                    RequestVerificationToken: (document.getElementById("RequestVerificationToken") as HTMLInputElement).value
                }
            })
                .then(handleResponse)
                .catch((error) => {
                    dispatch({ type: actionTypes.ERROR_TAX_DOCUMENT, ids: [documentId], message: error });
                });
        },
    sendControllertInfoMail:
        (groupInfo: IGroupInfo): AppThunkAction<KnownAction> =>
        (dispatch, getState) => {
            fetch(API_BASE_URL + "api/TaxDocumentMailEvent/SendControllerInfoMail/", {
                method: "POST",
                credentials: "include",
                body: CRSHelper.encodeSpecialCharactersOnly(JSON.stringify(groupInfo)),
                headers: {
                    Accept: "application/json, text/plain, */*",
                    "Content-Type": "application/json; charset=utf-8",
                    RequestVerificationToken: (document.getElementById("RequestVerificationToken") as HTMLInputElement).value
                }
            })
                .then(handleResponse)
                .catch((error) => {
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: Constants.SendControllerInfoMailConstants.StatusMessage.SendControllerMailError,
                        statusType: Notification.StatusType.Error
                    });
                });
        },
    SendDownloadTaxReturnMailOnDeliveredEmailAddressChange:
        (taxDocumentId: number, clientGUIDs: string[], resourceId: string = ""): AppThunkAction<KnownAction> =>
        (dispatch, getState) => {
            fetch(
                API_BASE_URL +
                    "api/TaxDocumentMailEvent/SendDownloadTaxReturnMailOnDeliveredEmailAddressChange/" +
                    taxDocumentId +
                    "/" +
                    clientGUIDs,
                {
                    method: "POST",
                    credentials: "include",
                    headers: {
                        Accept: "application/json, text/plain, */*",
                        "X-Resource-Id": resourceId,
                        "Content-Type": "application/json; charset=utf-8",
                        RequestVerificationToken: (document.getElementById("RequestVerificationToken") as HTMLInputElement).value
                    }
                }
            )
                .then(handleResponse)
                .catch((error) => {
                    dispatch({ type: actionTypes.ERROR_TAX_DOCUMENT, ids: [taxDocumentId], message: error });
                });
        },

    generateOTP:
        (
            id: number,
            clientType: ClientTypes,
            clientGUID: string,
            callBack?: () => void,
            resourceId: string = ""
        ): AppThunkAction<KnownAction> =>
        (dispatch, getState) => {
            const fetchTask = fetch(API_BASE_URL + "api/OTP/" + id + "/" + clientType + "/" + clientGUID, {
                method: "PUT",
                credentials: "include",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    RequestVerificationToken: (document.getElementById("RequestVerificationToken") as HTMLInputElement).value,
                    "X-Resource-Id": resourceId
                }
            })
                .then(handleResponse)
                .then((response) => {
                    dispatch({
                        type: actionTypes.GENERATE_OTP_TAX_DOCUMENT,
                        id: id,
                        clientGUID: clientGUID,
                        clientType: clientType,
                        otp: response.item1,
                        createdOn: response.item2
                    });
                    if (callBack) {
                        callBack();
                    }
                    logger.trackTrace(
                        `otp generated successfully for documentIds: ${id?.toString()} and clientGUID: ${clientGUID}`,
                        { DocumentId: id?.toString(), ClientGuid: clientGUID }
                    );
                })
                .catch((error) => {
                    dispatch({ type: actionTypes.ERROR_TAX_DOCUMENT, ids: [id], message: error });
                });
            addTask(fetchTask);
        },

    requestDownloadableReturns:
        (id: number, resourceId: string = ""): AppThunkAction<KnownAction> =>
        (dispatch, getState) => {
            const fetchTask = fetch(API_BASE_URL + "api/Download/GetDownloadableReturnsAsync/?documentId=" + id, {
                method: "POST",
                credentials: "include",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "X-Resource-Id": resourceId,
                    RequestVerificationToken: (document.getElementById("RequestVerificationToken") as HTMLInputElement).value
                },
                body: JSON.stringify(id)
            })
                .then(handleResponse)
                .then((response) => response as Promise<IDownloadableDocuments[]>)
                .then((data) => {
                    dispatch({
                        type: actionTypes.RECEIVE_TAX_DOCUMENT_DOWNLOADABLE_DOCUMENTS,
                        id: id,
                        downloadableDocuments: data
                    });
                })
                .catch((error) => {
                    dispatch({ type: actionTypes.ERROR_TAX_DOCUMENT, ids: [id], message: error });
                });
            addTask(fetchTask);
        },

    requestDownloadableEfileForms:
        (id: number, resourceId: string = ""): AppThunkAction<KnownAction> =>
        (dispatch, getState) => {
            logger.trackTrace(`TaxDocument requestDownloadableEfileForms started for documentId: ${id}`);
            const fetchTask = fetch(API_BASE_URL + "api/Download/GetDownlaodableEfileFormAsync", {
                method: "POST",
                credentials: "include",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "X-Resource-Id": resourceId,
                    RequestVerificationToken: (document.getElementById("RequestVerificationToken") as HTMLInputElement).value
                },
                body: JSON.stringify(id)
            })
                .then(handleResponse)
                .then((response) => response as Promise<IDownloadableDocuments[]>)
                .then((data) => {
                    logger.trackTrace(`TaxDocument requestDownloadableEfileForms ended for documentId: ${id}`);
                    dispatch({
                        type: actionTypes.RECEIVE_TAX_DOCUMENT_DOWNLOADABLE_EFILE_FORMS,
                        id: id,
                        downloadableEfileForms: data
                    });
                })
                .catch((error) => {
                    logger.trackError(`TaxDocument requestDownloadableEfileForms failed for documentId: ${id}`);
                    dispatch({ type: actionTypes.ERROR_TAX_DOCUMENT, ids: [id], message: error });
                });
            addTask(fetchTask);
        },

    downloadAllReturn:
        (
            documentGuid: string,
            documentId: number,
            taxYear: number,
            isEfileForm: boolean,
            clientId: string,
            returnType: any,
            clientName: string,
            isEsignedOrManuallySigned?: boolean,
            resourceId: string = ""
        ): AppThunkAction<KnownAction> =>
        (dispatch, getState) => {
            const d = new Date();
            const todayDateTime =
                d.getDate().toString().padStart(2, "0") +
                "-" +
                (d.getMonth() + 1).toString().padStart(2, "0") +
                "-" +
                d.getFullYear() +
                " " +
                d.getHours().toString().padStart(2, "0") +
                ":" +
                d.getMinutes().toString().padStart(2, "0");
            logger.trackTrace(
                `Taxdocument downloadAllReturn started for documentId: ${documentId?.toString()} and documentGuid: ${documentGuid}`,
                { DocumentId: documentId?.toString(), DocumentGuid: documentGuid }
            );
            fetch(API_BASE_URL + "api/Download/GetDownloadAllReturnAsync", {
                method: "POST",
                credentials: "include",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "X-Resource-Id": resourceId,
                    RequestVerificationToken: (document.getElementById("RequestVerificationToken") as HTMLInputElement).value
                },
                body: JSON.stringify({
                    documentKey: documentGuid,
                    documentId: documentId,
                    taxYear: taxYear,
                    IsEfileForm: isEfileForm,
                    clientId: clientId,
                    returnType: returnType,
                    clientName: clientName,
                    isSigned: isEsignedOrManuallySigned,
                    localDate: todayDateTime
                })
            })
                .then(handleBlobwithFileName)
                .then((data) => {
                    let displayDownloadFile = new DisplayDownloadFile();

                    displayDownloadFile.showFile(data.blob, data.filename);
                    HideLoader();
                    isEfileForm
                        ? logger.trackTrace(
                              `Taxdocument downloadAllReturn (EFile) downloaded successfully for documentId: ${documentId?.toString()} and documentGuid: ${documentGuid}`,
                              { DocumentId: documentId?.toString(), DocumentGuid: documentGuid }
                          )
                        : logger.trackTrace(
                              `Taxdocument downloadAllReturn downloaded successfully for documentId: ${documentId?.toString()} and documentGuid: ${documentGuid}`,
                              { DocumentId: documentId?.toString(), DocumentGuid: documentGuid }
                          );
                })
                .catch((error) => {
                    logger.trackError(
                        `Taxdocument downloadAllReturn failed for documentId: ${documentId?.toString()} and documentGuid: ${documentGuid}`,
                        { DocumentId: documentId?.toString(), DocumentGuid: documentGuid }
                    );
                    dispatch({ type: actionTypes.ERROR_TAX_DOCUMENT, ids: [documentId], message: error });
                    HideLoader();
                });
        },

    downloadReturn:
        (
            documentGuid: string,
            documentId: number,
            taxYear: number,
            fileName: string,
            groupId: number,
            signedDocumentId: number,
            clientId: string,
            clientName: string,
            returnType: any,
            isSinglePrinted: boolean,
            isK1Attachment: boolean,
            onPageReload?: () => void,
            isUpdateDocTransaction?: boolean,
            resourceId: string = ""
        ): AppThunkAction<KnownAction> =>
        (dispatch, getState) => {
            logger.trackTrace(
                `TaxDocument downloadReturn started for documentId: ${documentId?.toString()} and documentGuid: ${documentGuid}`,
                { DocumentId: documentId?.toString(), DocumentGuid: documentGuid }
            );
            fetch(API_BASE_URL + "api/Download/GetDownloadReturnLinkAsync", {
                method: "POST",
                credentials: "include",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "X-Resource-Id": resourceId,
                    RequestVerificationToken: (document.getElementById("RequestVerificationToken") as HTMLInputElement).value
                },
                body: JSON.stringify({
                    documentKey: documentGuid,
                    fileName: fileName,
                    group: groupId,
                    taxYear: taxYear,
                    id: documentId,
                    signedDocumentId: signedDocumentId,
                    clientId: clientId,
                    clientName: clientName,
                    returnType: returnType,
                    isSinglePrinted: isSinglePrinted,
                    isUpdateDocTransaction: isUpdateDocTransaction,
                    isK1Attachment: isK1Attachment
                })
            })
                .then(handleBlobwithFileName)
                .then((data) => {
                    let displayDownloadFile = new DisplayDownloadFile();
                    displayDownloadFile.showFile(data.blob, data.filename);
                    if (groupId == DocumentGroups.EFile && onPageReload) {
                        onPageReload();
                    }

                    groupId == DocumentGroups.EFile
                        ? logger.trackTrace(
                              `TaxDocument downloadReturn (EFile) successfully completed for documentId: ${documentId?.toString()} and documentGuid: ${documentGuid}`,
                              { DocumentId: documentId?.toString(), DocumentGuid: documentGuid }
                          )
                        : logger.trackTrace(
                              `TaxDocument downloadReturn successfully completed for documentId: ${documentId?.toString()} and documentGuid: ${documentGuid}`,
                              { DocumentId: documentId?.toString(), DocumentGuid: documentGuid }
                          );
                })
                .catch((error) => {
                    logger.trackError(
                        `TaxDocument downloadReturn failed for documentId: ${documentId?.toString()} and documentGuid: ${documentGuid}`,
                        { DocumentId: documentId?.toString(), DocumentGuid: documentGuid }
                    );
                    dispatch({ type: actionTypes.ERROR_TAX_DOCUMENT, ids: [documentId], message: error });
                });
        },

    downloadEfileForm:
        (downloadableDocumentIds: number[], callBack?: () => void, resourceId: string = ""): AppThunkAction<KnownAction> =>
        (dispatch, getState) => {
            const d = new Date();
            const todayDateTime =
                d.getDate().toString().padStart(2, "0") +
                "-" +
                (d.getMonth() + 1).toString().padStart(2, "0") +
                "-" +
                d.getFullYear() +
                " " +
                d.getHours().toString().padStart(2, "0") +
                ":" +
                d.getMinutes().toString().padStart(2, "0");
            logger.trackTrace(`TaxDocument downloadEfileForm started for documentIds: ${downloadableDocumentIds?.toString()}`);
            fetch(API_BASE_URL + "/api/Download/GetDownloadEfileFormAsync", {
                method: "POST",
                credentials: "include",
                body: JSON.stringify({
                    documentIds: downloadableDocumentIds,
                    localDate: todayDateTime
                }),
                headers: {
                    Accept: "application/json, text/plain,*/*",
                    "Content-Type": "application/json",
                    traditional: "true",
                    "X-Resource-Id": resourceId,
                    RequestVerificationToken: (document.getElementById("RequestVerificationToken") as HTMLInputElement).value
                }
            })
                .then(handleBlob)
                .then((data) => {
                    let displayDownloadFile = new DisplayDownloadFile();
                    let todayDate = getCurrentDate().replace(/\//g, "_");
                    displayDownloadFile.showFile(data, Constants.DownloadEfileZip(todayDate));
                    if (callBack) {
                        callBack();
                    }
                    logger.trackTrace(
                        `TaxDocument downloadEfileForm successfully completed for documentIds: ${downloadableDocumentIds?.toString()}`
                    );

                    HideLoader();
                })
                .catch((error) => {
                    logger.trackError(
                        `TaxDocument downloadEfileForm failed for documentIds: ${downloadableDocumentIds?.toString()}`
                    );
                    dispatch({ type: actionTypes.ERROR_TAX_DOCUMENT, ids: downloadableDocumentIds, message: error });
                    if (callBack) {
                        callBack();
                    }
                    HideLoader();
                });
        },

    downloadGroupedReturnEfileForm:
        (
            downloadableDocumentIds: number[],
            groupName: string,
            callBack?: () => void,
            resourceId: string = ""
        ): AppThunkAction<KnownAction> =>
        (dispatch, getState) => {
            const d = new Date();
            const todayDateTime =
                d.getDate().toString().padStart(2, "0") +
                "-" +
                (d.getMonth() + 1).toString().padStart(2, "0") +
                "-" +
                d.getFullYear() +
                " " +
                d.getHours().toString().padStart(2, "0") +
                ":" +
                d.getMinutes().toString().padStart(2, "0");
            fetch(API_BASE_URL + "/api/Download/GetDownloadEfileFormAsync", {
                method: "POST",
                credentials: "include",
                body: JSON.stringify({ documentIds: downloadableDocumentIds, localDate: todayDateTime }),
                headers: {
                    Accept: "application/json, text/plain,*/*",
                    "Content-Type": "application/json",
                    traditional: "true",
                    "X-Resource-Id": resourceId,
                    RequestVerificationToken: (document.getElementById("RequestVerificationToken") as HTMLInputElement).value
                }
            })
                .then(handleBlob)
                .then((data) => {
                    let displayDownloadFile = new DisplayDownloadFile();
                    let todayDate = getCurrentDate().replace(/\//g, "_");
                    displayDownloadFile.showFile(data, Constants.DownloadGroupedReturnEfileZip(todayDate, groupName));
                    if (callBack) {
                        callBack();
                    }
                    HideLoader();
                })
                .catch((error) => {
                    dispatch({ type: actionTypes.ERROR_TAX_DOCUMENT, ids: downloadableDocumentIds, message: error });
                    if (callBack) {
                        callBack();
                    }
                    HideLoader();
                });
        },

    requestTaxDocumentDownloadHistory:
        (id: number): AppThunkAction<KnownAction> =>
        (dispatch, getState) => {
            const fetchTask = fetch(
                API_BASE_URL + "api/Common/DownloadHistory/" + id + "/" + DocumentEvent.DocumentSignedEfileDownloadedByCPA,
                {
                    method: "GET",
                    credentials: "include"
                }
            )
                .then(handleResponse)
                .then((response) => response as Promise<IDocumentTransaction[]>)
                .then((data) => {
                    dispatch({ type: actionTypes.RECEIVE_TAX_DOCUMENT_DOWNLOAD_HISTORY, id: id, downloadHistory: data });
                })
                .catch((error) => {
                    dispatch({ type: actionTypes.ERROR_TAX_DOCUMENT, ids: [id], message: error });
                });
            addTask(fetchTask);
        },

    makeAvailableTaxDocument:
        (ids: number[], callback: () => void, resourceId: string): AppThunkAction<KnownAction> =>
        (dispatch, getState) => {
            const fetchTask = fetch(API_BASE_URL + "api/Reports/InUseReturns/", {
                method: "DELETE",
                credentials: "include",
                headers: {
                    Accept: "application/json, text/plain, */*",
                    "Content-Type": "application/json; charset=utf-8",
                    "X-Resource-Id": resourceId,
                    RequestVerificationToken: (document.getElementById("RequestVerificationToken") as HTMLInputElement).value
                },
                body: JSON.stringify(ids)
            })
                .then(handleResponse)
                .then(() => {
                    dispatch({ type: actionTypes.MAKE_AVAILABLE_INUSE_TAX_DOCUMENT, ids: ids });
                    dispatch({ type: actionTypes.RESET_TAX_RETURNS });
                    callback();
                })
                .catch((error) => {
                    dispatch({ type: actionTypes.ERROR_TAX_DOCUMENT, ids: ids, message: error });
                });
            addTask(fetchTask);
        },

    sendToERO:
        (taxReturn: ITaxReturn): AppThunkAction<KnownAction> =>
        (dispatch, getState) => {
            encodeTaxDocumentHtmlTags(taxReturn);

            const formData = new FormData();
            let taxReturnData = JSON.stringify(taxReturn);
            formData.append("taxDocument", taxReturnData);
            let options: any = {
                method: "PUT",
                credentials: "include",
                headers: {
                    Accept: "application/json",
                    RequestVerificationToken: (document.getElementById("RequestVerificationToken") as HTMLInputElement).value
                },
                body: formData
            };
            const fetchTask = fetch(API_BASE_URL + "api/TaxDocument/SendToERO/", options)
                .then(handleResponse)
                .then(() => {
                    let action: any = actionCreators.updateTaxDocument(taxReturn);
                    dispatch(action);
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: Constants.FinishProcessReturn.StatusMessage.SendToEROSuccess,
                        statusType: Notification.StatusType.Success
                    });
                })
                .catch((error) => {
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: Constants.FinishProcessReturn.StatusMessage.SendToEROError,
                        statusType: Notification.StatusType.Error
                    });
                });
            addTask(fetchTask);
        },

    sendForReview:
        (taxReturn: ITaxReturn): AppThunkAction<KnownAction> =>
        (dispatch, getState) => {
            encodeTaxDocumentHtmlTags(taxReturn);

            const formData = new FormData();
            let taxReturnData = JSON.stringify(taxReturn);
            formData.append("taxDocument", taxReturnData);
            let options: any = {
                method: "PUT",
                credentials: "include",
                headers: {
                    Accept: "application/json",
                    RequestVerificationToken: (document.getElementById("RequestVerificationToken") as HTMLInputElement).value
                },
                body: formData
            };
            const fetchTask = fetch(API_BASE_URL + "api/TaxDocument/SendForReview/", options)
                .then(handleResponse)
                .then(() => {
                    let action: any = actionCreators.updateTaxDocument(taxReturn);
                    dispatch(action);
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: Constants.FinishProcessReturn.StatusMessage.SendForReviewSuccess,
                        statusType: Notification.StatusType.Success
                    });
                })
                .catch((error) => {
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: Constants.FinishProcessReturn.StatusMessage.SendForReviewError,
                        statusType: Notification.StatusType.Error
                    });
                });
            addTask(fetchTask);
        },

    approveForDelivery:
        (taxReturn: ITaxReturn): AppThunkAction<KnownAction> =>
        (dispatch, getState) => {
            encodeTaxDocumentHtmlTags(taxReturn);

            const formData = new FormData();
            let taxReturnData = JSON.stringify(taxReturn);
            formData.append("taxDocument", taxReturnData);
            let options: any = {
                method: "PUT",
                credentials: "include",
                headers: {
                    Accept: "application/json",
                    RequestVerificationToken: (document.getElementById("RequestVerificationToken") as HTMLInputElement).value
                },
                body: formData
            };
            const fetchTask = fetch(API_BASE_URL + "api/TaxDocument/ApproveForDelivery/", options)
                .then(handleResponse)
                .then(() => {
                    let action: any = actionCreators.updateTaxDocument(taxReturn);
                    dispatch(action);
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: Constants.FinishProcessReturn.StatusMessage.ApproveForDeliverySuccess,
                        statusType: Notification.StatusType.Success
                    });
                })
                .catch((error) => {
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: Constants.FinishProcessReturn.StatusMessage.ApproveForDeliveryError,
                        statusType: Notification.StatusType.Error
                    });
                });
            addTask(fetchTask);
        },

    deliverToClient:
        (taxReturn: ITaxReturn): AppThunkAction<KnownAction> =>
        (dispatch, getState) => {
            encodeTaxDocumentHtmlTags(taxReturn);

            const formData = new FormData();
            let taxReturnData = JSON.stringify(taxReturn);
            formData.append("taxDocument", taxReturnData);
            let options: any = {
                method: "PUT",
                credentials: "include",
                headers: {
                    Accept: "application/json",
                    RequestVerificationToken: (document.getElementById("RequestVerificationToken") as HTMLInputElement).value
                },
                body: formData
            };
            const fetchTask = fetch(API_BASE_URL + "api/TaxDocument/DeliverAsync/", options)
                .then(handleResponse)
                .then(() => {
                    let action: any = actionCreators.updateTaxDocument(taxReturn);
                    dispatch(action);
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: Constants.FinishProcessReturn.StatusMessage.DeliverToClientSuccess,
                        statusType: Notification.StatusType.Success
                    });
                })
                .catch((error) => {
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: Constants.FinishProcessReturn.StatusMessage.DeliverToClientError,
                        statusType: Notification.StatusType.Error
                    });
                });
            addTask(fetchTask);
        },

    deliverToController:
        (taxReturn: ITaxReturn, groupInfo: IGroupInfo): AppThunkAction<KnownAction> =>
        (dispatch, getState) => {
            encodeTaxDocumentHtmlTags(taxReturn);

            const formData = new FormData();
            let taxReturnData = JSON.stringify(taxReturn);
            formData.append("taxDocument", taxReturnData);
            formData.append("groupInfo", JSON.stringify(encodeGroupInfoDataHtmlTags(groupInfo)));
            let options: any = {
                method: "PUT",
                credentials: "include",
                headers: {
                    Accept: "application/json",
                    RequestVerificationToken: (document.getElementById("RequestVerificationToken") as HTMLInputElement).value
                },
                body: formData
            };
            const fetchTask = fetch(API_BASE_URL + "api/GroupLevelProcessing/DeliverGroupIndividualAsync/", options)
                .then(handleResponse)
                .then(() => {
                    let action: any = actionCreators.updateTaxDocument(taxReturn);
                    dispatch(action);
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: Constants.FinishProcessReturn.StatusMessage.DeliverToClientSuccess,
                        statusType: Notification.StatusType.Success
                    });
                })
                .catch((error) => {
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: Constants.FinishProcessReturn.StatusMessage.DeliverToClientError,
                        statusType: Notification.StatusType.Error
                    });
                });
            addTask(fetchTask);
        },

    getK1InstructionFileName:
        (guid: string, taxYear: number, documentId: number, engagementType: EngagementType): AppThunkAction<KnownAction> =>
        (dispatch, getState) => {
            const fetchTask = fetch(
                API_BASE_URL +
                    "/api/download/GetK1InstructionMetaDataAsync?documentKey=" +
                    guid +
                    "&taxYear=" +
                    taxYear +
                    "&engagementType=" +
                    engagementType.toString(),
                { credentials: "include" }
            )
                .then(handleResponse)
                .then((data) => {
                    if (data.fileName === "FileNotFound") {
                        dispatch({ type: actionTypes.ERROR_TAX_DOCUMENT, ids: [documentId], message: "File Not Found" });
                    } else {
                        dispatch({
                            type: actionTypes.K1_INSTRUCTION_DETAIL,
                            fileName: data.fileName,
                            id: documentId,
                            isDefaultK1: data.isDefaultK1
                        });
                    }
                })
                .catch((error) => {
                    dispatch({ type: actionTypes.ERROR_TAX_DOCUMENT, ids: [documentId], message: error });
                });
            addTask(fetchTask);
        },

    deleteK1Instruction:
        (guid: string, taxYear: number): AppThunkAction<KnownAction> =>
        (dispatch, getState) => {
            const fetchTask = fetch(API_BASE_URL + "/api/ProcessReturn/DeleteK1Instruction/" + guid + "/" + taxYear, {
                method: "DELETE",
                credentials: "include",
                headers: {
                    Accept: "application/json, text/plain, */*",
                    "Content-Type": "application/json; charset=utf-8",
                    RequestVerificationToken: (document.getElementById("RequestVerificationToken") as HTMLInputElement).value
                }
            })
                .then(handleResponse)
                .then(() => {})
                .catch((error) => {
                    dispatch({ type: actionTypes.ERROR_TAX_DOCUMENT, ids: [], message: error });
                });
            addTask(fetchTask);
        },

    saveProcessReturnInfo:
        (
            taxReturn: ITaxReturn,
            subDocuments: ISubDocument[],
            isK1Replaced: boolean,
            isK1Restored: boolean,
            isMFJChanged: boolean,
            apiEndPoint?: string,
            callback?: (data?: any) => void,
            taxCaddyLookupResult?: TaxCaddyLookupResult,
            clientType?: ClientTypes,
            groupInfo?: IGroupInfo,
            resourceId: string = "",
            isShareHolderUpdated?: boolean,
            updatedShareHolders?: IShareHolder[]
        ): AppThunkAction<KnownAction> =>
        (dispatch, getState) => {
            let actualPath: string = subDocuments.filter((doc) => {
                return doc.subDocType === SubDocType.Original;
            })[0]?.path;
            subDocuments.map((doc, index) => {
                subDocuments[index].pageMap = doc.pageMap.filter((map) => {
                    return map.facadePageNum != -1;
                });
                subDocuments[index].path = subDocuments[index]?.path
                    ? subDocuments[index]?.path
                    : actualPath.split("/")[0] + "/" + "processReturn/" + taxReturn.documentGuid;
                (subDocuments[index].document as any) = undefined; //Circular dependency error by stringify if not done.
            });

            encodeTaxDocumentHtmlTags(taxReturn);

            let modelData: IProcessReturnViewModel = {
                taxDocument: taxReturn,
                parts: subDocuments,
                taxCaddyDeliveryViewModel: taxCaddyLookupResult ? taxCaddyLookupResult : initialTaxCaddyDeliveryViewModel,
                isK1Replaced: isK1Replaced,
                isK1Restored: isK1Restored,
                isMFJChanged: isMFJChanged,
                clientType: clientType,
                groupInfo: groupInfo,
                isK1ShareholderUpdated: isShareHolderUpdated,
                updatedShareHolders: updatedShareHolders
            };

            let endPoint: string = API_BASE_URL + "api/ProcessReturn";
            if (apiEndPoint) {
                endPoint = endPoint + "/" + apiEndPoint;
            }

            let options: any = {
                method: "PUT",
                credentials: "include",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "X-Resource-Id": resourceId,
                    RequestVerificationToken: (document.getElementById("RequestVerificationToken") as HTMLInputElement).value
                },
                body: CRSHelper.encodeSpecialCharactersOnly(JSON.stringify(modelData))
            };

            const fetchTask = fetch(endPoint, options)
                .then(handleResponse)
                .then((data) => {
                    let action: any = actionCreators.updateTaxDocument(taxReturn);
                    dispatch(action);
                    if (apiEndPoint !== processReturnActionEndPoints.generateTaxpayerViewAsync) {
                        dispatch({
                            type: actionTypes.NOTIFICATION,
                            statusMessage: getProcessReturnSucessStatusMessage(apiEndPoint ? apiEndPoint : ""),
                            statusType: Notification.StatusType.Success
                        });
                    }
                    if (apiEndPoint == processReturnActionEndPoints.generateTaxpayerViewAsync) {
                        let action: any = actionCreators.requestTaxDocument(taxReturn.id, false);
                        dispatch(action);
                    }
                    if (callback) {
                        data ? callback(data) : callback();
                    }
                    const routingName = getRoutingName(
                        apiEndPoint,
                        modelData.taxCaddyDeliveryViewModel.isTaxCaddyDelivery,
                        taxReturn.documentSettings.deliverySettings.deliveryMode,
                        taxReturn.engagementType
                    );
                    apiEndPoint === undefined
                        ? logger.trackTrace(
                              `${routingName} saved successfully for documentId: ${taxReturn.id?.toString()} and documentGuid: ${
                                  taxReturn.documentGuid
                              }`,
                              { DocumentId: taxReturn.id?.toString(), DocumentGuid: taxReturn.documentGuid }
                          )
                        : logger.trackTrace(
                              `${routingName} sent successfully for documentId: ${taxReturn.id?.toString()} and documentGuid: ${
                                  taxReturn.documentGuid
                              }`,
                              { DocumentId: taxReturn.id?.toString(), DocumentGuid: taxReturn.documentGuid }
                          );
                })
                .catch((error) => {
                    const routingName = getRoutingName(
                        apiEndPoint,
                        modelData.taxCaddyDeliveryViewModel.isTaxCaddyDelivery,
                        taxReturn.documentSettings.deliverySettings.deliveryMode,
                        taxReturn.engagementType
                    );

                    if (
                        error.statusText === userErrorKey.ero ||
                        error.statusText === userErrorKey.contactperson ||
                        error.statusText === userErrorKey.notify
                    ) {
                        dispatch({
                            type: actionTypes.NOTIFICATION,
                            statusMessage: getUserMissingErrorMessage(error.statusText),
                            statusType: Notification.StatusType.Error
                        });
                        logger.trackWarning(
                            `Error occured for ${routingName}
                            documentId: ${taxReturn.id?.toString()}
                            and documentGuid: ${taxReturn.documentGuid}
                            statusText: ${error.message ?? error.statusText}`,
                            { DocumentId: taxReturn.id?.toString(), DocumentGuid: taxReturn.documentGuid }
                        );
                    } else {
                        dispatch({
                            type: actionTypes.NOTIFICATION,
                            statusMessage: getProcessReturnFailureStatusMessage(apiEndPoint ? apiEndPoint : ""),
                            statusType: Notification.StatusType.Error
                        });
                        if (callback) {
                            callback();
                        }
                        logger.trackError(
                            `Error occured for ${routingName}
                            documentId: ${taxReturn.id?.toString()}
                            and documentGuid: ${taxReturn.documentGuid}
                            statusText: ${error.message ?? error.statusText}
                            error: ${JSON.stringify(error, Object.getOwnPropertyNames(error))}`,
                            { DocumentId: taxReturn.id?.toString(), DocumentGuid: taxReturn.documentGuid }
                        );
                    }
                });
            addTask(fetchTask);
        },

    getTaxCaddyLookupDetails:
        (
            taxpayerName: string,
            email: string,
            ssn: string,
            taxYear: number,
            taxClientId: string,
            docId: number
        ): AppThunkAction<KnownAction> =>
        (dispatch, getState) => {
            const fetchTask = fetch(
                API_BASE_URL +
                    "/api/ProcessReturn/GetTaxCaddyLookupAsync/" +
                    taxpayerName +
                    "/" +
                    email +
                    "/" +
                    ssn +
                    "/" +
                    taxYear +
                    "/" +
                    taxClientId,
                {
                    method: "GET",
                    credentials: "include",
                    headers: {
                        Accept: "application/json, text/plain, */*",
                        "Content-Type": "application/json; charset=utf-8",
                        RequestVerificationToken: (document.getElementById("RequestVerificationToken") as HTMLInputElement).value
                    }
                }
            )
                .then(handleResponse)
                .then((data) => {
                    dispatch({ type: actionTypes.REQUEST_TAXCADDY_LOOKUP_DETAILS, taxcaddyLookupDetails: data, id: docId });
                })
                .catch((error) => {
                    let taxcaddyLookupDetails = initialTaxCaddyLookupResultModel;
                    taxcaddyLookupDetails.isSuccess = false;
                    taxcaddyLookupDetails.result = Constants.FinishProcessReturn.StatusMessage.TaxCaddyLookupError;
                    dispatch({
                        type: actionTypes.REQUEST_TAXCADDY_LOOKUP_DETAILS,
                        taxcaddyLookupDetails: taxcaddyLookupDetails,
                        id: docId
                    });
                });
            addTask(fetchTask);
            dispatch({ type: actionTypes.CLEAR_TAXCADDY_LOOKUP_DETAILS, id: docId });
        },

    updateDocumentInitialStatus:
        (
            taxReturn: ITaxReturn,
            callback?: () => void,
            failureCallback?: () => void,
            resourceId: string = ""
        ): AppThunkAction<KnownAction> =>
        (dispatch, getState) => {
            encodeTaxDocumentHtmlTags(taxReturn);

            const fetchTask = fetch(
                API_BASE_URL +
                    "/api/TaxDocument/UpdateDocumentInitialStatus/" +
                    taxReturn.documentStatus +
                    "/" +
                    taxReturn.assignTo +
                    "/" +
                    taxReturn.lockedBy +
                    "/" +
                    taxReturn.id,
                {
                    method: "PUT",
                    credentials: "include",
                    headers: {
                        Accept: "application/json, text/plain, */*",
                        "Content-Type": "application/json; charset=utf-8",
                        "X-Resource-Id": resourceId,
                        RequestVerificationToken: (document.getElementById("RequestVerificationToken") as HTMLInputElement).value
                    }
                }
            )
                .then(handleResponse)
                .then(() => {
                    dispatch({
                        type: actionTypes.RECEIVE_TAX_DOCUMENT,
                        id: taxReturn.id,
                        taxDocument: taxReturn,
                        source: TaxReturnSource.TaxDocument
                    });
                    dispatch({ type: actionTypes.RECEIVE_GROUPED_TAX_DOCUMENT, id: taxReturn.id, taxDocument: taxReturn });
                    if (callback) {
                        callback();
                    }
                })
                .catch((error) => {
                    dispatch({ type: actionTypes.ERROR_TAX_DOCUMENT, ids: [taxReturn.id], message: error });
                    if (failureCallback) {
                        failureCallback();
                    }
                });
            addTask(fetchTask);
        },

    validateAssignTo:
        (
            documentIds: number[],
            assignedTo: number,
            callback?: (ids: number[], isValidAssign: boolean) => void,
            resourceId: string = ""
        ): AppThunkAction<KnownAction> =>
        (dispatch, getState) => {
            const fetchTask = fetch(API_BASE_URL + "/api/TaxDocument/ValidateAssign", {
                method: "POST",
                credentials: "include",
                headers: {
                    Accept: "application/json, text/plain, */*",
                    "Content-Type": "application/json; charset=utf-8",
                    "X-Resource-Id": resourceId,
                    RequestVerificationToken: (document.getElementById("RequestVerificationToken") as HTMLInputElement).value
                },
                body: CRSHelper.encodeSpecialCharactersOnly(
                    JSON.stringify({
                        documentIds: documentIds,
                        assignedTo: assignedTo
                    })
                )
            })
                .then(handleResponse)
                .then((data) => {
                    if (callback) {
                        callback(documentIds, data);
                    }
                })
                .catch((error) => {
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: "Failed To Assign Returns",
                        statusType: StatusType.Error
                    });
                });
        },

    notifyTaxDocument:
        (id: number, status: DocumentStatus): AppThunkAction<KnownAction> =>
        (dispatch, getState) => {
            const state = getState();
            if (state.taxDocuments[id] && status === DocumentStatus.READY) {
                dispatch(actionCreators.requestTaxDocument(id, true));
                console.log("id not in state.taxDocuments");
            } else if (state.taxDocuments[id]) {
                console.log("id exist in state.taxDocuments");
                const taxReturn = state.taxDocuments[id].taxReturn;
                if (taxReturn) {
                    if (
                        taxReturn.documentSettings?.deliverySettings.deliveryMode == DeliveryMode.PaperFiled &&
                        status == DocumentStatus.DELIVERED
                    ) {
                        taxReturn.documentStatus = DocumentStatus.DOWNLOADPDFDESCRIPTION;
                    } else {
                        taxReturn.documentStatus = status;
                    }
                    dispatch({
                        type: actionTypes.RECEIVE_TAX_DOCUMENT,
                        id: taxReturn.id,
                        taxDocument: taxReturn,
                        source: TaxReturnSource.TaxDocument
                    });
                }
            } else if (status === DocumentStatus.READY) {
                dispatch({ type: actionTypes.SET_MISSED_RECOGNIZED_EVENT_FOR_TAX_DOCUMENT, id: id });
            }

            let action: any = GroupedReturnsStore.actionCreators.notifyGroupedTaxDocument(id, status);
            dispatch(action);
        },

    restoreK1Instruction:
        (fileName: string, documentId: number): AppThunkAction<KnownAction> =>
        (dispatch, getState) => {
            dispatch({ type: actionTypes.K1_INSTRUCTION_DETAIL, fileName: fileName, id: documentId, isDefaultK1: true });
        },

    resetTaxDocument:
        (id: number): AppThunkAction<KnownAction> =>
        (dispatch, getState) => {
            dispatch({ type: actionTypes.RESET_TAX_DOCUMENT, id: id });
        },

    submitUploadForms:
        (
            taxReturn: ITaxReturn,
            files: string[],
            successCallback?: () => void,
            errorCallback?: () => void,
            resourceId: string = ""
        ): AppThunkAction<KnownAction> =>
        (dispatch, getState) => {
            encodeTaxDocumentHtmlTags(taxReturn);
            let modelData: any = {
                taxDocument: taxReturn,
                files: files
            };

            let options: any = {
                method: "PUT",
                credentials: "include",
                headers: {
                    Accept: "application/json",
                    "X-Resource-Id": resourceId,
                    "Content-Type": "application/json",
                    RequestVerificationToken: (document.getElementById("RequestVerificationToken") as HTMLInputElement).value
                },
                body: JSON.stringify(modelData)
            };
            const fetchTask = fetch(API_BASE_URL + "api/Upload/SubmitUploadForms" + "?userTimeZone=" + getUserTimeZone(), options)
                .then(handleResponse)
                .then(() => {
                    dispatch({
                        type: actionTypes.RECEIVE_TAX_DOCUMENT,
                        id: taxReturn.id,
                        taxDocument: taxReturn,
                        source: TaxReturnSource.TaxDocument
                    });
                    if (successCallback) {
                        successCallback();
                    }
                })
                .catch((error) => {
                    dispatch({ type: actionTypes.ERROR_TAX_DOCUMENT, ids: [taxReturn.id], message: error });
                    if (errorCallback) {
                        errorCallback();
                    }
                });
            addTask(fetchTask);
        },

    UpdateStatusToSignedAndESigned:
        (taxDocument: ITaxReturn, reason: string, resourceId: string = ""): AppThunkAction<KnownAction> =>
        (dispatch, getState) => {
            encodeTaxDocumentHtmlTags(taxDocument);
            const formData = new FormData();
            formData.append("taxDocument", JSON.stringify(taxDocument));
            formData.append("reason", reason);
            const fetchTask = fetch(
                API_BASE_URL + "api/Upload/UpdateStatusToSignedAndESigned/" + "?userTimeZone=" + getUserTimeZone(),
                {
                    method: "POST",
                    credentials: "include",
                    body: formData,
                    headers: {
                        Accept: "application/json",
                        "X-Resource-Id": resourceId,
                        RequestVerificationToken: (document.getElementById("RequestVerificationToken") as HTMLInputElement).value
                    }
                }
            )
                .then(handleResponse)
                .then((response) => {
                    logger.trackTrace(
                        `status change ToSignedAndESigned successfully for documentId: ${taxDocument.id?.toString()} and documentguid: ${
                            taxDocument.documentGuid
                        }`,
                        { DocumentId: taxDocument.id?.toString(), DocumentGuid: taxDocument.documentGuid }
                    );
                })
                .catch((error) => {
                    dispatch({ type: actionTypes.ERROR_TAX_DOCUMENT, ids: [taxDocument.id], message: error });
                });
            addTask(fetchTask);
        },

    UpdateSigningOrder:
        (id: number): AppThunkAction<KnownAction> =>
        (dispatch, getState) => {
            const fetchTask = fetch(API_BASE_URL + "api/Upload/UpdateSigningOrder/" + id, {
                method: "POST",
                credentials: "include",
                headers: {
                    Accept: "application/json",
                    RequestVerificationToken: (document.getElementById("RequestVerificationToken") as HTMLInputElement).value
                }
            })
                .then(handleResponse)
                .then((response) => {})
                .catch((error) => {
                    dispatch({ type: actionTypes.ERROR_TAX_DOCUMENT, ids: [id], message: error });
                });
            addTask(fetchTask);
        },

    CleanUpSigningOrder:
        (taxDocument: ITaxReturn, resourceId: string = ""): AppThunkAction<KnownAction> =>
        (dispatch, getState) => {
            encodeTaxDocumentHtmlTags(taxDocument);
            const formData = new FormData();
            formData.append("taxDocument", JSON.stringify(taxDocument));
            const fetchTask = fetch(API_BASE_URL + "api/Upload/CleanUpSigningOrder/", {
                method: "POST",
                credentials: "include",
                body: formData,
                headers: {
                    Accept: "application/json",
                    RequestVerificationToken: (document.getElementById("RequestVerificationToken") as HTMLInputElement).value,
                    "X-Resource-Id": resourceId
                }
            })
                .then(handleResponse)
                .then((response) => {
                    logger.trackTrace(
                        `CleanUpSigningOrder saved successfully for documentIds: ${taxDocument.id?.toString()} and documentguid: ${
                            taxDocument.documentGuid
                        }`,
                        { DocumentId: taxDocument.id?.toString(), DocumentGuid: taxDocument.documentGuid }
                    );
                })
                .catch((error) => {
                    dispatch({ type: actionTypes.ERROR_TAX_DOCUMENT, ids: [taxDocument.id], message: error });
                });
            addTask(fetchTask);
        },

    statusChangeEvent:
        (taxDocument: ITaxReturn, isDocStatus: boolean, resourceId: string = ""): AppThunkAction<KnownAction> =>
        (dispatch, getState) => {
            const postData: ITaxDocumentStatusViewModel = {
                isDocStatus: isDocStatus,
                documentId: taxDocument.id,
                documentGuid: taxDocument.documentGuid,
                assignedEmail: taxDocument.assignedUser.email,
                documentStatus: taxDocument.documentStatus,
                signatureStatus: taxDocument.signatureStatus,
                clientId: taxDocument.clientId,
                engagementType: taxDocument.engagementType,
                eroEmailAddress: taxDocument.partner.email,
                taxYear: taxDocument.taxYear
            };
            let fetchTask = fetch(API_BASE_URL + "api/TaxDocument/StatusChangeEvent", {
                method: "POST",
                credentials: "include",
                headers: {
                    Accept: "application/json",
                    "X-Resource-Id": resourceId,
                    "Content-Type": "application/json; charset=utf-8",
                    RequestVerificationToken: (document.getElementById("RequestVerificationToken") as HTMLInputElement).value
                },
                body: JSON.stringify(postData)
            })
                .then(handleResponse)
                .then((response) => {})
                .catch((error: any) => {
                    console.log(error);
                });
        },

    reloadDeliveredReturns: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: actionTypes.RESET_DELIVERED_RETURNS });
    },

    LogChangeStatusToManaullySignedTransaction:
        (taxDocument: ITaxReturn, resourceId: string = ""): AppThunkAction<KnownAction> =>
        (dispatch, getState) => {
            encodeTaxDocumentHtmlTags(taxDocument);
            const formData = new FormData();
            formData.append("taxDocument", JSON.stringify(taxDocument));
            let fetchTask = fetch(API_BASE_URL + "api/ClientTracking/ChangeStatusToManaullySignedTransaction/", {
                method: "POST",
                credentials: "include",
                body: formData,
                headers: {
                    Accept: "application/json",
                    RequestVerificationToken: (document.getElementById("RequestVerificationToken") as HTMLInputElement).value,
                    "X-Resource-Id": resourceId
                }
            })
                .then(handleResponse)
                .then((response) => {})
                .catch((error: any) => {
                    console.log(error);
                });
        },

    deleteProcessInfo:
        (documentId: number, resourceId: string = ""): AppThunkAction<KnownAction> =>
        (dispatch, getState) => {
            const fetchTask = fetch(API_BASE_URL + "/api/TaxDocument/DeleteProcessInfoAsync/" + documentId, {
                method: "DELETE",
                credentials: "include",
                headers: {
                    Accept: "application/json, text/plain, */*",
                    "Content-Type": "application/json; charset=utf-8",
                    RequestVerificationToken: (document.getElementById("RequestVerificationToken") as HTMLInputElement).value,
                    "X-Resource-Id": resourceId
                }
            })
                .then(handleResponse)
                .then(() => {})
                .catch((error) => {
                    dispatch({ type: actionTypes.ERROR_TAX_DOCUMENT, ids: [], message: error });
                });
            addTask(fetchTask);
        },
    SubmitReturnsToTaxCaddy:
        (taxDocument: ITaxReturn, resourceId: string = ""): AppThunkAction<KnownAction> =>
        (dispatch, getState) => {
            encodeTaxDocumentHtmlTags(taxDocument);

            const formData = new FormData();
            formData.append("taxDocument", CRSHelper.encodeSpecialCharactersOnly(JSON.stringify(taxDocument)));
            const fetchTask = fetch(API_BASE_URL + "api/Upload/SubmitReturnsToTaxCaddy/", {
                method: "POST",
                credentials: "include",
                body: formData,
                headers: {
                    Accept: "application/json",
                    RequestVerificationToken: (document.getElementById("RequestVerificationToken") as HTMLInputElement).value,
                    "X-Resource-Id": resourceId
                }
            })
                .then(handleResponse)
                .then((response) => {})
                .catch((error) => {
                    dispatch({ type: actionTypes.ERROR_TAX_DOCUMENT, ids: [taxDocument.id], message: error });
                });
            addTask(fetchTask);
        },

    requestRevisedTaxDocument:
        (id: number, callback?: (data: any) => void, resourceId: string = ""): AppThunkAction<KnownAction> =>
        (dispatch, getState) => {
            let state = getState();
            if (state.taxDocuments[id] || state.taxDocuments[id].taxReturn.id > 0) {
                const fetchTask = fetch(API_BASE_URL + "api/RevisedTaxDocument/GetRevisedTaxDocumentDetails/" + id, {
                    method: "GET",
                    credentials: "include",
                    headers: { "X-Resource-Id": resourceId }
                })
                    .then(handleResponse)
                    .then((response) => response as Promise<ITaxReturn>)
                    .then((data) => {
                        updateTaxFormType(data);
                        dispatch({
                            type: actionTypes.RECEIVE_TAX_DOCUMENT,
                            id: id,
                            taxDocument: data,
                            source: TaxReturnSource.TaxDocument,
                            isEditClientInfoRequest: false
                        });
                        if (callback) {
                            callback(data);
                        }
                    })
                    .catch((error) => {
                        dispatch({ type: actionTypes.ERROR_TAX_DOCUMENT, ids: [id], message: error });
                        dispatch({
                            type: actionTypes.NOTIFICATION,
                            statusMessage: Constants.FinishProcessReturn.StatusMessage.FetchRevisedTaxDocumentError,
                            statusType: Notification.StatusType.Error
                        });
                    });
                addTask(fetchTask);
            }
        },

    requestRevisionDocumentsUploadDetails:
        (id: number, callback?: (data: any) => void, resourceId: string = ""): AppThunkAction<KnownAction> =>
        (dispatch, getState) => {
            const fetchTask = fetch(API_BASE_URL + "api/RevisedTaxDocument/GetRevisionDocumentUploadDetails/" + id, {
                method: "GET",
                credentials: "include",
                headers: { "X-Resource-Id": resourceId }
            })
                .then(handleResponse)
                .then((response) => response as Promise<ITaxDocumentRevision[]>)
                .then((data) => {
                    if (callback) {
                        callback(data);
                    }
                })
                .catch((error) => {
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: Constants.FinishProcessReturn.StatusMessage.FetchRevisedTaxDocumentError,
                        statusType: Notification.StatusType.Error
                    });
                });
            addTask(fetchTask);
        },

    deliverRevisedTaxReturn:
        (
            taxReturn: ITaxReturn,
            subDocuments: ISubDocument[],
            revisionDocuments: ITaxDocumentRevision[],
            groupId?: number,
            callback?: (data?: any) => void,
            resourceId: string = ""
        ): AppThunkAction<KnownAction> =>
        (dispatch, getState) => {
            let actualPath: string = subDocuments.filter((doc) => {
                return doc.subDocType === SubDocType.Original;
            })[0].path;
            subDocuments.map((doc, index) => {
                subDocuments[index].pageMap = doc.pageMap.filter((map) => {
                    return map.facadePageNum != -1;
                });
                subDocuments[index].path = subDocuments[index].path
                    ? subDocuments[index].path
                    : actualPath.split("/")[0] + "/" + "processReturn/" + taxReturn.documentGuid;
                (subDocuments[index].document as any) = undefined;
            });

            encodeTaxDocumentHtmlTags(taxReturn);

            let modelData: IRevisedProcessReturnViewModel = {
                taxDocument: taxReturn,
                parts: subDocuments,
                revisionDocuments: revisionDocuments,
                groupId: groupId
            };
            let options: any = {
                method: "PUT",
                credentials: "include",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    RequestVerificationToken: (document.getElementById("RequestVerificationToken") as HTMLInputElement).value,
                    "X-Resource-Id": resourceId
                },
                body: CRSHelper.encodeSpecialCharactersOnly(JSON.stringify(modelData))
            };
            const fetchTask = fetch(API_BASE_URL + "api/RevisedTaxDocument/DeliverAsync/", options)
                .then(handleResponse)
                .then((data) => {
                    if (data) {
                        let action: any = actionCreators.updateTaxDocument(taxReturn);
                        dispatch(action);
                        dispatch({
                            type: actionTypes.NOTIFICATION,
                            statusMessage: Constants.SaveandCloseProcessReturn.StatusMessage.Success,
                            statusType: Notification.StatusType.Success
                        });
                    } else {
                        dispatch({
                            type: actionTypes.NOTIFICATION,
                            statusMessage: Constants.RevisionDocumentsConstant.NoChangesInRevisedEstimate,
                            statusType: Notification.StatusType.Warning
                        });
                    }
                    if (callback) {
                        callback(data);
                    }
                    logger.trackTrace(
                        `deliverRevisedTaxReturn executed successfully for documentId: ${taxReturn.id?.toString()}`
                    );
                })
                .catch((error) => {
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: Constants.FinishProcessReturn.StatusMessage.DeliverToClientError,
                        statusType: Notification.StatusType.Error
                    });

                    logger.trackError(
                        `Error occured for
                        documentId: ${taxReturn.id?.toString()}
                        and documentGuid: ${taxReturn.documentGuid}
                        statusText: ${error.message ?? error.statusText}
                        error: ${JSON.stringify(error, Object.getOwnPropertyNames(error))}`,
                        { DocumentId: taxReturn.id?.toString(), DocumentGuid: taxReturn.documentGuid }
                    );

                    if (callback) {
                        callback();
                    }
                });
            addTask(fetchTask);
        },

    saveRevisedProcessReturnInfo:
        (
            taxReturn: ITaxReturn,
            subDocuments: ISubDocument[],
            callback?: (data?: any) => void,
            resourceId: string = ""
        ): AppThunkAction<KnownAction> =>
        (dispatch, getState) => {
            let actualPath: string = subDocuments.filter((doc) => {
                return doc.subDocType === SubDocType.Original;
            })[0].path;
            subDocuments.map((doc, index) => {
                subDocuments[index].pageMap = doc.pageMap.filter((map) => {
                    return map.facadePageNum != -1;
                });
                subDocuments[index].path = subDocuments[index].path
                    ? subDocuments[index].path
                    : actualPath.split("/")[0] + "/" + "processReturn/" + taxReturn.documentGuid;
                (subDocuments[index].document as any) = undefined;
            });

            encodeTaxDocumentHtmlTags(taxReturn);

            let modelData: IRevisedProcessReturnViewModel = {
                taxDocument: taxReturn,
                parts: subDocuments
            };

            let endPoint: string = API_BASE_URL + "api/RevisedTaxDocument";
            let options: any = {
                method: "PUT",
                credentials: "include",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "X-Resource-Id": resourceId,
                    RequestVerificationToken: (document.getElementById("RequestVerificationToken") as HTMLInputElement).value
                },
                body: CRSHelper.encodeSpecialCharactersOnly(JSON.stringify(modelData))
            };

            const fetchTask = fetch(endPoint, options)
                .then(handleResponse)
                .then((data) => {
                    let action: any = actionCreators.updateTaxDocument(taxReturn);
                    dispatch(action);
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: Constants.SaveandCloseProcessReturn.StatusMessage.Success,
                        statusType: Notification.StatusType.Success
                    });
                    if (callback) {
                        data ? callback(data) : callback();
                    }
                })
                .catch((error) => {
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: Constants.SaveandCloseProcessReturn.StatusMessage.Fail,
                        statusType: Notification.StatusType.Error
                    });
                    if (callback) {
                        callback();
                    }
                });
            addTask(fetchTask);
        },
    saveTaxingAuthority:
        (
            taxingAuthority: ITaxingAuthority,
            callback1?: () => void,
            callback2?: () => void,
            resourceId: string = ""
        ): AppThunkAction<KnownAction> =>
        (dispatch, getState) => {
            const fetchTask = fetch(API_BASE_URL + "api/common/SaveTaxingAuthorityAsync", {
                method: "POST",
                credentials: "include",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "X-Resource-Id": resourceId,
                    RequestVerificationToken: (document.getElementById("RequestVerificationToken") as HTMLInputElement).value
                },
                body: JSON.stringify(taxingAuthority)
            })
                .then(handleResponse)
                .then(() => {
                    if (callback2) {
                        callback2();
                    }
                })
                .then((data) => {
                    callback1 && callback1();
                })
                .catch((error) => {
                    const statusMessage: any = error.statusText?.message ?? error.statusText;
                    if (typeof statusMessage === "string") {
                        dispatch({ type: actionTypes.NOTIFICATION, statusMessage: statusMessage, statusType: StatusType.Error });
                    }
                });
            addTask(fetchTask);
        }
};

export function getProcessReturnSucessStatusMessage(apiEndPoint: string) {
    let message: string = "";
    switch (apiEndPoint) {
        case processReturnActionEndPoints.approveForDeliveryAsync:
            message = Constants.FinishProcessReturn.StatusMessage.ApproveForDeliverySuccess;
            break;
        case processReturnActionEndPoints.sendToEROAsync:
            message = Constants.FinishProcessReturn.StatusMessage.SendToEROSuccess;
            break;
        case processReturnActionEndPoints.sendForReviewAsync:
            message = Constants.FinishProcessReturn.StatusMessage.SendForReviewSuccess;
            break;
        case processReturnActionEndPoints.deliverAsync:
            message = Constants.FinishProcessReturn.StatusMessage.DeliverToClientSuccess;
            break;
        case processReturnActionEndPoints.generateTaxpayerViewAsync:
            message = Constants.FinishProcessReturn.StatusMessage.GenerateTaxpayerViewSuccess;
            break;
        default:
            message = Constants.SaveandCloseProcessReturn.StatusMessage.Success;
    }
    return message;
}
function getUserMissingErrorMessage(type: string) {
    let message: string = "";
    switch (type) {
        case userErrorKey.ero:
            message = Constants.FinishProcessReturn.StatusMessage.EROError;
            break;
        case userErrorKey.contactperson:
            message = Constants.FinishProcessReturn.StatusMessage.ContactPersonError;
            break;
        case userErrorKey.notify:
            message = Constants.FinishProcessReturn.StatusMessage.NotifiError;
            break;
    }
    return message;
}

export function getProcessReturnFailureStatusMessage(apiEndPoint: string) {
    let message: string = "";
    switch (apiEndPoint) {
        case processReturnActionEndPoints.approveForDeliveryAsync:
            message = Constants.FinishProcessReturn.StatusMessage.ApproveForDeliveryError;
            break;
        case processReturnActionEndPoints.sendToEROAsync:
            message = Constants.FinishProcessReturn.StatusMessage.SendToEROError;
            break;
        case processReturnActionEndPoints.sendForReviewAsync:
            message = Constants.FinishProcessReturn.StatusMessage.SendForReviewError;
            break;
        case processReturnActionEndPoints.deliverAsync:
            message = Constants.FinishProcessReturn.StatusMessage.DeliverToClientError;
            break;
        case processReturnActionEndPoints.generateTaxpayerViewAsync:
            message = Constants.FinishProcessReturn.StatusMessage.GenerateTaxpayerViewError;
            break;
        default:
            message = Constants.SaveandCloseProcessReturn.StatusMessage.Fail;
    }
    return message;
}

export function getRoutingName(
    apiEndPoint: string,
    isTaxCaddyDelivery: boolean,
    paperFile: DeliveryMode,
    engagementType: EngagementType
) {
    let message: string = "";
    switch (apiEndPoint) {
        case processReturnActionEndPoints.approveForDeliveryAsync:
            message = Constants.FinishProcessReturn.MenuItems.ApproveForDelivery;
            break;
        case processReturnActionEndPoints.sendToEROAsync:
            message = Constants.FinishProcessReturn.MenuItems.SendToEROSigner;
            break;
        case processReturnActionEndPoints.sendForReviewAsync:
            message = Constants.FinishProcessReturn.MenuItems.SendForReview;
            break;
        case processReturnActionEndPoints.deliverAsync:
            if (isTaxCaddyDelivery) {
                message = Constants.FinishProcessReturn.MenuItems.DeliverToTaxCaddy;
            } else if (paperFile === DeliveryMode.PaperFiled) {
                message = Constants.FinishProcessReturn.MenuItems.PaperDelivery;
            } else if (engagementType.toString() === EngagementType[EngagementType.E1040]) {
                message = Constants.FinishProcessReturn.MenuItems.DeliverToClient;
            } else {
                message = Constants.FinishProcessReturn.MenuItems.DeliverToCompany;
            }
            break;
        default:
            message = Constants.FinishProcessReturn.MenuItems.Empty;
    }
    return message;
}

const unloadedState: ITaxReturn = {
    id: 0
} as ITaxReturn;

export const reducer: Reducer<ITaxDocumentDictionary> = (state = {}, incomingAction) => {
    const action = incomingAction as DispatchActions;
    switch (action.type) {
        case actionTypes.REQUEST_TAX_DOCUMENT:
            let loading: ITaxDocumentDictionary = { ...state };
            loading[action.id] = {
                taxReturn: state[action.id] ? state[action.id].taxReturn : unloadedState,
                isLoading: true,
                error: false,
                message: "",
                isFullyLoaded: false
            };

            return loading;

        case actionTypes.DELETE_TAX_DOCUMENT:
            let deleteState = { ...state };
            action.ids.map((id, i) => {
                delete deleteState[id];
            });

            return deleteState;

        case actionTypes.DELETE_DELIVERED_TAX_DOCUMENT:
            let deleteDeliveredState = { ...state };
            action.ids.map((id, i) => {
                delete deleteDeliveredState[id];
            });

            return deleteDeliveredState;

        case actionTypes.ARCHIVE_TAX_DOCUMENT:
            let archiveState = { ...state };
            action.ids.map((id, i) => {
                delete archiveState[id];
            });

            return archiveState;

        case actionTypes.RECALL_TAX_DOCUMENT:
            let recallState = { ...state };
            delete recallState[action.taxDocument.id];
            return recallState;

        case actionTypes.RECEIVE_TAX_DOCUMENT:
            if (action.source === TaxReturnSource.TaxDocument || !state[action.id]) {
                let received: ITaxDocumentDictionary = { ...state };
                let message = Date();
                action.taxDocument.accessCode = {
                    accessCodeDetails: [],
                    clientEvents: []
                };
                action.taxDocument.taxpayerEmail = received[action.id]?.taxReturn?.taxpayerEmail;
                action.taxDocument.spouseEmail = received[action.id]?.taxReturn?.spouseEmail;

                decodeTaxDocumentHtmlTags(action.taxDocument);

                received[action.id] = {
                    taxReturn: action.taxDocument,
                    isLoading: false,
                    error: false,
                    message: message,
                    isFullyLoaded:
                        action.isEditClientInfoRequest != undefined && action.isEditClientInfoRequest == true
                            ? false
                            : action.source === TaxReturnSource.TaxDocument &&
                              action.taxDocument.formGroups.length != 0 &&
                              action.taxDocument.documentSettings != undefined
                            ? true
                            : false,
                    fileName: received[action.id] == undefined ? "" : received[action.id].fileName,
                    isDefaultK1: received[action.id]?.isDefaultK1
                };

                return received;
            }
            break;

        case actionTypes.RECEIVE_TAX_DOCUMENTS:
            if (
                action.source === TaxReturnSource.CompanyAssignments ||
                TaxReturnSource.DeliveredReturns ||
                TaxReturnSource.QuickReport
            ) {
                let received: ITaxDocumentDictionary = { ...state };
                let message = Date();

                action.taxDocuments.forEach((model, i) => {
                    received[model.id] = {
                        taxReturn: model,
                        isLoading: false,
                        error: false,
                        message: message,
                        isFullyLoaded: action.source === TaxReturnSource.TaxDocument ? true : false,
                        fileName: received[model.id] == undefined ? "" : received[model.id].fileName
                    };
                });

                return received;
            }
            break;

        case actionTypes.ERROR_TAX_DOCUMENT:
            let errorState: ITaxDocumentDictionary = { ...state };
            action.ids.map((id, i) => {
                if (state[id]) {
                    let model = state[id].taxReturn;
                    errorState[id].isLoading = false;
                    errorState[id].error = true;
                    errorState[id].message = action.message;
                    errorState[id].fileName = state[id].fileName == undefined ? "" : state[id].fileName;
                }
            });

            return errorState;
        case actionTypes.REQUEST_TAX_DOCUMENT_CLIENT_TRACKING:
            let clientRequestStatusState: ITaxDocumentDictionary = { ...state };
            let requestClientTrackingModel = state[action.id];
            clientRequestStatusState[action.id] = {
                ...requestClientTrackingModel,
                isLoading: true
            };
            return clientRequestStatusState;
        case actionTypes.RECEIVE_TAX_DOCUMENT_CLIENT_TRACKING:
            let clientStatusState: ITaxDocumentDictionary = { ...state };
            let model = state[action.id].taxReturn;
            model.clientTracking = action.clientTracking;
            clientStatusState[action.id] = {
                taxReturn: model,
                isLoading: false,
                error: false,
                message: Date(),
                isFullyLoaded: clientStatusState[action.id].isFullyLoaded
            };
            return clientStatusState;

        case actionTypes.RECEIVE_TAX_DOCUMENT_SIGNED_DETAILS:
            let signedDetailsState: ITaxDocumentDictionary = { ...state };
            let signedDetailsTaxReturn = state[action.id];
            if (signedDetailsTaxReturn) {
                signedDetailsTaxReturn.taxReturn.signedDetails = action.signedDetails;
                signedDetailsState[action.id] = {
                    taxReturn: signedDetailsTaxReturn.taxReturn,
                    isLoading: false,
                    error: false,
                    message: "",
                    isFullyLoaded: signedDetailsState[action.id].isFullyLoaded
                };
            }
            return signedDetailsState;

        case actionTypes.RECEIVE_TAX_DOCUMENT_STATUS:
            let documentStatusState: ITaxDocumentDictionary = { ...state };
            let documentStatusOfTaxReturn = state[action.id].taxReturn;
            documentStatusOfTaxReturn.documentStatus = action.documentStatus;
            documentStatusState[action.id] = {
                taxReturn: documentStatusOfTaxReturn,
                isLoading: false,
                error: false,
                message: "",
                isFullyLoaded: documentStatusState[action.id].isFullyLoaded
            };

            return documentStatusState;

        case actionTypes.REQUEST_TAX_DOCUMENT_SIGNED_DETAILS:
            let signedDetailsRequestState: ITaxDocumentDictionary = { ...state };
            let signedDetailsRequestTaxReturn = state[action.id];
            if (signedDetailsRequestTaxReturn) {
                signedDetailsRequestTaxReturn.taxReturn.signedDetails = [];
                signedDetailsRequestState[action.id] = {
                    taxReturn: signedDetailsRequestTaxReturn.taxReturn,
                    isLoading: false,
                    error: false,
                    message: "",
                    isFullyLoaded: signedDetailsRequestState[action.id].isFullyLoaded
                };
            }
            return signedDetailsRequestState;

        case actionTypes.RECEIVE_TAX_DOCUMENT_ACCESS_CODE:
            let accessCodeState: ITaxDocumentDictionary = { ...state };
            let accessCodeRequestTaxReturn = state[action.id].taxReturn;
            accessCodeRequestTaxReturn.accessCode = action.accessCode;
            accessCodeState[action.id] = {
                taxReturn: accessCodeRequestTaxReturn,
                isLoading: false,
                error: false,
                message: Date(),
                isFullyLoaded: accessCodeState[action.id].isFullyLoaded
            };
            return accessCodeState;
        case actionTypes.GENERATE_OTP_TAX_DOCUMENT:
            let generateOTPState: ITaxDocumentDictionary = { ...state };
            let generateOTPTaxReturn = state[action.id].taxReturn;
            const index = generateOTPTaxReturn.accessCode.accessCodeDetails.findIndex((x) => x.clientGuid == action.clientGUID);
            if (index > -1) {
                generateOTPTaxReturn.accessCode.accessCodeDetails[index].otp = action.otp;
                generateOTPTaxReturn.accessCode.accessCodeDetails[index].createdOn = action.createdOn;
            }
            generateOTPState[action.id] = {
                taxReturn: generateOTPTaxReturn,
                isLoading: false,
                error: false,
                message: "",
                isFullyLoaded: generateOTPState[action.id].isFullyLoaded
            };

            return generateOTPState;
        case actionTypes.RECEIVE_TAX_DOCUMENT_DOWNLOADABLE_DOCUMENTS:
            let downloadableDocumentState: ITaxDocumentDictionary = { ...state };
            let downloadableDocumentTaxReturn = state[action.id];
            if (downloadableDocumentTaxReturn) {
                downloadableDocumentTaxReturn.taxReturn.downloadableDocuments = action.downloadableDocuments;
                downloadableDocumentState[action.id] = {
                    taxReturn: downloadableDocumentTaxReturn.taxReturn,
                    isLoading: false,
                    error: false,
                    message: Date(),
                    isFullyLoaded: downloadableDocumentState[action.id].isFullyLoaded
                };
            }
            return downloadableDocumentState;

        case actionTypes.RECEIVE_TAX_DOCUMENT_DOWNLOADABLE_EFILE_FORMS:
            let downloadableEfileFormState: ITaxDocumentDictionary = { ...state };
            let downloadableEfileFormTaxReturn = state[action.id];
            if (downloadableEfileFormTaxReturn) {
                downloadableEfileFormTaxReturn.taxReturn.downloadableEfileForms = action.downloadableEfileForms;
                downloadableEfileFormState[action.id] = {
                    taxReturn: downloadableEfileFormTaxReturn.taxReturn,
                    isLoading: false,
                    error: false,
                    message: Date(),
                    isFullyLoaded: downloadableEfileFormState[action.id].isFullyLoaded
                };
            }
            return downloadableEfileFormState;
        case actionTypes.RECEIVE_TAX_DOCUMENT_DOWNLOAD_HISTORY:
            let downloadHistoryState: ITaxDocumentDictionary = { ...state };
            let downloadHistoryTaxReturn = state[action.id];
            if (downloadHistoryTaxReturn) {
                downloadHistoryTaxReturn.taxReturn.downloadHistory = action.downloadHistory;
                downloadHistoryState[action.id] = {
                    taxReturn: downloadHistoryTaxReturn.taxReturn,
                    isLoading: false,
                    error: false,
                    message: Date(),
                    isFullyLoaded: downloadHistoryState[action.id].isFullyLoaded
                };
            }
            return downloadHistoryState;
        case actionTypes.MAKE_AVAILABLE_INUSE_TAX_DOCUMENT:
            let makeavailableState = { ...state };
            action.ids.map((id, i) => {
                delete makeavailableState[id];
            });

            return makeavailableState;
        case actionTypes.K1_INSTRUCTION_DETAIL:
            let taxDocument: ITaxDocumentDictionary = { ...state };
            let taxReturn = state[action.id]?.taxReturn;
            if (taxReturn && taxDocument[action.id]) {
                taxDocument[action.id] = {
                    taxReturn: taxReturn,
                    isLoading: false,
                    error: false,
                    message: Date(),
                    isFullyLoaded: taxDocument[action.id].isFullyLoaded,
                    fileName: action.fileName,
                    isDefaultK1: action.isDefaultK1
                };
            }
            return taxDocument;
        case actionTypes.REQUEST_TAXCADDY_LOOKUP_DETAILS:
            let taxcaddyDetails: ITaxDocumentDictionary = { ...state };
            let taxCaddyLookupDetails = state[action.id];
            if (taxCaddyLookupDetails) {
                taxCaddyLookupDetails.taxReturn.taxCaddyLookupResultModel = action.taxcaddyLookupDetails;
                taxcaddyDetails[action.id] = {
                    taxReturn: taxCaddyLookupDetails.taxReturn,
                    isLoading: false,
                    error: false,
                    message: Date(),
                    isFullyLoaded: taxcaddyDetails[action.id].isFullyLoaded
                };
            }
            return taxcaddyDetails;
        case actionTypes.CLEAR_TAXCADDY_LOOKUP_DETAILS:
            let clearTaxcaddyDetails: ITaxDocumentDictionary = { ...state };
            let clearTaxCaddyLookupDetails = state[action.id];
            //delete taxcaddyTaxreturn.taxCaddyLookupResultModel
            if (clearTaxCaddyLookupDetails) {
                clearTaxcaddyDetails[action.id] = {
                    taxReturn: clearTaxCaddyLookupDetails.taxReturn,
                    isLoading: false,
                    error: false,
                    message: Date(),
                    isFullyLoaded: clearTaxcaddyDetails[action.id].isFullyLoaded
                };
            }
            return clearTaxcaddyDetails;
        case actionTypes.REQUEST_ASSIGN_USER:
            let assignUser: ITaxDocumentDictionary = { ...state };
            assignUser[action.id] = {
                taxReturn: unloadedState,
                isLoading: true,
                error: false,
                message: "",
                isFullyLoaded: assignUser[action.id].isFullyLoaded
            };

            return assignUser;
        case actionTypes.RECEIVE_ASSIGN_USER:
            let receiveUser: ITaxDocumentDictionary = { ...state };
            receiveUser[action.id] = {
                taxReturn: action.taxDocument,
                isLoading: false,
                error: false,
                message: Date(),
                isFullyLoaded: receiveUser[action.id].isFullyLoaded
            };

            return receiveUser;
        case actionTypes.RESET_TAX_DOCUMENT:
            let resetTaxDocumentState = { ...state };
            resetTaxDocumentState[action.id].isFullyLoaded = false;
            return resetTaxDocumentState;

        case actionTypes.RECEIVE_INTERNAL_USER_TRACKING:
            let userTransactionState: ITaxDocumentDictionary = { ...state };
            let taxReturnModel = state[action.documentId].taxReturn;
            taxReturnModel.internalTracking = action.userEvents;
            userTransactionState[action.documentId] = {
                taxReturn: taxReturnModel,
                isLoading: false,
                error: false,
                message: Date(),
                isFullyLoaded: userTransactionState[action.documentId].isFullyLoaded
            };
            return userTransactionState;

        case actionTypes.RECEIVE_CONTROLLER_TRACKING:
            let userTransactionStateNew: ITaxDocumentDictionary = { ...state };
            let taxReturnModelNew = state[action.documentId].taxReturn;
            taxReturnModelNew.controllerTracking = action.controllerEvents;
            userTransactionStateNew[action.documentId] = {
                taxReturn: taxReturnModelNew,
                isLoading: false,
                error: false,
                message: Date(),
                isFullyLoaded: userTransactionStateNew[action.documentId].isFullyLoaded
            };
            return userTransactionStateNew;

        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            const exhaustiveCheck: never = action;
    }
    return state;
};

export const recognizedTaxReturnReducer: Reducer<IMissingTaxDocumentState> = (state = { ids: [] }, incomingAction) => {
    const action = incomingAction as Actions;
    switch (action.type) {
        case actionTypes.SET_MISSED_RECOGNIZED_EVENT_FOR_TAX_DOCUMENT:
            let missingTaxReturnIdState = { ...state };
            missingTaxReturnIdState.ids.push(action.id);
            return missingTaxReturnIdState;

        case actionTypes.RESET_MISSED_RECOGNIZED_EVENT_FOR_TAX_DOCUMENT:
            let resetMissingTaxReturnIdState = { ...state };
            const index = resetMissingTaxReturnIdState.ids.indexOf(action.id);
            if (index > -1) {
                resetMissingTaxReturnIdState.ids.splice(index, 1);
            }
            return resetMissingTaxReturnIdState;

        default:
            const exhaustiveCheck: never = action;
    }
    return state;
};
