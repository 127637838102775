import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Redirect } from "react-router-dom";
import { Button, Form, Modal } from 'react-bootstrap';
import * as PaymentAccountStore from '../../../store/PaymentAccount/PaymentAccountStore';
import queryString from 'query-string';

type PaymentInfoProps =
    {} & typeof PaymentAccountStore.actionCreators
    & RouteComponentProps<{ page: string}>;

export interface PaymentInfoState {
    page: string,
    showModal: boolean,
    redirect: boolean
}


export class RegistrationInfo extends React.Component<PaymentInfoProps, PaymentInfoState> {
    constructor(props: PaymentInfoProps) {
        super(props);
        this.state = {
            page: "/VouchersAndInvoices",
            showModal: false,
            redirect: false,
        };
        this.AccountRegistrationStatusUpdate = this.AccountRegistrationStatusUpdate.bind(this);
    }
    UNSAFE_componentWillMount() {
        this.AccountRegistrationStatusUpdate();
    }

    private AccountRegistrationStatusUpdate = () => {
        if (this.props && this.props.location && this.props.location.search
            && this.props.location.search.indexOf("code") > -1) {
            const params = queryString.parse(this.props.location.search);
            let code = params.code, state = params.state;
            this.props.onRegisterationSuccess(code, state, (isSuccess: boolean) => {
                if (isSuccess) {
                    this.setState({ showModal: true });
                }
                else {
                    window.location.replace(this.state.page);
                }
            });
        }
    }
    onOkClick = () => {
        this.setState({ redirect: true });
    }
    onHide = () => {
    }

    RegistrationStatusRender = () => {

        return <Modal show={this.state.showModal} onHide={this.onHide} backdrop="static">
            <Modal.Header data-test-auto="8B6C0958-7532-4C65-A10E-50C9D1019E28">
                <Modal.Title>
                    Registration Status
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="height-150p">
                <div className="row">
                    <div className="col-sm-12 tran-div-pad">
                        <div className="alert alert-success text-center-align" role="alert">
                            <h3 className="alert-heading pad-bottom-10p">Your account has been successfully created.</h3>
                            <p>
                                <br />
                                <label data-test-auto="02435D92-5F68-4684-AD15-409F2CB2C1B7"></label>
                            </p>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div>
                    <Button
                        className="btn btn-default"
                        onClick={this.onOkClick} >
                        <i className='fas fa-times'></i>
                        Close
                    </Button>
                </div>
            </Modal.Footer>
        </Modal>
    }

    render() {
        if (this.state.redirect) {
            return <Redirect to={this.state.page} />
        }

        return this.RegistrationStatusRender();
    }
}