import { PDFDocumentProxy, PDFJSStatic, PDFPageProxy } from 'pdfjs-dist';


const PDFJS: PDFJSStatic = require('pdfjs-dist');

export interface IPdfDocumentFacade {
    append: (document: PDFDocumentProxy, path: string, base64Data?: string) => void;
    appendSubDocument: (subDocument: ISubDocument) => void;
    removePages: (pages: number[]) => void;
    getPage: (pageNo: number) => Promise<PDFPageProxy>;
    getPageSourceBase64: (page: PDFPageProxy) => Promise<string>;
    getLastPage: () => number;
    getSubDocuments: () => ISubDocument[];
    getSubDocumentPDF: (pageNo: number) => PDFDocumentProxy | undefined;
    getProgress: () => number;
    updateBase64: (fileName: string, base64: string) => void;
    getSubDocument: (pageNo: number) => ISubDocument;
    getSubDocumentOriginalPageNum: (subDocument: ISubDocument, pageNo: number) => number;
};

export interface IFacadeViewModel {
    documentId: number;
    subDocuments: ISubDocument[];
    bookmarks: any;
}

export interface ISubDocument {
    document: PDFDocumentProxy;
    path: string;
    fileName: string;
    subDocType: SubDocType;
    pageMap: IPageMap[];
    downloadUrl: string;
    base64Data?: string;
}

export interface IPageMap {
    subDocPageNum: number;
    facadePageNum: number;
}

export enum SubDocType {
    None = 0,
    Original,
    Appended,
    Additional
}

export class PdfDocumentFacade implements IPdfDocumentFacade {
    private parts: ISubDocument[] = [];
    private pages: PDFPageProxy[] = [];
    constructor() {
    }

    public appendSubDocument = (subDocument: ISubDocument) => {
        if (subDocument.pageMap) {
            subDocument.pageMap.unshift({
                facadePageNum: -1,
                subDocPageNum: -1
            });
        }
        this.parts.push(subDocument);
    }
    public append = (document: PDFDocumentProxy, path: string, base64Data?: string) => {
        if (document.numPages > 0) {
            let subDoc: ISubDocument = {
                document: document,
                path: "",
                pageMap: [],
                downloadUrl: "",
                fileName: path,
                subDocType: SubDocType.Appended,
                base64Data: base64Data != undefined ? base64Data : ""
            }
            let pages = document.numPages;
            let lastPage = this.getLastPage();
            //subDoc.pageMap.push(-1);
            subDoc.pageMap.push({
                facadePageNum: -1,
                subDocPageNum: -1
            });
            for (var i = 1; i <= pages; i++) {
                subDoc.pageMap.push({
                    facadePageNum: lastPage + i,
                    subDocPageNum: i
                });
            }

            this.parts.push(subDoc);
        }
    }


    public removePages = (pages: number[]) => {
        let removed = 0;
        for (var index in this.parts) {

            let pageMap = this.parts[index].pageMap.filter((page) =>
                pages.find((p) => p === page.facadePageNum) === undefined);
            removed += this.parts[index].pageMap.length - pageMap.length;

            this.parts[index].pageMap = pageMap;
        }

        this.clearPages(pages);

        if (removed !== pages.length) {
            console.warn("Error in removePages");
        }
        return removed;
    };



    public getPage = (pageNo: number) => {
        return new Promise<PDFPageProxy>((resolve, reject) => {
            let doc: PDFDocumentProxy;
            let ret = this.getSubDocumentPageNum(pageNo);
            if (ret) {
                if (ret.document) {
                    doc = ret.document as PDFDocumentProxy;
                    try {
                        //If already stocked then return it
                        if (this.pages[pageNo]) {
                            resolve(this.pages[pageNo]);
                        }
                        doc.getPage(ret.pageNo).then((page: PDFPageProxy) => {
                            //Add to stock before returning
                            this.pages[pageNo] = page;
                            resolve(page);
                        }, reject);
                    } catch (e) {
                        reject(e);
                    }
                } else {
                    reject();
                }
            } else {
                reject("Couldn't find the page: " + pageNo);
            }
        });
    };


    public getLastPage = (): number => {
        let lastPage = 0;
        this.parts.map((subDoc) => {
            subDoc.pageMap.map((page) => {
                if (page.facadePageNum > lastPage) {
                    lastPage = page.facadePageNum;
                }
            })
        });
        return lastPage;
    }

    public updateBase64 = (fileName: string, base64: string): void => {
        for (var index in this.parts) {
            if (this.parts[index].fileName === fileName) {
                this.parts[index].base64Data = base64;
            }
        }
    }

    public getSubDocument = (pageNo: number): ISubDocument => {
        for (var i = 0; i < this.parts?.length; i++) {
            const pageMap = this.parts[i].pageMap;
            for (var j = 0; j < pageMap.length; j++) {
                const facadePageNum = pageMap[j].facadePageNum;
                if (facadePageNum == pageNo) {
                    return this.parts[i]
                }
            }
        }
    }

    public getSubDocumentOriginalPageNum = (subDocument: ISubDocument, pageNo: number): number => {
        if (subDocument === undefined) {
            return pageNo;
        }
        let found = subDocument?.pageMap?.findIndex((page) => page.facadePageNum === pageNo);
        if (found && found != -1) {
            return subDocument.pageMap[found].subDocPageNum;
        }
        return undefined;
    }

    private getSubDocumentPageNum = (pageNo: number) => {
        for (var index in this.parts) {
            let found = this.parts[index].pageMap?.findIndex((page) => page.facadePageNum === pageNo);
            if (found && found != -1) {
                return { document: this.parts[index].document, pageNo: this.parts[index].pageMap[found].subDocPageNum };
            }
        }
        return undefined;
    }



    private clearPages = (pages: number[]) => {
        pages.map((pageNo: number) => {
            delete this.pages[pageNo];
            //this.pages[pageNo] = undefined;
        });
    }

    public getPageSourceBase64(page: PDFPageProxy) {

        return new Promise<string>((resolve, reject) => {
            var canvas = document.createElement('canvas');
            let scale: number = 1.2;
            let viewport = page.getViewport(scale);

            var context = canvas.getContext('2d');
            if (context) {
                canvas.height = viewport.height;
                canvas.width = viewport.width;

                page.render({ canvasContext: context, viewport: viewport })
                    .then(() => {
                        resolve(canvas?.toDataURL());
                        canvas.remove();
                    }, reject);
            }
        });
    }

    public getSubDocuments = () => {
        return this.parts;
    }


    public getSubDocumentPDF = (pageNo: number) => {
        for (var index in this.parts) {
            let found = this.parts[index].pageMap?.findIndex((page) => page.facadePageNum === pageNo);
            if (found && found != -1) {
                return this.parts[index].document;
            }
        }
        return undefined;
    }

    public getProgress() {
        const loadedParts = this.parts.filter(x => x.document != undefined);
        return (loadedParts.length / this.parts.length) * 100
    }
}
