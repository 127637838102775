import * as React from 'react';
import { Row, Card, Col, Button } from 'react-bootstrap';
import { Tabs, Tab } from 'react-bootstrap';
import { UserNotificationEvents } from '../../navigation/notification/NotificationEvents';
import { UserNotificationGeneralSettings } from '../../navigation/notification/NotificationGeneralSettings';
import { IUserNotificationSettings, IUserNotificationGeneralSettings, IUserNotificationEvents, IUserNotificationEventsViewModel } from '../../../Core/ViewModels/User/NotificationSettingModel';
import * as _ from 'lodash';


export interface IUserNotificationSettingsProps {
    notificationSettings: IUserNotificationSettings,
    saveUserNotificationSettings: (settings: IUserNotificationSettings) => void;
    handlePopOverClose: (show: boolean) => void;


}

interface IUserNotificationEventState {
    notificationSettings: IUserNotificationSettings
    showSettings: boolean,
    notificationViewModel: IUserNotificationEventsViewModel[]

}

const initialNotificationEvents =
    [
        { eventId: 1, mailNotification: false, appNotification: false },
        { eventId: 2, mailNotification: false, appNotification: false },
        { eventId: 3, mailNotification: false, appNotification: false },
        { eventId: 4, mailNotification: false, appNotification: false },
        { eventId: 5, mailNotification: false, appNotification: false },
        { eventId: 6, mailNotification: false, appNotification: false },
        { eventId: 7, mailNotification: false, appNotification: false }
    ] as IUserNotificationEventsViewModel[]

export class UserNotificationSettings extends React.Component<IUserNotificationSettingsProps, IUserNotificationEventState>{
    state: IUserNotificationEventState = {
        notificationSettings: {
            notificationEvents:[],
            generalSettings: {
                maxPopupTime: 0,
                maxVisibileNotification: 0,
                notificationValidFor: 0,
                playSound: false,
                showPopup: false
            }
        },
        showSettings: true,
        notificationViewModel: initialNotificationEvents
    }

    UNSAFE_componentWillMount() {
        this.initializeState();
    }

    initializeState = () => {
        let notificationEvents: IUserNotificationEvents[] = [];
        let notificationViewModel: IUserNotificationEventsViewModel[]= [];
        if (this.props.notificationSettings.notificationEvents == undefined) {
            notificationEvents = this.convertFromViewModel();
            this.setState(
                {
                    notificationSettings: {
                        ...this.props.notificationSettings, notificationEvents: notificationEvents
                    }
                }
            );
        }
        else {
            notificationEvents = this.props.notificationSettings.notificationEvents
            this.setState(
                { notificationSettings: _.cloneDeep(this.props.notificationSettings) }
            );
        }
        notificationViewModel = this.prepareNotificationViewModel(notificationEvents);
        this.setState(
            {
                notificationViewModel:notificationViewModel
            });
    }


    updateNotificationSettings = () => {
        this.props.saveUserNotificationSettings(this.state.notificationSettings);
        this.props.handlePopOverClose(false);

    }

    convertFromViewModel = (): IUserNotificationEvents[] => {
        let events: IUserNotificationEvents[] = [];
        for (var i = 0; i < initialNotificationEvents.length; i++) {
            events.push({
                eventId: initialNotificationEvents[i].eventId,
                eventType: {
                    inApp: initialNotificationEvents[i].appNotification,
                    email: initialNotificationEvents[i].mailNotification
                }
            });

        }
        return events;
    }

    prepareNotificationViewModel = (notificationEvents: IUserNotificationEvents[]): IUserNotificationEventsViewModel[] => {
        let notificationViewModel: IUserNotificationEventsViewModel[] = [];
        for (var i = 0; i < notificationEvents.length; i++) {
            notificationViewModel.push({
                eventId: notificationEvents[i].eventId,
                appNotification: notificationEvents[i].eventType.inApp,
                mailNotification: notificationEvents[i].eventType.email
            });
        }
        return notificationViewModel;
    }

    onChangeNotificationEvents = (notificationViewModel: IUserNotificationEventsViewModel) => {
        const index = notificationViewModel.eventId - 1;
        let settings = Object.assign({}, this.state.notificationSettings);
        let event = settings.notificationEvents[index];
        event.eventType.email = notificationViewModel.mailNotification;
        event.eventType.inApp = notificationViewModel.appNotification;
        settings.notificationEvents[index] = event;
        this.setState({
            notificationSettings: settings
        });
    }

    onChangeGeneralSettings = (generalSettings: IUserNotificationGeneralSettings) => {
        let settings = Object.assign({}, this.state.notificationSettings);
        settings.generalSettings = generalSettings;
        this.setState({
            notificationSettings: settings
        });
    }

    onCancelClick = () => {
        this.props.handlePopOverClose(false);
    }


    render() {
        return <Card >
            <Card.Body>
                <Row className="marB15" >
                    <Tabs defaultActiveKey="events" id="notificationSettings-tab">
                        <Tab
                            eventKey="events"
                            title="Events"
                            data-test-auto="bb0810b4-d227-11ea-87d0-0242ac130003"
                        >
                            <UserNotificationEvents
                                onChangeNotificationEvents={this.onChangeNotificationEvents}
                                notificationEvent={this.state.notificationViewModel}
                            />
                        </Tab>
                        <Tab
                            eventKey="general"
                            title="General"
                            data-test-auto="bb080f38-d227-11ea-87d0-0242ac130003"
                        >
                            <UserNotificationGeneralSettings
                                generalSettings={this.props.notificationSettings.generalSettings}
                                onChangeGeneralSettings={this.onChangeGeneralSettings}
                            />
                        </Tab>
                    </Tabs>
                </Row>
            </Card.Body>
            <Card.Footer className="clearfix">
                <Row>
                    <Col sm={12}>
                        <Button
                            data-test-auto="bb080e7a-d227-11ea-87d0-0242ac130003"
                            variant='info'
                            className="float-right "
                            onClick={this.updateNotificationSettings}>
                            <i className='fas fa-save'></i>Save
                        </Button>
                        <Button
                            data-test-auto="bb080dbc-d227-11ea-87d0-0242ac130003"
                            variant='default'
                            className="float-right marR05"
                            onClick={this.onCancelClick}
                        >
                            <i className='fas fa-times'></i>Cancel
                        </Button>

                    </Col>
                </Row>
            </Card.Footer>
        </Card>

    }
}