import * as React from 'react'
import { Nav } from 'react-bootstrap';
import { FormGroup, Col } from 'react-bootstrap';




export interface IOrderList {
    id: number;
    value: string;
}

export const initialselectedOption: IOrderList = {
    id: 0,
    value: "None"
}


export interface IReorderListProps {
    listContent: IOrderList[];
    reorderList: (updatedList: IOrderList[]) => void;    
}


const Up = -1;
const Down = 1;


interface IReorderListState {
    selectedOption: IOrderList

}

export class ReorderList extends React.Component<IReorderListProps, IReorderListState> {
    state = {
        selectedOption : initialselectedOption
    }

    private onSelect = (selectedOption: IOrderList) => {
              this.setState({ selectedOption: selectedOption });
    }

    private handleMove = (direction: number) => {
        let items = this.props.listContent;

        if (this.state.selectedOption.value == "None")
        {
            // select value first.
            return;
        }

        let position = items.findIndex(m => m.id == this.state.selectedOption.id);

        let lastPosition = items.length - 1;

        if (position < 0)
        {
            //not found
        }
        else if (position === 0 && direction === Up || position === lastPosition && direction === Down)
        {
            //Invalid Move
        }
        else
        {
            let itemToBeMoved = items[position];
            let newItems = items.filter(m => m.id !== this.state.selectedOption.id);
            newItems.splice(position + direction, 0, itemToBeMoved);
            this.props.reorderList(newItems);
        }



    }


    public render() {
        return (
            <div className="row">

                    <Col sm={7}>

                    <FormGroup>
                        <ul>
                            {this.props.listContent.map((value,index) => {
                                return <li key={value.id}
                                    className={this.state.selectedOption.id === value.id ? "activeSelected list-group-item " : "list-group-item "}
                                    style={{ padding: "4px 15px" }}
                                    onClick={() => this.onSelect(value)}> {value.value} </li>
                                })
                            }
                        </ul>

                    </FormGroup>
                    </Col>
            

                <Col sm={5} style={{ marginTop: "15px", paddingLeft: "0px" }}>
                    <Nav.Link className='flex-align-center' href="javascript:" onClick={() => { this.handleMove(Up) }}
                    style={{ paddingBottom: "0px" }} >
                        <i className='fa fa-caret-up fa-2x text-secondary' style={{ paddingRight: "12px" }}></i>
                       Move Up
                    </Nav.Link>
                    <Nav.Link className='flex-align-center' href="javascript:" onClick={() => { this.handleMove(Down) }}
                        style={{ paddingTop: "0px" }}>
                        <i className='fa fa-caret-down fa-2x text-secondary' style={{ paddingRight: "12px" }}></i>
                        Move Down
                    </Nav.Link>
                   
                </Col>
            </div>
        )
    }
}