import { addTask } from 'domain-task';
import { Reducer } from 'redux';
import { AppThunkAction } from '../';
import { handleResponse } from '../Library';
import { RevisionDocumentsConstant } from '../../components/helper/Constants';
import { actionTypes } from '../../types/ActionTypes';
import { NotificationAction, StatusType } from '../../store/common/NotificationStore';
import { IRevisionDocumentUploadData } from '../../components/common/RevisionDocuments/RevisionDocuments';
import * as Loader from '../../components/helper/Loader';
import { API_BASE_URL } from '../../utils/constants';
import { logger } from '../../routes/LoggedIn';
import { IBlobFile } from '../common';
import * as CRSHelper from '../../components/helper/CRSHelper';

export interface RequestRevisionDocumentAction {
    type: actionTypes.REQUEST_REVISION_DOCUMENT_LINK;
    message: string;
}

export interface ReceiveRevisionDocumentUploadLinkAction {
    type: actionTypes.RECEIVE_REVISION_DOCUMENT_LINK;
    sasUrl: string;
    documentGuid: string;
}

const unloadedState: IBlobFile =
{
    sas: '',
    guid: '',
};

type DispatchAction = RequestRevisionDocumentAction |
    ReceiveRevisionDocumentUploadLinkAction;

type KnownAction = DispatchAction | NotificationAction;

export const actionCreators = {
    getRevisionDocumentUploadLink: (url: string, callback?: (data?: any) => void, resourceId:string=""): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(API_BASE_URL + url, {credentials: 'include', headers: { 'X-Resource-Id': resourceId }}).then((resp) => resp.json())
            .then(function (data) {
                if (callback) {
                    data ? callback(data) : callback();
                }
                dispatch({ type: actionTypes.RECEIVE_REVISION_DOCUMENT_LINK, sasUrl: data.sas, documentGuid: data.guid });
            }).catch(error => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: RevisionDocumentsConstant.fetchDocumentLinkError,
                    statusType: StatusType.Error
                })
            });
        addTask(fetchTask);
        dispatch({ type: actionTypes.REQUEST_REVISION_DOCUMENT_LINK, message: RevisionDocumentsConstant.OverlayMessage.ApplicationLoading });
    },

    deleteUploadedRevisionDocumentBlobFile: (documentGuid: string, fileName: string, taxYear: number, isDeleteOnCancel?: boolean, callback?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(API_BASE_URL + 'api/Upload/DeleteRevisionDocumentBlobFileAsync/' + documentGuid + '/' + fileName + '/' + taxYear, {
            method: 'DELETE',
            credentials: 'include',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8',
                'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
            }
        })
            .then(handleResponse)
            .then((data) => {
                if (callback) {
                    callback();
                }
                if (!isDeleteOnCancel)
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: RevisionDocumentsConstant.deleteDocument,
                        statusType: StatusType.Success
                    });
            }).catch(error => {
                const statusMessage: any = error.statusText;
                if (typeof(statusMessage) === "string") {
                    dispatch({
                        type: actionTypes.NOTIFICATION, statusMessage: statusMessage,
                        statusType: StatusType.Error
                    });
                }
            });
        addTask(fetchTask);
    },

    submitRevisionDocumentToTaxReturn: (revisionDocumentData: IRevisionDocumentUploadData, 
        callback?: () => void,resourceId:string=""): AppThunkAction<KnownAction> => (dispatch, getState) => {
        Loader.ShowLoader();
        const fetchTask = fetch(API_BASE_URL + "api/RevisedTaxDocument/SubmitRevisionDocumentAsync", {
            method: 'POST',
            credentials: 'include',
            body: CRSHelper.encodeSpecialCharactersOnly(JSON.stringify(revisionDocumentData)),
            headers: {
                'Accept': 'application/json, text/plain,*/*',
                'Content-Type': 'application/json',
                'traditional': 'true',
                'X-Resource-Id':resourceId,
                'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
            }
        }).then(handleResponse)
            .then((data) => {
                if (callback) { 
                    callback();
                }
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: RevisionDocumentsConstant.submitRevisionDocumentSuccess,
                    statusType: StatusType.Success
                });
                Loader.HideLoader();
                logger.trackTrace(`submit revisiondocumentToTaxReturn successfully for documentId: ${revisionDocumentData.documentId}`);
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: RevisionDocumentsConstant.submitRevisionDocumentError,
                    statusType: StatusType.Error
                })
                Loader.HideLoader();
            });
        addTask(fetchTask);
    }
}

export const reducer: Reducer<IBlobFile> = (state = unloadedState, incomingAction) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case actionTypes.REQUEST_REVISION_DOCUMENT_LINK:
            return {
                ...unloadedState
            } as IBlobFile;
        case actionTypes.RECEIVE_REVISION_DOCUMENT_LINK:
            var received = { ...state };
            received.sas = action.sasUrl;
            received.guid = action.documentGuid;
            return received;
    }
    return state;
}
