import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { ReturnsDeliveryOptions } from './GeneralSettingsComponents/ReturnsDeliveryOptions';
import { ReturnsDownloadOptions } from './GeneralSettingsComponents/ReturnsDownloadOptions';
import { CustomizeDeliveryReports } from './GeneralSettingsComponents/CustomizeDeliveredReports';
import {
    ICompanySettings, TransmittalDownloadOption, VoucherDownloadOption, initialCompanySettings
} from '../../Core/ViewModels/Company/CompanySettingsViewModel';
import { SettingsHeader } from '../common/settings/SettingsHeader';
import * as CompanyStore from '../../store/company/CompanyStore';
import * as CustomColumnStore from '../../store/common/CustomColumnStore';
import { ReportingChannel } from './GeneralSettingsComponents/ReportingChannel';
import { FooterSetting } from './GeneralSettingsComponents/FooterSetting';
import { OverlayLoader } from '../helper/OverlayLoader';
import * as UserSettingsStore from '../../store/userManagement/UserSettingStore';
import { PrintToPDFSetting } from './GeneralSettingsComponents/PrintToPDFSetting';
import * as UserStore from '../../store/userManagement/UserStore';
import { WatermarkSettings } from './GeneralSettingsComponents/WatermarkComponents/WatermarkSetting';
import * as WatermarkStore from '../../store/settings/WatermarkStore';
import * as ApplicationHelptextStore from '../../store/common/ApplicationHelptextStore';
import { PaperFiledSetting } from './GeneralSettingsComponents/PaperFiledSetting';
import { ProductStatus, ProductType } from '../../Core/ViewModels/Company/CompanyViewModel';
import { CustomRouterPrompt } from './CustomRouterPrompt';
import { TaxReturnSettingsResources } from '../../components/helper/ResourceIdConstants';

type CompanySettingsProps =
    {
        userStore: UserStore.IUserData,
        companyData: CompanyStore.ICompanyData,
        customColumnData: CustomColumnStore.ICustomColumnState
        watermarkData: WatermarkStore.IWatermarkSettingsState
        helptexts: ApplicationHelptextStore.IHelptextState
    }
    & typeof UserStore.actionCreators
    & typeof CompanyStore.actionCreators
    & typeof CustomColumnStore.actionCreators
    & typeof UserSettingsStore.actionCreators
    & typeof WatermarkStore.actionCreators
    & typeof ApplicationHelptextStore.actionCreators
    & RouteComponentProps<{}>;

interface IGeneralSettingsState {
    companySettings: ICompanySettings;
    showState: boolean;
    columnValues: IColumnValues;
    customColumn: ICustomColumn;
    showAddSubState: boolean;
    showEditSubState: boolean;
    newColumnValue: string;
    selectedListItem: string;
    surePrepDomainId: string;
    domainId: string;
    cuastomColumnTitleCurrentValue: string;
    disableCustomColumnDeleteButton: boolean;
    disableCustomColumnEditButton: boolean;
    selectedIndex: number;
    //isSigFlowEnabled: boolean;     Check this
    IsSignatureEnabled: boolean;
    IsOrganizerEnabled: boolean;
    IsExtensionEnabled: boolean;
    IsExchangeEnabled: boolean;
    showCustomPrompt: boolean;
}
export interface IColumnValues {
    id: number;
    value: string;
    customColumn: number;
    inUseCount: number;
}

export interface ICustomColumn {
    id: number;
    header: string;
    customColumnValues: IColumnValues[];
}

export class GeneralSettings extends React.Component<CompanySettingsProps, IGeneralSettingsState> {
    constructor(props: CompanySettingsProps) {
        super(props);
        this.state = {
            companySettings: initialCompanySettings,
            showState: false,
            columnValues: {
                id: 0,
                customColumn: 0,
                value: "",
                inUseCount: 0,
            },
            customColumn: {
                header: "",
                id: 0,
                customColumnValues: []
            },
            showAddSubState: false,
            showEditSubState: false,
            newColumnValue: "",
            selectedListItem: "",
            surePrepDomainId: "",
            domainId: "",
            cuastomColumnTitleCurrentValue: "",
            disableCustomColumnDeleteButton: false,
            disableCustomColumnEditButton: false,
            selectedIndex: 0,
            IsSignatureEnabled: false,
            IsOrganizerEnabled: false,
            IsExtensionEnabled: false,
            IsExchangeEnabled: false,
            showCustomPrompt: false
            //isSigFlowEnabled: false
        }
        this.handleApplyButton = this.handleApplyButton.bind(this);

        ///////////////////////////// Returns Delivery Options ////////////////////////
        this.handleAllowAnyUsertoSendDelivery = this.handleAllowAnyUsertoSendDelivery.bind(this);

        ///////////////////////////// Returns Download Options ////////////////////////
        this.handleCreateSeperatePDF = this.handleCreateSeperatePDF.bind(this);
        this.handleGroupInSinglePDF = this.handleGroupInSinglePDF.bind(this);
        this.handleIncludeInTaxReturnPDF = this.handleIncludeInTaxReturnPDF.bind(this)
        this.handleSeperateByQuarterlyDueDates = this.handleSeperateByQuarterlyDueDates.bind(this);


        ///////////////////////////// Custom column popup/////////////////////////
        this.OpenCustomColumnPopup = this.OpenCustomColumnPopup.bind(this);
        this.onHide = this.onHide.bind(this);
    }

    //componentDidMount() {
    //    getIsSignatureFlowEnabled().then(data => {
    //        this.setState({ isSigFlowEnabled: data });
    //    })
    //}
    //Set Enable Disable Values
    componentDidMount() {
        const element = document.getElementById('content-wrapper');
        element && element.scrollTo(0, 0);
    }
    UNSAFE_componentWillReceiveProps(nextProps: CompanySettingsProps) {
        let CompanyProducts = nextProps.companyData.companyProfile.companyProducts
        CompanyProducts.forEach(product => {

            switch (product.productId) {
                case ProductType.SignaturesMailMerge:
                    this.setState({
                        IsSignatureEnabled: product.isSubscribed ? product.productStatus === ProductStatus.Enabled ? true : false : false
                    })
                    break;
                case ProductType.Organizers:
                    this.setState({
                        IsOrganizerEnabled: product.isSubscribed ? product.productStatus === ProductStatus.Enabled ? true : false : false
                    })
                    break;
                case ProductType.Extensions:
                    this.setState({
                        IsExtensionEnabled: product.isSubscribed ? product.productStatus === ProductStatus.Enabled ? true : false : false
                    })
                    break;
                case ProductType.Exchange:
                    this.setState({
                        IsExchangeEnabled: product.isSubscribed ? product.productStatus === ProductStatus.Enabled ? true : false : false
                    })
                    break;
                default:
            }

        })

    }

    UNSAFE_componentWillMount() {
        this.props.requestCompanySettings(true);
        this.props.requestUserSettings(true);
        this.props.requestCustomColumn(true);
        this.props.requestAllUsers(true);
        this.props.requestWatermark(true);
        this.props.requestSamplePdf();
        this.props.requestHelptextsForApplication(true);
    }

    private enableCustomRouterPrompt = () => {
        this.setState({ showCustomPrompt: true });
    }

    private disableCustomRouterPrompt = () => {
        this.setState({ showCustomPrompt: false });
    }

    handleApplyButton(event?: any, resourceId?: string) {
        let temp = this.props.companyData.companySettings as ICompanySettings;
        this.props.updateCompanySettings(temp, undefined, resourceId);
        this.disableCustomRouterPrompt();

    }


    updateGeneralSettings = (companySettings: any, resourceId?: string) => {
        let temp = companySettings as ICompanySettings;
        this.props.updateCompanySettings(temp, undefined, resourceId);
    }

    ///////////////////////////// Returns Delivery Options start ////////////////////////
    handleAllowAnyUsertoSendDelivery(e: React.BaseSyntheticEvent) {
        let temp = this.props.companyData.companySettings as ICompanySettings;
        temp.userPrivilegeSettingsModel.allowAnyUsertoSendDelivary = e.currentTarget.checked;
        this.setState({
            companySettings: temp
        })
        this.enableCustomRouterPrompt();
    }

    handleAllowUserToDistributeK1 = (event: any) => {
        let temp = this.props.companyData.companySettings as ICompanySettings;
        temp.userPrivilegeSettingsModel.allowUserToDistributeK1 = event.currentTarget.checked;
        this.setState({
            companySettings: temp
        })
        this.enableCustomRouterPrompt();
    }
    ///////////////////////////// Returns Delivery Options end ////////////////////////

    ///////////////////////////// Returns Download Options start ////////////////////////
    handleIncludeInTaxReturnPDF() {
        let temp = this.props.companyData.companySettings as ICompanySettings;
        temp.deliverySettingsModel.transmittalsDownloadOptions = TransmittalDownloadOption.IncludeTransmittalInTaxReturnPDF;
        this.setState({
            companySettings: temp
        })
        this.enableCustomRouterPrompt();
    }

    handleCreateSeperatePDF() {
        let temp = this.props.companyData.companySettings as ICompanySettings;
        temp.deliverySettingsModel.transmittalsDownloadOptions = TransmittalDownloadOption.CreateSeparateTransmittalPDF;
        this.setState({
            companySettings: temp
        })
        this.enableCustomRouterPrompt();
    }

    handleSeperateByQuarterlyDueDates() {
        let temp = this.props.companyData.companySettings as ICompanySettings;
        temp.deliverySettingsModel.vouchersDownloadOptions = VoucherDownloadOption.SeparateByQuarterlyDueDates;
        this.setState({
            companySettings: temp
        })
        this.enableCustomRouterPrompt();
    }

    handleGroupInSinglePDF() {
        let temp = this.props.companyData.companySettings as ICompanySettings;
        temp.deliverySettingsModel.vouchersDownloadOptions = VoucherDownloadOption.GroupInSinglePDF;
        this.setState({
            companySettings: temp
        })
        this.enableCustomRouterPrompt();
    }
    ///////////////////////////// Returns Download Options end ////////////////////////

    ////////////////custom column start //////////////// TO-DO: Need to use custom column store
    OpenCustomColumnPopup() {
        this.setState({
            showState: true
        })
    }

    onHide() {
        this.setState({
            showState: false,
        })
    }

    private OnDeleteCustomColumnHeader = (customColumn: ICustomColumn) => {
        this.props.deleteCustomColumnHeader(customColumn);
        this.props.forceRefreshTaxDcoumentStore(true);
    }

    public render() {
        return <div id="divGeneralSettings" className='company-settings font14'>
            <CustomRouterPrompt
                enabled={this.state.showCustomPrompt}
                history={this.props.history}
            />
            <SettingsHeader
                handleApplyButton={this.handleApplyButton}
                header="General"
                showPopOver={false}
                parentResourceIdentifier={TaxReturnSettingsResources.GeneralPrefix + TaxReturnSettingsResources.HeaderPrefix} />
            <OverlayLoader show={this.props.companyData.isLoading} text={this.props.companyData.message} />
            {this.props.companyData.companySettings != undefined ?
                <div>
                    <ReturnsDeliveryOptions
                        companySettings={this.props.companyData.companySettings as ICompanySettings}
                        handleAllowAnyUsertoSendDelivery={this.handleAllowAnyUsertoSendDelivery}
                        handleAllowUserToDistributeK1={this.handleAllowUserToDistributeK1}
                        helptexts={this.props.helptexts.helpTexts}
                    />
                    <hr />
                    <ReturnsDownloadOptions
                        companySettings={this.props.companyData.companySettings as ICompanySettings}
                        handleCreateSeperatePDF={this.handleCreateSeperatePDF}
                        handleGroupInSinglePDF={this.handleGroupInSinglePDF}
                        handleIncludeInTaxReturnPDF={this.handleIncludeInTaxReturnPDF}
                        handleSeperateByQuarterlyDueDates={this.handleSeperateByQuarterlyDueDates} />
                    <hr />

                    <PaperFiledSetting
                        companySettings={this.props.companyData.companySettings as ICompanySettings}
                        updateCompanySettings={this.updateGeneralSettings}
                        parentResourceIdentifier={TaxReturnSettingsResources.GeneralPrefix + TaxReturnSettingsResources.FilingOptions}
                    />
                    <hr />

                    {this.props.customColumnData.customColumn != undefined ?
                        <CustomizeDeliveryReports
                            overlayLoading={this.props.customColumnData.isLoading}
                            overlayMessage={this.props.customColumnData.message}
                            customColumn={this.props.customColumnData.customColumn}
                            HandleCustomizeDeliveredreportsEditButton={this.OpenCustomColumnPopup}
                            updateCustomColumn={this.props.updateCustomColumn}
                            updateCustomColumnValue={this.props.updateCustomColumnValue}
                            addCustomColumnValue={this.props.addCustomColumnValue}
                            deleteCustomColumnValue={this.props.deleteCustomColumnValue}
                            deleteCustomColumnHeader={this.OnDeleteCustomColumnHeader}
                            helptexts={this.props.helptexts.helpTexts}
                            parentResourceIdentifier={TaxReturnSettingsResources.GeneralPrefix + TaxReturnSettingsResources.CustomizeDeliveredReport}
                        /> : ""
                    }
                    <hr />

                    <ReportingChannel
                        getSettings={this.props.requestCompanySettings}
                        updateCompanySettings={this.updateGeneralSettings}
                        companySettings={this.props.companyData.companySettings as ICompanySettings}
                        parentResourceIdentifier={TaxReturnSettingsResources.GeneralPrefix + TaxReturnSettingsResources.Integration}
                    />

                    <hr />

                    <PrintToPDFSetting
                        companySettings={this.props.companyData.companySettings as ICompanySettings}
                        updateCompanySettings={this.updateGeneralSettings}
                        parentResourceIdentifier={TaxReturnSettingsResources.GeneralPrefix + TaxReturnSettingsResources.DownloadPDF}
                    />
                    <hr />

                    <FooterSetting
                        updateCompanySettings={this.updateGeneralSettings}
                        companySettings={this.props.companyData.companySettings as ICompanySettings}
                        helptexts={this.props.helptexts.helpTexts}
                        parentResourceIdentifier={TaxReturnSettingsResources.GeneralPrefix + TaxReturnSettingsResources.Footer}
                    />

                    <hr />
                    <WatermarkSettings
                        watermarkData={this.props.watermarkData.waterMark}
                        saveWatermark={this.props.saveWatermark}
                        deleteWatermark={this.props.deleteWatermark}
                        requestWatermark={this.props.requestWatermark}
                        sampleDocument={this.props.watermarkData.document}
                        updateWatermark={this.props.updateWatermark}
                        showLoader={this.props.watermarkData.isLoading}
                        helptexts={this.props.helptexts.helpTexts}
                        companySettings={this.props.companyData.companySettings as ICompanySettings}
                        updateCompanySettings={this.updateGeneralSettings}
                        parentResourceIdentifier={TaxReturnSettingsResources.GeneralPrefix + TaxReturnSettingsResources.Watermarks}
                    />
                </div>
                : ""
            }

        </div>;
    }
}
