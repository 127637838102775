import * as React from 'react';
import { Card, Row, Col, Button, FormLabel } from 'react-bootstrap';
import { ISSRUserModel } from '../../../../Core/ViewModels/User/UserViewModel';

interface ISendForReview {
    allUsersList: ISSRUserModel[],
    cancelFinish: () => void;
    sendForReview: () => void;
    selectedUser: number
    onChangeUser(event: any): void;
}

export class SendForReview extends React.Component<ISendForReview, {}> {
    public render() {
        return <Card>
            <Card.Body>
                <Row className="marB15">
                    <Col sm={12}>
                    </Col>
                </Row>
                <Row className="marB15">
                    <Col className="text-left" sm={12} as={FormLabel}>
                        <span>Please select the reviewer</span>
                    </Col>
                </Row>
                <Row className="marB15">
                    <Col sm={12}>
                        <select className="form-control"
                            id="ddl"
                            value={this.props.selectedUser}
                            onChange={this.props.onChangeUser}
                            data-test-auto="0F977C9E-557E-4D36-B2D3-20946C82426A">
                            <option key={0} value={0}>---Select---</option>
                            {this.props.allUsersList.sort((a, b) => a.firstName.toLowerCase().localeCompare(b.firstName))
                                .map((user, index) => {
                                return <option key={index} value={user?.id}>{user?.firstName + " " + user?.lastName}</option>
                            })}
                        </select>
                    </Col>
                </Row>
                <div>
                    <Col sm={12}>
                        <Button type="button" variant="info" className="float-right" data-test-auto="35FF5139-3F92-4489-A24C-B625DB9783FF"
                        onClick={this.props.sendForReview}
                        ><i className="fa fa-paper-plane"></i>Send</Button>

                        <Button type="button" variant="default" className="float-right marR05 btn-white btn-default" data-test-auto="FE8C2959-1D18-4B55-9345-8FBA8564D7F8"
                        onClick={this.props.cancelFinish}
                        ><i className="fas fa-times"></i>Cancel</Button>
                       
                    </Col>
                </div>
            </Card.Body>
        </Card>
    }
}

export default SendForReview;
