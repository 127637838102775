import * as React from 'react';
import { IStates, IMailingReturnAddress, initialMailingReturnAddressList } from '../../../Core/ViewModels/CompanySettings/SignaturesViewModel';
import * as MailingReturnAddressStore from '../../../store/common/MailingReturnAddressStore';
import { ICountryState } from '../../../Core/ViewModels/Company/CompanyViewModel';


interface ISelectedMailingAddressView {
    mailingReturnAddressList: MailingReturnAddressStore.IMailingReturnAddressDictionary;
    states: ICountryState[];
    mailingReturnAddress: IMailingReturnAddress
    selectedAddressId: number;
}

export class SelectedMailingAddressView extends React.Component<ISelectedMailingAddressView, {}>{
    public render() {
        const list = this.props.mailingReturnAddressList ? this.props.mailingReturnAddressList : [];
        let authorityName: string = "";
        let addressId: number = this.props.selectedAddressId
        let mailingReturnAddress: IMailingReturnAddress = list[addressId];
        if (mailingReturnAddress) {
            let state = this.props.states.filter(x => x.id == mailingReturnAddress.stateId);
            authorityName = state && state[0] ? state[0].name : "";
        }
        else {
            mailingReturnAddress = initialMailingReturnAddressList;
        }

        return (
            <div className="card selected-message-view">{mailingReturnAddress != undefined ?
                <div className="card-body"> <address>
                    {mailingReturnAddress.streetAddress2} <br />
                    {(mailingReturnAddress.streetAddress3 != null && mailingReturnAddress.streetAddress3 != "")
                        ? mailingReturnAddress.streetAddress3 : ""}
                    {(mailingReturnAddress.streetAddress3 != null && mailingReturnAddress.streetAddress3 != "")
                        ? <br /> : ""}
                    {mailingReturnAddress.city}<br/>
                    {authorityName}<br/>
                    {mailingReturnAddress.zip}<br />
                    {(mailingReturnAddress.fax != null && mailingReturnAddress.fax != "") ? mailingReturnAddress.fax : ""}
                </address>
                </div>

                : ""
            }
            </div>
        )
    }

}