import * as React from 'react';
import { Modal, Alert, ListGroup, Form, Button } from 'react-bootstrap';
import { LoadingOverlay, Loader } from 'react-overlay-loader';
import { FaFileDownload } from 'react-icons/fa';
import * as DeliveredReturnsState from '../../store/reports/DeliveredReturnsState';
import { IDownloadableDocuments, IAttachment, ITaxReturn } from '../common/TaxReturn';
import { GetSafeFilename } from '../helper/Validations';

interface IDownloadAttachmentsProps {
    show: boolean;
    onCancel(): void;
    attachments: IAttachment[];
    downloadIndividual(documentId: number, attachmentId: number, fileName: string): void;
    downloadAll(): void;
    
}



export class DownloadAttachments extends React.Component<IDownloadAttachmentsProps, {}> {


    public render() {
        const { attachments } = this.props;
        return (<Modal className='download-attachments-modal' show={this.props.show} onHide={this.props.onCancel}>
            <Modal.Header closeButton onClick={this.props.onCancel} data-test-auto="8A258655-8C8D-43FC-8685-0E4793776C2D">
                <Modal.Title className='custom-modal-header'>
                    <FaFileDownload className='modalIcon' />
                    Download Attachments
                </Modal.Title>
            </Modal.Header>
            <Form>
                <Modal.Body>
                    <LoadingOverlay style={{ minHeight: 100 }}>
                        {this.props.attachments.length>0 ?
                            <div><Alert variant="info" ><i className='fa fa-info-circle' style={{ marginRight: '5px' }}>
                            </i>Click Attachment to download</Alert>
                                <ListGroup data-test-auto="EDE084EA-60DA-4F72-BF01-17A8F47F744C">
                                    {
                                        this.props.attachments.map((value, i) => {
                                            return <li className="delivered-return-download-link"
                                                onClick={() => { this.props.downloadIndividual(value.documentId, value.id, value.fileName); }} >
                                                {GetSafeFilename(value.fileName)}
                                            </li>;
                                        })

                                    }
                                    
                                </ListGroup></div> : (attachments !== undefined) &&
                            <Alert variant='warning' >
                                <i className='fas fa-exclamation-triangle' style={{ marginRight: '5px', color: '#666' }}>
                                </i>No returns found!
                                </Alert>}
                        <Loader loading={(attachments === undefined)} />
                    </LoadingOverlay>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.props.onCancel}
                        variant="default"
                        className="btn btn-white"
                        data-test-auto="BC3C6FF2-75C2-45AD-8552-8915E4C8EDF3">
                        <i className='fas fa-times'></i>
                        Cancel
                    </Button>
                    <Button onClick={() => { this.props.downloadAll(); }}
                        variant="info"
                        data-test-auto="BC7DCC23-8799-4DD0-BDC6-30597C7804F4">
                        <i className='fas fa-download'></i>
                        Download All
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>);
    }
}