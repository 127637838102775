import { IDropdown } from "../../../Core/Common/Dropdown";
import { API_BASE_URL } from "src/utils/constants";

export function getUsersByLocation(locationId:number,callback: (users: IDropdown[]) => void) {
    fetch(API_BASE_URL + 'api/UserManagement/location-users?locationId='+locationId, {
        method: 'GET',
        credentials: 'include',
        headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json; charset=utf-8'            
        }
    }).then((resp) => resp.json()).
        then(data => {
            callback(data);
        })
        .catch(() => {
            callback([]);
        });
}