import * as React from 'react';
import { IInboxMessage, MessageStatus, MessageType } from '../../Core/ViewModels/User/InboxMessage';
import { EmptyInbox } from './EmptyInbox';
import Moment from 'moment';
import { container } from '../../Startup/inversify.config';
import { TYPES } from "../../Startup/types";
import { IHtmlUtilities } from '../../Core/Utilities/HtmlUtilities';
import { LoadingOverlay, Loader } from 'react-overlay-loader';

export interface ISupportInboxProps {
    inboxMessages: IInboxMessage[];
    title: string;
    markAsRead: (messageId: number) => void;
    clearMessage: (event: any, messageId: number) => void;
    onClearAllSelection: (messageType: MessageType) => void;
    isLoading: boolean;
}

const htmlUtilities = container.get<IHtmlUtilities>(TYPES.IHtmlUtilities);

interface ISupportInboxDictionary {
    [messagetype: number]: number
}

interface ISupportInboxState {
    knownIssues: ISupportInboxDictionary,
    releaseUpdates: ISupportInboxDictionary,
    underMaintenance: ISupportInboxDictionary,
    general: ISupportInboxDictionary,
    messageType: MessageType,
    currentInboxMessage: ISupportInboxDictionary,
    isShowMore: boolean,
}

const initialState = {
    knownIssues: { 1: 5 },
    releaseUpdates: { 2: 5 },
    underMaintenance: { 3: 5 },
    general: { 5: 5 },
}

export class SupportInbox extends React.Component<ISupportInboxProps, ISupportInboxState>{
    state: ISupportInboxState = {
        knownIssues: initialState.knownIssues,
        releaseUpdates: initialState.releaseUpdates,
        underMaintenance: initialState.underMaintenance,
        general: initialState.general,
        messageType: MessageType.None,
        currentInboxMessage: {},
        isShowMore: false
    }

    UNSAFE_componentWillReceiveProps(nextProps: ISupportInboxProps) {
        if (nextProps.inboxMessages.length > 0) {
            switch (nextProps.inboxMessages[0].messageType) {
                case MessageType.KnownIssues:
                    this.setState({ currentInboxMessage: this.state.knownIssues, messageType:nextProps.inboxMessages[0].messageType });
                    break;
                case MessageType.ReleaseUpdates:
                    this.setState({ currentInboxMessage: this.state.releaseUpdates, messageType:nextProps.inboxMessages[0].messageType });
                    break;
                case MessageType.UnderMaintenance:
                    this.setState({ currentInboxMessage: this.state.underMaintenance, messageType:nextProps.inboxMessages[0].messageType });
                    break;
                case MessageType.Others:
                    this.setState({ currentInboxMessage: this.state.general, messageType:nextProps.inboxMessages[0].messageType });
                    break;
            }
        }
    }

    private onShowMore = () => {
        let showedMessages;
        switch (this.state.messageType) {
            case MessageType.KnownIssues:
                showedMessages = { ...this.state.knownIssues };
                showedMessages = { [1]: showedMessages[1] + (this.props.inboxMessages.length - showedMessages[1]) };
                this.setState({
                    currentInboxMessage: showedMessages,
                    knownIssues: showedMessages,
                    isShowMore: true
                });
                break;
            case MessageType.ReleaseUpdates:
                showedMessages = { ...this.state.releaseUpdates };
                showedMessages = { [2]: showedMessages[2] + (this.props.inboxMessages.length - showedMessages[2]) };
                this.setState({
                    currentInboxMessage: showedMessages,
                    releaseUpdates: showedMessages,
                    isShowMore: true
                });
                break;
            case MessageType.UnderMaintenance:
                showedMessages = { ...this.state.underMaintenance };
                showedMessages = { [3]: showedMessages[3] + (this.props.inboxMessages.length - showedMessages[3]) };
                this.setState({
                    currentInboxMessage: showedMessages,
                    underMaintenance: showedMessages,
                    isShowMore: true
                });
                break;
            case MessageType.Others:
                showedMessages = { ...this.state.general };
                showedMessages = { [5]: showedMessages[5] + (this.props.inboxMessages.length - showedMessages[5]) };
                this.setState({
                    currentInboxMessage: showedMessages,
                    general: showedMessages,
                    isShowMore: true
                });
                break;
        }
    }

    private onShowLess = () => {
        switch (this.state.messageType) {
            case MessageType.KnownIssues:
                this.setState({
                    currentInboxMessage: initialState.knownIssues,
                    knownIssues: initialState.knownIssues,
                    isShowMore: false
                });
                break;
            case MessageType.ReleaseUpdates:
                this.setState({
                    currentInboxMessage: initialState.releaseUpdates,
                    releaseUpdates: initialState.releaseUpdates,
                    isShowMore: false
                });
                break;
            case MessageType.UnderMaintenance:
                this.setState({
                    currentInboxMessage: initialState.underMaintenance,
                    underMaintenance: initialState.underMaintenance,
                    isShowMore: false
                });
                break;
            case MessageType.Others:
                this.setState({
                    currentInboxMessage: initialState.general,
                    general: initialState.general,
                    isShowMore: false
                });
                break;
        }
    }

    render() {
        const messageType=(this.state.messageType== MessageType.None ? 
            this.props.inboxMessages.length > 0 ? this.props.inboxMessages[0].messageType: 
            this.state.messageType: this.state.messageType);
        return this.props.inboxMessages.length > 0 ?
            <div style={{ height: '96%', marginTop: '-5px' }}>
                <div className="support-inbox">
                    <LoadingOverlay style={{ minHeight: 100, height: '100%'}}>
                        <div className="support-inbox-heading">{this.props.title}</div>
                        <div className="support-inbox-content">
                            {
                                this.props.inboxMessages.filter(x => x.messageStatus != MessageStatus.Deleted)
                                    .sort((a, b) => { return new Date(a.startDate).valueOf() > new Date(b.startDate).valueOf() ? -1 : 1 })
                                    .slice(0, this.state.currentInboxMessage[this.state.messageType]).map((message, i) => {

                                        return <div className={"support-message" + (message.messageStatus == MessageStatus.Unread ? " unreadMessage" : "")}
                                            key={message.id}
                                            data-testid={`message-${message.id}`}
                                            onClick={(event) => this.props.markAsRead(message.id)} >
                                            {i != 0 && <hr className="support-message-break" />}
                                            <div className="support-message-header">
                                                <label className="support-message-title">{message.title}</label>
                                                <label className="col-md-6 support-message-date">{Moment(message.startDate).format("lll").replace(",", "")}</label>
                                                <span className="support-message-clear" data-testid={`clearMessage-${message.id}`}
                                                    onClick={(e) => this.props.clearMessage(e, message.id)}>&times;</span>
                                            </div>
                                            <div className="support-message-body"
                                                dangerouslySetInnerHTML={{ __html: htmlUtilities.sanitize(message.message) }}>
                                            </div>
                                        </div>
                                    })
                            }

                        </div>

                        <Loader loading={this.props.isLoading} />
                    </LoadingOverlay>
                </div>
                <div className="support-message-footer width100">
                    {this.props.inboxMessages.length > 0 &&
                        <label className="text-dodgerblue"
                            onClick={() => this.props.onClearAllSelection(messageType)}>Clear All</label>
                    }
                    {
                        this.props.inboxMessages.length - this.state.currentInboxMessage[this.state.messageType] > 0 ?
                            <label className="float-right text-dodgerblue" style={{ marginRight: '35px' }} onClick={this.onShowMore}>Show More</label>
                            :
                            this.state.isShowMore && this.props.inboxMessages.length > 5 &&
                            <label className="float-right text-dodgerblue" style={{ marginRight: '35px'}} onClick={this.onShowLess}>Show Less</label>
                    }
                </div>
            </div>
            :
            <LoadingOverlay style={{ minHeight: 100 }}>
                <EmptyInbox />
                <Loader loading={this.props.isLoading} />
            </LoadingOverlay >
    }
}
