var htmlencode = require('htmlencode');
import * as React from 'react';
import { ReplaceHelperVariable } from '../../../helper/HelperFunctions';
import { container } from '../../../../Startup/inversify.config';
import { TYPES } from "../../../../Startup/types";
import { IHtmlUtilities } from '../../../../Core/Utilities/HtmlUtilities';
import { IClientMessage } from '../../../../Core/ViewModels/CompanySettings/ClientInstructionViewModel';

const htmlUtilities = container.get<IHtmlUtilities>(TYPES.IHtmlUtilities);

interface IClientMessageViewProps {
    selectedMessage: IClientMessage;
}

export class ClientMessageView extends React.Component<IClientMessageViewProps, {}>{
    public render() {
        return (
            <div className="width100">
                <div>
                    <h4>{this.props.selectedMessage.name}</h4>
                </div>
                <div
                    className="form-control"
                    style={{ overflowY: "auto", height: 174, backgroundColor: "#f1f1f1" }}
                    dangerouslySetInnerHTML={{
                        __html: htmlUtilities.sanitize(
                            ReplaceHelperVariable(htmlencode.htmlDecode(this.props.selectedMessage.text))
                        )
                    }}
                />
            </div>
        );
    }
}