import * as React from "react";
import * as Bs from "react-bootstrap";
import { BaseClientTrackingReport } from "../reports/DeliveredReturns/BaseClientTrackingReport";
import { ITaxReturn } from "./TaxReturn";

interface IClientTrackingProps {
    show: boolean;
    onCancel: (id: number) => void;
    taxReturn: ITaxReturn;
}

export class ClientTrackingModal extends React.Component<IClientTrackingProps, {}> {
    public render() {
        return (
            <Bs.Modal
                className="client-tracking-modal"
                show={this.props.show}
                onHide={() => {
                    this.props.onCancel(this.props.taxReturn?.id);
                }}
            >
                <Bs.Modal.Header closeButton>
                    <Bs.Modal.Title className="custom-modal-header">
                        <span className="text-secondary fas fa-user-clock" style={{ color: "grey", marginRight: "5px" }}></span>
                        Client Tracking History
                    </Bs.Modal.Title>
                </Bs.Modal.Header>
                <Bs.Modal.Body>
                    <div>
                        <BaseClientTrackingReport taxReturn={this.props.taxReturn} />
                    </div>
                </Bs.Modal.Body>
            </Bs.Modal>
        );
    }
}
