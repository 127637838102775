import * as React from 'react';
import * as Bs from 'react-bootstrap';
import { Modal } from 'react-bootstrap';
import { MyDefaults } from './MyDefaults';
import { MyNotifications } from './MyNotifications';
import { MySignatureStamp } from './MySignatureStamp';
import { IModalProps } from './ProfileObjects';
import { ISSRUserModel } from '../../../Core/ViewModels/User/UserViewModel';
import * as UserSettingStore from '../../../store/userManagement/UserSettingStore';
import { OverlayLoader } from '../../helper/OverlayLoader';
import { VenusNotifier } from '../../helper/VenusNotifier';
import * as Helper from '../../helper/HelperFunctions';
import SignatureDelegation from './SignatureDelegationSettings';
import * as MailingReturnAddressStore from '../../../store/common/MailingReturnAddressStore';
import { ICompanySettings } from '../../../Core/ViewModels/Company/CompanySettingsViewModel';
import { ICustomColumn } from '../../settings/GeneralSettings';
import { IHelptextModel } from '../../../Core/ViewModels/Common/IHelptextModel';
import { API_BASE_URL } from '../../../utils/constants';
import { IsAlsoSignatureUser } from './../../../oidcClient/userRoles';
import { IAuthState } from '../../../store/auth/reducer';
import { userSettingConstants } from '../../helper/Constants';
import { UserProfileResources } from '../../helper/ResourceIdConstants';

export interface IUserDefaultSettingsStates {
    defaultSettings: IDefaultSettings;
    notificationSettings: INotificationSettings;
    signatureSettings: ISignatureSettings;
    enableSignerDeligation: boolean;
    useSignatureStamp: boolean;
    signatureDelegation: ISignatureDelegation;
    mfaSettings: IMFASettings;
    userId: number;
}

export interface DefaultSenderInfo {
    senderType: DefaultSenderInfoType;
    senderId: number;
}
export interface DefaultDownloadInfo {
    downloadType: DefaultDownloadInfoType;
    rememberDefaultDownload: boolean;
}
export enum DefaultSenderInfoType {
    None = "None",
    Company = "Company",
    User = "User"
}
export enum DefaultDownloadInfoType {
    DownloadNow = "DownloadNow",
    DownloadToMyDownloads = "DownloadToMyDownloads",
    PromptMeEveryTime = "PromptMeEveryTime"
}
export interface IDefaultSettings {
    manualAddressId: number;
    eroUser: number;
    contactPerson: number;
    sender: DefaultSenderInfo;
    notifyUser: number;
    reportFilter: string;
    enableLastLogin: boolean;
    customColumnValue: number;
    download: DefaultDownloadInfo;
    showDefaultWarning: boolean;
}

export interface INotificationSettings {
    lastPersonNotified: number;
    isReturnReady: boolean;
    isReturnAssigned: boolean;
    isApprovedForDelivery: boolean;
    paymentNotification: boolean;
}

export interface ISignatureSettings {
    isAutoPlaceSignature: boolean;
    isEnableSignatureDelegation: boolean;
    signaturePath: string;
}

export interface DefaultMailingAddress {
    id: number;
    title: string;
}

export interface ISignatureDelegation {
    userId: number;
    delegate: number[];
}

export interface IMFASettings {
    isMfaEnabled: boolean;
}

interface States {
    partners: ISSRUserModel[];
    delegates: ISSRUserModel[];
    isSignatureDelegationEnabled: boolean;
    signatureCropShow: boolean;
    isDataLoaded: boolean;
    saving: boolean;
    message: string;
    originalDelegates: ISSRUserModel[];
    isSignatureLoaded: boolean;
    isSignatureUploaded: boolean;
    allUsers: ISSRUserModel[];
    leftSelect: ISSRUserModel[];
    lastLeft: number;
    rightSelect: ISSRUserModel[];
    lastRight: number;
    showSignatureDelegation: boolean;
    updatedDelegates: number[]

    // signature flow
    updateSignatureFlowDelegates: boolean;
}

let signatureDelegationUsersInitialState: ISignatureDelegation = {
    delegate: [],
    userId: 0
}

interface MySettingsProps extends IModalProps {
    auth: IAuthState;
    ssrUsers: ISSRUserModel[];
    my: UserSettingStore.UserSettings;
    mailingReturnAddressList: MailingReturnAddressStore.IMailingReturnAddressDictionary;
    customColumnData: ICustomColumn;
    updateSettings: (settings: IUserDefaultSettingsStates) => void;
    requestUserSettings: (reload: boolean) => void;
    saveSettings: (settings: IUserDefaultSettingsStates, showUserSettingsNotification: boolean, callback?: () => void) => void;
    saveSignatureDelegates: (delegates: number[]) => void;
    companySettings: ICompanySettings,

    // signature flow
    requestHelptexts?: (reload: boolean) => void;
    helptexts?: IHelptextModel[];
    showMySettings: boolean;
    allssrUsers: ISSRUserModel[];
}
const msg = {
    saving: 'Saving the updated settings...',
    loading: 'Loading my settings...',
    uploading: 'Uploading the file...',
};
const NO_INDEX = -1;
const nameText = "my";
const { SignatureStampSection } = UserProfileResources;

export class MySettings extends React.Component<MySettingsProps, States> {
    isAlsoSignatureUser = IsAlsoSignatureUser(this.props.auth.user);
    constructor(props: MySettingsProps) {
        super(props);

        this.onChangeMailingReturnAddress = this.onChangeMailingReturnAddress.bind(this);
        this.onChangeEROStamp = this.onChangeEROStamp.bind(this);
        this.onChangeContactPerson = this.onChangeContactPerson.bind(this);
        this.onChangeSenderName = this.onChangeSenderName.bind(this);
        this.onChangeNotifySigningEvents = this.onChangeNotifySigningEvents.bind(this);
        this.onChangeReadyForProcessing = this.onChangeReadyForProcessing.bind(this);
        this.onChangeRAssigneToMe = this.onChangeRAssigneToMe.bind(this);
        this.onChangeApprovedForDelivery = this.onChangeApprovedForDelivery.bind(this);
        this.onChangeAutoApplySignature = this.onChangeAutoApplySignature.bind(this);
        this.onChangeAllowDelegatestoApply = this.onChangeAllowDelegatestoApply.bind(this);
        this.onChangeDefaultSender = this.onChangeDefaultSender.bind(this);
        this.onChangeDefaultDownload = this.onChangeDefaultDownload.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.handleSignatureDelegationReset = this.handleSignatureDelegationReset.bind(this);
        this.signatureCrophandleShow = this.signatureCrophandleShow.bind(this);
        this.getSignerDelegationUsers = this.getSignerDelegationUsers.bind(this);
        this.uploadSignature = this.uploadSignature.bind(this);
        this.downloadSignature = this.downloadSignature.bind(this);
        this.onPopupClose = this.onPopupClose.bind(this);
        this.onSelectFromLeft = this.onSelectFromLeft.bind(this);
        this.onSelectFromRight = this.onSelectFromRight.bind(this);
        this.moveLeft = this.moveLeft.bind(this);
        this.moveRight = this.moveRight.bind(this);
        this.onClickAvailableUsers = this.onClickAvailableUsers.bind(this);
        this.onClickUsersWithAccessRights = this.onClickUsersWithAccessRights.bind(this);
        this.onSignatureDelegatesPopupHide = this.onSignatureDelegatesPopupHide.bind(this);
        this.onClickSelectUsers = this.onClickSelectUsers.bind(this);
        this.onSaveSignatureDelegates = this.onSaveSignatureDelegates.bind(this);
        this.onChangeCustomColumnValue = this.onChangeCustomColumnValue.bind(this);
        this.onChangePaymentNotification = this.onChangePaymentNotification.bind(this);

        this.state = {
            partners: [],
            delegates: [],
            isSignatureDelegationEnabled: false,
            signatureCropShow: false,
            isDataLoaded: false,
            saving: false,
            message: msg.loading,
            originalDelegates: [],
            isSignatureLoaded: false,
            isSignatureUploaded: false,
            allUsers: [],
            leftSelect: [],
            lastLeft: 0,
            rightSelect: [],
            lastRight: 0,
            showSignatureDelegation: false,
            updatedDelegates: [],
            updateSignatureFlowDelegates: false
        };

    }

    UNSAFE_componentWillReceiveProps(nextProps: MySettingsProps) {
        if (nextProps.showMySettings) {
            //Hide when done
            if (!nextProps.my.isLoading && this.state.saving) {
                if (this.props.my.error) {
                    VenusNotifier.Error("Failed to save the changes in settings!", "My Settings");
                }
                this.props.onToggle(userSettingConstants.mySettings);
                this.setState({ saving: false, message: msg.loading });
            }
            //Initialize states
            let saving = true;
            let message = msg.loading
            if (nextProps.ssrUsers) {
                if (nextProps.my.settings) {
                    saving = false;
                    message = msg.saving;
                    let signatureDelegates = nextProps.my.settings.signatureDelegation ? nextProps.my.settings.signatureDelegation.delegate : [];
                    this.setState({
                        isSignatureDelegationEnabled: (nextProps.my.settings.signatureSettings !== undefined && nextProps.my.settings.signatureSettings !== null) ? nextProps.my.settings.signatureSettings.isEnableSignatureDelegation : false,
                        allUsers: this.filterLeft(Helper.selectSSRUsers(
                            nextProps.ssrUsers,
                            signatureDelegates)),
                    });
                    this.getSignerDelegationUsers();
                    if (!this.state.isSignatureLoaded) {
                        this.downloadSignature();
                    }
                }
            }

            this.setState({ saving: saving });
        }
    }

    public render() {
        const temp = (this.props.showMySettings && this.props.my.settings && this.props.ssrUsers &&
            this.props.my.settings.signatureSettings && this.state.isSignatureLoaded) ? true : false;

        return <Modal show={this.props.showMySettings} onHide={this.onPopupClose} className="my-settings" size="lg" >
            <Modal.Header closeButton>
                <Modal.Title className='custom-modal-header'>
                    <span className='fa fa-cog' style={{ color: 'grey', marginRight: '5px' }}></span>
                    My Settings
                    </Modal.Title>
            </Modal.Header>
            {
                this.state.saving ? (<OverlayLoader
                    show={this.props.showMySettings}
                    text={"Saving, please wait..."} />) : (temp ?
                        (<Modal.Body style={{ maxHeight: '74vh', overflowY: 'auto' }}>

                            <MyDefaults
                                auth={this.props.auth}
                                DefaultSettings={this.props.my.settings.defaultSettings}
                                MailingAddressList={this.props.mailingReturnAddressList}
                                activeUser={this.props.ssrUsers}
                                customColumnData={this.props.customColumnData}
                                onChangeMailingReturnAddress={this.onChangeMailingReturnAddress}
                                onChangeEROStamp={this.onChangeEROStamp}
                                onChangeContactPerson={this.onChangeContactPerson}
                                onChangeSenderName={this.onChangeSenderName}
                                onChangeNotifySigningEvents={this.onChangeNotifySigningEvents}
                                onChangeDefaultSender={this.onChangeDefaultSender}
                                onChangeDefaultDownload={this.onChangeDefaultDownload}
                                onChangeCustomColumnValue={this.onChangeCustomColumnValue}
                                helptexts={this.props.helptexts}
                                inactiveUsers={this.props.allssrUsers}
                            />

                            <MyNotifications
                                auth={this.props.auth}
                                NotificationSettings={this.props.my.settings.notificationSettings}
                                onChangeReadyForProcessing={this.onChangeReadyForProcessing}
                                onChangeRAssigneToMe={this.onChangeRAssigneToMe}
                                onChangeApprovedForDelivery={this.onChangeApprovedForDelivery}
                                onChangePaymentNotification={this.onChangePaymentNotification}
                                isCompanyPaymentNotificationEnabled={this.props.companySettings.notificationSettingsModel.paymentVoucherNotificationSettings.paymentNotification}
                                helptexts={this.props.helptexts}
                            />

                            {this.props.my.settings.useSignatureStamp == true ? (
                                <Bs.Accordion defaultActiveKey="0" data-resource-hide={true} data-resource-id={SignatureStampSection}>
                                    <Bs.Card id="collapsible-panel-signature-stamp">
                                        <Bs.Accordion.Toggle eventKey="0">
                                        <Bs.Card.Header style={{ paddingTop: '8px', paddingBottom: '5px' }}>
                                            <Bs.Card.Title>
                                                <i className='fa fa-certificate' ></i>My Signature Stamp
                                            </Bs.Card.Title>
                                        </Bs.Card.Header>
                                        </Bs.Accordion.Toggle>
                                        <Bs.Accordion.Collapse eventKey="0">
                                            <Bs.Card.Body>
                                                    <MySignatureStamp
                                                        auth={this.props.auth}
                                                        SignatureSettings={this.props.my.settings.signatureSettings}
                                                        getCroppedCanvas={this.getCroppedCanvas.bind(this)}
                                                        onChangeAutoApplySignature={this.onChangeAutoApplySignature}
                                                        onChangeAllowDelegatestoApply={this.onChangeAllowDelegatestoApply}
                                                        enableSignerDeligation={this.props.my.settings.enableSignerDeligation}
                                                        signatureCrophandleShow={this.signatureCrophandleShow}
                                                        signatureCrophandleShowValue={this.state.signatureCropShow}
                                                        signatureUploadedStatus={
                                                            this.props.my.settings.signatureSettings.signaturePath ? true : false
                                                        }
                                                        onClickSelectUsers={this.onClickSelectUsers}
                                                        userFullName={nameText}
                                                        helptexts={this.props.helptexts}
                                                />
                                            </Bs.Card.Body>
                                        </Bs.Accordion.Collapse>
                                    </Bs.Card>
                                    </Bs.Accordion>)
                                    : ""
                            }


                            <SignatureDelegation
                                show={this.state.showSignatureDelegation}
                                onCancel={this.onSignatureDelegatesPopupHide}
                                onReset={this.handleSignatureDelegationReset}
                                delegates={this.state.delegates}
                                users={this.state.allUsers}
                                onSelectFromLeft={this.onSelectFromLeft.bind(this)}
                                onSelectFromRight={this.onSelectFromRight.bind(this)}
                                onDoubleClickLeft={this.onClickAvailableUsers}
                                onDoubleClickRight={this.onClickUsersWithAccessRights}
                                moveRight={this.moveRight}
                                moveLeft={this.moveLeft}
                                onSaveSignatureDelegates={this.onSaveSignatureDelegates}
                            />

                        </Modal.Body>) : (<OverlayLoader
                            show={this.props.showMySettings}
                            text={"Loading, please wait..."} />))
            }
            <Modal.Footer>
                <Bs.Button
                    className="btn-white"
                    variant='default'
                    data-test-auto="6A473DC7-675C-40F0-9A65-710B47FE0CBC"
                    onClick={this.onPopupClose}
                    disabled={!temp}>
                    <i className='fas fa-times'></i>
                    Cancel</Bs.Button>
                <Bs.Button
                    variant='info'
                    data-test-auto="92152B53-A40D-46B7-A39E-0DCDA1F9C1EA"
                    onClick={this.onSubmit}
                    disabled={!temp}>
                    <i className='fas fa-save'></i>
                    Save & Close</Bs.Button>
            </Modal.Footer>
        </Modal>
    }

    private getSignerDelegationUsers() {
        let delegates = Helper.selectSSRUsers(
            this.props.ssrUsers,
            this.props.my.settings.signatureDelegation ? this.props.my.settings.signatureDelegation.delegate : []);
        this.setState({
            delegates: delegates,
            originalDelegates: delegates

        });
    }

    private onSelectFromLeft(selected: ISSRUserModel, event: any) {
        let leftSelect = Helper.handleSSRSelect(selected, event, this.state.leftSelect, this.state.allUsers, this.state.lastLeft);
        this.setState({ leftSelect: leftSelect, lastLeft: selected?.id });
    }

    private onSelectFromRight(selected: ISSRUserModel, event: any) {
        let rightSelect = Helper.handleSSRSelect(selected, event, this.state.rightSelect, this.state.delegates, this.state.lastRight);
        this.setState({ rightSelect: rightSelect, lastRight: selected?.id });
    }

    private onClickAvailableUsers(selected: ISSRUserModel, callback: any) {
        let leftSelect = Helper.handleSSRSelect(selected, {}, this.state.leftSelect, this.state.allUsers, this.state.lastLeft);
        this.setState({ leftSelect: leftSelect, lastLeft: selected?.id });

        if (callback)
            callback();
    }

    private onClickUsersWithAccessRights(selected: ISSRUserModel, callback: any) {
        let rightSelect = Helper.handleSSRSelect(selected, {}, this.state.rightSelect, this.state.delegates, this.state.lastRight);
        this.setState({ rightSelect: rightSelect, lastRight: selected?.id });

        if (callback)
            callback();
    }

    private moveRight(event: any) {
        if (this.state.leftSelect.length > 0) {
            let right = [...this.state.delegates];
            this.state.leftSelect.map((u, i) => {
                right.push(u);
            });
            this.setState({
                allUsers: this.filterLeft(right),
                delegates: right,
                leftSelect: [],
                rightSelect: [],
                lastLeft: 0,
                lastRight: 0
            });
        } else {
            VenusNotifier.Warning("Please select user(s) to add!", "Select Users");
        }
        event?.preventDefault();
    }

    private moveLeft(event: any) {
        if (this.state.rightSelect.length > 0) {

            let right = [...this.state.delegates];
            this.state.rightSelect.map((u, i) => {
                Helper.removeSSRItem(u, right);
            });
            this.setState({
                allUsers: this.filterLeft(right),
                delegates: right,
                leftSelect: [],
                rightSelect: [],
                lastLeft: 0,
                lastRight: 0
            });
        } else {
            VenusNotifier.Warning("Please select user(s) to remove!", "Unselect Users");
        }
        event?.preventDefault();
    }

    private filterLeft(right: ISSRUserModel[]) {
        let left = [...this.props.ssrUsers];
        right.map((u, i) => {
            Helper.removeSSRItem(u, left);
        });
        return left;
    }

    private onChangeMailingReturnAddress(value: any) {
        let settings = this.props.my.settings;
        settings.defaultSettings.manualAddressId = value;
        this.props.updateSettings(settings);
    }

    private onChangeEROStamp(value: any) {
        let settings = this.props.my.settings;
        settings.defaultSettings.eroUser = value;
        this.props.updateSettings(settings);
    }

    private onChangeContactPerson(value: any) {
        let settings = this.props.my.settings;
        settings.defaultSettings.contactPerson = value;
        this.props.updateSettings(settings);
    }

    private onChangeSenderName(value: any) {
        let settings = this.props.my.settings;
        settings.defaultSettings.sender.senderId = value;
        this.props.updateSettings(settings);
    }

    private onChangeNotifySigningEvents(value: any) {
        let settings = this.props.my.settings;
        settings.defaultSettings.notifyUser = value;
        this.props.updateSettings(settings);
    }

    private onChangeReadyForProcessing(event: any) {
        let settings = this.props.my.settings;
        settings.notificationSettings.isReturnReady = event.target.checked;
        this.props.updateSettings(settings);
    }

    private onChangeRAssigneToMe(event: any) {
        let settings = this.props.my.settings;
        settings.notificationSettings.isReturnAssigned = event.target.checked;
        this.props.updateSettings(settings);
    }

    private onChangeApprovedForDelivery(event: any) {
        let settings = this.props.my.settings;
        settings.notificationSettings.isApprovedForDelivery = event.target.checked;
        this.props.updateSettings(settings);
    }

    private onChangePaymentNotification(event: any) {
        let settings = this.props.my.settings;
        settings.notificationSettings.paymentNotification = event.target.checked;
        this.props.updateSettings(settings);
    }

    private onChangeAutoApplySignature(event: any) {
        let settings = this.props.my.settings;
        settings.signatureSettings.isAutoPlaceSignature = event.target.checked;
        this.props.updateSettings(settings);
    }

    public onChangeAllowDelegatestoApply(event: any) {
        let settings = this.props.my.settings;
        settings.signatureSettings.isEnableSignatureDelegation = event.target.checked;
        this.props.updateSettings(settings);
        this.setState({ isSignatureDelegationEnabled: settings.signatureSettings.isEnableSignatureDelegation });
    }

    private onChangeDefaultSender(event: any) {
        let settings = this.props.my.settings;
        if (event.target.value == "company") {
            settings.defaultSettings.sender.senderType = DefaultSenderInfoType.Company;
            settings.defaultSettings.sender.senderId = 0;
        }
        else {
            settings.defaultSettings.sender.senderType = DefaultSenderInfoType.User
        }
        this.props.updateSettings(settings);
    }
    private onChangeDefaultDownload(event: any) {
        let settings = this.props.my.settings;
        let DownloadInfoType = event as DefaultDownloadInfoType;
        settings.defaultSettings.download.downloadType = DownloadInfoType;

        this.props.updateSettings(settings);
    }

    onSaveSignatureDelegates() {
        this.setState({
            allUsers: this.filterLeft(this.state.delegates),
            originalDelegates: this.state.delegates,
            updatedDelegates: this.state.delegates.map((user, index) => { return user.id }),
            showSignatureDelegation: false
        });
    }

    private onChangeCustomColumnValue(value: any) {
        let settings = this.props.my.settings;
        settings.defaultSettings.customColumnValue = value;
        this.props.updateSettings(settings);
    }

    private onSubmit() {
        if (!this.state.saving) {
            this.setState({ saving: true, message: msg.saving, isSignatureLoaded: false },
                () => {
                    if (this.state.isSignatureUploaded) {
                        this.uploadSignature(SignatureStampSection);
                    }
                    if (this.state.isSignatureDelegationEnabled) {
                        this.props.saveSignatureDelegates(this.state.updatedDelegates);
                    }
                    this.props.saveSettings(this.props.my.settings, true, () => {
                        this.props.requestUserSettings(true);
                    });
                });
        }
    }

    onPopupClose() {
        this.setState({
            isSignatureLoaded: false,
            isSignatureUploaded: false,
            saving: false,
            updateSignatureFlowDelegates: false
        }, () => {
            this.props.requestUserSettings(true);
            this.props.onToggle(userSettingConstants.mySettings);
        })
    }

    onClickSelectUsers() {
        this.setState({ showSignatureDelegation: true });
    }

    onSignatureDelegatesPopupHide() {
        this.setState({
            allUsers: this.filterLeft(this.state.originalDelegates),
            delegates: this.state.originalDelegates,
            showSignatureDelegation: false
        });
    }

    private uploadSignature(resourceId: string = "") {
        let path = this.props.my.settings.signatureSettings.signaturePath;
        if (!path) {
            VenusNotifier.Warning("Please select a signature file to upload!", "Upload Signature");
            return;
        }

        fetch(API_BASE_URL + "api/UserSettings/GetUserSignUploadLinkAsync", {
            credentials: "include",
            headers: new Headers({ "X-Resource-Id": resourceId })
        })
            .then((response) => response.json())
            .then((url) => {
                let blobData = Helper.b64toBlob(
                    path.replace('data:image/png;base64,', ''),
                    "image/png",
                    512)

                fetch(url.sas, {
                    method: 'PUT',
                    headers: new Headers({
                        'Access-Control-Allow-Origin': '*',
                        'x-ms-blob-type': 'BlockBlob',
                        'Content-Type': 'image/png',
                        'x-ms-meta-filename': 'UserSignature.png',
                        'x-ms-meta-filetype': 'image/png'
                    }),
                    body: blobData
                }).then(response => {
                    this.setState({ isSignatureLoaded: false, isSignatureUploaded: false });
                }).catch((error: any) => {
                    console.log(error);
                });
            })
            .catch((error: any) => {
				console.log(error);
			});
    }

    private getCroppedCanvas(croppedImage: any) {
        let settings = this.props.my.settings;
        settings.signatureSettings.signaturePath = croppedImage;
        this.props.updateSettings(settings);
        this.setState({ isSignatureUploaded: true });
    }

    private downloadSignature() {
        fetch(API_BASE_URL + 'api/UserSettings/GetUserSignLinkAsync/' + this.props.my.settings?.userId, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8'
            }
        }).then((resp) => resp.json()).
            then(response => {
                let settings = this.props.my.settings;
                settings.signatureSettings.signaturePath = response.sas;
                this.props.updateSettings(settings);
                this.setState({ isSignatureLoaded: true });
            })
            .catch((error: any) => {
				console.log(error);
			});
    }


    signatureCrophandleShow() {
        this.setState({ signatureCropShow: true });
    }


    handleSignatureDelegationReset(e: any) {
        this.setState({
            allUsers: this.filterLeft(this.state.originalDelegates),
            delegates: this.state.originalDelegates
        });
    }

}
export default MySettings;
