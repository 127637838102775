import * as React from 'react';
import * as Bootstrap from 'react-bootstrap';
import { UploadDocumentModal } from '../../common/UploadDocumentModal';
import { IUserBaseModel, IUserModel } from '../../../Core/ViewModels/User/UserViewModel';
import { ICompanySettings, ISuiteTaxSoftwareSetting, TaxSoftwareType } from '../../../Core/ViewModels/Company/CompanySettingsViewModel';
import * as CompanyStore from '../../../store/company/CompanyStore';
import * as UploadTaxReturnStore from '../../../store/common/uploadTaxReturn';
import { UploadFabButton } from '../../../components/common/UploadFabButton';
import { IUserDefaultSettingsStates } from '../../navigation/profile/MySettings';
import { IGroupAccess } from '../../GroupedReturns/GroupConfiguration';
import { IGroupInfo } from '../../../store/groupedreturns/GroupedReturnProcessState';

interface ArchivedReturnsHeaders {
	pageTitle: string;	
	onCustomColumnOpen: (rowIndex: number) => void;	
	onRestoreReturnOpen: (rowIndex: number) => void;
	onMultipleDownloadEfileForms: () => void,
	selectedDocumentCount: number,
	taxYear: number;
	customColumnTitle: string;
	users: IUserModel[];
    onPageReload: () => void;
    company: CompanyStore.ICompanyData;
    selectedERO: number;
    uploadTaxReturn: UploadTaxReturnStore.IUploadTaxReturnState;
    processTaxReturn: (url: string, callback: () => void) => void;
    getUploadLink: (url: string) => void;
    submitTaxReturn: (url: string, taxData: string, callback: () => void) => void;
	userDefaultSettings: IUserDefaultSettingsStates,
	companySettings: ICompanySettings,
	defaultGroup: string;
	groupInfo: IGroupInfo[];
	groupAccess: IGroupAccess[];
    updateGroupInfoState: (reload: boolean) => void;
    taxSoftwareSetting: ISuiteTaxSoftwareSetting;
}
interface ArchivedReturnsHeadersState {
	show: boolean,
	selectedTaxSoftware: TaxSoftwareType;
}
const NO_INDEX = -1;
export class ArchiveReturnsHeader extends React.Component<ArchivedReturnsHeaders, ArchivedReturnsHeadersState> {
	constructor(props: ArchivedReturnsHeaders) {
		super(props);
		this.onUploadReturnsOpen = this.onUploadReturnsOpen.bind(this);
		this.HandleClose = this.HandleClose.bind(this);
		this.state = {
			show: false,
			selectedTaxSoftware: TaxSoftwareType.None
		};
	}

	private onUploadReturnsOpen(taxSoftware: TaxSoftwareType) {
		this.setState({ show: true, selectedTaxSoftware: taxSoftware });
	}

	private HandleClose() {
		this.setState({ show: false });
	}
	public render() {
		
		return <div>
            <div className="row" style={{ marginBottom: 5 }}>
				<div className="col-sm-8">
					<h3>{this.props.pageTitle}</h3>
				</div>
              
			</div>
			<div className="row">
				<div className="col-sm-10">					
					
					<Bootstrap.Button
						variant='default'
						className='btn btn-white marL10'
						data-test-auto="C39CFC1B-36FE-4287-B529-8F88E341495F"
						disabled={this.props.selectedDocumentCount === 0}
						onClick={() => this.props.onCustomColumnOpen(NO_INDEX)} >
						<i className='fas fa-tags'></i>{"Change " + this.props.customColumnTitle}
					</Bootstrap.Button>					
					<Bootstrap.Button
						variant='default'
						className='btn btn-white marL10'
						data-test-auto="60BA7F81-48F2-40A2-B704-1930B193090A"
						disabled={this.props.selectedDocumentCount === 0}
						onClick={() => this.props.onRestoreReturnOpen(NO_INDEX)}>
						<i className='fas fa-sync-alt'></i>Restore
                    </Bootstrap.Button>
					<Bootstrap.Button
						variant='default'
						className='btn btn-white marL10'
						data-test-auto="03F15B8A-E226-4A43-BC66-6B2FF78F211E"
						disabled={this.props.selectedDocumentCount === 0}
						onClick={this.props.onMultipleDownloadEfileForms} >
						<i className='fas fa-file-signature'></i>Download e-file Forms
                    </Bootstrap.Button>
				</div>
				<div className="col-sm-2 txt-ar">
                    <UploadFabButton
                        taxSoftwareSetting={this.props.taxSoftwareSetting}
						userDefaultSettings={this.props.userDefaultSettings}
						onUploadReturnsClick={this.onUploadReturnsOpen}
					/>
				</div>
            </div>
			<UploadDocumentModal
				onShow={this.state.show}
				onHide={this.HandleClose}
				users={this.props.users}
				taxSoftware={this.state.selectedTaxSoftware}
				selectedERO={this.props.selectedERO}
				getUploadLink={this.props.getUploadLink}
				processTaxReturn={this.props.processTaxReturn}
				submitTaxReturn={this.props.submitTaxReturn}
				onPageReload={this.props.onPageReload}
				selectedGroup={this.props.defaultGroup}
				groupInfo={this.props.groupInfo}
				groupAccess={this.props.groupAccess}
				updateGroupInfoState={this.props.updateGroupInfoState}
			/>
		</div>
	}
}
