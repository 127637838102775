import ControlBase, { PDF_VIEWER_BASE_HEIGHT } from 'awesome-pdf-viewer/dist/Controls/ControlBase';
import { PdfProperties } from '../../../../Core/ViewModels/Common/PdfHelper';
import { AwesomePdfViewerDefaultScale } from '../../../helper/Constants';
import { VenusNotifier } from '../../../helper/VenusNotifier';
import { ISignatureControl, SignatureControlRole, SignatureControlTypes } from '../../TaxReturn';

export function getControlText(controlType: SignatureControlTypes): string {
    switch (controlType) {
        case SignatureControlTypes.Date:
            return "Date";
        case SignatureControlTypes.Signature:
            return "Signature";
        case SignatureControlTypes.NameAndTitle:
            return "Name and Title";
        case SignatureControlTypes.Company:
            return "Company";
        case SignatureControlTypes.Initial:
            return "Initials";
        case SignatureControlTypes.Text:
            return "Text";
        case SignatureControlTypes.PrintName:
            return "Print Name";
        case SignatureControlTypes.Title:
            return "Title"
        case SignatureControlTypes.CheckBox:
            return "Checkbox";
        case SignatureControlTypes.RadioButton:
            return "Radio Button";
        default:
            return "";
    }
}

export function getControlIcon(controlType: SignatureControlTypes): string {
    switch (controlType) {
        case SignatureControlTypes.Date:
            return "fas fa-calendar-alt";
        case SignatureControlTypes.Signature:
            return "fas fa-signature";
        case SignatureControlTypes.NameAndTitle:
            return "fas fa-address-card";
        case SignatureControlTypes.Company:
            return "fas fa-building";
        case SignatureControlTypes.Initial:
            return "fas fa-font";
        case SignatureControlTypes.Text:
            return "fas fa-text-width";
        case SignatureControlTypes.PrintName:
            return "fas fa-user-circle";
        case SignatureControlTypes.Title:
            return "fas fa-address-card"
        case SignatureControlTypes.CheckBox:
            return "fas fa-check-square";
        case SignatureControlTypes.RadioButton:
            return "fas fa-dot-circle";
        default:
            return "";
    }
}

const TopAdjustmentForSignatureControl = 12;

export function dragStartSignatureControl(
    event: any,
    controlType: SignatureControlTypes,
    signatureRole: SignatureControlRole,
    eroSignerLabel: string,
    currentPage: number,
    scale: number,
    addSignatureControl: (type: SignatureControlTypes, role: SignatureControlRole, clientX: number, clientY: number) => void,
    pageHeight?: number) {

    registerDropEvents(currentPage, scale, pageHeight, addSignatureControl);
    event.dataTransfer.setData('controlType', controlType.toString());
    event.dataTransfer.setData('signatureRole', signatureRole.toString());

    var element = document.createElement("div");
    element.id = "dragging-element";
    element.style.position = "absolute";
    element.style.top = "-1000px";

    if (signatureRole == SignatureControlRole.ERO && controlType == SignatureControlTypes.Signature) {     
            element.innerHTML = '<p class="esignEROStamp" style="border-style: solid; border-width: 1px; max-height: '
                + (22 * scale).toString() + 'px; font-size: '
                + (12 * scale).toString() + 'px; font-weight: normal; padding: 0px 5px 0px 5px; overflow: hidden;">'
                + eroSignerLabel + '</p>';    
    }
    else {
        let controlText = getControlText(controlType);
        let icon = getControlIcon(controlType);
        element.innerHTML = '<p style="border-style: solid; border-width: 1px;"><i class="'
            + icon + '" style="padding: 5px; background: rgb(240, 240, 241);"></i><label style="font-size: '
            + (12 * scale).toString() + 'px; font-weight: normal; padding: 0px 5px 0px 5px;">'
            + controlText + '</label></p>';
    }
    document.body.appendChild(element);
    event.dataTransfer.setDragImage(element, 0, 0);
}

function registerDropEvents(
    currentPage: number,
    scale: number,
    pageHeight: number,
    addSignatureControl: (type: SignatureControlTypes, role: SignatureControlRole, clientX: number, clientY: number) => void) {
    const elements = document.getElementsByClassName("page");
    for (var i = 0; i <= elements.length - 1; i++) {
        const tmpElement: any = elements[i];
        tmpElement.ondrop = function (ev: any) {
            ev?.preventDefault();
            const tmpPageElement = ev.target.offsetParent;
            if (tmpPageElement?.attributes["data-page-number"]) {
                dropSignatureControl(ev, currentPage, scale, pageHeight, addSignatureControl);
            }
        };
        tmpElement.ondragover = function (ev: any) {
            ev?.preventDefault();
        };
    }
}

function dropSignatureControl(
    event: any, currentPage: number,
    scale: number,
    pageHeight: number,
    addSignatureControl: (type: SignatureControlTypes, role: SignatureControlRole, clientX: number, clientY: number) => void) {
    event?.preventDefault();
    if (scale !== PdfProperties.DefaultScale) {
        VenusNotifier.Warning("The controls can be placed only on 100% Zoom in ratio.", "");
        return;
    }
    let controlType = event.dataTransfer.getData('controlType');
    let signatureRole = event.dataTransfer.getData('signatureRole');
    if (controlType && signatureRole) {
        controlType = parseInt(controlType);
        signatureRole = parseInt(signatureRole);
        if (!Number.isNaN(controlType) && !Number.isNaN(signatureRole)) {
            calculatePositionAndAddSignatureControl(
                controlType, signatureRole, event.offsetX, event.offsetY, currentPage, pageHeight, addSignatureControl);
        }
    }
}

function calculatePositionAndAddSignatureControl(
    type: SignatureControlTypes,
    role: SignatureControlRole,
    clientX: number,
    clientY: number,
    currentPage: number,
    pageHeight: number,
    addSignatureControl: (type: SignatureControlTypes, role: SignatureControlRole, clientX: number, clientY: number) => void) {
    const left = ControlBase.getBackendControlLeftPosition(clientX);
    const top = ControlBase.getBackendControlTopPosition(pageHeight ? pageHeight : PDF_VIEWER_BASE_HEIGHT,
        clientY + getTopAdjustmentSignatureControlValue(type));
    if (addSignatureControl) {
        addSignatureControl(type, role, left, top);
    }
}

export function getTopAdjustmentSignatureControlValue(controlType: SignatureControlTypes): number {
    switch (controlType) {
        case SignatureControlTypes.CheckBox:
        case SignatureControlTypes.RadioButton:
            return 0;
        default:
            return TopAdjustmentForSignatureControl;
    }
}

export function getControlTopPositionPDFJS(page: number, top: number, scale: number): number {
    const pageElement: any = ControlBase.getPageElement(page);
    const AWESOME_PDF_VIEWER_LETTER_HEIGHT = 1056;
    const AWESOME_PDF_VIEWER_BASE_HEIGHT_DOCTYPE_A4 = 842;
    const AWESOME_PDF_VIEWER_BASE_HEIGHT_DOCTYPE_LETTER = 792;

    const pageHeight = pageElement ? pageElement.offsetHeight : AWESOME_PDF_VIEWER_LETTER_HEIGHT;
    const baseHeight = pageHeight > AWESOME_PDF_VIEWER_LETTER_HEIGHT ? AWESOME_PDF_VIEWER_BASE_HEIGHT_DOCTYPE_A4
        : AWESOME_PDF_VIEWER_BASE_HEIGHT_DOCTYPE_LETTER;
    const topRatio = (pageHeight * scale) / baseHeight;
    top = pageHeight - top;
    top = top / topRatio;
    return top;
}

export function getSignatureControlStyle(control: ISignatureControl, scale: number): React.CSSProperties {
    let fontSize = 16;
    let style: React.CSSProperties = {};

    style.zIndex = 5;
    style.display = "block";
    style.fontSize = (fontSize * scale).toString() + "px";
    style.height = (30 * scale).toString() + "px";
    if (control.signatureControlRole !== SignatureControlRole.ERO) {
        style.paddingRight = "10px";
    }
    return style;
}

export function getMaskControlStyle(control: any, scale: number): React.CSSProperties {
    let fontSize = control.height * scale;
    let style: React.CSSProperties = {};

    style.zIndex = 5;
    style.display = "block";
    style.fontSize = (fontSize * scale).toString() + "px";
    style.height = (control.height * AwesomePdfViewerDefaultScale * scale).toString() + "px";

    style.width = (control.width * AwesomePdfViewerDefaultScale * scale).toString() + "px";
    style.cursor = "default";
    style.fontWeight = "600";
    style.textAlign = "center";
    style.overflow = "hidden";
    return style;
}
