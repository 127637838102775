import { connect, ConnectedComponent } from "react-redux";
import { ApplicationState } from "../../store";
import * as DeliveredReturnsStore from "../../store/reports/DeliveredReturnsStore";
import * as FilterStore from "../../store/reports/FilterStore";
import * as TaxDocumentStore from "../../store/common/TaxDocumentStore";
import * as Component from "./DeliveredReturns";
import * as UserStore from "../../store/userManagement/UserStore";
import * as UserSettingStore from "../../store/userManagement/UserSettingStore";
import * as CustomColumnstore from "../../store/common/CustomColumnStore";
import * as CompanyStore from "../../store/company/CompanyStore";
import * as ReminderStore from "../../store/common/ReminderStore";
import * as UploadTaxReturnStore from "../../store/common/uploadTaxReturn";
import * as AttachmentsStore from "../../store/ProcessReturn/AttachmentsStore";
import * as BulkOperationsStore from "../../store/common/BulkOperationsStore";
import * as ColumnSettingsStore from "./../../store/reports/ColumnSettings/ColumnSettingsStore";
import * as AdditionalEsignDocumentStore from "../../store/ProcessReturn/AdditionalEsignDocuments/AdditionalEsignDocumentStore";
import * as GroupedReturnStore from "../../store/groupedreturns/GroupedReturnStore";
import * as QuickReportStore from "../../store/reports/QuickReportStore";
import * as VoucherStore from "../../store/ProcessReturn/voucher/VoucherStore";
import * as UploadStore from "../../store/common/Upload";
import * as PdfStore from "../../store/pdf/PdfStore";
import * as UserSignatureStore from "../../store/common/UserSignatureStore";
import * as RevisionDocumentsStore from "../../store/common/RevisionDocumentStore";
import * as PaperFileStore from "../../store/ProcessReturn/PaperFileStore";
import * as K1AttachmentStore from "../../store/ProcessReturn/K1AttachmentStore";
import { DeliveredReturnReportResourceId, GroupedReturnReportResources } from "../helper/ResourceIdConstants";
import * as OfficeLocationStore from "../../store/common/OfficeLocationStore";
import * as ClientManagementSettingStore from "../../store/company/SuiteClientManagementSettingStore";
import * as SMSNotificationDetailsStore from "../../store/common/SMSNotificationDetailsStore";
import * as DeliveredGroupedReturnsStore from "../../store/reports/GroupedReturns/DeliveredGroupedReturns/DeliveredGroupedReturnsStore";
import * as GroupedTaxDocumentStore from "../../store/groupedreturns/GroupedTaxDocumentStore";
import * as SendGroupedReturnsStore from "../../store/reports/GroupedReturns/SendGroupedReturnsStore";
import * as GroupedReturnsCommonStore from "../../store/common/GroupedReturnsCommonStore";
import * as GroupedReturnProcessStore from "../../store/groupedreturns/GroupedReturnProcessStore";
import * as DeliveredGroupedReturnsHeaderStore from "../../store/reports/GroupedReturns/DeliveredGroupedReturns/DeliveredGroupedReturnsHeaderStore";
import * as OnehubStore from "../../store/common/OnehubStore";
import * as CCHReturnStore from '../../store/reports/CCHReturns/CCHReturnStore';
import * as EFileSettingsStore from '../../store/company/settings/EFileSettingsStore';


export default connect(
    (state: ApplicationState) => ({
        auth: state.auth,
        delivered: state.deliveredReturns, // Selects which state properties are merged into the component's props
        taxDocuments: state.taxDocuments,
        userStore: state.ssrUserList,
        reportFilters: state.reportFilters,
        userSettings: state.userSettings,
        customColumnData: state.customColumn,
        company: state.companyData,
        reminderData: state.reminderData,
        nextSignerDetails: state.nextSignerDetails,
        uploadTaxReturnStore: state.UploadTaxReturnStore,
        attachments: state.attachments,
        isArchived: false, //This flag is to re-use DeliveredReturns component for the temporary archive
        pageTitle: "Delivered Returns",
        columnSettings: state.columnSettingsState,
        userProfile: state.userProfile,
        groupedReturnStore: state.groupedReturnStore,
        quickReports: state.quickReports,
        pdfDocuments: state.pdfDocuments,
        voucherStore: state.voucherStore,
        userSignatures: state.userSignatures,
        K1AttachmentStore: state.k1Attachment,
        userResources: state.userResources,
        parentResourceId: DeliveredReturnReportResourceId.DeliveredPrefix,
        ssrUsers: state.ssrUsers,
        taxSoftwareSetting: state.taxSoftwareSetting,
        officeLocation: state.officeLocation,
        clientManagementSetting: state.clientManagementSetting,
        parentControllerGridResourceIdPrefix: GroupedReturnReportResources.ControllerGridPrefix,
        groupedReturnProcessState: state.groupedReturnProcessState,
        eFileSettings: state.eFileSettings,
        cchReturnState: state.cchReturns
    }),
    {
        ...DeliveredReturnsStore.actionCreators,
        ...TaxDocumentStore.actionCreators,
        ...UserStore.actionCreators,
        ...FilterStore.actionCreators,
        ...UserSettingStore.actionCreators,
        ...CustomColumnstore.actionCreators,
        ...CompanyStore.actionCreators,
        ...ReminderStore.actionCreators,
        ...UploadTaxReturnStore.actionCreators,
        ...AttachmentsStore.actionCreators,
        ...ColumnSettingsStore.actionCreator,
        ...BulkOperationsStore.actionCreators,
        ...AdditionalEsignDocumentStore.actionCreators,
        ...GroupedReturnStore.actionCreators,
        ...QuickReportStore.actionCreators,
        ...VoucherStore.actionCreators,
        ...UploadStore.actionCreators,
        ...PdfStore.actionCreators,
        ...UserSignatureStore.actionCreators,
        ...RevisionDocumentsStore.actionCreators,
        ...PaperFileStore.actionCreators,
        ...K1AttachmentStore.actionCreators,
        ...OfficeLocationStore.actionCreators,
        ...ClientManagementSettingStore.actionCreators,
        ...SMSNotificationDetailsStore.actionCreators,
        ...DeliveredGroupedReturnsStore.actionCreators,
        ...GroupedTaxDocumentStore.actionCreators,
        ...SendGroupedReturnsStore.actionCreators,
        ...GroupedReturnsCommonStore.actionCreators,
        ...GroupedReturnProcessStore.actionCreators,
        ...DeliveredGroupedReturnsHeaderStore.actionCreators,
        ...OnehubStore.actionCreators,
        ...CCHReturnStore.actionCreators,
        ...EFileSettingsStore.actionCreators
    }
)(Component.DeliveredReturns as any);
