var htmlencoder = require("htmlencode");
import * as React from "react";
import * as bootbox from "bootbox";
import "react-select/dist/react-select.css";
import * as Enumerable from "linq";
import { MessageDetails } from "../../../../Core/ViewModels/CompanySettings/SavedMessageViewModel";
import * as TaxDocument from "../../../common/TaxReturn";
import * as CompanyStore from "../../../../store/company/CompanyStore";
import { ICompanySettings, K1MailCycles } from "../../../../Core/ViewModels/Company/CompanySettingsViewModel";
import { FormLabel, Row, FormGroup, Button, Col, Form } from "react-bootstrap";
import ToggleSwitch from "../../../common/ToggleSwitch";
import {
    ClientTypes,
    SignatureType,
    isPartnership,
    IDocumentSettings,
    IMarriedJointTaxReturn,
    isMutual
} from "../../../common/TaxReturn";
import { ISSRUserModel } from "../../../../Core/ViewModels/User/UserViewModel";
import { PreparerMessage } from "../PreparerMessage";
import { DefaultSenderInfoType } from "../../../navigation/profile/MySettings";
import { Link } from "react-router-dom";
import * as SavedMessageStore from "../../../../store/settings/SavedMessageStore";
import { RadioButtonComponent } from "../../RadioButtonComponent";
import {
    isBusinessReturn,
    ReplaceHelperVariableForEditor,
    ReplaceHelperVariable,
    fetchHelpText
} from "../../../helper/HelperFunctions";
import { SelectedMailingAddressView } from "../../../common/ProcessReturnModal/SelectedMailingAddressView";
import * as MailingReturnAddressStore from "../../../../store/common/MailingReturnAddressStore";
import { TabContentContainer } from "./TabContentContainer/TabContentContainer";
import { DropdownComponent } from "../../../common/controls/DropdownComponent";
import { VenusNotifier } from "../../../helper/VenusNotifier";
import * as Constants from "../../../helper/Constants";
import { container } from "../../../../Startup/inversify.config";
import { TYPES } from "../../../../Startup/types";
import { IHtmlUtilities } from "../../../../Core/Utilities/HtmlUtilities";
import { YearAndMonth } from "../../../common/YearAndMonthComponent";
import { CheckBoxComponent } from "../../CheckBoxComponent";
import { IWatermarkModel } from "../../../../components/settings/GeneralSettingsComponents/WatermarkComponents/WatermarkSetting";
import { IColumnValues, ICustomColumn } from "../../../settings/GeneralSettings";
import * as UserSettingStore from "../../../../store/userManagement/UserSettingStore";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import { DatalistFormatter } from "../../../common/UploadDocument/TaxSoftwareTypeUpload/UploadCommonFormatters";
import { IGroupInfo } from "../../../../store/groupedreturns/GroupedReturnProcessState";
import * as PaperFileMessageStore from "../../../../store/settings/PaperFiledMessageStore";
import { PaperFileMessage } from "../PaperFile/PaperFileMessage";
import { logger } from "../../../../routes/LoggedIn";
import { ISSRSettings } from "../../../../Core/ViewModels/Company/Settings/SSRSettingsViewModel";
import { ISMSNotificationDetails } from "../../../../store/common/SMSNotificationDetailsStore";
import { IHelptextModel } from "../../../../Core/ViewModels/Common/IHelptextModel";
import KBADisableConfirmationModal from "../KBADisableConfirmationModal";

export interface IDeliveryOptionsViewProps {
    company: CompanyStore.ICompanyData;
    companySettings: ICompanySettings;
    ssrSettings: ISSRSettings;
    isAssignedToLoggedinUser?: boolean;
    isEsign: boolean;
    taxReturn: TaxDocument.ITaxReturn;
    requestMailingReturnAddressList: () => void;
    mailingReturnAddressList: MailingReturnAddressStore.IMailingReturnAddressDictionary;
    generateTaxPayerView: (clientType: ClientTypes) => void;
    savedMessages: SavedMessageStore.ISavedMessageDictionary;
    users: ISSRUserModel[];
    getAllSavedMessages: (reload: boolean) => void;
    disabledTaxpayerPreview: boolean;
    signatureTypeInDocSettings: string;
    isSignatureoptionsToggledInDeliveryOption: boolean;
    handleSignatureOptionToggle: (event: any) => void;
    watermarkList: IWatermarkModel[];
    customColumnData: ICustomColumn;
    userSettings: UserSettingStore.UserSettings;
    handleCustomColumnChange: (columnValue: IColumnValues) => void;
    isLoading: boolean;
    groups: IGroupInfo[];
    onGroupChange: (value: any) => void;
    selectedGroup: IGroupInfo;
    paperFileMessage: PaperFileMessageStore.IPaperFiledMessageDictionary;
    updateTaxDocument: (taxDocument: TaxDocument.ITaxReturn) => void;
    showSMSTextNotifications: boolean;
    isTPOpted: boolean;
    isSpouseOpted: boolean;
    smsNotificationDetails: ISMSNotificationDetails;
    updateTPOpted: (tpOpted: boolean) => void;
    updateSpouseOpted: (spouseOpted: boolean) => void;
    inactiveUsers: ISSRUserModel[];
    helptexts: IHelptextModel[];
    deliverFirstToUpdated: (updated: boolean) => void;
}

interface Tabstate {
    selectedOption: string;
    contactPerson: SelectOptions[];
    selectedContactPerson: number;
    sender: SelectOptions[];
    selectedSender: number;
    notifyUser: SelectOptions[];
    selectedNotifyUser: number;
    paymentNotifyUser: SelectOptions[];
    selectedPaymentNotifyUser: number;
    message: SelectOptions[];
    selectedMessage: number;
    messageDetails: MessageDetails[];
    preparerMessageShow: boolean;
    prepareMessage: string;
    prepareMessageMode: PrepareMessageMode;

    selectedReminder: number;
    reminderOptions: SelectOptions[];

    selectedSingingReminder: number;
    signingReminderOptions: SelectOptions[];

    isK1ShareHolder: boolean;
    selectedK1PartnerReminder: number;
    selectedK1CleintReminder: number;
    k1ReminderOptions: SelectOptions[];

    mailingAddressOptions: SelectOptions[];
    selectedMailingAddress: number;

    isAllowToEdit: boolean;
    disabledTaxpayerPreview: boolean;

    selectedMessageId: number;
    customPlaceHolderPreparerMessage: any;
    customPlaceHolderPaperfileMessage: any;
    retentionDuration: number;
    allowOverride: boolean;
    isAttested: boolean;

    watermarkOptions: SelectOptions[];
    selectedWatermark: number;
    customColumnOptions: SelectOptions[];
    selectedCustomColumn: number;

    isSignerDelegationChanged: boolean;
    isDelegateeKBAChanged: boolean;
    isKBADisableChecked: boolean;
    showReturnLevelKBADisablingConfirmationModal: boolean;
    paperFileMessages: SelectOptions[];
    selectedPaperFileMessage: number;
    isPaperFileMessageAllowToEdit: boolean;
    paperFileMessageShow: boolean;
    paperFileMessage: string;
    paperFileMessageMode: PrepareMessageMode;
}

const enum PrepareMessageMode {
    Add,
    Update
}

interface SelectOptions {
    value: any;
    label: string;
}

const htmlUtilities = container.get<IHtmlUtilities>(TYPES.IHtmlUtilities);

export class TabDeliveryOptions extends React.Component<IDeliveryOptionsViewProps, Tabstate> {
    constructor(props: IDeliveryOptionsViewProps) {
        super(props);
        this.state = {
            selectedOption: "",
            contactPerson: [],
            selectedContactPerson: 0,
            sender: [],
            selectedSender: 0,
            notifyUser: [],
            selectedNotifyUser: 0,
            paymentNotifyUser: [],
            selectedPaymentNotifyUser: 0,
            message: [],
            selectedMessage: 0,
            messageDetails: [],
            reminderOptions: [],
            selectedReminder: 1,
            signingReminderOptions: [],
            selectedSingingReminder: 1,
            isK1ShareHolder: false,
            selectedK1CleintReminder: 30,
            selectedK1PartnerReminder: 30,
            k1ReminderOptions: [],
            mailingAddressOptions: [],
            selectedMailingAddress: 0,
            preparerMessageShow: false,
            prepareMessage: "",
            prepareMessageMode: 0,
            isAllowToEdit: false,
            disabledTaxpayerPreview: false,
            selectedMessageId: 0,
            customPlaceHolderPreparerMessage: "Select...",
            customPlaceHolderPaperfileMessage: "Select...",
            retentionDuration: 2520,
            allowOverride: false,
            isAttested: false,

            watermarkOptions: [],
            selectedWatermark: 0,
            customColumnOptions: [],
            selectedCustomColumn: 0,
            isSignerDelegationChanged: false,
            isDelegateeKBAChanged: false,
            isKBADisableChecked: false,
            showReturnLevelKBADisablingConfirmationModal: false,
            paperFileMessages: [],
            selectedPaperFileMessage: 0,
            isPaperFileMessageAllowToEdit: false,
            paperFileMessage: "",
            paperFileMessageMode: 0,
            paperFileMessageShow: false
        };
        this.handleContactPersonChange = this.handleContactPersonChange.bind(this);
        this.handleSenderChange = this.handleSenderChange.bind(this);
        this.handleNotifyUserChange = this.handleNotifyUserChange.bind(this);
        this.preparerMessageHide = this.preparerMessageHide.bind(this);
        this.onEditButtonClick = this.onEditButtonClick.bind(this);
        this.onAddButtonClick = this.onAddButtonClick.bind(this);
        this.onChangeTinyMCE = this.onChangeTinyMCE.bind(this);
        this.preparerMessageUpdateButtonClick = this.preparerMessageUpdateButtonClick.bind(this);
        this.savePreparerMessage = this.savePreparerMessage.bind(this);
        this.handleDeliverFirstToChange = this.handleDeliverFirstToChange.bind(this);
        this.setMessageDetailsDropDown = this.setMessageDetailsDropDown.bind(this);
        this.setPreparerMessage = this.setPreparerMessage.bind(this);
        this.handleRetentionPeriodChange = this.handleRetentionPeriodChange.bind(this);
        this.handleAttestClientChange = this.handleAttestClientChange.bind(this);
        this.handlePaymentNotifyUserChange = this.handlePaymentNotifyUserChange.bind(this);
        this.setPaperFilePreparerMessage = this.setPaperFilePreparerMessage.bind(this);
    }

    UNSAFE_componentWillMount() {
        this.setMessageDetailsDropDown();
        this.setContactDropdown();
        this.setMailingAddressDropdown();
        this.SetReminderOptions();
        this.excerptRetentionPeriod(this.props.taxReturn);
        this.setWatermarkDropdown();
        this.setCustomColumnOptions();
        this.setPaperFileMessageDropDown();

        if (
            this.props.taxReturn.documentSettings.documentSignatureSetting.isKBADisabledInReturnLevel == false &&
            (this.props.taxReturn.documentSettings.documentSignatureSetting.allowDisablingKBAOnReturnLevel == true ||
                this.props.companySettings.signatureSettingsModel.allowDisablingKBAOnReturnLevel == true)
        ) {
            this.props.taxReturn.documentSettings.documentSignatureSetting.knowledgeBasedAuthentication = true;
            this.setState({ isKBADisableChecked: false });
        }
    }

    componentDidMount(): void {
        this.setPreparerMessage();
        this.setPaperFilePreparerMessage();
    }

    private excerptRetentionPeriod = (taxReturn: TaxDocument.ITaxReturn) => {
        if (TaxDocument.isIndividual(taxReturn) || TaxDocument.isMutual(taxReturn)) {
            this.setState({
                retentionDuration: taxReturn.documentSettings.documentRetentionSetting.retentionPeriod,
                isAttested: taxReturn.taxpayer.additionalInfo.isAttested,
                allowOverride: taxReturn.documentSettings.documentRetentionSetting.allowOverride
            });
        } else if (TaxDocument.isPartnership(taxReturn)) {
            this.setState({
                retentionDuration: taxReturn.documentSettings.documentRetentionSetting.retentionPeriod,
                isAttested: taxReturn.partnership.additionalInfo.isAttested,
                allowOverride: taxReturn.documentSettings.documentRetentionSetting.allowOverride
            });
        }
    };

    private handleContactPersonChange = (value: any) => {
        this.props.taxReturn.documentSettings.deliverySettings.contactPerson = value;
        this.setState({ selectedContactPerson: value });
    };

    private handleSenderChange = (value: any) => {
        if (value == 0) {
            this.props.taxReturn.documentSettings.deliverySettings.sender.senderType = DefaultSenderInfoType.Company;
            this.props.taxReturn.documentSettings.deliverySettings.sender.senderId = 0;
        } else {
            this.props.taxReturn.documentSettings.deliverySettings.sender.senderType = DefaultSenderInfoType.User;
            this.props.taxReturn.documentSettings.deliverySettings.sender.senderId = value;
        }
        this.setState({ selectedSender: value });
    };

    private handleNotifyUserChange = (value: any) => {
        if (this.props.taxReturn && this.props.taxReturn.documentSettings) {
            this.props.taxReturn.documentSettings.deliverySettings.notifyUser = value;
            let ssrUser = this.props.users.filter((x) => x.id == value)[0];
            if (ssrUser !== undefined) {
                this.props.taxReturn.documentSettings.documentNotificationSetting.notifyUser.userId = ssrUser.id;
                this.props.taxReturn.documentSettings.documentNotificationSetting.notifyUser.firstName = ssrUser.firstName;
                this.props.taxReturn.documentSettings.documentNotificationSetting.notifyUser.lastName = ssrUser.lastName;
                this.props.taxReturn.documentSettings.documentNotificationSetting.notifyUser.email = ssrUser.emailAddress;
            }
        }
        this.setState({ selectedNotifyUser: value });
    };

    private handlePaymentNotifyUserChange = (value: any) => {
        if (this.props.taxReturn && this.props.taxReturn.documentSettings) {
            this.props.taxReturn.documentSettings.deliverySettings.voucherPaymentNotifyUser = value;
            let ssrUser = this.props.users.filter((x) => x.id == value)[0];
            if (ssrUser !== undefined) {
                this.props.taxReturn.documentSettings.documentNotificationSetting.voucherPaymentNotifyUser.userId = ssrUser.id;
                this.props.taxReturn.documentSettings.documentNotificationSetting.voucherPaymentNotifyUser.firstName =
                    ssrUser.firstName;
                this.props.taxReturn.documentSettings.documentNotificationSetting.voucherPaymentNotifyUser.lastName =
                    ssrUser.lastName;
                this.props.taxReturn.documentSettings.documentNotificationSetting.voucherPaymentNotifyUser.email =
                    ssrUser.emailAddress;
            }
        }
        this.setState({ selectedPaymentNotifyUser: value });
    };

    private handleMessageChange = (value: any) => {
        let preparerMessage = this.props.taxReturn.documentSettings.deliverySettings.preparerMessage;
        if (!value) {
            this.props.taxReturn.documentSettings.deliverySettings.preparerMessage = {
                id: 0,
                name: "",
                body: "",
                isAllowToEdit: false
            };
            this.setState({
                selectedMessage: 0,
                customPlaceHolderPreparerMessage: "Select..."
            });
            return;
        }

        if (value !== preparerMessage.id) {
            preparerMessage.id = value;
            let selectedMessageBody =
                preparerMessage && preparerMessage.id == 0
                    ? preparerMessage.body
                    : this.props.savedMessages[value]
                    ? this.props.savedMessages[value].messageDetails.body
                    : "";
            if (value) {
                preparerMessage.name = this.props.savedMessages[value] ? this.props.savedMessages[value].messageDetails.name : "";
                preparerMessage.body = selectedMessageBody;
                this.setState({
                    selectedMessage: value,
                    isAllowToEdit:
                        preparerMessage && preparerMessage.id == 0
                            ? preparerMessage.isAllowToEdit
                            : this.props.savedMessages[value]
                            ? this.props.savedMessages[value].messageDetails.isAllowToEdit
                            : true,
                    selectedMessageId: value
                });
            }
            preparerMessage.body = htmlencoder.htmlDecode(preparerMessage.body);
            this.props.taxReturn.documentSettings.deliverySettings.preparerMessage = preparerMessage;
        }
    };

    private handleDeliverFirstToChange = (event: any) => {
        if (event.target.name == "TaxPayer") {
            this.props.taxReturn.documentSettings.deliverySettings.deliverTo = ClientTypes.Taxpayer;
        } else {
            this.props.taxReturn.documentSettings.deliverySettings.deliverTo = ClientTypes.Spouse;
        }
        this.props.deliverFirstToUpdated(true);
        this.forceUpdate();
    };

    private setMessageDetailsDropDown = () => {
        let _messageDetails: SelectOptions[] = [];

        Object.keys(this.props.savedMessages).map((key, index) => {
            _messageDetails.push({
                value: this.props.savedMessages[parseInt(key)].messageDetails.id,
                label: this.props.savedMessages[parseInt(key)].messageDetails.name
            });
        });
        let defaultMessageDetails = this.props.taxReturn.documentSettings.deliverySettings.preparerMessage
            ? this.props.taxReturn.documentSettings.deliverySettings.preparerMessage.id
            : this.props.ssrSettings.defaultMessageSettings.savedMessage;
        let selectedMessageValue: number = 0;
        let isAllowToEdit: boolean = false;
        if (
            this.props.taxReturn.documentSettings.deliverySettings.preparerMessage &&
            this.props.taxReturn.documentSettings.deliverySettings.preparerMessage.id == 0
        ) {
            selectedMessageValue = this.props.taxReturn.documentSettings.deliverySettings.preparerMessage.id;
            isAllowToEdit = this.props.taxReturn.documentSettings.deliverySettings.preparerMessage.isAllowToEdit;
        } else {
            selectedMessageValue =
                this.props.savedMessages[defaultMessageDetails] === undefined
                    ? this.props.savedMessages[parseInt(Object.keys(this.props.savedMessages)[0])].messageDetails.id
                    : this.props.savedMessages[defaultMessageDetails].messageDetails.id;
            isAllowToEdit =
                this.props.savedMessages[defaultMessageDetails] === undefined
                    ? this.props.savedMessages[parseInt(Object.keys(this.props.savedMessages)[0])].messageDetails.isAllowToEdit
                    : this.props.savedMessages[defaultMessageDetails].messageDetails.isAllowToEdit;
        }

        this.setState({
            message: _messageDetails
        });
    };

    private setPreparerMessage = () => {
        let selectedMessageId = 0;
        let selectedMessage = "";
        let isAllowToEdit: boolean = false;
        if (this.props.savedMessages) {
            if (
                this.props.taxReturn.documentSettings.deliverySettings.preparerMessage &&
                this.props.taxReturn.documentSettings.deliverySettings.preparerMessage.id != 0 &&
                this.props.savedMessages[this.props.taxReturn.documentSettings.deliverySettings.preparerMessage.id]
            ) {
                selectedMessageId = this.props.taxReturn.documentSettings.deliverySettings.preparerMessage.id;
                selectedMessage =
                    this.props.savedMessages[this.props.taxReturn.documentSettings.deliverySettings.preparerMessage.id]
                        .messageDetails.body;
                isAllowToEdit =
                    this.props.savedMessages[this.props.taxReturn.documentSettings.deliverySettings.preparerMessage.id]
                        .messageDetails.isAllowToEdit;
            } else if (
                this.props.taxReturn.documentSettings.deliverySettings.preparerMessage &&
                this.props.taxReturn.documentSettings.deliverySettings.preparerMessage.id == 0 &&
                (this.props.taxReturn.documentSettings.deliverySettings.preparerMessage.body ||
                    this.props.taxReturn.documentSettings.deliverySettings.preparerMessage.body == "")
            ) {
                selectedMessage = this.props.taxReturn.documentSettings.deliverySettings.preparerMessage.body;
                isAllowToEdit = this.props.taxReturn.documentSettings.deliverySettings.preparerMessage.isAllowToEdit;
            } else if (
                this.props.ssrSettings.defaultMessageSettings.savedMessage != 0 &&
                this.props.savedMessages[this.props.ssrSettings.defaultMessageSettings.savedMessage] &&
                this.props.savedMessages[this.props.ssrSettings.defaultMessageSettings.savedMessage].messageDetails
            ) {
                selectedMessageId = this.props.ssrSettings.defaultMessageSettings.savedMessage;
                selectedMessage =
                    this.props.savedMessages[this.props.ssrSettings.defaultMessageSettings.savedMessage].messageDetails.body;
                isAllowToEdit =
                    this.props.savedMessages[this.props.ssrSettings.defaultMessageSettings.savedMessage].messageDetails
                        .isAllowToEdit;
            } else if (
                this.props.savedMessages[parseInt(Object.keys(this.props.savedMessages)[0])] &&
                this.props.savedMessages[parseInt(Object.keys(this.props.savedMessages)[0])].messageDetails
            ) {
                selectedMessageId =
                    this.props.savedMessages[parseInt(Object.keys(this.props.savedMessages)[0])].messageDetails.id;
                selectedMessage =
                    this.props.savedMessages[parseInt(Object.keys(this.props.savedMessages)[0])].messageDetails.body;
                isAllowToEdit =
                    this.props.savedMessages[parseInt(Object.keys(this.props.savedMessages)[0])].messageDetails.isAllowToEdit;
            }
        }
        this.setState({
            selectedMessage: selectedMessageId,
            isAllowToEdit: isAllowToEdit
        });
        const values = this.state.message.map((x: any) => x.value);
        if (!values.includes(selectedMessageId) && selectedMessage !== "") {
            this.setState({ customPlaceHolderPreparerMessage: "Custom message" });
        }
        if (selectedMessage != "" && this.props.taxReturn.documentSettings.deliverySettings.preparerMessage) {
            this.props.taxReturn.documentSettings.deliverySettings.preparerMessage.body = selectedMessage;
            this.props.taxReturn.documentSettings.deliverySettings.preparerMessage.id = selectedMessageId;
        }
    };

    private setPaperFileMessageDropDown = () => {
        let _paperFileMessage: SelectOptions[] = [];

        Object.keys(this.props.paperFileMessage).map((key, index) => {
            _paperFileMessage.push({
                value: this.props.paperFileMessage[parseInt(key)]?.messageDetails.id,
                label: this.props.paperFileMessage[parseInt(key)]?.messageDetails.name
            });
        });
        let defaultPaperFileMessage = this.props.taxReturn.documentSettings.deliverySettings.paperFilePreparerMessage
            ? this.props.taxReturn.documentSettings.deliverySettings.paperFilePreparerMessage.id
            : this.props.ssrSettings.defaultMessageSettings.paperFiledMessage;
        let selectedMessageValue: number = 0;
        let isAllowToEdit: boolean = false;
        if (
            this.props.taxReturn.documentSettings.deliverySettings.paperFilePreparerMessage &&
            this.props.taxReturn.documentSettings.deliverySettings.paperFilePreparerMessage.id == 0
        ) {
            selectedMessageValue = this.props.taxReturn.documentSettings.deliverySettings.paperFilePreparerMessage.id;
            isAllowToEdit = this.props.taxReturn.documentSettings.deliverySettings.paperFilePreparerMessage.isAllowToEdit;
        } else {
            selectedMessageValue =
                this.props.paperFileMessage[defaultPaperFileMessage] === undefined
                    ? this.props.paperFileMessage[parseInt(Object.keys(this.props.paperFileMessage)[0])]?.messageDetails.id
                    : this.props.paperFileMessage[defaultPaperFileMessage]?.messageDetails.id;
            isAllowToEdit =
                this.props.paperFileMessage[defaultPaperFileMessage] === undefined
                    ? this.props.paperFileMessage[parseInt(Object.keys(this.props.paperFileMessage)[0])]?.messageDetails
                          .isAllowToEdit
                    : this.props.paperFileMessage[defaultPaperFileMessage]?.messageDetails.isAllowToEdit;
        }

        this.setState({
            paperFileMessages: _paperFileMessage
        });
    };

    private setPaperFilePreparerMessage = () => {
        let selectedMessageId = 0;
        let selectedMessage = "";
        let isAllowToEdit: boolean = false;
        if (this.props.paperFileMessage) {
            if (
                this.props.taxReturn.documentSettings.deliverySettings.paperFilePreparerMessage &&
                this.props.taxReturn.documentSettings.deliverySettings.paperFilePreparerMessage.id != 0 &&
                this.props.paperFileMessage[this.props.taxReturn.documentSettings.deliverySettings.paperFilePreparerMessage.id]
            ) {
                selectedMessageId = this.props.taxReturn.documentSettings.deliverySettings.paperFilePreparerMessage.id;
                selectedMessage =
                    this.props.paperFileMessage[
                        this.props.taxReturn.documentSettings.deliverySettings.paperFilePreparerMessage.id
                    ].messageDetails.body;
                isAllowToEdit =
                    this.props.paperFileMessage[
                        this.props.taxReturn.documentSettings.deliverySettings.paperFilePreparerMessage.id
                    ].messageDetails.isAllowToEdit;
            } else if (
                this.props.taxReturn.documentSettings.deliverySettings.paperFilePreparerMessage &&
                this.props.taxReturn.documentSettings.deliverySettings.paperFilePreparerMessage.id == 0 &&
                this.props.taxReturn.documentSettings.deliverySettings.paperFilePreparerMessage.body
            ) {
                selectedMessage = this.props.taxReturn.documentSettings.deliverySettings.paperFilePreparerMessage.body;
                isAllowToEdit = this.props.taxReturn.documentSettings.deliverySettings.paperFilePreparerMessage.isAllowToEdit;
            } else if (
                this.props.ssrSettings.defaultMessageSettings.paperFiledMessage != 0 &&
                this.props.paperFileMessage[this.props.ssrSettings.defaultMessageSettings.paperFiledMessage] &&
                this.props.paperFileMessage[this.props.ssrSettings.defaultMessageSettings.paperFiledMessage].messageDetails
            ) {
                selectedMessageId = this.props.ssrSettings.defaultMessageSettings.paperFiledMessage;
                selectedMessage =
                    this.props.paperFileMessage[this.props.ssrSettings.defaultMessageSettings.paperFiledMessage].messageDetails
                        .body;
                isAllowToEdit =
                    this.props.paperFileMessage[this.props.ssrSettings.defaultMessageSettings.paperFiledMessage].messageDetails
                        .isAllowToEdit;
            } else if (
                this.props.paperFileMessage[parseInt(Object.keys(this.props.paperFileMessage)[0])] &&
                this.props.paperFileMessage[parseInt(Object.keys(this.props.paperFileMessage)[0])].messageDetails
            ) {
                selectedMessageId =
                    this.props.paperFileMessage[parseInt(Object.keys(this.props.paperFileMessage)[0])].messageDetails.id;
                selectedMessage =
                    this.props.paperFileMessage[parseInt(Object.keys(this.props.paperFileMessage)[0])].messageDetails.body;
                isAllowToEdit =
                    this.props.paperFileMessage[parseInt(Object.keys(this.props.paperFileMessage)[0])].messageDetails
                        .isAllowToEdit;
            }
        }
        this.setState({
            selectedPaperFileMessage: selectedMessageId,
            isPaperFileMessageAllowToEdit: isAllowToEdit
        });
        const values = this.state.paperFileMessages.map((x: any) => x.value);
        if (!values.includes(selectedMessageId) && selectedMessage !== "") {
            this.setState({ customPlaceHolderPaperfileMessage: "Custom message" });
        }
        if (selectedMessage != "" && this.props.taxReturn.documentSettings.deliverySettings.paperFilePreparerMessage) {
            this.props.taxReturn.documentSettings.deliverySettings.paperFilePreparerMessage.body = selectedMessage;
        }
    };

    setContactDropdown() {
        var index = Enumerable.from(this.props.inactiveUsers).indexOf(
            (x) => x.id == this.props.taxReturn.documentSettings.deliverySettings.contactPerson
        );
        var _contactPerson: any = [];
        for (let i = 0; i < this.props.inactiveUsers.length; i++) {
            _contactPerson.push({
                value: this.props.inactiveUsers[i] ? this.props.inactiveUsers[i].id : 0,
                label: this.props.inactiveUsers[i]
                    ? this.props.inactiveUsers[i].firstName + " " + this.props.inactiveUsers[i].lastName
                    : ""
            });
        }
        this.setState(
            {
                contactPerson: _contactPerson,
                selectedContactPerson: this.props.inactiveUsers[index] ? this.props.inactiveUsers[index].id : 0
            },
            () => this.setSenderDropdown()
        );
    }

    setSenderDropdown() {
        let index: number;
        if (this.props.taxReturn.documentSettings.deliverySettings.sender.senderType == DefaultSenderInfoType.User) {
            if (this.props.taxReturn.documentSettings.deliverySettings.sender.senderId !== null) {
                index =
                    Enumerable.from(this.props.inactiveUsers).indexOf(
                        (x) => x.id === this.props.taxReturn.documentSettings.deliverySettings.sender.senderId
                    ) + 1;
            } else {
                index = -1;
            }
        } else {
            index = 0;
        }
        var _sender: any = [];
        _sender.push({
            value: 0,
            label: this.props.company.companyProfile.companyInfo.companyName
        });
        for (let i = 0; i < this.props.inactiveUsers.length; i++) {
            _sender.push({
                value: this.props.inactiveUsers[i] ? this.props.inactiveUsers[i].id : 0,
                label: this.props.inactiveUsers[i]
                    ? this.props.inactiveUsers[i].firstName + " " + this.props.inactiveUsers[i].lastName
                    : ""
            });
        }
        this.setState(
            {
                sender: _sender
            },
            () => (
                this.setState({
                    selectedSender: index >= 0 ? this.state.sender[index].value : 0
                }),
                this.setNotifyUser()
            )
        );
    }

    setNotifyUser() {
        var index = this.props.users.findIndex((x) => x.id == this.props.taxReturn.documentSettings.deliverySettings.notifyUser);
        var _sender = [];
        for (let i = 0; i < this.props.users.length; i++) {
            _sender.push({
                value: this.props.users[i] ? this.props.users[i].id : 0,
                label: this.props.users[i] ? this.props.users[i].firstName + " " + this.props.users[i].lastName : ""
            });
        }
        this.setState(
            {
                notifyUser: _sender,
                selectedNotifyUser: this.props.users[index] ? this.props.users[index].id : 0
            },
            () => this.setPaymentNotifyUser()
        );
    }

    setPaymentNotifyUser() {
        var index = this.props.users.findIndex(
            (x) => x.id == this.props.taxReturn.documentSettings.deliverySettings.voucherPaymentNotifyUser
        );
        var _sender = [];
        for (let i = 0; i < this.props.users.length; i++) {
            _sender.push({
                value: this.props.users[i] ? this.props.users[i].id : 0,
                label: this.props.users[i] ? this.props.users[i].firstName + " " + this.props.users[i].lastName : ""
            });
        }
        this.setState({
            paymentNotifyUser: _sender,
            selectedPaymentNotifyUser: this.props.users[index] ? this.props.users[index].id : 0
        });
    }

    SetReminderOptions() {
        let taxReturn: TaxDocument.ITaxReturn = this.props.taxReturn;
        var reminderOptions = [];
        for (var i = 1; i <= 30; i++) {
            reminderOptions.push({ value: i, label: i === 1 ? i + " day" : i + " days" });
        }
        let noOfDaysForVoucherReminder: number =
            this.props.taxReturn?.documentSettings?.documentNotificationSetting.paymentVoucherNotificationSettingsModel
                .noOfDaysForVoucherReminder;
        let noOfDaysForSigningReminder: number =
            this.props.taxReturn?.documentSettings?.documentNotificationSetting.signingReminderNotificationSettingsModel
                .noOfDaysForSigningReminder;
        if (TaxDocument.isK1ShareHolder(taxReturn)) {
            let noOfDaysForK1ClientReminder: number =
                this.props.taxReturn.documentSettings.documentK1Setting.k1ClientInfoSettings.noOfDays;
            let noOfDaysForK1PartnerReminder: number =
                this.props.taxReturn.documentSettings.documentK1Setting.k1PartnerInfoSettings.noOfDays;
            this.setState({
                selectedK1CleintReminder: noOfDaysForK1ClientReminder,
                selectedK1PartnerReminder: noOfDaysForK1PartnerReminder,
                isK1ShareHolder: true
            });
        }
        let k1ReminderOptions = this.getReminderOptions();
        this.setState({
            reminderOptions: reminderOptions,
            selectedReminder: noOfDaysForVoucherReminder,
            selectedSingingReminder: noOfDaysForSigningReminder,
            signingReminderOptions: reminderOptions,
            k1ReminderOptions: k1ReminderOptions
        });
    }

    getReminderOptions() {
        var reminderOpions = [];
        for (let i = 1; i <= 11; i++) {
            reminderOpions.push({
                value: i * 30, //default number of days per month is considered as 30
                label: i === 1 ? i + " Month" : i + " Months"
            });
        }
        for (let i = 1; i <= 2; i++) {
            reminderOpions.push({
                value: i * 12 * 30, //default number of days per month is considered as 30
                label: i === 1 ? i + " Year" : i + " Years"
            });
        }
        return reminderOpions;
    }

    private handleCompanyToggleSwitchChange = (event: any) => {
        this.props.updateTPOpted(event.target.checked);
    };

    private handleTaxpayerToggleSwitchChange = (event: any) => {
        this.props.updateTPOpted(event.target.checked);
    };

    private handleSpouseToggleSwitchChange = (event: any) => {
        this.props.updateSpouseOpted(event.target.checked);
    };

    private handleVoucherToggleSwitchChange = (event: any) => {
        if (this.props.taxReturn && this.props.taxReturn.documentSettings) {
            this.props.taxReturn.documentSettings.documentNotificationSetting.paymentVoucherNotificationSettingsModel.enablePaymentVoucherReminder =
                event.target.checked;
            this.forceUpdate();
        }
    };

    private handleSigningToggleSwitchChange = (event: any) => {
        if (this.props.taxReturn && this.props.taxReturn.documentSettings) {
            this.props.taxReturn.documentSettings.documentNotificationSetting.signingReminderNotificationSettingsModel.enableSigningReminder =
                event.target.checked;
            this.forceUpdate();
        }
    };

    private handleVoucherReminderChange = (selectedOption: any) => {
        if (this.props.taxReturn && this.props.taxReturn.documentSettings) {
            this.props.taxReturn.documentSettings.documentNotificationSetting.paymentVoucherNotificationSettingsModel.noOfDaysForVoucherReminder =
                selectedOption;
        }

        this.setState({
            selectedReminder: selectedOption
        });
    };

    private handleSigningReminderChange = (selectedOption: any) => {
        if (this.props.taxReturn && this.props.taxReturn.documentSettings) {
            this.props.taxReturn.documentSettings.documentNotificationSetting.signingReminderNotificationSettingsModel.noOfDaysForSigningReminder =
                selectedOption;
        }
        this.setState({
            selectedSingingReminder: selectedOption
        });
    };

    private handleK1PartnerReminderChange = (selectedOption: any) => {
        if (
            this.props.taxReturn.documentSettings.documentK1Setting &&
            this.props.taxReturn.documentSettings.documentK1Setting.k1PartnerInfoSettings
        )
            this.props.taxReturn.documentSettings.documentK1Setting.k1PartnerInfoSettings.noOfDays = selectedOption;
        else
            this.props.taxReturn.documentSettings.documentK1Setting = {
                k1ClientInfoSettings: { noOfDays: this.state.selectedK1CleintReminder, k1MailCycle: K1MailCycles.After },
                k1PartnerInfoSettings: { noOfDays: selectedOption, k1MailCycle: K1MailCycles.After }
            };
        this.setState({
            selectedK1PartnerReminder: selectedOption
        });
    };

    private handleK1ClientReminderChange = (selectedOption: any) => {
        if (
            this.props.taxReturn.documentSettings.documentK1Setting &&
            this.props.taxReturn.documentSettings.documentK1Setting.k1ClientInfoSettings
        )
            this.props.taxReturn.documentSettings.documentK1Setting.k1ClientInfoSettings.noOfDays = selectedOption;
        else
            this.props.taxReturn.documentSettings.documentK1Setting = {
                k1ClientInfoSettings: { noOfDays: selectedOption, k1MailCycle: K1MailCycles.After },
                k1PartnerInfoSettings: { noOfDays: this.state.selectedK1PartnerReminder, k1MailCycle: K1MailCycles.After }
            };
        this.setState({
            selectedK1CleintReminder: selectedOption
        });
    };

    private handleSignatureChange = (event: any, signatureType: SignatureType) => {
        if (signatureType == SignatureType.ESign) {
            this.props.taxReturn.documentSettings.documentSignatureSetting.signatureFormSelectionType = signatureType;
        } else {
            this.props.taxReturn.documentSettings.documentSignatureSetting.signatureFormSelectionType = signatureType;
        }
        this.props.handleSignatureOptionToggle(event);
        this.forceUpdate();
    };

    private handleMailingAddressChange = (value: any) => {
        this.props.taxReturn.documentSettings.deliverySettings.manualAddressId = value;
        this.setState({ selectedMailingAddress: value }, () => {
            this.props.updateTaxDocument(this.props.taxReturn);
        });
    };

    private handleWatermarkChange = (selectedOption: any) => {
        this.props.taxReturn.documentSettings.deliverySettings.watermarkId = selectedOption;
        this.setState({
            selectedWatermark: selectedOption
        });
    };

    private setWatermarkDropdown = () => {
        var _watermarkOptions: SelectOptions[] = [];
        _watermarkOptions.push({ value: 0, label: "None" });
        this.props.watermarkList.sort((w) => {
            return w.isDefault ? -1 : 1;
        });
        Object.keys(this.props.watermarkList).map((key, index) => {
            _watermarkOptions.push({
                value: this.props.watermarkList[parseInt(key)].id,
                label: this.props.watermarkList[parseInt(key)].name
            });
        });

        var defaultWatermarkId = 0;
        if (!this.props.companySettings.displaySettingsModel.isWatermarkEnabled) {
            this.props.taxReturn.documentSettings.deliverySettings.watermarkId = 0;
        } else {
            defaultWatermarkId = this.props.taxReturn.documentSettings.deliverySettings.watermarkId;
        }
        this.setState({
            watermarkOptions: _watermarkOptions,
            selectedWatermark: defaultWatermarkId ? defaultWatermarkId : 0
        });
    };

    private setCustomColumnOptions = () => {
        let _customColOptions: SelectOptions[] = [];
        _customColOptions.push({ value: 0, label: "None" });
        if (this.props.customColumnData != null) {
            let _selectedColumnValue = null;
            Object.keys(this.props.customColumnData.customColumnValues).map((key, index) => {
                let currColValue = this.props.customColumnData.customColumnValues[parseInt(key)];
                if (currColValue.id == this.props.taxReturn.documentSettings.documentDisplaySetting.customColumn.id) {
                    _selectedColumnValue = currColValue;
                }
                _customColOptions.push({
                    value: currColValue.id,
                    label: currColValue.value
                });
            });
            if (_selectedColumnValue == null) {
                this.setCustomColumnValueInTaxReturn(0);
            }
            this.setState({
                customColumnOptions: _customColOptions,
                selectedCustomColumn: _selectedColumnValue
                    ? this.props.taxReturn.documentSettings.documentDisplaySetting.customColumn.id
                    : 0
            });
        }
    };

    private handleCustomColumnChange = (selectedOption: any) => {
        this.setCustomColumnValueInTaxReturn(selectedOption);
        this.setState({
            selectedCustomColumn: selectedOption
        });
    };

    private setCustomColumnValueInTaxReturn = (selectedId: any) => {
        let customColValue: IColumnValues;
        let reqCols = this.props.customColumnData.customColumnValues.filter((i) => i.id == selectedId);
        if (reqCols && reqCols.length > 0) {
            customColValue = reqCols[0];
        } else {
            customColValue = { id: 0, value: "", customColumn: 0, inUseCount: 0 };
        }
        this.props.handleCustomColumnChange(customColValue);
    };

    private setMailingAddressDropdown = () => {
        var _mailingAddressesOptions: SelectOptions[] = [];
        Object.keys(this.props.mailingReturnAddressList).map((key, index) => {
            _mailingAddressesOptions.push({
                value: this.props.mailingReturnAddressList[parseInt(key)].id,
                label: this.props.mailingReturnAddressList[parseInt(key)].title
            });
        });

        let locationId = 0;
        if (!this.props.taxReturn.groupId || this.props.taxReturn.groupId === 0) {
            let primaryOfficeLocationId = this.props.mailingReturnAddressList.filter((x) => x.isPrimaryOfficeLocation)[0].id;

            locationId =
                this.props.taxReturn.locationId === undefined || this.props.taxReturn.locationId === null
                    ? primaryOfficeLocationId
                    : this.props.taxReturn.locationId;
        } else {
            let defaultMailingAddress = this.props.userSettings.settings.defaultSettings.manualAddressId;
            let documentManualAddressId = this.props.taxReturn.documentSettings.deliverySettings.manualAddressId;

            locationId =
                documentManualAddressId === null || documentManualAddressId === 0
                    ? defaultMailingAddress
                    : documentManualAddressId;
        }
        this.setState(
            {
                mailingAddressOptions: _mailingAddressesOptions
            },
            () => {
                this.setState(
                    {
                        selectedMailingAddress: locationId
                    },
                    () => {
                        logger.trackTrace(
                            `TabDeliveryOptions.setMailingAddressDropdown excuted for for document: ${this.props.taxReturn.documentGuid}`,
                            {
                                DocumentId: this.props.taxReturn?.id?.toString(),
                                DocumentGuid: this.props.taxReturn?.documentGuid,
                                taxDocumentManualAddressId: this.state.selectedMailingAddress,
                                selectedMailingAddress: this.state.selectedMailingAddress
                            }
                        );
                        this.props.taxReturn.documentSettings.deliverySettings.manualAddressId =
                            this.state.selectedMailingAddress;
                    }
                );
            }
        );
    };

    preparerMessageHide() {
        this.setState({
            preparerMessageShow: false
        });
    }
    onEditButtonClick() {
        this.setState({
            preparerMessageShow: true,
            prepareMessageMode: PrepareMessageMode.Update,
            prepareMessage: this.props.taxReturn.documentSettings.deliverySettings.preparerMessage.body
        });
    }
    onAddButtonClick() {
        this.setState({
            preparerMessageShow: true,
            prepareMessageMode: PrepareMessageMode.Add,
            prepareMessage: ""
        });
    }
    onChangeTinyMCE(value: any) {
        this.setState({
            prepareMessage: value
        });
    }
    preparerMessageUpdateButtonClick(event: any) {
        if (
            this.state.prepareMessage == "" ||
            this.state.prepareMessage
                .replace(/\&nbsp;/g, "")
                .replace(/<\/?p[^>]*>/g, "")
                .trim() == ""
        ) {
            VenusNotifier.Error(Constants.SavedMessageConstants.ValidateBody, "Error");
            return;
        } else if (this.state.prepareMessage != "") {
            this.props.taxReturn.documentSettings.deliverySettings.preparerMessage.body = this.state.prepareMessage
                ? this.state.prepareMessage
                : "";
            this.props.taxReturn.documentSettings.deliverySettings.preparerMessage.name = this.state.prepareMessage
                ? this.state.prepareMessage
                : "";
            VenusNotifier.Success(Constants.SavedMessageConstants.UpdateMessageSuccess, "Success");
        }
        this.setState({
            preparerMessageShow: false
        });
    }

    savePreparerMessage(event: any) {
        let newMessage = this.state.prepareMessage;
        if (
            newMessage == "" ||
            newMessage
                .replace(/\&nbsp;/g, "")
                .replace(/<\/?p[^>]*>/g, "")
                .trim() == ""
        ) {
            VenusNotifier.Error(Constants.SavedMessageConstants.ValidateBody, "Error");
            return;
        } else if (newMessage != "") {
            this.props.taxReturn.documentSettings.deliverySettings.preparerMessage = {
                id: 0,
                name: newMessage,
                body: this.state.prepareMessage,
                isAllowToEdit: true
            } as MessageDetails;
            VenusNotifier.Success(Constants.SavedMessageConstants.SaveMessageSuccess, "Success");
        }
        this.setState({
            preparerMessageShow: false,
            prepareMessage: "",
            selectedMessage: 0,
            customPlaceHolderPreparerMessage: "Custom message",
            isAllowToEdit: true
        });
    }

    public handleRetentionPeriodChange(value: number) {
        let taxReturn: TaxDocument.ITaxReturn = this.props.taxReturn;
        taxReturn.documentSettings.documentRetentionSetting.retentionPeriod = value;
        this.setState({ retentionDuration: value });
    }

    private handleAttestClientChange = (event: any) => {
        let compSettings: ICompanySettings = this.props.companySettings;
        let taxReturn: TaxDocument.ITaxReturn = this.props.taxReturn;
        let retentionPeriod: number = event.target.checked
            ? compSettings.retentionSettingsModel.attestRetentionPeriod
            : compSettings.retentionSettingsModel.retentionPeriod;
        taxReturn.documentSettings.documentRetentionSetting.retentionPeriod = retentionPeriod;
        if (TaxDocument.isIndividual(taxReturn) || TaxDocument.isMutual(taxReturn)) {
            taxReturn.taxpayer.isModified = true;
            taxReturn.taxpayer.additionalInfo.isAttested = event.target.checked;
        } else if (TaxDocument.isPartnership(taxReturn)) {
            taxReturn.partnership.isModified = true;
            taxReturn.partnership.additionalInfo.isAttested = event.target.checked;
        }
        this.setState({ retentionDuration: retentionPeriod, isAttested: event.target.checked });
    };

    handleKBADisableConfirmationModalOpen = (event: any) => {
        if (event.target.checked) {
            this.setState({ showReturnLevelKBADisablingConfirmationModal: true });
        } else {
            this.props.taxReturn.documentSettings.documentSignatureSetting.knowledgeBasedAuthentication = true;
            this.props.taxReturn.documentSettings.documentSignatureSetting.isKBADisabledInReturnLevel = false;
            this.setState({ isKBADisableChecked: false });
        }
    };

    handleKBADisableConfirmationModalContinue = () => {
        this.props.taxReturn.documentSettings.documentSignatureSetting.knowledgeBasedAuthentication = false;
        this.props.taxReturn.documentSettings.documentSignatureSetting.isKBADisabledInReturnLevel = true;
        this.setState({ isKBADisableChecked: true });
        this.handleKBADisableConfirmationModalClose();
    };

    handleKBADisableConfirmationModalClose = () => {
        this.setState({ showReturnLevelKBADisablingConfirmationModal: false });
    };

    handleDelegateeKbaAuthenticationChange = (event: any) => {
        if (this.props.taxReturn.documentSettings.documentSignatureSetting.enableSignerDeligation) {
            this.props.taxReturn.documentSettings.documentSignatureSetting.enableDelegateeKbaAuthentication =
                event.target.checked;
            this.setState({ isDelegateeKBAChanged: true });
        }
    };

    handleSignerDelegationChange = (event: any) => {
        this.props.taxReturn.documentSettings.documentSignatureSetting.enableSignerDeligation = event.target.checked;
        if (!event.target.checked) {
            this.props.taxReturn.documentSettings.documentSignatureSetting.enableDelegateeKbaAuthentication = false;
        }
        this.setState({ isSignerDelegationChanged: true });
    };

    private handlePaperFileMessageChange = (value: any) => {
        let paperFileMessage = this.props.taxReturn.documentSettings.deliverySettings.paperFilePreparerMessage;
        if (!value) {
            this.props.taxReturn.documentSettings.deliverySettings.paperFilePreparerMessage = {
                id: 0,
                name: "",
                body: "",
                isAllowToEdit: false
            };
            this.setState({
                selectedPaperFileMessage: 0,
                customPlaceHolderPaperfileMessage: "Select..."
            });
            return;
        }

        if (value !== paperFileMessage.id) {
            paperFileMessage.id = value;
            let selectedMessageBody =
                paperFileMessage && paperFileMessage.id == 0
                    ? paperFileMessage.body
                    : this.props.paperFileMessage[value]
                    ? this.props.paperFileMessage[value].messageDetails.body
                    : "";
            if (value) {
                paperFileMessage.name = this.props.paperFileMessage[value]
                    ? this.props.paperFileMessage[value].messageDetails.name
                    : "";
                paperFileMessage.body = selectedMessageBody;
                this.setState({
                    selectedPaperFileMessage: value,
                    isPaperFileMessageAllowToEdit:
                        paperFileMessage && paperFileMessage.id == 0
                            ? paperFileMessage.isAllowToEdit
                            : this.props.paperFileMessage[value]
                            ? this.props.paperFileMessage[value].messageDetails.isAllowToEdit
                            : true
                });
            }
            paperFileMessage.body = htmlencoder.htmlDecode(paperFileMessage.body);
            this.props.taxReturn.documentSettings.deliverySettings.paperFilePreparerMessage = paperFileMessage;
        }
    };

    onAddPaperFileButtonClick = () => {
        this.setState({
            paperFileMessageShow: true,
            paperFileMessageMode: PrepareMessageMode.Add,
            paperFileMessage: ""
        });
    };

    onEditPaperFileButtonClick = () => {
        this.setState({
            paperFileMessageShow: true,
            paperFileMessageMode: PrepareMessageMode.Update,
            paperFileMessage: this.props.taxReturn.documentSettings.deliverySettings.paperFilePreparerMessage.body
        });
    };

    onPaperFileChangeTinyMCE = (value: any) => {
        this.setState({
            paperFileMessage: value
        });
    };

    paperFileMessageHide = () => {
        this.setState({
            paperFileMessageShow: false
        });
    };

    paperFileMessageUpdateButtonClick = (event: any) => {
        let paperMessage = this.state.paperFileMessage;
        if (
            paperMessage == "" ||
            paperMessage
                .replace(/\&nbsp;/g, "")
                .replace(/<\/?p[^>]*>/g, "")
                .trim() == ""
        ) {
            VenusNotifier.Error(Constants.SavedMessageConstants.ValidateBody, "Error");
            return;
        } else if (paperMessage != "") {
            this.props.taxReturn.documentSettings.deliverySettings.paperFilePreparerMessage.body = paperMessage
                ? paperMessage
                : "";
            this.props.taxReturn.documentSettings.deliverySettings.paperFilePreparerMessage.name = paperMessage
                ? paperMessage
                : "";
            VenusNotifier.Success(Constants.SavedMessageConstants.UpdateMessageSuccess, "Success");
        }
        this.setState({
            paperFileMessageShow: false
        });
    };

    savePaperFileMessage = (event: any) => {
        let newMessage = this.state.paperFileMessage;
        if (
            newMessage == "" ||
            newMessage
                .replace(/\&nbsp;/g, "")
                .replace(/<\/?p[^>]*>/g, "")
                .trim() == ""
        ) {
            VenusNotifier.Error(Constants.SavedMessageConstants.ValidateBody, "Error");
            return;
        } else if (newMessage != "") {
            this.props.taxReturn.documentSettings.deliverySettings.paperFilePreparerMessage = {
                id: 0,
                name: newMessage,
                body: this.state.paperFileMessage,
                isAllowToEdit: true
            } as MessageDetails;
            VenusNotifier.Success(Constants.SavedMessageConstants.SaveMessageSuccess, "Success");
        }
        this.setState({
            paperFileMessageShow: false,
            paperFileMessage: "",
            selectedPaperFileMessage: 0,
            customPlaceHolderPaperfileMessage: "Custom message",
            isPaperFileMessageAllowToEdit: true
        });
    };

    public render() {
        let messageBody: string = this.props.taxReturn.documentSettings.deliverySettings.preparerMessage.body;
        messageBody = messageBody ? messageBody : "";
        let paperFileMessageBody: string = this.props.taxReturn.documentSettings.deliverySettings.paperFilePreparerMessage.body;
        paperFileMessageBody = paperFileMessageBody ? paperFileMessageBody : "";

        let clientType = isPartnership(this.props.taxReturn)
            ? ClientTypes.PartnerShip
            : isMutual(this.props.taxReturn) && this.props.taxReturn.taxpayer.isDeceased
            ? ClientTypes.Spouse
            : ClientTypes.Taxpayer;
        let enableSignatureOption: boolean = true;
        let customColumnPresent: boolean = true;

        let signatureType: any = this.props.taxReturn.documentSettings?.documentSignatureSetting.signatureFormSelectionType;
        signatureType = typeof signatureType == "number" ? SignatureType[signatureType] : signatureType;

        if (
            isBusinessReturn(this.props.taxReturn) &&
            !this.props.companySettings.signatureSettingsModel.enableEsignatureForBusinessReturns &&
            signatureType == SignatureType[SignatureType.ManualSign] &&
            !this.props.isSignatureoptionsToggledInDeliveryOption
        ) {
            enableSignatureOption = false;
        }

        let deceasedCount = 0;

        if (this.props.taxReturn && TaxDocument.isMutual(this.props.taxReturn)) {
            if (this.props.taxReturn.taxpayer.isDeceased) {
                deceasedCount += 1;
            }
            if (this.props.taxReturn.spouse && this.props.taxReturn.spouse.isDeceased) {
                deceasedCount += 1;
            }
        }
        let customColumnTitle = "";
        if (this.props.customColumnData == null || this.props.customColumnData.header == "") {
            customColumnPresent = false;
        } else {
            customColumnTitle = "Custom Column: " + this.props.customColumnData.header;
        }

        return (
            <TabContentContainer enableScroll={true}>
                <LoadingOverlay style={{ height: "100%" }}>
                    <Col sm={12}>
                        <div className="content-box">
                            <Row>
                                <Col sm={12}>
                                    <div className="col-sm-3" style={{ display: "inline-flex", marginRight: 5, marginLeft: -15 }}>
                                        <h4 className="header-delivery-options" style={{ marginRight: 5 }}>
                                            <i className="modalIcon fas fa-user-circle" style={{ marginRight: 7 }}></i>
                                            Taxpayer Dashboard
                                        </h4>
                                    </div>
                                    <Button
                                        type="button"
                                        style={{ marginTop: 5, marginLeft: 4 }}
                                        variant="info"
                                        onClick={() => {
                                            this.props.generateTaxPayerView(clientType);
                                            logger.trackTrace(
                                                `Taxpayer Preview for document: ${this.props.taxReturn.documentGuid}`,
                                                {
                                                    DocumentId: this.props.taxReturn?.id?.toString(),
                                                    DocumentGuid: this.props.taxReturn?.documentGuid
                                                }
                                            );
                                        }}
                                        disabled={this.props.disabledTaxpayerPreview}
                                        data-test-auto="1BF09CF9-CEB8-45E6-9025-33DA6D26693B"
                                    >
                                        <i
                                            className={
                                                this.props.disabledTaxpayerPreview
                                                    ? "fas fa-spinner fa-spin text-white bg-transparent"
                                                    : "fas fa-eye"
                                            }
                                            aria-hidden="true"
                                        ></i>
                                        Taxpayer Preview
                                    </Button>
                                </Col>
                                <Col sm={12}>
                                    <hr style={{ marginTop: "10px" }} />
                                    <div className="form-horizontal">
                                        {(!this.props.taxReturn.groupId || this.props.taxReturn.groupId === 0) && (
                                            <Row className="marB15">
                                                <Col className="text-left" sm={3} as={FormLabel}>
                                                    Group Name:
                                                </Col>

                                                <Col sm={3} className={"zeroborderRadius"}>
                                                    <DatalistFormatter
                                                        id="ddlPRGroup"
                                                        disableDropdown={false}
                                                        Groups={this.props.groups}
                                                        defaultGroup={this.props.selectedGroup.name}
                                                        onGroupChange={this.props.onGroupChange}
                                                        data-test-auto="88C41574-99E4-4559-9B8C-D3BA6F4D1964"
                                                    />
                                                </Col>
                                                <Col sm={1}>
                                                    <i
                                                        className="help-icon fas fa-question-circle fontS15"
                                                        data-toggle="tooltip"
                                                        title={Constants.GroupNameInfoConstants.GroupNameInfo}
                                                    ></i>
                                                </Col>
                                            </Row>
                                        )}
                                        <Row className="marB15">
                                            <Col className="text-left" sm={3} as={FormLabel}>
                                                Contact Person:
                                            </Col>

                                            <Col sm={3} className={"zeroborderRadius"}>
                                                <DropdownComponent
                                                    id="ddlContactPerson"
                                                    options={this.state.contactPerson}
                                                    onChange={this.handleContactPersonChange}
                                                    selectedValue={this.state.selectedContactPerson}
                                                    disabled={!this.props.isAssignedToLoggedinUser}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="text-left" sm={3} as={FormLabel}>
                                                Message From Preparer:
                                            </Col>

                                            <Col sm={3} className={"zeroborderRadius"}>
                                                <DropdownComponent
                                                    id="ddlPreparer"
                                                    options={this.state.message}
                                                    onChange={this.handleMessageChange}
                                                    customPlaceHolder={this.state.customPlaceHolderPreparerMessage}
                                                    selectedValue={this.state.selectedMessage}
                                                    disabled={!this.props.isAssignedToLoggedinUser}
                                                />
                                            </Col>
                                            <Col sm={3}>
                                                <Button
                                                    type="button"
                                                    title="Add New Message"
                                                    variant="success"
                                                    data-test-auto="2599FBD9-2565-45DB-AF1F-7250D3DB45F7"
                                                    style={{ paddingRight: 20, width: 76 }}
                                                    onClick={this.onAddButtonClick}
                                                >
                                                    <i className="fa fa-plus" style={{ paddingRight: 10, width: 25.5 }}></i>
                                                    New
                                                </Button>
                                            </Col>
                                        </Row>
                                        <Row style={{ paddingTop: "10px", paddingBottom: "10px" }}>
                                            <Col sm={3}></Col>
                                            <Col sm={3}>
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: htmlUtilities.sanitize(
                                                            ReplaceHelperVariable(htmlencoder.htmlDecode(messageBody))
                                                        )
                                                    }}
                                                    className="selected-message-view"
                                                    style={{ padding: 9 }}
                                                ></div>
                                            </Col>
                                            <Col sm={3}>
                                                <Button
                                                    type="button"
                                                    title={this.state.isAllowToEdit ? "Edit Message" : "View Message"}
                                                    variant="info"
                                                    style={{ paddingRight: 20, width: 76 }}
                                                    data-test-auto="2599FBD9-2565-45DB-AF1F-7250D3DB45F7"
                                                    onClick={this.onEditButtonClick}
                                                >
                                                    {this.state.isAllowToEdit ? (
                                                        <i className="fas fa-edit" aria-hidden="true"></i>
                                                    ) : (
                                                        <i className="fas fa-eye" aria-hidden="true"></i>
                                                    )}
                                                    {this.state.isAllowToEdit ? "Edit" : "View"}
                                                </Button>
                                            </Col>
                                        </Row>

                                        {this.props.companySettings.displaySettingsModel.isEnablePaperFiled &&
                                            this.props.taxReturn.paperFiles.length > 0 && (
                                                <div>
                                                    <Row>
                                                        <Col className="text-left" sm={3} as={FormLabel}>
                                                            Paper File Instructions:
                                                        </Col>
                                                        <Col sm={3} className={"zeroborderRadius"}>
                                                            <DropdownComponent
                                                                id="ddlPaperFile"
                                                                options={this.state.paperFileMessages}
                                                                onChange={this.handlePaperFileMessageChange}
                                                                customPlaceHolder={this.state.customPlaceHolderPaperfileMessage}
                                                                selectedValue={this.state.selectedPaperFileMessage}
                                                                disabled={!this.props.isAssignedToLoggedinUser}
                                                            />
                                                        </Col>
                                                        <Col sm={3}>
                                                            <Button
                                                                type="button"
                                                                title="Add New Paper File"
                                                                variant="success"
                                                                data-test-auto="611CFC69-ECE3-430F-AA68-7DA85239CC25"
                                                                style={{ paddingRight: 20, width: 76 }}
                                                                onClick={this.onAddPaperFileButtonClick}
                                                            >
                                                                <i className="fa fa-plus" style={{ paddingRight: 10 }}></i>
                                                                New
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                    <Row style={{ paddingTop: 10 }}>
                                                        <Col sm={3}></Col>
                                                        <Col sm={3}>
                                                            <div
                                                                dangerouslySetInnerHTML={{
                                                                    __html: htmlUtilities.sanitize(
                                                                        ReplaceHelperVariable(
                                                                            htmlencoder.htmlDecode(paperFileMessageBody)
                                                                        )
                                                                    )
                                                                }}
                                                                className="selected-message-view"
                                                                style={{ padding: 9 }}
                                                                data-test-auto="6335D6A3-ECD9-431F-B437-AB91BFE61945"
                                                            ></div>
                                                        </Col>
                                                        <Col sm={3}>
                                                            <Button
                                                                type="button"
                                                                title={
                                                                    this.state.isPaperFileMessageAllowToEdit
                                                                        ? "Edit Paper File"
                                                                        : "View Paper File"
                                                                }
                                                                variant="info"
                                                                style={{ paddingRight: "inherit", width: 76, marginLeft: 2 }}
                                                                data-test-auto="53D41AE7-7933-4A5C-A028-AD7B3422CA05"
                                                                onClick={this.onEditPaperFileButtonClick}
                                                                disabled={paperFileMessageBody.length == 0}
                                                            >
                                                                <i className="fas fa-edit" aria-hidden="true"></i>
                                                                {this.state.isPaperFileMessageAllowToEdit ? "Edit" : "View"}
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            )}
                                    </div>
                                </Col>
                            </Row>
                            {this.props.companySettings.displaySettingsModel.isWatermarkEnabled && (
                                <Row style={{ marginTop: "30px" }}>
                                    <Col sm={12} className="new-main-content">
                                        <h4>
                                            <span
                                                className="modalIcon watermarkIconwidth fa-stack fa-2x"
                                                style={{ fontSize: "12px" }}
                                            >
                                                <i className="fa fa-file fa-stack-2x"></i>
                                                <i className="fa fa-tint fa-stack-1x fa-inverse" style={{ bottom: "-3px" }}></i>
                                            </span>
                                            Tax Return Watermarks{" "}
                                        </h4>
                                        <hr style={{ marginTop: "-2px" }} />
                                    </Col>
                                    <Col sm={12}>
                                        <Row>
                                            <Col className="padT07" sm={3} as={FormLabel}>
                                                Watermark Name
                                            </Col>

                                            <Col sm={3} className={"zeroborderRadius"}>
                                                <DropdownComponent
                                                    id="ddlWatermark"
                                                    options={this.state.watermarkOptions}
                                                    onChange={this.handleWatermarkChange}
                                                    selectedValue={this.state.selectedWatermark}
                                                    clearable={true}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            )}
                            {customColumnPresent ? (
                                <Row style={{ marginTop: "30px" }}>
                                    <Col sm={12} className="new-main-content">
                                        <h4>
                                            <span className="modalIcon">
                                                <i>
                                                    <img
                                                        src={require("../../../../assets/images/logos/column-select.svg").default}
                                                        style={{ height: "20px" }}
                                                    />
                                                </i>
                                            </span>
                                            {customColumnTitle}{" "}
                                        </h4>
                                        <hr style={{ marginTop: "-2px" }} />
                                    </Col>
                                    <Col sm={12}>
                                        <Row>
                                            <Col className="padT07" sm={3} as={FormLabel}>
                                                Column Value
                                            </Col>

                                            <Col sm={3} className={"zeroborderRadius"}>
                                                <DropdownComponent
                                                    id="ddlCustomColumn"
                                                    options={this.state.customColumnOptions}
                                                    onChange={this.handleCustomColumnChange}
                                                    selectedValue={this.state.selectedCustomColumn}
                                                    clearable={true}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            ) : null}

                            <Row style={{ marginTop: "30px" }}>
                                <Col sm={12} className="new-main-content">
                                    <h4 className="header-delivery-options">
                                        <i className="modalIcon fas fa-envelope"></i>
                                        Email Notifications
                                    </h4>
                                    <hr style={{ marginTop: -2 }} />
                                </Col>
                                <Col sm={12}>
                                    <div className="form-horizontal">
                                        {TaxDocument.isMutual(this.props.taxReturn) && (
                                            <Row>
                                                <Col className="text-left" sm={3} as={FormLabel}>
                                                    Deliver First to:
                                                </Col>
                                                <Col sm={3}>
                                                    <FormGroup style={{ marginLeft: 5 }}>
                                                        <RadioButtonComponent
                                                            text="Taxpayer"
                                                            style={{ marginRight: 20 }}
                                                            id="TaxPayer"
                                                            name="TaxPayer"
                                                            data-test-auto="97521163-5EE4-42BA-949A-74B885444731"
                                                            checked={
                                                                this.props.taxReturn?.documentSettings?.deliverySettings
                                                                    .deliverTo === ClientTypes.Taxpayer
                                                            }
                                                            onChange={this.handleDeliverFirstToChange}
                                                            disabled={
                                                                (deceasedCount != 2 &&
                                                                    this.props.taxReturn.taxpayer.isDeceased) ||
                                                                !this.props.isAssignedToLoggedinUser
                                                            }
                                                        />
                                                        <RadioButtonComponent
                                                            text="Spouse"
                                                            id="Spouse"
                                                            name="Spouse"
                                                            data-test-auto="6182B06E-E58E-4DB6-80E1-FAD1A69FE8AE"
                                                            checked={
                                                                this.props.taxReturn?.documentSettings?.deliverySettings
                                                                    .deliverTo === ClientTypes.Spouse
                                                            }
                                                            onChange={this.handleDeliverFirstToChange}
                                                            disabled={
                                                                (deceasedCount != 2 && this.props.taxReturn.spouse.isDeceased) ||
                                                                !this.props.isAssignedToLoggedinUser
                                                            }
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        )}
                                        <Row className="marB15">
                                            <Col className="text-left" sm={3} as={FormLabel}>
                                                Sender Name:
                                            </Col>
                                            <Col sm={3} className={"zeroborderRadius"}>
                                                <DropdownComponent
                                                    id="ddlSender"
                                                    options={this.state.sender}
                                                    onChange={this.handleSenderChange}
                                                    selectedValue={this.state.selectedSender}
                                                    disabled={!this.props.isAssignedToLoggedinUser}
                                                />
                                            </Col>
                                        </Row>
                                        <Row
                                            className={
                                                this.props.companySettings.notificationSettingsModel
                                                    .paymentVoucherNotificationSettings.paymentNotification
                                                    ? "marB15"
                                                    : ""
                                            }
                                        >
                                            <Col className="text-left" sm={3} as={FormLabel}>
                                                Notify About Signing Events:
                                            </Col>
                                            <Col sm={3} className={"zeroborderRadius"}>
                                                <DropdownComponent
                                                    id="ddlSigningEvents"
                                                    options={this.state.notifyUser}
                                                    onChange={this.handleNotifyUserChange}
                                                    selectedValue={this.state.selectedNotifyUser}
                                                />
                                            </Col>
                                        </Row>
                                        {this.props.companySettings.notificationSettingsModel.paymentVoucherNotificationSettings
                                            .paymentNotification ? (
                                            <Row>
                                                <Col className="text-left" sm={3} as={FormLabel}>
                                                    <div style={{ display: "flex" }}>
                                                        Notify About Payment Activity:
                                                        <Link
                                                            to={"#"}
                                                            className="help-icon upload-help"
                                                            data-placement="right"
                                                            data-toggle="tooltip"
                                                            title="SafeSend Returns is not a financial institution and cannot verify any transfer of funds. When the client adds a payment they are doing so manually."
                                                        >
                                                            <i className="fas fa-question-circle" />
                                                        </Link>
                                                    </div>
                                                </Col>
                                                <Col sm={3} className={"zeroborderRadius"}>
                                                    <DropdownComponent
                                                        id="ddlPaymentEvents"
                                                        options={this.state.paymentNotifyUser}
                                                        onChange={this.handlePaymentNotifyUserChange}
                                                        selectedValue={this.state.selectedPaymentNotifyUser}
                                                        disabled={!this.props.isAssignedToLoggedinUser}
                                                    />
                                                </Col>
                                            </Row>
                                        ) : null}
                                    </div>
                                </Col>
                            </Row>

                            {this.props.showSMSTextNotifications && (
                                <Row style={{ marginTop: "30px" }}>
                                    <Col sm={12} className="new-main-content">
                                        <h4 className="header-delivery-options">
                                            <i className="modalIcon fas fa-comment"></i>
                                            SMS Text Notifications
                                        </h4>
                                        <hr style={{ marginTop: -2 }} />
                                    </Col>
                                    <Col sm={12}>
                                        {TaxDocument.isPartnership(this.props.taxReturn) ? (
                                            <Row>
                                                <Col className="padT07" sm={1} as={FormLabel}>
                                                    Company
                                                </Col>
                                                <Col sm={1} style={{ paddingTop: "2px" }}>
                                                    <ToggleSwitch
                                                        switched={this.props.isTPOpted}
                                                        handleChange={this.handleCompanyToggleSwitchChange}
                                                        disabled={
                                                            !this.props.smsNotificationDetails.isSuiteSMSNotificationSetting ||
                                                            !this.props.smsNotificationDetails.isTwilioTaxPayerSMSOptedIn
                                                        }
                                                        className={
                                                            !this.props.smsNotificationDetails.isSuiteSMSNotificationSetting ||
                                                            !this.props.smsNotificationDetails.isTwilioTaxPayerSMSOptedIn
                                                                ? "disable"
                                                                : ""
                                                        }
                                                        dataTestChecked={this.props.isTPOpted}
                                                        dataTestAuto="d471b1e4-8464-44ed-b676-5734e63b8943"
                                                    />
                                                </Col>
                                                {(!this.props.smsNotificationDetails.isSuiteSMSNotificationSetting ||
                                                    !this.props.smsNotificationDetails.isTwilioTaxPayerSMSOptedIn) && (
                                                    <Link
                                                        to={"#"}
                                                        style={{ marginLeft: "-12px", marginTop: "3px" }}
                                                        className="help-icon marL05 col-sm-1"
                                                        data-placement="right"
                                                        data-toggle="tooltip"
                                                        title={
                                                            !this.props.smsNotificationDetails.isSuiteSMSNotificationSetting
                                                                ? "To change, enable SMS notification at the Suite level."
                                                                : !this.props.smsNotificationDetails.isTwilioTaxPayerSMSOptedIn
                                                                ? "Client can opt-in again by replying START."
                                                                : ""
                                                        }
                                                    >
                                                        <i className="fas fa-question-circle"></i>
                                                    </Link>
                                                )}
                                            </Row>
                                        ) : (
                                            <>
                                                <Row>
                                                    <Col className="padT07" sm={1} as={FormLabel}>
                                                        Taxpayer
                                                    </Col>
                                                    <Col sm={1} style={{ paddingTop: "2px" }}>
                                                        <ToggleSwitch
                                                            switched={this.props.isTPOpted}
                                                            handleChange={this.handleTaxpayerToggleSwitchChange}
                                                            disabled={
                                                                !this.props.smsNotificationDetails
                                                                    .isSuiteSMSNotificationSetting ||
                                                                !this.props.smsNotificationDetails.isTwilioTaxPayerSMSOptedIn
                                                            }
                                                            className={
                                                                !this.props.smsNotificationDetails
                                                                    .isSuiteSMSNotificationSetting ||
                                                                !this.props.smsNotificationDetails.isTwilioTaxPayerSMSOptedIn
                                                                    ? "disable"
                                                                    : ""
                                                            }
                                                            dataTestChecked={this.props.isTPOpted}
                                                            dataTestAuto="09b2774c-dbe4-45f8-a17d-a602ebe72278"
                                                        />
                                                    </Col>
                                                    {(!this.props.smsNotificationDetails.isSuiteSMSNotificationSetting ||
                                                        !this.props.smsNotificationDetails.isTwilioTaxPayerSMSOptedIn) && (
                                                        <Link
                                                            to={"#"}
                                                            style={{ marginLeft: "-12px", marginTop: "3px" }}
                                                            className="help-icon marL05 col-sm-1"
                                                            data-placement="right"
                                                            data-toggle="tooltip"
                                                            title={
                                                                !this.props.smsNotificationDetails.isSuiteSMSNotificationSetting
                                                                    ? "To change, enable SMS notification at the Suite level."
                                                                    : !this.props.smsNotificationDetails
                                                                          .isTwilioTaxPayerSMSOptedIn
                                                                    ? "Client can opt-in again by replying START."
                                                                    : ""
                                                            }
                                                        >
                                                            <i className="fas fa-question-circle"></i>
                                                        </Link>
                                                    )}
                                                </Row>
                                                {TaxDocument.isMutual(this.props.taxReturn) && (
                                                    <Row>
                                                        <Col className="padT07" sm={1} as={FormLabel}>
                                                            Spouse
                                                        </Col>
                                                        <Col sm={1} style={{ paddingTop: "2px" }}>
                                                            <ToggleSwitch
                                                                switched={this.props.isSpouseOpted}
                                                                handleChange={this.handleSpouseToggleSwitchChange}
                                                                disabled={
                                                                    !this.props.smsNotificationDetails
                                                                        .isSuiteSMSNotificationSetting ||
                                                                    !this.props.smsNotificationDetails.isTwilioSpouseSMSOptedIn
                                                                }
                                                                className={
                                                                    !this.props.smsNotificationDetails
                                                                        .isSuiteSMSNotificationSetting ||
                                                                    !this.props.smsNotificationDetails.isTwilioSpouseSMSOptedIn
                                                                        ? "disable"
                                                                        : ""
                                                                }
                                                                dataTestChecked={this.props.isSpouseOpted}
                                                                dataTestAuto="7aab5bd3-ecfa-40e7-b281-1983fe008cf4"
                                                            />
                                                        </Col>
                                                        {(!this.props.smsNotificationDetails.isSuiteSMSNotificationSetting ||
                                                            !this.props.smsNotificationDetails.isTwilioSpouseSMSOptedIn) && (
                                                            <Link
                                                                to={"#"}
                                                                style={{ marginLeft: "-12px", marginTop: "3px" }}
                                                                className="help-icon marL05 col-sm-1"
                                                                data-placement="right"
                                                                data-toggle="tooltip"
                                                                title={
                                                                    !this.props.smsNotificationDetails
                                                                        .isSuiteSMSNotificationSetting
                                                                        ? "To change, enable SMS notification at the Suite level."
                                                                        : !this.props.smsNotificationDetails
                                                                              .isTwilioSpouseSMSOptedIn
                                                                        ? "Client can opt-in again by replying START."
                                                                        : ""
                                                                }
                                                            >
                                                                <i className="fas fa-question-circle"></i>
                                                            </Link>
                                                        )}
                                                    </Row>
                                                )}
                                            </>
                                        )}
                                    </Col>
                                </Row>
                            )}

                            <Row style={{ marginTop: "30px" }}>
                                <Col sm={12} style={{ paddingTop: "8px" }} className="new-main-content">
                                    <h4>
                                        <i className="modalIcon fas fa-bell" /> Reminders{" "}
                                    </h4>
                                    <hr style={{ marginTop: "-2px" }} />
                                </Col>

                                <Col sm={12}>
                                    <Row>
                                        <Col className="padT07" sm={2} as={FormLabel}>
                                            Send Voucher Reminder before
                                        </Col>

                                        <Col sm={3} className={"zeroborderRadius"}>
                                            <DropdownComponent
                                                id="ddlRemind"
                                                options={this.state.reminderOptions}
                                                onChange={this.handleVoucherReminderChange}
                                                selectedValue={this.state.selectedReminder}
                                                clearable={true}
                                                disabled={
                                                    !this.props.taxReturn?.documentSettings?.documentNotificationSetting
                                                        .paymentVoucherNotificationSettingsModel.enablePaymentVoucherReminder
                                                }
                                            />
                                        </Col>

                                        <Col sm={2} className="padT07" as={FormLabel}>
                                            from due date
                                        </Col>
                                        <Col sm={1} style={{ paddingTop: "5px" }}>
                                            <ToggleSwitch
                                                switched={
                                                    this.props.taxReturn?.documentSettings?.documentNotificationSetting
                                                        .paymentVoucherNotificationSettingsModel.enablePaymentVoucherReminder
                                                }
                                                handleChange={this.handleVoucherToggleSwitchChange}
                                            />
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col className="padT07" sm={2} as={FormLabel}>
                                            Send Signing Reminder after
                                        </Col>

                                        <Col sm={3} className={"zeroborderRadius"}>
                                            <DropdownComponent
                                                id="ddlsigningReminder"
                                                options={this.state.signingReminderOptions}
                                                onChange={this.handleSigningReminderChange}
                                                selectedValue={this.state.selectedSingingReminder}
                                                clearable={true}
                                                disabled={
                                                    !this.props.taxReturn?.documentSettings?.documentNotificationSetting
                                                        .signingReminderNotificationSettingsModel.enableSigningReminder
                                                }
                                            />
                                        </Col>

                                        <Col sm={2} className="padT07" as={FormLabel}>
                                            since last reminder/initial email
                                        </Col>
                                        <Col sm={1} style={{ paddingTop: "5px" }}>
                                            <ToggleSwitch
                                                switched={
                                                    this.props.taxReturn?.documentSettings?.documentNotificationSetting
                                                        .signingReminderNotificationSettingsModel.enableSigningReminder
                                                }
                                                handleChange={this.handleSigningToggleSwitchChange}
                                            />
                                        </Col>
                                    </Row>
                                    {/* Commented as a part US: 103985
                                 <Row className="marB15" style={{ display: !this.state.isK1ShareHolder ? "none" : "flex" }}>

                                    <Col className="padT07" sm={2} as={FormLabel}>
                                        Send K1 Partner Update Reminder after
                                    </Col>

                                    <Col sm={3} className={"zeroborderRadius"}>
                                        <DropdownComponent
                                            id="ddlK1PartnerReminder"
                                            options={this.state.k1ReminderOptions}
                                            onChange={this.handleK1PartnerReminderChange}
                                            selectedValue={this.state.selectedK1PartnerReminder}
                                            clearable={true}
                                        />
                                    </Col>

                                    <Col sm={2} className="padT07" as={FormLabel}>
                                        of Client's Year End
                                    </Col>
                                </Row>
                                <Row className="marB15" style={{ display: !this.state.isK1ShareHolder ? "none" : "flex" }}>

                                    <Col className="padT07" sm={2} as={FormLabel}>
                                        Send K1 Client Update Reminder after
                                    </Col>

                                    <Col sm={3} className={"zeroborderRadius"}>
                                        <DropdownComponent
                                            id="ddlK1ClientReminder"
                                            options={this.state.k1ReminderOptions}
                                            onChange={this.handleK1ClientReminderChange}
                                            selectedValue={this.state.selectedK1CleintReminder}
                                            clearable={true}
                                        />
                                    </Col>

                                    <Col sm={2} className="padT07" as={FormLabel}>
                                        of Client's Year End
                                    </Col>
                                </Row> */}
                                </Col>
                            </Row>
                            <Row style={{ marginTop: "30px" }}>
                                <Col sm={12} style={{ paddingTop: "8px" }} className="new-main-content">
                                    <h4>
                                        <i className="modalIcon fas fa-trash-alt" /> Modify Retention Period{" "}
                                    </h4>
                                    <hr style={{ marginTop: "-2px" }} />
                                </Col>

                                <Col sm={12}>
                                    <Row>
                                        <Col className="padT07" sm={3} as={FormLabel}>
                                            Please select the retention period for the document
                                        </Col>

                                        <Col sm={3} className={"zeroborderRadius marB15"}>
                                            <YearAndMonth
                                                id="DocRetention"
                                                controlValue={this.state.retentionDuration}
                                                onControlValueChange={this.handleRetentionPeriodChange}
                                                componentEnabled={this.state.allowOverride}
                                                years={7}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="padT07" sm={3}></Col>
                                        <Col sm={3} className={"zeroborderRadius"}>
                                            <CheckBoxComponent
                                                id="chkAttestClient"
                                                checked={this.state.isAttested}
                                                onChange={this.handleAttestClientChange}
                                                text="Attest Client"
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            {enableSignatureOption ? (
                                <Row style={{ marginTop: "30px" }}>
                                    <Col sm={12} className="new-main-content">
                                        <h4>
                                            {" "}
                                            <i className="modalIcon fas fa-pen-nib" /> Signature Options
                                        </h4>
                                        <hr style={{ marginTop: "-4px" }} />

                                        <Row>
                                            <Col className="text-left" sm={2} as={FormLabel}>
                                                <RadioButtonComponent
                                                    text="E-Sign"
                                                    id="eSign"
                                                    data-test-auto="EA4DE573-44E1-4D8D-9B4E-0BC92A00E06C"
                                                    checked={
                                                        signatureType === SignatureType[SignatureType.ESign] ||
                                                        (signatureType ==
                                                            SignatureType[SignatureType.ESignWhenAllIncludedReturnsAllowed] &&
                                                            this.props.isEsign == false)
                                                            ? true
                                                            : false
                                                    }
                                                    onChange={(event) => {
                                                        this.handleSignatureChange(event, SignatureType.ESign);
                                                    }}
                                                    disabled={
                                                        !this.props.isAssignedToLoggedinUser ||
                                                        deceasedCount == 2 ||
                                                        (TaxDocument.isIndividual(this.props.taxReturn)
                                                            ? this.props.taxReturn.taxpayer.isDeceased
                                                            : false)
                                                    }
                                                ></RadioButtonComponent>
                                            </Col>

                                            <Col className="text-left" sm={2} style={{ maxWidth: "200px", minWidth: "190px" }}>
                                                <RadioButtonComponent
                                                    text="Manual Sign and Return"
                                                    id="manualSign"
                                                    data-test-auto="6FBE97EF-59D7-414E-B4EC-12C93B75D34C"
                                                    checked={
                                                        signatureType == SignatureType[SignatureType.ManualSign] ||
                                                        (signatureType ==
                                                            SignatureType[SignatureType.ESignWhenAllIncludedReturnsAllowed] &&
                                                            this.props.isEsign == true)
                                                            ? true
                                                            : false
                                                    }
                                                    //onChange={(event) => { () => this.handleSignatureChange(event, SignatureType.ManualSign) }}
                                                    onChange={(event) => {
                                                        this.handleSignatureChange(event, SignatureType.ManualSign);
                                                    }}
                                                    disabled={!this.props.isAssignedToLoggedinUser}
                                                ></RadioButtonComponent>
                                            </Col>
                                            <Link
                                                to={"#"}
                                                style={{ marginLeft: "-13px", paddingLeft: "1px" }}
                                                className="help-icon marL05 col-sm-1"
                                                data-placement="right"
                                                data-toggle="tooltip"
                                                title="If selected, taxpayer must print and manually sign signature forms."
                                            >
                                                <i className="fas fa-question-circle"></i>
                                            </Link>
                                        </Row>

                                        <Row className="dispBlock">
                                            {(this.state.isKBADisableChecked ||
                                                this.props.taxReturn.documentSettings?.documentSignatureSetting
                                                    .allowDisablingKBAOnReturnLevel ||
                                                this.props.companySettings.signatureSettingsModel
                                                    .allowDisablingKBAOnReturnLevel) &&
                                                this.props.taxReturn.engagementType.toString() ==
                                                    TaxDocument.EngagementType[TaxDocument.EngagementType.E1040] && (
                                                    <Col style={{ paddingLeft: "0px" }}>
                                                        <div className="kba-settings">
                                                            <CheckBoxComponent
                                                                id={"disableKBA"}
                                                                text={"Disable KBA for Esign of this Document"}
                                                                onChange={this.handleKBADisableConfirmationModalOpen}
                                                                checked={
                                                                    this.props.taxReturn?.documentSettings
                                                                        ?.documentSignatureSetting
                                                                        ? !this.props.taxReturn.documentSettings
                                                                              .documentSignatureSetting
                                                                              .knowledgeBasedAuthentication &&
                                                                          this.props.taxReturn?.documentSettings
                                                                              ?.documentSignatureSetting
                                                                              .isKBADisabledInReturnLevel
                                                                        : false
                                                                }
                                                                datatestAuto={"C4EA8166-BFBB-4011-93E4-3306C26273A8"}
                                                            />

                                                            <Link
                                                                to={"#"}
                                                                className="help-icon marL05"
                                                                data-placement="right"
                                                                data-toggle="tooltip"
                                                                title={fetchHelpText(
                                                                    this.props.helptexts,
                                                                    "2B80C5E8-E43C-4C2C-A160-B6173A951F3D"
                                                                )}
                                                            >
                                                                <i className="fas fa-question-circle"></i>
                                                            </Link>
                                                        </div>
                                                    </Col>
                                                )}
                                            {(this.props.taxReturn.documentSettings?.documentSignatureSetting
                                                .enableSignerDeligation ||
                                                this.state.isSignerDelegationChanged ||
                                                (this.props.companySettings.signatureSettingsModel
                                                    .enableEntityReturnSignerDeligation &&
                                                    this.props.taxReturn.engagementType.toString() !=
                                                        TaxDocument.EngagementType[TaxDocument.EngagementType.E1040]) ||
                                                (this.props.companySettings.signatureSettingsModel
                                                    .enableIndividualReturnSignerDeligation &&
                                                    this.props.taxReturn.engagementType.toString() ==
                                                        TaxDocument.EngagementType[TaxDocument.EngagementType.E1040])) && (
                                                <Col style={{ paddingLeft: "0px" }}>
                                                    <div className="delegation-settings">
                                                        <CheckBoxComponent
                                                            id={"signerDelegation"}
                                                            text={"Allow signer delegation"}
                                                            onChange={this.handleSignerDelegationChange}
                                                            checked={
                                                                this.props.taxReturn?.documentSettings?.documentSignatureSetting
                                                                    ? this.props.taxReturn.documentSettings
                                                                          .documentSignatureSetting.enableSignerDeligation
                                                                    : false
                                                            }
                                                            datatestAuto={"0D350954-4A6D-43CB-8F53-B50B455C19F5"}
                                                        />
                                                    </div>
                                                </Col>
                                            )}

                                            {((this.props.taxReturn.engagementType.toString() ==
                                                TaxDocument.EngagementType[TaxDocument.EngagementType.E1040] &&
                                                this.props.taxReturn.documentSettings?.documentSignatureSetting
                                                    .enableDelegateeKbaAuthentication) ||
                                                (this.props.taxReturn.engagementType.toString() ==
                                                    TaxDocument.EngagementType[TaxDocument.EngagementType.E1040] &&
                                                    this.props.companySettings.signatureSettingsModel
                                                        .enableDelegateeKbaAuthentication) ||
                                                this.state.isDelegateeKBAChanged) && (
                                                <Col style={{ paddingLeft: "0px" }}>
                                                    <div className="delegation-settings">
                                                        <CheckBoxComponent
                                                            id={"delegateeKbaAuthentication"}
                                                            text={
                                                                "Enable Knowledge-Based Authentication for delegated signers (i.e. children and individuals living abroad)"
                                                            }
                                                            onChange={this.handleDelegateeKbaAuthenticationChange}
                                                            checked={
                                                                this.props.taxReturn?.documentSettings?.documentSignatureSetting
                                                                    ? this.props.taxReturn.documentSettings
                                                                          .documentSignatureSetting
                                                                          .enableDelegateeKbaAuthentication
                                                                    : false
                                                            }
                                                            disabled={
                                                                !this.props.taxReturn?.documentSettings?.documentSignatureSetting
                                                                    .enableSignerDeligation
                                                            }
                                                            datatestAuto={"7E573F4C-09BE-4781-AF10-FD789D8C728C"}
                                                        />
                                                    </div>
                                                </Col>
                                            )}
                                        </Row>
                                    </Col>
                                </Row>
                            ) : null}

                            {this.props.mailingReturnAddressList && (
                                <Row style={{ marginTop: "30px" }}>
                                    <Col sm={12} className="new-main-content">
                                        <h4>
                                            {" "}
                                            <i className="modalIcon fas fa-map-marker-alt " /> Mailing Address
                                        </h4>
                                        <hr style={{ marginTop: "-2px" }} />
                                    </Col>

                                    <Col sm={12}>
                                        <Row>
                                            <Col className="padT07" sm={3} as={FormLabel}>
                                                Address:
                                            </Col>
                                            <Col sm={3} className={"zeroborderRadius"}>
                                                <DropdownComponent
                                                    id="ddlAddress"
                                                    options={this.state.mailingAddressOptions}
                                                    onChange={this.handleMailingAddressChange}
                                                    selectedValue={this.state.selectedMailingAddress}
                                                    disabled={!this.props.isAssignedToLoggedinUser}
                                                />
                                            </Col>
                                        </Row>

                                        <Row style={{ paddingTop: 10 }}>
                                            <Col sm={3} as={FormLabel}></Col>

                                            <Col sm={3}>
                                                <SelectedMailingAddressView
                                                    states={this.props.company.companyProfile.countryStates}
                                                    selectedAddressId={this.state.selectedMailingAddress}
                                                    mailingReturnAddress={
                                                        this.props.mailingReturnAddressList[this.state.selectedMailingAddress]
                                                    }
                                                    mailingReturnAddressList={this.props.mailingReturnAddressList}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            )}
                        </div>
                    </Col>
                    <KBADisableConfirmationModal
                        showReturnLevelKBADisablingConfirmationModal={this.state.showReturnLevelKBADisablingConfirmationModal}
                        onHide={this.handleKBADisableConfirmationModalClose}
                        onContinue={this.handleKBADisableConfirmationModalContinue}
                    />
                    {this.state.prepareMessageMode == PrepareMessageMode.Add ? (
                        <PreparerMessage
                            changeStateTinymceBody={this.onChangeTinyMCE}
                            onHide={this.preparerMessageHide}
                            showState={this.state.preparerMessageShow}
                            messageBody={this.state.prepareMessage}
                            isAllowEdit={true}
                            updateButtonClick={this.preparerMessageUpdateButtonClick}
                            saveButtonClick={this.savePreparerMessage}
                            buttonText={"Save"}
                        ></PreparerMessage>
                    ) : (
                        <PreparerMessage
                            changeStateTinymceBody={this.onChangeTinyMCE}
                            onHide={this.preparerMessageHide}
                            showState={this.state.preparerMessageShow}
                            messageBody={ReplaceHelperVariableForEditor(this.state.prepareMessage)}
                            isAllowEdit={this.state.isAllowToEdit}
                            updateButtonClick={this.preparerMessageUpdateButtonClick}
                            saveButtonClick={this.savePreparerMessage}
                            buttonText={"Update"}
                        ></PreparerMessage>
                    )}
                    {this.state.paperFileMessageMode == PrepareMessageMode.Add ? (
                        <PaperFileMessage
                            changeStateTinymceBody={this.onPaperFileChangeTinyMCE}
                            onHide={this.paperFileMessageHide}
                            showState={this.state.paperFileMessageShow}
                            messageBody={this.state.paperFileMessage}
                            isAllowEdit={true}
                            updateButtonClick={this.paperFileMessageUpdateButtonClick}
                            saveButtonClick={this.savePaperFileMessage}
                            buttonText={"Save"}
                        ></PaperFileMessage>
                    ) : (
                        <PaperFileMessage
                            changeStateTinymceBody={this.onPaperFileChangeTinyMCE}
                            onHide={this.paperFileMessageHide}
                            showState={this.state.paperFileMessageShow}
                            messageBody={ReplaceHelperVariableForEditor(this.state.paperFileMessage)}
                            isAllowEdit={this.state.isPaperFileMessageAllowToEdit}
                            updateButtonClick={this.paperFileMessageUpdateButtonClick}
                            saveButtonClick={this.savePaperFileMessage}
                            buttonText={"Update"}
                        ></PaperFileMessage>
                    )}
                    <Loader loading={this.props.isLoading} />
                </LoadingOverlay>
            </TabContentContainer>
        );
    }
}
//=============================================================================
