import * as React from "react";
import { Modal, Nav } from "react-bootstrap";
import * as Constants from "../helper/Constants";
import {
    ITaxReturn,
    IIndividualTaxReturn,
    EngagementType,
    TaxCaddyLookupResult,
    DocumentGroups,
    PaperReturnDeliveryType
} from "./TaxReturn";
import { ICompanySettings } from "../../Core/ViewModels/Company/CompanySettingsViewModel";
import { IGroupInfo } from "../../store/groupedreturns/GroupedReturnProcessState";
import { ControllerInfoProperty } from "../../store/groupedreturns/GroupedReturnProcessStore";
import { logger } from "../../routes/LoggedIn";
import { DeliverGroupToController } from "./DeliverGroupToController";
import { TabPrintForPaperDelivery } from "./GroupLevelReturnProcess/Tabs/TabPrintForPaperDelivery";
import { IOrderList } from "./Generic/ReorderList";
import { Loader, LoadingOverlay } from "react-overlay-loader";

export interface DeliverGroupProps {
    groupedTaxDocuments: ITaxReturn[];
    companySettings: ICompanySettings;
    deliverToController: () => void;
    onPrintForPaperDelivery: () => void;
    updateDocumentGroupOrder: (engagementType: EngagementType, order: DocumentGroups[]) => void;
    handleSaveAsDefaultOrderChange: (event: any) => void;
    isDefaultOrder: boolean;
    groupInfo: IGroupInfo;
    changeControllerInfo: (item: ControllerInfoProperty, value: any) => void;
    onChangePaperDeliveryType: (event: any, paperDeliveryType: PaperReturnDeliveryType) => void;
    onReorderList: (updatedList: IOrderList[]) => void;
    handleNotifyUserChange: (event: any) => void;
    isNotifyUser: boolean;
    showDeliverGroup: boolean;
    onCancel: () => void;
}
export interface DeliverGroupState {
    activeMenuItem: string;
    saving: boolean;
}

export class DeliverGroup extends React.Component<DeliverGroupProps, DeliverGroupState> {
    constructor(props: DeliverGroupProps) {
        super(props);
        this.state = {
            activeMenuItem: Constants.FinishProcessReturn.MenuItems.DeliverToController,
            saving: false
        };

        this.cancelFinish = this.cancelFinish.bind(this);
        this.isSaving = this.isSaving.bind(this);
    }

    public isSaving() {
        this.setState({ saving: true });
    }

    public cancelFinish() {
        document.body.click();
        this.setState({ activeMenuItem: "" });
        this.props.onCancel();
    }

    menuToggleOpen = (event: any) => {
        if (event.target.nextElementSibling.style["display"] == "none") {
            event.target.style["display"] = "none";
            event.target.nextElementSibling.style["display"] = "block";
            event.target.parentElement.nextElementSibling.style["display"] = "block";
        } else {
            event.target.style["display"] = "block";
            event.target.nextElementSibling.style["display"] = "none";
            event.target.parentElement.nextElementSibling.style["display"] = "none";
        }
    };

    menuToggleClose = (event: any) => {
        if (event.target.previousElementSibling.style["display"] == "none") {
            event.target.style["display"] = "none";
            event.target.previousElementSibling.style["display"] = "block";
            event.target.parentElement.nextElementSibling.style["display"] = "none";
        } else {
            event.target.style["display"] = "block";
            event.target.previousElementSibling.style["display"] = "none";
            event.target.parentElement.nextElementSibling.style["display"] = "block";
        }
    };

    onMenuClick(menuName: string) {
        switch (menuName) {
            case Constants.FinishProcessReturn.MenuItems.PaperDelivery:
                this.setState({ activeMenuItem: menuName });
                break;
            case Constants.FinishProcessReturn.MenuItems.DeliverToController:
                this.setState({ activeMenuItem: menuName });
                break;
        }
        logger.trackPageView(
            `${menuName} started for groupId: ${this.props.groupInfo.id?.toString()} and clientGuid: ${
                this.props.groupInfo.controllerInfo.clientGuid
            }`,
            { GroupId: this.props.groupInfo.id?.toString(), ClientGuid: this.props.groupInfo.controllerInfo.clientGuid }
        );
    }
    getMenuContent() {
        switch (this.state.activeMenuItem) {
            case Constants.FinishProcessReturn.MenuItems.DeliverToController:
            case Constants.FinishProcessReturn.MenuItems.Empty:
                return (
                    <div>
                        <DeliverGroupToController
                            groupedTaxDocuments={this.props.groupedTaxDocuments}
                            cancelFinish={this.cancelFinish}
                            deliverToController={this.props.deliverToController}
                            groupInfo={this.props.groupInfo}
                            changeControllerInfo={this.props.changeControllerInfo}
                            isSaving={this.isSaving}
                            extensionPopupCancelled={() => {
                                this.setState({ saving: false });
                            }}
                        />
                    </div>
                );

            case Constants.FinishProcessReturn.MenuItems.PaperDelivery:
                return (
                    <div>
                        <TabPrintForPaperDelivery
                            groupedTaxDocuments={this.props.groupedTaxDocuments}
                            cancelFinish={this.cancelFinish}
                            onPrintForPaperDelivery={this.props.onPrintForPaperDelivery}
                            updateDocumentGroupOrder={this.props.updateDocumentGroupOrder}
                            handleSaveAsDefaultOrderChange={this.props.handleSaveAsDefaultOrderChange}
                            isDefaultOrder={this.props.isDefaultOrder}
                            groupInfo={this.props.groupInfo}
                            onChangePaperDeliveryType={this.props.onChangePaperDeliveryType}
                            onReorderList={this.props.onReorderList}
                            handleNotifyUserChange={this.props.handleNotifyUserChange}
                            isNotifyUser={this.props.isNotifyUser}
                            isSaving={this.isSaving}
                        />
                    </div>
                );
        }
    }

    componentWillReceiveProps(nextProps: DeliverGroupProps) {
        const saving = !nextProps.showDeliverGroup ? false : this.state.saving;
        this.setState({
            saving: saving
        });
    }

    render() {
        return (
            <Modal className="deliver-group-modal" show={this.props.showDeliverGroup} onHide={this.props.onCancel}>
                <Modal.Header>
                    <Modal.Title className="custom-modal-header">Finish Return Processing</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {" "}
                    <LoadingOverlay style={{ height: "100%" }}>
                        <div className="full-height">
                            <div className="row height-inherit">
                                <div className="col-lg-12 height-inherit dispFlex">
                                    <div className="col-lg-4" style={{ paddingLeft: "0px", height: "98%" }}>
                                        <div className="nav nav-tabs tabs-left height-inherit" style={{ height: "100%" }}>
                                            <li className={"nav-item Delivery-dropdown-Menu "}>
                                                <span
                                                    className=" fa nav-link dropdown-toggle"
                                                    role="button"
                                                    style={{
                                                        backgroundColor: "#f7f7f7",
                                                        marginLeft: "-15px",
                                                        paddingBottom: "10px",
                                                        paddingTop: "10px",
                                                        display: "block"
                                                    }}
                                                    data-toggle="Delivery"
                                                    data-target="#Delivery-dropdown"
                                                    aria-haspopup="true"
                                                    aria-expanded="false"
                                                >
                                                    <span
                                                        onClick={this.menuToggleOpen}
                                                        className="fa fa-angle-right"
                                                        style={{ display: "none", float: "right", paddingLeft: "20px" }}
                                                    ></span>
                                                    <span
                                                        onClick={this.menuToggleClose}
                                                        className="fa fa-angle-down"
                                                        style={{ color: "grey", float: "right", paddingLeft: "20px" }}
                                                    ></span>
                                                    <span style={{ color: "#337ab7", fontSize: "16px" }}>Delivery</span>
                                                </span>

                                                <div className="Delivery nav nav-tabs tabs-left" id="Delivery-dropdown">
                                                    {
                                                        <li
                                                            className={
                                                                this.state.activeMenuItem ==
                                                                Constants.FinishProcessReturn.MenuItems.DeliverToController
                                                                    ? "active"
                                                                    : ""
                                                            }
                                                        >
                                                            <Nav.Link
                                                                href="javascript:"
                                                                onClick={() => {
                                                                    this.onMenuClick(
                                                                        Constants.FinishProcessReturn.MenuItems
                                                                            .DeliverToController
                                                                    );
                                                                }}
                                                            >
                                                                <i
                                                                    className="fa fa-paper-plane"
                                                                    style={{ paddingRight: "10px" }}
                                                                ></i>
                                                                {Constants.FinishProcessReturn.MenuItems.DeliverToController}
                                                            </Nav.Link>
                                                        </li>
                                                    }

                                                    {this.props.companySettings.deliverySettingsModel.isPrintToPDFEnabled && (
                                                        <li
                                                            className={
                                                                this.state.activeMenuItem ==
                                                                Constants.FinishProcessReturn.MenuItems.PaperDelivery
                                                                    ? "active"
                                                                    : ""
                                                            }
                                                        >
                                                            <Nav.Link
                                                                href="javascript:"
                                                                onClick={() => {
                                                                    this.onMenuClick(
                                                                        Constants.FinishProcessReturn.MenuItems.PaperDelivery
                                                                    );
                                                                }}
                                                            >
                                                                <i className="fa fa-print" style={{ paddingRight: "10px" }}></i>
                                                                {Constants.FinishProcessReturn.MenuItems.PaperDelivery}
                                                            </Nav.Link>
                                                        </li>
                                                    )}
                                                </div>
                                            </li>
                                        </div>
                                    </div>
                                    <div className="col-lg-8 height-inherit">{this.getMenuContent()}</div>
                                </div>
                            </div>
                        </div>
                        <Loader loading={this.state.saving} text={""} />
                    </LoadingOverlay>
                </Modal.Body>
            </Modal>
        );
    }
}
