import * as React from 'react';
import { PDFJSStatic } from 'pdfjs-dist';
import { IPdfDocumentFacade } from '../../../../Core/Utilities/PdfDocumentFacade';
import { IVoucherState } from '../../../../store/ProcessReturn/voucher/VoucherStore';
import {
    CustomType, DocumentGroups, initialVoucher,
    IFormBase, IVoucherFormBase, IGroup, ITaxingAuthority, ITaxReturn,
    IVoucher, VoucherNo, VoucherMode, VoucherTypes, EngagementType, VoucherStatus
} from '../../../common/TaxReturn';
import { UploadFunctions } from '../../../common/UploadDocument/UploadFunctions';
import { VoucherTabConstants } from '../../../helper/Constants';
import { cloneTaxReturn, getAuthorityData, getVoucherStatus } from '../../../helper/HelperFunctions';
import { VenusNotifier } from '../../../helper/VenusNotifier';
import { BookmarksPane } from '../BookmarksPane';
import { INVALID_PAGE } from '../CustomPagination/CustomPagination';
import { PdfPage } from '../PdfPage';
import { IFormGroupViewProps, IFormGroupViewState, ProcessReturnTabs } from '../ProcessReturnModels';
import { VoucherScript } from '../Voucher/VoucherScript';
import { ValidateWebsite } from '../../../helper/Validations';
import { VoucherEntry } from '../Voucher/VoucherEntry';
import { PageMoveTo } from './../PageMoveTo';
import { AwesomePdfViewer } from './../AwesomePdfViewer';
import moment from "moment";
import * as TaxDocument from '../../../common/TaxReturn';
import { generateBase64, PdfProperties } from '../../../../Core/ViewModels/Common/PdfHelper';
import { TaxReturnSettingsResources } from '../../../helper/ResourceIdConstants';
import { IVoucherTaxFilingDate } from '@Core/ViewModels/Voucher/VoucherDefaultDateViewModel';
import { filterTaxFilingDates } from '../../../helper/VoucherNameFilter';

const PDFJS: PDFJSStatic = require('pdfjs-dist');

interface IVoucherViewState extends IFormGroupViewState {
    voucher: IVoucher;
    isVoucherEdit: boolean;
    showLoader: boolean;
    showDeleteZeroAmountVoucherButton: boolean;
    zeroAmountVouchersCount: number | 0;
    addedVoucher: IVoucher;
    formName: string;
    isPaymentUrlSame: boolean;
    masterPaymentUrl: string;
    isVoucherUpdated: boolean;
    currentPageIxdex: number;
    scale: number;
}

interface IVoucherViewProps extends IFormGroupViewProps {
    getVoucherUploadLink: (taxReturn: ITaxReturn, callback: (data: any) => void) => void;
    getMultiVoucherUploadLink: (taxReturn: ITaxReturn, cbVoucher: IVoucher, callback: (data: any, voucher: IVoucher) => void, isFinalVoucher?: boolean) => void;
    voucherStore: IVoucherState;
    getAllTaxingAuthorities: ITaxingAuthority[];
    isEnableInvoice: boolean;
    saveTaxingAuthority: (taxingAuthority: ITaxingAuthority, callback?: () => void, callback2?: () => void, resourceId?: string) => void;
    formGroup: TaxDocument.IGroup;
    showVoucherLabel: boolean;
    isRevisionProcess?: boolean;
    updateMultipleVouchersAdd?: (isAddMultipleVoucers: number) => void;
}

const INVALID_INDEX: number = -1;
const isEqual = require("react-fast-compare");

export class TabVoucher extends React.Component<IVoucherViewProps, IVoucherViewState>{
    constructor(props: IVoucherViewProps) {
        super(props);
        this.state = {
            currentPage: INVALID_PAGE,
            currentPageIxdex: 0,
            actionCardShow: true,
            zoomEnabled: false,
            voucher: initialVoucher,
            isVoucherEdit: false,
            showLoader: false,
            showDeleteZeroAmountVoucherButton: false,
            zeroAmountVouchersCount: 0,
            addedVoucher: initialVoucher,
            formName: "",
            isPaymentUrlSame: false,
            masterPaymentUrl: "",
            isVoucherUpdated: false,
            scale: PdfProperties.DefaultScale
        };
        this.page = {} as PdfPage;
    }
    private page: PdfPage;

    UNSAFE_componentWillReceiveProps(nextProps: IVoucherViewProps) {
        if (nextProps.pages && nextProps.pages.length > 0) {
            if (this.state.isVoucherUpdated == true) {
                this.onPageSelect(nextProps.pages[nextProps.pages.findIndex(x => x == this.state.currentPage)]);
            }
            else {
                this.setState({ currentPage: nextProps.pages[this.state.currentPageIxdex] });
                if (this.props.taxReturn.formGroups.length) {
                    this.onPageSelect(nextProps.pages[this.state.currentPageIxdex]);
                };
                if (nextProps.pages && nextProps.pages.length == this.state.currentPageIxdex) {
                    this.onPageSelect(nextProps.pages[this.state.currentPageIxdex - 1]);
                }
            }
        }

        if (this.props.taxReturn.formGroups.length > 0) {
            let voucherIndex = this.props.taxReturn.formGroups
                .findIndex(x => x.group == DocumentGroups.Vouchers);
            if (voucherIndex > -1 && this.props.taxReturn.formGroups[voucherIndex]?.forms.length == 0) {
                this.setState({ voucher: initialVoucher });

            }
        }
    }

    shouldComponentUpdate(nextProps: IVoucherViewProps, nextState: IVoucherViewState) {
        return ((nextProps.pages !== this.props.pages) ||
            (this.state.currentPage === INVALID_PAGE) ||
            (!isEqual(this.state, nextState)));
    }

    componentDidMount() {
        if (this.props && this.props.pages) {
            this.setState(
                {
                    currentPageIxdex: 0,
                    currentPage: this.props.pages[0]
                });
            this.onPageSelect(this.props.pages[0]);

            const pages = this.getZeroAmountVoucherPages();
            pages && pages.length > 0 ? this.setState({ showDeleteZeroAmountVoucherButton: true, zeroAmountVouchersCount: pages.length })
                : this.setState({ showDeleteZeroAmountVoucherButton: false });
        }
    }

    private onAddVoucher = (voucher: IVoucher) => {
        this.setState({ voucher: voucher, showLoader: true }, () => { this.setState({ addedVoucher: { ...this.state.voucher } }) });
        this.props.getVoucherUploadLink(this.props.taxReturn,
            this.getVoucherUploadLinkCallback);
    }

    private onAddMultipleVouchers = (vouchers: IVoucher[]) => {
        vouchers.forEach((el, idx) => {
            if (el.amount > 0) {
                const authorities: ITaxingAuthority[] = this.props.getAllTaxingAuthorities;
                let taxingAuthority: ITaxingAuthority = authorities[authorities.findIndex(x => x.Id == el.authorityID)]
                if (taxingAuthority && taxingAuthority.CustomAuthority && el.customType === CustomType.OnlinePaymentRequired) {
                    taxingAuthority.PrefixStateCode = false;
                    taxingAuthority.PaymentURL = el.onlinePaymentUri;
                    this.props.saveTaxingAuthority(taxingAuthority, undefined,
                        undefined, TaxReturnSettingsResources.VoucherAndInvoicesPrefix +
                        TaxReturnSettingsResources.CustomAuthorities +
                    TaxReturnSettingsResources.ButtonAdd);
                }
                this.setState({ addedVoucher: el });
                this.props.getMultiVoucherUploadLink(this.props.taxReturn, el,
                    this.getMultipleVoucherUploadLinkCallback, true);
            }
        });
    }

    private onUpdateAddedVoucher = (voucher: IVoucher, currentVoucherClone: IVoucher, taxingAuthority: ITaxingAuthority) => {
        if (!this.validateVoucher(voucher)) {
            return;
        }
        voucher.onlinePaymentUri = voucher.customType !== CustomType.None ? voucher.onlinePaymentUri : "";
        let addedVoucherIndex: number = INVALID_INDEX;
        let voucherIndex = this.props.taxReturn?.formGroups?.findIndex(x => x.group == DocumentGroups.Vouchers);
        addedVoucherIndex = (this.props.taxReturn?.formGroups[voucherIndex]?.forms as IVoucherFormBase[])?.findIndex(x => x.pageNo[0] == currentVoucherClone.pageNo[0] && x.voucherNo == currentVoucherClone.voucherNo);

        if (addedVoucherIndex > INVALID_INDEX) {
            if (this.state.voucher.formName != voucher.formName &&
                this.state.voucher.amount == voucher.amount &&
                this.state.voucher.customType == voucher.customType &&
                this.state.voucher.authorityID == voucher.authorityID &&
                this.state.voucher.dueDate == voucher.dueDate &&
                this.state.voucher.voucherNo == voucher.voucherNo
            ) {
                var clone = cloneTaxReturn(this.props.taxReturn);
                this.updateVoucherStatus(this.state.voucher, voucher);
                clone.formGroups[voucherIndex].forms[addedVoucherIndex] = { ...voucher } as IVoucher;
                clone.formGroups[voucherIndex].forms[addedVoucherIndex].bookmark = voucher.vocherMode === VoucherMode.CustomVoucher ? this.setBookmarkForManuallyAddedVoucher(voucher.authorityID, voucher.formName) : voucher.formName;
                this.props.updateTaxDocument(clone);
            }
            else {
                if (voucher.customType !== undefined &&
                    voucher.customType !== CustomType.None && (voucher.vocherMode === VoucherMode.CustomVoucher ||
                        voucher.vocherMode === VoucherMode.AutomatedVoucher)) {
                    let slicedVoucher = this.props.taxReturn.formGroups[voucherIndex]?.forms.splice(addedVoucherIndex, 1);
                    let documentFacade: IPdfDocumentFacade =
                        (this.props.pdfDocuments[this.props.docId].document as IPdfDocumentFacade);

                    if (slicedVoucher && slicedVoucher[0].pageNo !== undefined)
                        this.onPageDelete(slicedVoucher[0].pageNo[0]);

                    if (!voucher.authorityImage || !voucher.authorityName) {
                        let authorityData: { name: string, image: string } =
                            getAuthorityData(voucher.authorityID, this.props.getAllTaxingAuthorities);

                        let editedVoucherState: IVoucherViewState = Object.assign({}, this.state);
                        editedVoucherState.voucher = voucher;
                        editedVoucherState.voucher.authorityImage = authorityData.image;
                        editedVoucherState.voucher.authorityName = authorityData.name;
                        this.setState(editedVoucherState);
                    }
                    this.checkIfVoucherAmountIsZero(voucher);
                    this.updateVoucherStatus(this.state.voucher, voucher);

                    this.props.getMultiVoucherUploadLink(this.props.taxReturn, voucher, this.getMultipleVoucherUploadLinkCallback);
                    this.setState({ addedVoucher: { ...voucher }, isVoucherUpdated: true });
                    VenusNotifier.Success(VoucherTabConstants.VoucherUpdateSuccess, "Success");
                } else {

                    var clone = { ...this.props.taxReturn };
                    this.updateVoucherStatus(this.state.voucher, voucher);
                    clone.formGroups[voucherIndex].forms[addedVoucherIndex] = { ...voucher } as IFormBase;
                    this.checkIfVoucherAmountIsZero(voucher);
                    this.setState({
                        isVoucherEdit: false,
                        isVoucherUpdated: true
                    }, () => this.props.updateTaxDocument(clone, this.setStateValue));
                    VenusNotifier.Success(VoucherTabConstants.VoucherUpdateSuccess, "Success");
                }
            }
            if (taxingAuthority && taxingAuthority.CustomAuthority && voucher.customType === CustomType.OnlinePaymentRequired) {
                taxingAuthority.PrefixStateCode = false;
                taxingAuthority.PaymentURL = voucher.onlinePaymentUri;
                this.props.saveTaxingAuthority(taxingAuthority);
            }
        }

    }

    private setStateValue = () => {
        this.setState({
            showLoader: false,
            isVoucherEdit: false,
            isVoucherUpdated: false
        });
    }

    public setBookmarkForManuallyAddedVoucher = (authorityID: number, formName: string) => {
        var authorities: ITaxingAuthority[] = this.props.getAllTaxingAuthorities;
        for (let i in authorities) {
            if (authorities[i].Id === authorityID) {
                return authorities[i].AuthorityName + ' ' + formName;
            }
        }
        return formName;
    }

    private UpdateZeroAmountBookMark = (voucher: IVoucher) => {
        const SEPARATOR = "###";
        this.props.bookmarks.map((bookMark) => {
            bookMark.pages.map((page: any) => {
                if (page.pageNo == voucher.pageNo && page.voucherNo == voucher.voucherNo) {
                    page.pageTitle = page.pageTitle + SEPARATOR + '$' + voucher.amount + '.00'
                }
            })
        });

    }

    updateVoucherStatus = (oldVoucher: IVoucher, updatedVoucher: IVoucher) => {
        if (this.props.showVoucherLabel) {
            updatedVoucher.voucherStatus = this.props.showVoucherLabel && getVoucherStatus(this.state.voucher, updatedVoucher);
        }
    }

    checkIfVoucherAmountIsZero = (voucher: IVoucher) => {
        if (voucher.amount == 0) {
            this.UpdateZeroAmountBookMark(voucher);
            this.setState({ showDeleteZeroAmountVoucherButton: true, zeroAmountVouchersCount: this.state.zeroAmountVouchersCount + 1 })
        }
    }

    private validateVoucher(voucher: IVoucher) {

        if (voucher.authorityID === 0) {
            VenusNotifier.Warning(VoucherTabConstants.AddedVoucher.AuthorityWarning, "");
            return false;
        }
        if (voucher.paymentType === VoucherTypes.None || voucher.voucherNo === VoucherNo.None) {
            VenusNotifier.Warning(VoucherTabConstants.AddedVoucher.PaymentTypeWarning, "");
            return false;
        }
        if (!voucher.dueDate || !moment(voucher.dueDate).isValid()) {
            VenusNotifier.Warning(VoucherTabConstants.AddedVoucher.DueDateWarning, "");
            return false;
        }
        if (Number.isNaN(voucher.amount)) {
            VenusNotifier.Warning(VoucherTabConstants.AddedVoucher.AmountEmptyWarning, "");
            return false;
        }
        if (!voucher.formName || voucher.formName.trim() === "") {
            VenusNotifier.Warning(VoucherTabConstants.AddedVoucher.FormNameWarning, "");
            return false;
        }
        if ((voucher.customType == CustomType.Select || voucher.customType == CustomType.None) && voucher.vocherMode !== VoucherMode.Recognized) {
            VenusNotifier.Warning(VoucherTabConstants.AddedVoucher.CustomTypeWarning, "");
            return false;
        }
        if (voucher.customType && voucher.customType == CustomType.OnlinePaymentRequired) {
            if (!voucher.onlinePaymentUri || voucher.onlinePaymentUri === "") {
                VenusNotifier.Warning(VoucherTabConstants.AddedVoucher.OnlinePaymentUrlWarning, "");
                return false;
            }
            else if (!ValidateWebsite(voucher.onlinePaymentUri.trim())) {
                VenusNotifier.Warning(VoucherTabConstants.AddedVoucher.OnlinePaymentValidUrlWarning, "");
                return false;
            }
        }
        if (voucher.customType && voucher.customType === CustomType.AddCustom) {
            if (!voucher.customText || voucher.customText.trim() === "") {
                VenusNotifier.Warning(VoucherTabConstants.AddedVoucher.CustomTextWarning, "");
                return false;
            }
        }
        return true;
    }

    private onVoucherPrepareSuccess = (blob: any, uploadData: any): void => {
        PDFJS.getDocument(URL.createObjectURL(blob)).then((pdf) => {
            let documentFacade: IPdfDocumentFacade =
                (this.props.pdfDocuments[this.props.docId].document as IPdfDocumentFacade);
            generateBase64(URL.createObjectURL(blob)).then(base64data => {
                documentFacade.append(pdf, uploadData.fileGuid, base64data);
                let tempVoucher: IVoucher = { ...this.state.addedVoucher };
                let voucher = this.props.taxReturn.formGroups.find((value) => value.group === DocumentGroups.Vouchers);
                tempVoucher.pageNo = [documentFacade?.getLastPage()];
                tempVoucher.bookmark = tempVoucher.vocherMode === VoucherMode.CustomVoucher ? this.setBookmarkForManuallyAddedVoucher(tempVoucher.authorityID, tempVoucher.formName) : tempVoucher.formName;

                if (voucher === undefined) {
                    voucher = {} as IGroup;
                    voucher.group = DocumentGroups.Vouchers;
                    voucher.forms = [] as IFormBase[];
                    voucher.forms.push(tempVoucher);
                    this.props.taxReturn.formGroups.push(voucher);
                }
                else {
                    this.props.taxReturn.formGroups.map((value: any) => {
                        if (value.group === DocumentGroups.Vouchers) {
                            value.forms.push(tempVoucher);
                        }
                    });
                }
                this.props.updateTaxDocument(this.props.taxReturn);

                let uploadHelperFunctions = new UploadFunctions();
                uploadHelperFunctions.uploadedFile(blob, uploadData, tempVoucher.formName);

                this.getVoucherDetails(this.state.currentPage);

                this.setState({
                    showLoader: false,
                    isVoucherEdit: false,
                    isVoucherUpdated: false

                });
            });
        });
    }

    private onMultipleVoucherPrepareSuccess = (blob: any, uploadData: any, callbackVoucher: IVoucher, isFinalVoucher?: boolean): void => {
        PDFJS.getDocument(URL.createObjectURL(blob)).then((pdf) => {
            let documentFacade: IPdfDocumentFacade =
                (this.props.pdfDocuments[this.props.docId].document as IPdfDocumentFacade);
            generateBase64(URL.createObjectURL(blob)).then(base64data => {
                documentFacade.append(pdf, uploadData.fileGuid, base64data);
                let tempVoucher: IVoucher = { ...callbackVoucher };
                let voucher = this.props.taxReturn.formGroups.find((value) => value.group === DocumentGroups.Vouchers);
                tempVoucher.pageNo = [documentFacade?.getLastPage()];
                tempVoucher.bookmark = tempVoucher.vocherMode == VoucherMode.CustomVoucher ? this.setBookmarkForManuallyAddedVoucher(tempVoucher.authorityID, tempVoucher.formName) : tempVoucher.formName;
                if (this.props.isRevisionProcess && !callbackVoucher.voucherStatus) {
                    tempVoucher.voucherStatus = VoucherStatus.New;
                }

                if (voucher === undefined) {
                    voucher = {} as IGroup;
                    voucher.group = DocumentGroups.Vouchers;
                    voucher.forms = [] as IFormBase[];
                    voucher.forms.push(tempVoucher);
                    this.props.taxReturn.formGroups.push(voucher);
                }
                else {
                    this.props.taxReturn.formGroups.map((value: any) => {
                        if (value.group === DocumentGroups.Vouchers) {
                            value.forms.push(tempVoucher);
                        }
                    });
                }
                this.props.updateTaxDocument(this.props.taxReturn);

                let uploadHelperFunctions = new UploadFunctions();
                uploadHelperFunctions.uploadedFile(blob, uploadData, tempVoucher.formName);
                isFinalVoucher ? this.onPageSelect(tempVoucher.pageNo[0], tempVoucher.voucherNo, false) : this.onPageSelect(tempVoucher.pageNo[0], tempVoucher.voucherNo, true)

                this.setState({
                    showLoader: false,
                    isVoucherEdit: false,
                    isVoucherUpdated: false
                });
                if (isFinalVoucher) {
                    this.props.updateMultipleVouchersAdd && this.props.updateMultipleVouchersAdd(this.props.taxReturn.formGroups.find(x => x.group === DocumentGroups.Vouchers).forms.length);
                }
            });
        });
    }

    private getVoucherUploadLinkCallback = (uploadData: any) => {
        let voucherScript = new VoucherScript(this.state.addedVoucher, this.props.taxReturn);
        voucherScript.prepareVoucher(this.state.addedVoucher)
            .then((blob: any): void => {
                this.onVoucherPrepareSuccess(blob, uploadData);
            }, this.onVoucherPrepareError);
    }

    private getMultipleVoucherUploadLinkCallback = (uploadData: any, voucher: IVoucher, isFinalVoucher?: boolean) => {
        let voucherScript = new VoucherScript(voucher, this.props.taxReturn);
        voucherScript.prepareVoucher(voucher)
            .then((blob: any): void => {
                this.onMultipleVoucherPrepareSuccess(blob, uploadData, voucher, isFinalVoucher);
            }, this.onVoucherPrepareError);
    }

    private onVoucherPrepareError = (error: any) => {
        VenusNotifier.Error(VoucherTabConstants.AddVoucher.voucherPdfPrepareMessageFailed,
            "Error");

        this.setState({
            showLoader: false,
            isVoucherEdit: false,
            isVoucherUpdated: false

        });

        this.getVoucherDetails(this.state.currentPage);
    }

    private getSelectedVoucher = (voucher: IVoucher) => {
        if (this.page && (voucher.pageNo != null || voucher.pageNo != undefined))
            this.setState({ voucher: voucher });

    }

    private onPaginationClick = (pageNo: number) => {
        if (pageNo != 0 && pageNo != undefined) {
            this.setState({ currentPage: pageNo });
            this.getVoucherDetails(pageNo);
            let pagesIndex: number = this.props.pages.indexOf(pageNo);

            this.setState({
                currentPageIxdex: pagesIndex
            });
        }
    }

    private onPageSelect = (page: number, voucherNo: number = -1, isPageSelectionFromBookMark?: boolean) => {
        if (page != 0 && page != undefined) {
            isPageSelectionFromBookMark !== false && this.setState({ currentPage: page });
            this.getVoucherDetails(page, voucherNo);
            if (isPageSelectionFromBookMark) {
                this.setState({
                    currentPageIxdex: this.props.pages.indexOf(page)
                });
            }
        }
    }

    

    getVoucherDetails = (page: number, voucherNo: number = -1) => {
        let taxingAuthority: any;
        let paymentUrlInfo: any;
        let editedUrl: string = '';
        let voucherIndex = this.props.taxReturn?.formGroups?.findIndex(x => x.group == DocumentGroups.Vouchers);

        if (voucherIndex != -1 && page != undefined) {
            let voucherForms: IVoucherFormBase[] = this.props.taxReturn.formGroups[voucherIndex].forms as IVoucherFormBase[];
            this.setState({
                voucher: voucherNo != -1 ? voucherForms.find(x => x.pageNo[0] === page && x.voucherNo === voucherNo) as IVoucher :
                    voucherForms.find(x => x.pageNo[0] === page) as IVoucher,
                formName: voucherNo != -1 ? voucherForms.find(x => x.pageNo[0] === page && x.voucherNo === voucherNo)!.formName :
                    voucherForms.find(x => x.pageNo[0] === page)!.formName
            }, () => {
                taxingAuthority = this.props.getAllTaxingAuthorities.find(x => x.Id == this.state.voucher.authorityID);
                const defaultDates = this.props.voucherStore.voucherDefaultDates
                    .filter(x => x.authorityId === this.state.voucher.authorityID &&
                        x.taxYear === this.props.taxReturn.taxYear &&
                        x.returnType === Number(EngagementType[this.props.taxReturn.engagementType]))
                paymentUrlInfo = filterTaxFilingDates(defaultDates, this.state.formName, this.state.voucher.paymentType);
                if (paymentUrlInfo) {
                    if (this.state.voucher.onlinePaymentUri) {
                        editedUrl = this.state.voucher.onlinePaymentUri;
                    }

                    this.setState({
                        isPaymentUrlSame: taxingAuthority.CustomAuthority ? true : this.state.voucher.paymentType === VoucherTypes.PaymentVoucher ?
                            paymentUrlInfo.paymentURL === null || paymentUrlInfo.paymentURL === '' ? true : editedUrl === paymentUrlInfo.paymentURL
                            :
                            paymentUrlInfo.esPaymentURL === null || paymentUrlInfo.esPaymentURL === '' ? true : editedUrl === paymentUrlInfo.esPaymentURL,
                        masterPaymentUrl: taxingAuthority.CustomAuthority ? "" : this.state.voucher.paymentType === VoucherTypes.PaymentVoucher ? paymentUrlInfo.paymentURL : paymentUrlInfo.esPaymentURL
                    });
                }
                else {
                    this.setState({
                        isPaymentUrlSame: true
                    });
                }
            });
        }
    }

    private onPageDelete = (page: number) => {
        this.props.deletePages([page]);
        //this.props.reGroupPreview([page], DocumentGroups.Deleted);
    }

    private handleDeleteZeroAmountVoucher = () => {
        let pages = this.getZeroAmountVoucherPages();
        pages && pages.length > 0 &&
            this.props.reGroupPreview(pages, DocumentGroups.Deleted, undefined, () => {
                this.setState({
                    showDeleteZeroAmountVoucherButton: false,
                    zeroAmountVouchersCount: 0
                });
            });
    }

    private getZeroAmountVoucherPages = () => {
        let voucherForms: IGroup = this.props.taxReturn.formGroups.find((x: any) => x.group == DocumentGroups.Vouchers)!;
        return voucherForms && voucherForms.forms.filter((j: any) => j.amount == 0).map((k: any) => k.pageNo[0]);
    }

    createLeftPanel = () => {
        return <BookmarksPane
            title="Pages"
            docId={this.props.docId}
            pdfDocuments={this.props.pdfDocuments}
            sections={this.props.bookmarks}
            onPageDelete={this.onPageDelete}
            onPageSelect={this.onPageSelect}
            tabType={ProcessReturnTabs.Vouchers}
            showAddIcon={true}
            taxReturn={this.props.taxReturn}
            onAddVoucher={this.onAddVoucher}
            onAddMultipeVouchers={this.onAddMultipleVouchers}
            voucherStore={this.props.voucherStore}
            populateVoucherCard={this.getSelectedVoucher}
            handleDeleteZeroAmountVoucher={this.handleDeleteZeroAmountVoucher.bind(this)}
            showDeleteZeroAmountVoucherButton={this.state.showDeleteZeroAmountVoucherButton}
            zeroAmountVouchersCount={this.state.zeroAmountVouchersCount}
            getAllTaxingAuthorities={this.props.getAllTaxingAuthorities}
            showVoucherLabel={this.props.showVoucherLabel}
            isRevisionProcess={this.props.isRevisionProcess}
        />
    }

    createRightPanel = () => {
        return <VoucherEntry onUpdateAddedVoucher={this.onUpdateAddedVoucher}
            taxingAuthorities={this.props.getAllTaxingAuthorities}
            voucherDefaultDate={this.props.voucherStore.voucherDefaultDates}
            voucher={this.state.voucher ? this.state.voucher : initialVoucher}
            voucherStore={this.props.voucherStore}
            taxReturn={this.props.taxReturn}
            isPaymentUrlSame={this.state.isPaymentUrlSame}
            masterPaymentUrl={this.state.masterPaymentUrl}
        />
    }

    createMoveTo = () => {
        return <PageMoveTo
            documentGroup={DocumentGroups.Vouchers}
            reGroupPreview={this.reGroupPreview}
            engagementType={this.props.taxReturn.engagementType}
            arrangement={this.props.taxReturn.arrangement}
            isEnableInvoice={this.props.isEnableInvoice}
            isRevisionProcess={this.props.isRevisionProcess}
        />
    }

    reGroupPreview = (destinationGroup: DocumentGroups) => {
        const pages: number[] = [];
        this.props.formGroup && this.props.formGroup.forms.map((form, i) => { pages.push(...form.pageNo); });
        const indexOfCurrentPage: number = this.props.pages.indexOf(this.state.currentPage);
        this.props.reGroupPreview([this.state.currentPage], destinationGroup);
        if (this.props.isRevisionProcess && this.state.voucher.pageNo[0] == this.state.currentPage && !this.state.voucher.isPaid) {
            if (destinationGroup == TaxDocument.DocumentGroups.Deleted) {
                if ((this.props.pages.length - 1) == indexOfCurrentPage) {
                    this.setState({
                        currentPage: this.props.pages.indexOf(indexOfCurrentPage),
                        currentPageIxdex: indexOfCurrentPage - 1
                    });
                }
                else {
                    this.setState({
                        currentPage: this.props.pages.indexOf(indexOfCurrentPage),
                        currentPageIxdex: this.props.pages.indexOf(this.state.currentPage)
                    });
                }
            }
            else {
                this.setState({
                    currentPage: pages[0]
                });
            }
        }
    }

    public render() {
        return (
            <>
                <AwesomePdfViewer
                    pdfDocument={this.props.pdfDocuments[this.props.docId].document as IPdfDocumentFacade}
                    leftPanel={this.createLeftPanel()}
                    rightPanel={this.createRightPanel()}
                    currentPage={this.state.currentPage}
                    pages={this.props.pages}
                    enableMoveTo={true}
                    moveToElement={this.createMoveTo()}
                    isAssignedToLoggedinUser={this.props.isAssignedToLoggedinUser}
                    scale={this.state.scale}
                    onScaleChange={(s) => this.setState({ scale: s })}
                    onCurrentPageChange={this.onPaginationClick}
                />
            </>);
    }
};
