import * as React from "react";

export enum ToggleSwitchSize {
    SM = 1,
    MD = 2,
    LG = 3
}

interface IToggleSwitchProps {
    dataTestChecked?: boolean;
    dataTestAuto?: string;
    switched?: boolean;
    handleChange(event: any): void;
    title?: string;
    disabled?: boolean;
    size?: ToggleSwitchSize;
    className?: string;
}

const customToggleSwitchStyleSM = {
    width: "50px",
    height: "20px"
};

const customToggleSwitchStyleLG = {
    width: "75px",
    height: "25px"
};

const customToggleSwitchHandleStyleSM = {
    width: "15px",
    height: "17px"
};

const customToggleSwitchHandleStyleLG = {
    width: "21px",
    height: "22px"
};

export default class ToggleSwitch extends React.Component<IToggleSwitchProps, {}> {
    private getCustomToggleSwitchStyle = (): any => {
        switch (this.props?.size) {
            case ToggleSwitchSize.SM:
                return customToggleSwitchStyleSM;

            case ToggleSwitchSize.MD:
                return customToggleSwitchStyleLG;

            case ToggleSwitchSize.LG:
                return customToggleSwitchStyleLG;

            default:
                return customToggleSwitchStyleLG;
        }
    };

    private getCustomToggleSwitchHandleStyle = (): any => {
        switch (this.props?.size) {
            case ToggleSwitchSize.SM:
                return customToggleSwitchHandleStyleSM;

            case ToggleSwitchSize.MD:
                return customToggleSwitchHandleStyleLG;

            case ToggleSwitchSize.LG:
                return customToggleSwitchHandleStyleLG;

            default:
                return customToggleSwitchHandleStyleLG;
        }
    };

    private getCustomToggleSwitchHandleClass = (): string => {
        switch (this.props?.size) {
            case ToggleSwitchSize.SM:
                return "custom-toggle-switch-handle sm";

            case ToggleSwitchSize.MD:
                return "custom-toggle-switch-handle";

            case ToggleSwitchSize.LG:
                return "custom-toggle-switch-handle";

            default:
                return "custom-toggle-switch-handle";
        }
    };

    public render() {
        const { title, switched, disabled, handleChange } = this.props;
        let cssClass = "custom-toggle-switch dis-inline";
        if (disabled) {
            cssClass = cssClass + " disabled";
        }

        return (
            <label className={`${cssClass} ${this.props.className}`} style={this.getCustomToggleSwitchStyle()} title={title}>
                <input
                    className="custom-toggle-switch-input"
                    type="checkbox"
                    onChange={handleChange}
                    checked={switched}
                    disabled={disabled ? disabled : false}
                    data-test-checked={`${this.props.dataTestChecked}`}
                    data-test-auto={`${this.props.dataTestAuto}`}
                />
                <span className="custom-toggle-switch-label" data-on="On" data-off="Off"></span>
                <span className={this.getCustomToggleSwitchHandleClass()} style={this.getCustomToggleSwitchHandleStyle()}></span>
            </label>
        );
    }
}
