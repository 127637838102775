import { addTask } from "domain-task";
import { Reducer } from "redux";
import { AppThunkAction } from "..";
import { actionTypes } from "../../types/ActionTypes";
import { handleResponse } from "../Library";
import { API_BASE_URL } from "../../utils/constants";
import { StatusType, NotificationAction } from "./NotificationStore";
import { logger } from "../../routes/LoggedIn";
import * as Constants from "../../components/helper/Constants";

export interface ISMSNotificationDetails {
    isSuiteSMSNotificationSetting: boolean;
    isTaxPayerSMSOptedIn: boolean;
    isSpouseSMSOptedIn: boolean;
    isTwilioTaxPayerSMSOptedIn: boolean;
    isTwilioSpouseSMSOptedIn: boolean;
}

export interface IClientDetails {
    countryCode: string | null;
    mobileNumber: string | null;
    isOpted: boolean;
}

export interface IDataToUpdateSMSNotificationDetails {
    oldClientId: string | null;
    documentId: number;
    clientId: string;
    tpDetails: IClientDetails;
    spouseDetails: IClientDetails | null;
}

export interface ReceiveSMSNotificationDetailsAction {
    type: actionTypes.RECEIVE_SMS_NOTIFICATION_DETAILS;
    smsNotificationDetails: ISMSNotificationDetails;
}

type KnownAction = ReceiveSMSNotificationDetailsAction | NotificationAction;

export const initialSMSNotificationDetails: ISMSNotificationDetails = {
    isSuiteSMSNotificationSetting: false,
    isTaxPayerSMSOptedIn: false,
    isSpouseSMSOptedIn: false,
    isTwilioTaxPayerSMSOptedIn: false,
    isTwilioSpouseSMSOptedIn: false
} as ISMSNotificationDetails;

export const actionCreators = {
    getSMSNotificationDetails:
        (
            documentId: number,
            isEditClientInfoRequest: boolean,
            callback: (smsNotificationDetails: ISMSNotificationDetails) => void
        ): AppThunkAction<KnownAction> =>
        (dispatch) => {
            const fetchTask = fetch(
                API_BASE_URL +
                    "api/sms-notification/get-sms-notification-details?documentId=" +
                    documentId +
                    "&isEditClientInfoRequest=" +
                    isEditClientInfoRequest,
                {
                    credentials: "include"
                }
            )
                .then(handleResponse)
                .then((response) => response as Promise<ISMSNotificationDetails>)
                .then((data) => {
                    dispatch({
                        type: actionTypes.RECEIVE_SMS_NOTIFICATION_DETAILS,
                        smsNotificationDetails: data
                    });
                    callback(data);
                })
                .catch(function (error) {
                    const statusMessage: any = error.statusText?.message ?? error.statusText;
                    if (typeof statusMessage === "string") {
                        dispatch({
                            type: actionTypes.NOTIFICATION,
                            statusMessage: Constants.SMSTextNotifications.StatusMessage.GetError,
                            statusType: StatusType.Error
                        });
                    }
                });
            addTask(fetchTask);
        },

    updateSMSNotificationDetails:
        (dataToUpdateSMSNotificationDetails: IDataToUpdateSMSNotificationDetails): AppThunkAction<KnownAction> =>
        (dispatch) => {
            const fetchTask = fetch(API_BASE_URL + "api/sms-notification/update-sms-notification-details", {
                method: "PUT",
                credentials: "include",
                headers: {
                    Accept: "application/json, text/plain, */*",
                    "Content-Type": "application/json; charset=utf-8",
                    RequestVerificationToken: (document.getElementById("RequestVerificationToken") as HTMLInputElement).value
                },
                body: JSON.stringify(dataToUpdateSMSNotificationDetails)
            })
                .then(handleResponse)
                .then((response) => response as Promise<boolean>)
                .then((data) => {
                    logger.trackTrace(`updateSMSNotificationDetails successful`);
                    if (!data) {
                        dispatch({
                            type: actionTypes.NOTIFICATION,
                            statusMessage: Constants.SMSTextNotifications.StatusMessage.UpdateError,
                            statusType: StatusType.Error
                        });
                    }
                })
                .catch(function (error) {
                    const statusMessage: any = error.statusText?.message ?? error.statusText;
                    if (typeof statusMessage === "string") {
                        dispatch({
                            type: actionTypes.NOTIFICATION,
                            statusMessage: Constants.SMSTextNotifications.StatusMessage.UpdateError,
                            statusType: StatusType.Error
                        });
                    }
                });
            addTask(fetchTask);
        }
};

export const reducer: Reducer<ISMSNotificationDetails> = (state = initialSMSNotificationDetails, incomingAction) => {
    const action = incomingAction as KnownAction;

    switch (action.type) {
        case actionTypes.RECEIVE_SMS_NOTIFICATION_DETAILS:
            return { ...action.smsNotificationDetails } as ISMSNotificationDetails;
    }
    return state;
};
