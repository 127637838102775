import { resolve } from "dns";
import { DocumentUploadData } from "src/components/common/ProcessReturnModal/AdditionalEsignDocuments/AdditionalEsignDocument";
import { UploadtaxReturnConstants } from "src/components/helper/Constants";
import { getFileExtension, validateIndividualFileSize } from "src/components/helper/Validations";
import { VenusNotifier } from "src/components/helper/VenusNotifier";
import { API_BASE_URL } from "src/utils/constants";

export const PdfProperties = {
    PageHeight: 792,
    PageWidth: 612,
    DefaultScale: 1
}

export function generateBase64(url: string): Promise<any> {

    return new Promise(function (resolve, reject) {
        fetch(url, {
            method: 'GET',
            headers: new Headers({})
        })
            .then(resp => resp.blob())
            .then((blob => {
                var reader = new FileReader();
                reader.readAsDataURL(blob);
                let base64Data: any;
                reader.onloadend = function () {
                    base64Data = extractBase64(reader.result);
                    resolve(base64Data);
                }
            }), (error: string) => {
                reject(error);
            });
    });
}

function extractBase64(dataUrl: any) {
    const index = dataUrl.indexOf('base64,');
    if (index == -1) {
        return null;
    }
    return dataUrl.substring(index + 7);
}

export const validateEncryptedFile = (uploadData: DocumentUploadData[]): Promise<any[]> => {
    var promises: any[] = [];
    let _tmpTaxReturnUploadData: DocumentUploadData[] = Object.assign({}, uploadData);
    for (let i = 0; i < uploadData.length; i++) {
        let uploadedFile = uploadData[i].file;
        var fileExtension = getFileExtension(uploadedFile.name);
        if(fileExtension === "pdf" || fileExtension === "PDF"){
            var promise = null;
            if(validateIndividualFileSize(uploadedFile))
            {
                promise = new Promise((resolve) => {
                    const formData = new FormData();
                    formData.append('files', uploadedFile, uploadedFile.FileName);
                    let options: any = {
                        method: 'POST',
                        credentials: 'include',
                        headers: {
                            'Accept': 'application/json'
                        },
                        body: formData
                    };
                    fetch(API_BASE_URL + 'api/upload/IsPasswordProtectedDocument', options)
                    .then((resp) => resp.json())
                    .then((response) =>
                    {
                        if(response.encrypted===true){
                            let index = uploadData.findIndex(x => x.name == _tmpTaxReturnUploadData[i].name)
                            VenusNotifier.Error(_tmpTaxReturnUploadData[i].name + UploadtaxReturnConstants.PdfDocumentPasswordProtectedError, null);
                            uploadData.splice(index, 1)
                        }
                        resolve(response.encrypted===true);
                    })
                    .catch((error: any) => {
                        console.log(error);
                    });
                });
            }
            else{
                promise = new Promise((resolve)=>{
                    var reader = new FileReader();   
                    reader.readAsText(uploadedFile) 
                    reader.onload = (event:any)=>{
                        var result: any = event.target.result;
                        if(result.indexOf('/Encrypt') !== -1 || result.indexOf('<encryption') !== -1){
                            let index = uploadData.findIndex(x => x.name == _tmpTaxReturnUploadData[i].name)
                            VenusNotifier.Error(_tmpTaxReturnUploadData[i].name + UploadtaxReturnConstants.PdfDocumentPasswordProtectedError, null);
                            uploadData.splice(index, 1);
                        }        
                        resolve(true);
                    }
                })                                                                         
            }                       
            promises.push(promise);
        }          
    }
    return Promise.all(promises).then(() => { return uploadData });
}