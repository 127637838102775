import * as React from 'react';
import * as Bs from 'react-bootstrap';
import ToggleSwitch from '../../common/ToggleSwitch';
import { ICompanySettings, initialCompanySettings } from '../../../Core/ViewModels/Company/CompanySettingsViewModel';
import { TaxReturnSettingsResources } from '../../../components/helper/ResourceIdConstants';



interface IPrintToPDFSettingProps {
    updateCompanySettings(companySettings: ICompanySettings, resourceId?: string): void;
    companySettings: ICompanySettings;
    parentResourceIdentifier: string;
}


interface IPrintToPDFModelState {
    switched: boolean;
    companySettings: ICompanySettings;
}

export class PrintToPDFSetting extends React.Component<IPrintToPDFSettingProps, IPrintToPDFModelState> {
    state: IPrintToPDFModelState = {
        switched: false,
        companySettings: initialCompanySettings
    };

    UNSAFE_componentWillMount() {

    }
    UNSAFE_componentWillReceiveProps() {
        if (this.props.companySettings) {           
                this.setState({
                    switched: this.props.companySettings.deliverySettingsModel.isPrintToPDFEnabled,
                    companySettings: this.props.companySettings
                });           
        }
    }

    handleToggleSwitchChange = (event: any) => {
        let company: ICompanySettings = { ...this.state.companySettings };
        company.deliverySettingsModel.isPrintToPDFEnabled = event.currentTarget.checked;
        this.setState({
            switched: event.currentTarget.checked,
            companySettings: company
        });

        this.props.updateCompanySettings(this.state.companySettings, this.props.parentResourceIdentifier + TaxReturnSettingsResources.ToggleEnableOrDisable);
    };


    public render() {
        return <div className="new-main-content">
            <h3>Download PDF</h3>
            <div className="marL20">
                <Bs.Row>
                    <Bs.Col className="text-left" sm={2} as={Bs.FormLabel} style={{ fontSize: "large", paddingTop: "3px" }}>
                        Enable 
                    </Bs.Col>
                    <Bs.Col sm={4} md={3} lg={2}
                        data-resource-id = { this.props.parentResourceIdentifier + TaxReturnSettingsResources.ToggleEnableOrDisable }
                    >
                        <ToggleSwitch switched={this.state.switched} 
                            handleChange={this.handleToggleSwitchChange} />
                    </Bs.Col>
                    <Bs.Col sm={6} md={7} lg={8}>
                    </Bs.Col>
                </Bs.Row>
            </div>
        </div>
    }

}