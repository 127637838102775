export interface ISignatureFlowReportDocument {
    signingId: number;
    senderName: string;
    signatureStatus: SignatureFlowReportSignatureStatus;
    documentName: string;
    taxYear: any;
    recipientName: string;
}

export enum SignatureFlowReportSignatureStatus {
    None = 0,
    Processing = 1,
    OutForSignature = 2,
    ESigned = 3,
    Declined = 4,
    Cancelled = 5
}