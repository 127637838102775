import React, { useEffect, useState } from "react";
import ReactDOMServer from "react-dom/server";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { Loader, LoadingOverlay } from "react-overlay-loader";
import DragAndDrop from "src/components/common/DragAndDrop";
import {
    ISetAccessModalProps,
    IUserData,
    IUserDataFromDB,
    IUserGroupData,
    IUserGroupDataFromDB
} from "./SetAccessCommonModal.model";
import BlueInfoIcon from "src/assets/images/BlueInfoIcon";
import styles from "./SetAccessCommonModal.module.scss";
import { DeliveredReturnsConstants } from "../../helper/Constants";

const SetAccessCommonModal: React.FC<ISetAccessModalProps> = (props) => {
    const { availableUsers, selectedUsers, availableUserGroups, selectedUserGroups, isGroupedReturnExists } = props;
    const [availableUsersForDnD, setAvailableUsersForDnD] = useState<IUserData[]>([]);
    const [selectedUsersForDnD, setSelectedUsersForDnD] = useState<IUserData[]>([]);
    const [availableUserGroupsForDnD, setAvailableUserGroupsForDnD] = useState<IUserGroupData[]>([]);
    const [selectedUserGroupsForDnD, setSelectedUserGroupsForDnD] = useState<IUserGroupData[]>([]);

    const [availableUsersForDnDInitial, setAvailableUsersForDnDInitial] = useState<IUserData[]>([]);
    const [selectedUsersForDnDInitial, setSelectedUsersForDnDInitial] = useState<IUserData[]>([]);
    const [availableUserGroupsForDnDInitial, setAvailableUserGroupsForDnDInitial] = useState<IUserGroupData[]>([]);
    const [selectedUserGroupsForDnDInitial, setSelectedUserGroupsForDnDInitial] = useState<IUserGroupData[]>([]);

    const [isSetAccessSaving, setIsSetAccessSaving] = useState(false);
    const [savingMessage, setSavingMessage] = useState("");

    const [isNoUserAndUserGroup, setIsNoUserAndUserGroup] = useState(false);
    const [isDataChanged, setIsDataChanged] = useState(false);

    useEffect(() => {
        setIsSetAccessSaving(true);
        setSavingMessage("Loading, Please Wait...");
        initAllValues();
    }, []);

    useEffect(() => {
        if (selectedUsersForDnD.length === 0 && selectedUserGroupsForDnD.length === 0) {
            setIsNoUserAndUserGroup(true);
        } else {
            setIsNoUserAndUserGroup(false);
        }
    }, [selectedUsersForDnD, selectedUserGroupsForDnD]);

    const initAllValues = (): void => {
        initAvailableUsers(availableUsers);
        initSelectedUsers(selectedUsers);
        initAvailableUserGroups(availableUserGroups);
        initSelectedUserGroups(selectedUserGroups);

        setIsSetAccessSaving(false);
        setSavingMessage("");
    };

    const initAvailableUsers = (availableUsers: IUserDataFromDB[]) => {
        const availableUsersForDnDTemp: IUserData[] = availableUsers.map((eachUser: IUserDataFromDB) => {
            return { name: eachUser.name, title: eachUser.name, value: eachUser.id };
        });
        setAvailableUsersForDnD(availableUsersForDnDTemp);
        setAvailableUsersForDnDInitial(availableUsersForDnDTemp);
    };

    const initSelectedUsers = (selectedUsers: IUserDataFromDB[]) => {
        const selectedUsersForDnDTemp: IUserData[] = selectedUsers.map((eachUser: IUserDataFromDB) => {
            return { name: eachUser.name, title: eachUser.name, value: eachUser.id };
        });
        setSelectedUsersForDnD(selectedUsersForDnDTemp);
        setSelectedUsersForDnDInitial(selectedUsersForDnDTemp);
    };

    const initAvailableUserGroups = (availableUserGroups: IUserGroupDataFromDB[]) => {
        const availableUserGroupsForDnDTemp: IUserGroupData[] = availableUserGroups.map((eachUserGroup: IUserGroupDataFromDB) => {
            const name = ReactDOMServer.renderToStaticMarkup(
                <span
                    style={{
                        color: eachUserGroup.fontColor,
                        backgroundColor: eachUserGroup.backgroundColor,
                        border: `1px solid ${eachUserGroup.borderColor}`
                    }}
                    className="eachGroupSetAccess"
                >
                    {eachUserGroup.name}
                </span>
            );
            return { name, title: eachUserGroup.name, value: eachUserGroup.id };
        });
        setAvailableUserGroupsForDnD(availableUserGroupsForDnDTemp);
        setAvailableUserGroupsForDnDInitial(availableUserGroupsForDnDTemp);
    };

    const initSelectedUserGroups = (selectedUserGroups: IUserGroupDataFromDB[]) => {
        const selectedUserGroupsForDnDTemp = selectedUserGroups.map((eachUserGroup: IUserGroupDataFromDB) => {
            const name = ReactDOMServer.renderToStaticMarkup(
                <span
                    style={{
                        color: eachUserGroup.fontColor,
                        backgroundColor: eachUserGroup.backgroundColor,
                        border: `1px solid ${eachUserGroup.borderColor}`
                    }}
                    className="eachGroupSetAccess"
                >
                    {eachUserGroup.name}
                </span>
            );
            return { name, title: eachUserGroup.name, value: eachUserGroup.id };
        });
        setSelectedUserGroupsForDnD(selectedUserGroupsForDnDTemp);
        setSelectedUserGroupsForDnDInitial(selectedUserGroupsForDnDTemp);
    };

    const resetSetAccessData = () => {
        setAvailableUsersForDnD(availableUsersForDnDInitial);
        setSelectedUsersForDnD(selectedUsersForDnDInitial);
        setAvailableUserGroupsForDnD(availableUserGroupsForDnDInitial);
        setSelectedUserGroupsForDnD(selectedUserGroupsForDnDInitial);
        setIsDataChanged(false);
    };

    const handleSetAccessSave = () => {
        const isDisabled = isSetAccessSaving || !isDataChanged;
        if (isDisabled) return;
        if (selectedUsersForDnD.length === 0 && selectedUserGroupsForDnD.length === 0) {
            setIsNoUserAndUserGroup(true);
        } else {
            setIsNoUserAndUserGroup(false);
            setIsSetAccessSaving(true);
            setSavingMessage("Submitting the access changes...");
            const accessingUsersAndUserGroups = {
                selectedUsers: selectedUsersForDnD.map((eachUser) => eachUser.value),
                selectedUserGroups: selectedUserGroupsForDnD.map((eachUserGroup) => eachUserGroup.value),
                availableUsers: availableUsersForDnD.map((eachUser) => eachUser.value),
                availableUserGroups: availableUserGroupsForDnD.map((eachUserGroup) => eachUserGroup.value)
            };
            props.onApplyAccess(accessingUsersAndUserGroups);
        }
    };

    const numberOfSelectedUsersAndUserGroups = () => {
        const numberOfSelectedUsers =
            selectedUsersForDnD.findIndex((eachUser) => eachUser.value === 0) !== -1
                ? "All"
                : availableUsersForDnD.length === 0 || (availableUsersForDnD.length === 1 && availableUsersForDnD[0].value === 0)
                    ? "All"
                    : selectedUsersForDnD.length;
        const numberOfSelectedUserGroups =
            selectedUserGroupsForDnD.findIndex((eachUserGroup) => eachUserGroup.value === 0) !== -1
                ? "All"
                : availableUserGroupsForDnD.length === 0 ||
                    (availableUserGroupsForDnD.length === 1 && availableUserGroupsForDnD[0].value === 0)
                    ? "All"
                    : selectedUserGroupsForDnD.length;
        const text = `${numberOfSelectedUsers} ${numberOfSelectedUsers !== 1 ? "Users" : "User"
            } and ${numberOfSelectedUserGroups} ${numberOfSelectedUserGroups !== 1 ? "User groups" : "User group"} selected.`;
        return text;
    };

    return (
        <>
            <Modal.Body className={`${styles.setAccessBody}`}>
                <LoadingOverlay style={{ height: "100%" }}>
                    <section className={styles.selectionData}>
                        {!isGroupedReturnExists ?
                            <>
                                <span>
                                    <BlueInfoIcon />
                                </span>
                                <span>{numberOfSelectedUsersAndUserGroups()}</span>
                            </>
                            :
                            <>
                                <span style={{ paddingRight: "15px" }}>
                                    <BlueInfoIcon />
                                </span>
                                <div>
                                    <Row>
                                        <span>{numberOfSelectedUsersAndUserGroups()}</span>
                                    </Row>

                                    <Row>
                                        <span>{DeliveredReturnsConstants.OtherMessage.GroupReturnSetAccessMessage}</span>
                                    </Row>
                                </div>
                            </>
                        }
                    </section>
                    <div className={styles.usersDnD}>
                        <DragAndDrop
                            sourceData={availableUsersForDnD}
                            setSourceData={setAvailableUsersForDnD}
                            destinationData={selectedUsersForDnD}
                            setDestinationData={setSelectedUsersForDnD}
                            sourceDataInitial={availableUsersForDnDInitial}
                            destinationDataInitial={selectedUsersForDnDInitial}
                            sourceHeading="AVAILABLE USERS"
                            sourceHeadingInfoText="List of all users in company"
                            destinationHeading="USER WITH ACCESS RIGHTS"
                            destinationHeadingInfoText="List of selected users"
                            sourceWidth="350px"
                            sourceHeight="223px"
                            destinationWidth="350px"
                            destinationHeight="223px"
                            disableDragAndDrop={
                                props.returnAccessSettings &&
                                (props.returnAccessSettings.isSetAccessToIndividual ||
                                    props.returnAccessSettings.isSetAccessToUserGroups) &&
                                (props.isDragAndDropDisabled === undefined ? true : props.isDragAndDropDisabled)
                            }
                            setIsDataChanged={setIsDataChanged}
                            dndUniqueID="1"
                        />
                    </div>
                    <div className={styles.userGroupsDnD}>
                        <DragAndDrop
                            sourceData={availableUserGroupsForDnD}
                            setSourceData={setAvailableUserGroupsForDnD}
                            destinationData={selectedUserGroupsForDnD}
                            setDestinationData={setSelectedUserGroupsForDnD}
                            sourceDataInitial={availableUserGroupsForDnDInitial}
                            destinationDataInitial={selectedUserGroupsForDnDInitial}
                            sourceHeading="AVAILABLE USER GROUPS"
                            sourceHeadingInfoText="List of all groups in company"
                            destinationHeading="USER GROUPS WITH ACCESS RIGHTS"
                            destinationHeadingInfoText="List of selected groups"
                            sourceWidth="350px"
                            sourceHeight="184px"
                            destinationWidth="350px"
                            destinationHeight="184px"
                            disableDragAndDrop={
                                props.returnAccessSettings &&
                                (props.returnAccessSettings.isSetAccessToIndividual ||
                                    props.returnAccessSettings.isSetAccessToUserGroups) &&
                                (props.isDragAndDropDisabled === undefined ? true : props.isDragAndDropDisabled)
                            }
                            setIsDataChanged={setIsDataChanged}
                            dndUniqueID="2"
                            disableDestinationItems={[1]}
                        />
                    </div>
                    <div className={styles.errorMessage}>
                        {isNoUserAndUserGroup ? "At least one user or user group is mandatory" : ""}
                    </div>
                    <Loader loading={isSetAccessSaving} text={savingMessage} />
                </LoadingOverlay>
            </Modal.Body>
            <Modal.Footer className={styles.setAccessFooter}>
                <div>
                    <button
                        data-test-auto="292778c2-6b42-4c6f-9a09-91cccdc872ea"
                        className={styles.resetButton}
                        disabled={isSetAccessSaving}
                        onClick={resetSetAccessData}
                    >
                        <span className={styles.resetText}>Reset</span>
                    </button>
                    <Button
                        disabled={isSetAccessSaving}
                        data-test-auto="96af1953-27c9-47f5-aeae-5f3427ea379b"
                        className={styles.cancelButton}
                        onClick={props.onCancel}
                    >
                        Cancel
                    </Button>
                    <Button
                        data-test-auto="9ff5fabf-fb69-4a7e-b3dc-f2365b860dc9"
                        className={`${styles.saveButton} ${isSetAccessSaving || !isDataChanged ? styles.disabledSaveButton : ""}`}
                        onClick={handleSetAccessSave}
                    >
                        Save
                    </Button>
                </div>
            </Modal.Footer>
        </>
    );
};

export default SetAccessCommonModal;
