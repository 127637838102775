import { addTask } from 'domain-task';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../';
import { ITaxReturn, DocumentStatus } from '../../components/common/TaxReturn';
import {
    RequestCompanyAssignmentsAction, ReceiveCompanyAssignmentsAction,
    RequestCompanyAssignmentsPagesAction, ReceiveCompanyAssignmentsPagesAction,
    ReceiveSelectedDocumentForDeliveryAction, RemoveSelectedDocumentForDeliveryAction
} from "./KnownTypes";
import { IAssignmentsState } from "./AssignmentsState";
import { actionTypes } from '../../types/ActionTypes';
import {
    ReceiveTaxDocumentAction, DeleteTaxDocumentAction,
    TaxReturnSource, ResetTaxReturnsAction, ReceiveTaxDocumentsAction,
    actionCreators as TaxDocumentActions, ResetMissedRecognizedEventForTaxDocumentAction
} from '../common/TaxDocumentStore';
import { StatusType, NotificationAction } from '../common/NotificationStore';
import { handleResponse } from '../Library';
import * as Notification from '../common/NotificationStore';
import * as NotificationMessage from "../../components/helper/Constants";
import { API_BASE_URL } from '../../utils/constants';

type OwnAction =
    RequestCompanyAssignmentsAction |
    ReceiveSelectedDocumentForDeliveryAction |
    RemoveSelectedDocumentForDeliveryAction |
    ReceiveCompanyAssignmentsAction;

type KnownAction =
    OwnAction |
    ReceiveTaxDocumentAction |
    ReceiveTaxDocumentsAction |
    RequestCompanyAssignmentsPagesAction |
    ReceiveCompanyAssignmentsPagesAction |
    ResetMissedRecognizedEventForTaxDocumentAction |
    RemoveSelectedDocumentForDeliveryAction |
    ReceiveSelectedDocumentForDeliveryAction |
    NotificationAction;

type dispatchAction =
    OwnAction |
    ReceiveTaxDocumentAction |
    ReceiveTaxDocumentAction |
    ResetTaxReturnsAction |
    DeleteTaxDocumentAction;

export const actionCreators = {
    requestCompanyAssignments: (query: string, reload: boolean = false, callback?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        let state = getState();

        if (reload || query !== state.companyAssignments.query) {
            if (!reload) {
                dispatch({ type: actionTypes.REQUEST_COMPANY_ASSIGNMENTS, query: query });
                return;
            }
            const fetchTask = fetch(`${API_BASE_URL}api/CompanyAssignments` + query, {
                method: 'GET',
                credentials: 'include'
            })
                .then(handleResponse)
                .then(response => response as Promise<{ count: number, documents: ITaxReturn[] }>)
                .then(data => {
                    dispatch({ type: actionTypes.RECEIVE_COMPANY_ASSIGNMENTS, query: query, assignments: data.documents, totalRowCount: data.count });
                    dispatch({ type: actionTypes.RECEIVE_COMPANY_ASSIGNMENTS_PAGES, query: query, assignments: data.documents, totalRowCount: data.count });
                    dispatch({
                        type: actionTypes.RECEIVE_TAX_DOCUMENTS, taxDocuments: data.documents,
                        source: TaxReturnSource.CompanyAssignments
                    });
                    if (state.taxReturnsToNotifyRecognizedEvent.ids.length > 0) {
                        state.taxReturnsToNotifyRecognizedEvent.ids.forEach((id: number) => {
                            if (data.documents.findIndex((x) => x.id == id) > -1) {
                                let action: any = TaxDocumentActions.requestTaxDocument(id, true);
                                dispatch(action);
                            }
                            dispatch({ type: actionTypes.RESET_MISSED_RECOGNIZED_EVENT_FOR_TAX_DOCUMENT, id: id });
                        })
                    }
                    if (callback) {
                        callback();
                    }
                })
                .catch(error => {
                    const statusMessage: any = error.statusText?.message ?? error.statusText;
                    if (typeof (statusMessage) === "string") {
                        dispatch({ type: actionTypes.NOTIFICATION, statusMessage: statusMessage, statusType: StatusType.Error });
                    }
                    if (callback) {
                        callback();
                    }
                });

            addTask(fetchTask); // Ensure server-side prerendering waits for this to complete
            dispatch({ type: actionTypes.REQUEST_COMPANY_ASSIGNMENTS, query: query });
            dispatch({ type: actionTypes.REQUEST_COMPANY_ASSIGNMENTS_PAGES, query: query });
        }
    },
    exportAssignmentsAsExcel: (query: string, callback?: any, resourceId: string = ""): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let fetchTask = fetch(API_BASE_URL + 'api/ExportToExcel/ExportExcel' + query, {
            credentials: 'include',
            headers: {
                'X-Resource-Id': resourceId
            }
        })
            .then(response => response.blob())
            .then(blob => {
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.href = url;
                link.id = "download_link";
                link.target = '_blank';
                link.setAttribute('download', 'TaxReturns_In_Process.xlsx');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                if (callback) {
                    callback();
                }
            })
            .catch((error: any) => {
                console.log(error);
            });
        addTask(fetchTask); // Ensure server-side prerendering waits for this to complete
    },
    checkAssignmentProperty: (documentId: Number, currentAssign: boolean, rowIndex: any, callBack: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let fetchTask = fetch(API_BASE_URL + 'api/TaxDocument/checkAssignment', {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8',
                'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
            },
            body: JSON.stringify(documentId)
        }).then(response => response.json())
            .then(data => {

                if (data === true && currentAssign === true) {
                    callBack(rowIndex);
                }
                else if (data === true && currentAssign === false) {
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: NotificationMessage.staffReturn.AssignmentChange,
                        statusType: Notification.StatusType.Warning
                    });
                }
                else if (data === false && currentAssign === false) {
                    callBack(rowIndex, false);
                }
                else if (data === false && currentAssign === true) {
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: NotificationMessage.staffReturn.AssignmentChange,
                        statusType: Notification.StatusType.Warning
                    });
                }
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: NotificationMessage.staffReturn.ServerError,
                    statusType: Notification.StatusType.Error
                });
            });
        addTask(fetchTask);
    },
    checkAssignmentPropertyAction: (documentId: Number, currentAssign: boolean, callBack: any, rowIndex?: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let fetchTask = fetch(API_BASE_URL + 'api/TaxDocument/checkAssignment', {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8',
                'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
            },
            body: JSON.stringify(documentId)
        }).then(response => response.json())
            .then(data => {

                if (data === true) {
                    callBack(rowIndex);
                }
                else {
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: NotificationMessage.staffReturn.AssignmentChange,
                        statusType: Notification.StatusType.Warning
                    });
                }

            })
            .catch(error => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: NotificationMessage.staffReturn.ServerError,
                    statusType: Notification.StatusType.Error
                });
            });
        addTask(fetchTask);
    },
    selectForDelivery: (documentId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: actionTypes.RECEIVE_SELECTED_DOCUMENT_FOR_DELIVERY, DocumentIds: [documentId] });
    },

    selectAllForDelivery: (documentIds: number[]): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: actionTypes.RECEIVE_SELECTED_DOCUMENT_FOR_DELIVERY, DocumentIds: documentIds });
    },

    unselectForDelivery: (documentId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: actionTypes.REMOVE_SELECTED_DOCUMENT_FOR_DELIVERY, DocumentIds: [documentId] });
    },
    unselectAllForDelivery: (documentIds: number[]): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: actionTypes.REMOVE_SELECTED_DOCUMENT_FOR_DELIVERY, DocumentIds: documentIds});
    }
};

const unloadedState: IAssignmentsState = {
    assignments: [] as ITaxReturn[],
    query: '',
    isLoading: false,
    totalRowCount: 0
} as IAssignmentsState;

export const reducer: Reducer<IAssignmentsState> = (state = unloadedState, incomingAction) => {
    const action = incomingAction as dispatchAction;
    switch (action.type) {
        case actionTypes.REQUEST_COMPANY_ASSIGNMENTS:
            return {
                ...unloadedState,
                query: action.query,
                isLoading: true
            } as IAssignmentsState;
        case actionTypes.RECEIVE_COMPANY_ASSIGNMENTS:
            return {
                query: action.query,
                assignments: action.assignments,
                totalRowCount: action.totalRowCount,
                isLoading: false
            } as IAssignmentsState;

        case actionTypes.RECEIVE_TAX_DOCUMENT:
            if (action.source !== TaxReturnSource.CompanyAssignments) {//Ignore calls originated from this store
                let newList: ITaxReturn[] = [];
                state.assignments.map((item, i) => {
                    newList.push((item.id === action.id) ? action.taxDocument : item);
                });
                return {
                    query: state.query,
                    assignments: newList,
                    totalRowCount: state.totalRowCount,
                    isLoading: false
                } as IAssignmentsState;
            }
            break;
        case actionTypes.RECEIVE_SELECTED_DOCUMENT_FOR_DELIVERY:
            let newReceivedList: ITaxReturn[] = [];
            state.assignments.map((taxReturn, j) => {
                if (taxReturn.documentStatus !== DocumentStatus.RECALLED) {
                    action.DocumentIds.map((id, k) => {
                        if (taxReturn.id === id) {
                            taxReturn.isSelectedForDelivery = true;
                        }
                    })
                }
                newReceivedList.push({ ...taxReturn });
            });
            return {
                query: state.query,
                assignments: newReceivedList,
                isLoading: state.isLoading
            } as IAssignmentsState;
        case actionTypes.REMOVE_SELECTED_DOCUMENT_FOR_DELIVERY:
            let newRemovedList: ITaxReturn[] = [];
            state.assignments.map((taxReturn, j) => {
                action.DocumentIds.map((id, k) => {
                    if (taxReturn.id === id) {
                        taxReturn.isSelectedForDelivery = false;
                    }
                })
                newRemovedList.push({ ...taxReturn });
            });
            return {
                query: state.query,
                assignments: newRemovedList,
                isLoading: state.isLoading
            } as IAssignmentsState;
        case actionTypes.DELETE_TAX_DOCUMENT:
        case actionTypes.RESET_TAX_RETURNS:
            //clearing the dictionary on deletion
            return {
                ...unloadedState,
                query: "",
                isLoading: true
            } as IAssignmentsState;
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            const exhaustiveCheck: never = action;
    }
    return state;
};
