

export interface IEFileSettings {
    eFileFeatureEnabled: boolean,
    eFileSettingsEnabled: boolean
}

export class EFileSettings implements IEFileSettings {
    eFileFeatureEnabled: boolean;
    eFileSettingsEnabled: boolean
}

export const initialEFileSettings: IEFileSettings = {
    eFileFeatureEnabled: false,
    eFileSettingsEnabled: false
}