import React from 'react';
import { Button, Modal } from 'react-bootstrap';

interface IKBADisableConfirmationModalProps {
    showReturnLevelKBADisablingConfirmationModal: boolean;
    onHide: () => void;
    onContinue: () => void;
}

const KBADisableConfirmationModal: React.FC<IKBADisableConfirmationModalProps> = (props) => {
    return (
        <Modal
            id="ReturnLevelKbaDisablingConfirmationModal"
            show={props.showReturnLevelKBADisablingConfirmationModal}
            onHide={props.onHide}
            className="return-level-kba-check"
        >
            <Modal.Header closeButton data-test-auto="8A023778-C357-4515-ACAE-927627BDCE1A">
                <Modal.Title className="custom-modal-header">
                    Confirmation
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <div className="row marB40 dispBlock">
                        <div className="col-lg-12 dispFlex">
                            <span>
                                You are about to disable KBA from this E-file form. Do you want to continue?
                            </span>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="success"
                    onClick={props.onContinue}
                    data-test-auto="FAB19361-9604-4E8F-AF3D-826EA16331EF"
                >
                    <i className="fas fa-check"></i>Continue
                </Button>
                <Button variant="default" onClick={props.onHide} data-test-auto="4C3A5550-A1DC-4179-AB3D-A85214E69B3D">
                    <i className="fas fa-times"></i>Cancel
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default KBADisableConfirmationModal;