import * as React from 'react';
import { Modal } from 'react-bootstrap'

let FileViewer = require('react-file-viewer');

interface FilePreviewProps {
    url: string,
    showPDFPreview: boolean;
    onHidePDFPreview: () => void;
    fileType: string | undefined;
    fileName: string;
}

export class FilePreview extends React.Component<FilePreviewProps, {}>{
    getModalBodyClass = (): string => {
        let modalClass: string = "filePreviewModalBody";
        if (this.props.fileType && this.props.fileType.toLowerCase() === "pdf") {
            modalClass = modalClass + " nopadding";
        }
        return modalClass;
    }

    render() {
        return <div>
            <Modal className="file-preview-modal" show={this.props.showPDFPreview} onHide={this.props.onHidePDFPreview}>
                <div style={{ height: 800 }} >
                    <Modal.Header closeButton data-test-auto="">
                        <Modal.Title>{this.props.fileName}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className={this.getModalBodyClass()}>
                        {this.props.fileType && this.props.fileType.toLowerCase() === "pdf" ? (
                            <object name={this.props.fileName} data={this.props.url + "#toolbar=0"} type="application/pdf"
                                height="90%" width="100%"
                                title={this.props.fileName}>
                                alt : <a href={this.props.url}>{this.props.fileName}</a>
                            </object>
                        ) : (
                            <div className="file-image-preview">
                                <FileViewer
                                    fileType={this.props.fileType != undefined ? this.props.fileType : ""}
                                    filePath={this.props.url}
                                />
                                </div>
                            )
                        }
                    </Modal.Body>
                </div>
            </Modal>
        </div >
    }

}
