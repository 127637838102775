import { ITaxReturn } from '../../../components/common/TaxReturn';
import { IFilters } from '../../../components/reports/Filters';
import { IGroupProcessReturnProps } from '../../../components/common/GroupLevelReturnProcess/GroupProcessReturnModal'

import { IProcessReturnProps } from '../../../components/common/ProcessReturnModal/ProcessReturnModal';
import { IGroupedReturnsModel, IGroupInfo, SelectOptions } from '../../../store/groupedreturns/GroupedReturnProcessState';
import { IUserModel } from '../../../Core/ViewModels/User/UserViewModel';
import { IUserDataFromDB, IUserGroupDataFromDB } from '../../../components/common/SetAccessCommonModal/SetAccessCommonModal.model';

export interface SendGroupedReturnsModel {
	groupId: number,
	groupName: string,
	taxSoftware: string,
	createdBy: string,
	createdOn: Date,
	status: string,
	setAccess: string,
	taxReturns: ITaxReturn[]
}

export interface SendGroupedReturnsTableModel {
	count: number,
	sendGroupedReturns: SendGroupedReturnsModel[]
}

export interface SendGroupedReturnsState {
	sendGroupedReturnsTableModel: SendGroupedReturnsTableModel;
	query: string;
	totalRowCount: number;
	loading: boolean;
	error: boolean;
}

export interface SendGroupedReturnsDictionary {
	[index: string]: SendGroupedReturnsState;
}

export interface IPopupState {
	show: boolean
}

export interface IGroupReturnPopupState extends IPopupState {    
	groupId: number | undefined
}

export interface SendGroupedReturnsPageState {

	page: number;
	pageSize: number;
	selectedRows: number[];
	filter: IFilters;
	searchString: string;
	sortName: string;
	sortOrder: string;
	saveFilterShow: boolean,
	deSelectedRows: number[];
	isSelectAllChecked: boolean;
	defaultGroup: string;

	filterGroupName: string,
	filterSoftwareType: string,
	filterCreatedBy: string,
	filterSetAccess: SetAccessType,
	filterCreatedOn?: Date

	isBulkSelectionEnabled: boolean;
	showBulkSelectionMessage: boolean;

	refreshDelay: boolean;

	selectedDocuments: number[];

	//Popups
	setAccessState: IPopupStateMultiple;
	showAssignModal: boolean;
	onAssignSuccessDocumentList: number[];
	reportProblemState: IPopupStateTaxReturnSingle;
	documentSignatureType:string;
	processReturnWindows: IPopupState;
	processReturnModalLoader: boolean;
	reProcessReturnModalLoader: boolean;
	groupProcessReturnModalLoader: boolean;
	processingDocumentId: number;

	editGroupControllerInfo: IGroupReturnPopupState;
	groupId: number;
	groupProcessReturnWindow: {
		selectedGroupId: number;
		selectedGroupName: string;
		taxReturns: ITaxReturn[];
		show: boolean;
	}
	editClientInfoState: IPopupStateTaxReturnSingle;
	changeStatusState: IPopupStateTaxReturnSingle;
	showLoader: boolean;
	defaultAssignUser: number;

	isGroupedReportProblem: boolean;
	groupReportModel: ITaxReturn
	editControllerInfoState: IPopupStateGroupController;
    recallReturnState: IPopupStateTaxReturnSingle;
	editGroupInfoState: IPopupStateGroupInfo;

    groupConfigurationState: IGroupConfigurationStateMultiple;
    addedGroup: IGroupInfo;
    selectedGroupId: number;
	previewReadyForDeliverySelection: SelectOptions[];
	selectedReturnDocumentIds: number[];
	selectedDocumentGuid: string;
	groupSSRChangeStatusUsers : IUserModel[];
    skipEroValidation: boolean;
    availableUsers: IUserDataFromDB[];
    selectedUsers: IUserDataFromDB[];
    availableUserGroups: IUserGroupDataFromDB[];
    selectedUserGroups: IUserGroupDataFromDB[];
}

export enum SetAccessType {
	None = 0,
	Everyone = 1,
	Restricted = 2
}

export interface GroupedReturnsDocuments {
	assignments: ITaxReturn[],
	loading: boolean,
}

export interface IPopupState {
	show: boolean
}

export interface IPopupStateSingle extends IPopupState {
	model: SendGroupedReturnsModel | undefined,
	saveCheckId: number | undefined
}

export interface IPopupStateMultiple extends IPopupState {
	selected: SendGroupedReturnsModel[] | undefined,
	saveCheckIds: number[] | undefined
	remove: boolean
}

export interface IGroupConfigurationStateMultiple extends IPopupState {
    selected: ITaxReturn[] | undefined;
    saveCheckIds: number[] | undefined;
   
}

export interface IPopupStateTaxReturnSingle extends IPopupState {
	model: ITaxReturn | undefined,
	saveCheckId: number | undefined
}

export interface IPopupStateGroupController extends IPopupState {
	model: IGroupInfo | undefined,
	saveCheckId: number | undefined
}

export interface IPopupStateGroupInfo extends IPopupState {
	model: IGroupInfo | undefined
}
