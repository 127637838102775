import * as React from 'react';
import Lightbox from 'react-images';
export interface CarouselImage {
	src: string;
	title: string;
	pageNo: number;
}
interface PageCarouselProps {
	open: boolean;
	images: CarouselImage[],
	currentImage: number,
	onClose: () => void;
}
interface PageCarouselState {
	images: any[];
	open: boolean;
	currentImage: number;
	title: string;
}
const theme = {
	thumbnail__active: {
		boxShadow: '0 0 0 2px #00D8FF',
	},
};
export class PageCarousel extends React.Component<PageCarouselProps, PageCarouselState> {
	state: PageCarouselState = {
		images: [],
		open: false,
		currentImage: 0,
		title: ""
	};

	componentDidMount() {
		this.createImages(this.props);
	}

	UNSAFE_componentWillReceiveProps(props: PageCarouselProps) {
		this.createImages(props);
	}

	gotoPrevious = () => {
		this.setState({
			currentImage: this.state.currentImage - 1,
			title: this.props.images[this.state.currentImage - 1].title
		});
	}
	gotoNext = () => {
		this.setState({
			currentImage: this.state.currentImage + 1,
			title: this.props.images[this.state.currentImage + 1].title
		});
	}
	gotoImage = (index: number) => {
		this.setState({
			currentImage: index,
			title: this.props.images[index] && this.props.images[index].title
		});
	}
	private createImages = (props: PageCarouselProps) => {

		this.setState({
			images: props.images,
			open: props.open,
			title: props.images[props.currentImage] && props.images[props.currentImage].title,
			currentImage: props.currentImage,
		});
	}
	public render() {
		return (
			<Lightbox
				customControls={[
					<h4 style={{ color: "white" }}>
						{this.state.title}
					</h4> as React.ReactHTMLElement<HTMLHeadingElement>
				]}
				showThumbnails={true}
				onClose={this.props.onClose}
				currentImage={this.state.currentImage}
				onClickNext={this.gotoNext}
				onClickPrev={this.gotoPrevious}
				onClickThumbnail={this.gotoImage}
				images={this.state.images} isOpen={this.state.open}
				
			/>
		);
	}
}
