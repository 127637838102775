import * as React from "react";
import ControllerInfoProperty from "./ControllerInfoEnum";
import { IGroupInfo, IControllerInfo } from "../../store/groupedreturns/GroupedReturnProcessState";
import { Guid } from "../../Core/Utilities/Guid";
import { isValidEmailAddress } from "../helper/Validations";
import { FilterSSN, handleInvalidCountryCodeEntered } from "../helper/HelperFunctions";
import TaxIdentificationNumber from "./controls/TaxIdentificationNumber";
import "@sssuite-component-ui/react-phone-input-2/lib/style.css";
import PhoneInput, { CountryData } from "@sssuite-component-ui/react-phone-input-2";

interface IControllerInfoProps {
    groupInfo: IGroupInfo;
    updateControllerInfoData: (data: IControllerInfo) => void;
    markControllerEmailAsUpdated: () => void;
}

interface IControllerInfoState {
    mobileNumberFullValue?: string;
}

export class EditControllerInfo extends React.Component<IControllerInfoProps, IControllerInfoState> {
    constructor(props: IControllerInfoProps) {
        super(props);
    }
    state = {
        mobileNumberFullValue: undefined
    };

    private handleControllerFieldChange = (item: ControllerInfoProperty, value: any) => {
        switch (item) {
            case ControllerInfoProperty.controllerName:
                this.props.groupInfo.controllerInfo.name = value;
                break;
            case ControllerInfoProperty.controllerMobileNumber:
                this.props.groupInfo.controllerInfo.mobileNumber = value;
                break;
            case ControllerInfoProperty.controllerEmail: {
                this.props.groupInfo.controllerInfo.emailAddress = value || "";
                this.props.markControllerEmailAsUpdated();
                if (isValidEmailAddress(value) && this.props.groupInfo.controllerInfo.clientGuid.toString() == "") {
                    this.props.groupInfo.controllerInfo.clientGuid = Guid.newGuid();
                }
                break;
            }
            case ControllerInfoProperty.controllerSSN:
                this.props.groupInfo.controllerInfo.ssn = value.replace(/-/g, "");
                break;
            case ControllerInfoProperty.controllerCountryCode:
                this.props.groupInfo.controllerInfo.countryCode = value;
                break;
        }
        this.props.updateControllerInfoData(this.props.groupInfo.controllerInfo);
    };

    onMobileChange = (fullValue: string, countryObject: CountryData): void => {
        fullValue = handleInvalidCountryCodeEntered(fullValue, countryObject);
        this.handleControllerFieldChange(
            ControllerInfoProperty.controllerCountryCode,
            fullValue ? `+${countryObject.dialCode}` : ""
        );
        this.handleControllerFieldChange(
            ControllerInfoProperty.controllerMobileNumber,
            fullValue.slice(countryObject.dialCode.length)
        );
        this.setState({ mobileNumberFullValue: fullValue });
    };

    public render() {
        return (
            <div className="tab-content-width">
                <div className="row marL0  marT10">
                    <div className="col-sm-12 col-md-12 col-xl-12">
                        <div className="row">
                            <div className="col-sm-2 col-md-2 col-xl-2 padT07 tpLabelFont">
                                Name<span className="fas fa-asterisk compulsory-star"></span>
                            </div>
                            <div className="col-sm-10 col-md-10 col-xl-10 marB10 padR25">
                                <input
                                    type="text"
                                    className="form-control"
                                    value={this.props.groupInfo?.controllerInfo?.name}
                                    onChange={(event: any) => {
                                        this.handleControllerFieldChange(
                                            ControllerInfoProperty.controllerName,
                                            event.target?.value
                                        );
                                    }}
                                    placeholder="Enter Name"
                                    data-test-auto="{DD651E51-CC28-4992-84AE-49DDADF882D5}"
                                ></input>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row marL0">
                    <div className="col-sm-12 col-md-12 col-xl-12">
                        <div className="row">
                            <div className="col-sm-2 col-md-2 col-xl-2 padT07 tpLabelFont">
                                Email<span className="fas fa-asterisk compulsory-star"></span>
                            </div>
                            <div className="col-sm-10 col-md-10 col-xl-10 marB10 padR25">
                                <input
                                    type="text"
                                    className="form-control"
                                    value={this.props.groupInfo?.controllerInfo?.emailAddress}
                                    onChange={(event: any) => {
                                        this.handleControllerFieldChange(
                                            ControllerInfoProperty.controllerEmail,
                                            event.target?.value
                                        );
                                    }}
                                    placeholder="Enter Email"
                                    data-test-auto="{E13E6C20-2030-45FB-9197-72FD7418F123}"
                                ></input>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row marL0">
                    <div className="col-sm-12 col-md-12 col-xl-12">
                        <div className="row">
                            <div className="col-sm-2 col-md-2 col-xl-2 padT07 tpLabelFont">Mobile</div>
                            <div className="col-sm-10 col-md-10 col-xl-10 marB10 padR25">
                                <PhoneInput
                                    specialLabel=""
                                    country={"us"}
                                    autoFormat={true}
                                    value={
                                        this.state.mobileNumberFullValue ??
                                        `${this.props.groupInfo?.controllerInfo?.countryCode}${
                                            this.props.groupInfo?.controllerInfo?.mobileNumber
                                                ? this.props.groupInfo?.controllerInfo.mobileNumber
                                                : ""
                                        }`
                                    }
                                    onChange={this.onMobileChange}
                                    disabled={false}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row marL0">
                    <div className="col-sm-6 col-md-6 col-xl-6">
                        <div className="row">
                            <div className="col-sm-4 col-md-4 col-xl-4 padT07 tpLabelFont">SSN</div>
                            <div className="col-sm-8 col-md-8 col-xl-8 marB10">
                                <TaxIdentificationNumber
                                    value={
                                        this.props.groupInfo?.controllerInfo
                                            ? FilterSSN(this.props.groupInfo?.controllerInfo.ssn)
                                            : ""
                                    }
                                    onChange={(value: string) => {
                                        this.handleControllerFieldChange(ControllerInfoProperty.controllerSSN, value);
                                    }}
                                    placeholder="Enter SSN"
                                    mask="000-00-0000"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
