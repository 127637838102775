let moment = require("moment");
import * as React from "react";
import { Col, Nav } from "react-bootstrap";
import { DocumentUploadDropzoneComponent } from "../../../common/UploadDocument/DocumentUploadDropzoneComponent";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import * as Enumerable from "linq";
import { iconType, getFileSize } from "../../../helper/HelperFunctions";
import { IAttachment, ITaxReturn } from "../../TaxReturn";
import { validateFileTypes, fileContentValidation, GetSafeFilename } from "../../../helper/Validations";
import { VenusNotifier } from "../../../helper/VenusNotifier";
import * as Bs from "react-bootstrap";
import "react-bootstrap-table/css/react-bootstrap-table.css";
import * as bootbox from "bootbox";
import {
    UploadFunctions,
    isFileExist,
    getFileExtension,
    removeSpecialCharacters,
    GetFileMagicNumber
} from "@sssuite-js-packages/file-utility";
import { AttachmentConstants, UploadtaxReturnConstants, AttachmentInstructionsConstants } from "../../../helper/Constants";
import * as AttachmentsState from "../../../../store/ProcessReturn/AttachmentsState";
import { AddViewAttachmentInstruction } from "../Attachments/AddViewAttachmentInstruction";
import { FilePreview } from "../../../common/FilePreview";
import { TabContentContainer } from "./TabContentContainer/TabContentContainer";
import { ProcessReturnTabs } from "../ProcessReturnModels";
import * as AttachmentInstructionStore from "../../../../store/settings/AttachmentInstructionStore";

export interface AttachmentsProps {
    Attachment: AttachmentsState.AttachmentsState;
    requestAttachments: (
        taxDocument: ITaxReturn,
        fileName: string,
        file: any,
        processData: (data: AttachmentsState.AttachmentsTableModel, fileName: string, file: any) => void
    ) => void;
    downloadAttachments: (
        taxReturn: ITaxReturn,
        fileName: string,
        documentId: number,
        attachmentId: number,
        isArchivedReturn: boolean,
        callback?: (url: string, fileName: string) => void
    ) => void;
    previewAttachments: (
        taxReturn: ITaxReturn,
        fileName: string,
        documentId: number,
        attachmentId: number,
        isArchivedReturn: boolean,
        callback: (url: string, fileName: string) => void
    ) => void;
    setPageBusy: (isBusy: boolean, processReturnTabs: ProcessReturnTabs) => void;
    defaultInstructionId: number;
    attachmentInstructions: AttachmentInstructionStore.IAttachmentInstructionDictionary;
    isGroupReturn?: boolean;
}

const initialAttachment = {
    fileName: "",
    fileExtension: "fas fa-file",
    fileSize: "",
    instruction: "",
    number: 0,
    progressBar: 0,
    status: "",
    uploadedBy: 0,
    uploadedTime: new Date(),
    documentId: 0,
    id: 0,
    uploadedUser: "",
    isDeleted: false,
    selectedInstructionId: 0
};

export class TabAttachment extends React.Component<AttachmentsProps, AttachmentsState.AttachmentsPagesState> {
    constructor(props: AttachmentsProps) {
        super(props);
        this.state = {
            process: 0,
            gridColumnDisable: true,
            config: {
                dropzoneSelector: "div.filepicker",
                showFiletypeIcon: true,
                postUrl: "/api/"
            },
            AttachmentsTableModel: [],
            attachment: [],
            showInstruction: false,
            selectedRow: initialAttachment,
            userName: "",
            userId: 0,
            sasContainer: [],
            url: "",
            showPDFPreview: false,
            fileType: "",
            fileName: ""
        };
        this.handleUploaderClose = this.handleUploaderClose.bind(this);
        this.uploadProgressCallback = this.uploadProgressCallback.bind(this);
        this.onHideInstruction = this.onHideInstruction.bind(this);
        this.onInstructionSubmit = this.onInstructionSubmit.bind(this);
        this.downloadAttachment = this.downloadAttachment.bind(this);
        this.proccessData = this.proccessData.bind(this);
        this.deleteAttachment = this.deleteAttachment.bind(this);
        this.onHidePDFPreview = this.onHidePDFPreview.bind(this);
        this.showFilePreview = this.showFilePreview.bind(this);
        this.getPreviewURL = this.getPreviewURL.bind(this);
    }

    private handleClose() {
        // this.props.onHide();
    }

    componentDidMount() {
        this.setState({ AttachmentsTableModel: this.props.Attachment.attachments });
    }

    UNSAFE_componentWillReceiveProps(nextProps: AttachmentsProps) {
        let _gridData = nextProps.Attachment.attachments;
        this.setState({ AttachmentsTableModel: _gridData });
    }

    private handleUploaderClose() {
        let _self = this;
        if (this.state.AttachmentsTableModel.length > 0) {
            bootbox.confirm({
                message: UploadtaxReturnConstants.CloseConfirmationMessage,
                buttons: {
                    cancel: {
                        label: '<i class="fas fa-times"></i> Cancel',
                        className: "btn-white btn-default"
                    },
                    confirm: {
                        label: '<i class="fas fa-check"></i> OK',
                        className: "btn-info"
                    }
                },
                callback: (result: boolean) => {
                    if (result) {
                        _self.handleClose();
                    }
                }
            });
        } else {
            _self.handleClose();
        }
    }

    public getPreviewURL(row: any) {
        this.props.previewAttachments(
            this.props.Attachment?.taxReturn,
            row.fileName,
            row.documentId,
            row.id,
            false,
            (url, fileName) => {
                this.setState({
                    url: url,
                    showPDFPreview: true,
                    fileType: getFileExtension(fileName).toLowerCase(),
                    fileName: fileName
                });
            }
        );
    }

    //===========================================================================
    // Bootstrap Table Formatters start
    //===========================================================================

    defaultType(cell: any, row: any) {
        return cell;
    }

    progressbar(cell: any, row: any) {
        return (
            <Bs.ProgressBar
                striped
                hidden={cell === 100 || cell === undefined}
                variant={cell !== undefined && cell !== 100 ? "warning" : "success"}
                now={cell}
            />
        );
    }

    linkFormat(cell: any, row: any) {
        return (
            <Nav.Link
                href="javascript:"
                onClick={() => this.showFilePreview(row)}
                data-test-auto="7FDF51B6-41F6-4E58-9A03-C52D36983AF0"
                title={cell}
            >
                {cell}
            </Nav.Link>
        );
    }

    iconFormat(cell: any, row: any) {
        let fileIconImage = iconType(cell);
        return (
            <div className={fileIconImage.fileIcon}>
                <i
                    className={fileIconImage.fileImg}
                    style={{ fontSize: "20px" }}
                    data-test-auto="BDED2B3F-5EBA-4114-9E4B-863F7047511F"
                ></i>
            </div>
        );
    }

    DownloadFormat(cell: any, row: any) {
        return (
            <DownloadButtonFormatter
                downloadAttachment={() => this.downloadAttachment(row)}
                disabled={row.progressBar !== undefined && row.progressBar !== 100 ? true : false}
                data-test-auto="AD3B735C-70FA-4068-BD00-F3B8B984C5D0"
                isAssignedToLoggedinUser={this.props.Attachment.isAssignedToLoggedinUser}
            />
        );
    }

    addButtonFunction(cell: any, row: any) {
        return (
            <AddButtonFormatter
                showAddViewInstruction={() => this.showAddViewInstruction(row)}
                data-test-auto="AD3B735C-70FA-4068-BD00-F3B8B984C5D0"
                isAssignedToLoggedinUser={this.props.Attachment.isAssignedToLoggedinUser}
                instructionDisplay={cell}
            />
        );
    }

    removeButtonFunction(cell: any, row: any) {
        return (
            <RemoveButtonFormatter
                deleteAttachment={() => this.deleteAttachment(row)}
                disabled={row.progressBar !== undefined && row.progressBar !== 100 ? true : false}
            />
        );
    }

    //===========================================================================
    // Bootstrap Table Formatters End
    //===========================================================================

    eventHandlers = {
        addedfiles: (files: any) => {
            let _self = this;
            let tempAttachmentsTableModel = this.state.AttachmentsTableModel;
            let _files = Enumerable.from(files).toArray();

            if (_files.length > 0) {
                this.props.setPageBusy(true, ProcessReturnTabs.Attachments);
                _files.map((file: any, i: number) => {
                    let fileName = removeSpecialCharacters(file.name);
                    let fileExtension = getFileExtension(fileName);
                    fileName = fileName.replace("." + fileExtension, "");
                    fileName = GetSafeFilename(fileName);
                    fileName = fileName + "." + fileExtension;
                    GetFileMagicNumber(file).then((result) => {
                        let filecount = 1;
                        let fileExtension = getFileExtension(fileName);
                        let dupfileName = fileName;
                        if (validateFileTypes(fileExtension) && fileContentValidation(result)) {
                            while (this.isFileExist(dupfileName, tempAttachmentsTableModel)) {
                                dupfileName = fileName;
                                dupfileName = dupfileName.replace("." + fileExtension, "");
                                dupfileName = dupfileName + " - Copy (" + filecount + ")." + fileExtension;
                                filecount++;
                            }
                            fileName = dupfileName;
                            let _fetchThis = this;
                            if (file?.size != 0 && getFileSize(file.size) != "0 KB") {
                                if (file.size < AttachmentConstants.MaxFileSize) {
                                    let fileIconImage = iconType(fileName);
                                    tempAttachmentsTableModel.push({
                                        fileName: fileName,
                                        progressBar: this.state.process,
                                        fileSize: file.size,
                                        number: tempAttachmentsTableModel.length + 1,
                                        status: "Uploading...",
                                        uploadedBy: this.props.Attachment.userProfile?.userId || 0,
                                        uploadedTime: new Date(),
                                        instruction: this.props.attachmentInstructions[this.props.defaultInstructionId]
                                            ? this.props.attachmentInstructions[this.props.defaultInstructionId]
                                                .attachmentInstruction.text
                                            : "",
                                        documentId: this.props.Attachment.documentId,
                                        id: 0,
                                        sasGuid: "",
                                        uploadedUser:
                                            this.props.Attachment.userProfile.firstName +
                                            " " +
                                            this.props.Attachment.userProfile.lastName,
                                        url: "",
                                        isDeleted: false,
                                        fileExtension: fileIconImage.fileImg,
                                        selectedInstructionId: this.props.defaultInstructionId
                                    });
                                    this.setState({ AttachmentsTableModel: tempAttachmentsTableModel }, () => {
                                        this.props.requestAttachments(
                                            this.props.Attachment?.taxReturn,
                                            fileName,
                                            file,
                                            this.proccessData
                                        );
                                    });
                                } else {
                                    VenusNotifier.Warning(AttachmentConstants.FilesizeWarning, "");
                                }
                            } else {
                                VenusNotifier.Warning(AttachmentConstants.validDocument, "");
                            }
                        }
                    });
                });
            }
        }
    };

    isFileExist = (fileName: string, attachments: IAttachment[]): boolean => {
        var found = false;
        for (var i = 0; i < attachments.length; i++) {
            if (attachments[i].fileName === fileName) {
                found = true;
                break;
            }
        }

        return found;
    }

    public uploadProgressCallback(percent: number, fileToUpload: any) {
        let tempAttachmentsTableModel = this.state.AttachmentsTableModel;

        tempAttachmentsTableModel.map((tempAttachmentsTableModelValue, index) => {
            if (tempAttachmentsTableModelValue.fileName == fileToUpload.fileName) {
                if (percent == 100) {
                    tempAttachmentsTableModelValue.progressBar = percent - 10;
                } else {
                    tempAttachmentsTableModelValue.progressBar = percent;
                }
            }
        });
        if (this.refs.refAttachmentTable) {
            this.setState(
                {
                    AttachmentsTableModel: tempAttachmentsTableModel
                },
                () => this.props.Attachment.saveAttachments(this.state.AttachmentsTableModel)
            );
        } else {
            this.props.Attachment.saveAttachments(this.state.AttachmentsTableModel);
        }
    }

    private isAllAttachmentsUploded = () => {
        this.props.setPageBusy(false, ProcessReturnTabs.Attachments);
    };

    public uploadCompleteCallback = (fileToUpload: any) => {
        let tempAttachmentsTableModel = this.state.AttachmentsTableModel;

        tempAttachmentsTableModel.map((tempAttachmentsTableModelValue, index) => {
            if (tempAttachmentsTableModelValue.fileName == fileToUpload.fileName) {
                tempAttachmentsTableModelValue.progressBar = 100;
                tempAttachmentsTableModelValue.status = "Uploaded";
            }
        });
        if (this.refs.refAttachmentTable) {
            this.setState(
                {
                    AttachmentsTableModel: tempAttachmentsTableModel
                },
                () => this.props.Attachment.saveAttachments(this.state.AttachmentsTableModel)
            );
        } else {
            this.props.Attachment.saveAttachments(this.state.AttachmentsTableModel);
        }

        let isAllFileUploaded: boolean = true;
        for (let i = 0; i < this.props.Attachment.attachments.length; i++) {
            if ((this.props.Attachment.attachments[i].progressBar as number) < 100) {
                isAllFileUploaded = false;
                break;
            }
        }
        if (isAllFileUploaded) {
            this.isAllAttachmentsUploded();
        }
    };

    //Implementation of this callback will be done later
    uploadFailureCallback = (fileToUpload: any) => {
        console.log("upload failed for file:", fileToUpload.fileName);
    };

    public proccessData = (data: any, fileName: string, file: any) => {
        let tempAttachmentsTableModel = this.state.AttachmentsTableModel;
        let index = tempAttachmentsTableModel.findIndex((x) => x.fileName === fileName);
        try {
            if (data) {
                tempAttachmentsTableModel[index].sasGuid = data.guid;
                tempAttachmentsTableModel[index].url = data.sas;
                let uploadHelperFunctions = new UploadFunctions();
                if (this.refs.refAttachmentTable) {
                    this.setState({ AttachmentsTableModel: tempAttachmentsTableModel }, () =>
                        uploadHelperFunctions.uploadFile(
                            file,
                            data,
                            fileName,
                            this.uploadProgressCallback,
                            this.uploadCompleteCallback,
                            this.uploadFailureCallback
                        )
                    );
                }
            } else {
                tempAttachmentsTableModel.splice(index, 1);
                this.setState({ AttachmentsTableModel: tempAttachmentsTableModel });
            }
        } catch (err) {
            tempAttachmentsTableModel.splice(index, 1);
            this.setState({ AttachmentsTableModel: tempAttachmentsTableModel });
        }
    };

    deleteAttachment(row: any) {
        let _self = this;

        bootbox.confirm({
            message: UploadtaxReturnConstants.DeleteFileMessage,
            buttons: {
                cancel: {
                    label: '<i class="fas fa-times"></i> Cancel',
                    className: "btn-white btn-default"
                },
                confirm: {
                    label: '<i class="fas fa-check"></i> OK',
                    className: "btn-info"
                }
            },
            callback: (result: boolean) => {
                if (result) {
                    var _gridData = [..._self.state.AttachmentsTableModel];

                    for (let i = _gridData.length - 1; i >= 0; i--) {
                        let value = _gridData[i];
                        if (value.fileName === row.fileName) {
                            if (value.id == 0) {
                                _gridData.splice(i, 1);
                            } else {
                                value.isDeleted = true;
                            }
                        }
                    }

                    let _uploadData = [..._self.state.sasContainer];
                    _uploadData = _uploadData.filter((i) => i.sasGuid != row.sasGuid);

                    let _tempUploadData = _uploadData;
                    if (_gridData.length == 0) {
                        _self.setState({ gridColumnDisable: true });
                    }
                    _self.setState({ AttachmentsTableModel: _gridData, sasContainer: _tempUploadData });
                    _self.props.Attachment.saveAttachments(_gridData);
                }
            }
        });
    }

    public showAddViewInstruction(row: any) {
        if (row.id === 0 && row.progressBar !== 100) {
            VenusNotifier.Warning(AttachmentConstants.UploadingIncomplete, "");
        } else {
            this.setState({
                showInstruction: true,
                selectedRow: row
            });
        }
    }

    public showFilePreview(row: any) {
        this.getPreviewURL(row);
    }

    public onHideInstruction() {
        this.setState({
            selectedRow: initialAttachment,
            showInstruction: false
        });
    }

    private onHidePDFPreview() {
        this.setState({
            showPDFPreview: false
        });
    }

    public onInstructionSubmit(instruction: string, instructionId: number) {
        let tempGridData = this.state.AttachmentsTableModel;
        let selecteIndex: number = tempGridData.findIndex(
            (i) => i.fileName == this.state.selectedRow.fileName && i.isDeleted !== true
        );
        tempGridData[selecteIndex].instruction = instruction;
        tempGridData[selecteIndex].selectedInstructionId = instructionId;
        this.setState({
            selectedRow: initialAttachment,
            showInstruction: false
        });
        this.props.Attachment.saveAttachments(tempGridData);
        VenusNotifier.Success(AttachmentInstructionsConstants.UpdateSuccess, "");
    }

    public downloadAttachment(row: any) {
        this.props.downloadAttachments(this.props.Attachment?.taxReturn, row.fileName, row.documentId, row.id, false);
    }

    fileSize(cell: any, row: any) {
        return getFileSize(cell);
    }

    uploadedTime(cell: any) {
        return moment.utc(cell).local().format("MM/DD/YYYY");
    }

    public render() {
        let tableOptions = {
            noDataText: " "
        };
        let djsConfig: any = {
            uploadMultiple: true,
            addRemoveLinks: true,
            headers: { "Access-Control-Allow-Origin": "*", "x-ms-blob-type": "BlockBlob" },
            previewsContainer: false,
            autoProcessQueue: false,
            autoDiscover: false
        };
        let gridData =
            this.state.AttachmentsTableModel.length > 0 ? this.state.AttachmentsTableModel : this.props.Attachment.attachments;
        gridData = gridData.filter((i) => i.isDeleted === false);
        const columns = [
            {
                header: "FileIcon",
                key: "fileName",
                isKey: false,
                dataFormat: this.iconFormat,
                width: "50px",
                hidden: false,
                fontsize: "25px"
            },
            {
                header: "Name",
                key: "fileName",
                isKey: true,
                dataFormat: this.linkFormat.bind(this),
                width: "180px",
                hidden: false,
                fontsize: "10px"
            },
            {
                header: "Upload Progress",
                key: "progressBar",
                isKey: false,
                dataFormat: this.progressbar,
                width: "100px",
                hidden: false,
                fontsize: "10px"
            },
            {
                header: "Status",
                key: "status",
                isKey: false,
                dataFormat: this.defaultType,
                width: "auto",
                hidden: true,
                fontsize: "10px"
            },
            {
                header: "Size",
                key: "fileSize",
                isKey: false,
                dataFormat: this.fileSize,
                width: "auto",
                hidden: false,
                fontsize: "10px"
            },
            {
                header: "Uploaded Time",
                key: "uploadedTime",
                isKey: false,
                dataFormat: this.uploadedTime.bind(this),
                width: "auto",
                hidden: false,
                fontsize: "10px"
            },
            {
                header: "Uploaded By",
                key: "uploadedUser",
                isKey: false,
                dataFormat: this.defaultType,
                width: "auto",
                hidden: false,
                fontsize: "10px"
            },
            {
                key: "instruction",
                isKey: false,
                dataFormat: this.addButtonFunction.bind(this),
                width: "150",
                hidden: false,
                fontsize: "10px"
            },
            {
                header: "Download",
                key: "Download",
                isKey: false,
                dataFormat: this.DownloadFormat.bind(this),
                width: "auto",
                hidden: false,
                fontsize: "10px"
            },
            {
                key: "remove_button",
                isKey: false,
                dataFormat: this.removeButtonFunction.bind(this),
                width: "auto",
                hidden: false,
                fontsize: "10px"
            }
        ];
        //const data = this.state.AttachmentsTableModel.
        return (
            <TabContentContainer enableScroll={true}>
                <div className="upload-doc-modal dispFlex">
                    <Col md={3} sm={12}>
                        <div>
                            <div className="title">
                                {" "}
                                <h5 className="font12" style={{ paddingLeft: "24px" }}>
                                    {AttachmentConstants.AddAttachments}
                                </h5>
                            </div>
                            <div>
                                <div
                                    className="col-sm-12 text-center"
                                    style={{ paddingTop: "15px", background: "#9494940f", height: "235px" }}
                                >
                                    <div style={{ border: " 4px dashed #bababa", padding: "8px" }}>
                                        <DocumentUploadDropzoneComponent
                                            componentConfig={this.state.config}
                                            djsConfig={djsConfig}
                                            eventHandlers={this.eventHandlers}
                                            autoTestId={"0A606D5E-A8A2-4E6D-B74B-693AC1003D78"}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col md={9} sm={12}>
                        <div>
                            <div className="title">
                                <h5 className="font12" style={{ paddingLeft: "16px" }}>
                                    {AttachmentConstants.UploadedFiles}
                                </h5>
                            </div>
                            <div id="attachmentTable">
                                <BootstrapTable
                                    ref="refAttachmentTable"
                                    bodyStyle={{ border: 0 }}
                                    data={gridData}
                                    bordered={false}
                                    options={tableOptions}
                                >
                                    {columns.map((value, index) => {
                                        var isKsey = value.isKey ? "isKey" : "";
                                        return (
                                            <TableHeaderColumn
                                                className="noborder"
                                                key={index}
                                                isKey={value.isKey}
                                                dataField={value.key}
                                                width={value.width}
                                                hidden={value.hidden}
                                                dataFormat={value.dataFormat}
                                                dataAlign={"left"}
                                                tdStyle={{ borderTop: "0px", borderBottom: "0px" }}
                                                thStyle={{ borderTop: "0px", borderBottom: "0px" }}
                                            >
                                                {" "}
                                            </TableHeaderColumn>
                                        );
                                    })}
                                </BootstrapTable>
                                <AddViewAttachmentInstruction
                                    instructionMessage={this.state.selectedRow.instruction}
                                    instructionId={this.state.selectedRow.selectedInstructionId}
                                    attachmentInstructions={this.props.attachmentInstructions}
                                    onHide={this.onHideInstruction}
                                    onSubmitInstruction={this.onInstructionSubmit}
                                    showState={this.state.showInstruction}
                                    isGroupReturn={this.props.isGroupReturn}
                                />
                                <FilePreview
                                    url={this.state.url}
                                    showPDFPreview={this.state.showPDFPreview}
                                    onHidePDFPreview={this.onHidePDFPreview}
                                    fileType={this.state.fileType}
                                    fileName={this.state.fileName}
                                />
                            </div>
                        </div>
                    </Col>
                </div>
            </TabContentContainer>
        );
    }

    private onPageSelect = (page: number) => { };

    public onPageDelete = (page: number) => { };
}

interface buttonItem {
    showAddViewInstruction(event: any): void;
    isAssignedToLoggedinUser?: boolean;
    instructionDisplay: string;
}

class AddButtonFormatter extends React.Component<buttonItem, {}> {
    render() {
        return (
            <Nav.Link
                href="javascript:"
                onClick={this.props.showAddViewInstruction}
                data-test-auto="E9D02EB3-E3F0-494C-A5F4-A688ED4B7E87"
            >
                {this.props.instructionDisplay ? "View Instruction" : "Add Instruction"}
            </Nav.Link>
        );
    }
}

interface IDeleteAttachment {
    deleteAttachment: any;
    disabled: boolean;
}

export class RemoveButtonFormatter extends React.Component<IDeleteAttachment, {}> {
    render() {
        return (
            <button
                type="button"
                className="btn-white btn-lg"
                onClick={this.props.deleteAttachment}
                data-test-auto="ED580997-E4E5-4DA1-9A56-1EA1538E33EB"
                disabled={this.props.disabled}
                style={{ border: "0px", padding: "2px 5px 0px 5px" }}
            >
                <i className="text-danger fas fa-times" style={{ backgroundColor: "white", fontSize: "19px" }}></i>
            </button>
        );
    }
}

interface IDownloadAttachment {
    downloadAttachment(event: any): void;
    isAssignedToLoggedinUser?: boolean;
    disabled: boolean;
}

export class DownloadButtonFormatter extends React.Component<IDownloadAttachment, {}> {
    render() {
        return (
            <button
                type="button"
                className="btn-white btn-lg attachmentDownload"
                style={{ padding: "2px 5px 0px 5px" }}
                data-test-auto="AE822E79-1E27-4EF4-8C9F-ADFC1F842576"
                disabled={this.props.disabled}
                onClick={this.props.downloadAttachment}
            >
                <i className="fa fa-download" style={{ color: "#333", backgroundColor: "white", fontSize: "17px" }}></i>
            </button>
        );
    }
}
//=============================================================================
