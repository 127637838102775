import * as React from 'react';
import { Modal, Button, ProgressBar, Col } from 'react-bootstrap';
import { ButtonFormatter } from '../../UploadDocument/TaxSoftwareTypeUpload/UploadCommonFormatters';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { VenusNotifier } from '../../../helper/VenusNotifier';
import { K1Attachments } from '../../../helper/Constants';
import { SasContainer } from '../../../../Core/ViewModels/Common/UploadDocumentViewModel';
import { IBlobFile } from '../../../../store/common';
import { ITaxReturn } from '../../TaxReturn';
import { IK1Attachment, K1AttachmentUploadData } from './IK1Attachment';
import * as bootbox from 'bootbox';
import { CustomMultiSelect } from '../../MultipleSelectComponent';
import { IBookmarkSection } from '../ProcessReturnModels';

let tableOptions = {
    noDataText: K1Attachments.emptyFileListMessage
};

export interface K1AttachmentUploadProps {
    taxReturn: ITaxReturn;
    showUploadAttachment: boolean;
    onCloseModal: () => void;
    maxFileLimitUpload: number;
    onSubmit: (updateAttachmentData: IK1Attachment[], selectedBookmarks: number[]) => void;
    getUploadLink: (url: string, callback?: (data?: IBlobFile) => void) => void;
    bookmarks: IBookmarkSection[];
    selectedK1Attachment: IK1Attachment[];
}

export interface K1AttachmentUploadState {
    k1AttachmentData: K1AttachmentUploadData[],
    sasContainer: SasContainer[],
    config: {
        dropzoneSelector: string,
        iconFiletypes: ['.pdf'],
        showFiletypeIcon: boolean,
        postUrl: string
    },
    bookmarkOptions: any,
    selectedBookmarks: number[],
    isDeleted: boolean
}

export class K1AttachmentEditModal extends React.Component<K1AttachmentUploadProps, K1AttachmentUploadState>{
    private customMultiSelect: any;

    constructor(props: K1AttachmentUploadProps) {
        super(props);

        this.state = {
            k1AttachmentData: [],
            sasContainer: [],
            config: {
                dropzoneSelector: 'div.filepicker',
                iconFiletypes: ['.pdf'],
                showFiletypeIcon: true,
                postUrl: "/api/"
            },
            bookmarkOptions: [],
            selectedBookmarks: [],
            isDeleted: false
        }
    }

    componentDidMount(): void {
        if (this.props.bookmarks) {
            this.getK1Attachments();
        }

        if (this.props.selectedK1Attachment) {
            const selectedBookmarks: number[] = [];
            this.props.selectedK1Attachment[0].k1AttachmentDetails.forEach((item: any) => selectedBookmarks.push(item.shareholderId));

            if (this.props.bookmarks[0].pages.length === selectedBookmarks.length) {
                this.setState({ selectedBookmarks: [ 0, ...selectedBookmarks ] });
            } else {
                this.setState({ selectedBookmarks });
            }
        }
    }

    private isUploadOnGoing = (attachmentData: K1AttachmentUploadData[]): boolean => {
        let isuploading: boolean = false;
        attachmentData.map((value) => {
            if (value.progressBar == undefined ? 0 : value.progressBar < 100) {
                isuploading = true; return;
            }
        });
        return isuploading;
    }

    defaultType(cell: any, row: any) {
        return cell;
    }

    progressbar(cell: any, row: any) {
        return <ProgressBar striped
            variant={(cell != undefined) && (cell != 100) ? "warning" : "success"}
            now={cell} />
    }

    deleteDocument = (row: any) => {
        let _self = this;
        bootbox.confirm({
            message: K1Attachments.deleteConfirmMessage,
            buttons: {
                cancel: {
                    label: '<i class="fas fa-times"></i> Cancel',
                    className: 'btn-white btn-default'
                },
                confirm: {
                    label: '<i class="fas fa-check"></i> OK',
                    className: 'btn-info'
                }
            },
            callback: (result: boolean) => {
                if (result) {
                    var _gridData = [..._self.state.k1AttachmentData];
                    _gridData = _gridData.filter(i => i.fileName != row.fileName);

                    //Update row index
                    for (let i = 0; i < _gridData.length; i++) {
                        _gridData[i].rowIndex = i;
                        _gridData[i].number = i + 1;
                    }

                    _self.setState({
                        k1AttachmentData: _gridData, isDeleted: true
                    }, () => {
                        _self.forceUpdate();
                        _self.onK1AttachmentSubmit();
                    });
                }
            }
        });
    }

    buttonFunction(cell: any, row: any) {
        return (
            <ButtonFormatter deleteReturn={() => this.deleteDocument(row)}
                disabled={(row.progressBar != undefined) && (row.progressBar != 100) ? true : false}
                data-test-auto="83A48C48-3BCE-411C-B9F2-C32A386D424F" />
        );
    }

    private onCancel = () => {
        if (this.isUploadOnGoing(this.state.k1AttachmentData)) {
            VenusNotifier.Warning(K1Attachments.uploadInProgress, null);
            return;
        }
        this.setState({ k1AttachmentData: [] });
        this.props.onCloseModal();
    }

    private onHide = () => {
        this.onCancel();
    }

    private onK1AttachmentSubmit = () => {
        if (this.state.selectedBookmarks.length <= 0) {
            VenusNotifier.Warning(K1Attachments.noRecipientSelected, null);
            return;
        }

        if (this.state.isDeleted) {
            this.props.selectedK1Attachment[0].isDeleted = true;
        }

        this.props.onSubmit(this.props.selectedK1Attachment, this.state.selectedBookmarks);
    }

    getK1Attachments = () => {
        const bookmarkPageTitle = this.props.bookmarks[0].pages.map(e => ({ label: `${e.pageTitle}`, value: `${e.id}` }));
        this.setState({ bookmarkOptions: [ ...bookmarkPageTitle ] })
    }

    private handleBookmarkSelectionChange = (selectedOptions: string, cell: any) => {
        let selectedBookmarks: number[] = [];
        selectedOptions && selectedOptions.split(',').map((v, i) => {
            if (parseInt(v) !== -1) {
                selectedBookmarks.push(parseInt(v));
            }
        })

        this.setState({ selectedBookmarks });
    }

    private recipient = (row: any, cell: any) => {
        return <CustomMultiSelect
            id="k1Attachment"
            options={this.state.bookmarkOptions}
            selectedOptions={this.state.selectedBookmarks ? this.state.selectedBookmarks.join() : ''}
            clearable={false}
            onRef={(ref: any) => (this.customMultiSelect = ref)}
            enableAllFilter={true}
            isMultiSelectCheckbox={true}
            filterHandler={(selectedOptions: string) => { this.handleBookmarkSelectionChange(selectedOptions, cell) }}
            placeholder="Select..."
        />
    }

    public render() {
        const columns = [
            {
                header: 'File Name',
                key: 'fileName',
                isKey: true,
                dataFormat: this.defaultType,
                width: 'auto',
                columnClassName: ''
            },
            {
                header: 'Recipient(s)',
                key: 'k1ShareholderDetailsId',
                isKey: false,
                dataFormat: this.recipient.bind(this),
                columnClassName: 'overflowVisible text-left-align',
                width: '300px',
                hidden: false
            },
            {
                header: 'Action',
                key: 'button',
                isKey: false,
                dataFormat: this.buttonFunction.bind(this),
                width: '100px',
                columnClassName: ''
            }
        ];
        return (<Modal className="upload-doc-modal edit-upload-modal" show={this.props.showUploadAttachment} onHide={this.onHide} enforceFocus={false}>
            <Modal.Header closeButton data-test-auto="496E1238-E8D8-4655-B218-6F5EED8E37CE">
                <Modal.Title>K-1 Attachments</Modal.Title>
            </Modal.Header>
            <Modal.Body className="prosystem-upload-doc-body overflowVisible">
                <div className="row">
                    <Col id="uploadDocumentTable" className="col-sm-14 uploaded-documents-table-container overflowVisible">
                        <BootstrapTable data={this.props.selectedK1Attachment}
                            bodyContainerClass={"overflowVisible"} striped bordered options={tableOptions}>
                            {columns.map((value, index) => {
                                return <TableHeaderColumn
                                    tdStyle={{ whiteSpace: 'normal' }} thStyle={{ whiteSpace: 'normal' }}
                                    key={index} isKey={value.isKey} dataField={value.key}
                                    dataFormat={value.dataFormat} width={value.width}
                                    columnClassName={value.columnClassName}
                                    dataAlign={"center"}>
                                    {value.header}
                                </TableHeaderColumn>;
                            })}
                        </BootstrapTable>
                    </Col>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="default"
                    className="btn-white"
                    onClick={this.onCancel}
                    data-test-auto="50DCC1DC-54C6-48E4-811B-3667CDA348F5">
                    <i className='fas fa-times'></i>
                    Cancel
               </Button>
                <Button
                    variant="info"
                    onClick={this.onK1AttachmentSubmit}
                    data-test-auto="9C2D4DA8-BA1E-40AC-9E2F-9B8EBFA9AABA">
                    <i className='fas fa-save'></i>
                    Save
                    </Button>
            </Modal.Footer>
        </Modal>)
    }
}
