import * as React from 'react'
import { Modal, Button } from 'react-bootstrap';
import { IFormBase, DocumentGroups, SelectOptions, VoucherProperty, IEFileProperty, K1Property, IEFile, IK1ShareHolder, IVoucher, ITaxReturn, ITaxingAuthority } from '../../common/TaxReturn';
import { PDFRegroupPageViewer } from './../ProcessReturnModal/PdfViewer/PDFRegroupPageViewer';
import * as PdfStore from '../../../store/pdf/PdfStore';
import { Link } from 'react-router-dom';
import { RegroupToVoucher } from '../ProcessReturnModal/RightSidebar/RegroupToVoucher';
import { RegroupToEFile } from '../ProcessReturnModal/RightSidebar/RegroupToEFile';
import { ReGroupToKOne } from '../ProcessReturnModal/RightSidebar/ReGroupToKone';
import { IVoucherState } from '../../../store/ProcessReturn/voucher/VoucherStore';

interface GroupFilesPreviewProps {
    voucherStore: IVoucherState;
    allTaxingAuthorities: ITaxingAuthority[];
    taxReturn: ITaxReturn;
    showGroupFilesPreview: boolean;
    onHideGroupFilesPreview: () => void;
    documentId: number;
    pages: number[];
    matchedForms: IFormBase[];
    destinationGroup: DocumentGroups;
    pdfDocuments: PdfStore.IPdfDocumentDictionary;
    renderTrigger: string;
    removePage: (event: any, pageNo: number) => void;
    onMove: (pages: number[], matchedForms: IFormBase[], destinationGroup: DocumentGroups) => void;
    authoritiesOptions: SelectOptions[],
    shareHolderOptions: SelectOptions[],
    onVoucherDataUpdate: (propertyType: VoucherProperty, value: any, pageNumber: number) => void,
    onEFileDataUpdate: (propertyType: IEFileProperty, value: any, pageNumber: number) => void,
    onKOneDataUpdate: (propertyType: K1Property, value: any, pageNumber: number) => void,
}

export class GroupFilesPreview extends React.Component<GroupFilesPreviewProps, {}>{
    renderFormPage = (form: IFormBase) => {
        switch (this.props.destinationGroup) {
            //Fix below components
            case DocumentGroups.EFile:
            case DocumentGroups.AdditionalEsignDocument:
                {
                    let eFile: IEFile = form as IEFile;
                    return <div style={{ border: '1px solid black', marginLeft: '-10px', marginRight: '-10px' }}>
                        <RegroupToEFile
                            eFile={eFile}
                            authorities={this.props.authoritiesOptions}
                            pageNo={form.pageNo[0]}
                            onEFileDataUpdate={this.props.onEFileDataUpdate}
                        />
                    </div>
                }

            case DocumentGroups.K1:

                {
                    let k1Share: IK1ShareHolder = form as IK1ShareHolder;
                    return <div style={{ border: '1px solid black', marginLeft: '-10px', marginRight: '-10px' }}>
                        <ReGroupToKOne
                            k1Data={k1Share}
                            shareHolders={this.props.shareHolderOptions}
                            pageNo={form.pageNo[0]}
                            onKOneDataUpdate={this.props.onKOneDataUpdate}
                        />
                    </div>
                }
            case DocumentGroups.Vouchers:

                {
                    //this.props.matchedForms.filter(x => x.pageNo[0] == form.pageNo[0]) as IVoucher[]
                    let voucher: IVoucher = form as IVoucher;

                    return <div style={{ border: '1px solid black', marginLeft: '-10px', marginRight: '-10px' }}>

                        <RegroupToVoucher
                            voucher={voucher}
                            authorities={this.props.authoritiesOptions}
                            pageNo={form.pageNo[0]}
                            onVoucherDataUpdate={this.props.onVoucherDataUpdate}
                            taxReturn={this.props.taxReturn}
                            allTaxingAuthorities={this.props.allTaxingAuthorities}
                            voucherStore={this.props.voucherStore}
                        />
                    </div>
                }
        }
    }

    renderHeaderTitle = () => {
        switch (this.props.destinationGroup) {
            case DocumentGroups.AdditionalEsignDocument:
                return "Additional E-Sign";
            default:
                return DocumentGroups[this.props.destinationGroup]    
        }
    }

    render() {
        return <div>
            <Modal dialogClassName="modal-GroupFilePreview" show={this.props.showGroupFilesPreview} onHide={this.props.onHideGroupFilesPreview}>
                <Modal.Header closeButton data-test-auto="">
                    <Modal.Title>
                        <i className='fas fa-arrows-alt'></i>
                        <span style={{ paddingLeft: 6, paddingRight: 6 }}>Move to {this.renderHeaderTitle()}</span>
                        <span className="badge">{this.props.pages.length}</span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        this.props.matchedForms.map((form, index) => {
                            return <div className="row">
                                <div className="col-sm-12 col-lg-12" style={{ borderBottom: "1px" }}>
                                    <div className="row">
                                        <div className="col-sm-12 col-lg-12 dispFlex">
                                            <div className="col-sm-10 col-lg-10">
                                                <span>{form.formName}</span>
                                            </div>
                                            <div className="col-sm-2 col-lg-2 float-right">
                                                {
                                                    <Link to={'#'} style={{ color: "red" }} onClick={(event) => { this.props.removePage(event, form.pageNo[0]) }}>
                                                        <span><i className='fas fa-times'></i> Remove</span>
                                                    </Link>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row modal-GroupFilePreviewBody">
                                        <div className="col-sm-12 col-lg-12 dispFlex">
                                            <div className="col-sm-7 col-lg-7 marB5">
                                                <div style={{ width: "99%", border: "1px solid black" }}>
                                                    <PDFRegroupPageViewer
                                                        documentId={this.props.documentId}
                                                        pageNo={form.pageNo[0]}
                                                        pdfDocuments={this.props.pdfDocuments}
                                                        showText={false} />
                                                </div>
                                            </div>
                                            <div className="col-sm-5 col-lg-5">
                                                {

                                                    this.renderFormPage(form)
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    {index != this.props.matchedForms.length - 1 &&
                                        <hr />
                                    }
                                </div>
                            </div>
                        })
                    }

                </Modal.Body>
                <Modal.Footer style={{ overflow: "hidden" }}>
                    <Button
                        variant="default"
                        className="btn-white"
                        onClick={this.props.onHideGroupFilesPreview}
                    ><i className='fas fa-times'></i>Cancel</Button>
                    <Button
                        variant="info"
                        onClick={() => { this.props.onMove(this.props.pages, this.props.matchedForms, this.props.destinationGroup) }}
                    ><i className='fas fa-check'></i>Move</Button>
                </Modal.Footer>
            </Modal>
        </div>
    }
}