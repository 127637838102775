import { addTask } from 'domain-task';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../../../';
import { actionTypes } from '../../../../types/ActionTypes';
import {
	InUseGroupedReturnsTableModel,
	InUseGroupedReturnsState,
	InUseGroupedReturnsDictionary
} from './InUseGroupedReturnsState';
import { StatusType, NotificationAction } from '../../../common/NotificationStore';
import { handleResponse } from '../../../Library';
import {
	RequestInUseGroupedReturnsPagesAction,
	ReceiveInUseGroupedReturnsPagesAction
} from './InUseGroupedReturnsPagesStore';
import { API_BASE_URL } from '../../../../utils/constants';

export interface RequestInUseGroupedReturnsAction {
	type: actionTypes.REQUEST_INUSE_GROUPED_RETURNS;
	query: string;
}

export interface ReceiveInUseGroupedReturnsAction {
	type: actionTypes.RECEIVE_INUSE_GROUPED_RETURNS;
	query: string;
	table: InUseGroupedReturnsTableModel;
}

const unloadedState: InUseGroupedReturnsState = {
	inUseGroupedReturnsTableModel: {
		inUseGroupedReturns: [],
		count: 0
	} as InUseGroupedReturnsTableModel,
	loading: true,
	query: '',
	totalRowCount: 0,
	error: false
} as InUseGroupedReturnsState;

type KnownAction = RequestInUseGroupedReturnsAction
	| ReceiveInUseGroupedReturnsAction
	| RequestInUseGroupedReturnsPagesAction
	| ReceiveInUseGroupedReturnsPagesAction
	| NotificationAction

type DispatchAction = RequestInUseGroupedReturnsAction
	| ReceiveInUseGroupedReturnsAction

export const actionCreators = {
	requestInUseGroupedReturns: (query: string, reload: boolean = false): AppThunkAction<KnownAction> => (dispatch, getState) => {
		// Only load data if it's something we don't already have (and are not already loading)
		let state = getState();
		if (reload || query !== state.inUseGroupedReturns.query) {

			let page = state.inUseGroupedReturnsPages[query];
			if (!reload && page) {
				dispatch({
					type: actionTypes.RECEIVE_INUSE_GROUPED_RETURNS,
					query: query,
					table: page.inUseGroupedReturnsTableModel
				});

				return;
			}

			const fetchTask = fetch(API_BASE_URL + 'api/InUseGroupedReturns/GetInUseGroupedReturnsAsync' + query, {
				method: 'GET',
				credentials: 'include'
			})
				.then(handleResponse)
				.then(response => response as Promise<InUseGroupedReturnsTableModel>)
				.then(data => {
					dispatch({ type: actionTypes.RECEIVE_INUSE_GROUPED_RETURNS, query: query, table: data });
					dispatch({ type: actionTypes.RECEIVE_INUSE_GROUPED_RETURNS_PAGES, query: query, table: data, totalRowCount: data.count });
				})
				.catch((error) => {
					const statusMessage: any = error.statusText?.message ?? error.statusText;
                    if (typeof(statusMessage) === "string") {	
						dispatch({ type: actionTypes.NOTIFICATION, statusMessage: statusMessage, statusType: StatusType.Error })
					}
				});
			addTask(fetchTask);
			dispatch({ type: actionTypes.REQUEST_INUSE_GROUPED_RETURNS, query: query });
			dispatch({ type: actionTypes.REQUEST_INUSE_GROUPED_RETURNS_PAGES, query: query });
		}
	},
	exportInUseGroupedReturnsAsExcel: (query: string, resourceId: string, callback?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
		const fetchTask = fetch(API_BASE_URL + 'api/ExportToExcel/ExportExcelInUseGroupedReturnsAsync' + query, {
				credentials: 'include',
				headers: {
					'X-Resource-Id': resourceId
				}
			})
			.then(response => response.blob())
			.then(blob => {
				const url = window.URL.createObjectURL(new Blob([blob]));
				const link = document.createElement('a');
				link.href = url;

				const fileName = 'LockedReturns.xlsx';
				link.setAttribute('download', fileName);
				link.target = '_blank';
				document.body.appendChild(link);
				link.click();

				let parentNode = link.parentNode;

				if (parentNode) {
					parentNode.removeChild(link);
				}

				if (callback) {
					callback();
				}
			})
			.catch((error) => {
				const statusMessage: any = error.statusText?.message ?? error.statusText;
                if (typeof(statusMessage) === "string") {
					dispatch({ type: actionTypes.NOTIFICATION, statusMessage: statusMessage, statusType: StatusType.Error });
				}
				if (callback) {
					callback();
				}
			});
		addTask(fetchTask);
	}
}

export const reducer: Reducer<InUseGroupedReturnsState> = (state: InUseGroupedReturnsState = unloadedState, incomingAction: Action) => {
	const action = incomingAction as DispatchAction;
	let index = -1;

	switch (action.type) {
		case actionTypes.REQUEST_INUSE_GROUPED_RETURNS:
			return ({
				...unloadedState,
				query: action.query,
				loading: true
			}) as InUseGroupedReturnsState;
		case actionTypes.RECEIVE_INUSE_GROUPED_RETURNS:
			return {
				query: action.query,
				inUseGroupedReturnsTableModel: action.table,
				totalRowCount: action.table.count,
				loading: false
			} as InUseGroupedReturnsState;
		default:
			// The following line guarantees that every action in the KnownAction union has been covered by a case above
			const exhaustiveCheck: never = action;
	}
	return state || unloadedState;
}

function clearInUseGroupedTaxReturn(state: InUseGroupedReturnsState): InUseGroupedReturnsState {
	return {
		...unloadedState,
		query: state.query,
		isLoading: true
	} as InUseGroupedReturnsState;
}