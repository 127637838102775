import * as React from 'react';
import { TaxReturnUploadData } from '../../../Core/ViewModels/Common/UploadDocumentViewModel';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { UploadtaxReturnConstants } from '../../helper/Constants';

interface IUploadDocumentTable {
    data: TaxReturnUploadData[],
    column: ColumnNames[],
    bodyContainerClass?: string
}
interface ColumnNames {
    header: string;
    key: string;
    isKey: boolean;
    dataFormat: any;
    width: string;
    columnClassName: string;
}
export class UploadedDocumentsTable extends React.Component<IUploadDocumentTable, {}>{
    public render() {
        let tableOptions = {
            noDataText: UploadtaxReturnConstants.EmptyFileListMessage
        };
        return <BootstrapTable data={this.props.data} options={tableOptions} bodyContainerClass={this.props.bodyContainerClass}>
            {this.props.column.map((value, index) => {
                return <TableHeaderColumn key={index} isKey={value.isKey} dataField={value.key} dataFormat={value.dataFormat} width={value.width} columnClassName={value.columnClassName}>{value.header}</TableHeaderColumn>;
            })}
        </BootstrapTable>
    }
}

export default UploadedDocumentsTable;