import { ApplicationState } from "../../../store";
import * as MailingReturnAddressStore from "../../../store/common/MailingReturnAddressStore";
import { Signatures } from "../Signatures";
import { connect } from "react-redux";
import * as CompanyStore from "../../../store/company/CompanyStore";
import * as UserSettingsStore from "../../../store/userManagement/UserSettingStore";
import * as AdditionalDocumentTypeStore from "../../../store/common/AdditionalDocumentTypeStore";
import * as ApplicationHelptextStore from "../../../store/common/ApplicationHelptextStore";
import * as SuiteNotificationSettingStore from "../../../store/company/SuiteNotificationSettingStore";

export default connect(
    (state: ApplicationState) => ({
        companyData: state.companyData,
        mailingReturnAddress: state.mailingReturnAddress,
        settings: state.userSettings,
        additionalDocumentTypeData: state.additionalDocumentType,
        helptexts: state.Helptexts,
        suiteNotificationSetting: state.suiteNotificationSetting
    }),
    {
        ...CompanyStore.actionCreators,
        ...MailingReturnAddressStore.actionCreators,
        ...UserSettingsStore.actionCreators,
        ...AdditionalDocumentTypeStore.actionCreators,
        ...ApplicationHelptextStore.actionCreators,
        ...SuiteNotificationSettingStore.actionCreators
    }
)(Signatures as any);
