import * as React from "react";
import { Nav } from "react-bootstrap";
import { Modal, Button, FormControl, Table, ProgressBar } from "react-bootstrap";
import "react-bootstrap-table/css/react-bootstrap-table.css";
import { DocumentUploadDropzoneComponent } from "../../DocumentUploadDropzoneComponent";
import { VenusNotifier } from "../../../../helper/VenusNotifier";
import { validateFileType, NullandEmptyCheck } from "../../../../helper/Validations";
import * as bootbox from "bootbox";
import { getFileSize, getPdfFilePageCount } from "../../../../helper/HelperFunctions";
import { ShowLoader, HideLoader } from "../../../../helper/Loader";
import { UploadFunctions, isFileExist, getFileExtension, removeSpecialCharacters } from "@sssuite-js-packages/file-utility";
import { ButtonFormatter, DatalistFormatter } from ".././UploadCommonFormatters";
import {
    SasContainer,
    GoSystemReturnUploadData_GR,
    subDocumentUploadData,
    UploadStatus
} from "../../../../../Core/ViewModels/Common/UploadDocumentViewModel";
import { UploadtaxReturnConstants } from "../../../../helper/Constants";
import * as UploadTaxReturnStore from "../../../../../store/common/uploadTaxReturn";
import { ITaxReturn } from "../../../TaxReturn";
import { Guid } from "../../../../../Core/Utilities/Guid";
import { IGroupAccess } from "../../../../GroupedReturns/GroupConfiguration";
import { IGroupInfo } from "../../../../../store/groupedreturns/GroupedReturnProcessState";
import { initialGroupInfo } from "../../../../../store/groupedreturns/GroupedReturnProcessStore";
import { GoSystemUploadModalProps, GoSystemUploadModalState } from "../GoSystem/GoSystemUploadDocumentModal";
import UploadDocumentModalWarning from "../../UploadDocumentModalWarning";
import { CommonResources } from "../../../../helper/ResourceIdConstants";
import SuiteModal from "src/components/common/SuiteModal";
import {
    IAccessingUsersAndUserGroups,
    ISetAccessData,
    IUserDataFromDB,
    IUserGroupDataFromDB
} from "src/components/common/SetAccessCommonModal/SetAccessCommonModal.model";
import { getInitialListOfDataFromDB } from "src/components/common/SetAccessCommonModal/SetAccessCommonModal.api";
import SetAccessCommonModal from "src/components/common/SetAccessCommonModal";

interface GroupedGoSystemUploadModalProps extends GoSystemUploadModalProps {
    Groups: IGroupInfo[];
    defaultGroup: string;
    GroupAccess: IGroupAccess[];
    updateGroupInfoState: (reload: boolean) => void;
    parentResourceIdentifier: string;
}

interface GroupedGoSystemUploadModalState extends GoSystemUploadModalState {
    GoSystemReturnUploadData: GoSystemReturnUploadData_GR;
    availableUsers: IUserDataFromDB[];
    selectedUsers: IUserDataFromDB[];
    availableUserGroups: IUserGroupDataFromDB[];
    selectedUserGroups: IUserGroupDataFromDB[];
}

const MaxFileLimitPerUpload: number = 30;

export class GoSystemGroupedDocumentModal extends React.Component<
    GroupedGoSystemUploadModalProps,
    GroupedGoSystemUploadModalState
> {
    constructor(props: GroupedGoSystemUploadModalProps) {
        super(props);
        this.state = {
            GoSystemReturnUploadData: {
                clientId: "",
                sasGuid: "",
                setAccess: "Everyone",
                clientName: "",
                partnerId: 0,
                documentAccess: { documents: [], userGroups: [], users: [] },
                subDocumentsUploadData: [],
                groupInfo: initialGroupInfo,
                numberOfPages: 0
            },
            submitDisable: true,
            showUpload: false,
            showSetAccess: false,
            sasContainer: [],
            config: {
                dropzoneSelector: "div.filepicker",
                iconFiletypes: [".pdf"],
                showFiletypeIcon: true,
                postUrl: "/api/"
            },
            setAccessAccessingUsers: [],
            setAccessCurrentUsers: [],
            taxReturns: {} as ITaxReturn,
            availableUsers: [],
            selectedUsers: [],
            availableUserGroups: [],
            selectedUserGroups: []
        };
        this.deleteReturn = this.deleteReturn.bind(this);
        this.onSetAccess = this.onSetAccess.bind(this);
        this.onSetAccessHide = this.onSetAccessHide.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleUploaderClose = this.handleUploaderClose.bind(this);
        this.onGroupChange = this.onGroupChange.bind(this);
        this.validateTaxReturnUploadData = this.validateTaxReturnUploadData.bind(this);

        this.setAcessShow = this.setAcessShow.bind(this);
        this.onSetAccessSave = this.onSetAccessSave.bind(this);
        this.uploadProgressCallback = this.uploadProgressCallback.bind(this);
    }

    componentWillReceiveProps(nextProps: GroupedGoSystemUploadModalProps) {
        let tempGoSystemReturnUploadData = this.state.GoSystemReturnUploadData;
        tempGoSystemReturnUploadData.groupInfo.name = nextProps.defaultGroup;
    }

    getGoSystemDocumentGuid() {
        let tempGoSystemReturnUploadData = this.state.GoSystemReturnUploadData;
        tempGoSystemReturnUploadData.sasGuid = Guid.newGuid().toString();
        this.setState({ GoSystemReturnUploadData: tempGoSystemReturnUploadData });
    }

    private onClientIdChange(event: any) {
        let tempGoSystemReturnUploadData = this.state.GoSystemReturnUploadData;
        tempGoSystemReturnUploadData.clientId = event.target.value;
        this.setState({ GoSystemReturnUploadData: tempGoSystemReturnUploadData });
    }

    private onGroupChange(value: any) {
        let tempGoSystemReturnUploadData = this.state.GoSystemReturnUploadData;
        var group = this.props.Groups && this.props.Groups.length > 0 && this.props.Groups.find((e) => e.name === value);
        if (group) tempGoSystemReturnUploadData.groupInfo.id = group.id;
        else tempGoSystemReturnUploadData.groupInfo.id = 0;

        var access = this.props.GroupAccess.find((e) => e.groupId === tempGoSystemReturnUploadData.groupInfo.id);
        if (access) tempGoSystemReturnUploadData.setAccess = "Restricted";
        else tempGoSystemReturnUploadData.setAccess = "Everyone";

        tempGoSystemReturnUploadData.groupInfo.name = value;
        if (tempGoSystemReturnUploadData.documentAccess) {
            tempGoSystemReturnUploadData.documentAccess.documents = [];
            tempGoSystemReturnUploadData.documentAccess.users = [];
            tempGoSystemReturnUploadData.documentAccess.userGroups = [];
        }
        this.setState({ GoSystemReturnUploadData: tempGoSystemReturnUploadData });
    }

    private reset = () => {
        let tempUploadData: SasContainer[] = [];
        let tempGoSystemReturnUploadData = this.state.GoSystemReturnUploadData;
        tempGoSystemReturnUploadData.clientId = "";
        tempGoSystemReturnUploadData.clientName = "";
        tempGoSystemReturnUploadData.sasGuid = "";
        tempGoSystemReturnUploadData.groupInfo.id = 0;
        tempGoSystemReturnUploadData.groupInfo.controllerInfo.clientGuid = Guid.newGuid();
        tempGoSystemReturnUploadData.groupInfo.name = this.props.defaultGroup;
        tempGoSystemReturnUploadData.setAccess = "Everyone";
        tempGoSystemReturnUploadData.documentAccess = {
            documents: [],
            userGroups: [],
            users: []
        };

        tempGoSystemReturnUploadData.subDocumentsUploadData = [];

        this.state.sasContainer.slice(0, this.state.sasContainer.length);
        this.setState(
            {
                sasContainer: tempUploadData,
                GoSystemReturnUploadData: tempGoSystemReturnUploadData,
                submitDisable: true
            },
            () => {
                this.loadSetAccessData();
            }
        );
    };

    private handleClose() {
        this.reset();
        this.props.onHide();
    }

    private handleUploaderClose() {
        let _self = this;
        if (this.state.GoSystemReturnUploadData.subDocumentsUploadData.length > 0) {
            bootbox.confirm({
                message: UploadtaxReturnConstants.CloseConfirmationMessage,
                buttons: {
                    cancel: {
                        label: '<i class="fas fa-times"></i> Cancel',
                        className: "btn-white btn-default"
                    },
                    confirm: {
                        label: '<i class="fas fa-check"></i> OK',
                        className: "btn-info"
                    }
                },
                callback: (result: boolean) => {
                    if (result) {
                        _self.handleClose();
                    }
                }
            });
        } else {
            _self.handleClose();
        }
    }
    //===========================================================================
    // Set Access Start
    //===========================================================================

    setAcessShow() {
        this.setState({ showSetAccess: true });
    }

    onSetAccessHide() {
        this.setState({
            showSetAccess: false
        });
    }

    onSetAccess(taxReturn: any, event: any) {
        this.setState({
            showSetAccess: true
        });
    }
    private onSetAccessSave(accessingUsersAndUserGroups: IAccessingUsersAndUserGroups) {
        let uploadingDocument: GoSystemReturnUploadData_GR = Object.assign({}, this.state.GoSystemReturnUploadData);

        uploadingDocument.documentAccess = {
            documents: [],
            userGroups: accessingUsersAndUserGroups.selectedUserGroups,
            users: accessingUsersAndUserGroups.selectedUsers
        };

        this.setState({
            showSetAccess: false,
            selectedUsers: this.getSelectedUser(accessingUsersAndUserGroups),
            availableUsers: this.getAvailableUser(accessingUsersAndUserGroups),
            selectedUserGroups: this.getSelectedUserGroups(accessingUsersAndUserGroups),
            availableUserGroups: this.getAvailableUserGroups(accessingUsersAndUserGroups)
        });
    }

    getSelectedUser = (accessingUsersAndUserGroups: IAccessingUsersAndUserGroups) => {
        const allUsers = [...this.state.selectedUsers, ...this.state.availableUsers];
        let selectedUser: IUserDataFromDB[] = [];
        accessingUsersAndUserGroups.selectedUsers.map((userId) => {
            let index = allUsers.findIndex((x) => x.id == userId);
            if (index != -1) {
                selectedUser.push(allUsers[index]);
            }
        });
        return selectedUser;
    };

    getAvailableUser = (accessingUsersAndUserGroups: IAccessingUsersAndUserGroups) => {
        const allUsers = [...this.state.selectedUsers, ...this.state.availableUsers];
        let availableUser: IUserDataFromDB[] = [];
        accessingUsersAndUserGroups.availableUsers.map((userId) => {
            let index = allUsers.findIndex((x) => x.id == userId);
            if (index != -1) {
                availableUser.push(allUsers[index]);
            }
        });
        return availableUser;
    };

    getSelectedUserGroups = (accessingUsersAndUserGroups: IAccessingUsersAndUserGroups) => {
        const allUserGroups = [...this.state.selectedUserGroups, ...this.state.availableUserGroups];
        let selectedUserGroups: IUserGroupDataFromDB[] = [];
        accessingUsersAndUserGroups.selectedUserGroups.map((userGroupId) => {
            let index = allUserGroups.findIndex((x) => x.id == userGroupId);
            if (index != -1) {
                selectedUserGroups.push(allUserGroups[index]);
            }
        });
        return selectedUserGroups;
    };

    getAvailableUserGroups = (accessingUsersAndUserGroups: IAccessingUsersAndUserGroups) => {
        const allUserGroups = [...this.state.selectedUserGroups, ...this.state.availableUserGroups];
        let availableUserGroups: IUserGroupDataFromDB[] = [];
        accessingUsersAndUserGroups.availableUserGroups.map((userGroupId) => {
            let index = allUserGroups.findIndex((x) => x.id == userGroupId);
            if (index != -1) {
                availableUserGroups.push(allUserGroups[index]);
            }
        });
        return availableUserGroups;
    };

    //===========================================================================
    // Set Access End
    //===========================================================================

    onSubmitTaxreturns = () => {
        if (this.validateTaxReturnUploadData()) {
            ShowLoader();

            let uploadData = { ...this.state.GoSystemReturnUploadData };
            uploadData.numberOfPages = uploadData.subDocumentsUploadData.map((i) => i.numberOfPages).reduce((a, b) => a + b);

            uploadData.documentAccess = {
                documents: [],
                userGroups: this.state.selectedUserGroups.map((group) => {
                    return group.id;
                }),
                users: this.state.selectedUsers.map((user) => {
                    return user.id;
                })
            };
            this.setState({ submitDisable: true }, () => {
                this.props.submitTaxReturn(
                    "/api/GoSystemUpload/SubmitGroupedGoSystemReturnsAync",
                    JSON.stringify(uploadData),
                    () => {
                        this.UploadConfirmation();
                        this.props.onPageReload();
                        this.handleClose();
                        HideLoader();
                        if (uploadData.groupInfo.id == 0) this.props.updateGroupInfoState(true);
                    },
                    this.props.parentResourceIdentifier + CommonResources.GSSubmitButton
                );
            });
        }
    };

    deleteReturn(row: any) {
        let _self = this;
        bootbox.confirm({
            message: UploadtaxReturnConstants.DeleteFileMessage,
            buttons: {
                cancel: {
                    label: '<i class="fas fa-times"></i> Cancel',
                    className: "btn-white btn-default"
                },
                confirm: {
                    label: '<i class="fas fa-check"></i> OK',
                    className: "btn-info"
                }
            },
            callback: (result: boolean) => {
                if (result) {
                    var _subDocumentData = [..._self.state.GoSystemReturnUploadData.subDocumentsUploadData];
                    _subDocumentData = _subDocumentData.filter((i) => i.number != row.number);
                    //correct index
                    for (let i = row.number - 1; i < _subDocumentData.length; i++) {
                        _subDocumentData[i].number = i + 1;
                    }
                    let _tempgridData = _subDocumentData;

                    let _uploadData = [..._self.state.sasContainer];
                    _uploadData = _uploadData.filter((i) => i.sasGuid != row.subDocumentGuid);

                    let _tempUploadData = _uploadData;

                    let tempGoSystemReturnUploadData = _self.state.GoSystemReturnUploadData;
                    tempGoSystemReturnUploadData.subDocumentsUploadData = _tempgridData;
                    if (_subDocumentData.length == 0) {
                        _self.setState({ submitDisable: true });
                    }
                    _self.setState({
                        GoSystemReturnUploadData: tempGoSystemReturnUploadData,
                        sasContainer: _tempUploadData
                    });
                }
            }
        });
    }

    private validateTaxReturnUploadData = (): boolean => {
        let uploaddata = this.state.GoSystemReturnUploadData;
        uploaddata.groupInfo.name = uploaddata.groupInfo.name.trim();
        if (uploaddata.subDocumentsUploadData.length < 1) {
            VenusNotifier.Warning("No files Uploaded", "");
            return false;
        }

        if (!uploaddata.clientId || uploaddata.clientId.trim() === "") {
            VenusNotifier.Warning("Please enter client Id to submit", "");
            return false;
        }

        return true;
    };

    private UploadConfirmation() {
        let message: string =
            "<h5 class='marT0 font13'> " +
            UploadtaxReturnConstants.GroupedReturnSubmitSuccess +
            ":<h5> <br/><ul class='Modal-UploadUl font14'>";
        if (this.state.GoSystemReturnUploadData.groupInfo.name != "")
            message =
                message +
                "<li>" +
                this.state.GoSystemReturnUploadData.clientId +
                " (Return added to group " +
                this.state.GoSystemReturnUploadData.groupInfo.name +
                ")</li>";
        else message = message + "<li>" + this.state.GoSystemReturnUploadData.clientId + "</li>";
        message = message + "</ul>";
        bootbox.alert({
            message: message,
            buttons: {
                ok: {
                    label: '<i class="fas fa-check"></i> Ok',
                    className: "btn-info"
                }
            },
            callback() {}
        });
    }

    //===========================================================================
    // File Upload Start
    //===========================================================================

    djsConfig = {
        uploadMultiple: true,
        addRemoveLinks: true,
        acceptedFiles: "application/pdf",
        headers: { "Access-Control-Allow-Origin": "*", "x-ms-blob-type": "BlockBlob" },
        previewsContainer: false,
        autoProcessQueue: false,
        autoDiscover: false
    };

    eventHandlers = {
        init: () => {
            this.reset();
            this.getGoSystemDocumentGuid();
        },

        addedfiles: (files: any) => {
            try {
                let _self = this;

                const loggedInUserId = _self.props.userProfile?.userId as number;

                // Commented this because a Tax Return can have more than 30 K1-shareholders files, uncomment to add file count limit
                //this.validateFileCount(files);

                this.convertToModel(files).then((tmpTaxReturnUploadData: subDocumentUploadData[]) => {
                    tmpTaxReturnUploadData = this.handleDuplicates(tmpTaxReturnUploadData);

                    tmpTaxReturnUploadData =
                        _self.state.GoSystemReturnUploadData.subDocumentsUploadData.concat(tmpTaxReturnUploadData);

                    _self.state.GoSystemReturnUploadData.subDocumentsUploadData = tmpTaxReturnUploadData;

                    _self.setState(
                        {
                            GoSystemReturnUploadData: _self.state.GoSystemReturnUploadData,
                            submitDisable: true
                        },
                        () => {
                            _self.getUploadLink();
                        }
                    );
                });
            } catch (error) {
                VenusNotifier.Warning(error.message, null);
            }
        }
    };

    private validateFileCount = (uploadedFiles: any) => {
        if (uploadedFiles.length + this.state.GoSystemReturnUploadData.subDocumentsUploadData.length > MaxFileLimitPerUpload) {
            throw new Error("You cannot upload more than " + MaxFileLimitPerUpload + " files at a time. Please choose less.");
        }
    };

    private convertToModel = (uploadedFiles: any): Promise<subDocumentUploadData[]> => {
        let tmpTaxReturnUploadData: subDocumentUploadData[] = [];
        let filesFailedValidationCount = 0;
        return new Promise((resolve) => {
            for (let i = 0; i < uploadedFiles.length; i++) {
                let uploadedFile = uploadedFiles[i];
                let tmpFileName = removeSpecialCharacters(uploadedFiles[i].name);
                let fileExtension = getFileExtension(tmpFileName);

                // Validate file extension
                if (validateFileType(fileExtension)) {
                    getPdfFilePageCount(uploadedFile as File).then((pageCount: number) => {
                        tmpTaxReturnUploadData.push({
                            name: tmpFileName,
                            number:
                                tmpTaxReturnUploadData.length +
                                this.state.GoSystemReturnUploadData.subDocumentsUploadData.length +
                                1,
                            progressBar: 0,
                            size: getFileSize(uploadedFile?.size),
                            status: UploadStatus.Wait,
                            file: uploadedFile,
                            subDocumentGuid: "",
                            numberOfPages: pageCount
                        });
                        if (tmpTaxReturnUploadData.length + filesFailedValidationCount == uploadedFiles.length) {
                            resolve(tmpTaxReturnUploadData);
                        }
                    });
                } else {
                    filesFailedValidationCount++;
                    if (tmpTaxReturnUploadData.length + filesFailedValidationCount == uploadedFiles.length) {
                        resolve(tmpTaxReturnUploadData);
                    }
                    VenusNotifier.Info("Some file(s) found with invalid extesion, those will skip during upload !!", null);
                }
            }
        });
    };

    private handleDuplicates = (taxReturnUploadData: subDocumentUploadData[]): subDocumentUploadData[] => {
        for (let i = 0; i < taxReturnUploadData.length; i++) {
            let file = taxReturnUploadData[i];
            let fileName: string = file.name || "";
            let fileExtension = getFileExtension(fileName);

            let filecount = 1;
            while (isFileExist(fileName, this.state.GoSystemReturnUploadData.subDocumentsUploadData)) {
                fileName = file.name || "";
                fileName = fileName.replace("." + fileExtension, "");
                fileName = fileName + " (" + filecount + ")." + fileExtension;
                filecount++;
            }
            taxReturnUploadData[i].name = fileName;
        }
        return taxReturnUploadData;
    };

    private getUploadLink = () => {
        let _self = this;
        let uploadHelperFunctions = new UploadFunctions();
        this.state.GoSystemReturnUploadData.subDocumentsUploadData
            .filter((x) => x.status == UploadStatus.Wait)
            .forEach((file: subDocumentUploadData, index: number) => {
                file.status = UploadStatus.Initiating;

                this.props.getUploadLink(
                    "/api/GoSystemUpload/GetGoSystemReturnUploadLinkAsync?documentGuid=" +
                        this.state.GoSystemReturnUploadData.sasGuid,
                    (data?: UploadTaxReturnStore.IUploadTaxReturnState) => {
                        try {
                            if (data) {
                                file.subDocumentGuid = data ? data.guid : "";
                                file.status = UploadStatus.Uploading;
                                _self.setState({ GoSystemReturnUploadData: _self.state.GoSystemReturnUploadData }, () =>
                                    uploadHelperFunctions.uploadFile(
                                        file.file,
                                        data,
                                        file.name ? file.name : "",
                                        _self.uploadProgressCallback,
                                        _self.uploadCommittCallBack,
                                        _self.uploadFailureCallback
                                    )
                                );
                            } else {
                                throw new Error("Upload link not found !!");
                            }
                        } catch (error) {
                            _self.state.GoSystemReturnUploadData.subDocumentsUploadData.splice(index, 1);
                            _self.setState({ GoSystemReturnUploadData: _self.state.GoSystemReturnUploadData });
                        }
                    },
                    this.props.parentResourceIdentifier + CommonResources.HeaderPrefix + CommonResources.GSUploadButton
                );
            });
    };

    public uploadProgressCallback(percent: number, fileToUpload: any) {
        let tempUploadData = this.state.GoSystemReturnUploadData;
        let tempGridData = tempUploadData.subDocumentsUploadData;
        tempGridData.map((tempGridDataValue, index) => {
            if (tempGridDataValue.subDocumentGuid == fileToUpload.fileGUID) {
                tempGridDataValue.progressBar = percent - 10;
            }
        });
        tempUploadData.subDocumentsUploadData = tempGridData;
        this.setState({
            GoSystemReturnUploadData: tempUploadData
        });
    }

    uploadCommittCallBack = (fileToUpload: any) => {
        let tempUploadData = this.state.GoSystemReturnUploadData;
        if (tempUploadData.subDocumentsUploadData.length > 0) {
            let tempGridData = tempUploadData.subDocumentsUploadData;
            tempGridData.map((tempGridDataValue, index) => {
                if (tempGridDataValue.name == fileToUpload.fileName) {
                    tempGridDataValue.progressBar = 100;
                    tempGridDataValue.status = UploadStatus.Uploaded;
                }
            });
            this.setState(
                {
                    GoSystemReturnUploadData: tempUploadData
                },
                () => {
                    if (!this.isUploadOnGoing()) {
                        this.setState({ submitDisable: false });
                    }
                }
            );
        }
    };

    //Implementation of this callback will be done later
    uploadFailureCallback = (fileToUpload: any) => {
        console.log("upload failed for file:", fileToUpload.fileName);
    };

    private isUploadOnGoing = (): boolean => {
        let isuploading: boolean = false;
        this.state.GoSystemReturnUploadData.subDocumentsUploadData.map((value) => {
            if (value.progressBar == undefined ? 0 : value.progressBar < 100) {
                isuploading = true;
                return;
            }
        });
        return isuploading;
    };

    componentDidMount(): void {
        this.loadSetAccessData();
    }

    loadSetAccessData = () => {
        getInitialListOfDataFromDB((setAccessData?: ISetAccessData) => {
            if (!setAccessData) {
                VenusNotifier.Error("Error fetching list of users and user groups", "");
            } else {
                const { availableUsers, selectedUsers, availableUserGroups, selectedUserGroups } = setAccessData;
                this.setState({
                    availableUsers,
                    selectedUsers,
                    availableUserGroups,
                    selectedUserGroups
                });
            }
        });
    };

    getSetAccessLable = () => {
        const { selectedUsers, availableUsers, selectedUserGroups, availableUserGroups } = this.state;
        const selectedUserIndex = selectedUsers.findIndex((x) => x.id == 0);
        const selectedUserGroupIndex = selectedUsers.findIndex((x) => x.id == 0);
        if (selectedUserIndex != -1 && selectedUserGroupIndex != -1) {
            return "Everyone";
        } else {
            return "Restricted";
        }
    };
    //===========================================================================
    // File Upload End
    //===========================================================================

    public render() {
        let uploadDataLength = this.state.GoSystemReturnUploadData.subDocumentsUploadData.length;
        const disableLink = NullandEmptyCheck(this.state.GoSystemReturnUploadData.groupInfo.name) ? "disable-link" : "";

        return (
            <div>
                <Modal
                    className="upload-doc-modal"
                    show={this.props.onShow}
                    onHide={this.handleUploaderClose}
                    enforceFocus={false}
                >
                    <Modal.Header closeButton data-test-auto="9DFD99E6-5A5C-4A9A-B40F-975AB2D109B5">
                        <Modal.Title style={{ display: "block" }} className="upload-modal-title">
                            {" "}
                            Upload Documents
                            <UploadDocumentModalWarning
                                warningMessages={[
                                    UploadtaxReturnConstants.PdfDocumentGroupedReturnWarning,
                                    UploadtaxReturnConstants.PdfDocumentUploadWarning
                                ]}
                            />
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-sm-2 text-center">
                                <DocumentUploadDropzoneComponent
                                    componentConfig={this.state.config}
                                    djsConfig={this.djsConfig}
                                    eventHandlers={this.eventHandlers}
                                    autoTestId={"FC4527E4-D3B2-47EF-B529-3C56467E4405"}
                                />
                            </div>
                            <div className="col-sm-10 uploaded-documents-table-container">
                                <div className="row">
                                    <div className="col-sm-4 col-md-4 col-xl-4 dispFlex">
                                        <div className="padT07 txt-ar text-bold span-left-float">Client ID *</div>
                                        <div className="col-sm-8 col-md-8 col-xl-8 input-left-padding pos_rel">
                                            <FormControl
                                                type="text"
                                                disabled={uploadDataLength < 1}
                                                value={this.state.GoSystemReturnUploadData.clientId}
                                                onChange={this.onClientIdChange.bind(this)}
                                                data-test-auto="8E584D37-7EF3-4D2A-8CC8-E21FA295F6EB"
                                            />
                                        </div>
                                    </div>

                                    <div className="col-sm-4 col-md-4 col-xl-4 dispFlex">
                                        <div className="padT07 txt-ar text-bold span-left-float">Group Name</div>
                                        <div className="col-sm-8 col-md-8 col-xl-8 input-left-padding pos_rel">
                                            <DatalistFormatter
                                                id="ddlUTUploadGroup"
                                                disableDropdown={uploadDataLength < 1}
                                                Groups={this.props.Groups}
                                                defaultGroup={this.state.GoSystemReturnUploadData.groupInfo.name}
                                                onGroupChange={this.onGroupChange.bind(this)}
                                                data-test-auto="EE1C1137-1326-4C31-B850-D3086EA21C9F"
                                            />
                                        </div>
                                    </div>

                                    <div className="col-sm-3 col-md-3 col-xl-3 dispFlex">
                                        <div className="padT07 txt-ar text-bold span-left-float">Set Access</div>
                                        <div className="col-sm-6 col-md-6 col-xl-6 pos_rel">
                                            <Nav.Link
                                                href="javascript:"
                                                onClick={this.onSetAccess.bind(this)}
                                                className={disableLink}
                                                style={{ textDecoration: "underline", color: "#337ab7 !important" }}
                                                data-test-auto="7FDF51B6-41F6-4E58-9A03-C52D36983AF0"
                                            >
                                                {this.getSetAccessLable()}
                                            </Nav.Link>
                                        </div>
                                    </div>
                                </div>

                                <div className="padT07">
                                    <Table striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th className="w40">#</th>
                                                <th>Included Files</th>
                                                <th>Upload Progress</th>
                                                <th>Size</th>
                                                <th>Status</th>
                                                <th className="w40"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {uploadDataLength > 0 ? (
                                                this.state.GoSystemReturnUploadData.subDocumentsUploadData.map((value) => {
                                                    return (
                                                        <tr key={value.number}>
                                                            <th className="txt-ar">{value.number}</th>
                                                            <th>{value.name}</th>
                                                            <th>
                                                                <ProgressBar
                                                                    striped
                                                                    variant={
                                                                        value.progressBar != undefined && value.progressBar != 100
                                                                            ? "warning"
                                                                            : "success"
                                                                    }
                                                                    now={value.progressBar}
                                                                />
                                                            </th>
                                                            <th>{value.size}</th>
                                                            <th>{UploadStatus[value.status].toString()}</th>
                                                            <th>
                                                                <ButtonFormatter
                                                                    deleteReturn={() => this.deleteReturn(value)}
                                                                    disabled={
                                                                        value.progressBar != undefined && value.progressBar != 100
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    data-test-auto="B07622E7-FEB7-4759-BA19-4DF7D79BE7E9"
                                                                />
                                                            </th>
                                                        </tr>
                                                    );
                                                })
                                            ) : (
                                                <tr>
                                                    <th colSpan={8}>There is no data to display</th>
                                                </tr>
                                            )}
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="default"
                            onClick={this.handleUploaderClose}
                            data-test-auto="B1C823D3-8D5E-4B9B-9D59-11F3A0D9BEED"
                        >
                            <i className="fas fa-times"></i>
                            Cancel
                        </Button>
                        <Button
                            variant="info"
                            onClick={this.onSubmitTaxreturns}
                            data-resource-id={this.props.parentResourceIdentifier + CommonResources.GSSubmitButton}
                            disabled={this.state.submitDisable || uploadDataLength < 1}
                            data-test-auto="5D0B9B03-68DE-47E7-8681-6EDBD54E005B"
                        >
                            <i className="fa fa-check"></i>
                            Submit
                        </Button>
                    </Modal.Footer>
                </Modal>
                {this.state.showSetAccess && (
                    <SuiteModal width="787" theme="light" title="Set Access" hide={this.onSetAccessHide}>
                        <SetAccessCommonModal
                            onCancel={this.onSetAccessHide}
                            onApplyAccess={this.onSetAccessSave}
                            returnAccessSettings={this.props.returnAccessSettings}
                            availableUsers={this.state.availableUsers}
                            selectedUsers={this.state.selectedUsers}
                            availableUserGroups={this.state.availableUserGroups}
                            selectedUserGroups={this.state.selectedUserGroups}
                        />
                    </SuiteModal>
                )}
            </div>
        );
    }
}
