import * as React from "react";
import { ICompanySettings } from "../../../Core/ViewModels/Company/CompanySettingsViewModel";
import { CheckBoxComponent } from "../../common/CheckBoxComponent";
import { IHelptextModel } from "../../../Core/ViewModels/Common/IHelptextModel";
import { Button, Modal } from "react-bootstrap";

interface IKBAProps {
    companySettings: ICompanySettings;
    handleEnableKBA(event: any): void;
    handleAllowDisablingKBAOnReturnLevel(checked: boolean): void;
    handleEnableDelegateeKBA(event: any): void;
    disableESignature: boolean;
    helptexts?: IHelptextModel[];
}

interface IKBAState {
    showReturnLevelKBADisablingConfirmationModal: boolean;
}
export class KnowledgeBasedAuthentication extends React.Component<IKBAProps, IKBAState> {
    constructor(props: IKBAProps) {
        super(props);
        this.state = {
            showReturnLevelKBADisablingConfirmationModal: false
        };
    }

    public onOpenReturnLevelKBADisablingConfirmationModal = (event: any) => {
        if (event.currentTarget.checked) {
            this.setState({ showReturnLevelKBADisablingConfirmationModal: true });
        } else {
            this.props.handleAllowDisablingKBAOnReturnLevel(false);
        }
    };

    public onHandleAllowDisablingKBAOnReturnLevel = () => {
        this.props.handleAllowDisablingKBAOnReturnLevel(true);
        this.onHide();
    };

    public onHide = () => {
        this.setState({ showReturnLevelKBADisablingConfirmationModal: false });
    };

    public render() {
        return (
            <div id="divKnowledgeBasedAuthentication" className="new-main-content">
                <h3>1040 Knowledge Based Authentication</h3>
                <div className="marL20">
                    <div className="marB10" style={{ display: "flex" }}>
                        <CheckBoxComponent
                            id={"kbaAuthentication"}
                            text={"Use Knowledge-Based Authentication to initiate the Signing Process (Required by IRS)"}
                            onChange={this.props.handleEnableKBA}
                            checked={
                                this.props.companySettings.signatureSettingsModel
                                    ? this.props.companySettings.signatureSettingsModel.knowledgeBasedAuthentication
                                    : false
                            }
                            datatestAuto={"7EBE4B14-EE9B-4B39-9DD8-75F66E67EE70"}
                            disabled={this.props.disableESignature}
                        />
                    </div>
                    <div className="marL30 marB10" style={{ display: "flex" }}>
                        <CheckBoxComponent
                            id={"kbaAuthenticationInReturnLevel"}
                            text={"Allow disabling of Knowledge-Based Authentication on a return-by-return basis"}
                            checked={
                                this.props.companySettings.signatureSettingsModel
                                    ? this.props.companySettings.signatureSettingsModel.allowDisablingKBAOnReturnLevel
                                    : false
                            }
                            onChange={this.onOpenReturnLevelKBADisablingConfirmationModal}
                            datatestAuto={"647EF10E-50DB-436C-96C4-143420F47596"}
                            disabled = {this.props.disableESignature || !this.props.companySettings.signatureSettingsModel.knowledgeBasedAuthentication}
                        />                      
                    </div>
                </div>

                <div className="marL20">
                    <CheckBoxComponent
                        id={"delegateeKbaAuthentication"}
                        text={
                            "Use Knowledge-Based Authentication to initiate the Signing Process for delegated signers (Required by IRS)"
                        }
                        onChange={this.props.handleEnableDelegateeKBA}
                        checked={
                            this.props.companySettings.signatureSettingsModel
                                ? this.props.companySettings.signatureSettingsModel.enableDelegateeKbaAuthentication
                                : false
                        }
                        datatestAuto={"1A579285-FB0E-419D-B9CE-36398EB80103"}
                        disabled={!this.props.companySettings.signatureSettingsModel.enableIndividualReturnSignerDeligation || this.props.disableESignature}
                    />
                </div>

                <Modal
                    id="ReturnLevelKbaDisablingConfirmationModal"
                    show={this.state.showReturnLevelKBADisablingConfirmationModal}
                    onHide={this.onHide}
                    className="return-level-kba-check"
                >
                    <Modal.Header closeButton data-test-auto="471FEBB7-5C58-4E6F-9059-DF28023E84E5">
                        <Modal.Title className="custom-modal-header">
                            Message from SafeSend
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <div className="row marB40 dispBlock">
                                <div className="col-lg-12 dispFlex">
                                    <span>
                                        Having this box checked will allow Users to disable Knowledge-Based Authentication for
                                        selected E-File Forms. IRS requires KBA for electronic signature processes and disabling
                                        KBA will make these forms non-compliant. Without KBA, these forms will not be valid E-File
                                        forms. If the Firm understands the affects of Users disabling the KBA setting, please
                                        click the "I Understand" button below. You can disable this setting at anytime.
                                    </span>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="success"
                            onClick={this.onHandleAllowDisablingKBAOnReturnLevel}
                            data-test-auto="26AA5B79-5BAF-412D-A5C7-1AF0B0062067"
                        >
                            <i className="fas fa-check"></i>I Understand
                        </Button>
                        <Button variant="default" onClick={this.onHide} data-test-auto="D8091FF4-6B17-479F-BB63-1210B41DC957">
                            <i className="fas fa-times"></i>Cancel
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

export default KnowledgeBasedAuthentication;
