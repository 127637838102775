import * as React from 'react';
import { Col, FormControl, Row } from 'react-bootstrap';
import { TaxClientFileds } from '../../common/TaxClient';


interface ISpouseProps {
    spouseName: string,
    spouseEmail: string;
    handleValueChange?(taxclientField: TaxClientFileds, value: string): void;
    disabled: boolean;
}

export const SpouseDetails = (props: ISpouseProps) => {
    return (
        <div>
            <Row className="report-problem-field">

                <Col sm={2} >
                    <div className="font700">Spouse Name</div>
                </Col>
                <Col sm={4}>
                    <FormControl
                        type="text"
                        value={props.spouseName}
                        placeholder="Enter Spouse Name"
                        onChange={(event: any) => { if (props.handleValueChange) props.handleValueChange(TaxClientFileds.spouseName, event.target?.value) }}
                        onBlur={(event: any) => { if (props.handleValueChange) props.handleValueChange(TaxClientFileds.spouseName, event.target?.value) }}
                        disabled={props.disabled}
                    />
                </Col>

                <Col sm={2}>
                    <div className="font700">Spouse Email</div>
                </Col>
                <Col sm={4}>
                    <FormControl
                        type="text"
                        value={props.spouseEmail}
                        placeholder="Enter Spouse Email"
                        onChange={(event: any) => { if (props.handleValueChange) props.handleValueChange(TaxClientFileds.spouseName, event.target?.value) }}
                        onBlur={(event: any) => { if (props.handleValueChange) props.handleValueChange(TaxClientFileds.spouseEmail, event.target?.value) }}
                        disabled={props.disabled}
                    />
                </Col>
            </Row>
        </div>
    );
} 