import { ISectionData } from "@sssuite-component-ui/ss-suite-layout/dist/Header/NavigationWidget/NavigationWidget";
import { IMenuSection, ISideMenuProps } from "@sssuite-component-ui/ss-suite-layout/dist/SideMenu/SideMenu";
import { IHeaderProps } from "@sssuite-component-ui/ss-suite-layout/dist/Header/Header";
import { ProductType } from "@sssuite-component-ui/ss-suite-layout";
import { userSettingConstants } from "../../helper/Constants";
import { ChildComponents } from "./header/ChildComponents";
import history from "../../../routes/history";
import store from "../../../store";
import { ISideMenu } from "../../../Core/ViewModels/Common/Layout";
import { HideParentIfAllChildrenDisabled } from "../../../oidcClient/userRoles";
import { IAuthState } from '../../../store/auth/reducer';
import { IUserProfile } from '../profile/ProfileObjects';
import { ICompanyData } from '../../../store/company/CompanyStore';

interface IHeaderConfig {
    toggleShowModal: (prop: string) => void,
    assignRef: (reference: any) => void,
    toggleNotificationPopover: (prop: boolean) => void,
    widgetDropdownData: ISectionData[],
    suiteUrl: string,
    resourceIds: string[];
    auth: IAuthState;
    userProfile: IUserProfile;
    companyData: ICompanyData;
}

export const headerConfig = (props: IHeaderConfig): IHeaderProps => {

    const { profile, loginHistory, mySettings, myDownloads, logout } = userSettingConstants;

    const { 
        toggleShowModal,
        widgetDropdownData,
        suiteUrl,
        auth,
        userProfile,
        companyData,
        hideHeader
    } = props;

    const state = store.getState();
    const { firstName } = userProfile;
    const { company_name } = auth.user.profile;
    const { logoPath, isSsrLogo } = companyData?.companyLogoSetting;

    if(!isSsrLogo && logoPath) {
        return {
            productName: ProductType.Returns,
            companyLogo: logoPath,
            onClickProductLogo: () => history.push("/"),
            children: ChildComponents(props),
            hideHeader: hideHeader,
            profileDropDown: {
                dropdownHeader: `Hi, ${firstName?.trim()}!`,
                onClickLogout: () => toggleShowModal(logout),
                profileDropDownItems: [
                    {
                        caption: profile,
                        onClick: () => toggleShowModal(profile)
                    },
                    {
                        caption: mySettings,
                        onClick: () => toggleShowModal(mySettings)
                    },
                    {
                        caption: myDownloads,
                        onClick: () => toggleShowModal(myDownloads)
                    },
                    {
                        caption: loginHistory,
                        onClick: () => toggleShowModal(loginHistory)
                    }
                ]
            },
            widgetData: {
                onBackClick: () => {
                    window.open(suiteUrl, '_blank');
                },
                hideBackIcon: false,
                dropDownData: widgetDropdownData,
                onNavigate: (route: string) => {
                    history.push(route);
                }
            }
        }
    } else {
        return {
            productName: ProductType.Returns,
            companyName: company_name,
            hideHeader: hideHeader,
            onClickProductLogo: () => history.push("/"),
            children: ChildComponents(props),
            profileDropDown: {
                dropdownHeader: `Hi, ${firstName?.trim()}!`,
                onClickLogout: () => toggleShowModal(logout),
                profileDropDownItems: [
                    {
                        caption: profile,
                        onClick: () => toggleShowModal(profile)
                    },
                    {
                        caption: mySettings,
                        onClick: () => toggleShowModal(mySettings)
                    },
                    {
                        caption: myDownloads,
                        onClick: () => toggleShowModal(myDownloads)
                    },
                    {
                        caption: loginHistory,
                        onClick: () => toggleShowModal(loginHistory)
                    }
                ]
            },
            widgetData: {
                onBackClick: () => {
                    window.open(suiteUrl, '_blank');
                },
                hideBackIcon: false,
                dropDownData: widgetDropdownData,
                onNavigate: (route: string) => {
                    history.push(route);
                }
            }
        }
    }
};

export const sideMenuConfig = (arr: IMenuSection[], hideSideMenu: boolean): ISideMenuProps => {
    let topMenuData: IMenuSection[] = [];
    let bottomMenuData: IMenuSection[] = [];

    arr.forEach((x: IMenuSection) => {
        if (x.items?.length && x.items[0]?.isAtBottomOfLeftMenu) {
            bottomMenuData.push(x);
        } else {
            x.items = HideParentIfAllChildrenDisabled(x.items);
            if (x.items.length > 0)
                topMenuData.push(x);
        }
    })

    return {
        hideSideMenu: hideSideMenu,
        hasButton: false,
        buttonText: "Send Tax Return",
        buttonIcon: "",
        topMenuData: topMenuData,
        bottomMenuData: bottomMenuData,
        onButtonClick: () => null,
        onNavigate: (route: string) => {
            history.push(route);
        },
        isMenuToggled: false
    };
}

export const initialSideMenuConfig: ISideMenuProps = {
    hideSideMenu: true,
    hasButton: false,
    buttonText: "",
    buttonIcon: "",
    topMenuData: [
        {
            section: 1,
            items: [
                {
                    caption: "",
                    route: "/",
                    disabled: false,
                    menuOrder: 1,
                    active: false
                }
            ]
        }
    ],
    bottomMenuData: [
        {
            section: 1,
            items: [
                {
                    caption: "",
                    route: "#",
                    disabled: false,
                    menuOrder: 1,
                    active: false
                }
            ]
        }
    ],
    onButtonClick: () => null,
    onNavigate: () => null,
    isMenuToggled: false
};

export const initialWidgetDropdownData: ISectionData[] = [
    {
        caption: "",
        items: [
            {
                caption: "",
                route: "#"
            },
            {
                caption: "",
                route: "#"
            },
            {
                caption: "",
                route: "#"
            },
            {
                caption: "",
                route: "#"
            },
            {
                caption: "",
                route: "#"
            }
        ]
    },
    {
        caption: "",
        items: [
            {
                caption: "",
                route: "#"
            },
            {
                caption: "",
                route: "#"
            }
        ]
    }
];

export const initialMenuSection: IMenuSection = {
    section: 0,
    items: []
}

export const initialSideMenu: ISideMenu = {
    leftMenu: [initialMenuSection],
    isInitialState: true
};
