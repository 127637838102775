import * as React from 'react';
import { Card, Col, Row, Button, FormLabel } from 'react-bootstrap';
import { ISSRUserModel } from '../../../../Core/ViewModels/User/UserViewModel';
import * as TaxDocument from '../../../common/TaxReturn';

interface IApproveForDelivery {
    allUsersList: ISSRUserModel[],
    cancelFinish: () => void;
    approveForDelivery: () => void;
    selectedUser: number
    onChangeUser(event: any): void;
}

export class ApproveForDelivery extends React.Component<IApproveForDelivery, {}> {
    public render() {
        return <Card style={{ height:"200px" }}>
            <Card.Body>
                <Row className="marB15">
                    <Col sm={12}>
                    </Col>
                </Row>
                <Row className="marB15">
                    <Col className="text-left" sm={12} as={FormLabel}>
                        Please select a person
                    </Col>
                </Row>
                <Row className="marB15">
                    <Col sm={12}>
                        <select className="form-control"
                            id="ddl"
                            value={this.props.selectedUser}
                            onChange={this.props.onChangeUser}
                            data-test-auto="0F977C9E-557E-4D36-B2D3-20946C82426A">
                            <option key={0} value={0}>---Select---</option>
                            {this.props.allUsersList.map((user, index) => {
                                return <option key={index} value={user?.id}>{user?.firstName + " " + user?.lastName}</option>
                            })}
                        </select>
                    </Col>
                </Row>
                <div>
                    <Col sm={12}>
                        <Button type="button" variant="info" className="float-right" data-test-auto="195D89FF-87E4-4A26-8F01-D8F58B237077"
                            onClick={this.props.approveForDelivery}
                        ><i className="fa fa-check" ></i> Approve</Button>
                        <Button type="button" variant="default" className="float-right marR05 btn-white btn-default" data-test-auto="200E180C-30FE-411A-928F-8D23819501C1"
                            onClick={this.props.cancelFinish}
                        ><i className="fas fa-times"></i>Cancel</Button>
                    </Col>
                </div>
            </Card.Body>
        </Card>
    }
}

export default ApproveForDelivery;
