import { connect } from 'react-redux';
import { ApplicationState } from '../store/index';
import { ChangeDefault } from './ChangeDefaultWarning';
import * as UserSettingStore from '../store/userManagement/UserSettingStore';



export default connect(
    (state: ApplicationState) => ({
        userSettings: state.userSettings,
    }),
    {
        ...UserSettingStore.actionCreators,
    }
)(ChangeDefault as any);