import { injectable } from "inversify";

export interface IFileUtilities {
    getExtension(fileName: string): string;
    isValidateSize(file: any, maxSize: number): boolean;
    isValidateExtension(file: any, expectedExtensions: string[]): boolean;
    download(url: string, fileName?: string): void;
    getSafeFilename(fileName: string): string;
    addExtensionIfNotExist(fileName: string, extension: string): string;
    removeLastCharUnderscore(fileName: string): string;
}

@injectable()
export class FileUtilities implements IFileUtilities {

    getExtension(fileName: string): string {
        return fileName.slice((fileName.lastIndexOf(".") - 1 >>> 0) + 2);
    }

    isValidateSize(file: any, maxSize: number): boolean {
        return file?.size <= maxSize;
    }

    isValidateExtension(file: any, expectedExtensions: string[]): boolean {
        const fileExtension: string = this.getExtension(file);
        return expectedExtensions.indexOf(fileExtension.toLowerCase()) !== -1;
    }

    addExtensionIfNotExist(fileName: string, extension: string): string {

        if (!this.getExtension(fileName)) {
            return fileName + "." + extension;
        }

        return fileName;
    }


    getSafeFilename(fileName: string): string {
        var pattern = new RegExp(/[^a-z A-Z 0-9. _\-]+/g);
        return fileName.replace(pattern, '_');
    }

    download(url: string, fileName?: string): void {
        let link: HTMLAnchorElement = document.createElement('a');
        link.href = url;
        link.target = "_blank";
        if (fileName) {
            link.download = fileName;
        }
        document.body.appendChild(link);
        link.click();
        setTimeout(function () {
            document.body.removeChild(link);
        }, 100);
    }

    removeLastCharUnderscore(fileName: string): string {
        var pattern = new RegExp(/\_$/);
        var file = fileName.split('.');
        return this.addExtensionIfNotExist(file[0].replace(pattern, ''), file[1]);
    }
}