import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import * as Component from './K1DeliveredStatus';
import * as K1DeliveredStatusStore from './../../store/reports/K1DeliveredStatusReport/K1DeliveredStatusStore';
import * as FilterStore from '../../store/reports/FilterStore';
import * as UploadTaxReturnStore from '../../store/common/uploadTaxReturn';
import * as CompanyStore from '../../store/company/CompanyStore';
import * as UserStore from '../../store/userManagement/UserStore';
import * as UserSettingStore from '../../store/userManagement/UserSettingStore';
import * as TaxDocumentStore from './../../store/common/TaxDocumentStore';
import * as GroupedReturnStore from '../../store/groupedreturns/GroupedReturnStore';
import * as BulkOperationsStore from '../../store/common/BulkOperationsStore';

export default connect(
    (state: ApplicationState) => {
        return ({
            k1DeliveredStatusState: state.k1DeliveredStatusState,
            reportFilters: state.reportFilters,
            uploadTaxReturnStore: state.UploadTaxReturnStore,
            company: state.companyData,
            userStore: state.ssrUserList,
            userSettings: state.userSettings,
            groupedReturnStore: state.groupedReturnStore,
            userProfile: state.userProfile,
        });
    },
    {
        ...K1DeliveredStatusStore.actionCreator,
        ...FilterStore.actionCreators,
        ...UploadTaxReturnStore.actionCreators,
        ...CompanyStore.actionCreators,
        ...UserStore.actionCreators,
        ...UserSettingStore.actionCreators,
        ...TaxDocumentStore.actionCreators,
        ...GroupedReturnStore.actionCreators,
        ...BulkOperationsStore.actionCreators,
    }
)(Component.K1DeliveredStatus as any);