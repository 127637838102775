export interface IInboxMessage {
    id: number,
    userId: number,
    companyId: number,
    customerNotificationId: number,
    title: string,
    message: string,
    messageCategory: MessageCategory,
    messageType: MessageType,
    messageStatus: MessageStatus,
    createdOn: Date,
    startDate: Date,
    validThrough?: Date
}

export enum MessageCategory {
    None = 0,
    Support = 1,
    Firm = 2
}

export enum MessageType {
    None = 0,

    // support
    KnownIssues = 1,
    ReleaseUpdates = 2,
    UnderMaintenance = 3,
    ProcessingItems = 4,
    Others = 5,

    // firm
    // todo: this needs to be updated
    ReturnReady = 1,
    Delivered = 2
}

export enum MessageStatus {
    Unread = 0,
    Read = 1,
    Deleted = 2
}

