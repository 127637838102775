import { addTask } from 'domain-task';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../';
import { actionTypes } from '../../types/ActionTypes';
import { ITaxReturn, EngagementType, IVoucher } from '../../components/common/TaxReturn';
import { StatusType, NotificationAction } from '../common/NotificationStore';
import { handleResponse } from '../Library';
import { Guid } from '../../Core/Utilities/Guid';
import * as Constants from '../../components/helper/Constants';
import { K1Instruction } from '../common/TaxDocumentStore';
import { TaxReturnUploadData } from '../../Core/ViewModels/Common/UploadDocumentViewModel';
import { RequestTaxDocumentAction } from './TaxDocumentStore';
import { API_BASE_URL } from '../../utils/constants';
import { UploadFunctions } from "@sssuite-js-packages/file-utility";
import { UploadStatus } from '../../Core/ViewModels/Common/UploadDocumentViewModel';
import { logger } from '../../routes/LoggedIn';

export interface IUploadState { }

type KnownAction = NotificationAction | K1Instruction | RequestTaxDocumentAction;

export const actionCreators = {
	uploadK1Instruction: (engagementType: EngagementType, documentGuid: string, taxYear: number, file: any, documentId: number, callback?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
		const fetchTask = fetch(API_BASE_URL + 'api/Upload/GetK1UploadLinkAsync?engagementType=' + engagementType.toString() + "&documentKey=" +
			documentGuid + '&taxYear=' + taxYear, { credentials: 'include' })
			.then((resp) => resp.json())
			.then(function (data) { return data; })
			.then(url => {
				let uploadHelperFunctions = new UploadFunctions();
				file.sasUrl = url;
				file.status = UploadStatus.Uploading;
				let data = { sas: url, guid: documentGuid };
				uploadHelperFunctions.uploadFile(file, data, file.name ? file.name : "", null,
					() => {
						dispatch({
							type: actionTypes.NOTIFICATION, statusMessage: Constants.K1TabConstants.K1UploadConfirmation, statusType: StatusType.Success
						});
						dispatch({ type: actionTypes.K1_INSTRUCTION_DETAIL, fileName: file.name, id: documentId, isDefaultK1: false });
						if (callback)
							callback();
					}, (fileToUpload) => {
						// This code will be changed later
						console.log("upload failed for file:", fileToUpload.fileName);
					});
			})
			.catch((error: any) => {
                logger.trackError(`uploadK1Instruction failed for documentId: ${documentId}, error: ${error}`); 
			});
	},

	requestVoucherUploadLink: (taxReturn: ITaxReturn, callback: (data: any) => void):
		AppThunkAction<KnownAction> => (dispatch, getState) => {
			let fileGuid = Guid.newGuid().toString();
			const fetchTask = fetch(API_BASE_URL + 'api/ProcessReturn/GetUploadSASUrlAsync/?documentGuid=' +
				taxReturn.documentGuid + '&fileName=' + escape(fileGuid) + '&taxYear=' +
				taxReturn.taxYear + '&uploadType=' + "voucher", { credentials: 'include' })
				.then(handleResponse)
				.then(data => {
					data.fileGuid = fileGuid;
					callback(data);
				})
				.catch((error: any) => {
                    logger.trackError(`Upload requestVoucherUploadLink failed for documentId: ${taxReturn.id}, error: ${error}`); 
				});
			addTask(fetchTask); // Ensure server-side prerendering waits for this to complete
		},

	requestMultiVoucherUploadLink: (taxReturn: ITaxReturn, cbVoucher: IVoucher, callback: (data: any, voucher: IVoucher, isFinalVoucher?: boolean) => void, isFinalVoucher?: boolean):
		AppThunkAction<KnownAction> => (dispatch, getState) => {
			let fileGuid = Guid.newGuid().toString();
			const fetchTask = fetch(API_BASE_URL + 'api/ProcessReturn/GetUploadSASUrlAsync/?documentGuid=' +
				taxReturn.documentGuid + '&fileName=' + escape(fileGuid) + '&taxYear=' +
				taxReturn.taxYear + '&uploadType=' + "voucher", { credentials: 'include' })
				.then(handleResponse)
				.then(data => {
					data.fileGuid = fileGuid;
					if (isFinalVoucher !== undefined)
						callback(data, cbVoucher, isFinalVoucher);
					else
						callback(data, cbVoucher);
				})
				.catch((error: any) => {
                    logger.trackError(`Upload requestMultiVoucherUploadLink failed for documentId: ${taxReturn.id}, error: ${error}`); 
				});
			addTask(fetchTask); // Ensure server-side prerendering waits for this to complete
		},

	submitRecallTaxReturn: (docId: number, uploadData: TaxReturnUploadData[], onResponse: (response: any, error: any) => void, resourceId?: string): AppThunkAction<KnownAction> => (dispatch, getState) => {

		let options: any = {
			method: 'POST',
			credentials: 'include',
			body: JSON.stringify(uploadData),
			headers: {
				'Accept': 'application/json, text/plain,*/*',
				'Content-Type': 'application/json',
				'traditional': 'true',
				'X-Resource-Id': resourceId,
				'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
			}
		};

		const fetchTask = fetch(API_BASE_URL + 'api/Upload/SubmitRecallTaxReturnsAync', options)
			.then(response => {
				dispatch({ type: actionTypes.REQUEST_TAX_DOCUMENT, id: docId });
				if (onResponse) {
					onResponse(response, null);
				}
			}).catch(error => {
				if (onResponse) {
					onResponse(null, error);
				}
			});
	}
}

const unloadedState: IUploadState = {} as IUploadState;

export const reducer: Reducer<IUploadState> = (state = unloadedState, incomingAction) => {
	const action = incomingAction as KnownAction;

	return state;
};
