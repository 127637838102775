import * as React from "react";
import { IPdfDocumentFacade } from "../../../../Core/Utilities/PdfDocumentFacade";
import { PdfProperties } from "../../../../Core/ViewModels/Common/PdfHelper";
import { IBlobFile } from "../../../../store/common";
import * as PdfStore from "../../../../store/pdf/PdfStore";
import { IPageWiseK1SSNDictionary } from "../../../../store/ProcessReturn/K1SSNStore";
import { IBusinessReturnsState } from "../../../../store/settings/BusinessReturnsStore";
import { FilePreview } from "../../../common/FilePreview";
import * as TaxDocument from "../../../common/TaxReturn";
import { EngagementType } from "../../../common/TaxReturn";
import * as Constants from "../../../helper/Constants";
import { cloneTaxReturn } from "../../../helper/HelperFunctions";
import { isValidSSN } from "../../../helper/Validations";
import { IUserProfile } from "../../../navigation/profile/ProfileObjects";
import { BookmarkTree } from "../../BookmarkTree";
import { AwesomePdfViewer } from "../AwesomePdfViewer";
import { BookmarksPane } from "../BookmarksPane";
import { INVALID_PAGE } from "../CustomPagination/CustomPagination";
import { IK1Attachment, IK1AttachmentDetails, K1AttachmentUploadData } from "../K1Attachments/IK1Attachment";
import { K1AttachmentEditModal } from "../K1Attachments/K1AttachmentEditModal";
import { K1AttachmentUploadModal } from "../K1Attachments/K1AttachmentUploadModal";
import { PageMoveTo } from "../PageMoveTo";
import { IBookmarkSection, IShareHolder, PdfPageSignatureControls } from "../ProcessReturnModels";
import { K1Actions } from "../RightSidebar/K1Actions";
import { K1SSNMaskSetting } from "../RightSidebar/K1SSNMaskSetting";
import { ShareholderInfo } from "../RightSidebar/ShareholderInfo";
import { VenusNotifier } from "src/components/helper/VenusNotifier";

export interface IFormGroupViewProps {
    bookmarks: IBookmarkSection[];
    pages: number[];
    document: PdfStore.IPdfDocumentDictionary;
    reGroupPreview: (pages: number[], destinationGroup: TaxDocument.DocumentGroups) => void;
    taxReturn: TaxDocument.ITaxReturn;
    getTitle?: (pageTitle: string, id: number) => void;
    renderTrigger: string;
    shareHolderInfo: IShareHolder;
    updateTaxDocument: (taxDocument: TaxDocument.ITaxReturn) => void;
    getK1InstructionFileName: (
        guid: string,
        taxYear: number,
        documentId: number,
        engagementType: TaxDocument.EngagementType
    ) => void;
    fileName: string;
    uploadK1Instruction: (
        engagementType: TaxDocument.EngagementType,
        documentGuid: string,
        taxYear: number,
        fileName: string,
        documentId: number,
        callback?: () => void
    ) => void;
    downloadK1DocumentLink: (
        documentGuid: string,
        type: string,
        taxYear: number,
        openPreviewCallBack: (url: string) => void,
        verifyK1Replaced: boolean,
        isK1Replaced: boolean
    ) => void;
    isAssignedToLoggedinUser: boolean;
    verifyK1Replaced: boolean;
    isK1Restored: boolean;
    isK1Replaced: boolean;
    K1InstructionModified: (verifyK1Replaced: boolean, isK1Restored: boolean, isK1Replaced?: boolean) => void;
    restoreK1Instruction: (fileName: string, documentId: number) => void;
    getAllTaxingAuthorities: TaxDocument.ITaxingAuthority[];
    businessInstructionStore: IBusinessReturnsState;
    isEnableInvoice: boolean;
    disableSaveClose: (option: boolean) => void;
    getk1AttachmentUploadLink: (url: string, callback?: (data?: IBlobFile) => void) => void;
    userProfile: IUserProfile;
    isDefaultK1Instruction: boolean;
    isCompanyK1SSNMaskingEnabled: boolean;
    k1SSNDetails?: TaxDocument.IK1SSNDetails[];
    getK1SSNDetailsForPage: (
        documentId: number,
        ssn: string,
        pageNum: number,
        entityType: TaxDocument.EntityType,
        callBack: (ssnPlacements: TaxDocument.IK1SSNDetails[]) => void
    ) => void;
    pageWiseSSNDetails: IPageWiseK1SSNDictionary;
}

interface IformGroupViewState {
    currentPage: number;
    zoomEnabled: boolean;
    fileName: string;
    openActionPanel: string | null;
    openShareHolderPanel: string | null;
    name: string;
    ssn: string;
    mobileNumber: string;
    countryCode: string;
    emailAddress: string;
    url: string;
    fileType: string | undefined;
    showPDFPreview: boolean;
    files: any;
    financialYearEnd: Date | undefined;
    partnerAddress: string;
    entityType: TaxDocument.EntityType;
    currentPageIxdex: number;
    cachedPrevProp: IFormGroupViewProps;
    isK1ReminderEnabled: boolean;
    isK1RemindersAllChecked: boolean;
    showUploadAttachment: boolean;
    showEditAttachment: boolean;
    selectedK1Attachment: IK1Attachment[];
    scale: number;
    isDocumentK1SSNMaskingEnabled: boolean;
    ssnDetails: TaxDocument.IK1SSNDetails[] | undefined;
}
const isEqual = require("react-fast-compare");
export class TabK1s extends React.Component<IFormGroupViewProps, IformGroupViewState> {
    constructor(props: IFormGroupViewProps) {
        super(props);
        this.state = {
            currentPage: INVALID_PAGE,
            currentPageIxdex: 0,
            zoomEnabled: false,
            fileName: "",
            openActionPanel: null,
            openShareHolderPanel: null,
            name: "",
            ssn: "",
            mobileNumber: "",
            countryCode: "+1",
            emailAddress: "",
            url: "",
            fileType: undefined,
            files: {},
            showPDFPreview: false,
            financialYearEnd: undefined,
            partnerAddress: "",
            entityType: TaxDocument.EntityType.None,
            cachedPrevProp: {} as IFormGroupViewProps,
            showUploadAttachment: false,
            showEditAttachment: false,
            selectedK1Attachment: [],
            isK1ReminderEnabled: true,
            isK1RemindersAllChecked: true,
            scale: PdfProperties.DefaultScale,
            isDocumentK1SSNMaskingEnabled: false,
            ssnDetails: []
        };

        this.previewDocument = this.previewDocument.bind(this);
        this.onHidePDFPreview = this.onHidePDFPreview.bind(this);
        this.isIndividualEntityType = this.isIndividualEntityType.bind(this);

        this.onNameChange = this.onNameChange.bind(this);
        this.onSSNChange = this.onSSNChange.bind(this);
        this.onChangeCountryCode = this.onChangeCountryCode.bind(this);
        this.onChangeMobileNumber = this.onChangeMobileNumber.bind(this);
        this.onEmailChange = this.onEmailChange.bind(this);
        this.onAddressChange = this.onAddressChange.bind(this);
        this.onEntityTypeChange = this.onEntityTypeChange.bind(this);
        this.handleBlurName = this.handleBlurName.bind(this);
        this.handleBlurSSN = this.handleBlurSSN.bind(this);
        this.handleEntityTypeChange = this.handleEntityTypeChange.bind(this);
        this.handleBlurEmail = this.handleBlurEmail.bind(this);
        this.handleBlurMobileNumber = this.handleBlurMobileNumber.bind(this);
        this.handleBlurCountryCode = this.handleBlurCountryCode.bind(this);
        this.handleBlurAddress = this.handleBlurAddress.bind(this);
        this.handleFinancialYearEndChange = this.handleFinancialYearEndChange.bind(this);
    }

    componentDidMount(): void {
        let filteredK1Reminders: any = this.props.taxReturn.formGroups.filter((x: any) => x.group == 7);
        const filteredK1RemindersLength: number = filteredK1Reminders.length;
        this.setState({
            isK1RemindersAllChecked: this.handleK1RemindersAllChecked(filteredK1Reminders, filteredK1RemindersLength)
        });

        if (
            this.props.k1SSNDetails?.length == 0 &&
            (this.props.pageWiseSSNDetails[this.props.taxReturn.id] == undefined ||
                this.props.pageWiseSSNDetails[this.props.taxReturn.id][this.state.currentPage] == undefined)
        ) {
            this.props.getK1SSNDetailsForPage(
                this.props.taxReturn.id,
                this.state.ssn,
                this.state.currentPage,
                this.state.entityType,
                (ssnDetails) => {
                    this.getMaskControls(ssnDetails);
                }
            );
        }
    }

    static getDerivedStateFromProps(nextProps: IFormGroupViewProps, prevState: IformGroupViewState) {
        let currentPage = prevState.currentPage;
        let fileName = nextProps.fileName != Constants.PredefinedFileName.FileNotFound ? nextProps.fileName : "";
        let prevProp = prevState.cachedPrevProp;

        let name = nextProps.shareHolderInfo.name != prevState.name ? nextProps.shareHolderInfo.name : prevState.name;
        let ssn = nextProps.shareHolderInfo.ssn != prevState.ssn ? nextProps.shareHolderInfo.ssn : prevState.ssn;
        let emailAddress =
            nextProps.shareHolderInfo.emailAddress != prevState.emailAddress
                ? nextProps.shareHolderInfo.emailAddress
                : prevState.emailAddress;
        let partnerAddress =
            nextProps.shareHolderInfo.partnerAddress != prevState.partnerAddress
                ? nextProps.shareHolderInfo.partnerAddress
                : prevState.partnerAddress;
        let countryCode =
            nextProps.shareHolderInfo.countryCode != prevState.countryCode
                ? nextProps.shareHolderInfo.countryCode
                : prevState.countryCode;
        let mobileNumber =
            nextProps.shareHolderInfo.mobileNumber != prevState.mobileNumber
                ? nextProps.shareHolderInfo.mobileNumber
                : prevState.mobileNumber;
        let financialYearEnd =
            nextProps.shareHolderInfo.financialYearEnd != prevState.financialYearEnd
                ? nextProps.shareHolderInfo.financialYearEnd
                : prevState.financialYearEnd;
        let entityType =
            nextProps.shareHolderInfo.entityType != prevState.entityType
                ? nextProps.shareHolderInfo.entityType
                : prevState.entityType;
        let isK1ReminderEnabled =
            nextProps.shareHolderInfo.isK1ReminderEnabled != prevState.isK1ReminderEnabled
                ? nextProps.shareHolderInfo.isK1ReminderEnabled
                : prevState.isK1ReminderEnabled;

        if (prevState.currentPage != nextProps.pages[0]) {
            currentPage = nextProps.pages[prevState.currentPageIxdex];
        }

        if (nextProps.fileName != "" && prevState.fileName == "") {
            let instructionFileName = "";
            if (!nextProps.businessInstructionStore.isLoading && nextProps.businessInstructionStore.instructions) {
                const instruction = nextProps.businessInstructionStore.instructions.find(
                    (x) =>
                        x.taxYear == nextProps.taxReturn.taxYear &&
                        EngagementType[x.engagementType] == nextProps.taxReturn.engagementType.toString()
                );
                if (instruction) {
                    instructionFileName = instruction.fileName;
                }
            }

            if (nextProps.fileName == Constants.PredefinedFileName.FileNotFound) {
                fileName = instructionFileName;
                let isK1Restored: boolean = true,
                    verifyK1Replaced: boolean = false;
                nextProps.K1InstructionModified(verifyK1Replaced, isK1Restored);
            } else {
                fileName = nextProps.fileName;
            }
        }

        if (!isEqual(prevProp, nextProps)) {
            return {
                name: name,
                ssn: ssn,
                emailAddress: emailAddress,
                partnerAddress: partnerAddress,
                financialYearEnd: financialYearEnd,
                entityType: entityType,
                countryCode: countryCode,
                mobileNumber: mobileNumber ? mobileNumber : "",
                currentPage: currentPage,
                fileName: fileName,
                isK1ReminderEnabled: isK1ReminderEnabled,
                cachedPrevProp: nextProps,
                isDocumentK1SSNMaskingEnabled: nextProps.taxReturn.documentSettings.documentK1Setting.isSSNMaskingEnabled
            };
        }

        return {
            fileName: fileName,
            cachedPrevProp: nextProps
        };
    }

    shouldComponentUpdate(nextProps: IFormGroupViewProps, nextState: IformGroupViewState) {
        return this.state.currentPage === INVALID_PAGE || !isEqual(this.state, nextState) || !isEqual(this.props, nextProps);
    }

    showPreview = (url: string) => {
        this.setState({
            showPDFPreview: true,
            url: url
        });
    };

    previewDocument() {
        this.props.downloadK1DocumentLink(
            this.props.taxReturn.documentGuid,
            this.props.taxReturn.engagementType.toString(),
            this.props.taxReturn.taxYear,
            this.showPreview,
            this.props.verifyK1Replaced,
            this.props.isK1Replaced
        );
    }

    onHidePDFPreview() {
        this.setState({ showPDFPreview: false });
    }
    onNameChange(event: any) {
        this.setState({ name: event.target.value });
    }
    onSSNChange(id: number, value: string) {
        this.setState({ ssn: value });
        let taxReturn: any = { ...this.props.taxReturn };
        if (
            taxReturn.formGroups.filter(
                (x: any) => x.group === TaxDocument.FormType.K1 && x.shareHolder.id !== id && x.shareHolder.ssn === value
            ).length > 0
        ) {
            VenusNotifier.Warning("SSN/EIN are same with another partner. Please update the SSN/EIN to avoid duplicates.", null);
        }
    }
    onChangeCountryCode = (value: any) => {
        this.setState({ countryCode: value });
    };
    onChangeMobileNumber = (value: any) => {
        this.setState({ mobileNumber: value });
    };
    onEmailChange(event: any) {
        this.setState({ emailAddress: event.target.value });
    }
    onEntityTypeChange(event: any) {
        let value = event ? event.value : "";
        this.setState({ entityType: value });
    }
    onAddressChange(event: any) {
        this.setState({ partnerAddress: event.target.value });
    }

    handleBlurName(id: number, event: any) {
        let taxReturn: any = { ...this.props.taxReturn };
        if (taxReturn.formGroups.filter((x: any) => x.group == TaxDocument.FormType.K1).length > 0) {
            taxReturn.formGroups
                .filter((x: any) => x.group == TaxDocument.FormType.K1)
                .find((x: any) => x.shareHolder.id == id).shareHolder.name = this.state.name;
            this.props.updateTaxDocument(taxReturn);
        }
    }

    handleBlurSSN(id: number, event: any) {
        let ssn: string = this.state.ssn;
        let unmaskedSSN: string = ssn.replace(/[-]/g, "");
        let taxReturn: any = { ...this.props.taxReturn };

        if (taxReturn.formGroups.filter((x: any) => x.group == TaxDocument.FormType.K1).length > 0) {
            const k1FormGroups = taxReturn.formGroups.filter((x: any) => x.group === TaxDocument.FormType.K1);
            if (k1FormGroups.find((x: any) => x.shareHolder.id !== id && x.shareHolder.ssn === this.state.ssn)) {
                const oldSSN = k1FormGroups.find((x: any) => x.shareHolder.id === id).shareHolder.ssn;
                this.setState({ ssn: oldSSN });
            } else {
                taxReturn.formGroups
                    .filter((x: any) => x.group == TaxDocument.FormType.K1)
                    .find((x: any) => x.shareHolder.id == id).shareHolder.ssn = unmaskedSSN;
                this.props.updateTaxDocument(taxReturn);
            }
        }
    }

    handleEntityTypeChange(id: number, event: any) {
        let taxReturn: any = { ...this.props.taxReturn };
        if (taxReturn.formGroups.filter((x: any) => x.group == TaxDocument.FormType.K1).length > 0) {
            taxReturn.formGroups
                .filter((x: any) => x.group == TaxDocument.FormType.K1)
                .find((x: any) => x.shareHolder.id == id).shareHolder.entityType = this.state.entityType;
            this.props.updateTaxDocument(taxReturn);
        }
    }
    handleBlurEmail(id: number, event: any) {
        let taxReturn: any = { ...this.props.taxReturn };
        if (taxReturn.formGroups.filter((x: any) => x.group == TaxDocument.FormType.K1).length > 0) {
            taxReturn.formGroups
                .filter((x: any) => x.group == TaxDocument.FormType.K1)
                .find((x: any) => x.shareHolder.id == id).shareHolder.emailAddress = this.state.emailAddress;
            this.props.updateTaxDocument(taxReturn);
        }
    }

    handleBlurMobileNumber = (id: number, event: any) => {
        let taxReturn: any = { ...this.props.taxReturn };
        if (taxReturn.formGroups.filter((x: any) => x.group == TaxDocument.FormType.K1).length > 0) {
            const filterK1ReminderById: any = taxReturn.formGroups
                .filter((x: any) => x.group == TaxDocument.FormType.K1)
                .find((x: any) => x.shareHolder.id == id);
            if (filterK1ReminderById) {
                filterK1ReminderById.shareHolder.mobileNumber = this.state.mobileNumber;
            }
            this.props.updateTaxDocument(taxReturn);
        }
    };

    handleBlurCountryCode(id: number, event: any) {
        let taxReturn: any = { ...this.props.taxReturn };
        if (taxReturn.formGroups.filter((x: any) => x.group == TaxDocument.FormType.K1).length > 0) {
            taxReturn.formGroups
                .filter((x: any) => x.group == TaxDocument.FormType.K1)
                .find((x: any) => x.shareHolder.id == id).shareHolder.countryCode = this.state.countryCode;
            this.props.updateTaxDocument(taxReturn);
        }
    }

    handleBlurAddress(id: number, event: any) {
        let taxReturn: any = { ...this.props.taxReturn };
        if (taxReturn.formGroups.filter((x: any) => x.group == TaxDocument.FormType.K1).length > 0) {
            taxReturn.formGroups
                .filter((x: any) => x.group == 7)
                .find((x: any) => x.shareHolder.id == id).shareHolder.partnerAddress = this.state.partnerAddress;
            this.props.updateTaxDocument(taxReturn);
        }
    }

    handleK1ReminderChange = (id: number, event: any) => {
        let taxReturn: any = { ...this.props.taxReturn };
        let filteredK1Reminders: any = taxReturn.formGroups.filter((x: any) => x.group == 7);
        const filteredK1RemindersLength: number = filteredK1Reminders.length;
        const filterK1ReminderById: any = filteredK1Reminders.find((x: any) => x?.shareHolder.id === id);
        if (filterK1ReminderById) {
            filterK1ReminderById.shareHolder.isK1ReminderEnabled = event.target.checked ? false : true;
        }
        this.props.updateTaxDocument(taxReturn);
        this.setState({
            isK1ReminderEnabled: event.target.checked ? false : true
        });
        this.setState({
            isK1RemindersAllChecked: this.handleK1RemindersAllChecked(filteredK1Reminders, filteredK1RemindersLength)
        });
    };

    handleK1RemindersAllChecked = (check: any, length: number) => {
        let checkTrue: number = 0;
        let checkFalse: number = 0;

        check.forEach((x: any) => (x.shareHolder.isK1ReminderEnabled === true ? checkTrue++ : checkFalse++));

        return checkFalse === length ? false : true;
    };

    handleK1ReminderActionChange = (event: any) => {
        let taxReturn: any = { ...this.props.taxReturn };
        taxReturn.formGroups
            .filter((x: any) => x.group == 7)
            .forEach((x: any) => (x.shareHolder.isK1ReminderEnabled = event.target.checked ? false : true));
        this.props.updateTaxDocument(taxReturn);
        this.setState({ isK1RemindersAllChecked: event.target.checked ? false : true });
    };

    private handleFinancialYearEndChange = (date: any) => {
        let taxReturn: any = { ...this.props.taxReturn };
        this.setState({ financialYearEnd: date });
        //As of now FYE remains same for all shareholders
        taxReturn.formGroups
            .filter((x: any) => x.group == TaxDocument.DocumentGroups.K1)
            .map((k1: any) => {
                k1.shareHolder.financialYearEnd = date;
            });
        this.props.updateTaxDocument(taxReturn);
    };

    private isIndividualEntityType = (): boolean => {
        var isIndividual: boolean = false;
        if (isValidSSN(this.props.shareHolderInfo.ssn ? this.props.shareHolderInfo.ssn : "")) {
            if (this.state.entityType !== TaxDocument.EntityType.None) {
                isIndividual = this.state.entityType === TaxDocument.EntityType.Individual ? true : false;
            } else {
                isIndividual = true;
            }
        }
        return isIndividual;
    };

    private onAddAttachmentClick = () => {
        this.setState({
            showUploadAttachment: true
        });
    };

    private onCloseK1Attachment = () => {
        this.setState({ showUploadAttachment: false, showEditAttachment: false });
    };

    private onK1AttachmentSubmit = (newUploadedAttachmentData: K1AttachmentUploadData[]) => {
        let _self = this;
        let taxReturn = cloneTaxReturn(_self.props.taxReturn);
        if (taxReturn.k1Attachments === undefined) {
            taxReturn.k1Attachments = [];
        }

        newUploadedAttachmentData.forEach((item, index) => {
            var fileData: IK1Attachment = {} as IK1Attachment;
            fileData.id = 0;
            fileData.fileName = item.fileName != undefined ? item.fileName : "";
            fileData.uploadedBy = _self.props.userProfile?.userId || 0;
            fileData.uploadedOn = new Date();
            fileData.isModified = true;
            fileData.isDeleted = false;
            fileData.k1AttachmentDetails = this.createAttachmentDetails(item.shareholderId);
            taxReturn.k1Attachments.push(fileData);
        });

        _self.props.updateTaxDocument(taxReturn);
        _self.onCloseK1Attachment();
    };

    private onK1AttachmentUpdate = (updateAttachmentData: IK1Attachment[], selectedBookmarks: number[]) => {
        let _self = this;
        let taxReturn = cloneTaxReturn(_self.props.taxReturn);
        if (taxReturn.k1Attachments === undefined) {
            taxReturn.k1Attachments = [];
        }

        updateAttachmentData.forEach((item: any) => {
            if (item.isDeleted && item.id == 0) {
                taxReturn.k1Attachments = taxReturn.k1Attachments.filter((e) => e.fileName !== item.fileName);
            } else if (item.isDeleted) {
                taxReturn.k1Attachments.filter((e) => e.fileName === item.fileName)[0].isModified = true;
                taxReturn.k1Attachments.filter((e) => e.fileName === item.fileName)[0].isDeleted = item.isDeleted;
            } else {
                taxReturn.k1Attachments.filter((e) => e.fileName === item.fileName)[0].isModified = true;
                taxReturn.k1Attachments.filter((e) => e.fileName === item.fileName)[0].k1AttachmentDetails =
                    this.createAttachmentDetails(selectedBookmarks);
            }
        });

        _self.props.updateTaxDocument(taxReturn);
        this.setState({ selectedK1Attachment: [] });
        _self.onCloseK1Attachment();
    };

    private createAttachmentDetails = (shareholderId: number[]): IK1AttachmentDetails[] => {
        let details: IK1AttachmentDetails[] = [];
        shareholderId.map((value: number) => {
            if (value > 0) {
                var attachmentData: IK1AttachmentDetails = {} as IK1AttachmentDetails;
                attachmentData.shareholderId = value;
                attachmentData.k1ShareholderDetailsId = this.getK1StatusId(value);
                details.push(attachmentData);
            }
        });
        return details;
    };

    private getK1StatusId = (id: number) => {
        let k1s = this.props.taxReturn.formGroups.filter((x) => x.group == TaxDocument.DocumentGroups.K1);
        let statusId = k1s
            .map((i: any) => {
                return i?.shareHolder;
            })
            .filter((y) => y.id == id)[0]?.k1StatusId;
        return statusId;
    };

    private onEditK1Attachment = (item: any) => {
        this.setState({ showEditAttachment: true });

        const selectedK1Attachment = this.props.taxReturn.k1Attachments.filter((e) => e.fileName === item.fileName);
        this.setState({ selectedK1Attachment: selectedK1Attachment });
    };

    private handleSSNMasking = (value: boolean) => {
        this.props.taxReturn.documentSettings.documentK1Setting.isSSNMaskingEnabled = value;
        this.setState({ isDocumentK1SSNMaskingEnabled: value }, () => {
            this.props.updateTaxDocument(this.props.taxReturn);
        });
    };

    private getMaskControls = (ssnDetails: TaxDocument.IK1SSNDetails[] | undefined): PdfPageSignatureControls => {
        let signatureControls: TaxDocument.IK1MaskControl[] | undefined = [];
        signatureControls =
            ssnDetails &&
            ssnDetails
                .filter((x) => x.pageNo == this.state.currentPage)
                .map((ssn) => {
                    return {
                        width: ssn.width + 30,
                        height: ssn.height + 10,
                        controlGuid: ssn.controlGuid,
                        top: (ssn.pageHeight - ssn.top) * Constants.AwesomePdfViewerDefaultScale - 1,
                        left: ssn.left * Constants.AwesomePdfViewerDefaultScale + 3,
                        maskValue: ssn.maskValue
                    } as TaxDocument.IK1MaskControl;
                });

        this.setState({ ssnDetails: ssnDetails });
        return {
            signatureControls: signatureControls,
            eroSigner: { id: 0, signPath: "" },
            signer: { id: 0, role: TaxDocument.SignatureControlRole.None },
            focusedGroup: TaxDocument.SignatureGroupType.None
        } as PdfPageSignatureControls;
    };

    createLeftPanel = () => {
        const getBodyContent = (item: any, index: number) => (
            <div key={index} className="custom-list-group-item bookmark-item ">
                <div className="bookmark-row">
                    <div
                        className="wordbreak ellipsis"
                        style={{ width: "calc(100% - 27px)" }}
                        data-test-auto="F63341A2-1B6A-443A-BAC4-950DCAF80390"
                        title={item.fileName}
                    >
                        {item.fileName}
                    </div>
                    <div className="cursor-pointer" data-test-auto="CE0B0836-75B7-4FF4-BF53-E879C2792B37">
                        <i
                            className={"fas fa-edit cursor-pointer"}
                            onClick={() => {
                                this.onEditK1Attachment(item);
                            }}
                            title={"Edit " + item.fileName}
                        />
                    </div>
                </div>
            </div>
        );

        return (
            <>
                <BookmarksPane
                    title="Pages"
                    docId={this.props.taxReturn?.id}
                    pdfDocuments={this.props.document as PdfStore.IPdfDocumentDictionary}
                    sections={this.props.bookmarks}
                    onPageSelect={this.onPageSelect}
                    getTitle={this.props.getTitle}
                    getAllTaxingAuthorities={this.props.getAllTaxingAuthorities}
                    taxReturn={this.props.taxReturn}
                />

                <BookmarkTree
                    sectionTitle="Add Attachments"
                    headerContent={
                        <button
                            id="btn-add"
                            title="Add K1 Attachments"
                            onClick={() => {
                                this.onAddAttachmentClick();
                            }}
                            data-test-auto="FB96444E-B275-452B-BBB1-1A27170A0B40"
                            className="btn-plus btn-success fa fa-plus"
                        ></button>
                    }
                    bodyContent={
                        this.props.taxReturn.k1Attachments &&
                        this.props.taxReturn.k1Attachments
                            .filter((item: any) => item.isDeleted === false)
                            .map((item: any, index: number) => getBodyContent(item, index))
                    }
                    isNoBodyContent={
                        this.props.taxReturn.k1Attachments &&
                        this.props.taxReturn.k1Attachments.filter((x) => x.isDeleted == false).length > 0
                            ? false
                            : true
                    }
                />
            </>
        );
    };

    createRightPanel = () => {
        return (
            <>
                <div>
                    <K1Actions
                        fileName={this.props.fileName}
                        getK1InstructionFileName={this.props.getK1InstructionFileName}
                        previewDocument={this.previewDocument}
                        restoreK1Instruction={this.props.restoreK1Instruction}
                        taxReturn={this.props.taxReturn}
                        businessInstructionStore={this.props.businessInstructionStore}
                        K1InstructionModified={this.props.K1InstructionModified}
                        disableSaveClose={this.props.disableSaveClose}
                        uploadK1Instruction={this.props.uploadK1Instruction}
                        handleK1ReminderActionChange={this.handleK1ReminderActionChange}
                        isK1RemindersAllChecked={this.state.isK1RemindersAllChecked}
                        isDefaultK1Instruction={this.props.isDefaultK1Instruction}
                    />
                </div>
                <div>
                    <ShareholderInfo
                        countryCode={this.state.countryCode}
                        emailAddress={this.state.emailAddress}
                        entityType={this.state.entityType}
                        financialYearEnd={this.state.financialYearEnd}
                        mobileNumber={this.state.mobileNumber}
                        name={this.state.name}
                        partnerAddress={this.state.partnerAddress}
                        ssn={this.state.ssn}
                        isK1ReminderEnabled={this.state.isK1ReminderEnabled}
                        shareHolderInfo={this.props.shareHolderInfo}
                        taxReturn={this.props.taxReturn}
                        handleBlurAddress={this.handleBlurAddress}
                        handleBlurCountryCode={this.handleBlurCountryCode}
                        handleBlurEmail={this.handleBlurEmail}
                        handleBlurMobileNumber={this.handleBlurMobileNumber}
                        handleBlurName={this.handleBlurName}
                        handleBlurSSN={this.handleBlurSSN}
                        handleEntityTypeChange={this.handleEntityTypeChange}
                        handleFinancialYearEndChange={this.handleFinancialYearEndChange}
                        handleK1ReminderChange={this.handleK1ReminderChange}
                        isAssignedToLoggedinUser={this.props.isAssignedToLoggedinUser}
                        isIndividualEntityType={this.isIndividualEntityType}
                        onAddressChange={this.onAddressChange}
                        onChangeCountryCode={this.onChangeCountryCode}
                        onChangeMobileNumber={this.onChangeMobileNumber}
                        onEmailChange={this.onEmailChange}
                        onEntityTypeChange={this.onEntityTypeChange}
                        onNameChange={this.onNameChange}
                        onSSNChange={this.onSSNChange}
                        isK1SSNMaskingEnabled={this.props.taxReturn.documentSettings.documentK1Setting.isSSNMaskingEnabled}
                    />
                </div>
                {this.props.isCompanyK1SSNMaskingEnabled && (
                    <div>
                        <K1SSNMaskSetting
                            updateSSNMaskSetting={this.handleSSNMasking}
                            isDocumentK1SSNMaskingEnabled={this.state.isDocumentK1SSNMaskingEnabled}
                        />
                    </div>
                )}
            </>
        );
    };

    createMoveTo = () => {
        return (
            <PageMoveTo
                documentGroup={TaxDocument.DocumentGroups.K1}
                reGroupPreview={this.reGroupPreview}
                engagementType={this.props.taxReturn.engagementType}
                arrangement={this.props.taxReturn.arrangement}
                isEnableInvoice={this.props.isEnableInvoice}
            />
        );
    };

    reGroupPreview = (destinationGroup: TaxDocument.DocumentGroups) => {
        const indexOfCurrentPage: number = this.props.pages.indexOf(this.state.currentPage);
        this.props.reGroupPreview([this.state.currentPage], destinationGroup);
        if (destinationGroup == TaxDocument.DocumentGroups.Deleted) {
            if (this.props.pages.length - 1 == indexOfCurrentPage) {
                this.setState({
                    currentPage: this.props.pages.indexOf(indexOfCurrentPage),
                    currentPageIxdex: indexOfCurrentPage - 1
                });
            } else {
                this.setState({
                    currentPage: this.props.pages.indexOf(indexOfCurrentPage),
                    currentPageIxdex: this.props.pages.indexOf(this.state.currentPage)
                });
            }
        } else {
            this.setState({
                currentPage: this.props.pages[0]
            });
        }
    };

    render() {
        return (
            <>
                <AwesomePdfViewer
                    pdfDocument={this.props.document[this.props.taxReturn?.id].document as IPdfDocumentFacade}
                    leftPanel={this.createLeftPanel()}
                    rightPanel={this.createRightPanel()}
                    currentPage={this.state.currentPage}
                    pages={this.props.pages}
                    enableMoveTo={true}
                    moveToElement={this.createMoveTo()}
                    isAssignedToLoggedinUser={this.props.isAssignedToLoggedinUser}
                    isIndividual={TaxDocument.isIndividual(this.props.taxReturn)}
                    scale={this.state.scale}
                    onScaleChange={(s) => this.setState({ scale: s })}
                    onCurrentPageChange={(page) =>
                        this.setState({ currentPage: page }, () => {
                            if (
                                this.props.k1SSNDetails?.length == 0 &&
                                this.props.pageWiseSSNDetails[this.props.taxReturn.id][page] == undefined
                            ) {
                                this.props.getK1SSNDetailsForPage(
                                    this.props.taxReturn.id,
                                    this.state.ssn,
                                    page,
                                    this.state.entityType,
                                    (ssnDetails) => {
                                        this.getMaskControls(ssnDetails);
                                    }
                                );
                            }
                        })
                    }
                    signatureControls={
                        this.state.isDocumentK1SSNMaskingEnabled
                            ? this.getMaskControls(
                                  this.props.k1SSNDetails?.length == 0
                                      ? this.props.pageWiseSSNDetails[this.props.taxReturn.id] != undefined &&
                                        this.props.pageWiseSSNDetails[this.props.taxReturn.id][this.state.currentPage] !=
                                            undefined
                                          ? this.props.pageWiseSSNDetails[this.props.taxReturn.id][this.state.currentPage]
                                          : []
                                      : this.props.k1SSNDetails
                              )
                            : undefined
                    }
                    isSSNMaskEnabled={this.state.isDocumentK1SSNMaskingEnabled}
                />
                <FilePreview
                    showPDFPreview={this.state.showPDFPreview}
                    onHidePDFPreview={this.onHidePDFPreview}
                    url={this.state.url}
                    fileType={"pdf"}
                    fileName={this.state.fileName}
                />

                {this.state.showUploadAttachment == true && (
                    <K1AttachmentUploadModal
                        showUploadAttachment={this.state.showUploadAttachment}
                        onCloseModal={this.onCloseK1Attachment}
                        taxReturn={this.props.taxReturn}
                        maxFileLimitUpload={30}
                        onSubmit={this.onK1AttachmentSubmit}
                        getUploadLink={this.props.getk1AttachmentUploadLink}
                        bookmarks={this.props.bookmarks}
                    />
                )}

                {this.state.showEditAttachment == true && (
                    <K1AttachmentEditModal
                        showUploadAttachment={this.state.showEditAttachment}
                        onCloseModal={this.onCloseK1Attachment}
                        taxReturn={this.props.taxReturn}
                        maxFileLimitUpload={30}
                        onSubmit={this.onK1AttachmentUpdate}
                        getUploadLink={this.props.getk1AttachmentUploadLink}
                        bookmarks={this.props.bookmarks}
                        selectedK1Attachment={this.state.selectedK1Attachment}
                    />
                )}
            </>
        );
    }

    private onPageSelect = (page: number) => {
        this.setState({ currentPage: page }, () => {
            if (
                this.props.k1SSNDetails?.length == 0 &&
                this.props.pageWiseSSNDetails[this.props.taxReturn.id][page] == undefined
            ) {
                this.props.getK1SSNDetailsForPage(
                    this.props.taxReturn.id,
                    this.state.ssn,
                    page,
                    this.state.entityType,
                    (ssnDetails) => {
                        this.getMaskControls(ssnDetails);
                    }
                );
            }
        });
    };
}
//=============================================================================
