import * as React from 'react';
import * as BootStrap from 'react-bootstrap';
import { Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { WatermarkProperties } from './WatermarkProperties';
import { PDFDocumentProxy } from 'pdfjs-dist';
import { LoadingOverlay, Loader } from 'react-overlay-loader';
import { IHelptextModel } from '../../../../Core/ViewModels/Common/IHelptextModel';
import { fetchHelpText } from '../../../helper/HelperFunctions';
import ToggleSwitch from '../../../common/ToggleSwitch';
import { ICompanySettings } from '../../../../Core/ViewModels/Company/CompanySettingsViewModel';
import { VenusNotifier } from '../../../helper/VenusNotifier';
import { CompanySettingsConstants } from '../../../helper/Constants';
import { TaxReturnSettingsResources } from '../../../../components/helper/ResourceIdConstants';

export interface IWatermarkModel {
    id: number;
    name: string;
    text: string;
    fontSize: number;
    fontFamily: string;
    opacity: number;
    fontColor: string;
    angle: number;
    coordinateX: number;
    coordinateY: number;
    rectWidth: number;
    rectHeight: number;
    isDefault: boolean;
}

export const initialWatermarkModel: IWatermarkModel = {
    id: 0,
    name: '',
    text: '',
    fontSize: 36,
    fontFamily: 'TimesRoman',
    opacity: 0.5,
    fontColor: '#000000',
    angle: 0,
    coordinateX: 0,
    coordinateY: 0,
    rectWidth: 300,
    rectHeight: 100,
    isDefault: false
}

export enum FontFamily {
    Helvetica = 0,
    Courier = 1,
    TimesRoman = 2,
}

interface IWatermarkSettingsProps {
    showLoader: boolean;
    watermarkData: IWatermarkModel[],
    updateWatermark(watermarkModel: IWatermarkModel, resourceId?: string): void;
    deleteWatermark(id: number, callback: () => void, resourceId?: string): void;
    requestWatermark(reload: boolean): void;
    sampleDocument: PDFDocumentProxy;
    helptexts?: IHelptextModel[];
    saveWatermark(watermarkModel: IWatermarkModel, companySettings: ICompanySettings, resourceId?: string): void;
    companySettings: ICompanySettings;
    updateCompanySettings(companySettings: ICompanySettings, resourceId?: string): void;
    parentResourceIdentifier: string;
}

interface IWatermarkSettingsState {
    showWatermarkPopup: boolean;
    watermarkModel: IWatermarkModel;
    isUpdate: boolean;
    watermarkName: string;
    helptextWatermarks: string;
    switched: boolean,
    defaultWatermarkId: number;
}

export class WatermarkSettings extends React.Component<IWatermarkSettingsProps, IWatermarkSettingsState>{
    state: IWatermarkSettingsState = {
        showWatermarkPopup: false,
        watermarkModel: initialWatermarkModel,
        isUpdate: false,
        watermarkName: '',
        helptextWatermarks: '',
        switched: true,
        defaultWatermarkId: 0
    }

    UNSAFE_componentWillReceiveProps() {
        if (this.props.companySettings && this.props.companySettings.displaySettingsModel) {
            if (this.props.companySettings.displaySettingsModel.isWatermarkEnabled) {
                this.setState({ switched: true });
            }
            else {
                this.setState({ switched: false });
            }
            if (this.props.companySettings.displaySettingsModel.defaultWatermarkId) {
                this.setState({ defaultWatermarkId: this.props.companySettings.displaySettingsModel.defaultWatermarkId });
            }
        }
        this.setState({ helptextWatermarks: fetchHelpText(this.props.helptexts, "25EF588A-7ADB-4C86-AB08-2F027BE3DF0A") });
    }

    handleToggleSwitchChange = (event: any) => {
        if (event.currentTarget.checked) {
            this.setState({ switched: true });
        } else {
            this.setState({ switched: false });
        }

        this.props.companySettings.displaySettingsModel.isWatermarkEnabled = event.currentTarget.checked;
        this.props.updateCompanySettings(this.props.companySettings, this.props.parentResourceIdentifier + TaxReturnSettingsResources.ToggleEnableOrDisable);
    };
    deleteWatermark = () => {
        this.props.deleteWatermark(this.state.watermarkModel.id, this.requestWatermark, this.props.parentResourceIdentifier + TaxReturnSettingsResources.ButtonDelete);
    }

    requestWatermark = () => {
        this.props.requestWatermark(true);
    }

    onWatermarkChange = (event: any) => {
        this.setState({
            watermarkName: event.target.value
        })
    }

    onHideWatermarkPopup = () => {
        this.setState({
            showWatermarkPopup: false
        })
    }

    onAddClick = () => {
        let temp = { ...initialWatermarkModel }
        temp.name = this.state.watermarkName;
        this.setState({
            watermarkModel: temp,
            showWatermarkPopup: true,
            isUpdate: false,
            watermarkName: ''
        })
    }

    onEditClick = (value: IWatermarkModel) => {
        this.setState({
            watermarkModel: value,
            showWatermarkPopup: true,
            isUpdate: true,
            watermarkName: '',
            
        })
    }

    proceedToDeleteWatermarkValue = () => {
        if (this.state.watermarkModel.id == this.state.defaultWatermarkId) {
            VenusNotifier.Warning(CompanySettingsConstants.Validate.DeleteWatermarkWarning, "")
        }
        else {
            this.deleteWatermark();
            document.body.click();
        }
    }

    deleteSelectedWatermark = (value: IWatermarkModel) => {
        this.setState({
            watermarkModel: value
        })
    }

    public closePopHover() {
        document.body.click();
    } 
    private updateWatermark = (watermarkModel: IWatermarkModel) => {
        this.props.updateWatermark(watermarkModel, this.props.parentResourceIdentifier + TaxReturnSettingsResources.ButtonEdit);
        if (watermarkModel.isDefault) {
            this.props.companySettings.displaySettingsModel.defaultWatermarkId = watermarkModel.id;
            this.props.updateCompanySettings(this.props.companySettings, this.props.parentResourceIdentifier + TaxReturnSettingsResources.ToggleEnableOrDisable);
        }
    }

    private saveWatermark = (watermarkModel: IWatermarkModel) => {
        if (watermarkModel.isDefault) {
            this.props.companySettings.displaySettingsModel.defaultWatermarkId = watermarkModel.id;
        }
        this.props.saveWatermark(watermarkModel, this.props.companySettings, this.props.parentResourceIdentifier + TaxReturnSettingsResources.ButtonAdd);
    }
    public render() {

        const popover = (
            <BootStrap.Popover id="popover-basic">
                <BootStrap.Popover.Title as="h3">Are you sure?</BootStrap.Popover.Title>
                <BootStrap.Popover.Content>
                    <button
                        className="btn btn-only btn-success"
                        onClick={this.proceedToDeleteWatermarkValue}
                        title="Yes"
                        data-test-auto="66EBAFDE-2CE3-4B49-80D1-4F59FBCA2A32"
                        style={{ marginLeft: "5px" }}>
                        Yes
                    </button>
                    <button
                        onClick={this.closePopHover}
                        title="No"
                        data-test-auto="0C5BEDAF-855B-408D-825D-16DBA770D62B"
                        className="btn btn-only btn-danger"
                        style={{ marginLeft: "5px" }}>
                            No
                    </button>
                </BootStrap.Popover.Content>
            </BootStrap.Popover>
        );

        return (
            <div>
                <div>
                    <div className="new-main-content">
                        <BootStrap.Row>
                            <BootStrap.Col className="text-left" sm={2} as={BootStrap.FormLabel} style={{ fontSize: "large", paddingTop: "3px" }}>
                                <h3>Watermarks</h3>
                            </BootStrap.Col>
                            <BootStrap.Col sm={4} md={3} lg={2} className="marL16"
                                data-resource-id = { this.props.parentResourceIdentifier + TaxReturnSettingsResources.ToggleEnableOrDisable } 
                            >
                                <ToggleSwitch data-test-auto="E9B25115-8295-49AE-A34D-54CA31DC91AC" 
                                    switched={this.state.switched}
                                    handleChange={this.handleToggleSwitchChange} />
                                <Link to={'#'} className="help-icon" style={{ marginLeft: "25px", verticalAlign: "middle", padding: "0px" }}
                                    data-toggle="tooltip" data-placement="right" title={this.state.helptextWatermarks}>
                                    <i className="fas fa-question-circle" style={{ marginLeft: "-20px" }}></i>
                                </Link>
                            </BootStrap.Col>
                        </BootStrap.Row>
                    </div>
                    {this.state.switched &&
                        <div className="col-lg-12">
                            <div>
                                <div className="row" style={{ paddingBottom: "5px" }}>
                                    <div className="col-lg-2 padT05" >
                                        <label>Watermark Name </label>
                                    </div>
                                    <div className="col-lg-3">
                                        <input className="form-control input-sm"
                                            type="text"
                                            value={this.state.watermarkName}
                                            placeholder="Name"
                                            onChange={this.onWatermarkChange}
                                            data-test-auto="F1C8DF76-64BF-4984-B670-48E493596454">
                                        </input>
                                    </div>
                                    <div className="col-lg-1">
                                        <button
                                            style={{ padding: "8px 12px" }}
                                            data-resource-id = { this.props.parentResourceIdentifier + TaxReturnSettingsResources.ButtonAdd }
                                            onClick={this.onAddClick}
                                            title="Add"
                                            data-test-auto="9FC776B1-D679-4F37-AC72-4EFDF9390072"
                                            className="btn btn-success btn-lg">
                                            <span className="fa fa-plus"></span>
                                        </button>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="list-group" style={{
                                            height: "150px", overflowY: "scroll",
                                            border: "#ebebec 1px solid"
                                        }}>
                                            <div>
                                                <LoadingOverlay style={{ height: '100%' }}>
                                                    {
                                                        this.props.watermarkData && this.props.watermarkData.map((value, index) => {
                                                            return <li className="list-group-item" key={index}>
                                                                {value.name}

                                                                <Nav.Link href='javascript:' className="list-group-item-action" style={{ display: 'inline' }}>
                                                                    <BootStrap.OverlayTrigger rootClose trigger="click" placement="right" overlay={popover}>
                                                                        <span className="fas fa-trash-alt text-danger float-right"
                                                                            style={{ marginLeft: "5px" }}
                                                                            data-resource-id = { this.props.parentResourceIdentifier + TaxReturnSettingsResources.ButtonDelete }
                                                                            onClick={() => { this.deleteSelectedWatermark(value) }}
                                                                        ></span>
                                                                    </BootStrap.OverlayTrigger>
                                                                </Nav.Link>

                                                                <Nav.Link href='javascript:' className="list-group-item-action" style={{ display: 'inline' }}>
                                                                    <span className="fas fa-edit float-right" style={{ marginLeft: "15px" }}
                                                                        data-resource-id = { this.props.parentResourceIdentifier + TaxReturnSettingsResources.ButtonDelete }
                                                                        onClick={() => { this.onEditClick(value) }}></span>
                                                                </Nav.Link>
                                                                {
                                                                    value.id == this.state.defaultWatermarkId ?
                                                                        <span className={"badge"} style={{ marginLeft: 10, float: 'right' }}>Default</span>
                                                                        : <span></span>
                                                                }
                                                            </li>
                                                        })
                                                    }
                                                    <Loader loading={this.props.showLoader} />
                                                </LoadingOverlay>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>

                <WatermarkProperties
                    showWatermark={this.state.showWatermarkPopup}
                    onHideWatermarkPopup={this.onHideWatermarkPopup}
                    saveWatermark={this.saveWatermark}
                    updateWatermark={this.updateWatermark}
                    sampleDocument={this.props.sampleDocument}
                    watermark={this.state.watermarkModel}
                    isUpdate={this.state.isUpdate}
                    defaultWatermarkId={this.state.defaultWatermarkId}
                />

            </div>

        );
    }
}