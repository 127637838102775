import * as React from 'react';
import { Col, FormControl, Row } from 'react-bootstrap';
import { ReportProblemProperty } from './ReportProblemModel';
import { PhoneNumberComponent } from '../PhoneNumberComponent';

interface ITaxpayerProps {
    taxpayerName: string,
    taxpayerEmail: string;
    handleValueChange(item: ReportProblemProperty, value: string): void;
    disabled: boolean;
}

export const TaxpayerDetails = (props: ITaxpayerProps) => {
    return (
        <div>
            <Row className="report-problem-field">

                <Col sm={2} >
                    <div className="font700">Taxpayer Name</div>
                </Col>
                <Col sm={4}>
                    <FormControl
                        type="text"
                        value={props.taxpayerName}
                        placeholder="Enter Taxpayer Name"
                        onChange={(event: any) => { props.handleValueChange(ReportProblemProperty.taxpayerName, event.target?.value) }}
                        onBlur={(event: any) => { props.handleValueChange(ReportProblemProperty.taxpayerName, event.target?.value) }}
                        disabled={props.disabled}
                    />
                </Col>

                <Col sm={2}>
                    <div className="font700">Taxpayer Email</div>
                </Col>
                <Col sm={4}>
                    <FormControl
                        type="text"
                        value={props.taxpayerEmail}
                        placeholder="Enter Taxpayer Email"
                        onChange={(event: any) => { props.handleValueChange(ReportProblemProperty.taxpayerEmail, event.target?.value) }}
                        onBlur={(event: any) => { props.handleValueChange(ReportProblemProperty.taxpayerEmail, event.target?.value) }}
                        disabled={props.disabled}
                    />
                </Col>
            </Row>
        </div>
    );
} 
