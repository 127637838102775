import { addTask } from 'domain-task';
import { Reducer } from 'redux';
import { AppThunkAction } from '../';
import { K1Attachments, UploadtaxReturnConstants } from '../../components/helper/Constants';
import { actionTypes } from '../../types/ActionTypes';
import { NotificationAction, StatusType } from '../../store/common/NotificationStore';
import { API_BASE_URL } from '../../utils/constants';
import { ITaxReturn } from '../../components/common/TaxReturn';
import { handleBlobwithFileName } from '../Library';
import { DisplayDownloadFile } from '../../components/common/DisplayDownloadFile';
import { IBlobFile } from '../common';
import * as CRSHelper from '../../components/helper/CRSHelper';

export interface RequestK1AttachmentAction {
    type: actionTypes.REQUEST_K1_ATTACHMENT;
    message: string;
}

export interface ReceiveK1AttachmentLinkAction {
    type: actionTypes.RECEIVE_K1_ATTACHMENT_LINK;
    sasUrl: string;
    documentGuid: string;
}

const unloadedState: IBlobFile =
{
    sas: '',
    guid: '',
};

type DispatchAction = RequestK1AttachmentAction |
    ReceiveK1AttachmentLinkAction;

type KnownAction = DispatchAction | NotificationAction;

export const actionCreators = {
    getk1AttachmentUploadLink: (url: string, callback?: (data?: any) => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(API_BASE_URL + url, { credentials: 'include' }).then((resp) => resp.json())
            .then(function (data) {
                if (callback) {
                    data ? callback(data) : callback();
                }
                dispatch({ type: actionTypes.RECEIVE_K1_ATTACHMENT_LINK, sasUrl: data.sas, documentGuid: data.guid });
            }).catch(error => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: K1Attachments.fetchDocumentError,
                    statusType: StatusType.Error
                })
            });
        addTask(fetchTask);
        dispatch({ type: actionTypes.REQUEST_K1_ATTACHMENT, message: UploadtaxReturnConstants.OverlayMessage.ApplicationLoading });
    },

    downloadK1Attachments: (
        documentGuid: string,
        documentId: number,
        taxYear: number,
        fileName: string,       
        k1AttachmentId: number ,
        callback?: (url: string, fileName: string) => void,
        resourceId:string=""):
        AppThunkAction<KnownAction> => (dispatch, getState) => {            
            
            const fetchTask = fetch(API_BASE_URL + 'api/Download/GetDownloadK1AttachmentsLinkAsync', {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json; charset=utf-8',
                    'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value,
                    'X-Resource-Id': resourceId
                },
                body: JSON.stringify({
                    attachmentName: fileName,
                    taxYear: taxYear,
                    documentGuid: documentGuid,
                    documentId: documentId,
                    attachmentId: k1AttachmentId
                })
            }).then(response => {
                const contentDisposition = response.headers.get("content-disposition");
                const fileNameMatch = contentDisposition ? /filename="?([^"]*)"?;/g.exec(contentDisposition) : undefined;
                if (fileNameMatch && fileNameMatch.length > 1) {
                    fileName = fileNameMatch[1];
                }
                return response.blob();
            }).then((data: any) => {
                if (callback) {
                    callback(data, fileName);
                }
                else {
                    let displayDownloadFile = new DisplayDownloadFile();
                    displayDownloadFile.showFile(data, fileName);
                }
            }).catch((error: any) => {
                console.log(error);
            });
            addTask(fetchTask);
        },

    downloadAllK1Attachments: (taxReturn: ITaxReturn, isArchivedReturn: boolean, clientName: string, resourceId:string=""): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const formData = new FormData();
        let taxReturnData = JSON.stringify(taxReturn);
        formData.append('taxDocument', taxReturnData);
        formData.append('isArchivedReturn', '' + CRSHelper.encodeSpecialCharactersOnly(JSON.stringify(isArchivedReturn)));
        formData.append('clientName', clientName);
        let options: any = {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value,
                'X-Resource-Id': resourceId
            },
            body: formData,

        };
        fetch(API_BASE_URL + 'api/Download/GetDownloadAllK1AttachmentsAsync', options).then(handleBlobwithFileName)
            .then((data) => {
                let displayDownloadFile = new DisplayDownloadFile();
                displayDownloadFile.showFile(data.blob, data.filename);
            })
            .catch((error: any) => {
                console.log(error);
            });
    },
};

export const reducer: Reducer<IBlobFile> = (state = unloadedState, incomingAction) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case actionTypes.REQUEST_K1_ATTACHMENT:
            return {
                ...unloadedState
            } as IBlobFile;
        case actionTypes.RECEIVE_K1_ATTACHMENT_LINK:
            var received = { ...state };
            received.sas = action.sasUrl;
            received.guid = action.documentGuid;
            return received;
    }
    return state;
}
