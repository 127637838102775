import * as React from 'react';
import { ButtonGroup, ButtonToolbar, Dropdown, DropdownButton, Overlay, OverlayTrigger, Popover } from 'react-bootstrap';
import { PageToolBarConstants } from '../../helper/Constants';
import { isInvoiceAllowed, isK1ShareHolder } from '../../helper/HelperFunctions';
import { Arrangement, DocumentGroups, EngagementType } from '../TaxReturn';

interface PageToolBarProps {
    documentGroup: DocumentGroups;
    reGroupPreview: (destinationGroup: DocumentGroups) => void;
    engagementType: EngagementType;
    arrangement: Arrangement;
    isEnableInvoice?: boolean;
    isRevisionProcess?: boolean;
}

interface PageToolBarState {
    showDeleteConfirmationPopover: boolean;
    deletePopoverTarget: any
    moveToToggle: boolean;
}

export class PageMoveTo extends React.Component<PageToolBarProps, PageToolBarState> {
    state: PageToolBarState = {
        showDeleteConfirmationPopover: false,
        deletePopoverTarget: {},
        moveToToggle: false
    }

    private handleMoveToDelete = () => {
        this.props.reGroupPreview(DocumentGroups.Deleted);
        this.hideMoveToDeletePopover();
        document.body.click();
    }

    private hideMoveToDeletePopover = () => {
        this.setState({ showDeleteConfirmationPopover: false });
        document.body.click();
    }

    private attachMoveToDeletePopoverRef = (target: any) => {
        this.setState({ deletePopoverTarget: target });
    };

    private toggleMoveToDropDown = (eventKey: any, event: Object) => {
        if (eventKey === DocumentGroups.Deleted.toString()) {
            this.setState({ moveToToggle: true })
        }
        else {
            this.setState({ moveToToggle: !this.state.moveToToggle })
        }
    }


    public render() {
        const deleteConfirmationPopover = (
            <Popover id="popover-to-delete-confirm" onClick={() => { this.setState({ showDeleteConfirmationPopover: false }) }}>
                <Popover.Title as="h3">{PageToolBarConstants.moveToDeleteConfirmation}</Popover.Title>
                <Popover.Content>
                    <button
                        className="btn btn-only btn-success"
                        onClick={this.handleMoveToDelete}
                        title="Yes"
                        style={{ marginLeft: "5px" }}>
                        Yes
                </button>
                    <button
                        onClick={this.hideMoveToDeletePopover}
                        title="No"
                        className="btn btn-only btn-danger"
                        style={{ marginLeft: "5px" }}>
                        No
                </button>
                </Popover.Content>
            </Popover>);

        const revisedEstimatePageDeleteConfirmationPopover = (
            <Popover id="popover-to-delete-confirm" onClick={() => { this.setState({ showDeleteConfirmationPopover: false }) }}>
                <Popover.Title as="h3">{PageToolBarConstants.deleteRevisedEstimatePageConfirmation}</Popover.Title>
                <Popover.Content>
                    <button
                        className="btn btn-only btn-success"
                        onClick={this.handleMoveToDelete}
                        title="Yes"
                        style={{ marginLeft: "5px" }}>
                        Yes
                </button>
                    <button
                        onClick={this.hideMoveToDeletePopover}
                        title="No"
                        className="btn btn-only btn-danger"
                        style={{ marginLeft: "5px" }}>
                        No
                </button>
                </Popover.Content>
            </Popover>);

        return (
            <ButtonToolbar>
                {
                    <ButtonGroup className="float-right zIndex10 font14" >
                        {!this.props.isRevisionProcess ?
                            <DropdownButton style={{ zIndex: 10000 }}
                                show={this.state.moveToToggle}
                                size="sm"
                                title="Move To"
                                id="move-to-dropdown"
                                variant="warning"
                                alignRight
                                onToggle={this.toggleMoveToDropDown}>

                                {
                                    this.props.documentGroup == DocumentGroups.Transmittals ? null :
                                        <Dropdown.Item className="font14"
                                            data-test-auto="1F6591BB-D38D-466C-A27D-B27ED31EC0F8" eventKey={DocumentGroups.Transmittals} onClick={() => { this.props.reGroupPreview(DocumentGroups.Transmittals) }} href="#">
                                            <i style={{ marginLeft: '-15px' }} className='fas fa-file-alt ddl-icon'></i>Transmittals</Dropdown.Item>
                                }

                                {
                                    this.props.documentGroup == DocumentGroups.TaxReturns ? null :
                                        <Dropdown.Item className="font14"
                                            data-test-auto="C5EF0A27-33E6-413F-BF76-F2885EF478B1" eventKey={DocumentGroups.TaxReturns} onClick={() => { this.props.reGroupPreview(DocumentGroups.TaxReturns) }} href="#">
                                            <i style={{ marginLeft: '-15px' }} className='fas fa-copy ddl-icon'></i>Tax Returns</Dropdown.Item>
                                }

                                {
                                    this.props.documentGroup == DocumentGroups.EFile ? null :
                                        <Dropdown.Item className="font14"
                                            data-test-auto="58625E2A-F7D4-4CE5-BBA5-C4DA72C3E341" eventKey={DocumentGroups.EFile} onClick={() => { this.props.reGroupPreview(DocumentGroups.EFile) }} href="#">
                                            <i style={{ marginLeft: '-15px' }} className='fas fa-pen-nib ddl-icon'></i>E-File</Dropdown.Item>
                                }
                               
                                {
                                    this.props.documentGroup == DocumentGroups.AdditionalEsignDocument ? null :
                                        <Dropdown.Item className="font14"
                                            data-test-auto="A1B2C3D4-E5F6-7890-GH12-IJKL345MN678" eventKey={DocumentGroups.AdditionalEsignDocument} onClick={() => { this.props.reGroupPreview(DocumentGroups.AdditionalEsignDocument) }} href="#">
                                            <i style={{ marginLeft: '-15px' }} className='fas fa-pen ddl-icon'></i>Additional ESign</Dropdown.Item>
                                }

                                {
                                    this.props.documentGroup == DocumentGroups.Vouchers ? null :
                                        <Dropdown.Item className="font14"
                                            data-test-auto="5DE4E238-5A82-479A-9893-EEF2199DF377" eventKey={DocumentGroups.Vouchers} onClick={() => { this.props.reGroupPreview(DocumentGroups.Vouchers) }} href="#">
                                            <i style={{ marginLeft: '-15px' }} className='fas fa-money-check-alt ddl-icon'></i>Vouchers</Dropdown.Item>
                                }

                                {
                                    this.props.isEnableInvoice && isInvoiceAllowed(this.props.arrangement) &&
                                        this.props.documentGroup != DocumentGroups.Invoice ?
                                        <Dropdown.Item className="font14"
                                            data-test-auto="1FE77968-8D48-4ABE-B851-5C2CD1EF9B8A" eventKey={DocumentGroups.Invoice} onClick={() => { this.props.reGroupPreview(DocumentGroups.Invoice) }} href="#">
                                            <i style={{ marginLeft: '-15px' }} className='fas fa-scroll ddl-icon'></i>Invoices</Dropdown.Item> : null
                                }

                                {
                                    isK1ShareHolder(this.props.engagementType, this.props.arrangement) && this.props.documentGroup != DocumentGroups.K1 ?
                                        <Dropdown.Item className="font14"
                                            data-test-auto="22743DC2-14C1-4D54-8BD4-980363A7FEE3" eventKey={DocumentGroups.K1} onClick={() => { this.props.reGroupPreview(DocumentGroups.K1) }} href="#">
                                            <i style={{ marginLeft: '-15px' }} className='fas fa-id-badge ddl-icon'></i>K1's</Dropdown.Item> : null
                                }

                                {
                                    this.props.documentGroup == DocumentGroups.Deleted ? null :
                                        <div>
                                            <Dropdown.Item className="font14"
                                                data-test-auto="9554BC70-D712-4FB3-A5B4-D656EA3727B5" eventKey={DocumentGroups.Deleted}
                                                onClick={(e: React.MouseEvent) => {
                                                    this.setState({ showDeleteConfirmationPopover: true });
                                                    e.stopPropagation();
                                                }}
                                                onSelect={this.toggleMoveToDropDown}
                                                href="#"
                                                ref={this.attachMoveToDeletePopoverRef}>
                                                <i style={{ marginLeft: '-15px' }} className='fas fa-trash ddl-icon'></i>Deleted
                                                </Dropdown.Item>
                                            <Overlay rootClose={true} onHide={this.hideMoveToDeletePopover}
                                                target={this.state.deletePopoverTarget} placement="left"
                                                show={this.state.showDeleteConfirmationPopover}
                                            >
                                                {deleteConfirmationPopover}
                                            </Overlay>
                                        </div>
                                }
                               
                            </DropdownButton>
                            :
                            <span className="float-right" id="move-to-dropdown" style={{ height: 31 }}>
                                <OverlayTrigger rootClose trigger="click" placement="right" onEnter={() => document.body.click()}
                                    overlay={revisedEstimatePageDeleteConfirmationPopover}>
                                    <button
                                        title="Delete"
                                        className="btn-warning btn-revision-page-delete">
                                        Delete
                                </button>
                                </OverlayTrigger>
                            </span>
                        }
                    </ButtonGroup>
                }
            </ButtonToolbar>
        );
    }

}