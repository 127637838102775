import * as React from 'react';
import { Col, FormControl, Row } from 'react-bootstrap';
import { TaxClientFileds } from '../../common/TaxClient';

interface IParterProps {
    partnerName: string,
    partnerEmail: string;
    handleValueChange?(taxclientField: TaxClientFileds, value: string): void;
    disabled: boolean;
}



export const PartnerDetails = (props: IParterProps) => {
    return (
        <div>
            <Row className="report-problem-field">
                <div style={{ display: 'contents' }}>

                    <Col sm={2} >
                        <div className="font700">Company Name</div>
                    </Col>
                    <Col sm={4}>
                        <FormControl
                            type="text"
                            value={props.partnerName}
                            placeholder="Enter Partner Name"
                            onChange={(event: any) => { if (props.handleValueChange) props.handleValueChange(TaxClientFileds.partnerName, event.target?.value) }}
                            onBlur={(event: any) => { if (props.handleValueChange) props.handleValueChange(TaxClientFileds.partnerName, event.target?.value) }}
                            disabled={props.disabled}
                        />
                    </Col>

                    <Col sm={2} >
                        <div className="font700">Company Email</div>
                    </Col>
                    <Col sm={4}>
                        <FormControl
                            type="text"
                            value={props.partnerEmail}
                            placeholder="Enter Partner Email"
                            onChange={(event: any) => { if (props.handleValueChange) props.handleValueChange(TaxClientFileds.partnerEmail, event.target?.value) }}
                            onBlur={(event: any) => { if (props.handleValueChange) props.handleValueChange(TaxClientFileds.partnerEmail, event.target?.value) }}
                            disabled={props.disabled}
                        />
                    </Col>
                </div>
            </Row>
        </div>
    );
} 