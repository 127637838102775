import * as React from 'react';
import {Row, Col, Card } from 'react-bootstrap';
import ToggleSwitch from '../../common/ToggleSwitch';
import Select from 'react-select';
import { IUserNotificationGeneralSettings } from '../../../Core/ViewModels/User/NotificationSettingModel';

export interface IUserNotificationGeneralSettingsProps {
    generalSettings: IUserNotificationGeneralSettings,
    onChangeGeneralSettings(generalSettings: IUserNotificationGeneralSettings): void;
}

interface IUserNotificationGeneralSettingState {
    generalSettings:IUserNotificationGeneralSettings
}

export class UserNotificationGeneralSettings extends React.Component<IUserNotificationGeneralSettingsProps, IUserNotificationGeneralSettingState>{
    state: IUserNotificationGeneralSettingState = {
        generalSettings: (
            {
                maxPopupTime: 5,
                maxVisibileNotification: 5,
                notificationValidFor: 30,
                playSound: false,
                showPopup:false
            })
    }
    
    UNSAFE_componentWillMount() {
        if(this.props.generalSettings!=undefined)
            this.setState(
                { generalSettings: { ...this.props.generalSettings } }
            );
    }
   

    buildCountOptions = () => {
        var countArray = [];
        for (let i = 1; i <= 10; i++) {
            countArray.push({
                value: i,
                label: i.toString()
            })
        }
        return countArray;
    }

    buildMonthOptions = () => {
        var monthArray = [];
        for (let i = 1; i <= 30; i++) {
            monthArray.push({
                value: i,
                label: i.toString()
            })
        }
        return monthArray;
    }

    buildSecondsOptions = () => {
        var secondsArray = [];
        for (let i = 2; i <= 5; i++) {
            secondsArray.push({
                value: i,
                label: i.toString()
            })
        }
        return secondsArray;
    }

    handleShowPopupToggleSwitchChange = (event: any) => {
        this.setState({
            generalSettings:
                { ...this.state.generalSettings, showPopup: event.currentTarget.checked }
        }, () => this.props.onChangeGeneralSettings(this.state.generalSettings));
    }

    handlePlaySoundToggleSwitchChange = (event: any) => {
        this.setState({
            generalSettings:
                { ...this.state.generalSettings, playSound: event.currentTarget.checked }
        },() => this.props.onChangeGeneralSettings(this.state.generalSettings));
    }

    handleMaxVisibileNotification = (event: any) => {
        this.setState({
            generalSettings:
                { ...this.state.generalSettings, maxVisibileNotification: event.value }
        },() => this.props.onChangeGeneralSettings(this.state.generalSettings))
    }

    handleRemoveNotificationDays = (event: any) => {
        this.setState({
            generalSettings:
                { ...this.state.generalSettings, notificationValidFor: event.value }
        }, () => this.props.onChangeGeneralSettings(this.state.generalSettings))
    }

    handleMaxPopupTime = (event: any) => {
        this.setState({
            generalSettings:
                { ...this.state.generalSettings, maxPopupTime : event.value }
        }, () => this.props.onChangeGeneralSettings(this.state.generalSettings))
    }

    render() {
        return <Card className="settingsPanel">
                <Card.Body>
            <Row className="marB15">
                <Col className="text-left padT08" sm={6} >
                    <h5>Notifications to be visible</h5>
                </Col>
                <Col className="padT08" sm={3} >
                    <Select
                        data-test-auto="bb080c36-d227-11ea-87d0-0242ac130003"
                        name="maxVisibileNotification"
                        value={this.state.generalSettings.maxVisibileNotification}
                        onChange={this.handleMaxVisibileNotification}
                        options={this.buildCountOptions()}
                        clearable={false}
                    />
                </Col>
            </Row>
            <Row className="marB15">
                <Col className="text-left padT08" sm={6} >
                    <h5>Remove notifications after</h5>
                </Col>
                <Col className="padT08" sm={3} >
                    <Select
                        data-test-auto="bb080b6e-d227-11ea-87d0-0242ac130003"
                        name="notificationValidFor"
                        value={this.state.generalSettings.notificationValidFor}
                        onChange={this.handleRemoveNotificationDays}
                        options={this.buildMonthOptions()}
                        clearable={false}
                    />
                </Col>
                <Col className="padT08">
                    <h5>days</h5>
                </Col>
            </Row>
            <Row className="marB15">
                <Col className="text-left padT08" sm={6} >
                    <h5>Show notification pop-up</h5>
                </Col>
                    <Col className="padT08 padL05" sm={4} >
                    <ToggleSwitch
                         data-test-auto="bb0809b6-d227-11ea-87d0-0242ac130003"
                         switched={this.state.generalSettings.showPopup}
                         handleChange={this.handleShowPopupToggleSwitchChange}
                    />
                </Col>
            </Row>
            <Row className="marB15">
                <Col className="text-left padT08" sm={6} >
                    <h5>Set time limit for duration of pop-up</h5>
                </Col>
                <Col className="padT08" sm={3} >
                    <Select
                        data-test-auto="bb080902-d227-11ea-87d0-0242ac130003"
                        name="maxPopupTime"
                        value={this.state.generalSettings.maxPopupTime}
                        onChange={this.handleMaxPopupTime}
                        options={this.buildSecondsOptions()}
                        clearable={false}
                    />
                </Col>
                <Col className="padT08">
                    <h5>secs</h5>
                </Col>
            </Row>
            <Row className="marB15">
                <Col className="text-left padT08" sm={6} >
                    <h5>Play sound when notification arrives</h5>
                </Col>
                <Col className="padT08 padL05" sm={4} >
                     <ToggleSwitch
                         data-test-auto="bb08084e-d227-11ea-87d0-0242ac130003"
                         switched={this.state.generalSettings.playSound}
                         handleChange={this.handlePlaySoundToggleSwitchChange}
                     />
                </Col>
                    </Row>
                </Card.Body>
            </Card>
    }

}
