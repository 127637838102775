import * as React from 'react';
import * as Validation from '../helper/Validations';
import { phoneNumberDisplay } from '../helper/HelperFunctions';

interface PhoneNumberComponentProps {
    phoneNumber: string;
    handleChangePhoneNumber(value: string): void;
    disabled: boolean;
    onBlur?: (value: any) => void;
    datatestAuto?: string;
    className?: string;
}
interface PhoneNumberComponentState {
    phoneNumberState: string;
}
export class PhoneNumberComponent extends React.Component<PhoneNumberComponentProps, PhoneNumberComponentState> {
    state = {
        phoneNumberState: ""
    }
    componentDidMount() {
        this.phoneNumberDisplayValue();
    }

    componentDidUpdate(prevProps: PhoneNumberComponentProps, prevState: PhoneNumberComponentState) {
        if (this.props.phoneNumber != prevProps.phoneNumber) {
            this.phoneNumberDisplayValue();
        }
    }

    onBlur = (value: any) => {
        if (this.props.onBlur) {
            this.props.onBlur(value)
        }
    }

    validatePhoneNumber = (value: any) => {
        if (value === null || value === undefined) {
            return false;
        }
        return true;
    }

    onChangePhoneNumber = (event: any) => {
        let value = event.target.value;
        let phoneNumber: string = "";
        if (value !== "" && value !== undefined) {
            const length = value.length;
            const phoneNumberState = this.state.phoneNumberState;
            if (Validation.ValidateTenDigitphoneNumber(value)) {
                if (length <= 5) {
                    if (length > 1) {
                        phoneNumber = value.replace("(", "");
                        phoneNumber = phoneNumber.replace(")", "");
                        if (this.validatePhoneNumber(phoneNumberState)) {
                            if ((phoneNumberState.includes(")") && !value.includes(")")) || phoneNumberState.includes("(") && !value.includes("("))
                                phoneNumber = value
                        }
                        else if (length > 3)
                            phoneNumber = "(" + phoneNumber + ")";
                        else
                            phoneNumber = value
                    }
                    else {
                        if (this.validatePhoneNumber(phoneNumberState) && length < phoneNumberState.length && length > 1)
                            phoneNumber = value
                        else {
                            phoneNumber = !value.includes("(") ? "(" : "";
                            phoneNumber = phoneNumber != "" ? (phoneNumber + value + ")") : "";
                        }
                    }
                    this.setState({ phoneNumberState: phoneNumber });
                }
                if (length >= 6 && length <= 12) {
                    if (length == 6 && !value.includes(" "))
                        phoneNumber = phoneNumberState + " " + value.substring(6, 5)
                    else
                        phoneNumber = value;
                    this.setState({ phoneNumberState: phoneNumber });
                }
                if (length >= 10) {
                    if (length == 10 && !value.includes("-")) {
                        if (phoneNumberState && phoneNumberState != "") {
                            phoneNumber = phoneNumberState + "-" + event.target.value.substring(10, 9);
                        }
                        else {
                            phoneNumber = phoneNumberDisplay(event.target.value);
                        }
                    }
                    else
                        phoneNumber = value;
                    this.setState({ phoneNumberState: phoneNumber });
                }
                phoneNumber = this.replaceBrackets(value);
                if (phoneNumber.length > 10) {
                    this.setState({ phoneNumberState: "" });
                }
                else
                    this.props.handleChangePhoneNumber(phoneNumber);
            }

        }
        else {
            phoneNumber = value;
            this.setState({ phoneNumberState: phoneNumber });
            this.props.handleChangePhoneNumber(phoneNumber);
        }

    }

    replaceBrackets(value: string): string {
        let res = value.replace("(", "");
        res = res.replace(")", "");
        res = res.replace(" ", "");
        res = res.replace("-", "");
        return res;
    }

    phoneNumberDisplayValue = () => {
        let phoneNumber = this.props.phoneNumber;
        let phoneNumberDisplayvalue: any = phoneNumberDisplay(phoneNumber);
        this.setState({ phoneNumberState: phoneNumberDisplayvalue });

    }
    render() {
        return (
            <input type="text"
                data-lpignore="true"
                data-test-auto={this.props.datatestAuto ? this.props.datatestAuto : "F22C7D00-409F-48E3-AD55-5341EF451C9B"}
                className={(this.props.className) ? "form-control padL08 "+ this.props.className : "form-control padL08"}
                placeholder="( ) -"
                value={this.state.phoneNumberState}
                onChange={this.onChangePhoneNumber}
                onBlur={this.onBlur}
                disabled={this.props.disabled}>
            </input>
        );
    }
}
export default PhoneNumberComponent;