import { connect } from 'react-redux';
import { ApplicationState } from '../../../store';
import * as TaxDocumentStore from '../../../store/common/TaxDocumentStore';
import * as UploadTaxReturnStore from '../../../store/common/uploadTaxReturn';
import * as CompanyStore from '../../../store/company/CompanyStore';
import * as FilterStore from '../../../store/reports/FilterStore';
import * as RecycleReturnsStore from '../../../store/reports/RecycleReturns/RecycleReturnsStore';
import * as UserSettingStore from '../../../store/userManagement/UserSettingStore';
import * as UserStore from '../../../store/userManagement/UserStore';
import * as Component from './RecycleReturns';
import * as UserSettingsStore from '../../../store/userManagement/UserSettingStore';
import * as BulkOperationsStore from '../../../store/common/BulkOperationsStore';
import * as GroupedReturnStore from '../../../store/groupedreturns/GroupedReturnStore';

export default connect(
    (state: ApplicationState) => ({
        recycleReturns: state.recycleReturns, // Selects which state properties are merged into the component's props
        userStore: state.usersList,
        userSettings: state.userSettings,
        uploadTaxReturnStore: state.UploadTaxReturnStore,
        company: state.companyData,
        groupedReturnStore: state.groupedReturnStore,
        userResources: state.userResources
    }),
    {
        ...RecycleReturnsStore.actionCreators,
        ...UserStore.actionCreators,
        ...UserSettingStore.actionCreators,
        ...UploadTaxReturnStore.actionCreators,
        ...CompanyStore.actionCreators,
        ...BulkOperationsStore.actionCreators,
        ...GroupedReturnStore.actionCreators,
    }
)(Component.RecycleReturns as any);

