import * as React from 'react';
import { Card, Row, Col, Button, FormLabel } from 'react-bootstrap';
import { IUserModel } from '../../../../Core/ViewModels/User/UserViewModel';
import * as TaxDocument from '../../../common/TaxReturn';

interface ISendToERO {
    taxreturn: TaxDocument.ITaxReturn;
    cancelFinish: () => void;
    sendToERO: () => void;
}

export class SendToERO extends React.Component<ISendToERO, {}> {
    public render() {
        return <Card>
            <Card.Body>
                <Row className="marB15">
                    <Col sm={12}>
                    </Col>
                </Row>
                <Row className="marB15">
                    <Col className="text-left" sm={12} as={FormLabel}>
                        ERO : {this.props.taxreturn.partner.firstName + " " + this.props.taxreturn.partner.lastName}
                    </Col>
                </Row>
                <div style={{ paddingTop: "70px" }}>
                    <Col sm={12}>
                        <Button type="button" variant="info" className="float-right" data-test-auto="7F975ABC-E4A0-48FD-906C-DE6BF280D764"
                            onClick={this.props.sendToERO}
                            disabled={this.props.taxreturn.partner.inactive}
                            title={this.props.taxreturn.partner.inactive ? "ERO is an inactive user and is unable to receive tax returns." : ""}
                        ><i className="fa fa-paper-plane"></i>Send</Button>
                        <Button type="button" variant="default" className="float-right marR05 btn-white btn-default" data-test-auto="E627BB57-5B04-482A-8EF1-8B8D0E7D8EA7"
                            onClick={this.props.cancelFinish}
                        ><i className="fas fa-times"></i>Cancel</Button>
                    </Col>
                </div>
            </Card.Body>
        </Card>
    }
}

export default SendToERO;
