import * as React from 'react';
import { IInboxMessage, MessageStatus, MessageType } from '../../Core/ViewModels/User/InboxMessage';
import { EmptyInbox } from './EmptyInbox';
import Moment from 'moment';
import { container } from '../../Startup/inversify.config';
import { TYPES } from "../../Startup/types";
import { IHtmlUtilities } from '../../Core/Utilities/HtmlUtilities';

export interface ITabFirmInboxProps {
    inboxMessages: IInboxMessage[];
    markAsRead: (messageId: number) => void;
    clearMessage: (event: any,messageId: number) => void;
    unsubscribeNotification: (messageType: MessageType) => void;
}

const htmlUtilities = container.get<IHtmlUtilities>(TYPES.IHtmlUtilities);

export class TabFirmInbox extends React.Component<ITabFirmInboxProps>{
    render() {
        return <div>
            {
                this.props.inboxMessages.length > 0 ?
                    <div>
                        <div className="firm-inbox-content">
                            {
                                this.props.inboxMessages.sort((a, b) => { return new Date(a.startDate).valueOf() > new Date(b.startDate).valueOf() ? -1 : 1 })
                                    .map((message, i) => {
                                    return <div className={"card firm-message" + (message.messageStatus == MessageStatus.Unread ? " unreadMessage" : "")}
                                        onClick={(event) => this.props.markAsRead(message.id)} >
                                        <div className="firm-message-body">
                                            <div className="firm-message-text" 
                                                dangerouslySetInnerHTML={{ __html: htmlUtilities.sanitize(message.message) }}>
                                            </div>
                                            <div className="firm-message-clear">
                                                <span className='fas fa-times float-right firm-message-clear-icon' title={"Clear"} />
                                            </div>
                                        </div>
                                        <div>
                                            <label className="firm-message-date">{Moment(message.startDate).format("lll").replace(",", "")}</label>
                                            <span className='fas fa-bell-slash float-right firm-message-unsubscribe-icon'
                                                title={"Unsubscribe"} />
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                        <div className="padT10">
                            <label className="text-dodgerblue">Clear all</label>
                            <label className="float-right text-dodgerblue">Show more (5)</label>
                        </div>
                    </div> :
                    <EmptyInbox />
            }
        </div>
    }
}
