import * as React from 'react';
import { Tabs, Button, Popover, Overlay } from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import * as TaxDocumentStore from '../../../store/common/TaxDocumentStore';
import * as UserSettingStore from '../../../store/userManagement/UserSettingStore';
import * as TaxDocument from '../../common/TaxReturn';
import * as User from '../../../Core/ViewModels/User/UserViewModel';
import { IUserProfile } from '../../navigation/profile/ProfileObjects';
import { IUserModel, IUserBaseModel } from '../../../Core/ViewModels/User/UserViewModel';
import { ICompanySettings } from '../../../Core/ViewModels/Company/CompanySettingsViewModel';
import * as PdfStore from '../../../store/pdf/PdfStore';
import * as CompanyStore from '../../../store/company/CompanyStore';
import * as Company from '../../../store/company/CompanyStore';
import * as VoucherStore from '../../../store/ProcessReturn/voucher/VoucherStore';
import * as UserSignatureStore from '../../../store/common/UserSignatureStore';
import * as Helper from '../../helper/HelperFunctions';
import { CustomWindow, CustomWindowFooter, CustomWindowBody } from '../../common/ProcessReturnModal/CustomWindow/CustomWindow';
import { CarouselImage } from '../../common/ProcessReturnModal/PageCarousel/PageCarousel';
import { DocumentGroups, IVoucher, ITaxingAuthority, ITaxReturn, TaxDocumentRevisionStatus } from '../../common/TaxReturn';
import { TabVoucher } from '../../common/ProcessReturnModal/Tabs/TabVoucher';
import { TabTransmittals } from '../../common/ProcessReturnModal/Tabs/TabTransmittals';
import * as Constants from '../../helper/Constants';
import { VenusNotifier } from '../../helper/VenusNotifier';
import { container } from '../../../Startup/inversify.config';
import { ILocalStore } from '../../../Core/Utilities/LocalStore';
import { TYPES } from '../../../Startup/types';
import {
	IBookmarkSection, RevisedTransmittalsview, VoucherInfoView, ClientInfoView
} from '../../common/ProcessReturnModal/ProcessReturnModels';
import { Guid } from 'guid-typescript';
import { VoucherSort } from '../../common/ProcessReturnModal/Voucher/VoucherSort';
import { ISubDocument } from '../../../Core/Utilities/PdfDocumentFacade';
import { IReportProblemDetails } from '../../common/ReportProblem/ReportProblemModel';
import { IReportPopupState } from '../../common/PopupState';
import { ILogger } from '../../../Logger/Logger';
import { LoggerFactory } from '../../../Logger/LoggerFactory';
import { validateRefudInfo, validateRetentionPeriod } from '../../helper/Validations';
import { IPdfDocumentFacade } from '../../../Core/Utilities/PdfDocumentFacade';
import { FinishPopover } from '../RevisedTaxReturnProcess/FinishPopover';
import { IGroupInfo } from '../../../store/groupedreturns/GroupedReturnProcessState';
import { IAuthState } from '../../../store/auth/reducer';


export interface IModalState {
	isBusy: boolean;
	message: string;
}

interface IModalProps {
	show: boolean;
	onCancel: (id: number) => void;
}

enum RevisedProcessReturnTabs {
	Transmittals = 1,
	Vouchers,
}


export interface IRevisedProcessReturnModalProps extends IModalProps {
    auth: IAuthState;
	order: number;
	docId: number;
	taxDocuments: TaxDocumentStore.ITaxDocumentDictionary;
	requestTaxDocument: (id: number, force: boolean) => void;
	updateTaxDocument: (taxDocument: TaxDocument.ITaxReturn, callback?: () => void) => void;
	deliverToClient: (taxDocument: TaxDocument.ITaxReturn, subDocuments: ISubDocument[], revisionDocuments: TaxDocument.ITaxDocumentRevision[],
		groupId?: number, callback?: (response: boolean) => void, resourceId?:string) => void;
	saveProcessReturnInfo: (taxReturn: TaxDocument.ITaxReturn, subDocuments: ISubDocument[], callback?: () => void, resourceId?: string) => void;
	updateDocumentInitialStatus: (updateDocumentInitialStatus: TaxDocument.ITaxReturn, resourceId?: string) => void;

	companySettings: ICompanySettings;
	company: CompanyStore.ICompanyData;

	authorities: TaxDocument.ITaxingAuthority[];
	authorityLookup: Company.IAuthorityDictionary;
	saveTaxingAuthority: (taxingAuthority: ITaxingAuthority, callback?: () => void, callback2?: () => void) => void;
	requestTaxingAuthorities: (value: boolean) => void;

	requestEroSignaturePath: (userId: number, callback?: (data?: any) => void) => void;

	userBasicProfile: IUserProfile;
	users: User.IUserModel[];

	pdfDocuments: PdfStore.IPdfDocumentDictionary;
	loadPdf: (taxReturn: TaxDocument.ITaxReturn, reload: boolean) => void;

	getAllTaxingAuthorities: ITaxingAuthority[];
	userSettings: UserSettingStore.UserSettings;
	requestDelegatedSigners: (userId: number) => void;
	userSignatures: UserSignatureStore.IUserSignatures;

	onReportProblemSave: (id: number, problemDetails: IReportProblemDetails) => void;
	onReportProblemOpen: (id: number) => void;
	onReportProblemCancel: (id: number) => void;
	reportProblemState: IReportPopupState;

	voucherStore: VoucherStore.IVoucherState;
	requestVoucherDueDates: (taxReturn: TaxDocument.ITaxReturn, authorityId?: number,
		voucherNo?: TaxDocument.VoucherNo) => void;
	requestPaymentUrls: (taxReturn: TaxDocument.ITaxReturn) => void;
	requestVoucherFormNames: (taxReturn: TaxDocument.ITaxReturn) => void;
	getVoucherUploadLink: (taxReturn: TaxDocument.ITaxReturn,
		callback: (data: any) => void) => void;
	getMultiVoucherUploadLink: (taxReturn: ITaxReturn, cbVoucher: IVoucher, callback: (data: any, voucher: IVoucher) => void) => void;

	groupInfo?: IGroupInfo;
	updateRevisedTaxDocumentStatus: (id: number, status: TaxDocumentRevisionStatus, groupId: number) => void;
    revisionDocuments: TaxDocument.ITaxDocumentRevision[];
    uploadEstimateResourceId?: string;
}

interface IRevisedProcessReturnModalState extends IModalState {
	key: any;
	signers: User.IUserModel[];
	viewIndex: number | undefined;
	initialMFJValue: boolean;
	showCarousel: boolean;
	pdfPages: CarouselImage[];
	pdfCarouselActivePage: number;
	renderTrigger: string;
	pages: number[];
	matchedForms: TaxDocument.IFormBase[];
	destinationGroup: TaxDocument.DocumentGroups;
	authoritiesOptions: TaxDocument.SelectOptions[];
	renderCache: {
		voucher: {
			bookmarks: IBookmarkSection[],
			vouchers: IVoucher[]
		}
	},
	showClosePopover: boolean;
	disabledTaxpayerPreview: boolean;
	processingTaxReturn: TaxDocument.ITaxReturn | undefined;
	refreshTaxReturn: boolean;
	disableSaveClose: boolean;
	disableFinish: boolean;
	isDoNotDisplayDatesEnabled: boolean;
	clonedTaxDocument: TaxDocument.ITaxReturn | undefined;
	logger: ILogger;
}

const localStorage = container.get<ILocalStore>(TYPES.ILocalStore);
const viewMax = RevisedProcessReturnTabs.Vouchers;
const viewMin = RevisedProcessReturnTabs.Transmittals;

export class RevisedProcessReturnModal extends React.Component<IRevisedProcessReturnModalProps, IRevisedProcessReturnModalState>{
	private closePopoverRef: any;
	private deactivatedAuthority: number[] = [];

	constructor(props: IRevisedProcessReturnModalProps) {
		super(props);
		this.state = {
			key: undefined,
			signers: [],
			//IModalState Properties
			isBusy: false,
			message: "",
			viewIndex: RevisedProcessReturnTabs.Transmittals,
			initialMFJValue: false,
			showCarousel: false,
			pdfPages: [],
			pdfCarouselActivePage: 0,
			renderTrigger: Guid.create().toString(),
			pages: [],
			destinationGroup: DocumentGroups.None,
			matchedForms: [],
			authoritiesOptions: [],
			renderCache: {
				voucher: {
					bookmarks: [VoucherInfoView.getInitialVoucherBookmark()],
					vouchers: []
				}
			},
			showClosePopover: false,
			disabledTaxpayerPreview: false,
			processingTaxReturn: undefined,
			refreshTaxReturn: false,
			disableSaveClose: false,
			disableFinish: false,
			isDoNotDisplayDatesEnabled: true,
			clonedTaxDocument: undefined,
			logger: new LoggerFactory().getTelemetryLogger()
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps: IRevisedProcessReturnModalProps) {
		if (nextProps.show) {
			if (nextProps.taxDocuments && nextProps.taxDocuments[nextProps.docId]?.taxReturn
				&& nextProps.taxDocuments[this.props.docId]?.isFullyLoaded) {
				if (nextProps.taxDocuments[this.props.docId]?.taxReturn.lockedBy === 0) {
					this.updateDocumentLock(nextProps.taxDocuments[this.props.docId]?.taxReturn);
				}
			}
		}
	}

	updateDocumentLock = (taxDocument: TaxDocument.ITaxReturn) => {
		taxDocument.assignTo = this.props.userBasicProfile?.userId == undefined ? 0 : this.props.userBasicProfile?.userId;
		taxDocument.lockedBy = this.props.userBasicProfile?.userId == undefined ? 0 : this.props.userBasicProfile?.userId;
		taxDocument.assignedUser.userId = this.props.userBasicProfile?.userId == undefined ? 0 : this.props.userBasicProfile?.userId;
		taxDocument.assignedUser.firstName = this.props.userBasicProfile?.userId == undefined ? '' : this.props.userBasicProfile.firstName;
		taxDocument.assignedUser.lastName = this.props.userBasicProfile?.userId == undefined ? '' : this.props.userBasicProfile.lastName;
		taxDocument.assignedUser.email = this.props.userBasicProfile?.userId == undefined ? '' : this.props.userBasicProfile.email;
		taxDocument.isModified = true;
		this.props.updateDocumentInitialStatus(taxDocument, this.props.uploadEstimateResourceId);
	}


	onPrevious = (index: number | undefined) => {
		let currentViewIndex: number = index == undefined ? parseInt(localStorage.get(this.props.docId.toString())) : index;
		let activeIndex: number = index == undefined ? parseInt(localStorage.get(this.props.docId.toString())) : index;
		activeIndex = currentViewIndex - 1;

		this.handleTabSelect(activeIndex);
	}

	onNext = (index: number | undefined) => {
		let currentViewIndex: number = index == undefined ? parseInt(localStorage.get(this.props.docId.toString())) : index;
		let activeIndex: number = index == undefined ? parseInt(localStorage.get(this.props.docId.toString())) : index;
		activeIndex = currentViewIndex + 1;

		this.handleTabSelect(activeIndex);
	}

	handleTabSelect = (index: any) => {
		this.setState({ viewIndex: parseInt(index.toString()) }, () => {
			localStorage.set(this.props.docId.toString(), index);
		});
	}

	isAssignedToLoggedinUser = (): boolean => {
		return true;
	}

	onModalClose = (taxReturnID: number) => {
		this.resetTaxReturnLockStatus(this.props.taxDocuments[taxReturnID]?.taxReturn);
		this.props.onCancel(taxReturnID);
	}

	onClose = (taxReturnID: number, fromCallback: boolean = false) => {
		if (!fromCallback) {
			this.resetTaxReturnLockStatus(this.props.taxDocuments[taxReturnID]?.taxReturn);
		}
		this.props.onCancel(taxReturnID);
	}

	resetTaxReturnLockStatus = (taxDocument: TaxDocument.ITaxReturn) => {
		taxDocument.lockedBy = 0;
		taxDocument.isModified = true;
		this.props.updateDocumentInitialStatus(taxDocument, this.props.uploadEstimateResourceId);
	}

	reGroupPreview = (pages: number[], destinationGroup: TaxDocument.DocumentGroups) => {

		let taxDocument = this.props.taxDocuments[this.props.docId]?.taxReturn;
		let matchedForms: TaxDocument.IFormBase[] = [];
		let isValid: boolean = true;

		if (isValid) {
			taxDocument.formGroups.map((formGroups) => {
				if (formGroups.group != destinationGroup) {
					formGroups.forms.map((forms) => {
						if (forms.pageNo.some(x => pages.some(y => y == x)))
							matchedForms.push(forms);
					});
				} else {
					formGroups.forms.map((forms) => {
						pages = pages.filter(function (page) {
							return forms.pageNo.indexOf(page) < 0;
						});
					});
				}
			});

			if (matchedForms.length > 0) {
				if (matchedForms[0].formType == TaxDocument.TaxFormType.Voucher) {
					let voucher = matchedForms[0] as IVoucher;
					if (voucher.isPaid) {
						VenusNotifier.Warning(Constants.ReGroupConstants.PaidVoucherDeleteWarning, null);
						return;
					}
				}
			}


			if (pages.length > 0) {
				switch (destinationGroup) {
					case TaxDocument.DocumentGroups.Deleted:
						this.reGroup(pages, matchedForms, destinationGroup);
						VenusNotifier.Success(Constants.ReGroupConstants.RevisionEstimatePageDeleteSuccess, null);
						break;
				}
			} else {
				VenusNotifier.Warning(Constants.ReGroupConstants.SameSourceAndDestinationError, null);
			}
		}
	}

	reGroup = (pages: number[], forms: TaxDocument.IFormBase[], destinationGroup: TaxDocument.DocumentGroups) => {
		this.updateTaxFormType(forms, destinationGroup);

		let clone = Helper.cloneTaxReturn(this.props.taxDocuments[this.props.docId]?.taxReturn);
		Helper.removeForms(clone, pages);
		let formGroup = clone.formGroups.find(x => x.group == destinationGroup);
		if (formGroup) {
			if (formGroup.forms) {
				formGroup.forms = formGroup.forms.concat(forms);
			}
			else {
				formGroup.forms = forms;
			}
		}
		else {
			clone.formGroups.push({ group: destinationGroup, forms: forms });
		}
		this.props.updateTaxDocument(clone);
	}

	private deletePages = (pages: number[]) => {
		let clone = Helper.cloneTaxReturn(this.props.taxDocuments[this.props.docId]?.taxReturn);
		let deleted = Helper.removeForms(clone, pages);

		let deletedFormGroupIndex = new ClientInfoView(clone).getFormIndex(TaxDocument.DocumentGroups.Deleted);


		if (clone.formGroups[deletedFormGroupIndex]) {
			clone.formGroups[deletedFormGroupIndex].forms = clone.formGroups[deletedFormGroupIndex].forms.concat(deleted);
		} else {
			clone.formGroups.push({ group: TaxDocument.DocumentGroups.Deleted, forms: deleted });
		}

		this.props.updateTaxDocument(clone);
	}

	updateTaxFormType = (forms: TaxDocument.IFormBase[], destinationGroup: TaxDocument.DocumentGroups) => {
		forms.map((form, index) => {
			form.formType = TaxDocument.TaxFormType.FormBase;
		});
	}

	addSignatureControlTransmittalForm = (control: TaxDocument.ISignatureControl, pageNo: number): void => {
		let taxReturn = Helper.cloneTaxReturn(this.props.taxDocuments[this.props.docId]?.taxReturn);
		const transmittalInfo = new RevisedTransmittalsview(taxReturn);
		transmittalInfo.addSignatureControl(control, pageNo);
		this.props.updateTaxDocument(taxReturn);
	}

	removeSignatureControlTransmittalForm = (control: TaxDocument.ISignatureControl, pageNo: number): void => {
		let taxReturn = Helper.cloneTaxReturn(this.props.taxDocuments[this.props.docId]?.taxReturn);
		const transmittalInfo = new RevisedTransmittalsview(taxReturn);
		transmittalInfo.removeSignatureControl(control, pageNo);
		this.props.updateTaxDocument(taxReturn);
	}

	updateEROSignatureStamp = (userId: number): void => {
		const taxDocument = this.props.taxDocuments[this.props.docId]?.taxReturn;
		if (taxDocument && taxDocument.documentSettings) {

			let taxReturn = Helper.cloneTaxReturn(taxDocument);

			if (taxReturn.documentSettings.documentSignatureSetting.signatureStampUser) {
				taxReturn.documentSettings.documentSignatureSetting.signatureStampUser.userId = userId;
			}
			else {
				taxReturn.documentSettings.documentSignatureSetting.signatureStampUser = {
					userId: userId
				} as IUserModel;
			}
			taxReturn.documentSettings.isModified = true;

			this.props.updateTaxDocument(taxReturn);
		}
	}

	replaceSignatureControlTransmittalForm = (oldControl: TaxDocument.ISignatureControl, newControl: TaxDocument.ISignatureControl, pageNo: number) => {
		let taxReturn = Helper.cloneTaxReturn(this.props.taxDocuments[this.props.docId]?.taxReturn);
		const transmittalInfo = new RevisedTransmittalsview(taxReturn);
		transmittalInfo.replaceSignatureControl(oldControl, newControl, pageNo);
		this.props.updateTaxDocument(taxReturn);
	}

	onSaveTaxReturn = () => {
		this.setState({
			refreshTaxReturn: true
		});
		let taxReturn: TaxDocument.ITaxReturn = Object.assign({}, this.props.taxDocuments[this.props.docId]?.taxReturn);
		if (validateRefudInfo(taxReturn, this.props.authorities) && validateRetentionPeriod(taxReturn)) {
			taxReturn.lockedBy = 0;
			taxReturn.isModified = true;
			if (!this.state.isBusy) {
				this.setState({ isBusy: true },
					() => {
						this.props.saveProcessReturnInfo(taxReturn, this.getSubDocuments(),
							() => { this.callback(taxReturn.id, TaxDocumentRevisionStatus.Merged, true) }, this.props.uploadEstimateResourceId);
					}
				)
			}
		}
	}

	getSubDocuments = () => {
		let documentFacade: IPdfDocumentFacade = (this.props.pdfDocuments[this.props.docId].document as IPdfDocumentFacade);
		let subDocuments: ISubDocument[] = [];
		if (typeof (documentFacade) !== 'undefined') {
			subDocuments = documentFacade.getSubDocuments();
		}
		return subDocuments;
	}

	callback = (id: number, status: TaxDocumentRevisionStatus, response: boolean) => {

		this.onClose(this.props.taxDocuments[this.props.docId]?.taxReturn.id, true);
		if (response) {
			this.props.updateRevisedTaxDocumentStatus(id, status, this.props.groupInfo == undefined ? 0 : this.props.groupInfo.id);

			//Update store on any save operation in process return window 
			this.props.loadPdf(this.props.taxDocuments[this.props.docId]?.taxReturn, true);
		}
	}

	updateTaxDocument = (model: TaxDocument.ITaxReturn) => {
		this.props.updateTaxDocument(model);
	}

	deliverToClient = () => {
		let model = this.props.taxDocuments[this.props.docId]?.taxReturn;
		if (this.deactivatedAuthority.length !== 0) {
			VenusNotifier.Error(Constants.FinishProcessReturn.StatusMessage.SelectDeactivatedDeletedAuthority, "Error");
			return;
		}

		if (!this.state.isBusy) {
			model.lockedBy = 0;
			this.setState({ isBusy: true },
				() => {
					document.body.click();
					this.props.deliverToClient(model, this.getSubDocuments(), this.props.revisionDocuments, this.props.groupInfo?.id, (response: boolean) => {
						this.callback(model.id, TaxDocumentRevisionStatus.DeliveryInProgress, response)
                    }, this.props.uploadEstimateResourceId);
				})
		}
	}

	onFinishProcessing = () => {
		this.setState({
			refreshTaxReturn: true
		});
		this.setState((prevState) => { return { showClosePopover: !prevState.showClosePopover }; });
	}

	public render() {
		let defaultActiveTab = this.state.viewIndex || 1;

		const { authorities, pdfDocuments, order, docId, taxDocuments } = this.props;
		const taxDocument = taxDocuments[docId]?.taxReturn;
		const isBusy = this.state.isBusy;

		const transmittals = new RevisedTransmittalsview(taxDocument);
		const transmittalsbookmarks = transmittals.getBookmarks();
		const transmittalspages = transmittals.getPages(transmittalsbookmarks);
		const transmittalsSignatureControls = transmittals.getSignatureControls();

		let voucherInfoView = new VoucherInfoView(taxDocument);
		let renderCache = this.state.renderCache;
		let voucherSort = new VoucherSort(taxDocument, authorities);
		if (!voucherSort.isVouchersEqual(renderCache.voucher.vouchers)) {
			renderCache.voucher.bookmarks = voucherSort.getSortedBookmarks(true);
			renderCache.voucher.vouchers = voucherSort.getVouchers();
			this.setState({ renderCache: renderCache });
		}
		const voucherPages = voucherInfoView.getPages(renderCache.voucher.bookmarks);

		const voucherAuthorityId: number[] = voucherInfoView.getVoucherAuthorities(renderCache.voucher.vouchers);
		const taxingAuthorities = voucherInfoView.getAllAuthorities(this.props.getAllTaxingAuthorities);

		this.deactivatedAuthority = voucherAuthorityId.filter((item) => {
			return taxingAuthorities.indexOf(item) === -1;
		});

		const finishPopover = (<Popover id="revised-processModal-finish-popover" className="finishPopover"
			style={{ width: "750px", height: "530px", top: "219px" }}>
			<Popover.Title as="h3">Finish Return Processing</Popover.Title>
			<Popover.Content>
				<FinishPopover
					taxreturn={this.props.taxDocuments[docId]?.taxReturn}
					deliverToClient={this.deliverToClient}
					groupInfo={this.props.groupInfo}
				/>
			</Popover.Content>
		</Popover>);

		return (<CustomWindow key={"custome-window" + taxDocument?.id}
			onClose={() => { this.onModalClose(taxDocument?.id) }}
			title={Helper.taxReturnTitle(taxDocument)}
			order={order}
			disableMaximize={true}
			disableMinimize={true}
			disableFullscreen={false}
			alwaysFitToParent={true}
			taxReturn={taxDocument}
			onReportProblemSave={this.props.onReportProblemSave}
			onReportProblemOpen={this.props.onReportProblemOpen}
			onReportProblemCancel={this.props.onReportProblemCancel}
			reportProblemState={this.props.reportProblemState}
			onResize={() => { this.setState({ renderTrigger: Guid.create().toString() }) }}>
			{
				(<CustomWindowBody isBusy={isBusy}>
					<Tabs
						activeKey={this.state.viewIndex ? this.state.viewIndex : defaultActiveTab}
						onSelect={this.handleTabSelect}
						id="process-return-groups"
						fill>
						<Tab eventKey={RevisedProcessReturnTabs.Transmittals}
							title={<div className="overflowText">Transmittals</div>}
							className="zIndex10">
							{(this.state.viewIndex == RevisedProcessReturnTabs.Transmittals) &&
                                <TabTransmittals
                                    auth={this.props.auth}
									bookmarks={transmittalsbookmarks}
									pages={transmittalspages}
									docId={docId}
									pdfDocuments={pdfDocuments}
									taxReturn={taxDocuments[docId]?.taxReturn}
									formGroup={taxDocument.formGroups.filter(x => x.group === TaxDocument.DocumentGroups.Transmittals)[0]}
									reGroupPreview={this.reGroupPreview}
									deletePages={this.deletePages}
									renderTrigger={this.state.renderTrigger}
									getAllTaxingAuthorities={this.props.getAllTaxingAuthorities}
									isAssignedToLoggedinUser={this.isAssignedToLoggedinUser()}
									isEnableInvoice={this.props.companySettings.displaySettingsModel.isEnableInvoice}
									getVoucherUploadLink={this.props.getVoucherUploadLink}
									voucherStore={this.props.voucherStore}
									updateTaxDocument={this.props.updateTaxDocument}
									getMultiVoucherUploadLink={this.props.getMultiVoucherUploadLink}
									saveTaxingAuthority={this.props.saveTaxingAuthority}
									userSettings={this.props.userSettings}
									signatureControls={transmittalsSignatureControls}
									removeSignatureControl={this.removeSignatureControlTransmittalForm}
									addSignatureControl={this.addSignatureControlTransmittalForm}
									updateEROSignatureStamp={this.updateEROSignatureStamp}
									replaceSignatureControl={this.replaceSignatureControlTransmittalForm}
									company={this.props.company}
									userProfile={this.props.userBasicProfile}
									userSignatures={this.props.userSignatures}
									isRevisionProcess={true}
									showVoucherLabel={true}
								/>}
						</Tab>

						<Tab eventKey={RevisedProcessReturnTabs.Vouchers}
							title={<div className="overflowText">Vouchers</div>}
							className="zIndex10">
							{(this.state.viewIndex == RevisedProcessReturnTabs.Vouchers) && <TabVoucher
								bookmarks={this.state.renderCache.voucher.bookmarks}
								pages={voucherPages}
								docId={docId}
								pdfDocuments={this.props.pdfDocuments}
								taxReturn={taxDocuments[docId]?.taxReturn}
								updateTaxDocument={this.props.updateTaxDocument}
								isAssignedToLoggedinUser={this.isAssignedToLoggedinUser()}
								reGroupPreview={this.reGroupPreview}
								deletePages={this.deletePages}
								renderTrigger={this.state.renderTrigger}
								getVoucherUploadLink={this.props.getVoucherUploadLink}
								getMultiVoucherUploadLink={this.props.getMultiVoucherUploadLink}
								voucherStore={this.props.voucherStore}
								getAllTaxingAuthorities={this.props.getAllTaxingAuthorities}
								isEnableInvoice={this.props.companySettings.displaySettingsModel.isEnableInvoice}
								saveTaxingAuthority={this.props.saveTaxingAuthority}
								formGroup={taxDocument.formGroups.filter(x => x.group === TaxDocument.DocumentGroups.Vouchers)[0]}
								showVoucherLabel={true}
								isRevisionProcess={true}
							/>}
						</Tab>
					</Tabs>
				</CustomWindowBody>)
			}

			<CustomWindowFooter>
				<Button
					variant="default"
					data-test-auto="27A54F41-0CFF-4F81-B684-2052B7424A9B"
					className="btn-white"
					disabled={(this.state.viewIndex ? this.state.viewIndex : defaultActiveTab) === viewMin || isBusy}
					onClick={() => { this.onPrevious(this.state.viewIndex ? this.state.viewIndex : defaultActiveTab) }}>
					<i className='fa fa-chevron-left'></i>Previous
                    </Button>
				<Button
					variant="default"
					data-test-auto="B1C60A60-C306-4A4D-B914-AC034DCB71E6"
					className="btn-white"
					disabled={(this.state.viewIndex ? this.state.viewIndex : defaultActiveTab) === viewMax || isBusy}
					onClick={() => { this.onNext(this.state.viewIndex ? this.state.viewIndex : defaultActiveTab) }}>
					<i className='fa fa-chevron-right'></i>Next
                </Button>
				<Button
					disabled={isBusy || this.state.disableSaveClose}
					data-test-auto="BC827AB3-8EBD-4A3B-A7F9-72D632DCFB0D"
					variant="info" onClick={this.onSaveTaxReturn} >
					<i className='fas fa-save'></i>Save & Close
                    </Button>
				<Button
					disabled={isBusy || this.state.disableFinish}
					data-test-auto="B88BCB03-B5BB-4EA6-AE43-D80336A1A927"
					variant="success" onClick={this.onFinishProcessing}
					ref={(reference: any) => { this.closePopoverRef = reference }}
				>
					<i className='fa fa-check'></i>Finish
                </Button>

				<Overlay rootClose={true} placement="top"
					onHide={() => { this.setState({ showClosePopover: false }) }}
					target={this.closePopoverRef} show={this.state.showClosePopover}>
					{finishPopover}
				</Overlay>

			</CustomWindowFooter>
		</CustomWindow >)

	}
}
