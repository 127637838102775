import { addTask } from 'domain-task';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../';
import { actionTypes } from "../../types/ActionTypes";
import { ITaxReturn, initialTaxReturnState, updateTaxFormType, ClientTypes, DocumentStatus, PaperReturnDeliveryType, DocumentGroups, IAccessCodeViewModel, DeliveryMode } from "../../components/common/TaxReturn";
import {
	ReceiveSignedDetailsAction, MakeAvailableTaxDocumentAction,
	GetTaxCaddyLookupDetails, K1Instruction, ClearTaxCaddyLookupDetails,
	ResetTaxDocument, IProcessGroupedTaxDocumentViewModel,
	getProcessReturnSucessStatusMessage, getProcessReturnFailureStatusMessage,
	ReceiveDownloadableDocumentsAction, ReceiveDownloadableEfileFormsAction,
	ReceiveDownloadHistoryAction,
	ISelectedDocumentForDeliveryViewModel
} from '../common/TaxDocumentStore';
import { handleResponse } from '../Library';
import * as DeliveredReturnsStore from '../../store/reports/DeliveredReturnsStore';
import { IGroupedTaxDocumentDictionary } from './GroupedTaxDocumentState';
import { ReceiveSendGroupedReturnsAction } from '../reports/GroupedReturns/SendGroupedReturnsStore';
import { StatusType, NotificationAction } from '../common/NotificationStore';
import { GroupedTaxDocumentConstants } from '../../components/helper/Constants';
import { ISubDocument, SubDocType } from '../../Core/Utilities/PdfDocumentFacade';
import { processReturnActionEndPoints,IShareHolder } from '../../components/common/ProcessReturnModal/ProcessReturnModels';
import { IGroupInfo, IGroupedReturnsModel, IPrintForPaperDeliveryViewModel } from '../../store/groupedreturns/GroupedReturnProcessState';
import * as Constants from '../../components/helper/Constants'
import * as Notification from '../common/NotificationStore';
import { ReceiveDeliveredGroupedReturnsAction, RequestGroupedReturnDocumentLevelTaxreturns, ResetDeliveredGroupedReturnsAction } from '../reports/GroupedReturns/DeliveredGroupedReturns/DeliveredGroupedReturnsStore';
import { ResetSendGroupedReturnsAction } from '../reports/GroupedReturns/SendGroupedReturnsStore';
import { IUserBaseModel } from '../../Core/ViewModels/User/UserViewModel';
import { API_BASE_URL } from '../../utils/constants';
import { logger } from '../../routes/LoggedIn';
import { encodeTaxDocumentHtmlTags, decodeTaxDocumentHtmlTags, encodeDocumentSettingsHtmlTags,encodeGroupReturnDataHtmlTags, encodeGroupInfoDataHtmlTags } from '../../../src/components/helper/HelperFunctions';
import { IDeliveredTaxDocument } from '../../components/common/DeliveredTaxReturns';

export interface RequestGroupedTaxDocumentAction {
	type: actionTypes.REQUEST_GROUPED_TAX_DOCUMENT;
	id: number;
}

export interface ReceiveGroupedDocumentStatusAction {
	type: actionTypes.RECEIVE_GROUPED_TAX_DOCUMENT_STATUS,
	id: number;
	documentStatus: DocumentStatus;
}

export interface ReceiveGroupedTaxDocumentAction {
	type: actionTypes.RECEIVE_GROUPED_TAX_DOCUMENT;
	id: number;
	taxDocument: ITaxReturn;
	isEditClientInfoRequest?: boolean;
}

export interface ErrorGroupedTaxDocumentAction {
	type: actionTypes.ERROR_GROUPED_TAX_DOCUMENT;
	ids: number[],
	message: string
}

export interface MarkAsReadyForDeliveryAction {
	type: actionTypes.MARK_AS_READY_FOR_DELIVERY,
	id: number
}

export interface UpdateGroupedTaxDocumentStatusAction {
	type: actionTypes.UPDATE_GROUPED_TAX_DOCUMENT_STATUS,
	ids: number[],
	status: DocumentStatus
}

export interface UpdateSendGroupedReturnsDocumentStatusUpdate {
	type: actionTypes.UPDATE_SEND_GROUPED_DOCUMENT_STATUS,
	ids: number[],
	status: DocumentStatus
}
export interface UpdateGroupedTaxDocumentAssignAction {
	type: actionTypes.UPDATE_GROUPED_TAX_DOCUMENT_ASSIGN,
	ids: number[],
	assignTo: number,
	assignedUser: IUserBaseModel,
	status: DocumentStatus
}

export interface UpdateSendGroupedReturnsDocumentAssignUpdate {
	type: actionTypes.UPDATE_SEND_GROUPED_DOCUMENT_ASSIGN,
	ids: number[],
	assignTo: number,
	assignedUser: IUserBaseModel,
	status: DocumentStatus
}

export interface ResetGroupedTaxDocumentStateAction {
    type: actionTypes.RESET_GROUPED_TAX_DOCUMENT,
    id: number
}

type KnownAction =
	RequestGroupedTaxDocumentAction |
	ReceiveGroupedTaxDocumentAction |
	ErrorGroupedTaxDocumentAction |
	MarkAsReadyForDeliveryAction |
	UpdateGroupedTaxDocumentStatusAction |
	UpdateSendGroupedReturnsDocumentStatusUpdate |
	ResetDeliveredGroupedReturnsAction |
	ResetSendGroupedReturnsAction |
	UpdateGroupedTaxDocumentAssignAction |
	UpdateSendGroupedReturnsDocumentAssignUpdate |
    NotificationAction |
    ResetGroupedTaxDocumentStateAction |
	RequestGroupedReturnDocumentLevelTaxreturns;

type DispatchActions =
	RequestGroupedTaxDocumentAction |
	ReceiveGroupedTaxDocumentAction |
	ReceiveSendGroupedReturnsAction |
	ReceiveDeliveredGroupedReturnsAction |
	ErrorGroupedTaxDocumentAction |
	ReceiveGroupedDocumentStatusAction |
	ReceiveSignedDetailsAction |
	MakeAvailableTaxDocumentAction |
	K1Instruction |
	GetTaxCaddyLookupDetails |
	ClearTaxCaddyLookupDetails |
	MarkAsReadyForDeliveryAction |
	ReceiveDownloadableDocumentsAction |
	ReceiveDownloadableEfileFormsAction |
	UpdateGroupedTaxDocumentStatusAction |
	UpdateGroupedTaxDocumentAssignAction |
    ReceiveDownloadHistoryAction |
    ResetGroupedTaxDocumentStateAction |
	RequestGroupedReturnDocumentLevelTaxreturns;

export const actionCreators = {
	requestGroupedTaxDocument: (id: number, force: boolean = false, requestTaxPayerView?: boolean, 
		clientType?: ClientTypes, callback?: (data: any) => void, isEditClientInfoRequest?: boolean,
		resourceId:string=""): AppThunkAction<KnownAction> => (dispatch, getState) => {
		let state = getState();
		if (isEditClientInfoRequest === undefined) {
			isEditClientInfoRequest = false;
		}
		if (force ||
			!state.taxDocuments[id] ||
			!state.taxDocuments[id].taxReturn ||
			state.taxDocuments[id].taxReturn.id === 0) {
			const fetchTask = fetch(API_BASE_URL + 'api/GroupedTaxDocument/' + id + '/' + isEditClientInfoRequest, {
				method: 'GET',
				credentials: 'include'
			})
				.then(handleResponse)
				.then(response => response as Promise<ITaxReturn>)
				.then(data => {
					//when ungrouped tax document is requested, data is empty object.
					if (data.id) {
						updateTaxFormType(data);
						dispatch({ type: actionTypes.RECEIVE_GROUPED_TAX_DOCUMENT, id: id, taxDocument: data, isEditClientInfoRequest: isEditClientInfoRequest });
						if (requestTaxPayerView) {
							let action: any = DeliveredReturnsStore.actionCreators.generateTaxpayerView(data, 
								clientType,resourceId);
							dispatch(action);
						}
						if (callback) {
							callback(data);
						}
					}
				})
				.catch(error => {
					dispatch({ type: actionTypes.ERROR_GROUPED_TAX_DOCUMENT, ids: [id], message: error });
				});
			addTask(fetchTask);
		}
	},
	requestGroupedReturnDocumentsByGroupId: (groupId: number, callback?: (taxretuns:ITaxReturn[]) => void ): AppThunkAction<KnownAction> => (dispatch, getState) => {
		const fetchTask = fetch(API_BASE_URL + 'api/SendGroupedReturns/GetGroupedReturnsDocumentsQueryAsync?groupId=' + groupId, {
			method: 'GET',
			credentials: 'include'
		})
			.then(handleResponse)
			.then(response => response as Promise<ITaxReturn[]>)
			.then(data => {
				if (callback) {
					callback(data);
				}
			})
			.catch((error) => {
				const statusMessage: any = error.statusText?.message ?? error.statusText;
                if (typeof(statusMessage) === "string") {
					dispatch({ type: actionTypes.NOTIFICATION, statusMessage: statusMessage, statusType: StatusType.Error })
				}
			});
		addTask(fetchTask);
	},
	markAsReadyForDelivery: (id: number, successCallback?: (id: number) => void,resourceId?: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
		const fetchTask = fetch(API_BASE_URL + 'api/GroupedTaxDocument/MarkReturnAsReadyForDeliveryAsync/' + id, {
			method: 'GET',
			credentials: 'include',
			headers: {
				'X-Resource-Id': !resourceId? "": resourceId,
			}
		}).then(handleResponse)
			.then(() => {

				dispatch({ type: actionTypes.MARK_AS_READY_FOR_DELIVERY, id: id });
				dispatch({ type: actionTypes.NOTIFICATION, statusMessage: GroupedTaxDocumentConstants.StatusMessage.MarkAsReadyForDeliverySuccess, statusType: StatusType.Success });
				if (successCallback) {
					successCallback(id);
				}
			})
			.catch(error => {
				dispatch({ type: actionTypes.NOTIFICATION, statusMessage: GroupedTaxDocumentConstants.StatusMessage.MarkAsReadyForDeliveryError, statusType: StatusType.Error });
			});
		addTask(fetchTask);
	},
	updateGroupedTaxDocument: (taxReturn: ITaxReturn, callback?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
		dispatch({ type: actionTypes.RECEIVE_GROUPED_TAX_DOCUMENT, id: taxReturn.id, taxDocument: taxReturn });
		if (callback) {
			callback();
		}
	},
	saveProcessGroupedTaxDocumentInfo: (taxReturn: ITaxReturn, subDocuments: ISubDocument[], taxDocumentsForDelivery: ISelectedDocumentForDeliveryViewModel[], isK1Replaced: boolean,
		isK1Restored: boolean, isMFJChanged: boolean, apiEndPoint?: string, callback?: (data?: any) => void,
        clientType?: ClientTypes, groupInfo?: IGroupInfo, resourceId: string = "",
        isShareHolderUpdated?: boolean, updatedShareHolders?: IShareHolder[], isReturnMarkedReadyForDelivery?: boolean, isMarkAsReadyForDeliveryChanged?: boolean ): AppThunkAction<KnownAction> => (dispatch, getState) => {
			let actualPath: string = subDocuments.filter(doc => { return doc.subDocType === SubDocType.Original })[0].path;
			subDocuments.map((doc, index) => {
				subDocuments[index].pageMap = doc.pageMap.filter(map => { return map.facadePageNum != -1 });
				subDocuments[index].path = subDocuments[index].path ? subDocuments[index].path :
					actualPath.split("/")[0] + "/" + "processReturn/" + taxReturn.documentGuid;
				(subDocuments[index].document as any) = undefined; //Circular dependency error by stringify if not done.
			})

			encodeTaxDocumentHtmlTags(taxReturn);

			taxDocumentsForDelivery.map((taxDocument)=>{
				encodeDocumentSettingsHtmlTags(taxDocument.documentSettings);
			});			

			if(groupInfo)
			{
				encodeGroupInfoDataHtmlTags(groupInfo);
			}

			let modelData: IProcessGroupedTaxDocumentViewModel = {
				taxDocument: taxReturn,
				parts: subDocuments,
				isK1Replaced: isK1Replaced,
				isK1Restored: isK1Restored,
				isMFJChanged: isMFJChanged,
				clientType: clientType,
				groupInfo: groupInfo,
                taxDocumentsForDelivery: taxDocumentsForDelivery,
                isK1ShareholderUpdated: isShareHolderUpdated,
                updatedShareHolders: updatedShareHolders,
				isReturnMarkedReadyForDelivery: isReturnMarkedReadyForDelivery,
				isMarkAsReadyForDeliveryChanged: isMarkAsReadyForDeliveryChanged
			}

			let endPoint: string = API_BASE_URL + 'api/ProcessGroupedTaxDocument';
			if (apiEndPoint) {
				endPoint = endPoint + '/' + apiEndPoint;
			}

			let options: any = {
				method: 'PUT',
				credentials: 'include',
				headers: {
					'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'X-Resource-Id': resourceId,
					'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
				},
				body: JSON.stringify(modelData)
			};

			const fetchTask = fetch(endPoint, options)
				.then(handleResponse)
				.then((data) => {
					let action: any = actionCreators.updateGroupedTaxDocument(taxReturn);
					dispatch(action);
					if (apiEndPoint !== processReturnActionEndPoints.generateTaxpayerViewAsync) {
						dispatch({
							type: actionTypes.NOTIFICATION,
							statusMessage: getProcessReturnSucessStatusMessage(apiEndPoint ? apiEndPoint : ""),
							statusType: StatusType.Success
						});
					}
					if (apiEndPoint == processReturnActionEndPoints.generateTaxpayerViewAsync) {
						let action: any = actionCreators.requestGroupedTaxDocument(taxReturn.id, false);
						dispatch(action);
					}

                    if (apiEndPoint == processReturnActionEndPoints.deliverAsync && ((taxDocumentsForDelivery && taxDocumentsForDelivery.length > 0 || taxReturn != null))) {
						let Ids = taxDocumentsForDelivery.map(x => x.taxDocumentId);
						dispatch({
							type: actionTypes.UPDATE_GROUPED_TAX_DOCUMENT_STATUS,
							ids: Ids,
							status: DocumentStatus.PREPARINGFORDELIVERY
						});
						dispatch({
							type: actionTypes.UPDATE_SEND_GROUPED_DOCUMENT_STATUS,
							ids: Ids,
							status: DocumentStatus.PREPARINGFORDELIVERY
						});
						dispatch({
							type: actionTypes.RESET_DELIVERED_GROUPED_RETURNS
                        }); 
					}
					if ((apiEndPoint == processReturnActionEndPoints.approveForDeliveryAsync
						|| apiEndPoint == processReturnActionEndPoints.sendForReviewAsync
						|| apiEndPoint == processReturnActionEndPoints.sendToEROAsync)
						&& taxDocumentsForDelivery && taxDocumentsForDelivery.length > 0) {
						let Ids = taxDocumentsForDelivery.map(x => x.taxDocumentId);
						dispatch({
							type: actionTypes.UPDATE_GROUPED_TAX_DOCUMENT_ASSIGN,
							ids: Ids,
							status: taxReturn.documentStatus,
							assignedUser: taxReturn.assignedUser,
							assignTo: taxReturn.assignTo
						});
						dispatch({
							type: actionTypes.UPDATE_SEND_GROUPED_DOCUMENT_ASSIGN,
							ids: Ids,
							status: taxReturn.documentStatus,
							assignedUser: taxReturn.assignedUser,
							assignTo: taxReturn.assignTo
						});
					}

					if (callback) {
						data ? callback(data) : callback();
                    }

					if(apiEndPoint != processReturnActionEndPoints.generateTaxpayerViewAsync)
					{
						dispatch({
							type: actionTypes.RESET_GROUPED_TAX_DOCUMENT, id: taxReturn.id
						});
					}

					const routingName = getRoutingName(
						apiEndPoint,
						taxReturn.documentSettings.deliverySettings.deliveryMode)
					apiEndPoint === undefined ?
						logger.trackTrace(`saved successfully for documentId: ${taxReturn.id?.toString()}, documentGuid: ${taxReturn.documentGuid}`, { "DocumentId": taxReturn.id?.toString(), "DocumentGuid": taxReturn.documentGuid }) :
						logger.trackTrace(`${routingName} sent successfully for documentId: ${taxReturn.id?.toString()}, documentGuid: ${taxReturn.documentGuid}`, { "DocumentId": taxReturn.id?.toString(), "DocumentGuid": taxReturn.documentGuid });

				})
				.catch(error => {
					dispatch({
						type: actionTypes.NOTIFICATION,
						statusMessage: getProcessReturnFailureStatusMessage(apiEndPoint ? apiEndPoint : ""),
						statusType: StatusType.Error
					});
					if (callback) {
						callback();
					}
				});
			addTask(fetchTask);
		},
	groupedReturnsSendForReview: (groupedReturnsModel: IGroupedReturnsModel, callback?: (data?: any) => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
		encodeGroupReturnDataHtmlTags(groupedReturnsModel);
		let endPoint: string = API_BASE_URL + 'api/GroupLevelProcessing/SendForReviewAsync';
		let options: any = {
			method: 'PUT',
			credentials: 'include',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
			},
			body: JSON.stringify(groupedReturnsModel)
		};

		const fetchTask = fetch(endPoint, options)
			.then(handleResponse)
			.then((data) => {
				dispatch({
					type: actionTypes.NOTIFICATION,
					statusMessage: Constants.GroupReturnConstants.StatusMessage.SendForReviewSuccess,
					statusType: Notification.StatusType.Success
				})
				if (callback) {
					data ? callback(data) : callback();
				}
				let Ids = groupedReturnsModel.taxReturns.map((x: any) => x.id);
				let document: ITaxReturn[] = groupedReturnsModel.taxReturns.map(item => item as ITaxReturn);
				dispatch({
					type: actionTypes.UPDATE_GROUPED_TAX_DOCUMENT_ASSIGN,
					ids: Ids,
					status: DocumentStatus.REVIEW,
					assignedUser: document[0].assignedUser,
					assignTo: document[0].assignTo
				});
				dispatch({
					type: actionTypes.UPDATE_SEND_GROUPED_DOCUMENT_ASSIGN,
					ids: Ids,
					status: DocumentStatus.REVIEW,
					assignedUser: document[0].assignedUser,
					assignTo: document[0].assignTo
				});
				logger.trackTrace(`Send for review sent successfully for groupId: ${groupedReturnsModel.groupInfo.id?.toString()}`, { "GroupId": groupedReturnsModel.groupInfo.id?.toString() });
			})
			.catch(error => {
				dispatch({
					type: actionTypes.NOTIFICATION,
					statusMessage: Constants.GroupReturnConstants.StatusMessage.SendForReviewError,
					statusType: Notification.StatusType.Error
				});
				if (callback) {
					callback();
				}

			});
		addTask(fetchTask);
	},
	groupedReturnsSendToERO: (groupedReturnsModel: IGroupedReturnsModel, callback?: (data?: any) => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
		encodeGroupReturnDataHtmlTags(groupedReturnsModel);
		let endPoint: string = API_BASE_URL + 'api/GroupLevelProcessing/SendToEROAsync';
		let options: any = {
			method: 'PUT',
			credentials: 'include',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
			},
			body: JSON.stringify(groupedReturnsModel)
		};

		const fetchTask = fetch(endPoint, options)
			.then(handleResponse)
			.then((data) => {
				dispatch({
					type: actionTypes.NOTIFICATION,
					statusMessage: Constants.GroupReturnConstants.StatusMessage.SendToEROSuccess,
					statusType: Notification.StatusType.Success
				})
				if (callback) {
					data ? callback(data) : callback();
				}
				let Ids = groupedReturnsModel.taxReturns.map((x: any) => x.id);
				let document: ITaxReturn[] = groupedReturnsModel.taxReturns.map(item => item as ITaxReturn);
				dispatch({
					type: actionTypes.UPDATE_GROUPED_TAX_DOCUMENT_ASSIGN,
					ids: Ids,
					status: DocumentStatus.REVIEW,
					assignedUser: document[0].assignedUser,
					assignTo: document[0].assignTo
				});
				dispatch({
					type: actionTypes.UPDATE_SEND_GROUPED_DOCUMENT_ASSIGN,
					ids: Ids,
					status: DocumentStatus.REVIEW,
					assignedUser: document[0].assignedUser,
					assignTo: document[0].assignTo
				});
				logger.trackTrace(`Send to ERO / Signer sent successfully for groupId: ${groupedReturnsModel.groupInfo.id?.toString()}`, { "GroupId": groupedReturnsModel.groupInfo.id?.toString() });
			})
			.catch(error => {
				dispatch({
					type: actionTypes.NOTIFICATION,
					statusMessage: Constants.GroupReturnConstants.StatusMessage.SendToEROError,
					statusType: Notification.StatusType.Error
				});
				if (callback) {
					callback();
				}

			});
		addTask(fetchTask);
	},
	groupedReturnsApproveForDelivery: (groupedReturnsModel: IGroupedReturnsModel, callback?: (data?: any) => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
		encodeGroupReturnDataHtmlTags(groupedReturnsModel);
		let endPoint: string = API_BASE_URL + 'api/GroupLevelProcessing/ApproveForDeliveryAsync';
		let options: any = {
			method: 'PUT',
			credentials: 'include',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
			},
			body: JSON.stringify(groupedReturnsModel)
		};

		const fetchTask = fetch(endPoint, options)
			.then(handleResponse)
			.then((data) => {
				dispatch({
					type: actionTypes.NOTIFICATION,
					statusMessage: Constants.GroupReturnConstants.StatusMessage.ApproveForDeliverySuccess,
					statusType: Notification.StatusType.Success
				})
				if (callback) {
					data ? callback(data) : callback();
				}
				let Ids = groupedReturnsModel.taxReturns.map((x: any) => x.id);
				let document: ITaxReturn[] = groupedReturnsModel.taxReturns.map(item => item as ITaxReturn);
				dispatch({
					type: actionTypes.UPDATE_GROUPED_TAX_DOCUMENT_ASSIGN,
					ids: Ids,
					status: DocumentStatus.APPROVEDFORDELIVERY,
					assignedUser: document[0].assignedUser,
					assignTo: document[0].assignTo
				});
				dispatch({
					type: actionTypes.UPDATE_SEND_GROUPED_DOCUMENT_ASSIGN,
					ids: Ids,
					status: DocumentStatus.APPROVEDFORDELIVERY,
					assignedUser: document[0].assignedUser,
					assignTo: document[0].assignTo
				});
				logger.trackTrace(`Approve for Delivery sent successfully for groupId: ${groupedReturnsModel.groupInfo.id?.toString()}`, { "GroupId": groupedReturnsModel.groupInfo.id?.toString() });
			})
			.catch(error => {
				dispatch({
					type: actionTypes.NOTIFICATION,
					statusMessage: Constants.GroupReturnConstants.StatusMessage.ApproveForDeliveryError,
					statusType: Notification.StatusType.Error
				});
				if (callback) {
					callback();
				}

			});
		addTask(fetchTask);
	},
	groupedReturnsDeliverToController: (groupedReturnsModel: IGroupedReturnsModel, resourceId? : string , callback?: (data?: any) => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
		
		encodeGroupReturnDataHtmlTags(groupedReturnsModel);

		let endPoint: string = API_BASE_URL + 'api/GroupLevelProcessing/DeliverAsync';
		let options: any = {
			method: 'PUT',
			credentials: 'include',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value,
				'X-Resource-Id': resourceId
			},
			body: JSON.stringify(groupedReturnsModel)
		};

		const fetchTask = fetch(endPoint, options)
			.then(handleResponse)
			.then((data) => {
				dispatch({
					type: actionTypes.NOTIFICATION,
					statusMessage: Constants.GroupReturnConstants.StatusMessage.DeliverToClientSuccess,
					statusType: Notification.StatusType.Success
				})
				if (callback) {
					data ? callback(data) : callback();
				}
				let Ids = groupedReturnsModel.taxReturns.map((x: any) => x.id);
				dispatch({
					type: actionTypes.UPDATE_GROUPED_TAX_DOCUMENT_STATUS,
					ids: Ids,
					status: DocumentStatus.PREPARINGFORDELIVERY
				});
				dispatch({
					type: actionTypes.UPDATE_SEND_GROUPED_DOCUMENT_STATUS,
					ids: Ids,
					status: DocumentStatus.PREPARINGFORDELIVERY
				});
				logger.trackTrace(`Deliver to controller sent successfully for groupId: ${groupedReturnsModel.groupInfo.id?.toString()}`, { "GroupId": groupedReturnsModel.groupInfo.id?.toString() });
			})
			.catch(error => {
				dispatch({
					type: actionTypes.NOTIFICATION,
					statusMessage: Constants.GroupReturnConstants.StatusMessage.DeliverToClientError,
					statusType: Notification.StatusType.Error
				});
				if (callback) {
					callback();
				}

			});
		addTask(fetchTask);
	},
	PrintForPaperDeliveryAsync: (groupedReturnsModel: IGroupedReturnsModel, paperReturnDeliveryType: PaperReturnDeliveryType,
		orderList: DocumentGroups[], isNotifyUserEnabled: boolean, isSaveAsDefaultOrder: boolean, resourceId?: string , callback?: (data?: any) => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
			encodeGroupReturnDataHtmlTags(groupedReturnsModel);
			let modelData: IPrintForPaperDeliveryViewModel = {
				groupedReturnsModel: groupedReturnsModel,
				paperReturnDeliveryType: paperReturnDeliveryType,
				orderList: orderList,
				isNotifyUserEnabled: isNotifyUserEnabled,
				isSaveAsDefaultOrder: isSaveAsDefaultOrder
			};
			let endPoint: string = API_BASE_URL + 'api/GroupLevelProcessing/PrintForPaperDeliveryAsync';
			let options: any = {
				method: 'PUT',
				credentials: 'include',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json',
					'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value,
					'X-Resource-Id': resourceId
				},
				body: JSON.stringify(modelData)
			};

			const fetchTask = fetch(endPoint, options)
				.then(handleResponse)
				.then((data) => {
					if (Object.keys(data).length > 0) {
						dispatch({
							type: actionTypes.NOTIFICATION,
							statusMessage: Constants.GroupReturnConstants.StatusMessage.DeliverToClientSuccess,
							statusType: Notification.StatusType.Success
						})

						if (callback) {
							data ? callback(data) : callback();
						}
					}
					else {
						dispatch({
							type: actionTypes.NOTIFICATION,
							statusMessage: Constants.GroupReturnConstants.StatusMessage.DeliverToClientError,
							statusType: Notification.StatusType.Error
						});
						if (callback) {
							callback();
						}
					}
					logger.trackTrace(`Download pdf sent successfully for groupId: ${groupedReturnsModel.groupInfo.id?.toString()}`, { "GroupId": groupedReturnsModel.groupInfo.id?.toString() });
				})
				.catch(error => {
					dispatch({
						type: actionTypes.NOTIFICATION,
						statusMessage: Constants.GroupReturnConstants.StatusMessage.DeliverToClientError,
						statusType: Notification.StatusType.Error
					});
					if (callback) {
						callback();
					}

				});
			addTask(fetchTask);
		},
	notifyGroupedTaxDocument: (id: number, status: DocumentStatus): AppThunkAction<KnownAction> => (dispatch, getState) => {
		const state = getState();
		if (state.groupedTaxDocuments[id] && status === DocumentStatus.READY) {
			dispatch(actionCreators.requestGroupedTaxDocument(id, true));
			console.log('id not in state.taxDocuments in GR');
		} else if (state.groupedTaxDocuments[id]) {
			console.log('id exist in state.taxDocuments in GR');
			const taxReturn = state.groupedTaxDocuments[id].taxReturn;
			if (taxReturn) {
				if (taxReturn.documentSettings?.deliverySettings && taxReturn.documentSettings.deliverySettings.deliveryMode == DeliveryMode.PaperFiled &&
					status == DocumentStatus.DELIVERED) {
					taxReturn.documentStatus = DocumentStatus.DOWNLOADPDFDESCRIPTION;
				}
				else {
					taxReturn.documentStatus = status;
				}
				dispatch({ type: actionTypes.RECEIVE_GROUPED_TAX_DOCUMENT, id: taxReturn.id, taxDocument: taxReturn });
			}
		}
	}
}

export function getRoutingName(apiEndPoint: string, paperFile: DeliveryMode) {
	let message: string = "";
	switch (apiEndPoint) {
		case processReturnActionEndPoints.approveForDeliveryAsync:
			message = Constants.FinishProcessReturn.MenuItems.ApproveForDelivery;
			break;
		case processReturnActionEndPoints.sendToEROAsync:
			message = Constants.FinishProcessReturn.MenuItems.SendToEROSigner;
			break;
		case processReturnActionEndPoints.sendForReviewAsync:
			message = Constants.FinishProcessReturn.MenuItems.SendForReview;
			break;
		case processReturnActionEndPoints.deliverAsync:
			if (paperFile === DeliveryMode.PaperFiled) {
				message = Constants.FinishProcessReturn.MenuItems.PaperDelivery;
			}
			else {
				message = Constants.FinishProcessReturn.MenuItems.DeliverToController;
			}
			break;
		default: message = Constants.FinishProcessReturn.MenuItems.Empty;
	}
	return message;
}

const unloadedState: IGroupedTaxDocumentDictionary = {
	[0]: {
		taxReturn: initialTaxReturnState,
		isLoading: false,
		error: false,
		message: '',
		isFullyLoaded: false
	}
}

export const reducer: Reducer<IGroupedTaxDocumentDictionary> = (state: IGroupedTaxDocumentDictionary = unloadedState, incomingAction: Action) => {
	const action = incomingAction as DispatchActions;
	switch (action.type) {
		case actionTypes.REQUEST_GROUPED_TAX_DOCUMENT:
			let loading: IGroupedTaxDocumentDictionary = { ...state };
			loading[action.id] = {
				taxReturn: state[action.id] ? state[action.id].taxReturn : initialTaxReturnState,
				isLoading: true,
				error: false,
				message: '',
				isFullyLoaded: false
			};

			return loading;


		case actionTypes.RECEIVE_GROUPED_TAX_DOCUMENT:
			let received: IGroupedTaxDocumentDictionary = { ...state };
			let message = Date();
			action.taxDocument.accessCode = {} as IAccessCodeViewModel;

			decodeTaxDocumentHtmlTags(action.taxDocument);

			received[action.id] = {
				taxReturn: action.taxDocument,
				isLoading: false,
				error: false,
				message: message,
                isFullyLoaded: ((action.isEditClientInfoRequest != undefined && action.isEditClientInfoRequest == true)
                    || (received[action.id]?.isResetRequested != undefined && received[action.id]?.isResetRequested == true)) ? false
                    : action.taxDocument.formGroups.length != 0 && action.taxDocument.documentSettings != undefined ? true : false, 
				fileName: received[action.id] == undefined ? "" : received[action.id].fileName,
                isDefaultK1: received[action.id]?.isDefaultK1,
                isResetRequested: false
			};
			return received;

		case actionTypes.ERROR_GROUPED_TAX_DOCUMENT:
			let errorState: IGroupedTaxDocumentDictionary = { ...state };
			action.ids.map((id, i) => {
				if (state[id]) {
					let model = state[id].taxReturn;
					errorState[id].isLoading = false;
					errorState[id].error = true;
					errorState[id].message = action.message;
				}
			});

			return errorState;

		case actionTypes.RECEIVE_TAX_DOCUMENT_SIGNED_DETAILS:
			let signedDetailsState: IGroupedTaxDocumentDictionary = { ...state };
			let signedDetailsTaxReturn = state[action.id];
			if (signedDetailsTaxReturn) {
				signedDetailsTaxReturn.taxReturn.signedDetails = action.signedDetails;
				signedDetailsState[action.id] = {
					taxReturn: signedDetailsTaxReturn.taxReturn,
					isLoading: false,
					error: false,
					message: '',
					isFullyLoaded: signedDetailsState[action.id].isFullyLoaded
				};
			}

			return signedDetailsState;

		case actionTypes.RECEIVE_GROUPED_TAX_DOCUMENT_STATUS:
			let documentStatusState: IGroupedTaxDocumentDictionary = { ...state };
			let documentStatusOfTaxReturn = state[action.id].taxReturn;
			documentStatusOfTaxReturn.documentStatus = action.documentStatus;
			documentStatusState[action.id] = {
				taxReturn: documentStatusOfTaxReturn,
				isLoading: false,
				error: false,
				message: '',
				isFullyLoaded: documentStatusState[action.id].isFullyLoaded
			};

			return documentStatusState;

		case actionTypes.MAKE_AVAILABLE_INUSE_TAX_DOCUMENT:
			let makeavailableState = { ...state };
			action.ids.map((id, i) => {
				delete makeavailableState[id];
			});

			return makeavailableState;
		case actionTypes.K1_INSTRUCTION_DETAIL:
			let taxDocument: IGroupedTaxDocumentDictionary = { ...state };
			let taxReturn = state[action.id]?.taxReturn;
			if (taxDocument && taxDocument[action.id]) {
				taxDocument[action.id] = {
					taxReturn: taxReturn,
					isLoading: false,
					error: false,
					message: Date(),
					isFullyLoaded: taxDocument[action.id].isFullyLoaded,
					fileName: action.fileName,
					isDefaultK1: action.isDefaultK1
				};
			}
			return taxDocument;
		case actionTypes.REQUEST_TAXCADDY_LOOKUP_DETAILS:
			let taxcaddyDetails: IGroupedTaxDocumentDictionary = { ...state };
			let taxCaddyLookupDetails = state[action.id];
			if (taxCaddyLookupDetails) {
				taxCaddyLookupDetails.taxReturn.taxCaddyLookupResultModel = action.taxcaddyLookupDetails;
				taxcaddyDetails[action.id] = {
					taxReturn: taxCaddyLookupDetails.taxReturn,
					isLoading: false,
					error: false,
					message: Date(),
					isFullyLoaded: taxcaddyDetails[action.id].isFullyLoaded,
				};
			}
			return taxcaddyDetails;
		case actionTypes.CLEAR_TAXCADDY_LOOKUP_DETAILS:
			let clearTaxcaddyDetails: IGroupedTaxDocumentDictionary = { ...state };
			let clearTaxCaddyLookupDetails = state[action.id];
			//delete taxcaddyTaxreturn.taxCaddyLookupResultModel;
			if (clearTaxCaddyLookupDetails) {
				clearTaxcaddyDetails[action.id] = {
					taxReturn: clearTaxCaddyLookupDetails.taxReturn,
					isLoading: false,
					error: false,
					message: Date(),
					isFullyLoaded: clearTaxcaddyDetails[action.id].isFullyLoaded,
				};
			}
			return clearTaxcaddyDetails;

        case actionTypes.RESET_GROUPED_TAX_DOCUMENT:
            let resetTaxDocumentState: IGroupedTaxDocumentDictionary = { ...state };
			if (resetTaxDocumentState[action.id]) {
                resetTaxDocumentState[action.id].isFullyLoaded = false;
                resetTaxDocumentState[action.id].isResetRequested = true;
			}
			return resetTaxDocumentState;
		case actionTypes.RECEIVE_SEND_GROUPED_RETURNS:

			let receivedDocs: IGroupedTaxDocumentDictionary = { ...state };
			let messageStr = Date();

			action.table.sendGroupedReturns?.forEach((group, i) => {
				group.taxReturns.forEach((model, i) => {

					const document = state[model.id];

					receivedDocs[model.id] = {
						taxReturn: document && document.isFullyLoaded ? document.taxReturn : model,
						isLoading: false,
						error: false,
						message: messageStr,
						isFullyLoaded: document && document.isFullyLoaded ? true : false,
						fileName: receivedDocs[model.id] == undefined ? "" : receivedDocs[model.id].fileName
					};
				});
			});

			return receivedDocs;

		case actionTypes.RECEIVE_GROUPEDRETURN_DOCUMENTLEVEL_TAXRETURNS:

			let receivedDeliveredDocs: IGroupedTaxDocumentDictionary = { ...state };

			action.data?.forEach((model: IDeliveredTaxDocument) => {

					const document = state[model.document.id];
					receivedDeliveredDocs[model.document.id] = {
						taxReturn: document && document.isFullyLoaded ? document.taxReturn : model.document,
						isLoading: false,
						error: false,
						message: "",
						isFullyLoaded: false,
						fileName: receivedDeliveredDocs[model.document.id] == undefined ? "" : receivedDeliveredDocs[model.document.id].fileName
					};
			});

			return receivedDeliveredDocs;


		case actionTypes.MARK_AS_READY_FOR_DELIVERY:
			let markAsReadyForDeliveryState: IGroupedTaxDocumentDictionary = { ...state };
			let taxreturnMark = state[action.id].taxReturn;
			taxreturnMark.documentStatus = DocumentStatus.READYFORDELIVERY;
			markAsReadyForDeliveryState[action.id] = {
				taxReturn: taxreturnMark,
				isLoading: false,
				error: false,
				message: Date(),
				isFullyLoaded: false,
			};
			return markAsReadyForDeliveryState;

		case actionTypes.UPDATE_GROUPED_TAX_DOCUMENT_STATUS:
			let updateGroupedTaxDocumentStatusState: IGroupedTaxDocumentDictionary = { ...state };
			action.ids.map((id, index) => {
				let taxDocument = updateGroupedTaxDocumentStatusState[id];
				if (taxDocument) {
					taxDocument.taxReturn.documentStatus = action.status;
				}
			});
			return updateGroupedTaxDocumentStatusState;
		case actionTypes.UPDATE_GROUPED_TAX_DOCUMENT_ASSIGN:
			let updateGroupedTaxDocumentAssignState: IGroupedTaxDocumentDictionary = { ...state };
			action.ids.map((id, index) => {
				let taxDocument = updateGroupedTaxDocumentAssignState[id];
				if (taxDocument) {
					taxDocument.taxReturn.assignedUser = action.assignedUser;
					taxDocument.taxReturn.assignTo = action.assignTo;
					taxDocument.taxReturn.documentStatus = action.status;
				}
			});
			return updateGroupedTaxDocumentAssignState;

		case actionTypes.RECEIVE_TAX_DOCUMENT_DOWNLOADABLE_DOCUMENTS:
			let downloadableDocumentState: IGroupedTaxDocumentDictionary = { ...state };
			let downloadableDocumentTaxReturn = state[action.id];
			if (downloadableDocumentTaxReturn) {
				downloadableDocumentTaxReturn.taxReturn.downloadableDocuments = action.downloadableDocuments;
				downloadableDocumentState[action.id] = {
					taxReturn: downloadableDocumentTaxReturn.taxReturn,
					isLoading: false,
					error: false,
					message: Date(),
					isFullyLoaded: downloadableDocumentState[action.id].isFullyLoaded
				};
			}
			return downloadableDocumentState;

		case actionTypes.RECEIVE_TAX_DOCUMENT_DOWNLOADABLE_EFILE_FORMS:
			let downloadableEfileFormState: IGroupedTaxDocumentDictionary = { ...state };
			let downloadableEfileFormTaxReturn = state[action.id];
			if (downloadableEfileFormTaxReturn) {
				downloadableEfileFormTaxReturn.taxReturn.downloadableEfileForms = action.downloadableEfileForms;
				downloadableEfileFormState[action.id] = {
					taxReturn: downloadableEfileFormTaxReturn.taxReturn,
					isLoading: false,
					error: false,
					message: Date(),
					isFullyLoaded: downloadableEfileFormState[action.id].isFullyLoaded
				};
			}
			return downloadableEfileFormState;
		case actionTypes.RECEIVE_TAX_DOCUMENT_DOWNLOAD_HISTORY:
			let downloadHistoryState: IGroupedTaxDocumentDictionary = { ...state };
			let downloadHistoryTaxReturn = state[action.id];
			if (downloadHistoryTaxReturn) {
				downloadHistoryTaxReturn.taxReturn.downloadHistory = action.downloadHistory;
				downloadHistoryState[action.id] = {
					taxReturn: downloadHistoryTaxReturn.taxReturn,
					isLoading: false,
					error: false,
					message: Date(),
					isFullyLoaded: downloadHistoryState[action.id].isFullyLoaded
				};
			}
			return downloadHistoryState;

		default:
			// The following line guarantees that every action in the KnownAction union has been covered by a case above
			const exhaustiveCheck: never = action;
	}
	return state;
};


