export interface IUserNotificationSettings {
    notificationEvents: IUserNotificationEvents[],
    generalSettings: IUserNotificationGeneralSettings
}

export interface IUserNotificationTypes {
    inApp: boolean,
    email: boolean
}

export interface IUserNotificationGeneralSettings {
    maxVisibileNotification: number,
    notificationValidFor: number,
    showPopup: boolean,
    maxPopupTime: number;
    playSound: boolean
}

export interface IUserNotificationEvents {
    eventId: number,
    eventType: IUserNotificationTypes
}

export interface IUserNotificationEventsViewModel {
    eventId: number,
    mailNotification: boolean,
    appNotification: boolean
}

export enum NotificationType {
    Application=1,
    Email=2
}
