import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../';
import { actionTypes } from '../../types/ActionTypes';
import { NotificationAction } from '../common/NotificationStore';
import { handleResponse } from '../Library';
import { API_BASE_URL } from '../../utils/constants';

export interface IDownloadState {
    url: string;
    isK1Restored: boolean;
}

export interface RequestDownloadK1DocumentLInkAsyncAction {
    type: actionTypes.REQUEST_DOWNLOAD_K1DOCUMENT_LINK_ASYNC;
    url: string;
}
export interface ReceiveDownloadK1DocumentLInkAsyncAction {
    type: actionTypes.RECEIVE_DOWNLOAD_K1DOCUMENT_LINK_ASYNC;
    url: string;
}

export interface ReceiveCompanySettingsAction {
    type: actionTypes.RECEIVE_K1DOCUMENT_SETTINGS;
    isK1Restored: boolean;
}

type KnownAction = NotificationAction | RequestDownloadK1DocumentLInkAsyncAction
    | ReceiveDownloadK1DocumentLInkAsyncAction | ReceiveCompanySettingsAction;;

export const actionCreators = {
    requestDownloadK1DocumentLink: (documentGuid: string, type: string, taxYear: number, openPreview: (url: string) => void, verifyK1Replaced?: boolean, isK1Replaced?: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        fetch(API_BASE_URL + 'api/Download/GetK1DocumentLinkAsync?documentKey=' + documentGuid +
            "&type=" + type + "&taxYear=" + taxYear + "&verifyK1Replaced=" + verifyK1Replaced + "&isK1Replaced=" + isK1Replaced, { credentials: 'include' })
            .then(handleResponse)
            .then(json => json)
            .then(data => {
                openPreview(data.k1Path);
                dispatch({ type: actionTypes.RECEIVE_K1DOCUMENT_SETTINGS, isK1Restored: data.isCompanyK1 });
            })
            .catch((error: any) => {
                console.log(error);
            });
    }
}

const unloadedState: IDownloadState = {} as IDownloadState;

export const reducer: Reducer<IDownloadState> = (state = unloadedState, incomingAction) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case actionTypes.RECEIVE_K1DOCUMENT_SETTINGS:
            var received = { ...state };
            received.isK1Restored = action.isK1Restored
            return received;
    }
    return state;
};