import { Action, Reducer } from 'redux';
import { actionTypes } from '../../../types/ActionTypes';
import { RequestArchivedReturnsPagesAction, ReceiveArchivedReturnsPagesAction } from "./KnownTypes";
import {
    IArchivedTaxDocument, ArchivedReturnsState, ArchivedReturnsTableModel,
    IArchivedTaxReturn, IArchivedReturnsDictionary
} from './ArchivedReturnsState';
import {
    ReceiveArchivedTaxDocumentAction, RestoreArchivedTaxDocumentAction,
	UpdateArchivedTaxDocumentCustomColumnValueAction, ResetArchivedReturnsAction
} from './ArchivedTaxDocumentStore';

type DispatchAction = RequestArchivedReturnsPagesAction
    | ReceiveArchivedReturnsPagesAction | RestoreArchivedTaxDocumentAction | ReceiveArchivedTaxDocumentAction
    | UpdateArchivedTaxDocumentCustomColumnValueAction | ResetArchivedReturnsAction;

export const actionCreators = {};

const unloadedState: ArchivedReturnsState = {
	archivedReturnTableModel: {},
	query: '?',
	loading: false,
	totalRowCount: 0
} as ArchivedReturnsState;

const unloadedArchivedTaxDocument: IArchivedTaxDocument = {
	customColumn: '',
	document: {},
    downloadsCount: 0,
    lastReminderOn: undefined,
    retentionPeriod: undefined,
	signedCount: 0
} as IArchivedTaxDocument;

export const reducer: Reducer<IArchivedReturnsDictionary> = (state = { "?": unloadedState }, incomingAction: Action) => {
	const action = incomingAction as DispatchAction;
	switch (action.type) {
		case actionTypes.REQUEST_ARCHIVE_RETURNS_PAGES:
			if (!state[action.query]) {
				let added = { ...unloadedState } as ArchivedReturnsState;
				added.loading = true;
				added.query = action.query;
				let item = {} as IArchivedReturnsDictionary;
				item[action.query] = added;

				return {
					...state,
					...item
				};
			}
			break;
		case actionTypes.RECEIVE_ARCHIVE_RETURNS_PAGES:
			if (state[action.query]) {
				let changed = state[action.query] as ArchivedReturnsState;
				changed.loading = false;
				changed.archivedReturnTableModel = action.table;
				changed.totalRowCount = action.totalRowCount;
				let item = {} as IArchivedReturnsDictionary;
				item[action.query] = changed;
				return {
					...state,
					...item
				};
			}
			break;
		case actionTypes.RESTORE_ARCHIVE_TAX_DOCUMENT:
		case actionTypes.RESET_ARCHIVE_RETURNS:
			return clearTaxReturns();

		case actionTypes.RECEIVE_ARCHIVE_TAX_DOCUMENT:
		case actionTypes.UPDATE_ARCHIVE_DOCUMENT_CUSTOM_COLUMN_VALUE:
			return updateState(action.type, state, action);

		default:
			// The following line guarantees that every action in the KnownAction union has been covered by a case above
			const exhaustiveCheck: never = action;
	}
	return state;
};

function updateState(type: actionTypes, state: IArchivedReturnsDictionary, action: DispatchAction): IArchivedReturnsDictionary {
	let i: number = -1;
	let newState = {} as IArchivedReturnsDictionary;
	let oldDocument: IArchivedTaxDocument = unloadedArchivedTaxDocument;
	let document: IArchivedTaxReturn = {} as IArchivedTaxReturn;
	let customColumn: string = "";
	let id: number = 0;
	switch (action.type) {
		case actionTypes.RECEIVE_ARCHIVE_TAX_DOCUMENT:
			document = action.taxDocument as IArchivedTaxReturn;
			id = action.id;
			break;
		case actionTypes.UPDATE_ARCHIVE_DOCUMENT_CUSTOM_COLUMN_VALUE:
			customColumn = action.customColumn;
			id = action.id;
			break;
	}

	for (var query in state) {
		i = -1;
		if (state[query].archivedReturnTableModel.documents) {
			state[query].archivedReturnTableModel.documents.forEach((value, index) => {
				if (value.document.id === id) {
					i = index;
					oldDocument = value;
					return;
				}
			});
		}
		if (i !== -1) {
			let deliveredTaxDocument: IArchivedTaxDocument = {
				document: action.type == actionTypes.RECEIVE_ARCHIVE_TAX_DOCUMENT ? document : oldDocument.document,
				customColumn: action.type == actionTypes.UPDATE_ARCHIVE_DOCUMENT_CUSTOM_COLUMN_VALUE ? customColumn : oldDocument.customColumn,
				downloadsCount: oldDocument.downloadsCount,
				signedCount: oldDocument.signedCount,
                lastReminderOn: oldDocument.lastReminderOn,
                retentionPeriod: oldDocument.retentionPeriod,
                bulkDownloadCount: oldDocument.bulkDownloadCount
			};

			let documents = [
				...state[query].archivedReturnTableModel.documents?.slice(0, i),
				deliveredTaxDocument,
				...state[query].archivedReturnTableModel.documents?.slice(i + 1)];
			let archivedReturnTableModel: ArchivedReturnsTableModel = {
				count: state[query].archivedReturnTableModel.count,
				documents: documents
			}
			newState[query] = {
				query: query,
				archivedReturnTableModel: archivedReturnTableModel,
				totalRowCount: state[query].totalRowCount,
				loading: false
			};
		}
		else {
			newState[query] = {
				query: query,
				archivedReturnTableModel: state[query].archivedReturnTableModel,
				totalRowCount: state[query].totalRowCount,
				loading: false
			};
		}
	}
	return newState;
}

function clearTaxReturns(): IArchivedReturnsDictionary {
	return {} as IArchivedReturnsDictionary;   //clearing the dictionary on archive/recall
}