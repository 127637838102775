import * as React from 'react'
import { K1ReportStatus } from './../../../../store/reports/K1DeliveredStatusReport/K1DeliveredStatusState'
import K1PartnersTable from './K1PartnersTable';
import K1PartnersHeader from './K1PartnersHeader';
import { IK1PartnerViewModel } from './../../../../store/reports/K1DeliveredStatusReport/K1DeliveredStatusState'
import { VenusNotifier } from './../../../helper/VenusNotifier';
import { EntityType } from '../../../common/TaxReturn';
import { CommonResources, K1DeliveredStatusResources } from '../../../helper/ResourceIdConstants';

interface K1PartnersProps {
    k1Partners: IK1PartnerViewModel;
    isLoading: boolean;
    requestK1Partners: (query: string) => void;
    currentDocument: number;
    onExportToExcel: (query: string, callback : () => void, resourceId:string) => void;
    clearK1Partner: () => void;
    sendReminderToPartner: (partnerIds: number[], documentId: number, callback?: () => void, resourceId: string) => void;
    modalLoader: (toggleValue: boolean) => void;
    parentResourceIdentifier: string;
}
interface K1PartnersState {
    sortName: string;
    sortOrder: string;
    searchString: string;
    filterPIN: string;
    filterAddress: string;
    filterPartner: string;
    filterEmail: string;
    filterEntityType: EntityType;
    filterStatus: K1ReportStatus;
    pageNo: number;
    selectedRows: number[];
}

export default class K1Partners extends React.Component<K1PartnersProps, K1PartnersState>{
    state: K1PartnersState = {
        filterAddress: "",
        filterEmail: "",
        filterEntityType: EntityType.None,
        filterPartner: "",
        filterPIN: "",
        filterStatus: K1ReportStatus.None,
        searchString: "",
        sortName: "createdon",
        sortOrder: "desc",
        pageNo: 1,
        selectedRows: [],
    }

    UNSAFE_componentWillMount() {
        this.fetchK1Partners(1, this.pageSize);
    }

    componentWillUnmount() {
        this.props.clearK1Partner();
    }

    private pageSize : number = 10;
    private buildQuery = (page: number, pageSize: number) => {
        return '?PageNo=' + page
            + '&PageSize=' + pageSize
            + '&id=' + this.props.currentDocument
            + '&SortBy=' + this.state.sortName
            + '&SortOrder=' + this.state.sortOrder
            + '&SearchString=' + this.state.searchString
            + '&filterPIN=' + this.state.filterPIN
            + '&filterAddress=' + this.state.filterAddress
            + '&filterPartner=' + this.state.filterPartner
            + '&filterEmail=' + this.state.filterEmail
            + '&filterEntityType=' + this.state.filterEntityType
            + '&filterStatus=' + this.state.filterStatus
    }
    private onFilterChange = (dataField: any) => {
        let newState: any = {
            filterPIN: "",
            filterPartner: "",
            filterAddress: "",
            filterEmail: "",
            filterEntityType: EntityType.None,
            filterStatus: K1ReportStatus.None
        };

        for (let field of Object.keys(dataField)) {
            switch (field) {
                case 'PIN':
                    newState.filterPIN = dataField[field].value ? dataField[field].value : dataField[field];
                    break;
                case 'address':
                    newState.filterAddress = dataField[field].value ? dataField[field].value : dataField[field];
                    break;
                case 'partnerName':
                    newState.filterPartner = dataField[field].value ? dataField[field].value : dataField[field];
                    break;
                case 'email':
                    newState.filterEmail = dataField[field].value ? dataField[field].value : dataField[field];
                    break;
                case 'entityType':
                    newState.filterEntityType = dataField[field].value ? dataField[field].value : dataField[field];
                    break;
                case 'status':
                    newState.filterStatus = dataField[field].value ? dataField[field].value : dataField[field];
                    break;
            }
        }
        this.setState({
            ...newState
        },() => {
            this.fetchK1Partners(1, this.pageSize);
                 });
    }
    private onRowSelect = (row: any, isSelected: any, e: React.BaseSyntheticEvent) => {
        let newList = [...this.state.selectedRows];
        if (e.target.tagName !== 'BUTTON' && e.target.tagName !== 'I'
            && e.target.tagName !== 'SPAN' && e.target.tagName !== 'A') {
            if (isSelected) {
                newList.push(row.rowIndex);
            } else {
                var index = newList.indexOf(row.rowIndex);
                if (index > -1) {
                    newList.splice(index, 1);
                }
            }
            this.setState({ selectedRows: newList });
        }
        this.forceUpdate();
    }
    private onSelectAll = (isSelected: any, rows: any, e: any) => {
        let selectedRows: number[] = [];
        if (isSelected) {
            let count = rows && rows.length ? rows.length : 0;
            Array.from(Array(count).keys());
            selectedRows = Array.from(Array(count).keys());
        }
        this.setState({ selectedRows: selectedRows });
    }
    private onPageChange = (page: number, sizePerPage: number) => {
        this.setState({
            pageNo: page,
            selectedRows: []
        }, this.loadK1Partners);
    }
    private onSortChange = (sortName: string, sortOrder: string, column: number) => {
        this.setState({
            sortName: sortName,
            sortOrder: sortOrder == "asc" ? "asc" : "desc"
        }, () => this.fetchK1Partners(1, this.pageSize));
    }
    private onSearchChange = (searchString: string) => {
        this.setState({
            searchString: searchString

        }, () => this.fetchK1Partners(1, this.pageSize));
    }
    private onExportExcelPartners = () => {
        const { modalLoader } = this.props;
        modalLoader(true);
        this.props.onExportToExcel(this.buildQuery(this.state.pageNo, this.pageSize), () => { modalLoader(false); }, this.props.parentResourceIdentifier + K1DeliveredStatusResources.DownloadCSV);
    }

    private onSendReminder = () => {
        const { selectedRows } = this.state;
        const { currentDocument, modalLoader } = this.props;
        if (selectedRows.length < 0) {
            VenusNotifier.Warning("Select Partner to send reminder", "");
            return;
        }
        let ids: any = [];
        ids = this.getSelectedPartnerIds();
        if (ids.emptyEmailIds.length > 0) {
            VenusNotifier.Warning("Reminder can not be sent to the selected partners", "");
            return;
        }
        modalLoader(true);
        this.props.sendReminderToPartner(ids.ids, currentDocument, () => { this.onReminderSent(); }, this.props.parentResourceIdentifier + K1DeliveredStatusResources.SendReminder);
    }

    private onReminderSent = () => {
        this.props.modalLoader(false);
        VenusNotifier.Success("Reminders have been sent to the selected partner(s)", "");
        this.setState({
            selectedRows : []
        });
    }

    private loadK1Partners = () => {
        let queryString = this.buildQuery(this.state.pageNo, this.pageSize);
        this.props.requestK1Partners(queryString);
    }
    private fetchK1Partners = (pageNo: number, pageSize: number) => {
        let queryString = this.buildQuery(pageNo, pageSize);
        this.props.requestK1Partners(queryString);
    }

    private getSelectedPartnerIds = () => {
        let ids: number[] = [];
        let emptyEmailIds: number[] = [];
        const { selectedRows } = this.state;
        const { k1Partners } = this.props;
        for (var i of selectedRows) {
            if (!k1Partners.k1Partners[i]?.email) {
                emptyEmailIds.push(k1Partners.k1Partners[i].id);
            }
            else {
                ids.push(k1Partners.k1Partners[i].id);
            }
        }
        return { ids, emptyEmailIds};
    }


    render() {
        const { isLoading, k1Partners } = this.props;
        const { pageNo, selectedRows } = this.state;
        return (
            <div>
                <K1PartnersHeader
                    pageTitle={"K1 Partner Update"}
                    onExportExcelPartners={this.onExportExcelPartners}
                    onSendReminder={this.onSendReminder}
                    selectedPartnerCount={selectedRows.length}
                    parentResourceId={this.props.parentResourceIdentifier}
                />
                <br />
                <K1PartnersTable
                    isLoading={isLoading}
                    k1Partners={k1Partners}
                    pageSize={this.pageSize}
                    pageNo={pageNo}
                    selectedRows={selectedRows}
                    onFilterChange={this.onFilterChange}
                    onPageChange={this.onPageChange}
                    onRowSelect={this.onRowSelect}
                    onSearchChange={this.onSearchChange}
                    onSelectAll={this.onSelectAll}
                    onSortChange={this.onSortChange}
                />
            </div>
        );
    }
}
