import React from "react";
import Source from "./Source";
import Destination from "./Destination";
import { IDragAndDropData } from "./DragAndDrop.model";
import ResetIcon from "src/assets/images/ResetIcon";
import styles from "./DragAndDrop.module.scss";
import { useState } from "react";

interface DragAndDropProps {
    sourceData: IDragAndDropData[];
    setSourceData: (sourceData: IDragAndDropData[]) => void;
    destinationData: IDragAndDropData[];
    setDestinationData: (destinationData: IDragAndDropData[]) => void;
    sourceDataInitial: IDragAndDropData[];
    destinationDataInitial: IDragAndDropData[];
    sourceHeading?: string;
    sourceHeadingInfoText?: string;
    destinationHeading?: string;
    destinationHeadingInfoText?: string;
    showResetButton?: boolean;
    sourceWidth?: string;
    sourceHeight?: string;
    destinationWidth?: string;
    destinationHeight?: string;
    setRemovedUsers?: (usersRemoved: IDragAndDropData[]) => void;
    disableDragAndDrop?: boolean;
    dndUniqueID?: string;
    disableDestinationItems?: number[];
    setIsDataChanged?: (isDataChanged: boolean) => void;
}

function DragAndDrop(props: DragAndDropProps) {
    const {
        sourceData,
        setSourceData,
        destinationData,
        setDestinationData,
        sourceDataInitial,
        destinationDataInitial,
        sourceHeading,
        sourceHeadingInfoText,
        destinationHeading,
        destinationHeadingInfoText,
        showResetButton,
        sourceWidth,
        sourceHeight,
        destinationWidth,
        destinationHeight,
        setRemovedUsers,
        disableDragAndDrop,
        dndUniqueID = "dndID",
        disableDestinationItems = [],
        setIsDataChanged
    } = props;

    const [selectedList, setSelectedList] = useState<any>([]);

    const handleReset = () => {
        setSourceData(sourceDataInitial);
        setDestinationData(destinationDataInitial);
    };

    const handleDropChange = (
        destinationList: IDragAndDropData[],
        sourceList: IDragAndDropData[],
        usersRemoved?: IDragAndDropData[]
    ) => {
        const uniqueDestinationList = destinationList.filter(
            (each: any, index: any) => destinationList.findIndex((u: any) => each.value === u.value) === index
        );
        const uniqueSourceList = sourceList.filter(
            (each: any, index: any) => sourceList.findIndex((u: any) => each.value === u.value) === index
        );
        setDestinationData(uniqueDestinationList);
        setSourceData(uniqueSourceList);
        if (usersRemoved != undefined && setRemovedUsers) {
            setRemovedUsers(usersRemoved);
        }
        setSelectedList([]);
        setIsDataChanged && setIsDataChanged(true);
    };

    return (
        <section className={styles.dragAndDropContainer}>
            <Source
                destinationData={destinationData}
                sourceData={sourceData}
                handleDropChange={handleDropChange}
                sourceHeading={sourceHeading}
                sourceHeadingInfoText={sourceHeadingInfoText}
                sourceWidth={sourceWidth}
                sourceHeight={sourceHeight}
                dndUniqueID={dndUniqueID}
                selectedList={selectedList}
                setSelectedList={setSelectedList}
                disableDragAndDrop={disableDragAndDrop}
            />
            <Destination
                destinationData={destinationData}
                sourceData={sourceData}
                handleDropChange={handleDropChange}
                destinationHeading={destinationHeading}
                destinationHeadingInfoText={destinationHeadingInfoText}
                destinationWidth={destinationWidth}
                destinationHeight={destinationHeight}
                dndUniqueID={dndUniqueID}
                selectedList={selectedList}
                setSelectedList={setSelectedList}
                disableDragAndDrop={disableDragAndDrop}
                disableDestinationItems={disableDestinationItems}
            />
            {showResetButton && (
                <button className={styles.resetButton} onClick={handleReset}>
                    <span className={styles.resetIcon}>
                        <ResetIcon />
                    </span>
                    <span className={styles.resetText}>Reset</span>
                </button>
            )}
        </section>
    );
}

export default DragAndDrop;
