import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Modal, Alert, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { IWelcomeMessage, initialWelcomeMessage } from '../../../../Core/ViewModels/CompanySettings/ClientInstructionViewModel';
import { VenusNotifier } from '../../../helper/VenusNotifier';
import * as Constants from '../../../helper/Constants';
import { OverlayLoader } from '../../../helper/OverlayLoader';
import { IWelcomeMessageDictionary } from '../../../../store/settings/WelcomeMessageStore';
import { VariableList } from '../../ClientInstructions/VariableList';
import { getTextLengthInByte  } from '../../../helper/HelperFunctions';

interface IAddWelcomeInstructionProps {
    showState: boolean;
    onHide: () => void;
    submitButtonClick: (messageDetail: IWelcomeMessage, isSetDefault: boolean, isToggled: boolean) => void;
    buttonText: string;
    selectedMessageDetail: IWelcomeMessage;
    title: string;
    defaultMessageId: number;
    showLoader: boolean;
    loaderText: string;
    isUpdate: boolean;
    welcomeMessages: IWelcomeMessageDictionary;
}

interface IAddWelcomeInstructionState {
    welcomeMessageDetail: IWelcomeMessage;
    alert: string;
    message: string;
    isSetDefault: boolean;
    isToggled: boolean;
    showAlert: boolean;
}

export class WelcomeMessagePopUp extends React.Component<IAddWelcomeInstructionProps, IAddWelcomeInstructionState>{
    state: IAddWelcomeInstructionState = {
        welcomeMessageDetail: initialWelcomeMessage,
        alert: "warning",
        message: "Welcome Message",
        isSetDefault: false,
        isToggled: false,
        showAlert: true
    }

    UNSAFE_componentWillReceiveProps(nextProps: IAddWelcomeInstructionProps) {
        this.setState({
            welcomeMessageDetail: Object.assign({}, nextProps.selectedMessageDetail),
            alert: "warning",
            message: "Welcome Message",
            isSetDefault: false,
            isToggled: false,
            showAlert: true
        })
    }

    private onCancelButtonClick = () => {
        this.props.onHide()
        this.setState({
            welcomeMessageDetail: initialWelcomeMessage,
            alert: "warning",
            message: "Welcome Message",
            isSetDefault: false,
            isToggled: false,
            showAlert: true
        })
    }

    private onAddButtonClick = () => {
        if (this.state.welcomeMessageDetail.name.trim() == "") {
            this.setState({ alert: "danger", message: Constants.ClientInstructionConstants.ValidateName, showAlert: false })
            return false;
        }
        else if (this.state.welcomeMessageDetail.header.trim() == "") {
            this.setState({ alert: "danger", message: Constants.ClientInstructionConstants.ValidateSubject, showAlert: false })
            return false;
        }
        else if (this.state.welcomeMessageDetail.text.trim() == "") {
            this.setState({ alert: "danger", message: Constants.ClientInstructionConstants.ValidateText, showAlert: false })
            return false;
        }
        if (this.checkDuplicateExistance(this.state.welcomeMessageDetail) > 0) {
            this.setState({ alert: "danger", message: Constants.SavedMessageConstants.SaveMessageNameError, showAlert: false })
            return false;
        }
        var byteLength = getTextLengthInByte(this.state.welcomeMessageDetail.text.trim());
        if (byteLength > Constants.ClientInstructionConstants.MaximumTextSize) {
            this.setState({ alert: "danger", message: Constants.ClientInstructionConstants.TextSizeExceedsLimit, showAlert: false })
            return false;
        }
        this.props.submitButtonClick(this.state.welcomeMessageDetail, this.state.isSetDefault, this.state.isToggled)
        this.setState({ welcomeMessageDetail: initialWelcomeMessage })
    }

    private checkDuplicateExistance = (messageDetail: IWelcomeMessage) => {
        let count: number = 0;
        Object.keys(this.props.welcomeMessages).map((key, index) => {
            if (this.props.welcomeMessages[parseInt(key)].welcomeMessage.name.trim().toLowerCase() == messageDetail.name.trim().toLowerCase() && this.props.welcomeMessages[parseInt(key)].welcomeMessage.id != messageDetail.id)
                count++;
        })
        return count;
    }

    private onNameChange = (event: any) => {
        let _welcomeMessage = Object.assign({}, this.state.welcomeMessageDetail);
        _welcomeMessage.name = event.target.value;
        this.setState({ welcomeMessageDetail: _welcomeMessage })
    }

    private onTextChange = (event: any) => {
        let _welcomeMessage = Object.assign({}, this.state.welcomeMessageDetail);
        _welcomeMessage.text = event.target.value;
        this.setState({ welcomeMessageDetail: _welcomeMessage })
    }

    private onHeaderChange = (event: any) => {
        let _welcomeMessage = Object.assign({}, this.state.welcomeMessageDetail);
        _welcomeMessage.header = event.target.value;
        this.setState({ welcomeMessageDetail: _welcomeMessage })
    }

    private onSetDefaultClick = (isTrue: boolean) => {
        if (isTrue == true) {
            this.setState({ alert: "warning", message: Constants.ClientInstructionConstants.SetDefaultMessageWarning, showAlert: false })
        }
        else {
            this.setState({ alert: "warning", message: Constants.ClientInstructionConstants.RemoveDefaultMessageWarning, showAlert: false })
        }
        this.setState({
            isSetDefault: isTrue,
            isToggled: true
        })
    }

    public render() {
        let toggleState: boolean = this.props.defaultMessageId == this.props.selectedMessageDetail?.id && this.props.isUpdate == true && this.state.isToggled == false ?
            true : this.state.isSetDefault == true ? true : false
        return (
            <div style={{ position: 'relative' }}>
                <Modal className="add-edit-message" show={this.props.showState} onHide={this.onCancelButtonClick}>
                    <OverlayLoader show={this.props.showLoader} text={this.props.loaderText} />
                    <Modal.Header closeButton>
                        <Modal.Title className='custom-modal-header'>
                            <span
                                className='fas fa-envelope'
                                style={{ color: 'grey', marginRight: '5px' }}>
                            </span>{this.props.title}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Alert variant={this.state.alert} hidden={this.state.showAlert} ><i className='fas fa-exclamation-triangle' style={{ marginRight: '5px' }}>
                        </i>{this.state.message}</Alert>
                        <div className="form-group">
                            <label>Name</label>
                            <div className="float-right">
                                <label style={{ marginTop: 8, marginRight: 10 }}>Default</label>
                                <label className="switch">
                                    <input type="checkbox"
                                        checked={toggleState}
                                        disabled={this.props.defaultMessageId == this.props.selectedMessageDetail?.id && this.props.isUpdate == true ? true : false}
                                        onChange={(event) => this.onSetDefaultClick(event.target.checked)}
                                    ></input>
                                    <span className="slider round"></span>
                                </label>
                            </div>
                            <input type="text"
                                data-test-auto="48512DDC-6D9D-4CD6-9C9C-170BFD4D0B6C"
                                className="form-control"
                                placeholder="Instruction name"
                                maxLength={200}
                                value={this.state.welcomeMessageDetail.name}
                                onChange={this.onNameChange}
                                data-lpignore="true">
                            </input>
                        </div>
                        <div className="form-group">
                            <label>Subject</label>
                            <input
                                data-test-auto="45AD0222-B5E7-4AFB-8ED0-D02EF00A0221"
                                className="form-control"
                                placeholder="Subject"
                                maxLength={200}
                                value={this.state.welcomeMessageDetail.header}
                                onChange={this.onHeaderChange}
                            ></input>
                        </div>
                        <div className="form-group">
                            <label>Text</label>
                            <textarea
                                data-test-auto="AE81E9CB-BC6B-4529-A17A-EFFED58E060D"
                                className="form-control"
                                placeholder="Please enter instruction text"
                                rows={5}
                                value={this.state.welcomeMessageDetail.text}
                                onChange={this.onTextChange}
                            />
                        </div>
                        <VariableList />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            data-test-auto="651EF924-C2F9-41E7-9421-C891E64019FB"
                            variant='default'
                            onClick={this.onCancelButtonClick} >
                            <i className='fas fa-times'></i>Cancel
                        </Button>
                        <Button
                            variant='info'
                            onClick={this.onAddButtonClick}
                            data-test-auto="7539FCBB-EEEC-4A6F-ABBC-2FB42159304C">
                            <i className='fas fa-save'></i>{this.props.buttonText}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}