import * as React from 'react'
import {
    BootstrapTable, TableHeaderColumn, TextFilter,
    SelectFilter, CustomSelectProps
} from 'react-bootstrap-table';
import { Loader, LoadingOverlay } from 'react-overlay-loader';
import { CheckBoxComponent, CheckBoxSize } from "./../../../common/CheckBoxComponent";
import { IK1PartnerViewModel, K1ReportStatus } from './../../../../store/reports/K1DeliveredStatusReport/K1DeliveredStatusState'
import { formatEIN, formatSSN, formatSSNWithAsterisks, formatEINWithAsterisks } from '../../../helper/HelperFunctions'; 
import { EntityType } from '../../../common/TaxReturn';

interface K1PartnersTableProps {
    isLoading: boolean;
    k1Partners: IK1PartnerViewModel;
    pageSize: number;
    pageNo: number;
    selectedRows: number[];
    onPageChange: (page: number, sizePerPage: number) => void;
    onSortChange: any;
    onSearchChange: (searchString: string) => void;
    onFilterChange: (dataField: any) => void;
    onRowSelect: (row: any, isSelected: any, e: any) => void;
    onSelectAll: (isSelected: any, rows: any, e: any) => void;
}
interface K1PartnersTableState {

}

export default class K1PartnersTable extends React.Component<K1PartnersTableProps, K1PartnersTableState>{
    private filterChanged: boolean = false;
    private createCustomCheckbox = (props: CustomSelectProps): any => {
        return (<CheckBoxComponent size={CheckBoxSize.sm}
            id={"k1-partner-checkbox-" + props.rowIndex}
            indeterminate={props.indeterminate}
            checked={props.checked}
            disabled={props.disabled}
            onChange={(e: any) => props.onChange(e, props.rowIndex)}
            ref={(input: any) => {
                if (input) {
                    input.indeterminate = props.indeterminate;
                }
            }}
            text={""} />);
    }
    private renderShowsTotal = (start: number, to: number, total: number) => {
        return (
            <p>
                Showing {start} to {to} of {total} entries
            </p>
        );
    }
    private defaultType = (cell: any, row: any) => {
        return <span title={cell} className="ellipsis" style={{ display:"inline-block" }}>{cell}</span>;
    }

    private ssnEinDisplay = (cell: any, row: any) => {
        if (row.entityType === "Individual" || row.entityType === "None") {
            cell = !row.isSSNMaskingEnabled ? formatSSN(cell) : formatSSNWithAsterisks(cell);
        }
        else {
            cell = !row.isSSNMaskingEnabled ? formatEIN(cell) : formatEINWithAsterisks(cell);
        }
        return <span title={cell} className="ellipsis" style={{ display: "inline-block" }}>{cell}</span>;
    }
 
    private StatusOptions = {
        1 : 'Updated',
        2 : 'Confirmed',
        3 : 'Pending'
    }
    private columns = [
        {
            header: '',
            key: 'index',
            isKey: true,
            dataFormat: this.defaultType,
            columnClassName: '',
            dataSort: true,
            toolTip: false,
            isHidden: true,
            width: 'auto',
            filter: { type: 'TextFilter', placeholder: 'index', style: { font: 'bold' } } as TextFilter

        },
        {
            header: 'Partner',
            key: 'partnerName',
            isKey: false,
            dataFormat: this.defaultType,
            columnClassName: '',
            dataSort: true,
            toolTip: false,
            isHidden: false,
            width: 'auto',
            filter: { type: 'TextFilter', placeholder: 'Partner', style: { font: 'bold' } } as TextFilter
        },
        {
            header: 'SSN / EIN',
            key: 'PIN',
            isKey: false,
            dataFormat: this.ssnEinDisplay,
            columnClassName: '',
            dataSort: true,
            toolTip: false,
            isHidden: false,
            width: 'auto',
            filter: { type: 'TextFilter', placeholder: 'SSN / EIN', style: { font: 'bold' } } as TextFilter
        },
        {
            header: 'Address',
            key: 'address',
            isKey: false,
            dataFormat: this.defaultType,
            columnClassName: '',
            dataSort: true,
            toolTip: false,
            isHidden: false,
            width: 'auto',
            filter: { type: 'TextFilter', placeholder: 'Address', style: { font: 'bold' } } as TextFilter
        },
        {
            header: 'Email',
            key: 'email',
            isKey: false,
            dataFormat: this.defaultType,
            columnClassName: '',
            dataSort: true,
            toolTip: false,
            isHidden: false,
            width: 'auto',
            filter: { type: 'TextFilter', placeholder: 'Email', style: { font: 'bold' } } as TextFilter
        },
        {
            header: 'Status',
            key: 'status',
            isKey: false,
            dataFormat: this.defaultType,
            columnClassName: '',
            dataSort: false,
            toolTip: false,
            isHidden: false,
            width: 'auto',
            filter: { type: 'SelectFilter', placeholder: 'Status', options: this.StatusOptions } as SelectFilter
        }
    ];
    private onFilterChange = (dataField: any) => {
        if (!this.filterChanged) {
            this.filterChanged = true;
            this.props.onFilterChange(dataField);
            this.filterChanged = false;
        }
    }
    private setNoContent = () => {
        if (this.props.isLoading) {
            return (<LoadingOverlay style={{ height: '30vh' }}>
                <Loader loading={this.props.isLoading} />
            </LoadingOverlay>)
        } else {
            return "";
        }
    }
   
    render() {
        
        const options = {
            onSortChange: this.props.onSortChange,
            onPageChange: this.props.onPageChange,
            sizePerPage: this.props.pageSize,
            onSearchChange: this.props.onSearchChange,
            page: this.props.pageNo,
            paginationShowsTotal: this.renderShowsTotal,
            onRowDoubleClick: function (row: any) {
            },
            onFilterChange: this.onFilterChange,
            noDataText: this.setNoContent(),
            clearSearch: true,
            nextPage: <span className="fa fa-angle-right" />,
            prePage: <span className="fa fa-angle-left" />,
            firstPage: <span className="fa fa-angle-double-left" />,
            lastPage: <span className="fa fa-angle-double-right" />
        };

        const data = this.props.k1Partners.k1Partners.map((model, index) => {
            return {
                partnerName: model.name,
                PIN: model.PIN,
                address: model.address,
                email: model.email,
                entityType: EntityType[model.entityType],
                status: K1ReportStatus[model.k1Status],
                rowIndex: index,
                index: ((this.props.pageNo - 1) * this.props.pageSize) + index,
                isSSNMaskingEnabled: model.isSSNMaskingEnabled
            }
        });

        let selected: number[] = [];
        if (this.props.selectedRows.length > 0) {
            for (var i in this.props.selectedRows) {
                let rowIndex = this.props.selectedRows[i];
                selected.push(((this.props.pageNo - 1) * this.props.pageSize) + rowIndex);
            }
        }

        let selectRowProp: any =
        {
            mode: 'checkbox',
            clickToSelect: true,
            onSelect: this.props.onRowSelect,
            onSelectAll: this.props.onSelectAll,
            selected: selected,
            customComponent: this.createCustomCheckbox,
            className: 'row-selected'
        };
        return (
            <div>
                <BootstrapTable
                    ref={'K1PartnerTable'}
                    data={data}
                    remote={true}
                    fetchInfo={{ dataTotalSize: this.props.k1Partners.count }}
                    options={options}
                    striped
                    hover={true}
                    pagination={true}
                    selectRow={selectRowProp}>
                    {this.columns.map((value, index) => {
                        return <TableHeaderColumn
                            key={index}
                            ref={value.key}
                            isKey={value.isKey}
                            dataField={value.key}
                            hidden={value.isHidden}
                            width={value.width}
                            dataFormat={value.dataFormat}
                            columnClassName={value.columnClassName}
                            columnTitle={value.toolTip}
                            filter={value.filter}
                            dataSort={value.dataSort}
                        >{value.header}
                        </TableHeaderColumn>;
                    })}
                </BootstrapTable>
            </div>
        );
    }
}
