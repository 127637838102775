import { forEach } from 'lodash';
import * as React from 'react';
import { getMaskedValue, getUnmaskedValue, getSeparators, maskNumbers } from '../../../components/helper/HelperFunctions';

export interface ISSNMaskProps {
    value: string;
    mask: string;
    placeholder: string;
    disabled?: boolean;
    onChange?: (value: string) => void;
    onBlur?: (value: string) => void;
    className?: string;
}

interface ISSNMaskState {
    actualValue: string;
    maskedValue: string;
}

const MASK_PLACEHOLDER = '_';
const DIGIT = /\d/;
const SSNMASK = /\*/;

export class SSNMaskComponent extends React.Component<ISSNMaskProps, ISSNMaskState> {
    constructor(props: any) {
        super(props);

        this.state = {
            actualValue: "",
            maskedValue: "",
        };
    }

    componentDidMount() {
        this.updateValue(this.props.value, this.props.mask);
    }

    UNSAFE_componentWillReceiveProps(nextProps: ISSNMaskProps) {
        this.updateValue(nextProps.value, nextProps.mask);
    }

    private updateValue(value: string, mask: string) {
        const oldValue = this.state.maskedValue ? this.state.maskedValue : "";
        const newValue = getMaskedValue(value, mask);
        const maskedValue = maskNumbers(newValue);
        if (this.state.maskedValue == null || newValue != oldValue) {
            this.setState({
                actualValue: newValue,
                maskedValue: maskedValue
            });
        }
    }

    private onChange = (event: any) => {
        const _self = this;

        let selectionStart = event.target.selectionStart;
        const target = event.target;

        let newValue = event.target?.value;
        const separators = getSeparators(_self.props.mask);

        let maskedValue = getMaskedValue(newValue, _self.props.mask, true);
        if (maskedValue == "") {
            _self.setState({ actualValue: maskedValue }, () => {
                if (_self.props.onChange) {
                    _self.props.onChange(getUnmaskedValue(maskedValue, this.props.mask));
                }
            });
        }
        else {
            let temp: string[] = _self.state.actualValue.split("");
            forEach(maskedValue, (value, index) => {
                if (!SSNMASK.test(value) && DIGIT.test(value)) {
                    temp[index] = value;
                }
                else if (value == MASK_PLACEHOLDER) {
                    temp[index] = '';
                }
            });

            _self.setState({
                actualValue: temp.join("")
            }, () => {
                if (_self.props.onChange) {
                    _self.props.onChange(getUnmaskedValue(temp.join(""), this.props.mask));
                }
            });
        }

        const isAdded = _self.state.maskedValue && _self.state.maskedValue.length < newValue.length;
        const placeholderIndex = maskedValue.indexOf(MASK_PLACEHOLDER);
        const maskedValueWithoutPlaceholder = maskedValue.substring(0, placeholderIndex);

        if (isAdded && _self.state.maskedValue == maskedValue && separators.every((separator) => { return separator != maskedValue[selectionStart - 1] })) {
            selectionStart -= 1;
        }
        if (isAdded && _self.state.maskedValue != maskedValue && separators.some((separator) => { return separator == maskedValue[selectionStart - 1] })) {
            selectionStart += 1;
        }
        else if (placeholderIndex > -1 && selectionStart > maskedValueWithoutPlaceholder.length) {
            selectionStart = maskedValueWithoutPlaceholder.length;
        }

        maskedValue = maskNumbers(maskedValue);

        _self.setState({
            maskedValue: maskedValue
        }, () => {
            target.setSelectionRange(selectionStart, selectionStart);
        });

    }

    private onBlur = () => {
        if (this.props.onBlur) {
            this.props.onBlur(getUnmaskedValue(this.state.actualValue ? this.state.actualValue : '', this.props.mask));
        }
    }

    public render() {
        return <input
            type="text"
            style={{ fontSize: '12px' }}
            className={this.props.className ? this.props.className + " form-control" : "form-control"}
            value={this.state.maskedValue ? this.state.maskedValue : ""}
            placeholder={this.props.placeholder}
            disabled={this.props.disabled ? true : false}
            onChange={this.onChange}
            onBlur={this.onBlur}
            data-lpignore="true"
        />;
    }
}

export default SSNMaskComponent;
