import * as React from "react";
import { Modal, Button, FormControl, Table, ProgressBar, Alert, Nav } from "react-bootstrap";
import "react-bootstrap-table/css/react-bootstrap-table.css";
import { DocumentUploadDropzoneComponent } from "../DocumentUploadDropzoneComponent";
import { VenusNotifier } from "../../../helper/VenusNotifier";
import * as bootbox from "bootbox";
import { getFileSize, getPdfFilePageCount } from "../../../helper/HelperFunctions";
import { ShowLoader, HideLoader } from "../../../helper/Loader";
import { IParterUserModel, ISSRUserModel } from "../../../../Core/ViewModels/User/UserViewModel";
import {
    UploadFunctions,
    isFileExist,
    GetFileMagicNumber,
    getFileExtension,
    validatePdfFile,
    validateFileSize
} from "@sssuite-js-packages/file-utility";
import { ButtonFormatter } from "./UploadCommonFormatters";
import { SasContainer, UltraTaxReturnUploadData, UploadStatus } from "../../../../Core/ViewModels/Common/UploadDocumentViewModel";
import { UploadtaxReturnConstants } from "../../../helper/Constants";
import * as UploadTaxReturnStore from "../../../../store/common/uploadTaxReturn";
import { ITaxReturn, initialTaxReturnState } from "../../TaxReturn";
import { UploadCorrectedReturnWarning } from "../../../helper/Constants";
import UploadDocumentModalWarning from "../UploadDocumentModalWarning";
import { CommonResources } from "../../../helper/ResourceIdConstants";
import SuiteModal from "src/components/common/SuiteModal";
import {
    IAccessingUsersAndUserGroups,
    ISetAccessData,
    IUserDataFromDB,
    IUserGroupDataFromDB
} from "src/components/common/SetAccessCommonModal/SetAccessCommonModal.model";
import { getInitialListOfDataFromDB } from "src/components/common/SetAccessCommonModal/SetAccessCommonModal.api";
import SetAccessCommonModal from "src/components/common/SetAccessCommonModal";
import { IReturnAccessSettingsModel } from "src/Core/ViewModels/Company/CompanySettingsViewModel";

interface UltraTaxUploadModalProps {
    onShow: boolean;
    onHide(): void;
    defaultERO: number;
    EROs?: IParterUserModel[];
    availableUsers: ISSRUserModel[];
    documentKey: string;
    getUploadLink: (
        url: string,
        callback?: (data?: UploadTaxReturnStore.IUploadTaxReturnState) => void,
        resourceId?: string
    ) => void;
    submitTaxReturn: (url: string, taxData: string, callback: () => void, resourceId?: string) => void;
    uploadConfirmation: (documentKey: string, clientId: string) => void;
    isRecalled?: boolean;
    parentResourceIdentifier?: string;
    ssravailableUsers: ISSRUserModel[];
    returnAccessSettings?: IReturnAccessSettingsModel;
    isGroupedReturn: boolean;
}

interface UltraTaxUploadModalState {
    UltraTaxReturnUploadData: UltraTaxReturnUploadData;
    submitDisable: boolean;
    showUpload: boolean;
    showSetAccess: boolean;
    sasContainer: SasContainer[];
    config: {
        dropzoneSelector: string;
        iconFiletypes: [".pdf"];
        showFiletypeIcon: boolean;
        postUrl: string;
    };
    setAccessAccessingUsers: ISSRUserModel[];
    setAccessCurrentUsers: ISSRUserModel[];
    taxReturns: ITaxReturn;
    availableUsers: IUserDataFromDB[];
    selectedUsers: IUserDataFromDB[];
    availableUserGroups: IUserGroupDataFromDB[];
    selectedUserGroups: IUserGroupDataFromDB[];
}

export class UltraTaxUploadRecalledDocumentModal extends React.Component<UltraTaxUploadModalProps, UltraTaxUploadModalState> {
    constructor(props: UltraTaxUploadModalProps) {
        super(props);
        this.state = {
            UltraTaxReturnUploadData: {
                clientId: "",
                sasGuid: "",
                setAccess: "Everyone",
                clientName: "",
                partnerId: 0,
                documentAccess: { documents: [], userGroups: [], users: [] },
                subDocumentsUploadData: [],
                numberOfPages: 0
            },
            submitDisable: true,
            showUpload: false,
            showSetAccess: false,
            sasContainer: [],
            config: {
                dropzoneSelector: "div.filepicker",
                iconFiletypes: [".pdf"],
                showFiletypeIcon: true,
                postUrl: "/api/"
            },
            setAccessAccessingUsers: [],
            setAccessCurrentUsers: [],
            taxReturns: initialTaxReturnState,
            availableUsers: [],
            selectedUsers: [],
            availableUserGroups: [],
            selectedUserGroups: []
        };
        this.deleteReturn = this.deleteReturn.bind(this);
        this.OnSetAccessHide = this.OnSetAccessHide.bind(this);
        this.onSetAccess = this.onSetAccess.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleUploaderClose = this.handleUploaderClose.bind(this);
        this.validateTaxReturnUploadData = this.validateTaxReturnUploadData.bind(this);

        this.setAcessShow = this.setAcessShow.bind(this);
        this.onSetAccessSave = this.onSetAccessSave.bind(this);
        this.uploadProgressCallback = this.uploadProgressCallback.bind(this);
    }

    UNSAFE_componentWillReceiveProps(nextProps: UltraTaxUploadModalProps) {
        let tempUltraTaxReturnUploadData = this.state.UltraTaxReturnUploadData;
        tempUltraTaxReturnUploadData.partnerId = nextProps.defaultERO;

        this.setState({
            UltraTaxReturnUploadData: tempUltraTaxReturnUploadData
        });
    }

    getUltraTaxDocumentGuid() {
        let tempUltraTaxReturnUploadData = this.state.UltraTaxReturnUploadData;
        tempUltraTaxReturnUploadData.sasGuid = this.props.documentKey;
        this.setState({
            UltraTaxReturnUploadData: tempUltraTaxReturnUploadData
        });
    }

    private onClientIdChange(event: any) {
        let tempUltraTaxReturnUploadData = this.state.UltraTaxReturnUploadData;
        tempUltraTaxReturnUploadData.clientId = event.target.value;
        this.setState({ UltraTaxReturnUploadData: tempUltraTaxReturnUploadData });
    }

    private reset = () => {
        let tempUploadData: SasContainer[] = [];
        let tempUltraTaxReturnUploadData = this.state.UltraTaxReturnUploadData;
        tempUltraTaxReturnUploadData.clientId = "";
        tempUltraTaxReturnUploadData.clientName = "";
        tempUltraTaxReturnUploadData.sasGuid = "";
        tempUltraTaxReturnUploadData.partnerId = this.props.defaultERO;
        tempUltraTaxReturnUploadData.setAccess = "Everyone";
        tempUltraTaxReturnUploadData.documentAccess = {
            documents: [],
            userGroups: [],
            users: []
        };
        tempUltraTaxReturnUploadData.subDocumentsUploadData = [];

        this.state.sasContainer.slice(0, this.state.sasContainer.length);
        this.setState(
            {
                sasContainer: tempUploadData,
                UltraTaxReturnUploadData: tempUltraTaxReturnUploadData,
                taxReturns: initialTaxReturnState,
                submitDisable: true
            },
            () => {
                this.loadSetAccessData();
            }
        );
    };

    private handleClose() {
        this.reset();
        this.props.onHide();
    }

    private handleUploaderClose() {
        let _self = this;
        if (this.state.UltraTaxReturnUploadData.subDocumentsUploadData.length > 0) {
            bootbox.confirm({
                message: UploadtaxReturnConstants.CloseConfirmationMessage,
                buttons: {
                    cancel: {
                        label: '<i class="fas fa-times"></i> Cancel',
                        className: "btn-white btn-default"
                    },
                    confirm: {
                        label: '<i class="fas fa-check"></i> OK',
                        className: "btn-info"
                    }
                },
                callback: (result: boolean) => {
                    if (result) {
                        _self.handleClose();
                    }
                }
            });
        } else {
            _self.handleClose();
        }
    }
    //===========================================================================
    // Set Access Start
    //===========================================================================

    setAcessShow() {
        this.setState({ showSetAccess: true });
    }

    OnSetAccessHide() {
        this.setState({
            showSetAccess: false
        });
    }

    onSetAccess(taxReturn: any, event: any) {
        this.setState({
            showSetAccess: true
        });
    }
    private onSetAccessSave(accessingUsersAndUserGroups: IAccessingUsersAndUserGroups) {
        let uploadingDocument: UltraTaxReturnUploadData = Object.assign({}, this.state.UltraTaxReturnUploadData);

        uploadingDocument.documentAccess = {
            documents: [],
            userGroups: accessingUsersAndUserGroups.selectedUserGroups,
            users: accessingUsersAndUserGroups.selectedUsers
        };

        this.setState({
            showSetAccess: false,
            selectedUsers: this.getSelectedUser(accessingUsersAndUserGroups),
            availableUsers: this.getAvailableUser(accessingUsersAndUserGroups),
            selectedUserGroups: this.getSelectedUserGroups(accessingUsersAndUserGroups),
            availableUserGroups: this.getAvailableUserGroups(accessingUsersAndUserGroups)
        });
    }

    getSelectedUser = (accessingUsersAndUserGroups: IAccessingUsersAndUserGroups) => {
        const allUsers = [...this.state.selectedUsers, ...this.state.availableUsers];
        let selectedUser: IUserDataFromDB[] = [];
        accessingUsersAndUserGroups.selectedUsers.map((userId) => {
            let index = allUsers.findIndex((x) => x.id == userId);
            if (index != -1) {
                selectedUser.push(allUsers[index]);
            }
        });
        return selectedUser;
    };

    getAvailableUser = (accessingUsersAndUserGroups: IAccessingUsersAndUserGroups) => {
        const allUsers = [...this.state.selectedUsers, ...this.state.availableUsers];
        let availableUser: IUserDataFromDB[] = [];
        accessingUsersAndUserGroups.availableUsers.map((userId) => {
            let index = allUsers.findIndex((x) => x.id == userId);
            if (index != -1) {
                availableUser.push(allUsers[index]);
            }
        });
        return availableUser;
    };

    getSelectedUserGroups = (accessingUsersAndUserGroups: IAccessingUsersAndUserGroups) => {
        const allUserGroups = [...this.state.selectedUserGroups, ...this.state.availableUserGroups];
        let selectedUserGroups: IUserGroupDataFromDB[] = [];
        accessingUsersAndUserGroups.selectedUserGroups.map((userGroupId) => {
            let index = allUserGroups.findIndex((x) => x.id == userGroupId);
            if (index != -1) {
                selectedUserGroups.push(allUserGroups[index]);
            }
        });
        return selectedUserGroups;
    };

    getAvailableUserGroups = (accessingUsersAndUserGroups: IAccessingUsersAndUserGroups) => {
        const allUserGroups = [...this.state.selectedUserGroups, ...this.state.availableUserGroups];
        let availableUserGroups: IUserGroupDataFromDB[] = [];
        accessingUsersAndUserGroups.availableUserGroups.map((userGroupId) => {
            let index = allUserGroups.findIndex((x) => x.id == userGroupId);
            if (index != -1) {
                availableUserGroups.push(allUserGroups[index]);
            }
        });
        return availableUserGroups;
    };
    //===========================================================================
    // Set Access End
    //===========================================================================

    OnSubmitTaxreturns = () => {
        if (this.validateTaxReturnUploadData()) {
            ShowLoader();
            let uploadData = { ...this.state.UltraTaxReturnUploadData };
            uploadData.numberOfPages = uploadData.subDocumentsUploadData.map((i) => i.numberOfPages).reduce((a, b) => a + b);
            uploadData.documentAccess = {
                documents: [],
                userGroups: this.state.selectedUserGroups.map((group) => {
                    return group.id;
                }),
                users: this.state.selectedUsers.map((user) => {
                    return user.id;
                })
            };
            const docKey = this.props.documentKey;
            this.props.submitTaxReturn(
                "/api/UltraTaxUpload/SubmitRecallTaxReturnsAync",
                JSON.stringify(uploadData),
                () => {
                    this.props.uploadConfirmation(docKey || "", uploadData.clientId || "");
                    this.UploadConfirmation();
                    this.handleClose();
                    HideLoader();
                },
                this.props.parentResourceIdentifier + CommonResources.UTReSubmitButton
            );
        }
    };

    deleteReturn(row: any) {
        let _self = this;
        bootbox.confirm({
            message: UploadtaxReturnConstants.DeleteFileMessage,
            buttons: {
                cancel: {
                    label: '<i class="fas fa-times"></i> Cancel',
                    className: "btn-white btn-default"
                },
                confirm: {
                    label: '<i class="fas fa-check"></i> OK',
                    className: "btn-info"
                }
            },
            callback: (result: boolean) => {
                if (result) {
                    var _subDocumentData = [..._self.state.UltraTaxReturnUploadData.subDocumentsUploadData];
                    _subDocumentData = _subDocumentData.filter((i) => i.number != row.number);
                    //correct index
                    for (let i = row.number - 1; i < _subDocumentData.length; i++) {
                        _subDocumentData[i].number = i + 1;
                    }
                    let _tempgridData = _subDocumentData;

                    let _uploadData = [..._self.state.sasContainer];
                    _uploadData = _uploadData.filter((i) => i.sasGuid != row.subDocumentGuid);

                    let _tempUploadData = _uploadData;

                    let tempUltraTaxReturnUploadData = _self.state.UltraTaxReturnUploadData;
                    tempUltraTaxReturnUploadData.subDocumentsUploadData = _tempgridData;
                    if (_subDocumentData.length == 0) {
                        _self.setState({ submitDisable: true });
                    }
                    _self.setState({
                        UltraTaxReturnUploadData: tempUltraTaxReturnUploadData,
                        sasContainer: _tempUploadData
                    });
                }
            }
        });
    }

    private validateTaxReturnUploadData = (): boolean => {
        let uploaddata = this.state.UltraTaxReturnUploadData;
        if (uploaddata.subDocumentsUploadData.length < 1) {
            VenusNotifier.Warning("No files Uploaded", "");
            return false;
        }

        if (!uploaddata.clientId || uploaddata.clientId.trim() === "") {
            VenusNotifier.Warning("Please enter client Id to submit", "");
            return false;
        }

        return true;
    };

    private UploadConfirmation() {
        let message: string =
            "<h5 class='marT0 font13'> " +
            UploadtaxReturnConstants.ReturnSubmitSuccess +
            ":<h5> <br/><ul class='Modal-UploadUl'>";
        message = message + "<li>" + this.state.UltraTaxReturnUploadData.clientId + "</li>";
        message = message + "</ul>";
        bootbox.alert({
            message: message,
            buttons: {
                ok: {
                    label: '<i class="fas fa-check"></i> Ok',
                    className: "btn-info"
                }
            },
            callback() {}
        });
    }

    //===========================================================================
    // File Upload Start
    //===========================================================================

    djsConfig = {
        uploadMultiple: true,
        addRemoveLinks: true,
        acceptedFiles: "application/pdf",
        headers: { "Access-Control-Allow-Origin": "*", "x-ms-blob-type": "BlockBlob" },
        previewsContainer: false,
        autoProcessQueue: false,
        autoDiscover: false
    };

    eventHandlers = {
        init: () => {
            this.reset();
            this.getUltraTaxDocumentGuid();
        },

        addedfiles: (files: any) => {
            let _self = this;
            let tempUploadData = this.state.UltraTaxReturnUploadData;
            let tempGridData = tempUploadData.subDocumentsUploadData;
            for (let i = 0; i < files.length; i++) {
                let file = files[i];
                if (validateFileSize(file)) {
                    let fileName = file.name;
                    let fileExtension = getFileExtension(fileName);
                    let uploadHelperFunctions = new UploadFunctions();
                    GetFileMagicNumber(file).then((result) => {
                        if (validatePdfFile(fileExtension, result)) {
                            let filecount = 1;

                            while (isFileExist(fileName, tempGridData)) {
                                fileName = file.name;
                                fileName = fileName.replace("." + fileExtension, "");
                                fileName = fileName + " - Copy (" + filecount + ")." + fileExtension;
                                filecount++;
                            }

                            getPdfFilePageCount(file as File).then((pageCount: number) => {
                                tempGridData.push({
                                    name: fileName,
                                    number: tempGridData.length + 1,
                                    progressBar: 0,
                                    size: getFileSize(file?.size),
                                    status: UploadStatus.Uploading,
                                    subDocumentGuid: "",
                                    numberOfPages: pageCount
                                });

                                _self.setState({ submitDisable: true, UltraTaxReturnUploadData: tempUploadData });

                                this.props.getUploadLink(
                                    "/api/UltraTaxUpload/GetReplacedTaxReturnUploadLinkAsync?documentGuid=" +
                                        this.state.UltraTaxReturnUploadData.sasGuid,
                                    (data?: UploadTaxReturnStore.IUploadTaxReturnState) => {
                                        let index = tempGridData.findIndex((x) => x.name === fileName);

                                        try {
                                            if (data) {
                                                tempGridData[index].subDocumentGuid = data.guid;

                                                _self.setState({ UltraTaxReturnUploadData: tempUploadData }, () =>
                                                    uploadHelperFunctions.uploadFile(
                                                        file,
                                                        data,
                                                        fileName,
                                                        _self.uploadProgressCallback,
                                                        _self.uploadCommittCallBack,
                                                        _self.uploadFailureCallback
                                                    )
                                                );
                                            } else {
                                                tempGridData.splice(index, 1);
                                                _self.setState({
                                                    UltraTaxReturnUploadData: tempUploadData,
                                                    submitDisable: false
                                                });
                                            }
                                        } catch (err) {
                                            tempGridData.splice(index, 1);
                                            _self.setState({ UltraTaxReturnUploadData: tempUploadData, submitDisable: false });
                                        }
                                    },
                                    this.props.parentResourceIdentifier + CommonResources.UTReUploadButton
                                );
                            });
                        }
                    });
                }
            }
        }
    };

    public uploadProgressCallback(percent: number, fileToUpload: any) {
        let tempUploadData = this.state.UltraTaxReturnUploadData;
        let tempGridData = tempUploadData.subDocumentsUploadData;
        tempGridData.map((tempGridDataValue, index) => {
            if (tempGridDataValue.subDocumentGuid == fileToUpload.fileGUID) {
                tempGridDataValue.progressBar = percent - 10;
            }
        });
        tempUploadData.subDocumentsUploadData = tempGridData;
        this.setState({
            UltraTaxReturnUploadData: tempUploadData
        });
    }

    uploadCommittCallBack = (fileToUpload: any) => {
        let tempUploadData = this.state.UltraTaxReturnUploadData;
        if (tempUploadData.subDocumentsUploadData.length > 0) {
            let tempGridData = tempUploadData.subDocumentsUploadData;
            tempGridData.map((tempGridDataValue, index) => {
                if (tempGridDataValue.name == fileToUpload.fileName) {
                    tempGridDataValue.progressBar = 100;
                    tempGridDataValue.status = UploadStatus.Uploaded;
                }
            });
            this.setState(
                {
                    UltraTaxReturnUploadData: tempUploadData
                },
                () => {
                    if (!this.isUploadOnGoing()) {
                        this.setState({ submitDisable: false });
                    }
                }
            );
        }
    };

    //Implementation of this callback will be done later
    uploadFailureCallback = (fileToUpload: any) => {
        console.log("upload failed for file:", fileToUpload.fileName);
    };

    private isUploadOnGoing = (): boolean => {
        let isuploading: boolean = false;
        this.state.UltraTaxReturnUploadData.subDocumentsUploadData.map((value) => {
            if (value.progressBar == undefined ? 0 : value.progressBar < 100) {
                isuploading = true;
                return;
            }
        });
        return isuploading;
    };

    componentDidMount(): void {
        this.loadSetAccessData();
    }

    loadSetAccessData = () => {
        getInitialListOfDataFromDB((setAccessData?: ISetAccessData) => {
            if (!setAccessData) {
                VenusNotifier.Error("Error fetching list of users and user groups", "");
            } else {
                const { availableUsers, selectedUsers, availableUserGroups, selectedUserGroups } = setAccessData;
                this.setState({
                    availableUsers,
                    selectedUsers,
                    availableUserGroups,
                    selectedUserGroups
                });
            }
        }, this.props.isGroupedReturn);
    };

    getSetAccessLable = () => {
        const { selectedUsers, availableUsers, selectedUserGroups, availableUserGroups } = this.state;
        const selectedUserIndex = selectedUsers.findIndex((x) => x.id == 0);
        const selectedUserGroupIndex = selectedUsers.findIndex((x) => x.id == 0);
        if (selectedUserIndex != -1 && selectedUserGroupIndex != -1) {
            return "Everyone";
        } else {
            return "Restricted";
        }
    };

    //===========================================================================
    // File Upload End
    //===========================================================================

    public render() {
        let uploadDataLength = this.state.UltraTaxReturnUploadData.subDocumentsUploadData.length;
        return (
            <div>
                <Modal
                    className="upload-doc-modal"
                    show={this.props.onShow}
                    onHide={this.handleUploaderClose}
                    enforceFocus={false}
                >
                    <Modal.Header closeButton data-test-auto="9DFD99E6-5A5C-4A9A-B40F-975AB2D109B5">
                        <Modal.Title style={{ display: "block" }} className="upload-modal-title">
                            {" "}
                            Upload Documents
                            <UploadDocumentModalWarning
                                warningMessages={[
                                    UploadtaxReturnConstants.PdfDocumentGroupedReturnWarning,
                                    UploadtaxReturnConstants.PdfDocumentUploadWarning
                                ]}
                            />
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-sm-2 text-center">
                                <DocumentUploadDropzoneComponent
                                    componentConfig={this.state.config}
                                    djsConfig={this.djsConfig}
                                    eventHandlers={this.eventHandlers}
                                    autoTestId={"BAA13FF4-F4AD-466C-B03C-F58D3BCBC920"}
                                />
                            </div>
                            <div className="col-sm-10">
                                <div className="row">
                                    <div className="col-sm-1 col-md-1 col-xl-1">
                                        <div className="padT07 txt-ar text-bold">Client&nbsp;ID&nbsp;*</div>
                                    </div>
                                    <div className="col-sm-3 col-md-3 col-xl-3">
                                        <div className="pos_rel">
                                            <FormControl
                                                type="text"
                                                disabled={uploadDataLength < 1}
                                                value={this.state.UltraTaxReturnUploadData.clientId}
                                                onChange={this.onClientIdChange.bind(this)}
                                                data-test-auto="8E584D37-7EF3-4D2A-8CC8-E21FA295F6EB"
                                            />
                                        </div>
                                    </div>

                                    <div className="col-sm-2 col-md-2 col-xl-2">
                                        <div className="padT07 txt-ar text-bold">Set Access</div>
                                    </div>
                                    <div className="col-sm-3 col-md-3 col-xl-3">
                                        <div className="pos_rel">
                                            <Nav.Link
                                                href="javascript:"
                                                onClick={this.onSetAccess.bind(this)}
                                                className="text-underline"
                                                data-test-auto="7FDF51B6-41F6-4E58-9A03-C52D36983AF0"
                                            >
                                                {this.getSetAccessLable()}
                                            </Nav.Link>
                                        </div>
                                    </div>
                                </div>

                                <div className="padT07">
                                    <Table striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th className="w40">#</th>
                                                <th>Included Files</th>
                                                <th>Upload Progress</th>
                                                <th>Size</th>
                                                <th>Status</th>
                                                <th className="w40"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {uploadDataLength > 0 ? (
                                                this.state.UltraTaxReturnUploadData.subDocumentsUploadData.map((value) => {
                                                    return (
                                                        <tr key={value.number}>
                                                            <th>{value.number}</th>
                                                            <th>{value.name}</th>
                                                            <th>
                                                                <ProgressBar
                                                                    striped
                                                                    variant={
                                                                        value.progressBar != undefined && value.progressBar != 100
                                                                            ? "warning"
                                                                            : "success"
                                                                    }
                                                                    now={value.progressBar}
                                                                />
                                                            </th>
                                                            <th>{value.size}</th>
                                                            <th>{UploadStatus[value.status].toString()}</th>
                                                            <th>
                                                                <ButtonFormatter
                                                                    deleteReturn={() => this.deleteReturn(value)}
                                                                    disabled={
                                                                        value.progressBar != undefined && value.progressBar != 100
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    data-test-auto="B07622E7-FEB7-4759-BA19-4DF7D79BE7E9"
                                                                />
                                                            </th>
                                                        </tr>
                                                    );
                                                })
                                            ) : (
                                                <tr>
                                                    <th colSpan={8}>There is no data to display</th>
                                                </tr>
                                            )}
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        {this.props.isRecalled ? (
                            <Alert variant="warning" style={{ width: "100%", textAlign: "left" }}>
                                <i className="fas fa-info-circle"></i>
                                <span style={{ paddingLeft: "5px" }}>{UploadCorrectedReturnWarning.message}</span>
                            </Alert>
                        ) : (
                            ""
                        )}
                        <Button
                            variant="default"
                            onClick={this.handleUploaderClose}
                            data-test-auto="B1C823D3-8D5E-4B9B-9D59-11F3A0D9BEED"
                        >
                            <i className="fas fa-times"></i>
                            Cancel
                        </Button>
                        <Button
                            variant="info"
                            onClick={this.OnSubmitTaxreturns}
                            disabled={this.state.submitDisable || uploadDataLength < 1}
                            data-test-auto="5D0B9B03-68DE-47E7-8681-6EDBD54E005B"
                            data-resource-id={this.props.parentResourceIdentifier + CommonResources.UTReSubmitButton}
                        >
                            <i className="fa fa-check"></i>
                            Submit
                        </Button>
                    </Modal.Footer>
                </Modal>
                {this.state.showSetAccess && (
                    <SuiteModal width="787" theme="light" title="Set Access" hide={this.OnSetAccessHide}>
                        <SetAccessCommonModal
                            onCancel={this.OnSetAccessHide}
                            onApplyAccess={this.onSetAccessSave}
                            returnAccessSettings={this.props.returnAccessSettings}
                            availableUsers={this.state.availableUsers}
                            selectedUsers={this.state.selectedUsers}
                            availableUserGroups={this.state.availableUserGroups}
                            selectedUserGroups={this.state.selectedUserGroups}
                            isDragAndDropDisabled={!this.props.isGroupedReturn}
                        />
                    </SuiteModal>
                )}
            </div>
        );
    }
}
