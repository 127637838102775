import { addTask } from 'domain-task';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../';
import { handleResponse } from '../Library';
import { actionTypes } from '../../types/ActionTypes';
import { PDFJSStatic, PDFDocumentProxy } from 'pdfjs-dist';
import { IPdfDocumentFacade, PdfDocumentFacade, ISubDocument, IFacadeViewModel, IPageMap, SubDocType } from '../../Core/Utilities/PdfDocumentFacade';
import { ITaxReturn } from '../../components/common/TaxReturn';
import { TYPES } from '../../Startup/types';
import { container } from '../../Startup/inversify.config';
import { IFileClient } from '../../Core/Services/FileClient';
import { API_BASE_URL } from '../../utils/constants';
import { generateBase64 } from '../../Core/ViewModels/Common/PdfHelper';

const PDFJS: PDFJSStatic = require('pdfjs-dist');
const pdfjsWorker = require('pdfjs-dist/build/pdf.worker.entry');
(PDFJS as any).GlobalWorkerOptions.workerSrc = pdfjsWorker;

export interface IPdfDocumentDictionary {
    [index: number]: {
        document?: IPdfDocumentFacade,
        isLoading: boolean,
        progress: number,
        error: boolean,
        message: string
    };
}

interface RequestPdfAction {
    type: actionTypes.REQUEST_PDF_OBJECT;
    id: number;
}

interface ReceivePdfAction {
    type: actionTypes.RECEIVE_PDF_OBJECT;
    id: number;
    document: IPdfDocumentFacade;
}

interface ReceiveSubDocPdfAction {
    type: actionTypes.RECEIVE_SUB_DOC_PDF_OBJECT;
    id: number;
    subDoc: ISubDocument
}
interface ReceiveFacadeAction {
    type: actionTypes.RECEIVE_FACADE;
    id: number;
    document: IPdfDocumentFacade;
}

interface ErrorPdfAction {
    type: actionTypes.ERROR_PDF_OBJECT;
    id: number;
    reason: string;
}

export interface ResetPdfAction {
    type: actionTypes.RESET_PDF_OBJECTS;
    id: number
}

type KnownAction =
    RequestPdfAction |
    ReceivePdfAction |
    ReceiveFacadeAction |
    ReceiveSubDocPdfAction |
    ErrorPdfAction |
    ResetPdfAction;

const fileClient = container.get<IFileClient>(TYPES.IFileClient);

export const actionCreators = {

    requestTaxReturnPdf: (taxReturn: ITaxReturn, reload: boolean = false): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let state = getState();
        if (reload ||
            !state.pdfDocuments[taxReturn.id
            ] || !state.pdfDocuments[taxReturn.id].document) {
            let asyncHelper: number = 0;
            const fetchTask = fetch(API_BASE_URL + '/api/Download/GetDocumentFacadeAsync?id=' + taxReturn.id, { credentials: 'include' })
                .then(handleResponse)
                .then(response => response as Promise<IFacadeViewModel>)
                .then(documentFacade => {
                    let document: IPdfDocumentFacade = new PdfDocumentFacade();
                    documentFacade.subDocuments.forEach((doc: ISubDocument, index: number) => {
                        if (!doc.pageMap) {
                            doc.pageMap = [];
                        }
                        document.appendSubDocument(doc);
                    });

                    dispatch({ type: actionTypes.RECEIVE_FACADE, id: taxReturn.id, document: document }); 

                    documentFacade.subDocuments.forEach((doc: ISubDocument, index: number) => {
                        fileClient.download(doc.downloadUrl)
                            .then((data) => {
                                asyncHelper++;
                                const pdfDocument = data as PDFDocumentProxy;
                                if (doc.subDocType == SubDocType.Original && (doc.pageMap == undefined || doc.pageMap.length == 0 || (doc.pageMap.length===1 && doc.pageMap[0].facadePageNum === -1 ))) {
                                    doc.pageMap = [];
                                    doc.pageMap.unshift({
                                        facadePageNum: -1,
                                        subDocPageNum: -1
                                    });
                                    for (var i = 1; i <= pdfDocument.numPages; i++) {
                                        doc.pageMap.push({
                                            subDocPageNum: i,
                                            facadePageNum: i
                                        } as IPageMap);
                                    }
                                }

                                doc.document = pdfDocument;
                                documentFacade.subDocuments[index] = {
                                    ...doc,
                                    document: pdfDocument
                                };

                                //document.appendSubDocument(documentFacade.subDocuments[index]);

                                dispatch({ type: actionTypes.RECEIVE_SUB_DOC_PDF_OBJECT, id: taxReturn.id, subDoc: doc });

                                if (asyncHelper === documentFacade.subDocuments.length) {
                                   // dispatch({ type: actionTypes.RECEIVE_PDF_OBJECT, id: taxReturn.id, document: document });
                                }
                            }, (error: string) => {
                                dispatch({ type: actionTypes.ERROR_PDF_OBJECT, id: taxReturn.id, reason: error });
                            });
                    });
                    //fileClient.download(documentUrl)
                    //    .then((data: any) => {
                    //        let document: IPdfDocumentFacade = new PdfDocumentFacade(data as PDFDocumentProxy, documentUrl);
                    //        dispatch({ type: actionTypes.RECEIVE_PDF_OBJECT, id: taxReturn.id, document: document });
                    //    }, (error: string) => {
                    //        dispatch({ type: actionTypes.ERROR_PDF_OBJECT, id: taxReturn.id, reason: error });
                    //    });
                })
                .catch(error => {
                    dispatch({ type: actionTypes.ERROR_PDF_OBJECT, id: taxReturn.id, reason: error });
                });
            addTask(fetchTask);
            dispatch({ type: actionTypes.REQUEST_PDF_OBJECT, id: taxReturn.id });
        }
    }
};

export const reducer: Reducer<IPdfDocumentDictionary> = (state = {}, incomingAction) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case actionTypes.REQUEST_PDF_OBJECT:
            let cleanState: IPdfDocumentDictionary = { ...state };
            cleanState[action.id] = {
                document: undefined,
                isLoading: true,
                progress:0,
                error: false,
                message: ''
            };
            return cleanState;

        case actionTypes.RECEIVE_PDF_OBJECT:
            let newState: IPdfDocumentDictionary = { ...state };
            let document: IPdfDocumentFacade;
            document = action.document;
            newState[action.id] = {
                document: document,
                isLoading: false,
                progress: 100,
                error: false,
                message: ''
            };
            return newState;
        case actionTypes.RECEIVE_FACADE:
            let facadeState: IPdfDocumentDictionary = { ...state };
            let docFacade: IPdfDocumentFacade;
            docFacade = action.document;
            facadeState[action.id] = {
                document: docFacade,
                isLoading: true,
                progress: 0,
                error: false,
                message: ''
            };
            return facadeState;
        case actionTypes.RECEIVE_SUB_DOC_PDF_OBJECT:
            let subDocState: IPdfDocumentDictionary = { ...state };
            let subDoc: ISubDocument;
            subDoc = action.subDoc;
            let facade = subDocState[action.id].document;
            let progrss = 0;
            if (facade) {
                let subDocuments = facade.getSubDocuments();
                if (subDocuments) {
                    const subDocIndex = subDocuments.findIndex(x => (x.path + x.fileName) == (subDoc.path + subDoc.fileName));
                    subDocuments[subDocIndex] = subDoc;
                    progrss = facade.getProgress();
                }
            }
            subDocState[action.id] = {
                document: facade,
                isLoading: true,
                progress: progrss,
                error: false,
                message: ''
            };
            return subDocState;
        case actionTypes.ERROR_PDF_OBJECT:
            let errorState: IPdfDocumentDictionary = { ...state };
            errorState[action.id] = {
                document: undefined,
                isLoading: false,
                progress:0,
                error: true,
                message: action.reason
            };
            return errorState;
        case actionTypes.RESET_PDF_OBJECTS:
            let resetState: IPdfDocumentDictionary = { ...state };
            resetState[action.id] = {
                document: undefined,
                isLoading: false,
                progress: 0,
                error: false,
                message: ''
            };
            return resetState;
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            const exhaustiveCheck: never = action;
    }

    return state;
};
