import { connect } from 'react-redux';
import * as  ClientMessageStore from '../../../store/settings/ClientMessageStore';
import * as  EmailMessageStore from '../../../store/settings/EmailMessageStore';
import * as Component from '../ClientInstruction';
import { ApplicationState } from '../../../store';
import * as CompanyStore from '../../../store/company/CompanyStore';
import * as UserSettingsStore from '../../../store/userManagement/UserSettingStore';
import * as ApplicationHelptextStore from '../../../store/common/ApplicationHelptextStore';
import * as ReminderStore from '../../../store/common/ReminderStore';
import * as SSRSettingsStore from '../../../store/company/settings/SSRSettingsStore';

export default connect(
    (state: ApplicationState) => ({
        clientMessageList: state.clientMessage,
        emailMessageList: state.emailMessage,
        companyData: state.companyData,
        helptexts: state.Helptexts,
    }),
    {
        ...ClientMessageStore.ActionCreators,
        ...EmailMessageStore.ActionCreators,
        ...CompanyStore.actionCreators,
        ...UserSettingsStore.actionCreators,
        ...ApplicationHelptextStore.actionCreators,
        ...ReminderStore.actionCreators,
        ...SSRSettingsStore.actionCreators
    }
)(Component.ClientInstruction as any);