import { addTask } from 'domain-task';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../../';
import { actionTypes } from '../../../types/ActionTypes';
import {
	SendGroupedReturnsTableModel,
	SendGroupedReturnsState,
	SendGroupedReturnsDictionary,
	GroupedReturnsDocuments
} from './SendGroupedReturnsState';
import { handleResponse } from '../../Library';
import {
	RequestSendGroupedReturnsPagesAction,
	ReceiveSendGroupedReturnsPagesAction
} from './SendGroupedReturnsPagesStore';
import { ITaxReturn, DocumentStatus } from '../../../components/common/TaxReturn';
import { StatusType, NotificationAction } from '../../common/NotificationStore';
import { ReceiveGroupedTaxDocumentAction, MarkAsReadyForDeliveryAction, UpdateSendGroupedReturnsDocumentStatusUpdate, UpdateSendGroupedReturnsDocumentAssignUpdate } from '../../groupedreturns/GroupedTaxDocumentStore';
import * as Constants from '../../../components/helper/Constants';
import { NO_INDEX } from '../../../components/common/GroupLevelReturnProcess/GroupProcessReturnModels';
import { IReportProblemDetails } from '../../../components/common/ReportProblem/ReportProblemModel';
import { API_BASE_URL } from '../../../utils/constants';
import * as CRSHelper from '../../../components/helper/CRSHelper' 
export interface RequestGroupedReturnsDocumentsAction {
	type: actionTypes.REQUEST_GROUPED_RETURNS_DOCUMENTS;
	groupId: number;
}
import { encodeTaxDocumentHtmlTags } from '../../../components/helper/HelperFunctions';


export interface ReceiveGroupedReturnsDocumentsAction {
	type: actionTypes.RECEIVE_GROUPED_RETURNS_DOCUMENTS;
	data: ITaxReturn[];
	groupId: number;
}

export interface RequestSendGroupedReturnsAction {
	type: actionTypes.REQUEST_SEND_GROUPED_RETURNS;
	query: string;
}

export interface ReceiveSendGroupedReturnsAction {
	type: actionTypes.RECEIVE_SEND_GROUPED_RETURNS;
	query: string;
	table: SendGroupedReturnsTableModel;
}

export interface ReceiveSelectedDocumentForDeliveryAction {
	type: actionTypes.RECEIVE_SELECTED_DOCUMENT_FOR_DELIVERY;
	DocumentIds: number[]
}

export interface RemoveSelectedDocumentForDeliveryAction {
	type: actionTypes.REMOVE_SELECTED_DOCUMENT_FOR_DELIVERY;
	DocumentIds: number[]
}

export interface ResetSendGroupedReturnsAction {
	type: actionTypes.RESET_SEND_GROUPED_RETURNS;
}


const unloadedState: SendGroupedReturnsState = {
	sendGroupedReturnsTableModel: {
		sendGroupedReturns: [],
		count: 0
	} as SendGroupedReturnsTableModel,
	loading: true,
	query: '',
	totalRowCount: 0,
	error: false
} as SendGroupedReturnsState;

type KnownAction = RequestSendGroupedReturnsAction
	| ReceiveSendGroupedReturnsAction
	| RequestSendGroupedReturnsPagesAction
	| ReceiveSendGroupedReturnsPagesAction
	| RequestGroupedReturnsDocumentsAction
	| ReceiveGroupedReturnsDocumentsAction
	| NotificationAction
	| ReceiveGroupedTaxDocumentAction
	| ReceiveSelectedDocumentForDeliveryAction
	| RemoveSelectedDocumentForDeliveryAction
	| ResetSendGroupedReturnsAction;

type DispatchAction = RequestSendGroupedReturnsAction
	| ReceiveSendGroupedReturnsAction
	| RequestGroupedReturnsDocumentsAction
	| ReceiveGroupedReturnsDocumentsAction
	| ReceiveGroupedTaxDocumentAction
	| ReceiveSelectedDocumentForDeliveryAction
	| RemoveSelectedDocumentForDeliveryAction
	| MarkAsReadyForDeliveryAction
	| ResetSendGroupedReturnsAction
    | UpdateSendGroupedReturnsDocumentStatusUpdate
    | UpdateSendGroupedReturnsDocumentAssignUpdate;

export const actionCreators = {
	requestSendGroupedReturns: (query: string, reload: boolean = false): AppThunkAction<KnownAction> => (dispatch, getState) => {
		// Only load data if it's something we don't already have (and are not already loading)
		let state = getState();
		if (reload || query !== state.sendGroupedReturns.query) {

			let page = state.sendGroupedReturnsPages[query];
			if (!reload && page) {
				dispatch({
					type: actionTypes.RECEIVE_SEND_GROUPED_RETURNS,
					query: query,
					table: page.sendGroupedReturnsTableModel
				});

				return;
			}

			const fetchTask = fetch(API_BASE_URL + 'api/SendGroupedReturns/GetSendGroupedReturnsAsync' + query, {
				method: 'GET',
				credentials: 'include'
			})
				.then(handleResponse)
				.then(response => response as Promise<SendGroupedReturnsTableModel>)
				.then(data => {
					dispatch({ type: actionTypes.RECEIVE_SEND_GROUPED_RETURNS, query: query, table: data });
					dispatch({ type: actionTypes.RECEIVE_SEND_GROUPED_RETURNS_PAGES, query: query, table: data, totalRowCount: data.count });
				})
				.catch((error) => {
					const statusMessage: any = error.statusText?.message ?? error.statusText;
                    if (typeof(statusMessage) === "string") {
						dispatch({ type: actionTypes.NOTIFICATION, statusMessage: statusMessage, statusType: StatusType.Error })
					}
				});
			addTask(fetchTask);
			dispatch({ type: actionTypes.REQUEST_SEND_GROUPED_RETURNS, query: query });
			dispatch({ type: actionTypes.REQUEST_SEND_GROUPED_RETURNS_PAGES, query: query });
		}
	},
	requestGroupedReturnDocuments: (groupId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
		// Only load data if it's something we don't already have (and are not already loading)
		let state = getState();


		const fetchTask = fetch(API_BASE_URL + 'api/SendGroupedReturns/GetGroupedReturnsDocumentsQueryAsync?groupId=' + groupId, {
			method: 'GET',
			credentials: 'include'
		})
			.then(handleResponse)
			.then(response => response as Promise<ITaxReturn[]>)
			.then(data => {
				dispatch({ type: actionTypes.RECEIVE_GROUPED_RETURNS_DOCUMENTS, groupId: groupId, data: data });
			})
			.catch((error) => {
				const statusMessage: any = error.statusText?.message ?? error.statusText;
                if (typeof(statusMessage) === "string") {
					dispatch({ type: actionTypes.NOTIFICATION, statusMessage: statusMessage, statusType: StatusType.Error })
				}
			});
		addTask(fetchTask);
		dispatch({ type: actionTypes.REQUEST_GROUPED_RETURNS_DOCUMENTS, groupId: groupId });
	},
	exportGroupedReturnsAsExcel: (query: string, callback?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
		const fetchTask = fetch(API_BASE_URL + 'api/ExportToExcel/ExportExcelSendGroupedReturnsAsync' + query, { credentials: 'include' })
			.then(response => response.blob())
			.then(blob => {
				const url = window.URL.createObjectURL(new Blob([blob]));
				const link = document.createElement('a');
				link.href = url;

				const fileName = 'GroupedReturns.xlsx';
				link.setAttribute('download', fileName);
				link.target = '_blank';
				document.body.appendChild(link);
				link.click();

				let parentNode = link.parentNode;

				if (parentNode) {
					parentNode.removeChild(link);
				}

				if (callback) {
					callback();
				}
			})
			.catch((error) => {
				const statusMessage: any = error.statusText?.message ?? error.statusText;
                if (typeof(statusMessage) === "string") {
					dispatch({ type: actionTypes.NOTIFICATION, statusMessage: statusMessage, statusType: StatusType.Error });
				}
				if (callback) {
					callback();
				}
			});
		addTask(fetchTask);
	},
	ChangeStatusGroupedTaxDocument: (taxReturn: ITaxReturn,callback?: () => void,resourceId: string= ""): AppThunkAction<KnownAction> => (dispatch, getState) => {
		encodeTaxDocumentHtmlTags(taxReturn);
		const formData = new FormData();
		let taxReturnData = JSON.stringify(taxReturn);
		formData.append('taxDocument', taxReturnData);
		let options: any = {
			method: 'PUT',
			credentials: 'include',
			headers: {
                'Accept': 'application/json',
                'X-Resource-Id': resourceId,
				'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
			},
			body: formData
		};
		const fetchTask = fetch(API_BASE_URL + 'api/TaxDocument/UpdateChangeStatus', options)
			.then(handleResponse)
			.then(() => {
				dispatch({ type: actionTypes.RECEIVE_GROUPED_TAX_DOCUMENT, id: taxReturn.id, taxDocument: taxReturn, isEditClientInfoRequest: false });
				if (callback) {
					callback();
				}
			})
			.catch(error => {
				const statusMessage: any = error.statusText?.message ?? error.statusText;
                if (typeof(statusMessage) === "string") {
					dispatch({ type: actionTypes.NOTIFICATION, statusMessage: statusMessage, statusType: StatusType.Error })
				}
			});
		addTask(fetchTask);
	},

	requestSelectedDocumentsForDelivery: (groupId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
		const fetchTask = fetch(API_BASE_URL + 'api/GroupedReturns/GetSelectedForDeliveryDocumentListAsync/' + groupId, {
			method: 'GET',
			credentials: 'include'
		})
			.then(response => response.json() as Promise<number[]>)
			.then(data => {
				dispatch({ type: actionTypes.RECEIVE_SELECTED_DOCUMENT_FOR_DELIVERY, DocumentIds: data });
			})
			.catch(function (error) {
				const statusMessage: any = error.statusText;
                if (typeof(statusMessage) === "string") {
					dispatch({ type: actionTypes.NOTIFICATION, statusMessage: statusMessage, statusType: StatusType.Error })
				}
			});
		addTask(fetchTask);
	},

	selectForDelivery: (documentId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
		dispatch({ type: actionTypes.RECEIVE_SELECTED_DOCUMENT_FOR_DELIVERY, DocumentIds: [documentId] });
	},

	selectAllForDelivery: (documentIds: number[]): AppThunkAction<KnownAction> => (dispatch, getState) => {
		dispatch({ type: actionTypes.RECEIVE_SELECTED_DOCUMENT_FOR_DELIVERY, DocumentIds: documentIds});
	},

	unselectForDelivery: (documentId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
		dispatch({ type: actionTypes.REMOVE_SELECTED_DOCUMENT_FOR_DELIVERY, DocumentIds: [documentId] });
	},
	unselectAllForDelivery: (documentIds: number[]): AppThunkAction<KnownAction> => (dispatch, getState) => {
		dispatch({ type: actionTypes.REMOVE_SELECTED_DOCUMENT_FOR_DELIVERY, DocumentIds: documentIds });
	},
	resetSendGroupedReturns: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
		dispatch({ type: actionTypes.RESET_SEND_GROUPED_RETURNS });
    },
    reportDocumentGroupProblem: (problemDetails: IReportProblemDetails, groupId: number, 
		successCallback?: () => void,resourceId:string=""): AppThunkAction<KnownAction> => (dispatch, getState) => {
        var url = API_BASE_URL + "api/SupportGroupIssue/SaveTaxReturnReportProblem/" + groupId;
        const fetchTask = fetch(url, {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(problemDetails),
            headers: {
                'Accept': 'application/json, text/plain, */*',
				'Content-Type': 'application/json; charset=utf-8',
				'X-Resource-Id':resourceId,
				'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
            }
        })
            .then(handleResponse)
            .then((response) => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: Constants.ReportProblemConstants.SuccessMessage + response,
                    statusType: StatusType.Success
                });
                if (successCallback) {
                    successCallback();
                }
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: Constants.ReportProblemConstants.ErrorMessage,
                    statusType: StatusType.Error
                });
            });
        addTask(fetchTask);
    }
}

export const reducer: Reducer<SendGroupedReturnsState> = (state: SendGroupedReturnsState = unloadedState, incomingAction: Action) => {
	const action = incomingAction as DispatchAction;
	let index = -1;
	let returnIndex = -1;
	switch (action.type) {
		case actionTypes.REQUEST_SEND_GROUPED_RETURNS:
			return ({
				...unloadedState,
				query: action.query,
				loading: true
			}) as SendGroupedReturnsState;
		case actionTypes.RECEIVE_SEND_GROUPED_RETURNS:
			return {
				query: action.query,
				sendGroupedReturnsTableModel: action.table,
				totalRowCount: action.table.count,
				loading: false
			} as SendGroupedReturnsState;
		case actionTypes.REQUEST_GROUPED_RETURNS_DOCUMENTS:
			index = state.sendGroupedReturnsTableModel.sendGroupedReturns.findIndex(x => x.groupId === action.groupId);
			if (index != -1) {
				state.sendGroupedReturnsTableModel.sendGroupedReturns[index].taxReturns = [];
			}
			return state;
		case actionTypes.RECEIVE_GROUPED_RETURNS_DOCUMENTS:
			index = state.sendGroupedReturnsTableModel.sendGroupedReturns.findIndex(x => x.groupId === action.groupId);
			if (index != -1) {
				state.sendGroupedReturnsTableModel.sendGroupedReturns[index].taxReturns = action.data;
			}
			return state;
		case actionTypes.RECEIVE_GROUPED_TAX_DOCUMENT:
			let tableData: SendGroupedReturnsTableModel = {
				count: state.sendGroupedReturnsTableModel.count,
				sendGroupedReturns: []
			};

			state.sendGroupedReturnsTableModel.sendGroupedReturns.map((group, i) => {
				let newGroup = { ...group };
				newGroup.taxReturns = [];
				group.taxReturns.map((taxReturn, j) => {
					if (taxReturn.id === action.taxDocument.id) {
						newGroup.taxReturns.push({ ...action.taxDocument });
					}
					else {
						newGroup.taxReturns.push({ ...taxReturn });
					}
				});
				tableData.sendGroupedReturns.push({ ...newGroup });
			});
			return {
				query: state.query,
				sendGroupedReturnsTableModel: tableData,
				totalRowCount: state.totalRowCount,
				loading: state.loading
			} as SendGroupedReturnsState;
		case actionTypes.MARK_AS_READY_FOR_DELIVERY:

			let tableData1: SendGroupedReturnsTableModel = {
				count: state.sendGroupedReturnsTableModel.count,
				sendGroupedReturns: []
			};

			state.sendGroupedReturnsTableModel.sendGroupedReturns.map((group, i) => {
				let newGroup = { ...group };
				newGroup.taxReturns = [];
				group.taxReturns.map((taxReturn, j) => {
					if (taxReturn.id === action.id) {
						taxReturn.documentStatus = DocumentStatus.READYFORDELIVERY;
					}
					newGroup.taxReturns.push({ ...taxReturn });
				});
				tableData1.sendGroupedReturns.push({ ...newGroup });
			});
			return {
				query: state.query,
				sendGroupedReturnsTableModel: tableData1,
				totalRowCount: state.totalRowCount,
				loading: state.loading
			} as SendGroupedReturnsState;
		
		case actionTypes.RECEIVE_SELECTED_DOCUMENT_FOR_DELIVERY:

			let tableData2: SendGroupedReturnsTableModel = {
				count: state.sendGroupedReturnsTableModel.count,
				sendGroupedReturns: []
			};

			state.sendGroupedReturnsTableModel.sendGroupedReturns.map((group, i) => {
				let newGroup = { ...group };
				newGroup.taxReturns = [];
				group.taxReturns.map((taxReturn, j) => {
					if (taxReturn.documentStatus !== DocumentStatus.RECALLED) {
						action.DocumentIds.map((id, k) => {
							if (taxReturn.id === id) {
								taxReturn.isSelectedForDelivery = true;
							}
						})
					}
					newGroup.taxReturns.push({ ...taxReturn });
				});
				tableData2.sendGroupedReturns.push({ ...newGroup });
			});
			return {
				query: state.query,
				sendGroupedReturnsTableModel: tableData2,
				totalRowCount: state.totalRowCount,
				loading: state.loading
			} as SendGroupedReturnsState;

		case actionTypes.REMOVE_SELECTED_DOCUMENT_FOR_DELIVERY:

			let tableData3: SendGroupedReturnsTableModel = {
				count: state.sendGroupedReturnsTableModel.count,
				sendGroupedReturns: []
			};

			state.sendGroupedReturnsTableModel.sendGroupedReturns.map((group, i) => {
				let newGroup = { ...group };
				newGroup.taxReturns = [];
				group.taxReturns.map((taxReturn, j) => {
					action.DocumentIds.map((id, k) => {
						if (taxReturn.id === id) {
							taxReturn.isSelectedForDelivery = false;
						}
					})
					newGroup.taxReturns.push({ ...taxReturn });
				});
				tableData3.sendGroupedReturns.push({ ...newGroup });
			});
			return {
				query: state.query,
				sendGroupedReturnsTableModel: tableData3,
				totalRowCount: state.totalRowCount,
				loading: state.loading
			} as SendGroupedReturnsState;

		case actionTypes.UPDATE_SEND_GROUPED_DOCUMENT_STATUS:
			let tableData4: SendGroupedReturnsTableModel = {
				count: state.sendGroupedReturnsTableModel.count,
				sendGroupedReturns: []
			};

			state.sendGroupedReturnsTableModel.sendGroupedReturns.map((group, i) => {
				let newGroup = { ...group };
				newGroup.taxReturns = [];
				group.taxReturns.map((taxReturn, j) => {

					let docIndex = action.ids.findIndex(x => x == taxReturn.id);

					if (docIndex!=NO_INDEX) {
						taxReturn.documentStatus = action.status;
					}
					newGroup.taxReturns.push({ ...taxReturn });
				});
				tableData4.sendGroupedReturns.push({ ...newGroup });
			});
			return {
				query: state.query,
				sendGroupedReturnsTableModel: tableData4,
				totalRowCount: state.totalRowCount,
				loading: state.loading
            } as SendGroupedReturnsState;
        case actionTypes.UPDATE_SEND_GROUPED_DOCUMENT_ASSIGN:
            let tableDataAssign: SendGroupedReturnsTableModel = {
                count: state.sendGroupedReturnsTableModel.count,
                sendGroupedReturns: []
            };

            state.sendGroupedReturnsTableModel.sendGroupedReturns.map((group, i) => {
                let newGroup = { ...group };
                newGroup.taxReturns = [];
                group.taxReturns.map((taxReturn, j) => {

                    let docIndex = action.ids.findIndex(x => x == taxReturn.id);

                    if (docIndex != NO_INDEX) {
                        taxReturn.assignedUser = action.assignedUser;
                        taxReturn.assignTo = action.assignTo;
                        taxReturn.documentStatus = action.status;
                    }
                    newGroup.taxReturns.push({ ...taxReturn });
                });
                tableDataAssign.sendGroupedReturns.push({ ...newGroup });
            });
            return {
                query: state.query,
                sendGroupedReturnsTableModel: tableDataAssign,
                totalRowCount: state.totalRowCount,
                loading: state.loading
            } as SendGroupedReturnsState;

		case actionTypes.RESET_SEND_GROUPED_RETURNS:
			return clearGroupedTaxReturn(state);
		default:
			// The following line guarantees that every action in the KnownAction union has been covered by a case above
			const exhaustiveCheck: never = action;
	}
	return state || unloadedState;
}

function clearGroupedTaxReturn(state: SendGroupedReturnsState): SendGroupedReturnsState {
	return {
		...unloadedState,
		query: state.query,
		isLoading: true
	} as SendGroupedReturnsState;
}
