import * as React from 'react'
import { Modal, Button, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../store/index';
import * as UserLoginHistory from '../store/userManagement/UserLoginHistoryStore';
import { IUserLoginHistory } from './navigation/profile/ProfileObjects';
import Moment from 'moment';
import * as Browser from 'bowser';
import { TYPES } from './../Startup/types';
import { container } from './../Startup/inversify.config';
import { ISessionLocalStore } from './../Core/Utilities/SessionStore';
import { CheckBoxComponent } from './common/CheckBoxComponent';
import { UserSettings, actionCreators } from '../store/userManagement/UserSettingStore';
import { LoginHistory } from './svg/CustomGraphics';
const SessionStore = container.get<ISessionLocalStore>(TYPES.ISessionStore);


type LastLoginModalProps = {
    userLoginHistory: UserLoginHistory.IUserLoginHistoryState,
    userSetting: UserSettings
}
    & typeof actionCreators
    & RouteComponentProps<{}>;

interface LastLoginModalState {
    lastLogin: IUserLoginHistory;
    openModal: boolean;
    enableLastLogin: boolean;
}

export class LastLoginModal extends React.Component<LastLoginModalProps, LastLoginModalState>{
    state: LastLoginModalState = {
        lastLogin: {
            userEmail: "",
            clientApp: "",
            customData: "",
            deviceId: "",
            IPAddress: "",
            loggedInOn: "",
            loggedOutOn: ""
        },
        openModal: false,
        enableLastLogin: false
    }
    UNSAFE_componentWillReceiveProps(nextProps: LastLoginModalProps) {
        if (nextProps.userLoginHistory.loginHistories.length && !SessionStore.get("isVisited") && nextProps.userSetting.settings
            && nextProps.userSetting.settings.defaultSettings && nextProps.userSetting.settings.defaultSettings.enableLastLogin) {
            this.setLastLogin(nextProps);
        }
        else if (nextProps.userLoginHistory.loginHistories.length > 0 && nextProps.userSetting.settings
            && nextProps.userSetting.settings.defaultSettings && !nextProps.userSetting.settings.defaultSettings.enableLastLogin) {
            SessionStore.set("isVisited", true);
        }
    }

    private onCloseModal = (): void => {
        this.setState({
            openModal: false
        });
    }

    private setLastLogin = (props: LastLoginModalProps): void => { 
        SessionStore.set("isVisited", true);
        const { userLoginHistory } = props;
        if (userLoginHistory.loginHistories.length > 1) {
            (userLoginHistory.loginHistories[0].deviceId == userLoginHistory.deviceId) ?
                this.setState({
                    lastLogin: userLoginHistory.loginHistories[1]
                }) :
                this.setState({
                    lastLogin: userLoginHistory.loginHistories[0]
                });
            this.setState({
                enableLastLogin: props.userSetting.settings.defaultSettings.enableLastLogin,
                openModal: true
            });
        }
    }

    private getBrowserDetails = (userAgent: any) => {
        let browser = Browser.getParser(userAgent);
        return browser.getBrowserName() + "(" + browser.getBrowserVersion() + ")" + " on " + browser.getOSName() + "(" + browser.getOSVersion() + ")";
    }

    private changeLastLogin = (event: any) => {
        let settings = this.props.userSetting.settings;
        settings.defaultSettings.enableLastLogin = !event.currentTarget.checked;
        this.props.saveUserSettings(settings, true);
        this.setState({
            enableLastLogin: !event.currentTarget.checked
        });
    }

    render() {
        const { openModal, lastLogin: { loggedInOn, IPAddress, clientApp } } = this.state;
        let browserDetails: string = "";
        if (clientApp) {
            browserDetails = this.getBrowserDetails(clientApp);
        }
        return (
            <div className="margin-tbl-20">
                <Modal className="last-login-modal" show={openModal} onHide={this.onCloseModal}>
                    <Modal.Header closeButton>
                        <Modal.Title className='custom-modal-header'>
                            <span className='modalIcon text-secondary fas fa-info-circle'>
                            </span>Information
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <Row>
                                <Col lg={3}>
                                    <LoginHistory
                                        width={100}
                                        height={100}
                                        className="svg-shadow" />
                                </Col>
                                <Col lg={9}>
                                    <h2 style={{ color: '#337ab7', margin:'0' }}>Last Login</h2>
                                    <Row className="margin-top-10">
                                        <Col lg={3}>
                                            Log In On
                                        </Col>
                                        <Col lg={9} className="beforeContent">
                                            {Moment.utc(loggedInOn).local().format('MM/DD/YYYY hh:mm:ss A')}
                                        </Col>
                                    </Row>
                                    <Row className="margin-top-10">
                                        <Col lg={3}>
                                            IP Address
                                    </Col>
                                        <Col lg={9} className="beforeContent">
                                            {IPAddress}
                                        </Col>
                                    </Row>
                                    <Row className="margin-top-10">
                                        <Col lg={3}>
                                            Browser
                                    </Col>
                                        <Col lg={9} className="beforeContent">
                                            {browserDetails}
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="modal-footer-left float-left padT07 div-flex">
                            <CheckBoxComponent
                                id={"lastLoginDisable"}
                                text={"Don’t show me this message again"}
                                checked={!this.state.enableLastLogin}
                                onChange={this.changeLastLogin}
                                datatestAuto={"73E7B9C2-E1B6-4E00-80AE-8426EDDE969A"}
                            />
                            <Link to={'#'} className="help-icon marL05" data-placement="right" data-toggle="tooltip" title="To turn back on, see My Settings | Login History">
                                <i className="fas fa-question-circle"></i>
                            </Link>
                        </div>
                        <Button
                            className="btn-white"
                            variant='default'
                            onClick={this.onCloseModal}
                        >
                            <i className='fas fa-times'></i>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

export default connect(
    (state: ApplicationState) => ({
        userLoginHistory: state.userLoginHistory,
        userSetting: state.userSettings
    }), {
        ...actionCreators
    }
)(LastLoginModal as any); 
