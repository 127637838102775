import React, { useState } from "react";
import { Button, Modal, Col, FormLabel } from "react-bootstrap";
import { ReactComponent as LocationUpdateIcon } from "../../../assets/images/LocationUpdate.svg";
import "./ChangeOfficeLocationModal.scss";
import { IDropdown } from "../../../Core/Common/Dropdown";
import DropdownComponent from "../controls/DropdownComponent";
import { getUsersByLocation } from "./ChangeOfficeLocationModal.api";
import { Loader, LoadingOverlay } from "react-overlay-loader";

interface IChangeOfficeLocationModalProps {
    showModal: boolean;
    closeModal(): void;
    onChangeOfficeLocationConfirm(locationId: number, contactPerson: number, callBack: () => void): void;
    locationDropdown: IDropdown[];
}

const ChangeOfficeLocationModal: React.FC<IChangeOfficeLocationModalProps> = (props) => {
    const { showModal, closeModal, onChangeOfficeLocationConfirm } = props;
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [locationId, setLocationId] = useState<number>(0);
    const [contactPersonId, setContactPersonId] = useState<number>(0);
    const [contactPersonList, setContactPersonList] = useState<IDropdown[]>([]);
    const [isDisable, setIsDisable] = useState<boolean>(false);
    const locationDropdowns = props.locationDropdown?.map((item) => {
        return {
            value: item.value.toString(),
            label: item.name
        };
    });
    const [mandatoryFieldError, setMandatoryFieldError] = useState({
        ddlLocation: { error: false, message: "" },
        ddlContactPerson: { error: false, message: "" }
    });

    const contactPersonDropdowns = contactPersonList?.map((item) => {
        return {
            value: item.value.toString(),
            label: item.name
        };
    });

    const onPopupClose = () => {
        setLocationId(0);
        setContactPersonId(0);
        setContactPersonList([]);
        closeModal();
    };

    const onLocationChange = (value) => {
        const id = value ? parseInt(value) : 0;
        setLocationId(id);
        loadContactPersons(id);

        const newMandatoryFieldError = { ...mandatoryFieldError };
        newMandatoryFieldError.ddlLocation.error = id === 0;
        newMandatoryFieldError.ddlLocation.message = id === 0 ? "This field is required" : "";
        setMandatoryFieldError(newMandatoryFieldError);
    };

    const onConatactPersonChange = (value) => {
        const id = value ? parseInt(value) : 0;
        setContactPersonId(id);

        const newMandatoryFieldError = { ...mandatoryFieldError };
        newMandatoryFieldError.ddlContactPerson.error = id === 0;
        newMandatoryFieldError.ddlContactPerson.message = id === 0 ? "This field is required" : "";
        setMandatoryFieldError(newMandatoryFieldError);
    };

    const loadContactPersons = (locationId: number) => {
        setIsLoading(true);
        if (locationId > 0) {
            getUsersByLocation(locationId, (data) => {
                setContactPersonList(data);
                setIsLoading(false);
            });
        } else {
            setContactPersonList([]);
            setIsLoading(false);
        }
        setContactPersonId(0);
    };

    const onContinue = () => {
        let isValid = true;

        let mandatoryFieldErrorList = { ...mandatoryFieldError };

        if (locationId == 0) {
            isValid = false;
            mandatoryFieldErrorList = {
                ...mandatoryFieldErrorList,
                ddlLocation: { error: true, message: "This field is required" }
            };
        }

        if (contactPersonId == 0) {
            isValid = false;
            mandatoryFieldErrorList = {
                ...mandatoryFieldErrorList,
                ddlContactPerson: { error: true, message: "This field is required" }
            };
        }

        if (isValid) {
            setIsDisable(true);
            onChangeOfficeLocationConfirm(locationId, contactPersonId, () => {
                setLocationId(0);
                setContactPersonId(0);
                setContactPersonList([]);
                setIsDisable(false);
            });
        } else {
            setMandatoryFieldError({ ...mandatoryFieldErrorList });
        }
    };

    return (
        <Modal show={showModal} className="change-office-location-modal" onHide={onPopupClose}>
            <Modal.Dialog>
                <Modal.Header closeButton>
                    <Modal.Title className="custom-modal-header">
                        <span className="text-secondary fas" style={{ color: "grey", marginRight: "5px" }}>
                            <LocationUpdateIcon style={{ opacity: 0.7 }} width={18} height={18}></LocationUpdateIcon>
                        </span>
                        Select New Office Location
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <LoadingOverlay style={{ height: "100%" }}>
                        <div className="row marB03">
                            <Col sm={12}>
                                <div>
                                    <p className ="custom-modal-note">
                                        Note: Your changes will impact all returns, except for those associated with a specific
                                        group
                                    </p>
                                </div>
                            </Col>
                        </div>
                        <div className="row marB03">
                            <Col sm={12}>
                                <div>
                                    <p>
                                        Updating the Office Location for one or more Client Records, will also be reflected in the
                                        Client Management system. Please be aware that if the client records are moved to a
                                        location where you are not assigned, access to these records{" "}
                                        <strong>will be revoked</strong>.
                                    </p>
                                </div>
                                <div>
                                    <FormLabel>Office Location</FormLabel>
                                    <DropdownComponent
                                        id="ddlLocation"
                                        selectedValue={locationId}
                                        onChange={onLocationChange}
                                        options={locationDropdowns}
                                        data-test-auto="873ADF33-DACD-44DF-AF44-C2372C0D5B12"
                                        clearable={true}
                                        className={`${mandatoryFieldError.ddlLocation.error ? "fieldErrorBorder" : ""}`}
                                    />
                                </div>
                                <div
                                    id="ddlLocationError"
                                    className={`${mandatoryFieldError.ddlLocation.error ? "fieldErrorMessage" : ""}`}
                                >
                                    {mandatoryFieldError.ddlLocation.message}
                                </div>
                            </Col>
                            <Col sm={12}>
                                <br />
                                <div>
                                    <p>
                                        Client Records are being relocated to a location where the assigned contact person may not
                                        be available. Please choose a new contact person from the updated location.
                                    </p>
                                </div>
                                <div>
                                    <FormLabel>Change Contact Person</FormLabel>
                                    <DropdownComponent
                                        id="ddlContactPerson"
                                        selectedValue={contactPersonId}
                                        onChange={onConatactPersonChange}
                                        options={contactPersonDropdowns}
                                        data-test-auto="9A2D3996-AE27-4AA7-8DF0-393DF4B74D06"
                                        clearable={true}
                                        className={`${mandatoryFieldError.ddlContactPerson.error ? "fieldErrorBorder" : ""}`}
                                    />
                                </div>
                                <div
                                    id="ddlContactPersonError"
                                    className={`${mandatoryFieldError.ddlContactPerson.error ? "fieldErrorMessage" : ""}`}
                                >
                                    {mandatoryFieldError.ddlContactPerson.message}
                                </div>
                            </Col>
                        </div>
                        <Loader loading={isLoading} text="loading....." />
                    </LoadingOverlay>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        data-test-auto="541497BF-190E-4E54-8497-E1E413280630"
                        variant="default"
                        className="btn-white"
                        onClick={onPopupClose}
                    >
                        <i className="fas fa-times"></i>Cancel
                    </Button>
                    <Button
                        disabled={isDisable}
                        onClick={onContinue}
                        data-test-auto="CCF3D866-ED11-4504-B20B-F819F6AA531B"
                        variant="info"
                    >
                        <i className="fas fa-check"></i>Confirm
                    </Button>
                </Modal.Footer>
            </Modal.Dialog>
        </Modal>
    );
};

export default ChangeOfficeLocationModal;
