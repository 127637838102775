import React from 'react'
import { useSelector } from 'react-redux'
import { ILogger } from 'src/Logger/Logger';
import LoadingView from '../components/LoadingView';
import { TelemetryLogger } from '../Logger/TelemetryLogger'

import { ApplicationState } from '../store'

export let logger:ILogger;
const LoggedIn: React.FC = ({ children }) => {
    const user = useSelector((state: ApplicationState) => state.auth.user)
    logger = user ? TelemetryLogger.getInstance() : null;
    return user ? <>{children}</> : <LoadingView />
}

LoggedIn.displayName = 'LoggedIn'

export default LoggedIn
