export const USER_EXPIRED = "USER_EXPIRED";
export const SILENT_RENEW_ERROR = "SILENT_RENEW_ERROR";
export const USER_EXPIRING = "USER_EXPIRING";
export const USER_FOUND = "USER_FOUND";
export const LOADING_USER = "LOADING_USER";
export const USER_SIGNED_OUT = "USER_SIGNED_OUT";
export const LOAD_USER_ERROR = "LOAD_USER_ERROR";
export const USER_PRIVILEGE_CHANGED = "USER_PRIVILEGE_CHANGED";
export const RESET_USER_PRIVILEGE_CHANGE_STATE = "RESET_USER_PRIVILEGE_CHANGE_STATE";
export const RESET_CACHE_API_CALLED = "RESET_CACHE_API_CALLED";
export const LOGGED_OUT = "LOGGED_OUT";

export enum actionType {
    LOGGED_OUT
}
