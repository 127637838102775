import React, { useEffect, useRef, useState } from 'react';
import * as bootbox from 'bootbox';

interface CustomRouterPromptProps {
    enabled: boolean,
    history: any
};

export const CustomRouterPrompt = (props: CustomRouterPromptProps) => {

    const ref = useRef(null);
    const { enabled, history } = props;

    const [showPrompt, setShowPrompt] = useState(false);
    const [currentPath, setCurrentPath] = useState('');

    useEffect(() => {
        if (enabled) {
            history.block((location: any) => {
                if (history.location.pathname !== location.pathname) {
                    setCurrentPath(location.pathname);
                    setShowPrompt(true);
                    return 'true';
                }
            })
        } else {
            history.block(() => {});
        }

        return () => {
            history.block(() => {});
        }
    }, [enabled]);

    useEffect(() => {
        if (ref.current) {
            bootbox.confirm({
                message: 'Your changes will be discarded. Do you want to continue?',
                buttons: {
                    cancel: {
                        label: '<i class="fas fa-times"></i> No',
                        className: 'btn-white btn-default',
                    },
                    confirm: {
                        label: '<i class="fas fa-check"></i> Yes',
                        className: 'btn-success',
                    },
                },
                callback: (result: boolean) => {
                    if (result) {
                        handleOk();
                    } else {
                        handleCancel();
                    }
                },
            })
        }
    }, [showPrompt]);

    const handleOk = () => {
        history.block(() => {});
        history.push(currentPath);
    };

    const handleCancel = () => setShowPrompt(false);

    return showPrompt && <div ref={ref}></div>;
};
