import { ProductType } from "../Company/CompanySettingsViewModel"
import { PaymentState } from "../Purchase/PurchaseViewModels"
export interface IPaymentDetails {
    publicApiKey: string,
    accountId: string,
    paymentId: string,
    payemtSessionId: string,
    totAmount: number,
    paymentSuccessPath: string,
    paymentCancelPath: string,
    transactionId: string,
    status: PaymentState,
    message: string 

}
export const initializePaymentDetails: IPaymentDetails =
{
    accountId: "",
    payemtSessionId: "",
    paymentCancelPath: "",
    paymentId: "",
    paymentSuccessPath: "",
    publicApiKey: "",
    totAmount: 0,
    transactionId: "",
    status: PaymentState.Initialized,
    message:""
}

export interface IPaymentSetUpInfo {
    customerId: string,
    id: string ,
    setUpId: string ,
    successPath : string,
    cancelPath: string,
    publicApiKey: string,
    accountId: string,
    productType: ProductType
}
export const initializePaymentSetUpInfo: IPaymentSetUpInfo =
{
    cancelPath : "",
    customerId: "",
    id: "",
    setUpId: "",
    successPath: "",
    accountId: "",
    publicApiKey: "",
    productType: ProductType.SSR
}