import React from 'react'
import { useSelector } from 'react-redux'

import { ApplicationState } from '../store'

const ValidationDataLoaded: React.FC = ({ children }) => {
    const accessibleProduct = useSelector((state: ApplicationState) => state.AccessibleProductData)
    const leftMenuData = useSelector((state: ApplicationState) => state.LeftMenu);
    return accessibleProduct && leftMenuData && !accessibleProduct.isInitialState &&
        leftMenuData && !leftMenuData.isInitialState ? <>{children}</> : <></>
}

ValidationDataLoaded.displayName = 'ValidationDataLoaded'

export default ValidationDataLoaded
