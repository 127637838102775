import * as React from 'react';
import { Row, Col } from 'react-bootstrap';
import { ITaxReturn, DocumentStatus, SignatureStatus } from '../../common/TaxReturn';
import { LoadingOverlay, Loader } from 'react-overlay-loader';
import { DropdownComponent } from '../../common/controls/DropdownComponent'
import CustomToggleSwitch from "../../common/CustomToggleSwitch";
import * as Helper from "../../helper/HelperFunctions";

interface IVoucherReminderProps {
    show:boolean,
    taxReturn: ITaxReturn,
    isUserAllowedToEnableVoucherReminder: boolean;
    isUserAllowedToEnableSigningReminder: boolean;
    handleVoucherReminderChange: (allowVoucherReminder: boolean) => void
    handleSigningReminderChange: (allowSigningReminder: boolean) => void
    handleVoucherReminderInDaysChange: (voucherReminderInDays: number) => void
    handleSigningReminderInDaysChange: (signingReminderInDays: number) => void
}

interface SelectOptions {
    value: any,
    label: string
}

interface IVoucherReminderStates {
    loading: boolean,
    message: string,
    selectedReminder: SelectOptions,
    reminderOptions: SelectOptions[],
    selectedSingingReminder: SelectOptions,
    signingReminderOptions: SelectOptions[],
    initialVoucherReminderPopupState: InitialVoucherReminderPopupState,
    isSigningReminderDisabled: boolean,
    isVoucherReminderDisabled: boolean,
}

interface InitialVoucherReminderPopupState {
    allowVoucherReminder: boolean;
    voucherReminderInDays: number;
    allowSigningReminder: boolean;
    signingReminderInDays: number;
}


const msg = {
    saving: 'Saving Reminder...',
    loading: 'Loading, please wait...'
}

export class VoucherReminder extends React.Component<IVoucherReminderProps, IVoucherReminderStates>
{
    constructor(props: IVoucherReminderProps) {
        super(props);
        this.state = {
            loading: true,
            reminderOptions: [],
            selectedReminder: { value: 1, label: '1 day' },
            signingReminderOptions: [],
            selectedSingingReminder: { value: 1, label: '1 day' },
            message: msg.loading,
            isSigningReminderDisabled: false,
            isVoucherReminderDisabled: false,
            initialVoucherReminderPopupState: {
                allowVoucherReminder: false,
                voucherReminderInDays: 0,
                allowSigningReminder: false,
                signingReminderInDays: 0,
            }
        }
        this.handleSigningReminderChange = this.handleSigningReminderChange.bind(this);
        this.handleSigningToggleSwitchChange = this.handleSigningToggleSwitchChange.bind(this);
        this.handleVoucherReminderChange = this.handleVoucherReminderChange.bind(this);
        this.handleVoucherToggleSwitchChange = this.handleVoucherToggleSwitchChange.bind(this);
    }

    SetReminderOptions(props: IVoucherReminderProps) {
        var reminderOptions = [];
        for (var i = 1; i <= 30; i++) {
            reminderOptions.push({ value: i, label: i === 1 ? i + ' day' : i + ' days' });

        }
        let noOfDaysForVoucherReminder: number = props?.taxReturn?.documentSettings?.documentNotificationSetting.paymentVoucherNotificationSettingsModel.noOfDaysForVoucherReminder
        let noOfDaysForSigningReminder: number = props?.taxReturn?.documentSettings?.documentNotificationSetting.signingReminderNotificationSettingsModel.noOfDaysForSigningReminder
        this.setState({
            reminderOptions: reminderOptions,
            selectedReminder: { value: noOfDaysForVoucherReminder, label: noOfDaysForVoucherReminder + (noOfDaysForVoucherReminder === 1 ? " day" : " days") },
            selectedSingingReminder: { value: noOfDaysForSigningReminder, label: noOfDaysForSigningReminder + (noOfDaysForSigningReminder === 1 ? " day" : " days") },
            signingReminderOptions: reminderOptions
        });
    }

    private handleVoucherToggleSwitchChange = (event: any) => {
        this.props.taxReturn.documentSettings.documentNotificationSetting.paymentVoucherNotificationSettingsModel.enablePaymentVoucherReminder = event.target.checked
        if (event.target.checked) {
            this.props.handleVoucherReminderChange(true)
        }
        else {
            this.props.handleVoucherReminderChange(false)
        }
        this.forceUpdate()
    }

    private handleSigningToggleSwitchChange = (event: any) => {
        this.props.taxReturn.documentSettings.documentNotificationSetting.signingReminderNotificationSettingsModel.enableSigningReminder = event.target.checked
        if (event.target.checked) {
            this.props.handleSigningReminderChange(true)
        }
        else {
            this.props.handleSigningReminderChange(false)
        }
        this.forceUpdate()
    }

    private handleVoucherReminderChange = (selectedOption: any) => {
        if (selectedOption == null) {
            this.setState({
                selectedReminder: {
                    value: selectedOption, label: "Select..."
                }
            }, () => {
                this.props.handleVoucherReminderInDaysChange(selectedOption);
            });
        }

        else {
            this.props.taxReturn.documentSettings.documentNotificationSetting.paymentVoucherNotificationSettingsModel.noOfDaysForVoucherReminder = selectedOption
            this.setState({
                selectedReminder: {
                    value: selectedOption, label: selectedOption + (selectedOption == 1 ? " day" : " days")
                }
            }, () => {
                this.props.handleVoucherReminderInDaysChange(selectedOption);
            });
        }
    }

    private handleSigningReminderChange = (selectedOption: any) => {
        if (selectedOption == null) {
            this.setState({
                selectedSingingReminder: {
                    value: selectedOption, label: "Select..."
                }
            }, () => {
                this.props.handleSigningReminderInDaysChange(selectedOption);
            });
        }
        else {
            this.props.taxReturn.documentSettings.documentNotificationSetting.signingReminderNotificationSettingsModel.noOfDaysForSigningReminder = selectedOption
            this.setState({
                selectedSingingReminder: {
                    value: selectedOption, label: selectedOption + (selectedOption == 1 ? " day" : " days")
                }
            }, () => {
                this.props.handleSigningReminderInDaysChange(selectedOption);
            });
        }
    }

    componentDidMount() {
        if (this.props.taxReturn && this.props.taxReturn.documentSettings) {
            this.setState({
                isSigningReminderDisabled: !this.props.isUserAllowedToEnableSigningReminder,
                initialVoucherReminderPopupState: {
                    allowVoucherReminder: this.props.taxReturn.documentSettings.documentNotificationSetting.paymentVoucherNotificationSettingsModel.enablePaymentVoucherReminder,
                    voucherReminderInDays: this.props.taxReturn.documentSettings.documentNotificationSetting.paymentVoucherNotificationSettingsModel.noOfDaysForVoucherReminder,
                    allowSigningReminder: this.props.taxReturn.documentSettings.documentNotificationSetting.signingReminderNotificationSettingsModel.enableSigningReminder,
                    signingReminderInDays: this.props.taxReturn.documentSettings.documentNotificationSetting.signingReminderNotificationSettingsModel.noOfDaysForSigningReminder
                },
            });
        }
    }

    UNSAFE_componentWillReceiveProps(props: IVoucherReminderProps) {
            if (this.props.taxReturn && this.props.taxReturn.documentSettings) {
                        this.SetReminderOptions(this.props);
                        this.setState({
                            isVoucherReminderDisabled: !this.props.isUserAllowedToEnableVoucherReminder,
                            loading: false,
                            isSigningReminderDisabled: !this.props.isUserAllowedToEnableSigningReminder,
                            initialVoucherReminderPopupState: {
                                allowVoucherReminder: this.props.taxReturn.documentSettings.documentNotificationSetting.paymentVoucherNotificationSettingsModel.enablePaymentVoucherReminder,
                                voucherReminderInDays: this.props.taxReturn.documentSettings.documentNotificationSetting.paymentVoucherNotificationSettingsModel.noOfDaysForVoucherReminder,
                                allowSigningReminder: this.props.taxReturn.documentSettings.documentNotificationSetting.signingReminderNotificationSettingsModel.enableSigningReminder,
                                signingReminderInDays: this.props.taxReturn.documentSettings.documentNotificationSetting.signingReminderNotificationSettingsModel.noOfDaysForSigningReminder
                            }
                        });
            }
            else {
                this.setState({ loading: true });
            }
        }

    public signingReminderDescription = () => (
        <div className="mt-3">
            <div className="text-danger mb-2">Please Note!</div>
            <div>
                User cannot update reminders for the signature statuses "E-Signed", "Uploaded", "Manually Signed", "Signed & E-Signed", "AlternateDelivery", "DeliveredToTaxCaddy", "SignatureStampingFailed", "E-Signed – In Office".
            </div>
        </div>
    );

    public voucherReminderDescription = () => (
        <div className="mt-3">
            <div className="text-danger mb-2">Please Note!</div>
            <div className="mb-2">Voucher reminders cannot be delivered for the returns due to one of the following reasons:</div>
            <ul className="ml-3">
                <Row>
                    <Col>
                        <li>Tax Payer has disabled the settings</li>
                        <li>All voucher payments flagged as paid</li>
                    </Col>
                    <Col>
                        <li>No voucher found for the return</li>
                        <li>Expired Vouchers</li>
                    </Col>
                </Row>
            </ul>
        </div>
    );

    public render() {
        return (
            !this.state.loading ?
            <div className="reminder-modal py-3">
                <div className="px-3">
                    <div className="mb-4">
                        <div><b>Voucher</b></div>
                        <Row className="align-items-center" noGutters>
                            <Col xs={4}>Send a reminder</Col>
                            <Col xs={3}>
                                <DropdownComponent
                                    id="ddlVoucherReminder"
                                    options={this.state.reminderOptions}
                                    onChange={this.handleVoucherReminderChange}
                                    selectedValue={this.state.selectedReminder}
                                    clearable={true}
                                    disabled={(!this.props.taxReturn?.documentSettings?.documentNotificationSetting.paymentVoucherNotificationSettingsModel.enablePaymentVoucherReminder || this.state.isVoucherReminderDisabled)}
                                />
                            </Col>
                            <Col className="ml-3" xs={3}>
                                before due date
                            </Col>
                            <Col>
                                <CustomToggleSwitch
                                    switched={this.props.taxReturn?.documentSettings?.documentNotificationSetting.paymentVoucherNotificationSettingsModel.enablePaymentVoucherReminder && !this.state.isVoucherReminderDisabled}
                                    handleChange={this.handleVoucherToggleSwitchChange}
                                    disabled={this.state.isVoucherReminderDisabled}
                                />
                            </Col>
                        </Row>
                        {this.state.isVoucherReminderDisabled ? this.voucherReminderDescription() : null}
                    </div>
                    <div>
                        <div><b>Signing</b></div>
                        <Row className="align-items-center" noGutters>
                            <Col xs={4}>Send a reminder every</Col>
                            <Col xs={3}>
                                <DropdownComponent
                                    id="ddlsigningReminder"
                                    options={this.state.signingReminderOptions}
                                    onChange={this.handleSigningReminderChange}
                                    selectedValue={this.state.selectedSingingReminder}
                                    disabled={(!this.props.taxReturn?.documentSettings?.documentNotificationSetting.signingReminderNotificationSettingsModel.enableSigningReminder|| this.state.isSigningReminderDisabled)}
                                    clearable={true}
                                />
                            </Col>
                            <Col className="ml-3" xs={3}>
                                since last reminder / initial mail
                            </Col>
                            <Col>
                                <CustomToggleSwitch switched={this.props.taxReturn?.documentSettings?.documentNotificationSetting.signingReminderNotificationSettingsModel.enableSigningReminder && !this.state.isSigningReminderDisabled}
                                    handleChange={this.handleSigningToggleSwitchChange}
                                    disabled={this.state.isSigningReminderDisabled}
                                />
                            </Col>
                        </Row>
                        {this.state.isSigningReminderDisabled ? this.signingReminderDescription() : null}
                    </div>
                </div>
            </div>
            : <LoadingOverlay style={{ height: 100, position: 'relative' }}>
            <Loader loading={this.props.show} text={this.state.message} />
        </LoadingOverlay>
        )
    }
}
