import { getClientInfoByClientId, extractClientId } from "../../../components/helper/HelperFunctions";
import { handleResponse } from "../../../store/Library";
import { API_BASE_URL } from "../../../utils/constants";
import { IClientInfoViewModel } from "../TaxReturn";
import { IClientInfoComparisonResult, IClientInfoMismatchType } from "./MismatchModal.model";

export const compareClientInfo = (
    clientInfo: IClientInfoViewModel,
    setResult: (result: IClientInfoComparisonResult) => void,
    noDataMismatchCallback: () => void,
    newClientCallback: (clientInfo: IClientInfoViewModel) => void,
    onAutoCMUpdate: () => void,
    setClientBasicInfoId: (id: number) => void,
    callback?: (oldClientId: string) => void
) => {
    fetch(API_BASE_URL + "/api/ClientManagement/CompareAsync", {
        method: "POST",
        credentials: "include",
        body: JSON.stringify(clientInfo),
        headers: {
            Accept: "application/json, */*",
            "Content-Type": "application/json",
            RequestVerificationToken: (document.getElementById("RequestVerificationToken") as HTMLInputElement).value
        }
    })
        .then(handleResponse)
        .then((response: IClientInfoComparisonResult) => {
            let oldClientId = "";
             const cmClientId = response?.clientInfoResultData?.clientId?.clientManagementValue ?? "";
            if (response?.comparisonStatus === IClientInfoMismatchType.NoDataMismatch) {
                noDataMismatchCallback();
            } else if (response?.comparisonStatus === IClientInfoMismatchType.NewClient) {
                newClientCallback(clientInfo);
            }
            else if (response?.comparisonStatus === IClientInfoMismatchType.ClientIdMismatch && extractClientId(cmClientId) == clientInfo.clientId) {
                getClientInfoByClientId(cmClientId, clientInfo.uid, (resp: IClientInfoViewModel) => {
                    setClientBasicInfoId(resp.clientBasicInfoId);
                    onAutoCMUpdate();
                });
            } else {
                const clientInfoResultData = response.clientInfoResultData;
                if (clientInfoResultData.clientId.hadDifference && !clientInfoResultData.mobileNumber?.hadDifference) {
                    oldClientId = clientInfoResultData.clientId.clientManagementValue ?? "";
                }
                setResult(response);
                getClientInfoByClientId(clientInfo.clientId, clientInfo.uid, (resp: IClientInfoViewModel) => {
                    setClientBasicInfoId(resp.clientBasicInfoId);
                });
            }
            callback && callback(oldClientId);
        })
        .catch((error: any) => {
            console.log(error);
        });
};

export const editClientInfo = (clientInfo: IClientInfoViewModel, callback: () => void) => {
    fetch(API_BASE_URL + "/api/ClientManagement/", {
        method: "PUT",
        credentials: "include",
        body: JSON.stringify(clientInfo),
        headers: {
            Accept: "application/json, */*",
            "Content-Type": "application/json",
            RequestVerificationToken: (document.getElementById("RequestVerificationToken") as HTMLInputElement).value
        }
    })
        .then(handleResponse)
        .then((response: any) => {
            callback();
        })
        .catch((error: any) => {
            console.log(error);
        });
};

export const addNewClientInfo = (clientInfo: IClientInfoViewModel, callback: () => void) => {
    fetch(API_BASE_URL + "/api/ClientManagement/", {
        method: "POST",
        credentials: "include",
        body: JSON.stringify(clientInfo),
        headers: {
            Accept: "application/json, */*",
            "Content-Type": "application/json",
            RequestVerificationToken: (document.getElementById("RequestVerificationToken") as HTMLInputElement).value
        }
    })
        .then(handleResponse)
        .then((response: any) => {
            callback();
        })
        .catch((error: any) => {
            console.log(error);
        });
};
