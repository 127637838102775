import {
    SILENT_RENEW_ERROR,
    USER_FOUND,
    LOAD_USER_ERROR,
    USER_EXPIRED,
    USER_SIGNED_OUT,
    LOADING_USER,
    USER_PRIVILEGE_CHANGED,
    RESET_USER_PRIVILEGE_CHANGE_STATE,
    RESET_CACHE_API_CALLED,
    LOGGED_OUT
} from "./action-types";

export interface IAuthState {
    user: any;
    isLoadingUser: boolean;
    loggedOut: boolean;
}

const initialState = {
    user: null,
    isLoadingUser: false,
    loggedOut: false
};

export interface IUserPreviligeState {
    logoutCause: LogoutCause;
    userPrivilegesChanged: boolean;
    isResetCacheApiCalled: boolean;
}

export enum LogoutCause {
    None = 0,
    UserPermissionChange = 1,
    UserDelete = 2,
    ProductTierChange = 3,
    CompanyDisable = 4
}

const initialUserPreviligeState: IUserPreviligeState = {
    logoutCause: LogoutCause.None,
    userPrivilegesChanged: false,
    isResetCacheApiCalled: false
};

export const reducer = (state = initialState, action: any) => {
    switch (action.type) {
        case USER_FOUND:
            return {
                ...state,
                isLoadingUser: false,
                user: action.payload
            };
        case LOADING_USER:
            return {
                ...state,
                isLoadingUser: true
            };
        case USER_EXPIRED:
        case LOAD_USER_ERROR:
        case SILENT_RENEW_ERROR:
        case USER_SIGNED_OUT:
            return {
                ...state,
                user: null,
                isLoadingUser: false
            };
        case LOGGED_OUT: {
            return {
                ...state,
                loggedOut: action.loggedOut
            };
        }
        default:
            return state;
    }
};

export const userPrivilegeReducer = (state = initialUserPreviligeState, action: any) => {
    switch (action.type) {
        case USER_PRIVILEGE_CHANGED:
            return {
                ...state,
                userPrivilegesChanged: true,
                logoutCause: action.payload.logoutCause
            };
        case RESET_USER_PRIVILEGE_CHANGE_STATE:
            return {
                ...state,
                userPrivilegesChanged: false,
                logoutCause: LogoutCause.None
            };
        case RESET_CACHE_API_CALLED:
            return {
                ...state,
                isResetCacheApiCalled: true
            };
        default:
            return state;
    }
};
