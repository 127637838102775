import * as React from 'react';
import { Card, Row, Col, Button } from 'react-bootstrap';
import { phoneNumberDisplay, FilterSSN } from '../../helper/HelperFunctions';
import { IGroupInfo } from '../../../store/groupedreturns/GroupedReturnProcessState'


interface DeliverToControllerProps {
	cancelFinish: () => void;
	deliverToController: () => void;
	groupInfo?: IGroupInfo;
}

export const DeliverToController: React.FunctionComponent<DeliverToControllerProps> = ({ cancelFinish, deliverToController, groupInfo }) => {

	return (<Card>
		<Card.Body>

			<div>
				<Row className="marB15">
					<Col className="nopadding-right" sm={3} as={Col}>
						Name
                                </Col>
					<Col className="nopadding-left" sm={9} as={Col}>
						{groupInfo!.controllerInfo.name}
					</Col>
				</Row>

				<Row className="marB15">
					<Col className="nopadding-right" sm={3} as={Col}>
						SSN
                                 </Col>
					<Col className="nopadding-left" sm={9} as={Col}>
						{groupInfo!.controllerInfo ? FilterSSN(groupInfo!.controllerInfo.ssn) : ""}
					</Col>

				</Row>

				<Row className="marB15">
					<Col className="nopadding-right" sm={3} as={Col}>
						Email
                                </Col>
					<Col className="nopadding-left" sm={9} as={Col}>
						{groupInfo!.controllerInfo.emailAddress}
					</Col>
				</Row>
				{
					groupInfo!.controllerInfo.mobileNumber &&
					<Row className="marB15">
						<Col className="nopadding-right" sm={3} as={Col}>
							Mobile Number
                                 </Col>
						<Col className="nopadding-left" sm={9} as={Col}>
							{groupInfo!.controllerInfo.countryCode} {phoneNumberDisplay(groupInfo!.controllerInfo.mobileNumber)}

						</Col>

					</Row>
				}
			</div>

			<div>
				<Col sm={12}>
					<Button type="button" variant="info" className="float-right"
						onClick={deliverToController}
					><i className="fa fa-paper-plane"></i>Send</Button>
					<Button type="button" variant="default" className="float-right marR05 btn-white btn-default"
						onClick={cancelFinish}
					><i className="fas fa-times"></i>Cancel</Button>
				</Col>
			</div>
		</Card.Body>
	</Card>
	)
}