import * as React from 'react';
import { Nav } from 'react-bootstrap';
import * as Constants from '../../helper/Constants';
import { ITaxReturn, IIndividualTaxReturn, EngagementType, TaxCaddyLookupResult, DocumentGroups } from '../../common/TaxReturn';
import { ISSRUserModel } from '../../../Core/ViewModels/User/UserViewModel';
import { ApproveForDelivery } from '../../common/ProcessReturnModal/FinishProcessReturn/ApproveForDelivery';
import { SendToERO } from '../../common/ProcessReturnModal/FinishProcessReturn/SendToERO';
import { SendForReview } from '../../common/ProcessReturnModal/FinishProcessReturn/SendForReview';
import { IUserProfile } from '../../navigation/profile/ProfileObjects';
import { ICompanySettings } from '../../../Core/ViewModels/Company/CompanySettingsViewModel';
import { DeliverToClient } from '../../common/ProcessReturnModal/FinishProcessReturn/DeliverToClient';
import { PrintForPaperDelivery } from '../../common/ProcessReturnModal/FinishProcessReturn/PrintForPaperDelivery';
import { validateClientEmailInfo } from '../../helper/HelperFunctions';
import { TaxCaddyShort } from '../../svg/LogoCollection';
import * as CompanyStore from '../../../store/company/CompanyStore';
import { IGroupInfo } from '../../../store/groupedreturns/GroupedReturnProcessState';
import { ControllerInfoProperty } from '../../../store/groupedreturns/GroupedReturnProcessStore';
import { DeliverToController } from '../TaxReturnProcessing/DeliverToController';
import { logger } from '../../../routes/LoggedIn';
import { SendGroupedTaxReturnResources } from '../../helper/ResourceIdConstants';

export interface GroupFinishPopoverProps {
    groupedTaxDocuments: ITaxReturn[];
    taxreturn: ITaxReturn;
    users: ISSRUserModel[];
    userProfile: IUserProfile;
    companySettings: ICompanySettings;
    sendToERO: () => void;
    sendForReview: (selectedUser: number) => void;
    approveForDelivery: (selectedUser: number) => void;
    deliverToController: () => void;
    onPrintForPaperDelivery: () => void;
    updateTaxDocument: (taxDocument: ITaxReturn) => void;
    updateDocumentGroupOrder: (engagementType: EngagementType, order: DocumentGroups[]) => void;
    handleSaveAsDefaultOrderChange: (event: any) => void;
    isDefaultOrder: boolean;
    companyData: CompanyStore.ICompanyData;
    requestCompanyLogo: (reload?: boolean, isLogoUpdate?: boolean) => void;
    groupInfo: IGroupInfo;
    changeControllerInfo: (item: ControllerInfoProperty, value: any) => void;
    parentResourceIdentifier?: string;
    deliverToControllerResourceId?: string;
    approveForDeliveryResourceId?: string;
    sendToEroSignerResourceId?: string;
    sendForReviewResourceId?: string;
    paperDeliveryResourceId?: string;
}
export interface GroupFinishPopoverState {
    activeMenuItem: string
    filteredUsers: ISSRUserModel[];
    selectedUser: number;
}
export class GroupFinishPopover extends React.Component<GroupFinishPopoverProps, GroupFinishPopoverState>{

    constructor(props: GroupFinishPopoverProps) {
        super(props);
        this.state = {
            activeMenuItem: "",
            filteredUsers: [],
            selectedUser: 0,
            }
        
        this.sendForReview = this.sendForReview.bind(this);
        this.approveForDelivery = this.approveForDelivery.bind(this);
        this.onChangeUser = this.onChangeUser.bind(this);
        this.cancelFinish = this.cancelFinish.bind(this);
    }


    public cancelFinish() {
        document.body.click();
        this.setState({ activeMenuItem: "" })
    }
    public onChangeUser(event: any) {
        this.setState({ selectedUser: parseInt(event.currentTarget.value) })
    }
    public sendForReview() {
        this.props.sendForReview(this.state.selectedUser);
    }
    public approveForDelivery() {
        this.props.approveForDelivery(this.state.selectedUser);
    }
 

    menuToggleOpen = (event: any) => {

        if (event.target.nextElementSibling.style['display'] == "none") {
            event.target.style['display'] = "none";
            event.target.nextElementSibling.style['display'] = "block";
            event.target.parentElement.nextElementSibling.style['display'] = 'block'
        } else {
            event.target.style['display'] = "block";
            event.target.nextElementSibling.style['display'] = "none";
            event.target.parentElement.nextElementSibling.style['display'] = 'none'
        }
    }

    menuToggleClose = (event: any) => {
        if (event.target.previousElementSibling.style['display'] == "none") {
            event.target.style['display'] = "none";
            event.target.previousElementSibling.style['display'] = "block";
            event.target.parentElement.nextElementSibling.style['display'] = 'none'
        } else {
            event.target.style['display'] = "block";
            event.target.previousElementSibling.style['display'] = "none";
            event.target.parentElement.nextElementSibling.style['display'] = 'block'
        }
    }


    onMenuClick(menuName: string) {
        let indexCount = 0;
        let fliteredUsers: ISSRUserModel[] = Object.assign([], this.props.users);//Temprorary Case (Passing All SSR Users) May Change Later

        this.setState({ filteredUsers: fliteredUsers, selectedUser: 0 });
        switch (menuName) {
            case Constants.FinishProcessReturn.MenuItems.SendForReview:
            case Constants.FinishProcessReturn.MenuItems.SendToEROSigner:
            case Constants.FinishProcessReturn.MenuItems.ApproveForDelivery:
            case Constants.FinishProcessReturn.MenuItems.PaperDelivery:
                this.setState({ activeMenuItem: menuName });
                break;
            case Constants.FinishProcessReturn.MenuItems.DeliverToController:
                if (validateClientEmailInfo(this.props.taxreturn)) {
                    this.setState({ activeMenuItem: menuName });
                }
                break;
        }
        logger.trackPageView(`${menuName} started for documentid: ${this.props.taxreturn.id} and documentguid: ${this.props.taxreturn.documentGuid}, DocumentStatus: ${this.props.taxreturn.documentStatus}`, { "DocumentId": this.props.taxreturn.id?.toString(), "DocumentGuid": this.props.taxreturn.documentGuid ,"DocumentStatus": this.props.taxreturn.documentStatus});
    }
    getMenuContent() {
        switch (this.state.activeMenuItem) {
            case Constants.FinishProcessReturn.MenuItems.SendToEROSigner:
                return <div className="height-inherit">
                    <SendToERO
                        taxreturn={this.props.taxreturn}
                        cancelFinish={this.cancelFinish}
                        sendToERO={this.props.sendToERO}

                    />
                </div>
            case Constants.FinishProcessReturn.MenuItems.ApproveForDelivery:
                return <div className="height-inherit">
                    <ApproveForDelivery
                        allUsersList={this.state.filteredUsers}
                        cancelFinish={this.cancelFinish}
                        approveForDelivery={this.approveForDelivery}
                        selectedUser={this.state.selectedUser}
                        onChangeUser={this.onChangeUser}
                    />
                </div>
            case Constants.FinishProcessReturn.MenuItems.SendForReview:
                return <div className="height-inherit">
                    <SendForReview
                        allUsersList={this.state.filteredUsers}
                        cancelFinish={this.cancelFinish}
                        sendForReview={this.sendForReview}
                        selectedUser={this.state.selectedUser}
                        onChangeUser={this.onChangeUser}
                    />
                </div>
            case Constants.FinishProcessReturn.MenuItems.DeliverToController:
                return <div>
                    <DeliverToController
                    groupedTaxDocuments = {this.props.groupedTaxDocuments}
                        taxreturn={this.props.taxreturn}
                        cancelFinish={this.cancelFinish}
                        deliverToController={this.props.deliverToController}
                        updateTaxDocument={this.props.updateTaxDocument}
                        groupInfo={this.props.groupInfo}
                        changeControllerInfo={this.props.changeControllerInfo}
                    />
                </div>
          
            case Constants.FinishProcessReturn.MenuItems.PaperDelivery:
                return <div>
                    <PrintForPaperDelivery
                        taxreturn={this.props.taxreturn}
                        cancelFinish={this.cancelFinish}
                        onPrintForPaperDelivery={this.props.onPrintForPaperDelivery}
                        updateTaxDocument={this.props.updateTaxDocument}
                        updateDocumentGroupOrder={this.props.updateDocumentGroupOrder}
                        handleSaveAsDefaultOrderChange={this.props.handleSaveAsDefaultOrderChange}
                        isDefaultOrder={this.props.isDefaultOrder} />
                </div>
            case Constants.FinishProcessReturn.MenuItems.Empty:
                return <span>Please select an option to finish</span>
        }
    }


    render() {
        let engagementType: any = this.props.taxreturn.engagementType;
        return <div className="full-height">
            <div className="row height-inherit">
                <div className="col-lg-12 height-inherit dispFlex">
                    <div className="col-lg-4" style={{ paddingLeft: "0px", height: "98%" }}>
                        <div className="nav nav-tabs tabs-left height-inherit" style={{ height: "100%" }}>

                            <li className={"nav-item InternalRouting-dropdown-Menu"}>

                                <span className=" fa nav-link dropdown-toggle " style={{ backgroundColor: "#f7f7f7", marginLeft: "-15px", marginTop: "-10px", paddingBottom: "10px", paddingTop: "10px", display: "block" }} role="button" data-toggle="InternalRouting" data-target="#InternalRouting-dropdown" aria-haspopup="true" aria-expanded="false">

                                    <span style={{ color: "#337ab7", fontSize: '16px' }}>Internal Routing</span>
                                    <span onClick={this.menuToggleOpen} className="fa fa-angle-right" style={{ display: 'none', float: 'right', paddingLeft: '20px' }}></span>
                                    <span onClick={this.menuToggleClose} className="fa fa-angle-down" style={{ float: 'right', paddingLeft:'20px' }}></span>
                                </span>

                                <div className="InternalRouting nav nav-tabs tabs-left" id="InternalRouting-dropdown" >

                                    <li className={this.state.activeMenuItem == Constants.FinishProcessReturn.MenuItems.SendForReview ? "active" : ""}
                                        data-resource-id={this.props.parentResourceIdentifier +  (this.props.sendForReviewResourceId ? this.props.sendForReviewResourceId :SendGroupedTaxReturnResources.SendForReview)}                                     >
                                        <Nav.Link href="javascript:" onClick={() => { this.onMenuClick(Constants.FinishProcessReturn.MenuItems.SendForReview) }}>
                                            <i className="fa fa-eye" style={{ paddingRight: "10px" }}></i>
                                            {Constants.FinishProcessReturn.MenuItems.SendForReview}</Nav.Link>
                                    </li>
                                    {
                                        this.props.userProfile?.userId != this.props.taxreturn.partner?.userId ?
                                            <li className={this.state.activeMenuItem == Constants.FinishProcessReturn.MenuItems.SendToEROSigner ? "active" : ""}
                                                data-resource-id={this.props.parentResourceIdentifier +  (this.props.sendToEroSignerResourceId ? this.props.sendToEroSignerResourceId :SendGroupedTaxReturnResources.SendToEroSigner)}                                              >
                                                <Nav.Link href="javascript:" onClick={() => { this.onMenuClick(Constants.FinishProcessReturn.MenuItems.SendToEROSigner) }}>
                                                    <i className='fa fa-user text-secondary' style={{ paddingRight: "10px" }}></i>
                                                    {Constants.FinishProcessReturn.MenuItems.SendToEROSigner}</Nav.Link>
                                            </li> : ""
                                    }
                                    {
                                        <li className={this.state.activeMenuItem == Constants.FinishProcessReturn.MenuItems.ApproveForDelivery ? "active" : ""}
                                            data-resource-id={this.props.parentResourceIdentifier +  (this.props.approveForDeliveryResourceId ? this.props.approveForDeliveryResourceId :SendGroupedTaxReturnResources.ApproveForDelivery)}                                          >
                                            <Nav.Link href="javascript:" onClick={() => { this.onMenuClick(Constants.FinishProcessReturn.MenuItems.ApproveForDelivery) }}>
                                                <i className='fa fa-check' style={{ paddingRight: "10px" }}></i>
                                                {Constants.FinishProcessReturn.MenuItems.ApproveForDelivery}</Nav.Link>
                                        </li>
                                    }
                                </div>
                            </li>

                            <li className={"nav-item Delivery-dropdown-Menu "}>
                                <span className=" fa nav-link dropdown-toggle" role="button" style={{ backgroundColor: "#f7f7f7", marginLeft: "-15px", paddingBottom: "10px", paddingTop: "10px", display: "block" }} data-toggle="Delivery" data-target="#Delivery-dropdown" aria-haspopup="true" aria-expanded="false">
                                    <span onClick={this.menuToggleOpen} className="fa fa-angle-right" style={{ display: 'none', float: 'right', paddingLeft: '20px'  }}></span>
                                    <span onClick={this.menuToggleClose} className="fa fa-angle-down" style={{ color: 'grey', float: 'right', paddingLeft: '20px' }}></span><span style={{ color: "#337ab7", fontSize: '16px' }}>Delivery</span>
                                </span>

                                <div className="Delivery nav nav-tabs tabs-left" id="Delivery-dropdown" >
                                    {
                                        
                                        <li className={this.state.activeMenuItem == Constants.FinishProcessReturn.MenuItems.DeliverToController ? "active" : ""}
                                            data-resource-id={this.props.parentResourceIdentifier + (this.props.deliverToControllerResourceId ? this.props.deliverToControllerResourceId :SendGroupedTaxReturnResources.DeliverToController)}                                          >
                                            <Nav.Link href="javascript:" onClick={() => { this.onMenuClick(Constants.FinishProcessReturn.MenuItems.DeliverToController) }}>
                                                    <i className='fa fa-paper-plane' style={{ paddingRight: "10px" }}></i>
                                                {Constants.FinishProcessReturn.MenuItems.DeliverToController}</Nav.Link>
                                        </li>
                                    }
                                 
                                    {
                                        this.props.companySettings.deliverySettingsModel.isPrintToPDFEnabled &&
                                        <li className={this.state.activeMenuItem == Constants.FinishProcessReturn.MenuItems.PaperDelivery ? "active" : ""}
                                            data-resource-id={this.props.parentResourceIdentifier +  (this.props.paperDeliveryResourceId ? this.props.paperDeliveryResourceId : SendGroupedTaxReturnResources.DownloadPdf)}                                          >
                                            <Nav.Link href="javascript:" onClick={() => { this.onMenuClick(Constants.FinishProcessReturn.MenuItems.PaperDelivery) }}>
                                                <i className='fa fa-print' style={{ paddingRight: "10px" }}></i>
                                                {Constants.FinishProcessReturn.MenuItems.PaperDelivery}
                                            </Nav.Link>
                                        </li>
                                    }
                                </div>
                            </li>
                        </div>
                    </div>
                    <div className="col-lg-8 height-inherit">{
                        this.getMenuContent()
                    }

                    </div>
                </div>
            </div>
        </div>
    }
}
