import * as React from "react";
import { Modal, Alert, ListGroup, Form, Button } from "react-bootstrap";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import { FaFileDownload } from "react-icons/fa";
import * as DeliveredReturnsState from "../../store/reports/DeliveredReturnsState";
import {
    IDownloadableDocuments,
    IAttachment,
    ITaxReturn,
    getUnformattedClientName,
    FormType,
    SignatureStatus,
    PaperReturnDeliveryType,
    DocumentStatus
} from "../common/TaxReturn";
import { DownloadAttachments } from "../common/DownloadAttachments";
import { DownloadReturnsConstants } from "../helper/Constants";
import { getDocumentEntityorIndividualName, displayFileName } from "../../components/helper/HelperFunctions";
import { IDocumentData } from "../common/ProcessReturnModal/AdditionalEsignDocuments/AdditionalEsignDocument";
import { GetSafeFilename } from "../helper/Validations";
import { DownloadK1Attachments } from "../common/ProcessReturnModal/K1Attachments/DownloadK1Attachment";
import { IK1Attachment } from "../common/ProcessReturnModal/K1Attachments/IK1Attachment";
import { logger } from "../../routes/LoggedIn";

interface IDownloadReturnsProps {
    show: boolean;
    onCancel(): void;
    taxReturn: ITaxReturn;
    onDownloadIndividual(
        documentGuid: string,
        documentId: number,
        taxYear: number,
        fileName: string,
        groupId: number,
        signedDocumentId: number,
        isDownloadReturn: boolean,
        clientId: string,
        resourceId?: string
    ): void;
    onDownloadAll(documentGuid: string, documentId: number, taxYear: number, isEfileForm: boolean, resourceId?: string): void;
    onDownloadAttachments: (
        taxReturn: ITaxReturn,
        fileName: string,
        documentId: number,
        attachmentId: number,
        isArchivedReturn: boolean,
        callback?: (url: string, fileName: string) => void,
        resourceId?: string
    ) => void;
    onDownloadAllAttachments: (taxReturn: ITaxReturn, isArchivedReturn: boolean, clientName: string, resourceId?: string) => void;
    isArchivedReturn: boolean;
    onDownloadAdditionalEsignDocuments: (
        documentGuid: string,
        documentId: number,
        taxYear: number,
        clientId: string,
        additionalEsignDocuments: IDocumentData[],
        isSigned?: boolean,
        resourceId?: string
    ) => void;
    onDownloadMergedPaperFileDocument(documentGuid: string, documentId: number, taxYear: number, clientId: string): void;
    onDownloadK1Attachments: (
        documentGuid: string,
        documentId: number,
        taxYear: number,
        fileName: string,
        k1AttachmentId: number,
        callback?: (url: string, fileName: string) => void,
        resourceId?: string
    ) => void;
    onDownloadAllK1Attachments: (
        taxReturn: ITaxReturn,
        isArchivedReturn: boolean,
        clientName: string,
        resourceId?: string
    ) => void;
    resourceId?: string;
}

export interface IPopupState {
    show: boolean;
}
export interface IPopupStateSingle extends IPopupState {
    model: IAttachment[] | undefined;
    saveCheckId: number | undefined;
}

export interface IPopupK1Attachment extends IPopupState {
    model: IK1Attachment[] | undefined;
}

export interface DownloadReturnsPageState {
    downloadAttachmentsState: IPopupStateSingle;
    downloadK1AttachmentsState: IPopupK1Attachment;
}

export class DownloadReturns extends React.Component<IDownloadReturnsProps, DownloadReturnsPageState> {
    constructor(props: IDownloadReturnsProps) {
        super(props);
        this.handleDownloadAttachmentsOpen = this.handleDownloadAttachmentsOpen.bind(this);
        this.handleDownloadAttachmentCancel = this.handleDownloadAttachmentCancel.bind(this);
        this.handleDownloadAllAttachments = this.handleDownloadAllAttachments.bind(this);
        this.handleDownloadIndividualAttachmentFile = this.handleDownloadIndividualAttachmentFile.bind(this);
        this.state = {
            downloadAttachmentsState: {
                model: undefined,
                show: false,
                saveCheckId: undefined
            },
            downloadK1AttachmentsState: {
                model: undefined,
                show: false
            }
        };
    }

    private handleDownloadAttachmentsOpen() {
        let downloadableAttachments = this.props.taxReturn.attachments;
        this.setState({
            downloadAttachmentsState: {
                show: true,
                model: downloadableAttachments,
                saveCheckId: undefined
            }
        });
        logger.trackTrace(
            `Download attachment clicked for documentId: ${this.props.taxReturn.id?.toString()} and documentGuid: ${
                this.props.taxReturn.documentGuid
            }`,
            { DocumentId: this.props.taxReturn.id?.toString(), DocumentGuid: this.props.taxReturn.documentGuid }
        );
    }

    private handleDownloadAttachmentCancel() {
        this.setState({
            downloadAttachmentsState: {
                show: false,
                model: undefined,
                saveCheckId: undefined
            }
        });
    }
    private handleDownloadAllAttachments() {
        this.props.onDownloadAllAttachments(
            this.props.taxReturn,
            this.props.isArchivedReturn,
            getUnformattedClientName(this.props.taxReturn),
            this.props.resourceId
        );
    }

    private handleDownloadIndividualAttachmentFile(documentId: number, attachmentId: number, fileName: string) {
        this.props.onDownloadAttachments(
            this.props.taxReturn,
            fileName,
            documentId,
            attachmentId,
            this.props.isArchivedReturn,
            undefined,
            this.props.resourceId
        );
    }

    public handleDownloadIndividualK1Attachment = (documentId: number, fileName: string, k1AttachmentId: number) => {
        this.props.onDownloadK1Attachments(
            this.props.taxReturn.documentGuid,
            documentId,
            this.props.taxReturn.taxYear,
            fileName,
            k1AttachmentId,
            undefined,
            this.props.resourceId
        );
    };

    private getFormattedFileName(value: any) {
        const { taxReturn } = this.props;
        let fileName: any;
        let name = getDocumentEntityorIndividualName(taxReturn);

        const getFilenameForK1 = () => {
            return taxReturn.clientId +
                      "_" +
                      name +
                      "_" +
                      taxReturn.taxYear +
                      "_" +
                      taxReturn.engagementType.toString().replace("E", "") +
                      "_" +
                      "K1"
        };

        switch (value.groupId) {
            case FormType.Transmittals:
                fileName =
                    taxReturn.clientId +
                    "_" +
                    name +
                    "_" +
                    taxReturn.taxYear +
                    "_" +
                    taxReturn.engagementType.toString().replace("E", "") +
                    "_" +
                    value.fileName;
                break;
            case FormType.EFile:
                let deliveryType: string =
                    taxReturn.documentSettings.deliverySettings.paperReturnSettings.deliveryType.toString();
                let docStatus: string = taxReturn.signatureStatus.toString();

                let displayNameFormat: string =
                    deliveryType === PaperReturnDeliveryType[PaperReturnDeliveryType.SingleFile] &&
                    (docStatus === SignatureStatus[SignatureStatus.AlternateDelivery] ||
                        docStatus === SignatureStatus[SignatureStatus.AlternateAndUploaded])
                        ? "Tax Returns.pdf"
                        : docStatus === SignatureStatus[SignatureStatus.Uploaded] ||
                          docStatus === SignatureStatus[SignatureStatus.AlternateAndUploaded]
                        ? "Signature Documents.zip"
                        : "Signature Documents.pdf";

                fileName =
                    taxReturn.clientId +
                    "_" +
                    name +
                    "_" +
                    taxReturn.taxYear +
                    "_" +
                    taxReturn.engagementType.toString().replace("E", "") +
                    "_" +
                    displayNameFormat;
                break;
            case FormType.Vouchers:
                fileName =
                    taxReturn.clientId +
                    "_" +
                    name +
                    "_" +
                    taxReturn.taxYear +
                    "_" +
                    taxReturn.engagementType.toString().replace("E", "") +
                    "_" +
                    value.fileName.replace(" On ", "_");
                break;
            case FormType.TaxReturns:
                fileName =
                    taxReturn.clientId +
                    "_" +
                    name +
                    "_" +
                    taxReturn.taxYear +
                    "_" +
                    taxReturn.engagementType.toString().replace("E", "") +
                    "_" +
                    "Tax Returns.pdf";
                break;
            case FormType.Invoice:
                fileName =
                    taxReturn.clientId +
                    "_" +
                    name +
                    "_" +
                    taxReturn.taxYear +
                    "_" +
                    taxReturn.engagementType.toString().replace("E", "") +
                    "_" +
                    "Tax Preparation Fee.pdf";
                break;
            case FormType.K1:
                fileName = getFilenameForK1();
                break;
        }
        return GetSafeFilename(fileName);
    }

    private getPaperFiledFileName() {
        const { taxReturn } = this.props;
        let name = getDocumentEntityorIndividualName(taxReturn);
        return GetSafeFilename(`${taxReturn.clientId}_${name}_${taxReturn.taxYear}_Paper Filed Returns.pdf`);
    }

    private handleDownloadAdditionalEsignDocuments(taxReturn: ITaxReturn, isSigned: boolean) {
        this.props.onDownloadAdditionalEsignDocuments(
            taxReturn.documentGuid,
            taxReturn.id,
            taxReturn.taxYear,
            taxReturn.clientId,
            taxReturn.additionalEsignDocuments,
            isSigned ? true : false,
            this.props.resourceId
        );
    }

    private handleDownloadK1AttachmentsOpen = () => {
        this.setState({
            downloadK1AttachmentsState: {
                show: true,
                model: this.props.taxReturn.k1Attachments
            }
        });
    };

    private handleDownloadK1AttachmentCancel = () => {
        this.setState({
            downloadK1AttachmentsState: {
                show: false,
                model: undefined
            }
        });
    };

    private handleDownloadAllK1Attachments = () => {
        this.props.onDownloadAllK1Attachments(
            this.props.taxReturn,
            this.props.isArchivedReturn,
            getUnformattedClientName(this.props.taxReturn),
            this.props.resourceId
        );
    };

    private isReturnSigned = () : boolean => {
        return this.props.taxReturn.documentStatus.toString() === DocumentStatus[DocumentStatus.USERSIGNED] || 
            (this.props.taxReturn.documentStatus === DocumentStatus[DocumentStatus.DOWNLOADPDF]
                && this.props.taxReturn.signatureStatus.toString() === SignatureStatus[SignatureStatus.AlternateAndUploaded]);
    }

    private getAdditionalDocumentFileName = (): string => {
        return this.isReturnSigned() ?  "Other Signed Document" : "Other UnSigned Document";
    };

    public render() {
        const { taxReturn } = this.props;
        const downloadable = taxReturn !== undefined && taxReturn.downloadableDocuments !== undefined;

        return (
            <div>
                <Modal className="download-returns-modal" show={this.props.show} onHide={this.props.onCancel}>
                    <Modal.Header closeButton onClick={this.props.onCancel} data-test-auto="8A258655-8C8D-43FC-8685-0E4793776C2D">
                        <Modal.Title className="custom-modal-header">
                            <FaFileDownload className="modalIcon" />
                            Download Returns
                        </Modal.Title>
                    </Modal.Header>
                    <Form>
                        <Modal.Body>
                            <LoadingOverlay style={{ minHeight: 100 }}>
                                {downloadable ? (
                                    <div>
                                        <Alert variant="info">
                                            <i className="fa fa-info-circle" style={{ marginRight: "5px" }}></i>
                                            {DownloadReturnsConstants.downloadReturn}
                                        </Alert>
                                        <ListGroup data-test-auto="EDE084EA-60DA-4F72-BF01-17A8F47F744B">
                                            {this.props.taxReturn.downloadableDocuments.map((value, i) => {
                                                let formattedName = this.getFormattedFileName(value);
                                                return (
                                                    <li
                                                        className="delivered-return-download-link download-return-pop-up"
                                                        title={formattedName}
                                                        onClick={() => {
                                                            this.props.onDownloadIndividual(
                                                                value.documentGuid,
                                                                value.documentId,
                                                                value.taxYear,
                                                                value.fileName,
                                                                value.groupId,
                                                                value.signedDocumentId,
                                                                true,
                                                                taxReturn.clientId,
                                                                this.props.resourceId
                                                            );
                                                        }}
                                                    >
                                                        {formattedName}
                                                    </li>
                                                );
                                            })}
                                            {this.props.taxReturn && this.props.taxReturn.attachments && (
                                                <li
                                                    className="delivered-return-download-link"
                                                    onClick={() => {
                                                        this.handleDownloadAttachmentsOpen();
                                                    }}
                                                    hidden={this.props.taxReturn.attachments.length <= 0}
                                                >
                                                    {" "}
                                                    Attachments
                                                </li>
                                            )}
                                            {this.props.taxReturn &&
                                                this.props.taxReturn.additionalEsignDocuments &&
                                                this.props.taxReturn.additionalEsignDocuments.length > 0 && (
                                                    <li
                                                        className="delivered-return-download-link"
                                                        onClick={() => {
                                                            this.handleDownloadAdditionalEsignDocuments(
                                                                this.props.taxReturn,
                                                                this.isReturnSigned()
                                                            );
                                                        }}
                                                        hidden={this.props.taxReturn.additionalEsignDocuments.length <= 0}
                                                    >
                                                        {this.getAdditionalDocumentFileName()}
                                                    </li>
                                                )}
                                            {this.props.taxReturn &&
                                                this.props.taxReturn.paperFiles &&
                                                this.props.taxReturn.paperFiles.length > 0 && (
                                                    <li
                                                        className="delivered-return-download-link download-return-pop-up"
                                                        title={this.getPaperFiledFileName()}
                                                        onClick={() => {
                                                            this.props.onDownloadMergedPaperFileDocument(
                                                                taxReturn.documentGuid,
                                                                taxReturn.id,
                                                                taxReturn.taxYear,
                                                                taxReturn.clientId
                                                            );
                                                        }}
                                                        hidden={this.props.taxReturn.paperFiles.length <= 0}
                                                    >
                                                        {this.getPaperFiledFileName()}
                                                    </li>
                                                )}
                                            {this.props.taxReturn &&
                                                this.props.taxReturn.k1Attachments &&
                                                this.props.taxReturn.downloadableDocuments.length === 1 && (
                                                    <li
                                                        className="delivered-return-download-link"
                                                        onClick={() => {
                                                            this.handleDownloadK1AttachmentsOpen();
                                                        }}
                                                        hidden={this.props.taxReturn.k1Attachments.length <= 0}
                                                    >
                                                        {" "}
                                                        K1 Attachments
                                                    </li>
                                                )}
                                        </ListGroup>
                                    </div>
                                ) : (
                                    taxReturn !== undefined &&
                                    taxReturn.downloadableDocuments !== undefined && (
                                        <Alert variant="warning">
                                            <i
                                                className="fas fa-exclamation-triangle"
                                                style={{ marginRight: "5px", color: "#666" }}
                                            ></i>
                                            No returns found!
                                        </Alert>
                                    )
                                )}
                                <Loader loading={taxReturn === undefined || taxReturn.downloadableDocuments === undefined} />
                            </LoadingOverlay>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                onClick={this.props.onCancel}
                                variant="default"
                                className="btn btn-white"
                                data-test-auto="BC3C6FF2-75C2-45AD-8552-8915E4C8EDF8"
                            >
                                <i className="fas fa-times"></i>
                                Cancel
                            </Button>
                            <Button
                                onClick={() => {
                                    this.props.onDownloadAll(
                                        this.props.taxReturn.documentGuid,
                                        this.props.taxReturn.id,
                                        this.props.taxReturn.taxYear,
                                        false,
                                        this.props.resourceId
                                    );
                                }}
                                variant="info"
                                disabled={!downloadable}
                                data-test-auto="BC7DCC23-8799-4DD0-BDC6-30597C7804F1"
                            >
                                <i className="fas fa-download"></i>
                                Download All
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Modal>

                {this.props.taxReturn && this.props.taxReturn.attachments && (
                    <DownloadAttachments
                        show={this.state.downloadAttachmentsState.show}
                        onCancel={this.handleDownloadAttachmentCancel}
                        attachments={this.props.taxReturn.attachments as IAttachment[]}
                        downloadIndividual={this.handleDownloadIndividualAttachmentFile}
                        downloadAll={this.handleDownloadAllAttachments}
                    />
                )}

                {this.props.taxReturn && this.props.taxReturn.k1Attachments && (
                    <DownloadK1Attachments
                        show={this.state.downloadK1AttachmentsState.show}
                        onCancel={this.handleDownloadK1AttachmentCancel}
                        k1Attachments={this.props.taxReturn.k1Attachments as IK1Attachment[]}
                        onDownloadIndividualK1Attachment={this.handleDownloadIndividualK1Attachment}
                        onDownloadAll={this.handleDownloadAllK1Attachments}
                    />
                )}
            </div>
        );
    }
}
