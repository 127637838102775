import * as React from 'react';
import { Nav } from 'react-bootstrap';
import * as Constants from '../../../helper/Constants';
import { ITaxReturn, IIndividualTaxReturn, EngagementType, TaxCaddyLookupResult, DocumentGroups } from '../../../common/TaxReturn';
import { ISSRUserModel } from '../../../../Core/ViewModels/User/UserViewModel';
import { ApproveForDelivery } from './ApproveForDelivery';
import { SendToERO } from './SendToERO';
import { SendForReview } from './SendForReview';
import { IUserProfile } from '../../../navigation/profile/ProfileObjects';
import { ICompanySettings } from '../../../../Core/ViewModels/Company/CompanySettingsViewModel';
import { DeliverToClient } from './DeliverToClient';
import { PrintForPaperDelivery } from './PrintForPaperDelivery';
import { DeliverToCompany } from './DeliverToCompany';
import { DeliverWithTaxCaddy } from './DeliverWithTaxCaddy'
import { validateClientEmailInfo, isIndividualEnagagementType } from '../../../helper/HelperFunctions';
import { TaxCaddyShort } from '../../../svg/LogoCollection';
import * as CompanyStore from '../../../../store/company/CompanyStore';
import { logger } from '../../../../routes/LoggedIn';
import { SendTaxReturnResources } from '../../../helper/ResourceIdConstants';
import store from '../../../../store';

export interface FinishPopoverProps {
    taxreturn: ITaxReturn;
    users: ISSRUserModel[];
    userProfile: IUserProfile;
    companySettings: ICompanySettings;
    sendToERO: () => void;
    sendForReview: (selectedUser: number) => void;
    approveForDelivery: (selectedUser: number) => void;
    deliverToClient: (resourceId?: string) => void;
    onPrintForPaperDelivery: () => void;
    getTaxCaddyLookupDetails: (taxpayerName: string, email: string, ssn: string, taxYear: number, taxClientId: string, docId: number) => void;
    deliverWithTaxcaddy: (taxCaddyLookupResult: TaxCaddyLookupResult) => void;
    updateTaxDocument: (taxDocument: ITaxReturn) => void;
    updateDocumentGroupOrder: (engagementType: EngagementType, order: DocumentGroups[]) => void;
    handleSaveAsDefaultOrderChange: (event: any) => void;
    isDefaultOrder: boolean;
    companyData: CompanyStore.ICompanyData;
    requestCompanyLogo: (reload?: boolean, isLogoUpdate?: boolean) => void;
    parentResourceIdentifier?: string;
    partnerUsers: ISSRUserModel[];
    deliverFirstToUpdated: (updated: boolean) => void;
}
export interface FinishPopoverState {
    activeMenuItem: string
    filteredUsers: ISSRUserModel[];
    selectedUser: number;
}
export class FinishPopover extends React.Component<FinishPopoverProps, FinishPopoverState>{

    constructor(props: FinishPopoverProps) {
        super(props);
        this.state = {
            activeMenuItem: "",
            filteredUsers: [],
            selectedUser: 0,
            }
        
        this.sendForReview = this.sendForReview.bind(this);
        this.approveForDelivery = this.approveForDelivery.bind(this);
        this.onChangeUser = this.onChangeUser.bind(this);
        this.cancelFinish = this.cancelFinish.bind(this);
    }


    public cancelFinish() {
        document.body.click();
        this.setState({ activeMenuItem: "" })
    }
    public onChangeUser(event: any) {
        this.setState({ selectedUser: parseInt(event.currentTarget.value) })
    }
    public sendForReview() {
        this.props.sendForReview(this.state.selectedUser);
    }
    public approveForDelivery() {
        this.props.approveForDelivery(this.state.selectedUser);
    }
    public deliverWithTaxCaddy() {

    }

    menuToggleOpen = (event: any) => {

        if (event.target.nextElementSibling.style['display'] == "none") {
            event.target.style['display'] = "none";
            event.target.nextElementSibling.style['display'] = "block";
            event.target.parentElement.nextElementSibling.style['display'] = 'block'
        } else {
            event.target.style['display'] = "block";
            event.target.nextElementSibling.style['display'] = "none";
            event.target.parentElement.nextElementSibling.style['display'] = 'none'
        }
    }

    menuToggleClose = (event: any) => {
        if (event.target.previousElementSibling.style['display'] == "none") {
            event.target.style['display'] = "none";
            event.target.previousElementSibling.style['display'] = "block";
            event.target.parentElement.nextElementSibling.style['display'] = 'none'
        } else {
            event.target.style['display'] = "block";
            event.target.previousElementSibling.style['display'] = "none";
            event.target.parentElement.nextElementSibling.style['display'] = 'block'
        }
    }


    onMenuClick(menuName: string) {
        let fliteredUsers: ISSRUserModel[] = Object.assign([], this.props.users);//Temprorary Case (Passing All SSR Users) May Change Later
              

        this.setState({ filteredUsers: fliteredUsers, selectedUser: 0 });
        switch (menuName) {
            case Constants.FinishProcessReturn.MenuItems.SendForReview:
            case Constants.FinishProcessReturn.MenuItems.SendToEROSigner:
            case Constants.FinishProcessReturn.MenuItems.ApproveForDelivery:
            case Constants.FinishProcessReturn.MenuItems.PaperDelivery:
                this.setState({ activeMenuItem: menuName });
                break;
            case Constants.FinishProcessReturn.MenuItems.DeliverToClient:
            case Constants.FinishProcessReturn.MenuItems.DeliverToCompany:
            case Constants.FinishProcessReturn.MenuItems.DeliverToTaxCaddy:
            case Constants.FinishProcessReturn.MenuItems.DeliverWithTaxCaddy:
                if (validateClientEmailInfo(this.props.taxreturn)) {
                    this.setState({ activeMenuItem: menuName });
                }
                break;
        }
        logger.trackPageView(`${menuName} started for documentid: ${this.props.taxreturn.id} and documentguid: ${this.props.taxreturn.documentGuid}, DocumentStatus:${this.props.taxreturn.documentStatus}`, { "DocumentId": this.props.taxreturn.id, "DocumentGuid": this.props.taxreturn.documentGuid });
    }
    getMenuContent() {
        switch (this.state.activeMenuItem) {
            case Constants.FinishProcessReturn.MenuItems.SendToEROSigner:
                return <div className="height-inherit">
                    <SendToERO
                        taxreturn={this.props.taxreturn}
                        cancelFinish={this.cancelFinish}
                        sendToERO={this.props.sendToERO}

                    />
                </div>
            case Constants.FinishProcessReturn.MenuItems.ApproveForDelivery:
                return <div className="height-inherit">
                    <ApproveForDelivery
                        allUsersList={this.state.filteredUsers}
                        cancelFinish={this.cancelFinish}
                        approveForDelivery={this.approveForDelivery}
                        selectedUser={this.state.selectedUser}
                        onChangeUser={this.onChangeUser}
                    />
                </div>
            case Constants.FinishProcessReturn.MenuItems.SendForReview:
                return <div className="height-inherit">
                    <SendForReview
                        allUsersList={this.state.filteredUsers}
                        cancelFinish={this.cancelFinish}
                        sendForReview={this.sendForReview}
                        selectedUser={this.state.selectedUser}
                        onChangeUser={this.onChangeUser}
                    />
                </div>
            case Constants.FinishProcessReturn.MenuItems.DeliverToClient:
                return <div>
                    <DeliverToClient
                        taxreturn={this.props.taxreturn}
                        cancelFinish={this.cancelFinish}
                        deliverToClient={this.props.deliverToClient}
                        updateTaxDocument={this.props.updateTaxDocument}
                        deliverFirstToUpdated={this.props.deliverFirstToUpdated} />
                </div>
            case Constants.FinishProcessReturn.MenuItems.DeliverToCompany:
                return <div className="height-inherit">
                    <DeliverToCompany
                        taxreturn={this.props.taxreturn}
                        cancelFinish={this.cancelFinish}
                        deliverToClient={this.props.deliverToClient} />
                </div>
            case Constants.FinishProcessReturn.MenuItems.DeliverToTaxCaddy:
            case Constants.FinishProcessReturn.MenuItems.DeliverWithTaxCaddy:
                let taxReturn: IIndividualTaxReturn = Object.assign({}, this.props.taxreturn as IIndividualTaxReturn);
                let accepted: boolean = false;
                if (taxReturn.taxCaddyLookupResultModel && taxReturn.taxCaddyLookupResultModel.isSuccess) {
                    if (taxReturn.taxpayer.name ===
                        taxReturn.taxCaddyLookupResultModel.resultObjectModel.taxpayerName
                        && (taxReturn.taxpayer.ssn && taxReturn.taxCaddyLookupResultModel.resultObjectModel.ssn
                            && taxReturn.taxpayer.ssn.replace(/[^0-9]/g, "").trim()
                            === taxReturn.taxCaddyLookupResultModel.resultObjectModel.ssn.replace(/[^0-9]/g, "").trim())
                        && taxReturn.taxpayer.email === taxReturn.taxCaddyLookupResultModel.resultObjectModel.emailAddress
                        && taxReturn.taxpayer.address === taxReturn.taxCaddyLookupResultModel.resultObjectModel.address) {
                        accepted = true;
                    }
                }

                return <div className="height-inherit">
                    <DeliverWithTaxCaddy
                        name={taxReturn.taxpayer.name}
                        ssn={taxReturn.taxpayer.ssn}
                        emailAddress={taxReturn.taxpayer.email}
                        address={taxReturn.taxpayer.address}
                        taxCaddyLookupResultModel={taxReturn.taxCaddyLookupResultModel}
                        deliverWithTaxCaddy={this.deliverWithTaxCaddy}
                        cancelFinish={this.cancelFinish}
                        deliverWithTaxcaddy={this.props.deliverWithTaxcaddy}
                        isAccepted={accepted}
                        companySetting={this.props.companySettings}
                        companyData={this.props.companyData}
                        requestCompanyLogo={this.props.requestCompanyLogo}
                    />
                </div>
            case Constants.FinishProcessReturn.MenuItems.PaperDelivery:
                return <div>
                    <PrintForPaperDelivery
                        taxreturn={this.props.taxreturn}
                        cancelFinish={this.cancelFinish}
                        onPrintForPaperDelivery={this.props.onPrintForPaperDelivery}
                        updateTaxDocument={this.props.updateTaxDocument}
                        updateDocumentGroupOrder={this.props.updateDocumentGroupOrder}
                        handleSaveAsDefaultOrderChange={this.props.handleSaveAsDefaultOrderChange}
                        isDefaultOrder={this.props.isDefaultOrder} />
                </div>
            case Constants.FinishProcessReturn.MenuItems.Empty:
                return <span>Please select an option to finish</span>
        }
    }


    render() {
        let engagementType: any = Number(EngagementType[this.props.taxreturn.engagementType]);
        return <div className="full-height">
            <div className="row height-inherit">
                <div className="col-lg-12 height-inherit dispFlex">
                    <div className="col-lg-4" style={{ paddingLeft: "0px", height: "98%" }}>
                        <div className="nav nav-tabs tabs-left height-inherit" style={{ height: "100%" }}>

                            <li className={"nav-item InternalRouting-dropdown-Menu width100"}>

                                <span className=" fa nav-link dropdown-toggle " style={{
                                    backgroundColor: "#f7f7f7", marginLeft: "-15px",
                                    marginTop: "-10px", paddingBottom: "10px", paddingTop: "10px", display: "block"
                                }}
                                    role="button" data-toggle="InternalRouting" data-target="#InternalRouting-dropdown" aria-haspopup="true" aria-expanded="false">

                                    <span style={{ color: "#337ab7", fontSize: '16px' }}>Internal Routing</span>
                                    <span onClick={this.menuToggleOpen} className="fa fa-angle-right" style={{ display: 'none', float: 'right', paddingLeft: '20px' }}></span>
                                    <span onClick={this.menuToggleClose} className="fa fa-angle-down" style={{ float: 'right', paddingLeft:'20px' }}></span>
                                </span>

                                <div className="InternalRouting nav nav-tabs tabs-left" id="InternalRouting-dropdown" >

                                    <li data-resource-id={this.props.parentResourceIdentifier + SendTaxReturnResources.SendForReviewMenu} className={this.state.activeMenuItem == Constants.FinishProcessReturn.MenuItems.SendForReview ? "active" : ""}>
                                        <Nav.Link href="javascript:" onClick={() => { this.onMenuClick(Constants.FinishProcessReturn.MenuItems.SendForReview) }}>
                                            <i className="fa fa-eye" style={{ paddingRight: "10px" }}></i>
                                            {Constants.FinishProcessReturn.MenuItems.SendForReview}</Nav.Link>
                                    </li>
                                    {
                                        this.props.userProfile?.userId != this.props.taxreturn.partner?.userId ?
                                            <li data-resource-id={this.props.parentResourceIdentifier + SendTaxReturnResources.SendToEROSignerMenu} className={this.state.activeMenuItem == Constants.FinishProcessReturn.MenuItems.SendToEROSigner ? "active" : ""}>
                                                <Nav.Link href="javascript:" onClick={() => { this.onMenuClick(Constants.FinishProcessReturn.MenuItems.SendToEROSigner) }}>
                                                    <i className='fa fa-user text-secondary' style={{ paddingRight: "10px" }}></i>
                                                    {Constants.FinishProcessReturn.MenuItems.SendToEROSigner}</Nav.Link>
                                            </li> : ""
                                    }
                                    {
                                        <li data-resource-id={this.props.parentResourceIdentifier + SendTaxReturnResources.ApproveForDeliveryMenu} className={this.state.activeMenuItem == Constants.FinishProcessReturn.MenuItems.ApproveForDelivery ? "active" : ""}>
                                            <Nav.Link href="javascript:" onClick={() => { this.onMenuClick(Constants.FinishProcessReturn.MenuItems.ApproveForDelivery) }}>
                                                <i className='fa fa-check' style={{ paddingRight: "10px" }}></i>
                                                {Constants.FinishProcessReturn.MenuItems.ApproveForDelivery}</Nav.Link>
                                        </li>
                                    }
                                </div>
                            </li>

                            <li className={"nav-item Delivery-dropdown-Menu width100"}>
                                <span className=" fa nav-link dropdown-toggle" role="button" style={{ backgroundColor: "#f7f7f7", marginLeft: "-15px", paddingBottom: "10px", paddingTop: "10px", display: "block" }} data-toggle="Delivery" data-target="#Delivery-dropdown" aria-haspopup="true" aria-expanded="false">
                                    <span onClick={this.menuToggleOpen} className="fa fa-angle-right" style={{ display: 'none', float: 'right', paddingLeft: '20px' }}></span>
                                    <span onClick={this.menuToggleClose} className="fa fa-angle-down" style={{ color: 'grey', float: 'right', paddingLeft: '20px' }}></span><span style={{ color: "#337ab7", fontSize: '16px' }}>Delivery</span>
                                </span>

                                <div className="Delivery nav nav-tabs tabs-left" id="Delivery-dropdown" >
                                    {
                                        isIndividualEnagagementType(engagementType) ?
                                            <li data-resource-id={this.props.parentResourceIdentifier + SendTaxReturnResources.DeliverToClientMenu}  className={this.state.activeMenuItem == Constants.FinishProcessReturn.MenuItems.DeliverToClient ? "active" : ""}>
                                                <Nav.Link href="javascript:"  onClick={() => { this.onMenuClick(Constants.FinishProcessReturn.MenuItems.DeliverToClient) }}>
                                                    <i className='fa fa-paper-plane' style={{ paddingRight: "10px" }}></i>
                                                    {Constants.FinishProcessReturn.MenuItems.DeliverToClient}</Nav.Link>
                                            </li>
                                            :
                                            <li data-resource-id={this.props.parentResourceIdentifier + SendTaxReturnResources.DeliverToCompanyMenu} className={this.state.activeMenuItem == Constants.FinishProcessReturn.MenuItems.DeliverToCompany ? "active" : ""}>
                                                <Nav.Link href="javascript:"  onClick={() => { this.onMenuClick(Constants.FinishProcessReturn.MenuItems.DeliverToCompany) }}>
                                                    <i className='fa fa-paper-plane' style={{ paddingRight: "10px" }}></i>
                                                    {Constants.FinishProcessReturn.MenuItems.DeliverToCompany}</Nav.Link>
                                            </li>
                                    }
                                    {
                                        (isIndividualEnagagementType(engagementType) &&
                                            this.props.companySettings.reportingChannelSettings[0].enabled && this.props.companySettings.deliverySettingsModel.isDirectDeliveryToTaxCaddy == false) &&
                                        <li data-resource-id={this.props.parentResourceIdentifier + SendTaxReturnResources.DeliverWithTaxCaddyMenu}  className={this.state.activeMenuItem == Constants.FinishProcessReturn.MenuItems.DeliverWithTaxCaddy ? "active" : ""}>
                                            <Nav.Link href="javascript:" onClick={() => { this.onMenuClick(Constants.FinishProcessReturn.MenuItems.DeliverWithTaxCaddy) }}>
                                                <img src={require("../../../../assets/images/logos/TaxCaddy.svg").default} style={{ height: '16px', paddingRight: '9px' }} />
                                                {Constants.FinishProcessReturn.MenuItems.DeliverWithTaxCaddy}
                                            </Nav.Link>
                                        </li>
                                    }
                                    {
                                        (isIndividualEnagagementType(engagementType) &&
                                            this.props.companySettings.reportingChannelSettings[0].enabled && this.props.companySettings.deliverySettingsModel.isDirectDeliveryToTaxCaddy == true) &&
                                        <li data-resource-id={this.props.parentResourceIdentifier + SendTaxReturnResources.DeliverWithTaxCaddyMenu} className={this.state.activeMenuItem == Constants.FinishProcessReturn.MenuItems.DeliverToTaxCaddy ? "active" : ""}>
                                            <Nav.Link href="javascript:"  onClick={() => { this.onMenuClick(Constants.FinishProcessReturn.MenuItems.DeliverToTaxCaddy) }}>
                                                <img src={require("../../../../assets/images/logos/TaxCaddy.svg").default} style={{ height: '16px' }} />
                                                <span style={{ paddingLeft: "9px" }}>{Constants.FinishProcessReturn.MenuItems.DeliverToTaxCaddy}</span></Nav.Link>
                                        </li>
                                    }
                                    {
                                        this.props.companySettings.deliverySettingsModel.isPrintToPDFEnabled &&
                                        <li data-resource-id={this.props.parentResourceIdentifier + SendTaxReturnResources.DownloadPDFMenu} className={this.state.activeMenuItem == Constants.FinishProcessReturn.MenuItems.PaperDelivery ? "active" : ""}>
                                            <Nav.Link href="javascript:"  onClick={() => { this.onMenuClick(Constants.FinishProcessReturn.MenuItems.PaperDelivery) }}>
                                                <i className='fa fa-print' style={{ paddingRight: "10px" }}></i>
                                                {Constants.FinishProcessReturn.MenuItems.PaperDelivery}
                                            </Nav.Link>
                                        </li>
                                    }
                                </div>
                            </li>
                        </div>
                    </div>
                    <div className="col-lg-8 height-inherit">{
                        this.getMenuContent()
                    }

                    </div>
                </div>
            </div>
        </div>
    }
}
