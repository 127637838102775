import * as React from 'react';
import { IWelcomeMessage } from '../../../../Core/ViewModels/CompanySettings/ClientInstructionViewModel';

interface IWelcomeMessageViewProps {
    selectedMessage: IWelcomeMessage;
}

export class WelcomeMessageView extends React.Component<IWelcomeMessageViewProps, {}>{
    public render() {
        return (
            <div className="width100">
                <div><h4>{this.props.selectedMessage.name}</h4></div>
                <div>
                    <label>Header:</label>
                    <label style={{fontWeight:200,marginLeft:10}}>{this.props.selectedMessage.header}</label>
                </div>
                <textarea rows={7} value={this.props.selectedMessage.text} className="form-control" disabled={true} />
            </div>
        )
    }
}