import * as React from 'react';
import { Card, FormLabel, Row, Col, Button, Alert, FormGroup, Form, Accordion } from 'react-bootstrap';
import { ITaxingAuthority } from '../../TaxReturn'
import { ClientInfoRightSideCardType } from '../Tabs/TabClientInfo';
import { CurrencyTextBox } from '../../../common/controls/CurrencyTextBox';

export interface IRefundPaymentProps {
    taxingAuthority: ITaxingAuthority[];  
    currentPage: number,
    expandRefundPaymentInformation: boolean
    onCardToggle: (cardType: ClientInfoRightSideCardType) => void,
    selectedAuthority: number,
    amount: number,
    isPayment: boolean,
    isRefund: boolean,
    overPayAppliedAmount: number,
    netRefund: number;   
    penalty?: number;   
    authorityChange: (event: any) => void;
    amountChanged: (value: number) => void;
    paymentChanged: (event: any) => void;
    refundChanged: (event: any) => void;
    overPayAppliedAmountChanged: (value: number) => void;
    onChangePenaltyAmount: (value: number) => void;
    onSaveClick: () => void;
    onCancel: (page: number) => void;
    showPaymentDueAlert: boolean;
    paymentDueAlertMessage: string;
    enableUpdate: boolean;
}

export class ClientRefundPaymentDue extends React.Component<IRefundPaymentProps, {}> {
    public render() {   
        let icon = this.props.expandRefundPaymentInformation ? "fas fa-minus" : "fas fa-plus";
        return (
            <Accordion
                activeKey={this.props.expandRefundPaymentInformation ? '0' : ''}
                onSelect={() => { this.props.onCardToggle(ClientInfoRightSideCardType.RefundPaymentInfoCard) }}
            >
            <Card>
            <Accordion.Toggle eventKey="0">
                        <Card.Header style={{ padding: "10px" }}>
                        <Card.Title as="h3" style={{ marginBottom: '0px' }}>
                                <i className={icon}></i> Refunds & Payments Due
                            </Card.Title>
            </Card.Header>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
                <Card.Body>
                    {this.props.showPaymentDueAlert ?
                        <Alert variant="danger" ><i className='fas fa-exclamation-triangle' style={{ marginRight: '5px' }}>
                        </i><span style={{ fontSize: "11px" }}>{this.props.showPaymentDueAlert ? this.props.paymentDueAlertMessage : ""}</span></Alert> : " "}
                    <FormGroup className="row">
                        <Col className="text-left padT08" sm={3} as={FormLabel}>
                            Authority
                        </Col>
                        <Col sm={9}>
                             <select className="form-control"
                                id="authority"
                                value={this.props.selectedAuthority }
                                onChange={this.props.authorityChange}
                                data-test-auto="8BC82CE4-BDBD-4CC6-9CD9-C774D37E4EBE"
                            >
                                <option value={0} data-abbreviation=''>Select</option>
                                {this.props.taxingAuthority.map((authority, index) => {
                                    return <option key={index} value={authority.Id} data-abbreviation={authority.Abbreviation}>
                                        {authority.AuthorityName}
                                    </option>
                                })}
                            </select>
                               
                        </Col>
                    </FormGroup>
                    <FormGroup className="row">
                        <Col className="text-left padT08" sm={9} as={FormLabel}>
                            <Form.Check
                                        type="radio"
                                        name="paymentGroup"
                                        id={"PaymentDue"}
                                        onChange={this.props.paymentChanged}
                                        checked={this.props.isPayment}
                                        inline data-test-auto="A43895BA-46A2-4587-8BE4-E11CC68491D9"
                                        value={"typ1"}
                                        label="Payment Due"
                                    />
                        </Col>
                        <Col className="text-left padT08" sm={9} as={FormLabel}>
                                    <Form.Check
                                        type="radio"
                                        name="paymentGroup"
                                        id={"Refund"}
                                         onChange={this.props.refundChanged}
                                        checked={this.props.isRefund}
                                        inline data-test-auto="9D01956A-679D-4797-8053-C123FCD85C68"
                                        value={"typ2"}
                                        label="Refund" />
                        </Col>
                    </FormGroup>
                    <FormGroup className="row">
                        <Col className="text-left padT08 payment-refund-overpayment-lable" sm={3} as={FormLabel}>
                            {this.props.isPayment ? 'Amount' : 'Overpayment'}
                         </Col>
                        <Col sm={9}>
                            <CurrencyTextBox
                                value={this.props.amount}
                                placeholder="Amount"
                                onChange={this.props.amountChanged}
                                allowNegative={true}
                            />
                        </Col>
                    </FormGroup>
                    
                    {
                        !this.props.isPayment ? <div>
                            <FormGroup className="row">
                                <Col className="text-left padT08" sm={3} as={FormLabel}>
                                    Applied
                                </Col>
                                <Col sm={9}>
                                     <CurrencyTextBox
                                        value={this.props.overPayAppliedAmount}
                                        placeholder="Over Payment Applied"
                                        onChange={this.props.overPayAppliedAmountChanged}
                                        allowNegative={true}
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup className="row">
                                <Col className="text-left padT08" sm={3} as={FormLabel}>
                                    Penalty
                                </Col>
                                <Col sm={9}>
                                     <CurrencyTextBox
                                        value={this.props.penalty}
                                        placeholder="Penalty"
                                        onChange={this.props.onChangePenaltyAmount}
                                        allowNegative={true}
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup className="row">
                                <Col className="text-left padT08" sm={3} as={FormLabel}>
                                    Refund
                                </Col>
                                <Col sm={9}>
                                     <CurrencyTextBox
                                        value={this.props.netRefund}
                                        placeholder="Total Amount"
                                        disabled={true}
                                        allowNegative={true}
                                    />
                                </Col>
                            </FormGroup>
                        </div> :
                            <div></div>}
                    <FormGroup className="clearfix">
                        <Row>
                            <Col sm={7} md={7}>
                                 <Button
                                     variant="default"
                                     data-test-auto="80EA469C-FB12-4719-8690-F5240246AB60"
                                     className="btn-white float-right"
                                     title="Cancel"
                                     onClick={() => { this.props.onCancel(this.props.currentPage) }}>
                                     <i className="fas fa-times"></i>
                                     Cancel
                                 </Button>
                                
                            </Col>
                            <Col sm={4} md={4}>
                                <Button
                                    data-test-auto="0DE50D3E-5242-449F-817E-B64AA0834C5D"
                                    variant="btn btn-info float-right"
                                    title="Save Changes"
                                    disabled={!this.props.enableUpdate}
                                    onClick={this.props.onSaveClick} style={{ marginLeft: '3%' }}>
                                    <i className="fas fa-check"></i>
                                    Update
                            </Button>
                            </Col>
                        </Row>
                    </FormGroup>

                </Card.Body>
            </Accordion.Collapse>
        </Card>
        </Accordion>)
    }
}