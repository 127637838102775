import React, { FC } from 'react'
import { Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { signinRedirect, signinRedirectWithCallbackArgs } from '../oidcClient/userService'
import { ApplicationState } from '../store/types'
import LoadingView from './LoadingView'

const Login: FC = (props: any) => {
    const { user, isLoadingUser } = useSelector((state: ApplicationState) => state.auth)
    if (!user) {
        if (isLoadingUser) {
            return <LoadingView />
        }
        if (props.location && props.location.state
            && props.location.state.redirectedFrom && props.location.state.redirectedFrom.pathname!='/') {
            signinRedirectWithCallbackArgs(props.location.state.redirectedFrom.pathname
                + (props.location.state.redirectedFrom.search ? props.location.state.redirectedFrom.search : '')
            );
        } else {
            signinRedirect();
        }
        return <></>
    }

    return <Redirect to={'/'} />
}

Login.displayName = 'Login'

export default Login
