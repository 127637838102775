import { addTask } from 'domain-task';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../../';
import { actionTypes } from '../../../types/ActionTypes';

import { StatusType, NotificationAction } from '../../common/NotificationStore';

import {
    RequestVoucherReminderStatusAction,
    ReceiveVoucherReminderStatusAction,
    RequestVoucherReminderReportPagesAction,
    ReceiveVoucherReminderReportPagesAction,
    GetArchivedReminderReportEndDateAction
} from "./../KnownTypes";
import { VoucherReminderReportState, VoucherReminderReportTableModel } from './VoucherReminderManagementState';
import { API_BASE_URL } from '../../../utils/constants';
import { IFileUtilities } from '../../../Core/Utilities/File/FileUtilities';
import { container } from '../../../Startup/inversify.config';
import { TYPES } from '../../../Startup/types';

const fileUtilities = container.get<IFileUtilities>(TYPES.IFileUtilities);

type KnownAction =
    RequestVoucherReminderStatusAction |
    ReceiveVoucherReminderStatusAction |
    RequestVoucherReminderReportPagesAction |
    ReceiveVoucherReminderReportPagesAction |
    GetArchivedReminderReportEndDateAction;

type AllKnownAction =
    RequestVoucherReminderStatusAction |
    ReceiveVoucherReminderStatusAction |
    RequestVoucherReminderReportPagesAction |
    ReceiveVoucherReminderReportPagesAction |
    GetArchivedReminderReportEndDateAction |
    NotificationAction;


export const actionCreators = {

    requestVoucherReminderReport: (query: string, callback: () => string, reload: boolean = false): AppThunkAction<AllKnownAction> => (dispatch, getState) => {
        let state = getState();
        const reminderReportUrl: string = callback();

        if (reminderReportUrl !== "") {
            if (reload || query !== state.voucherReminderReport.query) {

                let page = state.voucherReminderStatusPages[query];
                if (!reload && page) {

                    dispatch({ type: actionTypes.REQUEST_VOUCHER_REMINDER_REPORT, query: query });

                    dispatch({
                        type: actionTypes.RECEIVE_VOUCHER_REMINDER_REPORT,
                        query: query,
                        table: page.voucherReminderReportTableModel
                    });

                    return;
                }
                const fetchTask = fetch(API_BASE_URL + `api/Reports/VoucherReminderManagement/${reminderReportUrl}` + query, {
                    method: 'GET',
                    credentials: 'include'
                })
                    .then(function (response ) {
                        return response.json() as Promise<VoucherReminderReportTableModel>
                    })
                    .then(function (data) {
                        dispatch({ type: actionTypes.RECEIVE_VOUCHER_REMINDER_REPORT, query: query, table: data });
                        dispatch({
                            type: actionTypes.RECEIVE_VOUCHER_REMINDER_REPORT_PAGES,
                            query: query,
                            table: data.reminders,
                            totalRowCount: data.count
                        });
                    })
                    .catch(function (error) {});
                addTask(fetchTask);
                dispatch({ type: actionTypes.REQUEST_VOUCHER_REMINDER_REPORT, query: query });
                dispatch({ type: actionTypes.REQUEST_VOUCHER_REMINDER_REPORT_PAGES, query: query });
            }
        }
    },
    
    exportVoucherReminderReportAsExcel: (query: string, exportVoucherReminderReportUrl: string, callback?: () => void): AppThunkAction<AllKnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(API_BASE_URL + `api/ExportToExcel/${exportVoucherReminderReportUrl}` + query, { credentials: 'include' })
            .then(response => response.blob())
            .then(blob => {
                const url = window.URL.createObjectURL(new Blob([blob]));
                fileUtilities.download(url, "Reminders.xlsx");
                if (callback) {
                    callback();
                }
            }).catch((error) => {
                const statusMessage: any = error.statusText?.message ?? error.statusText;
                if (typeof(statusMessage) === "string") {
                    dispatch({ type: actionTypes.NOTIFICATION, statusMessage: statusMessage, statusType: StatusType.Error });
                }
                if (callback) {
                    callback();
                }
            });
        addTask(fetchTask);
    },

    getArchivedReminderReportEndDate: (callback: () => void): AppThunkAction<AllKnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(API_BASE_URL + 'api/Reports/VoucherReminderManagement/GetArchivedReminderReportEndDate', {
            method: 'GET',
            credentials: 'include'
        })
            .then(response => response.json() as Promise<string>)
            .then(data => {
                if (typeof data === "string") {
                    dispatch({ type: actionTypes.GET_ARCHIVED_REMINDER_REPORT_END_DATE, date: data })
                }
            })
            .then(() => {
                if (callback) {
                    callback();
                }
            })
            .catch(error => {
                dispatch({ type: actionTypes.NOTIFICATION, statusMessage: error.statusText?.message ?? error.statusText, statusType: StatusType.Error });
            })

        addTask(fetchTask);
    }
};


const unloadedState: VoucherReminderReportState = {
    voucherReminderReportTableModel: {
        reminders: [],
        count: 0
    } as VoucherReminderReportTableModel,
    loading: true,
    query: '',
    totalRowCount: 0,
    archivedReminderReportEndDate: ''
} as VoucherReminderReportState;

export const reducer: Reducer<VoucherReminderReportState> = (state = unloadedState, incomingAction) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case actionTypes.REQUEST_VOUCHER_REMINDER_REPORT:
            return ({
                ...state,
                query: action.query,
                loading: true,
                totalRowCount: 0,
                voucherReminderReportTableModel: {
                    reminders: [],
                    count: 0
                } as VoucherReminderReportTableModel
            }) as VoucherReminderReportState;
        case actionTypes.RECEIVE_VOUCHER_REMINDER_REPORT:
            return {
                ...state,
                query: action.query,
                voucherReminderReportTableModel: action.table,
                totalRowCount: action.table.count,
                loading: false
            } as VoucherReminderReportState;
        case actionTypes.GET_ARCHIVED_REMINDER_REPORT_END_DATE:
            return {
                ...state,
                archivedReminderReportEndDate: action.date
            } as VoucherReminderReportState
    }

    return state;
};

