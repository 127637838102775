import { addTask } from 'domain-task';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../';
import { actionTypes } from '../../types/ActionTypes';
import { IInboxMessage, MessageCategory, MessageType } from '../../Core/ViewModels/User/InboxMessage';
import { handleResponse } from '../Library';
import * as Constants from '../../components/helper/Constants';
import { StatusType, NotificationAction } from '../common/NotificationStore';
import { API_BASE_URL } from '../../utils/constants';

export interface IInboxState {
    inboxMessages: IInboxMessage[];
    isLoading: boolean;
}

export interface ReceiveInboxMessagesAction {
    type: actionTypes.RECEIVE_INBOX_MESSAGES;
    inboxMessages: IInboxMessage[];
}

export interface UpdateInboxMessageAction {
    type: actionTypes.UPDATE_INBOX_MESSAGE;
    updatedMessage: IInboxMessage;
}

export interface InboxMessageArrivalAction {
    type: actionTypes.INBOX_MESSAGE_ARRIVAL;
    newMessage: IInboxMessage;
}

export interface ResponseInboxMessageClearAllAction {
    type: actionTypes.REQUEST_INBOX_MESSAGE_CLEARALL;
    isLoading: boolean;
}

export interface RequestInboxMessageClearAllAction {
    type: actionTypes.RESPONSE_INBOX_MESSAGE_CLEARALL;
    updatedMessage: IInboxMessage[];
}

type DispatchAction = ReceiveInboxMessagesAction |
    UpdateInboxMessageAction |
    InboxMessageArrivalAction |
    RequestInboxMessageClearAllAction |
    ResponseInboxMessageClearAllAction |
    NotificationAction

export const actionCreators = {
    getInboxMessages: (): AppThunkAction<DispatchAction> => (dispatch, getState) => {
        let fetchTask = fetch(API_BASE_URL + 'api/Inbox/GetInboxMessages', {
            credentials: 'include',
            method: 'GET',
        }).then(handleResponse)
            .then(json => json as Promise<IInboxMessage[]>)
            .then(data => {
                dispatch({ type: actionTypes.RECEIVE_INBOX_MESSAGES, inboxMessages: data })
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: Constants.InboxConstants.InboxFetchError,
                    statusType: StatusType.Error
                });
            });
        addTask(fetchTask);
    },
    updateInboxMessage: (inboxMessage: IInboxMessage): AppThunkAction<DispatchAction> => (dispatch, getState) => {
        let fetchTask = fetch(API_BASE_URL + 'api/Inbox/', {
            method: 'PUT',
            credentials: 'include',
            body: JSON.stringify(inboxMessage),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
            }
        })
            .then(handleResponse)
            .then(() => {
                let action: any = actionCreators.getInboxMessages();
                dispatch(action);
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: Constants.InboxConstants.InboxMessageUpdateError, statusType: StatusType.Error
                });
            });
        addTask(fetchTask);
    },
    inboxMessageArrived: (inboxMessage: IInboxMessage): AppThunkAction<DispatchAction> => (dispatch, getState) => {
        dispatch({
            type: actionTypes.INBOX_MESSAGE_ARRIVAL,
            newMessage: inboxMessage
        });
    },
    updateInboxMessageForClearAll: (inboxMessages: IInboxMessage[]): AppThunkAction<DispatchAction> => (dispatch, getState) => {
        dispatch({ type: actionTypes.REQUEST_INBOX_MESSAGE_CLEARALL, isLoading: true });
        let fetchTask = fetch(API_BASE_URL + 'api/Inbox/UpdateDeletedMessages', {
            method: 'PUT',
            credentials: 'include',
            body: JSON.stringify(inboxMessages),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
            }
        })
            .then(handleResponse)
            .then(() => {
                dispatch({
                    type: actionTypes.RESPONSE_INBOX_MESSAGE_CLEARALL, updatedMessage: inboxMessages
                });
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: Constants.InboxConstants.InboxMessageClearError, statusType: StatusType.Error
                });
            });
        addTask(fetchTask);
    }
}

const unloadedState: IInboxState = {
    inboxMessages: [],
    isLoading: false
};

export const reducer: Reducer<IInboxState> = (state = unloadedState, incomingAction) => {
    const action = incomingAction as DispatchAction;
    switch (action.type) {
        case actionTypes.RECEIVE_INBOX_MESSAGES:
            var inboxState = { ...state };
            inboxState.inboxMessages = action.inboxMessages;
            return inboxState;
        case actionTypes.UPDATE_INBOX_MESSAGE:
            var inboxState = { ...state };
            const index = inboxState.inboxMessages.findIndex(m => m.id == action.updatedMessage.id);
            if (index > -1) {
                inboxState.inboxMessages[index] = action.updatedMessage;
            }
            return inboxState;
        case actionTypes.INBOX_MESSAGE_ARRIVAL:
            var inboxState = { ...state };
            if (inboxState.inboxMessages.findIndex(i => i.id === action.newMessage.id) === -1 &&
                action.newMessage.messageCategory == MessageCategory.Support &&
                action.newMessage.messageType != MessageType.ProcessingItems) {
                inboxState.inboxMessages.push(action.newMessage);
            }
            return inboxState;
        case actionTypes.REQUEST_INBOX_MESSAGE_CLEARALL:
            var inboxState = { ...state };
            inboxState.isLoading = action.isLoading;
            return inboxState;
        case actionTypes.RESPONSE_INBOX_MESSAGE_CLEARALL:
            var inboxState = { ...state };
            inboxState.isLoading = false;
            action.updatedMessage.forEach((value, i, inboxState) => {
                const index = inboxState.findIndex(x => x.id == value.id);
                if (index > -1) {
                    inboxState[index] = value;
                }
            });
            return inboxState;
    }
    return state;
}
