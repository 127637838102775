import * as React from 'react';
import { QuickReportType, ReportFilterType } from '../Filters';
import { QuickReportsTable } from '../QuickReports/QuickReportsTable';
import { QuickReportPageState, QuickReportState } from './QuickReportState';
import * as Helper from '../../helper/HelperFunctions';
import { QuickReportBulkOperationQuery, BulkOperationQuery } from '../../../Core/ViewModels/Common/BulkOperationQuery';
import {  SetAccessType } from '../../../store/reports/GroupedReturns/SendGroupedReturnsState';


const pageSize: number = 20; 

interface QuickReportProp {
    quickReports: QuickReportState;
    exportQuickReportAsExcel(queryString: string, quickReportType: QuickReportType, isPaperFileEnabled: boolean,  onExportToComplete: () => void): void;
    isLoading: boolean;
    selectedQuickReport: QuickReportType;
    onSelectQuickReports: (quickReportType: QuickReportType, query?: string, callback?: () => void) => any;
    closeQuickReport: () => void;
    onRowSelect: (row: any, isSelected: any, e: any) => void;
    onSelectAll: (isSelected: any, rows: any, e: any) => void;
    selectedRows: number[];
    unSelectedRows: number[];
    showBulkSelectionMessage: boolean;
    onBulkSelectionChange: (isEnabled: boolean) => void;
    isBulkSelectionEnabled: boolean;
    onClearSelection: () => void;
    isArchived: boolean;
    isPaperFiledEnabled: boolean;
    onExportToExcelQuickReport(onExportToExcelComplete: () => void): void;
    onSignedDetailsOpen: (rowIndex: number) => void;
}



export class QuickReports extends React.Component<QuickReportProp, QuickReportPageState>{
    private filterChanged: boolean = false;
    state: QuickReportPageState = {
        page: 1,
        sortName : '',
        sortOrder : 'desc',
        searchString: '',
        filterTaxYear: new Date().getFullYear() - 1,
        filterName : '',
        filterClientId: '',
        filterStatus: '',
        filterLocation: '',
        filterDeliveredOn: undefined,
        filterPartner : '',
        filterSentBy: '',
        refreshDelay: false,
        filterSignatureStatus: '',
        filterFilingType: 0
    }

    onSortChange = (sortName: string, sortOrder: string, column: number) => {
        this.setState({
            sortName: sortName,
            sortOrder: sortOrder == "asc" ? "asc" : "desc"
        }, () => this.onFetchQuickReports(1, pageSize)
        );
    }

    onPageChange = (page: number, sizePerPage: number) => {
        if(this.props.isBulkSelectionEnabled) {

            this.setState({
                page: page,
            }, () => this.onFetchQuickReports(this.state.page, pageSize)
            );
        }
        else {
            this.props.onClearSelection();
            this.setState({
                page: page,
            }, () => this.onFetchQuickReports(this.state.page, pageSize)
            );
        }
    }

    onSearchChange = (searchString: string) => {
        this.setState({
            searchString: searchString
        }, () => this.onFetchQuickReports(1, pageSize)
        );
    }

    onClearFilter = (callback?: () => any) => {
        this.filterChanged = true;
        this.setState({
            page: 1,
            sortName: "",
            sortOrder: 'desc',
            searchString: "",
            filterTaxYear: new Date().getFullYear() - 1,
            filterName: "",
            filterClientId: "",
            filterStatus: "",
            filterLocation: "",
            filterDeliveredOn: undefined,
            filterPartner: "",
            filterSentBy: "",
            filterSignatureStatus: "",
            filterFilingType: 0
        },
            () => callback && callback()
        );
        this.filterChanged = false;
    }

    onSelectQuickReports = (quickReportType: QuickReportType, query?: string, callback?: () => void) => {                 
            this.props.onSelectQuickReports(quickReportType);           
            this.props.onClearSelection();
    }

    onFilterChange = (dataField: any) => {
        if (!this.filterChanged) {
            let newState: QuickReportPageState = {
                filterName: "",
                filterClientId: "",
                filterPartner: "",
                filterSentBy: "",
                filterDeliveredOn: undefined,
                filterFilingType: 0
            } as QuickReportPageState;
            let filterStatus: any;
            let filterTaxYear: any;
            let filterLocation: any;
            let isClearFilter = true;
            let filterSignatureStatus: any;
            let filterFilingType: any;
            var dict: { [columnName: string]: string; } = {};

            for (let field of Object.keys(dataField)) {
                var data = dataField[field.valueOf()].value ? dataField[field.valueOf()].value : dataField[field.valueOf()];
                if (isClearFilter && data !== "" && data !== "-1") {
                    isClearFilter = false;
                }
                dict[field.valueOf().toString()] = data;

                switch (field) {
                    case 'taxDocumentName':
                        newState.filterName = dataField[field].value ? dataField[field].value : dataField[field];
                        break;
                    case 'clientId':
                        newState.filterClientId = dataField[field].value ? dataField[field].value : dataField[field];
                        break;
                    case 'partner':
                        newState.filterPartner = dataField[field].value ? dataField[field].value : dataField[field];
                        break;
                    case 'deliveredBy':
                        newState.filterSentBy = dataField[field].value ? dataField[field].value : dataField[field];
                        break;
                    case 'status':
                        filterSignatureStatus = dataField[field].value ? dataField[field].value : dataField[field];
                        filterSignatureStatus = (filterSignatureStatus == "-1") ? "" : filterSignatureStatus;
                        this.setState({ filterSignatureStatus: filterSignatureStatus });
                        newState.filterSignatureStatus = ((dataField[field].value) && (dataField[field].value !== "-1")) ? dataField[field].value : "";
                        break;
                    case 'locationName':
                        filterLocation = dataField[field].value ? dataField[field].value : dataField[field];
                        filterLocation = (filterLocation == "-1") ? "" : filterLocation;
                        this.setState({ filterLocation: filterLocation });
                        newState.filterLocation = ((dataField[field].value) && (dataField[field].value !== "-1")) ? dataField[field].value : "";
                        break;
                    case 'linkStatus':
                    case 'kbaStatus':
                        filterStatus = dataField[field].value ? dataField[field].value : dataField[field];
                        filterStatus = (filterStatus == "-1") ? "" : filterStatus;
                        this.setState({ filterStatus: filterStatus });
                        newState.filterStatus = ((dataField[field].value) && (dataField[field].value !== "-1")) ? dataField[field].value : "";
                        break;
                    case 'deliveredDate':
                        newState.filterDeliveredOn = Helper.GetNumberOfDays(dataField[field].value ? dataField[field].value : dataField[field]);
                        break;
                    case 'taxYear':
                        filterTaxYear = dataField[field].value ? dataField[field].value : dataField[field];
                        filterTaxYear = (filterTaxYear == "-1") ? "" : filterTaxYear;
                        this.setState({ filterTaxYear: filterTaxYear });
                        newState.filterTaxYear = ((dataField[field].value) && (dataField[field].value !== "-1")) ? dataField[field].value : "";
                        break;
                    case 'documentFilingType':
                        filterFilingType = dataField[field].value ? dataField[field].value : dataField[field];
                        filterFilingType = (filterFilingType == "-1") ? "" : filterFilingType;
                        this.setState({ filterFilingType: filterFilingType });
                        newState.filterFilingType = ((dataField[field].value) && (dataField[field].value !== "-1")) ? dataField[field].value : "";
                        break;
                }
            }
            if (this.isFilterChanged(newState, filterStatus, filterTaxYear, filterSignatureStatus)) {
                this.setState({ ...newState, page: 1, },
                    () => this.onFetchQuickReports(this.state.page, pageSize)
                );
            }
        }
        
    }

    private isFilterChanged(newState: QuickReportPageState, filterStatus: any,  filterTaxYear: any, filterSignatureStatus:any): boolean {
        return (
            newState.filterName !== this.state.filterName ||
            newState.filterClientId !== this.state.filterClientId ||
            newState.filterPartner !== this.state.filterPartner ||
            newState.filterSentBy !== this.state.filterSentBy ||
            newState.filterDeliveredOn !== this.state.filterDeliveredOn ||
            newState.filterStatus !== this.state.filterStatus ||
            newState.filterLocation !== this.state.filterLocation ||
            newState.filterTaxYear !== this.state.filterTaxYear ||
            newState.filterSignatureStatus !== this.state.filterSignatureStatus ||
            newState.filterFilingType !== this.state.filterFilingType)
    }

    onFetchQuickReports = (page: number, sizePerPage: number, callback?: () => void) => {
        let query = this.buildQuery(page, sizePerPage);
        this.props.onSelectQuickReports(this.props.selectedQuickReport, query, callback && callback);
    }

    buildQuery(page: number, pageSize: number) {
        return '?PageNo=' + page
            + '&SortBy=' + this.state.sortName
            + '&SortOrder=' + this.state.sortOrder
            + '&SearchString=' + this.state.searchString
            + '&selectedTaxYear=' + this.state.filterTaxYear
            + '&PageSize=' + pageSize
            + '&filterName=' + encodeURIComponent(this.state.filterName)
            + '&filterClientId=' + encodeURIComponent(this.state.filterClientId)
            + '&filterPartner=' + encodeURIComponent(this.state.filterPartner)
            + '&filterStatus=' + this.state.filterStatus
            + '&filterLocation=' + this.state.filterLocation
            + '&filterDeliveredOn=' + (this.state.filterDeliveredOn || "")
            + '&filterSentBy=' + encodeURIComponent(this.state.filterSentBy)
            + '&filterSignatureStatus=' + this.state.filterSignatureStatus
            + '&isArchived=' + this.props.isArchived
            + '&filterFilingType=' + this.state.filterFilingType;
    }


    onExportToExcel = (quickReportType: QuickReportType, onExportToExcelComplete: () => void) => {
        this.props.onExportToExcelQuickReport(onExportToExcelComplete);
    }

    getBulkOperationQuery = (): BulkOperationQuery => {
        let query: BulkOperationQuery = {
            searchText: this.state.searchString,
            clientId: this.state.filterClientId,
            customColumn: '',
            downloadedBy: '',
            name: this.state.filterName,
            partner: this.state.filterPartner,
            deliveredOn: this.state.filterDeliveredOn,
            engagementType: 0,
            sentBy: this.state.filterSentBy,
            status: this.state.filterSignatureStatus,
            officeLocation: this.state.filterLocation,
            isArchived: this.props.isArchived,
            taxYear: this.state.filterTaxYear === 0 ? '' : this.state.filterTaxYear.toString(),
            unSelectedIds: [],
            assignedTo: '',
            reportType:
                this.props.selectedQuickReport === QuickReportType.LinkNotAccessed
                    ? ReportFilterType.LinkNotAccessed
                    : ReportFilterType.KBAFailed, 
            documentFilingType: this.state.filterFilingType,
            sortBy:this.state.sortName,
            sortOrder:this.state.sortOrder,
            isPaperFileEnabled:this.props.isPaperFiledEnabled,
            quickReportStatus: this.state.filterStatus,
            groupName: '',
            taxSoftware: '',
            createdBy: '',
            globalSearch:'',
            setAccess: SetAccessType.None
        }
        return query
    }

    onPageReload = () => {
        this.setState(
            { refreshDelay: true }, () => {
                this.onFetchQuickReports(this.state.page, pageSize, () => {
                    this.setState({ refreshDelay: false });
                });
            });
    }

    getQuickReportBulkOperationQuery = (): QuickReportBulkOperationQuery => {
        let query: QuickReportBulkOperationQuery = {
            clientId: this.state.filterClientId,
            name: this.state.filterName,
            partner: this.state.filterPartner,
            deliveredOn: this.state.filterDeliveredOn,
            sentBy: this.state.filterSentBy,
            status: this.state.filterSignatureStatus,
            location: this.state.filterLocation,
            taxYear: this.state.filterTaxYear.toString(),
            unSelectedIds: this.props.unSelectedRows,
            customStatus: this.state.filterStatus,
            engagementType: 0,
            quickReportType: this.props.selectedQuickReport
        }

        return query;
    }
    
    public render() {
        return <div>
            <QuickReportsTable
                isLoading={this.props.quickReports.loading}
                onExportToExcel={this.onExportToExcel}
                onFilterChange={this.onFilterChange}
                onPageChange={this.onPageChange}
                onPageReload={this.onPageReload}
                onSearchChange={this.onSearchChange}
                onSortChange={this.onSortChange}
                selectedQuickReport={this.props.selectedQuickReport}
                refreshDelay={this.state.refreshDelay}
                pageNo={this.state.page}
                pageSize={pageSize}
                totalRows={this.props.quickReports.totalRowCount}
                onSelectQuickReports={this.onSelectQuickReports}
                closeQuickReport={this.props.closeQuickReport}
                quickReports={this.props.quickReports}
                onRowSelect={this.props.onRowSelect}
                onSelectAll={this.props.onSelectAll}
                selectedRows={this.props.selectedRows}
                unSelectedRows={this.props.unSelectedRows}
                showBulkSelectionMessage={this.props.showBulkSelectionMessage}
                onBulkSelectionChange={this.props.onBulkSelectionChange}
                isArchived={this.props.isArchived}
                onClearFilter={this.onClearFilter}
                isPaperFiledEnabled={this.props.isPaperFiledEnabled}
                onSignedDetailsOpen={this.props.onSignedDetailsOpen}
            />
        </div>
    }
}

export default QuickReports;
