import * as React from 'react';
import { Alert, Modal, Col, FormLabel, Button, OverlayTrigger, Popover, Form } from 'react-bootstrap';
import 'react-overlay-loader/styles.css';
import { SignatureStatus } from '../../common/TaxReturn';
import { IDeliveredTaxDocument } from '../../common/DeliveredTaxReturns';
import { DropdownComponent } from '../../common/controls/DropdownComponent';
import { ChangeStatusConstants } from '../../helper/Constants';
import { getStatus } from '../../helper/HelperFunctions';
import { VenusNotifier } from '../../helper/VenusNotifier';
import { ISignatureStatusList } from '../../../store/reports/DeliveredReturnsState'; 

interface IChangeStatusProps {
    show: boolean;
    onChangeStatusSave(documentId: number, status: SignatureStatus, reason?: string): void;
    onCancel: () => void;
    showLoader: boolean;
    model: IDeliveredTaxDocument;
}
interface IChangeStatusState {
    selectedReturnStatus: SignatureStatus;
    saving: boolean;
    alert: string;
    message: string;
    hideAlert: boolean;
    reasonText: string;
    signatureList: any[];
}
export class DeliveredGroupedIndividualChangeStatusModal extends React.Component<IChangeStatusProps, IChangeStatusState>{
    constructor(props: IChangeStatusProps) {
        super(props);
        this.state = {
            selectedReturnStatus: SignatureStatus.None,
            saving: false,
            alert: "warning",
            message: ChangeStatusConstants.ChangeDeliveredReturnStatus,
            hideAlert: true,
            reasonText: "",
            signatureList:[]
        }
        this.onSubmit = this.onSubmit.bind(this);
        this.onStatusChange = this.onStatusChange.bind(this);
        this.clearState = this.clearState.bind(this);
        this.onCheckStatus = this.onCheckStatus.bind(this);
        this.onValidate = this.onValidate.bind(this);
    }

    componentWillReceiveProps(nextProps: IChangeStatusProps) {
        if (nextProps.model && !this.state.saving && this.props.model != nextProps.model) {
            let signatureList = this.PrepareSignatureList(nextProps.model);
            this.setState({ signatureList: signatureList});
        }
        if (!nextProps.show) {
            this.setState({ saving: false });
        }
    }

    private onStatusChange(value: any) {
        this.showWarning(value);
        let signatureStatus = Number(parseInt(value ? value : "0")) as SignatureStatus;
        this.setState({ selectedReturnStatus: signatureStatus });
    }

    private showWarning(value: any) {
        if (value == SignatureStatus.SignedAndESigned) {
            this.setState({ alert: "warning", message: ChangeStatusConstants.ChangeDeliveredReturnStatus, hideAlert: false })
        }
        else
            this.setState({ hideAlert: true });
    }

    private clearState() {
        this.setState({ hideAlert: true, reasonText: "" });
        this.props.onCancel();
    }

    private onValidate() {
        let isValid: boolean = true;
        if (this.state.selectedReturnStatus == 0) {
            VenusNotifier.Warning(ChangeStatusConstants.SelectedStatus, null)
            isValid = false;
        }
        else if (this.state.selectedReturnStatus === SignatureStatus.SignedAndESigned && this.state.reasonText.trim() == "") {
            VenusNotifier.Warning(ChangeStatusConstants.EmptyReason, null)
            isValid = false;
        }
        return isValid;
    }

    private onSubmit() {
        let result;
        result = this.onValidate();
        if (result == true) {
            this.props.onChangeStatusSave(this.props.model.document.id, this.state.selectedReturnStatus, this.state.reasonText);
            document.body.click();
            this.setState({ hideAlert: true, reasonText: "" });
        }
    }

    private onPopoverCancel() {
        document.body.click();
    }

    private onCheckStatus() {
        let isValid: boolean = true;
        if (this.state.selectedReturnStatus == 0) {
            isValid = false;
        }
        else if (this.state.selectedReturnStatus === SignatureStatus.SignedAndESigned && this.state.reasonText.trim() == "") {
            isValid = false;
        }
        return isValid;
    }

    private PrepareSignatureList = (taxReturn: IDeliveredTaxDocument) => {
        var arrayOption: any = [];
        let _signatureStatusList: ISignatureStatusList[] = [];
        let _signatureStatusAwaitingEsign: any = SignatureStatus[SignatureStatus.AwaitingESign]
        let _signatureStatusAwaitingUpload: any = SignatureStatus[SignatureStatus.AwaitingUpload]
        let _signatureStatusManuallySigned: any = SignatureStatus[SignatureStatus.ManuallySigned]
        let _signatureStatusPartiallySigned: any = SignatureStatus[SignatureStatus.PartiallySigned]
        //let taxReturn = this.props.model;
        let selectedStatus = SignatureStatus.None;
        if (taxReturn) {
            if (taxReturn.document.signatureStatus == _signatureStatusAwaitingEsign ||
                taxReturn.document.signatureStatus == _signatureStatusAwaitingUpload ||
                taxReturn.document.signatureStatus == _signatureStatusManuallySigned ||
                taxReturn.document.signatureStatus == _signatureStatusPartiallySigned) {

                if (taxReturn.document.signatureStatus == _signatureStatusAwaitingEsign) {
                    if (taxReturn.signedCount == 0) {
                        _signatureStatusList.push({ value: SignatureStatus.AwaitingESign, name: SignatureStatus[SignatureStatus.AwaitingESign] });
                        _signatureStatusList.push({ value: SignatureStatus.ManuallySigned, name: SignatureStatus[SignatureStatus.ManuallySigned] });
                        selectedStatus = SignatureStatus.AwaitingESign
                    }
                    else {
                        _signatureStatusList.push({ value: SignatureStatus.AwaitingESign, name: SignatureStatus[SignatureStatus.AwaitingESign] });
                        _signatureStatusList.push({ value: SignatureStatus.SignedAndESigned, name: SignatureStatus[SignatureStatus.SignedAndESigned] });
                        selectedStatus = SignatureStatus.AwaitingESign
                    }
                } else if (taxReturn.document.signatureStatus == _signatureStatusPartiallySigned) {
                    _signatureStatusList.push({ value: SignatureStatus.PartiallySigned, name: SignatureStatus[SignatureStatus.PartiallySigned] });
                    _signatureStatusList.push({ value: SignatureStatus.SignedAndESigned, name: SignatureStatus[SignatureStatus.SignedAndESigned] });
                    selectedStatus = SignatureStatus.PartiallySigned
                }
                else if (taxReturn.document.signatureStatus == _signatureStatusAwaitingUpload) {
                    _signatureStatusList.push({ value: SignatureStatus.AwaitingUpload, name: SignatureStatus[SignatureStatus.AwaitingUpload] });
                    _signatureStatusList.push({ value: SignatureStatus.ManuallySigned, name: SignatureStatus[SignatureStatus.ManuallySigned] });
                    selectedStatus = SignatureStatus.AwaitingUpload
                }
                else if (taxReturn.document.signatureStatus == _signatureStatusManuallySigned) {
                    _signatureStatusList.push({ value: SignatureStatus.ManuallySigned, name: SignatureStatus[SignatureStatus.ManuallySigned] });
                    _signatureStatusList.push({ value: SignatureStatus.AwaitingUpload, name: SignatureStatus[SignatureStatus.AwaitingUpload] });
                    _signatureStatusList.push({ value: SignatureStatus.AwaitingESign, name: SignatureStatus[SignatureStatus.AwaitingESign] });
                    selectedStatus = SignatureStatus.ManuallySigned
                }
            }
            else if (taxReturn.document.signatureStatus == _signatureStatusAwaitingUpload || taxReturn.document.signatureStatus == _signatureStatusAwaitingEsign) {
                _signatureStatusList.push({ value: SignatureStatus.ManuallySigned, name: SignatureStatus[SignatureStatus.ManuallySigned] });
                selectedStatus = SignatureStatus.ManuallySigned
            }
            this.setState({
                selectedReturnStatus: selectedStatus
            });
        }
        _signatureStatusList.map((i: any) => {
            let text: string = getStatus(parseInt(i.value));
            arrayOption.push({
                value: i.value,
                label: text
            })
        })
        return arrayOption;
    }

    private onReasonTextChange = (event: any) => {
        let reason = Object.assign({}, this.state.reasonText);
        reason = event.target.value;
        this.setState({ reasonText: reason })
    }

    public render() {

        const popover = (
            <Popover id="grouped-individual-status-change-modal-popover">
                <Popover.Title as="h3">Confirmation</Popover.Title>
                <Popover.Content>
                    {ChangeStatusConstants.changeStatusConfirmation}
                    <div className="popover-footer">
                        <button
                            className="btn btn-only btn-success"
                            onClick={this.onSubmit}
                            title="OK"
                            data-test-auto="BCDBF6AB-57E0-4AB7-BAB2-580203E088C8"
                            style={{ marginLeft: "5px" }}>
                            Yes
                    </button>
                        <button
                            onClick={this.onPopoverCancel}
                            title="Cancel"
                            data-test-auto="8AAB3A64-BD99-411A-9F63-E627D51F1F59"
                            className="btn btn-only btn-danger"
                            style={{ marginLeft: "5px" }}>
                            No
                     </button>
                    </div>
                </Popover.Content>
            </Popover>
        );
        return (<Modal
            className="report-change-status-modal"
            show={this.props.show}
            onHide={this.props.onCancel}>
            <Modal.Header closeButton>
                <Modal.Title className='custom-modal-header'>
                    <span className='text-secondary fas fa-random' style={{ color: 'grey', marginRight: '5px' }}>
                    </span>Change Status
                </Modal.Title>
            </Modal.Header>
            <Form>
                <Modal.Body>
                    <Alert variant={this.state.alert} hidden={this.state.hideAlert} ><i className='fas fa-exclamation-triangle' style={{ marginRight: '5px' }}>
                    </i>{this.state.message}</Alert>
                    <div>
                        <div className="row marB03">
                            <Col sm={12}>
                                <div>
                                    <FormLabel>Status</FormLabel>
                                    <DropdownComponent
                                        id="ddlReportStatus"
                                        selectedValue={this.state.selectedReturnStatus}
                                        onChange={this.onStatusChange}
                                        options={this.state.signatureList}
                                    />
                                </div>
                            </Col>
                        </div>
                    </div>
                    {this.state.hideAlert === false &&
                        <div>
                            <label>Reason</label>
                            <textarea
                                data-test-auto="E1A5D15F-F20D-40B0-BE5D-F593E6EDB4D2"
                                className="form-control"
                                rows={5}
                                value={this.state.reasonText}
                                onChange={this.onReasonTextChange}
                            />
                        </div>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button data-test-auto="3CDA1A83-2AEA-4211-9469-1562431A601D"
                        variant="default"
                        onClick={this.clearState}><i className='fas fa-times'></i>Cancel</Button>

                    {(this.onCheckStatus() === true) ?
                        <OverlayTrigger rootClose trigger="click" onEnter={() => document.body.click()} placement="top" overlay={popover} >
                            <Button variant="info"
                                data-test-auto="524CC1BF-10E5-4940-BBD9-36BD4208AE59">
                                <i className='fas fa-save'></i>Save
                            </Button>
                        </OverlayTrigger> :
                        <Button variant="info"
                            data-test-auto="524CC1BF-10E5-4940-BBD9-36BD4208AE59"
                            onClick={this.onValidate}>
                            <i className='fas fa-save'></i>Save
                        </Button>
                    }
                </Modal.Footer>
            </Form>
        </Modal>)
    }
}
