import { addTask } from 'domain-task';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../../store';
import { actionTypes } from '../../types/ActionTypes';
import { IAttachmentInstruction } from '../../Core/ViewModels/CompanySettings/ClientInstructionViewModel';
import { ICompanySettings } from '../../Core/ViewModels/Company/CompanySettingsViewModel';
import { handleResponse } from '../../store/Library';
import { NotificationAction, StatusType } from '../../store/common/NotificationStore';
import * as SSRSettingsStore from '../company/settings/SSRSettingsStore';
import * as Constants from '../../components/helper/Constants';
import { API_BASE_URL } from '../../utils/constants';
import { ISSRSettings } from '../../Core/ViewModels/Company/Settings/SSRSettingsViewModel';

export interface IAttachmentInstructionState {
    attachmentInstructions: IAttachmentInstructionDictionary;
    isLoading: boolean;
}

export interface IAttachmentInstructionDictionary {
    [index: number]: AttachmentInstructionData;
}

export interface AttachmentInstructionData {
    attachmentInstruction: IAttachmentInstruction;
}

export interface RequestAttachmentInstructions {
    type: actionTypes.REQUEST_ATTACHMENT_INSTRUCTIONS;
}

export interface ReceiveAttachmentInstructions {
    type: actionTypes.RECEIVE_ATTACHMENT_INSTRUCTIONS;
    attachmentInstructions: IAttachmentInstruction[];
}

export interface ReceiveAttachmentInstruction {
    type: actionTypes.RECEIVE_ATTACHMENT_INSTRUCTION;
    attachmentInstruction: IAttachmentInstruction;
    instructionId: number;
}

export interface DeleteAttachmentInstruction {
    type: actionTypes.DELETE_ATTACHMENT_INSTRUCTION;
    attachmentInstruction: IAttachmentInstruction;
}

type DispatchAction = RequestAttachmentInstructions |
    ReceiveAttachmentInstructions |
    ReceiveAttachmentInstruction |
    DeleteAttachmentInstruction |
    NotificationAction;

export const actionCreators = {
    requestAttachmentInstructions: (): AppThunkAction<DispatchAction> => (dispatch, getState) => {
        const fetchTask = fetch(API_BASE_URL + 'api/AttachmentInstruction/GetAttachmentInstructions', {
            method: 'GET',
            credentials: 'include'
        })
            .then(handleResponse)
            .then(json => json as Promise<IAttachmentInstruction[]>)
            .then(data => {
                if (data) {
                    dispatch({
                        type: actionTypes.RECEIVE_ATTACHMENT_INSTRUCTIONS, attachmentInstructions: data
                    });
                }
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: Constants.CompanySettingsConstants.StatusMessage.CompanySettingsError,
                    statusType: StatusType.Error
                });
            });
        addTask(fetchTask);

        dispatch({ type: actionTypes.REQUEST_ATTACHMENT_INSTRUCTIONS });
    },

    saveAttachmentInstruction: (attachmentInstruction: IAttachmentInstruction, ssrSettings: ISSRSettings, isSetDefault: boolean, resourceId: string = ""): AppThunkAction<DispatchAction> => (dispatch, getState) => {
        const fetchTask = fetch(API_BASE_URL + 'api/AttachmentInstruction/', {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(attachmentInstruction),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-Resource-Id': resourceId,
                'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
            }
        })
            .then(handleResponse)
            .then((newId) => {
                let attachmentInstructionDetail = Object.assign({}, attachmentInstruction);
                attachmentInstructionDetail.id = newId
                if (isSetDefault && ssrSettings.defaultMessageSettings) {
                    ssrSettings.defaultMessageSettings.attachmentInstruction = newId;
                    let action: any = SSRSettingsStore.actionCreators.updateSSRSettings(ssrSettings, undefined, resourceId)
                    dispatch(action);
                    dispatch({
                        type: actionTypes.RECEIVE_ATTACHMENT_INSTRUCTION, attachmentInstruction: attachmentInstructionDetail, instructionId: newId
                    });
                }
                else {
                    dispatch({
                        type: actionTypes.RECEIVE_ATTACHMENT_INSTRUCTION, attachmentInstruction: attachmentInstructionDetail, instructionId: newId
                    });
                }
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: Constants.AttachmentInstructionsConstants.Success, statusType: StatusType.Success
                });
            }).catch(error => {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: Constants.AttachmentInstructionsConstants.SaveError,
                    statusType: StatusType.Error
                });
            });
        addTask(fetchTask);
    },

    updateAttachmentInstruction: (attachmentInstruction: IAttachmentInstruction, resourceId: string = ""): AppThunkAction<DispatchAction> => (dispatch, getState) => {
        const fetchTask = fetch(API_BASE_URL + 'api/AttachmentInstruction/', {
            method: 'PUT',
            credentials: 'include',
            body: JSON.stringify(attachmentInstruction),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-Resource-Id': resourceId,
                'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
            }
        })
            .then(handleResponse)
            .then(() => {
                dispatch({
                    type: actionTypes.RECEIVE_ATTACHMENT_INSTRUCTION, attachmentInstruction: attachmentInstruction, instructionId: attachmentInstruction.id
                });
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: Constants.AttachmentInstructionsConstants.UpdateSuccess,
                    statusType: StatusType.Success
                });
            }).catch(error => {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: Constants.AttachmentInstructionsConstants.UpdateError,
                    statusType: StatusType.Error
                });
            });
        addTask(fetchTask);
    },

    deleteAttachmentInstruction: (attachmentInstruction: IAttachmentInstruction, resourceId: string = ""): AppThunkAction<DispatchAction> => (dispatch, getState) => {
        const fetchTask = fetch(API_BASE_URL + 'api/AttachmentInstruction/', {
            method: 'DELETE',
            credentials: 'include',
            body: JSON.stringify(attachmentInstruction),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-Resource-Id': resourceId,
                'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
            }
        })
            .then(handleResponse)
            .then(() => {
                dispatch({
                    type: actionTypes.DELETE_ATTACHMENT_INSTRUCTION, attachmentInstruction: attachmentInstruction
                });
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: Constants.AttachmentInstructionsConstants.DeleteSuccess,
                    statusType: StatusType.Success
                });
            }).catch(error => {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: Constants.AttachmentInstructionsConstants.DeleteError,
                    statusType: StatusType.Error
                });
            });
        addTask(fetchTask);
    }
};

const attachmentInstructionDic: IAttachmentInstructionDictionary = [];

const unloadedState: IAttachmentInstructionState = {
    attachmentInstructions: { ...attachmentInstructionDic },
    isLoading: false,
} as IAttachmentInstructionState;

export const reducer: Reducer<IAttachmentInstructionState> = (state = unloadedState, incomingAction) => {
    const action = incomingAction as DispatchAction;
    switch (action.type) {
        case actionTypes.REQUEST_ATTACHMENT_INSTRUCTIONS:
            return {
                ...unloadedState
            } as IAttachmentInstructionState;
        case actionTypes.RECEIVE_ATTACHMENT_INSTRUCTIONS:
            var received = { ...state };
            if (!received.attachmentInstructions || !Array.isArray(received.attachmentInstructions)) {
                received.attachmentInstructions = [];
            }
            action.attachmentInstructions.forEach((item, i) => {
                received.attachmentInstructions[item.id] = { attachmentInstruction: item };
            });
            return received;
        case actionTypes.RECEIVE_ATTACHMENT_INSTRUCTION:
            var received = { ...state };
            if (!received.attachmentInstructions || !Array.isArray(received.attachmentInstructions)) {
                received.attachmentInstructions = [];
            }
            received.attachmentInstructions[action.instructionId] = { attachmentInstruction: action.attachmentInstruction };
            return received;
        case actionTypes.DELETE_ATTACHMENT_INSTRUCTION:
            var received = { ...state }
            if (received.attachmentInstructions[action.attachmentInstruction.id]) {
                delete received.attachmentInstructions[action.attachmentInstruction.id];
                received.isLoading = false;
            }
            return received;
    }

    return state;
};
