import * as React from 'react';
import { useState } from 'react';
import { IControllerInfo } from '../../store/groupedreturns/GroupedReturnProcessState';
import { Modal, Alert, Form, Button, Row, Col } from 'react-bootstrap';
import { FiShare } from 'react-icons/fi';
import { ScreenShareConstants } from '../helper/Constants';
import { LoadingOverlay, Loader } from 'react-overlay-loader';

interface ControllerScreenShareProps {
	show: boolean;
	onCancel(): void;
	controller: IControllerInfo;
	otp: string;
	onStartScreenShare(clientGuid: string): void;
	clientId?: string
}

export const ControllerScreenShare: React.FC<ControllerScreenShareProps> = (props) => {

	return <Modal className="screen-share-modal" show={props.show} onHide={props.onCancel}>
		<Modal.Header closeButton data-test-auto="87391A52-20BC-4F34-9C97-07A549DC4BFA">
			<Modal.Title className='custom-modal-header'>
				<FiShare className='modalIcon' />
                    Start Screen Sharing Session
                </Modal.Title>
		</Modal.Header>
		<Form>
			<Modal.Body>
				<LoadingOverlay style={{ minHeight: 100 }}>
					<Alert variant="info" >
						<i className='fa fa-info-circle' style={{ marginRight: '5px' }} />{ScreenShareConstants.controllerScreenShareInfo}
					</Alert>
					{
						props.controller &&
						<div>
							{
								props.clientId && <Row>
									<Col className="col-sm-2 col-md-2 col-lg-2 padT07 tpLabelFont">Client Id :</Col>
									<Col className="col-sm-10 col-md-10 col-lg-10 padT07">{props.clientId}</Col>
								</Row>
							}
							<Row>
								<Col className="col-sm-2 col-md-2 col-lg-2 padT07 tpLabelFont">Controller :</Col>
								<Col className="col-sm-10 col-md-10 col-lg-10 padT07">{props.controller.name && props.controller.emailAddress}</Col>
							</Row>
							<Row>
								<Col className="col-sm-2 col-md-2 col-lg-2 padT07 tpLabelFont">Code :</Col>
								<Col className="col-sm-10 col-md-10 col-lg-10 padT07">{props.otp ? props.otp : <Loader loading={true} />}</Col>
							</Row>
						</div>
					}
					<Loader loading={(props.controller === undefined)} />
				</LoadingOverlay>
			</Modal.Body>
			<Modal.Footer>
				<Button onClick={props.onCancel}
					variant="default"
					className="btn btn-white"
					data-test-auto="2207AF56-B19F-4474-8641-0660A531585E">
					<i className='fas fa-times'></i>
                        Cancel
                    </Button>
				<Button onClick={() => { props.onStartScreenShare(props.controller.clientGuid.toString()); }}
					variant="info"
					data-test-auto="8FF516D1-5465-4DC1-9CE0-D87070F73235">
					<i className='fas fa-desktop'></i>
                        Share
                    </Button>
			</Modal.Footer>
		</Form>
	</Modal>

}