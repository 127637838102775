// Single select searchable dropdown for react-bootstrap-table.

import * as React from "react";
import Select from "react-select";

interface ISingleSelectDropdownProps {
    selectedValue?: any;
    options: any;
    onChange?: (selectedValue: any) => void;
    clearable?: boolean;
    disabled?: boolean;
    customPlaceHolder?: any;
    id: string;
    onFocus?: (event: any) => void;
    className?: any;
    onRef: (ref: any) => void;
    enableAllFilter?: boolean;
    setClearFilter?: () => void;
    filterHandler: (value: any) => void;
}

interface ISingleSelectDropdownState {
    selectedValue: string;
    options: any[];
}

class SingleSelectSearchableDropdown extends React.Component<ISingleSelectDropdownProps, ISingleSelectDropdownState> {
    constructor(props: any) {
        super(props);

        this.state = {
            selectedValue: "",
            options: []
        };
    }

    componentDidMount() {
        this.dropDownDataCreation();
    }

    componentDidUpdate(prevProps: any) {
        if (this.props.options !== prevProps.options) {
            this.dropDownDataCreation();
        }
    }

    private dropDownDataCreation = () => {
        this.props.onRef(this);
        var dropDownData: any = [];
        this.props.options.map((data: any) => {
            dropDownData.push(data);
        });
        this.setState({ options: dropDownData });
    };

    private onChange = (event: any) => {
        const selectedValue = event?.value ? `${event?.value}` : "-1";
        this.setState({ selectedValue: selectedValue }, () => {
            if (!selectedValue) {
                if (this.props.setClearFilter !== undefined) {
                    this.props.setClearFilter();
                }
                this.props.filterHandler("-1");
            } else {
                this.props.filterHandler(`${this.state.selectedValue}`);
            }
        });
    };

    async applyFilter(value: any) {
        await this.waitSet(value);
        this.setState(
            {
                selectedValue: value
            },
            () => {
                this.props.filterHandler(value);
            }
        );
    }

    waitSet(value: any) {
        return new Promise((res) => {
            this.setState(
                {
                    selectedValue: value
                },
                () => {
                    res("done");
                    this.props.filterHandler(value);
                }
            );
        });
    }

    public cleanFiltered = async (clearAll?: boolean) => {
        if (clearAll !== undefined) {
            await this.waitClean();
            this.setState(
                {
                    selectedValue: "-1"
                },
                () => {
                    this.props.filterHandler("-1");
                }
            );
        } else {
            this.setState(
                {
                    selectedValue: "-1"
                },
                () => {
                    this.props.filterHandler("-1");
                }
            );
        }
    };

    waitClean() {
        return new Promise((res) => {
            this.setState(
                {
                    selectedValue: "-1"
                },
                () => {
                    this.props.filterHandler("-1");
                    res("done");
                }
            );
        });
    }

    public render() {
        const { clearable = true } = this.props;

        return (
            <Select
                id={this.props.id}
                value={this.state.selectedValue}
                onChange={this.onChange}
                options={this.state.options}
                placeholder={
                    this.props.customPlaceHolder && this.props.customPlaceHolder.length > 0
                        ? this.props.customPlaceHolder
                        : "Select..."
                }
                clearable={clearable}
                disabled={this.props.disabled ? this.props.disabled : false}
                data-toggle="dropdown"
                data-boundary="window"
                onFocus={this.props.onFocus}
                className={`${this.props.className} filter select-filter ${
                    this.props.enableAllFilter ? "multi-select-width100" : "multi-select-widthauto"
                }`}
            />
        );
    }
}

export default SingleSelectSearchableDropdown;
