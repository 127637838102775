import * as React from 'react';
import { Button } from 'react-bootstrap';
import { Inbox } from './../../inbox/Inbox';
import { UserNotificationSettings } from '../notification/NotificationSettings';
import { IUserNotificationSettings } from '../../../Core/ViewModels/User/NotificationSettingModel';
import { IInboxMessage } from '../../../Core/ViewModels/User/InboxMessage';


export interface IUserNotificationPopoverProps {
    notificationSettings: IUserNotificationSettings;
    inboxMessages: IInboxMessage[];
    updateInboxMessage: (inboxMessage: IInboxMessage) => void;
    saveUserNotificationSettings: (settings: IUserNotificationSettings) => void;
    handlePopOverClose: (show: boolean) => void;
    updateClearAll: (inboxMessages: IInboxMessage[]) => void;
    isLoading: boolean;
}

export interface IUserNotificationPopoverState {
    activeMenuItem: string;
    showBackToInbox: boolean;
}

const menuItems = {
    inbox: 'Inbox',
    settings:'Settings'
}

export class UserNotificationPopover extends React.Component<IUserNotificationPopoverProps, IUserNotificationPopoverState>{
    state: IUserNotificationPopoverState = {
        activeMenuItem: menuItems.inbox,
        showBackToInbox: false
    };

    onSettingsClick = () => {
        this.setState({
            activeMenuItem: menuItems.settings,
            showBackToInbox: true
        });
    };

    onBackToInboxClick = () => {
        this.setState(
            {
                activeMenuItem: menuItems.inbox,
                showBackToInbox: false
            });
    };

    onCancelClick = () => {
        this.props.handlePopOverClose(false);
    }

    onClose = (e: React.BaseSyntheticEvent) => {
        e.stopPropagation();
        this.props.handlePopOverClose(false);
    }

    getPageContent=()=> {
        if (this.state.activeMenuItem == menuItems.settings) {
            return <UserNotificationSettings
                notificationSettings={this.props.notificationSettings}
                saveUserNotificationSettings={this.props.saveUserNotificationSettings}
                handlePopOverClose={this.props.handlePopOverClose}
            />
        }
        else {
            return <Inbox
                inboxMessages={this.props.inboxMessages}
                updateInboxMessage={this.props.updateInboxMessage}
                updateClearAll={this.props.updateClearAll}
                isLoading={this.props.isLoading}
            />;
        }
    };

    render() {
        return <div style={{ height: '100%', margin: '5px' }}>
            <div className="row">
                <div className="col-sm-8" style={{ fontSize: 'x-large' }}>
                    Notifications
                </div>
                <div className="col-sm-4">
                    <Button
                        variant="default"
                        data-test-auto="d969d87b-7109-4a55-a7a3-4644a4c0286d"
                        className="btn-white btn-only float-right btn btn-default"
                        onClick={this.onClose}
                        title='Close'>
                        <span className='fas fa-times settings' />
                    </Button>
                    {
                        false && <Button
                            data-test-auto="bb080cf4-d227-11ea-87d0-0242ac130003"
                            className="btn-white btn-only float-right marR10 btn btn-default"
                            onClick={this.state.activeMenuItem == menuItems.settings ? this.onBackToInboxClick : this.onSettingsClick}
                            title={this.state.activeMenuItem == menuItems.settings ? "View Inbox" : "Settings"}>
                            <span className={this.state.activeMenuItem == menuItems.settings ? "fa fa-arrow-left settings " : "fas fa-cog ddl-icon settings pad-settings"}>
                            </span>
                        </Button>
                    }
                </div>
            </div>
            {
                this.getPageContent()
            }
        </div>
    }

}
