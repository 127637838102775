import { addTask } from 'domain-task';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../';
import { actionTypes } from '../../types/ActionTypes';
import { IEmailTemplateMessage, clientInstructions } from '../../Core/ViewModels/CompanySettings/ClientInstructionViewModel';
import { handleResponse } from '../Library';
import * as Constants from '../../components/helper/Constants';
import { StatusType, NotificationAction } from '../common/NotificationStore';
import { ICompanySettings } from '../../Core/ViewModels/Company/CompanySettingsViewModel';
import * as CompanyStore from '../company/CompanyStore';
import * as SSRSettingsStore from '../company/settings/SSRSettingsStore';
import * as ReminderStore from '../common/ReminderStore';
import { API_BASE_URL } from '../../utils/constants';
import { ISSRSettings, SSRSettings } from '../../Core/ViewModels/Company/Settings/SSRSettingsViewModel';

export interface IEmailMessageStoreState {
    data: IEmailMessageDictionary;
    isLoading: boolean;
}

export interface IEmailMessageDictionary {
    [index: number]: IEmailMessageState
}

export interface IEmailMessageState {
    emailMessage: IEmailTemplateMessage;
}

interface RequestEmailMessasgeListAction {
    type: actionTypes.REQUEST_EMAIL_MESSAGES;
}

interface ReceiveEmailMessageListAction {
    type: actionTypes.RECEIVE_EMAIL_MESSAGES;
    messageDetails: IEmailTemplateMessage;
    id: number;
}

interface DeleteEmailMessageDetail {
    type: actionTypes.DELETE_EMAIL_MESSAGE;
    deletedMessageDetail: IEmailTemplateMessage;
}

type KnownAction =
    RequestEmailMessasgeListAction |
    ReceiveEmailMessageListAction |
    DeleteEmailMessageDetail |
    NotificationAction

export const ActionCreators = {
    requestEmailMessages: (reload: boolean = false): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(API_BASE_URL + 'api/EmailTemplate/GetEmailTemplate', {
            method: 'GET',
            credentials: 'include'
        })
            .then(handleResponse)
            .then(response => response as Promise<IEmailTemplateMessage[]>)
            .then((data) => {
                if (data) {
                    data.map((value, index) => {
                        dispatch({
                            type: actionTypes.RECEIVE_EMAIL_MESSAGES,
                            messageDetails: value, id: value.id
                        });
                    });
                }
            }).catch(function (error) {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: Constants.ClientInstructionConstants.FetchMessageError, statusType: StatusType.Error
                });
            });
        addTask(fetchTask);
        dispatch({ type: actionTypes.REQUEST_EMAIL_MESSAGES });
    },

    saveEmailMessageDetail: (message: IEmailTemplateMessage, ssrSettings: ISSRSettings, isSetDefault: boolean, typeId: number, callback?:() => void, resourceId:string = ""): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(API_BASE_URL + 'api/EmailTemplate', {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(message),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-Resource-Id': resourceId,
                'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
            }
        })
            .then(handleResponse)
            .then((newId) => {
                let addedMessage: IEmailTemplateMessage = Object.assign({}, message);
                addedMessage.id = newId;
                ssrSettings = new SSRSettings(ssrSettings.defaultMessageSettings);
                if (isSetDefault && ssrSettings.defaultMessageSettings) {
                    switch (typeId) { 
                        case clientInstructions.initialEmail:
                            ssrSettings.defaultMessageSettings.initialEmail = newId;
                            break;
                        case clientInstructions.voucherReminder:
                            ssrSettings.defaultMessageSettings.voucherReminders = newId;
                            break;
                        case clientInstructions.downloadTaxDocument:
                            ssrSettings.defaultMessageSettings.downloadTaxDocuments = newId;
                            break;
                        case clientInstructions.downloadEsignedFroms:
                            ssrSettings.defaultMessageSettings.downloadSignedForms = newId;
                            break;
                        case clientInstructions.k1Distribution:
                            ssrSettings.defaultMessageSettings.k1Distribution = newId;
                            break;
                        case clientInstructions.k1PartnerInfoUpdate:
                            ssrSettings.defaultMessageSettings.k1PartnerInfoUpdate = newId;
                            break;
                        case clientInstructions.k1ClientInfoUpdate:
                            ssrSettings.defaultMessageSettings.k1ClientInfoUpdate = newId;
                            break;
                        case clientInstructions.k1PartnerUpdatePageMessage:
                            ssrSettings.defaultMessageSettings.k1PartnerUpdatePageMessage = newId;
                            break;
                        case  clientInstructions.signingReminder:
                            ssrSettings.defaultMessageSettings.signingReminder = newId;
                             break;
                        case  clientInstructions.InvoicePaymentSuccessMail:
                            ssrSettings.defaultMessageSettings.invoicePaymentSuccessMail = newId;
                            break;
                        case  clientInstructions.voucherRevision:
                            ssrSettings.defaultMessageSettings.voucherRevision = newId;
                            break;
                        case  clientInstructions.initialMailForManualSign:
                            ssrSettings.defaultMessageSettings.initialMailForManualSign = newId;
                            break;
                        case clientInstructions.recalledReturnMail:
                            ssrSettings.defaultMessageSettings.recallReturnMail = newId;
                            break;
                        default:
                            break;
                    }

                    let action: any = SSRSettingsStore.actionCreators.updateSSRSettings(ssrSettings, callback, resourceId)
                    dispatch(action);
                    dispatch({
                        type: actionTypes.RECEIVE_EMAIL_MESSAGES,
                        messageDetails: addedMessage, id: newId
                    });                   
                }
                else {
                    dispatch({
                        type: actionTypes.RECEIVE_EMAIL_MESSAGES,
                        messageDetails: addedMessage, id: newId
                    });
                }
                
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: Constants.ClientInstructionConstants.SaveMessageSuccess, statusType: StatusType.Success
                });
            }).catch(error => {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: Constants.ClientInstructionConstants.SaveMessageError, statusType: StatusType.Error
                });
            });
        addTask(fetchTask);
    },

    saveGroupEmailMessageDetail: (message: IEmailTemplateMessage, ssrSettings: ISSRSettings, isSetDefault: boolean, typeId: number,callback?:() => void, resourceId: string = ""): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(API_BASE_URL + 'api/EmailTemplate', {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(message),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-Resource-Id': resourceId,
                'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
            }
        })
            .then(handleResponse)
            .then((newId) => {
                let addedMessage: IEmailTemplateMessage = Object.assign({}, message);
                addedMessage.id = newId;
                ssrSettings = new SSRSettings(undefined, ssrSettings.defaultGroupMessageSettings);
                if (isSetDefault && ssrSettings.defaultGroupMessageSettings) {
                    if (typeId == clientInstructions.groupInitialEmail)
                        ssrSettings.defaultGroupMessageSettings.groupInitialEmail = newId
                    else if (typeId == clientInstructions.groupSigningReminder)
                        ssrSettings.defaultGroupMessageSettings.groupSigningReminder = newId
                    else if (typeId == clientInstructions.groupVoucherReminder)
                        ssrSettings.defaultGroupMessageSettings.groupVoucherReminders = newId
                    else if (typeId == clientInstructions.groupDownloadTaxDocument)
                        ssrSettings.defaultGroupMessageSettings.groupDownloadTaxDocuments = newId
                    else if (typeId == clientInstructions.groupDownloadEsignedFroms)
                        ssrSettings.defaultGroupMessageSettings.groupDownloadSignedForms = newId
                    else if (typeId == clientInstructions.groupDownloadInstruction)
                        ssrSettings.defaultGroupMessageSettings.groupDownloadInstruction = newId
                    else if (typeId == clientInstructions.groupVoucherRevision)
                        ssrSettings.defaultGroupMessageSettings.groupVoucherRevision = newId
                    let action: any =  callback? SSRSettingsStore.actionCreators.updateSSRSettings(ssrSettings,callback, resourceId):
                                        SSRSettingsStore.actionCreators.updateSSRSettings(ssrSettings, undefined, resourceId);
                    dispatch(action);
                    dispatch({
                        type: actionTypes.RECEIVE_EMAIL_MESSAGES,
                        messageDetails: addedMessage, id: newId
                    });
                }
                else {
                    dispatch({
                        type: actionTypes.RECEIVE_EMAIL_MESSAGES,
                        messageDetails: addedMessage, id: newId
                    });
                }

                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: Constants.ClientInstructionConstants.SaveMessageSuccess, statusType: StatusType.Success
                });
            }).catch(error => {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: Constants.ClientInstructionConstants.SaveMessageError, statusType: StatusType.Error
                });
            });
        addTask(fetchTask);
    },

    updateEmailMessageDetail: (message: IEmailTemplateMessage, resourceId:string = ""): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(API_BASE_URL + 'api/EmailTemplate', {
            method: 'PUT',
            credentials: 'include',
            body: JSON.stringify(message),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-Resource-Id': resourceId,
                'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
            }
        })
            .then(handleResponse)
            .then(() => {
                dispatch({
                    type: actionTypes.RECEIVE_EMAIL_MESSAGES,
                    messageDetails: message, id: message.id
                });
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: Constants.ClientInstructionConstants.UpdateMessageSuccess,
                    statusType: StatusType.Success
                });
            }).catch(error => {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: Constants.ClientInstructionConstants.UpdateMessageError, statusType: StatusType.Error
                });
            });
        addTask(fetchTask);
    },

    deleteEmailMessageDetail: (message: IEmailTemplateMessage, resourceId: string = ""): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(API_BASE_URL + 'api/EmailTemplate', {
            method: 'DELETE',
            credentials: 'include',
            body: JSON.stringify(message),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-Resource-Id': resourceId,
                'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
            }
        })
            .then(handleResponse)
            .then(() => {
                dispatch({
                    type: actionTypes.DELETE_EMAIL_MESSAGE,
                    deletedMessageDetail: message
                });
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: Constants.ClientInstructionConstants.DeleteMessageSuccess,
                    statusType: StatusType.Success
                });
            }).catch(error => {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: Constants.ClientInstructionConstants.DeleteMessageError, statusType: StatusType.Error
                });
            });
        addTask(fetchTask);
    }
}

const emailMessageDic: IEmailMessageDictionary = [];

const unloadedState: IEmailMessageStoreState =
    {
        data: { ...emailMessageDic },
        isLoading: true
    } as IEmailMessageStoreState;

export const reducer: Reducer<IEmailMessageStoreState> = (state = unloadedState, incomingAction) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case actionTypes.REQUEST_EMAIL_MESSAGES:
            return {
                ...unloadedState
            } as IEmailMessageStoreState;

        case actionTypes.RECEIVE_EMAIL_MESSAGES:
            let received: IEmailMessageStoreState = { ...state };
            received.data[action.id] = { emailMessage: action.messageDetails };
            received.isLoading = false;
            return received;

        case actionTypes.DELETE_EMAIL_MESSAGE:
            let deleteMessage: IEmailMessageStoreState = { ...state }
            if (deleteMessage.data[action.deletedMessageDetail.id]) {
                delete deleteMessage.data[action.deletedMessageDetail.id];
                deleteMessage.isLoading = false;
            }
            return deleteMessage;
    }
    return state;
};
