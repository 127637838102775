import React from "react";
import "react-bootstrap-table/css/react-bootstrap-table.css";
import { BootstrapTable,TableHeaderColumn, CustomSelectProps , TextFilter , SelectFilter, CustomFilter, SelectRow, Options } from 'react-bootstrap-table';
import { CCHReturn } from "@store/reports/CCHReturns/CCHReturnsState";
import { CheckBoxComponent, CheckBoxSize } from "../../common/CheckBoxComponent";
import * as Helper from '../../helper/HelperFunctions';
import { CustomMultiSelect } from '../../common/MultipleSelectComponent';
import { Loader, LoadingOverlay } from 'react-overlay-loader';
import { ImportCCHAxcess } from '../../helper/Constants';
import { DisableColor, RefreshIcon } from "./Icons";

interface ReturnsTableProps {
    returnsData: CCHReturn[];        
    currentPageNo : number;    
    isLoading : boolean;
    totalReturns :number;
    pageNo :number;
    pageSize : number;    
    selectedRows : number[];    
    areEntireReturnsSelected : boolean,  
    isLoggedIn :boolean,    
    onSortChange :  (sortName: string, sortOrder: string) => void;
    onPageChange : (page: number, sizePerPage: number) => void;
    onFilterChange : (dataField: Record<string, any>) => void;
    onRowSelect: (row: CCHReturn, isSelected: boolean, e: any) => boolean | void;
    onSelectAll: (isSelected: boolean, rows: CCHReturn[]) => boolean | Array<number | string>;        
    onSyncReturn: (rowIndex: number) => void;    
}


export class ReturnsTable extends React.Component<ReturnsTableProps>{
    private customMultiSelect: CustomMultiSelect;
    private customTaxyearMultiSelect : CustomMultiSelect;
    constructor(props:ReturnsTableProps){
        super(props); 
    }    
    
    createCustomCheckbox = (props: CustomSelectProps): JSX.Element => {
        return (<CheckBoxComponent size={CheckBoxSize.sm}
            id={"cch-returns-checkbox-" + props.rowIndex}
            indeterminate={props.indeterminate}                           
            checked={props.checked}
            disabled={props.disabled}
            onChange={(e: any) => props.onChange(e, props.rowIndex)}
            ref={(input: any) => {
                if (input) {
                    input.indeterminate = props.indeterminate;
                }
            }}
            text={""} />);
    }

    renderShowsTotal(start, to, total) {        
        start = isNaN(start) || start === undefined ? 0 : start;
        to = isNaN(to) || to === undefined ? 0 : to;
        total = isNaN(total) || total === undefined ? 0 : total;
    
        return (
            <p>                
                {ImportCCHAxcess.Table.pagerText
                    .replace("{start}", start.toString())
                    .replace("{to}", to.toString())
                    .replace("{total}", total.toString())}
            </p>
        );
    }

    getMultiSelectDropDown = (filterHandler: any, customFilterParameters: any) => {
        const options = customFilterParameters.options;
        const enableAllFilter = customFilterParameters.enableAllFilter;
        const placeholder = customFilterParameters.placeholder;
        return (
            <CustomMultiSelect onRef={(ref: any) => (this.customMultiSelect = ref)} filterHandler={filterHandler} enableAllFilter={enableAllFilter} options={options} placeholder={placeholder} />
        );
    }

    private getTaxYearMultiSelectDropDown = (filterHandler: any, customFilterParameters: any) => {
		const options = customFilterParameters.options;
		const enableAllFilter = customFilterParameters.enableAllFilter;
		const placeholder = customFilterParameters.placeholder;
		return (
			<CustomMultiSelect onRef={(ref: any) => (this.customTaxyearMultiSelect = ref)} filterHandler={filterHandler} options={options} enableAllFilter={enableAllFilter} placeholder={placeholder} />
		);
	}

    onFilterChange = (dataField: Record<string, any>) => {
        this.props.onFilterChange(dataField);
    }    

    setNoContent = () => {
        const noTaxReturnsMsg = (
            <div style={{ paddingBottom:"2px"}}>
                No Tax Returns available <br />                
            </div>
        );

        const noTaxReturnsMsgBeforeLogin = (
            <div style={{ paddingBottom:"2px"}}>
                No Tax Returns loaded yet <br />
                Please <b>Login</b> to start.
            </div>
        );        

		if (this.props.isLoading) {
			return (<LoadingOverlay style={{ height: '400px' }}>
				<Loader loading={this.props.isLoading} />
			</LoadingOverlay>)
		} else {
			return (this.props.isLoggedIn ? noTaxReturnsMsg :  noTaxReturnsMsgBeforeLogin);
		}
	}

    onSyncBtnClick = (rowId : number) => {
        this.props.onSyncReturn(rowId);            
    }    

    CreateActions = (cell: any, row: CCHReturn) => {        
        const canEnableSync = this.props.isLoggedIn && (row.importStatus === 'Failed' || row.importStatus === 'Completed');    
        let  toolTip = ImportCCHAxcess.Table.syncDisableTooltip;
        if(!this.props.isLoggedIn){
            toolTip = ImportCCHAxcess.Table.syncWhenNotLoggedIn;
        }
        else if((row.importStatus === 'Failed' || row.importStatus === 'Completed')){
            toolTip = ImportCCHAxcess.Table.syncTitle;
        }

        return (
            <button
                type="button"
                style={{ background:"none", border:"none"}}
                className={`btn-white${canEnableSync ? '' : ' disabled'}`}
                onClick={() => canEnableSync && this.onSyncBtnClick(row.rowId)}
                data-test-auto={`6C801968-C04C-454D-9A6A-E760B36A28AC_${row.rowId}`}
                disabled={!canEnableSync}
                title={toolTip}
            >
            <RefreshIcon color={ canEnableSync ? "" : DisableColor }></RefreshIcon>             
            </button>
        );
    };

    formatImportStatus = (cell: any, row: CCHReturn) => {
        return (
            <>
                {(() => {
                    const {statusNew,statusInProgress,statusCompleted,statusFailed} = ImportCCHAxcess.Table;
                    switch (row.importStatus) {      
                        case statusNew:
                            return <div className="status status-new" title={statusNew}>{statusNew}</div>;                  
                        case statusInProgress:
                            return <div className="status status-inprogress" title={statusInProgress}>{statusInProgress}</div>;
                        case statusCompleted:
                            return <div className="status status-completed" title={statusCompleted}>{statusCompleted}</div>;
                        case statusFailed:
                            return <div className="status status-failed" title={statusFailed}>{statusFailed}</div>;                        
                    }
                })()}
            </>
        );
    };

    public render() {                      

        const constants = ImportCCHAxcess.Table;
        const ImportStatusList =
        [            
            { label: constants.statusNew, value: 1 },
            { label: constants.statusInProgress, value: 2 },
            { label: constants.statusCompleted, value: 3 },
            { label: constants.statusFailed, value: 4 }            
        ];        

        let selectedRows : number[] = [];
        if (this.props.selectedRows.length > 0) {
			for (var i in this.props.selectedRows) {
				let rowIndex = this.props.selectedRows[i];
				selectedRows.push(((this.props.pageNo - 1) * this.props.pageSize) + rowIndex);
			}
		}

        let selectRowProp: SelectRow = {                                  
            mode: 'checkbox',
            clickToSelect: true,
            onSelect: this.props.onRowSelect,
            onSelectAll: this.props.onSelectAll,
            customComponent: this.createCustomCheckbox, 
            className: 'row-selected',
            selected:selectedRows
        };

        let taxYearList:any = Helper.PrepareTaxYear(new Date().getFullYear() - 2);       
        
        const engagementTypes = ['1040', '1065', '1120', '1041', '990'];
        let engagementList: any = {};        
        engagementTypes.forEach(type => {
            engagementList[type] = type;
        });
        
        const returnsColumn = [
            {
                header: '',
                key: 'rowId',
                isKey: true,                
                width: 'auto',
                toolTip: false,
                dataSort: false,
                isHidden : true                
            },
            {
                header: constants.clientId,
                key: 'clientId',
                isKey: false,                
                width: 'auto',
                toolTip: true,
                dataSort: true,
                filter: { type: 'TextFilter', placeholder: 'Client ID', style: { font: 'bold' } } as TextFilter
            },
            {
                header: constants.version,
                key: 'version',
                isKey: false,                
                width: 'auto',
                toolTip: true,
                dataSort: true,
                filter: { type: 'TextFilter', placeholder: 'Version', style: { font: 'bold' } } as TextFilter
            },
            {
                header: constants.returnId,
                key: 'returnId',
                isKey: false,                
                width: 'auto',
                toolTip: true,
                dataSort: true,
                filter: { type: 'TextFilter', placeholder: 'Return ID', style: { font: 'bold' } } as TextFilter
            },
            {
                header: constants.returnType,
                key: 'returnType',
                isKey: false,                
                width: 'auto',
                toolTip: true,
                dataSort: true,
                filter: { type: 'SelectFilter', placeholder: 'Select Type...', options: engagementList} as SelectFilter
            },       
            {
                header: constants.taxYear,
                key: 'taxYear',
                isKey: false,                
                width: 'auto',
                toolTip: true,
                dataSort: true,                
                filter: { type: 'CustomFilter', getElement: this.getTaxYearMultiSelectDropDown, customFilterParameters: { options: taxYearList, enableAllFilter: true, placeholder: 'Select TaxYear...'  } as any } as CustomFilter
            },        
            {
                header: constants.clientName,
                key: 'clientName',
                isKey: false,                
                width: 'auto',
                toolTip: true,
                dataSort: true,
                filter: { type: 'TextFilter', placeholder: 'Client Name', style: { font: 'bold' } } as TextFilter
            },                 
            {
                header: constants.returnStatus,
                key: 'returnStatus',
                isKey: false,                
                width: 'auto',
                dataSort: true,
                toolTip: true,
                filter: { type: 'TextFilter', placeholder: constants.returnStatus, style: { font: 'bold' } } as TextFilter                
            },
            {
                header: constants.importStatus,
                key: 'importStatus',
                dataFormat : this.formatImportStatus,
                isKey: false,                
                width: 'auto',
                dataSort: true,                
                filter: { type: 'CustomFilter', getElement: this.getMultiSelectDropDown, customFilterParameters: { options: ImportStatusList, enableAllFilter: true, placeholder: 'Select Import Status...'  } as any } as CustomFilter
            },
            {
				header: constants.action,
				key: 'button', // String-based value accessors!
				isKey: false,
				dataFormat: this.CreateActions,
				columnClassName: 'button-cell',
				dataSort: false,
				toolTip: false,
				isHidden: false,
				width: '100px',		                
			}               
        ];

        const options : Options ={
            onSortChange: this.props.onSortChange,            
            page:this.props.currentPageNo,
            sizePerPage: this.props.pageSize,
            onPageChange : this.props.onPageChange,
            nextPage: <span className="fa fa-angle-right" />,
            prePage: <span className="fa fa-angle-left" />,
            firstPage: <span className="fa fa-angle-double-left" />,
            lastPage: <span className="fa fa-angle-double-right" />,
            paginationShowsTotal : this.renderShowsTotal,
            clearSearch: true,
            onFilterChange: this.onFilterChange,
            noDataText:this.setNoContent(),
            onRowDoubleClick: function (row: CCHReturn) {
			},
        };

        const returns = this.props.returnsData != null ? this.props.returnsData : [];    
        const data:CCHReturn[] = returns.map((row,index) => {
                return {
                    ...row,
                    rowId : ((this.props.currentPageNo - 1) * this.props.pageSize) + index,
                    index : index
                };
        });

        return(
            <>
                <div className='row'>
                    <div className="mt-1 mb-3 ml-3 mr-3">
                        <BootstrapTable 
                            data={data}
                            ref='table'
                            remote={true}
                            fetchInfo={{ dataTotalSize: this.props.totalReturns }}
                            striped
                            hover={true}                                
                            pagination={true}                                
                            selectRow={selectRowProp}
                            options={options}
                        >{returnsColumn.map((value, index) => {
                                return <TableHeaderColumn                                     
                                    key={index}           
                                    ref={value.key}                              
                                    isKey={value.isKey} 
                                    dataField={value.key} 
                                    width={value.width} 
                                    filter={value.filter}
                                    dataSort ={value.dataSort}
                                    columnTitle={value.toolTip}
                                    hidden={value.isHidden}
                                    dataFormat={value.dataFormat}
                                    columnClassName={value.columnClassName}>
                                        {value.header}
                                </TableHeaderColumn>;
                            })}
                        </BootstrapTable>   
                    </div>
                </div>                
            </>
        );
    }
}
export default ReturnsTable;
