import * as React from 'react';
import * as Bs from 'react-bootstrap';
import { ITaxingAuthority, IEFile, initialEfile, StateUSA, getAllStateImages } from '../../common/TaxReturn';
import { IPageItem } from './ProcessReturnModels';
import { CSSProperties } from 'react';

export interface IAddAuthorityProps {
    getAllTaxingAuthorities: ITaxingAuthority[];
    efile?: IEFile;
    pages: IPageItem[];
    onUpdateAddedEfile?: (efile: IEFile, currentEFileClone: IEFile, taxingAuthority: ITaxingAuthority) => void;
    className: string;
    title: string;
    style?: CSSProperties
}

export interface IAddAuthorityState {
    showAddAuthorityView: boolean;
    popoverTarget: any;
    efile: any;
    currentEFileClone: IEFile;
}

export class AddAuthority extends React.Component<IAddAuthorityProps, IAddAuthorityState> {

    constructor(props: IAddAuthorityProps) {
        super(props);
        this.state = {
            showAddAuthorityView: false,
            popoverTarget: {},
            efile: initialEfile,
            currentEFileClone: initialEfile,
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps: IAddAuthorityProps) {
        const { efile } = nextProps;
        if (nextProps && nextProps.efile) {
            var propsEfile = JSON.parse(JSON.stringify(efile));
            this.setState({
                currentEFileClone: propsEfile,
                efile: efile
            });
        }
    }

    private handleUpdate = () => {
        let tempEfile: IEFile = { ...this.state.efile };
        const taxingAuthority: any = this.props.getAllTaxingAuthorities.find(x => x.Id == tempEfile.authorityID);
        if (this.props.onUpdateAddedEfile) {
            this.props.onUpdateAddedEfile(tempEfile, this.state.currentEFileClone, taxingAuthority)

            this.setState({
                showAddAuthorityView: false
            });
        }
    }

    private handleAuthorityChange = (event: any) => {
        const authorities: ITaxingAuthority[] = this.props.getAllTaxingAuthorities;
        let selectedAuthority: ITaxingAuthority = authorities[authorities.findIndex(x => x.Id == event.currentTarget.value)]
        let tempEfile: IEFile = { ...this.state.efile };
        if (selectedAuthority) {
            tempEfile.authorityID = parseInt(event.currentTarget.value);
        }
        this.setState({ efile: tempEfile });
    }

    private handleAddAuthorityBtnClick = (event: any) => {
        this.setState(s => ({ showAddAuthorityView: !s.showAddAuthorityView }));
    }

    private attachAddPopoverRef = (target: any) => {
        this.setState({ popoverTarget: target })
    };

    private onCancel = (event: any) => {
        var propsEfile = JSON.parse(JSON.stringify(this.props.efile))
        this.setState({
            showAddAuthorityView: false,
            currentEFileClone: propsEfile,
            efile: this.props.efile

        });
    }

    public render() {
        let authorities: ITaxingAuthority[] = this.props.getAllTaxingAuthorities;
        const addPopover = (<Bs.Popover id="popover-voucher-add" style={{ maxWidth: 1000 }}>
            <Bs.Popover.Title as="h3">Specify Authority</Bs.Popover.Title>
            <Bs.Popover.Content>
            <div className="new-main-content">
                <Bs.Row style={{ marginBottom: "10px" }}>
                    <Bs.Col sm={4} md={3} lg={4} as={Bs.FormLabel} style={{
                        fontSize: "medium", textAlign: "left", paddingTop: "7px",
                    }}>
                        Authority
                        </Bs.Col>
                    <Bs.Col sm={4} md={3} lg={8}>
                        <select
                            className="form-control"
                            id="add-dd-authority"
                            value={this.state.efile.authorityID}
                            onChange={this.handleAuthorityChange}
                            data-test-auto="A075FBD0-79EB-468D-920F-96F7A9CB3C12">                            
                            <option value={0} data-abbreviation=''>Select</option>
                            {authorities.map((authority, index) => {
                                return <option key={index} value={authority.Id} data-abbreviation={authority.Abbreviation}>
                                    {authority.AuthorityName}
                                </option>
                            })}
                        </select>
                    </Bs.Col>
                </Bs.Row>
            </div>
            <div className="modal-footer">
                    <Bs.Button
                        variant="default"
                        className="btn-white"
                        onClick={this.onCancel}
                        data-test-auto="F6EFE0C3-B741-40F0-9A2A-B555904A1267"
                ><i className="fas fa-times"></i>
                    Cancel
                </Bs.Button>

                <Bs.Button
                    variant='info'
                    onClick={this.handleUpdate}
                    data-test-auto="910A12E1-9EB7-4A19-824F-0963379F58E8"
                > <i className='fas fa-plus'></i>
                    Update
                </Bs.Button>
                </div>
            </Bs.Popover.Content>
        </Bs.Popover>)

        return <div>
            <span className="float-right">
                <a 
                    title={this.props.title}
                    style={this.props.style}
                    onClick={this.handleAddAuthorityBtnClick}
                    ref={this.attachAddPopoverRef}
                    data-test-auto="EF49F967-CB71-4E2E-8837-65F5148EBA65"
                >
                    <i className={this.props.className}></i>
                </a>
            </span>
            <Bs.Overlay
                rootClose={true}
                onHide={this.onCancel}
                placement="right"
                target={this.state.popoverTarget}
                show={this.state.showAddAuthorityView}
            >
                {addPopover}
            </Bs.Overlay>
        </div>
    }
}