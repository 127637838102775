import * as React from 'react';
import * as BootStrap from 'react-bootstrap';
import { CompanySettingConstants } from '../../helper/Constants';
import moment from "moment";
import { TaxReturnSettingsResources } from '../../../components/helper/ResourceIdConstants';

interface ISettingsHeader {
    handleApplyButton(event: any, resourceId?: string): void;
    header: string;
    showPopOver: boolean;    
    expiryDate?: Date;
	handleDummyApplyButton?(event: any,callback:(count:number)=>void): void;	
    parentResourceIdentifier?: string;
}

interface ISettingsHeaderState {
	showClosePopover: boolean;
	showLoader: boolean;
	count:number
}

export class SettingsHeader extends React.Component<ISettingsHeader, ISettingsHeaderState>
{
    private closePopoverRef: any;

    state = {
        showClosePopover: false,
        showLoader: false,
        count:0
    }

    private onApply = (event: any) => {
        this.props.handleApplyButton(event, this.props.parentResourceIdentifier + TaxReturnSettingsResources.ButtonApply);
        this.setState({ showClosePopover: false });
    }

    private onDummyApply = (e: any) => {
		this.setState({ showLoader: true }, () => {
			if (this.props.handleDummyApplyButton) {
				this.props.handleDummyApplyButton(e, (count: number) => { this.showPopoverMessage(count)});
			}
		});        
	}

	private showPopoverMessage = (count: number) => {
		this.setState({
			showClosePopover: true,
			showLoader: false,
			count: count
		});
	}

    public render() {
       
        const popover = (
            <BootStrap.Popover id="popover-basic">
                <BootStrap.Popover.Title as="h3">Confirm Apply</BootStrap.Popover.Title>
                <BootStrap.Popover.Content>
				{CompanySettingConstants.closePopoverMessage.replace("%COUNT%", this.state.count.toString()).replace("%DATE%", this.props.expiryDate ? moment(this.props.expiryDate).format('MM-DD-YYYY') : "")}
                <div className="popover-footer">
                    <button
                        className="btn btn-only btn-success"
                        onClick={this.onApply}
                        title="Yes"
                        data-test-auto="BCDBF6AB-57E0-4AB7-BAB2-580203E088C8"
                        style={{ marginLeft: "5px" }}>
                        Yes
                </button>
                    <button
                        onClick={() => { this.setState({ showClosePopover: false }); }}
                        title="No"
                        data-test-auto="8AAB3A64-BD99-411A-9F63-E627D51F1F59"
                        className="btn btn-only btn-danger"
                        style={{ marginLeft: "5px" }}>
                        No
                </button>
                </div>
                </BootStrap.Popover.Content>
            </BootStrap.Popover>
        );

        const popoverApply = <div className={this.props.header==''?'row':'row settings-info-header' }>
            <div className="col-sm-8 header-text">
                <h3>{this.props.header}</h3>
            </div>
            <div className="col-sm-4 txt-ar">
                <BootStrap.Button
                    variant="info"
                    data-resource-id = {this.props.parentResourceIdentifier + TaxReturnSettingsResources.ButtonApply}
                    className=" marR05"
                    onClick={this.onDummyApply}
                    ref={(reference: any) => { this.closePopoverRef = reference }}
                    data-test-auto="39E31156-A0C2-40A9-8E52-AC85A456D14D">
                    <i className={this.state.showLoader ? "fas fa-spinner fa-spin text-white bg-transparent" : "fas fa-check"}></i> <span>Apply</span>
                </BootStrap.Button>
                <BootStrap.Overlay rootClose={true} placement="left"
                    onHide={() => { this.setState({ showClosePopover: false }) }}
                    target={this.closePopoverRef} show={this.state.showClosePopover}>
                    {popover}
                </BootStrap.Overlay>
            </div>
        </div>;

        const defaultApply = <div className={this.props.header==''?'row':'row settings-info-header' }>
            <div className="col-sm-8 header-text">
                <h3>{this.props.header}</h3>
            </div>
            <div className="col-sm-4 txt-ar">
                <BootStrap.Button
                    variant="info"
                    data-resource-id = {this.props.parentResourceIdentifier + TaxReturnSettingsResources.ButtonApply}
                    className=" marR05"
                    onClick={(e:any) => this.props.handleApplyButton(e,this.props.parentResourceIdentifier + TaxReturnSettingsResources.ButtonApply)}
                    data-test-auto="39E31156-A0C2-40A9-8E52-AC85A456D14D">
                    <i className="fas fa-check"></i>
                    Apply
                </BootStrap.Button>
            </div>
        </div>;

        return this.props.showPopOver ? popoverApply : defaultApply;
    }
}

export default SettingsHeader;