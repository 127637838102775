import store from "..";
import { validateError } from "../../components/helper/HelperFunctions";
import { ProductType } from "../../Core/ViewModels/Company/CompanyViewModel";
import { logger } from "../../routes/LoggedIn";
import { SSSUITE_API_BASE_URL } from "../../utils/constants";
import { setCacheResetApiState } from "./actions";

export const resetUserCache = (callBack?: () => void) => {
    fetch(SSSUITE_API_BASE_URL + `/api/user-management/reset-user-status-cache/${ProductType.Returns}`, {
        credentials: 'include',
        method: 'GET',
    }).then((handleResponse) => {
        store.dispatch(setCacheResetApiState());
        if (callBack) {
            callBack();
        }
    })
        .catch(error => {
            logger.trackTrace(`SSSuite reset-user-status-cache API failed with error ${validateError(error)}`);
            store.dispatch(setCacheResetApiState());
        });
}

export const updateLogoutDate = () => {
    fetch(SSSUITE_API_BASE_URL + `/api/login-history/update-logout-date`, {
        credentials: 'include',
        method: 'PUT',
    }).catch(error => {
        logger.trackTrace(`SSSuite update-logout-date API failed with error ${validateError(error)}`);
    });
}

