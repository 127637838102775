import * as React from 'react';
import { ICompanySettings } from '../../../Core/ViewModels/Company/CompanySettingsViewModel';
import { RadioButtonComponent } from '../../common/RadioButtonComponent';
import { CheckBoxComponent } from '../../common/CheckBoxComponent';

export interface IReturnAccessSettingsProps {
	companySettings: ICompanySettings;
	handleSetAccessToEveryoneChange: (event: any) => void;
	handleSetAccessToIndividualChange: (event: any) => void;
	handleSetAccessToOthersChange: (event: any) => void;
	handleSetAccessToUserGroupsChange: (event: any) => void;
}


export class ReturnAccessSettings extends React.Component<IReturnAccessSettingsProps, {}>{
	public render() {
		return (
			<div className="new-main-content">
				<h3>SS Return Access Options</h3>

				<div className="marL20">
					<div className="div-height">
						<RadioButtonComponent
							id={"SetAccessToEveryone"}
							text={"Default Access set to Everyone"}
							checked={this.props.companySettings.returnAccessSettings && this.props.companySettings.returnAccessSettings!.isSetAccessToEveryone}
							onChange={this.props.handleSetAccessToEveryoneChange}
						/>

					</div>
					<div>
						<RadioButtonComponent
							id={"SetAccessToIndividual"}
							text={"Default Access set to Individual User"}
							checked={this.props.companySettings.returnAccessSettings && this.props.companySettings.returnAccessSettings!.isSetAccessToIndividual}
							onChange={this.props.handleSetAccessToIndividualChange}
						/>

					</div>
					<div className="row">
						<div className="settings-checkbox return-setting-others col-sm-4 col-md-4 col-lg-4">
							<CheckBoxComponent
								id={"SetAccessToOthers"}
								text={"Allow user to set additional access to other users before delivery"}
								checked={this.props.companySettings.returnAccessSettings && this.props.companySettings.returnAccessSettings!.isSetAccessToOtherUsers}
								onChange={this.props.handleSetAccessToOthersChange}
								datatestAuto={"E8381C6B-FE39-4F33-BE2F-DB5B953C90E6"}
								disabled={this.props.companySettings.returnAccessSettings && !this.props.companySettings.returnAccessSettings!.isSetAccessToIndividual}
							/>
						</div>
					</div>
					<div>
						<RadioButtonComponent
							id={"SetAccessToUserGroups"}
							text={"Default Access set to Uploaders User Group"}
							checked={this.props.companySettings.returnAccessSettings && this.props.companySettings.returnAccessSettings!.isSetAccessToUserGroups}
							onChange={this.props.handleSetAccessToUserGroupsChange}
						/>

					</div>
				</div>
			</div>
		);
	}
}