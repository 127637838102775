import * as React from "react";
import { Row, Col, Alert } from "react-bootstrap";
import { ITaxReturn } from "../../common/TaxReturn";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import { DropdownComponent } from "../../common/controls/DropdownComponent";
import { ICompanySettings } from "../../../Core/ViewModels/Company/CompanySettingsViewModel";
import CustomToggleSwitch from "../../common/CustomToggleSwitch";

interface IBulkVoucherRemindersProps {
    companySettings: ICompanySettings;
    models: ITaxReturn[];
    handleVoucherReminderChange: (allowVoucherReminder: boolean) => void;
    handleSigningReminderChange: (allowSigningReminder: boolean) => void;
    handleVoucherReminderInDaysChange: (voucherReminderInDays: number) => void;
    handleSigningReminderInDaysChange: (signingReminderInDays: number) => void;
    totalCount: number;
    isGroupedReturnExists: boolean;
}

interface SelectOptions {
    value: any;
    label: string;
}

interface IBulkVoucherRemindersStates {
    allowVoucherReminderdefault: boolean;
    allowSigningReminderdefault: boolean;
    selectedReminder: SelectOptions;
    reminderOptions: SelectOptions[];
    selectedSingingReminder: SelectOptions;
    signingReminderOptions: SelectOptions[];
    isNoteSection: boolean;
    initialVoucherReminderPopupState: InitialVoucherReminderPopupState;
    isVoucherReminderDisabled: boolean;
    isSigningReminderDisabled: boolean;
}

interface InitialVoucherReminderPopupState {
    InitallowVoucherReminder: boolean;
    InitvoucherReminderValue: number;
    InitallowSigningReminder: boolean;
    InitsigningReminderValue: number;
}

const msg = {
    saving: "Saving Reminder...",   
};

export class BulkVoucherReminders extends React.Component<IBulkVoucherRemindersProps, IBulkVoucherRemindersStates> {
    constructor(props: IBulkVoucherRemindersProps) {
        super(props);
        let noOfDaysForVoucherReminder: number =
            props.companySettings.notificationSettingsModel.paymentVoucherNotificationSettings.noOfDaysForVoucherReminder;
        let noOfDaysForSigningReminder: number =
            props.companySettings.notificationSettingsModel.signingReminderNotificationSettings.noOfDaysForSigningReminder;

        this.state = {
            allowVoucherReminderdefault:
                this.props.companySettings.notificationSettingsModel.paymentVoucherNotificationSettings
                    .enablePaymentVoucherReminder,
            allowSigningReminderdefault:
                this.props.companySettings.notificationSettingsModel.signingReminderNotificationSettings.enableSigningReminder,

            isVoucherReminderDisabled: false,
            isSigningReminderDisabled: false,
            reminderOptions: [],
            selectedReminder: {
                value: noOfDaysForVoucherReminder,
                label: noOfDaysForVoucherReminder + (noOfDaysForVoucherReminder === 1 ? " day" : " days")
            },
            signingReminderOptions: [],
            selectedSingingReminder: {
                value: noOfDaysForSigningReminder,
                label: noOfDaysForSigningReminder + (noOfDaysForSigningReminder === 1 ? " day" : " days")
            },
            isNoteSection: false,
            initialVoucherReminderPopupState: {
                InitallowVoucherReminder:
                    this.props.companySettings.notificationSettingsModel.paymentVoucherNotificationSettings
                        .enablePaymentVoucherReminder,
                InitvoucherReminderValue:
                    this.props.companySettings.notificationSettingsModel.paymentVoucherNotificationSettings
                        .noOfDaysForVoucherReminder,
                InitallowSigningReminder:
                    this.props.companySettings.notificationSettingsModel.signingReminderNotificationSettings
                        .enableSigningReminder,
                InitsigningReminderValue:
                    this.props.companySettings.notificationSettingsModel.signingReminderNotificationSettings
                        .noOfDaysForSigningReminder
            }
        };
        this.handleSigningReminderChange = this.handleSigningReminderChange.bind(this);
        this.handleSigningToggleSwitchChange = this.handleSigningToggleSwitchChange.bind(this);
        this.handleVoucherReminderChange = this.handleVoucherReminderChange.bind(this);
        this.handleVoucherToggleSwitchChange = this.handleVoucherToggleSwitchChange.bind(this);
    }
    buildOptions() {
        var arr = [];
        for (let i = 1; i <= 30; i++) {
            if (i == 1) {
                arr.push({
                    value: i,
                    label: i + " day"
                });
            } else {
                arr.push({
                    value: i,
                    label: i + " days"
                });
            }
        }

        return arr;
    }

    SetReminderOptions(props: IBulkVoucherRemindersProps) {
        var reminderOptions = [];
        for (var i = 1; i <= 30; i++) {
            reminderOptions.push({ value: i, label: i === 1 ? i + " day" : i + " days" });
        }

        let noOfDaysForVoucherReminder: number =
            props.companySettings.notificationSettingsModel.paymentVoucherNotificationSettings.noOfDaysForVoucherReminder;
        let noOfDaysForSigningReminder: number =
            props.companySettings.notificationSettingsModel.signingReminderNotificationSettings.noOfDaysForSigningReminder;

        this.setState({
            reminderOptions: reminderOptions,
            signingReminderOptions: reminderOptions
        });
    }

    private handleVoucherReminderChange = (selectedOption: any) => {
        if (selectedOption == null) {
            if (selectedOption == null) {
                this.setState(
                    {
                        selectedReminder: {
                            value: selectedOption,
                            label: "Select..."
                        }
                    },
                    () => {
                        this.props.handleVoucherReminderInDaysChange(selectedOption);
                    }
                );
            }
        } else {
            this.setState(
                {
                    selectedReminder: {
                        value: selectedOption,
                        label: selectedOption + (selectedOption == 1 ? " day" : " days")
                    }
                },
                () => {
                    this.props.handleVoucherReminderInDaysChange(selectedOption);
                }
            );
        }
    };

    private handleVoucherToggleSwitchChange = (event: any) => {
        this.setState({ allowVoucherReminderdefault: event.target.checked });

        if (event.target.checked) {
            this.props.handleVoucherReminderChange(true);
        } else {
            this.props.handleVoucherReminderChange(false);
        }
        this.forceUpdate();
    };

    private handleSigningReminderChange = (selectedOption: any) => {
        if (selectedOption == null) {
            this.setState(
                {
                    selectedSingingReminder: {
                        value: selectedOption,
                        label: "Select..."
                    }
                },
                () => {
                    this.props.handleSigningReminderInDaysChange(selectedOption);
                }
            );
        } else {
            this.setState(
                {
                    selectedSingingReminder: {
                        value: selectedOption,
                        label: selectedOption + (selectedOption == 1 ? " day" : " days")
                    }
                },
                () => {
                    this.props.handleSigningReminderInDaysChange(selectedOption);
                }
            );
        }
    };

    private handleSigningToggleSwitchChange = (event: any) => {
        this.setState({ allowSigningReminderdefault: event.target.checked });

        if (event.target.checked) {
            this.props.handleSigningReminderChange(true);
        } else {
            this.props.handleSigningReminderChange(false);
        }
        this.forceUpdate();
    };

    UNSAFE_componentWillReceiveProps(props: IBulkVoucherRemindersProps) {
        this.SetReminderOptions(props);
    }

    public signingReminderDescription = () => (
        <div className="mt-3">
            <div className="text-danger mb-3">Please Note!</div>
            <div>Signing Reminder will be sent for statuses: “Awaiting E-Sign”, “Awaiting Upload”, “Partially Signed”.</div>
        </div>
    );

    public voucherReminderDescription = () => (
        <div className="mt-3">
            <div className="text-danger mb-3">Please Note!</div>
            <div className="mb-2">Voucher reminders cannot be delivered for the returns due to one of the following reasons:</div>
            <ul className="ml-3">
                <Row>
                    <Col>
                        <li>Tax Payer has disabled the settings</li>
                        <li>All voucher payments flagged as paid</li>
                    </Col>
                    <Col>
                        <li>No voucher found for the return</li>
                        <li>Expired Vouchers</li>
                    </Col>
                </Row>
            </ul>
        </div>
    );

    private isMultiple: boolean = this.props.totalCount > 1;

    public render() {
        return(
            <div className="reminder-modal py-2">
                {this.isMultiple ? (
                    <Alert variant="primary">
                        <i className="fas fa-info-circle mr-2"></i>
                        {this.props.totalCount} Returns selected.
                        {this.props.isGroupedReturnExists && (
                            <div style={{ marginLeft: "22px" }}>
                                The changes made will not impact the returns belonging to any group.
                            </div>
                        )}
                    </Alert>
                ) : (
                    this.props.isGroupedReturnExists && (
                        <Alert variant="primary">
                            <i className="fas fa-info-circle mr-2"></i>
                            The changes made will not impact the returns belonging to any group.
                        </Alert>
                    )
                )}
                <div className="py-3" style={{ padding: "16px 16px 16px 20px" }}>
                    <div className="mb-4">
                        <div>
                            <b>Voucher</b>
                        </div>
                        <Row className="align-items-center" noGutters>
                            <Col xs={4}>Send a reminder</Col>
                            <Col xs={3}>
                                <DropdownComponent
                                    id="ddlVoucherReminder"
                                    options={this.state.reminderOptions}
                                    onChange={this.handleVoucherReminderChange}
                                    selectedValue={this.state.selectedReminder}
                                    clearable={true}
                                    disabled={!this.state.allowVoucherReminderdefault || this.state.isVoucherReminderDisabled}
                                />
                            </Col>
                            <Col className="ml-3" xs={3}>
                                before due date
                            </Col>
                            <Col>
                                <CustomToggleSwitch
                                    switched={this.state.allowVoucherReminderdefault && !this.state.isVoucherReminderDisabled}
                                    handleChange={this.handleVoucherToggleSwitchChange}
                                    disabled={this.state.isVoucherReminderDisabled}
                                />
                            </Col>
                        </Row>
                        {this.props.totalCount > 1 || (this.props.models && this.props.models.every((x) => x.groupId > 0))
                            ? this.voucherReminderDescription()
                            : this.state.isVoucherReminderDisabled && this.voucherReminderDescription()}
                    </div>
                    <div>
                        <div>
                            <b>Signing</b>
                        </div>
                        <Row className="align-items-center" noGutters>
                            <Col xs={4}>Send a reminder every</Col>
                            <Col xs={3}>
                                <DropdownComponent
                                    id="ddlsigningReminder"
                                    options={this.state.signingReminderOptions}
                                    onChange={this.handleSigningReminderChange}
                                    selectedValue={this.state.selectedSingingReminder}
                                    disabled={!this.state.allowSigningReminderdefault || this.state.isSigningReminderDisabled}
                                    clearable={true}
                                />
                            </Col>
                            <Col className="ml-3" xs={3}>
                                since last reminder / initial mail
                            </Col>
                            <Col>
                                <CustomToggleSwitch
                                    switched={this.state.allowSigningReminderdefault && !this.state.isSigningReminderDisabled}
                                    handleChange={this.handleSigningToggleSwitchChange}
                                    disabled={this.state.isSigningReminderDisabled}
                                />
                            </Col>
                        </Row>
                        {this.props.totalCount > 1 || (this.props.models && this.props.models.every((x) => x.groupId > 0))
                            ? this.signingReminderDescription()
                            : this.state.isSigningReminderDisabled && this.signingReminderDescription()}
                    </div>
                </div>
            </div>
        )
    }
}
