import * as React from 'react';
import { Col, Row, Table, Popover, OverlayTrigger, Card, Accordion } from 'react-bootstrap';
import * as TaxDocument from '../../../common/TaxReturn';
import * as Helper from '.././../../helper/HelperFunctions';
import { DocumentGroups, IGroup, IRefundInfo, ITaxingAuthority, IVoucher, VoucherTypes } from '../../../common/TaxReturn';
import { ClientInfoView, IBookmarkSection, IPageItem, NO_INDEX } from '../ProcessReturnModels';
import { BookmarkIcon } from '../../BookmarkIcon';
import * as TaxRetrun from '../../TaxReturn';
import { AddedRefundPopover } from '../AddedRefundPopover';
import * as _ from 'lodash';

export interface IAddedOverPaymentProps {
    taxReturn: TaxDocument.ITaxReturn;
    onPageSelect: (pageNo: number, voucherNo: number, isPageSelectionFromBookMark?: boolean, isViewClientInfo?: boolean) => void;
    getAllTaxingAuthorities: ITaxingAuthority[];
    authorities: TaxRetrun.ITaxingAuthority[];
}
export interface IAddedOverPaymentState {
    refundandPaymentDueBookmarks: IBookmarkSection[];
    expandAddedRefundListPanel: boolean;
    addedRefundPopoverTitle: string;
    enableLeftPanelScroll: boolean;
    refund: IRefundInfo;
}

export const initialRefund: IRefundInfo = {
    overPayment: 0,
    authorityID: 0,
    documentId: 0,
    id: 0,
    overPaymentApplied: 0,
    penalty: 0
}

export const SEPARATOR = "###";

export class AddedOverPaymentsList extends React.Component<IAddedOverPaymentProps, IAddedOverPaymentState> {
    constructor(props: IAddedOverPaymentProps) {
        super(props);
        this.state = {
            expandAddedRefundListPanel: true,
            refundandPaymentDueBookmarks: [],
            addedRefundPopoverTitle: "Refund",
            enableLeftPanelScroll: true,
            refund: initialRefund
        }
    }

    componentDidMount() {
        if (this.state.refundandPaymentDueBookmarks.length == 0) {
            const clientInfo = new ClientInfoView(this.props.taxReturn);
            const clientInfoBookmarks = clientInfo.getBookmarksWithZeroRefund(this.props.authorities, this.props.getAllTaxingAuthorities)
            this.setState({
                refundandPaymentDueBookmarks: clientInfoBookmarks.filter(x => x.heading == 'Refunds & Payments Due'),
            });
        }
    }

    enableScroll = () => {
        if (!this.state.enableLeftPanelScroll) {
            const scrollDiv = (document.querySelector(".scroll div div") as HTMLElement);
            scrollDiv.style.overflow = "scroll";
            scrollDiv.style.marginRight = "-17px";
            ((document.querySelector(".scroll div") as HTMLElement).children[2] as HTMLElement).style.display = "block";
            this.setState({ enableLeftPanelScroll: true });
        }

    }
    stopScroll = () => {
        if (this.state.enableLeftPanelScroll) {
            const scrollDiv = (document.querySelector(".scroll div div") as HTMLElement);
            const top: number = scrollDiv.scrollTop;
            scrollDiv.style.overflow = "hidden";
            scrollDiv.style.marginRight = "0px";
            scrollDiv.scrollTop = top;
            ((document.querySelector(".scroll div") as HTMLElement).children[2] as HTMLElement).style.display = "none";
            this.setState({ enableLeftPanelScroll: false });
        }

    }

    addedRefundAuthorityChange(event: any) {

    }

    onChangeRefundAmount(value: number) {

    }
    handleOverPaymentApplied(value: number) {

    }
    onCancelAddedRefundPopover() {

    }

    submitAddedRefundPopover() {
    }

    onSelectAddedRefund(refund: IRefundInfo) {
        this.setState({
            addedRefundPopoverTitle: "Refund",
            refund: refund,
        })
    }

    getTitle(authorities: ITaxingAuthority[], authorityId: number, amount: number) {
        let authority: ITaxingAuthority = authorities[authorities.findIndex(x => x.Id == authorityId)];
        if (authority) {
            return [
                <div title={authority.AuthorityName} className="bookmark-name wordbreak ellipsis">{authority.AuthorityName}</div>,
                <div className="bookmark-right bookmark-amount" title={'$' + Helper.formatCurency(Number(amount))}>
                    {'$' + Helper.formatCurency(Number(amount))}
                </div>
            ]
        } else {
            return null;
        }
    }

    getOverPaymentsTitle(authorityName: string, amount: number) {
        return [
            <div title={authorityName} className="bookmark-name wordbreak ellipsis">{authorityName}</div>,
            <div className="bookmark-right bookmark-amount" title={'$' + Helper.formatCurency(Number(amount))}>
                {'$' + Helper.formatCurency(Number(amount))}
            </div>
        ]
    }

    getOverpaymentAmount = (page: number) => {
        let overPayment: number = 0;
        this.props.taxReturn.formGroups.map((formGroups) => {
            if (formGroups.group == DocumentGroups.TaxReturns) {
                formGroups.forms.map((forms) => {
                    if (forms.pageNo.some(x => page == x)) {
                        overPayment = 0;
                        let refund: TaxDocument.IRefund = forms as TaxDocument.IRefund;
                        if (refund && refund.hasOwnProperty('overPayment')) {
                            overPayment = refund.overPayment;
                            return overPayment;
                        }
                    }
                });
            }
        });
        return overPayment;
    }

    removeDuplicateMultipartRefunds = (pageItems: IPageItem[]) => {
        let uniquePageItems: IPageItem[] = [];
        if (pageItems.filter(f => f.isMultipart == true).length > 0) {
            let multipartItems: IPageItem[] = pageItems.filter(f => f.isMultipart === true);
            uniquePageItems = pageItems.filter(f => f.isMultipart == undefined || f.isMultipart === false);
            multipartItems.forEach(form => {
                let formIndex: number = uniquePageItems.findIndex(x => x.pageTitle === form.pageTitle);
                if (formIndex === -1) {
                    uniquePageItems.push(form);
                }
                else {
                    let item = uniquePageItems[formIndex];
                    if (item && item.pageNo > form.pageNo) {
                        uniquePageItems.splice(formIndex, 1);
                        uniquePageItems.push(form);
                    }
                }
            });
            return uniquePageItems;
        }
        uniquePageItems = [...pageItems];
        return uniquePageItems;
    }

    public render() {
        let authorities = this.props.getAllTaxingAuthorities;
        let overPaymentTotalAmount: number = 0;

        const addedRefundPopover = (<Popover id="popover-added-refund" title={this.state.addedRefundPopoverTitle}>
            <AddedRefundPopover
                usStates={this.props.getAllTaxingAuthorities}
                addedRefund={this.state.refund}
                addedRefundAuthorityChange={this.addedRefundAuthorityChange}
                onChangeRefundAmount={this.onChangeRefundAmount}
                handleOverPaymentApplied={this.handleOverPaymentApplied}
                onCancelAddedRefundPopover={this.onCancelAddedRefundPopover}
                submitAddedRefundPopover={this.submitAddedRefundPopover}
                refundValue={this.state.refund.overPayment - this.state.refund.overPaymentApplied - this.state.refund.penalty}
                showAlert={false}
                message={""}
                isEdit={false}
                hideAddEditOption={true}
            />
        </Popover>
        )

        return <div className="bookmarksPane">
            <Accordion defaultActiveKey="0">
                <Card>
                    <Accordion.Toggle eventKey="0">
                        <Card.Header>
                            <Card.Title className="pull-left">
                                OverPayments
                        </Card.Title>

                        </Card.Header>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                        <Card.Body style={{ padding: '0px' }}>
                            <div className="pageListView">
                                {
                                    (this.state.refundandPaymentDueBookmarks.map((bookmarks) => {
                                        return this.removeDuplicateMultipartRefunds(bookmarks.pages).map((overpayment) => {
                                            var overPaymentAmount = this.getOverpaymentAmount(overpayment.pageNo);
                                            overPaymentTotalAmount = overPaymentTotalAmount + overPaymentAmount;
                                            return overPaymentAmount > 0 ? <div
                                                key={_.random(0, Number.MAX_SAFE_INTEGER)}
                                                className="custom-list-group-item bookmark-item">
                                                <div className="bookmark-row">
                                                    <div style={{ width: "24px" }}>
                                                        <BookmarkIcon {...overpayment} />
                                                    </div>
                                                    <div className="cursor-pointer" style={{ display: "inline-flex", width: "calc(100% - 26px)" }}
                                                        onClick={() => {
                                                            this.props.onPageSelect(overpayment.pageNo, -1, true, overpayment.isClientInfo);
                                                        }}>
                                                        {
                                                            this.getOverPaymentsTitle(overpayment.pageTitle.substring(0,
                                                                overpayment.pageTitle.indexOf(SEPARATOR)),
                                                                overPaymentAmount)
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                                : ""
                                        })
                                    }))
                                }
                                {
                                    (this.props.taxReturn.refundInfo.map((refund, i) => {
                                        return <div
                                            key={i}
                                            className="custom-list-group-item bookmark-item">
                                            <div className="bookmark-row">
                                                <div style={{ width: "24px" }}>
                                                    <i className="bookmark-icon fas fa-hand-holding-usd text-success"></i>
                                                </div>
                                                <OverlayTrigger rootClose trigger="click" onEnter={() => document.body.click()} onEntered={() => { this.stopScroll() }} onExit={() => { this.enableScroll() }} placement="right" overlay={addedRefundPopover}>
                                                    <div className="cursor-pointer" onClick={() => { this.onSelectAddedRefund(refund) }}
                                                        style={{ display: "inline-flex", width: "calc(100% - 26px)" }}>
                                                        {
                                                            this.getTitle(authorities, refund.authorityID, refund.overPayment)
                                                        }
                                                    </div>
                                                </OverlayTrigger>
                                            </div>
                                        </div>
                                    }))
                                }
                                {
                                    this.props.taxReturn.refundInfo.length == 0 && overPaymentTotalAmount == 0 ?
                                        <div className="textalign-center">No Content</div> : ""
                                }
                            </div>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        </div>
    }
};
