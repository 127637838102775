import { Action, Reducer } from 'redux';
import { actionTypes } from '../../../types/ActionTypes';
import {
	SendGroupedReturnsTableModel,
	SendGroupedReturnsState,
	SendGroupedReturnsDictionary
} from './SendGroupedReturnsState'
import { ResetSendGroupedReturnsAction } from './SendGroupedReturnsStore';

export interface RequestSendGroupedReturnsPagesAction {
	type: actionTypes.REQUEST_SEND_GROUPED_RETURNS_PAGES;
	query: string;
}

export interface ReceiveSendGroupedReturnsPagesAction {
	type: actionTypes.RECEIVE_SEND_GROUPED_RETURNS_PAGES;
	query: string;
	table: SendGroupedReturnsTableModel;
	totalRowCount: number;
}

type DispatchAction = RequestSendGroupedReturnsPagesAction
	| ReceiveSendGroupedReturnsPagesAction
	| ResetSendGroupedReturnsAction;

const unloadedState: SendGroupedReturnsState = {
	sendGroupedReturnsTableModel: {},
	query: '?',
	loading: false,
	totalRowCount: 0
} as SendGroupedReturnsState;

export const actionCreators = {};

export const reducer: Reducer<SendGroupedReturnsDictionary> = (state: SendGroupedReturnsDictionary = { "?": unloadedState }, incomingAction: Action) => {
	const action = incomingAction as DispatchAction;
	switch (action.type) {
		case actionTypes.REQUEST_SEND_GROUPED_RETURNS_PAGES:
			if (!state[action.query]) {
				let added = { ...unloadedState } as SendGroupedReturnsState;
				added.loading = true;
				added.query = action.query;
				let item = {} as SendGroupedReturnsDictionary;
				item[action.query] = added;

				return {
					...state,
					...item
				};
			}
			break;
		case actionTypes.RECEIVE_SEND_GROUPED_RETURNS_PAGES:
			if (state[action.query]) {
				let changed = state[action.query] as SendGroupedReturnsState;
				changed.loading = false;
				changed.sendGroupedReturnsTableModel = action.table;
				changed.totalRowCount = action.totalRowCount;
				let item = {} as SendGroupedReturnsDictionary;
				item[action.query] = changed;
				return {
					...state,
					...item
				};
			}
			break;
		case actionTypes.RESET_SEND_GROUPED_RETURNS:
			return clearGroupedReturnsPages();
		default:
			// The following line guarantees that every action in the KnownAction union has been covered by a case above
			const exhaustiveCheck: never = action;
	}
	return state || { "?": unloadedState };
};

function clearGroupedReturnsPages(): SendGroupedReturnsDictionary {
	return {} as SendGroupedReturnsDictionary;   //clearing the dictionary on archive/recall
}