import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { RecycleTaxReturns } from '../../../components/common/RecycleTaxReturns';
import { RecyleReturnsConstants, RestoreReturnConstants } from '../../../components/helper/Constants';
import * as RecycleReturnsState from '../../../store/reports/RecycleReturns/RecycleReturnsState';
import * as RecycleReturnsStore from '../../../store/reports/RecycleReturns/RecycleReturnsStore';
import * as Helper from '../../helper/HelperFunctions';
import { RecycleReturnsHeader } from './../RecycleReturns/RecycleReturnsHeader';
import { RecycleReturnsTable } from './../RecycleReturns/RecycleReturnsTable';
import * as CompanyStore from '../../../store/company/CompanyStore';
import * as UserSettingStore from '../../../store/userManagement/UserSettingStore';
import * as UploadTaxReturnStore from '../../../store/common/uploadTaxReturn';
import * as UserStore from '../../../store/userManagement/UserStore';
import { initialCompanySettings } from '../../../Core/ViewModels/Company/CompanySettingsViewModel';
import { VenusNotifier } from '../../helper/VenusNotifier';
import { RecycleBulkOperationQuery } from '../../../Core/ViewModels/Common/BulkOperationQuery';
import * as BulkOperationsStore from '../../../store/common/BulkOperationsStore';
import * as bootbox from 'bootbox';
import { ShowLoader, HideLoader } from '../../helper/Loader';
import { RecycleDocumentResources } from '../../helper/ResourceIdConstants';
import * as GroupedReturnStore from '../../../store/groupedreturns/GroupedReturnStore';
import { ITaxDocumentViewModel } from '@components/common/TaxReturn';

let moment = require('moment');
const pageSize: number = 20;
const NO_INDEX = -1;

type RecycleReturnsProps =
    {
        recycleReturns: RecycleReturnsState.RecycleReturnsState,
        userSettings: UserSettingStore.UserSettings,
        company: CompanyStore.ICompanyData,
        uploadTaxReturnStore: UploadTaxReturnStore.IUploadTaxReturnState,
        userStore: UserStore.IUserData,
        groupedReturnStore: GroupedReturnStore.IGroupInfoState,
        userResources: UserStore.IUserResources
    }
    & typeof RecycleReturnsStore.actionCreators   
    & typeof UploadTaxReturnStore.actionCreators
    & typeof UserStore.actionCreators
    & typeof UserSettingStore.actionCreators
    & typeof CompanyStore.actionCreators
    & typeof BulkOperationsStore.actionCreators
    & typeof GroupedReturnStore.actionCreators
    & RouteComponentProps<{ page: string }>;  

export class RecycleReturns extends React.Component<RecycleReturnsProps, RecycleReturnsState.RecycleReturnsPageState> {
    constructor(props: RecycleReturnsProps) {
        super(props);
        this.state = {
            page: 1,
            pageSize: pageSize,
            selectedRows: [],
            sortColumn: "",
            sortDirection: "",
            taxYear: new Date().getFullYear() - 1,
            clientName: "",
            clientID: "",
            taxReturnType: undefined,
            partner: "",
            sentBy: "",
            deliveredOn: undefined,
            status: "",
            deletedOn: undefined,
            deletedBy: "",
            refreshDelay: false,
            isBulkSelectionEnabled: false,
            deSelectedRows: [],
            isSelectAllChecked: false,
            showBulkSelectionMessage: false,
            defaultGroup: '',
            selectedDocumentGuid: '',
            officeLocation: '',
            filterGroupType: -1
        }

        this.onFilterChange = this.onFilterChange.bind(this);    
  

        this.onRowSelect = this.onRowSelect.bind(this);
        this.onSelectAll = this.onSelectAll.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.onSortChange = this.onSortChange.bind(this);
        this.handleTaxYearChange = this.handleTaxYearChange.bind(this);
         

        //Support Methods
        this.selectedDocumentIds = this.selectedDocumentIds.bind(this);
        this.selectedDocuments = this.selectedDocuments.bind(this);
        this.setSelectedDocumentsGuid = this.setSelectedDocumentsGuid.bind(this);
        this.buildQuery = this.buildQuery.bind(this);
        this.fetchRecycleReturns = this.fetchRecycleReturns.bind(this);
        this.loadRecycleReturns = this.loadRecycleReturns.bind(this);
        this.onPageReload = this.onPageReload.bind(this);
    }
    componentWillMount() {
        this.props.requestGroupConfiguration(true);
        this.props.requestGroupAccess(true);
    }

    componentDidMount() {
        this.props.requestUserSettings(true);
		this.props.requestRecycleReturns(this.buildQuery(this.state.page, pageSize), true);
        this.props.requestCompanySettings(false);

        const element = document.getElementById('content-wrapper');
        element && element.scrollTo(0, 0);
    }
    
    UNSAFE_componentWillReceiveProps(props: RecycleReturnsProps ) { 
        if (this.state.isBulkSelectionEnabled) {
            let selectedRows: number[] = [];
                 props.recycleReturns.recycleReturnTableModel.documents.forEach((value, index) => {
                    this.state.deSelectedRows.find((m) => m === value.documentId) === undefined && selectedRows.push(index);
                });

            this.setState({ selectedRows: selectedRows });
        }
    }
    //======================Grid Operation Starts here===============================


    private onRowSelect(row: any, isSelected: any, e: React.BaseSyntheticEvent) {
        let newList = [...this.state.selectedRows];
        let deSelectedRows = this.state.deSelectedRows;
        if (e.target.tagName !== 'BUTTON' && e.target.tagName !== 'I'
            && e.target.tagName !== 'SPAN' && e.target.tagName !== 'A') {
            if (isSelected) {
                newList.push(row.rowIndex);
                if (this.state.isBulkSelectionEnabled) {
                    index = deSelectedRows.indexOf(row.id);
                    deSelectedRows.splice(index, 1);
                }
            } else {
                var index = newList.indexOf(row.rowIndex);
                if (index > -1) {
                    newList.splice(index, 1);
                }
                if (this.state.isBulkSelectionEnabled) {
                    deSelectedRows.push(row.id);
                }
            }
            this.state.isBulkSelectionEnabled ?
                this.setState({ deSelectedRows: deSelectedRows, selectedRows: newList }, this.setSelectedDocumentsGuid) :
                this.setState({ selectedRows: newList }, this.setSelectedDocumentsGuid);
        }
        this.forceUpdate();
    }

    private onSelectAll(isSelected: any, rows: any, e: any) {
        let selectedRows: number[] = [];
        let showBulkSelectionMessage = false;
        if (isSelected) {
            let count = rows && rows.length ? rows.length : 0;
            Array.from(Array(count).keys());
            selectedRows = Array.from(Array(count).keys());

            showBulkSelectionMessage = true;
        }

        this.setState({
            isSelectAllChecked: isSelected,
            selectedRows: selectedRows,
            deSelectedRows: [],
            showBulkSelectionMessage: showBulkSelectionMessage,
            isBulkSelectionEnabled: false
        }, this.setSelectedDocumentsGuid);
    }

    private onPageChange(page: number, sizePerPage: number) {
        if (this.state.isBulkSelectionEnabled) {
            this.setState({
                page: page
            }, this.loadRecycleReturns);
        }
        else {
            this.setState({
                page: page,
                selectedRows: [],
                showBulkSelectionMessage: false,
                selectedDocumentGuid: ''
            }, this.loadRecycleReturns);
        }
    }

    private onBulkSelectionChange = (isEnabled: boolean) => {
        let showBulkSelectionMessage = false;
        this.setState({
            isBulkSelectionEnabled: isEnabled,
            showBulkSelectionMessage: showBulkSelectionMessage
        });
    }

    private onSortChange(sortName: string, sortOrder: string, column: number) {
        this.clearSelection();
        let newState: RecycleReturnsState.RecycleReturnsPageState = this.state;
        newState.sortColumn = sortName;
        newState.sortDirection = sortOrder;
        this.setState({ ...newState }, () => { this.onPageReload(); }); 
    }
        
    private handleTaxYearChange(eventKey: any) {

        let newState: RecycleReturnsState.RecycleReturnsPageState = this.state;
        newState.taxYear = eventKey.value;
        this.setState({ ...newState }, () => { this.onPageReload(); }); 
    }

    //======================Grid Operation Ends here=================================


    //======================Filter Operation Starts here=================================

    private onFilterChange(dataField: any) {

        let newState: RecycleReturnsState.RecycleReturnsPageState = {
            page: 1,
            pageSize: pageSize,
            selectedRows: [],
            sortColumn: this.state.sortColumn,
            sortDirection: this.state.sortDirection,
            taxYear: this.state.taxYear,
            clientName: "",
            clientID: "",
            taxReturnType: undefined,
            partner: "",
            sentBy: "",
            deliveredOn: undefined,
            status: "",
            deletedOn: undefined,
            deletedBy: "",
            isBulkSelectionEnabled: false,
            deSelectedRows: [],
            isSelectAllChecked: false,
            showBulkSelectionMessage: false,
            defaultGroup: '',
            selectedDocumentGuid: '',
            officeLocation: '',
            filterGroupType: -1
        };

        let filterTaxYear :any;
        for (let field of Object.keys(dataField)) {
            switch (field) {
                case 'clientName':
                    newState.clientName = dataField[field].value ? dataField[field].value : dataField[field];
                    break;
                case 'clientId':
                    newState.clientID = dataField[field].value ? dataField[field].value : dataField[field];
                    break;
                case 'taxReturnType':
                    newState.taxReturnType = dataField[field].value ? dataField[field].value : dataField[field];
                    break;
                case 'partner':
                    newState.partner = dataField[field].value ? dataField[field].value : dataField[field];
                    break;
                case 'sentBy':
                    newState.sentBy = dataField[field].value ? dataField[field].value : dataField[field];
                    break;
                case 'deliveredOn':
                    newState.deliveredOn = Helper.GetNumberOfDays(dataField[field].value ? dataField[field].value : dataField[field]);
                    break;
                case 'signatureStatus':
                    newState.status = dataField[field].value ? dataField[field].value : dataField[field];
                    newState.status = newState.status == '-1' ? '' : newState.status;
                    break;
                case 'locationName':
                    newState.officeLocation = dataField[field].value ? dataField[field].value : dataField[field];
                    newState.officeLocation = newState.officeLocation == '-1' ? '' : newState.officeLocation;
                    break;
                case 'deletedOn':
                    newState.deletedOn = moment(dataField[field].value ? dataField[field].value : dataField[field]).format('MM/DD/YYYY');
                    break;
                case 'deletedBy':
                    newState.deletedBy = dataField[field].value ? dataField[field].value : dataField[field];
                    break;
                case 'taxYear':
                    filterTaxYear = dataField[field].value ? dataField[field].value : dataField[field];
                    filterTaxYear = (filterTaxYear == "-1") ? "" : filterTaxYear;
                    this.setState({ taxYear: filterTaxYear });
                    newState.taxYear = ((dataField[field].value) && (dataField[field].value !== "-1")) ? dataField[field].value : "";
                    break;
                case 'groupName':
                    newState.filterGroupType = dataField[field].value ? dataField[field].value : dataField[field];
                    break;
            }
        }

        this.setState({ ...newState }, () => { this.onPageReload(); });
    }

    private onPageReload() {
        this.setState(
            { refreshDelay: true },
            () => {
                let query = this.buildQuery(this.state.page, this.state.pageSize);
                this.props.requestRecycleReturns(query, true, () => {
                    const totalRecords = this.props.recycleReturns.recycleReturnTableModel.documents.length;
                    if (this.state.page > 1 && totalRecords == 0) {
                        this.setState({
                            refreshDelay: false,
                            page: 1
                        }, () => this.onPageReload());
                    }
                    else {
                        this.setState({
                            refreshDelay: false,
                        });
                    }
                });
                this.clearSelection();
            });
    }

    private clearSelection = () => {
        this.setState({ selectedRows: [], deSelectedRows: [], isBulkSelectionEnabled: false, showBulkSelectionMessage: false, selectedDocumentGuid: '' });
    }
  

    buildQuery(page: number, pageSize: number) {
        return '?pageNo=' + page
            + '&pageSize=' + pageSize
            + '&sortColumn=' + this.state.sortColumn
            + '&sortDirection=' + this.state.sortDirection
            + '&taxYear=' + this.state.taxYear
            + '&clientName=' + this.state.clientName
            + '&clientID=' + this.state.clientID
            + '&partner=' + this.state.partner
            + '&status=' + this.state.status
            + '&engagementType=' + this.state.taxReturnType
            + '&sentBy=' + this.state.sentBy
            + '&deliveredOn=' + this.state.deliveredOn
            + '&deletedOn=' + this.state.deletedOn
            + '&deletedBy=' + this.state.deletedBy
            + '&officeLocation=' + this.state.officeLocation
            + '&filterGroupType=' + this.state.filterGroupType;
    }

    loadRecycleReturns() {
        let queryString = this.buildQuery(this.state.page, this.state.pageSize);
        this.props.requestRecycleReturns(queryString, true);
    }

    fetchRecycleReturns(page: number, sizePerPage: number) {
        let queryString = this.buildQuery(page, sizePerPage);
        this.setState({ page: page, pageSize: sizePerPage }, () => { this.props.requestRecycleReturns(queryString) });
    }

    private selectedDocumentIds() {
        let ids: number[] = [];
        for (var i in this.state.selectedRows) {
            let row = this.state.selectedRows[i];
            let selectedDocument = this.props.recycleReturns.recycleReturnTableModel.documents[row];
            if (selectedDocument) {
                ids.push(selectedDocument.documentId);
            }
        }
        return ids;
    }

    private setSelectedDocumentsGuid() {

        let documentGuids: string[] = [];
        this.state.selectedRows.map((row, i) => {
            let selectedDocument = this.props.recycleReturns.recycleReturnTableModel.documents[row];
            if (selectedDocument) {
                documentGuids.push(selectedDocument.documentGuid);
            }
        });

        this.setState({
            selectedDocumentGuid: documentGuids.join(",")
        });
    }

    private selectedDocuments() {
        let docs: RecycleTaxReturns[] = [];
        for (var i in this.state.selectedRows) {
            let row = this.state.selectedRows[i];
            let selectedDocument = this.props.recycleReturns.recycleReturnTableModel.documents[row];
            if (selectedDocument) {
                docs.push(selectedDocument);
            }
        }
        return docs;
    }

    private getTaxReturnViewModel() {
        return this.state.selectedRows.map((row) => {
            return {
                documentId: this.props.recycleReturns.recycleReturnTableModel.documents[row].documentId,
                isGroupedReturn: this.props.recycleReturns.recycleReturnTableModel.documents[row].groupId > 0,
                groupId: this.props.recycleReturns.recycleReturnTableModel.documents[row].groupId
            } as ITaxDocumentViewModel;
        }).filter((y) => y.documentId > 0);
    }
 
    private confirmDocumentDelete = (rowIndex: number = NO_INDEX) => {
        let ids = this.onPopupOpen(rowIndex);

        if (ids.length == 0) {
            VenusNotifier.Warning(RecyleReturnsConstants.SelectDocumentWarning, null);
            return;
        }

        bootbox.confirm({
            title: RecyleReturnsConstants.Title.RecyleReturns,
            message: RecyleReturnsConstants.ConfirmMessages.Delete,
            buttons: {
                cancel: {
                    label: '<i class="fas fa-times"></i> Cancel',
                    className: 'btn-white btn-default'
                },
                confirm: {
                    label: '<i class="fas fa-check"></i> Confirm',
                    className: 'btn-info'
                }
            },
            callback: (result: boolean) => {
                if (result) {
                    this.handleDocumentDelete(this.getTaxReturnViewModel(), result);
                }
            }
        });
    }

    private handleDocumentDelete = (selectedDocumentIds: ITaxDocumentViewModel[], result: boolean) => {
        result && this.props.deleteDocuments(selectedDocumentIds, RecycleDocumentResources.Prefix + RecycleDocumentResources.DeleteButton ,() => {
            this.setState({ selectedRows: [], selectedDocumentGuid: '' });
            this.onPageReload();
        });
    }

    private onPopupOpen(rowIndex: number) {
        if (rowIndex !== NO_INDEX) {
            return [this.props.recycleReturns.recycleReturnTableModel.documents[rowIndex].id];
        }
        if (this.state.selectedRows.length == 0) {
            return [];
        }
        return this.selectedDocumentIds();
    }

    private onRestoreDocument = (rowIndex: number = NO_INDEX) => {
        this.onRestoreValidate(rowIndex, this.restoreCallBack);
    }

    private onRestoreValidate = (rowIndex: number, callback: (rowIndex: number) => void) => {
        const selectedDocuments = this.selectedDocuments();
        for (let i = 0; i < selectedDocuments.length; i++) {
            if (moment(selectedDocuments[i].deliveredOn).add(selectedDocuments[i].retentionPeriod, 'days') <
                moment(new Date())) {
                VenusNotifier.Warning(RestoreReturnConstants.ReturnExpired.Restore, null);
                return;
            }
        }
        callback(rowIndex);
    }

    private restoreCallBack = (rowIndex: number) => {
        let ids = this.onPopupOpen(rowIndex);

        if (ids.length == 0) {
            VenusNotifier.Warning(RestoreReturnConstants.SelectDocumentWarning, null);
            return;
        }

        bootbox.confirm({
            title: RestoreReturnConstants.Title.Restore,
            message: RestoreReturnConstants.ConfirmMessages.Restore,
            buttons: {
                cancel: {
                    label: '<i class="fas fa-times"></i> Cancel',
                    className: 'btn-white btn-default'
                },
                confirm: {
                    label: '<i class="fas fa-check"></i> Confirm',
                    className: 'btn-info'
                }
            },
            callback: (result: boolean) => {
                if (result) {
                    this.handleDocumentRestore(ids, result);
                }
            }
        });
    }

    private handleDocumentRestore = (selectedDocumentIds: number[], result: boolean) => {
        ShowLoader(RestoreReturnConstants.Progress);
        if (result) {
            if (this.state.isBulkSelectionEnabled) {
                this.onBulkRestoreReturns();
            }
            else {
                this.props.restoreDocuments(selectedDocumentIds, 
                        RecycleDocumentResources.Prefix + RecycleDocumentResources.RestoreButton,
                            (deletionPendingDocuments:number[]) => {
                    HideLoader();
                    VenusNotifier.Success(RestoreReturnConstants.SuccessMessages.Restore,null);
                    this.setState({
                        selectedRows: [],
                        isBulkSelectionEnabled: false,
                        showBulkSelectionMessage: false,
                        selectedDocumentGuid: ''
                    });
                    this.onPageReload();
                });
            }
        }
    }

    private onBulkRestoreReturns = () => {
        let query = this.getBulkOperationQuery();
        this.props.bulkReturnRestore(query, () => {
            HideLoader();
            this.setState({
                selectedRows: [],
                isBulkSelectionEnabled: false,
                selectedDocumentGuid: ''
            });
            this.onPageReload();
        });
    }

    getBulkOperationQuery = (): RecycleBulkOperationQuery => {
        let query: RecycleBulkOperationQuery = {
            clientId: this.state.clientID,
            name: this.state.clientName,
            partner: this.state.partner,
            deliveredOn: this.state.deliveredOn,
            engagementType: this.state.taxReturnType as number,
            sentBy: this.state.sentBy,
            status: this.state.status,
            taxYear: this.state.taxYear.toString(),
            deletedBy: this.state.deletedBy,
            deletionDate: this.state.deletedOn,
            unSelectedIds: this.state.deSelectedRows,
            officeLocation: this.state.officeLocation,
        }
        return query;
    }

    public render() {

        return <div className='user-assignment-content'>
            <RecycleReturnsHeader 
                parentCommonPrefix={RecycleDocumentResources.Prefix}
                pageTitle="Recycle Bin"
                onTaxYearSelect={this.handleTaxYearChange}
                selectedDocumentCount={this.state.selectedRows.length}
                taxYear={this.state.taxYear}
                onPageReload={this.onPageReload}
                onDeleteDocument={this.confirmDocumentDelete}
                company={this.props.company}
                selectedERO={this.props.userSettings.settings && this.props.userSettings.settings.defaultSettings ? this.props.userSettings.settings.defaultSettings.eroUser : 0}
                getUploadLink={this.props.getUploadLink}
                processTaxReturn={this.props.processTaxReturn}
                submitTaxReturn={this.props.submitTaxReturn}
                users={this.props.userStore.users}
                companySettings={this.props.company.companySettings ? this.props.company.companySettings : initialCompanySettings}
                userDefaultSettings={this.props.userSettings.settings}
                refreshDelay={this.state.refreshDelay}
                onRestoreDocument={this.onRestoreDocument}
                isBulkSelectionEnabled={this.state.isBulkSelectionEnabled}
                defaultGroup={this.state.defaultGroup ? this.state.defaultGroup : ''}
                groupInfo={this.props.groupedReturnStore.groupInfo}
                groupAccess={this.props.groupedReturnStore.groupAccess}
                updateGroupInfoState={this.props.requestGroupConfiguration}
                selectedDocuments = { this.selectedDocuments()}
                userResources={this.props.userResources}
            />
            <br />
            <input type="hidden" id="selectedDocumentsGuid" value={this.state.selectedDocumentGuid} />
            <RecycleReturnsTable
                ref='RecycleReturnsTable'
                onPageChange={this.onPageChange}
                onSortChange={this.onSortChange}
                onFilterChange={this.onFilterChange}
                pageNo={this.state.page}
                totalRows={this.props.recycleReturns.totalRowCount}
                loadGrid={this.loadRecycleReturns}
                isLoading={this.props.recycleReturns.loading}
                pageSize={pageSize}
                onRowSelect={this.onRowSelect}
                onSelectAll={this.onSelectAll}
                recycleReturns={this.props.recycleReturns}
                selectedRows={this.state.selectedRows}
                showBulkSelectionMessage={this.state.showBulkSelectionMessage}
                onBulkSelectionChange={this.onBulkSelectionChange}
                groupInfo={this.props.groupedReturnStore.groupInfo}
            />
        </div>;
    }
};
