import * as React from "react";
import { Modal, Button, ProgressBar, Col } from "react-bootstrap";
import { DocumentUploadDropzoneComponent } from "../../../../components/common/UploadDocument/DocumentUploadDropzoneComponent";
import { ButtonFormatter } from "../../../../components/common/UploadDocument/TaxSoftwareTypeUpload/UploadCommonFormatters";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { VenusNotifier } from "../../../../components/helper/VenusNotifier";
import {
    UploadFunctions,
    getFileExtension,
    removeSpecialCharacters,
    GetFileMagicNumber,
    validateFileSize
} from "@sssuite-js-packages/file-utility";
import { validateFileTypes, fileContentValidation } from "../../../../components/helper/Validations";
import { K1Attachments } from "../../../../components/helper/Constants";
import { UploadStatus, SasContainer } from "../../../../Core/ViewModels/Common/UploadDocumentViewModel";
import { IBlobFile } from "../../../../store/common";
import { ITaxReturn } from "../../../common/TaxReturn";
import { K1AttachmentUploadData } from "../K1Attachments/IK1Attachment";
import { confirm } from "bootbox";
import { CustomMultiSelect } from "../../MultipleSelectComponent";
import { IBookmarkSection } from "../ProcessReturnModels";

const djsConfig: any = {
    uploadMultiple: true,
    addRemoveLinks: true,
    headers: { "Access-Control-Allow-Origin": "*", "x-ms-blob-type": "BlockBlob" },
    previewsContainer: false,
    autoProcessQueue: false,
    autoDiscover: false
};

let tableOptions = {
    noDataText: K1Attachments.emptyFileListMessage
};

export interface K1AttachmentUploadProps {
    taxReturn: ITaxReturn;
    showUploadAttachment: boolean;
    onCloseModal: () => void;
    maxFileLimitUpload: number;
    onSubmit: (newUploadedDocumentData: K1AttachmentUploadData[]) => void;
    getUploadLink: (url: string, callback?: (data?: IBlobFile) => void) => void;
    bookmarks: IBookmarkSection[];
}
export interface K1AttachmentUploadState {
    k1AttachmentData: K1AttachmentUploadData[];
    sasContainer: SasContainer[];
    config: {
        dropzoneSelector: string;
        iconFiletypes: [".pdf"];
        showFiletypeIcon: boolean;
        postUrl: string;
    };
    bookmarkOptions: any;
    selectedBookmarks: number[];
}
export class K1AttachmentUploadModal extends React.Component<K1AttachmentUploadProps, K1AttachmentUploadState> {
    private customMultiSelect: any;

    constructor(props: K1AttachmentUploadProps) {
        super(props);

        this.state = {
            k1AttachmentData: [],
            sasContainer: [],
            config: {
                dropzoneSelector: "div.filepicker",
                iconFiletypes: [".pdf"],
                showFiletypeIcon: true,
                postUrl: "/api/"
            },
            bookmarkOptions: [],
            selectedBookmarks: []
        };
    }

    eventHandlers = {
        addedfiles: (files: any) => {
            try {
                let _self = this;
                _self.validateFileCount(files);
                let documentUploadData: K1AttachmentUploadData[] = _self.convertToModel(files);
                documentUploadData = _self.handleDuplicates(documentUploadData);
                if (documentUploadData.length > 0) {
                    _self.validateFileContent(documentUploadData).then((result) => {
                        _self.setState(
                            {
                                k1AttachmentData: _self.state.k1AttachmentData.concat(result)
                            },
                            () => {
                                _self.getUploadLink();
                            }
                        );
                    });
                }
            } catch (error) {
                VenusNotifier.Warning(error.message, null);
            }
        }
    };

    componentDidMount(): void {
        if (this.props.bookmarks) {
            this.getK1Attachments();
        }
    }

    private convertToModel = (uploadedFiles: any): K1AttachmentUploadData[] => {
        let documentUploadData: K1AttachmentUploadData[] = [];
        for (let i = 0; i < uploadedFiles.length; i++) {
            let uploadedFile = uploadedFiles[i];
            if (validateFileSize(uploadedFile)) {
                let tmpFileName = removeSpecialCharacters(uploadedFiles[i].name);
                let fileExtension = getFileExtension(tmpFileName);

                // Validate file extension
                if (validateFileTypes(fileExtension)) {
                    documentUploadData.push({
                        fileName: tmpFileName,
                        number: documentUploadData.length + this.state.k1AttachmentData.length + 1,
                        progressBar: 0,
                        status: UploadStatus.Wait,
                        gridRowDisable: true,
                        rowIndex: documentUploadData.length + this.state.k1AttachmentData.length + 1,
                        file: uploadedFile,
                        sasUrl: "",
                        isPDFloaded: false,
                        isDeleted: false,
                        fileExtension: fileExtension,
                        shareholderId: [],
                        clientId: "",
                        uuid: ""
                    });
                }
            }
        }
        return documentUploadData;
    };

    private handleDuplicates = (uploadData: K1AttachmentUploadData[]): K1AttachmentUploadData[] => {
        for (let i = 0; i < uploadData.length; i++) {
            let file = uploadData[i];
            let fileName: string = file.fileName || "";
            let fileExtension = getFileExtension(fileName);
            let filecount = 1;
            while (this.isFileExist(fileName, this.state.k1AttachmentData, this.props.taxReturn.k1Attachments)) {
                fileName = file.fileName || "";
                fileName = fileName.replace("." + fileExtension, "");
                fileName = fileName + " (" + filecount + ")." + fileExtension;
                filecount++;
            }
            uploadData[i].fileName = fileName;
        }
        return uploadData;
    };

    private validateFileContent = (uploadData: K1AttachmentUploadData[]): Promise<any> => {
        var promise: any = null;
        let _tmpTaxReturnUploadData: K1AttachmentUploadData[] = Object.assign({}, uploadData);
        for (let i = 0; i < uploadData.length; i++) {
            let uploadedFile = uploadData[i].file;
            promise = new Promise((resolve) => {
                GetFileMagicNumber(uploadedFile).then((result) => {
                    // Validate file content
                    if (!fileContentValidation(result)) {
                        let index = uploadData.findIndex((x) => x.fileName == _tmpTaxReturnUploadData[i].fileName);
                        uploadData.splice(index, 1);
                    }
                    resolve(result);
                });
            });
        }
        return promise.then(() => {
            return uploadData;
        });
    };

    isFileExist = (fileName: string, tempGridData: any, documentData: any): boolean => {
        var found = false;
        for (var i = 0; i < tempGridData.length; i++) {
            if (tempGridData[i].fileName == fileName && !tempGridData[i].isDeleted) {
                found = true;
                break;
            }
        }
        if (documentData && documentData.length > 0) {
            for (var i = 0; i < documentData.length; i++) {
                if (documentData[i].fileName == fileName) {
                    found = true;
                    break;
                }
            }
        }
        return found;
    };

    private validateFileCount = (uploadedFiles: any) => {
        if (uploadedFiles.length + this.state.k1AttachmentData.length > this.props.maxFileLimitUpload) {
            throw new Error("You cannot upload more than " + this.props.maxFileLimitUpload + " files.");
        }
    };

    private getUploadLink = () => {
        let _self = this;
        let uploadHelperFunctions = new UploadFunctions();
        this.state.k1AttachmentData
            .filter((x) => x.status == UploadStatus.Wait)
            .forEach((file: K1AttachmentUploadData, index: number) => {
                file.status = UploadStatus.Initiating;
                file.fileName = removeSpecialCharacters(file.fileName);
                let param =
                    "?documentGuid=" +
                    this.props.taxReturn.documentGuid +
                    "&fileName=" +
                    escape(file.fileName != undefined ? file.fileName : "") +
                    "&taxYear=" +
                    this.props.taxReturn.taxYear +
                    "&uploadType=k1attachment" +
                    "&fileExtension=" +
                    file.fileExtension;
                _self.props.getUploadLink("/api/ProcessReturn/GetUploadSasUrlAsync" + param, (data?: IBlobFile) => {
                    try {
                        if (data) {
                            file.sasUrl = data ? data.sas : "";
                            file.status = UploadStatus.Uploading;
                            _self.setState({ k1AttachmentData: _self.state.k1AttachmentData }, () =>
                                uploadHelperFunctions.uploadFile(
                                    file.file,
                                    data,
                                    file.fileName ? file.fileName : "",
                                    _self.uploadProgressCallback,
                                    _self.uploadCommittCallBack,
                                    _self.uploadFailureCallback
                                )
                            );
                        } else {
                            throw new Error("Upload link not found !!");
                        }
                    } catch (error) {
                        _self.state.k1AttachmentData.splice(index, 1);
                        _self.setState({ k1AttachmentData: _self.state.k1AttachmentData });
                    }
                });
            });
    };

    public uploadProgressCallback = (percent: number, fileToUpload: any) => {
        let tempGridData = this.state.k1AttachmentData;
        tempGridData.map((tempGridDataValue: K1AttachmentUploadData, index: number) => {
            if (tempGridDataValue.fileName == fileToUpload.fileName) {
                tempGridDataValue.progressBar = percent - 10;
            }
        });
        this.setState({
            k1AttachmentData: tempGridData
        });
    };

    uploadCommittCallBack = (fileToUpload: any) => {
        if (this.state.k1AttachmentData.length > 0) {
            let tempGridData: K1AttachmentUploadData[] = this.state.k1AttachmentData;
            tempGridData.map((tempGridDataValue: K1AttachmentUploadData, index: number) => {
                if (tempGridDataValue.fileName == fileToUpload.fileName) {
                    tempGridDataValue.progressBar = 100;
                    tempGridDataValue.gridRowDisable = false;
                    tempGridDataValue.status = UploadStatus.Uploaded;
                    this.updateDocumentData(tempGridDataValue);
                }
            });
        }
    };

    //Implementation of this callback will be done later
    uploadFailureCallback = (fileToUpload: any) => {
        console.log("upload failed for file:", fileToUpload.fileName);
    };

    private updateDocumentData = (attachmentData: K1AttachmentUploadData) => {
        let uploadedDocumentData: K1AttachmentUploadData[] = this.state.k1AttachmentData;
        uploadedDocumentData.map((item, index) => {
            if (item.fileName == attachmentData.fileName) {
                item = attachmentData;
            }
        });
        this.setState({ k1AttachmentData: uploadedDocumentData });
    };

    private isUploadOnGoing = (attachmentData: K1AttachmentUploadData[]): boolean => {
        let isuploading: boolean = false;
        attachmentData.map((value) => {
            if (value.progressBar == undefined ? 0 : value.progressBar < 100) {
                isuploading = true;
                return;
            }
        });
        return isuploading;
    };

    defaultType(cell: any, row: any) {
        return cell;
    }

    progressbar(cell: any, row: any) {
        return <ProgressBar striped variant={cell != undefined && cell != 100 ? "warning" : "success"} now={cell} />;
    }

    deleteDocument = (row: any) => {
        let _self = this;
        confirm({
            message: K1Attachments.deleteConfirmMessage,
            buttons: {
                cancel: {
                    label: '<i class="fas fa-times"></i> Cancel',
                    className: "btn-white btn-default"
                },
                confirm: {
                    label: '<i class="fas fa-check"></i> OK',
                    className: "btn-info"
                }
            },
            callback: (result: boolean) => {
                if (result) {
                    var _gridData = [..._self.state.k1AttachmentData];
                    _gridData = _gridData.filter((i) => i.fileName != row.fileName);

                    //Update row index
                    for (let i = 0; i < _gridData.length; i++) {
                        _gridData[i].rowIndex = i;
                        _gridData[i].number = i + 1;
                    }

                    _self.setState(
                        {
                            k1AttachmentData: _gridData
                        },
                        () => {
                            _self.forceUpdate();
                        }
                    );
                }
            }
        });
    };

    buttonFunction(cell: any, row: any) {
        return (
            <ButtonFormatter
                deleteReturn={() => this.deleteDocument(row)}
                disabled={row.progressBar != undefined && row.progressBar != 100 ? true : false}
                data-test-auto="83A48C48-3BCE-411C-B9F2-C32A386D424F"
            />
        );
    }

    private onCancel = () => {
        if (this.isUploadOnGoing(this.state.k1AttachmentData)) {
            VenusNotifier.Warning(K1Attachments.uploadInProgress, null);
            return;
        }
        this.setState({ k1AttachmentData: [] });
        this.props.onCloseModal();
    };

    private onHide = () => {
        this.onCancel();
    };

    private onK1AttachmentSubmit = () => {
        if (this.state.k1AttachmentData.length < 1) {
            VenusNotifier.Warning(K1Attachments.documentRequired, null);
            return;
        }
        if (this.isUploadOnGoing(this.state.k1AttachmentData)) {
            VenusNotifier.Warning(K1Attachments.uploadInProgress, null);
            return;
        }
        if (this.checkForMinimumRecipient() > 0) {
            VenusNotifier.Warning(K1Attachments.noRecipientSelected, null);
            return;
        }

        let updatedK1AttachmentData: K1AttachmentUploadData[] = this.state.k1AttachmentData;
        updatedK1AttachmentData.forEach((e) => (e.shareholderId = e.shareholderId.filter((item) => item !== 0)));

        this.props.onSubmit(updatedK1AttachmentData);
    };

    checkForMinimumRecipient = () => {
        const selectedRecipients = this.state.k1AttachmentData.filter((e) => e.shareholderId.length < 1);
        return selectedRecipients.length;
    };

    getK1Attachments = () => {
        const bookmarkPageTitle = this.props.bookmarks[0].pages.map((e) => ({ label: `${e.pageTitle}`, value: `${e.id}` }));
        this.setState({ bookmarkOptions: [...bookmarkPageTitle] });
    };

    private handleBookmarkSelectionChange = (selectedOptions: string, cell: any) => {
        let tempData: K1AttachmentUploadData[] = this.state.k1AttachmentData;
        let selectedBookmarks: number[] = [];

        selectedOptions &&
            selectedOptions.split(",").map((v, i) => {
                if (parseInt(v) !== -1) {
                    selectedBookmarks.push(parseInt(v));
                }
            });

        tempData.map((tempDataValue: K1AttachmentUploadData, index: number) => {
            if (tempDataValue.fileName == cell.fileName) {
                tempDataValue.shareholderId = selectedBookmarks;
            }
        });
    };

    private recipient = (row: any, cell: any) => {
        let shareholderIds = cell.shareholderId as number[];
        return (
            <CustomMultiSelect
                id="k1Attachment"
                options={this.state.bookmarkOptions}
                selectedOptions={
                    this.state.selectedBookmarks.length != 0 ? this.state.selectedBookmarks.join() : shareholderIds.join()
                }
                clearable={false}
                onRef={(ref: any) => (this.customMultiSelect = ref)}
                enableAllFilter={true}
                isMultiSelectCheckbox={true}
                filterHandler={(selectedOptions: string) => {
                    this.handleBookmarkSelectionChange(selectedOptions, cell);
                }}
                placeholder="Select..."
            />
        );
    };

    public render() {
        const columns = [
            {
                header: "File Name",
                key: "fileName",
                isKey: true,
                dataFormat: this.defaultType,
                width: "300px",
                columnClassName: ""
            },
            {
                header: "Progress",
                key: "progressBar",
                isKey: false,
                dataFormat: this.progressbar,
                width: "25%",
                columnClassName: ""
            },
            {
                header: "Recipient(s)",
                key: "k1ShareholderDetailsId",
                isKey: false,
                dataFormat: this.recipient.bind(this),
                columnClassName: "overflowVisible text-left-align",
                width: "25%",
                hidden: false
            },
            {
                header: "Action",
                key: "button",
                isKey: false,
                dataFormat: this.buttonFunction.bind(this),
                width: "60px",
                columnClassName: ""
            }
        ];

        return (
            <Modal className="upload-doc-modal" show={this.props.showUploadAttachment} onHide={this.onHide} enforceFocus={false}>
                <Modal.Header closeButton data-test-auto="496E1238-E8D8-4655-B218-6F5EED8E37CE">
                    <Modal.Title>K-1 Attachments</Modal.Title>
                </Modal.Header>
                <Modal.Body className="prosystem-upload-doc-body overflowVisible">
                    <div className="row">
                        <Col className="col-sm-2 text-center">
                            <DocumentUploadDropzoneComponent
                                componentConfig={this.state.config}
                                djsConfig={djsConfig}
                                eventHandlers={this.eventHandlers}
                                autoTestId={"{8EF10222-5FB4-4BE3-A4A8-9451DB339692}"}
                            />
                        </Col>
                        <Col id="uploadDocumentTable" className="col-sm-10 uploaded-documents-table-container overflowVisible">
                            <BootstrapTable
                                data={this.state.k1AttachmentData}
                                bodyContainerClass={"overflowVisible"}
                                striped
                                bordered
                                options={tableOptions}
                            >
                                {columns.map((value, index) => {
                                    return (
                                        <TableHeaderColumn
                                            tdStyle={{ whiteSpace: "normal" }}
                                            thStyle={{ whiteSpace: "normal" }}
                                            key={index}
                                            isKey={value.isKey}
                                            dataField={value.key}
                                            dataFormat={value.dataFormat}
                                            width={value.width}
                                            columnClassName={value.columnClassName}
                                            dataAlign={"center"}
                                        >
                                            {value.header}
                                        </TableHeaderColumn>
                                    );
                                })}
                            </BootstrapTable>
                        </Col>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="default"
                        className="btn-white"
                        onClick={this.onCancel}
                        data-test-auto="50DCC1DC-54C6-48E4-811B-3667CDA348F5"
                    >
                        <i className="fas fa-times"></i>
                        Cancel
                    </Button>
                    <Button
                        variant="info"
                        onClick={this.onK1AttachmentSubmit}
                        data-test-auto="9C2D4DA8-BA1E-40AC-9E2F-9B8EBFA9AABA"
                    >
                        <i className="fas fa-save"></i>
                        Add
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}
