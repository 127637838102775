import * as React from 'react';
import { Button } from 'react-bootstrap';
import { UploadDocumentModal } from '../../common/UploadDocumentModal';
import { IUserModel } from '../../../Core/ViewModels/User/UserViewModel';
import { ICompanySettings, TaxSoftwareType } from '../../../Core/ViewModels/Company/CompanySettingsViewModel';
import * as CompanyStore from '../../../store/company/CompanyStore';
import { UploadFabButton } from '../../../components/common/UploadFabButton';
import { IUserDefaultSettingsStates } from '../../navigation/profile/MySettings';
import { IGroupAccess } from '../../GroupedReturns/GroupConfiguration';
import { IGroupInfo } from '../../../store/groupedreturns/GroupedReturnProcessState';
import { RecycleDocumentResources } from '../../helper/ResourceIdConstants';
import { RecycleBinStatus } from '../../../components/common/TaxReturn';
import { RecycleTaxReturns } from '../../../components/common/RecycleTaxReturns';
import { RestoreReturnConstants, CommonMessages } from '../../../components/helper/Constants';
import * as UserStore from '../../../store/userManagement/UserStore';

interface RecycleReturnsHeaders {
    pageTitle: string, 
    onTaxYearSelect: (event: any) => void;
    selectedDocumentCount: number,
    taxYear: number;
    onPageReload: () => void;  
    onDeleteDocument: (rowIndex: number) => void;
    users: IUserModel[];
    company: CompanyStore.ICompanyData;
    selectedERO: number;
    processTaxReturn: (url: string, callback: () => void) => void;
    getUploadLink: (url: string) => void;
	submitTaxReturn: (url: string, taxData: string, callback: () => void) => void;
	userDefaultSettings: IUserDefaultSettingsStates,
    companySettings: ICompanySettings,
    refreshDelay?: boolean;
    onRestoreDocument: (rowIndex: number) => void;
    isBulkSelectionEnabled: boolean;
    defaultGroup: string;
    groupInfo: IGroupInfo[];
    groupAccess: IGroupAccess[];
    updateGroupInfoState: (reload: boolean) => void;
    parentCommonPrefix:string;
    selectedDocuments:RecycleTaxReturns[];
    userResources: UserStore.IUserResources
}


const NO_INDEX = -1;
export class RecycleReturnsHeader extends React.Component<RecycleReturnsHeaders, {}> {
    
    private isRestoreButtonDisabled():boolean {
        if(this.props.isBulkSelectionEnabled || this.props.selectedDocumentCount === 0)
            return true;
        return this.props.selectedDocuments.filter(x=> x.status==RecycleBinStatus.DeletionInProgress).length>0;
    }

    private getRestoreButtonTitle():string {
        let hasRestoreButtonAccess = this.props.userResources.resourceIds.includes(RecycleDocumentResources.Prefix + RecycleDocumentResources.RestoreButton);
        if(this.props.isBulkSelectionEnabled || this.props.selectedDocumentCount === 0)
            return hasRestoreButtonAccess ? '' : CommonMessages.UnAuthorized;
        return (hasRestoreButtonAccess ? (this.props.selectedDocuments.filter(x=> x.status==RecycleBinStatus.DeletionInProgress).length>0?RestoreReturnConstants.RestoreNotAllowedMessage:'') : CommonMessages.UnAuthorized);
    }

    public render() {
       
        return <div>
            <div className="row" style={{ marginBottom:5 }}>
                <div className="col-sm-8">
                    <h3>{this.props.pageTitle}</h3>
                </div>
                
            </div>
            <div className="row">
                <div className="col-sm-10"> 
                    <Button
                        variant='default'
                        className='btn btn-white'
                        disabled={this.props.selectedDocumentCount === 0 || this.props.isBulkSelectionEnabled}
                        data-test-auto="B7924657-96E3-4965-8469-BBE05578F208"
                        onClick={() => this.props.onDeleteDocument(NO_INDEX)}
                        data-resource-id={this.props.parentCommonPrefix + RecycleDocumentResources.DeleteButton}
                        >
                        <i className='fas fa-trash-alt'></i> Delete
                    </Button>
                    <Button
                        variant='default'
                        className='btn btn-white'
                        style={{ marginLeft: '8px' }}
                        disabled={this.isRestoreButtonDisabled()}
                        title={this.getRestoreButtonTitle()}
                        data-test-auto="CF7B62E7-CD65-4081-8E2B-64A0C011F6DD"
                        onClick={() => this.props.onRestoreDocument(NO_INDEX)}
                        data-resource-id={this.props.parentCommonPrefix + RecycleDocumentResources.RestoreButton}>
                        <i className='fas fa-undo'></i> Restore
                    </Button>
                    <Button
                        variant="default"
						onClick={this.props.onPageReload}
                        title="Refresh"
                        disabled={this.props.refreshDelay}
						className="btn-white btn-only marR10 btn btn-default"
                        style={{ paddingLeft: '12px', marginLeft: '8px' }}
						data-test-auto="11BC25B0-F325-4170-AFA9-598384364972">
                        <span className={(this.props.refreshDelay ? "fa-spin " : " ") + 'fas fa-redo-alt fa-btn-icon'}
                            style={{ padding: '4px 0', textShadow: '0px 1px 0px rgba(255,255,255,.5)', fontSize: '12px' }}>
                        </span>
					</Button>
                </div>
               
            </div>
            
        </div >
    }
}
export default RecycleReturnsHeader;