import * as React from 'react';
import { Link } from 'react-router-dom';
import { ICompanySettings, DueDateType } from '../../../Core/ViewModels/Company/CompanySettingsViewModel';
import Select from 'react-select';
import { VoucherTabConstants } from '../../helper/Constants'; 
import { IHelptextModel } from '../../../Core/ViewModels/Common/IHelptextModel';
import { fetchHelpText } from '../../../components/helper/HelperFunctions';
export interface VoucherDueDateProps {
    companySettings: ICompanySettings;
    handleVoucherDueDateChange(event: any): void;
    helptexts?: IHelptextModel[];
}

export class VoucherDueDateSetting extends React.Component<VoucherDueDateProps, {}> {

    public render() {
        return <div className="new-main-content">
            <h3>Default Voucher Due Date <Link to={'#'}
                className="help-icon marL05"
                data-placement="right"
                data-toggle="tooltip"
                title={fetchHelpText(this.props.helptexts, "1F1A9C6A-2DCC-49B2-8E1D-FAEA3F0D327B")}>
                <i className="fas fa-question-circle"></i>
            </Link></h3> 
            <div className="marL20">
            </div>
            <div className="row marT20">
                <div className="col-sm-2 col-md-2 col-lg-2 padT07">Select Default Voucher Due Date from </div>
                <div className="col-sm-3 col-md-3 col-lg-3">
                    <Select
                        name="form-field-name"
                        value={this.props.companySettings.displaySettingsModel.voucherDueDateType == 0 ? DueDateType.SSRDefault: this.props.companySettings.displaySettingsModel.voucherDueDateType}
                        onChange={this.props.handleVoucherDueDateChange}
                        options={VoucherTabConstants.voucherDueDateTypes}
                        data-test-auto="52A69016-2B50-4B88-84B4-3E82ED02586A"
                        clearable={false}
                    />

                </div>
            </div>
            </div>
    }
}