import React, { useEffect } from "react";
import { ProductAccessDenied } from "./svg/CustomGraphics";
import { Button } from "react-bootstrap";
import { WithLogout, WithLogoutProps } from "src/oidcClient/withLogout";
import { logger } from "src/routes/LoggedIn";

const FeatureDisabled: React.FC<WithLogoutProps> = ({ onLogout }) => {
    useEffect(() => {
        logger && logger.trackTrace("Unauthorized Access", {
            "ProductType": "1",
            "IsAllMenuAccessDenied": "True"
        });
    }
    , []);
    return (
    <div className="product-access-denied">
        <div>
            <ProductAccessDenied width={200} height={200} />
        </div>
        <br />
        <div className="product-access-denied-text">
            <h5>Access Denied</h5>
            <div>
                <div>
                    We are sorry, you do not have permission to access <span>Safesend Returns</span>.
                    <br /> Please contact your system administrator to update your user profile
                </div>
                <br />
                <Button onClick={onLogout} id="product-access-denied-btn" data-test-auto="8b5d9c1d-823e-42bd-9b20-443a9bd4dade">
                    Sign in as a Different User
                </Button>
            </div>
        </div>
    </div>
    );
};

export default WithLogout(FeatureDisabled);
