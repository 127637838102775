import * as React from 'react';
import { ToastContainer,toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

export class VenusNotifier extends React.Component<any, any> {

    static Error = (message: string, title: any) =>
        message && toast.error(message, { position: toast.POSITION.TOP_CENTER});


    static Warning = (message: string, title: any) =>
        message && toast.warn(message, { position: toast.POSITION.TOP_CENTER });
    

    static Success = (message: string, title: any) =>
        message && toast.success(message, { position: toast.POSITION.TOP_CENTER});
    

    static Info = (message: string, title: any) =>
        message && toast.info(message, { position: toast.POSITION.TOP_CENTER});
    
}

export class Toaster extends React.Component<any, any> {

    render() {
        return (
            <div>
                <ToastContainer
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick={true}
                    rtl={false}
                    pauseOnHover
                    style={{ fontSize: '14px' }}
                />
            </div>
        );
    }
}