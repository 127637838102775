import * as React from 'react';
import { Card, Row, Col, Button } from 'react-bootstrap';
import * as TaxDocument from '../../../components/common/TaxReturn';
import { ITaxpayer, initiaITaxpayerState} from '../../../components/common/TaxClient';
import { phoneNumberDisplay} from '../../../components/helper/HelperFunctions';

interface DeliverToClientProps {
    taxreturn: TaxDocument.ITaxReturn;
    cancelFinish: () => void;
    deliverToClient: () => void;
}

export const DeliverToClient: React.FunctionComponent<DeliverToClientProps> = ({ taxreturn, cancelFinish, deliverToClient }) => {
    const [taxpayer, setTaxpayer] = React.useState<ITaxpayer>(initiaITaxpayerState);

    React.useEffect(() => {
        if (TaxDocument.isMutual(taxreturn)) {
            if (taxreturn.documentSettings.deliverySettings.deliverTo === TaxDocument.ClientTypes.Spouse) {
                setTaxpayer(taxreturn.spouse);
            }
            else {
                setTaxpayer(taxreturn.taxpayer);
            }
        }
        else if (TaxDocument.isIndividual(taxreturn)) {
            setTaxpayer(taxreturn.taxpayer);
        }
    }, [taxpayer])

    
        return (<Card>
            <Card.Body>
                <div>
                    <Row className="marB15">
                        <Col className="text-right" sm={4} as={Col}>
                            Name
                            </Col>
                        <Col sm={8} as={Col}>
                            {taxpayer.name}
                        </Col>


                    </Row>
                    <Row className="marB15">
                        <Col className="text-right" sm={4} as={Col}>
                            SSN
                                </Col>
                        <Col sm={8} as={Col}>
                            {taxpayer.ssn}
                        </Col>

                    </Row>
                    <Row className="marB15">
                        <Col className="text-right" sm={4} as={Col}>
                            Address
                                </Col>
                        <Col sm={8} as={Col}>
                            {taxpayer.address}
                        </Col>

                    </Row>
                    <Row className="marB15">
                        <Col className="text-right" sm={4} as={Col}>
                            Email
                                </Col>
                        <Col sm={8} as={Col}>
                            {taxpayer.email}
                        </Col>


                    </Row>
                    {
                        taxpayer.mobileNumber &&
                        <Row className="marB15">
                            <Col className="text-right" sm={4} as={Col}>
                                Mobile Number
                                </Col>
                            <Col sm={8} as={Col}>
                                {taxpayer.countryCode} {phoneNumberDisplay(taxpayer.mobileNumber)}
                            </Col>


                        </Row>
                    }
                </div>

                <div>
                    <Col sm={12}>
                        <Button type="button" variant="info" className="float-right"
                            onClick={deliverToClient}
                        ><i className="fa fa-paper-plane"></i>Send</Button>
                        <Button type="button" variant="default" className="float-right marR05 btn-white btn-default"
                            onClick={cancelFinish}
                        ><i className="fas fa-times"></i>Cancel</Button>
                    </Col>
                </div>
            </Card.Body>
        </Card>
    )
}