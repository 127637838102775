import * as bootbox from "bootbox";
import * as React from "react";
import { Button, FormControl, Modal, ProgressBar } from "react-bootstrap";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import "react-bootstrap-table/css/react-bootstrap-table.css";
import { Nav } from "react-bootstrap";
import {
    SasContainer,
    TaxReturnUploadData_GR,
    UploadStatus
} from "../../../../../Core/ViewModels/Common/UploadDocumentViewModel";
import { IParterUserModel, ISSRUserModel, IUserModel } from "../../../../../Core/ViewModels/User/UserViewModel";
import * as UploadTaxReturnStore from "../../../../../store/common/uploadTaxReturn";
import { UploadtaxReturnConstants, ValidationContants } from "../../../../helper/Constants";
import { getFileSize, getPdfFilePageCount, extractClientId } from "../../../../helper/HelperFunctions";
import { validateFileType, NullandEmptyCheck } from "../../../../helper/Validations";
import {
    UploadFunctions,
    isFileExist,
    removeSpecialCharacters,
    getFileExtension,
    validatePdfFileContent,
    validateFileSize,
    GetFileMagicNumber
} from "@sssuite-js-packages/file-utility";

import { VenusNotifier } from "../../../../helper/VenusNotifier";
import SetAccessCommonModal from "src/components/common/SetAccessCommonModal";
import { ITaxReturn } from "../../../TaxReturn";
import { UploadedDocumentsTable } from "../../../UploadDocument/UploadedDocumentsTable";
import { DocumentUploadDropzoneComponent } from "../../DocumentUploadDropzoneComponent";
import { ButtonFormatter, DatalistFormatter } from "../UploadCommonFormatters";
import { IGroupAccess } from "../../../../GroupedReturns/GroupConfiguration";
import { IGroupInfo } from "../../../../../store/groupedreturns/GroupedReturnProcessState";
import {
    unloadedGroupDeliveryState,
    unloadedGroupK1Settings,
    unloadedGroupNotificationSettings,
    unloadedGroupRetentionSetting,
    unloadedGroupSignatureSetting
} from "../../../../../store/groupedreturns/GroupedReturnProcessStore";
import { Guid } from "../../../../../Core/Utilities/Guid";
import { logger } from "../../../../../routes/LoggedIn";
import UploadDocumentModalWarning from "../../UploadDocumentModalWarning";
import { CommonResources } from "../../../../helper/ResourceIdConstants";
import * as CRSHelper from "../../../../helper/CRSHelper";
import SuiteModal from "src/components/common/SuiteModal";
import {
    IAccessingUsersAndUserGroups,
    ISetAccessData,
    IUserDataFromDB,
    IUserGroupDataFromDB
} from "src/components/common/SetAccessCommonModal/SetAccessCommonModal.model";
import { IReturnAccessSettingsModel } from "src/Core/ViewModels/Company/CompanySettingsViewModel";
import { IUserProfile } from "../../../../navigation/profile/ProfileObjects";
import { getInitialListOfDataFromDB } from "src/components/common/SetAccessCommonModal/SetAccessCommonModal.api";
import { IClientManagementSetting } from "../../../../../store/company/SuiteClientManagementSettingStore";

export interface ProSystemUploadModalProps {
    onShow: boolean;
    onHide(): void;
    defaultERO: number;
    EROs?: IParterUserModel[];
    availableUsers?: IUserModel[];
    routing?: boolean;
    processTaxReturn: (url: string, callback: () => void) => void;
    getUploadLink: (
        url: string,
        callback?: (data?: UploadTaxReturnStore.IUploadTaxReturnState) => void,
        resourceId?: string
    ) => void;
    submitTaxReturn: (url: string, taxData: string, callback: () => void, resourceId?: string) => void;
    onPageReload: () => void;
    returnAccessSettings?: IReturnAccessSettingsModel;
    userProfile?: IUserProfile;
    ssrAvailableUsers: ISSRUserModel[];
    clientManagementSetting: IClientManagementSetting;
    requestClientManagementSetting: () => void;
}

export interface ProSystemUploadModalState {
    gridColumnDisable: boolean;
    showSetAccess: boolean;
    sasContainer: SasContainer[];
    config: {
        dropzoneSelector: string;
        iconFiletypes: [".pdf"];
        showFiletypeIcon: boolean;
        postUrl: string;
    };
    setAccessAccessingUsers: ISSRUserModel[];
    setAccessCurrentUsers: ISSRUserModel[];
    taxReturnIndex: number;
    taxReturns: ITaxReturn;
    inProgress: boolean;
    availableUsers: IUserDataFromDB[];
    selectedUsers: IUserDataFromDB[];
    availableUserGroups: IUserGroupDataFromDB[];
    selectedUserGroups: IUserGroupDataFromDB[];
}

interface GroupedProSystemUploadModalProps extends ProSystemUploadModalProps {
    Groups: IGroupInfo[];
    defaultGroup: string;
    GroupAccess: IGroupAccess[];
    updateGroupInfoState: (reload: boolean) => void;
    parentResourceIdentifier: string;
}

interface GroupedProSystemUploadModalState extends ProSystemUploadModalState {
    TaxReturnUploadData: TaxReturnUploadData_GR[];
}

const MaxFileLimitPerUpload: number = 30;

export class ProSystemGroupedDocumentModal extends React.Component<
    GroupedProSystemUploadModalProps,
    GroupedProSystemUploadModalState
> {
    constructor(props: GroupedProSystemUploadModalProps) {
        super(props);
        this.state = {
            TaxReturnUploadData: [],
            gridColumnDisable: true,
            showSetAccess: false,
            sasContainer: [],
            config: {
                dropzoneSelector: "div.filepicker",
                iconFiletypes: [".pdf"],
                showFiletypeIcon: true,
                postUrl: "/api/"
            },
            setAccessAccessingUsers: [],
            setAccessCurrentUsers: [],
            taxReturnIndex: 0,
            taxReturns: {} as ITaxReturn,
            inProgress: false,
            availableUsers: [],
            selectedUsers: [],
            availableUserGroups: [],
            selectedUserGroups: []
        };
        this.deleteReturn = this.deleteReturn.bind(this);
        this.onSetAccess = this.onSetAccess.bind(this);
        this.onSetAccessHide = this.onSetAccessHide.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleUploaderClose = this.handleUploaderClose.bind(this);
        this.validateTaxReturnUploadData = this.validateTaxReturnUploadData.bind(this);
        this.setAcessShow = this.setAcessShow.bind(this);
        this.onSetAccessSave = this.onSetAccessSave.bind(this);

        this.uploadProgressCallback = this.uploadProgressCallback.bind(this);
    }

    private onClientIdChange = (row: any, event: any) => {
        var _gridData = [...this.state.TaxReturnUploadData];
        _gridData.map((value, index) => {
            if (value.rowIndex == row.rowIndex) {
                value.clientId = event.target.value;
            }
        });

        this.setState({ TaxReturnUploadData: _gridData });
    };

    private onGroupChange = (row: any, value: any) => {
        var _gridData = [...this.state.TaxReturnUploadData];
        _gridData.map((gridData, index) => {
            if (gridData.rowIndex == row.rowIndex) {
                gridData.groupInfo.name = value;
                var group = this.props.Groups && this.props.Groups.length > 0 && this.props.Groups.find((e) => e.name === value);
                if (group) gridData.groupInfo.id = group.id;
                else gridData.groupInfo.id = 0;

                var access =
                    this.props.GroupAccess &&
                    this.props.GroupAccess.length > 0 &&
                    this.props.GroupAccess.find((e) => e.groupId === gridData.groupInfo.id);
                if (access) gridData.setAccess = "Restricted";
                else gridData.setAccess = "Everyone";

                if (gridData.documentAccess) {
                    gridData.documentAccess.documents = [];
                    gridData.documentAccess.users = [];
                    gridData.documentAccess.userGroups = [];
                }
            }
        });
        this.setState({ TaxReturnUploadData: _gridData });
        logger.trackTrace(`Group Name changed`);
    };

    //===========================================================================
    // Bootstrap Table Formatters start
    //===========================================================================

    defaultType(cell: any, row: any) {
        return cell;
    }

    statusFormatter(cell: any, row: any) {
        return <span>{UploadStatus[cell].toString()}</span>;
    }

    buttonFunction(cell: any, row: any) {
        return (
            <ButtonFormatter
                deleteReturn={() => this.deleteReturn(row)}
                disabled={row.progressBar != undefined && row.progressBar != 100 ? true : false}
                data-test-auto="B07622E7-FEB7-4759-BA19-4DF7D79BE7E9"
            />
        );
    }

    progressbar(cell: any, row: any) {
        return <ProgressBar striped variant={cell != undefined && cell != 100 ? "warning" : "success"} now={cell} />;
    }

    textBox(cell: any, row: any) {
        return (
            <FormControl
                type="text"
                disabled={row.gridRowDisable}
                value={cell}
                onChange={this.onClientIdChange.bind(this, row)}
                data-test-auto="8E584D37-7EF3-4D2A-8CC8-E21FA295F6EB"
            />
        );
    }

    DatalistFormat(cell: any, row: any) {
        return (
            <DatalistFormatter
                id={"ddlPSUploadGroups" + row.rowIndex}
                disableDropdown={row.gridRowDisable}
                Groups={this.props.Groups}
                defaultGroup={row.groupInfo.name}
                onGroupChange={this.onGroupChange.bind(this, row)}
                data-test-auto="EE1C1137-1326-4C31-B850-D3086EA21C9F"
            />
        );
    }

    linkFormat(cell: any, row: any) {
        const disableLink = NullandEmptyCheck(row.groupInfo.name) ? "disable-link" : "";
        return (
            <Nav.Link
                href="javascript:"
                className={disableLink}
                style={{ textDecoration: "underline", paddingLeft: "0px", display: "contents" }}
                onClick={this.onSetAccess.bind(this, row)}
                data-test-auto="7FDF51B6-41F6-4E58-9A03-C52D36983AF0"
            >
                {cell}
            </Nav.Link>
        );
    }

    //===========================================================================
    // Bootstrap Table Formatters End
    //===========================================================================

    uploadCommittCallBack = (fileToUpload: any) => {
        if (this.state.TaxReturnUploadData.length > 0) {
            let tempGridData = this.state.TaxReturnUploadData;
            tempGridData.map((tempGridDataValue, index) => {
                if (tempGridDataValue.name == fileToUpload.fileName) {
                    tempGridDataValue.progressBar = 100;
                    tempGridDataValue.status = UploadStatus.Uploaded;
                    tempGridDataValue.gridRowDisable = false;
                    logger.trackTrace(
                        `ProSystemGroupedDocumentModal --> getUploadLink executes with status: ${
                            UploadStatus[tempGridDataValue.status]
                        }`,
                        { Filename: tempGridDataValue.name }
                    );
                }
            });
            this.setState(
                {
                    TaxReturnUploadData: tempGridData
                },
                () => {
                    if (!this.isUploadOnGoing()) {
                        this.setState({ gridColumnDisable: false });
                    }
                }
            );
        }
    };

    //Implementation of this callback will be done later
    uploadFailureCallback = (fileToUpload: any) => {
        console.log("upload failed for file:", fileToUpload.fileName);
    };

    onSubmitTaxreturns = () => {
        var tempUploadObj = this.state.TaxReturnUploadData;
        var newGroup = tempUploadObj.find((a) => a.groupInfo.id === 0);
        if (this.validateTaxReturnUploadData(this.state.TaxReturnUploadData)) {
            this.setState(
                {
                    inProgress: true,
                    gridColumnDisable: true
                },
                () => {
                    this.props.submitTaxReturn(
                        "/api/Upload/SubmitGroupedTaxReturnsAync",
                        CRSHelper.encodeSpecialCharactersOnly(JSON.stringify(this.state.TaxReturnUploadData)),
                        () => {
                            this.UploadConfirmation();
                            this.props.onPageReload();
                            this.handleClose();
                            this.setState({
                                inProgress: false
                            });
                            if (newGroup) this.props.updateGroupInfoState(true);
                        },
                        this.props.parentResourceIdentifier + CommonResources.CCHSubmitButton
                    );
                }
            );
        }
        logger.trackTrace(`ProSystemGroupedDocumentModal --> onSubmitTaxReturns executes`);
    };

    private UploadConfirmation() {
        let message: string =
            "<h5 class='marT0 font13'>" + UploadtaxReturnConstants.ReturnSubmitSuccess + ":</h5><ul class='marL30'>";
        this.state.TaxReturnUploadData.map((value, index) => {
            if (value.groupInfo.name != "")
                message = message + "<li>" + value.clientId + " (Return added to group " + value.groupInfo.name + ")</li>";
            else message = message + "<li>" + value.clientId + "</li>";
        });
        message = message + "</ul>";
        bootbox.alert({
            message: message,
            buttons: {
                ok: {
                    label: '<i class="fas fa-check"></i> OK',
                    className: "btn-info"
                }
            },
            callback() {}
        });
    }

    deleteReturn(row: any) {
        let _self = this;
        bootbox.confirm({
            message: UploadtaxReturnConstants.DeleteFileMessage,
            buttons: {
                cancel: {
                    label: '<i class="fas fa-times"></i> Cancel',
                    className: "btn-white btn-default"
                },
                confirm: {
                    label: '<i class="fas fa-check"></i> OK',
                    className: "btn-info"
                }
            },
            callback: (result: boolean) => {
                if (result) {
                    var _gridData = [..._self.state.TaxReturnUploadData];
                    _gridData = _gridData.filter((i) => i.rowIndex != row.rowIndex);
                    //Update row index
                    for (let i = 0; i < _gridData.length; i++) {
                        _gridData[i].rowIndex = i + 1;
                        _gridData[i].number = i + 1;
                        this.DatalistFormat(null, _gridData[i]);
                    }
                    let _tempgridData = _gridData;

                    let _uploadData = [..._self.state.sasContainer];
                    _uploadData = _uploadData.filter((i) => i.sasGuid != row.sasGuid);

                    let _tempUploadData = _uploadData;
                    if (_gridData.length == 0) {
                        _self.setState({ gridColumnDisable: true });
                    }
                    _self.setState({ TaxReturnUploadData: _tempgridData, sasContainer: _tempUploadData }, () =>
                        _self.forceUpdate()
                    );
                    logger.trackTrace(`ProSystemGroupedDocumentModal --> deleteReturn executes`);
                }
            }
        });
    }

    private reset = () => {
        let tempUploadData: SasContainer[] = [];
        let tempGridData: TaxReturnUploadData_GR[] = [];
        this.state.sasContainer.slice(0, this.state.sasContainer.length);
        this.setState({ sasContainer: tempUploadData, TaxReturnUploadData: tempGridData, gridColumnDisable: true }, () => {
            this.loadSetAccessData();
        });
    };

    private handleClose() {
        this.reset();
        this.props.onHide();
    }

    private isUploadOnGoing = (): boolean => {
        let isuploading: boolean = false;
        this.state.TaxReturnUploadData.map((value) => {
            if (value.progressBar == undefined ? 0 : value.progressBar < 100) {
                isuploading = true;
                return;
            }
        });
        return isuploading;
    };

    private handleUploaderClose() {
        let _self = this;
        if (this.state.TaxReturnUploadData.length == 0) {
            _self.handleClose();
            return;
        }
        if (this.isUploadOnGoing()) {
            bootbox.alert(UploadtaxReturnConstants.AbortingUploadWarning);
        } else {
            bootbox.confirm({
                message: UploadtaxReturnConstants.CloseConfirmationMessage,
                buttons: {
                    cancel: {
                        label: '<i class="fas fa-times"></i> Cancel',
                        className: "btn-white btn-default"
                    },
                    confirm: {
                        label: '<i class="fas fa-check"></i> OK',
                        className: "btn-info"
                    }
                },
                callback: (result: boolean) => {
                    if (result) {
                        _self.handleClose();
                        _self.forceUpdate();
                        logger.trackTrace(`ProSystemGroupedDocumentModal --> handleUploaderClose executes`);
                    }
                }
            });
        }
    }

    groupAlreadyExistsValidation(groupName: string) {
        let isValid: boolean = true;
        this.props.Groups.map((list, index) => {
            if (list.name == groupName.trim()) {
                isValid = false;
                return isValid;
            }
        });
        return isValid;
    }

    private validateTaxReturnUploadData = (uploadData: TaxReturnUploadData_GR[]): boolean => {
        let valid: boolean = true;
        for (var i = 0; i < uploadData.length; i++) {
            if (!uploadData[i].clientId || uploadData[i].clientId!.trim() === "") {              
                VenusNotifier.Warning("Please enter client Id to submit", "");
                valid = false;
                break;
            }
            uploadData[i].groupInfo.name = uploadData[i].groupInfo.name.trim();
        }
        return valid;
    };

    //===========================================================================
    // Set Access End
    //===========================================================================

    setAcessShow() {
        this.setState({ showSetAccess: true });
    }

    onSetAccessHide() {
        this.setState({
            showSetAccess: false
        });
    }

    onSetAccess(taxReturn: any, event: any) {
        this.setState({
            taxReturnIndex: taxReturn.rowIndex,
            showSetAccess: true
        });
    }

    private onSetAccessSave(accessingUsersAndUserGroups: IAccessingUsersAndUserGroups) {
        let index = this.state.taxReturnIndex;
        const { selectedUsers, selectedUserGroups, availableUsers, availableUserGroups } = accessingUsersAndUserGroups;
        this.state.TaxReturnUploadData.map((value) => {
            if (value.rowIndex == index) {
                let isAllUsers = false;
                let isAllUserGroups = false;
                if (
                    (selectedUsers.length > 0 && selectedUsers.indexOf(0) !== -1) ||
                    availableUsers.length === 0 ||
                    (availableUsers.length === 1 && availableUsers[0] === 0)
                ) {
                    isAllUsers = true;
                }

                if (
                    (selectedUserGroups.length > 0 && selectedUserGroups.indexOf(0) !== -1) ||
                    availableUserGroups.length === 0 ||
                    (availableUserGroups.length === 1 && availableUserGroups[0] === 0)
                ) {
                    isAllUserGroups = true;
                }

                if (isAllUsers && isAllUserGroups) {
                    // value.documentAccess.userId = userIds;
                    value.setAccess = "Everyone";
                } else {
                    // value.documentAccess.userId = [];
                    value.setAccess = "Restricted";
                }

                value.documentAccess = {
                    documents: [],
                    userGroups: accessingUsersAndUserGroups.selectedUserGroups,
                    users: accessingUsersAndUserGroups.selectedUsers
                };
            }
        });
        this.setState({
            showSetAccess: false,
            selectedUsers: this.getSelectedUser(accessingUsersAndUserGroups),
            availableUsers: this.getAvailableUser(accessingUsersAndUserGroups),
            selectedUserGroups: this.getSelectedUserGroups(accessingUsersAndUserGroups),
            availableUserGroups: this.getAvailableUserGroups(accessingUsersAndUserGroups)
        });
    }

    getSelectedUser = (accessingUsersAndUserGroups: IAccessingUsersAndUserGroups) => {
        const allUsers = [...this.state.selectedUsers, ...this.state.availableUsers];
        let selectedUser: IUserDataFromDB[] = [];
        accessingUsersAndUserGroups.selectedUsers.map((userId) => {
            let index = allUsers.findIndex((x) => x.id == userId);
            if (index != -1) {
                selectedUser.push(allUsers[index]);
            }
        });
        return selectedUser;
    };

    getAvailableUser = (accessingUsersAndUserGroups: IAccessingUsersAndUserGroups) => {
        const allUsers = [...this.state.selectedUsers, ...this.state.availableUsers];
        let availableUser: IUserDataFromDB[] = [];
        accessingUsersAndUserGroups.availableUsers.map((userId) => {
            let index = allUsers.findIndex((x) => x.id == userId);
            if (index != -1) {
                availableUser.push(allUsers[index]);
            }
        });
        return availableUser;
    };

    getSelectedUserGroups = (accessingUsersAndUserGroups: IAccessingUsersAndUserGroups) => {
        const allUserGroups = [...this.state.selectedUserGroups, ...this.state.availableUserGroups];
        let selectedUserGroups: IUserGroupDataFromDB[] = [];
        accessingUsersAndUserGroups.selectedUserGroups.map((userGroupId) => {
            let index = allUserGroups.findIndex((x) => x.id == userGroupId);
            if (index != -1) {
                selectedUserGroups.push(allUserGroups[index]);
            }
        });
        return selectedUserGroups;
    };

    getAvailableUserGroups = (accessingUsersAndUserGroups: IAccessingUsersAndUserGroups) => {
        const allUserGroups = [...this.state.selectedUserGroups, ...this.state.availableUserGroups];
        let availableUserGroups: IUserGroupDataFromDB[] = [];
        accessingUsersAndUserGroups.availableUserGroups.map((userGroupId) => {
            let index = allUserGroups.findIndex((x) => x.id == userGroupId);
            if (index != -1) {
                availableUserGroups.push(allUserGroups[index]);
            }
        });
        return availableUserGroups;
    };

    //===========================================================================
    // Set Access End
    //===========================================================================

    djsConfig = {
        uploadMultiple: true,
        addRemoveLinks: true,
        acceptedFiles: "application/pdf",
        headers: { "Access-Control-Allow-Origin": "*", "x-ms-blob-type": "BlockBlob" },
        previewsContainer: false,
        autoProcessQueue: false,
        autoDiscover: false
    };

    eventHandlers = {
        init: () => {
            this.reset();
        },
        addedfiles: (files: any) => {
            try {
                let _self = this;

                _self.validateFileCount(files);

                _self.convertToModel(files).then((tmpTaxReturnUploadData: TaxReturnUploadData_GR[]) => {
                    tmpTaxReturnUploadData = _self.handleDuplicates(tmpTaxReturnUploadData);
                    if (tmpTaxReturnUploadData.length > 0) {
                        _self.validateFileContent(tmpTaxReturnUploadData).then((result) => {
                            _self.setState(
                                {
                                    TaxReturnUploadData: _self.state.TaxReturnUploadData.concat(result),
                                    gridColumnDisable: true
                                },
                                () => {
                                    _self.getUploadLink();
                                }
                            );
                        });
                    }
                });
            } catch (error) {
                VenusNotifier.Warning(error.message, null);
            }
        }
    };

    private validateFileCount = (uploadedFiles: any) => {
        if (uploadedFiles.length + this.state.TaxReturnUploadData.length > MaxFileLimitPerUpload) {
            throw new Error("You cannot upload more than " + MaxFileLimitPerUpload + " files at a time. Please choose less.");
        }
    };

    private convertToModel = (uploadedFiles: any): Promise<TaxReturnUploadData_GR[]> => {
        let tmpTaxReturnUploadData: TaxReturnUploadData_GR[] = [];
        let filesFailedValidationCount = 0;
       
        return new Promise((resolve) => {
            for (let i = 0; i < uploadedFiles.length; i++) {
                let uploadedFile = uploadedFiles[i];
               
                if (validateFileSize(uploadedFile)) {
                    let tmpFileName = removeSpecialCharacters(uploadedFiles[i].name);
                    let fileExtension = getFileExtension(tmpFileName);
                    const loggedInUserId = this.props.userProfile?.userId as number;
                    // Validate file extension

                    if (validateFileType(fileExtension)) {
                        getPdfFilePageCount(uploadedFile as File).then((pageCount: number) => {
                            tmpTaxReturnUploadData.push({
                                name: tmpFileName,
                                clientName: "clname",
                                clientId: this.props.clientManagementSetting.extractClientIdEnabled ?
                                    extractClientId(tmpFileName, fileExtension) : tmpFileName.replace("." + fileExtension, ""),
                                number: tmpTaxReturnUploadData.length + this.state.TaxReturnUploadData.length + 1,
                                progressBar: 0,
                                setAccess: !(
                                    this.props.returnAccessSettings != undefined &&
                                    this.props.returnAccessSettings!.isSetAccessToEveryone
                                )
                                    ? "Restricted"
                                    : "Everyone",
                                size: getFileSize(uploadedFile?.size),
                                status: UploadStatus.Wait,
                                sasGuid: "",
                                gridRowDisable: true,
                                documentAccess: {
                                    documents: [],
                                    userGroups: this.state.selectedUserGroups.map((group) => {
                                        return group.id;
                                    }),
                                    users: this.state.selectedUsers.map((user) => {
                                        return user.id;
                                    })
                                },
                                rowIndex: tmpTaxReturnUploadData.length + this.state.TaxReturnUploadData.length + 1,
                                file: uploadedFile,
                                groupInfo: {
                                    id: 0,
                                    name: "",
                                    createdBy: 0,
                                    createdOn: new Date(),
                                    isDeleted: false,
                                    controllerInfo: {
                                        clientGuid: Guid.newGuid(),
                                        countryCode: "",
                                        emailAddress: "",
                                        groupId: 0,
                                        mobileNumber: "",
                                        name: "",
                                        ssn: ""
                                    },
                                    groupAccess: {},
                                    groupSettings: {
                                        groupDeliverySettings: unloadedGroupDeliveryState,
                                        groupId: 0,
                                        groupNotificationSettings: unloadedGroupNotificationSettings,
                                        groupRetentionSettings: unloadedGroupRetentionSetting,
                                        groupSignatureSettings: unloadedGroupSignatureSetting,
                                        groupK1Settings: unloadedGroupK1Settings
                                    },
                                    isModified: false
                                },
                                numberOfPages: pageCount
                            });
                            if (tmpTaxReturnUploadData.length + filesFailedValidationCount == uploadedFiles.length) {
                                resolve(tmpTaxReturnUploadData);
                            }
                        });
                    } else {
                        filesFailedValidationCount++;
                        if (tmpTaxReturnUploadData.length + filesFailedValidationCount == uploadedFiles.length) {
                            resolve(tmpTaxReturnUploadData);
                        }
                    }
                } else {
                    filesFailedValidationCount++;
                    if (tmpTaxReturnUploadData.length + filesFailedValidationCount == uploadedFiles.length) {
                        resolve(tmpTaxReturnUploadData);
                    }
                }
            }
        });
    };

    private validateFileContent = (tmpTaxReturnUploadData: TaxReturnUploadData_GR[]): Promise<any> => {
        var promise: any = null;
        let _tmpTaxReturnUploadData: TaxReturnUploadData_GR[] = Object.assign({}, tmpTaxReturnUploadData);
        for (let i = 0; i < tmpTaxReturnUploadData.length; i++) {
            let uploadedFile = tmpTaxReturnUploadData[i].file;
            promise = new Promise((resolve) => {
                GetFileMagicNumber(uploadedFile).then((result) => {
                    // Validate file content
                    if (!validatePdfFileContent(result)) {
                        let index = tmpTaxReturnUploadData.findIndex((x) => x.name == _tmpTaxReturnUploadData[i].name);
                        tmpTaxReturnUploadData.splice(index, 1);
                        VenusNotifier.Warning(ValidationContants.PdfFileValidation, null);
                    }
                    resolve(result);
                });
            });
        }
        return promise.then(() => {
            return tmpTaxReturnUploadData;
        });
    };

    private handleDuplicates = (taxReturnUploadData: TaxReturnUploadData_GR[]): TaxReturnUploadData_GR[] => {
        for (let i = 0; i < taxReturnUploadData.length; i++) {
            let file = taxReturnUploadData[i];
            let fileName: string = file.name || "";
            let fileExtension = getFileExtension(fileName);

            let filecount = 1;
            while (isFileExist(fileName, this.state.TaxReturnUploadData)) {
                fileName = file.name || "";
                fileName = fileName.replace("." + fileExtension, "");
                fileName = fileName + " (" + filecount + ")." + fileExtension;
                filecount++;
            }
            taxReturnUploadData[i].name = fileName;
        }

        return taxReturnUploadData;
    };

    private getUploadLink = () => {
        let _self = this;

        let uploadHelperFunctions = new UploadFunctions();

        this.state.TaxReturnUploadData.filter((x) => x.status == UploadStatus.Wait).forEach(
            (file: TaxReturnUploadData_GR, index: number) => {
                file.status = UploadStatus.Initiating;
                logger.trackTrace(
                    `ProSystemGroupedDocumentModal --> getUploadLink executes with status: ${UploadStatus[file.status]}`,
                    { Filename: file.name }
                );

                this.props.getUploadLink(
                    "/api/Upload/GetTaxReturnUploadLinkAsync",
                    (data?: UploadTaxReturnStore.IUploadTaxReturnState) => {
                        try {
                            if (data) {
                                file.sasGuid = data ? data.guid : "";
                                file.status = UploadStatus.Uploading;

                                _self.setState({ TaxReturnUploadData: _self.state.TaxReturnUploadData }, () => {
                                    uploadHelperFunctions.uploadFile(
                                        file.file,
                                        data,
                                        file.name ? file.name : "",
                                        _self.uploadProgressCallback,
                                        _self.uploadCommittCallBack,
                                        _self.uploadFailureCallback
                                    );
                                    logger.trackTrace(
                                        `ProSystemGroupedDocumentModal --> getUploadLink executes with status: ${
                                            UploadStatus[file.status]
                                        }`,
                                        { Filename: file.name }
                                    );
                                });
                            } else {
                                throw new Error("Upload link not found !!");
                            }
                        } catch (error) {
                            _self.state.TaxReturnUploadData.splice(index, 1);
                            _self.setState({ TaxReturnUploadData: _self.state.TaxReturnUploadData });
                        }
                    },
                    this.props.parentResourceIdentifier + CommonResources.HeaderPrefix + CommonResources.CCHUploadButton
                );
            }
        );
    };

    public uploadProgressCallback(percent: number, fileToUpload: any) {
        let tempGridData = this.state.TaxReturnUploadData;
        tempGridData.map((tempGridDataValue, index) => {
            if (tempGridDataValue.sasGuid == fileToUpload.fileGUID) {
                tempGridDataValue.progressBar = percent - 10;
                if (percent === 100) {
                    logger.trackTrace(`Uploading process completed`);
                }
            }
        });
        this.setState({
            TaxReturnUploadData: tempGridData
        });
    }

    componentDidMount(): void {
        this.loadSetAccessData();
        this.props.requestClientManagementSetting();
    }

    loadSetAccessData = () => {
        getInitialListOfDataFromDB((setAccessData?: ISetAccessData) => {
            if (!setAccessData) {
                VenusNotifier.Error("Error fetching list of users and user groups", "");
            } else {
                const { availableUsers, selectedUsers, availableUserGroups, selectedUserGroups } = setAccessData;
                this.setState({
                    availableUsers,
                    selectedUsers,
                    availableUserGroups,
                    selectedUserGroups
                });
            }
        });
    };

    public render() {
        const columns = [
            {
                header: "#",
                key: "number",
                isKey: true,
                dataFormat: this.defaultType,
                width: "30px",
                columnClassName: ""
            },
            {
                header: "Name",
                key: "name",
                isKey: false,
                dataFormat: this.defaultType,
                width: "auto",
                columnClassName: "word-Visible"
            },
            {
                header: "Progress",
                key: "progressBar",
                isKey: false,
                dataFormat: this.progressbar,
                width: "auto",
                columnClassName: ""
            },
            {
                header: "Size",
                key: "size",
                isKey: false,
                dataFormat: this.defaultType,
                width: "auto",
                columnClassName: ""
            },
            {
                header: "Status",
                key: "status",
                isKey: false,
                dataFormat: this.statusFormatter,
                width: "auto",
                columnClassName: ""
            },
            {
                header: "Client ID *",
                key: "clientId",
                isKey: false,
                dataFormat: this.textBox.bind(this),
                width: "240px",
                columnClassName: ""
            },
            {
                header: "Group Name",
                key: "groupName",
                isKey: false,
                dataFormat: this.DatalistFormat.bind(this),
                width: "240px",
                columnClassName: "overflowVisible"
            },
            {
                header: "Set Access",
                key: "setAccess",
                isKey: false,
                dataFormat: this.linkFormat.bind(this),
                width: "72px",
                columnClassName: ""
            },
            {
                header: "Remove",
                key: "button",
                isKey: false,
                dataFormat: this.buttonFunction.bind(this),
                width: "60px",
                columnClassName: ""
            }
        ];

        return (
            <div>
                <Modal
                    className="upload-doc-modal"
                    show={this.props.onShow}
                    onHide={this.handleUploaderClose}
                    enforceFocus={false}
                >
                    <Modal.Header closeButton data-test-auto="9DFD99E6-5A5C-4A9A-B40F-975AB2D109B5">
                        <Modal.Title style={{ display: "block" }} className="upload-modal-title">
                            {" "}
                            Upload Documents
                            <UploadDocumentModalWarning
                                warningMessages={[
                                    UploadtaxReturnConstants.PdfDocumentGroupedReturnWarning,
                                    UploadtaxReturnConstants.PdfDocumentUploadWarning
                                ]}
                            />
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="prosystem-upload-doc-body">
                        <LoadingOverlay>
                            <div className="row">
                                <div className="col-sm-2 text-center">
                                    <DocumentUploadDropzoneComponent
                                        componentConfig={this.state.config}
                                        djsConfig={this.djsConfig}
                                        eventHandlers={this.eventHandlers}
                                        autoTestId={"66CC0BE6-4C69-4D54-8179-0B736D384559"}
                                    />
                                </div>
                                <div className="col-sm-10 uploaded-documents-table-container overflowVisible" id="uploadDocument">
                                    <UploadedDocumentsTable
                                        column={columns}
                                        data={this.state.TaxReturnUploadData}
                                        bodyContainerClass={"overflowVisible"}
                                    />
                                </div>
                            </div>
                            <Loader loading={this.state.inProgress} text="File upload in progress..." />
                        </LoadingOverlay>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="default"
                            onClick={this.handleUploaderClose}
                            data-test-auto="B1C823D3-8D5E-4B9B-9D59-11F3A0D9BEED"
                        >
                            <i className="fas fa-times"></i>Close
                        </Button>
                        <Button
                            variant="info"
                            data-resource-id={this.props.parentResourceIdentifier + CommonResources.CCHSubmitButton}
                            onClick={this.onSubmitTaxreturns}
                            disabled={this.state.gridColumnDisable}
                            data-test-auto="5D0B9B03-68DE-47E7-8681-6EDBD54E005B"
                        >
                            <i className="fas fa-save"></i>Submit
                        </Button>
                    </Modal.Footer>
                </Modal>

                {this.state.showSetAccess && (
                    <SuiteModal width="787" theme="light" title="Set Access" hide={this.onSetAccessHide}>
                        <SetAccessCommonModal
                            onCancel={this.onSetAccessHide}
                            onApplyAccess={this.onSetAccessSave}
                            returnAccessSettings={this.props.returnAccessSettings}
                            availableUsers={this.state.availableUsers}
                            selectedUsers={this.state.selectedUsers}
                            availableUserGroups={this.state.availableUserGroups}
                            selectedUserGroups={this.state.selectedUserGroups}
                        />
                    </SuiteModal>
                )}
            </div>
        );
    }
}
