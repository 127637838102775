import * as React from 'react';
import { ICompanySettings } from '../../../Core/ViewModels/Company/CompanySettingsViewModel';
import { Link } from 'react-router-dom';
import { RadioButtonComponent } from '../../common/RadioButtonComponent';
import { IHelptextModel } from '../../../Core/ViewModels/Common/IHelptextModel';
import { fetchHelpText } from '../../helper/HelperFunctions';

interface ISignatureFormSelections {
    companySettings: ICompanySettings;
    handleEsignAllReturns(event: any): void;
    handleEsignWhenAllAllowed(event: any): void;
    handleEnableManualEsignAllReturns(event: any): void;
    helptexts?: IHelptextModel[];
}
export class SignatureFormSelection extends React.Component<ISignatureFormSelections, {}>{
    state = {
        ESign: "ESign",
        ESignWhenAllIncludedReturnsAllowed: "ESignWhenAllIncludedReturnsAllowed",
        ManualSign: "ManualSign"
    }

    public render() {    
        return <div id="divSignatureFormSelection" className="new-main-content">
            <h3>1040 Forms</h3>
            <div className="marL20">
                <div className="div-height">
                    <RadioButtonComponent
                        id={"ESign"}
                        text={"E-Sign all returns."}
                         checked={this.props.companySettings.signatureSettingsModel ? this.state.ESign ==
                            this.props.companySettings.signatureSettingsModel.signatureFormSelectionType : false}
                        onChange={this.props.handleEsignAllReturns}
                        dataTestAuto="FED57B60-F343-4660-98A0-601C2BBEBB82"
                        value={this.state.ESign}
                    />
                    
                </div>               
                <div>
                    <RadioButtonComponent
                        id={"ManualSign"}
                        text={"Manually sign all returns"}
                        onChange={this.props.handleEnableManualEsignAllReturns}
                        checked={this.props.companySettings.signatureSettingsModel ? this.state.ManualSign ===
                            this.props.companySettings.signatureSettingsModel.signatureFormSelectionType : false}
                        dataTestAuto="665E7942-7DC9-4144-BE03-F6831A8CCE06"
                        value={this.state.ManualSign}
                    />
                    
                </div>
            </div>
        </div>;
    }
}

export default SignatureFormSelection;
