import { Action, Reducer } from 'redux';
import { IDeliveredTaxDocument, IDeliveredTaxReturns } from '../../../../components/common/DeliveredTaxReturns';
import { actionTypes } from '../../../../types/ActionTypes';
import { SendReminderTaxDocumentAction, UpdateTaxDocumentCustomColumnValueAction } from '../../../common/TaxDocumentStore';
import { ReceiveGroupedTaxDocumentAction } from '../../../groupedreturns/GroupedTaxDocumentStore';
import { IDeliveredReturnsDictionary } from '../../DeliveredReturnsState';
import {
	DeliveredGroupedReturnsState,
	DeliveredGroupedReturnsDictionary,
	DeliveredGroupedReturnsModel,
	DeliveredGroupedReturnsTableModel
} from './DeliveredGroupedReturnsState'
import { ResetDeliveredGroupedReturnsAction } from './DeliveredGroupedReturnsStore';

export interface RequestDeliveredGroupedReturnsPagesAction {
	type: actionTypes.REQUEST_DELIVERED_GROUPED_RETURNS_PAGES;
	query: string;
}

export interface ReceiveDeliveredGroupedReturnsPagesAction {
	type: actionTypes.RECEIVE_DELIVERED_GROUPED_RETURNS_PAGES;
	query: string;
	table: DeliveredGroupedReturnsTableModel;
	totalRowCount: number;
}

type DispatchAction = RequestDeliveredGroupedReturnsPagesAction
    | ReceiveDeliveredGroupedReturnsPagesAction
    | ReceiveGroupedTaxDocumentAction
    | ResetDeliveredGroupedReturnsAction
    | UpdateTaxDocumentCustomColumnValueAction
    | SendReminderTaxDocumentAction;

const unloadedState: DeliveredGroupedReturnsState = {
	deliveredGroupedReturnsTableModel: {},
	query: '?',
	loading: false,
	totalRowCount: 0
} as DeliveredGroupedReturnsState;

export const unloadedDeliveredGroupedReturnsState: DeliveredGroupedReturnsState = {
	deliveredGroupedReturnsTableModel: {
		deliveredGroupedReturns: [],
		count: 0
	} as DeliveredGroupedReturnsTableModel,
	loading: true,
	query: '',
	totalRowCount: 0,
	error: false
} as DeliveredGroupedReturnsState;

export const unloadedDeliveredTaxDocument: IDeliveredTaxDocument = {
	customColumn: '',
	document: {},
	downloadsCount: 0,
	lastReminderOn: undefined,
	retentionPeriod: undefined,
	signedCount: 0,
	bulkDownloadCount: 0
} as IDeliveredTaxDocument;


export const actionCreators = {};

export const reducer: Reducer<DeliveredGroupedReturnsDictionary> = (state: DeliveredGroupedReturnsDictionary = { "?": unloadedState }, incomingAction: Action) => {
	const action = incomingAction as DispatchAction;
	switch (action.type) {
		case actionTypes.REQUEST_DELIVERED_GROUPED_RETURNS_PAGES:
			if (!state[action.query]) {
				let added = { ...unloadedState } as DeliveredGroupedReturnsState;
				added.loading = true;
				added.query = action.query;
				let item = {} as DeliveredGroupedReturnsDictionary;
				item[action.query] = added;

				return {
					...state,
					...item
				};
			}
			break;
		case actionTypes.RECEIVE_DELIVERED_GROUPED_RETURNS_PAGES:
			if (state[action.query]) {
				let changed = state[action.query] as DeliveredGroupedReturnsState;
				changed.loading = false;
				changed.deliveredGroupedReturnsTableModel = action.table;
				changed.totalRowCount = action.totalRowCount;
				let item = {} as DeliveredGroupedReturnsDictionary;
				item[action.query] = changed;
				return {
					...state,
					...item
				};
			}
			break;
		case actionTypes.RECEIVE_GROUPED_TAX_DOCUMENT:
		case actionTypes.UPDATE_DOCUMENT_CUSTOM_COLUMN_VALUE:
		case actionTypes.SEND_REMINDER_TAX_DOCUMENT:
			return updateState(action.type, state, action);
		case actionTypes.RESET_DELIVERED_GROUPED_RETURNS:
			return clearGroupedReturnsPages();
		default:
			// The following line guarantees that every action in the KnownAction union has been covered by a case above
			const exhaustiveCheck: never = action;
	}
	return state || { "?": unloadedState };
};

function updateState(type: actionTypes, state: DeliveredGroupedReturnsDictionary, action: DispatchAction): DeliveredGroupedReturnsDictionary {
	let i: number = -1;
	let newState = {} as DeliveredGroupedReturnsDictionary;
	let oldDocument: IDeliveredTaxDocument = unloadedDeliveredTaxDocument;
	let document: IDeliveredTaxReturns = {} as IDeliveredTaxReturns;
	let customColumn: string = "";
	let id: number = 0;
	switch (action.type) {
		case actionTypes.RECEIVE_GROUPED_TAX_DOCUMENT:
			document = action.taxDocument as IDeliveredTaxReturns;
			id = action.id;
			break;
		case actionTypes.UPDATE_DOCUMENT_CUSTOM_COLUMN_VALUE:
			customColumn = action.customColumn;
			id = action.id;
			break;
		case actionTypes.SEND_REMINDER_TAX_DOCUMENT:
			id = action.id;
			break;
	}
	for (var query in state) {
		i = -1;

		if (state[query].deliveredGroupedReturnsTableModel.deliveredGroupedReturns) {
			state[query].deliveredGroupedReturnsTableModel.deliveredGroupedReturns.forEach((groupedReturn, index) => {

				if (groupedReturn && groupedReturn.taxReturns) {
					groupedReturn.taxReturns.forEach((taxReturn, taxReturnIndex) => {
						if (taxReturn.document.id === id) {
							i = taxReturnIndex;
							oldDocument = taxReturn;
							return;
						}
					});
				}
			});
		}
		if (i !== -1) {
			let deliveredTaxDocument: IDeliveredTaxDocument = {
				document: action.type == actionTypes.RECEIVE_GROUPED_TAX_DOCUMENT ? document : oldDocument.document,
				customColumn: action.type == actionTypes.UPDATE_DOCUMENT_CUSTOM_COLUMN_VALUE ? customColumn : oldDocument.customColumn,
				downloadsCount: oldDocument.downloadsCount,
				signedCount: oldDocument.signedCount,
				lastReminderOn: action.type == actionTypes.SEND_REMINDER_TAX_DOCUMENT ? new Date() : oldDocument.lastReminderOn,
				retentionPeriod: oldDocument.retentionPeriod,
				lockType: oldDocument.lockType,
				clientGuid: oldDocument.clientGuid,
				isDocumentLocked: oldDocument.isDocumentLocked,
				bulkDownloadCount: oldDocument.bulkDownloadCount,
				documentFilingType: oldDocument.documentFilingType,
				hasRevisionDocumentReady: oldDocument.hasRevisionDocumentReady,
				hasUploadedRevisionDocument: oldDocument.hasUploadedRevisionDocument,
				hasRevisionDocumentMerged: oldDocument.hasRevisionDocumentMerged,
			};

			let updatedState = { ...state[query] };
			let groupedReturns = updatedState.deliveredGroupedReturnsTableModel.deliveredGroupedReturns;
			for (let i = 0; i < groupedReturns.length; i++) {
				const taxReturns = groupedReturns[i].taxReturns;
				for (let j = 0; j < taxReturns.length; j++) {
					if (taxReturns[j].document.id === deliveredTaxDocument.document.id) {
						taxReturns[j] = deliveredTaxDocument;
					}
				}
			}

			newState[query] = {
				query: query,
				deliveredGroupedReturnsTableModel: updatedState.deliveredGroupedReturnsTableModel,
				totalRowCount: state[query].totalRowCount,
				loading: false,
				error: state[query].error,
				message: state[query].message
			};
		}
		else {
			newState[query] = {
				query: query,
				deliveredGroupedReturnsTableModel: state[query].deliveredGroupedReturnsTableModel,
				totalRowCount: state[query].totalRowCount,
				loading: false,	
				error: state[query].error,
				message: state[query].message
			};
		}
	}
	return state;
}

function clearGroupedReturnsPages(): DeliveredGroupedReturnsDictionary {
	return {} as DeliveredGroupedReturnsDictionary;   //clearing the dictionary on archive/recall
}