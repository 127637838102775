import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import * as ArchivedReturnsStore from '../../store/reports/archive/ArchivedReturnsStore';
import * as ArchivedTaxDocumentStore from '../../store/reports/archive/ArchivedTaxDocumentStore';
import * as DeliveredReturnsState from '../../store/reports/DeliveredReturnsState';
import * as Component from './ArchiveReturns';
import * as UserStore from '../../store/userManagement/UserStore';
import * as FilterStore from '../../store/reports/FilterStore';
import * as UserSettingStore from '../../store/userManagement/UserSettingStore';
import * as CustomColumnstore from '../../store/common/CustomColumnStore';
import * as CompanyStore from '../../store/company/CompanyStore';
import * as UploadTaxReturnStore from '../../store/common/uploadTaxReturn';
import * as AttachmentsStore from '../../store/ProcessReturn/AttachmentsStore';
import * as BulkOperationStore from '../../store/common/BulkOperationsStore';
import * as AdditionalEsignDocumentStore from '../../store/ProcessReturn/AdditionalEsignDocuments/AdditionalEsignDocumentStore';
import * as PaperFileStore from '../../store/ProcessReturn/PaperFileStore';

export default connect(
	(state: ApplicationState) => ({
		archivedReturns: state.archivedReturns, // Selects which state properties are merged into the component's props
        archiveTaxDocument: state.archiveTaxDocument,
        userStore: state.ssrUserList,
		reportFilters: state.reportFilters,
		userSettings: state.userSettings,
        customColumnData: state.customColumn,
        company: state.companyData,
        uploadTaxReturnStore: state.UploadTaxReturnStore,
        attachments: state.attachments
	}),
	{
		...ArchivedReturnsStore.actionCreators,
		...ArchivedTaxDocumentStore.actionCreators,
		...UserStore.actionCreators,
		...FilterStore.actionCreators,
		...UserSettingStore.actionCreators,
        ...CustomColumnstore.actionCreators,
        ...CompanyStore.actionCreators,
        ...UploadTaxReturnStore.actionCreators,
        ...AttachmentsStore.actionCreators,
        ...AdditionalEsignDocumentStore.actionCreators,
        ...BulkOperationStore.actionCreators,
        ...PaperFileStore.actionCreators, 
	}
)(Component.ArchiveReturns as any);