import { addTask } from 'domain-task';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../../';
import { handleResponse } from './../../Library';
import { actionTypes } from './../../../types/ActionTypes'
import { StatusType, NotificationAction } from './../../common/NotificationStore';
import { ColumnSettingsInitialState, IColumnSettings, IColumnSettingsState, ReportType } from './ColumnSettingsState';
import { RecieveColumnSettingsAction } from './ColumnSettingsKnownTypes';
import { API_BASE_URL } from '../../../utils/constants';

type KnownActions =
    RecieveColumnSettingsAction |
    NotificationAction;


export const actionCreator = {
    requestColumnSettings: (reportType: ReportType): AppThunkAction<KnownActions> => (dispatch, getState) => {
        const fetchTask = fetch(API_BASE_URL + 'api/Reports/ReportColumnSettings/' + reportType, {
            method: 'GET',
            credentials: 'include'
        })
            .then(handleResponse)
            .then(response => response as IColumnSettings[])
            .then(data => {
                if (Object.keys(data).length === 0) {
                    data = [];
                } else {
                    data = data.map(col => {
                        return {
                            ...col,
                            isActive: (col.isActive as any == true) ? 1: 0
                        }
                    })
                }
                dispatch({
                    type: actionTypes.RECEIVE_DELIVERED_RETURN_COLUMN_SETTINGS,
                    data: data,
                    reportType: reportType
                });
            })
            .catch((error) => {
                const statusMessage: any = error.statusText?.message ?? error.statusText;
                if (typeof(statusMessage) === "string") {
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: statusMessage,
                        statusType: StatusType.Error
                    });
                }
            });
        addTask(fetchTask);
    },
    saveColumnSettings: (reportType: ReportType, data: IColumnSettings[], callback?: () => void): AppThunkAction<KnownActions> => (dispatch, getState) => {
        const fetchTask = fetch(API_BASE_URL + 'api/Reports/ReportColumnSettings/' + reportType, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Accept': 'application/json, text/plain, *',
                'Content-Type': 'application/json; charset=utf-8',
                'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
            },
            body: JSON.stringify(data)
        })
            .then(handleResponse)
            .then(response => response)
            .then(res => {
                dispatch({
                    type: actionTypes.RECEIVE_DELIVERED_RETURN_COLUMN_SETTINGS,
                    data: data,
                    reportType: reportType
                });

                if (callback)
                    callback();
            })
            .catch((error) => {
                const statusMessage: any = error.statusText?.message ?? error.statusText;
                if (typeof(statusMessage) === "string") {
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: statusMessage,
                        statusType: StatusType.Error
                    });
                }
            });
        addTask(fetchTask);
    }
}

export const reducer: Reducer<IColumnSettingsState> = (state = ColumnSettingsInitialState, incomingAction) => {
    const action = incomingAction as KnownActions;
    switch (action.type) {
        case actionTypes.RECEIVE_DELIVERED_RETURN_COLUMN_SETTINGS:
            switch (action.reportType) {
                case ReportType.Delivered:
                    return {
                        ...state,
                        DeliveredReturnsColumnSettings: action.data
                    };
                case ReportType.Archived:
                    return {
                        ...state,
                        ArchivedReturnsColumnSettings: action.data
                    };
                case ReportType.GroupedReturnsReport:
                    return {
                        ...state,
                        GroupReturnsColumnSettings: action.data
                    };
            }
            break;
    }
    return state;
}