import { addTask } from 'domain-task';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../';
import { IQuickReportDocument, IDeliveredTaxReturns } from '../../components/common/DeliveredTaxReturns';
import { ITaxReturn } from '../../components/common/TaxReturn';
import { actionTypes } from '../../types/ActionTypes';
import {
     RequestQuickReportsAction, ReceiveQuickReportsAction, ReceiveQuickReportsPagesAction, RequestQuickReportsPagesAction
} from "./KnownTypes";
import { handleResponse } from '../Library';
import { StatusType, NotificationAction } from '../common/NotificationStore';
import { IQuickReportsDictionary, QuickReportsTableModel, QuickReportState } from '../../components/reports/QuickReports/QuickReportState';
import { QuickReportType } from '../../components/reports/Filters';
import {
    ReceiveTaxDocumentsAction, TaxReturnSource, ReceiveTaxDocumentAction, ArchiveTaxDocumentAction,
    SendReminderTaxDocumentAction, ResetDeliveredReturnsAction
} from '../common/TaxDocumentStore';
import { API_BASE_URL } from '../../utils/constants';

type KnownAction = RequestQuickReportsAction |
    ReceiveQuickReportsAction | ArchiveTaxDocumentAction | ReceiveTaxDocumentAction
    | ResetDeliveredReturnsAction | SendReminderTaxDocumentAction;

type AllKnownAction =
    RequestQuickReportsAction |
    ReceiveQuickReportsAction | 
    RequestQuickReportsPagesAction |
    ReceiveQuickReportsPagesAction |
    NotificationAction |
    ReceiveTaxDocumentsAction |
    ArchiveTaxDocumentAction |
    ReceiveTaxDocumentAction |
    ResetDeliveredReturnsAction |
    SendReminderTaxDocumentAction;

export const actionCreators = {
    requestQuickReport: (query: string, reload: boolean = false, quickReportType: QuickReportType, callback?: () => void): AppThunkAction<AllKnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        let state = getState();
        if (reload /*|| query !== state.quickReports.query */) {

            let page = state.quickReportPages[query];
            if (!reload && page) {
                dispatch({ type: actionTypes.REQUEST_QUICK_REPORTS, query: query });
                dispatch({ type: actionTypes.RECEIVE_QUICK_REPORTS, query: query, table: page.quickReportTableModel });
                return;
            }

            const fetchTask = fetch(API_BASE_URL + 'api/Reports/DeliveredReturns/GetQuickReportsAsync' + query + '&quickReportType=' + quickReportType, {
                method: 'GET',
                credentials: 'include'
            })
                .then(handleResponse)
                .then(response => response as Promise<QuickReportsTableModel>)
                .then(data => {

                    let documents: ITaxReturn[] = [];

                    data.documents.forEach((model, i) => {
                        documents.push(model.document);
                    });

                    dispatch({
                        type: actionTypes.RECEIVE_TAX_DOCUMENTS, taxDocuments: documents,
                        source: TaxReturnSource.QuickReport
                    });

                    dispatch({ type: actionTypes.RECEIVE_QUICK_REPORTS, query: query, table: data });
                    dispatch({ type: actionTypes.RECEIVE_QUICK_REPORTS_PAGES, query: query, table: data, totalRowCount: data.count });
                    if (callback) {
                        callback();
                    }
                })
                .catch((error) => {
                    dispatch({ type: actionTypes.NOTIFICATION, statusMessage: 'Error', statusType: StatusType.Error });
                    if (callback) {
                        callback();
                    }                    
                });
            addTask(fetchTask);
            dispatch({ type: actionTypes.REQUEST_QUICK_REPORTS, query: query });
            dispatch({ type: actionTypes.REQUEST_QUICK_REPORTS_PAGES, query: query });
        }
    },
    exportQuickReportAsExcel: (query: string, quickReportType: QuickReportType, isPaperFiledEnabled: boolean, callback?: () => void, resourceId:string=""): AppThunkAction<AllKnownAction> => (dispatch, getState) => {
        const fileName = quickReportType == QuickReportType.LinkNotAccessed ? 'LinkNotAccessedReport.xlsx' : 'KBAFailedReport.xlsx';
        const fetchTask = fetch(API_BASE_URL + 'api/ExportToExcel/ExportExcelQuickReport' + query +
            '&quickReportType=' + quickReportType +
            '&isPaperFiledEnabled=' + isPaperFiledEnabled +
            '&fileName=' + fileName, { credentials: 'include', headers:{'X-Resource-Id': resourceId} })
            .then(response => response.blob())
            .then(blob => {
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.href = url;
                const urlParams = new URLSearchParams(query);
              
                link.setAttribute('download', fileName);
                link.id = "download_link";
                link.target = '_blank';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                if (callback) {
                    callback();
                }
            })
            .catch((error) => {
                const statusMessage: any = error.statusText?.message ?? error.statusText;
                if (typeof(statusMessage) === "string") {
                    dispatch({ type: actionTypes.NOTIFICATION, statusMessage: statusMessage, statusType: StatusType.Error });
                }
                if (callback) {
                    callback();
                }
            });
        addTask(fetchTask);
    },

};

const unloadedState: QuickReportState = {
    quickReportTableModel: {
        documents: [],
        count: 0,
        quickReportType: QuickReportType.None
    } as QuickReportsTableModel,
    loading: true,
    query: '',
    totalRowCount: 0
} as QuickReportState;

const unloadedQuickReportDocument: IQuickReportDocument = {
    document: {}
} as IQuickReportDocument;


export const reducer: Reducer<QuickReportState, Action> = (state = unloadedState, incomingAction) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case actionTypes.REQUEST_QUICK_REPORTS:
            return ({
                ...unloadedState,
              //  query: action.query,
                loading: true
            }) as QuickReportState;
        case actionTypes.RECEIVE_QUICK_REPORTS:
            return {
               // query: action.query,
                quickReportTableModel: action.table,
                totalRowCount: action.table.count,
                loading: false
            } as QuickReportState;
        case actionTypes.RECEIVE_TAX_DOCUMENT:
        case actionTypes.SEND_REMINDER_TAX_DOCUMENT:
            return updateState(action.type, state, action);

        case actionTypes.ARCHIVE_TAX_DOCUMENT:
        case actionTypes.RESET_DELIVERED_RETURNS:
            return ({
                ...unloadedState,
                loading: true
            }) as QuickReportState;
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            const exhaustiveCheck: never = action;
    }

    return state;
};

export const quickReportPageReducer: Reducer<IQuickReportsDictionary, Action> = (state = { "?": unloadedState }, incomingAction) => {
    const action = incomingAction as AllKnownAction;
    switch (action.type) {
        case actionTypes.REQUEST_QUICK_REPORTS_PAGES:
            if (!state[action.query]) {
                let added = { ...unloadedState } as QuickReportState;
                added.loading = true;
                //added.query = action.query;
                let item = {} as IQuickReportsDictionary;
                item[action.query] = added;

                return {
                    ...state,
                    ...item
                };
            }
            break;

        case actionTypes.RECEIVE_QUICK_REPORTS_PAGES:
            // Only accept the incoming data if it matches the most recent request. This ensures we correctly
            // handle out-of-order responses.
            if (state[action.query]) {
                let changed = state[action.query] as QuickReportState;
                changed.loading = false;
                changed.quickReportTableModel = action.table;
                changed.totalRowCount = action.totalRowCount;
                let item = {} as IQuickReportsDictionary;
                item[action.query] = changed;
                return {
                    ...state,
                    ...item
                };
            }
            break;
        case actionTypes.ARCHIVE_TAX_DOCUMENT:
        case actionTypes.RESET_DELIVERED_RETURNS:
            return {} as IQuickReportsDictionary;
        case actionTypes.RECEIVE_TAX_DOCUMENT:
        case actionTypes.SEND_REMINDER_TAX_DOCUMENT:
            return updatePageState(action.type, state, action);
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            //const exhaustiveCheck: never = action;
    }
    return state;
};

function updateState(type: actionTypes, state: QuickReportState, action: KnownAction): QuickReportState {
    let i: number = -1;
    let oldDocument: IQuickReportDocument = unloadedQuickReportDocument;
    let document: IDeliveredTaxReturns = {} as IDeliveredTaxReturns;
    let id: number = 0;
    switch (action.type) {
        case actionTypes.RECEIVE_TAX_DOCUMENT:
            document = action.taxDocument as IDeliveredTaxReturns;
            id = action.id;
            break;
        case actionTypes.SEND_REMINDER_TAX_DOCUMENT:
            id = action.id;
            break;
    }
    if (state.quickReportTableModel.documents) {
        state.quickReportTableModel.documents.forEach((value, index) => {
            if (value.document.id === id) {
                i = index;
                oldDocument = value;
                return;
            }
        });
    }
    if (i !== -1) {
        let deliveredTaxDocument: IQuickReportDocument = {
            document: action.type == actionTypes.RECEIVE_TAX_DOCUMENT ? document : oldDocument.document,
            signedCount: oldDocument.signedCount,
            linkStatus: oldDocument.linkStatus,
            kbaStatus: oldDocument.kbaStatus,
            documentFilingType: oldDocument.documentFilingType
        };

        let documents = [
            ...state.quickReportTableModel.documents?.slice(0, i),
            deliveredTaxDocument,
            ...state.quickReportTableModel.documents?.slice(i + 1)];
        let deliveredReturnTableModel: QuickReportsTableModel = {
            count: state.quickReportTableModel.count,
            documents: documents,
            quickReportType: state.quickReportTableModel.quickReportType
        }

        return {
            quickReportTableModel: deliveredReturnTableModel,
            totalRowCount: state.totalRowCount,
            loading: false
        } as QuickReportState;
    }
    return state;
}

function updatePageState(type: actionTypes, state: IQuickReportsDictionary, action: KnownAction): IQuickReportsDictionary {
    let i: number = -1;
    let newState = {} as IQuickReportsDictionary;
    let oldDocument: IQuickReportDocument = unloadedQuickReportDocument;
    let document: IDeliveredTaxReturns = {} as IDeliveredTaxReturns;
    let id: number = 0;
    switch (action.type) {
        case actionTypes.RECEIVE_TAX_DOCUMENT:
            document = action.taxDocument as IDeliveredTaxReturns;
            id = action.id;
            break;
        case actionTypes.SEND_REMINDER_TAX_DOCUMENT:
            id = action.id;
            break;
    }

    for (var query in state) {
        i = -1;
        if (state[query].quickReportTableModel.documents) {
            state[query].quickReportTableModel.documents.forEach((value, index) => {
                if (value.document.id === id) {
                    i = index;
                    oldDocument = value;
                    return;
                }
            });
        }
        if (i !== -1) {
            let deliveredTaxDocument: IQuickReportDocument = {
                document: action.type == actionTypes.RECEIVE_TAX_DOCUMENT ? document : oldDocument.document,                
                signedCount: oldDocument.signedCount,
                linkStatus: oldDocument.linkStatus,
                kbaStatus: oldDocument.kbaStatus,
                documentFilingType: oldDocument.documentFilingType
            };

            let documents = [
                ...state[query].quickReportTableModel.documents?.slice(0, i),
                deliveredTaxDocument,
                ...state[query].quickReportTableModel.documents?.slice(i + 1)];
            let deliveredReturnTableModel: QuickReportsTableModel = {
                count: state[query].quickReportTableModel.count,
                documents: documents,
                quickReportType: state[query].quickReportTableModel.quickReportType
            }
            newState[query] = {
                quickReportTableModel: deliveredReturnTableModel,
                totalRowCount: state[query].totalRowCount,
                loading: false
            };
        }
        else {
            newState[query] = {
                quickReportTableModel: state[query].quickReportTableModel,
                totalRowCount: state[query].totalRowCount,
                loading: false
            };
        }
    }
    return newState;
}
