import * as Enumerable from 'linq';
import * as React from 'react';
import { DragDropContainer } from 'react-drag-drop-container';
import { IPdfDocumentFacade } from '../../../../Core/Utilities/PdfDocumentFacade';
import { DocumentGroups } from '../../../common/TaxReturn';
import { CheckBoxComponent } from '../../CheckBoxComponent';
import { groupDictionary, ISelectedPagesDictionary } from '../GroupsView';
import { PdfPageViewer } from '../PdfViewer/PdfPageViewer';
import { GroupTabConstants } from '../../../helper/Constants';

interface PageThumbnailProps {
    document: IPdfDocumentFacade | undefined;
    documentId: number;
    pageNo: number;
    title: string;
    group: DocumentGroups;
    selectedPages?: ISelectedPagesDictionary;
    groupIcons?: groupDictionary;
    containerClass?: string;
    onClick?: (page: number) => void;
    onDelete?: (page: number) => void;
    onOpen?: (group: DocumentGroups, activePage: number) => void;
    onSelectPage?: (pageNo: number, documentGroup: DocumentGroups) => void;
    onUnSelectPage?: (pageNo: number) => void;
    onDrop?: (event: any) => void;
}

interface PageThumbnailState {
    selectedPages: ISelectedPagesDictionary;
}

export class PageThumbnail extends React.Component<PageThumbnailProps, PageThumbnailState> {
    state: PageThumbnailState = {
        selectedPages: []
    };

    UNSAFE_componentWillReceiveProps(props: PageThumbnailProps) {
        this.setState({
            selectedPages: props.selectedPages || []
        });
    }

    private selectPageHandler = (pageNo: number, source: DocumentGroups, event: any) => {
        if (event.target.checked) {
            this.props.onSelectPage && this.props.onSelectPage(pageNo, source);
        }
        else {
            this.props.onUnSelectPage && this.props.onUnSelectPage(pageNo);
        }
    }

    private onDrag = (dragData: any) => {

        if (!this.state.selectedPages[dragData.pageNo]) {

            let draggedItem: ISelectedPagesDictionary = {};
            draggedItem[dragData.pageNo as number] = { source: dragData.documentGroup as DocumentGroups }

            this.setState({
                selectedPages: []
            });
        }
    }

    private createDraggableThumbnail = () => {

        if (this.props.onDrop) {

            return this.props.document && (<DragDropContainer customDragElement={<PdfPageThumbnailGhostImage key={"draggable-ghost" + this.props.pageNo}
                selectedPages={this.state.selectedPages}
                pageNo={this.props.pageNo} documentGroup={this.props.group} groupIcons={this.props.groupIcons} />}
                targetKey="groups" onDrag={this.onDrag} onDrop={this.props.onDrop}
                dragData={{ pageNo: this.props.pageNo, documentGroup: this.props.group }}>
                <PdfPageViewer key={"page-viewer-" + this.props.pageNo} document={this.props.document} pageNo={this.props.pageNo} />
            </DragDropContainer>);
        }
        else {
            return (this.props.document && <PdfPageViewer key={"page-viewer-" + this.props.pageNo} document={this.props.document} pageNo={this.props.pageNo} />);
        }
    }
    
    public render() {

        return (<div className={this.props.containerClass || "col-lg-2 col-md-3 col-sm-12"}>
            <div className="page-thumbnail" onClick={() => { this.props.onClick && this.props.onClick(this.props.pageNo) }}>
                <span>
                    {this.createDraggableThumbnail()}
					{this.props.title && <div className="col-lg-12 title-bar text-color-black" title={this.props.title}>
                        {this.props.title}
                    </div>}
                </span>
                <div className="overlay-content">
                    <div className="action-buttons">
                        <ul>
                            {this.props.onOpen && <li>
                                <span title="View" onClick={() => this.props.onOpen && this.props.onOpen(this.props.group, this.props.pageNo)} >
                                    <i className="fas fa-eye" aria-hidden="true"></i></span>
                            </li>}
                            {(this.props.onDelete && this.props.group !== DocumentGroups.Deleted) && <li>
                                <span onClick={() => this.props.onDelete && this.props.onDelete(this.props.pageNo)}
                                    title="Delete">
                                    <i className="fas fa-times" aria-hidden="true"></i></span>
                            </li>}
                        </ul>
                    </div>

                    {this.props.onSelectPage && <CheckBoxComponent id={"page-thumbnail-checkbox-" + this.props.documentId + this.props.pageNo}
                        text={""} className={"page-thumbnail-checkbox"}
                        checked={this.props.selectedPages && this.props.selectedPages[this.props.pageNo] ? true : false}
                        onChange={this.selectPageHandler.bind(this, this.props.pageNo, this.props.group)}
                        datatestAuto={"a5d4d52c-d9f5-4602-969f-4ae3090c9082"} />}

                    <span className="page-number">{this.props.pageNo}</span>
                </div>
            </div>
        </div>);
    }
}

interface PdfPageThumbnailGhostImageProps {
    selectedPages: ISelectedPagesDictionary;
    pageNo: number;
    documentGroup: DocumentGroups;
    groupIcons: groupDictionary | undefined;
}

export class PdfPageThumbnailGhostImage extends React.Component<PdfPageThumbnailGhostImageProps, {}>{
    private createDragSummary = () => {

        let groupedResult: any[] = Enumerable.from(this.props.selectedPages).where(x => x != undefined)
            .groupBy((x: any) => x.source)
            .select(function (groupedItem: any) {
                return { "source": groupedItem.first().source, "count": groupedItem.count() }
            }).toArray();


        let elementCollection: any = [];

        if (Object.keys(this.props.selectedPages).length <= 0) {

            elementCollection.push(<li key="1" className="list-group-item">
                <i className={" draggable-ghost-list-item-icon " + (this.props.groupIcons && this.props.groupIcons[this.props.documentGroup].icon)} />
                {DocumentGroups[this.props.documentGroup]}
                <span className="badge badge-primary">{1}</span>
            </li>);
        }
        else {

            for (var i = 0; i < groupedResult.length; i++) {

                elementCollection.push(<li className="list-group-item" key={i + 1}>
                    <i className={" draggable-ghost-list-item-icon " + (this.props.groupIcons && this.props.groupIcons[groupedResult[i].source].icon)} />
                    {DocumentGroups[groupedResult[i].source]}
                    <span className="badge badge-primary">{groupedResult[i].count}</span>
                </li>);
            }
        }

        return elementCollection;
    }

    private createFooter = () => {

        if (Object.keys(this.props.selectedPages).length <= 0) {

            return (<div className="caption">
                <h4 className="group inner list-group-item-heading">
                    {GroupTabConstants.singlePageDragGhostText}
                </h4>
            </div>);
        }
        else {
            return (<div className="caption">
                <h5 className="group inner list-group-item-heading">
                    {Object.keys(this.props.selectedPages).filter(x => x != undefined).length + " Pages."}
                </h5>
            </div>);
        }
    }

    public render() {

        return <div className="item" style={{ height: 280, width: 160 }} >
            <div className="thumbnail">
                <div style={{ height: 210 }}>
                    <ul className={Object.keys(this.props.selectedPages).length <= 0 ? "draggable-ghost-list-center list-group" : "list-group"}   >
                        {this.createDragSummary()}
                    </ul>
                </div>
                {this.createFooter()}
            </div>
        </div>

    }
}