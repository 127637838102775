import * as React from "react";
export interface SvgImageProps {
    height: number;
    width: number;
    style?: React.CSSProperties;
    className?: string;
}

export const LoginSession: React.FC<SvgImageProps> = (props) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={props.width}
            height={props.height}
            style={props.style}
            className={props.className}
            viewBox="0 0 300 300"
        >
            <path
                fill="#FFFFFF"
                d="M132.5,160c44.112,0,80-35.888,80-80s-35.888-80-80-80s-80,35.888-80,80S88.388,160,132.5,160z"
            />
            <path
                fill="#FFFFFF"
                d="M297.199,221.809l-54.191-93.964c-3.766-6.523-10.515-10.418-18.055-10.418 c-7.538,0-14.286,3.895-18.051,10.421l-17.276,29.955l-4.402-0.207c-1.358-0.063-2.577-0.095-3.724-0.095h-11.321l-1.987,0.913 c-11.318,5.2-23.326,7.837-35.691,7.837c-12.315,0-24.329-2.639-35.708-7.844l-1.981-0.906H83.5C37.458,157.5,0,194.958,0,241 v22.749C0,283.738,16.262,300,36.25,300h192.499C248.738,300,265,283.738,265,263.749v-10.687h14.146 c7.529,0,14.274-3.896,18.044-10.423C300.957,236.116,300.96,228.328,297.199,221.809z"
            />
            <path
                fill="#4D4D4D"
                d="M132.5,150c38.664,0,70-31.336,70-70s-31.336-70-70-70s-70,31.336-70,70S93.836,150,132.5,150z"
            />
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="224.5654" y1="217.7676" x2="225.3447" y2="217.7676">
                <stop offset="0" style={{ stopColor: "#F7C001" }} />
                <stop offset="1" style={{ stopColor: "#E77A17" }} />
            </linearGradient>
            <path
                fill="url(#SVGID_1_)"
                d="M224.955,217.378c-0.215,0-0.39,0.175-0.39,0.39c0,0.214,0.175,0.389,0.39,0.389 s0.39-0.175,0.39-0.389C225.345,217.553,225.17,217.378,224.955,217.378L224.955,217.378z"
            />
            <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="159.9102" y1="185.2441" x2="290" y2="185.2441">
                <stop offset="0" style={{ stopColor: "#F7C001" }} />
                <stop offset="1" style={{ stopColor: "#E77A17" }} />
            </linearGradient>
            <path
                fill="url(#SVGID_2_)"
                stroke="#FBB03B"
                stroke-width="3"
                d="M288.536,226.805c4.169,7.226-1.063,16.258-9.391,16.258H170.763 c-8.342,0-13.551-9.046-9.39-16.258l54.191-93.961c4.171-7.229,14.617-7.216,18.782,0L288.536,226.805L288.536,226.805z M224.955,207.378c-5.737,0-10.39,4.651-10.39,10.39c0,5.737,4.652,10.389,10.39,10.389s10.39-4.651,10.39-10.389 C235.345,212.029,230.692,207.378,224.955,207.378z M215.092,170.034l1.675,30.716c0.079,1.438,1.268,2.563,2.706,2.563h10.964 c1.44,0,2.628-1.125,2.707-2.563l1.675-30.716c0.085-1.553-1.151-2.857-2.706-2.857h-14.314 C216.243,167.177,215.007,168.481,215.092,170.034L215.092,170.034z"
            />
            <path
                fill="#808080"
                d="M170.763,252.396c-7.288,0-13.816-3.771-17.464-10.086c-3.648-6.318-3.652-13.857-0.01-20.17l31.465-54.556 c-1.079-0.051-2.162-0.084-3.254-0.084h-9.134c-12.141,5.578-25.648,8.75-39.866,8.75c-14.219,0-27.672-3.172-39.867-8.75H83.5 C42.922,167.5,10,200.422,10,241v22.749C10,278.242,21.758,290,36.25,290h192.499C243.242,290,255,278.242,255,263.749v-11.354 H170.763z"
            />
        </svg>
    );
};

export const LoginHistory: React.FC<SvgImageProps> = (props) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={props.width}
            height={props.height}
            style={props.style}
            className={props.className}
            viewBox="0 0 300 300"
        >
            <path
                fill="#FFFFFF"
                d="M132.5,160c44.111,0,80-35.888,80-80s-35.889-80-80-80c-44.112,0-80,35.888-80,80S88.388,160,132.5,160z"
            />
            <path
                fill="#4D4D4D"
                d="M132.5,150c38.664,0,70-31.336,70-70s-31.336-70-70-70s-70,31.336-70,70S93.836,150,132.5,150z"
            />
            <linearGradient
                id="SVGID_1_"
                gradientUnits="userSpaceOnUse"
                x1="480.5654"
                y1="-263.7676"
                x2="481.3447"
                y2="-263.7676"
                gradientTransform="matrix(1 0 0 -1 -256 -46)"
            >
                <stop offset="0" style={{ stopColor: "#F7C001" }} />
                <stop offset="1" style={{ stopColor: "#E77A17" }} />
            </linearGradient>
            <path
                fill="url(#SVGID_1_)"
                d="M224.955,217.378c-0.215,0-0.391,0.175-0.391,0.39s0.176,0.39,0.391,0.39s0.391-0.175,0.391-0.39 S225.17,217.378,224.955,217.378L224.955,217.378z"
            />
            <path
                fill="#FFFFFF"
                d="M36.251,300c-14.598,0-27.707-8.689-33.397-22.139C0.96,273.385,0,268.637,0,263.749V241 c0-46.042,37.458-83.5,83.5-83.5h11.271l2.247,1.006c11.319,5.139,23.256,7.744,35.482,7.744c12.226,0,24.163-2.605,35.479-7.743 l2.207-0.999l2.18-0.008h9.134c46.042,0,83.5,37.458,83.5,83.5v22.749c0,4.89-0.96,9.638-2.854,14.113 C256.456,291.311,243.348,300,228.75,300H36.251z"
            />
            <path
                fill="#808080"
                d="M181.5,167.5h-9.133h-0.001c-0.084,0.038-0.169,0.074-0.253,0.112c-12.129,5.507-25.493,8.638-39.613,8.638 s-27.485-3.131-39.613-8.638c-0.084-0.038-0.169-0.074-0.253-0.112h-0.001H83.5C42.922,167.5,10,200.422,10,241v22.749 c0,3.623,0.735,7.075,2.063,10.216C16.05,283.387,25.381,290,36.25,290h0.001h192.498h0.001c10.869,0,20.2-6.613,24.187-16.035 c1.329-3.141,2.063-6.593,2.063-10.216V241C255,200.422,222.078,167.5,181.5,167.5z"
            />
            <path
                fill="#FFFFFF"
                d="M232.183,284.391c-15.447,0-30.563-5.334-42.565-15.019c-3.432-2.771-5.534-6.886-5.769-11.292 c-0.234-4.414,1.424-8.738,4.549-11.864l2.626-2.626c2.96-2.958,6.891-4.586,11.069-4.586c3.494,0,6.923,1.193,9.653,3.361 c5.858,4.648,12.925,7.105,20.437,7.105c4.496,0,8.785-0.907,12.692-2.547c-0.485,0.045-0.977,0.067-1.472,0.067l0,0 c-3.448,0-6.847-1.167-9.571-3.285l-19.109-14.863v-15.598c-0.889,0.18-1.804,0.272-2.736,0.272h-33.891 c-7.571,0-13.73-6.159-13.73-13.73v-33.891c0-7.575,6.172-13.738,13.757-13.738c3.645,0,7.083,1.43,9.681,4.027l5.096,5.096 c11.41-8.131,25.052-12.525,39.284-12.525c37.326,0,67.749,30.365,67.817,67.689c0.033,18.081-7.013,35.121-19.841,47.982 c-12.827,12.859-29.848,19.949-47.927,19.963H232.183z M255.271,216.142c3.287,2.556,5.384,6.241,5.9,10.375 c0.285,2.285,0.07,4.555-0.611,6.684c2.871-4.882,4.519-10.565,4.519-16.627c0-11.94-6.395-22.416-15.939-28.182 c0.328,1.258,0.503,2.577,0.503,3.936v19.437L255.271,216.142z"
            />
            <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="174.3652" y1="216.5732" x2="290" y2="216.5732">
                <stop offset="0" style={{ stopColor: "#F7C001" }} />
                <stop offset="1" style={{ stopColor: "#E77A17" }} />
            </linearGradient>
            <path
                fill="url(#SVGID_2_)"
                stroke="#FBB03B"
                strokeWidth="3"
                d="M290,216.464c0.059,31.855-25.92,57.903-57.775,57.927 c-13.759,0.01-26.396-4.786-36.327-12.801c-2.583-2.085-2.776-5.955-0.429-8.303l2.626-2.626c2.008-2.007,5.212-2.228,7.436-0.463 c7.316,5.806,16.576,9.272,26.652,9.272c23.711,0,42.896-19.189,42.896-42.897c0-23.711-19.189-42.897-42.896-42.897 c-11.381,0-21.717,4.424-29.392,11.641l11.833,11.833c2.35,2.351,0.687,6.368-2.638,6.368h-33.891c-2.062,0-3.73-1.669-3.73-3.73 v-33.891c0-3.324,4.018-4.988,6.368-2.639l11.51,11.511c10.38-9.92,24.447-16.013,39.939-16.013 C264.078,158.756,289.941,184.582,290,216.464z M247.823,234.831l2.29-2.944c1.897-2.439,1.458-5.955-0.981-7.852l-9.489-7.381 v-24.327c0-3.091-2.505-5.595-5.595-5.595h-3.73c-3.09,0-5.595,2.504-5.595,5.595v31.625l15.249,11.86 C242.41,237.709,245.926,237.271,247.823,234.831z"
            />
        </svg>
    );
};

export const SessionTimeout: React.FC<SvgImageProps> = (props) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={props.width}
            height={props.height}
            style={props.style}
            className={props.className}
            viewBox="0 19 192 192"
        >
            <path fill="#F7C001" d="M-323-93" />
            <path
                fill="#F8F8F8"
                d="M96,207c-50.729,0-92.001-41.271-92.001-92c0-50.729,41.272-92,92.001-92s92.001,41.271,92.001,92 C188.001,165.729,146.729,207,96,207L96,207z M75.27,66.749C56.591,74.805,43.484,93.4,43.484,115 c0,28.957,23.558,52.516,52.516,52.516c28.959,0,52.517-23.559,52.517-52.516c0-21.6-13.107-40.195-31.786-48.251 c0.198,1,0.302,2.033,0.302,3.09v39.601l16.607,12.083c3.438,2.483,5.704,6.193,6.349,10.423c0.633,4.152-0.378,8.317-2.844,11.729 l-6.078,8.374c-3.028,4.113-7.813,6.53-12.846,6.53c-3.364,0-6.586-1.042-9.314-3.016l-27.51-19.993 c-4.025-2.986-6.43-7.758-6.43-12.764V69.839C74.967,68.782,75.071,67.749,75.27,66.749L75.27,66.749z"
            />
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="16" y1="115" x2="176" y2="115">
                <stop offset="0" style={{ stopColor: "#F7C001" }} />
                <stop offset="1" style={{ stopColor: "#E77A17" }} />
            </linearGradient>
            <path
                fill="url(#SVGID_1_)"
                d="M96,35c-44.193,0-80,35.807-80,80s35.807,80,80,80s80-35.807,80-80S140.193,35,96,35z M96,179.516 c-35.646,0-64.516-28.87-64.516-64.516c0-35.645,28.87-64.516,64.516-64.516S160.517,79.355,160.517,115 C160.517,150.646,131.646,179.516,96,179.516z M115.936,145.839l-27.388-19.902c-1.001-0.744-1.58-1.904-1.58-3.13V69.839 c0-2.13,1.741-3.871,3.871-3.871h10.322c2.13,0,3.871,1.741,3.871,3.871v45.71l21.548,15.677c1.742,1.259,2.098,3.678,0.84,5.42 L121.355,145C120.096,146.71,117.677,147.097,115.936,145.839L115.936,145.839z"
            />
        </svg>
    );
};

export const AccessDenied: React.FC<SvgImageProps> = (props) => {
    return (
        <svg width="200" height="200" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="100" cy="100" r="100" fill="#E6EBF0" />
            <path
                d="M132.974 47.8873H67.0259C56.455 47.8908 47.8909 56.379 47.8873 66.8561V132.219C47.8873 133.669 49.0744 134.845 50.5371 134.845C51.9998 134.845 53.1869 133.669 53.1869 132.219V79.2871H146.813V132.219C146.817 139.793 140.623 145.932 132.981 145.935H132.974H67.0259C64.6234 145.935 62.2634 145.312 60.1753 144.135C58.861 143.498 57.2747 144.041 56.6317 145.343C56.0487 146.53 56.4409 147.959 57.5538 148.688C60.4403 150.316 63.7049 151.174 67.0259 151.174H132.974C143.545 151.174 152.113 142.682 152.113 132.205V66.8561C152.109 56.379 143.541 47.8908 132.974 47.8873ZM146.813 66.8561V74.0346H53.1869V66.8561C53.1834 59.2819 59.3768 53.1434 67.0189 53.1399H67.0259H132.974C140.616 53.1364 146.81 59.2749 146.813 66.8491V66.8561Z"
                fill="#B4C3D3"
            />
            <path
                d="M80.2852 60.0939C78.4696 60.0904 76.9988 61.5611 76.9953 63.3767C76.9918 65.1923 78.4626 66.6631 80.2782 66.6666C82.0938 66.6702 83.5646 65.1994 83.5681 63.3838V63.3767C83.5681 61.5647 82.0973 60.0939 80.2852 60.0939Z"
                fill="#B4C3D3"
            />
            <path
                d="M67.6097 60.0939C65.5347 60.0904 63.8538 61.5611 63.8498 63.3767C63.8457 65.1923 65.5266 66.6631 67.6016 66.6666C69.6766 66.6702 71.3575 65.1994 71.3615 63.3838V63.3767C71.3575 61.5647 69.6806 60.0974 67.6097 60.0939Z"
                fill="#B4C3D3"
            />
            <path
                d="M94.3697 60.0939C92.5506 60.0939 91.0798 61.5647 91.0798 63.3803C91.0798 65.1959 92.5506 66.6666 94.3662 66.6666C96.1818 66.6666 97.6526 65.1959 97.6526 63.3803C97.6526 61.5682 96.1818 60.0939 94.3697 60.0939Z"
                fill="#B4C3D3"
            />
            <path
                d="M86.6714 122.693C87.0429 123.089 87.4844 123.404 87.9706 123.619C88.4568 123.833 88.9781 123.944 89.5046 123.944C90.0311 123.944 90.5525 123.833 91.0387 123.619C91.5249 123.404 91.9664 123.089 92.3379 122.693L100.047 114.5L107.761 122.693C108.521 123.445 109.526 123.855 110.565 123.836C111.603 123.816 112.594 123.369 113.329 122.588C114.064 121.808 114.484 120.754 114.503 119.65C114.521 118.547 114.135 117.478 113.427 116.67L105.719 108.478L113.427 100.285C114.158 99.4819 114.562 98.4057 114.554 97.2885C114.545 96.1712 114.124 95.1023 113.381 94.3119C112.638 93.5214 111.633 93.0728 110.581 93.0626C109.53 93.0523 108.517 93.4813 107.761 94.2571L100.047 102.45L92.3379 94.2571C91.9679 93.8504 91.5253 93.526 91.0361 93.3029C90.5468 93.0798 90.0207 92.9626 89.4883 92.9579C88.9559 92.9533 88.428 93.0613 87.9354 93.2758C87.4428 93.4903 86.9953 93.807 86.619 94.2072C86.2427 94.6075 85.9452 95.0834 85.7439 95.6072C85.5425 96.1309 85.4413 96.6921 85.4462 97.2579C85.4511 97.8236 85.5619 98.3827 85.7723 98.9025C85.9826 99.4222 86.2883 99.8923 86.6714 100.285L94.3587 108.478L86.65 116.67C86.279 117.067 85.9852 117.538 85.7854 118.055C85.5857 118.573 85.4839 119.127 85.4859 119.686C85.4879 120.246 85.5936 120.799 85.797 121.315C86.0004 121.831 86.2975 122.299 86.6714 122.693Z"
                fill="#B4C3D3"
            />
        </svg>
    );
};
export const ProductAccessDenied: React.FC<SvgImageProps> = (props) => {
    return (
        <svg width="200" height="200" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="100" cy="100" r="100" fill="#E6EBF0" />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M121.382 80.9565C124.178 83.7531 126.387 87.0806 127.879 90.7434C129.699 95.2353 130.39 100.105 129.887 104.926C129.385 109.746 127.707 114.37 125 118.39C122.293 122.41 118.641 125.704 114.363 127.982C110.086 130.26 105.315 131.453 100.469 131.455C94.2834 131.461 88.2526 129.525 83.2258 125.921C78.1989 122.317 74.4295 117.226 72.4485 111.366C71.0499 107.209 70.5856 102.794 71.0889 98.4366C71.1352 98.0403 71.3369 97.6787 71.6498 97.4312C71.9627 97.1837 72.3611 97.0707 72.7573 97.1169C73.1535 97.1632 73.5151 97.365 73.7626 97.6779C74.01 97.9909 74.1231 98.3893 74.0768 98.7855C73.6252 102.701 74.0422 106.668 75.298 110.403C76.4976 113.935 78.4252 117.173 80.955 119.911C81.197 119.584 81.4772 119.259 81.7852 118.951L117.713 83.0225C117.964 82.772 118.225 82.54 118.49 82.332C116.15 80.1747 113.433 78.449 110.465 77.2463C104.05 74.6653 96.8852 74.6653 90.4701 77.2463C87.1765 78.5805 84.1863 80.5662 81.6789 83.0844C81.5392 83.2241 81.3733 83.3349 81.1908 83.4105C81.0083 83.4861 80.8126 83.525 80.6151 83.525C80.4175 83.525 80.2219 83.4861 80.0393 83.4105C79.8568 83.3349 79.691 83.2241 79.5513 83.0844C79.4116 82.9446 79.3007 82.7788 79.2251 82.5962C79.1495 82.4137 79.1106 82.218 79.1106 82.0204C79.1106 81.8228 79.1495 81.6272 79.2251 81.4446C79.3007 81.2621 79.4116 81.0962 79.5513 80.9565C82.3428 78.1541 85.6712 75.944 89.3371 74.4586C96.4778 71.5811 104.455 71.5811 111.596 74.4586C115.258 75.9506 118.585 78.1599 121.382 80.9565ZM120.598 84.5096C120.709 84.6384 120.819 84.7682 120.928 84.8992C123.73 88.2753 125.652 92.2929 126.523 96.5931C127.393 100.893 127.185 105.342 125.917 109.543C124.649 113.743 122.361 117.564 119.256 120.664C116.748 123.18 113.758 125.165 110.465 126.5C104.05 129.084 96.8846 129.084 90.4701 126.5C87.774 125.402 85.2819 123.873 83.0895 121.976C83.4034 121.741 83.7145 121.471 84.0101 121.176L119.938 85.2474C120.176 85.0093 120.398 84.7611 120.598 84.5096Z"
                fill="#B4C3D3"
                stroke="#B4C3D3"
                stroke-width="3"
            />
            <path
                d="M154.848 59.1888C137.336 59.4942 120.141 54.5004 105.523 44.8633C103.905 43.7742 101.998 43.1925 100.047 43.1925C98.0964 43.1925 96.1899 43.7742 94.5719 44.8633C81.3889 53.6022 66.0573 58.5575 50.2488 59.1888C48.0036 59.2684 45.8548 60.12 44.1654 61.5996C42.476 63.0792 41.3497 65.0961 40.9768 67.3094C37.9311 84.6663 32.9871 142.822 97.1556 162.03C98.0943 162.307 99.0685 162.445 100.047 162.441C101.025 162.442 101.998 162.302 102.935 162.026C178.725 139.33 158.044 62.0585 157.828 61.2815C157.649 60.643 157.254 60.086 156.711 59.7046C156.168 59.3233 155.51 59.1411 154.848 59.1888ZM153.431 102.046C148.302 129.359 130.751 147.693 101.272 156.521C100.472 156.759 99.6192 156.759 98.819 156.521C79.5011 150.716 35.744 130.543 46.664 68.3019C46.8183 67.3899 47.2823 66.5589 47.978 65.9486C48.6737 65.3383 49.5586 64.9862 50.4837 64.9513C67.3697 64.2739 83.7448 58.9751 97.8217 49.6333C98.4789 49.1894 99.254 48.9522 100.047 48.9522C100.841 48.9522 101.616 49.1894 102.273 49.6333C117.208 59.6548 134.789 65.011 152.78 65.0206C155.351 77.2089 155.572 89.7752 153.431 102.046Z"
                fill="#B4C3D3"
            />
        </svg>
    );
};
