import { IUserGroup, IUserGroups, ISSRUserModel, IUserModel } from '../../Core/ViewModels/User/UserViewModel';
import { fullName, fullSSRName } from '../helper/HelperFunctions';
import * as Helper from '../helper/HelperFunctions';
var moment = require('moment-timezone');
import store from "../../store";

export function getPartnerUsers() {
    return store.getState().partnerUsersList?.partnerUsers || [];
}

export function getNonSignerUser(signatureusers: IUserModel[], allUsers: IUserModel[]) {
    let result = [...allUsers];
    signatureusers.map((u, i) => {
        Helper.removeItem(u, result);
    });
    return result;
}
export function sortUsersAscOrder(users: IUserModel[]) {
    return users.sort((a, b) => {      //Sorting
        if (a.firstName.toLocaleLowerCase() > b.firstName.toLocaleLowerCase()) {
            return 1;
        }
        if (a.firstName.toLocaleLowerCase() < b.firstName.toLocaleLowerCase()) {
            return -1;
        }
        return 0;
    });
}

export function PrepareUserList(userList: IUserModel[]) {
    var userOptionArray: any = [];
    userOptionArray.push({
        value: 0,
        label: "Select"
    })
    userList.map((user: any) =>
        userOptionArray.push({
            value: user.userId,
            label: fullName(user)
        }));

    return userOptionArray;
}
export function PrepareSSRUserList(userList: ISSRUserModel[]) {
    var userOptionArray: any = [];
    userOptionArray.push({
        value: 0,
        label: "Select"
    })
    userList.map((user: any) =>
        userOptionArray.push({
            value: user.id,
            label: fullSSRName(user)
        }));

    return userOptionArray;
}


export function DocumentTypeExpirationList() {
    var documentTypeExpirationArray: any = [];
    documentTypeExpirationArray.push({
        value: 0,
        label: "Select"
    })
    documentTypeExpirationArray.push({
        value: "6",
        label: "6 Months"
    });
    documentTypeExpirationArray.push({
        value: "12",
        label: "1 Year"
    });
    documentTypeExpirationArray.push({
        value: "24",
        label: "2 Years"
    });
    documentTypeExpirationArray.push({
        value: "36",
        label: "3 Years"
    });
    documentTypeExpirationArray.push({
        value: "84",
        label: "7 Years"
    });

    return documentTypeExpirationArray;
}

export function getSignatureUsers(userList: IUserModel[])
{
    let sigusers: IUserModel[] = [];
    let result: IUserModel[] = [];
    userList.map((user, i) => {
        if (user.userGroups) {
            if (user.userGroups.find((g) => g.userGroups === IUserGroup.Signatures)) {
                sigusers.push(user);
            }
        }
    });
    if (sigusers.length > 0) {
        result = sortUsersAscOrder(sigusers);
    }
    else {
        result = sigusers;
    }
    return result;
}
export function getUserTimeZone() {
    return encodeURIComponent(moment.tz.guess(true));
}
