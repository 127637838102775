import * as React from "react";
import "react-bootstrap-table/css/react-bootstrap-table.css";
import { ISSRUserModel, IUserBaseModel, IUserModel, IParterUserModel } from "../../Core/ViewModels/User/UserViewModel";
import { IDocumentAccess } from "../common/TaxReturn";
import * as UserHelper from "../helper/UserHelperFunctions";
import {
    ICompanySettings,
    TaxSoftwareType,
    IReturnAccessSettingsModel
} from "../../Core/ViewModels/Company/CompanySettingsViewModel";
import { ProSystemGroupedDocumentModal } from "./UploadDocument/TaxSoftwareTypeUpload/GroupedReturn/ProSystemGroupedDocumentModal";
import { UltraTaxGroupedDocumentModal } from "./UploadDocument/TaxSoftwareTypeUpload/GroupedReturn/UltraTaxGroupedDocumentModal";
import { GoSystemGroupedDocumentModal } from "./UploadDocument/TaxSoftwareTypeUpload/GroupedReturn/GoSystemGroupedDocumentModal";
import { LacerteGroupedDocumentModal } from "./UploadDocument/TaxSoftwareTypeUpload/GroupedReturn/LacerteGroupedDocumentModal";
import { IGroupAccess } from "../GroupedReturns/GroupConfiguration";
import { IGroupInfo } from "../../store/groupedreturns/GroupedReturnProcessState";
import { IUserProfile } from "../navigation/profile/ProfileObjects";
import { IClientManagementSetting } from "../../store/company/SuiteClientManagementSettingStore";


interface UploadModalProps {
    taxSoftware: TaxSoftwareType;
    onShow: boolean;
    onHide(): void;
    users?: IUserModel[];
    selectedERO: number;
    isRedirect?: boolean;
    processTaxReturn: (url: string, callback: () => void) => void;
    getUploadLink: (url: string, callback?: () => void, resourceId?: string) => void;
    submitTaxReturn: (url: string, taxData: string, callback: () => void, resourceId?: string) => void;
    onPageReload: () => void;
    selectedGroup: string;
    groupInfo: IGroupInfo[];
    groupAccess: IGroupAccess[];
    updateGroupInfoState: (reload: boolean) => void;
    returnAccessSettings?: IReturnAccessSettingsModel;
    userProfile?: IUserProfile;
    parentResourceIdentifier: string;
    ssrUsers: ISSRUserModel[];
    clientManagementSetting: IClientManagementSetting;
    requestClientManagementSetting: () => void;
}
export interface UploadModelstate {
    EROs: IParterUserModel[];
    defaultERO: number;
    Groups: IGroupInfo[];
    defaultGroup: string;
    GroupAccess: IGroupAccess[];
    ssrAvailableUsers: ISSRUserModel[];
}

export interface TaxReturnUploadData {
    number?: number;
    clientName?: string;
    name?: string;
    progressBar?: number;
    size?: string;
    status?: string;
    clientId?: string;
    partnerId?: number;
    setAccess?: string;
    sasGuid?: string;
    gridRowDisable: boolean;
    documentAccess: IDocumentAccess;
}

export class UploadDocumentModal extends React.Component<UploadModalProps, UploadModelstate> {
    constructor(props: UploadModalProps) {
        super(props);
        this.state = {
            defaultERO: 0,
            EROs: [],
            Groups: [],
            defaultGroup: "",
            GroupAccess: [],
            ssrAvailableUsers: []
        };
        this.handleClose = this.handleClose.bind(this);
    }

    UNSAFE_componentWillReceiveProps(nextProps: UploadModalProps) {
        let partners = UserHelper.getPartnerUsers();
        this.setState({
            EROs: partners,
            Groups: nextProps.groupInfo && nextProps.groupInfo.length > 0 ? nextProps.groupInfo : this.state.Groups,
            defaultGroup: this.props.selectedGroup,
            GroupAccess: this.props.groupAccess,
            defaultERO: this.props.selectedERO,
            ssrAvailableUsers: this.props.ssrUsers
        });
    }

    componentDidMount() {
        this.getAvailableUsers();
    }

    private validateTaxReturnUploadData = (uploadData: TaxReturnUploadData[]): boolean => {
        let tempPartnerId: number = 0;
        let valid: boolean = true;
        uploadData.map((value) => {
            tempPartnerId = value.partnerId == undefined ? 0 : value.partnerId;
            if (tempPartnerId < 1) {
                valid = false;
                return;
            }
        });
        return valid;
    };

    getAvailableUsers() {
        this.setState({
            ssrAvailableUsers: this.props.ssrUsers
        });
    }

    private handleClose() {
        this.props.onHide();
    }

    public render() {
        return (
            <div>
                {this.props.onShow && this.props.taxSoftware == TaxSoftwareType.ProSystems && (
                    <ProSystemGroupedDocumentModal
                        parentResourceIdentifier={this.props.parentResourceIdentifier}
                        onShow={this.props.onShow && this.props.taxSoftware == TaxSoftwareType.ProSystems}
                        onHide={this.handleClose}
                        defaultERO={this.state.defaultERO}
                        Groups={this.state.Groups}
                        defaultGroup={this.state.defaultGroup}
                        routing={this.props.isRedirect}
                        getUploadLink={this.props.getUploadLink}
                        processTaxReturn={this.props.processTaxReturn}
                        submitTaxReturn={this.props.submitTaxReturn}
                        onPageReload={this.props.onPageReload}
                        GroupAccess={this.state.GroupAccess}
                        updateGroupInfoState={this.props.updateGroupInfoState}                       
                        returnAccessSettings={this.props.returnAccessSettings}
                        userProfile={this.props.userProfile}
                        ssrAvailableUsers={this.state.ssrAvailableUsers}
                        clientManagementSetting={this.props.clientManagementSetting}
                        requestClientManagementSetting={this.props.requestClientManagementSetting}
                    />
                )}

                {this.props.onShow && this.props.taxSoftware == TaxSoftwareType.UltraTax && (
                    <UltraTaxGroupedDocumentModal
                        parentResourceIdentifier={this.props.parentResourceIdentifier}
                        onShow={this.props.onShow && this.props.taxSoftware == TaxSoftwareType.UltraTax}
                        onHide={this.handleClose}
                        defaultERO={this.state.defaultERO}
                        Groups={this.state.Groups}
                        defaultGroup={this.state.defaultGroup}
                        getUploadLink={this.props.getUploadLink}
                        submitTaxReturn={this.props.submitTaxReturn}
                        onPageReload={this.props.onPageReload}
                        GroupAccess={this.state.GroupAccess}
                        updateGroupInfoState={this.props.updateGroupInfoState}
                        returnAccessSettings={this.props.returnAccessSettings}
                        userProfile={this.props.userProfile}
                        ssrAvailableUsers={this.state.ssrAvailableUsers}
                    />
                )}

                {this.props.onShow && this.props.taxSoftware == TaxSoftwareType.GoSystem && (
                    <GoSystemGroupedDocumentModal
                        parentResourceIdentifier={this.props.parentResourceIdentifier}
                        onShow={this.props.onShow && this.props.taxSoftware == TaxSoftwareType.GoSystem}
                        onHide={this.handleClose}
                        defaultERO={this.state.defaultERO}
                        getUploadLink={this.props.getUploadLink}
                        submitTaxReturn={this.props.submitTaxReturn}
                        onPageReload={this.props.onPageReload}
                        Groups={this.state.Groups}
                        defaultGroup={this.state.defaultGroup}
                        GroupAccess={this.state.GroupAccess}
                        updateGroupInfoState={this.props.updateGroupInfoState}
                        returnAccessSettings={this.props.returnAccessSettings}
                        userProfile={this.props.userProfile}
                        ssrAvailableUsers={this.state.ssrAvailableUsers}
                    />
                )}

                {this.props.onShow && this.props.taxSoftware == TaxSoftwareType.Lacerte && (
                    <LacerteGroupedDocumentModal
                        parentResourceIdentifier={this.props.parentResourceIdentifier}
                        onShow={this.props.onShow && this.props.taxSoftware == TaxSoftwareType.Lacerte}
                        onHide={this.handleClose}
                        defaultERO={this.state.defaultERO}
                        getUploadLink={this.props.getUploadLink}
                        submitTaxReturn={this.props.submitTaxReturn}
                        onPageReload={this.props.onPageReload}
                        Groups={this.state.Groups}
                        defaultGroup={this.state.defaultGroup}
                        GroupAccess={this.state.GroupAccess}
                        updateGroupInfoState={this.props.updateGroupInfoState}
                        returnAccessSettings={this.props.returnAccessSettings}
                        userProfile={this.props.userProfile}
                        ssrAvailableUsers={this.state.ssrAvailableUsers}
                    />
                )}
            </div>
        );
    }
}
export default UploadDocumentModal;
