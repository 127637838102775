import * as React from 'react'
import { Modal, Button } from 'react-bootstrap';
import K1Partners from './K1Partners/K1Partners';
import { IK1PartnerViewModel } from './../../../store/reports/K1DeliveredStatusReport/K1DeliveredStatusState';
import { Loader, LoadingOverlay } from 'react-overlay-loader';

interface K1PartnersModalProps {
    k1Partners: IK1PartnerViewModel;
    isLoading: boolean;
    requestK1Partners: (query: string) => void;
    openPartnerModal: boolean;
    currentDocument: number;
    onCloseModal: () => void;
    onExportToExcel: (query: string, callback: () => void, resourceId:string) => void;
    clearK1Partner: () => void;
    sendReminderToPartner: (partnerIds: number[], documentId: number, callback?: () => void, resourceId: string) => void;
    parentResourceIdentifier: string;
}
interface K1PartnersModalState {
    modalLoader: boolean;
}

export default class K1PartnersModal extends React.Component<K1PartnersModalProps, K1PartnersModalState>{
    state = {
        modalLoader: false
    }
    private toggleLoader = (toggleValue: boolean): void => {
        this.setState({
            modalLoader: toggleValue
        });
    }
    render() {
        const { openPartnerModal, currentDocument, isLoading, k1Partners, onCloseModal, requestK1Partners } = this.props;
        return (
            <div>
                <Modal show={openPartnerModal} onHide={onCloseModal} dialogClassName="viewWidth90" className="" size='lg' >
                    <Modal.Header closeButton>
                        <Modal.Title className='custom-modal-header'>
                            <span className='fas fas fa-users-cog' style={{ color: 'grey', marginRight: '5px' }}></span>
                            K1 Partners
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <LoadingOverlay>
                            <K1Partners
                                currentDocument={currentDocument}
                                isLoading={isLoading}
                                k1Partners={k1Partners}
                                requestK1Partners={requestK1Partners}
                                onExportToExcel={this.props.onExportToExcel}
                                clearK1Partner={this.props.clearK1Partner}
                                sendReminderToPartner={this.props.sendReminderToPartner}
                                modalLoader={this.toggleLoader}
                                parentResourceIdentifier={this.props.parentResourceIdentifier}
                            />
                            <Loader loading={this.state.modalLoader} />
                        </LoadingOverlay>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant='default'
                            onClick={onCloseModal}
                        >
                            <i className='fas fa-times'></i>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}
