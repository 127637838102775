import React from "react";
import { ITaxReturn } from "../../TaxReturn";
import { Link } from "react-router-dom";
import { IOnehubAccessCodeModel, OnehubAccessCodeStatus } from "../../OnehubAccessCodeModal";
import { Alert } from "react-bootstrap";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { OverlayLoader } from "../../../helper/OverlayLoader";
import { AccessCodeConstants } from "../../../helper/Constants";
import { InfoIcon } from "src/assets/images/viewAccessCode/InfoIcon";
import { SuccessTickIcon } from "src/assets/images/viewAccessCode/SuccessTickIcon";
import moment from "moment";

interface IOnehubAccessCodeProps {
    taxReturn: ITaxReturn;
    oneHubAccessModel: IOnehubAccessCodeModel[];
    show: boolean;
    onGenerateOnehubAccessCode(primaryEmailAddress: string, callback: () => void): void;
}

interface IOnehubAccessCodeState {
    message: string;
    hideInfo: boolean;
}

interface IColumnValues {
    clientName: string;
    clientEmailId: string;
    otpDate: string;
    accessCode: string;
    status: OnehubAccessCodeStatus;
}

const msg = {
    saving: "Generating OTP...",
    loading: "Loading, please wait..."
};

export class OnehubAccessCode extends React.Component<IOnehubAccessCodeProps, IOnehubAccessCodeState> {
    constructor(props: IOnehubAccessCodeProps) {
        super(props);
        this.state = {
            message: msg.loading,
            hideInfo: false
        };
    }

    componentDidMount() {
        this.setState({ hideInfo: false });
    }

    private createdOnDataFormat = (cell: any, row: IColumnValues) => {
        if (row.status === OnehubAccessCodeStatus.Active) {
            return moment.utc(row.otpDate).local().format("MM/DD/YYYY");
        } else {
            return "NA";
        }
    };

    private getAccessCodeDataType = (cell: any, row: IColumnValues) => {
        if (row.status === OnehubAccessCodeStatus.Active) {
            return row.accessCode;
        } else {
            return "NA";
        }
    };

    private showOnehubAccessCodeStatus = (cell: any, row: IColumnValues) => {
        if (row.status === OnehubAccessCodeStatus.Active) {
            return (
                <>
                    <span className="active" title="Active">
                        <span className="status-text">Active</span>
                    </span>
                </>
            );
        } else if (row.status === OnehubAccessCodeStatus.Authenticated) {
            return (
                <>
                    <span className="authenticated" title="Authenticated">
                        <span className="status-text">Authenticated</span>
                    </span>
                </>
            );
        } else if (row.status === OnehubAccessCodeStatus.Expired) {
            return (
                <>
                    <span className="expired" title="Expired">
                        <span className="status-text">Expired</span>
                    </span>
                </>
            );
        } else {
            return (
                <>
                    <span className="notRequested" title="Not Requested">
                        <span className="status-text">Not Requested</span>
                    </span>
                </>
            );
        }
    };

    private generateOnehubAccess(cell: any, row: IColumnValues) {
        return (
            <Link
                to={"#"}
                onClick={() => {
                    this.OnGenerateOnehubAccessCode(row);
                }}
            >
                {" "}
                {AccessCodeConstants.AccessCode.GenerateAccessCodeToolTip}
            </Link>
        );
    }

    private OnGenerateOnehubAccessCode(row: IColumnValues) {
        this.setState({ message: msg.saving, hideInfo: true }, () => {
            this.props.onGenerateOnehubAccessCode(row.clientEmailId, () => {
                this.setState({ hideInfo: true });
            });
        });
    }

    private ellipsisType(cell: any) {
        return (
            <div title={cell} className="custom-ellipsis">
                {cell}
            </div>
        );
    }

    public render() {
        let oneHubViewAccessCodeData: any[] = [];
        const oneHubAccessCodeColumn = [
            {
                header: "Client Name",
                key: "clientName",
                isKey: false,
                dataFormat: this.ellipsisType,
                toolTip: true,
                width: "100px",
                columnClassName: ""
            },
            {
                header: "Client Email ID",
                key: "clientEmailId",
                isKey: false,
                dataFormat: this.ellipsisType,
                toolTip: true,
                width: "150px",
                columnClassName: ""
            },
            {
                header: "Date",
                key: "otpDate",
                isKey: true,
                dataFormat: this.createdOnDataFormat,
                toolTip: true,
                width: "100px",
                columnClassName: ""
            },
            {
                header: "Access Code",
                key: "accessCode",
                isKey: false,
                dataFormat: this.getAccessCodeDataType,
                toolTip: true,
                width: "120px",
                columnClassName: ""
            },
            {
                header: "Status",
                key: "status",
                isKey: false,
                toolTip: true,
                dataFormat: this.showOnehubAccessCodeStatus,
                width: "130px",
                columnClassName: ""
            },
            {
                header: "Action",
                key: "action",
                isKey: false,
                dataFormat: this.generateOnehubAccess.bind(this),
                width: "170px",
                toolTip: AccessCodeConstants.AccessCode.GenerateAccessCodeToolTip,
                columnClassName: ""
            }
        ];

        if (this.props.oneHubAccessModel && this.props.oneHubAccessModel?.length > 0) {
            oneHubViewAccessCodeData = this.props.oneHubAccessModel.map((value) => {
                return {
                    clientName: value.clientName,
                    clientEmailId: value.clientEmail,
                    otpDate: !value.otpDate ? "" : value.otpDate,
                    accessCode: !value.accessCode ? "" : value.accessCode,
                    status: value.accessCodeStatus
                };
            });
        }

        return this.props.oneHubAccessModel && this.props.oneHubAccessModel?.length > 0 ? (
            <>
                <Alert variant="success" hidden={!this.state.hideInfo}>
                    <SuccessTickIcon />
                    <span className="toaster-message">{AccessCodeConstants.AccessCode.Success}</span>
                </Alert>

                <Alert variant="info" hidden={this.state.hideInfo}>
                    <InfoIcon />
                    <span className="toaster-message">{AccessCodeConstants.AccessCode.Info}</span>
                </Alert>
                <div className="onehub-access-code-table" style={{ marginTop: "25px" }}>
                    <BootstrapTable headerStyle={{ background: "lightgray" }} data={oneHubViewAccessCodeData}>
                        {oneHubAccessCodeColumn.map((value, index) => {
                            return (
                                <TableHeaderColumn
                                    key={index}
                                    isKey={value.isKey}
                                    dataField={value.key}
                                    dataFormat={value.dataFormat}
                                    className={value.columnClassName}
                                    columnTitle={value.toolTip}
                                    width={value.width}
                                >
                                    {value.header}
                                </TableHeaderColumn>
                            );
                        })}
                    </BootstrapTable>
                </div>
            </>
        ) : (
            <OverlayLoader show={this.props.show} text={this.state.message} width="94%" />
        );
    }
}

export default OnehubAccessCode;
