import * as React from 'react';
export interface LogoImageProps {
    height?: number;
    width?: number;
    className?: string;
};

export const DeleteDollarZero: React.FC<LogoImageProps> = (props) => {
    return <svg xmlns="http://www.w3.org/2000/svg" width={props.width || 100} height={props.height || 100} className={props.className} viewBox="0 0 100 100">
        <path fill="#333333" d="M17.669,27.607v-7.113c3.92,0.646,6.777,2.297,6.817,2.321l4.725-5.337 c-0.229-0.136-4.729-3.189-11.542-4.035V6.988h-4.094v6.264c-5.37,0.296-12.466,3.424-12.468,10.984 c-0.004,7.254,7.017,8.645,12.468,9.529v9.321c-4.966-0.743-9.762-3.744-9.812-3.775L0,45.22c0.293,0.188,6.652,4.19,13.575,4.922 v6.449h4.094v-6.427c6.699-0.618,12.798-4.367,12.966-10.987C30.856,30.472,23.784,28.707,17.669,27.607z M13.576,26.846 c-2.644-0.581-4.534-1.375-4.562-3.216c-0.044-2.829,2.916-3.338,4.562-3.405V26.846z M17.669,43.1v-8.611 c4.463,0.935,5.959,2.016,5.959,4.69C23.629,41.333,20.593,42.67,17.669,43.1z" />
        <path fill="#0055A3" d="M54.999,80c0-4.081,0.976-8.011,2.81-11.529c-3.152-4.455-4.081-12.816-4.081-22.406 c0-14.263,2.631-25.825,10.427-25.825c7.699,0,10.429,11.563,10.429,25.825c0,3.4-0.112,6.643-0.396,9.617 C76.069,55.234,78.017,55,80,55c3.834,0,7.534,0.861,10.886,2.485c0.374-3.318,0.557-7.027,0.557-11.226 c0-9.082-1.061-16.342-3.243-22.194c-4.146-11.121-13.133-17.76-24.045-17.76c-10.964,0-19.97,6.653-24.093,17.795 c-2.149,5.808-3.193,13.056-3.193,22.159c0,9.372,0.922,16.346,2.901,21.946c2.896,8.191,8.505,13.968,15.678,16.525 C55.153,83.188,54.999,81.605,54.999,80z" />
        <circle fill="#E80505" cx="80" cy="80" r="20.001" />
        <circle fill="#E80505" cx="80" cy="80" r="20.001" />
        <path fill="#FFFFFF" d="M93,81.333C92.999,82.807,92.17,84,91.142,84H68.857C67.829,83.999,67,82.806,67,81.333v-2.666 C67,77.193,67.83,76,68.857,76h22.284C92.17,76,93,77.194,93,78.667V81.333z" />
        <ellipse fill="#0055A3" cx="64" cy="47" rx="6.5" ry="8.5" />
    </svg>;
}


export const VoucherEstimatedPayment: React.FC<LogoImageProps> = (props) => {
    return <svg xmlns="http://www.w3.org/2000/svg" width={props.width || 525.029} height={props.height || 525} className={props.className} viewBox="0 0 525.029 525">
        <path fill="#39B54A" d="M467.779,0h-412.5C24.224,0-0.971,25.195,0.029,56.25v412.5c-1,31.055,24.195,56.25,55.25,56.25h412.5 c31.055,0,56.25-25.195,57.25-56.25V56.25C524.029,25.195,498.833,0,467.779,0z M221.923,168.754c0,10.313-8.438,18.75-18.75,18.75 l-66.888-0.004V225h38.067c10.312,0,18.75,8.438,18.75,18.75v37.5c0,10.313-8.438,18.75-18.75,18.75h-38.067v37.5l66.888-0.004 c10.312,0,18.75,8.438,18.75,18.75v37.5c0,10.313-8.438,18.75-18.75,18.75L80.036,412.5c-10.313,0-18.75-8.438-18.75-18.75v-262.5 c0-10.313,8.438-18.75,18.75-18.75l123.138,0.004c10.312,0,18.75,8.438,18.75,18.75V168.754z M362.272,413.667H273.72h-0.07 c-10.312,0-18.75-8.438-18.75-18.75v-37.5c0-10.313,8.438-18.75,18.75-18.75h0.07h88.552c20.625,0,37.5,1.125,37.5-19.5 c0-13.719-10.47-19.926-21.538-20.539l-46.796-2.293c-53.007-5.705-89.538-34.969-89.538-90.5c0-61.992,43.508-94.5,105.5-94.5 h88.552h0.07c10.313,0,18.75,8.438,18.75,18.75v37.5c0,10.313-8.438,18.75-18.75,18.75h-0.07H347.4c-20.625,0-37.5-1.125-37.5,19.5 c0,13.718,10.47,19.926,21.538,20.539l46.796,2.292c53.008,5.706,89.538,34.97,89.538,90.501 C467.772,381.159,424.264,413.667,362.272,413.667z" />
    </svg>;
}

export const VoucherPayment: React.FC<LogoImageProps> = (props) => {
    return <svg xmlns="http://www.w3.org/2000/svg" width={props.width || 525.003} height={props.height || 525} className={props.className} viewBox="0 0 525.003 525">
        <path fill="#0071BC" d="M-1027.039,853.093v-68.971h-75h-11.943c-8.131-0.695-16.596-1-25.286-1h-42.398h-13.262 c-0.558-1.654-0.86-3.776-0.86-6.5c0-20.625,16.875-31.5,37.5-31.5h28.393h27.857h75v-56.25c0-10.313-8.438-18.75-18.75-18.75 h-112.5c-61.992,0-112.5,44.508-112.5,106.5c0,2.596,0.096,5.111,0.271,7.563v73.938h75h11.812h2.812c7.306,0.68,14.867,1,22.606,1 h15.128h41.122h0.23c0.022,0.48,0.041,0.97,0.041,1.5c0,20.625-16.875,34.5-37.5,34.5h-44.438h-11.812h-75v56.25 c0,10.313,8.438,18.75,18.75,18.75h112.5c61.992,0,112.5-47.508,112.5-109.5C-1026.769,858.029-1026.865,855.525-1027.039,853.093z" />
        <path fill="#0071BC" d="M277.878,187.5h-56.25v75h56.25c20.625,0,37.5-16.875,37.5-37.5S298.503,187.5,277.878,187.5z" />
        <path fill="#0071BC" d="M468.753,0H56.25C25.195,0,0,25.195,0,56.25v412.5C0,499.805,25.195,525,56.25,525h412.503 c31.055,0,56.25-25.195,56.25-56.25V56.25C525.003,25.195,499.808,0,468.753,0z M277.878,337.5h-56.25v56.25 c0,10.311-8.439,18.75-18.752,18.75h-37.501c-10.313,0-18.75-8.439-18.75-18.75v-262.5c0-10.313,8.438-18.75,18.75-18.75h112.503 c61.992,0,112.5,50.508,112.5,112.5S339.87,337.5,277.878,337.5z" />
    </svg>;
}

export const SvgIconContactHeader: React.FC<LogoImageProps> = (props) => {
    return <svg xmlns="http://www.w3.org/2000/svg" width={props.width || 24} height={props.height || 24} className={props.className} viewBox="0 0 24 24">
        <path fill="#FFFFFF" d="M12.053,1.448c0,0,4.402,0.202,4.402,4.552S10.643,10.35,12,10.35c1.357,0-4.35,0-4.35-4.35 S12.053,1.448,12.053,1.448z M12.15,15.15c4.966,0,10.2,3.285,10.2,4.725v2.475H1.95v-2.475C1.95,18.436,7.184,15.15,12.15,15.15z M6,6c0,3.315,2.685,6,6,6c3.314,0,6-2.685,6-6s-2.686-6-6-6C8.685,0,6,2.685,6,6z M12,13.5c-4.005,0-12,2.01-12,6V24h24v-4.5 C24,15.51,16.005,13.5,12,13.5z"/>
    </svg>;
}

export const StripeLogo: React.FC<LogoImageProps> = (props) => {
    return (
        <svg className={props.className} xmlns="http://www.w3.org/2000/svg" width="48" height="20" viewBox="0 0 48 20" fill="none">
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M48 10.3338C48 6.91592 46.3467 4.21899 43.1867 4.21899C40.0133 4.21899 38.0933 6.91592 38.0933 10.3071C38.0933 14.3258 40.36 16.3552 43.6133 16.3552C45.2 16.3552 46.4 15.9947 47.3067 15.4874V12.8171C46.4 13.2711 45.36 13.5514 44.04 13.5514C42.7467 13.5514 41.6 13.0975 41.4533 11.5221H47.9733C47.9733 11.3485 48 10.6542 48 10.3338ZM41.4133 9.06546C41.4133 7.55678 42.3333 6.92928 43.1733 6.92928C43.9867 6.92928 44.8533 7.55678 44.8533 9.06546H41.4133Z"
                fill="white"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M32.9467 4.21899C31.64 4.21899 30.8 4.83315 30.3334 5.26038L30.16 4.43261H27.2267V20L30.56 19.2924L30.5734 15.5141C31.0534 15.8612 31.76 16.3552 32.9334 16.3552C35.3201 16.3552 37.4934 14.4326 37.4934 10.2003C37.4801 6.32847 35.2801 4.21899 32.9467 4.21899ZM32.1467 13.4179C31.36 13.4179 30.8934 13.1376 30.5734 12.7904L30.56 7.83715C30.9067 7.44997 31.3867 7.18295 32.1467 7.18295C33.3601 7.18295 34.2 8.54476 34.2 10.2938C34.2 12.0828 33.3734 13.4179 32.1467 13.4179Z"
                fill="white"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M22.6398 3.43124L25.9865 2.71028V0L22.6398 0.70761V3.43124Z"
                fill="white"
            />
            <path d="M25.9865 4.4458H22.6398V16.128H25.9865V4.4458Z" fill="white" />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M19.0534 5.43385L18.84 4.44587H15.96V16.1281H19.2934V8.21089C20.08 7.18285 21.4134 7.36976 21.8267 7.51663V4.44587C21.4 4.28565 19.84 3.99193 19.0534 5.43385Z"
                fill="white"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12.3867 1.54883L9.13336 2.24309L9.12003 12.9373C9.12003 14.9133 10.6 16.3686 12.5734 16.3686C13.6667 16.3686 14.4667 16.1683 14.9067 15.928V13.2177C14.48 13.3913 12.3734 14.0054 12.3734 12.0295V7.28982H14.9067V4.44603H12.3734L12.3867 1.54883Z"
                fill="white"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M3.37333 7.83709C3.37333 7.3164 3.8 7.11613 4.50667 7.11613C5.52 7.11613 6.8 7.42321 7.81334 7.9706V4.83309C6.70667 4.3925 5.61334 4.21893 4.50667 4.21893C1.8 4.21893 0 5.63415 0 7.9973C0 11.6822 5.06667 11.0948 5.06667 12.6836C5.06667 13.2977 4.53333 13.498 3.78667 13.498C2.68 13.498 1.26667 13.044 0.146666 12.4299V15.6075C1.38667 16.1415 2.64 16.3685 3.78667 16.3685C6.56 16.3685 8.46667 14.9933 8.46667 12.6034C8.45334 8.62481 3.37333 9.33242 3.37333 7.83709Z"
                fill="white"
            />
        </svg>
    );
};

export const ModalCloseIcon: React.FC<LogoImageProps> = (props) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={props.width || 16}
            height={props.height || 16}
            className={props.className}
            viewBox="0 0 16 16"
            fill="none"
        >
            <g clip-path="url(#clip0_1433_425)">
                <path
                    d="M0.292542 0.292166C0.48007 0.104695 0.734378 -0.000620803 0.999542 -0.000620803C1.26471 -0.000620803 1.51901 0.104695 1.70654 0.292166L7.99954 6.58517L14.2925 0.292166C14.3848 0.196655 14.4951 0.120473 14.6171 0.0680643C14.7391 0.0156552 14.8704 -0.011931 15.0031 -0.0130848C15.1359 -0.0142386 15.2676 0.0110631 15.3905 0.061344C15.5134 0.111625 15.625 0.185878 15.7189 0.279771C15.8128 0.373663 15.8871 0.485315 15.9374 0.608212C15.9876 0.731108 16.0129 0.862788 16.0118 0.995567C16.0106 1.12835 15.9831 1.25957 15.9306 1.38157C15.8782 1.50357 15.8021 1.61392 15.7065 1.70617L9.41354 7.99917L15.7065 14.2922C15.8887 14.4808 15.9895 14.7334 15.9872 14.9956C15.9849 15.2578 15.8798 15.5086 15.6944 15.694C15.509 15.8794 15.2581 15.9846 14.9959 15.9868C14.7337 15.9891 14.4811 15.8883 14.2925 15.7062L7.99954 9.41317L1.70654 15.7062C1.51794 15.8883 1.26534 15.9891 1.00314 15.9868C0.740944 15.9846 0.490132 15.8794 0.304724 15.694C0.119316 15.5086 0.0141467 15.2578 0.0118683 14.9956C0.00958985 14.7334 0.110384 14.4808 0.292542 14.2922L6.58554 7.99917L0.292542 1.70617C0.105071 1.51864 -0.000244141 1.26433 -0.000244141 0.999166C-0.000244141 0.734001 0.105071 0.479693 0.292542 0.292166Z"
                    fill="rgba(0, 0, 0, 0.5)"
                />
            </g>
            <defs>
                <clipPath id="clip0_1433_425">
                    <rect width="16" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};
