import { addTask } from 'domain-task';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../../';
import { IArchivedTaxDocument, ArchivedReturnsState, ArchivedReturnsTableModel, IArchivedTaxReturn } from './ArchivedReturnsState';
import {
    ReceiveArchivedTaxDocumentAction, RestoreArchivedTaxDocumentAction, ArchivedTaxReturnSource,
    UpdateArchivedTaxDocumentCustomColumnValueAction, ResetArchivedReturnsAction
} from './ArchivedTaxDocumentStore';
import { actionTypes } from '../../../types/ActionTypes';
import {
    RequestArchivedReturnsAction, ReceiveArchivedReturnsAction,
    ReceiveArchivedReturnsPagesAction, RequestArchivedReturnsPagesAction
} from "./KnownTypes";
import { handleResponse } from '../../Library';
import { StatusType, NotificationAction } from '../../common/NotificationStore';
import { API_BASE_URL } from '../../../utils/constants';

type KnownAction = RequestArchivedReturnsAction | ReceiveArchivedReturnsAction | RestoreArchivedTaxDocumentAction
    | ReceiveArchivedTaxDocumentAction | UpdateArchivedTaxDocumentCustomColumnValueAction | ResetArchivedReturnsAction;

type AllKnownAction =
    RequestArchivedReturnsAction |
    ReceiveArchivedReturnsAction |
    ReceiveArchivedTaxDocumentAction |
    RestoreArchivedTaxDocumentAction |
    ReceiveArchivedReturnsPagesAction |
    RequestArchivedReturnsPagesAction |
    UpdateArchivedTaxDocumentCustomColumnValueAction | NotificationAction;

export const actionCreators = {
    requestArchivedReturns: (query: string, reload: boolean = false): AppThunkAction<AllKnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        let state = getState();
        if (reload || query !== state.archivedReturns.query) {

            let page = state.archivedReturnsPages[query];
            if (!reload && page) {
                dispatch({ type: actionTypes.REQUEST_ARCHIVE_RETURNS, query: query });
                dispatch({ type: actionTypes.RECEIVE_ARCHIVE_RETURNS, query: query, table: page.archivedReturnTableModel });
                return;
            }

            const fetchTask = fetch(API_BASE_URL + 'api/Reports/ArchivedReturns/GetArchivedReturns' + query, {
                method: 'GET',
                credentials: 'include'
            })
                .then(handleResponse)
                .then(response => response as Promise<ArchivedReturnsTableModel>)
                .then(data => {
                    data.documents.forEach((model, i) => {
                        dispatch({ type: actionTypes.RECEIVE_ARCHIVE_TAX_DOCUMENT, id: model.document.id, taxDocument: model.document as IArchivedTaxReturn, source: ArchivedTaxReturnSource.ArchiveReturns });
                    });
                    dispatch({ type: actionTypes.RECEIVE_ARCHIVE_RETURNS, query: query, table: data });
                    dispatch({ type: actionTypes.RECEIVE_ARCHIVE_RETURNS_PAGES, query: query, table: data, totalRowCount: data.count });
                })
                .catch((error) => {
                    const statusMessage: any = error.statusText?.message ?? error.statusText;
                    if (typeof(statusMessage) === "string") {
                        dispatch({ type: actionTypes.NOTIFICATION, statusMessage: statusMessage, statusType: StatusType.Error })
                    }
                });
            addTask(fetchTask);
            dispatch({ type: actionTypes.REQUEST_ARCHIVE_RETURNS, query: query });
            dispatch({ type: actionTypes.REQUEST_ARCHIVE_RETURNS_PAGES, query: query });
        }
    },

    updateTaxDocumentCustomColumnValue: (id: number, customColumn: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: actionTypes.UPDATE_ARCHIVE_DOCUMENT_CUSTOM_COLUMN_VALUE, id: id, customColumn: customColumn });
    },

    exportArchivedReturnsAsExcel: (query: string, callback?: () => void): AppThunkAction<AllKnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(API_BASE_URL + 'api/ExportToExcel/ExportExcelArchiveReturns' + query, { credentials: 'include' })
            .then(response => {
                window.location.href = response.url
                if (callback) {
                    callback();
                }
            }).catch((error) => {
                const statusMessage: any = error.statusText?.message ?? error.statusText;
                if (typeof(statusMessage) === "string") {
                    dispatch({ type: actionTypes.NOTIFICATION, statusMessage: statusMessage, statusType: StatusType.Error })
                }
                if (callback) {
                    callback();
                }
            });
        addTask(fetchTask);
    },
};

const unloadedState: ArchivedReturnsState = {
    archivedReturnTableModel: {
        documents: [],
        count: 0
    } as ArchivedReturnsTableModel,
    loading: true,
    query: '',
    totalRowCount: 0
} as ArchivedReturnsState;

const unloadedDeliveredTaxDocument: IArchivedTaxDocument = {
    customColumn: '',
    document: {},
    downloadsCount: 0,
    lastReminderOn: undefined,
    retentionPeriod: undefined,
    signedCount: 0,
    bulkDownloadCount: 0,
} as IArchivedTaxDocument;

export const reducer: Reducer<ArchivedReturnsState> = (state = unloadedState, incomingAction) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case actionTypes.REQUEST_ARCHIVE_RETURNS:
            return ({
                ...unloadedState,
                query: action.query,
                loading: true
            }) as ArchivedReturnsState;
        case actionTypes.RECEIVE_ARCHIVE_RETURNS:
            return {
                query: action.query,
                archivedReturnTableModel: action.table,
                totalRowCount: action.table.count,
                loading: false
            } as ArchivedReturnsState;

        case actionTypes.RECEIVE_ARCHIVE_TAX_DOCUMENT:
        case actionTypes.UPDATE_ARCHIVE_DOCUMENT_CUSTOM_COLUMN_VALUE:
            return updateState(action.type, state, action);

        case actionTypes.RESTORE_ARCHIVE_TAX_DOCUMENT:
        case actionTypes.RESET_ARCHIVE_RETURNS:
            return clearTaxReturns(state);
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            const exhaustiveCheck: never = action;
    }

    return state;
};

function updateState(type: actionTypes, state: ArchivedReturnsState, action: KnownAction): ArchivedReturnsState {
    let i: number = -1;
    let oldDocument: IArchivedTaxDocument = unloadedDeliveredTaxDocument;
    let document: IArchivedTaxReturn = {} as IArchivedTaxReturn;
    let customColumn: string = "";
    let id: number = 0;
    switch (action.type) {
        case actionTypes.RECEIVE_ARCHIVE_TAX_DOCUMENT:
            document = action.taxDocument as IArchivedTaxReturn;
            id = action.id;
            break;
        case actionTypes.UPDATE_ARCHIVE_DOCUMENT_CUSTOM_COLUMN_VALUE:
            customColumn = action.customColumn;
            id = action.id;
            break;
    }
    if (state.archivedReturnTableModel.documents) {
        state.archivedReturnTableModel.documents.forEach((value, index) => {
            if (value.document.id === id) {
                i = index;
                oldDocument = value;
                return;
            }
        });
    }
    if (i !== -1) {
        let archivedTaxDocument: IArchivedTaxDocument = {
            document: action.type == actionTypes.RECEIVE_ARCHIVE_TAX_DOCUMENT ? document : oldDocument.document,
            customColumn: action.type == actionTypes.UPDATE_ARCHIVE_DOCUMENT_CUSTOM_COLUMN_VALUE ? customColumn : oldDocument.customColumn,
            downloadsCount: oldDocument.downloadsCount,
            signedCount: oldDocument.signedCount,
            lastReminderOn: oldDocument.lastReminderOn,
            retentionPeriod: oldDocument.retentionPeriod,
            bulkDownloadCount: oldDocument.bulkDownloadCount
        };

        let documents = [
            ...state.archivedReturnTableModel.documents?.slice(0, i),
            archivedTaxDocument,
            ...state.archivedReturnTableModel.documents?.slice(i + 1)];
        let archivedReturnTableModel: ArchivedReturnsTableModel = {
            count: state.archivedReturnTableModel.count,
            documents: documents
        }

        return {
            query: state.query,
            archivedReturnTableModel: archivedReturnTableModel,
            totalRowCount: state.totalRowCount,
            loading: false
        } as ArchivedReturnsState;
    }
    return state;
}

function clearTaxReturns(state: ArchivedReturnsState): ArchivedReturnsState {
    return {
        ...unloadedState,
        query: '',
        isLoading: true
    } as ArchivedReturnsState;
}