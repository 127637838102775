import * as React from 'react';
import { Button, ButtonGroup, ButtonToolbar } from 'react-bootstrap';
import { DocumentGroups, EngagementType, Arrangement } from '../../TaxReturn';
import { ISelectedPagesDictionary } from '../GroupsView';
import { ReGroupPage } from '../ReGroupPage';

interface GroupToolBarProps {
    onClearSelection: () => void;
    onDelete: () => void;
    onMove: (destinationGroup: DocumentGroups) => void;
    selectedPages: ISelectedPagesDictionary
    engagementType: EngagementType;
    arrangement: Arrangement;
    isEnableInvoice?: boolean;
}
const NO_INDEX = -1;
export class GroupToolBar extends React.Component<GroupToolBarProps, {}> {
    public render() {
        let selectedGroup = Object.keys(this.props.selectedPages).map((key: any) => this.props.selectedPages[key].source); 

        return (<ButtonToolbar className="group-toolbar">

            <ButtonGroup className="float-left">
                <Button title="Clear Selection"
                    variant="default"
                    className="btn-white btn-only" onClick={this.props.onClearSelection} >
                    <span className='fas fa-times'></span>
                    <span className="pages-selected">{Object.keys(this.props.selectedPages).length} Selected</span>
                </Button>
            </ButtonGroup>

            <ButtonGroup className="float-right">
                <ReGroupPage selectedGroup={selectedGroup}
                    engagementType={this.props.engagementType}
                    arrangement={this.props.arrangement}
                    onRegroup={this.props.onMove}
                    isEnableInvoice={this.props.isEnableInvoice} />
            </ButtonGroup>
            {selectedGroup.findIndex(x => x === DocumentGroups.Deleted) == NO_INDEX &&
                <ButtonGroup className="float-right">
                <Button
                    variant="default"
                    className="btn-white btn-only" title="Delete selected pages." onClick={this.props.onDelete}>
                    <span className='fas fa-trash-alt'></span> Delete
                 </Button>
                </ButtonGroup>
            }
        </ButtonToolbar>);
    }
}