import * as React from 'react';
import {
    FormGroup, Accordion, FormControl, FormLabel,
    Col, Card
} from 'react-bootstrap';
import { IOptions, PaperFileUploadData } from '../PaperFile/PaperFile';
import { DropdownComponent } from '../../../../components/common/controls/DropdownComponent';
import { ITaxReturn } from '../../../common/TaxReturn';


export interface IPaperFileInfoProps {
    authority: IOptions[];
    updateTaxDocument: (taxDocument: ITaxReturn) => void;
    taxReturn: ITaxReturn;
    selectedPaperFile: PaperFileUploadData;
    onAddressChange: (event: any) => void;
    onAuthorityChange: (fileName: string, value: any) => void;
    handleBlurAddress: (fileName: string, event: any) => void;
    handleBlurFormName: (fileName: string, event: any) => void;
    onFormNameChange: (event: any) => void;
}

export interface IPaperFileInfoState {
    open: boolean;
}

export class PaperFileInfo extends React.Component<IPaperFileInfoProps, IPaperFileInfoState> {
    constructor(props: IPaperFileInfoProps) {
        super(props);
        this.state = {
            open: true
        }
    }

    public render() {
        let icon = this.state.open ? "fas fa-minus" : "fas fa-plus";
        return (
            <Accordion defaultActiveKey="0">
                <Card>
                    <Accordion.Toggle eventKey="0">
                        <Card.Header style={{ padding: "10px" }}
                            onClick={() => this.setState({ open: !this.state.open })}>
                            <Card.Title as="h3" style={{ marginBottom: '0px' }}>
                                <i className={icon}></i> Authority Info
                            </Card.Title>
                        </Card.Header>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                        <Card.Body>
                            <FormGroup className="row">
                                <Col className="text-left padT08" sm={4} as={FormLabel}>
                                    Form Name
                            </Col>
                                <Col sm={8}>
                                    <FormControl
                                        type="text"
                                        value={this.props.selectedPaperFile.formName}
                                        onChange={this.props.onFormNameChange}
                                        placeholder="Form Name"
                                        data-test-auto="3415B86F-F190-4660-AA2E-87BAD55204BD"
                                        onBlur={this.props.handleBlurFormName.bind(this, this.props.selectedPaperFile.fileName)}
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup className="row">
                                <Col className="text-left padT08" sm={4} as={FormLabel}>
                                    Authority
                            </Col>
                                <Col sm={8}>
                                    <DropdownComponent
                                        id="ddlAuthority"
                                        options={this.props.authority}
                                        selectedValue={this.props.selectedPaperFile.authorityId}
                                        onChange={this.props.onAuthorityChange.bind(this, this.props.selectedPaperFile.fileName)}
                                        customPlaceHolder="Select Authority"
                                    />

                                </Col>
                            </FormGroup>
                            <FormGroup className="row">
                                <Col className="text-left" sm={4} as={FormLabel}>
                                    Mailing Address
                                </Col>
                                <Col sm={8}>
                                    <textarea
                                        className="form-control"
                                        rows={2}
                                        placeholder="Mailing Address"
                                        data-test-auto="2ABAA964-56A8-409E-8EEB-CA6FE8D475A4"
                                        value={this.props.selectedPaperFile.mailingAddress}
                                        onChange={this.props.onAddressChange}
                                        onBlur={this.props.handleBlurAddress.bind(this, this.props.selectedPaperFile.fileName)}
                                    />
                                </Col>
                            </FormGroup>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        )

        //return <Panel defaultExpanded onToggle={() => this.setState({ open: !this.state.open })}>
        //    <Panel.Heading style={{ padding: '10px' }}>
        //        <Panel.Title componentClass="h3" toggle>
        //            <i className={icon}></i> Authority Info
        //        </Panel.Title>
        //    </Panel.Heading>
        //</Panel>;
    }
}
export default PaperFileInfo;