import * as React from "react";
import { RouteComponentProps } from "react-router";
import { ClientInstructionHeader } from "./ClientInstructions/ClientInstructionHeader";
import * as ClientMessageStore from "../../store/settings/ClientMessageStore";
import * as EmailMessageStore from "../../store/settings/EmailMessageStore";
import * as CompanyStore from "../../store/company/CompanyStore";
import * as SSRSettingsStore from "../../store/company/settings/SSRSettingsStore";
import * as ReminderStore from "../../store/common/ReminderStore";
import * as UserSettingsStore from "../../store/userManagement/UserSettingStore";
import * as ApplicationHelptextStore from "../../store/common/ApplicationHelptextStore";
import { ClientInstructionReturns } from "./ClientInstructionReturns";
import { ClientInstructionGroupReturns } from "./ClientInstructionGroupReturns";

export interface ITabs {
    returnsTab: boolean;
    groupReturnsTab: boolean;
}

const defaultTabsState: ITabs = {
    returnsTab: true,
    groupReturnsTab: false
};

export interface ClientInstructionState {
    tabs: ITabs;
}

type ClientInstructionProps = {
    clientMessageList: ClientMessageStore.IClientMessageStoreState;
    emailMessageList: EmailMessageStore.IEmailMessageStoreState;
    companyData: CompanyStore.ICompanyData;
    helptexts: ApplicationHelptextStore.IHelptextState;
} & typeof ClientMessageStore.ActionCreators &
    typeof EmailMessageStore.ActionCreators &
    typeof CompanyStore.actionCreators &
    typeof UserSettingsStore.actionCreators &
    typeof ApplicationHelptextStore.actionCreators &
    typeof ReminderStore.actionCreators &
    typeof SSRSettingsStore.actionCreators &
    RouteComponentProps<{}>;

export class ClientInstruction extends React.Component<ClientInstructionProps, ClientInstructionState> {
    state: ClientInstructionState = {
        tabs: defaultTabsState
    };

    showTab = (id: string) => {
        const newTabs = { ...this.state.tabs };
        for (const tab in this.state.tabs) {
            tab === id ? (newTabs[tab] = true) : (newTabs[tab] = false);
        }
        this.setState({ tabs: newTabs });
    };

    handleTabClick = (e: any) => {
        this.showTab(e.target.id);
    };

    public render() {
        return (
            <div style={{ marginTop: "20px" }} className="font14">
                <ClientInstructionHeader helptexts={this.props.helptexts.helpTexts} />

                <ul id="leftAlignedTabHeader" className="leftAlignedTabHeader">
                    <li
                        id="returnsTab"
                        data-test-auto="1de9ab26-7934-49f7-a612-66514885afa6"
                        className={`returnsTab${this.state.tabs.returnsTab ? " tabSelected" : ""}`}
                        onClick={this.handleTabClick}
                    >
                        Returns
                    </li>
                    <li
                        id="groupReturnsTab"
                        data-test-auto="189f6422-5688-4c6d-8395-66b4f9dc56ac"
                        className={`groupReturnsTab${this.state.tabs.groupReturnsTab ? " tabSelected" : ""}`}
                        onClick={this.handleTabClick}
                    >
                        Group&nbsp;Returns
                    </li>
                </ul>

                {this.state.tabs.returnsTab ? (
                    <ClientInstructionReturns {...this.props} />
                ) : (
                    <ClientInstructionGroupReturns {...this.props} />
                )}
            </div>
        );
    }
}
