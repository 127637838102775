import * as React from 'react';
import { TaxpayerAuthenticationType, ICompanySettings } from '../../../Core/ViewModels/Company/CompanySettingsViewModel';
import Radio from '../../common/Radio';
import { RadioButtonComponent } from '../../common/RadioButtonComponent';

interface ITaxPayerAuthentication {
    companySettings: ICompanySettings;
    handleFirst4SSN(event: any): void;
    handleLast4SSN(event: any): void;
}
export class TaxpayerAuthenticationTypes extends React.Component<ITaxPayerAuthentication, {}>{
    state = {
        Last4ofSSN: "Last4ofSSN",
        First4ofSSN: "First4ofSSN"
    }
    public render() {
        return <div className="new-main-content">
            <h3>Taxpayer Authentication</h3>
            <div className="marL20">
                <div className="div-height">
                    <RadioButtonComponent
                        id={"LastSSN"}
                        text={"Last 4 of SSN"}
                        checked={this.props.companySettings.deliverySettingsModel.taxpayerAuthentication == this.state.Last4ofSSN}
                        onChange={this.props.handleLast4SSN}
                        value={this.state.Last4ofSSN}
                        dataTestAuto={"5FD24C1F-0BBF-4ABF-BDA4-BFD171EE10BE"}
                    />

                </div>
                <div>
                    <RadioButtonComponent
                        id={"FirstSSN"}
                        text={"First 4 of SSN"}
                        checked={this.props.companySettings.deliverySettingsModel.taxpayerAuthentication == this.state.First4ofSSN}
                        onChange={this.props.handleFirst4SSN}
                        value={this.state.First4ofSSN}
                        dataTestAuto={"84447BD9-88F8-4118-A9C4-4B738E3C0922"}
                    />

                </div>
            </div>
        </div>
    }
}

export default TaxpayerAuthenticationTypes;