export enum actionTypes {
    REQUEST_USER_ASSIGNMENTS,
    RECEIVE_USER_ASSIGNMENTS,

    REQUEST_COMPANY_ASSIGNMENTS,
    RECEIVE_COMPANY_ASSIGNMENTS,

    REQUEST_USER_ASSIGNMENTS_PAGES,
    RECEIVE_USER_ASSIGNMENTS_PAGES,

    REQUEST_COMPANY_ASSIGNMENTS_PAGES,
    RECEIVE_COMPANY_ASSIGNMENTS_PAGES,
    UPDATE_CLIENT_INFO,

    REQUEST_USER_PROFILE,
    REQUEST_USER_DETAILS,
    RECEIVE_USER_PROFILE,
    UPDATE_USER_PROFILE,
    UPDATE_USER_DETAILS,
    UPDATE_USER_PASSWORD,
    RECEIVE_USER_DETAILS,

    RECEIVE_COMPANY_LOGO,
    RECEIVE_COMPANY_PROFILE,
    UPDATE_COMPANY_PROFILE,
    UPDATE_COMPANY_SIGNATURE,
    UPDATE_COMPANY_LOGO,
    DELETE_COMPANY_LOGO,

    UPDATE_COMPANY_WHITE_LOGO,
    RECEIVE_COMPANY_WHITE_LOGO,
    DELETE_COMPANY_WHITE_LOGO,

    REQUEST_DELIVERED_RETURNS,
    RECEIVE_DELIVERED_RETURNS,
    RESET_DELIVERED_RETURNS,

    REQUEST_DELIVERED_RETURNS_PAGES,
    RECEIVE_DELIVERED_RETURNS_PAGES,

    REQUEST_QUICK_REPORTS,
    RECEIVE_QUICK_REPORTS,

    REQUEST_QUICK_REPORTS_PAGES,
    RECEIVE_QUICK_REPORTS_PAGES,

    REQUEST_TAX_DOCUMENT,
    RECEIVE_TAX_DOCUMENT,
    RECEIVE_TAX_DOCUMENTS,
    UPDATE_TAX_DOCUMENT,
    SAVE_TAX_DOCUMENT,
    DELETE_TAX_DOCUMENT,
    DELETE_DELIVERED_TAX_DOCUMENT,
    CLEAR_TAX_DOCUMENT,
    ARCHIVE_TAX_DOCUMENT,
    ASSIGN_TAX_DOCUMENT,
    UPDATE_TAX_DOCUMENT_ASSIGNED_TO,
    REQUEST_TAX_DOCUMENT_CLIENT_TRACKING,
    RECEIVE_TAX_DOCUMENT_CLIENT_TRACKING,
    REQUEST_TAX_DOCUMENT_SIGNED_DETAILS,
    RECEIVE_TAX_DOCUMENT_SIGNED_DETAILS,
    REQUEST_TAX_DOCUMENT_ACCESS_CODE,
    RECEIVE_TAX_DOCUMENT_ACCESS_CODE,
    REQUEST_TAX_DOCUMENT_DOWNLOADABLE_DOCUMENTS,
    RECEIVE_TAX_DOCUMENT_DOWNLOADABLE_DOCUMENTS,
    REQUEST_TAX_DOCUMENT_DOWNLOADABLE_EFILE_FORMS,
    RECEIVE_TAX_DOCUMENT_DOWNLOADABLE_EFILE_FORMS,
    REQUEST_TAX_DOCUMENT_DOWNLOAD_HISTORY,
    RECEIVE_TAX_DOCUMENT_DOWNLOAD_HISTORY,
    DELIVER_TAX_DOCUMENT,
    DELIVER_TAX_DOCUMENT_TO_CONTROLLER,
    RECALL_TAX_DOCUMENT,
    SEND_FOR_REVIEW_TAX_DOCUMENT,
    APPROVE_FOR_DELIVERY_TAX_DOCUMENT,
    SEND_TO_ERO_TAX_DOCUMENT,
    SEND_REMINDER_TAX_DOCUMENT,
    ERROR_TAX_DOCUMENT,
    GENERATE_OTP_TAX_DOCUMENT,
    UPDATE_DOCUMENT_CUSTOM_COLUMN_VALUE,
    RESET_TAX_RETURNS,
    RESET_TAX_DOCUMENT,
    RESET_GROUPED_TAX_DOCUMENT,
    UPDATE_DOCUMENT_INITIAL_STATUS,
    NOTIFY_TAX_DOCUMENT,
    REQUEST_EXPIRE_DOCUMENT_COUNT,
    RECEIVE_EXPIRE_DOCUMENT_COUNT,
    ERROR_EXPIRE_TAX_DOCUMENT_COUNT,
    SET_MISSED_RECOGNIZED_EVENT_FOR_TAX_DOCUMENT,
    RESET_MISSED_RECOGNIZED_EVENT_FOR_TAX_DOCUMENT,

    REQUEST_USER_LIST,
    RECEIVE_USER_LIST,
    REQUEST_PARTNER_USER_LIST,
    REQUEST_USER_RESOURCEID_LIST,
    RECEIVE_USER_RESOURCEID_LIST,
    RECEIVE_DEPENDENT_USER_LIST,
    REQUEST_USER_SETTINGS,
    RECEIVE_USER_SETTINGS,
    REQUEST_MAILING_ADDRESS,
    RECEIVE_MAILING_ADDRESS,
    RECEIVE_DELEGATED_SIGNERS,
    ERROR_USER_SETTINGS,
    RECEIVE_USER_DOWNLOAD_SIGNATURE,
    ERROR_USER_DOWNLOAD_SIGNATURE,
    REQUEST_USER_NOTIFICATION_SETTINGS,
    RECEIVE_USER_NOTIFICATION_SETTINGS,
    ERROR_USER_NOTIFICATION_SETTINGS,

    SAVE_USER,
    DELETE_USER,
    UPDATE_USER,
    UPDATE_DEFAULT_CONTACT,
    ERROR_USER_MANAGEMENT,

    REQUEST_CUSTOM_COLUMN_SETTINGS,
    RECEIVE_CUSTOM_COLUMN_SETTINGS,
    REQUEST_UPDATE_CUSTOM_COLUMN,
    REQUEST_ADD_CUSTOM_COLUMN_VALUE,
    RECEIVE_ADDED_CUSTOM_COLUMN_VALUE,
    REQUEST_UPDATE_CUSTOM_COLUMN_VALUE,
    RECEIVE_UPDATED_CUSTOM_COLUMN_VALUE,
    DELETE_CUSTOM_COLUMN_HEADER,
    DELETE_CUSTOM_COLUMN_VALUES,
    ERROR_CUSTOM_COLUMN,
    ERROR_GENERAL_SETTINGS,

    RECEIVE_ADDED_DOCUMENT_TYPE_VALUE,
    REQUEST_DOCUMENT_TYPE_SETTINGS,
    RECEIVE_DOCUMENT_TYPE_SETTINGS,
    ERROR_DOCUMENT_TYPE,
    DELETE_DOCUMENT_TYPE_VALUES,
    RECEIVE_UPDATED_DOCUMENT_TYPE_VALUE,

    REQUEST_REPORT_FILTER,
    RECEIVE_REPORT_FILTER,
    UPDATE_REPORT_FILTER,
    SAVE_REPORT_FILTER,
    DELETE_REPORT_FILTER,
    ERROR_REPORT_FILTER,
    ADD_DEFAULT_FILTER,
    REMOVE_DEFAULT_FILTER,

    REQUEST_PDF_OBJECT,
    RECEIVE_PDF_OBJECT,
    ERROR_PDF_OBJECT,
    RESET_PDF_OBJECTS,

    REQUEST_COMPANY_SETTINGS,
    RECEIVE_COMPANY_SETTINGS,
    ERROR_COMPANY_SETTINGS,
    UPDATE_COMPANY_SETTINGS,
    UPDATE_COMPANY_SETTINGS_LOCAL,

    REQUEST_TAX_AUTHORITIES,
    RECEIVE_TAX_AUTHORITIES,

    REQUEST_AUTHORITY_RELATED_TAX_DOCUMENT_IDS,

    REQUEST_ARCHIVE_RETURNS,
    RECEIVE_ARCHIVE_RETURNS,
    RESET_ARCHIVE_RETURNS,

    REQUEST_ARCHIVE_RETURNS_PAGES,
    RECEIVE_ARCHIVE_RETURNS_PAGES,

    REQUEST_ARCHIVE_TAX_DOCUMENT,
    RECEIVE_ARCHIVE_TAX_DOCUMENT,
    RESTORE_ARCHIVE_TAX_DOCUMENT,
    REQUEST_ARCHIVE_TAX_DOCUMENT_CLIENT_TRACKING,
    RECEIVE_ARCHIVE_TAX_DOCUMENT_CLIENT_TRACKING,
    REQUEST_ARCHIVE_TAX_DOCUMENT_DOWNLOADABLE_DOCUMENTS,
    RECEIVE_ARCHIVE_TAX_DOCUMENT_DOWNLOADABLE_DOCUMENTS,
    REQUEST_ARCHIVE_TAX_DOCUMENT_DOWNLOADABLE_EFILE_FORMS,
    RECEIVE_ARCHIVE_TAX_DOCUMENT_DOWNLOADABLE_EFILE_FORMS,
    ERROR_ARCHIVE_TAX_DOCUMENT,
    RECEIVE_ARCHIVED_TAX_DOCUMENT_DOWNLOAD_HISTORY,
    REQUEST_ARCHIVED_TAX_DOCUMENT_DOWNLOAD_HISTORY,
    UPDATE_ARCHIVE_DOCUMENT_CUSTOM_COLUMN_VALUE,
    REQUEST_ARCHIVED_TAX_DOCUMENT_SIGNED_DETAILS,
    RECEIVE_ARCHIVED_TAX_DOCUMENT_SIGNED_DETAILS,

    REQUEST_MANUAL_ADDRESS_LIST,
    RECEIVE_MANUAL_ADDRESS_LIST,
    ERROR_MANUAL_ADDRESS_LIST,
    REQUEST_UPDATE_MANUAL_ADDRESS,
    RECEIVE_UPDATED_MANUAL_ADDRESS,
    ADD_MANUAL_ADDRESS,
    DELETE_MANUAL_ADDRESS,
    CLEAR_MANUAL_ADDRESS_STATUS,

    NOTIFICATION,

    REQUEST_SAVED_MESSAGES,
    RECEIVE_SAVED_MESSAGES,
    DELETE_SAVED_MESSAGE,

    REQUEST_CLIENT_MESSAGES,
    RECEIVE_CLIENT_MESSAGES,
    DELETE_CLIENT_MESSAGE,

    REQUEST_WELCOME_MESSAGES,
    RECEIVE_WELCOME_MESSAGES,
    DELETE_WELCOME_MESSAGE,

    REQUEST_EMAIL_MESSAGES,
    RECEIVE_EMAIL_MESSAGES,
    DELETE_EMAIL_MESSAGE,
    REQUEST_START_IPRESTRICTION,
    RECEIVE_CURRENT_IPADDRESS,

    REQUEST_IPRESTRICTION_LIST,
    RECEIVE_IPRESTRICTION_LIST,
    ERROR_IPRESTRICTION,
    RESET_IPRESTRICTION,
    HIDE_LOADING_IPRESTRICTION,
    ADD_ROW_CHANGE_IPRESTRICTION,
    EDIT_ROW_CHANGE_IPRESTRICTION,
    SET_EDIT_ROW_IPRESTRICTION,

    REQUEST_INUSE_RETURNS,
    RECEIVE_INUSE_RETURNS,

    RECEIVE_INUSE_RETURNS_PAGES,
    REQUEST_INUSE_RETURNS_PAGES,
    MAKE_AVAILABLE_INUSE_TAX_DOCUMENT,

    REQUEST_RECYCLE_RETURNS,
    RECEIVE_RECYCLE_RETURNS,

    RECEIVE_RECYCLE_RETURNS_PAGES,
    REQUEST_RECYCLE_RETURNS_PAGES,

    REQUEST_VOUCHER_REMINDER_REPORT,
    RECEIVE_VOUCHER_REMINDER_REPORT,

    GET_ARCHIVED_REMINDER_REPORT_END_DATE,

    RECEIVE_VOUCHER_REMINDER_REPORT_PAGES,
    REQUEST_VOUCHER_REMINDER_REPORT_PAGES,

    REQUEST_ATTACHMENTS,
    RECEIVE_ATTACHMENTS,

    REQUEST_TAXPAYERVIEW_LOADER,

    K1_INSTRUCTION_DETAIL,

    REQUEST_DOWNLOAD_ATTACHMENTS,
    RECEIVE_DOWNLOAD_ATTACHMENTS,
    REQUEST_DOWNLOAD_K1DOCUMENT_LINK_ASYNC,
    RECEIVE_DOWNLOAD_K1DOCUMENT_LINK_ASYNC,

    SET_VOUCHER_DUE_DATE_REMINDER,
    ERROR_SET_REMINDER,
    REQUEST_TAXCADDY_LOOKUP_DETAILS,
    CLEAR_TAXCADDY_LOOKUP_DETAILS,

    RECEIVE_VOUCHER_DUE_DATE_LIST,
    RECEIVE_PAYMENT_URL_INFO_LIST,
    RECEIVE_VOUCHER_FORM_NAME_LIST,

    RECEIVE_K1DOCUMENT_SETTINGS,

    REQUEST_BUSINESS_RETURN_INSTRUCTIONS,
    RECEIVE_BUSINESS_RETURN_INSTRUCTIONS,
    REPLACE_BUSINESS_RETURN_INSTRUCTION_START,
    REPLACE_BUSINESS_RETURN_INSTRUCTION_FINISH,

    RECEIVE_NEXT_SIGNER_DETAILS,

    UNLOCK_LOCKED_DOCUMENT,
    UNLOCK_LOCKED_GROUPEDRETURN_DOCUMENT,
    REQUEST_TAX_RETURN,
    RECEIVE_TAX_RETURN_LINK,
    PROCESS_TAX_RETURN,
    UPDATE_TAX_RETURN,

    REQUEST_ASSIGN_USER,
    RECEIVE_ASSIGN_USER,

    REQUEST_USER_ACCESSIBLE_PRODUCT,
    RECEIVE_USER_ACCESSIBLE_PRODUCT,

    REQUEST_USER_LEFT_MENU,
    RECEIVE_USER_LEFT_MENU,

    RECEIVE_TAX_DOCUMENT_STATUS,

    REQUEST_USER_LOGIN_HISTORY,
    RECEIVE_USER_LOGIN_HISTORY,

    RECEIVE_COMPANY_LEDGER,
    REQUEST_COMPANY_LEDGER,
    VALIDATE_AD_AZURE_GROUP,
    REFRESH_TAX_DOCUMENT_STORE,
    CHECK_TAXDOCUMENT_EXIST,

    REQUEST_K1_DELIVEREDSTATUS_DOCUMENTS,
    RECEIVE_K1_DELIVEREDSTATUS_DOCUMENTS,
    REQUEST_K1_PARTNERS,
    RECEIVE_K1_PARTNERS,
    CLEAR_K1_PARTNERS,
    UPDATE_DOCUMENTORDER,

    REQUEST_ATTACHMENT_INSTRUCTIONS,
    RECEIVE_ATTACHMENT_INSTRUCTION,
    RECEIVE_ATTACHMENT_INSTRUCTIONS,
    DELETE_ATTACHMENT_INSTRUCTION,

    REQUEST_ADDITIONAL_ESIGN_DOCUMENT,
    RECEIVE_ADDITIONAL_ESIGN_DOCUMENT_LINK,

    RECEIVE_INBOX_MESSAGES,
    UPDATE_INBOX_MESSAGE,
    INBOX_MESSAGE_ARRIVAL,
    REQUEST_INBOX_MESSAGE_CLEARALL,
    RESPONSE_INBOX_MESSAGE_CLEARALL,

    // SF

    REQUEST_SIGNATURE_FLOW_SETTINGS,
    RECEIVE_SIGNATURE_FLOW_SETTINGS,
    ERROR_SIGNATURE_FLOW_SETTINGS,

    RECEIVE_SIGNATURE_FLOW_USER_DELEGATION,
    RECEIVE_SIGNATURE_FLOW_USER_DELEGATEE,

    RECEIVE_SIGNATURE_FLOW_DOCUMENT_TYPES,
    RESET_SF_SETTINGS_DATA,
    RECEIVE_SIGNATURE_FLOW_REPORT_SIGNERS,

    REQUEST_SF_USER_LIST,
    RECEIVE_SF_USER_LIST,
    ERROR_SF_USER_MANAGEMENT,
    RECEIVE_SSR_USER_LIST,
    RECEIVE_COLUMN_SETTINGS,
    RECEIVE_SSR_USERS,

    RECEIVE_DELIVERED_RETURN_COLUMN_SETTINGS,

    REQUEST_MFA_SETTING,
    RECEIVE_MFA_SETTING,
    RECEIVE_MFAIPADDRESS_LIST,
    REQUEST_MFAIPADDRESS_LIST,
    ADD_MFAIPADDRESS_STATE,
    UPDATE_MFAIPADDRESS_STATE,
    RECEIVE_MFAUSER_LIST,
    REQUEST_MFAUSER_LIST,
    UPDATE_MFA_SETTINGS,

    REQUEST_ONETIMEPASSWORD,
    RECEIVE_ONETIMEPASSWORD,
    VALIDATE_ONETIMEPASSWORD,

    REQUEST_WATERMARK,
    RECEIVE_WATERMARK,
    UPDATE_WATERMARK,
    DELETE_WATERMARK,
    ADD_WATERMARK,
    RECEIVE_SAMPLE_PDF_OBJECT,
    // My download List(Bulk operation)
    REQUEST_MY_DOWNLOADS_LIST,
    RECEIVE_MY_DOWNLOADS_LIST,

    //Grouped Returns
    REQUEST_ADD_GROUP_NAME,
    RECEIVE_ADDED_GROUP_NAME,
    REQUEST_GROUP_NAME,
    RECEIVE_GROUP_NAME,
    ERROR_GROUP_NAME,
    REQUEST_MAP_RETURN_GROUP,
    REQUEST_GROUP_INFO,
    RECEIVE_GROUP_INFO,
    RESET_GROUP_INFO,
    DELETE_GROUP_INFO,

    REQUEST_SEND_GROUPED_RETURNS_PAGES,
    RECEIVE_SEND_GROUPED_RETURNS_PAGES,

    REQUEST_SEND_GROUPED_RETURNS,
    RECEIVE_SEND_GROUPED_RETURNS,
    RESET_SEND_GROUPED_RETURNS,

    REQUEST_GROUPED_RETURNS_DOCUMENTS,
    RECEIVE_GROUPED_RETURNS_DOCUMENTS,

    REQUEST_GROUP_ACCESS,
    RECEIVE_GROUP_ACCESS,
    ERROR_GROUP_ACCESS,

    REQUEST_GROUP_LEVEL_DATA,
    RECEIVE_GROUP_LEVEL_DATA,

    REQEUST_GROUPLEVEL_SAVE,
    RECEIVE_GROUPLELVEL_SAVE_RESULT,

    REQUEST_GROUP_ACCESS_BY_GROUP_ID,
    RECEIVE_GROUP_ACCESS_BY_GROUP_ID,

    REQUEST_GROUPED_TAX_DOCUMENT,
    RECEIVE_GROUPED_TAX_DOCUMENT,
    ERROR_GROUPED_TAX_DOCUMENT,

    MARK_AS_READY_FOR_DELIVERY,

    SEND_REMINDER_GROUP_TAX_DOCUMENT,

    REQUEST_PURCHASE_SUMMARY,
    RESPONSE_PURCHASE_SUMMARY,
    FAILURE_PURCHASE_SUMMARY,
    RECEIVE_BILLING_DETAIL,
    RECEIVE_MIN_QUANTITY_FOR_DISCOUNT,
    REQUEST_ACCOUNT_DETAILS,
    RESPONSE_ACCOUNT_DETAILS,

    RECEIVE_FACADE,
    RECEIVE_SUB_DOC_PDF_OBJECT,

    //Organizers
    UPDATE_COMPANY_SETTINGS_ORGANIZER,

    RECEIVE_SELECTED_DOCUMENT_FOR_DELIVERY,
    REMOVE_SELECTED_DOCUMENT_FOR_DELIVERY,

    REQUEST_DELIVERED_GROUPED_RETURNS_PAGES,
    RECEIVE_DELIVERED_GROUPED_RETURNS_PAGES,

    REQUEST_DELIVERED_GROUPED_RETURNS,
    RECEIVE_DELIVERED_GROUPED_RETURNS,
    RESET_DELIVERED_GROUPED_RETURNS,

    REQUEST_RECYCLE_GROUPED_RETURNS_PAGES,
    RECEIVE_RECYCLE_GROUPED_RETURNS_PAGES,

    REQUEST_INUSE_GROUPED_RETURNS,
    RECEIVE_INUSE_GROUPED_RETURNS,

    REQUEST_INUSE_GROUPED_RETURNS_PAGES,
    RECEIVE_INUSE_GROUPED_RETURNS_PAGES,

    REQUEST_RECYCLE_GROUPED_RETURNS,
    RECEIVE_RECYCLE_GROUPED_RETURNS,

    RECEIVE_GROUP_CLIENT_TRACKING,
    ERROR_GROUPED_RETURN,
    DELETE_DELIVERED_GROUP_DOCUMENT,
    RECALL_GROUPED_TAX_DOCUMENT,
    SAVE_GROUP_REMINDER_SETTINGS,
    RESEND_GROUPEDRETURN_ACCESSLINK_SUCCESS,
    RECEIVE_CONTROLLER_ACCESSLINK_SUCCESS,
    RECEIVE_GROUPEDRETURN_DOWNLOADABLE_DOCUMENTS,
    RECEIVE_GROUPEDRETURN_TAX_DOCUMENT,
    RECEIVE_GROUP_OTP_VIEWMODEL,
    REFRESH_GROUPEDRETURN_TAX_DOCUMENT_RECALLACTION,

    UPDATE_SEND_GROUPED_DOCUMENT_STATUS,
    UPDATE_GROUPED_TAX_DOCUMENT_STATUS,
    RECEIVE_GROUPED_TAX_DOCUMENT_STATUS,

    UPDATE_SEND_GROUPED_DOCUMENT_ASSIGN,
    UPDATE_GROUPED_TAX_DOCUMENT_ASSIGN,
    //Extension
    UPDATE_COMPANY_SETTINGS_EXTENSION,

    REQUEST_HELPTEXT,
    RECEIVE_HELPTEXT,

    //Revision Documents
    REQUEST_REVISION_DOCUMENT_LINK,
    RECEIVE_REVISION_DOCUMENT_LINK,

    REQUEST_PAPER_FILE_DOCUMENT,
    RECEIVE_PAPER_FILE_DOCUMENT_LINK,
    REQUEST_PAPERFILED_MESSAGES,
    RECEIVE_PAPERFILED_MESSAGES,
    DELETE_PAPERFILED_MESSAGE,

    UPDATE_REVISED_TAXDOCUMENT_STATUS,
    UPDATE_GROUP_REVISED_TAXDOCUMENT_STATUS,
    RECEIVE_DISCARDED_REVISED_TAXDOCUMENT_STATE,
    RECEIVE_DISCARDED_GROUP_REVISED_TAXDOCUMENT_STATE,
    RECEIVE_UPDATED_REVISED_TAXDOCUMENT_STATUS,
    RECEIVE_UPDATED_GROUP_REVISED_TAXDOCUMENT_STATUS,

    REQUEST_SSO_ACCESS_REQUESTS_LIST,
    RECEIVE_SSO_ACCESS_REQUESTS_LIST,

    REQUEST_GROUPS_AND_ROLE,
    RECEIVE_GROUPS_AND_ROLE,
    UPDATE_ROLE_SETTING,
    UPDATE_GROUP_SETTING,

    RECEIVE_All_PERMISSION,
    REQUEST_K1_ATTACHMENT,
    RECEIVE_K1_ATTACHMENT_LINK,
    RECEIVE_DASHBOARD_LINK,

    REQUEST_SSR_SETTINGS,
    RECEIVE_SSR_SETTINGS,
    ERROR_SSR_SETTINGS,
    UPDATE_SSR_SETTINGS,

    REQUEST_TAXSOFTWARE_SETTING,
    RECEIVE_TAXSOFTWARE_SETTING,

    REQUEST_OFFICE_LOCATION_DROPDOWN,
    RECEIVE_OFFICE_LOCATION_DROPDOWN,

    CCH_SHOW_LOADING_ICON,
    REQUEST_CCH_RETRURNS,
    RECEIVE_CCH_RETURNS,
    RESPONSE_CCH_AUTHENTICATION,
    RESPONSE_CCH_CONNECTIONSTATUS, 
    UPDATE_CCH_GETRETRUNS_JOB_STATUS,

    REQUEST_CCH_UNIT_STATUSES,
    RESPONSE_CCH_UNIT_STATUSES,

    // SMS Notification Details
    RECEIVE_SMS_NOTIFICATION_DETAILS,

    REQUEST_NOTIFICATION_SETTING,
    RECEIVE_NOTIFICATION_SETTING,

    REQUEST_CLIENTMANAGEMENT_SETTING,
    RECEIVE_CLIENTMANAGEMENT_SETTING,

    REQUEST_K1_SSN_DETAILS,
    RECEIVE_K1_SSN_DETAILS,
    UPDATE_K1_SSN_DETAILS,
    REQUEST_PAGEWISE_K1_SSN_DETAILS,
    RECEIVE_PAGEWISE_K1_SSN_DETAILS,
    REMOVE_PAGEWISE_K1_SSN_DETAILS,
    RECEIVE_GROUPEDRETURN_DOCUMENTLEVEL_TAXRETURNS,

    REQUEST_INTERNAL_USER_TRACKING,
    RECEIVE_INTERNAL_USER_TRACKING,

    RECEIVE_CONTROLLER_TRACKING,

    REQEUST_GROUPRETURN_CLIENTINFO,
    RECEIVE_GROUPRETURN_CLIENTINFO_SAVE,

    RECEIVE_USER_ASSIGN_DELIVERY_PERMISSION,

    RECEIVE_EFILE_SETTINGS
}
