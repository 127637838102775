import * as React from 'react';
import { IDefaultSettings, DefaultSenderInfoType, DefaultDownloadInfoType } from './MySettings'
import { ISSRUserModel } from '../../../Core/ViewModels/User/UserViewModel';
import Radio from '../../common/Radio';
import 'react-select/dist/react-select.css';
import { Card, Accordion } from 'react-bootstrap';
import * as MailingReturnAddressStore from '../../../store/common/MailingReturnAddressStore';
import { DropdownComponent } from '../../common/controls/DropdownComponent'
import { TaxSoftwareType, ICompanySettings } from '../../../Core/ViewModels/Company/CompanySettingsViewModel';
import { RadioButtonComponent } from '../../../components/common/RadioButtonComponent';
import { ICustomColumn } from '../../../components/settings/GeneralSettings';
import { IHelptextModel } from '../../../Core/ViewModels/Common/IHelptextModel';
import { fetchHelpText } from '../../helper/HelperFunctions';
import { IsOnlySignatureUser } from '../../../oidcClient/userRoles';
import { IAuthState } from '../../../store/auth/reducer';
import EROSignerDropdown from "../../common/EROSignerDropdown";

interface Props {
    auth: IAuthState;
    DefaultSettings: IDefaultSettings;
    MailingAddressList: MailingReturnAddressStore.IMailingReturnAddressDictionary;
    activeUser: ISSRUserModel[];
    customColumnData: ICustomColumn;
    onChangeMailingReturnAddress(event: any): void;
    onChangeEROStamp(event: any): void;
    onChangeContactPerson(event: any): void;
    onChangeSenderName(event: any): void;
    onChangeNotifySigningEvents(event: any): void;
    onChangeDefaultSender(event: any): void;
    onChangeDefaultDownload(event: any): void;
    onChangeCustomColumnValue(value: any): void;
    helptexts?: IHelptextModel[];
    inactiveUsers: ISSRUserModel[];
}

interface DefaultState {
    customColOptions: any;
    selectedCustomColValue: number;
    DownloadOption: any;
    selectedDownloadOption: DefaultDownloadInfoType;
    helptextMailing: string;
}

export class MyDefaults extends React.Component<Props, DefaultState> {
    isSignatureOnlyUser = IsOnlySignatureUser(this.props.auth.user);
    state: DefaultState = {
        customColOptions: [],
        selectedCustomColValue: 0,
        DownloadOption: [],
        selectedDownloadOption: DefaultDownloadInfoType.PromptMeEveryTime,
        helptextMailing:''
    }

    UNSAFE_componentWillMount() {
        this.setCustomColumnOptions();
        this.SetDownloadOption()
        this.setState({
            helptextMailing: fetchHelpText(this.props.helptexts, "6E1689F1-65F3-4A01-AE76-5DEA7DF7EA0A")
        });
    }

    InactiveUserList() {
        var columnValues: any = [];
        this.props.inactiveUsers.map((person: ISSRUserModel) => {
            columnValues.push({
                value: person.id,
                label: person.firstName + " " + person.lastName,
            })
        });
        return columnValues;
    }

    ActiveUserList() {
        var columnValues: any = [];
        this.props.activeUser.map((person: ISSRUserModel) => {
            columnValues.push({
                value: person.id,
                label: person.firstName + " " + person.lastName,
            })
        });
        return columnValues;
    }

    MailingAddressList() {
        var columnValues: any = [];
        Object.keys(this.props.MailingAddressList).map((Addres, index) => {
            columnValues.push({
                value: this.props.MailingAddressList[parseInt(Addres)].id,
                label: this.props.MailingAddressList[parseInt(Addres)].title,
            })

        });
        return columnValues;
    }

    private setCustomColumnOptions() {
        let _customColOptions: any = [];
        let selectedValue: number = 0;
        Object.keys(this.props.customColumnData.customColumnValues).map((key, index) => {
            let custCol = this.props.customColumnData.customColumnValues[parseInt(key)];
            if (custCol.id == this.props.DefaultSettings.customColumnValue) {
                selectedValue = custCol.id;
            }
            _customColOptions.push({
                value: custCol.id,
                label: custCol.value
            })
        })

        this.setState({
            customColOptions: _customColOptions,
            selectedCustomColValue: selectedValue
        });
        if (selectedValue == 0) {
            this.props.onChangeCustomColumnValue(0);
        }
    }
    private SetDownloadOption() {
        let _DownloadOption: any = [];
        let selectedValue: number = 0;
        

        _DownloadOption.push({
            value: DefaultDownloadInfoType.DownloadNow,
            label: "Download Now"
        })
        _DownloadOption.push({
            value: DefaultDownloadInfoType.DownloadToMyDownloads,
            label: "Download to \"My Downloads\""
        })
        _DownloadOption.push({
            value: DefaultDownloadInfoType.PromptMeEveryTime,
            label: "Prompt me Every Time"

        })
        if (this.props.DefaultSettings.download.rememberDefaultDownload == false) {
            this.setState({
                DownloadOption: _DownloadOption,
                selectedDownloadOption: DefaultDownloadInfoType.PromptMeEveryTime
            });
        }
        else {
            this.setState({
                DownloadOption: _DownloadOption,
                selectedDownloadOption: this.props.DefaultSettings.download.downloadType
            });
        }
        
    }
    private handleCustomColumnChange = (selectedOption: any) => {
        this.props.onChangeCustomColumnValue(selectedOption);
        this.setState({
            selectedCustomColValue: selectedOption
        });
    }
    private handleDefaultDownloadChange = (selectedOption: any) => {
        this.props.onChangeDefaultDownload(selectedOption);
        this.setState({
            selectedDownloadOption: selectedOption
        });
    }
    public render() {
        let customColumnPresent: boolean = true;
        if (this.props.customColumnData == null || this.props.customColumnData.header == "") {
            customColumnPresent = false;
        }
        return (
            <Accordion defaultActiveKey="0">
                <Card id="collapsible-panel-defaults" className="marB20">
                    <Accordion.Toggle eventKey="0">
                        <Card.Header style={{ paddingTop: '8px', paddingBottom: '5px' }}>
                            <Card.Title>
                                <i className='fa fa-star'></i>My Defaults
                            </Card.Title>
                        </Card.Header>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                        <Card.Body>
                            <div className="row marB03">
                                <div className="col-sm-6 col-md-5 col-xl-5">
                                    <div className="marB10 padT07">Default Mailing Return Address:</div>
                                </div>
                                <div className="col-sm-6 col-md-7 col-xl-7 my-def-help-tooltip">
                                    <div className={`padR30 ${(this.isSignatureOnlyUser) ? "disabled" : ""}`}>
                                        <DropdownComponent
                                            id="ddlMailingAddressList"
                                            options={this.MailingAddressList()}
                                            onChange={this.props.onChangeMailingReturnAddress}
                                            selectedValue={this.props.DefaultSettings.manualAddressId}
                                            data-test-auto="63CD1B71-4B4C-4952-9EF2-3F9867BFF8D8"
                                            disabled={this.isSignatureOnlyUser}
                                            className={(this.isSignatureOnlyUser) ? "disabled" : ""}

                                        />
                                    </div>
                                    <a href="#"
                                        className="help-icon my-def-help"
                                        data-toggle="tooltip"
                                        title={this.state.helptextMailing}>
                                        <i className="fas fa-question-circle" />
                                    </a>
                                </div>
                            </div>
                            <div className="row marB03 padT07">
                                <div className="col-sm-6 col-md-5 col-xl-5">
                                    <div className="marB10 padT07">Default ERO/Signer:</div>
                                </div>
                                <div className="col-sm-6 col-md-7 col-xl-7 my-def-help-tooltip">
                                    <div className={`padR30 ${(this.isSignatureOnlyUser) ? "disabled" : ""}`}>
                                    <EROSignerDropdown
                                            id="ddlEROList"
                                            onChange={this.props.onChangeEROStamp.bind(this)}
                                            selectedValue={
                                                this.props.DefaultSettings.eroUser !== 0 ? this.props.DefaultSettings.eroUser : ""
                                            }
                                            testId="A9B8AC8F-6220-4863-865D-A95E7492809F"
                                            disabled={this.isSignatureOnlyUser}
                                            className={this.isSignatureOnlyUser ? "disabled" : ""}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row marB03 padT07">
                                <div className="col-sm-6 col-md-5 col-xl-5">
                                    <div className="marB10 padT07">Default Contact Person:</div>
                                </div>
                                <div className="col-sm-6 col-md-7 col-xl-7 my-def-help-tooltip">
                                    <div className={`padR30 ${(this.isSignatureOnlyUser) ? "disabled" : ""}`} >

                                        <DropdownComponent
                                            id="ddlContactPersonList"
                                            options={this.InactiveUserList()}
                                            onChange={this.props.onChangeContactPerson.bind(this)}
                                            selectedValue={this.props.DefaultSettings.contactPerson}
                                            data-test-auto="D96F597E-56F3-4179-AEAB-A12A8B7308E2"
                                            disabled={this.isSignatureOnlyUser}
                                            className={(this.isSignatureOnlyUser) ? "disabled" : ""}
                                        />
                                    </div>


                                </div>
                            </div>
                            <div className="row marB03 padT07">
                                <div className="col-sm-6 col-md-5 col-xl-5">
                                    <div className="marB10 padT07">Default Sender:</div>
                                </div>
                                <div className={`col-sm-3 col-md-2 col-xl-2 ${(this.isSignatureOnlyUser) ? "disabled" : ""}`}>
                                    <RadioButtonComponent
                                        id={"Company"}
                                        checked={this.props.DefaultSettings.sender.senderType == DefaultSenderInfoType.Company}
                                        onChange={this.props.onChangeDefaultSender}
                                        value={"company"}
                                        dataTestAuto="EDE9EF76-CB65-476D-8CB8-0AE9E815384A"
                                        name="rdbDefaultSender"
                                        text={"Company"}
                                        disabled={this.isSignatureOnlyUser}
                                    />
                                </div>
                                <div className="col-sm-3 col-md-2 col-xl-2">
                                    <RadioButtonComponent
                                        id={"Users"}
                                        text={"Users"}
                                        checked={this.props.DefaultSettings.sender.senderType == DefaultSenderInfoType.User}
                                        onChange={this.props.onChangeDefaultSender}
                                        value={"users"}
                                        dataTestAuto={"ADE9EF76-CB65-476D-8CB8-0AE9E815477A"}
                                        disabled={this.isSignatureOnlyUser}
                                        name="rdbDefaultSender"
                                    />
                                </div>
                            </div>

                            <div className={this.props.DefaultSettings.sender.senderType == DefaultSenderInfoType.Company ? 'hidden' : 'row marB03 padT07'}>
                                <div className="col-sm-6 col-md-5 col-xl-5">
                                    <div className="marB10 padT07">Select Default Sender Name:</div>
                                </div>
                                <div className="col-sm-6 col-md-7 col-xl-7 my-def-help-tooltip">
                                    <div className={`padR30 ${(this.isSignatureOnlyUser) ? "disabled" : ""}`} >
                                        <DropdownComponent
                                            id="ddlDefaultSender"
                                            options={this.InactiveUserList()}
                                            onChange={this.props.onChangeSenderName.bind(this)}
                                            selectedValue={this.props.DefaultSettings.sender.senderId}
                                            data-test-auto="A8E1183B-863F-4649-83BA-787DC6A1FACD"
                                            disabled={this.isSignatureOnlyUser}
                                            className={(this.isSignatureOnlyUser) ? "disabled" : ""}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="row marB03 padT07">
                                <div className="col-sm-6 col-md-5 col-xl-5">
                                    <div className="marB10 padT07">Default Notify About Signing Events:</div>
                                </div>
                                <div className="col-sm-6 col-md-7 col-xl-7 my-def-help-tooltip">
                                    <div className={`padR30 ${(this.isSignatureOnlyUser) ? "disabled" : ""}`}>
                                        <DropdownComponent
                                            id="ddlDefaultSigningEvents"
                                            options={this.ActiveUserList()}
                                            onChange={this.props.onChangeNotifySigningEvents.bind(this)}
                                            selectedValue={this.props.DefaultSettings.notifyUser}
                                            data-test-auto="A97096D1-35C5-4428-AB53-BCBEA46B12DA"
                                            disabled={this.isSignatureOnlyUser}
                                            className={(this.isSignatureOnlyUser) ? "disabled" : ""}
                                        />
                                    </div>
                                </div>
                            </div>
                            {customColumnPresent ?
                                <div className="row marB03 padT07">
                                    <div className="col-sm-6 col-md-5 col-xl-5">
                                        <div className="marB10 padT07">Default Custom Column Value ({this.props.customColumnData.header}):</div>
                                    </div>
                                    <div className="col-sm-6 col-md-7 col-xl-7 my-def-help-tooltip">
                                        <div className={`padR30 ${(this.isSignatureOnlyUser) ? "disabled" : ""}`}>
                                            <DropdownComponent
                                                id="ddlDefaultCustomColumn"
                                                options={this.state.customColOptions}
                                                onChange={this.handleCustomColumnChange}
                                                selectedValue={this.state.selectedCustomColValue}
                                                disabled={this.isSignatureOnlyUser}
                                                className={(this.isSignatureOnlyUser) ? "disabled" : ""}
                                            />
                                        </div>
                                    </div>
                                </div>
                                : null
                            }
                            <div className="row marB03 padT07">
                                <div className="col-sm-6 col-md-5 col-xl-5">
                                    <div className="marB10 padT07">Default Download Option:</div>
                                </div>
                                <div className="col-sm-6 col-md-7 col-xl-7 my-def-help-tooltip">
                                    <div className={`padR30 ${(this.isSignatureOnlyUser) ? "disabled" : ""}`}>
                                        <DropdownComponent
                                            id="DownloadOptionChange"
                                            options={this.state.DownloadOption}
                                            selectedValue={this.state.selectedDownloadOption}
                                            onChange={this.handleDefaultDownloadChange}
                                            data-test-auto="CC1D0849-C531-4A43-8A88-CFB97C57D971"
                                        />
                                    </div>
                                </div>
                            </div>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        )
    }
}

export default MyDefaults;
