import * as React from 'react';
import * as Loadash from 'lodash';
import { Tab, Tabs } from 'react-bootstrap';
import { IInboxMessage, MessageCategory, MessageType, MessageStatus } from '../../Core/ViewModels/User/InboxMessage';
import { TabSupportInbox } from './TabSupportInbox';
import { TabFirmInbox } from './TabFirmInbox';
import * as CRSHelper  from '../helper/CRSHelper'

export interface IInboxProps {
    inboxMessages: IInboxMessage[];
    updateInboxMessage: (inboxMessage: IInboxMessage) => void;
    updateClearAll: (inboxMessages: IInboxMessage[]) => void;
    isLoading: boolean;
}

export interface IInboxState {
    activeCategory: MessageCategory;
}

export class Inbox extends React.Component<IInboxProps, IInboxState>{
    state: IInboxState = {
        activeCategory: MessageCategory.Support
    };

    private handleTabSelect = (activeCategory: any) => {
        this.setState({
            activeCategory: activeCategory
        });
    }

    private markAsRead = (messageId: number) => {
        const messages = Loadash.cloneDeep(this.props.inboxMessages);
        let inboxMessage = messages.find(m => m.id == messageId);
        if (inboxMessage && inboxMessage.messageStatus != MessageStatus.Read) {
            inboxMessage.messageStatus = MessageStatus.Read;
            CRSHelper.encodeInboxMessage(inboxMessage);
            this.props.updateInboxMessage(inboxMessage);
        }
    }

    private clearMessage = (event: any, messageId: number) => {
        event.stopPropagation();
        const messages = Loadash.cloneDeep(this.props.inboxMessages);
        let inboxMessage = messages.find(m => m.id == messageId);
        if (inboxMessage) {
            inboxMessage.messageStatus = MessageStatus.Deleted;
            CRSHelper.encodeInboxMessage(inboxMessage);
            this.props.updateInboxMessage(inboxMessage);
        }
    }

    private onClearAllMessages = (messageType: MessageType) => {
        const messages = this.props.inboxMessages;
        let message = messages.filter(m => m.messageType == messageType);
        if (message) {
            message.map((value, index) => {
                value.messageStatus = MessageStatus.Deleted;
                CRSHelper.encodeInboxMessage(value);
            })
        }
        this.props.updateClearAll(message);
    }

    private unsubscribeNotification = (messageType: MessageType) => {
    }

    private getActiveMessages = (messageCategory: MessageCategory): IInboxMessage[] => {
        const currentDate: number = Date.now().valueOf();
        return this.props.inboxMessages.filter(message => message.messageCategory == messageCategory
            && new Date(message.startDate).valueOf() <= currentDate
            && message.messageStatus != MessageStatus.Deleted);
    }

    render() {
        const supportMessages = this.getActiveMessages(MessageCategory.Support);
        const firmMessages = this.getActiveMessages(MessageCategory.Firm);

        const unreadSupportCount = supportMessages.filter(m => m.messageStatus == MessageStatus.Unread).length;
        const unreadFirmCount = firmMessages.filter(m => m.messageStatus == MessageStatus.Unread).length;

        return <Tabs
            activeKey={this.state.activeCategory}
            onSelect={this.handleTabSelect}
            id="inbox-tab"
            data-testid="inboxTab">

            <Tab eventKey={MessageCategory.Support}
                onClick={(e: React.BaseSyntheticEvent) => { e.stopPropagation(); }}
                title={
                    <div className="text-bold">{"cPaperless "}
                        {
                            unreadSupportCount ? <span className="badge inbox-count">{unreadSupportCount}</span> : ""
                        }
                    </div>
                }
                className="zIndex10">
                <TabSupportInbox
                    inboxMessages={supportMessages}
                    markAsRead={this.markAsRead}
                    clearMessage={this.clearMessage}
                    onClearAllSelection={this.onClearAllMessages}
                    isLoading={this.props.isLoading}
                />
            </Tab>

            {
                false && <Tab eventKey={MessageCategory.Firm}
                    title={
                        <div className="text-bold">{"My Firm "}
                            {
                                unreadFirmCount ? <span className="badge inbox-count">{unreadFirmCount}</span> : ""
                            }
                        </div>
                    }
                    className="zIndex10">
                    <TabFirmInbox
                        inboxMessages={firmMessages}
                        markAsRead={this.markAsRead}
                        clearMessage={this.clearMessage}
                        unsubscribeNotification={this.unsubscribeNotification}
                    />
                </Tab>
            }
        </Tabs>
    }
}
