import * as React from 'react';
import { PDFPageProxy, PDFDocumentProxy, PDFRenderTask } from 'pdfjs-dist';
import { Modal, Row, Col, Button, Container } from 'react-bootstrap';
import { IWatermarkModel, initialWatermarkModel, FontFamily } from './WatermarkSetting';
import { DropdownComponent } from '../../../common/controls/DropdownComponent';
import { watermarkConstants } from '../../../helper/Constants';
import { VenusNotifier } from '../../../helper/VenusNotifier';
import * as Validation from '../../../helper/Validations';
import Moveable from 'react-moveable';
import { Frame } from "scenejs";
import { ref } from "framework-utils";
import { SketchPicker } from 'react-color';
import reactCSS from 'reactcss';

interface IWatermarkState {
    watermarkModel: IWatermarkModel;
    target: any;
    isChange: boolean;
    displayColorPicker: boolean;
    isSetDefault: boolean;
    alert: string;
    message: string;
    isToggled: boolean;
    showAlert: boolean;
}
interface IWatermarkProps {
    showWatermark: boolean;
    onHideWatermarkPopup: () => void;
    saveWatermark(watermarkModel: IWatermarkModel): void;
    updateWatermark(watermarkModel: IWatermarkModel): void;
    sampleDocument: PDFDocumentProxy;
    watermark: IWatermarkModel;
    isUpdate: boolean;
    defaultWatermarkId: number;
}

export class WatermarkProperties extends React.Component<IWatermarkProps, IWatermarkState>{
    renderTask?: PDFRenderTask;
    frame: any;
    moveable: any;

    state: IWatermarkState = {
        watermarkModel: initialWatermarkModel,
        target: null,
        isChange: false,
        displayColorPicker: false,
        isSetDefault: false,
        alert: "warning",
        message: "Watermark",
        isToggled: false,
        showAlert: true
    }

    UNSAFE_componentWillReceiveProps(nextProps: IWatermarkProps) {
        this.setState({
            watermarkModel: Object.assign({}, nextProps.watermark),
            isSetDefault: false,
            isToggled: false
        })
    }

    componentDidMount() {
        const { watermarkModel: { rectWidth, rectHeight, coordinateX, coordinateY, angle } } = this.state

        this.frame = new Frame({
            width: `${rectWidth}px`,
            height: `${rectHeight}px`,
            left: `${coordinateX}px`,
            top: `${coordinateY}px`,
            transform: {
                rotate: `${angle}deg`,
                scaleX: 1,
                scaleY: 1,
                matrix3d: [1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1]
            }
        });

        this.setState({
            target: document.querySelector(".moveableWatermark")
        });
        window.addEventListener("resize", this.onWindowReisze);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.onWindowReisze);
    }

    onSave = () => {
        let temp = { ...this.state.watermarkModel }
        if (this.state.isChange) {
            const newX = temp.coordinateX < 300 ?
                300 + (temp.coordinateX) - (temp.rectWidth / 2) :
                temp.coordinateX - 300 + (temp.rectWidth / 2);
            temp.coordinateX = newX;
        }
        this.setState({
            watermarkModel: temp,
            isChange: false

        }, () => {
            if (this.validateWatermark()) {
                this.props.isUpdate ?
                    this.props.updateWatermark(this.state.watermarkModel) :
                    this.props.saveWatermark(this.state.watermarkModel);
                this.props.onHideWatermarkPopup();
            }
        })

    }

    onWatermarkNameChange = (event: any) => {
        let temp = { ...this.state.watermarkModel }
        temp.name = event.target.value;
        this.setState({
            watermarkModel: temp
        })
    }

    onWatermarkTextChange = (event: any) => {
        let temp = { ...this.state.watermarkModel }
        temp.text = event.target.value;
        this.setState({
            watermarkModel: temp
        })
    }

    onFontSizeChange = (event: any) => {
        let temp = { ...this.state.watermarkModel }
        if (Validation.ValidateTenDigitNumber(event)) {
            temp.fontSize = parseInt(event.target.value);
            this.setState({
                watermarkModel: temp
            })
        }
    }

    onFontNameChange = (value: any) => {
        let temp = { ...this.state.watermarkModel }
        temp.fontFamily = value;
        this.setState({
            watermarkModel: temp
        })
    }

    onFontColorChange = (color: any) => {
        let temp = { ...this.state.watermarkModel }
        temp.fontColor = color.hex;
        this.setState({
            watermarkModel: temp
        })
    }

    onOpacityChange = (value: any) => {
        let temp = { ...this.state.watermarkModel }
        temp.opacity = value;
        this.setState({
            watermarkModel: temp
        })
    }

    validateWatermark = () => {

        if (!Validation.NullandEmptyCheck(this.state.watermarkModel.name)) {
            VenusNotifier.Warning(watermarkConstants.NameWarning, "");
            return false;
        }
        else if (!Validation.NullandEmptyCheck(this.state.watermarkModel.text)) {
            VenusNotifier.Warning(watermarkConstants.TextWarning, "");
            return false;
        }
        else if (!Validation.NullandEmptyCheck(this.state.watermarkModel.fontSize.toString())) {
            VenusNotifier.Warning(watermarkConstants.FontSizeWarning, "");
            return false;
        }
        else if (!Validation.ValidateFontSizeValue(this.state.watermarkModel.fontSize)) {
            VenusNotifier.Warning(watermarkConstants.FontSizeValueWarning, "");
            return false;
        }
        else if (!Validation.NullandEmptyCheck(this.state.watermarkModel.fontFamily)) {
            VenusNotifier.Warning(watermarkConstants.FontFamilyWarning, "");
            return false;
        }
        else if (this.state.watermarkModel.opacity == null) {
            VenusNotifier.Warning(watermarkConstants.OpacityWarning, "");
            return false;
        }
        else {
            return true
        }
    }

    private renderPage = (pdf: PDFDocumentProxy) => {
        pdf.getPage && pdf.getPage(1).then((page: PDFPageProxy) => {
            this.renderPdf(page);
        });
    }

    private getCanvas = () => {
        return document.getElementById("sampleDocumentCanvas");
    }

    private renderPdf = (page: PDFPageProxy) => {
        let scale = 1;
        const viewport = page.getViewport(scale);
        let canvas: any = this.getCanvas();
        if (canvas) {
            var context = canvas.getContext('2d');
            if (context) {
                canvas.height = viewport.height;
                canvas.width = viewport.width;
                if (this.renderTask) {
                    this.renderTask.cancel();
                }
                this.renderTask = page.render({ canvasContext: context, viewport: viewport });
            }
        }
    }

    OpacityList = () => {
        var opacity = [];
        for (let i = 0.1; i <= 1.0;) {
            opacity.push({
                value: Number(i).toFixed(1),
                label: Number(i).toFixed(1).toString()
            })
            i += 0.1;
        }
        return opacity;
    }

    FontFamilyList = () => {
        var fontfamily = [];
        const fontDisplayLimit = 3;
        for (let i = 0; i < fontDisplayLimit; i++) {
            fontfamily.push({
                value: FontFamily[i],
                label: FontFamily[i]
            })
        }
        return fontfamily;
    }

    onCancel = () => {
        this.setState({
            watermarkModel: initialWatermarkModel,
            isSetDefault: false,
            isToggled: false,
        }, () => { this.props.onHideWatermarkPopup() });
    }

    onDrag = ({ target, clientX, clientY, top, left, isPinch }: any) => {
        this.frame.set("left", `${left}px`);
        this.frame.set("top", `${top}px`);
        const waterMark = { ...this.state.watermarkModel }

        waterMark.coordinateX = left;
        waterMark.coordinateY = top;
        this.setState({ watermarkModel: waterMark });
        this.setTransform(target);
    };

    onDragEnd = ({ target, isDrag, clientX, clientY }: any) => {

        let xValue = this.state.watermarkModel.coordinateX;
        let yValue = this.state.watermarkModel.coordinateY;
        let widthValue = this.state.watermarkModel.rectWidth;
        let heightValue = this.state.watermarkModel.rectHeight;
        const waterMark = { ...this.state.watermarkModel }

        if (Math.abs(xValue) + (widthValue / 2) > 300 || yValue < 0 || Math.abs(yValue) + heightValue > 792) {
            if (xValue > 0) {
                const left = (300 - widthValue);
                this.frame.set("left", `${left}px`);
                this.frame.set("top", `${top}px`);
                waterMark.coordinateX = left;
                waterMark.coordinateY = 0;
            } else {
                const left = -(300 - widthValue);
                this.frame.set("left", `${left}px`);
                this.frame.set("top", `${top}px`);
                waterMark.coordinateX = left;
                waterMark.coordinateY = 0;
            }
            this.setState({
                watermarkModel: waterMark,
                isChange: true
            });
        } else {
            this.setState({
                watermarkModel: waterMark,
                isChange: true
            });
        }
        this.setTransform(target);

    };

    setTransform(target: any) {
        target.style.cssText = this.frame.toCSS();
        this.moveable.updateRect();
    }

    onRotate = ({ target, clientX, clientY, beforeDelta, isPinch }: any) => {
        const deg = parseInt(this.frame.get("transform", "rotate")) + parseInt(beforeDelta);
        this.frame.set("transform", "rotate", `${deg}deg`);
        const waterMark = { ...this.state.watermarkModel }
        waterMark.angle = deg;
        this.setState({ watermarkModel: waterMark });
        this.setTransform(target);
    };

    onResize = ({ target, clientX, clientY, width, height, isPinch }: any) => {

        this.frame.set("width", `${width}px`);
        this.frame.set("height", `${height}px`);
        const waterMark = { ...this.state.watermarkModel }
        waterMark.rectWidth = width;
        waterMark.rectHeight = height;
        this.setState({ watermarkModel: waterMark });
        this.setTransform(target);
    };

    onResizeEnd = ({ target, isDrag, clientX, clientY }: any) => {

        const waterMark = { ...this.state.watermarkModel }

        if (waterMark.coordinateX < 300) {
            if (Math.abs(waterMark.coordinateX) + 300 + (waterMark.rectWidth / 2) > 600 || (waterMark.coordinateY + waterMark.rectHeight) > 784) {

                this.frame.set("width", `${300}px`);
                this.frame.set("height", `${100}px`);

                waterMark.rectWidth = 300;
                waterMark.rectHeight = 100;
                this.setState({
                    watermarkModel: waterMark,
                    isChange: true
                });

            } else {
                this.setState({
                    watermarkModel: waterMark,
                    isChange: true
                });
            }
        } else {
            if (Math.abs(waterMark.coordinateX) + (waterMark.rectWidth) > 600 || (waterMark.coordinateY + waterMark.rectHeight) > 784) {

                this.frame.set("left", `${0}px`);
                this.frame.set("top", `${0}px`);
                this.frame.set("width", `${300}px`);
                this.frame.set("height", `${100}px`);

                waterMark.rectWidth = 300;
                waterMark.rectHeight = 100;
                this.setState({
                    watermarkModel: waterMark,
                    isChange: true
                });

            } else {
                this.setState({
                    watermarkModel: waterMark,
                    isChange: true
                });
            }
        }
        this.setTransform(target);
    };

    onWindowReisze = () => {
        this.moveable && this.moveable.updateRect();
    };

    handleClick = () => {
        this.setState({ displayColorPicker: !this.state.displayColorPicker })
    };

    handleClose = (event: any) => {
        this.setState({ displayColorPicker: false })
    };
    private onSetDefaultClick = (isTrue: boolean) => {
        if (isTrue == true) {
            this.setState({ alert: "warning", message: watermarkConstants.SetDefaultWatermarkWarning, showAlert: false });
        }
        else {
            this.setState({ alert: "warning", message: watermarkConstants.RemoveDefaultWatermarkWarning, showAlert: false });
        }
        let temp = { ...this.state.watermarkModel }
        temp.isDefault = isTrue;
        this.setState({
            watermarkModel: temp,
            isSetDefault: isTrue,
            isToggled: true
        });
    }
    render() {
        let toggleState: boolean = this.props.defaultWatermarkId == this.state.watermarkModel?.id && this.props.isUpdate == true && this.state.isToggled == false ?
            true : this.state.isSetDefault == true ? true : false;
        this.renderPage(this.props.sampleDocument)

        const styles = reactCSS({
            'default': {
                color: {
                    width: '275px',
                    height: '25px',
                    borderRadius: '2px',
                    background: `${this.state.watermarkModel.fontColor}`,
                },
                swatch: {
                    padding: '5px',
                    background: '#fff',
                    borderRadius: '1px',
                    boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                    display: 'inline-block',
                    cursor: 'pointer',
                },
                popover: {
                    position: 'absolute',
                    zIndex: '2',
                },
                cover: {
                    position: 'fixed',
                    top: '0px',
                    right: '0px',
                    bottom: '0px',
                    left: '0px',
                },
            },
        });

        return (
            <div>
                <Modal show={this.props.showWatermark} onHide={this.onCancel} size="lg" dialogClassName="watermark-settings"
                    onEntered={() => {
                        if (!this.state.target) {
                            this.setState({
                                target: document.querySelector(".moveableWatermark")
                            }, () => {
                                const { target, watermarkModel: { rectWidth, rectHeight, coordinateX, coordinateY, angle } } = this.state
                                const newX = coordinateX < 300 ? -(300 - coordinateX - (rectWidth / 2)) : coordinateX - 300 + (rectWidth / 2);
                                this.frame = new Frame({
                                    width: `${rectWidth}px`,
                                    height: `${rectHeight}px`,
                                    left: `${newX}px`,
                                    top: `${coordinateY}px`,
                                    transform: {
                                        rotate: `${angle}deg`,
                                        scaleX: 1,
                                        scaleY: 1,
                                        matrix3d: [1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1]
                                    }
                                });
                                this.setTransform(target);

                            });
                        }
                    }}
                    onExit={() => {
                        if (this.state.target) {
                            this.setState({
                                target: null
                            });
                        }
                    }}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Watermark Settings
                    </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Container fluid>
                            <Row>
                                <Col sm={5}>
                                    <h2 style={{ textAlign: "center", color: "black" }}>Selection Panel</h2>

                                    <div>
                                        <Row className="padT05" >
                                            <Col sm={4}>
                                                Name
                                            </Col>
                                            <Col sm={8} className="form-group">
                                                <input id="myWatermarkName"
                                                    value={this.state.watermarkModel.name}
                                                    type="textbox"
                                                    placeholder="Name"
                                                    onChange={this.onWatermarkNameChange}
                                                    className="form-control"
                                                    data-toggle="tooltip" data-placement="right" title="Text for Watermark Name."
                                                    data-test-auto="4A7C0EA9-4CF7-4A73-9008-956DE8AE3E4E"
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="padT10">
                                            <Col sm={4}>
                                                Watermark Text
                                            </Col>
                                            <Col sm={8} className="form-group">
                                                <input id="myText"
                                                    value={this.state.watermarkModel.text}
                                                    type="textbox"
                                                    placeholder="Watermark Text"
                                                    onChange={this.onWatermarkTextChange}
                                                    className="form-control"
                                                    maxLength={100}
                                                    data-toggle="tooltip" data-placement="right" title="Text for watermark."
                                                    date-test-auto="9937FADE-7ADF-4725-9BCA-380706927B92"
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="padT10">
                                            <Col sm={4}>
                                                Font Size
                                            </Col>
                                            <Col sm={8} className="form-group">
                                                <input id="myFontSize"
                                                    value={this.state.watermarkModel.fontSize}
                                                    type="number"
                                                    placeholder="0"
                                                    onChange={this.onFontSizeChange}
                                                    className="form-control"
                                                    maxLength={5}
                                                    data-toggle="tooltip" data-placement="right" title="The size of Font."
                                                    date-test-auto="96B86FA8-30A1-4CE9-8744-09B565167017"
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="padT10">
                                            <Col sm={4}>
                                                Font
                                            </Col>
                                            <Col sm={8} className="form-group">
                                                <DropdownComponent
                                                    className="width100"
                                                    id="ddlFont"
                                                    selectedValue={this.state.watermarkModel.fontFamily}
                                                    onChange={this.onFontNameChange}
                                                    options={this.FontFamilyList()}
                                                    data-toggle="tooltip" data-placement="right"
                                                    data-test-auto="93D8BDE5-0644-4B1E-B4DA-B76C90DD1AE3"
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="padT10">
                                            <Col sm={4}>
                                                Opacity
                                            </Col>
                                            <Col sm={8} className="form-group">
                                                <DropdownComponent
                                                    className="width100"
                                                    id="ddlOpacity"
                                                    selectedValue={this.state.watermarkModel.opacity === 1 ?
                                                        this.state.watermarkModel.opacity.toPrecision(2) : this.state.watermarkModel.opacity}
                                                    onChange={this.onOpacityChange}
                                                    options={this.OpacityList()}
                                                    data-toggle="tooltip" data-placement="right"
                                                    data-test-auto="3359CDA5-0579-4518-9460-C3EAC99EF879"
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="padT10">
                                            <Col sm={4}>
                                                Color
                                            </Col>
                                            <Col sm={8} className="form-group">
                                                <div>
                                                    <div style={styles.swatch} onClick={this.handleClick}>
                                                        <div
                                                            style={styles.color}
                                                            data-toggle="tooltip"
                                                            data-placement="right"
                                                            title="Color of watermark text."
                                                        />
                                                    </div>
                                                    {this.state.displayColorPicker ? <div style={styles.popover as React.CSSProperties}>
                                                        <div style={styles.cover as React.CSSProperties} onClick={this.handleClose} />
                                                        <SketchPicker
                                                            color={this.state.watermarkModel.fontColor}
                                                            onChange={this.onFontColorChange}
                                                            date-test-auto="EC8B6F35-F848-4622-A3BA-951448704E4C"
                                                        />
                                                    </div> : null}

                                                </div>
                                                                                             
                                            </Col>
                                        </Row>
                                        <Row className="padT10">
                                            <Col sm={4}>
                                                Default
                                            </Col>
                                            <Col sm={8} className="form-group">
                                                <label className="switch">
                                                    <input type="checkbox"
                                                        checked={toggleState}
                                                        disabled={this.props.defaultWatermarkId == this.state.watermarkModel?.id && this.props.isUpdate == true ? true : false}
                                                        onChange={(event) => this.onSetDefaultClick(event.target.checked)}
                                                        data-test-auto="5CD4CE93-C1BD-40EE-90C9-4B41D6F0FBBA"
                                                    ></input>
                                                    <span className="slider round"></span>
                                                </label>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                                <Col sm={7}>
                                    <h2 style={{ textAlign: "center", color: "black" }}>Preview Panel</h2>
                                    <div style={{ border: "0px outset black", backgroundColor: "white", textAlign: "center" }}>
                                        <Row>
                                            <Col sm={12}>
                                                <div className="divMainProperty" >
                                                    <div className="divCanvasProperty">
                                                        <canvas id="sampleDocumentCanvas" className="canvasProperty" />

                                                        <Moveable
                                                            ref={(ins: any) => { this.moveable = ins }}
                                                            target={this.state.target}
                                                            draggable={true}
                                                            container={document.querySelector('.divCanvasProperty')}
                                                            resizable={true}
                                                            rotatable={true}
                                                            origin={false}
                                                            onDrag={this.onDrag}
                                                            onDragEnd={this.onDragEnd}
                                                            onResize={this.onResize}
                                                            onResizeEnd={this.onResizeEnd}
                                                            onRotate={this.onRotate}
                                                        />
                                                        <div className="moveableWatermark">
                                                            <span style={{
                                                                fontSize: this.state.watermarkModel.fontSize,
                                                                fontFamily: this.state.watermarkModel.fontFamily,
                                                                fontWeight: "bold",
                                                                opacity: this.state.watermarkModel.opacity,
                                                                color: this.state.watermarkModel.fontColor
                                                            }}>
                                                                {this.state.watermarkModel.text}
                                                            </span>
                                                        </div>


                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                        </Container>

                    </Modal.Body>
                    <Modal.Footer className="clearfix">
                        <Button
                            data-test-auto="117E0887-DE3E-4662-81C5-878416A615CF"
                            variant='default'
                            className='btn-white'
                            onClick={this.onCancel} >
                            <i className='fas fa-times'></i>Cancel
                        </Button>
                        <Button
                            variant='info'
                            onClick={this.onSave}
                            data-test-auto="9B85E724-58CB-4CD3-A376-4049225D5688">
                            <i className='fas fa-save'></i>Save & Close
                        </Button>
                    </Modal.Footer >

                </Modal >
            </div>
        );
    }
}