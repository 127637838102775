import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { ICompanySettings, initialCompanySettings, TaxpayerAuthenticationType } from '../../../Core/ViewModels/Company/CompanySettingsViewModel';
import * as CompanyStore from '../../../store/company/CompanyStore';
import { SettingsHeader } from '../../common/settings/SettingsHeader';
import { OverlayLoader } from '../../helper/OverlayLoader';
import { VenusNotifier } from '../../helper/VenusNotifier';
import { RetentionSettings } from '../SecurityComponents/RetentionSettings';
import { TaxpayerAuthenticationTypes } from '../GeneralSettingsComponents/TaxpayerAuthenticationTypes';
import * as UserSettingsStore from '../../../store/userManagement/UserSettingStore';
import { ReturnAccessSettings } from './ReturnAccessSettings';
import * as ApplicationHelptextStore from '../../../store/common/ApplicationHelptextStore';
import { ScreenShareSettings } from './ScreenShareSettings';
import { CustomRouterPrompt } from '../CustomRouterPrompt';
import { TaxReturnSettingsResources } from '../../../components/helper/ResourceIdConstants';

interface ISecuritySettings {
    companySettings: ICompanySettings,
    expiryDate: Date,
    showPopOver: boolean,
    showCustomPrompt: boolean
}

type SecuritySettingsProps =
    {
        companyData: CompanyStore.ICompanyData,
        helptexts: ApplicationHelptextStore.IHelptextState,
    }
    & typeof CompanyStore.actionCreators
    & typeof UserSettingsStore.actionCreators
    & typeof ApplicationHelptextStore.actionCreators
    & RouteComponentProps<{}>

export class SecuritySettings extends React.Component<SecuritySettingsProps, ISecuritySettings> {
    constructor(props: SecuritySettingsProps) {
        super(props);

        this.handleApplyButton = this.handleApplyButton.bind(this);
        this.handleDefaultRetentionPeriodChange = this.handleDefaultRetentionPeriodChange.bind(this);
        this.handleAttestRetentionPeriodChange = this.handleAttestRetentionPeriodChange.bind(this);

        this.state = {
            companySettings: initialCompanySettings,
            expiryDate: new Date(),
            showPopOver: false,
            showCustomPrompt: false
        }
    }
    componentDidMount() {
        const element = document.getElementById('content-wrapper');
        element && element.scrollTo(0, 0);
    }

    UNSAFE_componentWillMount() {
        this.props.requestCompanySettings(true);
        this.props.requestUserSettings(true);
    }

    private buildQuery = (page: number, pageSize: number) => {
        return '?pageNo=' + page
            + '&pageSize=' + pageSize
            + '&sortBy=' + 'userName'
            + '&sortOrder=' + 'asc'
            + '&searchString=' + ''
    }

    private enableCustomRouterPrompt = () => {
        this.setState({ showCustomPrompt: true });
    }

    private disableCustomRouterPrompt = () => {
        this.setState({ showCustomPrompt: false });
    }

    ////////////////////Settings Header start//////////////////////////
    handleApplyButton(event: any, resourceId?: string) {

        let companySettings = this.props.companyData.companySettings as ICompanySettings
        if (companySettings) {
            if (!companySettings.retentionSettingsModel.retentionPeriod ||
                !companySettings.retentionSettingsModel.attestRetentionPeriod) {
                !companySettings.retentionSettingsModel.retentionPeriod ?
                    VenusNotifier.Warning("Please select a valid value for default retention period.", "") :
                    VenusNotifier.Warning("Please select a valid value for attest retention period.", "")
            }
            else {
                this.props.updateCompanySettings(companySettings, undefined, resourceId);
                this.disableCustomRouterPrompt();
                if (this.state.showPopOver) {
                    this.props.updateRetentionForAllDocuments(companySettings.retentionSettingsModel.retentionPeriod,
                        companySettings.retentionSettingsModel.attestRetentionPeriod);
                    this.props.forceRefreshTaxDcoumentStore(true);
                }
            }
        }
    }
    ////////////////////Settings Header end//////////////////////////

    ///////////////////////////// Return Access Settings Start ///////////////////////////////

    handleSetAccessToEveryoneChange = (event: any) => {
        let temp = this.props.companyData.companySettings as ICompanySettings;
        temp.returnAccessSettings.isSetAccessToEveryone = event.target.checked;
        temp.returnAccessSettings.isSetAccessToIndividual = false;
        temp.returnAccessSettings.isSetAccessToOtherUsers = false;
        temp.returnAccessSettings.isSetAccessToUserGroups= false;
        this.setState({
            companySettings: temp
        })
        this.enableCustomRouterPrompt();
    }

    handleSetAccessToIndividualChange = (event: any) => {
        let temp = this.props.companyData.companySettings as ICompanySettings;
        temp.returnAccessSettings.isSetAccessToIndividual = event.target.checked;
        temp.returnAccessSettings.isSetAccessToEveryone = false;
        temp.returnAccessSettings.isSetAccessToUserGroups= false;
        temp.returnAccessSettings.isSetAccessToOtherUsers = true;

        this.setState({
            companySettings: temp
        })
        this.enableCustomRouterPrompt();
    }

    handleSetAccessToOthersChange = (event: any) => {
        let temp = this.props.companyData.companySettings as ICompanySettings;
        temp.returnAccessSettings.isSetAccessToOtherUsers = event.currentTarget.checked;
        this.setState({
            companySettings: temp
        })
        this.enableCustomRouterPrompt();
    }

    handleSetAccessToUserGroupChange = (event: any) => {
        let temp = this.props.companyData.companySettings as ICompanySettings;
        temp.returnAccessSettings.isSetAccessToUserGroups = event.currentTarget.checked;
        temp.returnAccessSettings.isSetAccessToEveryone = false;
        temp.returnAccessSettings.isSetAccessToIndividual = false;
        temp.returnAccessSettings.isSetAccessToOtherUsers = false;
        this.setState({
            companySettings: temp
        })
        this.enableCustomRouterPrompt();
    }


    ///////////////////////////// Return Access Settings END ///////////////////////////////

    ///////////////////////////// General Settings Start ///////////////////////////////

    updateGeneralSettings = (companySettings: any, resourceId?: string) => {
        let temp = companySettings as ICompanySettings;
            this.props.updateCompanySettings(temp, undefined, resourceId);
    }

    ///////////////////////////// General Settings END /////////////////////////////////

    ///////////////////////////// Taxpayer Authentication Types start /////////////////////////
    handleFirst4SSN = () => {
        let temp = this.props.companyData.companySettings as ICompanySettings;
        temp.deliverySettingsModel.taxpayerAuthentication = TaxpayerAuthenticationType.First4ofSSN;
        this.setState({
            companySettings: temp
        })
        this.enableCustomRouterPrompt();
    }

    handleLast4SSN = () => {
        let temp = this.props.companyData.companySettings as ICompanySettings;
        temp.deliverySettingsModel.taxpayerAuthentication = TaxpayerAuthenticationType.Last4ofSSN;
        this.setState({
            companySettings: temp
        })
        this.enableCustomRouterPrompt();
    }
    ///////////////////////////// Taxpayer Authentication Types end /////////////////////////

    handleDefaultRetentionPeriodChange(value: number) {
        if (value && this.props.companyData.companySettings ? this.props.companyData.companySettings.retentionSettingsModel.attestRetentionPeriod : 0) {
            const date = new Date();
            date.setDate(date.getDate() + 1);
            this.setState({
                expiryDate: date,
                showPopOver: true,
            });
        }

        if (this.state.showPopOver && this.props.companyData.companySettings) {
            if (this.props.companyData.companySettings.retentionSettingsModel.retentionPeriod === 0 || this.props.companyData.companySettings.retentionSettingsModel.attestRetentionPeriod === 0) {
                this.setState({ showPopOver: false });
            }
        }
    }

    handleAttestRetentionPeriodChange(value: number) {
        if (value && this.props.companyData.companySettings ? this.props.companyData.companySettings.retentionSettingsModel.retentionPeriod : 0) {
            const date = new Date();
            date.setDate(date.getDate() + 1);
            this.setState({
                expiryDate: date,
                showPopOver: true,
            });
        }

        if (this.state.showPopOver && this.props.companyData.companySettings) {
            if (this.props.companyData.companySettings.retentionSettingsModel.retentionPeriod === 0 || this.props.companyData.companySettings.retentionSettingsModel.attestRetentionPeriod === 0) {
                this.setState({ showPopOver: false });
            }
        }
    }

    handleDocumentsToExpire = (e: any, callback: (count: number) => void) => {
        if (this.props.companyData.companySettings) {
            this.props.requestDocumentsToexpire(this.props.companyData.companySettings.retentionSettingsModel.retentionPeriod, this.props.companyData.companySettings.retentionSettingsModel.attestRetentionPeriod, callback);
        }
    }

    public render() {

        return <div id="divSecurity" className='company-settings font14'>
            <CustomRouterPrompt
                enabled={this.state.showCustomPrompt}
                history={this.props.history}
            />
            <SettingsHeader
                handleApplyButton={this.handleApplyButton}
                header="Security "
                showPopOver={this.state.showPopOver}
                expiryDate={this.state.expiryDate}
                handleDummyApplyButton={this.handleDocumentsToExpire} 
                parentResourceIdentifier = { TaxReturnSettingsResources.SecurityPrefix + TaxReturnSettingsResources.HeaderPrefix }/>
            <OverlayLoader show={this.props.companyData.isLoading} text={this.props.companyData.message} />
            {this.props.companyData.companySettings != undefined ?
                <div>
                    <ReturnAccessSettings
                        companySettings={this.props.companyData.companySettings}
                        handleSetAccessToEveryoneChange={this.handleSetAccessToEveryoneChange}
                        handleSetAccessToIndividualChange={this.handleSetAccessToIndividualChange}
                        handleSetAccessToOthersChange={this.handleSetAccessToOthersChange}
                        handleSetAccessToUserGroupsChange={this.handleSetAccessToUserGroupChange}
                    />
                    <hr />

                    <ScreenShareSettings
                        companySettings={this.props.companyData.companySettings as ICompanySettings}
                        updateCompanySettings={this.updateGeneralSettings}
                        helptexts = {this.props.helptexts.helpTexts}
                        parentResourceIdentifier = { TaxReturnSettingsResources.SecurityPrefix + TaxReturnSettingsResources.ScreenShare }
                    />
                    <hr />

                    <TaxpayerAuthenticationTypes
                        companySettings={this.props.companyData.companySettings as ICompanySettings}
                        handleFirst4SSN={this.handleFirst4SSN}
                        handleLast4SSN={this.handleLast4SSN} />
                    <hr />
                    <RetentionSettings
                        companySettings={this.props.companyData.companySettings}
                        handleDefaultRetentionDays={this.handleDefaultRetentionPeriodChange}
                        handleAttestRetentionDays={this.handleAttestRetentionPeriodChange}
                        helptexts={this.props.helptexts.helpTexts}
                        enableCustomRouterPrompt={this.enableCustomRouterPrompt}
                    />


                    <hr />
                </div> : ""}
        </div>;
    }
}
