import * as React from 'react';
import { Button } from "react-bootstrap";
import { ImportCCHAxcess } from '../../helper/Constants';
import { CCHReturn } from '@store/reports/CCHReturns/CCHReturnsState';
import { Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { DisableColor, GetCCHReturnsIcon, ImportCCHAxcessIcon, RefreshIcon } from './Icons';

interface ReturnsTableHeaderProps {
    returnsData : CCHReturn[];
    totalReturns:number;
    showBulkSelectionMessage : boolean;   
    isLoading :boolean;
    selectedRows : number[];
    isLoggedIn : boolean;
    isGetCCHReturnsCompleted : boolean;
    isImportCompleted : boolean;
    onBulkSelectionChange: (isEnabled: boolean) => void;
    onImport:() => void;   
    onGetCCHReturns:() => void;
    onRefresh():void;
}

export const ReturnsTableHeader: React.FC<ReturnsTableHeaderProps> = 
                                    ({returnsData,totalReturns,showBulkSelectionMessage,isLoading,selectedRows,isLoggedIn,isGetCCHReturnsCompleted,isImportCompleted, 
                                      onRefresh,onImport, onGetCCHReturns, onBulkSelectionChange}) => {
    let onRefreshBtnClick =() => {
        onRefresh();     
    }

    let onImportBtnClick =() => {
        onImport();
    }

    let onGetCCHReturnsBtnClick = () => {
        onGetCCHReturns(); 
    }   

    let onBulkSelectionClick = () => {
        onBulkSelectionChange(true);
    }

    let getBulkSelectionMessage = () => {
        let rowsInCurrentPage = returnsData ? returnsData.length : 0;
        let remainingRecords = totalReturns - rowsInCurrentPage;
    
        return (
            showBulkSelectionMessage && remainingRecords > 0 &&
            <Alert variant="warning" style={{ padding: "3px 5px", marginBottom:"0px", width: "fit-content" }}>
                <span className="fa fa-exclamation-triangle" style={{ marginRight: '5px', paddingLeft: "5px" }}></span>
                {constants.bulkSelectionMsg.replace("{rowsInCurrentPage}", rowsInCurrentPage.toString()).replace("{remainingRecords}", remainingRecords.toString())}
                <Link to={"#"} onClick={onBulkSelectionClick}>{constants.clickHere}</Link>
            </Alert>
        );
    }
  
    const constants = ImportCCHAxcess.TableHeader;
    const isImportDisabled = selectedRows.length <= 0 || !isLoggedIn || !isImportCompleted;    
    const isGetCCHReturnsDisabled = !isLoggedIn || !isGetCCHReturnsCompleted;
    
    return(
        <>        
                  <div className='row'>   
                            <div className="mr-auto ml-3">
                                {getBulkSelectionMessage()}
                            </div>
                            <div className='ml-auto mr-3'>
                                <Button
                                    variant="default"
                                    disabled={isLoading}
                                    onClick={() => onRefreshBtnClick()}
                                    title={constants.refreshTooltip} 
                                    className="btn-white btn-default btn-only"                                    
                                    data-test-auto="C93C886A-5D92-4F1A-A514-5B6C64397D19">                                    
                                    <RefreshIcon></RefreshIcon>
                                </Button> 
                                <Button
                                    disabled={isImportDisabled}
                                    variant="default"
                                    onClick={() => onImportBtnClick()}
                                    title={ isLoggedIn ? constants.importTooltip : constants.importDisabledTooltip}                                    
                                    className="ml-2 btn-white btn-default btn-only marR10"                                    
                                    data-test-auto="D6564447-25B8-46DB-B151-D505119B0A6D">                                    
                                    <span style={{ paddingRight:"8px" , verticalAlign:"bottom" }}>
                                        <ImportCCHAxcessIcon color = {isImportDisabled ? DisableColor : '' }></ImportCCHAxcessIcon>
                                    </span>
                                     { isImportCompleted ? constants.import : constants.importInProgress}
                                </Button>   
                                <Button                        
                                    disabled={isGetCCHReturnsDisabled}
                                    variant= {(isLoggedIn && isGetCCHReturnsCompleted) ? "primary" : "default"}
                                    onClick={() => onGetCCHReturnsBtnClick()}
                                    style={{ width:'170px' }}
                                    title={isLoggedIn ? ( !isGetCCHReturnsCompleted ? constants.pullReturnsMsg :constants.getReturnsTooltip) : constants.getReturnsDisabledTooltip}                                    
                                    className={`ml-2 btn-only marR10 ${(isLoggedIn && isGetCCHReturnsCompleted) ? ' btn-info' : ' btn-white'}`}                                   
                                    data-test-auto="11D387FE-06CB-4CDB-B9D5-CAD3C913DC90">                                    
                                    <span style={{ paddingRight:"8px" , verticalAlign:"text-bottom" }}>
                                        <GetCCHReturnsIcon color={isGetCCHReturnsDisabled ?  DisableColor : ''}></GetCCHReturnsIcon>
                                    </span>
                                     {constants.getReturns}
                                </Button>                                 
                            </div>                                                    
                        </div>          
        </>
    );
};