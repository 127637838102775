import { actionTypes } from "../../types/ActionTypes";
import { Reducer } from 'redux';
import { AppThunkAction } from "../index"; 
import { SSSUITE_API_BASE_URL } from "../../utils/constants";
import { handleResponse } from "../Library";
import { CommonMessages } from '../../components/helper/Constants';
import { NotificationAction, StatusType } from '../common/NotificationStore';
import { addTask } from 'domain-task';

export interface IClientManagementSetting {
    reconcileType: ReconcileType;
    extractClientIdEnabled: boolean;
}

export enum ReconcileType {
    None = -1,
    Add = 0,
    Ignore = 1,
    Confirm = 2,
}

export const initialClientManagementSetting: IClientManagementSetting = {
    reconcileType: ReconcileType.Ignore,
    extractClientIdEnabled: true
}

export interface RequestClientManagementSetting {
    type: actionTypes.REQUEST_CLIENTMANAGEMENT_SETTING;
}

export interface ReceiveClientManagementSetting {
    type: actionTypes.RECEIVE_CLIENTMANAGEMENT_SETTING;
    clientManagementSetting: IClientManagementSetting;
}

export type KnownAction =
    RequestClientManagementSetting |
    ReceiveClientManagementSetting |
    NotificationAction;

export const actionCreators = {
    requestClientManagementSetting: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(SSSUITE_API_BASE_URL + '/api/client-management-setting', {
            method: 'GET',
            credentials: 'include'
        })
            .then(handleResponse)
            .then(data => {
                dispatch({ type: actionTypes.RECEIVE_CLIENTMANAGEMENT_SETTING, clientManagementSetting: data });
            })
            .catch(error => {
                if (error.status != 512) {
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: CommonMessages.Error.CompanyDisabled,
                        statusType: StatusType.Error
                    })
                }
            });
        addTask(fetchTask);
        dispatch({ type: actionTypes.REQUEST_CLIENTMANAGEMENT_SETTING });
    }
}

export const reducer: Reducer<IClientManagementSetting> = (state = initialClientManagementSetting, incomingAction) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case actionTypes.REQUEST_CLIENTMANAGEMENT_SETTING:
            return state;
        case actionTypes.RECEIVE_CLIENTMANAGEMENT_SETTING:
            return {
                ...state,
                reconcileType: action.clientManagementSetting.reconcileType,
                extractClientIdEnabled: action.clientManagementSetting.extractClientIdEnabled
            } as IClientManagementSetting;
        default: return state;
    }
}
