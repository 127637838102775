import React from 'react'
import { useSelector } from 'react-redux'
import { Route } from 'react-router-dom'

import { Unauthorized } from '../components/Unauthorized'
import FeatureDisabled from '../components/FeatureDisabled'
import { IsOnlyExchangeUser, IsOnlyRestrictedRoles, IsOnlySignatureUser, IsRouteDisabled } from '../oidcClient/userRoles'
import { ApplicationState } from '../store'

type Props = {
    component: React.ComponentType<any>
    path: string | string[]
    exact?: boolean | undefined
    sensitive?: boolean | undefined
    strict?: boolean | undefined
}

const RoleBasedRoute: React.FC<Props> = ({ component, ...rest }) => {
    const accessibleProducts = useSelector((state: ApplicationState) => state.AccessibleProductData);
    const leftMenuData = useSelector((state: ApplicationState) => state.LeftMenu);
    const user = useSelector((state: ApplicationState) => state.auth.user);
    if ((accessibleProducts && !accessibleProducts.isInitialState && !accessibleProducts.isAccessible)
        || IsOnlySignatureUser(user) || IsOnlyExchangeUser(user) || IsOnlyRestrictedRoles(user)    ) {
        return <Route component={FeatureDisabled} {...rest} />;
    }
    else if (!leftMenuData.isInitialState) {
        const isDisabled = IsRouteDisabled(leftMenuData.leftMenu, rest.path);
        return isDisabled ? <Route component={Unauthorized} {...rest} /> : <Route component={component} {...rest} />
    }
    return <Route component={component} {...rest} />;
}

RoleBasedRoute.displayName = 'RoleBasedRoute'

export default RoleBasedRoute
