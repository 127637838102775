import * as React from 'react';
import { ClientInstructionMessageVariables } from '../../helper/Constants';
import { copyToClipboard } from "../../helper/HelperFunctions";
import { Card, Accordion } from 'react-bootstrap';

export interface IVariableListData {
	variable: string,
	helpText: string
}

export interface VariableListProps {
    isGroupReturn?: boolean;
}
export interface VariableListModel {
	variableListModel: IVariableListData[];
	open: boolean;
	isHovering: boolean
}

export class VariableList extends React.Component<VariableListProps, VariableListModel>{
    constructor(props: VariableListProps) {
		super(props);
		this.state = {
			variableListModel: [],
			open: false,
			isHovering: false
		}
		this.getVariableList = this.getVariableList.bind(this);
	}


    public getVariableList(): IVariableListData[] {

        const tempVariableListModel: IVariableListData[] = [];
        if (this.props.isGroupReturn) {
            tempVariableListModel.push({ variable: "COMPANYNAME", helpText: ClientInstructionMessageVariables.CompanyName },
                { variable: "TAXPAYERNAME", helpText: ClientInstructionMessageVariables.TaxPayerName },
                { variable: "SPOUSENAME", helpText: ClientInstructionMessageVariables.SpouseName },
                { variable: "GROUPNAME", helpText: ClientInstructionMessageVariables.GroupName },
                { variable: "CONTROLLERNAME", helpText: ClientInstructionMessageVariables.ControllerName },
                { variable: "HELPCONTACT", helpText: ClientInstructionMessageVariables.HelpContact },
                { variable: "HELPPHONE", helpText: ClientInstructionMessageVariables.HelpPhone },
                { variable: "HELPEMAIL", helpText: ClientInstructionMessageVariables.HelpEmail },
                { variable: "PREPARERNAME", helpText: ClientInstructionMessageVariables.PrepareName },
                { variable: "DUEDATE", helpText: ClientInstructionMessageVariables.DueDate },
                { variable: "TAXYEAR", helpText: ClientInstructionMessageVariables.TaxYear },
                { variable: "NUMBEROFDAYS", helpText: ClientInstructionMessageVariables.NumberOfays },
                { variable: "RECIPIENT", helpText: ClientInstructionMessageVariables.Recipient },
                { variable: "ERONAME", helpText: ClientInstructionMessageVariables.EROName },
                { variable: "SENDERNAME", helpText: ClientInstructionMessageVariables.SenderName }

            )
        }
        else {
            tempVariableListModel.push({ variable: "COMPANYNAME", helpText: ClientInstructionMessageVariables.CompanyName },
                { variable: "TAXPAYERNAME", helpText: ClientInstructionMessageVariables.TaxPayerName },
                { variable: "SPOUSENAME", helpText: ClientInstructionMessageVariables.SpouseName },
                { variable: "GROUPNAME", helpText: ClientInstructionMessageVariables.GroupName },
                { variable: "CONTROLLERNAME", helpText: ClientInstructionMessageVariables.ControllerName },
                { variable: "HELPCONTACT", helpText: ClientInstructionMessageVariables.HelpContact },
                { variable: "HELPPHONE", helpText: ClientInstructionMessageVariables.HelpPhone },
                { variable: "HELPEMAIL", helpText: ClientInstructionMessageVariables.HelpEmail },
                { variable: "PREPARERNAME", helpText: ClientInstructionMessageVariables.PrepareName },
                { variable: "DUEDATE", helpText: ClientInstructionMessageVariables.DueDate },
                { variable: "TAXYEAR", helpText: ClientInstructionMessageVariables.TaxYear },
                { variable: "NUMBEROFDAYS", helpText: ClientInstructionMessageVariables.NumberOfays },
                { variable: "RECIPIENT", helpText: ClientInstructionMessageVariables.Recipient },
                { variable: "ERONAME", helpText: ClientInstructionMessageVariables.EROName },
                { variable: "SENDERNAME", helpText: ClientInstructionMessageVariables.SenderName }

            )
        }
        return tempVariableListModel;
    }

	public render() {
		let icon = this.state.open ? "fas fa-minus" : "fas fa-plus";
		let text = this.state.open ? "Variables List" : "Show Variables List";
		return (
			<Accordion>
				<Card>
					<Accordion.Toggle eventKey="0">
						<Card.Header style={{ padding: "10px" }}
							onClick={() => this.setState({ open: !this.state.open })}>
							<Card.Title as="h3" style={{ marginBottom: '0px' }}>
								<i className={icon}></i> <a>{text}</a>
							</Card.Title>
						</Card.Header>
					</Accordion.Toggle>
					<Accordion.Collapse eventKey="0">
						<Card.Body style={{ paddingTop: '0.5rem', paddingBottom: '0.5rem' }}>
							<ul>{
								this.getVariableList().map((item) => {
									var value = `<${item.variable}>`;
									return <div className="row">
										<div className="col-md-4"><li className="copy">{value}
											<span onClick={() => copyToClipboard(item.variable)}
												title="Copy"
												className="far fa-copy copyIcon"
												style={{ fontSize: "16px", paddingLeft: "14px", cursor: "pointer" }}></span></li></div>
										<div className="col-md-8">{item.helpText}</div>
									</div>
								})
							}
							</ul>

						</Card.Body>
					</Accordion.Collapse>

				</Card>
			</Accordion>
		)
	}
}
