import { addTask } from "domain-task";
import { Action, Reducer } from "redux";
import { AppThunkAction } from "../";
import { IGroupAccess } from "../../components/GroupedReturns/GroupConfiguration";
import { actionTypes } from "../../types/ActionTypes";
import { handleResponse } from "../Library";
import * as Notification from "../common/NotificationStore";
import * as Constants from "../../components/helper/Constants";
import { encodeGroupInfoDataHtmlTags } from "../../components/helper/HelperFunctions";
import { IGroupInfo } from "../groupedreturns/GroupedReturnProcessState";
import { initialGroupInfo } from "../groupedreturns/GroupedReturnProcessStore";
import { NotificationAction } from "../common/NotificationStore";
import { ResetSendGroupedReturnsAction } from "../reports/GroupedReturns/SendGroupedReturnsStore";
import { API_BASE_URL } from "../../utils/constants";

export interface IGroupInfoState {
    groupInfo: IGroupInfo[];
    isLoading: boolean;
    message: string;
    groupAccess: IGroupAccess[];
    groupData: IGroupInfo;
}

export interface RequestGroupConfigurationAction {
    type: actionTypes.REQUEST_GROUP_NAME;
    message: string;
}

export interface ReceiveGroupConfigurationAction {
    type: actionTypes.RECEIVE_GROUP_NAME;
    groupInfo: IGroupInfo[];
}

export interface RequestAddGroupName {
    type: actionTypes.REQUEST_ADD_GROUP_NAME;
}

export interface ReceiveAddedGroupName {
    type: actionTypes.RECEIVE_ADDED_GROUP_NAME;
    groupInfo: IGroupInfo;
}
export interface RequestMapReturntoGroup {
    type: actionTypes.REQUEST_MAP_RETURN_GROUP;
}
export interface RequestGroupInfoAction {
    type: actionTypes.REQUEST_GROUP_INFO;
    message: string;
}

export interface ReceiveGroupInfoAction {
    type: actionTypes.RECEIVE_GROUP_INFO;
    groupData: IGroupInfo;
}

export interface ErrorGroupNameAction {
    type: actionTypes.ERROR_GROUP_NAME;
    reason: string;
}

export interface ReceiveGroupAccess {
    type: actionTypes.RECEIVE_GROUP_ACCESS;
    groupAccess: IGroupAccess[];
}
export interface ErrorGroupAccessAction {
    type: actionTypes.ERROR_GROUP_ACCESS;
    reason: string;
}
export interface RequestGroupAccess {
    type: actionTypes.REQUEST_GROUP_ACCESS;
    message: string;
}

export interface DeleteGroupInfoAction {
    type: actionTypes.DELETE_GROUP_INFO;
    groupIds: number[];
}

export interface ResetGroupInfoAction {
    type: actionTypes.RESET_GROUP_INFO;
}

type KnownAction =
    | ReceiveAddedGroupName
    | ReceiveGroupAccess
    | ErrorGroupNameAction
    | ReceiveGroupInfoAction
    | ReceiveGroupConfigurationAction
    | RequestGroupConfigurationAction
    | RequestAddGroupName
    | RequestMapReturntoGroup
    | RequestGroupInfoAction
    | NotificationAction
    | ErrorGroupAccessAction
    | ResetSendGroupedReturnsAction
    | RequestGroupAccess
    | DeleteGroupInfoAction
    | ResetGroupInfoAction;

type DispatchAction =
    | ReceiveAddedGroupName
    | ReceiveGroupAccess
    | ErrorGroupNameAction
    | ReceiveGroupInfoAction
    | ReceiveGroupConfigurationAction
    | RequestGroupConfigurationAction
    | ErrorGroupAccessAction
    | RequestGroupAccess
    | DeleteGroupInfoAction
    | ResetGroupInfoAction;

export const actionCreators = {
    requestGroupConfiguration:
        (reload: boolean = false): AppThunkAction<KnownAction> =>
        (dispatch, getState) => {
            if (reload || getState().groupedReturnStore.groupInfo.length == 0) {
                const fetchTask = fetch(API_BASE_URL + "api/GroupedReturns/GetGroupInfo", {
                    method: "GET",
                    credentials: "include"
                })
                    .then((response) => response.json() as Promise<IGroupInfo[]>)
                    .then((data) => {
                        if (data) {
                            dispatch({
                                type: actionTypes.RECEIVE_GROUP_NAME,
                                groupInfo: data
                            });
                        }
                    })
                    .catch(function (error) {
                        const statusMessage: any = error.statusText;
                        if (typeof statusMessage === "string") {
                            dispatch({
                                type: actionTypes.NOTIFICATION,
                                statusMessage: statusMessage,
                                statusType: Notification.StatusType.Error
                            });
                        }
                        dispatch({ type: actionTypes.ERROR_GROUP_NAME, reason: error.message });
                    });
                addTask(fetchTask);
                dispatch({ type: actionTypes.REQUEST_GROUP_NAME, message: "" });
            }
        },
    requestGroupInfo:
        (id: number, callback?: (data: any) => void): AppThunkAction<KnownAction> =>
        (dispatch, getState) => {
            const fetchTask = fetch(API_BASE_URL + "api/GroupedReturns/GetGroupInfoAsync/" + id, {
                method: "GET",
                credentials: "include"
            })
                .then((response) => response.json() as Promise<IGroupInfo>)
                .then((data) => {
                    dispatch({
                        type: actionTypes.RECEIVE_GROUP_INFO,
                        groupData: data
                    });
                    if (callback) {
                        callback(data);
                    }
                })
                .catch(function (error) {
                    const statusMessage: any = error.statusText;
                    if (typeof statusMessage === "string") {
                        dispatch({
                            type: actionTypes.NOTIFICATION,
                            statusMessage: statusMessage,
                            statusType: Notification.StatusType.Error
                        });
                    }
                    dispatch({ type: actionTypes.ERROR_GROUP_NAME, reason: error.message });
                });
            addTask(fetchTask);
        },
    requestGroupSettings:
        (id: number, callback: (data: any) => void): AppThunkAction<KnownAction> =>
        (dispatch, getState) => {
            const fetchTask = fetch(API_BASE_URL + "api/GroupedReturns/GetGroupInfoAsync/" + id, {
                method: "GET",
                credentials: "include"
            })
                .then((response) => response.json() as Promise<IGroupInfo>)
                .then((data) => {
                    if (callback) {
                        callback(data);
                    }
                })
                .catch(function (error) {
                    const statusMessage: any = error.statusText;
                    if (typeof statusMessage === "string") {
                        dispatch({
                            type: actionTypes.NOTIFICATION,
                            statusMessage: statusMessage,
                            statusType: Notification.StatusType.Error
                        });
                    }
                    dispatch({ type: actionTypes.ERROR_GROUP_NAME, reason: error.message });
                });
            addTask(fetchTask);
        },

    addGroup:
        (
            groupInfo: IGroupInfo,
            callback?: (id: number) => void,
            failurecallback?: () => void,
            disableSuccessNotification?: boolean,
            resourceId: string = ""
        ): AppThunkAction<KnownAction> =>
        (dispatch, getState) => {
            const fetchTask = fetch(API_BASE_URL + "api/GroupedReturns/AddGroupInfo", {
                method: "POST",
                credentials: "include",
                headers: {
                    Accept: "application/json, text/plain, */*",
                    "Content-Type": "application/json; charset=utf-8",
                    "X-Resource-Id": resourceId,
                    RequestVerificationToken: (document.getElementById("RequestVerificationToken") as HTMLInputElement).value
                },
                body: JSON.stringify(groupInfo)
            })
                .then(handleResponse)
                .then((id) => {
                    groupInfo.id = id;
                    dispatch({
                        type: actionTypes.RECEIVE_ADDED_GROUP_NAME,
                        groupInfo: groupInfo
                    });
                    if (!disableSuccessNotification) {
                        dispatch({
                            type: actionTypes.NOTIFICATION,
                            statusMessage: Constants.GroupReturnConstants.StatusMessage.GroupConfigurationAddSuccess,
                            statusType: Notification.StatusType.Success
                        });
                    }
                    if (callback) {
                        callback(id);
                    }
                })
                .catch(function (error) {
                    if (failurecallback) {
                        failurecallback();
                    }
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: Constants.GroupReturnConstants.StatusMessage.GroupConfigurationAddError,
                        statusType: Notification.StatusType.Error
                    });
                    dispatch({
                        type: actionTypes.ERROR_GROUP_NAME,
                        reason: error.message
                    });
                });
            addTask(fetchTask);
            dispatch({ type: actionTypes.REQUEST_GROUP_NAME, message: "" });
        },
    mapReturnstoGroup:
        (
            ids: number[],
            groupId: number,
            callback?: () => void,
            failurecallback?: () => void,
            combineMultipleNotification?: boolean,
            resourceId: string = ""
        ): AppThunkAction<KnownAction> =>
        (dispatch, getState) => {
            const fetchTask = fetch(API_BASE_URL + "api/GroupedReturns/MapReturntoGroup/" + groupId, {
                method: "POST",
                credentials: "include",
                headers: {
                    Accept: "application/json, text/plain, */*",
                    "Content-Type": "application/json; charset=utf-8",
                    "X-Resource-Id": resourceId,
                    RequestVerificationToken: (document.getElementById("RequestVerificationToken") as HTMLInputElement).value
                },
                body: JSON.stringify(ids)
            })
                .then(handleResponse)
                .then(() => {
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: combineMultipleNotification
                            ? Constants.GroupReturnConstants.StatusMessage.GroupConfigurationAndMapReturnToGroupSuccess
                            : Constants.GroupReturnConstants.StatusMessage.MapReturnToGroupSuccess,
                        statusType: Notification.StatusType.Success
                    });
                    dispatch({ type: actionTypes.RESET_SEND_GROUPED_RETURNS });
                    dispatch({ type: actionTypes.RESET_GROUP_INFO });
                    if (callback) {
                        callback();
                    }
                })
                .catch(function (error) {
                    if (failurecallback) {
                        failurecallback();
                    }
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: Constants.GroupReturnConstants.StatusMessage.MapReturnToGroupError,
                        statusType: Notification.StatusType.Error
                    });
                    dispatch({
                        type: actionTypes.ERROR_GROUP_NAME,
                        reason: error.message
                    });
                });
            addTask(fetchTask);
            dispatch({ type: actionTypes.REQUEST_GROUP_NAME, message: "" });
        },

    unMapReturnsFromGroup:
        (
            ids: number[],
            callback?: (callbackData?: IGroupInfo) => void,
            callbackData?: IGroupInfo,
            failurecallback?: () => void,
            resourceId: string = ""
        ): AppThunkAction<KnownAction> =>
        (dispatch, getState) => {
            const fetchTask = fetch(API_BASE_URL + "api/GroupedReturns/UnMapReturnsToGroupAsync/", {
                method: "POST",
                credentials: "include",
                headers: {
                    Accept: "application/json, text/plain, */*",
                    "Content-Type": "application/json; charset=utf-8",
                    "X-Resource-Id": resourceId,
                    RequestVerificationToken: (document.getElementById("RequestVerificationToken") as HTMLInputElement).value
                },
                body: JSON.stringify(ids)
            })
                .then(handleResponse)
                .then(() => {
                    dispatch({ type: actionTypes.RESET_SEND_GROUPED_RETURNS });
                    if (callback) {
                        callbackData ? callback(callbackData) : callback();
                    }
                })
                .catch(function (error) {
                    if (failurecallback) {
                        failurecallback();
                    }
                    dispatch({
                        type: actionTypes.ERROR_GROUP_NAME,
                        reason: Constants.SendGroupedReturnsHeaderConstants.StatusMessage.UnMapReturnToGroupError
                    });
                });
            addTask(fetchTask);
        },

    requestGroupAccess:
        (reload: boolean = false): AppThunkAction<KnownAction> =>
        (dispatch, getState) => {
            if (reload || getState().groupedReturnStore.groupAccess.length == 0) {
                const fetchTask = fetch(API_BASE_URL + "api/GroupedReturns/GetGroupAccess", {
                    method: "GET",
                    credentials: "include"
                })
                    .then((response) => response.json() as Promise<IGroupAccess[]>)
                    .then((data) => {
                        if (data) {
                            dispatch({
                                type: actionTypes.RECEIVE_GROUP_ACCESS,
                                groupAccess: data
                            });
                        }
                    })
                    .catch(function (error) {
                        const statusMessage: any = error.statusText;
                        if (typeof statusMessage === "string") {
                            dispatch({
                                type: actionTypes.NOTIFICATION,
                                statusMessage: statusMessage,
                                statusType: Notification.StatusType.Error
                            });
                        }
                        dispatch({ type: actionTypes.ERROR_GROUP_ACCESS, reason: error.message });
                    });
                addTask(fetchTask);
                dispatch({ type: actionTypes.REQUEST_GROUP_ACCESS, message: "" });
            }
        },

    updateGroup:
        (
            groupInfo: IGroupInfo,
            callback?: () => void,
            failurecallback?: () => void,
            disableSuccessNotification?: boolean,
            resourceId: string = ""
        ): AppThunkAction<KnownAction> =>
        (dispatch, getState) => {
            encodeGroupInfoDataHtmlTags(groupInfo);
            const fetchTask = fetch(API_BASE_URL + "api/GroupedReturns/UpdateGroupInfo", {
                method: "PUT",
                credentials: "include",
                headers: {
                    Accept: "application/json, text/plain, */*",
                    "Content-Type": "application/json; charset=utf-8",
                    "X-Resource-Id": resourceId,
                    RequestVerificationToken: (document.getElementById("RequestVerificationToken") as HTMLInputElement).value
                },
                body: JSON.stringify(groupInfo)
            })
                .then(handleResponse)
                .then(() => {
                    if (!disableSuccessNotification) {
                        dispatch({
                            type: actionTypes.NOTIFICATION,
                            statusMessage: Constants.GroupReturnConstants.StatusMessage.GroupConfigurationUpdateSuccess,
                            statusType: Notification.StatusType.Success
                        });
                    }
                    if (callback) {
                        callback();
                    }
                })
                .catch(function (error) {
                    if (failurecallback) {
                        failurecallback();
                    }
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: Constants.GroupReturnConstants.StatusMessage.GroupConfigurationUpdateError,
                        statusType: Notification.StatusType.Error
                    });
                    dispatch({
                        type: actionTypes.ERROR_GROUP_NAME,
                        reason: error.message
                    });
                });
            addTask(fetchTask);
            dispatch({ type: actionTypes.REQUEST_GROUP_NAME, message: "" });
        }
};

const unloadedState: IGroupInfoState = {
    groupInfo: [],
    isLoading: false,
    message: "",
    groupAccess: [],
    groupData: initialGroupInfo
} as IGroupInfoState;

export const reducer: Reducer<IGroupInfoState> = (state: IGroupInfoState = unloadedState, incomingAction: Action) => {
    const action = incomingAction as DispatchAction;
    switch (action.type) {
        case actionTypes.REQUEST_GROUP_NAME:
            let groupConfiguration: IGroupInfoState = { ...state };
            groupConfiguration.isLoading = true;
            groupConfiguration.message = action.message;
            return groupConfiguration;

        case actionTypes.RECEIVE_GROUP_NAME:
            return {
                ...state,
                groupInfo: action.groupInfo,
                isLoading: false,
                error: false,
                message: ""
            } as IGroupInfoState;

        case actionTypes.RECEIVE_ADDED_GROUP_NAME:
            let added: IGroupInfoState = { ...state };
            added.groupInfo.push(action.groupInfo);
            added.isLoading = false;
            return added;

        case actionTypes.RECEIVE_GROUP_INFO:
            let group: IGroupInfoState = { ...state };
            group.groupData = action.groupData;
            return group;

        case actionTypes.RESET_GROUP_INFO:
            let resetGroup: IGroupInfoState = { ...state };
            resetGroup.groupData = initialGroupInfo;
            resetGroup.groupData.id = 0;
            return resetGroup;

        case actionTypes.RECEIVE_GROUP_ACCESS:
            let groupAccessData: IGroupInfoState = { ...state };
            groupAccessData.isLoading = true;
            groupAccessData.groupAccess = action.groupAccess;
            return groupAccessData;
        case actionTypes.REQUEST_GROUP_ACCESS:
            let groupAccess: IGroupInfoState = { ...state };
            groupAccess.isLoading = true;
            groupAccess.message = action.message;
            return groupAccess;
        case actionTypes.ERROR_GROUP_ACCESS:
            return {
                groupInfo: state.groupInfo,
                isLoading: false,
                message: ""
            } as IGroupInfoState;

        case actionTypes.ERROR_GROUP_NAME:
            return {
                groupInfo: state.groupInfo,
                isLoading: false,
                message: ""
            } as IGroupInfoState;

        case actionTypes.DELETE_GROUP_INFO:
            let data: IGroupInfoState = { ...state };
            for (let i = 0; i < action.groupIds.length; i++) {
                var index = data.groupInfo.findIndex((group) => group.id == action.groupIds[i]);
                if (index != -1) {
                    data.groupInfo.splice(index, 1);
                }
            }
            return data;
    }
    return state || unloadedState;
};
