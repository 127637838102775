import { Action, Reducer } from 'redux';
import { actionTypes } from '../../../../types/ActionTypes';
import { RecycleGroupedReturnsDictionary, RecycleGroupedReturnsState, RecycleGroupedReturnsTableModel } from './RecycleGroupedReturnsState';

export interface RequestRecycleGroupedReturnsPagesAction {
	type: actionTypes.REQUEST_RECYCLE_GROUPED_RETURNS_PAGES;
	query: string;
}

export interface ReceiveRecycleGroupedReturnsPagesAction {
	type: actionTypes.RECEIVE_RECYCLE_GROUPED_RETURNS_PAGES;
	query: string;
	table: RecycleGroupedReturnsTableModel;
	totalRowCount: number;
}

type DispatchAction = RequestRecycleGroupedReturnsPagesAction
	| ReceiveRecycleGroupedReturnsPagesAction;

const unloadedState: RecycleGroupedReturnsState = {
	recycleGroupedReturnsTableModel: {},
	query: '?',
	loading: false,
	totalRowCount: 0
} as RecycleGroupedReturnsState;

export const actionCreators = {};

export const reducer: Reducer<RecycleGroupedReturnsDictionary> = (state: RecycleGroupedReturnsDictionary = { "?": unloadedState }, incomingAction: Action) => {
	const action = incomingAction as DispatchAction;
	switch (action.type) {
		case actionTypes.REQUEST_RECYCLE_GROUPED_RETURNS_PAGES:
			if (!state[action.query]) {
				let added = { ...unloadedState } as RecycleGroupedReturnsState;
				added.loading = true;
				added.query = action.query;
				let item = {} as RecycleGroupedReturnsDictionary;
				item[action.query] = added;

				return {
					...state,
					...item
				};
			}
			break;
		case actionTypes.RECEIVE_RECYCLE_GROUPED_RETURNS_PAGES:
			if (state[action.query]) {
				let changed = state[action.query] as RecycleGroupedReturnsState;
				changed.loading = false;
				changed.recycleGroupedReturnsTableModel = action.table;
				changed.totalRowCount = action.totalRowCount;
				let item = {} as RecycleGroupedReturnsDictionary;
				item[action.query] = changed;
				return {
					...state,
					...item
				};
			}
			break;

		default:
			// The following line guarantees that every action in the KnownAction union has been covered by a case above
			const exhaustiveCheck: never = action;
	}
	return state || { "?": unloadedState };
};
