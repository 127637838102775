import { addTask } from 'domain-task';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../';
import { actionTypes } from '../../types/ActionTypes';
import { IWelcomeMessage, clientInstructions } from '../../Core/ViewModels/CompanySettings/ClientInstructionViewModel';
import { handleResponse } from '../Library';
import * as Constants from '../../components/helper/Constants';
import { StatusType, NotificationAction } from '../common/NotificationStore';
import * as SSRSettingsStore from '../company/settings/SSRSettingsStore';
import { API_BASE_URL } from '../../utils/constants';
import { ISSRSettings, SSRSettings } from '../../Core/ViewModels/Company/Settings/SSRSettingsViewModel';

export interface IWelcomeMessageStoreState {
    data: IWelcomeMessageDictionary;
    isLoading: boolean;
}
export interface IWelcomeMessageDictionary {
    [index: number]: IWelcomeMessageState
}

export interface IWelcomeMessageState {
    welcomeMessage: IWelcomeMessage;
}

interface RequestWelcomeMessasgeListAction {
    type: actionTypes.REQUEST_WELCOME_MESSAGES;
}

interface ReceiveWelcomeMessageListAction {
    type: actionTypes.RECEIVE_WELCOME_MESSAGES;
    messageDetails: IWelcomeMessage;
    id: number;
}

interface DeleteWelcomeMessageDetail {
    type: actionTypes.DELETE_WELCOME_MESSAGE;
    deletedMessageDetail: IWelcomeMessage;
}

type KnownAction =
    RequestWelcomeMessasgeListAction |
    ReceiveWelcomeMessageListAction |
    DeleteWelcomeMessageDetail |
    NotificationAction

export const ActionCreators = {
    requestWelcomeMessages: (reload: boolean = false): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(API_BASE_URL + 'api/WelcomeMessage/GetWelcomeMessage', {
            method: 'GET',
            credentials: 'include'
        })
            .then(handleResponse)
            .then(response => response as Promise<IWelcomeMessage[]>)
            .then((data) => {
                if (data) {
                    data.map((value, index) => {
                        dispatch({
                            type: actionTypes.RECEIVE_WELCOME_MESSAGES,
                            messageDetails: value, id: value.id
                        });
                    });
                }
            }).catch(function (error) {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: Constants.ClientInstructionConstants.FetchMessageError, statusType: StatusType.Error
                });
            });
        addTask(fetchTask);
        dispatch({ type: actionTypes.REQUEST_WELCOME_MESSAGES });
    },

    saveWelcomeMessageDetail: (message: IWelcomeMessage, ssrSettings: ISSRSettings, isSetDefault: boolean, typeId: number, resourceId: string = ""): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(API_BASE_URL + 'api/WelcomeMessage', {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(message),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-Resource-Id': resourceId,
                'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
            }
        })
            .then(handleResponse) 
            .then((newId) => {
                let addedMessage: IWelcomeMessage = Object.assign({}, message);
                addedMessage.id = newId;
                ssrSettings = new SSRSettings(ssrSettings.defaultMessageSettings);
                if (isSetDefault && ssrSettings.defaultMessageSettings) {
                    switch(typeId){
                        case clientInstructions.welcomePage:
                            ssrSettings.defaultMessageSettings.welcomeMessage = newId;
                            break;
                        case clientInstructions.k1WelcomePage:
                            ssrSettings.defaultMessageSettings.k1WelcomeMessage = newId;
                            break;
                        case clientInstructions.k1PartnerUpdatePageMessage:
                            ssrSettings.defaultMessageSettings.k1PartnerUpdatePageMessage = newId;
                            break;
                        default:
                            break;
                    }

                    let action: any = SSRSettingsStore.actionCreators.updateSSRSettings(ssrSettings, undefined, resourceId)
                    dispatch(action);
                    dispatch({
                        type: actionTypes.RECEIVE_WELCOME_MESSAGES, messageDetails: addedMessage, id: newId
                    });
                }
                else {
                    dispatch({
                        type: actionTypes.RECEIVE_WELCOME_MESSAGES, messageDetails: addedMessage, id: newId
                    });
                }
                
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: Constants.ClientInstructionConstants.SaveMessageSuccess,
                    statusType: StatusType.Success
                });
            }).catch(error => {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: Constants.ClientInstructionConstants.SaveMessageError, statusType: StatusType.Error
                });
            });
        addTask(fetchTask);
    },

    updateWelcomeMessageDetail: (message: IWelcomeMessage, resourceId: string = ""): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(API_BASE_URL + 'api/WelcomeMessage', {
            method: 'PUT',
            credentials: 'include',
            body: JSON.stringify(message),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-Resource-Id': resourceId,
                'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
            }
        })
            .then(handleResponse) 
            .then(() => {
                dispatch({
                    type: actionTypes.RECEIVE_WELCOME_MESSAGES, messageDetails: message,id: message.id
                });
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: Constants.ClientInstructionConstants.UpdateMessageSuccess,
                    statusType: StatusType.Success
                });
            }).catch(error => {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: Constants.ClientInstructionConstants.UpdateMessageError, statusType: StatusType.Error
                });
            });
        addTask(fetchTask);
    },

    deleteWelcomeMessageDetail: (message: IWelcomeMessage, resourceId: string = ""): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(API_BASE_URL + 'api/WelcomeMessage', {
            method: 'DELETE',
            credentials: 'include',
            body: JSON.stringify(message),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-Resource-Id': resourceId,
                'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
            }
        })
            .then(handleResponse) 
            .then(() => {
                dispatch({
                    type: actionTypes.DELETE_WELCOME_MESSAGE, deletedMessageDetail: message
                });
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: Constants.ClientInstructionConstants.DeleteMessageSuccess,
                    statusType: StatusType.Success
                });
            }).catch(error => {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: Constants.ClientInstructionConstants.DeleteMessageError, statusType: StatusType.Error
                });
            });
        addTask(fetchTask);
    }
}

const welcomeMessageDic: IWelcomeMessageDictionary = [];

const unloadedState: IWelcomeMessageStoreState =
    {
        data: { ...welcomeMessageDic },
        isLoading: true
    } as IWelcomeMessageStoreState;

export const reducer: Reducer<IWelcomeMessageStoreState> = (state = unloadedState, incomingAction) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case actionTypes.REQUEST_WELCOME_MESSAGES:
            return {
                ...unloadedState
            } as IWelcomeMessageStoreState;
        case actionTypes.RECEIVE_WELCOME_MESSAGES:
            let received: IWelcomeMessageStoreState = { ...state };
            received.data[action.id] = { welcomeMessage: action.messageDetails };
            received.isLoading = false;
            return received;

        case actionTypes.DELETE_WELCOME_MESSAGE:
            let deleteMessage: IWelcomeMessageStoreState = { ...state }
            if (deleteMessage.data[action.deletedMessageDetail.id]) {
                delete deleteMessage.data[action.deletedMessageDetail.id];
                deleteMessage.isLoading = false;
            }
            return deleteMessage;
    }
    return state;
};
