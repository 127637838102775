import { ApplicationState } from "../../../store";
import * as CompanyStore from "../../../store/company/CompanyStore";
import * as PaymentAccountStore from "../../../store/PaymentAccount/PaymentAccountStore";
import { VouchersAndInvoices } from "../VouchersAndInvoices";
import { connect } from "react-redux";
import * as UserSettingsStore from "../../../store/userManagement/UserSettingStore";

import * as ApplicationHelptextStore from "../../../store/common/ApplicationHelptextStore";

export default connect(
    (state: ApplicationState) => ({
        companyData: state.companyData,
        paymentAccountData: state.paymentAccountStore,
        helptexts: state.Helptexts
    }),
    {
        ...CompanyStore.actionCreators,
        ...UserSettingsStore.actionCreators,
        ...PaymentAccountStore.actionCreators,
        ...ApplicationHelptextStore.actionCreators
    }
)(VouchersAndInvoices as any);
