import React from "react";

export const InfoIcon: React.FC = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath ="url(#clip0_9881_176)">
                <rect width="16" height="16" fill="white" fillOpacity="0.01" />
                <g clipPath ="url(#clip1_9881_176)">
                    <path
                        d="M8 15.9992C10.1217 15.9992 12.1566 15.1564 13.6569 13.6561C15.1571 12.1558 16 10.1209 16 7.99921C16 5.87747 15.1571 3.84264 13.6569 2.34235C12.1566 0.842061 10.1217 -0.000793457 8 -0.000793457C5.87827 -0.000793457 3.84344 0.842061 2.34315 2.34235C0.842855 3.84264 0 5.87747 0 7.99921C0 10.1209 0.842855 12.1558 2.34315 13.6561C3.84344 15.1564 5.87827 15.9992 8 15.9992ZM8.93 6.58721L7.93 11.2922C7.86 11.6322 7.959 11.8252 8.234 11.8252C8.428 11.8252 8.721 11.7552 8.92 11.5792L8.832 11.9952C8.545 12.3412 7.912 12.5932 7.367 12.5932C6.664 12.5932 6.365 12.1712 6.559 11.2742L7.297 7.80621C7.361 7.51321 7.303 7.40721 7.01 7.33621L6.559 7.25521L6.641 6.87421L8.931 6.58721H8.93ZM8 5.49921C7.73478 5.49921 7.48043 5.39385 7.29289 5.20631C7.10536 5.01878 7 4.76442 7 4.49921C7 4.23399 7.10536 3.97964 7.29289 3.7921C7.48043 3.60456 7.73478 3.49921 8 3.49921C8.26522 3.49921 8.51957 3.60456 8.70711 3.7921C8.89464 3.97964 9 4.23399 9 4.49921C9 4.76442 8.89464 5.01878 8.70711 5.20631C8.51957 5.39385 8.26522 5.49921 8 5.49921Z"
                        fill="#0973BA"
                    />
                </g>
            </g>
            <defs>
                <clipPath id="clip0_9881_176">
                    <rect width="16" height="16" fill="white" />
                </clipPath>
                <clipPath id="clip1_9881_176">
                    <rect width="16" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};
