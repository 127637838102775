import React, { Component } from 'react'
import * as Bs from 'react-bootstrap'
import ToggleSwitch from '../../common/ToggleSwitch'
import { ICompanySettings, initialCompanySettings } from '../../../Core/ViewModels/Company/CompanySettingsViewModel'
import { IHelptextModel } from 'src/Core/ViewModels/Common/IHelptextModel'
import { fetchHelpText } from 'src/components/helper/HelperFunctions'
import { Link } from 'react-router-dom'
import { TaxReturnSettingsResources } from '../../../components/helper/ResourceIdConstants'

interface IScreenShareSettingsProps {
    updateCompanySettings(companySettings: ICompanySettings, resourceId?: string): void
    companySettings: ICompanySettings;
    helptexts?: IHelptextModel[];
    parentResourceIdentifier: string;
}

interface IScreenShareModelState {
    switched: boolean;
    companySettings: ICompanySettings;
    screenShareHelpText: string;
}

export class ScreenShareSettings extends React.Component<IScreenShareSettingsProps, IScreenShareModelState> {
    state: IScreenShareModelState = {
        switched: false,
        companySettings: initialCompanySettings,
        screenShareHelpText:""
    }

    UNSAFE_componentWillReceiveProps(nextProps:IScreenShareSettingsProps) {
        if (nextProps.companySettings) {
            this.setState({
                switched: nextProps.companySettings.displaySettingsModel.isScreenShareEnabled,
                companySettings: nextProps.companySettings,
            })
        }
        if(nextProps.helptexts?.length>0)
        this.setState({screenShareHelpText:fetchHelpText(nextProps.helptexts, "5A9B4627-2892-4FF9-B2E8-E1E08E8C85FF")})
    }

    handleToggleSwitchChange = (event: any) => {
        let company: ICompanySettings = { ...this.state.companySettings }
        company.displaySettingsModel.isScreenShareEnabled = event.currentTarget.checked
        this.setState({
            switched: event.currentTarget.checked,
            companySettings: company,
        })

        this.props.updateCompanySettings(this.state.companySettings, this.props.parentResourceIdentifier + TaxReturnSettingsResources.ToggleEnableOrDisable)
    }

    render() {
        return (
            <div className="new-main-content">
                <div className="row marL05">
                    <h3>Screen Share</h3>
                    <Link
                        to={'#'}
                        className="help-icon marL05"
                        data-placement="right"
                        data-toggle="tooltip"
                        title={fetchHelpText(this.props.helptexts, "5A9B4627-2892-4FF9-B2E8-E1E08E8C85FF")}
                    >
                        <i className="fas fa-question-circle"></i>
                    </Link>
                </div>

                <div className="marL20">
                    <Bs.Row>
                        <Bs.Col
                            className="text-left"
                            sm={2}
                            as={Bs.FormLabel}
                            style={{ fontSize: 'large', paddingTop: '3px', paddingRight: '0px' }}
                        >
                            Enable Screen Share
                        </Bs.Col>
                        <Bs.Col sm={4} md={3} lg={2}
                            data-resource-id = { this.props.parentResourceIdentifier + TaxReturnSettingsResources.ToggleEnableOrDisable }
                        >
                            <ToggleSwitch switched={this.state.switched}
                            handleChange={this.handleToggleSwitchChange} />
                        </Bs.Col>
                        <Bs.Col sm={6} md={7} lg={8}></Bs.Col>
                    </Bs.Row>
                </div>
            </div>
        )
    }
}
