import { addTask } from 'domain-task';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../../';
import { IDownloadableDocuments, IDocumentTransaction, DocumentEvent, ISignerModel, DocumentGroups} from '../../../components/common/TaxReturn';
import { actionTypes } from '../../../types/ActionTypes';
import { handleResponse, handleBlob,handleBlobwithFileName } from '../../Library';
import { DisplayDownloadFile } from '../../../components/common/DisplayDownloadFile';
import { IArchivedTaxReturn } from './ArchivedReturnsState';
import { ResetDeliveredReturnsAction } from '../../common/TaxDocumentStore'
import * as Constants from '../../../components/helper/Constants';
import * as Notification from '../../common/NotificationStore';
import { getCurrentDate } from '../../../components/helper/HelperFunctions';
import { IColumnValues } from '../../../components/settings/GeneralSettings';
import * as CustomColumnStore from '../../../store/common/CustomColumnStore';
import { HideLoader } from '../../../components/helper/Loader';
import { API_BASE_URL } from '../../../utils/constants';

export enum ArchivedTaxReturnSource {
    TaxDocument,
    ArchiveReturns
}

export interface ResetArchivedReturnsAction {
    type: actionTypes.RESET_ARCHIVE_RETURNS;
}

export interface RequestArchivedTaxDocumentAction {
    type: actionTypes.REQUEST_ARCHIVE_TAX_DOCUMENT;
    id: number;
}

export interface ReceiveArchivedTaxDocumentAction {
    type: actionTypes.RECEIVE_ARCHIVE_TAX_DOCUMENT;
    id: number;
    taxDocument: IArchivedTaxReturn;
    source: ArchivedTaxReturnSource;
}

export interface RestoreArchivedTaxDocumentAction {
    type: actionTypes.RESTORE_ARCHIVE_TAX_DOCUMENT;
    ids: number[];
}

export interface ErrorArchivedTaxDocumentAction {
    type: actionTypes.ERROR_ARCHIVE_TAX_DOCUMENT;
    ids: number[],
    message: string
}

export interface RequestArchivedTaxDocumentClientTrackingAction {
    type: actionTypes.REQUEST_ARCHIVE_TAX_DOCUMENT_CLIENT_TRACKING;
    id: number;
}

export interface ReceiveArchivedTaxDocumentClientTrackingAction {
    type: actionTypes.RECEIVE_ARCHIVE_TAX_DOCUMENT_CLIENT_TRACKING;
    id: number,
    clientTracking: IDocumentTransaction[];
}

export interface UpdateArchivedTaxDocumentCustomColumnValueAction {
    type: actionTypes.UPDATE_ARCHIVE_DOCUMENT_CUSTOM_COLUMN_VALUE;
    id: number,
    customColumn: string;
}

export interface RequestArchivedDownloadableDocumentsAction {
    type: actionTypes.REQUEST_ARCHIVE_TAX_DOCUMENT_DOWNLOADABLE_DOCUMENTS;
    id: number;
}

export interface ReceiveArchivedDownloadableDocumentsAction {
    type: actionTypes.RECEIVE_ARCHIVE_TAX_DOCUMENT_DOWNLOADABLE_DOCUMENTS;
    id: number,
    downloadableDocuments: IDownloadableDocuments[];
}

export interface RequestArchivedDownloadableEfileFormsAction {
    type: actionTypes.REQUEST_ARCHIVE_TAX_DOCUMENT_DOWNLOADABLE_EFILE_FORMS;
    id: number;
}

export interface ReceiveArchivedDownloadableEfileFormsAction {
    type: actionTypes.RECEIVE_ARCHIVE_TAX_DOCUMENT_DOWNLOADABLE_EFILE_FORMS;
    id: number,
    downloadableEfileForms: IDownloadableDocuments[];
}
export interface RequestDownloadHistoryAction {
    type: actionTypes.REQUEST_ARCHIVED_TAX_DOCUMENT_DOWNLOAD_HISTORY;
    id: number;
}

export interface ReceiveDownloadHistoryAction {
    type: actionTypes.RECEIVE_ARCHIVED_TAX_DOCUMENT_DOWNLOAD_HISTORY;
    id: number,
    downloadHistory: IDocumentTransaction[];
}

export interface RequestArchivedSignedDetailsAction {
    type: actionTypes.REQUEST_ARCHIVED_TAX_DOCUMENT_SIGNED_DETAILS;
    id: number;
}

export interface ReceiveArchivedSignedDetailsAction {
    type: actionTypes.RECEIVE_ARCHIVED_TAX_DOCUMENT_SIGNED_DETAILS;
    id: number,
    signedDetails: ISignerModel[];
}

type KnownAction =
    RestoreArchivedTaxDocumentAction |
    ErrorArchivedTaxDocumentAction |
    RequestArchivedTaxDocumentClientTrackingAction |
    ReceiveArchivedTaxDocumentClientTrackingAction |
    ReceiveArchivedTaxDocumentAction |
    RequestArchivedDownloadableDocumentsAction |
    ReceiveArchivedDownloadableDocumentsAction |
    RequestArchivedDownloadableEfileFormsAction |
    ResetDeliveredReturnsAction |
    ResetArchivedReturnsAction |
    ReceiveArchivedDownloadableEfileFormsAction |
    Notification.NotificationAction |
    RequestDownloadHistoryAction |
    ReceiveDownloadHistoryAction |
    RequestArchivedSignedDetailsAction |
    ReceiveArchivedSignedDetailsAction;

type DispatchActions =
    RequestArchivedTaxDocumentAction |
    ReceiveArchivedTaxDocumentAction |
    RestoreArchivedTaxDocumentAction |
    ErrorArchivedTaxDocumentAction |
    ReceiveArchivedTaxDocumentClientTrackingAction |
    ReceiveArchivedDownloadableDocumentsAction |
    ReceiveArchivedDownloadableEfileFormsAction |
    ReceiveDownloadHistoryAction |
    RequestArchivedSignedDetailsAction |
    ReceiveArchivedSignedDetailsAction;

export interface IArchiveTaxDocumentDictionary {
    [index: number]: {
        taxReturn: IArchivedTaxReturn,
        isLoading: boolean,
        error: boolean,
        message: string,
        isFullyLoaded: boolean
    };
}

export function documentsToUpdate(store: IArchiveTaxDocumentDictionary, ids: number[]) {
    let newList: number[] = [];
    ids.map((id, i) => {
        if (!store[id].taxReturn.documentAccess) {
            newList.push(id);
        }
    });
    return newList;
}

export const actionCreators = {

    restoreArchivedTaxDocument: (ids: number[], callback: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(API_BASE_URL + 'api/Reports/ArchivedReturns/', {
            method: 'DELETE',
            credentials: 'include',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8',
                'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
            },
            body: JSON.stringify(ids)
        })
            .then(handleResponse)
            .then(() => {
                dispatch({ type: actionTypes.RESTORE_ARCHIVE_TAX_DOCUMENT, ids: ids });
                dispatch({ type: actionTypes.RESET_ARCHIVE_RETURNS });
                dispatch({ type: actionTypes.RESET_DELIVERED_RETURNS });
                callback();
            })
            .catch(error => {
                dispatch({ type: actionTypes.ERROR_ARCHIVE_TAX_DOCUMENT, ids: ids, message: error });
            });
        addTask(fetchTask);
    },

    requestTaxDocumentClientTracking: (id: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(API_BASE_URL + 'api/Reports/ArchivedReturns/GetArchivedDocumentTransactions?documentId=' + id, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8',
                'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
            },
        })
            .then(handleResponse)
            .then(response => response as Promise<IDocumentTransaction[]>)
            .then(data => {
                dispatch({ type: actionTypes.RECEIVE_ARCHIVE_TAX_DOCUMENT_CLIENT_TRACKING, id: id, clientTracking: data });
            })
            .catch(error => {
                dispatch({ type: actionTypes.ERROR_ARCHIVE_TAX_DOCUMENT, ids: [id], message: error });
            });
        addTask(fetchTask);
    },

    requestDownloadableReturns: (id: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(API_BASE_URL + 'api/Reports/ArchivedReturns/GetArchivedDownloadableReturnsAsync', {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(id),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
            },
        })
            .then(handleResponse)
            .then(response => response as Promise<IDownloadableDocuments[]>)
            .then(data => {
                dispatch({ type: actionTypes.RECEIVE_ARCHIVE_TAX_DOCUMENT_DOWNLOADABLE_DOCUMENTS, id: id, downloadableDocuments: data });
            })
            .catch(error => {
                dispatch({ type: actionTypes.ERROR_ARCHIVE_TAX_DOCUMENT, ids: [id], message: error });
            });
        addTask(fetchTask);
    },

    requestDownloadableEfileForms: (id: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(API_BASE_URL + 'api/Reports/ArchivedReturns/GetArchivedDownlaodableEfileFormAsync', {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(id),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
            },
        })
            .then(handleResponse)
            .then(response => response as Promise<IDownloadableDocuments[]>)
            .then(data => {
                dispatch({ type: actionTypes.RECEIVE_ARCHIVE_TAX_DOCUMENT_DOWNLOADABLE_EFILE_FORMS, id: id, downloadableEfileForms: data });
            })
            .catch(error => {
                dispatch({ type: actionTypes.ERROR_ARCHIVE_TAX_DOCUMENT, ids: [id], message: error });
            });
        addTask(fetchTask);
    },

    downloadAllReturn: (documentGuid: string, documentId: number, taxYear: number, isEfileForm: boolean, clientId: string,returnType: any, clientName:string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        fetch(API_BASE_URL + '/api/Reports/ArchivedReturns/GetArchivedDownloadAllReturnAsync', {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify({
                documentKey: documentGuid,
                documentId: documentId,
                taxYear: taxYear,
                isEfileForm: isEfileForm,
                clientId: clientId,
                returnType:returnType,
                clientName:clientName
            }),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
            }
        }).then(handleBlobwithFileName).then((data) => {
                let displayDownloadFile = new DisplayDownloadFile();

                if (isEfileForm) {
                    let todayDate = getCurrentDate().replace(/\//g, '_');
                    displayDownloadFile.showFile(data.blob, Constants.DownloadEfileZip(todayDate));
                }
                else
                {
                    displayDownloadFile.showFile(data.blob, data.filename);
                }
                HideLoader();
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: Constants.ArchiveReturnsConstants.downloadDocument,
                    statusType: Notification.StatusType.Error
                });
                HideLoader();
            })
    },

    downloadReturn: (documentGuid: string, documentId: number, taxYear: number, fileName: string, groupId: number, signedDocumentId: number, clientId: string,clientName: string,returnType: any,isSinglePrinted:boolean, onPageReload?: () => void, isUpdateDocTransaction?: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        fetch(API_BASE_URL + 'api/Reports/ArchivedReturns/GetArchivedDownloadReturnLinkAsync', {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify({
                documentKey: documentGuid,
                fileName: fileName,
                group: groupId,
                taxYear: taxYear,
                id: documentId,
                signedDocumentId: signedDocumentId,
                clientId: clientId,
                clientName: clientName,
                returnType: returnType,
                isSinglePrinted:isSinglePrinted,
                isUpdateDocTransaction: isUpdateDocTransaction
            }),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
            }
        }).then(handleBlobwithFileName)
            .then((data) => {
                let displayDownloadFile = new DisplayDownloadFile();
                displayDownloadFile.showFile(data.blob, data.filename);
                if (groupId == DocumentGroups.EFile && onPageReload) {
                    onPageReload();
                }
            })
            .catch(error => {
                dispatch({ type: actionTypes.NOTIFICATION, statusMessage: Constants.ArchiveReturnsConstants.downloadDocument, statusType: Notification.StatusType.Error });
            });
    },

    downloadEfileForm: (downloadableDocumentIds: number[]): AppThunkAction<KnownAction> => (dispatch, getState) => {
        fetch(API_BASE_URL + '/api/Reports/ArchivedReturns/GetArchivedDownloadEfileFormAsync', {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(downloadableDocumentIds),
            headers: {
                'Accept': 'application/json, text/plain,*/*',
                'Content-Type': 'application/json',
                'traditional': 'true',
                'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
            }
        }).then(handleBlob)
            .then((data) => {
                let displayDownloadFile = new DisplayDownloadFile();
                let todayDate = getCurrentDate().replace(/\//g, '_');
                displayDownloadFile.showFile(data, "efile_download_" + todayDate + ".zip");
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: Constants.ArchiveReturnsConstants.downloadEformFilesState,
                    statusType: Notification.StatusType.Error
                });
            })
    },

    requestTaxDocumentDownloadHistory: (id: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(API_BASE_URL + 'api/Common/ArchivedDownloadHistory/' + id + '/' + DocumentEvent.DocumentSignedEfileDownloadedByCPA, {
            method: 'GET',
            credentials: 'include'
        })
            .then(handleResponse)
            .then(response => response as Promise<IDocumentTransaction[]>)
            .then(data => {
                dispatch({ type: actionTypes.RECEIVE_ARCHIVED_TAX_DOCUMENT_DOWNLOAD_HISTORY, id: id, downloadHistory: data });
            })
            .catch(error => {
                dispatch({ type: actionTypes.ERROR_ARCHIVE_TAX_DOCUMENT, ids: [id], message: error });
            });
        addTask(fetchTask);
    },

    saveTaxDocument: (taxReturn: IArchivedTaxReturn, customColumn?: IColumnValues): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const formData = new FormData();
        let taxReturnData = JSON.stringify(taxReturn);
        formData.append('taxDocument', taxReturnData);
        let options: any = {
            method: 'PUT',
            credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
            },
            body: formData
        };
        const fetchTask = fetch(API_BASE_URL + 'api/Reports/ArchivedReturns/UpdateArchiveTaxDocument', options)
            .then(handleResponse)
            .then(() => {
                if (customColumn) {
                    let action: any = CustomColumnStore.actionCreators.updateCustomColumnValue(customColumn, false);
                    dispatch(action);
                }
                dispatch({ type: actionTypes.RECEIVE_ARCHIVE_TAX_DOCUMENT, id: taxReturn.id, taxDocument: taxReturn, source: ArchivedTaxReturnSource.ArchiveReturns });
            })
            .catch(error => {
                dispatch({ type: actionTypes.ERROR_ARCHIVE_TAX_DOCUMENT, ids: [taxReturn.id], message: error });
            });
        addTask(fetchTask);
    },

    requestTaxDocument: (id: number, force: boolean = false,  callback?: (data: any) => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let state = getState();
        if (force ||
            !state.taxDocuments[id] ||
            !state.taxDocuments[id].taxReturn ||
            state.taxDocuments[id].taxReturn.id === 0) {
            const fetchTask = fetch(API_BASE_URL + 'api/Reports/ArchivedReturns/GetArchivedTaxDocument/' + id, {
                method: 'GET',
                credentials: 'include'
            })
                .then(handleResponse)
                .then(response => response as Promise<IArchivedTaxReturn>)
                .then(data => {
                    dispatch({ type: actionTypes.RECEIVE_ARCHIVE_TAX_DOCUMENT, id: id, taxDocument: data, source: ArchivedTaxReturnSource.TaxDocument });
                    if (callback) {
                        callback(data);
                    }
                })
                .catch(error => {
                    dispatch({ type: actionTypes.ERROR_ARCHIVE_TAX_DOCUMENT, ids: [id], message: error });
                });
            addTask(fetchTask);
        }
    },
    requestArchivedSignedDetails: (id: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(API_BASE_URL + 'api/Reports/ArchivedReturns/GetArchivedSignedDetailsAsync/' + id, {
            method: 'GET',
            credentials: 'include'
        })
            .then(handleResponse)
            .then(response => response as Promise<ISignerModel[]>)
            .then(data => {
                dispatch({ type: actionTypes.RECEIVE_ARCHIVED_TAX_DOCUMENT_SIGNED_DETAILS, id: id, signedDetails: data });
            })
            .catch(error => {
                dispatch({ type: actionTypes.ERROR_ARCHIVE_TAX_DOCUMENT, ids: [id], message: error });
            });
        addTask(fetchTask);
    },

    reloadArchivedReturns: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: actionTypes.RESET_ARCHIVE_RETURNS });
    }
};

const unloadedState: IArchivedTaxReturn = {
    id: 0
} as IArchivedTaxReturn;

export const reducer: Reducer<IArchiveTaxDocumentDictionary> = (state = {}, incomingAction) => {
    const action = incomingAction as DispatchActions;
    switch (action.type) {
        case actionTypes.REQUEST_ARCHIVE_TAX_DOCUMENT:
            let loading: IArchiveTaxDocumentDictionary = { ...state };
            loading[action.id] = {
                taxReturn: state[action.id] ? state[action.id].taxReturn : unloadedState,
                isLoading: true,
                error: false,
                message: '',
                isFullyLoaded: false
            };

            return loading;

        case actionTypes.RECEIVE_ARCHIVE_TAX_DOCUMENT:
            if (action.source === ArchivedTaxReturnSource.TaxDocument || !state[action.id]) {
                let received: IArchiveTaxDocumentDictionary = { ...state };
                let message = Date();
                received[action.id] = {
                    taxReturn: action.taxDocument,
                    isLoading: false,
                    error: false,
                    message: message,
                    isFullyLoaded: action.source === ArchivedTaxReturnSource.TaxDocument ? true : false
                };
                return received;
            }
            break;

        case actionTypes.RESTORE_ARCHIVE_TAX_DOCUMENT:
            let deleteState = { ...state };
            action.ids.map((id, i) => {
                delete deleteState[id];
            });

            return deleteState;

        case actionTypes.ERROR_ARCHIVE_TAX_DOCUMENT:
            let errorState: IArchiveTaxDocumentDictionary = { ...state };
            action.ids.map((id, i) => {
                errorState[id].isLoading = false;
                errorState[id].error = true;
                errorState[id].message = action.message;
            });

            return errorState;

        case actionTypes.RECEIVE_ARCHIVE_TAX_DOCUMENT_CLIENT_TRACKING:
            let clientStatusState: IArchiveTaxDocumentDictionary = { ...state };
            let model = state[action.id].taxReturn;
            model.clientTracking = action.clientTracking;
            clientStatusState[action.id] = {
                taxReturn: model,
                isLoading: false,
                error: true,
                message: Date(),
                isFullyLoaded: false
            };
            return clientStatusState;

        case actionTypes.RECEIVE_ARCHIVE_TAX_DOCUMENT_DOWNLOADABLE_DOCUMENTS:
            let downloadableDocumentState: IArchiveTaxDocumentDictionary = { ...state };
            let downloadableDocumentTaxReturn = state[action.id].taxReturn;
            downloadableDocumentTaxReturn.downloadableDocuments = action.downloadableDocuments;
            downloadableDocumentState[action.id] = {
                taxReturn: downloadableDocumentTaxReturn,
                isLoading: false,
                error: false,
                message: Date(),
                isFullyLoaded: false
            };
            return downloadableDocumentState;
        case actionTypes.RECEIVE_ARCHIVED_TAX_DOCUMENT_DOWNLOAD_HISTORY:
            let downloadHistoryState: IArchiveTaxDocumentDictionary = { ...state };
            let downloadHistoryTaxReturn = state[action.id].taxReturn;
            downloadHistoryTaxReturn.downloadHistory = action.downloadHistory;
            downloadHistoryState[action.id] = {
                taxReturn: downloadHistoryTaxReturn,
                isLoading: false,
                error: true,
                message: Date(),
                isFullyLoaded: downloadHistoryState[action.id].isFullyLoaded
            };
            return downloadHistoryState;
        case actionTypes.RECEIVE_ARCHIVE_TAX_DOCUMENT_DOWNLOADABLE_EFILE_FORMS:
            let downloadableEfileFormState: IArchiveTaxDocumentDictionary = { ...state };
            let downloadableEfileFormTaxReturn = state[action.id].taxReturn;
            downloadableEfileFormTaxReturn.downloadableEfileForms = action.downloadableEfileForms;
            downloadableEfileFormState[action.id] = {
                taxReturn: downloadableEfileFormTaxReturn,
                isLoading: false,
                error: false,
                message: Date(),
                isFullyLoaded: false
            };
            return downloadableEfileFormState;
        case actionTypes.REQUEST_ARCHIVED_TAX_DOCUMENT_SIGNED_DETAILS:
            let signedDetailsRequestState: IArchiveTaxDocumentDictionary = { ...state };
            let signedDetailsRequestTaxReturn = state[action.id].taxReturn;
            signedDetailsRequestTaxReturn.signedDetails = [];
            signedDetailsRequestState[action.id] = {
                taxReturn: signedDetailsRequestTaxReturn,
                isLoading: true,
                error: false,
                message: '',
                isFullyLoaded: signedDetailsRequestState[action.id].isFullyLoaded
            };

            return signedDetailsRequestState;
        case actionTypes.RECEIVE_ARCHIVED_TAX_DOCUMENT_SIGNED_DETAILS:
            let signedDetailsState: IArchiveTaxDocumentDictionary = { ...state };
            let signedDetailsTaxReturn = state[action.id].taxReturn;
            signedDetailsTaxReturn.signedDetails = action.signedDetails;
            signedDetailsState[action.id] = {
                taxReturn: signedDetailsTaxReturn,
                isLoading: false,
                error: false,
                message: '',
                isFullyLoaded: signedDetailsState[action.id].isFullyLoaded
            };

            return signedDetailsState;
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            const exhaustiveCheck: never = action;
    }
    return state;
};