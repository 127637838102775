import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { LoadingOverlay, Loader } from 'react-overlay-loader';

import { WelcomeMessageList } from './WelcomeMessageList';
import * as  IWelcomeMessageStoreState from '../../../../store/settings/WelcomeMessageStore';
import { WelcomeMessageView } from './WelcomeMessageView';
import * as Bootstrap from 'react-bootstrap';
import { initialWelcomeMessage } from '../../../../Core/ViewModels/CompanySettings/ClientInstructionViewModel';
import { InstructionsActionButtonResources } from '../../../../components/helper/ResourceIdConstants';

interface IWelcomeMessageProps {
    heading: string;
    description: string;
    welcomeMessages: IWelcomeMessageStoreState.IWelcomeMessageDictionary;
    defaultMessage: number;
    selectedMessage: number;
    onListItemClick: (messageId: number) => void;
    typeId: number;
    onAddButtonClick: (defaultMessageId: number, selectedMessageId: number, typeId: number, resourceId?: string) => void;
    onEditButtonClick: (defaultMessageId: number, selectedMessageId: number, typeId: number, resourceId?: string) => void;
    onDeleteButtonClick: (selectedMessageId: number, defaultMessage: number, resourceId?: string) => void;
    showLoader: boolean;
    loaderText: string;
    parentResourceIdentifier:string;
}


export class WelcomeMessage extends React.Component<IWelcomeMessageProps, {}> {
    public render() {

        return (<LoadingOverlay>
            <div className="row">
                <div style={{ marginLeft: 16, marginBottom: 10 }}>
                    <h3 className="text-primary">{this.props.heading}</h3>
                    <i className="fas fa-info-circle"></i><span style={{ marginLeft: 5 }}>{this.props.description}</span>
                </div>
            </div>

            <div className="row" style={{ position: "relative" }}>
                <div className="col-sm-6 col-lg-4" style={{ marginLeft: 17 }}>
                    <div className="row dispFR">
                        <div className="float-right">
                            <Bootstrap.Button
                                data-test-auto="01354C39 - 6B1B-4BBD-93BF-AA0F060D441E"
                                data-resource-id = { this.props.parentResourceIdentifier + InstructionsActionButtonResources.ButtonAdd }
                                variant="info"
                                style={{ padding: 12, minWidth: 40 }}
                                onClick={() => this.props.onAddButtonClick(this.props.defaultMessage, this.props.selectedMessage, this.props.typeId, this.props.parentResourceIdentifier + InstructionsActionButtonResources.ButtonAdd)}
                                title="Add Message">
                                <span className="fas fa-plus" />
                            </Bootstrap.Button>
                        </div>
                    </div>
                    <div className="row" style={{ marginTop: 10, overflowY: 'auto', height: 207, border: 'rgb(221, 221, 221) 1px solid' }}>
                        <div className="width100">
                            <WelcomeMessageList
                                welcomeMessageList={this.props.welcomeMessages}
                                defaultMessage={this.props.defaultMessage}
                                selectedMessage={this.props.selectedMessage}
                                onListItemClick={this.props.onListItemClick}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-sm-6 col-lg-5" style={{ marginLeft: 32 }}>
                    <div className="row dispFR">
                        <div className="float-right">
                            <Bootstrap.Button
                                variant="info"
                                data-resource-id = { this.props.parentResourceIdentifier + InstructionsActionButtonResources.ButtonEdit }
                                data-test-auto="C7D673C4-5E79-4185-8CEF-72E05313FD82"
                                bsPrefix="btn btn-icon-default btn-lg fas fa-edit"
                                style={{ padding: 12, minWidth: 40 }}
                                title="Edit Message"
                                onClick={() => this.props.onEditButtonClick(this.props.defaultMessage, this.props.selectedMessage, this.props.typeId, this.props.parentResourceIdentifier + InstructionsActionButtonResources.ButtonEdit)}
                            >
                            </Bootstrap.Button>
                            <Bootstrap.Button
                                variant="info"
                                data-resource-id = { this.props.parentResourceIdentifier + InstructionsActionButtonResources.ButtonDelete }
                                data-test-auto="73194033-00F4-43F4-8875-14B9D36F5235"
                                bsPrefix="btn btn-icon-default btn-lg fas fa-trash-alt"
                                title="Delete Message"
                                style={{ marginLeft: 5, padding: 12, minWidth: 40 }}
                                onClick={() => this.props.onDeleteButtonClick(this.props.selectedMessage, this.props.defaultMessage, this.props.parentResourceIdentifier + InstructionsActionButtonResources.ButtonDelete)}
                            >
                            </Bootstrap.Button>
                        </div>
                    </div>
                    <div className="row text-message" style={{ overflow: 'auto' }}>
                        <WelcomeMessageView
                            selectedMessage={this.props.welcomeMessages[this.props.selectedMessage] ?
                                this.props.welcomeMessages[this.props.selectedMessage].welcomeMessage : initialWelcomeMessage}
                        />
                    </div>
                </div>
            </div>
            <Loader loading={this.props.showLoader} />
        </LoadingOverlay>)
    }
}