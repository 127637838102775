import * as React from 'react';
import { Button } from "react-bootstrap";
import { ImportCCHAxcess } from '../../helper/Constants';
import { useState } from 'react';
import { Loader, LoadingOverlay } from 'react-overlay-loader';
import { useRef } from 'react';
import { useEffect } from 'react';


interface HeaderProps {
    isLoggedIn:boolean,
    loggedInUsername :string,
    isLoading : boolean;
}

export const Header: React.FC<HeaderProps> = ({ isLoggedIn, loggedInUsername, isLoading }) => {

    const focusRef = useRef<HTMLSpanElement>(null);

    useEffect(() => {
        if(focusRef && focusRef.current)
            focusRef.current.focus();
    });

    const headerConstants = ImportCCHAxcess.Header;
    
    return(
        <>                  
            <div className="row">
                <div className="col-sm-12">
                    <h3>{headerConstants.title}</h3>  
                    <span className='d-flex mt-3' style={{fontSize:16}}>{headerConstants.loginTitle}</span>                      
                </div>
            </div>                
            <div className='row mt-2 mb-1'>
                <div className='col-sm-11'>
                    {isLoggedIn ?                             
                    (<div className='connection-status'><span className='status-indicator connected'></span> <span className='text-connected'>{headerConstants.connected}</span> <span> | </span><span className='font-italic'>{headerConstants.loggedInAs} {loggedInUsername}</span></div>) :
                    (<div className='connection-status'><span className='status-indicator disconnected'></span> <span>{headerConstants.notConnected}</span></div>) 
                    }       
                </div>              
            </div>
            {isLoading && (
            <LoadingOverlay>
                <Loader loading={isLoading} />
            </LoadingOverlay>                                          
            )}              
        </>
    );
};