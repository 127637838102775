import * as React from 'react';
import { ICompanySettings, PaymentOption } from '../../../Core/ViewModels/Company/CompanySettingsViewModel';
import { Link } from 'react-router-dom';
import { CheckBoxComponent } from '../../common/CheckBoxComponent';
import ToggleSwitch from '../../common/ToggleSwitch'
import StripeAccountSettings from '../../../components/settings/VoucherAndInvoiceComponent/StripeAccountSettings';
import StripeAccountDetails from '../../../components/settings/VoucherAndInvoiceComponent/StripeAccountDetails';
import { PaymentGateway } from '../../../Core/ViewModels/Purchase/PurchaseViewModels';
import { IAccountModel } from '../../../store/PaymentAccount/PaymentAccountStore';
import { IHelptextModel } from '../../../Core/ViewModels/Common/IHelptextModel';
import { fetchHelpText } from '../../../components/helper/HelperFunctions';
import { RadioButtonComponent } from '../../common/RadioButtonComponent';

interface InvoiceSettingProps  {
    companySettings: ICompanySettings
    handleEnableInvoice(event: any): void;
    handleAllowUserToSendwithoutInvoice(event: any): void;
    handleAllowUserToReplaceInvoice(event: any): void;
    handleEnablePriorToReview(event: any): void;
    handleAllowUserToSendReturnWithoutPayment(event: any): void;
    handlePaymentUrl(event: any): void;
    requestPaymentRegisterUrl(paymentGateway: PaymentGateway, standardRegister: boolean, callback?: (data: any) => void): any;
    showStripeIntegrationSettingsPopup: boolean;    
    onHideStripePopup(): void;    
    handleShowAccountDetails(event: any): void;
    showAccountDetailsPopup: boolean;
    onHideAccountDetailsPopup(): void;
    paymentAccountDetails: IAccountModel;
    helptexts?: IHelptextModel[];
    handleDisconnect(callback?:(data:any)=>void):any;
    requestStripeEditURL(callback?: (data: any) => void): any;
    handlePayOptionChange(payOption: PaymentOption): void;
}
export class InvoiceSettings extends React.Component<InvoiceSettingProps, {}> {
    handleStripeSelect = () => {
        this.props.handlePayOptionChange(PaymentOption.Stripe);
    }
    handleThirdParySelect = () => {
        this.props.handlePayOptionChange(PaymentOption.AttachedURL);
    }
    handleExternalSystemSelect = () => {
        this.props.handlePayOptionChange(PaymentOption.ExternalSystem);
    }
    public render() {
        let disable = this.props.companySettings.taxpayerPaymentSettings?.payOption &&
            this.props.companySettings.taxpayerPaymentSettings.payOption == PaymentOption.Stripe &&
            this.props.companySettings.taxpayerPaymentSettings?.companyConnectedAccountId != null ?
            "fas fa-cog taxcaddy-icon marL10" : "fas fa-cog taxcaddy-icon disableAnchor marL10";
        return <div className="new-main-content" >
            <h3 className="invoice-header-text">Invoices</h3>
            <div className="marL03">
                <label className="invoice-toggle-switch-label">Enable Invoices</label>
                <label className="miniToggleSwitch">
                    <input type="checkbox"
                        checked={this.props.companySettings.displaySettingsModel.isEnableInvoice}
                    onChange={this.props.handleEnableInvoice}
                    ></input>
                    <span className="slider round"></span>
                </label >
                
                <div className="marL03 marT10">
                    <div className="diplay-div">
                        <CheckBoxComponent
                            id={"AllowUserToSendWithoutInvoice"}
                            text={"Allow user to send without invoice"}
                            onChange={this.props.handleAllowUserToSendwithoutInvoice}
                            datatestAuto={"A6D87D07-9F94-40DB-9A3A-946E2EAB0641"}
                            checked={this.props.companySettings.userPrivilegeSettingsModel.allowUserToSendwithoutInvoice}
                        />
                        
                        <Link to={'#'}
                            className="help-icon marL05"
                            data-placement="right"
                            data-toggle="tooltip"
                            title={fetchHelpText(this.props.helptexts, "57BD922E-34A4-4BC3-A794-686F82D4E6B0")}>
                            <i className="fas fa-question-circle"></i>
                        </Link>
                    </div>
                    <div className="diplay-div">
                        <CheckBoxComponent
                            id={"AllowUserToReplaceInvoice"}
                            text={"Allow user to replace invoice"}
                            onChange={this.props.handleAllowUserToReplaceInvoice}
                            datatestAuto={"7E9FCC88-8555-47CD-9E12-A36E842B1473"}
                            checked={this.props.companySettings.userPrivilegeSettingsModel.allowUserToReplaceInvoice}
                        />
                        
                        <Link to={'#'}
                            className="help-icon marL05"
                            data-placement="right"
                            data-toggle="tooltip"
                            title={fetchHelpText(this.props.helptexts, "651FEA07-0600-44B7-9A12-1386C46B9577")}>
                            <i className="fas fa-question-circle"></i>
                        </Link>
                    </div>
                </div>
            </div>
            <hr></hr>
            {this.props.companySettings.displaySettingsModel.isEnableInvoice ?
                <div>
                    <div>
                        <h3>Invoice Payment Processing Options <Link to={'#'}
                            className="help-icon marL05"
                            data-placement="right"
                            data-toggle="tooltip"
                            title="Choose one of the payment options">
                            <i className="fas fa-question-circle"></i>
                        </Link></h3>
                        <div className="marL03">
                            <div>
                                {this.props.companySettings.taxpayerPaymentSettings ?
                                    <>
                                        <div className="row marT10">
                                            <div className="col-md-3">
                                                <RadioButtonComponent
                                                    id={"RadioStripe"}
                                                    text={"Stripe"}
                                                    className={"span-left-float"}
                                                    onChange={this.handleStripeSelect}
                                                    dataTestAuto={"5E7F90F9-A924-413F-AB4D-FAEDD2357B11"}
                                                    checked={this.props.companySettings.taxpayerPaymentSettings.payOption === PaymentOption.Stripe} />
                                                <div><a className={disable} onClick={this.props.handleShowAccountDetails}></a></div>
                                            </div>
                                        </div>
                                        {this.props.companySettings.taxpayerPaymentSettings?.payOption === PaymentOption.Stripe ?
                                            <div>
                                                <div className="row marT5 marL10">                                                    
                                                    <div className="col-md-12">
                                                        <div className="marL06">
                                                            <div className="diplay-div">
                                                                <label className="invoice-label">Required taxpayer to pay invoice prior to reviewing their tax return</label>
                                                                <label className="miniToggleSwitch">
                                                                    <input type="checkbox"
                                                                        checked={this.props.companySettings.taxpayerPaymentSettings?.priortoReview}
                                                                        onChange={this.props.handleEnablePriorToReview}
                                                                    ></input>
                                                                    <span className="slider round"></span>
                                                                </label >
                                                            </div>
                                                            <div className="diplay-div">
                                                                <CheckBoxComponent
                                                                    id={"AllowUserToSendTheReturnWithoutPaymentRequired"}
                                                                    text={"Allow user to send the return without payment required"}
                                                                    onChange={this.props.handleAllowUserToSendReturnWithoutPayment}
                                                                    datatestAuto={"5E7F90F9-A924-413F-AB4D-FAEDD2357B11"}
                                                                    checked={this.props.companySettings.taxpayerPaymentSettings?.allowUserToSendReturnWithoutPayment}
                                                                    disabled={!this.props.companySettings.taxpayerPaymentSettings?.priortoReview}
                                                                    className={"padT3"}
                                                                />
                                                                <Link to={'#'}
                                                                    className="help-icon marL05"
                                                                    data-placement="right"
                                                                    data-toggle="tooltip"
                                                                    title="Enabling this setting will allow the Staff member to deliver the return without invoice payment required.">
                                                                    <i className="fas fa-question-circle"></i>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div></div> : ""}
                                        <div className="row marT20">
                                            <div className="col-md-7">
                                                <RadioButtonComponent
                                                    id={"RadioURL"}
                                                    text={"Third Party URL"}
                                                    onChange={this.handleThirdParySelect}
                                                    dataTestAuto={"5E7F90F9-A924-413F-AB4D-FAEDD2357B11"}
                                                    checked={this.props.companySettings.taxpayerPaymentSettings.payOption === PaymentOption.AttachedURL}
                                                />
                                                {this.props.companySettings.taxpayerPaymentSettings?.payOption === PaymentOption.AttachedURL ?
                                                    <div className="diplay-div marL10">
                                                        <label className="linked-url-label fntsmall marL20">Through linked URL</label>
                                                        <div className="marL10">
                                                            <input type="text"
                                                                style={{ width: 500 }}
                                                                className="form-control"
                                                                placeholder="Enter URL"
                                                                value={this.props.companySettings.displaySettingsModel.onlinePaymentUrl ?
                                                                    this.props.companySettings.displaySettingsModel.onlinePaymentUrl : ""}
                                                                data-test-auto="AF7B52B8-7EDE-4C8A-9911-9F17A3C086B6"
                                                                data-lpignore="true"
                                                                onChange={this.props.handlePaymentUrl}
                                                                disabled={this.props.companySettings.taxpayerPaymentSettings.payOption !== PaymentOption.AttachedURL}
                                                            />
                                                        </div>
                                                    </div>
                                                    : <></>}
                                            </div>
                                        </div>
                                        <div className="row marT20">
                                            <div className="col-md-7">
                                                <RadioButtonComponent
                                                    id={"RadioExternal"}
                                                    text={"Make Payment outside of SSR System"}
                                                    onChange={this.handleExternalSystemSelect}
                                                    dataTestAuto={"5E7F90F9-A924-413F-AB4D-FAEDD2357B11"}
                                                    checked={this.props.companySettings.taxpayerPaymentSettings.payOption === PaymentOption.ExternalSystem}
                                                />
                                            </div>
                                        </div>
                                    </> : <></>}
                                {/*<ToggleSwitch
                                    switched={this.props.companySettings.taxpayerPaymentSettings?.payOption == PaymentOption.Stripe}
                                    handleChange={this.props.handleEnableStripe}
                                />*/
                                }
                            </div>
                        </div>
                    </div>                    
                    <hr></hr>
                </div> : ""}
            <StripeAccountSettings
                showStripeIntegrationSettingsPopup={this.props.showStripeIntegrationSettingsPopup}
                onHideStripePopup={this.props.onHideStripePopup}
                requestPaymentRegisterUrl={this.props.requestPaymentRegisterUrl}
            />
            <StripeAccountDetails
                showAccountDetailsPopup={this.props.showAccountDetailsPopup}
                onHideAccountDetailsPopup={this.props.onHideAccountDetailsPopup}
                paymentAccountDetails={this.props.paymentAccountDetails}
                requestStripeEditURL={this.props.requestStripeEditURL}
                handleDisconnect={this.props.handleDisconnect}
            />
        </div>
    }
}
export default InvoiceSettings;