import React from "react";

const LinkIcon = () => {
    return (
        <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M2.71476 5.54244L1.343 6.9142C0.171427 8.08577 0.171427 9.98527 1.343 11.1568C2.51457 12.3284 4.41407 12.3284 5.58564 11.1568L7.41407 9.32842C8.58564 8.15684 8.58564 6.25735 7.41407 5.08577C7.16613 4.83784 6.88559 4.64237 6.58625 4.49938L5.99985 5.08578C5.93888 5.14675 5.88742 5.21365 5.84548 5.28461C6.16092 5.37499 6.4585 5.54442 6.70696 5.79288C7.48801 6.57393 7.48801 7.84026 6.70696 8.62131L4.87853 10.4497C4.09749 11.2308 2.83116 11.2308 2.05011 10.4497C1.26906 9.66869 1.26906 8.40236 2.05011 7.62131L2.84271 6.8287C2.73052 6.40858 2.68787 5.97375 2.71476 5.54244Z"
                fill="#05386B"
            />
            <path
                d="M4.58564 3.67156C3.41407 4.84313 3.41407 6.74263 4.58564 7.9142C4.83358 8.16214 5.11411 8.3576 5.41345 8.5006L6.18905 7.72501C5.86071 7.63732 5.55034 7.46468 5.29275 7.2071C4.5117 6.42605 4.5117 5.15972 5.29275 4.37867L7.12118 2.55024C7.90222 1.76919 9.16855 1.76919 9.9496 2.55024C10.7307 3.33129 10.7307 4.59762 9.9496 5.37867L9.15699 6.17128C9.26919 6.5914 9.31184 7.02623 9.28495 7.45754L10.6567 6.08577C11.8283 4.9142 11.8283 3.01471 10.6567 1.84313C9.48514 0.671561 7.58564 0.671561 6.41407 1.84313L4.58564 3.67156Z"
                fill="#05386B"
            />
        </svg>
    );
};

export default LinkIcon;
