import { addTask } from 'domain-task';
import { Action, Dispatch, Reducer } from 'redux';
import { AppThunkAction } from '../../../';
import { actionTypes } from '../../../../types/ActionTypes';
import {
	DeliveredGroupedReturnsState,
	DeliveredGroupedReturnsDictionary,
	IControllerAccessLinkModel,
	DeliveredGroupedReturnsTableModel,
	IGroupedReturnsTransactionViewModel,
	IGroupedReturnsOTPViewModel,
} from './DeliveredGroupedReturnsState';
import { handleResponse, handleBlobwithFileName } from '../../../Library';
import {
	RequestDeliveredGroupedReturnsPagesAction,
	ReceiveDeliveredGroupedReturnsPagesAction
} from './DeliveredGroupedReturnsPagesStore';
import {
	SendReminderGroupTaxDocumentAction
} from './DeliveredGroupedReturnsHeaderStore';
import { ITaxReturn, DocumentStatus, IDownloadableDocuments, TaxDocumentRevisionStatus } from '../../../../components/common/TaxReturn';
import { StatusType, NotificationAction } from '../../../common/NotificationStore';
import { IDeliveredTaxDocument, IDeliveredTaxReturns, IDocumentGroupAndArchiveInfo } from '../../../../components/common/DeliveredTaxReturns';
import { ReceiveGroupedTaxDocumentAction } from '../../../groupedreturns/GroupedTaxDocumentStore';
import { SendReminderTaxDocumentAction, UpdateTaxDocumentCustomColumnValueAction } from '../../../common/TaxDocumentStore';
import * as Constants from '../../../../components/helper/Constants';
import { IGroupSettingsModel } from '../../../groupedreturns/GroupedReturnProcessState';
import * as Notification from '../../../../store/common/NotificationStore';
import { DisplayDownloadFile } from '../../../../components/common/DisplayDownloadFile';
import { UnlockLockedGroupedReturnDocument } from '../../../../store/reports/KnownTypes';
import { HideLoader } from '../../../../components/helper/Loader';
import { Guid } from 'guid-typescript';
import { openWindowWithPostRequest,encodeTaxDocumentHtmlTags } from '../../../../components/helper/HelperFunctions';
import { API_BASE_URL } from '../../../../utils/constants';
import { getRequestVerificationToken } from '../../../../oidcClient/helpers';
import { get } from 'http';

export interface RequestDeliveredGroupedReturnsAction {
	type: actionTypes.REQUEST_DELIVERED_GROUPED_RETURNS;
	query: string;
}

export interface ReceiveDeliveredGroupedReturnsAction {
	type: actionTypes.RECEIVE_DELIVERED_GROUPED_RETURNS;
	query: string;
	table: DeliveredGroupedReturnsTableModel;
}

export interface ResetDeliveredGroupedReturnsAction {
	type: actionTypes.RESET_DELIVERED_GROUPED_RETURNS;
}

export interface ReceiveClientTrackingAction {
	type: actionTypes.RECEIVE_GROUP_CLIENT_TRACKING;
	groupId: number;
	clientTracking: IGroupedReturnsTransactionViewModel[];
}

export interface ReceiveGroupOTPViewModelAction {
	type: actionTypes.RECEIVE_GROUP_OTP_VIEWMODEL;
	groupId: number;
    documentId: number;
	viewModel: IGroupedReturnsOTPViewModel[];
}

export interface ReceiveControllerAccessLink {
	type: actionTypes.RECEIVE_CONTROLLER_ACCESSLINK_SUCCESS;
	groupId: number;
    documentId: number;
	accessLink: IControllerAccessLinkModel;
}


export const unloadedState: DeliveredGroupedReturnsState = {
	deliveredGroupedReturnsTableModel: {
		deliveredGroupedReturns: [],
		count: 0
	} as DeliveredGroupedReturnsTableModel,
	loading: true,
	query: '',
	totalRowCount: 0,
	error: false
} as DeliveredGroupedReturnsState;

export interface RecieveGroupedReturnDownloadableDocument {
	type: actionTypes.RECEIVE_GROUPEDRETURN_DOWNLOADABLE_DOCUMENTS;
	groupId: number,
	downloadableDocuments: IDownloadableDocuments[];
}

export const unloadedDeliveredTaxDocument: IDeliveredTaxDocument = {
	customColumn: '',
	document: {},
	downloadsCount: 0,
	lastReminderOn: undefined,
	retentionPeriod: undefined,
	signedCount: 0,
	bulkDownloadCount: 0
} as IDeliveredTaxDocument;

export interface ErrorGroupedTaxDocumentAction {
	type: actionTypes.ERROR_GROUPED_RETURN;
	message: string;
}

export interface DeleteDeliveredGroupedDocumentAction {
	type: actionTypes.DELETE_DELIVERED_GROUP_DOCUMENT;
	groupIds: number[];
}

export interface RecallGroupedTaxDocumentAction {
	type: actionTypes.RECALL_GROUPED_TAX_DOCUMENT;
	groupId: number;
}

export interface ResendGoupedReturnAccessLink {
	type: actionTypes.RESEND_GROUPEDRETURN_ACCESSLINK_SUCCESS
}

export interface RequestGroupedReturnTaxDocument {
	type: actionTypes.RECEIVE_GROUPEDRETURN_TAX_DOCUMENT,
	groupId: number,
	data: ITaxReturn[]
}

export interface RequestGroupedReturnDocumentLevelTaxreturns {
	type: actionTypes.RECEIVE_GROUPEDRETURN_DOCUMENTLEVEL_TAXRETURNS,
	data: IDeliveredTaxDocument[],
	groupId: number
}

export interface RefreshGroupedReturnsTaxDocumentRecallAction {
	type: actionTypes.REFRESH_GROUPEDRETURN_TAX_DOCUMENT_RECALLACTION,
	groupId: number,
	data: ITaxReturn
}

export interface DiscardRevisedTaxDocumentAction {
	type: actionTypes.RECEIVE_DISCARDED_GROUP_REVISED_TAXDOCUMENT_STATE;
	groupId: number;
	taxDocumentId: number;
}

export interface UpdateRevisedTaxDocumentStatusAction {
	type: actionTypes.UPDATE_GROUP_REVISED_TAXDOCUMENT_STATUS;
	groupId: number;
	taxDocumentId: number;
	status: TaxDocumentRevisionStatus;
}

export interface ReceiveUpdatedGroupRevisedDeliveredReturnsAction {
	type: actionTypes.RECEIVE_UPDATED_GROUP_REVISED_TAXDOCUMENT_STATUS;
	tableData: IDeliveredTaxDocument[];
	documentGuid: string;
}

type KnownAction = RequestDeliveredGroupedReturnsAction
	| ReceiveDeliveredGroupedReturnsAction
	| RequestDeliveredGroupedReturnsPagesAction
	| ReceiveDeliveredGroupedReturnsPagesAction
	| ResetDeliveredGroupedReturnsAction
	| UpdateTaxDocumentCustomColumnValueAction
	| ReceiveClientTrackingAction
	| ReceiveControllerAccessLink
	| ErrorGroupedTaxDocumentAction
	| SendReminderTaxDocumentAction
	| SendReminderGroupTaxDocumentAction
	| DeleteDeliveredGroupedDocumentAction
	| ResendGoupedReturnAccessLink
	| RecallGroupedTaxDocumentAction
	| RecieveGroupedReturnDownloadableDocument
	| RequestGroupedReturnTaxDocument
	| ReceiveGroupOTPViewModelAction
	| Notification.NotificationAction
	| UnlockLockedGroupedReturnDocument
	| RefreshGroupedReturnsTaxDocumentRecallAction
	| DiscardRevisedTaxDocumentAction
	| UpdateRevisedTaxDocumentStatusAction
	| ReceiveUpdatedGroupRevisedDeliveredReturnsAction
	| RequestGroupedReturnDocumentLevelTaxreturns


type DispatchAction = RequestDeliveredGroupedReturnsAction
	| ReceiveDeliveredGroupedReturnsAction
	| ReceiveGroupedTaxDocumentAction
	| ResetDeliveredGroupedReturnsAction
	| UpdateTaxDocumentCustomColumnValueAction
	| ReceiveClientTrackingAction
	| SendReminderTaxDocumentAction
	| SendReminderGroupTaxDocumentAction
	| DeleteDeliveredGroupedDocumentAction
	| ResendGoupedReturnAccessLink
	| ErrorGroupedTaxDocumentAction
	| RecallGroupedTaxDocumentAction
	| RecieveGroupedReturnDownloadableDocument
	| RequestGroupedReturnTaxDocument
	| RefreshGroupedReturnsTaxDocumentRecallAction
	| DiscardRevisedTaxDocumentAction
	| UpdateRevisedTaxDocumentStatusAction
	| ReceiveUpdatedGroupRevisedDeliveredReturnsAction
	| RequestGroupedReturnDocumentLevelTaxreturns

export const actionCreators = {
	requestDeliveredGroupedReturns: (query: string, reload: boolean = false): AppThunkAction<KnownAction> => (dispatch, getState) => {
		// Only load data if it's something we don't already have (and are not already loading)
        let state = getState();      
		if (reload || query !== state.deliveredGroupedReturns.query) {

			let page = state.deliveredGroupedReturnsPages[query];
			if (!reload && page) {
				dispatch({
					type: actionTypes.RECEIVE_DELIVERED_GROUPED_RETURNS,
					query: query,
					table: page.deliveredGroupedReturnsTableModel
				});

				return;
			}

			const fetchTask = fetch(API_BASE_URL + 'api/DeliveredGroupedReturns/GetDeliveredGroupedReturnsAsync' + query, {
				method: 'GET',
				credentials: 'include'
			})
				.then(handleResponse)
				.then(response => response as Promise<DeliveredGroupedReturnsTableModel>)
				.then(data => {
					dispatch({ type: actionTypes.RECEIVE_DELIVERED_GROUPED_RETURNS, query: query, table: data });
					dispatch({ type: actionTypes.RECEIVE_DELIVERED_GROUPED_RETURNS_PAGES, query: query, table: data, totalRowCount: data.count });
				})
				.catch((error) => {
					const statusMessage: any = error.statusText?.message ?? error.statusText;
                    if (typeof(statusMessage) === "string") {
						dispatch({ type: actionTypes.NOTIFICATION, statusMessage: statusMessage, statusType: StatusType.Error })
					}
				});
			addTask(fetchTask);
			dispatch({ type: actionTypes.REQUEST_DELIVERED_GROUPED_RETURNS, query: query });
			dispatch({ type: actionTypes.REQUEST_DELIVERED_GROUPED_RETURNS_PAGES, query: query });
		}
	},
    requestGroupedReturnDocumentLevelTaxReturns: (query: string, groupId: number, reload: boolean = false, callback?: (data?: IDeliveredTaxDocument[]) => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
		let state = getState();
		let documentLevelTaxReturns = state.deliveredGroupedReturns.deliveredGroupedReturnsTableModel.deliveredGroupedReturns.find(x => x.groupId === groupId)?.taxReturns;
		if (reload || !documentLevelTaxReturns?.length) {
			const fetchTask = fetch(API_BASE_URL + 'api/DeliveredGroupedReturns/GetDeliveredGroupedDocumentsAsync' + query, {
				method: 'GET',
				credentials: 'include'
			})
				.then(handleResponse)
				.then((data: IDeliveredTaxDocument[]) => {
					dispatch({ type: actionTypes.RECEIVE_GROUPEDRETURN_DOCUMENTLEVEL_TAXRETURNS, data: data, groupId });
					if (callback) {
						callback(data);
					}
				})
				.catch((error) => {
					const statusMessage: any = error.statusText?.message ?? error.statusText;
					if (typeof(statusMessage) === "string") {
						dispatch({ type: actionTypes.NOTIFICATION, statusMessage: statusMessage, statusType: StatusType.Error });
					}
				});
				addTask(fetchTask);
		}
	},
	unlockGroupedReturnDocument: (groupId: number, callback?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
		const fetchTask = fetch(API_BASE_URL + 'api/DeliveredGroupedReturns/UnlockDocument/' + groupId, {
			method: 'PUT',
			credentials: 'include',
			headers: {
				'Accept': 'application/json, text/plain, */*',
				'Content-Type': 'application/json; charset=utf-8',
				'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
			}
		})
			.then(handleResponse)
			.then(() => {
				dispatch({ type: actionTypes.UNLOCK_LOCKED_GROUPEDRETURN_DOCUMENT, groupId: groupId });
				dispatch({ type: actionTypes.NOTIFICATION, statusMessage: Constants.DeliveredReturnsConstants.StatusMessage.UnlockDocumentSuccess, statusType: StatusType.Success });
				if (callback) {
					callback();
				}
			})
			.catch((error) => {
				const statusMessage: any = error.statusText?.message ?? error.statusText;
                if (typeof(statusMessage) === "string") {
					dispatch({ type: actionTypes.NOTIFICATION, statusMessage: statusMessage, statusType: StatusType.Error });
				}
			});
		addTask(fetchTask);
	},
	exportDeliveredGroupedReturnsAsExcel: (query: string, callback?: () => void, fileName:string="", resourceId:string=""): AppThunkAction<KnownAction> => (dispatch, getState) => {
		const fetchTask = fetch(API_BASE_URL + 'api/ExportToExcel/ExportExcelDeliveredGroupedReturnsAsync' + query, 
		{ 
			method: 'GET',
			credentials: 'include',
			headers: {
				'X-Resource-Id':resourceId,
			}
		})
			.then(response => response.blob())
			.then(blob => {
				const url = window.URL.createObjectURL(new Blob([blob]));
				const link = document.createElement('a');
				link.href = url;

				fileName = (fileName == ''? 'GroupedReturns.xlsx': fileName);
				link.setAttribute('download', fileName);
				link.target = '_blank';
				document.body.appendChild(link);
				link.click();

				let parentNode = link.parentNode;

				if (parentNode) {
					parentNode.removeChild(link);
				}

				if (callback) {
					callback();
				}
			})
			.catch((error) => {
				const statusMessage: any = error.statusText?.message ?? error.statusText;
                if (typeof(statusMessage) === "string") {
					dispatch({ type: actionTypes.NOTIFICATION, statusMessage: statusMessage, statusType: StatusType.Error });
				}
				if (callback) {
					callback();
				}
			});
		addTask(fetchTask);
	},
	updateGroupedTaxDocumentCustomColumnValue: (id: number, customColumn: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
		dispatch({ type: actionTypes.UPDATE_DOCUMENT_CUSTOM_COLUMN_VALUE, id: id, customColumn: customColumn });
	},
	resetDeliveredGroupedReturns: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
		dispatch({ type: actionTypes.RESET_DELIVERED_GROUPED_RETURNS });
	},


	requestGroupedReturnClientTracking: (groupId: number, callback?: () => void,
		resourceId:string=""): AppThunkAction<KnownAction> => (dispatch, getState) => {
		const fetchTask = fetch(API_BASE_URL + 'api/GroupLevelActions/GetClientTrackingAsync?groupId=' + groupId, {
			method: 'GET',
			credentials: 'include',
			headers: {
				'X-Resource-Id':resourceId,
			}
		})
			.then(handleResponse)
			.then(response => response as Promise<IGroupedReturnsTransactionViewModel[]>)
			.then(data => {
				dispatch({ type: actionTypes.RECEIVE_GROUP_CLIENT_TRACKING, groupId: groupId, clientTracking: data });
				if (callback) {
					callback();
				}
			})
			.catch(error => {
				dispatch({ type: actionTypes.ERROR_GROUPED_RETURN, message: error });
			});
		addTask(fetchTask);
	},

    requestGroupedReturnAccessCode: (groupId: number, documentId: number, callback?: (data: IGroupedReturnsOTPViewModel[]) => void, resourceId:string= ""): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(API_BASE_URL + 'api/GroupLevelActions/GetGroupedReturnsOTPViewModel?groupId=' + groupId + "&documentId=" + documentId, {
			method: 'GET',
			credentials: 'include',
			headers:{
				'X-Resource-Id':resourceId,
			}
		})
			.then(handleResponse)
			.then(response => response as Promise<IGroupedReturnsOTPViewModel[]>)
			.then(data => {
                dispatch({ type: actionTypes.RECEIVE_GROUP_OTP_VIEWMODEL, groupId: groupId, documentId: documentId, viewModel: data });
				if (callback) {
                    callback(data);
				}
			})
			.catch(error => {
				dispatch({ type: actionTypes.ERROR_GROUPED_RETURN, message: error });
			});
		addTask(fetchTask);
	},

    requestControllerAccessLink: (id: number, documentId: number, callback?: () => void,resourceId:string=""): AppThunkAction<KnownAction> => (dispatch, getState) => {
		const fetchTask = fetch(API_BASE_URL + 'api/GroupLevelActions/GetControllerAccessLinkModel?groupId=' + id, {
			method: 'GET',
			credentials: 'include',
			headers:{
				'X-Resource-Id':resourceId,
			}
		})
			.then(handleResponse)
			.then(response => response as IControllerAccessLinkModel)
			.then((response) => {
                dispatch({ type: actionTypes.RECEIVE_CONTROLLER_ACCESSLINK_SUCCESS, groupId: id, documentId: documentId, accessLink: response });
				if (callback) {
					callback();
				}
			})
			.catch(error => {
				const statusMessage: any = error.statusText?.message ?? error.statusText;
                if (typeof(statusMessage) === "string") {
					dispatch({
						type: actionTypes.NOTIFICATION,
						statusMessage: statusMessage,
						statusType: StatusType.Error
					});
				}
			});
		addTask(fetchTask);
	},

	deleteDeliveredGroupedReturnDocument: (groupIds: number[], isArchived: boolean, onSuccess: () => void,
	resourceId:string=""): AppThunkAction<KnownAction> => (dispatch, getState) => {
		const fetchTask = fetch(API_BASE_URL + 'api/GroupLevelActions?isArchived=' + isArchived, {
			method: 'DELETE',
			credentials: 'include',
			headers: {
				'Accept': 'application/json, text/plain, */*',
				'Content-Type': 'application/json; charset=utf-8',
				'X-Resource-Id':resourceId,
				'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
			},
			body: JSON.stringify(groupIds)
		})
			.then(handleResponse)
			.then(() => {
				dispatch({ type: actionTypes.DELETE_DELIVERED_GROUP_DOCUMENT, groupIds: groupIds });
				onSuccess();
				dispatch({
					type: actionTypes.NOTIFICATION,
					statusMessage: Constants.DeliveredGroupedReturnsConstants.StatusMessage.DeleteSuccess,
					statusType: StatusType.Success
				});
			})
			.catch(error => {
				dispatch({ type: actionTypes.ERROR_GROUPED_RETURN, message: error.statusText });
			});
		addTask(fetchTask);
	},

    recallGroupedReturnDocument: (groupId: number, isArchived: boolean, successCallback: () => void, errorCallBack: () => void, resourceId: string=""): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(API_BASE_URL + 'api/GroupLevelActions/RecallAsync/' + groupId + ' /' + isArchived, {
			method: 'GET',
			credentials: 'include',
			headers:{
				'X-Resource-Id': resourceId,
			}
		})
			.then(handleResponse)
			.then(() => {
				dispatch({ type: actionTypes.RECALL_GROUPED_TAX_DOCUMENT, groupId: groupId });
				successCallback();
			})
			.catch(error => {
				dispatch({ type: actionTypes.ERROR_GROUPED_RETURN, message: error });
				errorCallBack();
			});
		addTask(fetchTask);
	},

	recallGroupedReturnIndividualDocument: (taxReturn: ITaxReturn, groupId: number, successCallback: () => void, 
	errorCallback?: () => void,resourceId:string=""): AppThunkAction<KnownAction> => (dispatch, getState) => {
		const formData = new FormData();
		encodeTaxDocumentHtmlTags(taxReturn);
		let taxReturnData = JSON.stringify(taxReturn);
		formData.append('taxDocument', taxReturnData);
		const fetchTask = fetch(API_BASE_URL + 'api/GroupedTaxDocument/RecallReturn?groupId=' + groupId, {
			method: 'PUT',
			credentials: 'include',
			body: formData,
			headers:{
				'X-Resource-Id':resourceId,
			}
		})
			.then(handleResponse)
			.then(() => {
				dispatch({ type: actionTypes.REFRESH_GROUPEDRETURN_TAX_DOCUMENT_RECALLACTION, data: taxReturn, groupId: groupId })
				successCallback();
			})
			.catch(error => {
				if (errorCallback) {
					errorCallback();
				}
				dispatch({ type: actionTypes.ERROR_GROUPED_RETURN, message: error });
			});
		addTask(fetchTask);
	},

	resendGroupedReturnAccessLink: (id: number, callback?: () => void, resourceId: string = ""): AppThunkAction<KnownAction> => (dispatch, getState) => {
		const fetchTask = fetch(API_BASE_URL + 'api/GroupLevelActions/ResendAccessLink?groupId=' + id, {
			method: 'GET',
			credentials: 'include',
			headers:{
				'X-Resource-Id':resourceId,
			}
		})
			.then(handleResponse)
			.then((response) => {
				dispatch({ type: actionTypes.RESEND_GROUPEDRETURN_ACCESSLINK_SUCCESS });
				if (callback) {
					callback();
				}
			})
			.catch(error => {
				dispatch({
					type: actionTypes.NOTIFICATION,
					statusMessage: Constants.DeliveredGroupedReturnsTableConstants.GroupSettingsUpate.resendGroupedReturnAccessLinkFailure,
					statusType: StatusType.Error
				});
			});
		addTask(fetchTask);
	},

	requestGroupedReturnTaxDocument: (groupId: number, callback?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
		const fetchTask = fetch(API_BASE_URL + 'api/GroupLevelActions/GetTaxDocument?groupId=' + groupId, {
			method: 'GET',
			credentials: 'include'
		})
			.then(handleResponse)
			.then(response => response as Promise<ITaxReturn[]>)
			.then(data => {
				dispatch({ type: actionTypes.RECEIVE_GROUPEDRETURN_TAX_DOCUMENT, groupId: groupId, data: data });
				if (callback) {
					callback();
				}
			})
			.catch(error => {
				dispatch({ type: actionTypes.ERROR_GROUPED_RETURN, message: error });
			});
		addTask(fetchTask);
	},

	requestGroupedReturnDownloadAll: (groupId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
		fetch(API_BASE_URL + 'api/GroupLevelActions/GetDownloadAllReturn?groupId=' + groupId, {
			method: 'GET',
			credentials: 'include'
		}).then(handleBlobwithFileName)
			.then((data) => {
				let displayDownloadFile = new DisplayDownloadFile();

				displayDownloadFile.showFile(data.blob, data.filename);
				HideLoader();

			}).catch(error => {
				dispatch({ type: actionTypes.ERROR_GROUPED_RETURN, message: error });
				HideLoader();
			});
    },

    archiveGroupedTaxReturn: (ids: number[], successCallback : () => void, errorCallback: () => void , resourcedId: string = ""): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(API_BASE_URL + 'api/GroupedTaxDocument/ArchiveGroupedTaxReturn', {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8',
                'X-Resource-Id': resourcedId,
                'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
            },
            body: JSON.stringify(ids)
        })
            .then(handleResponse)
            .then(() => {
                if (successCallback)
                    successCallback()
            })
            .catch(error => {
                dispatch({ type: actionTypes.ERROR_GROUPED_RETURN, message: error.statusText });
                if (errorCallback)
                    errorCallback()
            });
        addTask(fetchTask);
    },


    restoreGroupedTaxReturn: (ids: number[], successCallback: () => void, errorCallback: () => void, resourcedId: string = ""): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(API_BASE_URL + 'api/GroupedTaxDocument/RestoreGroupedTaxReturn', {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8',
                'X-Resource-Id': resourcedId,
                'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
            },
            body: JSON.stringify(ids)
        })
            .then(handleResponse)
            .then(() => {
                if (successCallback)
                    successCallback()
            })
            .catch(error => {
                dispatch({ type: actionTypes.ERROR_GROUPED_RETURN, message: error.statusText });
                if (errorCallback)
                    errorCallback()
            });
        addTask(fetchTask);
    },



	updateDeliveredReturnsVoucherReminder: (groupId: number, allowVoucherReminder: boolean, voucherReminderInDays: number,
		allowSigningReminder: boolean, signingReminderInDays: number, callback?: () => void,resourceId: string= ""): AppThunkAction<KnownAction> => (dispatch, getState) => {
			let state = getState();
			const param = `?groupId=${groupId}&allowVoucherReminder=${allowVoucherReminder}&voucherReminderInDays=${voucherReminderInDays}&allowSigningReminder=${allowSigningReminder}&signingReminderInDays=${signingReminderInDays}`;
			const fetchTask = fetch(API_BASE_URL + 'api/GroupLevelActions/UpdateDeliveredReturnsVoucherReminder' + param, {
				method: 'PUT',
				credentials: 'include',
				headers: {
					'Accept': 'application/json, text/plain, */*',
					'Content-Type': 'application/json; charset=utf-8',
					'X-Resource-Id': resourceId,
					'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
				}
			})
				.then(handleResponse)
				.then(() => {
					//let deliveredGroupedReturns = state.deliveredGroupedReturns.deliveredGroupedReturnsTableModel;
					//const index = deliveredGroupedReturns.deliveredGroupedReturns.findIndex(x => x.groupId === groupId);
					//deliveredGroupedReturns.deliveredGroupedReturns[index].enableSigningReminder = allowSigningReminder;
					//dispatch({ type: actionTypes.RECEIVE_DELIVERED_GROUPED_RETURNS, query: state.deliveredGroupedReturns.query, table: deliveredGroupedReturns });
					if (callback)
						callback()
				})
				.catch(error => {
					dispatch({ type: actionTypes.ERROR_GROUPED_RETURN, message: error.statusText });
				});
			addTask(fetchTask);
	},

	updateGroupedReturnDocumentNotificationSettings: (documentId: number, allowVoucherReminder: boolean, voucherReminderInDays: number,
		allowSigningReminder: boolean, signingReminderInDays: number, callback?: () => void,resourceId: string= ""): AppThunkAction<KnownAction> => (dispatch, getState) => {
			let state = getState();
			const param = `?documentId=${documentId}&allowVoucherReminder=${allowVoucherReminder}&voucherReminderInDays=${voucherReminderInDays}&allowSigningReminder=${allowSigningReminder}&signingReminderInDays=${signingReminderInDays}`;
			const fetchTask = fetch(API_BASE_URL + 'api/DeliveredGroupedReturns/UpdateGroupedReturnReminders/' + param, {
				method: 'PUT',
				credentials: 'include',
				headers: {
					'Accept': 'application/json, text/plain, */*',
					'Content-Type': 'application/json; charset=utf-8',
					'X-Resource-Id': resourceId,
					'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
				}
			})
				.then(handleResponse)
				.then(() => {
					if (callback)
						callback()
				})
				.catch(error => {
					dispatch({ type: actionTypes.ERROR_GROUPED_RETURN, message: error.statusText });
				});
			addTask(fetchTask);
	},

    requestGroupedReturnOtpGenerate: (groupId: number, documentId: number, clientGUID: string, callBack?: () => void, resourceId:string="'"): AppThunkAction<KnownAction> => (dispatch, getState) => {
		const fetchTask = fetch(API_BASE_URL + 'api/GroupLevelActions/GenerateOtp/' + clientGUID, {
			method: 'PUT',
			credentials: 'include',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'X-Resource-Id':resourceId,
				'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
			}
		})
			.then(handleResponse)
			.then((response) => {
                let action: any = actionCreators.requestGroupedReturnAccessCode(groupId, documentId, undefined, resourceId);
				dispatch(action);
				if (callBack) {
					callBack();
				}
			})
			.catch(error => {
				dispatch({ type: actionTypes.ERROR_GROUPED_RETURN, message: error.statusText });
			});
		addTask(fetchTask);
	},

	requestGenerateTaxPayerView: (groupId: number, resourceId: string): AppThunkAction<KnownAction> => (dispatch, getstate) => {
		const fetchTask = fetch(API_BASE_URL + 'api/ProcessGroupedTaxDocument/GenerateControllerViewAsync?groupId=' + groupId, {
			method: 'GET',
			credentials: 'include',
			headers: {
				'X-Resource-Id': resourceId,
			}
		})
			.then(response => response.json())
			.then((data) => {
				HideLoader();
				openWindowWithPostRequest(data.url, getRequestVerificationToken(), 'CPAToken', Constants.PreviewConstants.Scope.ControllerView, null, getstate().userProfile?.userId);
			})
			.catch(error => {
				dispatch({ type: actionTypes.ERROR_GROUPED_RETURN, message: error.statusText });
			});
		addTask(fetchTask);
	},

	discardGroupRevisedTaxDocuments: (id: number, groupId: number, callback?: () => void, resourceId: string = ""): AppThunkAction<KnownAction> => (dispatch, getState) => {
		let options: any = {
			method: 'PUT',
			credentials: 'include',
			headers: {
				'Accept': 'application/json',
				'X-Resource-Id': resourceId,
				'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
			},
		};
		const fetchTask = fetch(API_BASE_URL + 'api/RevisedTaxDocument/DiscardRevisedTaxDocument/' + id, options)
			.then(handleResponse)
			.then((data) => {
				if (data) {
					dispatch({ type: actionTypes.RECEIVE_DISCARDED_GROUP_REVISED_TAXDOCUMENT_STATE, taxDocumentId: id, groupId: groupId });
					dispatch({
						type: actionTypes.NOTIFICATION,
						statusMessage: Constants.FinishProcessReturn.StatusMessage.DiscardRevisedTaxDocumentSuccess,
						statusType: StatusType.Success
					});
					callback && callback();
				}
				else {
					dispatch({
						type: actionTypes.NOTIFICATION,
						statusMessage: Constants.FinishProcessReturn.StatusMessage.DiscardRevisedTaxDocumentError,
						statusType: StatusType.Error
					});
				}
			})
			.catch(error => {
				dispatch({
					type: actionTypes.NOTIFICATION,
					statusMessage: Constants.FinishProcessReturn.StatusMessage.DiscardRevisedTaxDocumentError,
					statusType: StatusType.Error
				});
			});
		addTask(fetchTask);
	},

	discardGroupUnrecognizedRevisedTaxDocuments: (id: number, resourceId: string = ""): AppThunkAction<KnownAction> => (dispatch, getState) => {
		let options: any = {
			method: 'PUT',
			credentials: 'include',
			headers: {
				'Accept': 'application/json',
				'X-Resource-Id': resourceId,
				'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
			},
		};
		const fetchTask = fetch(API_BASE_URL + 'api/RevisedTaxDocument/DiscardUnRecognizedTaxDocument/' + id, options)
			.then(handleResponse)
			.then(() => {
			})
			.catch(error => {
				const statusMessage: any = error.statusText?.message ?? error.statusText;
                if (typeof(statusMessage) === "string") {
					dispatch({ type: actionTypes.NOTIFICATION, statusMessage: statusMessage, statusType: StatusType.Error });
				}
			});
		addTask(fetchTask);
    },

    requestDocumentGroupAndArchiveInfo: (documentGuid: string, callback: (data: any) => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(API_BASE_URL + 'api/DeliveredGroupedReturns/GetGroupIdByDocumentGuidAsync?documentGuid=' + documentGuid , {
            method: 'GET',
            credentials: 'include'
        })
            .then(handleResponse)
            .then(response => response as Promise<IDocumentGroupAndArchiveInfo>)
            .then(data => {
                //if (state.groupedTaxDocuments[documentId]) { // notifcationmessage not contain documentid in case voucher rivision
                //	dispatch(actionCreators.requestUpdatedRevisionDeliveredGroupedReturns(state.deliveredGroupedReturns.query, documentGuid));
                //}
                 if (callback) {
                    callback(data);
                }
            })
            .catch((error) => {
                const statusMessage: any = error.statusText?.message ?? error.statusText;
                if (typeof (statusMessage) === "string") {
                    dispatch({ type: actionTypes.NOTIFICATION, statusMessage: statusMessage, statusType: StatusType.Error })
                }
            });
        addTask(fetchTask);
    },
    //updateDeliveredGroupedReturns: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
    //    let state = getState();
    //    dispatch({ type: actionTypes.RESET_DELIVERED_GROUPED_RETURNS });
    //    dispatch(actionCreators.requestDeliveredGroupedReturns(state.deliveredGroupedReturns.query, true));
    //},
	updateGroupReviseDocumentMergedStatus: (id: number, status: TaxDocumentRevisionStatus, groupId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
		dispatch({ type: actionTypes.UPDATE_GROUP_REVISED_TAXDOCUMENT_STATUS, taxDocumentId: id, groupId: groupId, status: status });
	},

    updateDeliveredGroupedReturnRevisionStatus: (documentGuid: string, documentId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(API_BASE_URL + 'api/DeliveredGroupedReturns/GetGroupIdByDocumentGuidAsync?documentGuid=' + documentGuid, {
            method: 'GET',
            credentials: 'include'
        })
            .then(handleResponse)
            .then(response => response as Promise<IDocumentGroupAndArchiveInfo>)
            .then(data => { 
                //if (state.groupedTaxDocuments[documentId]) { // notifcationmessage not contain documentid in case voucher rivision
                //	dispatch(actionCreators.requestUpdatedRevisionDeliveredGroupedReturns(state.deliveredGroupedReturns.query, documentGuid));
                //}
                let query =
                    "?searchString=" +
                    "" +
                    "&isArchived=" +
                    data.isArchived +
                    "&groupId=" +
                    data.groupId;

                dispatch(actionCreators.requestUpdatedRevisionDeliveredGroupedReturns(query, documentGuid));                
            })
            .catch((error) => {
                const statusMessage: any = error.statusText?.message ?? error.statusText;
                if (typeof (statusMessage) === "string") {
                    dispatch({ type: actionTypes.NOTIFICATION, statusMessage: statusMessage, statusType: StatusType.Error })
                }
            });
        addTask(fetchTask);
	},
    requestUpdatedRevisionDeliveredGroupedReturns: (query: string, documentGuid: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(API_BASE_URL + 'api/DeliveredGroupedReturns/GetDeliveredGroupedDocumentsAsync' + query, {
			method: 'GET',
			credentials: 'include'
		})
			.then(handleResponse)
			.then(response => response as Promise<IDeliveredTaxDocument[]>)
			.then(data => {
				dispatch({ type: actionTypes.RECEIVE_UPDATED_GROUP_REVISED_TAXDOCUMENT_STATUS, tableData: data, documentGuid: documentGuid });
			})
			.catch((error) => {
				const statusMessage: any = error.statusText?.message ?? error.statusText;
                if (typeof(statusMessage) === "string") {
					dispatch({ type: actionTypes.NOTIFICATION, statusMessage: statusMessage, statusType: StatusType.Error })
				}
			});
		addTask(fetchTask);
	},
    cancelGroupedReturnSigningReminder: (taxDocument: ITaxReturn): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const formData = new FormData();
		encodeTaxDocumentHtmlTags(taxDocument);
        formData.append('taxDocument', JSON.stringify(taxDocument));
        const fetchTask = fetch(API_BASE_URL + 'api/DeliveredGroupedReturns/CancelSigningReminder/', {
            method: 'POST',
            credentials: 'include',
            body: formData,
            headers: {
                'Accept': 'application/json',
                'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
            },
        })
		.then(handleResponse)
		.then((response) => {})
		.catch(error => {
			const statusMessage: any = error.statusText?.message ?? error.statusText;
			if (typeof(statusMessage) === "string") {
				dispatch({ type: actionTypes.NOTIFICATION, statusMessage: statusMessage, statusType: StatusType.Error });
			}
		});
        addTask(fetchTask);
    }
}

export const reducer: Reducer<DeliveredGroupedReturnsState> = (state: DeliveredGroupedReturnsState = unloadedState, incomingAction: Action) => {
	const action = incomingAction as DispatchAction;
	let index = -1;
	let returnIndex = -1;
	switch (action.type) {
		case actionTypes.REFRESH_GROUPEDRETURN_TAX_DOCUMENT_RECALLACTION:
			let refreshTableModel = state.deliveredGroupedReturnsTableModel;
			const refreshDeliveredReturnIndex = refreshTableModel.deliveredGroupedReturns.findIndex(x => x.groupId === action.groupId);
			if (refreshTableModel.deliveredGroupedReturns[refreshDeliveredReturnIndex]
				&& refreshTableModel.deliveredGroupedReturns[refreshDeliveredReturnIndex].taxReturns) {
				let recallIndex = refreshTableModel.deliveredGroupedReturns[refreshDeliveredReturnIndex].taxReturns.findIndex(x => x.document.id === action.data.id);
				refreshTableModel.deliveredGroupedReturns[refreshDeliveredReturnIndex].taxReturns.splice(recallIndex, 1);
				if (refreshTableModel.deliveredGroupedReturns[refreshDeliveredReturnIndex].taxReturns.length === 1) {
					refreshTableModel.deliveredGroupedReturns.splice(refreshDeliveredReturnIndex, 1);
				}
			}
			return state;
		case actionTypes.REQUEST_DELIVERED_GROUPED_RETURNS:
			return ({
				...unloadedState,
				query: action.query,
				loading: true
			}) as DeliveredGroupedReturnsState;
		case actionTypes.RECEIVE_DELIVERED_GROUPED_RETURNS:
			return {
				...state,
				query: action.query,
				deliveredGroupedReturnsTableModel: action.table,
				totalRowCount: action.table.count,
				loading: false
			} as DeliveredGroupedReturnsState;
		case actionTypes.RECEIVE_GROUP_CLIENT_TRACKING:
			let clientTrackiinTable = state.deliveredGroupedReturnsTableModel;
			let clientTrackingModel = clientTrackiinTable.deliveredGroupedReturns.find(x => x.groupId === action.groupId);
			if (clientTrackingModel)
				clientTrackingModel.controllerTracking = action.clientTracking;
			state.loading = false;
			return state;
		//case actionTypes.RECEIVE_GROUP_OTP_VIEWMODEL:
		//	let otpViewTableModel = state.deliveredGroupedReturnsTableModel;
		//	let otpViewModel = otpViewTableModel.deliveredGroupedReturns.find(x => x.groupId === action.groupId);
		//	if (otpViewModel)
		//		otpViewModel.groupedReturnOTPViewModel = action.viewModel;
		//	state.loading = false;
		//	return state;
		case actionTypes.RECALL_GROUPED_TAX_DOCUMENT:
			const recallIndex = state.deliveredGroupedReturnsTableModel.deliveredGroupedReturns.findIndex((m, i) => m ? m.groupId === action.groupId : false)
			delete state.deliveredGroupedReturnsTableModel.deliveredGroupedReturns[recallIndex];
			return state;

		case actionTypes.DELETE_DELIVERED_GROUP_DOCUMENT:
			let deleteDeliveredState = { ...state };
			action.groupIds.map((id, i) => {
				const deleteIndex = state.deliveredGroupedReturnsTableModel.deliveredGroupedReturns.findIndex((m, i) => m ? m.groupId === id : false)
				delete state.deliveredGroupedReturnsTableModel.deliveredGroupedReturns[deleteIndex];
			});
            return deleteDeliveredState;

		case actionTypes.ERROR_GROUPED_RETURN:
			let errorState = { ...state }
			errorState.loading = false;
			errorState.error = true;
			errorState.message = action.message;
			break;

		//case actionTypes.RECEIVE_CONTROLLER_ACCESSLINK_SUCCESS:
		//	let groupReturnModel = state.deliveredGroupedReturnsTableModel.deliveredGroupedReturns.find((model, i) => model.groupId === action.groupId);
		//	if (groupReturnModel)
		//		groupReturnModel.controllerViewModel = action.accessLink;
		//	state.loading = false;
		//	return { ...state };

		case actionTypes.RECEIVE_GROUPEDRETURN_DOWNLOADABLE_DOCUMENTS:
			let downloadableDocumentState = { ...state };
			let downloadableState = downloadableDocumentState.deliveredGroupedReturnsTableModel.deliveredGroupedReturns.find((model, i) => model.groupId === action.groupId);
			if (downloadableState)
				downloadableState.taxReturns.map((doc, i) => {
					let downloadableDocument = action.downloadableDocuments.filter((d, i) => d.documentId === doc.document.id)
					if (downloadableDocument)
						doc.document.downloadableDocuments = downloadableDocument
				})
			return downloadableDocumentState;

		case actionTypes.RECEIVE_GROUPEDRETURN_TAX_DOCUMENT:
			let taxDocumentState = { ...state };
			let taxDocuments = taxDocumentState.deliveredGroupedReturnsTableModel.deliveredGroupedReturns.find((model, i) => model.groupId === action.groupId);
			if (taxDocuments && taxDocuments.taxReturns) {
				let i: number = 0;
				const length = taxDocuments.taxReturns.length;
				const id = taxDocuments.taxReturns[i].document.id;
				for (i = 0; i < length; i++) {
					let latestTaxDocument = action.data.find((d, i) => d.id === id);
					if (latestTaxDocument) {
						taxDocuments.taxReturns[i].document.documentSettings = latestTaxDocument.documentSettings
					}
				}
			}
			return taxDocumentState;

		case actionTypes.RECEIVE_GROUPEDRETURN_DOCUMENTLEVEL_TAXRETURNS:
			let taxDocumentStateGR = { ...state };
			let documentLevelTaxReturns = taxDocumentStateGR.deliveredGroupedReturnsTableModel.deliveredGroupedReturns.find((model) => model.groupId === action.groupId);
			if (documentLevelTaxReturns) {
				documentLevelTaxReturns.taxReturns = action.data;
			}
			return taxDocumentStateGR;

		case actionTypes.RESEND_GROUPEDRETURN_ACCESSLINK_SUCCESS:
		case actionTypes.RECEIVE_GROUPED_TAX_DOCUMENT:
		case actionTypes.UPDATE_DOCUMENT_CUSTOM_COLUMN_VALUE:
		case actionTypes.SEND_REMINDER_TAX_DOCUMENT:
			return updateState(action.type, state, action);
		case actionTypes.SEND_REMINDER_GROUP_TAX_DOCUMENT:
			return updateStates(action.type, state, action);
		case actionTypes.RESET_DELIVERED_GROUPED_RETURNS:
			return clearGroupedTaxReturn(state);

		case actionTypes.RECEIVE_DISCARDED_GROUP_REVISED_TAXDOCUMENT_STATE:
			let discardedState = { ...state };
			let documents = discardedState.deliveredGroupedReturnsTableModel.deliveredGroupedReturns.filter((model, i) => model.groupId === action.groupId)[0];
			const taxReturnIndex = documents.taxReturns.findIndex(x => x.document.id == action.taxDocumentId);
			if (taxReturnIndex > -1) {
				documents.taxReturns[taxReturnIndex].hasRevisionDocumentReady = false;
				documents.taxReturns[taxReturnIndex].hasUploadedRevisionDocument = false;
			}
			return discardedState;

		case actionTypes.UPDATE_GROUP_REVISED_TAXDOCUMENT_STATUS:
			let mergedRevisedTaxDocumentState = { ...state };
			let groupTaxdocuments = mergedRevisedTaxDocumentState.deliveredGroupedReturnsTableModel.deliveredGroupedReturns.filter((model, i) => model.groupId === action.groupId)[0];
			const docIndex = groupTaxdocuments.taxReturns.findIndex(x => x.document.id == action.taxDocumentId);
			if (docIndex > -1) {
				if (action.status == TaxDocumentRevisionStatus.Merged) {
					groupTaxdocuments!.taxReturns[docIndex].hasRevisionDocumentMerged = true;
				}
				else if (action.status == TaxDocumentRevisionStatus.DeliveryInProgress) {
					groupTaxdocuments.taxReturns[docIndex].hasRevisionDocumentReady = false;
					groupTaxdocuments.taxReturns[docIndex].hasUploadedRevisionDocument = false;
				}
			}
			return mergedRevisedTaxDocumentState;

        case actionTypes.RECEIVE_UPDATED_GROUP_REVISED_TAXDOCUMENT_STATUS:
            let groupRevisedTaxdocuments = { ...state };
            let reportIndex = -1, taxreturnIndex = -1, reportGroupIndex = -1;
            groupRevisedTaxdocuments.deliveredGroupedReturnsTableModel.deliveredGroupedReturns.map((model, i) => {
                const searchIndex = model.taxReturns.findIndex(x => x.document.documentGuid === action.documentGuid);
                if (searchIndex > -1) {
                    reportIndex = searchIndex;
                    reportGroupIndex = i;
                }
            });

            const searchTaxIndex = action.tableData.findIndex(x => x.document.documentGuid === action.documentGuid);
            if (searchTaxIndex > -1) {
                taxreturnIndex = searchTaxIndex;
            }
			if (taxreturnIndex > -1 && reportIndex > -1) {

				groupRevisedTaxdocuments.deliveredGroupedReturnsTableModel.deliveredGroupedReturns[reportGroupIndex]
					.taxReturns[reportIndex].hasRevisionDocumentReady = action.tableData[taxreturnIndex].hasRevisionDocumentReady;

				groupRevisedTaxdocuments.deliveredGroupedReturnsTableModel.deliveredGroupedReturns[reportGroupIndex]
					.taxReturns[reportIndex].hasUploadedRevisionDocument = action.tableData[taxreturnIndex].hasUploadedRevisionDocument;

				groupRevisedTaxdocuments.deliveredGroupedReturnsTableModel.deliveredGroupedReturns[reportGroupIndex]
					.taxReturns[reportIndex].hasRevisionDocumentMerged = action.tableData[taxreturnIndex].hasRevisionDocumentMerged;
			}
			return groupRevisedTaxdocuments;

		default:
			// The following line guarantees that every action in the KnownAction union has been covered by a case above
			const exhaustiveCheck: NotificationAction = action;
	}
	return state || unloadedState;
}

function clearGroupedTaxReturn(state: DeliveredGroupedReturnsState): DeliveredGroupedReturnsState {
	return {
		...unloadedState,
		query: state.query,
		isLoading: true
	} as DeliveredGroupedReturnsState;
}

function updateState(type: actionTypes, state: DeliveredGroupedReturnsState, action: DispatchAction): DeliveredGroupedReturnsState {
	let i: number = -1;
	let oldDocument: IDeliveredTaxDocument = unloadedDeliveredTaxDocument;
	let document: IDeliveredTaxReturns = {} as IDeliveredTaxReturns;
	let customColumn: string = "";
	let id: number = 0;
	switch (action.type) {
		case actionTypes.RECEIVE_GROUPED_TAX_DOCUMENT:
			document = action.taxDocument as IDeliveredTaxReturns;
			id = action.id;
			break;
		case actionTypes.UPDATE_DOCUMENT_CUSTOM_COLUMN_VALUE:
			customColumn = action.customColumn;
			id = action.id;
			break;
		case actionTypes.SEND_REMINDER_TAX_DOCUMENT:
			id = action.id;
			break;
	}
	if (state.deliveredGroupedReturnsTableModel.deliveredGroupedReturns) {
		state.deliveredGroupedReturnsTableModel.deliveredGroupedReturns.forEach((groupedReturn, index) => {

			if (groupedReturn && groupedReturn.taxReturns) {
				groupedReturn.taxReturns.forEach((taxReturn, taxReturnIndex) => {
					if (taxReturn.document.id === id) {
						i = taxReturnIndex;
						oldDocument = taxReturn;
						return;
					}
				});
			}
		});
	}
	if (i !== -1) {
		let deliveredTaxDocument: IDeliveredTaxDocument = {
			document: action.type == actionTypes.RECEIVE_GROUPED_TAX_DOCUMENT ? document : oldDocument.document,
			customColumn: action.type == actionTypes.UPDATE_DOCUMENT_CUSTOM_COLUMN_VALUE ? customColumn : oldDocument.customColumn,
			downloadsCount: oldDocument.downloadsCount,
			signedCount: oldDocument.signedCount,
			lastReminderOn: action.type == actionTypes.SEND_REMINDER_TAX_DOCUMENT ? new Date() : oldDocument.lastReminderOn,
			retentionPeriod: oldDocument.retentionPeriod,
			lockType: oldDocument.lockType,
			clientGuid: oldDocument.clientGuid,
			isDocumentLocked: oldDocument.isDocumentLocked,
			bulkDownloadCount: oldDocument.bulkDownloadCount,
			documentFilingType: oldDocument.documentFilingType,
			hasRevisionDocumentReady: oldDocument.hasRevisionDocumentReady,
			hasUploadedRevisionDocument: oldDocument.hasUploadedRevisionDocument,
			hasRevisionDocumentMerged: oldDocument.hasRevisionDocumentMerged
		};

		let updatedState = { ...state };
		let groupedReturns = updatedState.deliveredGroupedReturnsTableModel.deliveredGroupedReturns;
		for (let i = 0; i < groupedReturns.length; i++) {
			const taxReturns = groupedReturns[i].taxReturns;
			for (let j = 0; j < taxReturns.length; j++) {
				if (taxReturns[j].document.id === deliveredTaxDocument.document.id) {
					taxReturns[j] = deliveredTaxDocument;
				}
			}
		}

		return updatedState;
	}
	return state;
}


function updateStates(type: actionTypes, state: DeliveredGroupedReturnsState, action: DispatchAction): DeliveredGroupedReturnsState {
	let documentIds: number[] = [];
	let oldDocuments: IDeliveredTaxDocument[] = [];
	switch (action.type) {
		case actionTypes.SEND_REMINDER_GROUP_TAX_DOCUMENT:
			documentIds = action.documentIds;
			break;
	}
	if (state.deliveredGroupedReturnsTableModel.deliveredGroupedReturns) {
		state.deliveredGroupedReturnsTableModel.deliveredGroupedReturns.forEach((groupedReturn, index) => {

			if (groupedReturn && groupedReturn.taxReturns) {
				groupedReturn.taxReturns.forEach((taxReturn) => {
					if (documentIds.indexOf(taxReturn.document.id) > -1) {
						oldDocuments.push(taxReturn);
						return;
					}
				});
			}
		});
	}
	if (documentIds.length > 0) {

		let updatedState = { ...state };
		let groupedReturns = updatedState.deliveredGroupedReturnsTableModel.deliveredGroupedReturns;

		for (let i = 0; i < groupedReturns.length; i++) {
			const taxReturns = groupedReturns[i].taxReturns;
			for (let j = 0; j < taxReturns.length; j++) {
				var updatedDoc = oldDocuments.find(d => d.document && d.document.id === taxReturns[j].document.id)
				if (updatedDoc) {
					let deliveredTaxDocument: IDeliveredTaxDocument = {
						document: updatedDoc.document,
						customColumn: updatedDoc.customColumn,
						downloadsCount: updatedDoc.downloadsCount,
						signedCount: updatedDoc.signedCount,
						lastReminderOn: action.type == actionTypes.SEND_REMINDER_GROUP_TAX_DOCUMENT ? new Date() : updatedDoc.lastReminderOn,
						retentionPeriod: updatedDoc.retentionPeriod,
						lockType: updatedDoc.lockType,
						clientGuid: updatedDoc.clientGuid,
						isDocumentLocked: updatedDoc.isDocumentLocked,
						bulkDownloadCount: updatedDoc.bulkDownloadCount,
						documentFilingType: updatedDoc.documentFilingType,
						hasRevisionDocumentReady: updatedDoc.hasRevisionDocumentReady,
						hasUploadedRevisionDocument: updatedDoc.hasUploadedRevisionDocument,
						hasRevisionDocumentMerged: updatedDoc.hasRevisionDocumentMerged
					};
					taxReturns[j] = deliveredTaxDocument;
				}
			}
		}
		return updatedState;
	}
	return state;
}
