import { Action, Reducer } from 'redux';
import { actionTypes } from '../../../../types/ActionTypes';
import {
	InUseGroupedReturnsTableModel,
	InUseGroupedReturnsState,
	InUseGroupedReturnsDictionary
} from './InUseGroupedReturnsState'
//import { ResetInUseGroupedReturnsAction } from './InUseGroupedReturnsStore';

export interface RequestInUseGroupedReturnsPagesAction {
	type: actionTypes.REQUEST_INUSE_GROUPED_RETURNS_PAGES;
	query: string;
}

export interface ReceiveInUseGroupedReturnsPagesAction {
	type: actionTypes.RECEIVE_INUSE_GROUPED_RETURNS_PAGES;
	query: string;
	table: InUseGroupedReturnsTableModel;
	totalRowCount: number;
}
const unloadedState: InUseGroupedReturnsState = {
	inUseGroupedReturnsTableModel: {},
	query: '?',
	loading: false,
	totalRowCount: 0
} as InUseGroupedReturnsState;

type DispatchAction = RequestInUseGroupedReturnsPagesAction
	| ReceiveInUseGroupedReturnsPagesAction;

export const actionCreators = {};

export const reducer: Reducer<InUseGroupedReturnsDictionary> = (state: InUseGroupedReturnsDictionary = { "?": unloadedState }, incomingAction: Action) => {
	const action = incomingAction as DispatchAction;
	switch (action.type) {
		case actionTypes.REQUEST_INUSE_GROUPED_RETURNS_PAGES:
			if (!state[action.query]) {
				let added = { ...unloadedState } as InUseGroupedReturnsState;
				added.loading = true;
				added.query = action.query;
				let item = {} as InUseGroupedReturnsDictionary;
				item[action.query] = added;

				return {
					...state,
					...item
				};
			}
			break;
		case actionTypes.RECEIVE_INUSE_GROUPED_RETURNS_PAGES:
			if (state[action.query]) {
				let changed = state[action.query] as InUseGroupedReturnsState;
				changed.loading = false;
				changed.inUseGroupedReturnsTableModel = action.table;
				changed.totalRowCount = action.totalRowCount;
				let item = {} as InUseGroupedReturnsDictionary;
				item[action.query] = changed;
				return {
					...state,
					...item
				};
			}
			break;
		//case actionTypes.RESET_INUSE_GROUPED_RETURNS:
		//	return clearGroupedReturnsPages();
		default:
			// The following line guarantees that every action in the KnownAction union has been covered by a case above
			const exhaustiveCheck: never = action;
	}
	return state || { "?": unloadedState };
};

//function clearGroupedReturnsPages(): InUseGroupedReturnsDictionary {
//	return {} as InUseGroupedReturnsDictionary;   //clearing the dictionary on archive/recall
//}