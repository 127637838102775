import * as React from 'react';
import * as Bs from 'react-bootstrap';
import { TaxCaddyIntegrationPopup } from '../GeneralSettingsComponents/TaxCaddyIntegrationPopup';
import { IReportingChannelSettings, ICompanySettings, ReportingEngineType, ITaxCaddySettings } from '../../../Core/ViewModels/Company/CompanySettingsViewModel';
import ToggleSwitch from '../../common/ToggleSwitch';
import * as Validation from '../../helper/Validations';
import * as Constants from '../../helper/Constants';
import { TaxReturnSettingsResources } from '../../../components/helper/ResourceIdConstants';
interface IReportingChannelProps {
    getSettings(reload: boolean): void;
    updateCompanySettings(companySettings: ICompanySettings, resourceId?: string): void;
    companySettings: ICompanySettings;
    parentResourceIdentifier: string;
}
interface IReportingChannelStates {
    checked: boolean,
    showTaxCaddyPopup: boolean,
    isByPassManualVerification: boolean,
    isDirectDeliveryToTaxCaddy: boolean,
    isTaxCaddyReportingChannelEnabled: boolean,
    firmIdExists: boolean,
    switched: boolean,
    firmIds: string[],
    alert: string,
    message: string,
    isValidFirmKey: boolean
}

const msg = {
    warning: 'Please add Firm ID(s) to enable Taxcaddy integration.',
}

export class ReportingChannel extends React.Component<IReportingChannelProps, IReportingChannelStates>{
    constructor(props: IReportingChannelProps) {
        super(props);
        this.state = {
            checked: false,
            showTaxCaddyPopup: false,
            isByPassManualVerification: false,
            isDirectDeliveryToTaxCaddy: false,
            isTaxCaddyReportingChannelEnabled: false,
            firmIdExists: true,
            switched: false,
            firmIds: [],
            alert: 'warning',
            message: msg.warning,
            isValidFirmKey: true,
        }
        this.handleTaxCaddySettingsClick = this.handleTaxCaddySettingsClick.bind(this);
        this.onHide = this.onHide.bind(this);
        this.handleByPassManualVerificationChange = this.handleByPassManualVerificationChange.bind(this);
        this.handleIsDirectDeliveryToTaxCaddy = this.handleIsDirectDeliveryToTaxCaddy.bind(this);
        this.onTaxCaddySettingsSaveButtonClick = this.onTaxCaddySettingsSaveButtonClick.bind(this);
        this.handleToggleSwitchChange = this.handleToggleSwitchChange.bind(this);
        this.onChangeTagValue = this.onChangeTagValue.bind(this);
        this.onValidate = this.onValidate.bind(this);
    }

    UNSAFE_componentWillReceiveProps() {
        if (this.props.companySettings && this.props.companySettings.reportingChannelSettings) {
            this.props.companySettings.reportingChannelSettings.map((value, index) => {
                if (value.reportingEngine == ReportingEngineType.TaxCaddy) {
                    let taxCaddySetting: ITaxCaddySettings = value.settings as ITaxCaddySettings;
                    this.setState({
                        isByPassManualVerification: taxCaddySetting.isSkipManualVerification,
                        isDirectDeliveryToTaxCaddy: this.props.companySettings.deliverySettingsModel.isDirectDeliveryToTaxCaddy,
                        isTaxCaddyReportingChannelEnabled: value.enabled,
                        firmIdExists: taxCaddySetting.firmIds.length > 0 ? true : false,
                        firmIds: taxCaddySetting.firmIds,
                        alert: taxCaddySetting.firmIds.length > 0 ? "info" : "warning",
                        message: msg.warning
                    });

                    if (value.enabled && taxCaddySetting.firmIds.length > 0) {
                        this.setState({ switched: true });
                    }
                    else {
                        this.setState({ switched: false });
                    }
                }
            })
        }
    }

    onHide() {
        this.setState({
            showTaxCaddyPopup: false
        });
        this.props.getSettings(true);
    }

    handleToggleSwitchChange = (event: any) => {
        if (event.currentTarget.checked) {
            this.setState({ switched: true });
        } else {
            this.setState({ switched: false });
        }

        if (this.state.firmIdExists) {
            this.props.companySettings.reportingChannelSettings.map((value: IReportingChannelSettings, index: number) => {
                if (value.reportingEngine == ReportingEngineType.TaxCaddy) {
                    value.enabled = event.currentTarget.checked;
                }
            });
            this.props.updateCompanySettings(this.props.companySettings, this.props.parentResourceIdentifier + TaxReturnSettingsResources.ToggleEnableOrDisable);
        }
        else {
            this.setState({
                showTaxCaddyPopup: true
            });
        }
    };

    handleTaxCaddySettingsClick() {
        this.setState({
            showTaxCaddyPopup: true
        })
    }

    handleByPassManualVerificationChange(event: any) {
        this.setState({ isByPassManualVerification: event.currentTarget.checked });
    }

    handleIsDirectDeliveryToTaxCaddy(event: any) {
        this.setState({ isDirectDeliveryToTaxCaddy: event.currentTarget.checked });
    }

    onTaxCaddySettingsSaveButtonClick() {
        if (this.state.isValidFirmKey) {
            let temp = this.props.companySettings as ICompanySettings;
            temp.reportingChannelSettings.map((value: IReportingChannelSettings, index: number) => {
                if (value.reportingEngine == ReportingEngineType.TaxCaddy) {
                    let taxCaddySetting: ITaxCaddySettings = value.settings as ITaxCaddySettings;
                    value.enabled = this.state.firmIds.length > 0 ? true : false;
                    taxCaddySetting.isSkipManualVerification = this.state.isByPassManualVerification;
                    taxCaddySetting.firmIds = this.state.firmIds;
                }
            });
            temp.deliverySettingsModel.isDirectDeliveryToTaxCaddy = this.state.isDirectDeliveryToTaxCaddy;
            temp.deliverySettingsModel.isByPassManualVerification = this.state.isByPassManualVerification;
            this.props.updateCompanySettings(this.props.companySettings, this.props.parentResourceIdentifier + TaxReturnSettingsResources.ToggleEnableOrDisable);

            this.setState({
                showTaxCaddyPopup: false
            })
        }
    }

    onChangeTagValue(e: any, firmIds: string[]) {
        this.setState({
            firmIds: firmIds,
            alert: firmIds.length > 0 ? "info" : "warning",
            message: msg.warning,
            isTaxCaddyReportingChannelEnabled: firmIds.length > 0 ? this.state.isTaxCaddyReportingChannelEnabled : false,
            isValidFirmKey: true,
        });
    }

    onValidate(key: string[]) {
        if (key[0] && !Validation.isValidFirmKey(key[0])) { 
            this.setState({
                isValidFirmKey: false,
                alert: "warning",
                message: Constants.ValidationContants.FirmKeyWarning,
            });
        }
        else { 
            this.setState({
                isValidFirmKey: true,
                alert: "info",
                message: "",
            });
        }
    }

    public render() {
        const alertMessage = !this.state.firmIdExists ?
            (<Bs.Alert variant="warning" style={{ padding: "6px" }} >
                <i className='fas fa-exclamation-triangle' style={{ marginRight: '5px' }}></i>
                {msg.warning}
            </Bs.Alert>) : null;

        return <div className="new-main-content">
            <h3>Integration</h3>
            <div className="marL20">
                <Bs.Row>
                    <Bs.Col className="text-left" sm={2} as={Bs.FormLabel} style={{ fontSize: "large", paddingTop: "3px" }}>
                        TaxCaddy
                    </Bs.Col>
                    <Bs.Col sm={4} md={3} lg={2}
                        data-resource-id = { this.props.parentResourceIdentifier + TaxReturnSettingsResources.ToggleEnableOrDisable }
                    >
                        <ToggleSwitch switched={this.state.switched}
                            handleChange={this.handleToggleSwitchChange} />
                        <a className="fas fa-cog taxcaddy-icon" 
                        onClick={this.handleTaxCaddySettingsClick}></a>
                    </Bs.Col>                    
                    <Bs.Col sm={6} md={7} lg={8}>
                        {
                            alertMessage
                        }
                    </Bs.Col>
                </Bs.Row>
            </div>
            <TaxCaddyIntegrationPopup
                onHide={this.onHide}
                showState={this.state.showTaxCaddyPopup}
                isByPassManualVerification={this.state.isByPassManualVerification}
                isDirectDeliveryToTaxCaddy={this.state.isDirectDeliveryToTaxCaddy}
                onSaveButtonClick={this.onTaxCaddySettingsSaveButtonClick}
                handleByPassManualVerificationChange={this.handleByPassManualVerificationChange}
                handleIsDirectDeliveryToTaxCaddy={this.handleIsDirectDeliveryToTaxCaddy}
                onChangeTagValue={this.onChangeTagValue}
                firmIds={this.state.firmIds}
                alert={this.state.alert}
                message={this.state.message}
                onValidate={this.onValidate}
                isValidFirmKey={this.state.isValidFirmKey}
            />
        </div>
    }
}