import React from "react";
import { useSelector } from "react-redux";
import { DropdownComponent } from "./controls/DropdownComponent";
import { ApplicationState } from "../../store/types";
import { IParterUserModel } from "../../Core/ViewModels/User/UserViewModel";

export interface IEROSignerProps {
    id: string;
    testId: string;
    options?: IParterUserModel[];
    onChange: (event: any) => void;
    selectedValue: number | string;
    disabled?: boolean;
    className?: string;
}

export default function EROSignerDropdown(props: IEROSignerProps) {
    const { partnerUsers } = useSelector((state: ApplicationState) => state.partnerUsersList);

    const EROUserList = () => {
        var listValues: any = [];
        partnerUsers.map((ERO: IParterUserModel) => {
            listValues.push({
                value: ERO.id,
                label: ERO.firstName + " " + ERO.lastName
            });
        });
        return listValues;
    };

    return (
        <DropdownComponent
            id={props.id}
            data-test-auto={props.testId}
            options={props.options ? props.options : EROUserList()}
            onChange={props.onChange}
            selectedValue={props.selectedValue}
            disabled={props.disabled}
            className={props.className}
        />
    );
}
