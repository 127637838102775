import { addTask } from "domain-task";
import { Action, Reducer } from "redux";
import { AppThunkAction } from "../../../";
import { actionTypes } from "../../../../types/ActionTypes";
import { StatusType, NotificationAction } from "../../../common/NotificationStore";
import { handleResponse } from "../../../Library";
import {
    IGroupAccess,
    GroupedReturnSendReminderRequest,
    IDocumentGroupAccessSaveModel
} from "./DeliveredGroupedReturnsHeaderState";
import * as Constants from "../../../../components/helper/Constants";
import { API_BASE_URL } from "../../../../utils/constants";
import { logger } from "../../../../routes/LoggedIn";

export interface SendReminderGroupTaxDocumentAction {
    type: actionTypes.SEND_REMINDER_GROUP_TAX_DOCUMENT;
    documentIds: number[];
}
type KnownAction = SendReminderGroupTaxDocumentAction | NotificationAction;

const NO_INDEX = -1;

export const actionCreators = {
    requestGroupAccessByGroupIds:
        (ids: number[], callback?: (result: IGroupAccess) => void, resourceId: string = ""): AppThunkAction<KnownAction> =>
        (dispatch, getState) => {
            let queryString: string = "";
            ids.map((id: number, index: number) => {
                if (index === 0) {
                    queryString = "?groups=" + id;
                } else {
                    queryString += "&groups=" + id;
                }
            });

            const fetchTask = fetch(API_BASE_URL + "api/document-group-access" + queryString, {
                method: "GET",
                credentials: "include",
                headers: {
                    Accept: "application/json, text/plain, */*",
                    "Content-Type": "application/json; charset=utf-8",
                    "X-Resource-Id": resourceId,
                    RequestVerificationToken: (document.getElementById("RequestVerificationToken") as HTMLInputElement).value
                }
            })
                .then((response) => response.json() as Promise<IGroupAccess>)
                .then((data) => {
                    if (callback) {
                        callback(data);
                    }
                })
                .catch(function (error) {
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: Constants.DeliveredGroupedReturnsHeaderConstants.StatusMessage.GroupAccessRequestError,
                        statusType: StatusType.Error
                    });
                });
            addTask(fetchTask);
        },
    updateGroupAccess:
        (
            accessMaps: IDocumentGroupAccessSaveModel,
            callback?: () => void,
            failurecallback?: () => void,
            resourceId: string = ""
        ): AppThunkAction<KnownAction> =>
        (dispatch, getState) => {
            let options: any = {
                method: "PUT",
                credentials: "include",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json; charset=utf-8",
                    "X-Resource-Id": resourceId,
                    RequestVerificationToken: (document.getElementById("RequestVerificationToken") as HTMLInputElement).value
                },
                body: JSON.stringify(accessMaps)
            };
            const fetchTask = fetch(API_BASE_URL + "api/document-group-access", options)
                .then(handleResponse)
                .then(() => {
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: Constants.DeliveredGroupedReturnsHeaderConstants.StatusMessage.UpdateGroupAccessSuccess,
                        statusType: StatusType.Success
                    });
                    if (callback) {
                        callback();
                    }
                    logger.trackTrace(
                        `updateGroupAccess saved successfully for groupId: ${accessMaps.documentGroups.map((x) => x)?.toString()}`
                    );
                })
                .catch(function (error) {
                    if (failurecallback) {
                        failurecallback();
                    }
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: Constants.DeliveredGroupedReturnsHeaderConstants.StatusMessage.UpdateGroupAccessError,
                        statusType: StatusType.Error
                    });
                });
            addTask(fetchTask);
        },

    sendReminderForGroupedReturn:
        (
            sendReminderRequest: GroupedReturnSendReminderRequest[],
            callback?: () => void,
            failurecallback?: () => void,
            resourceId: string = ""
        ): AppThunkAction<KnownAction> =>
        (dispatch, getState) => {
            let request = JSON.stringify(sendReminderRequest);
            let selectedDocumentIds: number[] = getSelectedDocumentIds(sendReminderRequest);
            let options: any = {
                method: "POST",
                credentials: "include",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json; charset=utf-8",
                    "X-Resource-Id": resourceId,
                    RequestVerificationToken: (document.getElementById("RequestVerificationToken") as HTMLInputElement).value
                },
                body: request
            };
            const fetchTask = fetch(API_BASE_URL + "api/TaxDocumentMailEvent/SendReminderForGroupedReturn", options)
                .then(handleResponse)
                .then(() => {
                    dispatch({
                        type: actionTypes.SEND_REMINDER_GROUP_TAX_DOCUMENT,
                        documentIds: selectedDocumentIds
                    });
                    if (callback) {
                        callback();
                    }
                    logger.trackTrace(
                        `send reminder for group return sent successfully for documentIds: ${sendReminderRequest
                            .map((x) => x.documentId)
                            ?.toString()} and groupId: ${sendReminderRequest.map((x) => x.groupId)?.toString()}`
                    );
                })
                .catch(function (error) {
                    if (failurecallback) {
                        failurecallback();
                    }
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: Constants.DeliveredGroupedReturnsHeaderConstants.StatusMessage.SendSignReminderError,
                        statusType: StatusType.Error
                    });
                });
            addTask(fetchTask);
        }
};

function getSelectedDocumentIds(sendReminderRequest: GroupedReturnSendReminderRequest[]): number[] {
    let documentIds: number[] = [];
    for (let i = 0; i < sendReminderRequest.length; i++) {
        sendReminderRequest[i].documentId.map((documentId, index) => {
            documentIds.push(documentId);
        });
    }
    return documentIds;
}
