import { addTask } from 'domain-task';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../';
import { actionTypes } from '../../types/ActionTypes';
import { handleResponse } from './../Library';
import { StatusType, NotificationAction } from '../common/NotificationStore';
import { Guid } from '../../Core/Utilities/Guid';
import { withRouter } from 'react-router';
import { IGroupedReturnsModel, IGroupK1Settings, IGroupedReturnsClientInfoModel } from './GroupedReturnProcessState';
import { IGroupLevelReturnDictionary } from './GroupedReturnProcessState'; 
import {
    IControllerInfo, IGroupDeliverySettings,
    IGroupNotificationSettings, IGroupRetentionSettings,
    IGroupSignatureSettings, IGroupSettingsModel
} from './GroupedReturnProcessState';
import {
    ClientTypes, SignatureType, ITaxReturn, IPaperReturnSettings, KBAMode,
    DeliveryMode, PaperReturnDeliveryType
} from '../../components/common/TaxReturn';
import { DefaultSenderInfo, DefaultSenderInfoType } from '../../components/navigation/profile/MySettings';
import * as Constants from '../../components/helper/Constants';
import * as Notification from '../common/NotificationStore';
import { K1MailCycles } from '../../Core/ViewModels/Company/CompanySettingsViewModel';
import { API_BASE_URL } from '../../utils/constants';
import { decodeGroupReturnDataHtmlTags,encodeGroupReturnDataHtmlTags } from '../../components/helper/HelperFunctions';

export const unloadedGroupK1Settings: IGroupK1Settings = {
    k1ClientInfoSettings: {
        k1MailCycle: K1MailCycles.After,
        noOfDays:30
    },
    k1PartnerInfoSettings: {
        k1MailCycle: K1MailCycles.After,
        noOfDays: 30
    }
}
export const unloadedGroupNotificationSettings: IGroupNotificationSettings = {
    notifyUser: {
        email: '',
        firstName: '',
        lastName: '',
        userId: 0
    },
    paymentVoucherNotificationSettingsModel: {
        enablePaymentVoucherReminder: false,
        noOfDaysForVoucherReminder: 0,
        paymentNotification: true

    },
    signingReminderNotificationSettingsModel: {
        enableSigningReminder: false,
        noOfDaysForSigningReminder: 0
    },
    voucherPaymentNotifyUser: {
        email: '',
        firstName: '',
        lastName: '',
        userId: 0
    }
}

export const unloadedGroupDeliveryState: IGroupDeliverySettings = {
    deliverTo: ClientTypes.Taxpayer,
    contactPerson: 0,
    preparerMessage: {
        body: '',
        id: 0,
        isAllowToEdit: false,
        name: ''
    },
    sender: {
        senderId: 0,
        senderType: DefaultSenderInfoType.User
    },
    notifyUser: 0,
    manualAddressId: 0,
    integrationSettings: [],
    reportingEngine: '',
    allowUserToDistributeK1: false,
    isDirectDeliveryToTaxCaddy: false,
    isByPassManualVerification: false,
    deliveryMode: DeliveryMode.ElectronicFiled,
    paperReturnSettings: {
        deliveryType: PaperReturnDeliveryType.None,
        documentOrder: [],
        isNotifyUserEnabled: false
    },
    watermarkId: 0,
    selectedTaxDocumentId: [],
    voucherPaymentNotifyUser: 0,
    paperFilePreparerMessage: {
        body: '',
        id: 0,
        isAllowToEdit: false,
        name: ''
    },
}

export const unloadedGroupRetentionSetting: IGroupRetentionSettings = {
    retentionPeriod: 0
}

export const unloadedGroupSignatureSetting: IGroupSignatureSettings = {
    kbaMode: KBAMode.Live,
    knowledgeBasedAuthentication: false,
    signatureFormSelectionType: SignatureType.ESign,
    signatureStampUser: {
        email: '',
        firstName: '',
        lastName: '',
        userId: 0
    }
}
export enum ControllerInfoProperty {
    name,
    emailAddress,
    ssn,
    mobileNumber,
    countryCode,
}

export const unloadedGroupLevelData: IGroupedReturnsModel = {
    groupInfo: {
        id: 0,
        name: '',
        isDeleted: false,
        controllerInfo: {
            clientGuid: Guid.newGuid(),
            countryCode: "",
            emailAddress: "",
            groupId: 0,
            mobileNumber: "",
            name: "",
            ssn: ""
        },
        createdBy: 1,
        createdOn: new Date(2020, 11, 1),
        groupAccess: {},
        groupSettings: {
            groupId: 0,
            groupDeliverySettings: unloadedGroupDeliveryState,
            groupNotificationSettings: unloadedGroupNotificationSettings,
            groupRetentionSettings: unloadedGroupRetentionSetting,
            groupSignatureSettings: unloadedGroupSignatureSetting,
            groupK1Settings:unloadedGroupK1Settings
        },
        isModified: false
    },
    taxReturns: [{}]
}


export const unloadedState: IGroupLevelReturnDictionary = {
    [-1]: {
        groupLevelReturnsData: unloadedGroupLevelData,
        isLoading: false,
        error: false,
        message: ''
    }
}

export interface RequestGroupControllerInfoAction {
    type: actionTypes.REQUEST_GROUP_LEVEL_DATA;
    loading: boolean;
    groupId: number;
}

export interface RecieveGroupControllerInfoAction {
    type: actionTypes.RECEIVE_GROUP_LEVEL_DATA;
    loading: boolean;
    groupId: number;
    isModified: boolean;
    groupControllerInfoModel: IGroupedReturnsModel;
}

export interface RequestGroupControllerSaveAction {
    type: actionTypes.REQEUST_GROUPLEVEL_SAVE;
    updating: boolean;
}

export interface RecieveGroupControllerSaveAction {
    type: actionTypes.RECEIVE_GROUPLELVEL_SAVE_RESULT;
    success: boolean;
    updating: boolean;
}

export interface RequestGroupReturnClientInfoAction {
    type: actionTypes.REQEUST_GROUPRETURN_CLIENTINFO;
} 

export interface ReceiveGroupReturnClientInfoAction {
    type: actionTypes.RECEIVE_GROUPRETURN_CLIENTINFO_SAVE;
    data: boolean;
}

export const initialGroupInfo = {
    id: 0,
    name: "",
    createdBy: 0,
    createdOn: new Date(),
    isDeleted: false,
    controllerInfo: {
        clientGuid: Guid.newGuid(),
        countryCode: "",
        emailAddress: "",
        groupId: 0,
        mobileNumber: "",
        name: "",
        ssn: "",
    },
    groupAccess: {},
    groupSettings: {
        groupDeliverySettings: unloadedGroupDeliveryState,
        groupId: 0,
        groupNotificationSettings: unloadedGroupNotificationSettings,
        groupRetentionSettings: unloadedGroupRetentionSetting,
        groupSignatureSettings: unloadedGroupSignatureSetting,
        groupK1Settings: unloadedGroupK1Settings
    },
    isModified: false
}

type KnownAction = RequestGroupControllerInfoAction
    | RecieveGroupControllerInfoAction
    | NotificationAction
    | RequestGroupControllerSaveAction
    | RecieveGroupControllerSaveAction
    | RequestGroupReturnClientInfoAction
    | ReceiveGroupReturnClientInfoAction


export const actionCreators = {
    requestGroupLevelData: (groupId: number, force: boolean, callback?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let state = getState();
        dispatch({
            type: actionTypes.REQUEST_GROUP_LEVEL_DATA,
            loading: true,
            groupId: groupId
        })

        if (force
            || !state.groupedReturnProcessState[groupId]
            || !state.groupedReturnProcessState[groupId].groupLevelReturnsData
            || !(state.groupedReturnProcessState[groupId].groupLevelReturnsData.groupInfo.id === 0)) {
            const fetchTask = fetch(API_BASE_URL + 'api/GroupLevelProcessing/GetGroupLevelDataAsync/?groupId=' + groupId, {
                method: 'GET',
                credentials: 'include'
            })
                .then(handleResponse)
                .then(response => response as IGroupedReturnsModel)
                .then(data => {
                    dispatch({
                        type: actionTypes.RECEIVE_GROUP_LEVEL_DATA,
                        groupId: groupId,
                        loading: false,
                        isModified: false,
                        groupControllerInfoModel: data
                    });
                    if (callback) {
                        callback();
                    }
                })
                .catch((error) => {
                    const statusMessage: any = error.statusText?.message ?? error.statusText;
                    if (typeof(statusMessage) === "string") {
                        dispatch({
                            type: actionTypes.NOTIFICATION,
                            statusMessage: statusMessage,
                            statusType: StatusType.Error
                        });
                    }
                    if (callback) {
                        callback();
                    }
                });
            addTask(fetchTask);
        }
    },

    updateControllerInfoData: (data: IControllerInfo, callback?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let state = getState();

        dispatch({
            type: actionTypes.RECEIVE_GROUP_LEVEL_DATA,
            groupId: data.groupId,
            loading: false,
            isModified: true,
            groupControllerInfoModel: {
                ...state.groupedReturnProcessState[data.groupId]?.groupLevelReturnsData,
            },
        });
        if (callback) {
            callback();
        }
    },

    saveGroupLevelReturnData: (groupReturnsModel: IGroupedReturnsModel, 
        callback: (clientGuid?:Guid) => void,
        resourceId:string=""): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({
            type: actionTypes.REQEUST_GROUPLEVEL_SAVE,
            updating: true,
        });
        encodeGroupReturnDataHtmlTags(groupReturnsModel);
        const state = getState();
        const fetchTask = fetch(API_BASE_URL + 'api/GroupLevelProcessing', {
            method: 'PUT',
            credentials: 'include',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8',
                'X-Resource-Id':resourceId,
                'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
            },
            body:JSON.stringify(groupReturnsModel)
        })
            .then(handleResponse)
            .then((data) => {
                dispatch({
                    type: actionTypes.RECEIVE_GROUPLELVEL_SAVE_RESULT,
                    success: true,
                    updating: false,
                })
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: Constants.SaveandCloseProcessReturn.StatusMessage.Success,
                    statusType: Notification.StatusType.Success
                });
                callback(data);
            })
            .catch(function (error) {
                dispatch({
                    type: actionTypes.RECEIVE_GROUPLELVEL_SAVE_RESULT,
                    success: false,
                    updating: false,
                });
                callback();
            })
        addTask(fetchTask);
    },

    requestGrouLevelTaxDocument: (id: number, callback?: (data: any) => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let state = getState();
        const fetchTask = fetch(API_BASE_URL + 'api/TaxDocument/' + id + '/' + false, {
                method: 'GET',
                credentials: 'include'
            })
                .then(handleResponse)
                .then(response => response as Promise<ITaxReturn>)
                .then(data => {
                    if (callback) {
                        callback(data);
                    }
                })
                .catch(error => {
                    
                });
            addTask(fetchTask);
    },

    saveGroupReturnClientInfoData: (groupedReturnsClientInfoModel: IGroupedReturnsClientInfoModel, successCallback: () => void,       
        resourceId: string = ""): AppThunkAction<KnownAction> => (dispatch, getState) => {
            dispatch({
                type: actionTypes.REQEUST_GROUPRETURN_CLIENTINFO
            });
            const state = getState();
            const fetchTask = fetch(API_BASE_URL + 'api/tax-return-action-handler/grouped-return-client-info', {
                method: 'PUT',
                credentials: 'include',
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json; charset=utf-8',
                    'X-Resource-Id': resourceId,
                    'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
                },
                body: JSON.stringify(groupedReturnsClientInfoModel)
            })
                .then(handleResponse)
                .then((data) => {
                    dispatch({
                        type: actionTypes.RECEIVE_GROUPRETURN_CLIENTINFO_SAVE,
                        data: data
                    })
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: Constants.SaveandCloseProcessReturn.StatusMessage.Success,
                        statusType: StatusType.Success
                    });  
                    successCallback();                  
                })
                .catch(function (error) {
                    dispatch({
                        type: actionTypes.RECEIVE_GROUPRETURN_CLIENTINFO_SAVE,
                        data: false
                    });                   
                })
            addTask(fetchTask);
        }
}

export const reducer: Reducer<IGroupLevelReturnDictionary> = (state: IGroupLevelReturnDictionary = unloadedState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case actionTypes.REQUEST_GROUP_LEVEL_DATA:
            let groupLevelData: IGroupLevelReturnDictionary = { ...state };
            groupLevelData[action.groupId] = {
                error: false,
                isLoading: true,
                message: '',
                groupLevelReturnsData: unloadedGroupLevelData
            }
            return groupLevelData as IGroupLevelReturnDictionary
        case actionTypes.RECEIVE_GROUP_LEVEL_DATA:
            let newState: IGroupLevelReturnDictionary = { ...state }
            decodeGroupReturnDataHtmlTags(action.groupControllerInfoModel);
            newState[action.groupId] = {
                error: false,
                isLoading: action.loading,
                message: '',
                groupLevelReturnsData:action.groupControllerInfoModel
            }
            return newState
        case actionTypes.REQEUST_GROUPLEVEL_SAVE:
            return {
                groupLevelReturnSaving: true
            }
        case actionTypes.RECEIVE_GROUPLELVEL_SAVE_RESULT:
            return {
                ...unloadedState,
                groupLevelReturnSaving: false
            } as IGroupLevelReturnDictionary
        case actionTypes.REQEUST_GROUPRETURN_CLIENTINFO:
            return {
                ...unloadedState,
                isLoading: true,
            }
        case actionTypes.RECEIVE_GROUPRETURN_CLIENTINFO_SAVE:
            return {
                ...unloadedState,
                isLoading: false, 
            }
    }
    return state || unloadedState;
}
