import * as React from 'react';

export class EmptyInbox extends React.Component<{}>{
    render() {
        return <div className="textalign-center">
            <i className="far fa-meh fa-10x" style={{ paddingTop: '50px' }} />
            <h4>You don't have any notifications</h4>
            <h5>Please check back later</h5>
        </div>
    }
}
