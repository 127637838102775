import * as React from "react";
import { RouteComponentProps } from "react-router";
import { SignatureFormSelection } from "./SignaturteComponents/SignatureFormSelection";
import { KnowledgeBasedAuthentication } from "./SignaturteComponents/KnowledgeBasedAuthentication";
import { EROSignatures } from "./SignaturteComponents/EROSignatures";
import { AdditionalDocumentType } from "./SignaturteComponents/AdditionalDocumentType";
import { EsignatureForBusinessReturns } from "./SignaturteComponents/ESignatureForBusinessReturns";
import { SigningReminderNotification } from "./SignaturteComponents/SigningReminderNotification";
import {
    ICompanySettings,
    SignatureFormSelectionType,
    initialCompanySettings,
    ISuiteNotificationSetting
} from "../../Core/ViewModels/Company/CompanySettingsViewModel";
import { SettingsHeader } from "../common/settings/SettingsHeader";
import * as CompanyStore from "../../store/company/CompanyStore";
import * as MailingReturnAddressStore from "../../store/common/MailingReturnAddressStore";
import { OverlayLoader } from "../helper/OverlayLoader";
import * as UserSettingsStore from "../../store/userManagement/UserSettingStore";
import * as AdditionalDocumentTypeStore from "../../store/common/AdditionalDocumentTypeStore";
import { EfileFormSettings } from "./SignaturteComponents/EfileTabSettings";
import { SignerDelegation } from "./SignaturteComponents/SignerDelegation";
import * as ApplicationHelptextStore from "../../store/common/ApplicationHelptextStore";
import { CustomRouterPrompt } from "./CustomRouterPrompt";
import { TaxReturnSettingsResources } from "../../components/helper/ResourceIdConstants";
import * as SuiteNotificationSettingStore from "../../store/company/SuiteNotificationSettingStore";

interface ISigntaureSettingsState {
    companySettings: ICompanySettings;
    disableESignature: boolean;
    documentValues: IDocumentTypeValue[];
    showState: boolean;
    showCustomPrompt: boolean;
    smsSigningReminderChanged: boolean;
}

export interface IDocumentTypeValue {
    id: number;
    value: string;
    inUseCount: number;
}

type SignatureSettingsProps = {
    companyData: CompanyStore.ICompanyData;
    mailingReturnAddress: MailingReturnAddressStore.IMailingReturnAddressStoreState;
    settings: UserSettingsStore.UserSettings;
    additionalDocumentTypeData: AdditionalDocumentTypeStore.IDocumentTypeState;
    helptexts: ApplicationHelptextStore.IHelptextState;
    suiteNotificationSetting: ISuiteNotificationSetting;
} & typeof CompanyStore.actionCreators &
    typeof MailingReturnAddressStore.actionCreators &
    typeof UserSettingsStore.actionCreators &
    typeof AdditionalDocumentTypeStore.actionCreators &
    typeof ApplicationHelptextStore.actionCreators &
    typeof SuiteNotificationSettingStore.actionCreators &
    RouteComponentProps<{ page: string }>;

export class Signatures extends React.Component<SignatureSettingsProps, ISigntaureSettingsState> {
    constructor(props: SignatureSettingsProps) {
        super(props);
        this.state = {
            showState: false,
            disableESignature: false,
            companySettings: initialCompanySettings,
            documentValues: [],
            showCustomPrompt: false,
            smsSigningReminderChanged: false
        };

        this.handleEsignAllReturns = this.handleEsignAllReturns.bind(this);
        this.handleEsignWhenAllAllowed = this.handleEsignWhenAllAllowed.bind(this);
        this.handleEnableManualEsignAllReturns = this.handleEnableManualEsignAllReturns.bind(this);
        this.handleApplyButton = this.handleApplyButton.bind(this);
        this.handleResetButton = this.handleResetButton.bind(this);
        this.handleEnableKBA = this.handleEnableKBA.bind(this);
        this.handleSignerDeligation = this.handleSignerDeligation.bind(this);
        this.handleUseSignatureStamp = this.handleUseSignatureStamp.bind(this);
        this.handleEsignatureForBusinessReturn = this.handleEsignatureForBusinessReturn.bind(this);
        this.handleSettingSigningReminderDays = this.handleSettingSigningReminderDays.bind(this);
        this.handleAllowDisablingKBAOnReturnLevel = this.handleAllowDisablingKBAOnReturnLevel.bind(this);
        this.openDocumentTypePopup = this.openDocumentTypePopup.bind(this);
        this.onHide = this.onHide.bind(this);
    }
    componentDidMount() {
        const element = document.getElementById("content-wrapper");
        element && element.scrollTo(0, 0);
    }
    UNSAFE_componentWillMount() {
        this.props.requestTaxingAuthorities();
        this.props.requestCompanySettings(true);
        this.props.requestUserSettings(true);
        this.props.requestMailingReturnAddressList();
        this.props.requestCompanyProfile();
        this.props.requestAdditionalDocumentType(true);
        this.props.requestHelptextsForApplication(true);
        this.props.requestSuiteNotificationSetting();
    }

    UNSAFE_componentWillReceiveProps(nextporps: SignatureSettingsProps) {
        if (nextporps.companyData.companySettings != undefined) {
            this.setState({
                disableESignature: nextporps.companyData.companySettings.signatureSettingsModel
                    ? nextporps.companyData.companySettings.signatureSettingsModel.signatureFormSelectionType ==
                      SignatureFormSelectionType.ManualSign
                    : false
            });
        }
    }

    private enableCustomRouterPrompt = () => {
        this.setState({ showCustomPrompt: true });
    };

    private disableCustomRouterPrompt = () => {
        this.setState({ showCustomPrompt: false });
    };

    //////////////////////////////// Signature settings start /////////////////////////////////////////////////////////
    handleApplyButton(event?: any, resourceId?: string) {
        let temp = this.props.companyData.companySettings as ICompanySettings;
        let userSettings = this.props.settings.settings;
        userSettings.useSignatureStamp = temp.signatureSettingsModel.useSignatureStamp;
        userSettings.enableSignerDeligation = temp.signatureSettingsModel.enableSignerDeligation;
        this.props.saveUserSettings(userSettings, false);
        this.props.updateCompanySettings(
            temp,
            () => {
                if (this.state.smsSigningReminderChanged) {
                    this.props.onSMSSigningReminderToggelChange(
                        temp.smsNotificationSettings.signingReminderNotificationSettings.enableSigningReminder
                    );
                    this.setState({ smsSigningReminderChanged: false });
                }
            },
            resourceId
        );
        this.disableCustomRouterPrompt();
    }

    handleResetButton() {
        this.props.requestCompanySettings(true);
    }
    //////////////////////////////// Signature settings end/////////////////////////////////////////////////////////

    //////////////////////////////// Signature form selection start /////////////////////////////////////////////////////////
    handleEsignAllReturns(e: React.BaseSyntheticEvent) {
        let temp = this.props.companyData.companySettings as ICompanySettings;
        if (e.currentTarget.checked == true) {
            temp.signatureSettingsModel.knowledgeBasedAuthentication = true;
            temp.signatureSettingsModel.enableDelegateeKbaAuthentication = true;
        }
        temp.signatureSettingsModel.signatureFormSelectionType = SignatureFormSelectionType.ESign;
        this.setState({
            companySettings: temp,
            disableESignature: false
        });
        this.enableCustomRouterPrompt();
    }

    handleEsignWhenAllAllowed(e: React.BaseSyntheticEvent) {
        let temp = this.props.companyData.companySettings as ICompanySettings;
        if (e.currentTarget.checked == true) {
            temp.signatureSettingsModel.knowledgeBasedAuthentication = true;
        }
        temp.signatureSettingsModel.signatureFormSelectionType = SignatureFormSelectionType.ESignWhenAllIncludedReturnsAllowed;
        this.setState({
            companySettings: temp,
            disableESignature: false
        });
        this.enableCustomRouterPrompt();
    }

    handleEnableManualEsignAllReturns() {
        let temp = this.props.companyData.companySettings as ICompanySettings;
        temp.signatureSettingsModel.signatureFormSelectionType = SignatureFormSelectionType.ManualSign;
        temp.signatureSettingsModel.knowledgeBasedAuthentication = false;
        temp.signatureSettingsModel.enableDelegateeKbaAuthentication = false;
        temp.signatureSettingsModel.allowDisablingKBAOnReturnLevel = false;
        temp.signatureSettingsModel.useSignatureStamp = false;
        temp.signatureSettingsModel.enableSignerDeligation = false;
        temp.signatureSettingsModel.enableEsignatureForBusinessReturns = false;
        this.setState({
            companySettings: temp,
            disableESignature: true
        });
        this.enableCustomRouterPrompt();
    }
    //////////////////////////////// Signature form selection end /////////////////////////////////////////////////////////

    /////////////////////////////////////////Knowledge Based Authentication start///////////////////////////////////////////
    handleEnableKBA(e: React.BaseSyntheticEvent) {
        let temp = this.props.companyData.companySettings as ICompanySettings;
        temp.signatureSettingsModel.knowledgeBasedAuthentication = e.currentTarget.checked;
        if (e.currentTarget.checked == false) {
            temp.signatureSettingsModel.allowDisablingKBAOnReturnLevel = false;
        }
        this.setState({
            companySettings: temp
        });
        this.enableCustomRouterPrompt();
    }

    handleEnableDelegateeKBA = (e: React.BaseSyntheticEvent) => {
        let temp = this.props.companyData.companySettings as ICompanySettings;
        temp.signatureSettingsModel.enableDelegateeKbaAuthentication = e.currentTarget.checked;
        this.setState({
            companySettings: temp
        });
        this.enableCustomRouterPrompt();
    };

    handleAllowDisablingKBAOnReturnLevel(checked: boolean) {
        let temp = this.props.companyData.companySettings as ICompanySettings;
        temp.signatureSettingsModel.allowDisablingKBAOnReturnLevel = checked;
        if (checked == true) {
            temp.signatureSettingsModel.knowledgeBasedAuthentication = true;
        }
        this.setState({
            companySettings: temp
        });
        this.enableCustomRouterPrompt();
    }
    /////////////////////////////////////////Knowledge Based Authentication end///////////////////////////////////////////

    /////////////////////////////////////////ERO Signatures start //////////////////////////////////
    handleUseSignatureStamp(e: React.BaseSyntheticEvent) {
        let temp = this.props.companyData.companySettings as ICompanySettings;
        temp.signatureSettingsModel.useSignatureStamp = e.currentTarget.checked;
        if (e.currentTarget.checked == false) {
            temp.signatureSettingsModel.enableSignerDeligation = false;
        }
        this.setState({
            companySettings: temp
        });
        this.enableCustomRouterPrompt();
    }

    handleSignerDeligation(e: React.BaseSyntheticEvent) {
        let temp = this.props.companyData.companySettings as ICompanySettings;
        temp.signatureSettingsModel.enableSignerDeligation = e.currentTarget.checked;
        if (e.currentTarget.checked == true) {
            temp.signatureSettingsModel.useSignatureStamp = true;
        }
        this.setState({
            companySettings: temp
        });
        this.enableCustomRouterPrompt();
    }
    ///////////////////C//////////////////////ERO Signatures end //////////////////////////////////

    /////////////////////////////////////////Esignature For Business Returns start //////////////////////////////////
    handleEsignatureForBusinessReturn(e: React.BaseSyntheticEvent) {
        let temp = this.props.companyData.companySettings as ICompanySettings;
        temp.signatureSettingsModel.enableEsignatureForBusinessReturns = e.currentTarget.checked;
        this.setState({
            companySettings: temp
        });
        this.enableCustomRouterPrompt();
    }
    /////////////////////////////////////////Esignature For Business Returns end //////////////////////////////////
    /////////////////////////////////////////Signing Reminder starts //////////////////////////////////
    handleSigningReminder = (event: any) => {
        let temp = this.props.companyData.companySettings as ICompanySettings;
        temp.notificationSettingsModel.signingReminderNotificationSettings.enableSigningReminder = event.currentTarget.checked;
        this.setState({
            companySettings: temp
        });
        this.enableCustomRouterPrompt();
    };

    handleSettingSigningReminderDays(event: any) {
        if (event && event.value > 0) {
            let temp = this.props.companyData.companySettings as ICompanySettings;
            temp.notificationSettingsModel.signingReminderNotificationSettings.noOfDaysForSigningReminder = parseInt(event.value);
            this.setState({
                companySettings: temp
            });
            this.enableCustomRouterPrompt();
        }
    }

    handleSettingSMSSigningReminderDays = (event: any) => {
        if (event && event.value > 0) {
            let temp = this.props.companyData.companySettings as ICompanySettings;
            temp.smsNotificationSettings.signingReminderNotificationSettings.noOfDaysForSigningReminder = parseInt(event.value);
            this.setState({
                companySettings: temp
            });
            this.enableCustomRouterPrompt();
        }
    };

    handleSMSSigningReminder = (event: any) => {
        let temp = this.props.companyData.companySettings as ICompanySettings;
        temp.smsNotificationSettings.signingReminderNotificationSettings.enableSigningReminder = event.currentTarget.checked;
        this.setState({
            companySettings: temp,
            smsSigningReminderChanged: true
        });
        this.enableCustomRouterPrompt();
    };
    /////////////////////////////////////////Signing Reminder end //////////////////////////////////

    ////////////////////////////////////////Signer Delegation start/////////////////////////////////////

    handleEntityReturnSignerDelegation = (e: React.BaseSyntheticEvent) => {
        let temp = this.props.companyData.companySettings as ICompanySettings;
        temp.signatureSettingsModel.enableEntityReturnSignerDeligation = e.currentTarget.checked;
        this.setState({ companySettings: temp });
        this.enableCustomRouterPrompt();
    };

    handleIndividualReturnSignerDelegation = (e: React.BaseSyntheticEvent) => {
        let temp = this.props.companyData.companySettings as ICompanySettings;
        temp.signatureSettingsModel.enableIndividualReturnSignerDeligation = e.currentTarget.checked;
        if (e.currentTarget.checked) {
            temp.signatureSettingsModel.enableDelegateeKbaAuthentication = true;
        } else {
            temp.signatureSettingsModel.enableDelegateeKbaAuthentication = false;
        }
        this.setState({ companySettings: temp });
        this.enableCustomRouterPrompt();
    };

    ////////////////////////////////////////Signer Delegation end/////////////////////////////////////

    openDocumentTypePopup() {
        this.setState({
            showState: true
        });
    }

    onHide() {
        this.setState({
            showState: false,
            smsSigningReminderChanged: false
        });
    }

    onToggleEfileDateSettings = (event: any) => {
        let temp = this.props.companyData.companySettings as ICompanySettings;
        temp.signatureSettingsModel.isDoNotDisplayDatesEnabled = event.currentTarget.checked;
        this.setState({
            companySettings: temp
        });
        this.enableCustomRouterPrompt();
    };
    onToggleEfileNameAndTitleSettings = (event: any) => {
        let temp = this.props.companyData.companySettings as ICompanySettings;
        temp.signatureSettingsModel.isDoNotDisplayNameAndTitleEnabled = event.currentTarget.checked;
        this.setState({
            companySettings: temp
        });
        this.enableCustomRouterPrompt();
    };

    public render() {
        return (
            <div id="divSignature" className="company-settings font14">
                <CustomRouterPrompt enabled={this.state.showCustomPrompt} history={this.props.history} />
                <SettingsHeader
                    handleApplyButton={this.handleApplyButton}
                    header="E-Signatures"
                    showPopOver={false}
                    parentResourceIdentifier={
                        TaxReturnSettingsResources.ESignOptionsPrefix + TaxReturnSettingsResources.HeaderPrefix
                    }
                />
                <OverlayLoader
                    show={this.props.companyData.isLoading || this.props.settings.isLoading}
                    text={this.props.companyData.message}
                />
                {this.props.companyData.companySettings != undefined && this.props.settings ? (
                    <div>
                        <SignatureFormSelection
                            companySettings={this.props.companyData.companySettings as ICompanySettings}
                            handleEsignAllReturns={this.handleEsignAllReturns}
                            handleEsignWhenAllAllowed={this.handleEsignWhenAllAllowed}
                            handleEnableManualEsignAllReturns={this.handleEnableManualEsignAllReturns}
                            helptexts={this.props.helptexts.helpTexts}
                        />
                        <hr />
                        <KnowledgeBasedAuthentication
                            companySettings={this.props.companyData.companySettings as ICompanySettings}
                            handleEnableKBA={this.handleEnableKBA}
                            handleAllowDisablingKBAOnReturnLevel={this.handleAllowDisablingKBAOnReturnLevel}
                            handleEnableDelegateeKBA={this.handleEnableDelegateeKBA}
                            disableESignature={this.state.disableESignature}
                        />
                        <hr />
                        <EROSignatures
                            companySettings={this.props.companyData.companySettings as ICompanySettings}
                            handleUseSignatureStamp={this.handleUseSignatureStamp}
                            handleSignerDeligation={this.handleSignerDeligation}
                            helptexts={this.props.helptexts.helpTexts}
                        />
                        <AdditionalDocumentType
                            overlayLoading={this.props.additionalDocumentTypeData.isLoading}
                            overlayMessage={this.props.additionalDocumentTypeData.message}
                            documentType={this.props.additionalDocumentTypeData.documentType}
                            HandleCustomizeDeliveredreportsEditButton={this.openDocumentTypePopup}
                            updateDocumentTypeValue={this.props.updateAdditionalDocumentTypeValue}
                            addDocumentTypeValue={this.props.addAdditionalDocumentTypeValue}
                            deleteDocumentTypeValue={this.props.deleteAdditionalDocumentTypeValue}
                            parentResourceIdentifier={
                                TaxReturnSettingsResources.ESignOptionsPrefix + TaxReturnSettingsResources.AdditionalDocumentType
                            }
                        />
                        <hr />
                        <SignerDelegation
                            companySettings={this.props.companyData.companySettings as ICompanySettings}
                            handleEntityReturnSignerDelegation={this.handleEntityReturnSignerDelegation}
                            handleIndividualReturnSignerDelegation={this.handleIndividualReturnSignerDelegation}
                        />
                        <hr />
                        <EsignatureForBusinessReturns
                            companySettings={this.props.companyData.companySettings as ICompanySettings}
                            handleEsignatureForBusinessReturn={this.handleEsignatureForBusinessReturn}
                            disableESignature={this.state.disableESignature}
                        />
                        <hr />
                        {this.props.companyData.companySettings != undefined ? (
                            <SigningReminderNotification
                                companySettings={this.props.companyData.companySettings as ICompanySettings}
                                handleSettingSigningReminderDays={this.handleSettingSigningReminderDays}
                                handleSigningReminder={this.handleSigningReminder}
                                handleSettingSMSSigningReminderDays={this.handleSettingSMSSigningReminderDays}
                                handleSMSSigningReminder={this.handleSMSSigningReminder}
                                suiteNotificationSetting={this.props.suiteNotificationSetting}
                            />
                        ) : (
                            ""
                        )}
                        <hr />
                        <EfileFormSettings
                            companySettings={this.props.companyData.companySettings as ICompanySettings}
                            onToggleEfileDateSettings={this.onToggleEfileDateSettings}
                            onToggleEfileNameAndTitleSettings={this.onToggleEfileNameAndTitleSettings}
                        />
                        <hr />
                    </div>
                ) : (
                    ""
                )}                
            </div>
        );
    }
}
