import React from "react";
import { Router } from "react-router";
import { Provider } from "react-redux";
import store from "./store";
import AuthProvider from "./oidcClient/AuthProvider";
import { Routes } from "./routes";
import history from "./routes/history";

const App = () => {
    return (
        <Provider store={store}>
            <AuthProvider store={store}>
                <Router history={history}>
                    <Routes />
                </Router>
            </AuthProvider>
        </Provider>
    );
};

export default App;
