import * as React from 'react';
import { Guid } from '../../../../Core/Utilities/Guid';
import { IPdfDocumentFacade } from '../../../../Core/Utilities/PdfDocumentFacade';
import { PdfProperties } from '../../../../Core/ViewModels/Common/PdfHelper';
import { ICompanySettings } from '../../../../Core/ViewModels/Company/CompanySettingsViewModel';
import { IAuthState } from '../../../../store/auth/reducer';
import * as UserSignatureStore from '../../../../store/common/UserSignatureStore';
import * as CompanyStore from '../../../../store/company/CompanyStore';
import * as UserSettingStore from '../../../../store/userManagement/UserSettingStore';
import * as TaxDocument from '../../../common/TaxReturn';
import { DocumentGroups, SignatureControlRole, SignatureControlTypes, SignatureGroupType } from '../../../common/TaxReturn';
import { IUserProfile } from '../../../navigation/profile/ProfileObjects';
import { BookmarksPane } from '../BookmarksPane';
import { INVALID_PAGE } from '../CustomPagination/CustomPagination';
import { getAllEroSigners, IFormGroupViewProps, IFormGroupViewState, ISignatureControlsDictionary, PdfPageSignatureControls } from '../ProcessReturnModels';
import { EROSignatureCard } from '../RightSidebar/EROSignaturePanel';
import { InvoiceSideBar } from "../RightSidebar/InvoiceSideBar";
import { AwesomePdfViewer } from './../AwesomePdfViewer';
import { PageMoveTo } from './../PageMoveTo';
import { dragStartSignatureControl } from './../SignatureControls/SignatureHelper';
import { IEROSigner } from './TabEFile';
const NO_INDEX = -1;

export interface ITabInvoiceProps extends IFormGroupViewProps {
    auth: IAuthState;
    companySettings: ICompanySettings;
    userProfile: IUserProfile;

    removeSignatureControl(control: TaxDocument.ISignatureControl, pageNo: number): void;
    addSignatureControl(control: TaxDocument.ISignatureControl, pageNo: number, group: DocumentGroups): void;
    updateEROSignatureStamp(userId: number): void;
    replaceSignatureControl(oldControl: TaxDocument.ISignatureControl, newControl: TaxDocument.ISignatureControl, pageNo: number): void;
    signatureControls: ISignatureControlsDictionary;
    userSettings: UserSettingStore.UserSettings;
    company: CompanyStore.ICompanyData;
    userSignatures: UserSignatureStore.IUserSignatures;
    pages: number[];
    formGroup: TaxDocument.IGroup;
}

export interface ITabInvoiceState extends IFormGroupViewState {
    eroSigners: IEROSigner[];
    selectedEROSigner: IEROSigner;
    focusedGroup: SignatureGroupType;
    currentPageIxdex: number;
    scale: number;
}

const isEqual = require("react-fast-compare");
export class TabInvoice extends React.Component<ITabInvoiceProps, ITabInvoiceState>{
    constructor(props: ITabInvoiceProps) {
        super(props);
        this.state = {
            currentPage: INVALID_PAGE,
            currentPageIxdex: 0,
            actionCardShow: false,
            zoomEnabled: false,

            eroSigners: [],
            selectedEROSigner: {
                value: 0,
                label: "",
                isEnableSignatureDelegation: true,
                eroImage: ""
            },
            focusedGroup: SignatureGroupType.None,
            scale: PdfProperties.DefaultScale
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps: ITabInvoiceProps) {
        if (this.props.userSignatures !== nextProps.userSignatures)
            this.setEroSignerDropdownValues(nextProps);

        if (nextProps.pages && nextProps.pages.length != this.props.pages.length) {
            this.onPageSelect(nextProps.pages[this.state.currentPageIxdex]);
        }
        if (nextProps.pages && nextProps.pages.length == this.state.currentPageIxdex) {
            this.onPageSelect(nextProps.pages[this.state.currentPageIxdex - 1]);
        }
        if (nextProps.pages && nextProps.pages[this.state.currentPageIxdex] !== this.state.currentPage) {
            if (this.state.currentPage == -1) {
                this.setState({ currentPage: nextProps.pages[0] });
            } else {
                this.onPageSelect(nextProps.pages[this.state.currentPageIxdex]);
            }
        }

        if (nextProps.taxReturn.documentSettings && nextProps.companySettings.displaySettingsModel
            && nextProps.taxReturn.documentSettings.documentDisplaySetting.isEnableInvoice != nextProps.companySettings.displaySettingsModel.isEnableInvoice) {
            nextProps.taxReturn.documentSettings.documentDisplaySetting.isEnableInvoice = nextProps.companySettings.displaySettingsModel.isEnableInvoice;
            nextProps.taxReturn.documentSettings.documentDisplaySetting.isSendWithoutInvoice = nextProps.companySettings.userPrivilegeSettingsModel.allowUserToSendwithoutInvoice;
            nextProps.taxReturn.documentSettings.documentDisplaySetting.priorToReview = nextProps.companySettings.taxpayerPaymentSettings.priortoReview;
        }
    }

    shouldComponentUpdate(nextProps: ITabInvoiceProps, nextState: IFormGroupViewState) {
        return ((nextProps.pages !== this.props.pages) ||
            (this.state.currentPage === INVALID_PAGE) ||
            (!isEqual(this.state, nextState)));
    }

    componentDidMount() {
        this.props && this.props.pages && this.setState({ currentPage: this.props.pages[0] });
    }

    UNSAFE_componentWillMount() {
        this.setEroSignerDropdownValues(this.props);
    }

    onPageSelect = (page: number, voucherNo?: number, isPageSelectionFromBookMark?: boolean) => {
        if (page) {
            this.setState({ currentPage: page });
            if (isPageSelectionFromBookMark) {
                this.setState({
                    currentPageIxdex: this.props.pages.indexOf(page)
                });
            }
        }
    }
    onPageDelete = (page: number) => { this.props.deletePages([page]); }
    onReGroupPreview = (pages: number[], destinationGroup: TaxDocument.DocumentGroups) => {
        this.setState({ currentPage: INVALID_PAGE }, () => { this.props.reGroupPreview(pages, destinationGroup) });
    }

    createLeftPanel = () => {
        return (<BookmarksPane
            title="Pages"
            docId={this.props.docId}
            pdfDocuments={this.props.pdfDocuments}
            sections={this.props.bookmarks}
            onPageDelete={this.onPageDelete}
            onPageSelect={this.onPageSelect}
            getAllTaxingAuthorities={this.props.getAllTaxingAuthorities} />);
    }

    createRightPanel = () => {
        return (<>
            <InvoiceSideBar
                docId={this.props.docId}
                invoiceAmount={this.props.taxReturn.invoiceAmount}
                companySettings={this.props.companySettings}
                taxReturn={this.props.taxReturn}
                pdfDocuments={this.props.pdfDocuments}
                updateTaxDocument={this.props.updateTaxDocument}
                userBasicProfile={this.props.userProfile}
                recognisedBookmarks={this.props.bookmarks}
                auth={this.props.auth}

            />
            {
                (this.props.userSettings.settings.useSignatureStamp) &&
                <div>
                    <EROSignatureCard
                        title="ERO Signature Stamp"
                        selectedEROSigner={this.state.selectedEROSigner}
                        eroSigner={this.state.eroSigners}
                        onEROSignerChange={this.handleEROSignerChange}
                        isAssignedToLoggedinUser={this.props.isAssignedToLoggedinUser}
                        onSignatureControlFocus={this.onSignatureControlFocus}
                        dragStart={this.dragStartSignatureControl}
                        dragEnd={this.dragEnd}
                    />
                </div>
            }
        </>)
    }

    createMoveTo = () => {
        return <PageMoveTo
            documentGroup={TaxDocument.DocumentGroups.Invoice}
            reGroupPreview={this.reGroupPreview}
            engagementType={this.props.taxReturn.engagementType}
            arrangement={this.props.taxReturn.arrangement}
        />
    }

    reGroupPreview = (destinationGroup: TaxDocument.DocumentGroups) => {
        let indexOfCurrentPage: number = this.props.pages.indexOf(this.state.currentPage);
        this.props.reGroupPreview([this.state.currentPage], destinationGroup);
        if (destinationGroup == TaxDocument.DocumentGroups.Deleted) {
            if ((this.props.pages.length - 1) == indexOfCurrentPage) {
                this.setState({
                    currentPage: this.props.pages.indexOf(indexOfCurrentPage),
                    currentPageIxdex: indexOfCurrentPage - 1
                });
            }
            else {
                this.setState({
                    currentPage: this.props.pages.indexOf(indexOfCurrentPage),
                    currentPageIxdex: this.props.pages.indexOf(this.state.currentPage)
                });
            }
        }
        else {
            this.setState({
                currentPage: this.props.pages[0]
            });
        }
    }

    public render() {
        return (
            <>
                <AwesomePdfViewer
                    pdfDocument={this.props.pdfDocuments[this.props.docId].document as IPdfDocumentFacade}
                    leftPanel={this.createLeftPanel()}
                    rightPanel={this.createRightPanel()}
                    currentPage={this.state.currentPage}
                    pages={this.props.pages}
                    enableMoveTo={true}
                    moveToElement={this.createMoveTo()}
                    signatureControls={this.getEroSignatureControls()}
                    isAssignedToLoggedinUser={this.props.isAssignedToLoggedinUser}
                    isIndividual={TaxDocument.isIndividual(this.props.taxReturn)}
                    keyPrefix={"invoice-control-"}
                    onControlRemove={this.onControlRemove}
                    onControlUpdate={this.onControlUpdate}
                    scale={this.state.scale}
                    onScaleChange={(s) => this.setState({ scale: s })}
                    onCurrentPageChange={(page) => this.setState({ currentPage: page })}
                />
            </>);

    }

    private onControlRemove = (control: TaxDocument.ISignatureControl) => {
        this.props.removeSignatureControl(control, this.state.currentPage);
    }

    private onControlUpdate = (oldControl: TaxDocument.ISignatureControl, newControl: TaxDocument.ISignatureControl) => {
        this.props.replaceSignatureControl(oldControl, newControl, this.state.currentPage);
    }

    private adjustSamePositionControl = (control: TaxDocument.ISignatureControl): TaxDocument.ISignatureControl => {
        const signatureControls = this.props.signatureControls[this.state.currentPage];
        if (signatureControls) {
            const index = signatureControls.findIndex(x => x.controlGuid === control.controlGuid);
            if (index !== NO_INDEX) {
                control.left = control.left + 5;
                control.top = control.top + 5;
                return this.adjustSamePositionControl(control);
            }
            else {
                return control;
            }
        }
        return control;
    }

    private addSignatureControl = (type: TaxDocument.SignatureControlTypes, role: TaxDocument.SignatureControlRole, left: number, top: number) => {
        let _self = this;
        let control: TaxDocument.ISignatureControl = {} as TaxDocument.ISignatureControl;
        control.controlGuid = Guid.newGuid().toString();
        control.left = left;
        control.top = top;
        control.type = type;
        control.signatureControlRole = role;
        _self.props.addSignatureControl(_self.adjustSamePositionControl(control), _self.state.currentPage, DocumentGroups.TaxReturns);
    }

    private filterSignatureControls = (signatureControls: TaxDocument.ISignatureControl[], signatureType: any):
        TaxDocument.ISignatureControl[] => {
        let signControls: TaxDocument.ISignatureControl[] = signatureControls;
        if (signControls) {
            if (signatureType === TaxDocument.SignatureType[TaxDocument.SignatureType.ManualSign] && this.props.userSettings.settings.useSignatureStamp) {
                signControls = signControls.filter(control => control.signatureControlRole === SignatureControlRole.ERO);
            }
            else if (signatureType === TaxDocument.SignatureType[TaxDocument.SignatureType.ManualSign] && !this.props.userSettings.settings.useSignatureStamp) {
                signControls = [];
            }
            else {
                if (!this.props.userSettings.settings.useSignatureStamp) {
                    signControls = signControls.filter(control => control.signatureControlRole !== SignatureControlRole.ERO);
                }
            }
        }

        return signControls;
    }

    private getEroSignatureControls = (): PdfPageSignatureControls => {
        let signatureControls: TaxDocument.ISignatureControl[] = [];
        let signatureType: any = this.props.taxReturn.documentSettings.documentSignatureSetting.signatureFormSelectionType;
        signatureType = (typeof signatureType == "number") ? TaxDocument.SignatureType[signatureType] : signatureType;
        signatureControls = this.filterSignatureControls(this.props.signatureControls[this.state.currentPage], signatureType);
        return {
            signatureControls: signatureControls,
            eroSigner: { id: this.state.selectedEROSigner.value, signPath: this.state.selectedEROSigner.eroImage },
            signer: {},
            focusedGroup: SignatureGroupType.Stamp
        } as PdfPageSignatureControls;
    }

    private dragStartSignatureControl = (event: any, controlType: SignatureControlTypes, signatureRole: SignatureControlRole) => {
        dragStartSignatureControl(
            event, controlType, signatureRole,
            this.state.selectedEROSigner.label,
            this.state.currentPage,
            this.state.scale,
            this.addSignatureControl);
    }

    private dragEnd = (event: any) => {
        let element = document.getElementById("dragging-element");
        if (element) {
            element.remove();
        }
    }

    private handleEROSignerChange = (selectedOption: IEROSigner) => {
        if (selectedOption && selectedOption.eroImage !== "") {
            this.setState({
                selectedEROSigner: {
                    value: selectedOption.value,
                    label: selectedOption.label,
                    isEnableSignatureDelegation: selectedOption.isEnableSignatureDelegation,
                    eroImage: selectedOption.eroImage
                }
            }, () => {
                this.props.updateEROSignatureStamp(selectedOption.value);
            })
        }
    }

    private onSignatureControlFocus = (focusedGroup: SignatureGroupType) => {
        this.setState({
            focusedGroup: focusedGroup
        });
    }

    private setEroSignerDropdownValues = (nextprops: ITabInvoiceProps) => {
        const eroSigners: IEROSigner[] = getAllEroSigners(this.props.company,
            this.props.auth.user,
            this.props.taxReturn,
            this.props.userSettings,
            nextprops.userSignatures
        );

        const selectedEROId = this.props.taxReturn.documentSettings.documentSignatureSetting.signatureStampUser ?
            this.props.taxReturn.documentSettings.documentSignatureSetting.signatureStampUser?.userId
            : this.state.selectedEROSigner.value;
        let selectedEROSigner = eroSigners.find(x => x.value == selectedEROId) as IEROSigner;
        selectedEROSigner = selectedEROSigner ? selectedEROSigner : eroSigners.find(x => x.value === 0) as IEROSigner;

        this.setState({
            eroSigners: eroSigners,
            selectedEROSigner: selectedEROSigner,
        });
    }
};
//=============================================================================
