import { addTask } from 'domain-task';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../';
import { handleResponse } from '../Library';
import { actionTypes } from '../../types/ActionTypes';
import { IDelegatedEROSigner } from '../../components/common/ProcessReturnModal/ProcessReturnModels';
import { IUserDefaultSettingsStates, DefaultMailingAddress, ISignatureDelegation, DefaultDownloadInfoType } from '../../components/navigation/profile/MySettings';
import { StatusType, NotificationAction } from '../common/NotificationStore';
import * as Helper from '../../components/helper/Constants';
import { EngagementType, DocumentGroups } from '../../components/common/TaxReturn';
import { IUserNotificationSettings } from '../../Core/ViewModels/User/NotificationSettingModel'
import { API_BASE_URL } from '../../utils/constants';
import * as CRSHelper from '../../components/helper/CRSHelper';

export interface UserSettings {
    settings: IUserDefaultSettingsStates;
    addresses: DefaultMailingAddress[];
    delegatedSigners: IDelegatedEROSigner[];
    error: boolean;
    isLoading: boolean;
    message: string;
    notificationSettings: IUserNotificationSettings
}

interface RequestUserSettingAction {
    type: actionTypes.REQUEST_USER_SETTINGS;
}

interface ReceiveUserSettingAction {
    type: actionTypes.RECEIVE_USER_SETTINGS;
    settings: IUserDefaultSettingsStates;
}

interface ErrorUserSettingAction {
    type: actionTypes.ERROR_USER_SETTINGS;
    message: string;
}


interface ReceiveDelegatedSignersAction {
    type: actionTypes.RECEIVE_DELEGATED_SIGNERS;
    delegatedSigners: IDelegatedEROSigner[];
}

interface UpdateDocumentOrderAction {
    type: actionTypes.UPDATE_DOCUMENTORDER;
}

interface ReceiveUserNotificationSettingAction {
    type: actionTypes.RECEIVE_USER_NOTIFICATION_SETTINGS;
    notificationSettings: IUserNotificationSettings;
}

interface RequestUserNotificationSettingAction {
    type: actionTypes.REQUEST_USER_NOTIFICATION_SETTINGS;
}

interface ErrorUserNotificationSettingAction {
    type: actionTypes.ERROR_USER_NOTIFICATION_SETTINGS;
    message: string;
}

type KnownAction =
    RequestUserSettingAction |
    ReceiveUserSettingAction |
    ReceiveDelegatedSignersAction |
    ErrorUserSettingAction |
    NotificationAction |
    UpdateDocumentOrderAction |
    RequestUserNotificationSettingAction |
    ReceiveUserNotificationSettingAction |
    ErrorUserNotificationSettingAction;

export const actionCreators = {
    requestUserSettings: (reload: boolean = false, callback?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {

        if (reload || !getState().userSettings.settings) {
            const fetchTask = fetch(API_BASE_URL + 'api/UserSettings/UserSetting', {
                method: 'GET',
                credentials: 'include'
            })
                .then(handleResponse)
                .then(json => json as Promise<IUserDefaultSettingsStates>)
                .then(data => {

                    if (data) {
                        dispatch({ type: actionTypes.RECEIVE_USER_SETTINGS, settings: data });
                    }

                    if (callback) {
                        callback();
                    }

                })
                .catch(error => {
                    dispatch({ type: actionTypes.ERROR_USER_SETTINGS, message: Helper.UserSettings.fetchUserSettings });
                });
            addTask(fetchTask);
            //dispatch({ type: actionTypes.REQUEST_USER_SETTINGS });
        }
    },

    saveUserSettings: (settings: IUserDefaultSettingsStates, showUserSettingsNotification: boolean, callback?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        if (settings.defaultSettings.download.downloadType == DefaultDownloadInfoType.PromptMeEveryTime) {
            settings.defaultSettings.download.rememberDefaultDownload = false;
        }
        else {
            settings.defaultSettings.download.rememberDefaultDownload = true;
            if (settings.defaultSettings.download.downloadType == null) {
                settings.defaultSettings.download.rememberDefaultDownload = false;
                settings.defaultSettings.download.downloadType = DefaultDownloadInfoType.PromptMeEveryTime;
            }
        }
        const fetchTask = fetch(API_BASE_URL + 'api/UserSettings', {
            method: 'PUT',
            credentials: 'include',
            body: CRSHelper.encodeSpecialCharactersOnly(JSON.stringify(settings)),
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8',
                'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
            }
        })
            .then(handleResponse)
            .then(json => json as Promise<IUserDefaultSettingsStates>)
            .then(data => {
                dispatch({ type: actionTypes.RECEIVE_USER_SETTINGS, settings: settings });
                if (callback) {
                    callback();
                }
                if (showUserSettingsNotification) {
                    dispatch({ type: actionTypes.NOTIFICATION, statusMessage: Helper.UserSettings.saveUserSettingsSuccess, statusType: StatusType.Success });
                }
            })
            .catch(error => {
                dispatch({ type: actionTypes.ERROR_USER_SETTINGS, message: Helper.UserSettings.saveUserSettingsError });
                if (showUserSettingsNotification) {
                    dispatch({ type: actionTypes.NOTIFICATION, statusMessage: Helper.UserSettings.saveUserSettingsError, statusType: StatusType.Error });
                }
            });
        addTask(fetchTask);
    },

    saveUserSettingsAdmin: (settings: IUserDefaultSettingsStates, callback?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {

        const fetchTask = fetch(API_BASE_URL + 'api/UserSettings/SaveUserSettings', {
            method: 'PUT',
            credentials: 'include',
            body: CRSHelper.encodeSpecialCharactersOnly(JSON.stringify(settings)),
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8',
                'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
            }
        })
            .then(handleResponse)
            .then(json => json as Promise<IUserDefaultSettingsStates>)
            .then(data => {
                if (callback) {
                    callback();
                }
                dispatch({ type: actionTypes.NOTIFICATION, statusMessage: Helper.UserSettings.saveUserSettingsSuccess, statusType: StatusType.Success });
            })
            .catch(error => {
                dispatch({ type: actionTypes.ERROR_USER_SETTINGS, message: Helper.UserSettings.saveUserSettingsError });
                dispatch({ type: actionTypes.NOTIFICATION, statusMessage: Helper.UserSettings.saveUserSettingsError, statusType: StatusType.Error });
            });
        addTask(fetchTask);
    },

    ChangeNotificationStatus: (userId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {

        const fetchTask = fetch(API_BASE_URL + 'api/UserSettings/ChangeNotificationStatus/' + userId, {
            method: 'PUT',
            credentials: 'include',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8',
                'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
            }
        })
            .then(handleResponse)
            .then(json => json as Promise<IUserDefaultSettingsStates>)
            .catch(error => {
                //dispatch({ type: actionTypes.NOTIFICATION, statusMessage: Helper.UserSettings.saveUserSettingsError, statusType: StatusType.Error });
            });
        addTask(fetchTask);
    },

    updateUserSettings: (settings: IUserDefaultSettingsStates): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: actionTypes.RECEIVE_USER_SETTINGS, settings: settings });
    },

    saveSignatureDeligates: (delegates: number[]): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let state = getState().userSettings;
        if (state.settings?.signatureDelegation) {
            state.settings.signatureDelegation.userId = state.settings.userId;
            state.settings.signatureDelegation.delegate = delegates;
        }
        if (state.settings && state.settings.signatureDelegation) {
            const fetchTask = fetch(API_BASE_URL + 'api/UserSettings/SaveSignatureDelegation', {
                method: 'PUT',
                credentials: 'include',
                body: JSON.stringify(state.settings.signatureDelegation),
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json; charset=utf-8',
                    'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
                }
            })
                .then(handleResponse)
                .then(() => {
                    dispatch({ type: actionTypes.RECEIVE_USER_SETTINGS, settings: state.settings });
                })
                .catch(error => {
                    dispatch({ type: actionTypes.ERROR_USER_SETTINGS, message: Helper.UserSettings.saveSignatureDelegations });
                });
            addTask(fetchTask);
            //dispatch({ type: actionTypes.RECEIVE_MAILING_ADDRESS, addresses: state.addresses });
        }
    },

    saveSignatureDelegatesAdmin: (signatureDelegation: ISignatureDelegation, callback?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        if (signatureDelegation) {
            const fetchTask = fetch(API_BASE_URL + 'api/UserSettings/SaveSignatureDelegation', {
                method: 'PUT',
                credentials: 'include',
                body: JSON.stringify(signatureDelegation),
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json; charset=utf-8',
                    'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
                }
            })
                .then(handleResponse)
                .then(() => {
                    if (callback) {
                        callback();
                    }
                })
                .catch(error => {
                    dispatch({ type: actionTypes.ERROR_USER_SETTINGS, message: Helper.UserSettings.saveSignatureDelegations });
                });
            addTask(fetchTask);
        }
    },

    requestDelegatedSigners: (userId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let state = getState().userSettings;
        if (state.delegatedSigners && userId != 0) {
            const fetchTask = fetch(API_BASE_URL + 'api/UserManagement/GetAllDelegatedEroSigners/?id=' + userId, { credentials: 'include' })
                .then(
                    response => response.json() as Promise<Array<IDelegatedEROSigner>>)
                .then(data => {
                    dispatch({ type: actionTypes.RECEIVE_DELEGATED_SIGNERS, delegatedSigners: data });
                })
                .catch(error => {

                });

            addTask(fetchTask);
        }
    },

    updateDocumentGroupOrder: (engagementType: EngagementType, documentGroupOrder: DocumentGroups[]): AppThunkAction<KnownAction> => (dispatch, getState) => {

        const fetchTask = fetch(`${API_BASE_URL}api/UserSettings/UpdateDocumentGroupOrder/?engagementType=${engagementType}&documentGroupOrder=${JSON.stringify(documentGroupOrder)}`,
            {
                method: 'PUT',
                credentials: 'include',
                headers: {
                    'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
                }
            })
            .then(handleResponse)
            .then(data => {
            })
            .catch(error => {

            });

        addTask(fetchTask);

    },

    requestUserNotificationSettings: (callback?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let fetchTask = fetch(API_BASE_URL + 'api/UserSettings/InboxNotificationSettings', {
            method: 'GET',
            credentials: 'include',
            headers: {
                'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
            }
        })
            .then(handleResponse)
            .then(json => json as Promise<IUserNotificationSettings>)
            .then(data => {
                dispatch({
                    type: actionTypes.RECEIVE_USER_NOTIFICATION_SETTINGS,
                    notificationSettings: data
                });
                if (callback) {
                    callback();
                }
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: Helper.UserSettings.fetchUserNotificationSettingsError,
                    statusType: StatusType.Error
                })
                dispatch({
                    type: actionTypes.ERROR_USER_NOTIFICATION_SETTINGS,
                    message: Helper.UserSettings.fetchUserNotificationSettingsError
                });
            });
        addTask(fetchTask);
    },

    saveUserNotificationSettings: (notificationSettings: IUserNotificationSettings, callback?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {

        let fetchTask = fetch(API_BASE_URL + 'api/UserSettings/SaveInboxNotificationSettings', {
            method: 'PUT',
            credentials: 'include',
            body: JSON.stringify(notificationSettings),
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8',
                'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
            }
        })
            .then(handleResponse)
            .then(() => {
                dispatch({
                    type: actionTypes.RECEIVE_USER_NOTIFICATION_SETTINGS,
                    notificationSettings: notificationSettings
                });
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: Helper.UserSettings.saveUserNotificationSettingsSuccess,
                    statusType: StatusType.Success
                });
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: Helper.UserSettings.saveUserNotificationSettingsError,
                    statusType: StatusType.Error
                });
                dispatch({
                    type: actionTypes.ERROR_USER_NOTIFICATION_SETTINGS,
                    message: Helper.UserSettings.saveUserNotificationSettingsError
                });
            });
        addTask(fetchTask);
    }

};

const unloadedState: UserSettings = {
    settings: {},
    addresses: {},
    delegatedSigners: {},
    notificationSettings: {}
} as UserSettings;

export const reducer: Reducer<UserSettings> = (state = unloadedState, incomingAction) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case actionTypes.REQUEST_USER_SETTINGS:
            let cleanSettings: UserSettings = { ...state };
            delete cleanSettings.settings;
            cleanSettings.error = false;
            cleanSettings.isLoading = true;
            cleanSettings.message = '';
            return cleanSettings;
        case actionTypes.RECEIVE_USER_SETTINGS:
            let updated: UserSettings = { ...state };
            updated.error = false;
            updated.settings = action.settings;
            updated.isLoading = false;
            updated.message = Date();
            return updated;
        case actionTypes.RECEIVE_DELEGATED_SIGNERS:
            var received: UserSettings = { ...state };
            received.delegatedSigners = action.delegatedSigners;
            received.error = false;
            received.isLoading = false;
            received.message = Date();
            return received;
        case actionTypes.ERROR_USER_SETTINGS:
            return {
                ...state,
                isLoading: false,
                error: true,
                message: action.message
            } as UserSettings;
        case actionTypes.RECEIVE_USER_NOTIFICATION_SETTINGS:
            var receivedUserSettings: UserSettings = { ...state };
            receivedUserSettings.notificationSettings = action.notificationSettings;
            receivedUserSettings.error = false;
            receivedUserSettings.isLoading = false;
            receivedUserSettings.message = Date();
            return receivedUserSettings;
    }

    return state;
};
