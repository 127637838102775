import { ApplicationState } from "../../../store";
import * as CompanyStore from '../../../store/company/CompanyStore';
import { SecuritySettings } from "./SecuritySettings";
import { connect } from 'react-redux';
import * as UserSettingsStore from '../../../store/userManagement/UserSettingStore';
import * as ApplicationHelptextStore from '../../../store/common/ApplicationHelptextStore';

export default connect(
    (state: ApplicationState) => ({
        companyData: state.companyData,
        helptexts: state.Helptexts,
        
    }),
    {
        ...CompanyStore.actionCreators,
        ...UserSettingsStore.actionCreators,
        ...ApplicationHelptextStore.actionCreators,
    }
)(SecuritySettings as any);
