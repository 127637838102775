import { addTask } from 'domain-task';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../';
import { ITaxReturn } from '../../components/common/TaxReturn';
import {
	RequestAttachmentsAction, ReceiveAttachmentsAction,
	RequestDownloadAttachmentsAction, ReceiveDownloadAttachmentsAction
} from '../reports/KnownTypes';
import { AttachmentsState, AttachmentsTableModel, IDownloadAttachmentLinkViewModel } from './AttachmentsState';
import { handleResponse, extractDocumentIds, handleBlob,handleBlobwithFileName } from '../Library';
import { DisplayDownloadFile } from '../../components/common/DisplayDownloadFile';
import * as Constants from '../../components/helper/Constants';
import { initialUserProfile } from '../../components/navigation/profile/ProfileObjects';
import { API_BASE_URL } from '../../utils/constants';
import { logger } from '../../routes/LoggedIn';
import * as CRSHelper from '../../components/helper/CRSHelper';

type KnownAction = RequestAttachmentsAction
	| ReceiveAttachmentsAction | RequestDownloadAttachmentsAction | ReceiveDownloadAttachmentsAction;

export const actionCreators = {
	requestAttachments: (taxReturn: ITaxReturn, fileName: string, file: any, processData: (data: AttachmentsTableModel, fileName: string, file: any) => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
		// Only load data if it's something we don't already have (and are not already loading)
		const fetchTask = fetch(API_BASE_URL + 'api/ProcessReturn/GetUploadSasUrlAsync/?documentGuid=' + taxReturn.documentGuid + '&fileName=' + escape(fileName) + '&taxYear=' + taxReturn.taxYear + '&uploadType=' + "attachment", { credentials: 'include' }).then((resp) => resp.json())
			.then(data => {
				processData(data, fileName, file)
			})
			.catch((error: any) => {
				console.log(error);
			});
		addTask(fetchTask); // Ensure server-side prerendering waits for this to complete
	},
	previewAttachments: (taxReturn: ITaxReturn, fileName: string, documentId: number, attachmentId: number, isArchivedReturn: boolean, callback: (url: string, fileName: string) => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
		const fetchTask = fetch(API_BASE_URL + 'api/Download/PreviewAttachmentAsync', {
			method: 'POST',
			credentials: 'include',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
			},
			body: JSON.stringify({
				attachmentName: fileName,
				taxYear: taxReturn.taxYear,
				documentGuid: taxReturn.documentGuid,
				documentId: documentId,
				attachmentId: attachmentId,
				isArchivedReturn: isArchivedReturn
			})
		}).then((resp) => resp.json())
			.then((data) => {
				callback(data, fileName);
			})
			.catch((error: any) => {
				console.log(error);
			});
		addTask(fetchTask);
	},
    downloadAttachments: (taxReturn: ITaxReturn, fileName: string, documentId: number, attachmentId: number, isArchivedReturn: boolean,
        callback?: (url: string, fileName: string) => void, resourceId:string=""): AppThunkAction<KnownAction> => (dispatch, getState) => {
		let DownloadUrl = isArchivedReturn ? API_BASE_URL + 'api/ArchivedDownload/GetArchivedDownloadAttachmentsLinkAsync' : API_BASE_URL + 'api/Download/GetDownloadAttachmentsLinkAsync';
		
		const postData : IDownloadAttachmentLinkViewModel = {
			attachmentName: fileName,
			taxYear: taxReturn.taxYear,
			documentGuid: taxReturn.documentGuid,
			documentId: documentId,
			attachmentId: attachmentId
		};
		
		const fetchTask = fetch(DownloadUrl, {
			method: 'POST',
			credentials: 'include',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json; charset=utf-8',
                'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value,
                'X-Resource-Id': resourceId
			},
			body: JSON.stringify(postData)
		}).then(response => {
			const contentDisposition = response.headers.get("content-disposition");
			const fileNameMatch = contentDisposition ? /filename="?([^"]*)"?;/g.exec(contentDisposition) : undefined;
			if (fileNameMatch && fileNameMatch.length > 1) {
				fileName = fileNameMatch[1];
			}
			return response.blob();
		}).then((data : any) => {
			if (callback) {
				callback(data, fileName);
			}
			else {
				let displayDownloadFile = new DisplayDownloadFile();
				displayDownloadFile.showFile(data, fileName);
			}
			logger.trackTrace(`Attachment downloaded successfully for documentId: ${documentId?.toString()} and documentGuid: ${taxReturn.documentGuid}`, { "DocumentId": taxReturn.id?.toString(), "DocumentGuid": taxReturn.documentGuid });
		}).catch((error: any) => {
            console.log(error);
        });
		addTask(fetchTask);
	},
	downloadAllAttachments: (taxReturn: ITaxReturn, isArchivedReturn: boolean, clientName: string, resourceId:string=""): AppThunkAction<KnownAction> => (dispatch, getState) => {
		const formData = new FormData();
		let taxReturnData = CRSHelper.encodeSpecialCharactersOnly(JSON.stringify(taxReturn));
		formData.append('taxDocument', taxReturnData);
		formData.append('isArchivedReturn', '' + JSON.stringify(isArchivedReturn));
		formData.append('clientName', clientName);
		let options: any = {
			method: 'POST',
			credentials: 'include',
			headers: {
				'Accept': 'application/json',
                'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value,
                'X-Resource-Id': resourceId
			},
			body: formData,

		};
		fetch(API_BASE_URL + 'api/Download/GetDownloadAllAttachmentsAsync', options).then(handleBlobwithFileName)
			.then((data) => {
				let displayDownloadFile = new DisplayDownloadFile();
				displayDownloadFile.showFile(data.blob, data.filename);
				logger.trackTrace(`All attachment downloaded successfully for documentId: ${taxReturn.id?.toString()} and documentGuid: ${taxReturn.documentGuid}`, { "DocumentId": taxReturn.id?.toString(), "DocumentGuid": taxReturn.documentGuid });
			})
			.catch((error: any) => {
				console.log(error);
			});
	},
};

const unloadedState: AttachmentsState = {
	AttachmentsTableModel: {
		AttachmentsModel: [],
	} as AttachmentsTableModel,
	isAssignedToLoggedinUser: true,
	taxReturn: '',
	documentId: 0,
	userProfile: initialUserProfile,
	saveAttachments: () => void (0),
	attachments: []
} as AttachmentsState;

export const reducer: Reducer<AttachmentsState> = (state = unloadedState, incomingAction) => {
	return state;
}

