import React, { useState, useEffect } from 'react';
import { Button, Modal } from "react-bootstrap";
import { ImportCCHAxcess } from '../../helper/Constants';
import { Loader, LoadingOverlay } from 'react-overlay-loader';

interface SyncConsentModalProps {
    show:boolean,    
    onSync():void;     
    onCancel():void;
}

export const SyncConsentModal: React.FC<SyncConsentModalProps> = ({ show, onSync, onCancel }) => {
    const [disableButtons,  setDisableButtons] = useState(false);
    const [isLoading,  setIsLoading] = useState(false);

    const onProceedBtnClick = () => {        
        onSync();
        setIsLoading(true);
        setDisableButtons(true);
    }

    const onCancelBtnClick = () => {        
        onCancel();
        setIsLoading(false);
        setDisableButtons(false);
    }
    
    const resetStates = () => {
        setIsLoading(false);
        setDisableButtons(false);
    };

    useEffect(() => {        
        resetStates();
    }, [show]);

    const tableConstants = ImportCCHAxcess.Table;
    const headerConstants = ImportCCHAxcess.Header;    
    
    return(<>                
        <Modal className="cch-modal" show={show} onHide={onCancelBtnClick}>
            <Modal.Header closeButton>                        
                <Modal.Title>{tableConstants.syncTitle}</Modal.Title>
            </Modal.Header>
            <LoadingOverlay>
                <Modal.Body>                        
                    <Loader loading={isLoading} />                   
                    <div className="row">
                        <div className='col-sm-12 col-md-12 col-xl-12'>
                            <div className="row">
                                <p> {tableConstants.syncText}
                                </p>                           
                            </div>
                        </div>
                    </div>                    
                </Modal.Body>                
                <Modal.Footer>
                    <Button disabled={disableButtons} variant="default" className="btn btn-only btn-white btn-default" onClick={onCancelBtnClick} data-test-auto="D4D4E151-755C-4992-AE6C-6A0662BBD463">
                        {headerConstants.cancel}
                    </Button>
                    <Button disabled={disableButtons} variant="primary" className="btn btn-only btn-info"  onClick={onProceedBtnClick} data-test-auto ="BA504366-A1F5-4FCB-80E4-15BD5E39AA25">
                        {tableConstants.confirm}
                    </Button>                       
                </Modal.Footer>
            </LoadingOverlay>
        </Modal>
    </>);
};