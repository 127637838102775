import { UploadStatus } from '../../../../Core/ViewModels/Common/UploadDocumentViewModel';
import { IPdfDocumentFacade } from '../../../../Core/Utilities/PdfDocumentFacade';
import { ISignatureControl, SignatureControlTypes, SignatureControlRole } from '../../TaxReturn';

export interface PaperFileUploadData {
    number?: number;
    fileName: string;
    progressBar?: number;
    status?: UploadStatus;
    clientId?: string;
    uuid?: string;
    gridRowDisable: boolean;
    rowIndex?: number;
    file?: any;
    sasUrl: string;
    pdf?: IPdfDocumentFacade;
    isPDFloaded?: boolean;
    isDeleted: boolean;
    authorityId: number;
    mailingAddress: string;
    formName: string;
    authorityName: string;
    fileExtension?: string;
}

export interface IPaperFileData {
    id: number | 0;
    fileName: string,
    authorityId: number,
    uploadedBy: number;
    uploadedOn: Date;
    isDeleted: boolean | false;
    fileControls: IPaperFilePage[];
    isModified: boolean | false;
    mailingAddress: string;
    formName: string;
}

export interface IOptions {
    label: any;
    value: any;
}

export interface IPaperFileControl extends ISignatureControl {
    required: boolean;
    tooltip: string;
}

export interface IPaperFilePage {
    pageNo: number,
    controls: IPaperFileControl[]
}

export const initialControl: IPaperFileControl = {
    type: SignatureControlTypes.None,
    controlGuid: "",
    top: 0,
    left: 0,
    signatureControlRole: SignatureControlRole.None,
    selectedSignStyle: "",
    selectedEroImage: "",
    tooltip: "",
    required: false
}

export const DocumentFilingTypeList = {
    "None": "None",
    "EFiled": "E-Filed",
    "Both": "E-Filed & Paper Filed"
};

export const DocumentFilingTypeOptionsList = {
    1: "E-Filed",
    2: "E-Filed & Paper Filed"
}

