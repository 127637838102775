import { addTask } from 'domain-task';
import { Reducer } from 'redux';
import { AppThunkAction } from '../';
import { actionTypes } from '../../types/ActionTypes';
import { initialSideMenu } from '../../components/navigation/Layout/LayoutConfig';
import { IMenuSection } from "@sssuite-component-ui/ss-suite-layout/dist/SideMenu/SideMenu";
import { ProductType } from "../../Core/ViewModels/Company/CompanyViewModel";
import { ISideMenu, IProductData, IProduct } from "../../Core/ViewModels/Common/Layout";
import { SSSUITE_API_BASE_URL } from "../../utils/constants";
import { IsAccessibleProduct } from '../../oidcClient/userRoles';
import history from '../../routes/history';
import { IUserRole } from '../../Core/ViewModels/User/UserViewModel';

interface ReceiveUserAccessibleProductAction {
    type: actionTypes.RECEIVE_USER_ACCESSIBLE_PRODUCT;
    isSSRAccessible: boolean;
}
interface RequestUserAccessibleProductAction {
    type: actionTypes.REQUEST_USER_ACCESSIBLE_PRODUCT;
}
interface ReceiveUserLeftMenuAction {
    type: actionTypes.RECEIVE_USER_LEFT_MENU;
    userLeftMenu: IMenuSection[];
}
interface RequestUserLeftMenuAction {
    type: actionTypes.REQUEST_USER_LEFT_MENU;
}
type KnownAction = ReceiveUserAccessibleProductAction | RequestUserAccessibleProductAction | ReceiveUserLeftMenuAction | RequestUserLeftMenuAction;
type DispatchActions = KnownAction;

export const actionCreators = {
    requestUserRoles: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let state = getState().auth.user;

        const fetchTask = fetch(SSSUITE_API_BASE_URL + `api/user-group/user-roles/${state.profile.user_id}`, {
            method: "GET",
            credentials: "include",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
            .then((response) => response.json() as Promise<IUserRole>)
            .then((data) => {
                dispatch(actionCreators.requestUserLeftMenu(data));
            })
            .catch(function (error) {
                console.log(error);
            });
        addTask(fetchTask);
    },
    requestUserLeftMenu: (userRoles: IUserRole): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let state = getState().LeftMenu;

        if (!state || state.isInitialState) {
            const fetchTask = fetch(SSSUITE_API_BASE_URL + `api/menu/product-left-menu/${ProductType.Returns}`, {
                method: "GET",
                credentials: "include",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            })
                .then((response) => response.json() as Promise<IMenuSection[]>)
                .then((data) => {
                    dispatch({ type: actionTypes.RECEIVE_USER_LEFT_MENU, userLeftMenu: data })
                    if (data.length > 0) {
                        let section = data.find(sections => sections.items.find(menu => menu.disabled == false
                            && menu.caption.toLowerCase() != 'help'));
                        if (section) {
                            let location = window.location.pathname;
                            if (location == '/' || location =='/CompanyAssignments') {
                                let menu = section.items.find(m => m.disabled == false);
                                if (menu && menu.route && menu.route != "") {
                                    history.push(menu.route);
                                } else {
                                    let subMenu = menu && menu.items?.find(x => x.disabled == false);
                                    if (subMenu && subMenu.route) {
                                        if (subMenu.title == "User Management" && !subMenu.disabled) {
                                            history.push("/UserPermission");
                                            return data;

                                        }
                                        history.push(subMenu.route);
                                    }
                                }
                            }
                        }
                        else if (userRoles.roles.length == 1 && userRoles.roles.find(x => x.roleName == 'Suite - ERO Signer')) {
                            history.push("/Unauthorized")
                        }
                        else { history.push("/FeatureDisabled") }
                    }

                    return data;
                })
                .catch(function (error) {
                    console.log(error);
                });
            addTask(fetchTask);
            dispatch({ type: actionTypes.REQUEST_USER_LEFT_MENU });
        }
    },
    requestUserAccessibleProducts: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let state = getState().AccessibleProductData;

        if (!state || state.isInitialState) {
            const fetchTask = fetch(SSSUITE_API_BASE_URL + `api/menu/accessible-products`, {
                method: "GET",
                credentials: "include",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            })
                .then((response) => response.json() as Promise<IProduct[]>)
                .then((response) => {
                    var isAccessible = IsAccessibleProduct(response);
                    dispatch({ type: actionTypes.RECEIVE_USER_ACCESSIBLE_PRODUCT, isSSRAccessible: isAccessible })
                    return response;
                })
                .catch(function (error) {
                    console.log(error);
                });
            addTask(fetchTask);
            dispatch({ type: actionTypes.REQUEST_USER_ACCESSIBLE_PRODUCT });
        }
    }
}

const unloadedState: ISideMenu = initialSideMenu;

export const reducer: Reducer<ISideMenu> = (state = unloadedState, incomingAction) => {
    const action = incomingAction as DispatchActions;
    switch (action.type) {
        case actionTypes.RECEIVE_USER_LEFT_MENU:
            let receivedLeftMenu = { ...state };
            receivedLeftMenu.leftMenu = action.userLeftMenu;
            receivedLeftMenu.isInitialState = false;
            return receivedLeftMenu;
        case actionTypes.REQUEST_USER_LEFT_MENU:
            let leftMenu = { ...state };
            return leftMenu;
        default:
            const exhaustiveCheck: any = action;
    }
    return state;
};


const unloadedAccessibleProductsState: IProductData = {
    isAccessible: false,
    isInitialState: true
};

export const productsReducer: Reducer<IProductData> = (state = unloadedAccessibleProductsState, incomingAction) => {
    const action = incomingAction as DispatchActions;
    switch (action.type) {
        case actionTypes.RECEIVE_USER_ACCESSIBLE_PRODUCT:
            let receivedaccessibleProducts = { ...state };
            receivedaccessibleProducts.isAccessible = action.isSSRAccessible;
            receivedaccessibleProducts.isInitialState = false;
            return receivedaccessibleProducts;
        case actionTypes.REQUEST_USER_ACCESSIBLE_PRODUCT:
            let accessibleProducts = { ...state };
            return accessibleProducts;
        default:
            const exhaustiveCheck: any = action;
    }
    return state;
};
