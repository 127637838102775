import React from 'react';
import * as Bs from 'react-bootstrap';

export interface IK1PopoverProps {
    k1Attachments: string[]
}

export interface IK1PopoverState {
    showPopover: boolean;
    popoverTarget: any;
}

export class K1AttachmentPopover extends React.Component<IK1PopoverProps, IK1PopoverState> {

    constructor(props) {
        super(props);

        this.state = {
            showPopover: false,
            popoverTarget: {}
        }
    }

    onCancel = () => {
        this.setState({ showPopover: false });
    }

    attachAddPopoverRef = (target: any) => {
        this.setState({ popoverTarget: target })
    }

    public render() {
        const addPopover = (
            <Bs.Popover id="popover-voucher-add" style={{ maxWidth: 1000 }}>
            <Bs.Popover.Title className='k1-popover-header' as="h3">{this.props.k1Attachments.length} Attachment(s)</Bs.Popover.Title>
            <Bs.Popover.Content>
                <div className="new-main-content k1-popover-content">
                    <Bs.Row style={{ minWidth: '120px', maxWidth: '300px' }}>
                        <table>
                        {
                            this.props.k1Attachments.map((x: any) => (
                                <tr style={{ height: '1.2rem' }}>
                                    <td className='k1-popover-row' style={{ textAlign: 'left' }}>
                                        <i className='fas fa-circle bullet-point'></i> {x}
                                    </td>
                                </tr>
                            ))
                        }
                        </table>
                    </Bs.Row>
                </div>
            </Bs.Popover.Content>
            </Bs.Popover>
        )

        return (
            <div>
                <span>
                    <div
                        className="paperclip-icon"
                        ref={this.attachAddPopoverRef}
                        onMouseEnter={() => this.setState({ showPopover: true })}
                        onMouseLeave={() => this.setState({ showPopover: false })}
                    >
                        <img
                            width="15px"
                            height="21px"
                            src={require("../../../../assets/images/paperclip-icon.svg").default}
                            alt="paperclip"
                            className="svg-shadow"
                        />
                    </div>
                </span>
                <Bs.Overlay
                    rootClose={true}
                    onHide={this.onCancel}
                    placement="right"
                    target={this.state.popoverTarget}
                    show={this.state.showPopover}
                >
                    {addPopover}
                </Bs.Overlay>
            </div>
        )
    }
};
