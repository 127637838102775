import * as React from "react";
import { Card, Row, Col, Button } from "react-bootstrap";
import * as TaxDocument from "../../../common/TaxReturn";
import { phoneNumberDisplay, isIndividualEnagagementType } from "../../../helper/HelperFunctions";
import { FinishProcessReturn } from "../../../helper/Constants";
import * as bootbox from "bootbox";
import { EngagementType } from "../../../common/TaxReturn";
import { disableExtensionReminders, IsDuplicateExtension } from "../../../../store/common/ExtensionStore";
import { SendTaxReturnResources } from "../../../helper/ResourceIdConstants";
import * as Helper from "../../../helper/HelperFunctions";

interface IDeliverToCompany {
    taxreturn: any;
    cancelFinish: () => void;
    deliverToClient: (resourceId?: string) => void;
}

interface IDeliverToCompanyState {
    sendButtonClicked: boolean;
}

export class DeliverToCompany extends React.Component<IDeliverToCompany, IDeliverToCompanyState> {
    constructor(props) {
        super(props);
        this.state = {
            sendButtonClicked: false
        };
    }
    public onDeliverToCompany = () => {
        this.setState({ sendButtonClicked: true });
        let taxIdentifier = isIndividualEnagagementType(this.props.taxreturn.engagementType)
            ? this.props.taxreturn.taxpayer.ssn
            : this.props.taxreturn.partnership.ssn;
        let tempThis = this;
        if ((!(this.props.taxreturn.groupId > 0) && Helper.validateDocumentLocation(this.props.taxreturn)) || this.props.taxreturn.groupId > 0) {
            IsDuplicateExtension(taxIdentifier, EngagementType[this.props.taxreturn.engagementType], this.props.taxreturn.taxYear)
                .then((isDuplicate) => {
                    if (isDuplicate) {
                        bootbox.dialog({
                            message: FinishProcessReturn.StatusMessage.DisableExtensionRemindersConfirmation.replace(
                                "<<taxyear>>",
                                this.props.taxreturn.taxYear
                            ),
                            className: "extensions-modal",
                            buttons: {
                                cancel: {
                                    label: "No",
                                    className: "btn-cancel btn-default",
                                    callback: function () {
                                        tempThis.props.deliverToClient(
                                            SendTaxReturnResources.Prefix + SendTaxReturnResources.DeliverToCompanyMenu
                                        );
                                    }
                                },
                                confirm: {
                                    label: "Yes, turn it off",
                                    className: "btn-confirm",
                                    callback: function () {
                                        disableExtensionReminders(
                                            taxIdentifier,
                                            EngagementType[tempThis.props.taxreturn.engagementType],
                                            tempThis.props.taxreturn.taxYear,
                                            tempThis.props.taxreturn.id
                                        );
                                        tempThis.props.deliverToClient(
                                            SendTaxReturnResources.Prefix + SendTaxReturnResources.DeliverToCompanyMenu
                                        );
                                    }
                                }
                            }
                        });
                    } else {
                        this.props.deliverToClient(SendTaxReturnResources.Prefix + SendTaxReturnResources.DeliverToCompanyMenu);
                    }
                })
                .catch((error) => {
                    this.props.deliverToClient(SendTaxReturnResources.Prefix + SendTaxReturnResources.DeliverToCompanyMenu);
                });
        }
    };
    public render() {
        return (
            <Card>
                <Card.Body>
                    {TaxDocument.isPartnership(this.props.taxreturn) ? (
                        <div>
                            <Row className="marB15">
                                <Col className="nopadding-right" sm={3} as={Col}>
                                    Company Name
                                </Col>
                                <Col className="nopadding-left" sm={9} as={Col}>
                                    {this.props.taxreturn.partnership.name}
                                </Col>
                            </Row>
                            <Row className="marB15">
                                <Col className="nopadding-right" sm={3} as={Col}>
                                    Company EIN
                                </Col>
                                <Col className="nopadding-left" sm={9} as={Col}>
                                    {this.props.taxreturn.partnership.ssn}
                                </Col>
                            </Row>
                            <Row className="marB15">
                                <Col className="nopadding-right" sm={3} as={Col}>
                                    Company Email
                                </Col>
                                <Col className="nopadding-left" sm={9} as={Col}>
                                    {this.props.taxreturn.partnership.email}
                                </Col>
                            </Row>
                            {this.props.taxreturn.partnership.mobileNumber && (
                                <Row className="marB15">
                                    <Col className="nopadding-right" sm={3} as={Col}>
                                        Mobile Number
                                    </Col>
                                    <Col className="nopadding-left" sm={9} as={Col}>
                                        {this.props.taxreturn.partnership.countryCode}{" "}
                                        {phoneNumberDisplay(this.props.taxreturn.partnership.mobileNumber)}
                                    </Col>
                                </Row>
                            )}
                        </div>
                    ) : (
                        ""
                    )}
                    <div>
                        <Col sm={12}>
                            <Button
                                type="button"
                                variant="info"
                                className="float-right"
                                data-test-auto="DD34A32B-935B-4D21-AF90-0989DE8BFD3E"
                                onClick={this.onDeliverToCompany}
                                disabled={this.state.sendButtonClicked}
                            >
                                <i className="fa fa-paper-plane"></i>Send
                            </Button>
                            <Button
                                type="button"
                                variant="default"
                                className="float-right marR05 btn-white btn-default"
                                data-test-auto="EAB6FEF8-0152-495A-ACAC-F07B48F6BF09"
                                onClick={this.props.cancelFinish}
                            >
                                <i className="fas fa-times"></i>Cancel
                            </Button>
                        </Col>
                    </div>
                </Card.Body>
            </Card>
        );
    }
}

export default DeliverToCompany;
