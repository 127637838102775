import React from "react";
import { ReactComponent as BellIcon } from "../../../../assets/images/bell.svg";
import { ReactComponent as SupportIcon } from "../../../../assets/images/support.svg";
import { openWidgetOnClick } from "@sssuite-component-ui/forethought-ai-chatbot";
import { TopRibbon } from "../../../helper/ResourceIdConstants";

interface IChildComponentsProps {
    toggleShowModal: (prop: string) => void,
    assignRef: (reference: any) => void,
    toggleNotificationPopover: (prop: boolean) => void
    resourceIds: string[]
}

export const ChildComponents = (props: IChildComponentsProps) => {

    const { assignRef, toggleNotificationPopover, resourceIds } = props;

    const title = resourceIds.findIndex(x => x == TopRibbon.ReportAProblem) > -1 ? "Support" : "";

    return (
        <>
            <BellIcon
                className="header-icon"
                onClick={() => toggleNotificationPopover(true)}
                ref={(reference: any) => assignRef(reference)}
                title="Notifications"
            ></BellIcon>

            <div data-resource-id={TopRibbon.ReportAProblem}>
                <SupportIcon
                    className="header-icon"
                    onClick={openWidgetOnClick}
                    title={title}
                ></SupportIcon>
            </div>
        </>
    );
};
