import { CCHUnitStatus, CCHUnitStatusResponse } from '../../store/reports/CCHReturns/CCHReturnsState';
import React, { Component, useState } from 'react';
import * as Bs from 'react-bootstrap';
import { BootstrapTable, TextFilter, TableHeaderColumn, CustomFilter } from 'react-bootstrap-table';
import { Button } from 'react-bootstrap';
import moment from 'moment';
import { CustomDateFilter } from '../../components/reports/DeliveredReturns/CustomDateFilter';
import { Loader, LoadingOverlay } from "react-overlay-loader";
import { OverlayLoader } from '../helper/OverlayLoader';


interface CCHEFileStatusTableProps {
	show: boolean;
    cchEfileUnitStatusModel : CCHUnitStatusResponse;
    onClose: () => void;
    fetchCCHUnitStatus: (id: number, queryString: string) => any;
    id: number;
}

interface CCHEFileStatusFilters {
    authority: string;
    status: string;
    statusDesc: string;
    summary: string;
    statusDate: string;
    sortBy: string;
    sortOrder: string;
    pageNo: number;
    pageSize: number;
}

class CCHEFileStatusTable extends Component<CCHEFileStatusTableProps, {}> {
    
    constructor(props: CCHEFileStatusTableProps) {
        super(props);

		this.defaultType = this.defaultType.bind(this);
        this.onClose = this.onClose.bind(this);
        this.setDefaultValues = this.setDefaultValues.bind(this);
        this.fetchEFileStatusList = this.fetchEFileStatusList.bind(this);
        this.setNoContent = this.setNoContent.bind(this);
    }

    private setDefaultValues(){
        this.FilterQuery = "";
        this.CCHEfileFilters = this.setDefaultFilters();
    }

    private onClose(){
        this.setDefaultValues();
        this.props.onClose();
    }
    
    CCHEfileFilters :  CCHEFileStatusFilters = this.setDefaultFilters();
    FilterQuery : string ="";
        
    private setDefaultFilters() : CCHEFileStatusFilters {
        return {
            authority: "",
            status: "",
            statusDesc: '',
            summary: '',
            sortBy: '',
            sortOrder: '',
            statusDate: '',
            pageNo: 1,
            pageSize: 10
        };
    };

    private defaultType(cell: any, row: any) {
        return (
            <div title={cell} className="ellipsis">
                {cell}
            </div>
        );
	}

    public fetchEFileStatusList()
    {
        console.log('CCHEfileFilters',this.CCHEfileFilters);
        var filter = Object.keys(this.CCHEfileFilters)
                             .filter(key=> this.CCHEfileFilters[key] !== undefined && this.CCHEfileFilters[key] !== null && this.CCHEfileFilters[key] !== "")
                             .map((key) => `${key}=${this.CCHEfileFilters[key] || ""}`)
                             .join('&');

        if(this.FilterQuery !== filter)
        {
            this.FilterQuery = filter;
            this.props.fetchCCHUnitStatus(this.props.id, filter);
        }
        return;
    }

    private statusDateFilter = (filterHandler: any, customFilterParameters: any) => {
        return (
            <CustomDateFilter
                onRef={(ref: any) => { 
                    if(typeof(ref) == 'string')
                        this.CCHEfileFilters.statusDate = ref
                }}
                filterHandler={filterHandler}
                customFilterParameters={customFilterParameters}
            />
        );
    };

    private summaryColorMapper =  {
        pending: "status-btn-orange",
        exported: "status-btn-sapphire",
        accepted: "status-btn-shamrock",
        rejected: "status-btn-magenta",
        stopped: "status-btn-orange",
        exception: "status-btn-sapphire"
    }

    private setNoContent() {
        if (this.props.cchEfileUnitStatusModel.isLoading) {
            return (
                <LoadingOverlay style={{ height: "400px" }}>
                    <Loader loading={this.props.cchEfileUnitStatusModel.isLoading} />
                </LoadingOverlay>
            );
        }
    }

    render() {
        const cchUnitStatusColumns = [
            {
                header: 'Authority',
                key: 'authority',
                isKey: true,
                dataFormat: this.defaultType,
                toolTip: true,
                dataSort: true,
                width: '15%',
                filter: { type: "TextFilter", placeholder: "Authority", style: { font: "bold" } } as TextFilter
            },
            {
                header: 'Status',
                key: 'status',
                isKey: false,
                dataFormat: this.defaultType,
                toolTip: false,
                dataSort: true,
                width: '23%',
                filter: { type: "TextFilter", placeholder: "Status", style: { font: "bold" }} as TextFilter
            }, 
            {
                header: 'Status Description',
                key: 'statusDesc',
                isKey: false,
                dataFormat: this.defaultType,
                toolTip: true,
                dataSort: true,
                width: '37%',
                filter: { type: "TextFilter", placeholder: "Status Description", style: { font: "bold" } } as TextFilter
            },
            {
                header: 'Summary',
                key: 'summary',
                isKey: false,
                dataFormat: (_, row) =>
                    {
                        var className = "table-status-btn " + this.summaryColorMapper[row.summary.toLowerCase()] || "status-btn-orange";
                        return <span className={className}>{row.summary}</span>;
                    },
                toolTip: false,
                dataSort: true,
                width: '13%',
                filter: { type: "TextFilter", placeholder: "Summary", style: { font: "bold" }} as TextFilter
            },
            {
                header: 'Date',
                key: 'statusDate',
                isKey: false,
                dataFormat: (_,row) => moment(row.statusDate).format("MM/DD/YYYY"),
                toolTip: false,
                dataSort: true,
                width: '12%',
                filter: {
                    type: "CustomFilter",
                    getElement: this.statusDateFilter
                } as CustomFilter
            },
        ];

        let cchUnitStatusData: CCHUnitStatus[] = this.props.cchEfileUnitStatusModel.data || [];

        let renderShowsTotal = (start: number, to: number, total: number) =>
        {
            return (
                <p>
                    Showing {start} to {to} of {total} entries
                </p>
            );
        }

        let onFilterChange = (filterObj) =>{

            console.log(filterObj);

            this.CCHEfileFilters = {
                ...this.CCHEfileFilters,
                authority : filterObj.authority?.value ?? "",
                status : filterObj.status?.value ?? "",
                statusDesc : filterObj.statusDesc?.value ?? "",
                summary : filterObj.summary?.value ?? "",
                statusDate : filterObj.statusDate?.value ?? "",
                pageNo : 1
            }

            this.fetchEFileStatusList();
        }

        let onPageChange = (page, sizePerPage) =>{
            
            this.CCHEfileFilters.pageNo = page;
            this.fetchEFileStatusList();
        }

          let onSortChange = (sortName, sortOrder) => {

            this.CCHEfileFilters = {
                ...this.CCHEfileFilters,
                sortBy : sortName,
                sortOrder : sortOrder,
                pageNo : 1
            }
            this.fetchEFileStatusList();
          }


        const options = {
            onSortChange: onSortChange,
            onPageChange: onPageChange,
            sizePerPage: this.CCHEfileFilters.pageSize,
            page: this.CCHEfileFilters.pageNo,
            paginationShowsTotal: renderShowsTotal,
            onRowDoubleClick: function (row: any) {},
            noDataText: this.setNoContent(),
            onFilterChange:onFilterChange,
            nextPage: <span className="fa fa-angle-right" />,
            prePage: <span className="fa fa-angle-left" />,
            firstPage: <span className="fa fa-angle-double-left" />,
            lastPage: <span className="fa fa-angle-double-right" />
        };

        let modalBody =  
        <div data-test-auto="B510184C-5041-464B-9904-107F0B3C6F60">
            <BootstrapTable 
                ref="table" 
                data={cchUnitStatusData}
                remote={true}
                fetchInfo={{ dataTotalSize: this.props.cchEfileUnitStatusModel.totalCount }}
                options={options}
                striped
                hover 
                pagination>
                {cchUnitStatusColumns.map((column, index) => {
                    return (
                    <TableHeaderColumn 
                        columnTitle={column.toolTip}
                        dataField={column.key}
                        isKey={column.isKey} 
                        dataFormat={column.dataFormat} 
                        filter={column.filter}
                        width={column.width}
                        dataSort={column.dataSort} >

                            {" "}
                            { column.header }
                    
                    </TableHeaderColumn>)
                })} 
            </BootstrapTable> 
        </div> 


        return <Bs.Modal className="download-history-modal" show={this.props.show} onHide={this.onClose} >
                    
                    <Bs.Modal.Header closeButton onClick={this.onClose} data-test-auto="134ABB8D-9B61-4B6F-A47C-DCD94D6BAF9D">
                        <Bs.Modal.Title>Released E-file Status</Bs.Modal.Title>
                    </Bs.Modal.Header>
                    
                    <Bs.Modal.Body>
                        {modalBody}
                    </Bs.Modal.Body>
                    
                    <Bs.Modal.Footer>
                        <Button variant="default" className="btn-white" onClick={this.onClose}>
                            <i className="fas fa-times"></i>Close
                        </Button>
                    </Bs.Modal.Footer>

                </Bs.Modal >
    
    }
}

export default CCHEFileStatusTable;