import { useDispatch, useSelector } from "react-redux";
import { SessionTimeoutHelper } from "@sssuite-component-ui/session-timeout";
import { ApplicationState } from "src/store";
import { signoutRedirect } from "./userService";
import React from "react";
import { actionCreators } from "src/store/auth/actions";

export interface WithLogoutProps {
    onLogout: () => void;
}

export const WithLogout = (Component) => {
    return function Wrapper(props) {
        const { device_id } = useSelector((state: ApplicationState) => state.auth.user.profile);
        const dispatch = useDispatch();
        return (
            <Component
                {...props}
                onLogout={() => {
                    SessionTimeoutHelper.logoutHelper(device_id, signoutRedirect);
                    dispatch(actionCreators.loggedOut());
                }}
            />
        );
    };
};
