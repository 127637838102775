import * as React from 'react';
import { Col, Card, Row, Accordion } from 'react-bootstrap';
import Select from 'react-select';
import { SignatureControlRole, SignatureControlTypes, SignatureGroupType } from '../../../common/TaxReturn';
import { EfileSignControl } from './EfileSignControl';

export interface IEfileSignatureCardProps {
	title: string;
	selectedSigner: ISigner,
	signer: ISigner[],
	signerChangeHandler(selectedValue: any): void,
	isAssignedToLoggedinUser?: boolean,
	ControlBoxItems: IEfileSignControlBoxItem[],
    onSignatureControlFocus(focusedGroup: SignatureGroupType): void;
    dragStart(event: any, controlType: SignatureControlTypes, signatureRole: SignatureControlRole): void;
	dragEnd?: (event: any) => void;
	keyPrefix: string;
}

export interface IEfileSignatureCardState {
	open: boolean;
}

export interface IEsignControlBoxItem {
	caption: string,
	icon: string,
	controlType: SignatureControlTypes
}

export interface ISigner {
	value: number;
	label: string;
    signatureRole: SignatureControlRole;
    disabled?: boolean;
}

interface IEfileSignControlBoxItem {
	caption: string,
	icon: string,
    controlType: SignatureControlTypes,
    helpText: string
}

export class EfileSignatureControlCard extends React.Component<IEfileSignatureCardProps, IEfileSignatureCardState>{

	state = {
		open: true
	};

	public render() {


		const { keyPrefix } = this.props;

        const esignControls = this.props.ControlBoxItems.map((controlBoxItem, Index) => {
            return <EfileSignControl
                key={keyPrefix + Index}
                icon={controlBoxItem.icon}
                caption={controlBoxItem.caption}
                controlType={controlBoxItem.controlType}
                helpText={controlBoxItem.helpText}
                dragStart={(event, controlType) => { this.props.dragStart(event, controlType, this.props.selectedSigner.signatureRole) }}
                dragEnd={this.props.dragEnd}
            />
		});

		let icon = this.state.open ? "fas fa-minus" : "fas fa-plus";
		return (
			<Accordion defaultActiveKey="0" onSelect={() => this.setState({ open: !this.state.open })}>
				<Card>
					<Accordion.Toggle eventKey="0">
						<Card.Header style={{ padding: "10px" }}>
							<Card.Title as="h3" style={{ marginBottom: '0px' }}>
								<i className={icon}></i> {this.props.title}
								</Card.Title>
						</Card.Header>
					</Accordion.Toggle>
					<Accordion.Collapse eventKey="0">
						<Card.Body>
							<div className="form-horizontal">
								<Row className="marB15">
									<Col sm={12}>
										<Select
											name="form-field-name"
											value={this.props.selectedSigner}
											onChange={this.props.signerChangeHandler}
											options={this.props.signer}
											data-test-auto="32C60802-06ED-42F6-80E2-244747E4F8F9"
											onFocus={() => { this.props.onSignatureControlFocus(SignatureGroupType.Esign) }}                           
											clearable={false}                                    
										/>
									</Col>
								</Row>
							</div>
							<div>
								{esignControls}
							</div>
						</Card.Body>
					</Accordion.Collapse>
				</Card>
			</Accordion>
		)
	};
};