import * as React from "react";
import { Button, Modal } from "react-bootstrap";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import { CompanySettingsConstants } from "../../helper/Constants";
import { PaymentGateway } from "../../../Core/ViewModels/Purchase/PurchaseViewModels";
import "./StripeAccountSettings.css";
import { ModalCloseIcon, StripeLogo } from "../../../components/svg/IconCollection";

interface Props {
    showStripeIntegrationSettingsPopup: boolean;
    onHideStripePopup: () => void;
    requestPaymentRegisterUrl(paymentGateway: PaymentGateway, standardRegister: boolean, callback?: (data: any) => void): any;
}

interface IState {
    showRegister: boolean;
    standardRegister: boolean;
    saving: boolean;
}

export class StripeAccountSettings extends React.Component<Props, IState> {
    constructor(props: Props) {
        super(props);
        this.state = {
            showRegister: false,
            standardRegister: false,
            saving: false
        };
    }

    redirectToRegistration = (data: any) => {
        if (data) {
            window.location.replace(data);
        }
    };

    handleStandardRegister = () => {
        this.setState(
            {
                saving: true
            },
            () => {
                this.props.requestPaymentRegisterUrl(PaymentGateway.Stripe, true, this.redirectToRegistration);
            }
        );
    };

    public render() {
        return (
            <Modal
                className="stripe-integration-modal"
                show={this.props.showStripeIntegrationSettingsPopup}
                onHide={() => this.props.onHideStripePopup()}
            >
                <Modal.Header>
                    <Modal.Title>
                        <span style={{ alignSelf: 'center' }}>Connect to Stripe</span>
                    </Modal.Title>
                    <div
                        data-test-auto="e2ab6c41-b6f5-4181-b38f-3455585b2141"
                        className="closeIcon"
                        onClick={this.props.onHideStripePopup}
                    >
                        <ModalCloseIcon />
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <LoadingOverlay className="full-height">
                        <div>
                            Instantly begin making or receiving payments for your invoices with Stripe by either creating a new
                            account or linking an existing one. For getting started and more information, please visit this{" "}
                            <a
                                href="https://safesendreturns.zendesk.com/hc/en-us/articles/4402806536595-Stripe-Integration-for-Client-Invoicing"
                                target="_blank"
                                className="link"
                            >
                                link
                            </a>
                            .
                        </div>
                        <Loader loading={this.state.saving} text={CompanySettingsConstants.OverlayMessage.ApplicationLoading} />
                    </LoadingOverlay>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        className="btn btn-stripe"
                        onClick={this.handleStandardRegister}
                        data-test-auto="A53F04D2-4D1E-46B6-A5B8-4EACDA39AE1A"
                        disabled={this.state.saving}
                    >
                        Connect with
                        <StripeLogo className="stripe-logo" />
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default StripeAccountSettings;
