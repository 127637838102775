import * as React from "react";
import { IUserDefaultSettingsStates } from "../navigation/profile/MySettings";
import {
	ISuiteTaxSoftwareSetting,
	TaxSoftwareType,
} from "../../Core/ViewModels/Company/CompanySettingsViewModel";
import { logger } from "../../routes/LoggedIn";
import { CommonResources  } from '../helper/ResourceIdConstants';

export interface UploadFabButtonProps {
    userDefaultSettings: IUserDefaultSettingsStates;
    taxSoftwareSetting: ISuiteTaxSoftwareSetting;
	onUploadReturnsClick: (taxSoftware: TaxSoftwareType) => void;
	parentResourceIdentifier: string;
}

const NO_INDEX = -1;

export class UploadFabButton extends React.Component<UploadFabButtonProps, {}> {
	onMainBtnClick = (event: any, taxSoftware: TaxSoftwareType[]) => {
		if (taxSoftware.length == 1) {
			this.props.onUploadReturnsClick(taxSoftware[0]);
		}
	};

	onBtnClick = (taxSoftware: TaxSoftwareType) => {
		this.props.onUploadReturnsClick(taxSoftware);
	};

	public render() {

		let taxSoftwareList: TaxSoftwareType[] = this.props.taxSoftwareSetting.taxSoftware;
		let transform: number = 0;

		return (
			<div>
				{taxSoftwareList &&
					taxSoftwareList.length >= 1 &&
					taxSoftwareList.map((value, index) => {
						const style = { transform: "translateX(-" + transform + "em)" };
						transform = transform + 3.0;
						return value === TaxSoftwareType.ProSystems ? (
							<div
                                data-resource-id={this.props.parentResourceIdentifier + CommonResources.CCHUploadButton}
								key={index}
								title="CCH"
								data-test-auto="93AFA737-631C-493E-80AD-67F3B5E81A41"
								className="cch-btn"
								onClick={() => {
									this.onBtnClick(value);
									logger.trackPageView("Clicked on CCH button");
								}}
								style={style}
							>
								CCH
							</div>
						) : value === TaxSoftwareType.UltraTax ? (
							<div
                                    data-resource-id={this.props.parentResourceIdentifier + CommonResources.UTUploadButton}
								key={index}
								title="UltraTax"
								data-test-auto="A0C11973-E03C-42F9-98B0-70CD9FB0FE4C"
								className="ut-btn"
								onClick={() => {
									this.onBtnClick(value);
								}}
								style={style}
							>
								UT
							</div>
						) : value === TaxSoftwareType.GoSystem ? (
							<div
                                        data-resource-id={this.props.parentResourceIdentifier + CommonResources.GSUploadButton}
								key={index}
								title="GoSystem"
								data-test-auto="8CD788EC-5A73-404D-AEB4-473D325DB7B6"
								className="gs-btn"
								onClick={() => {
									this.onBtnClick(value);
								}}
								style={style}
							>
								GS
							</div>
						) : value === TaxSoftwareType.Lacerte ? (
							<div
                                            data-resource-id={this.props.parentResourceIdentifier + CommonResources.LTUploadButton}
								key={index}
								title="Lacerte"
								data-test-auto="B5ACAB24-F260-4125-A732-911E1B69187A"
								className="lt-btn"
								onClick={() => {
									this.onBtnClick(value);
								}}
								style={style}
							>
								LT
							</div>
						) : (
							""
						);
					})}
			</div>
		);
        return(<div></div>)
	}
}
