import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import * as InUseReturnsStore from '../../store/reports/InUseReturnsStore';
import * as FilterStore from '../../store/reports/FilterStore';
import * as TaxDocumentStore from '../../store/common/TaxDocumentStore';
import * as InUseReturnsState from '../../store/reports/InUseReturnsState';
import * as Component from './InUseReturns';
import * as UserStore from '../../store/userManagement/UserStore';
import * as UserSettingStore from '../../store/userManagement/UserSettingStore';
import * as CompanyStore from '../../store/company/CompanyStore';
import * as UploadTaxReturnStore from '../../store/common/uploadTaxReturn';
import * as GroupedReturnStore from '../../store/groupedreturns/GroupedReturnStore';
import * as ClientManagementSettingStore from "../../store/company/SuiteClientManagementSettingStore";
import * as BulkOperationsStore from '../../store/common/BulkOperationsStore';

export default connect(
    (state: ApplicationState) => ({
        InUse: state.inUseReturns, // Selects which state properties are merged into the component's props
        taxDocuments: state.taxDocuments,
        userStore: state.ssrUserList,
        reportFilters: state.reportFilters,
        userSettings: state.userSettings,
        company: state.companyData,
        uploadTaxReturnStore: state.UploadTaxReturnStore,
        groupedReturnStore: state.groupedReturnStore,
        userProfile: state.userProfile,
        ssrUsers: state.ssrUsers,
        taxSoftwareSetting: state.taxSoftwareSetting,
        clientManagementSetting: state.clientManagementSetting
    }),
    {
        ...InUseReturnsStore.actionCreators,
        ...TaxDocumentStore.actionCreators,
        ...UserStore.actionCreators,
        ...FilterStore.actionCreators,
        ...UserSettingStore.actionCreators,
        ...CompanyStore.actionCreators,
        ...UploadTaxReturnStore.actionCreators,
        ...GroupedReturnStore.actionCreators,
        ...ClientManagementSettingStore.actionCreators,
        ...BulkOperationsStore.actionCreators
    }
)(Component.InUseReturns as any);
