import * as React from "react";
import { ICompanySettings, ISuiteNotificationSetting } from "../../../Core/ViewModels/Company/CompanySettingsViewModel";
import { CheckBoxComponent } from "../../common/CheckBoxComponent";
import Select from "react-select";
import ToggleSwitch from "../../common/ToggleSwitch";

interface ISigningReminderNotification {
    suiteNotificationSetting: ISuiteNotificationSetting;
    companySettings: ICompanySettings;
    handleSigningReminder(event: any): void;
    handleSettingSigningReminderDays(event: any): void;
    handleSettingSMSSigningReminderDays(event: any): void;
    handleSMSSigningReminder(event: any): void;
}

export class SigningReminderNotification extends React.Component<ISigningReminderNotification, {}> {
    buildOptions() {
        let arr: any = [];
        for (let i = 1; i <= 30; i++) {
            if (i == 1) {
                arr.push({
                    value: i,
                    label: i + " day"
                });
            } else {
                arr.push({
                    value: i,
                    label: i + " days"
                });
            }
        }

        return arr;
    }

    public render() {
        return (
            <div id="divPaymentVoucherNotifications" className="new-main-content">
                <h3>Automatic Signing Reminders</h3>
                <div className="marL30">
                    <CheckBoxComponent
                        id={"EnableSigningReminder"}
                        text={"Enable Automatic Signing Reminders"}
                        checked={
                            this.props.companySettings.notificationSettingsModel.signingReminderNotificationSettings
                                .enableSigningReminder
                        }
                        onChange={this.props.handleSigningReminder}
                        datatestAuto={"8BAE0132-3B3C-492A-8399-B26C6F7582E8"}
                    />
                    <div className="row marT20">
                        <div className="col-sm-2 col-md-2 col-lg-2 padT07 voucher-value">Set default reminder to</div>
                        <div className="col-sm-2 col-md-2 col-lg-1 select-reminder-control">
                            <Select
                                name="form-field-name"
                                value={
                                    this.props.companySettings.notificationSettingsModel.signingReminderNotificationSettings
                                        .noOfDaysForSigningReminder
                                }
                                onChange={this.props.handleSettingSigningReminderDays}
                                options={this.buildOptions()}
                                data-test-auto="52A69016-2B50-4B88-84B4-3E82ED02586A"
                                clearable={false}
                            />
                        </div>
                        <div className="col-sm-2 col-md-2 col-lg-3 padT07">since last reminder/initial email</div>
                    </div>
                </div>
                <div className="marL30 marT20 padT07">
                    <h3>
                        <span className="font-color-black font600">SMS Text</span>
                    </h3>
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-12 voucher-value">
                            <span>Send default SMS Text Reminder before </span>
                            <div className="sms-esign-duedate select-reminder-control">
                                <Select
                                    name="form-field-name"
                                    value={
                                        this.props.companySettings.smsNotificationSettings.signingReminderNotificationSettings
                                            .noOfDaysForSigningReminder
                                    }
                                    onChange={this.props.handleSettingSMSSigningReminderDays}
                                    options={this.buildOptions()}
                                    data-test-auto="CF9F463D-3A82-458D-87E0-5FC7D4DF402F"
                                    clearable={false}
                                    disabled={
                                        !this.props.companySettings.smsNotificationSettings.signingReminderNotificationSettings
                                            .enableSigningReminder ||
                                        (this.props.suiteNotificationSetting &&
                                            !this.props.suiteNotificationSetting.isSMSNotificationEnabled)
                                    }
                                />
                            </div>
                            <span className="padT07">from due date</span>
                            <div className="padT07 sms-esign-duedate">
                                <ToggleSwitch
                                    switched={
                                        this.props.companySettings.smsNotificationSettings.signingReminderNotificationSettings
                                            .enableSigningReminder
                                    }
                                    handleChange={this.props.handleSMSSigningReminder}
                                    disabled={
                                        this.props.suiteNotificationSetting &&
                                        !this.props.suiteNotificationSetting.isSMSNotificationEnabled
                                    }
                                    title={
                                        this.props.suiteNotificationSetting &&
                                        !this.props.suiteNotificationSetting.isSMSNotificationEnabled
                                            ? "To change, enable SMS notification at the Suite level"
                                            : ""
                                    }
                                    dataTestChecked={
                                        this.props.companySettings.smsNotificationSettings.signingReminderNotificationSettings
                                            .enableSigningReminder
                                    }
                                    dataTestAuto="205FA448-0CAA-497B-9155-B7BA9E3D2907"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default SigningReminderNotification;
