import * as React from 'react';
import * as Bootstrap from 'react-bootstrap';
import { SaveFilters } from './SaveFilterModal';
import { VenusNotifier } from '../helper/VenusNotifier';
import { ReportFilterConstants, Common } from '../helper/Constants'
import * as bootbox from 'bootbox';
import { SessionStore } from '../helper/HelperFunctions'; 
import { CommonResources } from '../helper/ResourceIdConstants';

interface IFilter {
    show: boolean
    onFilterNameChange: any
    onFilterSave(onApplyFilter: (filter: IFilters) => void): void;
    onExportToExcel(onExportToComplete: () => void, resourceId: string): void;
    onClearFilter(clearAll?: boolean): void;
    filterList: IFilters[];
    onSetDefaultFilter(name: string, filterType: ReportFilterType): void;
    onRemoveDefaultFilter(name: string, filterType: ReportFilterType): void;
    onFilterDelete(filterName: string, filterType: ReportFilterType): any;
    onFilterSaveClick(filterName: string): void;
    onSaveFilterHide(): void;
    onLoadSelectedFilter(filter?: IFilters): void;
    defaultFilter: string | undefined;
    loadGrid(): void;
    showExportExcel: boolean;
    OnOpenColumnSettings?: () => void;
    proxyFilter: string;
    onPageReload: () => void;
    refreshDelay?: boolean;
    disableColumnSettings?: boolean;
    onSelectQuickReports?: (quickReportType: QuickReportType) => any;
    selectedQuickReport?: QuickReportType;
    showQuickReportOption?: boolean;
    setClearFilter?: any;
    loadSpinner?: boolean;
    totalRows: number;
    parentResourceIdentifier?:string;
}

export interface IFilters {
    name: string,
    sort: ISort,
    fields: { [id: string]: string; }
    searchText: string
    isDefaultFilter: boolean,
    filterType: ReportFilterType,
    isMasterFilter: boolean
}

export interface ISort {
    column: string,
    direction: SortDirections
}
export enum SortDirections {
    None = 0,
    Ascending = 1,
    Descending = 2
}

export interface FilterState {
    activeFilter: string,
    appliedFilters: string[],
    exportingState: boolean,
    showFilter: boolean,
    showQuickReport: boolean
}

export enum QuickReportType {
    None = 0,
    LinkNotAccessed = 1,
    KBAFailed = 2
}


export enum ReportFilterType {
    None = 0,
    MyAssignments = 1,
    AllAssignments = 2,
    Delivered = 3,
    InUseReturn = 4,
    Archived = 5,
    K1Delivery = 6,
	RecycleBin = 7,
    SendGroupedReturn = 8,
    GroupedReturnsReport = 9,
    InUseGroupedReturns = 10,
    LinkNotAccessed = 11,
    KBAFailed = 12,
    GroupedReturnsArchiveReport = 13,
}

export function clearAllTemporaryFilter() {
    for (let item in ReportFilterType) {
        if (isNaN(Number(item))) {
            SessionStore.remove(item.toUpperCase() + ReportFilterConstants.DefaultFilterBuilder);
            SessionStore.remove(item.toUpperCase() + ReportFilterConstants.DefaultFilterBuilder + "RECENT");
        }
    }
}

export class Filters extends React.Component<IFilter, FilterState> {
    private proxyRecentFilter: any;
    constructor(props: IFilter) {
        super(props);
        this.state = {
            activeFilter: '',
            appliedFilters: [],
            exportingState: false,
            showFilter: false,
            showQuickReport: false
        }
        this.onApplyFilter = this.onApplyFilter.bind(this);
        this.onClearFilter = this.onClearFilter.bind(this);
        this.onDeleteFilter = this.onDeleteFilter.bind(this);
        this.onApplyRecentFilter = this.onApplyRecentFilter.bind(this);
        this.getFilter = this.getFilter.bind(this);
        this.onApplySavedFilter = this.onApplySavedFilter.bind(this);
        this.onExportToComplete = this.onExportToComplete.bind(this);
    }
    UNSAFE_componentWillReceiveProps(nextProps: IFilter) {
        {
            if (typeof nextProps.defaultFilter !== 'undefined' && nextProps.filterList.length > 0 &&
                nextProps.defaultFilter.length > 0 && this.state.appliedFilters.findIndex(x => x == nextProps.defaultFilter) == -1) {
                this.setState({ activeFilter: nextProps.defaultFilter, appliedFilters: [nextProps.defaultFilter] },
                    () => {
                        //54539: quick fix for filters as react version changed
                        setTimeout(this.onDefaultFilterLoad, 1000);
                    });
            }
            if (this.props.defaultFilter != nextProps.defaultFilter) {
                this.addRecentFilter(nextProps.defaultFilter as string);
            }
        }
    }

    onDefaultFilterLoad = () => {
        const defaultFilter = this.getFilter(this.state.activeFilter);
        this.props.onLoadSelectedFilter(defaultFilter);
    }

    onApplySavedFilter(filter: IFilters) {
        let filterName = filter.name;
        this.addRecentFilter(filterName);
        if (this.state.activeFilter != filterName) {
            let appliedFilters: string[] = this.state.appliedFilters;
            let index = appliedFilters.indexOf(filterName);
            if (index != -1) {
                appliedFilters.splice(index, 1);
            }
            appliedFilters.push(filterName);
            this.setState({ activeFilter: filterName, appliedFilters: appliedFilters });
        }
        SessionStore.set(this.props.proxyFilter, JSON.stringify(this.getFilter(filterName)));
    }

    //Validation
    private isProxyFilter = (name: string): boolean => {
        return name === this.props.proxyFilter;
    }
    private addRecentFilter = (filterName: string): void => {
        if (filterName && filterName !== "" && !this.isProxyFilter(filterName)) {
            SessionStore.set(this.proxyRecentFilter, filterName);
        }
    }
    //Validation End

    onApplyFilter(filter: IFilters) {

        let filterName = filter.name;
        this.addRecentFilter(filterName);
        if (this.state.activeFilter != filterName) {
            let appliedFilters: string[] = this.state.appliedFilters;
            let index = appliedFilters.indexOf(filterName);
            if (index != -1) {
                appliedFilters.splice(index, 1);
            }
            appliedFilters.push(filterName);
            this.setState({ activeFilter: filterName, appliedFilters: appliedFilters }, () => { this.props.onLoadSelectedFilter(filter) });
        }
        SessionStore.set(this.props.proxyFilter, JSON.stringify(this.getFilter(filterName)));
    }

    getFilter(filterName: string) {
        return this.props.filterList.find(x => x.name == filterName);
    }

    onDeleteFilter(filterName: string, filterType: ReportFilterType) {
        let temp = this;
        bootbox.confirm({
            title: ReportFilterConstants.ControlsLabel.DeleteFilter,
            message: ReportFilterConstants.OtherMessage.DeleteFilter,
            buttons: {
                cancel: {
                    label: '<i class="fas fa-times"></i>' + Common.ButtonLabel.Cancel,
                    className: 'btn-white btn-default'
                },
                confirm: {
                    label: '<i class="fas fa-times"></i>' + Common.ButtonLabel.Delete,
                    className: 'btn-info'
                }
            },
            callback: (result: boolean) => {
                if (result) {
                    let index = temp.state.appliedFilters.findIndex(x => x == filterName);
                    if (index != -1) {
                        let appliedFilters: string[] = temp.state.appliedFilters.splice(index, 1);
                        let activeFilter = temp.state.activeFilter == filterName ? '' : temp.state.activeFilter;
                        temp.setState({ activeFilter: activeFilter, appliedFilters: appliedFilters }, () => { temp.props.onFilterDelete(filterName, filterType) });

                        let recentappliedFilter: string[] = this.state.appliedFilters;
                        let recentfilterNames = recentappliedFilter[recentappliedFilter.length - 1];
                        if (filterName === recentfilterNames) {
                            let index = recentappliedFilter.indexOf(filterName);
                            recentappliedFilter.splice(index, 1);
                        }
                    }
                    else {
                        temp.props.onFilterDelete(filterName, filterType);
                    }
                }
            }
        });
    }

    onClearFilter() {
        this.setState({ activeFilter: '' }, () => {
            this.props.onClearFilter(true)
            if (this.props.setClearFilter !== undefined)
            {
                this.props.setClearFilter();
            }
         });
    }
    onSetDefaultFilter(name: string, filterType: ReportFilterType) {
        this.addRecentFilter(name);
        SessionStore.set(this.props.proxyFilter, JSON.stringify(this.getFilter(name)));

        this.props.onSetDefaultFilter(name, filterType);
    }

    onApplyRecentFilter() {
        if (!SessionStore.isExists(this.proxyRecentFilter)
            || this.props.filterList.findIndex(x => x.name == SessionStore.get(this.proxyRecentFilter)) == -1) {
            VenusNotifier.Error(ReportFilterConstants.ValidationMessage.NoRecentFilters, null);
        }
        else {
            let tempRecentFilterName = SessionStore.get(this.proxyRecentFilter);
            SessionStore.set(this.props.proxyFilter, JSON.stringify(this.getFilter(tempRecentFilterName)));

            let appliedFilters: string[] = [tempRecentFilterName];
            if (appliedFilters.length > 0) {
                let filterName = appliedFilters[appliedFilters.length - 1];
                if (filterName != this.state.activeFilter) {
                    this.setState({ activeFilter: filterName }, () => { this.props.onLoadSelectedFilter(this.getFilter(filterName)) });
                }
            }
        }
    }
    onExportToComplete = () => {
        this.setState({ exportingState: false });
    }
    public render() {
        this.proxyRecentFilter = this.props.proxyFilter + "RECENT";
        const btnExportResourceId = this.props.parentResourceIdentifier + CommonResources.ExportToExcel;

        let appliedFilter = this.state.activeFilter !== this.props.proxyFilter && this.state.activeFilter.length > 0 ? <div className="dis-inline applied-filter">
            <span>{ReportFilterConstants.ControlsLabel.AppliedFilter}:</span> <strong> {this.state.activeFilter}</strong>
        </div > : null;
        return <div className="filters">
            <div className="btn-group marR05">
                <Bootstrap.Button
                    variant="default"
                    onClick={this.props.onPageReload}
                    title="Refresh"
                    disabled={this.props.refreshDelay}
                    className="btn-white btn-only marR10"
                    style={{ paddingLeft: '12px' }}
                    data-test-auto="11BC25B0-F325-4170-AFA9-598384364972">
                    <span className={(this.props.refreshDelay ? "fa-spin " : " ") + 'fas fa-redo-alt fa-btn-icon'}
                        style={{ padding: '2px 0', textShadow: '0px 1px 0px rgba(255,255,255,.5)', fontSize: '12px' }}>
                    </span>
                </Bootstrap.Button>
            </div>

          {this.props.showQuickReportOption && this.props.showQuickReportOption==true &&
             <div className={`btn-group marR05 ${this.state.showQuickReport ? "show" : ""}`}>
                <Bootstrap.Button
                    variant="default"
                    type="button"
                    data-toggle="dropdown"
                    title="View Quick Reports"
                    data-test-auto="205D09A5-60E5-4C49-A6A1-9BC0AED44CEE"
                    className=" btn btn-default btn-filter marR10"
                    onClick={() => this.setState({ showQuickReport: !this.state.showQuickReport })}
                >
                    <span className="fas fa-file-alt marR05"
                        style={{ padding: '2px 0', textShadow: '0px 1px 0px rgba(255,255,255,.5)', fontSize: '12px' }}>
                    </span>   
                    <span className="fas fa-angle-down"
                        title="View Quick Reports">
                    </span>
                </Bootstrap.Button>
                <ul className={`dropdown-menu dropdown-menu-right ${this.state.showQuickReport ? "show" : ""}`} style={{ width: '160px' }} role="menu" aria-labelledby="dropdownMenu">
                    <li><Bootstrap.Button variant="link" 
                        onClick={() => { this.props.onSelectQuickReports && this.props.onSelectQuickReports(QuickReportType.LinkNotAccessed) }}
                        data-test-auto="FE6A8717-3FD9-428F-81B3-10FCD4932A34">
                        <span style={{ marginLeft: '2px' }}>{ReportFilterConstants.QuickReports.LinkNotAccessed}</span>
                    </Bootstrap.Button>
                    </li>
                    <li><Bootstrap.Button variant="link" 
                        onClick={() => { this.props.onSelectQuickReports && this.props.onSelectQuickReports(QuickReportType.KBAFailed) }}
                        data-test-auto="46BDEE28-A82A-4392-8B29-B2FE256C98B9">
                        <span style={{ marginLeft: '2px' }}>{ReportFilterConstants.QuickReports.KBAFailed}</span>
                    </Bootstrap.Button>
                    </li>
                </ul>
            </div>
            } 
           

            {
                this.props.OnOpenColumnSettings &&
                <Bootstrap.Button variant="default" className="btn-only marR05" disabled={this.props.disableColumnSettings} type="button" title="Column Options" onClick={this.props.OnOpenColumnSettings}>
                    <span className="fas fa-sliders-h fa-lg"></span>
                </Bootstrap.Button>
            }

            {appliedFilter}
            <div className= {`btn-group marR05 ${this.state.showFilter ? "show" : ""}`}>
                <button className="btn btn-default btn-filter" type="button" title={ReportFilterConstants.ControlsTitle.ApplyMostRecentFilter} onClick={this.onApplyRecentFilter} >
                    <span className="fas fa-filter"></span>
                </button>
                <button className="btn btn-default btn-filter" type="button" data-toggle="dropdown" data-test-auto="E1EC4A09-E72E-4E71-AA68-D9A596B28309"  onClick={()=> this.setState({showFilter: !this.state.showFilter })}>
                    <span className="fas fa-angle-down" title={ReportFilterConstants.ControlsTitle.ToggleDropdownMenu}></span>
                </button>
                <ul className={`dropdown-menu dropdown-menu-right ${this.state.showFilter ? "show" : ""}`} style={{ width: '250px' }} role="menu" aria-labelledby="dropdown">
                    <li><Bootstrap.Button variant="link" onClick={this.onApplyRecentFilter} data-test-auto="86505833-BF5A-494C-A6A0-16784C18D96E">
                        <i className="fas fa-check-circle marR05"></i> <span style={{ marginLeft: '15px' }}>{ReportFilterConstants.ControlsLabel.ApplyRecentFilterBtn}</span>
                    </Bootstrap.Button>
                    </li>
                    <li><Bootstrap.Button variant="link" onClick={() => { this.props.onFilterSaveClick(this.state.activeFilter) }} data-test-auto="18C06943-6C1E-4EBF-9CEB-34840CB572D7">
                        <i className="fas fa-save marR05" ></i> <span style={{ marginLeft: '15px' }}>{ReportFilterConstants.ControlsLabel.SaveCurrentFilterBtn}</span>
                    </Bootstrap.Button>
                    </li>
                    <li><Bootstrap.Button variant="link" onClick={this.onClearFilter} data-test-auto="18C06943-6C1E-4EBF-9CEB-34840CB572D7">
                        <i className="fas fa-times-circle marR05" ></i> <span style={{ marginLeft: '15px' }}>{ReportFilterConstants.ControlsLabel.ClearFilter}</span>
                    </Bootstrap.Button></li>
                    <li className="divider"></li>
                    {this.props.filterList.map((value, index) => {
                        let filterName = value.name.length <= 25 ? value.name : value.name.substring(0, 25) + '...';
                        let activeFilter = value.isDefaultFilter ? <i className="fas fa-check marL05" ></i> : null;
                        let setAsDefaultFilter = value.isDefaultFilter ?
                            <li key={"filter-sub-menu" + index + "_2"}>
                                <Bootstrap.Button variant="link" onClick={() => this.props.onRemoveDefaultFilter(value.name, value.filterType)} data-test-auto="44FCD3DE-6611-4128-BC78-B0EFBB1DEC63">
                                    <span>{ReportFilterConstants.ControlsLabel.RemoveDefault}</span>
                                </Bootstrap.Button>
                            </li> :
                            <li key={"filter-sub-menu" + index + "_2"}>
                                <Bootstrap.Button variant="link" onClick={() => this.onSetDefaultFilter(value.name, value.filterType)} data-test-auto="3b3651e2-4499-4530-b605-a0fa262f093a">
                                    <span>{ReportFilterConstants.ControlsLabel.SetDefault}</span>
                                </Bootstrap.Button>
                            </li>;
                        let deleteFilter =<li key={"filter-sub-menu" + index + "_3"}>
                                <Bootstrap.Button variant="link" disabled={value.isDefaultFilter} onClick={() => this.onDeleteFilter(value.name, value.filterType)} data-test-auto="0ded7caf-ab98-4029-a802-c12f63154f2d">
                                    <span>{ReportFilterConstants.ControlsLabel.Delete}</span>
                                </Bootstrap.Button>
                            </li>;
                        if (value.name !== this.props.proxyFilter)
                            return <li key={"filter-menu" + index} className="dropdown-submenu dropdown-menu-right font14" style={{ paddingLeft: '20px', paddingBottom: '3px' }}>
                                <a title={value.name}>{filterName} {activeFilter}</a>
                                <ul className="dropdown-menu" style={{ left: "-160px" }}>
                                    <li key={"filter-sub-menu" + index + "_1"}><Bootstrap.Button variant="link" onClick={() => this.onApplyFilter(value)} data-test-auto="0c5eeba6-5010-4ac2-b6dd-3a7f5ba210cb">
                                        <span>{ReportFilterConstants.ControlsLabel.Apply}</span>
                                    </Bootstrap.Button>
                                    </li>
                                    {setAsDefaultFilter}
                                    {deleteFilter}
                                </ul>
                            </li>
                    })}
                </ul>

                <SaveFilters
                    showState={this.props.show}
                    onHide={this.props.onSaveFilterHide}
                    data-test-auto="8822BA2D-A81A-4FE9-ABDB-929D5E1F236C"
                    formSubmit={() => { this.props.onFilterSave(this.onApplySavedFilter) }}
                    onFilterNameChange={this.props.onFilterNameChange}>
                </SaveFilters>
            </div>
            {this.props.showExportExcel == false ?
                <Bootstrap.Button
                    data-resource-id={btnExportResourceId}
                    variant="default"
                    className="btn-only marR05"
                    title={ReportFilterConstants.ControlsLabel.ExportToExcel}
                    onClick={() => { this.setState({ exportingState: true }, () => { this.props.onExportToExcel(this.onExportToComplete, btnExportResourceId); }) }}
                    disabled={!this.props.totalRows}
                    data-test-auto="D04B9868-CF8B-4832-B0F3-E36BE8F836F4">
                    <span className={(this.state.exportingState || this.props.loadSpinner ) ? 'fas fa-spinner fa-spin text-info' : 'fas fa-file-export'} ></span>
                </Bootstrap.Button> : true
            }
        </div >
    }
}

export default Filters;
