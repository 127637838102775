import * as Enumerable from 'linq';
import * as React from 'react';
import { Accordion, ButtonGroup, ButtonToolbar, Card } from 'react-bootstrap';
import * as PdfStore from '../../../store/pdf/PdfStore';
import * as TaxDocument from '../../common/TaxReturn';
import { CustomPagination } from './CustomPagination/CustomPagination';
import { groupDictionary, ISelectedPagesDictionary } from './GroupsView';
import { PageThumbnail } from './PageThumbnail/PageThumbnail';
import { CheckBoxComponent } from '../CheckBoxComponent';
import { IBookmarkSection, IPageItem } from '../ProcessReturnModal/ProcessReturnModels';

export interface IGroupPagesProps {
    docId: number;
    group: TaxDocument.IGroup;
    label: string | undefined;
    color: string;
    icon: string;
    groupIcons: groupDictionary;
    selectedPages: ISelectedPagesDictionary;
    pdfDocuments: PdfStore.IPdfDocumentDictionary;
    documentGroup: TaxDocument.DocumentGroups;
    onDeletePage: (page: number) => void;
    onPreviewPage: (group: TaxDocument.DocumentGroups, activePage: number, sortedPageOrder: number[]) => void;
    onSelectPage: (pageNo: number, documentGroup: TaxDocument.DocumentGroups) => void;
    onUnSelectPage: (pageNo: number) => void;
    onDropPage: (item: any) => void;
    efileBookmarks: IBookmarkSection[];
}
interface IGroupPagesState {
    open: boolean;
    currentPage: number;
}

const MAX_ITEMS = 12;
const isEqual = require("react-fast-compare");

export class GroupPages extends React.Component<IGroupPagesProps, IGroupPagesState> {
    private efilePageOrder: any[];
    constructor(props: IGroupPagesProps) {
        super(props);
        this.state = {
            open: false,
            currentPage: 1,
        };
        this.efilePageOrder=[];
    }

    UNSAFE_componentWillReceiveProps(nextProps: IGroupPagesProps) {
        (this.props.documentGroup != nextProps.documentGroup &&
            this.state.currentPage != 1) &&
            this.setState({ currentPage: 1 });
    }

    private selectAllHandler = (event: any) => {
        if (event.target.checked) {
            this.props.group.forms.map((item, index) => {
                this.props.onSelectPage && this.props.onSelectPage(item.pageNo[0], this.props.group.group);
            })
        }
        else {
            this.props.group.forms.map((item, index) => {
                this.props.onUnSelectPage && this.props.onUnSelectPage(item.pageNo[0]);
            })
        }
    }
    private getSortedEfilebookmarks(items: any[]): any[] {
        let tmpBookmarks: IBookmarkSection[] = [];
        let bookmarks: IBookmarkSection[] = this.props.efileBookmarks;

        for (var i = 0; i <= bookmarks.length - 1; i++) {
            tmpBookmarks.push({
                heading: bookmarks[i].heading,
                pages: bookmarks[i].pages.sort((first: IPageItem, second: IPageItem) => (first.pageNo - second.pageNo)).sort((first: IPageItem, second: IPageItem) =>
                    first.pageTitle.localeCompare(second.pageTitle))
                    .reduce((result: any, e: IPageItem) => {
                        e.pageTitle === 'Federal' ? result.unshift(e) : result.push(e);
                        return result;
                    }, [])
            });
        }

        var tmpItems: any[] = [];
        for (var i = 0; i < tmpBookmarks[0].pages.length; i++) {
            let tmpitm = items.filter(x => x.pageNo[0] === tmpBookmarks[0].pages[i].pageNo);
            if (tmpitm && tmpitm.length > 0) {
                tmpItems.push(tmpitm[0]);
            }
        }
        return tmpItems;

        // return tmpBookmarks;
    }

    public render() {
        let items: any[] = [];
        items = this.props.group &&
            Enumerable.from(this.props.group.forms)
                .skip((this.state.currentPage - 1) * MAX_ITEMS).take(MAX_ITEMS)
                .select(function (item: any) {
                    return { "pageNo": item.pageNo, "formName": item.formName }
                }).toArray()


        if (this.props.group && this.props.group.group === TaxDocument.DocumentGroups.EFile && this.props.group.forms) {
            items = this.getSortedEfilebookmarks(items);
            if (this.efilePageOrder.length === 0) {
                this.efilePageOrder = items.map(p => p.pageNo[0]);
            }
        }

        let allSelected: boolean = true;
        {
            if (this.props.group) {
                for (let i = 0; i < this.props.group.forms.length; i++) {

                    if (!this.props.selectedPages[this.props.group.forms[i].pageNo[0]]) {
                        allSelected = false;
                        break;
                    }
                }
            }
        }
        return (
            <Accordion defaultActiveKey="1" onSelect={() => this.setState({ open: !this.state.open })} style={{ borderRadius: '5px', borderLeft: this.props.color + ' 36px solid' }}>
                <Card>
                    <Accordion.Toggle eventKey="1">
                        <Card.Header style={{ display: 'block', paddingBottom: 8 }}>
                            <Card.Title style={{ marginLeft: '-42px' }} as="h3">
                                {
                                    <div className="row form-group-header" style={{ marginLeft: '-5px', marginRight: '0px', display: 'flow-root' }}>
                                        <div style={{ float: 'left' }}>
                                            <i className={this.props.icon} style={{ fontSize: 'medium', width: '16px', height: '16px' }}></i>
                                        </div>
                                        <div className="col-sm-8 col-lg-6" style={{ float: 'left', paddingLeft: '8px' }}>
                                            {this.props.label ? this.props.label : this.props.group.group}
                                        </div>
                                        <div className="col-sm-1 col-lg-1" style={{ float: 'right' }}>
                                            <i className={this.state.open ? "fas fa-plus float-right" : "fas fa-minus float-right"}></i>
                                        </div>
                                    </div>
                                }

                            </Card.Title>
                        </Card.Header>
                    </Accordion.Toggle>

                    <Accordion.Collapse eventKey="1" style={{ marginLeft: '-30px' }}>
                        {this.props.group && this.props.group.forms.length > 0 ?
                            <Card.Body className={"group-panel-body"} >

                                <div className="group-view-hedear background-light-yellow row">
                                    <ButtonToolbar className="group-toolbar">
                                        <ButtonGroup>
                                            {this.props.group &&
                                                <CheckBoxComponent id={"page-selectAll-checkbox-" + this.props.docId + this.props.group.group}
                                                    text={"Select All"}
                                                    checked={allSelected}
                                                    onChange={this.selectAllHandler.bind(this)}
                                                    datatestAuto={"a5d4d52c-d9f5-4602-969f-4ae3090c9082"} />
                                            }
                                        </ButtonGroup>
                                        <ButtonGroup className="float-right">
                                            <CustomPagination pageSize={MAX_ITEMS} activePage={this.state.currentPage}
                                                onPageChange={(page: number) => {
                                                    this.setState({ currentPage: page })
                                                }}
                                                totalPages={this.props.group?.forms ? this.props.group.forms.length : 1} />
                                        </ButtonGroup>
                                    </ButtonToolbar>
                                </div>

                                <br />

                                <div className="row">

                                    {this.props.pdfDocuments[this.props.docId].document && items.map((item, i) => {

                                        return (<PageThumbnail
                                            key={"pagethumbnail" + this.props.docId + item.pageNo[0] + i}
                                            documentId={this.props.docId}
                                            document={this.props.pdfDocuments[this.props.docId].document}
                                            pageNo={item.pageNo[0]}
                                            title={item.formName || ""}
                                            group={this.props.group.group}
                                            onDelete={this.props.onDeletePage}
                                            onOpen={() => { this.props.onPreviewPage(this.props.group.group, item.pageNo,this.efilePageOrder) }}

                                            selectedPages={this.props.selectedPages}
                                            groupIcons={this.props.groupIcons}
                                            onDrop={this.props.onDropPage}
                                            onSelectPage={this.props.onSelectPage}
                                            onUnSelectPage={this.props.onUnSelectPage} />);
                                    })}
                                </div>
                            </Card.Body> :
                            <Card.Body className={"group-panel-body"}>
                                <div style={{ paddingTop: '12px' }}>No Pages.</div>
                            </Card.Body>
                        }
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        );
    }
}
