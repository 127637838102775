import * as React from "react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { GetFormatedDateTime, insertWhiteSpace, capitaliseString } from "../../helper/HelperFunctions";
import { OverlayLoader } from "../../helper/OverlayLoader";
import { ClientTrackingEvents, DocumentEventValue } from "../../helper/Constants";
import { ITaxReturn, MailEvent, DocumentEvent, IDocumentEventData } from "../../common/TaxReturn";

interface IControllerTrackingProps {
    taxReturn: ITaxReturn;
    show: boolean;
}

interface ITrackingEvent {
    eventId: DocumentEvent;
    actedBy: string;
    actedOn: Date;
    eventData: IDocumentEventData;
}

export class ControllerTracking extends React.Component<IControllerTrackingProps, {}> {
    public render() {
        this.state = {
            loading: false
        };

        const columns = [
            {
                header: "Events",
                key: "eventId",
                isKey: true,
                dataFormat: this.eventIdType,
                columnClassName: "overflowTextClientTracking",
                dataSort: true,
                toolTip: true,
                hidden: false,
                width: "200px"
            },
            {
                header: "By",
                key: "actedBy",
                isKey: false,
                dataFormat: this.defaultType,
                columnClassName: "overflowTextClientTracking",
                dataSort: true,
                toolTip: true,
                hidden: false,
                width: "125px"
            },
            {
                header: "Event Date",
                key: "actedOn",
                isKey: false,
                dataFormat: this.actedOnType,
                columnClassName: "overflowTextClientTracking",
                dataSort: true,
                toolTip: true,
                hidden: false,
                width: "75px"
            },
            {
                header: "Event Data",
                key: "eventData",
                isKey: false,
                hidden: true,
                dataFormat: this.defaultType,
                columnClassName: "",
                dataSort: false,
                toolTip: false,
                width: "75px"
            }
        ];

        const options = {
            sizePerPage: 20,
            paginationShowsTotal: this.createTotalPages
        };
        let data: ITrackingEvent[] = [];
        this.props.taxReturn && this.props.taxReturn.controllerTracking
            ? this.props.taxReturn.controllerTracking.map((model) => {
                  data.push({
                      eventId: model.eventId,
                      actedBy: model.actedBy && model.actedBy.firstName + " " + model.actedBy.lastName,
                      actedOn: model.actedOn,
                      eventData: model.eventData
                  });
              })
            : [];

        this.props.taxReturn && this.props.taxReturn.clientTracking
            ? this.props.taxReturn.clientTracking.map((model) => {
                  data.push({
                      eventId: model.eventId,
                      actedBy: model.actedBy && model.actedBy.firstName + " " + model.actedBy.lastName,
                      actedOn: model.actedOn,
                      eventData: model.eventData
                  });
              })
            : [];
        data = data.filter((event) => event.eventId != DocumentEvent.Filed && event.eventId != DocumentEvent.SendForSignature);

        let modalBody = data ? (
            <BootstrapTable data={data} options={options} striped pagination={true}>
                {columns.map((value, index) => {
                    return (
                        <TableHeaderColumn
                            key={index}
                            width={value.width}
                            hidden={value.hidden}
                            isKey={value.isKey}
                            dataField={value.key}
                            dataFormat={value.dataFormat}
                            columnClassName={value.columnClassName}
                            dataSort={true}
                            columnTitle={value.toolTip}
                        >
                            {" "}
                            {value.header}
                        </TableHeaderColumn>
                    );
                })}
            </BootstrapTable>
        ) : (
            <OverlayLoader show={this.props.show} text={"Loading, please wait..."} />
        );

        return <div>{modalBody}</div>;
    }

    defaultType(cell: any, row: any) {
        return cell;
    }

    actedOnType(cell: any, row: any) {
        return GetFormatedDateTime(cell);
    }

    eventResponseType(cell: any, row: any) {
        return MailEvent[cell.mailEventId];
    }

    createTotalPages(start: number, to: number, total: number) {
        return (
            <p>
                Showing {start} to {to} of {total} entries
            </p>
        );
    }

    actedByType(cell: any, row: any) {
        return cell.firstName + " " + cell.lastName;
    }

    eventIdType(cell: any, row: any) {
        let eventName = "";
        let mailEvent = row.eventData.mailEventId;
        switch (cell) {
            case DocumentEvent.DocumentsForwardedToCCRecipient:
            case DocumentEvent.K1DistributedTo:
                eventName = DocumentEventValue[cell] + " " + insertWhiteSpace(row.eventData.recipientInfo.firstName);
                break;
            case DocumentEvent.NewTaxReturnUploaded:
            case DocumentEvent.TaxReturnReprocessed:
                eventName = DocumentEventValue[cell];
                break;
            case DocumentEvent.ReturnIsRecalled:
            case DocumentEvent.FileUploaded:
            case DocumentEvent.AttachmentDownloaded:
                eventName = DocumentEventValue[cell] + " (" + row.eventData.fileName + ")";
                break;
            case DocumentEvent.DocumentMailStatus:
                eventName = ClientTrackingEvents.InitialEmail + capitaliseString(MailEvent[row.eventData.mailEventId]);
                break;
            case DocumentEvent.VoucherRemainderMailStatus:
                eventName = ClientTrackingEvents.VoucherReminderEmail + capitaliseString(MailEvent[row.eventData.mailEventId]);
                break;
            case DocumentEvent.SigningReminderMailStatus:
                eventName = ClientTrackingEvents.SigningReminderEmail + capitaliseString(MailEvent[row.eventData.mailEventId]);
                break;
            case DocumentEvent.AccessCodeEmail:
                eventName = ClientTrackingEvents.AccessCodeEmail + capitaliseString(MailEvent[row.eventData.mailEventId]);
                break;
            case DocumentEvent.AccessLinkEmailed:
                eventName = DocumentEventValue[cell] + capitaliseString(MailEvent[row.eventData.mailEventId]);
                break;
            case DocumentEvent.DocumentKBASuccess:
                if (row.eventData.clientType == 1) {
                    eventName = ClientTrackingEvents.TaxPayerKBASuccess;
                } else {
                    eventName = ClientTrackingEvents.SpouseKBASuccess;
                }
                break;
            case DocumentEvent.DocumentKBAFailed:
                if (row.eventData.clientType == 1) {
                    eventName = ClientTrackingEvents.TaxPayerKBAFailed;
                } else {
                    eventName = ClientTrackingEvents.SpouseKBAFailed;
                }
                break;
            case DocumentEvent.ResendAccessLink:
                eventName = DocumentEventValue[cell] + " (" + row.eventData.resentAccessLinkTo + ")";
                break;
            case DocumentEvent.RestoreReturn:
                eventName = DocumentEventValue[cell];
                break;
            case DocumentEvent.ControllerEsigned:
            case DocumentEvent.ControllerSignedAdditionalDocument:
                eventName = DocumentEventValue[cell]
                    .replace("<Taxpayer>", row.eventData.recipientInfo.firstName)
                    .replace("<TaxpayerEmail>", row.eventData.recipientInfo.emailAddress);
                break;
            default:
                eventName =
                    DocumentEventValue[cell] +
                    (mailEvent == 2 || mailEvent == 3 || mailEvent == 4 ? " - " + MailEvent[row.eventData.mailEventId] : "");
                break;
        }
        return eventName;
    }
}
