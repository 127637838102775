import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import * as Component  from './CCHReturns';
import * as CCHReturnStore from '../../store/reports/CCHReturns/CCHReturnStore';

export default connect(
	(state: ApplicationState) => ({
		returnState:state.cchReturns
	}),
	{
		...CCHReturnStore.actionCreators
	}
)(Component.CCHReturns as any);
