import { addTask } from 'domain-task';
import { Reducer } from 'redux';
import { AppThunkAction } from "../..";
import { actionTypes } from '../../../types/ActionTypes';
import { API_BASE_URL } from '../../../utils/constants';
import { handleResponse } from '../../Library';
import { IEFileSettings, initialEFileSettings } from '../../../Core/ViewModels/Company/Settings/EFileSettingsViewModel';

export interface ReceiveEFileSettings {
    type: actionTypes.RECEIVE_EFILE_SETTINGS;
    efileSettings: IEFileSettings;
}

type DispatchAction = ReceiveEFileSettings 

type KnownAction = DispatchAction

export const actionCreators = {
    requestEFileSettings: (): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
        const fetchTask = fetch(API_BASE_URL + "api/efile/settings", {
            method: "GET",
            credentials: "include"
        })
        .then(handleResponse)
        .then((response) => {
            dispatch({
                type: actionTypes.RECEIVE_EFILE_SETTINGS,
                efileSettings: response
            });
        })
        .catch((error: any) => {
            console.log(error);
        });
        addTask(fetchTask);
    }
}

export const reducer: Reducer<IEFileSettings> = (state = initialEFileSettings, incomingAction) => {
    const action = incomingAction as DispatchAction;
    switch (action.type) {
        case actionTypes.RECEIVE_EFILE_SETTINGS:
            var efileSettings = action.efileSettings;
            return efileSettings;        
    }    
    return state;
}