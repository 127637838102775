import { addTask } from 'domain-task';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../../../';
import { actionTypes } from '../../../../types/ActionTypes';
import { handleResponse } from '../../../Library';
import { ITaxReturn, DocumentStatus } from '../../../../components/common/TaxReturn';
import { StatusType, NotificationAction } from '../../../common/NotificationStore';
import { ReceiveGroupedTaxDocumentAction, MarkAsReadyForDeliveryAction } from '../../../groupedreturns/GroupedTaxDocumentStore';
import { ReceiveSendGroupedReturnsAction, RequestSendGroupedReturnsAction } from '../SendGroupedReturnsStore';
import { IRecycleGroupedDeleteReturnsViewModel, RecycleGroupedReturnsState, RecycleGroupedReturnsTableModel } from './RecycleGroupedReturnsState';
import { ReceiveRecycleGroupedReturnsPagesAction, RequestRecycleGroupedReturnsPagesAction } from './RecycleGroupedReturnsPagesStore';
import { RecyleGroupedReturnsConstants, RestoreReturnConstants } from '../../../../components/helper/Constants';
import { API_BASE_URL } from '../../../../utils/constants';
export interface RequestRecycleGroupedReturnsAction {
	type: actionTypes.REQUEST_RECYCLE_GROUPED_RETURNS;
	query: string;
}

export interface ReceiveRecycleGroupedReturnsAction {
	type: actionTypes.RECEIVE_RECYCLE_GROUPED_RETURNS;
	query: string;
	table: RecycleGroupedReturnsTableModel;
}

const unloadedState: RecycleGroupedReturnsState = {
	recycleGroupedReturnsTableModel: {
		recycleGroupedReturns: [],
		count: 0
	} as RecycleGroupedReturnsTableModel,
	loading: true,
	query: '',
	totalRowCount: 0,
	error: false
} as RecycleGroupedReturnsState;

type KnownAction = RequestRecycleGroupedReturnsAction
	| ReceiveRecycleGroupedReturnsAction
	| RequestRecycleGroupedReturnsPagesAction
	| ReceiveRecycleGroupedReturnsPagesAction
	| NotificationAction;

type DispatchAction = RequestRecycleGroupedReturnsAction
	| ReceiveRecycleGroupedReturnsAction


export const actionCreators = {
	requestRecycleGroupedReturns: (query: string, reload: boolean = false): AppThunkAction<KnownAction> => (dispatch, getState) => {
		// Only load data if it's something we don't already have (and are not already loading)
		let state = getState();
		if (reload || query !== state.recycleGroupedReturns.query) {

			let page = state.recycleGroupedReturnsPages[query];
			if (!reload && page) {
				dispatch({
					type: actionTypes.RECEIVE_RECYCLE_GROUPED_RETURNS,
					query: query,
					table: page.recycleGroupedReturnsTableModel
				});

				return;
			}
			
			const fetchTask = fetch(API_BASE_URL + 'api/RecycleGroupedReturns/GetRecycledGroupedReturnsAsync' + query, {
				method: 'GET',
				credentials: 'include'
			})
				.then(handleResponse)
				.then(response => response as Promise<RecycleGroupedReturnsTableModel>)
				.then(data => {
					dispatch({ type: actionTypes.RECEIVE_RECYCLE_GROUPED_RETURNS, query: query, table: data });
					dispatch({ type: actionTypes.RECEIVE_RECYCLE_GROUPED_RETURNS_PAGES, query: query, table: data, totalRowCount: data.count });
				})
				.catch((error) => {
					const statusMessage: any = error.statusText?.message ?? error.statusText;
                    if (typeof(statusMessage) === "string") {
						dispatch({ type: actionTypes.NOTIFICATION, statusMessage: statusMessage, statusType: StatusType.Error })
					}
				});
			addTask(fetchTask);
			dispatch({ type: actionTypes.REQUEST_RECYCLE_GROUPED_RETURNS, query: query });
			dispatch({ type: actionTypes.REQUEST_RECYCLE_GROUPED_RETURNS_PAGES, query: query });
		}
	},
    deleteGroupedReturns: (returnIds: number[], groupIds: number[], resourceId:string, callback?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
		let postData: IRecycleGroupedDeleteReturnsViewModel = {
			documentsId: returnIds,
			groupId: groupIds
		};
        const fetchTask = fetch(API_BASE_URL + 'api/RecycleGroupedReturns/DeleteReturns', {
            method: 'POST',
            credentials: 'include',
            headers: {
				'Accept': 'application/json',
				'X-Resource-Id': resourceId,
				'Content-Type': 'application/json; charset=utf-8',
				'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
            },
			body: JSON.stringify(postData)
        })
            .then(handleResponse)
            .then(() => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: RecyleGroupedReturnsConstants.SuccessMessages.Delete,
                    statusType: StatusType.Success
                });

                if (callback) {
                    callback();
                }
            })
            .catch((error) => {
				const statusMessage: any = error.statusText?.message ?? error.statusText;
                if (typeof(statusMessage) === "string") {
					dispatch({ type: actionTypes.NOTIFICATION, statusMessage: statusMessage, statusType: StatusType.Error });
				}
            });
        addTask(fetchTask);
    },
	restoreGroupedReturns: (returnIds: number[], resourceId:string, callback?: (deletionPendingDocuments: number[]) => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
		let fetchTask = fetch(API_BASE_URL + 'api/RecycleGroupedReturns/RestoreReturns', {
			method: 'POST',
			credentials: 'include',
			headers: {
				'Accept': 'application/json, text/plain,*/*',
				'X-Resource-Id': resourceId,
				'Content-Type': 'application/json',
				'traditional': 'true',
				'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
			},
			body: JSON.stringify(returnIds)
		}).then(handleResponse)
			.then((deletionPendingDocuments) => {
				if (callback) {
                    callback(deletionPendingDocuments);
                }
                else {
                    dispatch({
						type: actionTypes.NOTIFICATION,
						statusMessage: RestoreReturnConstants.SuccessMessages.GroupedReturnRestore,
						statusType: StatusType.Success
					});
                }
			})
			.catch((error) => {
				const statusMessage: any = error.statusText?.message ?? error.statusText;
                if (typeof(statusMessage) === "string") {
					dispatch({ type: actionTypes.NOTIFICATION, statusMessage: statusMessage, statusType: StatusType.Error });
				}
			});
		addTask(fetchTask);
	}
}

export const reducer: Reducer<RecycleGroupedReturnsState> = (state: RecycleGroupedReturnsState = unloadedState, incomingAction: Action) => {
	const action = incomingAction as DispatchAction;
	let index = -1;
	let returnIndex = -1;
	switch (action.type) {
		case actionTypes.REQUEST_RECYCLE_GROUPED_RETURNS:
			return ({
				...unloadedState,
				query: action.query,
				loading: true
			}) as RecycleGroupedReturnsState;
		case actionTypes.RECEIVE_RECYCLE_GROUPED_RETURNS:
			return {
				query: action.query,
				recycleGroupedReturnsTableModel: action.table,
				totalRowCount: action.table.count,
				loading: false
			} as RecycleGroupedReturnsState;
		default:
			// The following line guarantees that every action in the KnownAction union has been covered by a case above
			const exhaustiveCheck: never = action;
	}
	return state || unloadedState;
}