import * as React from "react";
import { Modal, Button } from "react-bootstrap";
import { DocumentUploadDropzoneComponent } from "../../../../components/common/UploadDocument/DocumentUploadDropzoneComponent";
import { ProgressBar, Col } from "react-bootstrap";
import { ButtonFormatter } from "../../../../components/common/UploadDocument/TaxSoftwareTypeUpload/UploadCommonFormatters";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { VenusNotifier } from "../../../../components/helper/VenusNotifier";
import {
    UploadFunctions,
    getFileExtension,
    removeSpecialCharacters,
    GetFileMagicNumber,
    validateFileSize
} from "@sssuite-js-packages/file-utility";
import { validateSFUploadFileType, validateWordAndPDFFileContent, isWordFile } from "../../../../components/helper/Validations";
import { getFileSize } from "../../../../components/helper/HelperFunctions";
import { AdditionalEsignDocuments, UploadtaxReturnConstants, ValidationContants } from "../../../../components/helper/Constants";
import { DropdownComponent } from "../../../../components/common/controls/DropdownComponent";
import * as bootbox from "bootbox";
import { UploadStatus, SasContainer } from "../../../../Core/ViewModels/Common/UploadDocumentViewModel";
import { IPdfDocumentFacade } from "../../../../Core/Utilities/PdfDocumentFacade";
import * as AdditionalEsignDocumentStore from "../../../../store/ProcessReturn/AdditionalEsignDocuments/AdditionalEsignDocumentStore";
import * as TaxDocument from "../../../common/TaxReturn";
import { DocumentUploadData, IOptions } from "../AdditionalEsignDocuments/AdditionalEsignDocument";
import { IUserProfile } from "../../../navigation/profile/ProfileObjects";
import * as AdditionalDocumentTypeStore from "../../../../store/common/AdditionalDocumentTypeStore";
import { logger } from "../../../../routes/LoggedIn";
import { IBlobFile } from "../../../../store/common";
import UploadDocumentModalWarning from "../../UploadDocument/UploadDocumentModalWarning";
import { validateEncryptedFile } from "src/Core/ViewModels/Common/PdfHelper";

const isEqual = require("react-fast-compare");

const djsConfig: any = {
    uploadMultiple: true,
    addRemoveLinks: true,
    headers: { "Access-Control-Allow-Origin": "*", "x-ms-blob-type": "BlockBlob" },
    previewsContainer: false,
    autoProcessQueue: false,
    autoDiscover: false
};

let tableOptions = {
    noDataText: AdditionalEsignDocuments.emptyFileListMessage
};

export interface UploadDocumentProps {
    taxReturn: TaxDocument.ITaxReturn;
    showUploadModal: boolean;
    onCloseModal: () => void;
    getUploadLink: (url: string, callback?: (data?: IBlobFile) => void) => void;
    documentTypes: IOptions[];
    maxFileLimitUpload: number;
    onSubmit: (newUploadedDocumentData: DocumentUploadData[]) => void;
    deleteUploadedAdditionalDocumentBlobFile: (
        documentGuid: string,
        fileName: string,
        taxYear: number,
        callback?: () => void
    ) => void;
    userProfile: IUserProfile;
    convertDocToPdfArtifactAsync: (url: string, callback?: (data?: IBlobFile) => void) => void;
    additionalDocumentTypeData: AdditionalDocumentTypeStore.IDocumentTypeState;
}
export interface UploadDocumentState {
    documentUploadData: DocumentUploadData[];
    sasContainer: SasContainer[];
    config: {
        dropzoneSelector: string;
        iconFiletypes: [".pdf"];
        showFiletypeIcon: boolean;
        postUrl: string;
    };
    isFileUploadInProgress: boolean;
    isRowDisable: boolean;
}
export class UploadAdditionalEsignDocumentModal extends React.Component<UploadDocumentProps, UploadDocumentState> {
    constructor(props: UploadDocumentProps) {
        super(props);

        this.state = {
            documentUploadData: [],
            sasContainer: [],
            config: {
                dropzoneSelector: "div.filepicker",
                iconFiletypes: [".pdf"],
                showFiletypeIcon: true,
                postUrl: "/api/"
            },
            isFileUploadInProgress: false,
            isRowDisable: false
        };
    }

    eventHandlers = {
        addedfiles: (files: any) => {
            try {
                let _self = this;
                _self.setState({
                    isRowDisable: true
                });
                _self.validateFileCount(files);
                let documentUploadData: DocumentUploadData[] = _self.convertToModel(files);
                documentUploadData = _self.handleDuplicates(documentUploadData);
                if (documentUploadData.length > 0) {
                    _self.validateFileContent(documentUploadData).then((result) => {
                        validateEncryptedFile(documentUploadData).then(() => {
                            _self.setState(
                                {
                                    documentUploadData: _self.state.documentUploadData.concat(result),
                                    isFileUploadInProgress: true
                                },
                                () => {
                                    _self.getUploadLink();
                                }
                            );
                        });
                    });
                }
            } catch (error) {
                VenusNotifier.Warning(error.message, null);
            }
        }
    };

    private convertToModel = (uploadedFiles: any): DocumentUploadData[] => {
        let documentUploadData: DocumentUploadData[] = [];
        for (let i = 0; i < uploadedFiles.length; i++) {
            let uploadedFile = uploadedFiles[i];
            if (validateFileSize(uploadedFile)) {
                let tmpFileName = removeSpecialCharacters(uploadedFiles[i].name);
                let fileExtension = getFileExtension(tmpFileName);

                // Validate file extension
                if (validateSFUploadFileType(fileExtension)) {
                    documentUploadData.push({
                        name: tmpFileName,
                        number: documentUploadData.length + this.state.documentUploadData.length + 1,
                        progressBar: 0,
                        size: getFileSize(uploadedFile?.size),
                        status: UploadStatus.Wait,
                        gridRowDisable: true,
                        rowIndex: documentUploadData.length + this.state.documentUploadData.length + 1,
                        file: uploadedFile,
                        documentType: 0,
                        sasUrl: "",
                        pdf: undefined,
                        isPDFloaded: false,
                        isDeleted: false
                    });
                }
            }
        }
        return documentUploadData;
    };

    private validateFileCount = (uploadedFiles: any) => {
        if (uploadedFiles.length + this.state.documentUploadData.length > this.props.maxFileLimitUpload) {
            throw new Error("You cannot upload more than " + this.props.maxFileLimitUpload + " files.");
        }
    };

    private GetFileName = (fileName: string, fileExtension: string) => {
        return fileExtension.toLowerCase() === "doc" || fileExtension.toLowerCase() === "docx"
            ? fileName.replace(fileExtension, "pdf")
            : fileName;
    };

    private handleDuplicates = (uploadData: DocumentUploadData[]): DocumentUploadData[] => {
        for (let i = 0; i < uploadData.length; i++) {
            let file = uploadData[i];
            let fileName: string = file.name || "";
            let fileExtension = getFileExtension(fileName);
            let filecount = 1;
            let updatedFileName: string = this.GetFileName(fileName, fileExtension);
            while (
                this.isFileExist(
                    updatedFileName,
                    this.state.documentUploadData,
                    this.props.taxReturn.additionalEsignDocuments,
                    uploadData,
                    i
                )
            ) {
                fileName = file.name || "";
                fileName = fileName.replace("." + fileExtension, "");
                fileName = fileName + " (" + filecount + ")." + fileExtension;
                updatedFileName = this.GetFileName(fileName, fileExtension);
                filecount++;
            }
            uploadData[i].name = fileName;
        }
        return uploadData;
    };

    isFileExist(fileName: string, tempGridData: any, documentData: any, uploadingData: any, index: number): boolean {
        var found = false;
        for (var i = 0; i < tempGridData.length; i++) {
            if (tempGridData[i].name == fileName) {
                found = true;
                break;
            }
        }
        if (documentData && documentData.length > 0) {
            for (var i = 0; i < documentData.length; i++) {
                if (documentData[i].fileName == fileName) {
                    found = true;
                    break;
                }
            }
        }
        if (uploadingData && uploadingData.length > 0) {
            for (var i = 0; i < uploadingData.length; i++) {
                if (uploadingData[i].name === fileName && i !== index) {
                    found = true;
                    break;
                }
            }
        }
        return found;
    }

    private validateFileContent = (uploadData: DocumentUploadData[]): Promise<any> => {
        var promise: any = null;
        let _tmpTaxReturnUploadData: DocumentUploadData[] = Object.assign({}, uploadData);
        for (let i = 0; i < uploadData.length; i++) {
            let uploadedFile = uploadData[i].file;
            promise = new Promise((resolve) => {
                GetFileMagicNumber(uploadedFile).then((result) => {
                    // Validate file content
                    if (!validateWordAndPDFFileContent(result)) {
                        let index = uploadData.findIndex((x) => x.name == _tmpTaxReturnUploadData[i].name);
                        uploadData.splice(index, 1);
                        VenusNotifier.Warning(ValidationContants.PdfFileValidation, null);
                    }
                    resolve(result);
                });
            });
        }
        return promise.then(() => {
            return uploadData;
        });
    };

    private getUploadLink = () => {
        let _self = this;
        let uploadHelperFunctions = new UploadFunctions();
        this.state.documentUploadData
            .filter((x) => x.status == UploadStatus.Wait)
            .forEach((file: DocumentUploadData, index: number) => {
                file.status = UploadStatus.Initiating;
                let param =
                    "?documentGuid=" +
                    this.props.taxReturn.documentGuid +
                    "&fileName=" +
                    escape(file.name != undefined ? file.name : "") +
                    "&taxYear=" +
                    this.props.taxReturn.taxYear +
                    "&uploadType=additionalesigndocument";
                this.props.getUploadLink("/api/ProcessReturn/GetUploadSasUrlAsync" + param, (data?: IBlobFile) => {
                    try {
                        if (data) {
                            file.sasUrl = data ? data.sas : "";
                            file.status = UploadStatus.Uploading;
                            _self.setState({ documentUploadData: _self.state.documentUploadData }, () =>
                                uploadHelperFunctions.uploadFile(
                                    file.file,
                                    data,
                                    file.name ? file.name : "",
                                    _self.uploadProgressCallback,
                                    _self.uploadCommittCallBack,
                                    _self.uploadFailureCallback
                                )
                            );
                        } else {
                            logger.trackWarning(
                                `TabAdditionalEsignDocuments --> Upload link not found for documenId: ${this.props.taxReturn.id?.toString()}`,
                                {
                                    DocumentGuid: this.props.taxReturn.documentGuid,
                                    FileName: file.name,
                                    TaxYear: this.props.taxReturn.taxYear?.toString(),
                                    DocumentId: this.props.taxReturn.id?.toString()
                                }
                            );
                            throw new Error("Upload link not found !!");
                        }
                    } catch (error) {
                        _self.state.documentUploadData.splice(index, 1);
                        _self.setState({ documentUploadData: _self.state.documentUploadData });
                        logger.trackWarning(
                            `TabAdditionalEsignDocuments --> getUploadLink failed for documenId: ${this.props.taxReturn.id?.toString()}, error: ${error}`,
                            {
                                DocumentGuid: this.props.taxReturn.documentGuid,
                                FileName: file.name,
                                TaxYear: this.props.taxReturn.taxYear?.toString(),
                                DocumentId: this.props.taxReturn.id?.toString()
                            }
                        );
                    }
                });
            });
    };

    public uploadProgressCallback = (percent: number, fileToUpload: any) => {
        let tempGridData = this.state.documentUploadData;
        tempGridData.map((tempGridDataValue: DocumentUploadData, index: number) => {
            if (tempGridDataValue.name == fileToUpload.fileName) {
                tempGridDataValue.progressBar = percent - 10;
            }
        });
        this.setState({
            documentUploadData: tempGridData
        });
    };

    uploadCommittCallBack = (fileToUpload: any) => {
        if (this.state.documentUploadData.length > 0) {
            let tempGridData: DocumentUploadData[] = this.state.documentUploadData;
            tempGridData.map((tempGridDataValue: DocumentUploadData, index: number) => {
                if (tempGridDataValue.name == fileToUpload.fileName) {
                    logger.trackTrace(
                        `TabAdditionalEsignDocuments --> uploadCommittCallBack for documentId: ${this.props.taxReturn.id?.toString()}`,
                        {
                            DocumentGuid: this.props.taxReturn.documentGuid,
                            FileName: fileToUpload.fileName,
                            TaxYear: this.props.taxReturn.taxYear?.toString(),
                            DocumentId: this.props.taxReturn.id?.toString()
                        }
                    );
                    let magicNumber: string = "";
                    new Promise((resolve) => {
                        GetFileMagicNumber(fileToUpload.file).then((result) => {
                            magicNumber = result;
                            if (isWordFile(result)) {
                                resolve(true);
                            } else {
                                resolve(false);
                            }
                        });
                    }).then((result) => {
                        if (result) {
                            let param =
                                "?documentGuid=" +
                                this.props.taxReturn.documentGuid +
                                "&fileName=" +
                                escape(fileToUpload.fileName != undefined ? fileToUpload.fileName : "") +
                                "&taxYear=" +
                                this.props.taxReturn.taxYear;
                            this.props.convertDocToPdfArtifactAsync(
                                "/api/Upload/ConvertDocToPdfDocumentLinkAsync" + param,
                                (data?: any) => {
                                    if (data.message && (data.message.includes("Document is encrypted") || data.message.includes("The document password is incorrect"))) {
                                        this.deleteDocumentData(tempGridDataValue.name);
                                        VenusNotifier.Error(
                                            tempGridDataValue.name + UploadtaxReturnConstants.PdfDocumentPasswordProtectedError,
                                            ""
                                        );
                                    } else {
                                        tempGridDataValue.sasUrl = data != undefined ? data.sas : "";
                                        tempGridDataValue.progressBar = 100;
                                        tempGridDataValue.gridRowDisable = false;
                                        tempGridDataValue.status = UploadStatus.Uploaded;
                                        tempGridDataValue.name =
                                            fileToUpload.fileName.substr(0, fileToUpload.fileName.lastIndexOf(".")) + ".pdf";

                                        this.updateDocumentData(tempGridDataValue);
                                        logger.trackTrace(
                                            `TabAdditionalEsignDocuments --> convertDocToPdfArtifactAsync for documenId: ${this.props.taxReturn.id?.toString()}`,
                                            {
                                                DocumentGuid: this.props.taxReturn.documentGuid,
                                                FileName: fileToUpload.fileName,
                                                TaxYear: this.props.taxReturn.taxYear?.toString(),
                                                DocumentId: this.props.taxReturn.id?.toString()
                                            }
                                        );
                                    }
                                }
                            );
                        } else {
                            tempGridDataValue.progressBar = 100;
                            tempGridDataValue.gridRowDisable = false;
                            tempGridDataValue.status = UploadStatus.Uploaded;
                            this.updateDocumentData(tempGridDataValue);
                        }
                    });
                }

                logger.trackTrace("Additional E-Sign Document Uploaded", {
                    CustomEventType: "Application Event",
                    DocumentGuid: this.props.taxReturn.documentGuid,
                    ClientId: this.props.taxReturn.clientId,
                    SASURL: tempGridDataValue.sasUrl,
                    DocumentId: this.props.taxReturn.id?.toString()
                });
            });
        }
    };

    //Implementation of this callback will be done later
    uploadFailureCallback = (fileToUpload: any) => {
        console.log("upload failed for file:", fileToUpload.fileName);
    };

    private updateDocumentData = (documentData: DocumentUploadData) => {
        let uploadedDocumentData: DocumentUploadData[] = this.state.documentUploadData;
        uploadedDocumentData.map((item, index) => {
            if (item.name == documentData.name) {
                item = documentData;
            }
        });
        this.setState({
            documentUploadData: uploadedDocumentData,
            isRowDisable: false
        });
    };

    private deleteDocumentData = (fileName: string) => {
        var documentUploadData: DocumentUploadData[] = this.state.documentUploadData;
        var filteredDocumentData = documentUploadData.filter((x) => x.name != fileName);
        this.setState({ documentUploadData: filteredDocumentData });
    };

    private isUploadOnGoing = (documentUploadData: DocumentUploadData[]): boolean => {
        let isuploading: boolean = false;
        documentUploadData.map((value) => {
            if (value.progressBar == undefined ? 0 : value.progressBar < 100) {
                isuploading = true;
                return;
            }
        });
        return isuploading;
    };

    defaultType(cell: any, row: any) {
        return cell;
    }

    progressbar(cell: any, row: any) {
        return <ProgressBar striped variant={cell != undefined && cell != 100 ? "warning" : "success"} now={cell} />;
    }

    selectFormat(cell: any, row: any) {
        return (
            <DropdownComponent
                id="ddlUploadDocumentType"
                options={this.props.documentTypes}
                selectedValue={cell}
                onChange={this.handleDocumentType.bind(this, row)}
                customPlaceHolder="Document Type"
                disabled={row.gridRowDisable}
            />
        );
    }

    buttonFunction(cell: any, row: any) {
        return (
            <ButtonFormatter
                deleteReturn={() => this.deleteDocument(row)}
                disabled={row.progressBar != undefined && row.progressBar != 100 ? true : false}
                data-test-auto="B07622E7-FEB7-4759-BA19-4DF7D79BE7E9"
            />
        );
    }

    handleDocumentType = (row: any, value: any) => {
        var _gridData = [...this.state.documentUploadData];

        _gridData.map((gridData, index) => {
            if (gridData.rowIndex == row.rowIndex) {
                gridData.documentType = value == null || value == undefined ? 0 : value;
            }
        });
        this.setState({ documentUploadData: _gridData });
    };

    deleteDocument = (row: any) => {
        let _self = this;
        bootbox.confirm({
            message: AdditionalEsignDocuments.deleteConfirmMessage,
            buttons: {
                cancel: {
                    label: '<i class="fas fa-times"></i> Cancel',
                    className: "btn-white btn-default"
                },
                confirm: {
                    label: '<i class="fas fa-check"></i> OK',
                    className: "btn-info"
                }
            },
            callback: (result: boolean) => {
                if (result) {
                    _self.props.deleteUploadedAdditionalDocumentBlobFile(
                        _self.props.taxReturn.documentGuid,
                        row.name,
                        _self.props.taxReturn.taxYear,
                        () => {
                            _self.deleteDocumentData(row.name);
                            var _gridData = [..._self.state.documentUploadData];
                            _gridData = _gridData.filter((i) => i.name != row.name);

                            //Update row index
                            for (let i = 0; i < _gridData.length; i++) {
                                _gridData[i].rowIndex = i;
                                _gridData[i].number = i + 1;
                            }

                            _self.setState(
                                {
                                    documentUploadData: _gridData
                                },
                                () => {
                                    _self.forceUpdate();
                                }
                            );
                        }
                    );
                }
            }
        });
    };

    private onCancel = () => {
        if (this.isUploadOnGoing(this.state.documentUploadData)) {
            VenusNotifier.Warning(AdditionalEsignDocuments.uploadInProgress, null);
            return;
        }
        this.setState({ documentUploadData: [] });
        this.props.onCloseModal();
    };

    private onHide = () => {
        this.onCancel();
    };

    private onSubmit = () => {
        if (this.state.documentUploadData.length < 1) {
            VenusNotifier.Warning(AdditionalEsignDocuments.documentRequired, null);
            return;
        }
        if (!this.isDocumentTypeSelected(this.state.documentUploadData)) {
            return false;
        }
        if (this.isUploadOnGoing(this.state.documentUploadData)) {
            VenusNotifier.Warning(AdditionalEsignDocuments.uploadInProgress, null);
            return;
        }
        this.props.onSubmit(this.state.documentUploadData);
        logger.trackTrace(`Add Documents for Esignature for document: ${this.props.taxReturn.documentGuid}`, {
            DocumentId: this.props.taxReturn.id?.toString(),
            DocumentGuid: this.props.taxReturn.documentGuid
        });
    };

    private isDocumentTypeSelected = (uploadedDocumentData: DocumentUploadData[]) => {
        for (let i = 0; i < uploadedDocumentData.length; i++) {
            if (uploadedDocumentData[i].documentType == 0 || uploadedDocumentData[i].documentType == null) {
                VenusNotifier.Warning(AdditionalEsignDocuments.documentTypeRequired, null);
                return false;
            }
        }
        return true;
    };

    public render() {
        const columns = [
            {
                header: "Name",
                key: "name",
                isKey: true,
                dataFormat: this.defaultType,
                width: "auto",
                columnClassName: "word-Visible text-left-align"
            },
            {
                header: "Upload Progress",
                key: "progressBar",
                isKey: false,
                dataFormat: this.progressbar,
                width: "auto",
                columnClassName: ""
            },
            {
                header: "Size",
                key: "size",
                isKey: false,
                dataFormat: this.defaultType,
                width: "auto",
                hidden: false,
                columnClassName: ""
            },
            {
                header: "Document Type",
                key: "documentType",
                isKey: false,
                dataFormat: this.selectFormat.bind(this),
                columnClassName: "overflowVisible text-left-align",
                width: "auto"
            },
            {
                header: "Action",
                key: "button",
                isKey: false,
                dataFormat: this.buttonFunction.bind(this),
                width: "auto",
                columnClassName: ""
            }
        ];
        return (
            <Modal className="upload-doc-modal" show={this.props.showUploadModal} onHide={this.onHide} enforceFocus={false}>
                <Modal.Header closeButton data-test-auto="9DFD99E6-5A5C-4A9A-B40F-975AB2D109B5">
                    <Modal.Title style={{ display: "block" }}>
                        {" "}
                        Add Documents for Esignature
                        <UploadDocumentModalWarning warningMessages={[UploadtaxReturnConstants.PdfDocumentUploadWarning]} />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="prosystem-upload-doc-body">
                    <div className="row">
                        <Col className="col-sm-2 text-center">
                            <DocumentUploadDropzoneComponent
                                componentConfig={this.state.config}
                                djsConfig={djsConfig}
                                eventHandlers={this.eventHandlers}
                                autoTestId={"{187003DD-F31E-4DAC-9970-7295F3A3878A}"}
                                isDisable={this.state.isRowDisable}
                            />
                        </Col>
                        <Col id="uploadDocumentTable" className="col-sm-10 uploaded-documents-table-container overflowVisible">
                            <BootstrapTable
                                data={this.state.documentUploadData}
                                bodyContainerClass={"overflowVisible"}
                                striped
                                bordered
                                options={tableOptions}
                            >
                                {columns.map((value, index) => {
                                    return (
                                        <TableHeaderColumn
                                            key={index}
                                            isKey={value.isKey}
                                            dataField={value.key}
                                            dataFormat={value.dataFormat}
                                            width={value.width}
                                            columnClassName={value.columnClassName}
                                            dataAlign={"center"}
                                        >
                                            {value.header}
                                        </TableHeaderColumn>
                                    );
                                })}
                            </BootstrapTable>
                        </Col>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="default" onClick={this.onCancel} data-test-auto="B1C823D3-8D5E-4B9B-9D59-11F3A0D9BEED">
                        <i className="fas fa-times"></i>Close
                    </Button>
                    <Button variant="info" onClick={this.onSubmit} data-test-auto="5D0B9B03-68DE-47E7-8681-6EDBD54E005B">
                        <i className="fas fa-save"></i>Submit
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}
