import { renderTokenElement } from "src/oidcClient/helpers";
import {
    USER_EXPIRED,
    USER_FOUND,
    SILENT_RENEW_ERROR,
    USER_EXPIRING,
    LOADING_USER,
    USER_SIGNED_OUT,
    LOAD_USER_ERROR,
    USER_PRIVILEGE_CHANGED,
    RESET_USER_PRIVILEGE_CHANGE_STATE,
    RESET_CACHE_API_CALLED,
    LOGGED_OUT
} from "./action-types";
import { AppThunkAction } from "../";

// dispatched when the existing user expired
export function userExpired() {
    return {
        type: USER_EXPIRED
    };
}

// dispatched when a user has been found in storage
export function userFound(user: any) {
    renderTokenElement(user.access_token);

    return {
        type: USER_FOUND,
        payload: user
    };
}

export function userRenewed(user: any) {
    return {
        type: USER_FOUND,
        payload: user
    };
}

// dispatched when silent renew fails
// payload: the error
export function silentRenewError(error) {
    renderTokenElement("");
    return {
        type: SILENT_RENEW_ERROR,
        payload: error
    };
}

// dispatched when the user is expiring (just before a silent renew is triggered)
export function userExpiring() {
    return {
        type: USER_EXPIRING
    };
}

// dispatched when a new user is loading
export function loadingUser() {
    return {
        type: LOADING_USER
    };
}

export function userSignedOut() {
    renderTokenElement("");
    return {
        type: USER_SIGNED_OUT
    };
}

export function loadUserError() {
    renderTokenElement("");
    return {
        type: LOAD_USER_ERROR
    };
}

export function userPrivilegeChanged(payload) {
    return {
        type: USER_PRIVILEGE_CHANGED,
        payload: payload
    };
}

export function resetUserPrivilegeChangeState() {
    return {
        type: RESET_USER_PRIVILEGE_CHANGE_STATE
    };
}

export function setCacheResetApiState() {
    return {
        type: RESET_CACHE_API_CALLED
    };
}

interface loggedOut {
    type: "LOGGED_OUT";
    loggedOut: boolean;
}

type KnownAction = loggedOut;

export const actionCreators = {
    loggedOut: (): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: LOGGED_OUT, loggedOut: true });
    }
};
