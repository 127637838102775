import * as React from 'react';
import { ICompanySettings } from '../../../Core/ViewModels/Company/CompanySettingsViewModel';
import { Link } from 'react-router-dom';
import { CheckBoxComponent } from '../../common/CheckBoxComponent';
import { IHelptextModel } from '../../../Core/ViewModels/Common/IHelptextModel';
import { fetchHelpText } from '../../helper/HelperFunctions';

interface IEROSignature {
    companySettings: ICompanySettings
    handleSignerDeligation(event: any): void;
    handleUseSignatureStamp(event: any): void;
    helptexts?: IHelptextModel[];
}

export class EROSignatures extends React.Component<IEROSignature, {}> {
    public render() {
        return <div id="divEROSignature" className="new-main-content" >
            <h3>Allow ERO Signature Stamps</h3>
            <div className="marL20">
                <div className="marB10" style={{ display:"flex" }}>
                    <CheckBoxComponent
                        id={"UseSignatureStamps"}
                        text={"Use ERO Signature Stamps"}
                        checked={this.props.companySettings.signatureSettingsModel ? this.props.companySettings.signatureSettingsModel.useSignatureStamp : false}
                        onChange={this.props.handleUseSignatureStamp}
                        datatestAuto={"B06AE671-061D-4E1C-905C-DB09E5AAD89C"}
                    />
                    
                    <Link to={'#'}
                        className="help-icon marL05"
                        data-placement="right"
                        data-toggle="tooltip"
                        title={fetchHelpText(this.props.helptexts, "E58474BC-374D-4286-8007-9FD753F66A17")}>
                        <i className="fas fa-question-circle"></i>
                    </Link>
                </div>
                <div className="marL30 marB10" style={{ display: "flex" }}>
                    <CheckBoxComponent
                        id={"EnableSignerDelegation"}
                        text={"Enabled ERO Stamp Delegation"}
                        checked={this.props.companySettings.signatureSettingsModel ?
                            this.props.companySettings.signatureSettingsModel.enableSignerDeligation : false}
                        onChange={this.props.handleSignerDeligation}
                        datatestAuto={"DBE42651-56E3-41C7-A402-381CAE1F2AFA"}
                    />
                    
                    <Link to={'#'}
                        className="help-icon marL05"
                        data-placement="right"
                        data-toggle="tooltip"
                        title={fetchHelpText(this.props.helptexts,"FB235E50-A81D-4E08-ABD0-C513D5053986")}>
                        <i className="fas fa-question-circle"></i>
                    </Link>
                </div>
            </div>
        </div>;
    }
}

export default EROSignatures;