import { addTask } from 'domain-task';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../';
import { actionTypes } from '../../types/ActionTypes';
import { IFileClient } from '../../Core/Services/FileClient';
import { TYPES } from '../../Startup/types';
import { container } from '../../Startup/inversify.config';
import { IWatermarkModel } from '../../components/settings/GeneralSettingsComponents/WatermarkComponents/WatermarkSetting';
import { handleResponse } from '../Library';
import { StatusType, NotificationAction } from '../common/NotificationStore';
import { watermarkConstants } from '../../components/helper/Constants';
import { PDFDocumentProxy } from 'pdfjs-dist';
import { API_BASE_URL } from '../../utils/constants';
import { ICompanySettings } from '../../Core/ViewModels/Company/CompanySettingsViewModel';
import * as CompanyStore from '../company/CompanyStore';
import { TaxReturnSettingsResources } from '../../components/helper/ResourceIdConstants';

export interface IWatermarkSettingsState {
	waterMark: IWatermarkModel[];
	isLoading: boolean;
	document: PDFDocumentProxy;
}

interface ReceiveWatermarkListAction {
	type: actionTypes.RECEIVE_WATERMARK;
	waterMark: IWatermarkModel[];
	isLoading: boolean;
}

interface RequestWatermarkListAction {
	type: actionTypes.REQUEST_WATERMARK;
	isLoading: boolean;
}

interface AddWatermarkAction {
	type: actionTypes.ADD_WATERMARK;
	data: IWatermarkModel;
	isLoading: boolean;
}

interface UpdateWatermarkAction {
	type: actionTypes.UPDATE_WATERMARK;
	data: IWatermarkModel;
	isLoading: boolean;
}

interface DeleteWatermarkAction {
	type: actionTypes.DELETE_WATERMARK;
	id: number;
	isLoading: boolean;
}

interface ReceivePdfAction {
	type: actionTypes.RECEIVE_SAMPLE_PDF_OBJECT;
	document: PDFDocumentProxy;
}


type KnownAction =
	ReceiveWatermarkListAction |
	RequestWatermarkListAction |
	AddWatermarkAction |
	UpdateWatermarkAction |
	DeleteWatermarkAction |
	NotificationAction |
	ReceivePdfAction

const fileClient = container.get<IFileClient>(TYPES.IFileClient);

export const actionCreators = {

	requestWatermark: (reload: boolean = false): AppThunkAction<KnownAction> => (dispatch, getState) => {
		dispatch({ type: actionTypes.REQUEST_WATERMARK, isLoading: true });
		let fetchTask = fetch(API_BASE_URL + 'api/Watermark/GetWatermark',
			{
				method: 'GET',
				credentials: 'include'
			})
			.then(handleResponse)
			.then(response => response as Promise<IWatermarkModel[]>)
            .then(data => {
                if (data) {
                    dispatch({ type: actionTypes.RECEIVE_WATERMARK, waterMark: data, isLoading: false });
                }
			})
			.catch(function (error) {
				const statusMessage: any = error.statusText;
                if (typeof(statusMessage) === "string") {
					dispatch({ type: actionTypes.NOTIFICATION, statusMessage: statusMessage, statusType: StatusType.Error })
				}
			});
		addTask(fetchTask);
	},

	saveWatermark: (iwatermarkModel: IWatermarkModel, companySettings: ICompanySettings, resourceId: string = ""): AppThunkAction<KnownAction> => (dispatch, getState) => {
		dispatch({ type: actionTypes.REQUEST_WATERMARK, isLoading: true });
		let fetchTask = fetch(API_BASE_URL + 'api/Watermark',
			{
				method: 'POST',
				credentials: 'include',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json',
					'X-Resource-Id': resourceId,
					'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
				},
				body: JSON.stringify(iwatermarkModel)
			})
			.then(handleResponse)
			.then(response => {
				if (iwatermarkModel.isDefault) {
                    companySettings.displaySettingsModel.defaultWatermarkId = response;
                    let action: any = CompanyStore.actionCreators.updateCompanySettings(companySettings, undefined,
                        TaxReturnSettingsResources.GeneralPrefix + TaxReturnSettingsResources.HeaderPrefix + TaxReturnSettingsResources.ButtonApply);
					dispatch(action);
				}
				dispatch({ type: actionTypes.ADD_WATERMARK, data: { ...iwatermarkModel, id: response }, isLoading: false })
				dispatch({ type: actionTypes.NOTIFICATION, statusMessage: watermarkConstants.SaveSuccessfully, statusType: StatusType.Success });
			})
			.catch(function (error) {
				const statusMessage: any = error.statusText;
                if (typeof(statusMessage) === "string") {
					dispatch({ type: actionTypes.NOTIFICATION, statusMessage: statusMessage, statusType: StatusType.Error });
				}
			});
		addTask(fetchTask);
	},

	updateWatermark: (iwatermarkModel: IWatermarkModel, resourceId: string = ""): AppThunkAction<KnownAction> => (dispatch, getState) => {
		dispatch({ type: actionTypes.REQUEST_WATERMARK, isLoading: true });
		let fetchTask = fetch(API_BASE_URL + 'api/Watermark',
			{
				method: 'PUT',
				credentials: 'include',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json',
					'X-Resource-Id': resourceId,
					'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
				},
				body: JSON.stringify(iwatermarkModel)
			})
			.then(handleResponse)
			.then(response => {
				dispatch({ type: actionTypes.NOTIFICATION, statusMessage: watermarkConstants.UpdateSuccessfully, statusType: StatusType.Success });
				dispatch({ type: actionTypes.UPDATE_WATERMARK, data: { ...iwatermarkModel }, isLoading: false });
			})
			.catch(function (error) {
				const statusMessage: any = error.statusText;
                if (typeof(statusMessage) === "string") {
					dispatch({ type: actionTypes.NOTIFICATION, statusMessage: statusMessage, statusType: StatusType.Error })
				}
			});
		addTask(fetchTask);
	},

	deleteWatermark: (id: number, callback?: () => void, resourceId: string = ""): AppThunkAction<KnownAction> => (dispatch, getState) => {
		dispatch({ type: actionTypes.REQUEST_WATERMARK, isLoading: true });
		let fetchTask = fetch(API_BASE_URL + 'api/Watermark/?id=' + id,
			{
				method: 'DELETE',
				credentials: 'include',
				headers: {
					'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value,
					'X-Resource-Id': resourceId
				},
			})
			.then(handleResponse)
			.then(response => {
				if (callback)
					callback();
				dispatch({ type: actionTypes.NOTIFICATION, statusMessage: watermarkConstants.DeleteSuccessfully, statusType: StatusType.Success });
				dispatch({ type: actionTypes.REQUEST_WATERMARK, isLoading: false })

			})
			.catch(function (error) {
				const statusMessage: any = error.statusText;
                if (typeof(statusMessage) === "string") {
					dispatch({ type: actionTypes.NOTIFICATION, statusMessage: statusMessage, statusType: StatusType.Error })
				}
			});
		addTask(fetchTask);
	},

	requestSamplePdf: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
		let fetchTask = fetch(API_BASE_URL + 'api/Watermark/GetSampleDocumentAsync',
			{
				method: 'GET',
				credentials: 'include'
			})
			.then(handleResponse)
			.then(response => response as Promise<string>)
			.then(doc => {
				if (doc != undefined) {
					fileClient.download(doc)
						.then((data) => {
							dispatch({ type: actionTypes.RECEIVE_SAMPLE_PDF_OBJECT, document: data });
						})
						.catch((error: any) => {
							console.log(error);
						});
				}
			})
			.catch((error: any) => {
				console.log(error);
			});
		addTask(fetchTask);
	}
};

const unloadedState: IWatermarkSettingsState =
	{
		waterMark: [],
		isLoading: false,
		document: {} as PDFDocumentProxy
	} as IWatermarkSettingsState;

export const reducer: Reducer<IWatermarkSettingsState> = (state = unloadedState, incomingAction) => {
	const action = incomingAction as KnownAction;
	switch (action.type) {
		case actionTypes.REQUEST_WATERMARK:
			return {
				...state,
				waterMark: [...state.waterMark == undefined ? [] : state.waterMark],
				isLoading: action.isLoading
			} as IWatermarkSettingsState;
		case actionTypes.RECEIVE_WATERMARK:
			return {
				...state,
				waterMark: action.waterMark,
				isLoading: false
			} as IWatermarkSettingsState;
		case actionTypes.ADD_WATERMARK:
			let received: IWatermarkSettingsState = { ...state };
			received.waterMark.push(action.data);
			return {
				...received,
				isLoading: action.isLoading
			} as IWatermarkSettingsState;
		case actionTypes.UPDATE_WATERMARK:
			let updated: IWatermarkSettingsState = { ...state };
			let index = updated.waterMark.findIndex(x => x.id === action.data.id);
			updated.waterMark[index] = action.data;
			return {
				...updated,
				isLoading: action.isLoading
			} as IWatermarkSettingsState;
		case actionTypes.DELETE_WATERMARK:
			let receive: IWatermarkSettingsState = { ...state };
			let indexWM = receive.waterMark.findIndex(x => x.id === action.id);
			if (indexWM != -1)
				receive.waterMark.splice(indexWM, 1);
			receive.isLoading = false;
			return receive;
		case actionTypes.RECEIVE_SAMPLE_PDF_OBJECT:
			let sample: IWatermarkSettingsState = { ...state };
			sample.document = action.document;
			return sample;

	}
	return state;
};
