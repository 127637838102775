import * as React from "react";
import { Modal, Button, FormControl } from "react-bootstrap";
import { DocumentUploadDropzoneComponent } from "../../../../components/common/UploadDocument/DocumentUploadDropzoneComponent";
import { ProgressBar, Col } from "react-bootstrap";
import { ButtonFormatter } from "../../../../components/common/UploadDocument/TaxSoftwareTypeUpload/UploadCommonFormatters";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { VenusNotifier } from "../../../../components/helper/VenusNotifier";
import {
    UploadFunctions,
    getFileExtension,
    removeSpecialCharacters,
    GetFileMagicNumber,
    validateFileSize
} from "@sssuite-js-packages/file-utility";
import { validatePaperFileUploadFileType, validateWordAndPDFFileContent } from "../../../../components/helper/Validations";
import { PaperFile, UploadtaxReturnConstants } from "../../../../components/helper/Constants";
import { DropdownComponent } from "../../../../components/common/controls/DropdownComponent";
import * as bootbox from "bootbox";
import { UploadStatus, SasContainer } from "../../../../Core/ViewModels/Common/UploadDocumentViewModel";
import * as TaxDocument from "../../../common/TaxReturn";
import { PaperFileUploadData, IOptions } from "../PaperFile/PaperFile";
import { Guid } from "../../../../Core/Utilities/Guid";
import { logger } from "../../../../routes/LoggedIn";
import { IBlobFile } from "../../../../store/common";
import UploadDocumentModalWarning from "../../UploadDocument/UploadDocumentModalWarning";

const djsConfig: any = {
    uploadMultiple: true,
    addRemoveLinks: true,
    headers: { "Access-Control-Allow-Origin": "*", "x-ms-blob-type": "BlockBlob" },
    previewsContainer: false,
    autoProcessQueue: false,
    autoDiscover: false
};

let tableOptions = {
    noDataText: PaperFile.emptyFileListMessage
};

export interface UploadDocumentProps {
    taxReturn: TaxDocument.ITaxReturn;
    showUploadModal: boolean;
    onCloseModal: () => void;
    getUploadLink: (url: string, callback?: (data?: IBlobFile) => void) => void;
    authority: IOptions[];
    maxFileLimitUpload: number;
    onSubmit: (newUploadedDocumentData: PaperFileUploadData[]) => void;
    deleteUploadedPaperFileFromBlob: (documentGuid: string, fileName: string, taxYear: number, callback?: () => void) => void;
    convertToPdfAsync: (url: string) => void;
}
export interface UploadDocumentState {
    paperFileData: PaperFileUploadData[];
    sasContainer: SasContainer[];
    config: {
        dropzoneSelector: string;
        iconFiletypes: [".pdf"];
        showFiletypeIcon: boolean;
        postUrl: string;
    };
    isFileUploadInProgress: boolean;
    isRowDisable: boolean;
}
export class PaperFileUploadModal extends React.Component<UploadDocumentProps, UploadDocumentState> {
    constructor(props: UploadDocumentProps) {
        super(props);

        this.state = {
            paperFileData: [],
            sasContainer: [],
            config: {
                dropzoneSelector: "div.filepicker",
                iconFiletypes: [".pdf"],
                showFiletypeIcon: true,
                postUrl: "/api/"
            },
            isFileUploadInProgress: false,
            isRowDisable: false
        };
    }

    eventHandlers = {
        addedfiles: (files: any) => {
            try {
                let _self = this;
                _self.setState({
                    isRowDisable: true
                });
                _self.validateFileCount(files);
                let paperFileData: PaperFileUploadData[] = _self.convertToModel(files);
                if (paperFileData.length > 0) {
                    _self.validateFileContent(paperFileData).then((result) => {
                        _self.setState(
                            {
                                paperFileData: _self.state.paperFileData.concat(result),
                                isFileUploadInProgress: true
                            },
                            () => {
                                _self.getUploadLink();
                            }
                        );
                    });
                }
            } catch (error) {
                VenusNotifier.Warning(error.message, null);
            }
        }
    };

    private convertToModel = (uploadedFiles: any): PaperFileUploadData[] => {
        let paperFileData: PaperFileUploadData[] = [];
        for (let i = 0; i < uploadedFiles.length; i++) {
            let uploadedFile = uploadedFiles[i];
            if (validateFileSize(uploadedFile)) {
                let tmpFileName = uploadedFiles[i].name;
                let fileExtension = getFileExtension(tmpFileName);

                // Validate file extension
                if (validatePaperFileUploadFileType(fileExtension)) {
                    paperFileData.push({
                        fileName:
                            fileExtension.toLowerCase() === "pdf"
                                ? Guid.newGuid().toString()
                                : Guid.newGuid().toString() + "." + fileExtension,
                        number: paperFileData.length + this.state.paperFileData.length + 1,
                        progressBar: 0,
                        status: UploadStatus.Wait,
                        gridRowDisable: true,
                        rowIndex: paperFileData.length + this.state.paperFileData.length + 1,
                        file: uploadedFile,
                        sasUrl: "",
                        pdf: undefined,
                        isPDFloaded: false,
                        isDeleted: false,
                        authorityId: 0,
                        mailingAddress: "",
                        formName: tmpFileName.replace("." + fileExtension, ""),
                        authorityName: "",
                        fileExtension: fileExtension
                    });
                }
            }
        }
        return paperFileData;
    };

    private validateFileCount = (uploadedFiles: any) => {
        if (uploadedFiles.length + this.state.paperFileData.length > this.props.maxFileLimitUpload) {
            throw new Error("You cannot upload more than " + this.props.maxFileLimitUpload + " files.");
        }
    };

    private validateFileContent = (uploadData: PaperFileUploadData[]): Promise<any> => {
        var promise: any = null;
        let _tmpTaxReturnUploadData: PaperFileUploadData[] = Object.assign({}, uploadData);
        for (let i = 0; i < uploadData.length; i++) {
            let uploadedFile = uploadData[i].file;
            promise = new Promise((resolve) => {
                GetFileMagicNumber(uploadedFile).then((result) => {
                    // Validate file content
                    if (!validateWordAndPDFFileContent(result)) {
                        let index = uploadData.findIndex((x) => x.fileName == _tmpTaxReturnUploadData[i].fileName);
                        uploadData.splice(index, 1);
                        VenusNotifier.Warning(PaperFile.uploadPaperFileWarning, null);
                    }
                    resolve(result);
                });
            });
        }
        return promise.then(() => {
            return uploadData;
        });
    };

    private getUploadLink = () => {
        let _self = this;
        let uploadHelperFunctions = new UploadFunctions();
        this.state.paperFileData
            .filter((x) => x.status == UploadStatus.Wait)
            .forEach((file: PaperFileUploadData, index: number) => {
                file.status = UploadStatus.Initiating;
                file.fileName = removeSpecialCharacters(file.fileName);
                let param =
                    "?documentGuid=" +
                    this.props.taxReturn.documentGuid +
                    "&fileName=" +
                    escape(file.fileName != undefined ? file.fileName : "") +
                    "&taxYear=" +
                    this.props.taxReturn.taxYear +
                    "&uploadType=paperfile" +
                    "&fileExtension=" +
                    file.fileExtension;
                this.props.getUploadLink("/api/ProcessReturn/GetUploadSasUrlAsync" + param, (data?: IBlobFile) => {
                    try {
                        if (data) {
                            file.sasUrl = data ? data.sas : "";
                            file.status = UploadStatus.Uploading;
                            _self.setState({ paperFileData: _self.state.paperFileData }, () =>
                                uploadHelperFunctions.uploadFile(
                                    file.file,
                                    data,
                                    file.fileName ? file.fileName : "",
                                    _self.uploadProgressCallback,
                                    _self.uploadCommittCallBack,
                                    _self.uploadFailureCallback
                                )
                            );
                        } else {
                            throw new Error("Upload link not found !!");
                        }
                    } catch (error) {
                        _self.state.paperFileData.splice(index, 1);
                        _self.setState({ paperFileData: _self.state.paperFileData });
                    }
                });
            });
    };

    public uploadProgressCallback = (percent: number, fileToUpload: any) => {
        let tempGridData = this.state.paperFileData;
        tempGridData.map((tempGridDataValue: PaperFileUploadData, index: number) => {
            if (tempGridDataValue.fileName == fileToUpload.fileName) {
                tempGridDataValue.progressBar = percent - 10;
            }
        });
        this.setState({
            paperFileData: tempGridData
        });
    };

    uploadCommittCallBack = (fileToUpload: any) => {
        if (this.state.paperFileData.length > 0) {
            let tempGridData: PaperFileUploadData[] = this.state.paperFileData;
            tempGridData.map((tempGridDataValue: PaperFileUploadData, index: number) => {
                if (tempGridDataValue.fileName == fileToUpload.fileName) {
                    if (getFileExtension(fileToUpload.fileName) === "") {
                        tempGridDataValue.progressBar = 100;
                        tempGridDataValue.gridRowDisable = false;
                        tempGridDataValue.status = UploadStatus.Uploaded;
                        this.updateDocumentData(tempGridDataValue);
                    } else {
                        let param =
                            "?documentGuid=" +
                            this.props.taxReturn.documentGuid +
                            "&fileName=" +
                            escape(fileToUpload.fileName != undefined ? fileToUpload.fileName : "") +
                            "&taxYear=" +
                            this.props.taxReturn.taxYear;
                        this.props.convertToPdfAsync("/api/ProcessReturn/ConvertToPdfAsync" + param);
                        tempGridDataValue.progressBar = 100;
                        tempGridDataValue.gridRowDisable = false;
                        tempGridDataValue.status = UploadStatus.Uploaded;
                        tempGridDataValue.fileName = fileToUpload.fileName.substr(0, fileToUpload.fileName.lastIndexOf("."));
                        this.updateDocumentData(tempGridDataValue);
                    }
                }

                logger.trackTrace("PaperFile Uploaded", {
                    CustomEventType: "Application Event",
                    DocumentGuid: this.props.taxReturn.documentGuid,
                    ClientId: this.props.taxReturn.clientId,
                    SASURL: tempGridDataValue.sasUrl,
                    DocumentId: this.props.taxReturn.id?.toString()
                });
            });
        }
    };

    //Implementation of this callback will be done later
    uploadFailureCallback = (fileToUpload: any) => {
        console.log("upload failed for file:", fileToUpload.fileName);
    };

    private updateDocumentData = (documentData: PaperFileUploadData) => {
        let uploadedDocumentData: PaperFileUploadData[] = this.state.paperFileData;
        uploadedDocumentData.map((item, index) => {
            if (item.fileName == documentData.fileName) {
                item = documentData;
            }
        });
        this.setState({
            paperFileData: uploadedDocumentData,
            isRowDisable: false
        });
    };

    private isUploadOnGoing = (paperFileData: PaperFileUploadData[]): boolean => {
        let isuploading: boolean = false;
        paperFileData.map((value) => {
            if (value.progressBar == undefined ? 0 : value.progressBar < 100) {
                isuploading = true;
                return;
            }
        });
        return isuploading;
    };

    defaultType(cell: any, row: any) {
        return cell;
    }

    progressbar(cell: any, row: any) {
        return <ProgressBar striped variant={cell != undefined && cell != 100 ? "warning" : "success"} now={cell} />;
    }

    selectFormat(cell: any, row: any) {
        return (
            <DropdownComponent
                id="ddlAuthority"
                options={this.props.authority}
                selectedValue={cell}
                onChange={this.handleAuthority.bind(this, row)}
                customPlaceHolder="Select Authority"
                disabled={row.gridRowDisable}
            />
        );
    }

    addressTextBox(cell: any, row: any) {
        return (
            <FormControl
                type="text"
                disabled={row.gridRowDisable}
                value={cell}
                onChange={this.onMailingAddressChange.bind(this, row)}
                data-test-auto="128E68DF-18ED-4DC0-9503-9EE7A18E0A63"
                maxLength={500}
            />
        );
    }

    formNameTextBox(cell: any, row: any) {
        return (
            <FormControl
                type="text"
                disabled={row.gridRowDisable}
                value={cell}
                onChange={this.onFormNameChange.bind(this, row)}
                data-test-auto="9CB16761-FC0C-4F29-B8E5-CA24832496CF"
                maxLength={200}
            />
        );
    }

    buttonFunction(cell: any, row: any) {
        return (
            <ButtonFormatter
                deleteReturn={() => this.deleteDocument(row)}
                disabled={row.progressBar != undefined && row.progressBar != 100 ? true : false}
                data-test-auto="634FFE91-17BC-415B-B6C6-18C26261E672"
            />
        );
    }

    handleAuthority = (row: any, value: any) => {
        var _gridData = [...this.state.paperFileData];
        _gridData.map((gridData, index) => {
            if (gridData.rowIndex == row.rowIndex) {
                gridData.authorityId = value == null || value == undefined ? 0 : value;
                gridData.authorityName = this.props.authority.find((x) => x.value == value)?.label;
            }
        });
        this.setState({ paperFileData: _gridData });
    };

    deleteDocument = (row: any) => {
        let _self = this;
        bootbox.confirm({
            message: PaperFile.deleteConfirmMessage,
            buttons: {
                cancel: {
                    label: '<i class="fas fa-times"></i> Cancel',
                    className: "btn-white btn-default"
                },
                confirm: {
                    label: '<i class="fas fa-check"></i> OK',
                    className: "btn-info"
                }
            },
            callback: (result: boolean) => {
                if (result) {
                    _self.props.deleteUploadedPaperFileFromBlob(
                        _self.props.taxReturn.documentGuid,
                        row.fileName,
                        _self.props.taxReturn.taxYear,
                        () => {
                            // _self.deleteDocumentData(row.fileName);
                            var _gridData = [..._self.state.paperFileData];
                            _gridData = _gridData.filter((i) => i.fileName != row.fileName);

                            //Update row index
                            for (let i = 0; i < _gridData.length; i++) {
                                _gridData[i].rowIndex = i;
                                _gridData[i].number = i + 1;
                            }

                            _self.setState(
                                {
                                    paperFileData: _gridData
                                },
                                () => {
                                    _self.forceUpdate();
                                }
                            );
                        }
                    );
                }
            }
        });
    };

    private onCancel = () => {
        if (this.isUploadOnGoing(this.state.paperFileData)) {
            VenusNotifier.Warning(PaperFile.uploadInProgress, null);
            return;
        }
        this.setState({ paperFileData: [] });
        this.props.onCloseModal();
    };

    private onHide = () => {
        this.onCancel();
    };

    private onSubmit = () => {
        if (this.state.paperFileData.length < 1) {
            VenusNotifier.Warning(PaperFile.documentRequired, null);
            return;
        }
        if (!this.validatePaperFile(this.state.paperFileData)) {
            return false;
        }
        if (this.isUploadOnGoing(this.state.paperFileData)) {
            VenusNotifier.Warning(PaperFile.uploadInProgress, null);
            return;
        }
        this.props.onSubmit(this.state.paperFileData);
        logger.trackTrace(`Forms added for Paper Filing for document: ${this.props.taxReturn.documentGuid}`, {
            DocumentId: this.props.taxReturn.id?.toString(),
            DocumentGuid: this.props.taxReturn.documentGuid
        });
    };

    private validatePaperFile(paperFileData: PaperFileUploadData[]) {
        for (let i = 0; i < paperFileData.length; i++) {
            if (paperFileData[i].formName.trim() === "" || paperFileData[i].formName === null) {
                VenusNotifier.Warning(PaperFile.formNameRequired, null);
                return false;
            }
            if (paperFileData[i].authorityId === 0 || paperFileData[i].authorityId === null) {
                VenusNotifier.Warning(PaperFile.authorityRequired, null);
                return false;
            }
            if (paperFileData[i].mailingAddress.trim() === "" || paperFileData[i].mailingAddress === null) {
                let warningMessage = PaperFile.mailingAddressRequired;
                warningMessage = warningMessage
                    .replace("<authorityName>", paperFileData[i].authorityName)
                    .replace("<formName>", paperFileData[i].formName);
                VenusNotifier.Warning(warningMessage, null);
                return false;
            }
        }
        return true;
    }

    private onMailingAddressChange = (row: any, event: any) => {
        var _gridData = [...this.state.paperFileData];
        _gridData.map((value, index) => {
            if (value.rowIndex == row.rowIndex) {
                value.mailingAddress = event.target.value;
            }
        });
        this.setState({ paperFileData: _gridData });
    };

    private onFormNameChange = (row: any, event: any) => {
        var _gridData = [...this.state.paperFileData];
        _gridData.map((value, index) => {
            if (value.rowIndex == row.rowIndex) {
                value.formName = event.target.value;
            }
        });
        this.setState({ paperFileData: _gridData });
    };

    public render() {
        const columns = [
            {
                header: "",
                key: "fileName",
                isKey: true,
                dataFormat: this.defaultType,
                width: "0px",
                columnClassName: ""
            },
            {
                header: "Form Name *",
                key: "formName",
                isKey: false,
                dataFormat: this.formNameTextBox.bind(this),
                width: "auto",
                columnClassName: "word-Visible text-left-align"
            },
            {
                header: "Upload Progress",
                key: "progressBar",
                isKey: false,
                dataFormat: this.progressbar,
                width: "auto",
                columnClassName: ""
            },
            {
                header: "Authority *",
                key: "authorityId",
                isKey: false,
                dataFormat: this.selectFormat.bind(this),
                width: "auto",
                hidden: false,
                columnClassName: "overflowVisible text-left-align"
            },
            {
                header: "Mailing Address *",
                key: "mailingAddress",
                isKey: false,
                dataFormat: this.addressTextBox.bind(this),
                columnClassName: "",
                width: "auto"
            },
            {
                header: "Action",
                key: "button",
                isKey: false,
                dataFormat: this.buttonFunction.bind(this),
                width: "100px",
                columnClassName: ""
            }
        ];
        return (
            <Modal className="upload-doc-modal" show={this.props.showUploadModal} onHide={this.onHide} enforceFocus={false}>
                <Modal.Header closeButton data-test-auto="978326E9-BB87-48AC-8AA8-AF6418C08AB2">
                    <Modal.Title style={{ display: "block" }}>
                        {" "}
                        Add Forms for Paper Filing
                        <UploadDocumentModalWarning warningMessages={[UploadtaxReturnConstants.PdfDocumentUploadWarning]} />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="prosystem-upload-doc-body">
                    <div className="row">
                        <Col className="col-sm-2 text-center">
                            <DocumentUploadDropzoneComponent
                                componentConfig={this.state.config}
                                djsConfig={djsConfig}
                                eventHandlers={this.eventHandlers}
                                autoTestId={"{9C336BC6-A1A1-42EC-A28C-F3B066A3AF8A}"}
                                isDisable={this.state.isRowDisable}
                            />
                        </Col>
                        <Col id="uploadDocumentTable" className="col-sm-10 uploaded-documents-table-container overflowVisible">
                            <BootstrapTable
                                data={this.state.paperFileData}
                                bodyContainerClass={"overflowVisible"}
                                striped
                                bordered
                                options={tableOptions}
                            >
                                {columns.map((value, index) => {
                                    return (
                                        <TableHeaderColumn
                                            key={index}
                                            isKey={value.isKey}
                                            dataField={value.key}
                                            dataFormat={value.dataFormat}
                                            width={value.width}
                                            columnClassName={value.columnClassName}
                                            dataAlign={"center"}
                                        >
                                            {value.header}
                                        </TableHeaderColumn>
                                    );
                                })}
                            </BootstrapTable>
                        </Col>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="default"
                        className="btn-white"
                        onClick={this.onCancel}
                        data-test-auto="8F094E22-71AE-4D21-B448-26BA28E87918"
                    >
                        <i className="fas fa-times"></i>
                        Close
                    </Button>
                    <Button variant="info" onClick={this.onSubmit} data-test-auto="4EFB50C8-0219-4498-B9F3-51095D80261F">
                        <i className="fas fa-save"></i>
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}
