import React, { FC } from "react";

const InfoIcon: FC = () => {
    return (
        <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5 9.375C3.83968 9.375 2.72688 8.91406 1.90641 8.09359C1.08594 7.27312 0.625 6.16032 0.625 5C0.625 3.83968 1.08594 2.72688 1.90641 1.90641C2.72688 1.08594 3.83968 0.625 5 0.625C6.16032 0.625 7.27312 1.08594 8.09359 1.90641C8.91406 2.72688 9.375 3.83968 9.375 5C9.375 6.16032 8.91406 7.27312 8.09359 8.09359C7.27312 8.91406 6.16032 9.375 5 9.375ZM5 10C6.32608 10 7.59785 9.47322 8.53553 8.53553C9.47322 7.59785 10 6.32608 10 5C10 3.67392 9.47322 2.40215 8.53553 1.46447C7.59785 0.526784 6.32608 0 5 0C3.67392 0 2.40215 0.526784 1.46447 1.46447C0.526784 2.40215 0 3.67392 0 5C0 6.32608 0.526784 7.59785 1.46447 8.53553C2.40215 9.47322 3.67392 10 5 10Z" fill="#565A5E" />
            <path d="M5.58125 4.1175L4.15 4.29688L4.09875 4.53438L4.38 4.58625C4.56375 4.63 4.6 4.69625 4.56 4.87937L4.09875 7.04688C3.9775 7.6075 4.16438 7.87125 4.60375 7.87125C4.94438 7.87125 5.34 7.71375 5.51938 7.4975L5.57437 7.2375C5.44937 7.3475 5.26687 7.39125 5.14563 7.39125C4.97375 7.39125 4.91125 7.27063 4.95563 7.05813L5.58125 4.1175ZM5.625 2.8125C5.625 2.97826 5.55915 3.13723 5.44194 3.25444C5.32473 3.37165 5.16576 3.4375 5 3.4375C4.83424 3.4375 4.67527 3.37165 4.55806 3.25444C4.44085 3.13723 4.375 2.97826 4.375 2.8125C4.375 2.64674 4.44085 2.48777 4.55806 2.37056C4.67527 2.25335 4.83424 2.1875 5 2.1875C5.16576 2.1875 5.32473 2.25335 5.44194 2.37056C5.55915 2.48777 5.625 2.64674 5.625 2.8125Z" fill="#565A5E" />
        </svg>
    );
};

export default InfoIcon;