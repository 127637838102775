import {API_BASE_URL } from '../../utils/constants';
import store from '../../store';
import { ITaxReturnExtension, ITaxReturnExtensionResponse } from 'src/components/common/TaxReturn';

export function extensionsBaseAPIUrl() : Promise<string>{
	let url = API_BASE_URL + `api/KeyVault/ExtensionsBaseAPIUrl`;
	const user = store.getState().auth.user;
	console.log(url);
    return fetch(url, {    	
      headers: {
		method: 'GET',
		accept: 'application/json',
				Authorization: `Bearer ${user.access_token}`,
				'Accept': 'application/json',
				'Content-Type': 'application/json; charset=utf-8',
      },
    })
    .then((response) => response.json() as Promise<string>)
	.then(data => {
		return data;
	});
};
    

export function  IsDuplicateExtension(taxIdentifier: string,engagementType : any,taxYear : number): Promise<any> {
	console.log(extensionsBaseAPIUrl());
	
	return new Promise((resolve, reject) => {
	extensionsBaseAPIUrl().then((data) => {
	const user = store.getState().auth.user	
	let jsonData = JSON.stringify({
		taxIdentifier: taxIdentifier,
		taxYear: taxYear,
		engagementType : engagementType			
	});
	console.log(jsonData);
	
	let url = data + '/api/ExtensionsTaxDocument/GetExtensionsTaxDetails';
    try
	{
	fetch(url,
	{  
		method: 'POST',
		credentials: 'include',
		 headers: {
			'accept': 'application/json',
			'Authorization': `Bearer ${user.access_token}`,			
			'Content-Type': 'application/json; charset=utf-8',
			"Access-Control-Allow-Origin": '*'
			},
			body: jsonData})
			.then(response => response?.json() as Promise<ITaxReturnExtensionResponse>)
			.then((data) => {
				console.log(data);
				resolve(data?.extensionId > 0);	
			}, 
			(error)=> {
				console.log("error block");
				reject(error);					
			});		
			}catch (error) {
				reject(error);
			  }
			});
		});	
			}
	

	export function  IsDuplicateGroupExtension(taxReturnExtension: ITaxReturnExtension[], apiUrl: string): Promise<any> {
		
		return new Promise((resolve, reject) => {
		const user = store.getState().auth.user	
		let url = API_BASE_URL + apiUrl; 
		try
		{
		fetch(url,
		{  
			method: 'POST',
			credentials: 'include',
			 headers: {
				accept: 'application/json',
				Authorization: `Bearer ${user.access_token}`,
				'Accept': 'application/json',
				'Content-Type': 'application/json; charset=utf-8',
			},
			body: JSON.stringify(taxReturnExtension)})
			.then(response => response?.json() as Promise<number>)
			.then((data) => {				
			resolve(data > 0);	
		}, 
			(error)=> {           
				reject(error);	
		});			
		}catch (error) {
			console.log(error);
			reject(error);
		  }
		});	
		}
		
export function disableExtensionReminders(taxIdentifier: string,engagementType : any,taxYear : number, ssrid:number): void {
	
	const user = store.getState().auth.user	
		var jsonData = JSON.stringify({
			taxIdentifier: taxIdentifier,
			taxYear: taxYear,
			engagementType : engagementType,
			ssrId: ssrid			
		})	
        console.log(jsonData);
		extensionsBaseAPIUrl().then((data) => {
		let url = data + '/api/ExtensionsTaxDocument/CleanExtensionsVoucher';
		try
		{
		fetch(url,
		{  
			method: 'POST',
			credentials: 'include',
			 headers: {
				accept: 'application/json',
				Authorization: `Bearer ${user.access_token}`,
				'Accept': 'application/json, text/plain, */*',
				'Content-Type': 'application/json; charset=utf-8',
			},
			body:jsonData})
			.then(response => response?.json())
			.then((data) => {
				console.log(data);	
			}, 
			(error)=> {
				console.log(error);			
		});	
		
		}catch (error) {
			console.log(error);			
		  }
		});			
		};
	