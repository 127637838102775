import * as React from "react";
import * as Bootstrap from "react-bootstrap";
import { UploadDocumentModal } from "../../common/UploadDocumentModal";
import { ISSRUserModel, IUserModel } from "../../../Core/ViewModels/User/UserViewModel";
import {
    ICompanySettings,
    ISuiteTaxSoftwareSetting,
    TaxSoftwareType
} from "../../../Core/ViewModels/Company/CompanySettingsViewModel";
import * as CompanyStore from "../../../store/company/CompanyStore";
import * as UploadTaxReturnStore from "../../../store/common/uploadTaxReturn";
import { UploadFabButton } from "../../../components/common/UploadFabButton";
import { IUserDefaultSettingsStates } from "../../navigation/profile/MySettings";
import { IGroupAccess } from "../../GroupedReturns/GroupConfiguration";
import { IGroupInfo } from "../../../store/groupedreturns/GroupedReturnProcessState";
import { IUserProfile } from "../../navigation/profile/ProfileObjects";
import { DeliveredReturnReportResourceId, CommonResources } from "../../helper/ResourceIdConstants";
import { IClientManagementSetting } from "../../../store/company/SuiteClientManagementSettingStore";
import { ReactComponent as LocationUpdateIcon } from "../../../assets/images/LocationUpdate.svg";

interface DeliveredReturnsHeaders {
    isArchived: boolean;
    pageTitle: string;
    onSetAccessOpen: (rowIndex: number) => void;
    onChangeStatusOpen: (rowIndex: number) => void;
    onCustomColumnOpen: (rowIndex: number) => void;
    onSendReminder: () => void;
    onArchiveReturnsOpen: (rowIndex: number) => void;
    onMultipleDownloadEfileForms: () => void;
    selectedDocumentCount: number;
    customColumnTitle: string;
    users: IUserModel[];
    onPageReload: () => void;
    company: CompanyStore.ICompanyData;
    selectedERO: number;
    uploadTaxReturn: UploadTaxReturnStore.IUploadTaxReturnState;
    processTaxReturn: (url: string, callback: () => void) => void;
    getUploadLink: (
        url: string,
        callback?: (data?: UploadTaxReturnStore.IUploadTaxReturnState) => void,
        resourceId?: string
    ) => void;
    submitTaxReturn: (url: string, taxData: string, callback: () => void, resourceId?: string) => void;
    onRestoreReturnOpen: (rowIndex: number, resourceId: string) => void;
    isBulkSelectionEnabled: boolean;
    onDeleteTaxReturnOpen: (rowIndex: number, resourceId: string) => void;
    userDefaultSettings: IUserDefaultSettingsStates;
    companySettings: ICompanySettings;
    defaultGroup: string;
    groupInfo: IGroupInfo[];
    groupAccess: IGroupAccess[];
    updateGroupInfoState: (reload: boolean) => void;
    showQuickReports: boolean;
    loadExcelSpinner: boolean;
    userProfle: IUserProfile;
    parentResourceIdentifier: string;
    headerResourceIdentifier: string;
    ssrUsers: ISSRUserModel[];
    taxSoftwareSetting: ISuiteTaxSoftwareSetting;
    clientManagementSetting: IClientManagementSetting;
    requestClientManagementSetting: () => void;
    onChangeOfficeLocationOpen: (rowIndex: number) => void;
}
interface DeliveredReturnsHeadersState {
    show: boolean;
    selectedTaxSoftware: TaxSoftwareType;
}
const NO_INDEX = -1;
export class DeliveredReturnsHeader extends React.Component<DeliveredReturnsHeaders, DeliveredReturnsHeadersState> {
    constructor(props: DeliveredReturnsHeaders) {
        super(props);
        this.onUploadReturnsOpen = this.onUploadReturnsOpen.bind(this);
        this.HandleClose = this.HandleClose.bind(this);
        this.state = {
            show: false,
            selectedTaxSoftware: TaxSoftwareType.None
        };
    }

    private onUploadReturnsOpen(taxSoftware: TaxSoftwareType) {
        this.setState({ show: true, selectedTaxSoftware: taxSoftware });
    }

    private HandleClose() {
        this.setState({ show: false });
    }
    public render() {
        var btnStyle = {
            paddingLeft: "30px",
            paddingRight: "10px",
            marginRight: "5px"
        };

        const isDisabled = this.props.selectedDocumentCount === 0 || this.props.isBulkSelectionEnabled;
        const isDisabledBulk = this.props.isBulkSelectionEnabled ? false : this.props.selectedDocumentCount === 0;
        return (
            <div>
                <div className="row" style={{ marginBottom: 5 }}>
                    <div className="col-sm-8">
                        <h3>{this.props.pageTitle}</h3>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-10">
                        <Bootstrap.Button
                            data-resource-id={`${this.props.headerResourceIdentifier + CommonResources.SetAccess_Header}`}
                            variant="default"
                            className="btn btn-white"
                            style={btnStyle}
                            data-test-auto="FF737AE4-41B0-4051-AEDC-9845EFD31E06"
                            disabled={isDisabled}
                            onClick={() => this.props.onSetAccessOpen(NO_INDEX)}
                        >
                            <i className="fas fa-key"></i>Set Access
                        </Bootstrap.Button>
                        <Bootstrap.Button
                            data-resource-id={`${this.props.headerResourceIdentifier + CommonResources.ChangeStatus_Header}`}
                            variant="default"
                            className="btn btn-white"
                            style={btnStyle}
                            data-test-auto="32E4B7C3-3C20-4BC0-9DB8-270A6C877DD7"
                            disabled={isDisabled}
                            onClick={() => this.props.onChangeStatusOpen(NO_INDEX)}
                        >
                            <i className="fas fa-random"></i> Change Status
                        </Bootstrap.Button>
                        {this.props.customColumnTitle ? (
                            <Bootstrap.Button
                                data-resource-id={`${this.props.headerResourceIdentifier + CommonResources.CustomColum_Header}`}
                                variant="default"
                                className="btn btn-white"
                                style={btnStyle}
                                data-test-auto="C39CFC1B-36FE-4287-B529-8F88E341495F"
                                disabled={this.props.showQuickReports ? true : isDisabledBulk}
                                onClick={() => this.props.onCustomColumnOpen(NO_INDEX)}
                            >
                                <i className="fas fa-tags"></i>
                                {"Change " + this.props.customColumnTitle}
                            </Bootstrap.Button>
                        ) : (
                            ""
                        )}
                        <Bootstrap.Button
                            data-resource-id={`${this.props.headerResourceIdentifier + CommonResources.Reminder_Header}`}
                            variant="default"
                            className="btn btn-white"
                            style={btnStyle}
                            data-test-auto="04CE0584-4E83-4706-AF86-2D90BC9599AB"
                            disabled={isDisabledBulk}
                            onClick={this.props.onSendReminder}
                        >
                            <i className="fas fa-paper-plane"></i>Send Reminder
                        </Bootstrap.Button>
                        {this.props.isArchived ? (
                            <Bootstrap.Button
                                data-resource-id={`${
                                    this.props.headerResourceIdentifier + DeliveredReturnReportResourceId.RestoreReturn
                                }`}
                                variant="default"
                                className="btn btn-white"
                                style={btnStyle}
                                data-test-auto="60BA7F81-48F2-40A2-B704-1930B193090A"
                                disabled={this.props.selectedDocumentCount === 0}
                                onClick={() =>
                                    this.props.onRestoreReturnOpen(
                                        NO_INDEX,
                                        this.props.parentResourceIdentifier +
                                            DeliveredReturnReportResourceId.HeaderPrefix +
                                            DeliveredReturnReportResourceId.RestoreReturn
                                    )
                                }
                            >
                                <i className="fas fa-sync-alt"></i>Restore
                            </Bootstrap.Button>
                        ) : (
                            <Bootstrap.Button
                                data-resource-id={`${
                                    this.props.headerResourceIdentifier + DeliveredReturnReportResourceId.ArchiveReturn
                                }`}
                                variant="default"
                                className="btn btn-white"
                                style={btnStyle}
                                data-test-auto="60BA7F81-48F2-40A2-B704-1930B193090A"
                                disabled={isDisabledBulk}
                                onClick={() => this.props.onArchiveReturnsOpen(NO_INDEX)}
                            >
                                <i className="fas fa-archive"></i>Archive
                            </Bootstrap.Button>
                        )}
                        <Bootstrap.Button
                            data-resource-id={`${
                                this.props.headerResourceIdentifier + DeliveredReturnReportResourceId.DownloadEfileForm
                            }`}
                            variant="default"
                            className="btn btn-white"
                            style={btnStyle}
                            data-test-auto="03F15B8A-E226-4A43-BC66-6B2FF78F211E"
                            disabled={this.props.showQuickReports || this.props.loadExcelSpinner ? true : isDisabledBulk}
                            onClick={this.props.onMultipleDownloadEfileForms}
                        >
                            <i className="fas fa-file-signature"></i>Download e-file Forms
                        </Bootstrap.Button>
                        <Bootstrap.Button
                            data-resource-id={`${
                                this.props.headerResourceIdentifier + DeliveredReturnReportResourceId.DeleteReturn
                            }`}
                            variant="default"
                            className="btn btn-white"
                            style={btnStyle}
                            data-test-auto="03F15B8A-E226-4A43-BC66-6B2FF78F211E"
                            disabled={isDisabledBulk}
                            onClick={() =>
                                this.props.onDeleteTaxReturnOpen(
                                    NO_INDEX,
                                    this.props.parentResourceIdentifier +
                                        DeliveredReturnReportResourceId.HeaderPrefix +
                                        DeliveredReturnReportResourceId.DeleteReturn
                                )
                            }
                        >
                            <i className="fas fa-trash-alt"></i>Delete
                        </Bootstrap.Button>
                        <Bootstrap.Button
                            data-resource-id={`${
                                this.props.headerResourceIdentifier + DeliveredReturnReportResourceId.ChangeOfficeLocation
                            }`}
                            variant="default"
                            className="btn btn-white"
                            style={btnStyle}
                            data-test-auto="BF6BD04B-8F00-4EF9-898D-8DB6AD3F4EC4"
                            disabled={isDisabledBulk}
                            onClick={() => this.props.onChangeOfficeLocationOpen(NO_INDEX)}
                        >
                            <i className="fas">
                                <LocationUpdateIcon width={12} height={12}></LocationUpdateIcon>
                            </i>
                            Change Office Location
                        </Bootstrap.Button>
                    </div>
                    <div className="col-sm-2 txt-ar">
                        <UploadFabButton
                            parentResourceIdentifier={
                                this.props.parentResourceIdentifier + DeliveredReturnReportResourceId.HeaderPrefix
                            }
                            taxSoftwareSetting={this.props.taxSoftwareSetting}
                            userDefaultSettings={this.props.userDefaultSettings}
                            onUploadReturnsClick={this.onUploadReturnsOpen}
                        />
                    </div>
                </div>
                <UploadDocumentModal
                    parentResourceIdentifier={this.props.parentResourceIdentifier}
                    onShow={this.state.show}
                    onHide={this.HandleClose}
                    users={this.props.users}
                    taxSoftware={this.state.selectedTaxSoftware}
                    selectedERO={this.props.selectedERO}
                    getUploadLink={this.props.getUploadLink}
                    processTaxReturn={this.props.processTaxReturn}
                    submitTaxReturn={this.props.submitTaxReturn}
                    onPageReload={this.props.onPageReload}
                    selectedGroup={this.props.defaultGroup}
                    groupInfo={this.props.groupInfo}
                    groupAccess={this.props.groupAccess}
                    updateGroupInfoState={this.props.updateGroupInfoState}
                    userProfile={this.props.userProfle}
                    returnAccessSettings={this.props.company.companySettings.returnAccessSettings}
                    ssrUsers={this.props.ssrUsers}
                    clientManagementSetting={this.props.clientManagementSetting}
                    requestClientManagementSetting={this.props.requestClientManagementSetting}
                />
            </div>
        );
    }
}
