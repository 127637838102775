import { IDownloadedZipFilesModel } from '../../../components/navigation/profile/MyDownload';
export interface ICountryState {
    id: number;
    name: string;
    image: string;
}

export interface ICompanyInfo {
    companyName: string;
    adminUser: number;
    isSsrLogo: boolean;
    id: number;
    isCompanyNameAsWhiteLogo: boolean;
    isDisabled:boolean;
};



export interface IPrimaryAdmin {
    id: number;
    firstName: string;
    phone: string;
    extension: string;
    emailAddress: string;
    title: string;
    mobileNumber: string;
    countryCode: string;
};

export interface ICompany {
    companyInfo: ICompanyInfo;
    countryStates: ICountryState[];
    companySubscription: ICompanySubscription;
    companyProducts: ICompanyProduct[];
}

export interface ICompanySubscription {
    id: number;
    name: string;
    products: IProductInfo[];

}

export interface ICompanyProduct {
    productId: ProductType
    productStatus: ProductStatus
    isSubscribed: boolean
}


export interface IProductInfo {
    name: string;
    pricingInfos: IPricingInfo[];
    productType: ProductType
}

export interface IPricingInfo {
    id: number;
    maxQuantity: number;
    minQuantity: number;
    price: number;
}

export enum SubscriptionTier {
    None =0,
    SuiteEssentials = 1,
    SuitePreferred = 2
}

export enum ProductStatus {
    None = 0,
    Enabled = 1,
    Disabled = 2
}

export enum ProductType {
    None = 0,
    Returns = 1,
    SignaturesOneOff = 2,
    SignaturesMailMerge = 3,
    Organizers = 4,
    Extensions = 5,
    Exchange = 6,
    SSSuitePortal = 1001
}

export interface IAdmin {
    adminId: number;
    adminUsers: IPrimaryAdmin[];
    currentAdmin: IPrimaryAdmin
}

export interface ICompanyLogoSetting {
    isSsrLogo: boolean;
    logoPath: string;
    updatedLogo: Blob;
    isLogoUpdated: boolean;
    isLogoChangedToMyLogo: boolean;
    isCompanyNameChanged: boolean
}

export interface ICompanyWhiteLogoSetting {
    isCompanyNameAsWhiteLogo: boolean;
    whiteLogoPath: string;
    updatedWhiteLogo: Blob;
    isWhiteLogoUpdated: boolean;
    isLogoChangedToCompanyName: boolean;
    isWhiteLogoDelted: boolean;
}

export interface IState {
    company: ICompany;
    admins: IAdmin;
    companyLogoSetting: ICompanyLogoSetting; 
    showEditCompanyPopUp: boolean;
    showEditPrimaryAdminPopup: boolean;
    showEditCompanyLogoPopup: boolean;
    alert: string;
    message: string;
    showAlert: boolean;
    companyWhiteLogoSetting: ICompanyWhiteLogoSetting;
};


export const initialCompanyModel: ICompany = {
    companyInfo: {
        companyName: "",
        adminUser: 0,
        isSsrLogo: false,
        id: 0,
        isCompanyNameAsWhiteLogo: false,
        isDisabled: false
    },
    companySubscription: {
        id: 0,
        name: "",
        products: []
    },
    companyProducts: [],
    countryStates: [{
        id: 0,
        name: "",
        image: ""
    }]
}

export const initialAdminModel: IAdmin = {
    adminId: 0,
    adminUsers: [{
        id: 0,
        firstName: "",
        phone: "",
        extension: "",
        emailAddress: "",
        title: "",
        mobileNumber: "",
        countryCode: ""
    }],
    currentAdmin: {
        id: 0,
        firstName: "",
        phone: "",
        extension: "",
        emailAddress: "",
        title: "",
        mobileNumber: "",
        countryCode: ""
    }
}

export const initialCompanyLogoSettings: ICompanyLogoSetting = {
    isSsrLogo: false,
    logoPath: "",
    updatedLogo: new Blob([]),
    isLogoUpdated: false,
    isLogoChangedToMyLogo: false,
    isCompanyNameChanged: false
}

export const initialCompanyWhiteLogoSettings: ICompanyWhiteLogoSetting = {
    isCompanyNameAsWhiteLogo: false,
    isWhiteLogoDelted: false,
    isLogoChangedToCompanyName: false,
    isWhiteLogoUpdated: false,
    updatedWhiteLogo: new Blob([]),
    whiteLogoPath:""
}

export const initalMyDownloadsList: IDownloadedZipFilesModel[] = [];
