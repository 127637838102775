import { addTask } from 'domain-task';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../';
import { actionTypes } from '../../types/ActionTypes';
import { handleResponse } from '../Library';
import { StatusType, NotificationAction } from '../common/NotificationStore';
import { API_BASE_URL } from '../../utils/constants';

export interface IUserSignatures {
	[index: number]: {
		signatureUploadPath: string,
		signatureDownloadPath: string,
	}
}

interface ReceiveSignatureDownloadPath {
	type: actionTypes.RECEIVE_USER_DOWNLOAD_SIGNATURE;
	userId: number,
	path: string,
}

type KnownAction =
	DispatchAction |
	NotificationAction;

type DispatchAction = ReceiveSignatureDownloadPath;

export const actionCreators = {
	requestDownloadPath: (userId: number, callback?: (data?: any) => void): AppThunkAction < KnownAction > => (dispatch, getState) => {
		let state = getState();
		if (!state.userSignatures[userId] || (state.userSignatures[userId] && state.userSignatures[userId].signatureDownloadPath == "")) {
			const fetchTask = fetch(API_BASE_URL + 'api/UserSettings/GetUserSignLinkAsync/' + userId, {
				method: 'GET',
				credentials: 'include',
				headers: {
					'Accept': 'application/json, text/plain, */*',
					'Content-Type': 'application/json; charset=utf-8',
					'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
				}
			})
				.then(handleResponse)
				.then((data) => {
					if (callback) {
						callback(data.sas);
					}
					dispatch({ type: actionTypes.RECEIVE_USER_DOWNLOAD_SIGNATURE, path: data.sas, userId: userId });
				}).catch(error => {
					const statusMessage: any = error.statusText;
                    if (typeof(statusMessage) === "string") {
						dispatch({
							type: actionTypes.NOTIFICATION, statusMessage: statusMessage,
							statusType: StatusType.Error
						});
					}
				});
			addTask(fetchTask);
		}
		else {
			if (callback) {
				callback(state.userSignatures[userId].signatureDownloadPath);
			}
        }
	}
}

const unloadedState: IUserSignatures = {
	0: {
		signatureDownloadPath: "",
		signatureUploadPath: ""
	}
};

export const reducer: Reducer<IUserSignatures> = (state = unloadedState, incomingAction) => {
	const action = incomingAction as DispatchAction;
	switch (action.type) {
		case actionTypes.RECEIVE_USER_DOWNLOAD_SIGNATURE:
			let newState: IUserSignatures = { ...state };
			if (newState[action.userId]) {
				newState[action.userId].signatureDownloadPath = action.path;
			}
			else {
				newState[action.userId] = {
					signatureDownloadPath: action.path,
					signatureUploadPath: "",
				};
			}
			return newState;
		default:
			//const exhaustiveCheck: never = action;
	}
	return state;
}