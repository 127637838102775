import * as React from 'react';

interface K1DeliveredStatusHeaderProps {
    pageTitle: string;
}

export default class K1DeliveredStatusHeader extends React.Component<K1DeliveredStatusHeaderProps> {
    constructor(props: K1DeliveredStatusHeaderProps) {
        super(props);
    }

    public render() {

		return <div>
			<div className="row" style={{ marginBottom: 5 }}>
				<div className="col-sm-8">
					<h3>{this.props.pageTitle}</h3>
				</div>

			</div>
		</div>
	}
}