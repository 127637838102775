import React, { FC } from "react";

const BlueInfoIcon: FC = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1967_854)">
                <rect width="16" height="16" fill="white" fillOpacity="0.01" />
                <g clipPath="url(#clip1_1967_854)">
                    <path
                        d="M7.99963 15.9992C10.1214 15.9992 12.1562 15.1563 13.6565 13.656C15.1568 12.1557 15.9996 10.1209 15.9996 7.99918C15.9996 5.87744 15.1568 3.84261 13.6565 2.34232C12.1562 0.842031 10.1214 -0.000823975 7.99963 -0.000823975C5.8779 -0.000823975 3.84307 0.842031 2.34278 2.34232C0.842489 3.84261 -0.000366211 5.87744 -0.000366211 7.99918C-0.000366211 10.1209 0.842489 12.1557 2.34278 13.656C3.84307 15.1563 5.8779 15.9992 7.99963 15.9992ZM8.92963 6.58718L7.92963 11.2922C7.85963 11.6322 7.95863 11.8252 8.23363 11.8252C8.42763 11.8252 8.72063 11.7552 8.91963 11.5792L8.83163 11.9952C8.54463 12.3412 7.91163 12.5932 7.36663 12.5932C6.66363 12.5932 6.36463 12.1712 6.55863 11.2742L7.29663 7.80618C7.36063 7.51318 7.30263 7.40718 7.00963 7.33618L6.55863 7.25518L6.64063 6.87418L8.93063 6.58718H8.92963ZM7.99963 5.49918C7.73442 5.49918 7.48006 5.39382 7.29253 5.20628C7.10499 5.01875 6.99963 4.76439 6.99963 4.49918C6.99963 4.23396 7.10499 3.97961 7.29253 3.79207C7.48006 3.60453 7.73442 3.49918 7.99963 3.49918C8.26485 3.49918 8.5192 3.60453 8.70674 3.79207C8.89428 3.97961 8.99963 4.23396 8.99963 4.49918C8.99963 4.76439 8.89428 5.01875 8.70674 5.20628C8.5192 5.39382 8.26485 5.49918 7.99963 5.49918Z"
                        fill="#0973BA"
                    />
                </g>
            </g>
            <defs>
                <clipPath id="clip0_1967_854">
                    <rect width="16" height="16" fill="white" />
                </clipPath>
                <clipPath id="clip1_1967_854">
                    <rect width="16" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default BlueInfoIcon;
