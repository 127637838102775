import * as React from 'react';
import { Modal, FormGroup, FormLabel, FormControl, Col, Button } from 'react-bootstrap';
import { LoadingOverlay, Loader } from 'react-overlay-loader';
import { CompanySettingsConstants } from '../../helper/Constants';
import { IAccountModel } from '../../../store/PaymentAccount/PaymentAccountStore';
import Moment from 'moment';
import {DisconnectStripe} from '../../helper/Constants';
import * as bootbox from "bootbox";


interface Props {
    showAccountDetailsPopup: boolean;
    onHideAccountDetailsPopup: () => void;
    paymentAccountDetails: IAccountModel;
    requestStripeEditURL(callback?: (data: any) => void): any
    handleDisconnect(callback?:(data:any)=>void):any;
}

export class StripeAccountDetails extends React.Component<Props, {}> {
    stripeLinkCallBack = (value: any) => {
        window.open(value, '_blank');
    }
    handleDisconnectClick=()=>
        {
            bootbox.confirm({
                title: DisconnectStripe.Title,
                message: DisconnectStripe.ConfirmDisconnect,
                buttons: {
                    cancel: {
                        label: '<i class="fas fa-times"></i> Cancel',
                        className: "btn-white btn-default"
                    },
                    confirm: {
                        label: '<i class="fas fa-check"></i> Confirm',
                        className: "btn-info"
                    }
                },
                callback: (result: boolean) => {
                    if (result) {
                        this.props.handleDisconnect();
                    }
                }
            });        }
    handleEditStripeClick = () => {
        this.props.requestStripeEditURL(this.stripeLinkCallBack);
    }

    public render() {
        return (<Modal className='stripe-account-details' show={this.props.showAccountDetailsPopup} onHide={() => this.props.onHideAccountDetailsPopup()}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <span>Stripe Account Details</span>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <LoadingOverlay className="height-100per" text={CompanySettingsConstants.OverlayMessage.ApplicationLoading}>
                    <FormGroup className="row" style={{ marginBottom: '0.75rem' }}>
                        <Col className="text-left padT08 marL20" sm={3} as={FormLabel}>
                            Name
                        </Col>                        
                   
                        <Col className="text-left padT08" sm={8}>
                            <FormControl
                                type="text"
                                value={this.props.paymentAccountDetails.accountDetails.name}
                                data-test-auto="AFFDB348-ED80-4DDD-83F5-CCF786DF6168"
                                disabled={true}
                            />
                        </Col>
                    </FormGroup>

                    <FormGroup className="row" style={{ marginBottom: '0.75rem' }}>
                        <Col className="text-left padT08 marL20" sm={3} as={FormLabel}>
                            Email Id
                        </Col>
                    
                        <Col className="text-left padT08" sm={8}>
                            <FormControl
                                type="text"
                                value={this.props.paymentAccountDetails.accountDetails.email}
                                data-test-auto="4816EA06-AC40-49B3-8513-111769264361"
                                disabled={true}
                            />
                        </Col>
                    </FormGroup>
                   

                    <FormGroup className="row" style={{ marginBottom: '0.75rem' }}>
                        <Col className="text-left padT08 marL20" sm={3} as={FormLabel}>
                            Phone
                        </Col>
                   
                        <Col className="text-left padT08" sm={8}>
                            <FormControl
                                type="text"
                                value={this.props.paymentAccountDetails.accountDetails.phone}
                                data-test-auto="DDAB2092-3B31-4CF4-B5AE-7BA292476B96"
                                disabled={true}
                            />
                        </Col>
                    </FormGroup>

                    <FormGroup className="row" style={{ marginBottom: '0.75rem' }}>
                        <Col className="text-left padT08 marL20" sm={3} as={FormLabel}>
                            Default Currency
                        </Col>
                   
                        <Col className="text-left padT08" sm={8}>
                            <FormControl
                                type="text"
                                value={this.props.paymentAccountDetails.accountDetails.defaultCurrency}
                                data-test-auto="1E7CF4B3-80C4-4057-8003-9B2BBC9D7C70"
                                disabled={true}
                            />
                        </Col>
                    </FormGroup>
                   
                    <FormGroup className="row" style={{ marginBottom: '0.75rem' }}>
                        <Col className="text-left padT08 marL20" sm={3} as={FormLabel}>
                            Created on
                        </Col>
                    
                        <Col className="text-left padT08" sm={8}>
                            <FormControl
                                type="text"
                                value={this.props.paymentAccountDetails.accountDetails.created ?
                                    Moment.utc(this.props.paymentAccountDetails.accountDetails.created).local().format('MM/DD/YYYY hh:mm:ss A') : ""}
                                data-test-auto="3FED7818-4A93-4BE3-9228-7C2F5E639874"
                                disabled={true}
                            />
                        </Col>
                    </FormGroup>                   
                   
                </LoadingOverlay>
            </Modal.Body>
            <Modal.Footer className="clearfix">
                <Button
                    variant='secondary'
                    onClick={this.handleDisconnectClick}
                    data-test-auto="B2C60B60-C306-4A4D-B914-AC034DCB71E6">
                    Disconnect
                </Button>
                <Button
                    variant='primary'
                    onClick={this.handleEditStripeClick}
                    data-test-auto="8316868B-E98D-4CBD-B79C-65146D9D8B2C">
                    Edit
                </Button>
            </Modal.Footer>
        </Modal>);
    }
}
export default StripeAccountDetails;