import * as React from 'react';
import { ICompanySettings, IRetentionSettings } from '../../../Core/ViewModels/Company/CompanySettingsViewModel';
import { Link } from 'react-router-dom';
import { YearAndMonth } from '../../common/YearAndMonthComponent'
import { CheckBoxComponent } from '../../common/CheckBoxComponent';
import { IHelptextModel } from '../../../Core/ViewModels/Common/IHelptextModel';
import { fetchHelpText } from '../../helper/HelperFunctions';

interface IRetentionSettingsProps {
    companySettings: ICompanySettings;
    handleDefaultRetentionDays(val: number): void;
    handleAttestRetentionDays(val: number): void;    
    helptexts?: IHelptextModel[];
    enableCustomRouterPrompt?(): void;
}

interface IRetentionSettingsState {
    allowOverride: boolean;
    retentionPeriod: number;
    attestRetentionPeriod: number;
    helptextRetention: string;
}

export class RetentionSettings extends React.Component<IRetentionSettingsProps, IRetentionSettingsState>{
    state = {
        allowOverride: false,
        retentionPeriod: 0,
        attestRetentionPeriod: 0,
        helptextRetention:""
    }

    UNSAFE_componentWillMount() {
        const retentionSettingsModel = this.props.companySettings.retentionSettingsModel;
        this.setState({
            allowOverride: retentionSettingsModel.allowOverride,
            retentionPeriod: retentionSettingsModel.retentionPeriod,
            attestRetentionPeriod: retentionSettingsModel.attestRetentionPeriod,
            helptextRetention: fetchHelpText(this.props.helptexts, "0DE737C8-3378-42F3-A2E6-D57287DBF053")
        });
    }

    UNSAFE_componentWillReceiveProps(nextProps: { companySettings: { retentionSettingsModel: IRetentionSettings; }; }) {
        const retentionSettingsModel = nextProps.companySettings.retentionSettingsModel;
        this.setState({
            allowOverride: retentionSettingsModel.allowOverride,
            retentionPeriod: retentionSettingsModel.retentionPeriod,
            attestRetentionPeriod: retentionSettingsModel.attestRetentionPeriod            
        });
    }

    handleDefaultRetentionPeriodChange = (value: number) => {
        let temp = this.props.companySettings as ICompanySettings;

        this.setState({ retentionPeriod: value }, () => {
            if (temp.retentionSettingsModel.retentionPeriod !== value) {
                temp.retentionSettingsModel.retentionPeriod = value;
                this.props.handleDefaultRetentionDays(value);
                this.props.enableCustomRouterPrompt && this.props.enableCustomRouterPrompt();
            }
        });
    }

    handleAttestRetentionPeriodChange = (value: number) => {
        let temp = this.props.companySettings as ICompanySettings;

        this.setState({ attestRetentionPeriod: value }, () => {
            if (temp.retentionSettingsModel.attestRetentionPeriod !== value) {
                temp.retentionSettingsModel.attestRetentionPeriod = value;
                this.props.handleAttestRetentionDays(value);
                this.props.enableCustomRouterPrompt && this.props.enableCustomRouterPrompt();
            }
        });
    }

    handleRetentionOverrideChange = (event: any) => {
        let temp = this.props.companySettings as ICompanySettings;
        temp.retentionSettingsModel.allowOverride = event.target.checked;

        this.setState({ allowOverride: event.target.checked });
        this.props.enableCustomRouterPrompt && this.props.enableCustomRouterPrompt();
    }

    public render() {
        return <div className="company-settings">
            <div id="divRetentionSettings" className="new-main-content">
                <h3 className="dis-inline">Retention Policy</h3>
                <Link to={'#'}
                    className="help-icon marL05"
                    data-placement="right"
                    data-toggle="tooltip"
                    title={this.state.helptextRetention}>
                    <i className="fas fa-question-circle"></i>
                </Link>
                <div className="row settings-subheading">
                    Default
                </div>
                <div className="row">
                    <div className="col-sm-2 col-md-2 col-lg-2 marL20">Automatically delete documents</div>
                    <div className=""><YearAndMonth id="DefaultRetention" controlValue={this.state.retentionPeriod} onControlValueChange={this.handleDefaultRetentionPeriodChange} componentEnabled={true} years={7} /></div>
                    <div className="col-sm-2 col-md-2 col-lg-2">after the delivery date</div>
                </div>
                <div className="row settings-subheading">
                    Attest Clients
                </div>
                <div className="row marB15">
                    <div className="col-sm-2 col-md-2 col-lg-2 marL20">Automatically delete documents</div>
                    <div className=""><YearAndMonth id="AttestRetention" controlValue={this.state.attestRetentionPeriod} onControlValueChange={this.handleAttestRetentionPeriodChange} componentEnabled={true} years={7} /></div>
                    <div className="col-sm-2 col-md-2 col-lg-2">after the delivery date</div>
                </div>
                <div className="row">
                    <div className="settings-checkbox marL20 col-sm-4 col-md-4 col-lg-4" style={{ display: "flex" }}>
                        <CheckBoxComponent
                            id={"cbOverrideRetention"}
                            text={"Allow any user to override before delivery"}
                            checked={this.state.allowOverride}
                            onChange={this.handleRetentionOverrideChange}
                            datatestAuto={"754F3E87-4F74-47C9-B5AD-DE48519E1A43"}
                        />
                    </div>
                </div>
            </div>
        </div>
    }
}

export default RetentionSettings;