import * as ApplicationEnums from '../Settings/enums';

export interface ISSRSettings {
    isDefault: boolean;
    defaultMessageSettings?: IDefaultMessageSettings;
    defaultGroupMessageSettings?: IDefaultGroupMessageSettings;
    extensionSettings?: IExtensionSettings;
    k1Settings?: IK1Settings;
    reportingChannelSettings?: IReportingChannelSettings[];
    retentionSettingsModel?: IRetentionSettings;
    returnAccessSettings?: IReturnAccessSettingsModel;
    taxpayerPaymentSettings?: ITaxpayerPaymentSettings;
}

export class SSRSettings implements ISSRSettings {
    isDefault: boolean;
    defaultMessageSettings?: IDefaultMessageSettings;
    defaultGroupMessageSettings?: IDefaultGroupMessageSettings;
    extensionSettings?: IExtensionSettings;
    k1Settings?: IK1Settings;
    reportingChannelSettings?: IReportingChannelSettings[];
    retentionSettingsModel?: IRetentionSettings;
    returnAccessSettings?: IReturnAccessSettingsModel;
    taxpayerPaymentSettings?: ITaxpayerPaymentSettings;

    constructor(
        defaultMessageSettings?: IDefaultMessageSettings,
        defaultGroupMessageSettings?: IDefaultGroupMessageSettings,
        extensionSettings?: IExtensionSettings,
        k1Settings?: IK1Settings,
        reportingChannelSettings?: IReportingChannelSettings[],
        retentionSettingsModel?: IRetentionSettings,
        returnAccessSettings?: IReturnAccessSettingsModel,
        taxpayerPaymentSettings?: ITaxpayerPaymentSettings) {
        this.defaultMessageSettings = defaultMessageSettings;
        this.defaultGroupMessageSettings = defaultGroupMessageSettings;
        this.extensionSettings = extensionSettings;
        this.k1Settings = k1Settings;
        this.reportingChannelSettings = reportingChannelSettings;
        this.retentionSettingsModel = retentionSettingsModel;
        this.returnAccessSettings = returnAccessSettings;
        this.taxpayerPaymentSettings = taxpayerPaymentSettings;
    }
}

export interface IDefaultMessageSettings {
    initialEmail: number;
    voucherReminders: number;
    downloadTaxDocuments: number;
    downloadSignedForms: number;
    k1Distribution: number;
    welcomeMessage: number;
    k1WelcomeMessage: number;
    downloadInstruction: number;
    paymentInstruction: number;
    savedMessage: number;
    signingReminder: number;
    k1PartnerInfoUpdate: number;
    k1ClientInfoUpdate: number;
    k1PartnerWelcomeMessage: number;
    k1ClientWelcomeMessage: number;
    k1PartnerUpdatePageMessage: number;
    attachmentInstruction: number;
    invoicePaymentSuccessMail: number;
    resendSignedReturn: number;
    voucherRevision: number;
    paperFiledMessage: number;
    initialMailForManualSign: number;
    recallReturnMail: number;
}

export interface IDefaultGroupMessageSettings {
    groupInitialEmail: number;
    groupSigningReminder: number;
    groupVoucherReminders: number;
    groupDownloadTaxDocuments: number;
    groupDownloadSignedForms: number;
    groupDownloadInstruction: number;
    groupPaymentInstruction: number;
    groupResendSignedReturn: number;
    groupVoucherRevision: number;
}

export interface IExtensionSettings {
    enabled: boolean;
}

export interface IK1Settings {
    k1ClientInfoSettings: IK1InfoSettings;
    k1PartnerInfoSettings: IK1InfoSettings;
}

export interface IK1InfoSettings {
    noOfDays: number,
    k1MailCycle: number
}

export enum K1MailCycles {
    None = 0,
    Before = 1,
    After = 2
}

export interface IReportingChannelSettings {
    reportingEngine: string;
    enabled: boolean;
    settings: IReportingEngineSettings;
}

export interface IReportingEngineSettings {

}

export interface IRetentionSettings {
    retentionPeriod: number;
    attestRetentionPeriod: number;
    allowOverride: boolean
}

export interface IPaymentSettingsProxy {
    paymentFlowType: ApplicationEnums.PaymentFlowType,
    productType: ApplicationEnums.ProductType,
    hasCompanyCustomer: boolean,
    hasCompanyPayments: boolean,
    receiptEmail: string
}

export interface ITaxCodeData {
    taxCode: string,
    taxCodeType: ApplicationEnums.TaxCodeType,
    itemCode: string
}

export interface ISaleTaxSetings {
    companyCode: string,
    taxCodes: ITaxCodeData[]
}

export interface IReturnAccessSettingsModel {
    isSetAccessToEveryone: boolean;
    isSetAccessToIndividual: boolean;
    isSetAccessToOtherUsers: boolean;
}

export interface ITaxpayerPaymentSettings {
    companyConnectedAccountId: string;
    applicationFeesAmount: number;
    priortoReview: boolean;
    allowUserToSendReturnWithoutPayment: boolean;
    payOption: ApplicationEnums.PaymentOption;
}


export const initialSSRSettings: ISSRSettings = {
    isDefault: true,
    defaultMessageSettings: {
        initialEmail: 0,
        voucherReminders: 0,
        downloadTaxDocuments: 0,
        downloadSignedForms: 0,
        k1Distribution: 0,
        welcomeMessage: 0,
        k1WelcomeMessage: 0,
        downloadInstruction: 0,
        paymentInstruction: 0,
        savedMessage: 0,
        signingReminder: 0,
        k1PartnerInfoUpdate: 0,
        k1ClientInfoUpdate: 0,
        k1PartnerWelcomeMessage: 0,
        k1ClientWelcomeMessage: 0,
        k1PartnerUpdatePageMessage: 0,
        attachmentInstruction: 0,
        invoicePaymentSuccessMail: 0,
        resendSignedReturn: 0,
        voucherRevision: 0,
        paperFiledMessage: 0,
        initialMailForManualSign: 0,
        recallReturnMail: 0
    },
    defaultGroupMessageSettings: {
        groupInitialEmail: 0,
        groupSigningReminder: 0,
        groupVoucherReminders: 0,
        groupDownloadTaxDocuments: 0,
        groupDownloadSignedForms: 0,
        groupDownloadInstruction: 0,
        groupPaymentInstruction: 0,
        groupResendSignedReturn: 0,
        groupVoucherRevision: 0
    },
    extensionSettings: {
        enabled : false
    },
    reportingChannelSettings: [{
        reportingEngine: ApplicationEnums.ReportingEngineType.TaxCaddy,
        enabled: false,
        settings: {
            isSkipManualVerification: false,
            firmIds: []
        }
    }],

    retentionSettingsModel: {
        retentionPeriod: 2520,
        attestRetentionPeriod: 2520,
        allowOverride: false
    },
    k1Settings: {
        k1PartnerInfoSettings:
        {
            noOfDays: 15,
            k1MailCycle: 1
        },
        k1ClientInfoSettings:
        {
            noOfDays: 15,
            k1MailCycle: 1
        }
    },
    taxpayerPaymentSettings: {
        companyConnectedAccountId: "",
        allowUserToSendReturnWithoutPayment: false,
        applicationFeesAmount: 0,
        payOption: ApplicationEnums.PaymentOption.None,
        priortoReview: false
    },
    returnAccessSettings: {
        isSetAccessToEveryone: true,
        isSetAccessToIndividual: false,
        isSetAccessToOtherUsers: false
    }
}
