import React, { useState, useEffect } from "react";
import InfoIcon from "src/assets/images/InfoIcon";
import DraggableList from "../DraggableList";
import { IDragAndDropData } from "../DragAndDrop.model";

interface DestinationProps {
    destinationData: IDragAndDropData[];
    sourceData: IDragAndDropData[];
    handleDropChange: (destinationData: IDragAndDropData[], sourceData: IDragAndDropData[]) => void;
    destinationHeading?: string;
    destinationHeadingInfoText?: string;
    destinationWidth?: string;
    destinationHeight?: string;
    dndUniqueID?: string;
    selectedList: any;
    setSelectedList: (selectedList: any) => void;
    disableDragAndDrop?: boolean;
    disableDestinationItems?: number[];
}

function Destination(props: DestinationProps) {
    const {
        destinationData,
        sourceData,
        handleDropChange,
        destinationHeading,
        destinationHeadingInfoText,
        destinationWidth,
        destinationHeight,
        disableDragAndDrop,
        disableDestinationItems
    } = props;

    const [destination, setDestination] = useState<any[]>([]);

    useEffect(() => {
        setDestination(destinationData);
    }, [destinationData]);

    const dragOverHandler = (event: any) => {
        event.preventDefault();
    };

    const dropHandler = (event: any) => {
        const data = JSON.parse(event.dataTransfer.getData("text"));
        const dndUniqueID = event.dataTransfer.getData("dndUniqueID");
        if (dndUniqueID === props.dndUniqueID)
            if (
                destinationData.some((ele: any) => data.findIndex((datum: any) => datum.value === ele.value) === -1) ||
                !destinationData.length
            ) {
                const newDestinationList = [...data, ...destinationData];
                const newSourceList = sourceData.filter(
                    (ele: any) => !data.some((eachData: any) => eachData.value === ele.value)
                );
                handleDropChange(newDestinationList, newSourceList);
            }
    };

    return (
        <article className="destinationDataContainer" style={destinationWidth ? { width: destinationWidth } : null}>
            {destinationHeading && (
                <div className="destinationDataHeaderDiv">
                    <span className="destinationDataHeader">{destinationHeading}</span>
                    {destinationHeadingInfoText && (
                        <span className="destinationDataInfo" title={destinationHeadingInfoText}>
                            <InfoIcon />
                        </span>
                    )}
                </div>
            )}
            {disableDragAndDrop ? (
                <ul
                    className="list_container"
                    style={destinationHeight ? { height: destinationHeight } : null}
                    data-dnduniqueid={props.dndUniqueID}
                >
                    <DraggableList
                        listData={destination}
                        dndUniqueID={props.dndUniqueID}
                        selectedList={props.selectedList}
                        setSelectedList={props.setSelectedList}
                        disableDragAndDrop={disableDragAndDrop}
                        disableDestinationItems={disableDestinationItems}
                    />
                </ul>
            ) : (
                <ul
                    className="list_container"
                    onDragOver={(event) => dragOverHandler(event)}
                    onDrop={(event) => dropHandler(event)}
                    style={destinationHeight ? { height: destinationHeight } : null}
                    data-dnduniqueid={props.dndUniqueID}
                >
                    <DraggableList
                        listData={destination}
                        dndUniqueID={props.dndUniqueID}
                        selectedList={props.selectedList}
                        setSelectedList={props.setSelectedList}
                        disableDragAndDrop={disableDragAndDrop}
                        disableDestinationItems={disableDestinationItems}
                    />
                </ul>
            )}
        </article>
    );
}

export default Destination;
