import * as React from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import "react-bootstrap-table/css/react-bootstrap-table.css";
import * as UserLoginHistory from '../../../store/userManagement/UserLoginHistoryStore';
import { LoadingOverlay, Loader } from 'react-overlay-loader';
import Moment from 'moment';
import * as Browser from 'bowser';
import * as _ from 'lodash';
interface MyLoginHistoryTableProps {
    userLoginHistory: UserLoginHistory.IUserLoginHistoryState;
    pageNo: number;
    onPageChange: any;
    requestUserLoginHistory: (page: number) => void;
}
interface ColumnValues {
    browser: string,
    loggedInOn: string,
    loggedOutOn?: string,
    IPAddress: string
}
interface MyLoginHistoryTableState {
    data: ColumnValues[]
}
export class MyLoginHistoryTable extends React.Component<MyLoginHistoryTableProps, MyLoginHistoryTableState> {

    constructor(props: MyLoginHistoryTableProps) {
        super(props);
    }
    componentDidMount() {
        this.props.requestUserLoginHistory(1);
    }
    renderShowsTotal(start: number, to: number, total: number) {
        return <p>
            Showing <b>{start}</b> to <b>{to}</b> of <b>{total}</b> entries
            </p>
    }
    private defaultType(cell: any, row: any) {
        return <div title={cell} className="ellipsis">{cell}</div>;
    }

    private setNoContent() {
            return "No Login History"
    }
    private getTableData() {
        const { userLoginHistory: { deviceId } } = this.props;
        const data = _.cloneDeep(this.props.userLoginHistory.loginHistories);
        data.forEach(loginHistory => {
            let browser = Browser.getParser(loginHistory.clientApp);
            loginHistory.clientApp = browser.getBrowserName() + "(" + browser.getBrowserVersion() + ")" + " on " + browser.getOSName() + "(" + browser.getOSVersion() + ")";
            loginHistory.loggedInOn = Moment.utc(loginHistory.loggedInOn).local().format('MM/DD/YYYY hh:mm:ss A');
            if (loginHistory.deviceId === deviceId)
                loginHistory.loggedOutOn = "Current Device";
            else if (!loginHistory.loggedOutOn)
                loginHistory.loggedOutOn = "Logged In";
            else {
                loginHistory.loggedOutOn = Moment.utc(loginHistory.loggedOutOn).local().format('MM/DD/YYYY hh:mm:ss A');
            }
        })
        return data;
    }
    private getColumnWidth(cell: any, row: any, rowIndex: number, colIndex: number) {
        if(colIndex === 1)
            return { width: '30%' };
        return {}
    }
    public render() {
        let data = this.getTableData();
        let pagingationPos: "both" | "top" | "bottom" | undefined = "bottom";
        const columns = [
            {
                header: '',
                key: 'index',
                isKey: true,
                dataFormat: this.defaultType,
                columnClassName: '',
                toolTip: false,
                isHidden: true

            },
            {
                header: 'Browser',
                key: 'clientApp',
                isKey: false,
                dataFormat: this.defaultType,
                columnClassName: 'width30p',
                toolTip: false,
                isHidden: false
            },
            {
                header: 'IP Address',
                key: 'IPAddress',
                isKey: false,
                dataFormat: this.defaultType,
                columnClassName: '',
                toolTip: false,
                isHidden: false
            },
            {
                header: 'LogIn Date',
                key: 'loggedInOn',
                isKey: false,
                dataFormat: this.defaultType,
                columnClassName: '',
                toolTip: false,
                isHidden: false
                
            },
            {
                header: 'LogOut Date',
                key: 'loggedOutOn',
                isKey: false,
                dataFormat: this.defaultType,
                columnClassName: '',
                toolTip: false,
                isHidden: false

            }];

        const options = {
            sizePerPage: 15,
            page: this.props.pageNo,
            onPageChange: this.props.onPageChange,
            paginationShowsTotal: this.renderShowsTotal,
            noDataText: this.setNoContent(),
            paginationPosition: pagingationPos, // default is bottom, top and both is all available
            nextPage: <span className="fa fa-angle-right" />,
            prePage: <span className="fa fa-angle-left" />,
            firstPage: <span className="fa fa-angle-double-left" />,
            lastPage: <span className="fa fa-angle-double-right" />
        };
        return <div className="pos-relative">
            <div>
                <BootstrapTable
                    ref='table'
                    data={data}
                    remote={true}
                    fetchInfo={{ dataTotalSize: this.props.userLoginHistory.loginHistoryCount }}
                    options={options}
                    striped
                    hover={true}
                    pagination={true}
                    
                >
                    {columns.map((value, index) => {
                        return <TableHeaderColumn
                            key={index}
                            ref={value.key}
                            isKey={value.isKey}
                            dataField={value.key}
                            hidden={value.isHidden}
                            dataFormat={value.dataFormat}
                            columnClassName={value.columnClassName}
                            columnTitle={value.toolTip}
                            tdStyle={this.getColumnWidth}
                        >{value.header}
                        </TableHeaderColumn>
                    })}
                </BootstrapTable>
            </div>
        </div >
    }
}
export default MyLoginHistoryTable;