import * as React from 'react';
import { Row, Col } from 'react-bootstrap';
import { DropdownComponent } from '../common/controls/DropdownComponent';

interface SelectOptions {
    value: any,
    label: string
}

interface IYearAndMonthProps {
    controlValue: number;
    onControlValueChange(event: any): void;
    years: number;
    id: string;
    componentEnabled: boolean
}

interface IYearAndMonthState {
    selectedYear: SelectOptions;
    selectedMonth: SelectOptions;
    monthList: SelectOptions[],
    yearList: SelectOptions[],
    monthEnabled: boolean,
    controlEnabled: boolean
}

export class YearAndMonth extends React.Component<IYearAndMonthProps, IYearAndMonthState> {
    constructor(props: IYearAndMonthProps) {
        super(props);

        this.state = {
            selectedYear: {
                value: 0, label: 0 + ' year'
            },
            selectedMonth: {
                value: 0, label: 0 + ' month'
            },
            monthEnabled: true,
            controlEnabled: true,
            monthList: [],
            yearList: []
        }

        this.onYearChange = this.onYearChange.bind(this);
        this.onMonthChange = this.onMonthChange.bind(this);
    }

    UNSAFE_componentWillMount() {
        let monthOptions = [];
        for (var i = 0; i <= 11; i++) {
            monthOptions.push({ value: i, label: i === 0 || i === 1 ? i + ' month' : i + ' months' });
        }
        let yearOptions = [];
        for (var i = 0; i <= this.props.years; i++) {
            yearOptions.push({ value: i, label: i === 0 || i === 1 ? i + ' year' : i + ' years' });
        }
        this.setState({
            yearList: yearOptions,
            monthList: monthOptions
        });
        this.SetMonthOptions(this.props);
        this.SetYearOptions(this.props);
    }

    UNSAFE_componentWillReceiveProps(nextProps: IYearAndMonthProps) {
        if (nextProps !== this.props) {
            this.SetMonthOptions(nextProps);
            this.SetYearOptions(nextProps);
        }
    }

    private SetMonthOptions = (props: IYearAndMonthProps) => {
        let controlValue = props.controlValue;
        let enabled = props.componentEnabled;

        let maxMonths = props.years * 12;
        let months: number = ((controlValue / 30) | 0);
        if (months > maxMonths)
            months = maxMonths;
        let month: number = months % 12;

        this.setState({ selectedMonth: { value: month, label: month === 0 || month === 1 ? month + " month" : month + " months" }, controlEnabled: enabled });
    }

    private SetYearOptions = (props: IYearAndMonthProps) => {
        let controlValue = props.controlValue;
        let enabled = props.componentEnabled;

        let years: number = ((controlValue / 30) | 0);
        let year: number = ((years / 12) | 0);
        if (year > props.years)
            year = props.years;

        if (year === props.years)
            this.setState({
                monthEnabled: false, selectedMonth: {
                    value: 0, label: 0 + " month"
                }
            });
        else
            this.setState({ monthEnabled: true });

        this.setState({ selectedYear: { value: year, label: year === 0 || year === 1 ? year + " year" : year + " years" }, controlEnabled: enabled });
    }

    private onYearChange = (value: any) => {
        if (value === undefined || value === null) {
            value = 0;
        }
        let val: number = parseInt(value);
        if (!isNaN(val) && !(val > this.props.years)) {
            let duration: number = (val * 12) + parseInt(this.state.selectedMonth.value);
            let totalDays: number = duration * 30;
            this.props.onControlValueChange(totalDays);

            if (val === this.props.years)
                this.setState({
                    monthEnabled: false, selectedMonth: {
                        value: 0, label: 0 + " month"
                    }
                });
            else
                this.setState({ monthEnabled: true });

            this.setState({
                selectedYear: {
                    value: value, label: value + " years"
                }
            });
        }
    }

    private onMonthChange = (value: any) => {
        if (value === undefined || value === null) {
            value = 0;
        }
        let val: number = parseInt(value);
        if (!isNaN(val) && !(val > 11)) {
            let duration: number = (parseInt(this.state.selectedYear.value) * 12) + val;
            let totalDays: number = duration * 30;
            this.props.onControlValueChange(totalDays);

            this.setState({
                selectedMonth: {
                    value: value, label: value + " months"
                }
            });
        }
    }

    public render() {
        return (
            <div>
                <div className="select-control">
                    <DropdownComponent
                        id={"ddlYears_" + this.props.id}
                        disabled={!this.state.controlEnabled}
                        options={this.state.yearList}
                        onChange={this.onYearChange}
                        selectedValue={this.state.selectedYear}
                        customPlaceHolder="Years"
                        clearable={false}
                    />
                </div>
                <div className="select-control">
                    <DropdownComponent
                        id={"ddlMonths_" + this.props.id}
                        disabled={!this.state.controlEnabled || !this.state.monthEnabled}
                        options={this.state.monthList}
                        onChange={this.onMonthChange}
                        selectedValue={this.state.selectedMonth}
                        customPlaceHolder="Months"
                        clearable={false}
                    />
                </div>
            </div>
        );
    }
}

export default YearAndMonth;