import fetchIntercept from 'fetch-intercept'
import { VenusNotifier } from '../../components/helper/VenusNotifier'
import * as Loader from '../../components/helper/Loader'
import store from '../../store'
import history from '../../routes/history';
import * as CRSHelper from '../../components/helper/CRSHelper'
import { userPrivilegeChanged } from '../../store/auth/actions';
import { logger } from '../../routes/LoggedIn';

export const unregister = fetchIntercept.register({

    request: function (url, config) {
        let cookieValue = null;
        let name = 'XSRF-TOKEN';
        if (document.cookie && document.cookie !== '') {
            const cookies = document.cookie.split(';');
            for (let i = 0; i < cookies.length; i++) {
                const cookie = cookies[i].trim();
                if (cookie.substring(0, name.length + 1) === (name + '=')) {
                    cookieValue = CRSHelper.encode(cookie.substring(name.length + 1));
                    break;
                } 
            }
        }
        const user = store.getState().auth.user;
        const configDefaults = {
            ...config,
            headers: {
                ...(config.credentials === 'include' && {
                    'X-Requested-With': 'XMLHttpRequest',
                    ...(user.access_token && { authorization: `Bearer ${user.access_token}` }),
                    'X-XSRF-TOKEN': cookieValue
                }),
            },
        }

        const headerEntries = config?.headers?.entries?.() //temporary fix for bug: 67699
        if (headerEntries) {
            for (var pair of headerEntries) {
                configDefaults.headers[pair[0]] = pair[1]
            }
        }

        const correctedUrl = url.replace(/([^:]\/)\/+/g, "$1"); //code added for removing extra slash from url before api
        
        return [correctedUrl, user? configDefaults : config]
    },
    response: function (response) {
        if (response.status == 401) {
            response
                .clone()
                .json()
                .then(response => {
                    Loader.HideLoader()
                    VenusNotifier.Info(response.message, '')
                    window.location.reload()
                })
            return response
        } else if (response.status == 512) {           
            history.push('/restricted-access');          
        }
        else if (response.status == 419) {
            response
                .clone()
                .json()
                .then(response => {
                    logger.trackTrace(`User Permission Change Triggered from Interceptor with logoutCause: ${JSON.stringify(response)}`);
                    store.dispatch(userPrivilegeChanged(response));
                })
        }
        else {           
            return response
        }
    },
})
