import { actionTypes } from "../../types/ActionTypes";
import { SSSUITE_API_BASE_URL } from "../../utils/constants";
import * as Constants from '../../components/helper/Constants';
import { initialTaxSoftwareSetting, ISuiteTaxSoftwareSetting } from "../../Core/ViewModels/Company/CompanySettingsViewModel";
import { handleResponse } from "../Library";
import { NotificationAction, StatusType } from '../common/NotificationStore';
import { Reducer } from 'redux';
import { AppThunkAction } from "../index";
import { addTask } from 'domain-task';

export interface RequestTaxSoftwareSettingAction {
    type: actionTypes.REQUEST_TAXSOFTWARE_SETTING;
}

export interface ReceiveTaxSoftwareSettingAction {
    type: actionTypes.RECEIVE_TAXSOFTWARE_SETTING;
    taxSoftwareSetting: ISuiteTaxSoftwareSetting;
}

type DispatchAction =
    RequestTaxSoftwareSettingAction |
    ReceiveTaxSoftwareSettingAction |
    NotificationAction

type KnownAction =
    DispatchAction

export const actionCreators = {
    requestTaxSoftwareSetting: (): AppThunkAction<KnownAction> => (dispatch, getState) => {

        const fetchTask = fetch(SSSUITE_API_BASE_URL + '/api/tax-software', {
            method: 'GET',
            credentials: 'include'
        })
            .then(handleResponse)
            .then(data => {
                dispatch({ type: actionTypes.RECEIVE_TAXSOFTWARE_SETTING, taxSoftwareSetting: data });
            })
            .catch(error => {
                if (error.status != 512) {
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: Constants.CommonMessages.Error.CompanyDisabled,
                        statusType: StatusType.Error
                    })
                }
            });
        addTask(fetchTask);
        dispatch({ type: actionTypes.REQUEST_TAXSOFTWARE_SETTING });
    },
}

export const reducer: Reducer<ISuiteTaxSoftwareSetting> = (state = initialTaxSoftwareSetting, incomingAction) => {
    const action = incomingAction as DispatchAction;
    switch (action.type) {
        case actionTypes.REQUEST_TAXSOFTWARE_SETTING:
            return state;
        case actionTypes.RECEIVE_TAXSOFTWARE_SETTING:
            let taxSoftwareSetting = { ...state };
            taxSoftwareSetting = action.taxSoftwareSetting;
            return taxSoftwareSetting;

        default: return state;
    }
}
