import { addTask } from 'domain-task';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../';
import { actionTypes } from '../../types/ActionTypes';
import { handleResponse } from '../Library';
import { StatusType, NotificationAction } from '../common/NotificationStore';
import { IHelptextModel } from '../../Core/ViewModels/Common/IHelptextModel';
import { API_BASE_URL } from '../../utils/constants';

export interface IHelptextState {
	helpTexts: IHelptextModel[];
}
interface ReceiveHelpTexts {
	type: actionTypes.RECEIVE_HELPTEXT;
	helpTexts: IHelptextModel[];
}

type KnownAction =
	ReceiveHelpTexts |
	NotificationAction;

export const actionCreators = {
	requestHelptextsForApplication: (reload: boolean = false): AppThunkAction<KnownAction> => (dispatch, getState) => {
		if (reload || (getState().Helptexts.helpTexts.length == 0)) {
			const fetchTask = fetch(`${API_BASE_URL}api/Common/GetAllHelptextsAsync`, {
				method: 'GET',
				credentials: 'include'
			})
				.then(handleResponse)
				.then(response => response as Promise<IHelptextModel[]>)
				.then(data => {					
					//dispatch
					dispatch({ type: actionTypes.RECEIVE_HELPTEXT, helpTexts: data });
				})
				.catch(error => {
					const statusMessage: any = error.statusText?.message ?? error.statusText;
                    if (typeof(statusMessage) === "string") {
						dispatch({ type: actionTypes.NOTIFICATION, statusMessage: statusMessage, statusType: StatusType.Error });
					}
				});
			addTask(fetchTask);
		}
	}
}

const unloadedState: IHelptextState = {
	helpTexts:[]
};

export const reducer: Reducer<IHelptextState> = (state = unloadedState, incomingAction) => {
	const action = incomingAction as KnownAction;
	switch (action.type) {
		case actionTypes.RECEIVE_HELPTEXT:
			let newState: IHelptextState = { ...state };
			newState.helpTexts = action.helpTexts
			return newState;
	}
	return state;
}