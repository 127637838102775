import * as bootbox from 'bootbox';
import * as React from 'react';
import * as ReactDom from 'react-dom';
import {
    BootstrapTable, CustomSelectProps, DateFilter, SelectFilter, CustomFilter, SelectFilterOptionsType,
    TableHeaderColumn, TextFilter, ToolBarProps
} from 'react-bootstrap-table';
import "react-bootstrap-table/css/react-bootstrap-table.css";
import { Loader, LoadingOverlay } from 'react-overlay-loader';
import * as ArchivedReturnsState from '../../../store/reports/archive/ArchivedReturnsState';
import { CheckBoxComponent, CheckBoxSize } from "../../common/CheckBoxComponent";
import { engagementType, getClientName, SignatureStatus, DaysRangeOptionsList } from '../../common/TaxReturn';
import { DeliveredReturnsConstants, DeliveredReturnsTableConstants } from '../../helper/Constants';
import * as Helper from '../../helper/HelperFunctions';
import { VenusNotifier } from '../../helper/VenusNotifier';
import { IShowExportExcelState } from '../../reports/DeliveredReturns/DeliveredReturnsTable';
import { ArchiveReturnsAction } from '../ArchiveReturns/ArchiveReturnsAction';
import { Filters, IFilters, ReportFilterType } from '../Filters';
import { StatusFilter } from '../DeliveredReturns/StatusFilter';
import { CustomMultiSelect } from '../../common/MultipleSelectComponent';
import { Button } from 'react-bootstrap';

let moment = require('moment');
import { Link } from 'react-router-dom';
import { CustomDateFilter } from '../DeliveredReturns/CustomDateFilter';


interface TableData {
    archivedReturns: ArchivedReturnsState.ArchivedReturnsState
    saveFilterShow: boolean,
    onPageChange: any,
    onSortChange: any,
    onSearchChange: any,
    onFilterChange: any,
    pageNo: number,
    totalRows: number;
    customColumnHeader: string;
    onExportToExcel(onExportToExcelComplete: () => void): void;
    onFilterNameChange(event: any): void;
    loadGrid(): void;
    onDownloadHistoryOpen: (rowIndex: number) => void;
    onFilterSave(onApplyFilter: (filter: IFilters) => void): void;
    onFilterUpdate(filterName: string): void;
    onFilterDelete(filterName: string, filterType: ReportFilterType): any;
    filterList: IFilters[],
    currentFilter: IFilters,
    onSetDefaultFilter(name: string, filterType: ReportFilterType): void;
    onRemoveDefaultFilter(name: string, filterType: ReportFilterType): void;
    defaultFilter: string | undefined;
    onSaveFilterShow(): void;
    onSaveFilterHide(): void;

    onDownloadEfileFormsOpen: (rowIndex: number) => void;
    onDownloadReturnOpen: (rowIndex: number) => void;
    onClientTrackingOpen: (rowIndex: number) => void;
    onRestoreReturnOpen: (rowIndex: number) => void;

    isLoading: boolean,
    pageSize: number,

    onRowSelect: (row: any, isSelected: any, e: any) => void;
    onSelectAll: (isSelected: any, rows: any, e: any) => void;
    selectedRows: number[];
    onSignedDetailsOpen: (rowIndex: number) => void;
    proxyFilter: string;
    onPageReload: () => void;
}
const SignatureStatusList = {
    "None": "None",
    "ESigned": "E-Signed",
    "ManuallySigned": "Manually Signed",
    "Uploaded": "Uploaded",
    "AwaitingESign": "Awaiting E-Sign",
    "AwaitingUpload": "Awaiting Upload",
    "Locked": "Locked",
    "MailOrFax": "Mail Or Fax",
    "SignedAndESigned": "Signed And E-Signed",
    "AutoParsed": "Auto-Parsed",
    "Processing": "Processing",
    "Delivering": "Delivering",
    "Delivered": "Delivered",
    "DeliveryFailed": "Delivery Failed",
    "UploadInProgress": "Upload In Progress",
    "PartiallySigned": "Partially Signed",
    "ESignedInOffice": "E-Signed – In Office"
};
export class ArchiveReturnsTable extends React.Component<TableData, IShowExportExcelState> {
    private taxYearList: any[] = [];
    private filterChanged: boolean = false;
    private customStatusMultiSelect: any;
    private customMultiSelect: any;
    private customTaxYearMultiSelect: any;
    public isAppiedFilter: boolean = false;
    constructor(props: TableData) {
        super(props);
        this.state = {
            isActive: true,
            isHiddenExportExcel: false
        };
        this.onClearFilter = this.onClearFilter.bind(this);
        this.defaultType = this.defaultType.bind(this);
        this.onFilterChange = this.onFilterChange.bind(this);
        this.onLoadSelectedFilter = this.onLoadSelectedFilter.bind(this);
        this.onFilterSaveClick = this.onFilterSaveClick.bind(this);
        this.CustomDateFilter = this.CustomDateFilter.bind(this);
    }

    UNSAFE_componentWillMount() {
        this.taxYearList = Helper.PrepareTaxYear();
    }
    renderShowsTotal(start: number, to: number, total: number) {
        return (
            <p>
                Showing {start} to {to} of {total} entries
            </p>
        );
    }
    private getStatusMultiSelectDropDown = (filterHandler: any, customFilterParameters: any) => {
        const options = customFilterParameters.options;
        const enableAllFilter = customFilterParameters.enableAllFilter;
        const placeholder = customFilterParameters.placeholder;
        return (
            <CustomMultiSelect onRef={(ref: any) => (this.customStatusMultiSelect = ref)} filterHandler={filterHandler} options={options} enableAllFilter={enableAllFilter} placeholder={placeholder} />
        );
    }

    private getYearMultiSelectDropDown = (filterHandler: any, customFilterParameters: any) => {
        const options = customFilterParameters.options;
        const enableAllFilter = customFilterParameters.enableAllFilter;
        const placeholder = customFilterParameters.placeholder;
        return (
            <CustomMultiSelect onRef={(ref: any) => (this.customTaxYearMultiSelect = ref)} filterHandler={filterHandler} options={options} enableAllFilter={enableAllFilter} placeholder={placeholder} />
        );
    }
    private setNoContent() {
        if (this.props.isLoading) {
            return (<LoadingOverlay style={{ height: '400px' }}>
                <Loader loading={this.props.isLoading} />
            </LoadingOverlay>)
        } else {
            return DeliveredReturnsTableConstants.OtherMessage.NoReturnsFound
        }
    }

    private defaultType(cell: any, row: any) {
        return <div title={cell} className="ellipsis">{cell}</div>;
    }

    customStatusTooltip = (cell: any, row: any) => {
        const status: "None" | "ESigned" | "ManuallySigned" | "Uploaded" | "AwaitingESign" | "AwaitingUpload"
            | "Locked" | "MailOrFax" | "SignedAndESigned" | "AutoParsed" | "Processing" | "Delivering"
            | "Delivered" | "DeliveryFailed" | "PartiallySigned" | "ESignedInOffice" = row.signatureStatusIdType;
        return `${SignatureStatusList[status]}`;
    }

    private onFilterChange(dataField: any) {
        if (!this.filterChanged) {
            this.filterChanged = true;
            this.props.onFilterChange(dataField, ReportFilterType.Archived);
            this.filterChanged = false;
        }
    }

    public onClearFilter() {
        this.filterChanged = true;
        (this.refs.taxDocumentName as TableHeaderColumn)?.cleanFiltered();
        (this.refs.clientId as TableHeaderColumn)?.cleanFiltered();
        (this.refs.engagementType as TableHeaderColumn)?.cleanFiltered();
        (this.refs.partner as TableHeaderColumn)?.cleanFiltered();
        (this.refs.deliveredBy as TableHeaderColumn)?.cleanFiltered();
        (this.refs.deliveredDate as TableHeaderColumn)?.cleanFiltered();
        this.customStatusMultiSelect?.cleanFiltered();
        (this.refs.lastReminder as TableHeaderColumn)?.cleanFiltered();
        (this.refs.downloaded as TableHeaderColumn)?.cleanFiltered();
        (this.refs.testColumn as TableHeaderColumn)?.cleanFiltered();
        this.customTaxYearMultiSelect?.cleanFiltered();
        this.filterChanged = false;
    }

    private onFilterSaveClick(filterName: string) {

        if (!Helper.validateFilter(this.props.currentFilter)) {
            VenusNotifier.Warning(DeliveredReturnsConstants.OtherMessage.FilterFieldsAreEmpty, null);
            return;
        }

        let temThis = this;
        if (typeof filterName == 'undefined' || filterName.length == 0 || filterName == this.props.proxyFilter) {
            this.props.onSaveFilterShow();
        }
        else {
            bootbox.dialog({
                title: DeliveredReturnsTableConstants.Title.FilterSaveUpdate,
                message: DeliveredReturnsTableConstants.OtherMessage.DoYouWantToCreateNewFilterOrUpdateTheCurrent,
                buttons: {
                    cancel: {
                        label: '<i class="fas fa-times"></i>' + DeliveredReturnsTableConstants.FormLabel.NoUpdateTheCurrent,
                        className: 'btn-white',
                        callback: function () { temThis.props.onFilterUpdate(filterName) }
                    },
                    confirm: {
                        label: '<i class="fas fa-check"></i>' + DeliveredReturnsTableConstants.FormLabel.YesCreateNew,
                        className: 'btn-info',
                        callback: function () { temThis.props.onSaveFilterShow(); }
                    }
                }
            });
        }
    }

    public onLoadSelectedFilter(filter?: IFilters) {
        this.isAppiedFilter = true;
        this.onClearFilter();
        if (filter) {
            this.filterChanged = true;
            for (let field of Object.keys(filter.fields)) {
                switch (field) {
                    case 'taxDocumentName':
                        (this.refs.taxDocumentName as TableHeaderColumn)?.applyFilter(filter.fields[field]);
                        break;
                    case 'clientId':
                        (this.refs.clientId as TableHeaderColumn)?.applyFilter(filter.fields[field]);
                        break;
                    case 'partner':
                        (this.refs.partner as TableHeaderColumn)?.applyFilter(filter.fields[field]);
                        break;
                    case 'deliveredBy':
                        (this.refs.deliveredBy as TableHeaderColumn)?.applyFilter(filter.fields[field]);
                        break;
                    case 'status':
                        this.customStatusMultiSelect?.applyFilter(filter.fields[field]);
                        break;
                    case 'deliveredDate':
                        let deliveredDateFilter: any = filter.fields[field];
                        if (deliveredDateFilter.date) {
                            (this.refs.deliveredDate as TableHeaderColumn)?.applyFilter(deliveredDateFilter);
                        }
                        break;
                    case 'lastReminder':
                        let lastReminderFilter: any = filter.fields[field];
                        if (lastReminderFilter.date) {
                            (this.refs.lastReminder as TableHeaderColumn)?.applyFilter(lastReminderFilter);
                        }
                        break;
                    case 'engagementType':
                        (this.refs.engagementType as TableHeaderColumn)?.applyFilter(filter.fields[field]);
                        break;
                    case 'downloaded':
                        (this.refs.downloaded as TableHeaderColumn)?.applyFilter(filter.fields[field]);
                        break;
                    case 'testColumn':
                        (this.refs.testColumn as TableHeaderColumn)?.applyFilter(filter.fields[field]);
                        break;
                    case 'taxYear':
                        this.customTaxYearMultiSelect?.applyFilter((filter.fields[field]));
                        break;
                }
                this.filterChanged = false;
            }
            this.isAppiedFilter = false;
            this.onFilterChange(filter.fields);
            (this.refs.table as BootstrapTable)?.handleSearch(filter.searchText);
        }
    }

    customTypeTooltip = (cell: any, row: any) => {
        return `${row.engagementType}`;
    }

    customDownloadedTooltip = (cell: any, row: any) => {
        return `${(cell > 0 && (row.signatureStatusIdType === SignatureStatus[SignatureStatus.ESigned] || row.signatureStatusIdType === SignatureStatus[SignatureStatus.ESignedInOffice] ||row.signatureStatusIdType === SignatureStatus[SignatureStatus.Uploaded] || row.signatureStatusIdType === SignatureStatus[SignatureStatus.SignedAndESigned])) ? 'Downloaded'
            : row.bulkDownloadCount > 0 ? 'Downloaded' : 'To Be Downloaded'}`;
    }

    private getCustomFilter = (filterHandler: any, customFilterParameters: any) => {
        return <StatusFilter filterHandler={filterHandler} customFilterParameters={customFilterParameters} />

    }

    private signatureStatusIdType = (cell: any, row: any) => {

        const status: "None" | "ESigned" | "ManuallySigned" | "Uploaded" | "AwaitingESign" | "AwaitingUpload"
            | "Locked" | "MailOrFax" | "SignedAndESigned" | "AutoParsed" | "Processing" | "Delivering"
            | "Delivered" | "DeliveryFailed" | "PartiallySigned" | "ESignedInOffice" = row.signatureStatusIdType;

        if (row.signedCount > 0 && row.signatureStatusIdType === SignatureStatus[SignatureStatus.PartiallySigned]) {
            return <Link to={"#"} onClick={() => this.props.onSignedDetailsOpen(row.rowIndex)}>{SignatureStatusList[status].toUpperCase()}</Link>;
        }
        return <span>{SignatureStatusList[status].toUpperCase()}</span>;
    }

    private archivedReportAction = (cell: any, row: any) => {
        return <ArchiveReturnsAction
            rowIndex={row.rowIndex}
            signatureStatus={row.signatureStatusIdType}
            onClientTrackingOpen={this.props.onClientTrackingOpen}
            onDownloadEfileFormsOpen={this.props.onDownloadEfileFormsOpen}
            onDownloadReturnOpen={this.props.onDownloadReturnOpen}
            onRestoreReturnOpen={this.props.onRestoreReturnOpen}
            title={cell} />
    }

    private downloadedHistory = (cell: any, row: any) => {
        return ((row.downloaded > 0 && (row.signatureStatusIdType === SignatureStatus[SignatureStatus.ESigned] ||row.signatureStatusIdType === SignatureStatus[SignatureStatus.ESignedInOffice] || row.signatureStatusIdType === SignatureStatus[SignatureStatus.Uploaded] || row.signatureStatusIdType === SignatureStatus[SignatureStatus.SignedAndESigned])) ? <Link to={"#"} onClick={() => this.props.onDownloadHistoryOpen(row.rowIndex)}>Downloaded</Link>
            : (row.bulkDownloadCount > 0) ? <span>Downloaded</span> : <span>To Be Downloaded</span>);

    }

    createCustomCheckbox = (props: CustomSelectProps): any => {
        return (<CheckBoxComponent size={CheckBoxSize.sm}
            id={"archive-returns-checkbox-" + props.rowIndex}
            indeterminate={props.indeterminate}
            checked={props.checked}
            disabled={props.disabled}
            onChange={(e: any) => props.onChange(e, props.rowIndex)}
            ref={(input: any) => {
                if (input) {
                    input.indeterminate = props.indeterminate;
                }
            }}
            text={""} />);
    }


    private createCustomToolBar = () => {
        return (
            <div className="col-sm-12 col-md-12 col-lg-12" style={{ height: 33, position: 'unset' }}>
                <Filters ref='Filters'
                    onFilterNameChange={this.props.onFilterNameChange}
                    onFilterSave={this.props.onFilterSave}
                    onClearFilter={this.onClearFilter}
                    onExportToExcel={this.props.onExportToExcel}
                    filterList={this.props.filterList}
                    onSetDefaultFilter={this.props.onSetDefaultFilter}
                    onRemoveDefaultFilter={this.props.onRemoveDefaultFilter}
                    onFilterDelete={this.props.onFilterDelete}
                    show={this.props.saveFilterShow}
                    onFilterSaveClick={this.onFilterSaveClick}
                    onSaveFilterHide={this.props.onSaveFilterHide}
                    onLoadSelectedFilter={this.onLoadSelectedFilter}
                    defaultFilter={this.props.defaultFilter}
                    loadGrid={this.props.loadGrid}
                    showExportExcel={this.state.isHiddenExportExcel}
                    proxyFilter={this.props.proxyFilter}
                    onPageReload={this.props.onPageReload}
                    totalRows={this.props.totalRows}
                />
            </div>
        );
    }

    private CustomDateFilter = (filterHandler: any, customFilterParameters: any) => {
        return (<CustomDateFilter onRef={(ref: any) => (this.customMultiSelect = ref)} filterHandler={filterHandler} customFilterParameters={customFilterParameters} calendarContainer={ReactDom.findDOMNode(this.refs.table)} />);
    }

    public render() {
        const options = {
            onSortChange: this.props.onSortChange,
            onPageChange: this.props.onPageChange,
            sizePerPage: this.props.pageSize,
            onSearchChange: this.props.onSearchChange,
            page: this.props.pageNo,
            paginationShowsTotal: this.renderShowsTotal,
            onRowDoubleClick: function (row: any) {
            },
            onFilterChange: this.onFilterChange,
            noDataText: this.setNoContent(),
            clearSearch: true,
            toolBar: this.createCustomToolBar,
            nextPage: <span className="fa fa-angle-right" />,
            prePage: <span className="fa fa-angle-left" />,
            firstPage: <span className="fa fa-angle-double-left" />,
            lastPage: <span className="fa fa-angle-double-right" />
        };

        const pagination = true;

        let selected: number[] = [];

        if (this.props.selectedRows.length > 0) {
            for (var i in this.props.selectedRows) {
                let rowIndex = this.props.selectedRows[i];
                selected.push(((this.props.pageNo - 1) * this.props.pageSize) + rowIndex);
            }
        }

        const EngagementList = {
            1: '1040',
            2: '1065',
            3: '1120',
            4: '1120S',
            5: '1041',
            6: '1040NR'
        }

        const SignatureStatusOptionsList = [
            { label: 'E-SIGNED', value: 1 },
            { label: 'MANUALLY SIGNED', value: 2 },
            { label: 'UPLOADED', value: 3 },
            { label: 'AWAITING E-SIGN', value: 4 },
            { label: 'AWAITING UPLOAD', value: 5 },
            { label: 'SIGNED AND E-SIGNED', value: 8 },
            { label: 'DELIVERED TO TAXCADDY', value: 10 },
            { label: 'TAXCADDY DELIVERY FAILED', value: 16 },
            { label: 'PARTIALLY SIGNED', value: 20 },
            { label: 'E-SIGNED – IN OFFICE', value: 22 },
        ];

        const DownloadedOptionsList = {
            0: "To Be Downloaded",
            1: "Downloaded"
        }

        let selectRowProp: any = {
            mode: 'checkbox',
            clickToSelect: true,
            onSelect: this.props.onRowSelect,
            onSelectAll: this.props.onSelectAll,
            selected: selected,
            customComponent: this.createCustomCheckbox,
            className: 'row-selected'
        };

        const columns = [
            {
                header: '',
                key: 'index',
                isKey: true,
                dataFormat: this.defaultType,
                columnClassName: '',
                dataSort: true,
                toolTip: false,
                isHidden: true,
                filter: { type: 'TextFilter', placeholder: 'index', style: { font: 'bold' } } as TextFilter

            },
            {
                header: 'Name',
                key: 'taxDocumentName',
                isKey: false,
                dataFormat: this.defaultType,
                columnClassName: '',
                dataSort: true,
                toolTip: true,
                isHidden: false,
                filter: { type: 'TextFilter', placeholder: 'Name', style: { font: 'bold' } } as TextFilter
            },
            {
                header: 'Client ID',
                key: 'clientId',
                isKey: false,
                dataFormat: this.defaultType,
                columnClassName: '',
                dataSort: true,
                toolTip: false,
                isHidden: false,
                filter: { type: 'TextFilter', placeholder: 'Client ID', style: { font: 'bold' } } as TextFilter
            },
            {
                header: 'Type',
                key: 'engagementType',
                isKey: false,
                dataFormat: this.defaultType,
                columnClassName: '',
                dataSort: true,
                toolTip: this.customTypeTooltip,
                isHidden: false,
                filter: { type: 'SelectFilter', options: EngagementList } as SelectFilter
            },
            {
                header: 'ERO / Signer',
                key: 'partner',
                isKey: false,
                dataFormat: this.defaultType,
                columnClassName: '',
                dataSort: true,
                toolTip: false,
                isHidden: false,
                filter: { type: 'TextFilter', placeholder: 'ERO / Signer', style: { font: 'bold' } } as TextFilter
            },
            {
                header: 'Delivered By',
                key: 'deliveredBy',
                isKey: false,
                dataFormat: this.defaultType,
                columnClassName: '',
                dataSort: true,
                toolTip: false,
                isHidden: false,
                filter: { type: 'TextFilter', placeholder: 'Delivered By', style: { font: 'bold' } } as TextFilter
            },
            {
                header: 'Delivered Date',
                key: 'deliveredDate',
                isKey: false,
                dataFormat: this.defaultType,
                columnClassName: '',
                dataSort: true,
                toolTip: false,
                isHidden: false,
                filter: { type: 'SelectFilter', placeholder: 'Select Date Limit', options: DaysRangeOptionsList } as SelectFilter
            },
            {
                header: 'Status',
                key: 'status', // String-based value accessors!
                isKey: false,
                dataFormat: this.signatureStatusIdType,
                columnClassName: '',
                dataSort: true,
                toolTip: this.customStatusTooltip,
                isHidden: false,
                width: 'auto',
                Placeholder: 'Status',
                filter: { type: 'CustomFilter', getElement: this.getStatusMultiSelectDropDown, customFilterParameters: { options: SignatureStatusOptionsList, enableAllFilter: false, placeholder: 'Select Status...' } as any } as CustomFilter
            },
            {
                header: 'Last Reminder',
                key: 'lastReminder', // String-based value accessors!
                isKey: false,
                dataFormat: this.defaultType,
                columnClassName: '',
                dataSort: true,
                toolTip: false,
                isHidden: false,
                filter: {
                    type: 'CustomFilter',
                    getElement: this.CustomDateFilter

                } as CustomFilter
            },
            {
                header: this.props.customColumnHeader, //need to get it from data base
                key: 'testColumn', // String-based value accessors!
                isKey: false,
                dataFormat: this.defaultType,
                columnClassName: '',
                dataSort: true,
                toolTip: false,
                isHidden: this.props.customColumnHeader != "" ? false : true,
                filter: { type: 'TextFilter', placeholder: this.props.customColumnHeader, style: { font: 'bold' } } as TextFilter // change the placeholder according to header
            },
            {
                header: 'Downloaded',
                key: 'downloaded', // String-based value accessors!
                isKey: false,
                dataFormat: this.downloadedHistory,
                columnClassName: '',
                dataSort: true,
                toolTip: this.customDownloadedTooltip,
                isHidden: false,
                filter: { type: 'SelectFilter', options: DownloadedOptionsList } as SelectFilter
            },
            {
                header: 'Deletion Date',
                key: 'expiryDate', // String-based value accessors!
                isKey: false,
                dataFormat: this.defaultType,
                columnClassName: '',
                dataSort: true,
                toolTip: false,
                isHidden: false,
                width: 'auto',
                filter: {
                    type: 'CustomFilter',
                    getElement: this.CustomDateFilter

                } as CustomFilter
            },
            {
                header: 'Tax Year',
                key: 'taxYear',
                isKey: false,
                dataFormat: this.defaultType,
                columnClassName: '',
                dataSort: true,
                toolTip: true,
                isHidden: false,
                filter: { type: 'CustomFilter', getElement: this.getYearMultiSelectDropDown, customFilterParameters: { options: this.taxYearList, enableAllFilter: true, placeholder: 'Select Year...' } as any } as CustomFilter

            },
            {
                header: 'Action',
                key: 'button', // String-based value accessors!
                isKey: false,
                dataFormat: this.archivedReportAction,
                columnClassName: 'button-cell',
                dataSort: false,
                toolTip: false,
                isHidden: false,
                filter: { type: 'TextFilter', placeholder: '', style: { display: 'none' } } as TextFilter
            },
        ];

        const data = this.props.archivedReturns.archivedReturnTableModel.documents.map((model, index) => {
            return {
                taxDocumentName: getClientName(model.document),
                clientId: model.document.clientId,
                engagementType: engagementType(model.document.engagementType),
                partner: model.document.partner.lastName + ", " + model.document.partner.firstName,
                deliveredBy: model.document.deliveredByUser == undefined || model.document.deliveredByUser.userId == 0 ? "" : model.document.deliveredByUser.lastName + ", " + model.document.deliveredByUser.firstName,
                deliveredDate: moment(model.document.deliveredOn).format('MM/DD/YYYY'),
                signatureStatusIdType: model.document.signatureStatus,
                lastReminder: model.lastReminderOn == null ? "" : moment(model.lastReminderOn).format('MM/DD/YYYY'),
                testColumn: model.customColumn == null ? "" : model.customColumn,
                downloaded: model.downloadsCount,
                bulkDownloadCount: model.bulkDownloadCount,
                button: 'Actions',
                index: ((this.props.pageNo - 1) * this.props.pageSize) + index,
                signedCount: model.signedCount,
                id: model.document.id,
                documentKey: model.document.documentGuid,
                taxYear: model.document.taxYear,
                rowIndex: index
            }
        });

        return <div className="pos-relative">

            <div data-test-auto="B510184C-5041-464B-9904-107F0B3C6F60">
                <BootstrapTable
                    ref='table'
                    data={data}
                    remote={true}
                    fetchInfo={{ dataTotalSize: this.props.totalRows }}
                    options={options}
                    striped
                    hover={true}
                    pagination={pagination}
                    selectRow={selectRowProp}>
                    {columns.map((value, index) => {
                        return <TableHeaderColumn
                            key={index}
                            ref={value.key}
                            isKey={value.isKey}
                            dataField={value.key}
                            hidden={value.isHidden}
                            dataFormat={value.dataFormat}
                            columnClassName={value.columnClassName}
                            columnTitle={value.toolTip}
                            filter={value.filter}
                            dataSort={value.dataSort}
                        >{value.header}
                        </TableHeaderColumn>;
                    })}
                </BootstrapTable>
            </div>
        </div >
    }
}

export default ArchiveReturnsTable