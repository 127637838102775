import * as React from 'react';
import { Popover, Overlay } from 'react-bootstrap';
import { VoucherTabConstants } from '../../../helper/Constants';
import { DeleteDollarZero } from '../../../svg/IconCollection';

export interface DeleteZeroAmountVoucherProps {
    handleDeleteZeroAmountVoucher?: () => void;
    zeroAmountVouchersCount?: number | 0;
}

export interface DeleteZeroAmountVoucherState {
    showDeleteVoucherView: boolean;
    deletePopoverTarget: any;
}

export class ZeroAmountVoucherRemover extends React.Component<DeleteZeroAmountVoucherProps, DeleteZeroAmountVoucherState>
{
    state: DeleteZeroAmountVoucherState = {
        showDeleteVoucherView: false,
        deletePopoverTarget: {}
    };

    public closeDeleteConfirmPopHover = () => {
        document.body.click();
    }

    private handleDeleteZeroAmountVoucher = () => {
        if (this.props.handleDeleteZeroAmountVoucher) {
            this.props.handleDeleteZeroAmountVoucher();
            document.body.click();
        }
    }

    private handleDeleteVoucher = (event: any) => {
        this.setState(s => ({ showDeleteVoucherView: !s.showDeleteVoucherView }));
    }

    private onCancelDeleteVoucherPopover = () => {
        this.setState({ showDeleteVoucherView: false });
    }

    private attachDeletePopoverRef = (target: any) => { this.setState({ deletePopoverTarget: target }) };


    public render() {
        const deletepopover = (<Popover id="popover-voucher-delete-confirm">
            <Popover.Title as="h3">{VoucherTabConstants.DeleteZeroAmountVoucher_1 + this.props.zeroAmountVouchersCount + VoucherTabConstants.DeleteZeroAmountVoucher_2}</Popover.Title>
            <Popover.Content>
                <button
                className="btn btn-only btn-success"
                onClick={this.handleDeleteZeroAmountVoucher}
                title="Yes"
                data-test-auto="BCDBF6AB-57E0-4AB7-BAB2-580203E088C8"
                style={{ marginLeft: "30px" }}>
                Yes
                </button>
                <button
                onClick={this.closeDeleteConfirmPopHover}
                title="No"
                data-test-auto="8AAB3A64-BD99-411A-9F63-E627D51F1F59"
                className="btn btn-only btn-danger"
                style={{ marginLeft: "5px" }}>
                No
                </button>
            </Popover.Content>
        </Popover>
        )

        return <div>
            <span className="float-right">
                <button
                    className="delete-zero-amount-button"
                    onClick={this.handleDeleteVoucher}
                    ref={this.attachDeletePopoverRef}
                    title="Remove all $0 vouchers"
                    data-test-auto="397565BE-17F0-44D9-BDD2-E8F425180A45"
                >
                    <DeleteDollarZero className="delete-zero-icon"/>
                </button>
            </span>
            <Overlay
                rootClose={true} onHide={this.onCancelDeleteVoucherPopover}
                target={this.state.deletePopoverTarget} containerPadding={20}
                placement="right" show={this.state.showDeleteVoucherView}>
                {deletepopover}
            </Overlay>
        </div >
    }
};