import * as React from 'react';
import { Card, Accordion } from 'react-bootstrap';
import { IVoucherState } from '../../../../store/ProcessReturn/voucher/VoucherStore';
import {
	CustomType, DocumentGroups, getAllStateImages, getVoucherTypes,
	IFormBase, ITaxingAuthority, ITaxReturn, IVoucher,
	StateUSA, VoucherNo, VoucherTypes, VoucherMode, initialVoucher
} from '../../../common/TaxReturn';
import { VoucherTabConstants } from '../../../helper/Constants';
import * as Helper from '.././../../helper/HelperFunctions';
import { VoucherPayment, VoucherEstimatedPayment } from '../../../svg/IconCollection';
import { VoucherSort } from '../Voucher/VoucherSort';
import { IPageItem } from '../ProcessReturnModels';



export interface IRecognizedVoucherProps {
	taxReturn: ITaxReturn;

	populateVoucherCard: (voucher: IVoucher) => void;

	getAllTaxingAuthorities: ITaxingAuthority[];

	isRevisionProcess?: boolean;
}

export interface IRecognizedVoucherState {
	voucher: IVoucher;
	showAddVoucherView: boolean;
	popoverTarget: any;
	showAlert: boolean;
	message: string;
	expandRecognizedVoucherListCard: boolean;
	enableLeftCardScroll: boolean;
}

export class RecognizedVoucherList extends React.Component<IRecognizedVoucherProps, IRecognizedVoucherState>
{
    constructor(props: IRecognizedVoucherProps) {
        super(props);
        this.state = {
            voucher: initialVoucher,
            showAddVoucherView: false,
            popoverTarget: {},
            showAlert: false,
            message: '',
            expandRecognizedVoucherListCard: true,
            enableLeftCardScroll: true
        };



		this.enableScroll = this.enableScroll.bind(this);

		this.getTitle = this.getTitle.bind(this);
		this.stopScroll = this.stopScroll.bind(this);

		this.getVoucherClass = this.getVoucherClass.bind(this);
	}




	getTitle(voucher: IVoucher, formName: string) {
		let authority = this.props.getAllTaxingAuthorities.find(x => x.Id == (voucher && voucher.authorityID))
		let authorityName = authority ? authority.AuthorityName + " " : "";
		if (formName) {
			const parts = formName ? formName.split("###") : [];
			const title = parts[0].startsWith(authorityName) ? parts[0] : authorityName + parts[0];
			return [
				<div title={title} className="bookmark-name wordbreak ellipsis">{title}</div>,
				<div className="bookmark-right client-info-payments" title={'$' + Helper.formatCurency(Number(voucher.amount))}
					style={{
						fontFamily: "'Lucida Console', 'Monaco', 'monospace'",
						color: '#ff0000'
					}}>
					{'($' + Helper.formatCurency(Number(voucher.amount)) + ')'}
				</div>
			]
		} else {
			return null;
		}
	}

	private getVoucherClass(voucher: IVoucher) {
		let voucherClass: string = "payment-voucher";
		if (getVoucherTypes(voucher.voucherNo) ===
			VoucherTypes.EstimatedVoucher) {
			voucherClass = "estimated-voucher";
		}
		return voucherClass;
	}

    private getVoucherByPageNo = (vouchers: IVoucher[], pageNo: number, voucherNo: VoucherNo) => {
		let voucher = {} as IVoucher;
		if (vouchers && vouchers.length > 0) {
			vouchers.map((value, index) => {
                if (value.pageNo[0] && value.pageNo[0] === pageNo && value.voucherNo == voucherNo) {
					voucher = value;
					return;
				}
			})
		}
		return voucher;
	}

	enableScroll = () => {
		if (!this.state.enableLeftCardScroll) {
			const scrollDiv = (document.querySelector(".scroll div div") as HTMLElement);
			scrollDiv.style.overflow = "scroll";
			scrollDiv.style.marginRight = "-17px";
			((document.querySelector(".scroll div") as HTMLElement).children[2] as HTMLElement).style.display = "block";
			this.setState({ enableLeftCardScroll: true });
		}

	}

	stopScroll = () => {
		if (this.state.enableLeftCardScroll) {
			const scrollDiv = (document.querySelector(".scroll div div") as HTMLElement);
			const top: number = scrollDiv.scrollTop;
			scrollDiv.style.overflow = "hidden";
			scrollDiv.style.marginRight = "0px";
			scrollDiv.scrollTop = top;
			((document.querySelector(".scroll div") as HTMLElement).children[2] as HTMLElement).style.display = "none";
			this.setState({ enableLeftCardScroll: false });
		}

	}

	private getRecognizedVoucherModes = () => {
		let voucherModes:VoucherMode[]=[];
		voucherModes.push(VoucherMode.Recognized);
		voucherModes.push(VoucherMode.AutomatedVoucher);
		return voucherModes;
	}

	public render() {
		let authorities = this.props.getAllTaxingAuthorities;
		let voucherSort = new VoucherSort(this.props.taxReturn, authorities, 
			this.getRecognizedVoucherModes());
		let bookmarks = voucherSort.getSortedBookmarks(this.props.isRevisionProcess);
		let recognizedVouchers = voucherSort.getVouchers();

		return <div className="bookmarksPane">
			<Accordion defaultActiveKey="0">
				<Card>
				<Accordion.Toggle eventKey="0">
						<Card.Header style={{ padding: '10px' }}>
							<Card.Title className="float-left" as="h3" style={{ marginBottom: '0px' }}>
						Recognized Vouchers
                    </Card.Title>

					<div className="clearfix"></div>
				</Card.Header>
				</Accordion.Toggle>
				<Accordion.Collapse eventKey="0">
					<Card.Body style={{ padding: '0px' }}>
						<div className="pageListView">
								{
									recognizedVouchers.length == 0 || (this.props.isRevisionProcess && bookmarks[0].pages.length == 0) ? <div className="textalign-center">No Content</div> :

									(bookmarks && bookmarks.length == 1 && bookmarks[0].pages.map((value: IPageItem, index: number) => {
                                        let voucher = this.getVoucherByPageNo(recognizedVouchers, value.pageNo, value.voucherNo);
										return voucher && voucher.authorityID > 0 && (<div
											key={index}
											className="custom-list-group-item bookmark-item " >
											<div className="bookmark-row">
												<div style={{ width: "24px" }}>

													{(this.getVoucherClass(voucher) == "estimated-voucher") ?
														<VoucherEstimatedPayment className={"bookmark-icon-image"} /> :
														<VoucherPayment className={"bookmark-icon-image"} />
													}
												</div>
												<div className="cursor-pointer" style={{ display: "inline-flex", width: "calc(100% - 25px)" }}
													onClick={() => { this.props.populateVoucherCard(voucher); }}>
													{
														this.getTitle(voucher, value.pageTitle)
													}
												</div>
											</div>
										</div>)
									}))
							}
						</div>
					</Card.Body>
				</Accordion.Collapse>
			</Card>
			</Accordion>
		</div>
	}
};
