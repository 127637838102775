import {  addTask } from 'domain-task';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../';
import { actionTypes } from '../../types/ActionTypes';
import { IPaymentViewModel, IPurchaseDetails, initialPurchaseDetails, intialPurchaseViewModel } from '../../Core/ViewModels/Purchase/PurchaseViewModels'
import { handleResponse } from '../Library';
import { StatusType, NotificationAction } from '../common/NotificationStore';
import { IPaymentDetails, initializePaymentDetails} from '../../Core/ViewModels/Payment/IPaymentDetails'
import { API_BASE_URL } from '../../utils/constants';


export interface IPurchaseData {
    purchaseDetails: IPurchaseDetails,
    purchaseViewModel: IPaymentViewModel,
    paymentDetails: IPaymentDetails
};

export interface RequestPurchaseSummary {
    type: actionTypes.REQUEST_PURCHASE_SUMMARY;
}
export interface ResponsePurchaseSummary {
    type: actionTypes.RESPONSE_PURCHASE_SUMMARY;
    purchaseDetails: IPurchaseDetails
}



type KnownAction =
    RequestPurchaseSummary |
    ResponsePurchaseSummary |
    NotificationAction;

export const unloadedState: IPurchaseData =
{
    purchaseDetails: initialPurchaseDetails,
    purchaseViewModel: intialPurchaseViewModel,
    paymentDetails: initializePaymentDetails
};

export const actionCreators = {

    requestPurchaseSummary: (callback?: (data?: any) => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let url=API_BASE_URL + 'api/PurchaseReturn/GetPurchaseSummaryAsync'
        let fetchTask = fetch(url, {  method: 'GET',
        credentials: 'include'
       })
            .then(handleResponse)
            .then(response => response as Promise<IPurchaseDetails>)
            .then(data => {
                dispatch({
                    type: actionTypes.RESPONSE_PURCHASE_SUMMARY, purchaseDetails: data
                });
                if (callback && data) {
                    callback(data);
                }
            }).catch(function (error) {
                const statusMessage: any = error.statusText?.message ?? error.statusText;
                if (typeof(statusMessage) === "string") {
                    dispatch({ type: actionTypes.NOTIFICATION, statusMessage: statusMessage, statusType: StatusType.Error });
                }
            });
        addTask(fetchTask);
    },
   
    getStore: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
    }

};


export const reducer: Reducer<IPurchaseData> = (state: IPurchaseData = unloadedState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;

    switch (action.type) {
        case actionTypes.RESPONSE_PURCHASE_SUMMARY:
            return {
                purchaseDetails: action.purchaseDetails,
                purchaseViewModel: state.purchaseViewModel,
                paymentDetails: initializePaymentDetails
            } as IPurchaseData;
        default:
            return unloadedState;
    }
}
