import * as React from "react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { GetFormatedDateTime } from "../../helper/HelperFunctions";
import { OverlayLoader } from "../../helper/OverlayLoader";
import { UserEventValue } from "../../helper/Constants";
import { ITaxReturn } from "../../common/TaxReturn";

interface IInternalUserTrackingProps {
    taxReturn: ITaxReturn;
    show: boolean;
}

export class InternalUserTracking extends React.Component<IInternalUserTrackingProps, {}> {
    public render() {
        this.state = {
            loading: false
        };

        const columns = [
            {
                header: "Events",
                key: "eventId",
                isKey: true,
                dataFormat: this.eventIdType,
                columnClassName: "overflowTextClientTracking",
                dataSort: true,
                toolTip: true,
                hidden: false,
                width: "200px"
            },
            {
                header: "By",
                key: "actedBy",
                isKey: false,
                dataFormat: this.defaultType,
                columnClassName: "overflowTextClientTracking",
                dataSort: true,
                toolTip: true,
                hidden: false,
                width: "125px"
            },
            {
                header: "Event Date",
                key: "actedOn",
                isKey: false,
                dataFormat: this.actedOnType,
                columnClassName: "overflowTextClientTracking",
                dataSort: true,
                toolTip: true,
                hidden: false,
                width: "75px"
            }
        ];

        const options = {
            sizePerPage: 20,
            paginationShowsTotal: this.createTotalPages
        };
        const data =
            this.props.taxReturn && this.props.taxReturn.internalTracking
                ? this.props.taxReturn.internalTracking.map((model, index) => {
                      return {
                          eventId: model.eventId,
                          actedBy: model.actedBy,
                          actedOn: model.actedOn
                      };
                  })
                : [];

        let modalBody = data ? (
            <BootstrapTable data={data} options={options} striped pagination={true}>
                {columns.map((value, index) => {
                    return (
                        <TableHeaderColumn
                            key={index}
                            width={value.width}
                            hidden={value.hidden}
                            isKey={value.isKey}
                            dataField={value.key}
                            dataFormat={value.dataFormat}
                            columnClassName={value.columnClassName}
                            dataSort={true}
                            columnTitle={value.toolTip}
                        >
                            {" "}
                            {value.header}
                        </TableHeaderColumn>
                    );
                })}
            </BootstrapTable>
        ) : (
            <OverlayLoader show={this.props.show} text={"Loading, please wait..."} />
        );

        return <div>{modalBody}</div>;
    }

    defaultType(cell: any, row: any) {
        return cell;
    }

    actedOnType(cell: any, row: any) {
        return GetFormatedDateTime(cell);
    }

    createTotalPages(start: number, to: number, total: number) {
        return (
            <p>
                Showing {start} to {to} of {total} entries
            </p>
        );
    }

    eventIdType(cell: any, row: any) {
        return UserEventValue[cell];
    }
}
