import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { Toaster } from '../components/helper/VenusNotifier'
import CompanyAssignments from '../components/assignments/CompanyAssignmentsContainer'
import ArchiveReturns from '../components/reports/TempArchiveReturnsContainer'
import K1DeliveredStatus from '../components/reports/K1DeliveredStatusContainer'
import PermanentlyArchivedReturns from '../components/reports/ArchiveReturnsContainer'
import DeliveredReturns from '../components/reports/DeliveredReturnsContainer'
import SendGroupedReturns from '../components/reports/GroupedReturns/SendGroupedReturnsContainer'
import DeliveredGroupedReturns from '../components/reports/GroupedReturns/DeliveredGroupedReturns/DeliveredGroupedReturnsContainer'
import ArchivedGroupedReturns from '../components/reports/GroupedReturns/DeliveredGroupedReturns/ArchiveGroupedReturnsContainer'
import InUseReturns from '../components/reports/InUseReturnsContainer'
import RecycleReturns from '../components/reports/RecycleReturns/RecycleReturnsContainer'
import VoucherReminderReport from '../components/reports/VoucherReminderManagement/voucherReminderReportContainer'
import ClientInstructionContainer from '../components/settings/ClientInstructions/ClientInstructionContainer'
import GeneralSettings from '../components/settings/GeneralSettingsComponents/GeneralSettingsContainer'
import SecuritySettings from '../components/settings/SecurityComponents/SecurityContainer'
import SavedMessageContainer from '../components/settings/SavedMessage/SavedMessageContainer'
import Signatures from '../components/settings/SignaturteComponents/SignaturesContainer'
import VouchersAndInvoices from '../components/settings/VoucherAndInvoiceComponent/VoucherAndInvoiceContainer'
import BusinessReturnInstructionContainer from '../components/settings/BusinessReturnsContainer'
import FeatureDisabled from '../components/FeatureDisabled'
import AccountRegistered from '../components/settings/VoucherAndInvoiceComponent/AccountRegistered'
import RecycleGroupedReturns from '../components/reports/GroupedReturns/RecycleGroupedReturns/RecycleGroupedReturnsContainer'
import GroupedSettings from '../components/settings/GroupedSettings/GroupedSettingsContainer'
import InUseGroupedReturns from '../components/reports/GroupedReturns/InUseGroupedReturns/InUseGroupedReturnsContainer' 
import Login from '../components/Login'
import SigninOidc from '../oidcClient/SigninOidc'
import silentSigninOidc from '../oidcClient/silentSigninOidc'
import SignoutOidc from '../oidcClient/SignoutOidc'
import RestrictedAccess from '../components/RestrictedAccess'
import LoggedIn from './LoggedIn'
import LoggedOut from './LoggedOut'
import RoleBasedRoute from './RoleBasedRoute'
import PreLogin from '../components/PreLogin';
import LayoutStoreContainer from '../components/navigation/Layout/LayoutStoreContainer';
import ValidationDataLoaded from './ValidationDataLoaded';
import { Unauthorized } from '../components/Unauthorized';
import CCHReturns from '../components/reports/CCHReturnsContainer'
import AdminUserRoute from './AdminUserRoute'

export const Routes = () => (
    <>
        <LoggedIn>
            <LayoutStoreContainer>
                <ValidationDataLoaded>
                    <Switch>
                        <Route path="/restricted-access" component={RestrictedAccess} />
                        <Route exact path="/FeatureDisabled" component={FeatureDisabled} />
                        <RoleBasedRoute exact path={['/', '/Home', '/CompanyAssignments']} component={CompanyAssignments} />
                        <RoleBasedRoute path="/DeliveredReturns" component={DeliveredReturns} />
                        <RoleBasedRoute path="/InUseReturns" component={InUseReturns} />
                        <RoleBasedRoute path="/ArchiveReturns" component={ArchiveReturns} />
                        <RoleBasedRoute path="/PermanentlyArchivedReturns" component={PermanentlyArchivedReturns} />
                        <RoleBasedRoute path="/K1DeliveredStatus" component={K1DeliveredStatus} />
                        <RoleBasedRoute path="/RecycleReturns" component={RecycleReturns} />
                        <RoleBasedRoute path="/VoucherReminderManagement" component={VoucherReminderReport} /> 
                        <Route path="/AccountRegistered/" component={AccountRegistered} />
                        <RoleBasedRoute path="/K1Settings" component={BusinessReturnInstructionContainer} />
                        <RoleBasedRoute path="/ClientInstruction" component={ClientInstructionContainer} />
                        <RoleBasedRoute path="/GeneralSettings" component={GeneralSettings} />
                        <RoleBasedRoute path="/SavedMessages" component={SavedMessageContainer} />
                        <RoleBasedRoute path="/Signatures" component={Signatures} />
                        <RoleBasedRoute path="/VouchersAndInvoices" component={VouchersAndInvoices} />
                        <RoleBasedRoute path="/Security" component={SecuritySettings} />
                        <RoleBasedRoute path="/cchimport" component={CCHReturns} />
                        <Route path="/Unauthorized" component={Unauthorized} />
                        <Route path='/*'>
                            <Redirect to='/'/>
                        </Route>
                    </Switch>
                    <Toaster />
                </ValidationDataLoaded>
            </LayoutStoreContainer>
        </LoggedIn>

        <LoggedOut>
            <Switch>
                <Route path="/login" component={Login} />
                <Route path="/signin-oidc" component={SigninOidc} />
                <Route path="/silent-signin-oidc" component={silentSigninOidc} />
                <Route path="/signout-callback-oidc" component={SignoutOidc} />
                <Route path="*" component={PreLogin} />
            </Switch>
        </LoggedOut>
    </>
)
