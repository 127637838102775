import { connect } from "react-redux";
import { ApplicationState } from "../../store";

import * as Component from "./CompanyAssignments";
import * as UserProfileStore from "../../store/userManagement/UserProfileStore";
import * as CompanyAssignmentsStore from "../../store/assignments/CompanyAssignmentsStore";
import * as TaxDocumentStore from "../../store/common/TaxDocumentStore";
import * as UserStore from "../../store/userManagement/UserStore";
import * as PdfStore from "../../store/pdf/PdfStore";
import * as CompanyStore from "../../store/company/CompanyStore";
import * as UserSettingStore from "../../store/userManagement/UserSettingStore";
import * as MailingReturnAddressStore from "../../store/common/MailingReturnAddressStore";
import * as Upload from "../../store/common/Upload";
import * as SavedMessageStore from "../../store/settings/SavedMessageStore";
import * as UploadStore from "../../store/common/Upload";
import * as DeliveredReturnsStore from "../../store/reports/DeliveredReturnsStore";
import * as AttachmentsStore from "../../store/ProcessReturn/AttachmentsStore";
import * as DownloadStore from "../../store/common/Download";
import * as GroupedTaxDocumentStore from "../../store/groupedreturns/GroupedTaxDocumentStore";
import * as UserSignatureStore from "../../store/common/UserSignatureStore";
import * as VoucherStore from "../../store/ProcessReturn/voucher/VoucherStore";
import * as UploadTaxReturnStore from "../../store/common/uploadTaxReturn";
import * as BusinessReturnsStore from "../../store/settings/BusinessReturnsStore";
import * as AttachmentInstructionStore from "../../store/settings/AttachmentInstructionStore";
import * as FilterStore from "../../store/reports/FilterStore";
import * as AdditionalEsignDocumentStore from "../../store/ProcessReturn/AdditionalEsignDocuments/AdditionalEsignDocumentStore";
import * as AdditionalDocumentTypeStore from "../../store/common/AdditionalDocumentTypeStore";
import * as WatermarkStore from "../../store/settings/WatermarkStore";
import * as GroupedReturnStore from "../../store/groupedreturns/GroupedReturnStore";
import * as CustomColumnStore from "../../store/common/CustomColumnStore";
import * as BulkOperationsStore from "../../store/common/BulkOperationsStore";
import * as PaperFileStore from "../../store/ProcessReturn/PaperFileStore";
import * as PaperFileMessageStore from "../../store/settings/PaperFiledMessageStore";
import * as K1AttachmentStore from "../../store/ProcessReturn/K1AttachmentStore";
import * as SSRSettingsStore from "../../store/company/settings/SSRSettingsStore";
import * as OfficeLocationStore from "../../store/common/OfficeLocationStore";
import * as SMSNotificationDetailsStore from "../../store/common/SMSNotificationDetailsStore";
import * as SuiteClientManagement from "../../store/company/SuiteClientManagementSettingStore";
import * as K1SSNStore from "../../store/ProcessReturn/K1SSNStore";
import * as MyAccountStore from "../../store/userManagement/MyAccountStore";
import * as GroupedReturnProcessStore from "../../store/groupedreturns/GroupedReturnProcessStore";
import * as SendGroupedReturnsStore from "../../store/reports/GroupedReturns/SendGroupedReturnsStore"
import * as ApplicationHelptextStore from '../../store/common/ApplicationHelptextStore';

export default connect(
    (state: ApplicationState) => ({
        auth: state.auth,
        assignments: state.companyAssignments,
        profile: state.userProfile,
        userDetails: state.userDetails,
        taxDocuments: state.taxDocuments,
        userStore: state.ssrUserList,
        ssrUsers: state.ssrUsers,
        company: state.companyData,
        pdfDocuments: state.pdfDocuments,
        attachments: state.attachments,
        userSettings: state.userSettings,
        uploadStore: state.UploadStore,
        savedMessages: state.savedMessage,
        mailingReturnAddresses: state.mailingReturnAddress,
        deliveredReturns: state.deliveredReturns,
        downloadStore: state.DownloadStore,
        userSignatures: state.userSignatures,
        voucherStore: state.voucherStore,
        uploadTaxReturnStore: state.UploadTaxReturnStore,
        businessReturnInstructions: state.businessReturnInstructions,
        customAuthorities: state.customAuthority,
        attachmentInstructionList: state.attachmentInstruction,
        reportFilters: state.reportFilters,
        additionalEsignDocument: state.additionalEsignDocument,
        additionalDocumentTypeData: state.additionalDocumentType,
        watermarkStore: state.watermarkStore,
        customColumnState: state.customColumn,
        groupedReturnStore: state.groupedReturnStore,
        paperFileStore: state.paperFile,
        paperFileMessage: state.paperFiledMessage,
        k1AttachmentStore: state.k1Attachment,
        userResources: state.userResources,
        taxSoftwareSetting: state.taxSoftwareSetting,
        officeLocation: state.officeLocation,
        smsNotificationDetails: state.smsNotificationDetails,
        clientManagementSetting: state.clientManagementSetting,
        k1SSNDetails: state.k1SSNDetails,
        pageWiseSSNDetails: state.pageWiseSSNDetails,
        helpTextState: state.Helptexts,
        userAssignDeliveryPermission: state.userAssignDeliveryPermission
    }),
    {
        ...CompanyAssignmentsStore.actionCreators,
        ...UserProfileStore.actionCreators,
        ...TaxDocumentStore.actionCreators,
        ...GroupedTaxDocumentStore.actionCreators,
        ...FilterStore.actionCreators,
        ...UserStore.actionCreators,
        ...CompanyStore.actionCreators,
        ...PdfStore.actionCreators,
        ...AttachmentsStore.actionCreators,
        ...UserSettingStore.actionCreators,
        ...MailingReturnAddressStore.actionCreators,
        ...Upload.actionCreators,
        ...UploadStore.actionCreators,
        ...SavedMessageStore.actionCreators,
        ...DeliveredReturnsStore.actionCreators,
        ...DownloadStore.actionCreators,
        ...UserSignatureStore.actionCreators,
        ...VoucherStore.actionCreators,
        ...UploadTaxReturnStore.actionCreators,
        ...BusinessReturnsStore.actionCreators,
        ...AttachmentInstructionStore.actionCreators,
        ...AdditionalEsignDocumentStore.actionCreators,
        ...AdditionalDocumentTypeStore.actionCreators,
        ...WatermarkStore.actionCreators,
        ...CustomColumnStore.actionCreators,
        ...GroupedReturnStore.actionCreators,
        ...BulkOperationsStore.actionCreators,
        ...PaperFileStore.actionCreators,
        ...PaperFileMessageStore.actionCreators,
        ...K1AttachmentStore.actionCreators,
        ...SSRSettingsStore.actionCreators,
        ...OfficeLocationStore.actionCreators,
        ...SMSNotificationDetailsStore.actionCreators,
        ...SuiteClientManagement.actionCreators,
        ...K1SSNStore.actionCreators,
        ...MyAccountStore.actionCreators,
        ...GroupedReturnProcessStore.actionCreators,
        ...SendGroupedReturnsStore.actionCreators,
        ...ApplicationHelptextStore.actionCreators

    }
)(Component.CompanyAssignments as any);
