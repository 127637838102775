import { addTask } from 'domain-task';
import { Reducer } from 'redux';
import { AppThunkAction } from '../';
import { initialUserModel, IUserProfileModel, IPasswordUpdateModel, IPasswordPolicySettings } from '../../components/navigation/profile/ProfileObjects';
import { actionTypes } from '../../types/ActionTypes';
import { SSSUITE_API_BASE_URL } from '../../utils/constants';
import { handleResponse } from '../Library';


interface UpdateUserDetailsAction {
    type: actionTypes.UPDATE_USER_DETAILS;
    userDetails: IUserProfileModel;
}
interface RequestUserProfileAction {
    type: actionTypes.REQUEST_USER_DETAILS;
}
export interface ReceiveUserProfileAction {
    type: actionTypes.RECEIVE_USER_DETAILS;
    user: IUserProfileModel;
}
interface UpdatePasswordAction {
    type: actionTypes.UPDATE_USER_PASSWORD;
}

type KnownAction = RequestUserProfileAction | ReceiveUserProfileAction | UpdateUserDetailsAction | UpdatePasswordAction;

type DispatchActions = KnownAction;

export const actionCreators = {
    requestUserDetails: (reload: boolean = false): AppThunkAction<KnownAction> => (dispatch, getState) => {
        if (reload || !getState().userProfile.email) {
            const fetchTask = fetch(SSSUITE_API_BASE_URL + 'api/user-management/user-details', {
                method: 'GET',
                credentials: 'include'
            })
                .then(handleResponse)
                .then(response => response as Promise<IUserProfileModel>)
                .then(data => {
                    dispatch({ type: actionTypes.RECEIVE_USER_DETAILS, user: data });
                })
                .catch(function (error) {
                });
            addTask(fetchTask); // Ensure server-side prerendering waits for this to complete
            dispatch({ type: actionTypes.REQUEST_USER_DETAILS });
        }
    },

    updateMyAccount: (userInfo: IUserProfileModel, onResponse: (response: any, error: any) => void): AppThunkAction<KnownAction> => (dispatch, getState) => {

        let options: any = {
            method: 'PUT',
            credentials: "include",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=utf-8'
            },
            body: JSON.stringify(userInfo)
        };

        const fetchTask = fetch(SSSUITE_API_BASE_URL + 'api/user-management/user-details', options)
            .then(response => response.json())
            .then(response => {
                dispatch({
                    type: actionTypes.UPDATE_USER_DETAILS, userDetails: userInfo
                });
                if (onResponse) {
                    if (response == true)
                        onResponse(response, null);
                    else
                        onResponse(null, response);
                }
            }).catch(error => {
                if (onResponse) {
                    onResponse(null, error);
                }
            });
        addTask(fetchTask);
        dispatch({ type: actionTypes.REQUEST_USER_DETAILS });
    },

    updatePassword: (passwordUpdateModel: IPasswordUpdateModel, onResponse: (response: any, error: any) => void): AppThunkAction<KnownAction> => (dispatch, getState) => {

        let options: any = {
            method: 'PUT',
            credentials: "include",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=utf-8'
            },
            body: JSON.stringify(passwordUpdateModel)
        };

        const fetchTask = fetch(SSSUITE_API_BASE_URL + 'api/user-management/user-password', options)
            .then(response => response.json())
            .then(response => {
                dispatch({
                    type: actionTypes.UPDATE_USER_PASSWORD
                });
                if (onResponse) {
                    onResponse(response, null);
                }
            }).catch(error => {
                if (onResponse) {
                    onResponse(null, error);
                }
            });
        addTask(fetchTask);
    }, 

    getPasswordPolicySetting : (callback: (response: IPasswordPolicySettings) => void): AppThunkAction<KnownAction> => () => {
        fetch(`${SSSUITE_API_BASE_URL}api/settings/password-policy`, {
            method: "GET",
            credentials: "include",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
        .then(handleResponse)
        .then(response => response as Promise<IPasswordPolicySettings>)
        .then(data =>{
            if(callback){
                callback(data);
            }
        })
        .catch((error: any) => {
            console.log(error);
        });
    }
};

const unloadedState: IUserProfileModel = initialUserModel;

export const reducer: Reducer<IUserProfileModel> = (state = unloadedState, incomingAction) => {
    const action = incomingAction as DispatchActions;
    switch (action.type) {
        case actionTypes.REQUEST_USER_DETAILS:
        case actionTypes.UPDATE_USER_PASSWORD:
            return ({
                ...unloadedState
            }) as IUserProfileModel;
        case actionTypes.RECEIVE_USER_DETAILS:
            // Only accept the incoming data if it matches the most recent request. This ensures we correctly
            // handle out-of-order responses.
            if (action.user?.userId === state.userId || state.firstName === '') {
                return {
                    ...action.user,
                    fullName() { return action.user.firstName + ' ' + action.user.lastName; }
                } as IUserProfileModel;
            }
            else {
                return {
                    ...state,
                    fullName() { return this.firstName + ' ' + this.lastName; }
                } as IUserProfileModel;
            }
        case actionTypes.UPDATE_USER_DETAILS:
            let received: IUserProfileModel = { ...state };
            received = action.userDetails
            return received;
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            const exhaustiveCheck: never = action;
    }

    return state;
};
