import * as React from "react";
import { Card, Accordion, FormGroup, FormLabel, Col, FormControl } from "react-bootstrap";
import { ClientReturn, IPrefilledClientInfo } from "../../../common/TaxReturn";
import Select from "react-select";
import { IUserModel } from "../../../../Core/ViewModels/User/UserViewModel";
import { ITaxReturn, EngagementType, isJointFilingAllowed } from "../../TaxReturn";
import { PrepareTaxYear } from "../../../helper/HelperFunctions";
import { CheckBoxComponent } from "../../CheckBoxComponent";
import { ClientInfoRightSideCardType } from "../Tabs/TabClientInfo";
import EROSignerDropdown from "../../EROSignerDropdown";
import ClientInfoProperty from "../../../../components/common/ClientInfoEnum";
import { IDropdown } from "../../../../Core/Common/Dropdown";

interface SelectOptions {
    value: string;
    label: string;
}
export interface IReturnInformationProps {
    clientId: string;
    taxReturn: ITaxReturn;
    allSignerList: IUserModel[];
    selectedTaxYear: number;
    selectedEngagement: EngagementType;
    isAssignedToLoggedinUser?: boolean;
    handleChange: (item: ClientReturn, value: string) => void;
    isMarriedJointly: boolean;
    onChangeMJFCheckbox: (e: any) => void;
    partnerId: string;
    expandReturnInformation: boolean;
    onCardToggle: (cardType: ClientInfoRightSideCardType) => void;
    prefilledClientInfo?: IPrefilledClientInfo;
    resetPrefillInfo?: (clientInfoProperty: ClientInfoProperty | ClientReturn) => void;
    locationDropdown: IDropdown[];
    selectedLocationId: number;
    isGroupedReturn?: boolean;
}

export interface IReturnInformationStates {
    engagementType: SelectOptions[];
    open: boolean;
    isMarriedJoint: boolean;
    clientId: string;
    partnerId: string;
    taxReturnYear: number;
    locationId: number;
}

export class ClientReturnInfo extends React.Component<IReturnInformationProps, IReturnInformationStates> {
    state: IReturnInformationStates = {
        engagementType: [],
        open: true,
        isMarriedJoint: false,
        clientId: "",
        partnerId: "",
        taxReturnYear: 0,
        locationId: 0
    };

    UNSAFE_componentWillMount() {
        this.setState({
            clientId: this.props.clientId,
            partnerId: this.props.partnerId,
            taxReturnYear: this.props.taxReturn.taxYear,
            locationId: this.props.selectedLocationId
        });
    }

    componentDidUpdate(
        prevProps: Readonly<IReturnInformationProps>,
        prevState: Readonly<IReturnInformationStates>,
        snapshot?: any
    ): void {
        if (prevProps.partnerId !== this.props.partnerId) {
            this.setState({ partnerId: this.props.partnerId });
        }
        if (prevProps.selectedLocationId !== this.props.selectedLocationId) {
            this.setState({ locationId: this.props.selectedLocationId });
        }
    }

    getEngagementTypeDisplayName = (engagementType: EngagementType) => {
        let type: string = "";
        switch (engagementType) {
            case EngagementType.None:
                type = "None";
                break;
            case EngagementType.E1040:
                type = "1040";
                break;
            case EngagementType.E1120:
                type = "1120";
                break;
            case EngagementType.E1065:
                type = "1065";
                break;
            case EngagementType.E1120S:
                type = "1120S";
                break;
            case EngagementType.E1041:
                type = "1041";
                break;
            case EngagementType.E1040NR:
                type = "1040NR";
                break;
            case EngagementType.E990:
                type = "990T";
                break;
            case EngagementType.E990PF:
                type = "990PF";
                break;
            case EngagementType.E990EZ:
                type = "990EZ";
                break;
        }
        return type;
    };

    onClientIdChange = (event: any) => {
        this.setState({ clientId: event.target.value });
    };

    onClientIdBlur = (event: any) => {
        this.props.handleChange(ClientReturn.ClientId, event.target.value);
    };

    signerChange = (value: any) => {
        this.setState({ partnerId: value ? value.toString() : "0" });
        this.props.handleChange(ClientReturn.Signer, value ? value : "0");
        this.props.resetPrefillInfo && this.props.resetPrefillInfo(ClientReturn.Signer);
    };

    taxYearChange = (event: any) => {
        this.setState({ taxReturnYear: event.value });
        this.props.handleChange(ClientReturn.TaxYear, event.value);
    };

    onOfficeLocationChange = (event: any) => {
        const selectedValue = event ? event.value.toString() : 0;
        this.setState({ locationId: selectedValue }, () => {
            this.props.handleChange(ClientReturn.OfficeLocation, selectedValue);
            this.props.resetPrefillInfo && this.props.resetPrefillInfo(ClientReturn.OfficeLocation);
        });
    };

    prepareLocation = () => {
        return (
            this.props.locationDropdown &&
            this.props.locationDropdown.map((item) => {
                return {
                    value: item.value.toString(),
                    label: item.name
                };
            })
        );
    };

    public render() {
        let engType = [];
        for (let type in EngagementType) {
            if (isNaN(Number(type))) {
                if (!(type === EngagementType.None.toString()))
                    engType.push({ value: type?.toString(), label: this.getEngagementTypeDisplayName(EngagementType[type]) });
                else engType.push({ value: type?.toString(), label: type });
            }
        }

        let icon = this.state.open ? "fas fa-minus" : "fas fa-plus";
        return (
            <Accordion defaultActiveKey="0">
                <Card>
                    <Accordion.Toggle eventKey="0">
                        <Card.Header
                            style={{ padding: "10px" }}
                            onSelect={() => {
                                this.props.onCardToggle(ClientInfoRightSideCardType.ReturnInformationCard);
                            }}
                            onClick={() => this.setState({ open: !this.state.open })}
                        >
                            <Card.Title as="h3" style={{ marginBottom: "0px" }}>
                                <i className={icon}></i> Return Information
                            </Card.Title>
                        </Card.Header>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                        <Card.Body>
                            <FormGroup className="row">
                                <Col className="text-left padT08" sm={4} as={FormLabel}>
                                    Client ID
                                </Col>
                                <Col sm={8}>
                                    <FormControl
                                        placeholder="Client ID"
                                        value={this.state.clientId}
                                        onChange={this.onClientIdChange}
                                        data-test-auto="C65CB625-33B0-445E-9BB8-9B7B763D5301"
                                        onBlur={this.onClientIdBlur}
                                        disabled={!this.props.isAssignedToLoggedinUser}
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup className="row">
                                <Col className="text-left padT08" sm={4} as={FormLabel}>
                                    ERO / Signer
                                </Col>
                                <Col sm={8} className="zeroborderRadius customClassCC">
                                    <EROSignerDropdown
                                        id="ddlSigner"
                                        testId="C65CB625-33B0-445E-9BB9-9B7B763D5302"
                                        onChange={this.signerChange}
                                        selectedValue={this.state.partnerId}
                                        disabled={!this.props.isAssignedToLoggedinUser}
                                        className={
                                            this.props.prefilledClientInfo && this.props.prefilledClientInfo[ClientReturn.Signer]
                                                ? "prefilledField"
                                                : ""
                                        }
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup className="row">
                                <Col className="text-left padT08" sm={4} as={FormLabel}>
                                    Engagement Type
                                </Col>
                                <Col sm={8}>
                                    <Select
                                        disabled={true}
                                        name="form-field-engagement"
                                        value={this.props.selectedEngagement}
                                        options={engType}
                                        data-test-auto="1B5E2CCC-F4D5-4198-A82E-DD0397E4DAC1"
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup className="row">
                                <Col className="text-left padT08" sm={4} as={FormLabel}>
                                    Tax Year
                                </Col>
                                <Col sm={8}>
                                    <Select
                                        name="form-field-taxYear"
                                        value={this.props.taxReturn.taxYear.toString()}
                                        onChange={this.taxYearChange}
                                        options={PrepareTaxYear()}
                                        data-test-auto="F6251C24-A0A0-4013-A962-B38A5EB40C2D"
                                        clearable={false}
                                        disabled={true}
                                        data-toggle="dropdown"
                                        data-boundary="window"
                                    ></Select>
                                </Col>
                            </FormGroup>

                            {!this.props.isGroupedReturn ? (
                                <FormGroup className="row">
                                    <Col className="text-left padT08" sm={4} as={FormLabel}>
                                        Office Location
                                    </Col>
                                    <Col sm={8} className="customClassCC">
                                        <Select
                                            name="form-field-location"
                                            value={this.state.locationId}
                                            onChange={this.onOfficeLocationChange}
                                            options={this.prepareLocation()}
                                            data-test-auto="894C3CEB-4671-4529-A835-ECA0CCC9DD91"
                                            clearable={true}
                                            placeholder="Select..."
                                            className={
                                                this.props.prefilledClientInfo &&
                                                this.props.prefilledClientInfo[ClientReturn.OfficeLocation]
                                                    ? "prefilledField"
                                                    : ""
                                            }
                                        ></Select>
                                    </Col>
                                </FormGroup>
                            ) : (
                                <FormGroup className="row"></FormGroup>
                            )}
                            {isJointFilingAllowed(this.props.taxReturn) ? (
                                <FormGroup className="Row">
                                    <Col sm={12} className="nopadding-left">
                                        <CheckBoxComponent
                                            id={"isMarriedJointly"}
                                            text={"Married Filing Joint"}
                                            checked={this.props.isMarriedJointly}
                                            onChange={this.props.onChangeMJFCheckbox}
                                            datatestAuto={"E2DF135F-F2B4-4E7E-9F34-691CAF70E24D"}
                                        />
                                    </Col>
                                </FormGroup>
                            ) : (
                                <FormGroup className="row"></FormGroup>
                            )}
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        );
    }
}
