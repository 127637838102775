import * as React from 'react';
import * as Bs from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { SignatureStatus } from '../../common/TaxReturn';

interface DeliveredReportDropdownPros {
    title: string;
    rowIndex: number;
    signatureStatus: string;
    onDownloadEfileFormsOpen: (rowIndex: number) => void;
    onDownloadReturnOpen: (rowIndex: number) => void;
    onClientTrackingOpen: (rowIndex: number) => void;
    onRestoreReturnOpen: (rowIndex: number) => void;
}

export class ArchiveReturnsAction extends React.Component<DeliveredReportDropdownPros, {}> {
    public render() {
        let signatureStatus: any = SignatureStatus[SignatureStatus.ManuallySigned];
        let awaitingESignStatus: any = SignatureStatus[SignatureStatus.AwaitingESign];
        let awaitingUploadStatus: any = SignatureStatus[SignatureStatus.AwaitingUpload];
        let PartiallySignedStatus: any = SignatureStatus[SignatureStatus.PartiallySigned];

        return (<div>
            {
                this.props.signatureStatus == signatureStatus
                    || this.props.signatureStatus == awaitingESignStatus
                    || this.props.signatureStatus == awaitingUploadStatus
                    || this.props.signatureStatus == PartiallySignedStatus ?
                    null :
                    <button title={"Download e-file Forms"} type="button" className="btn-white"
                        onClick={() => this.props.onDownloadEfileFormsOpen(this.props.rowIndex)} >
                        <i className='fas fa-file-signature text-violet'></i></button>
            }

            <button title={"Download Returns"} type="button" className="btn-white btn-sm"
                onClick={() => this.props.onDownloadReturnOpen(this.props.rowIndex)}>
                <i className='fas fa-download text-grey'></i> </button>

            <button title={"Client Tracking"} type="button" className="btn-white btn-sm"
                onClick={() => this.props.onClientTrackingOpen(this.props.rowIndex)}>
                <i className='fas fa-user-clock text-success'></i> </button>

            <button title={"Restore Return"} type="button" className="btn-white btn-sm"
                onClick={() => this.props.onRestoreReturnOpen(this.props.rowIndex)}>
                <i className='fas fa-sync-alt text-danger'></i> </button>
        </div>);
    }
}