import * as React from 'react';
import { Card, Accordion } from 'react-bootstrap';

import { PageListView } from './PageListView';
import { IBookmarkSection } from './ProcessReturnModels';
import * as PdfStore from '../../../store/pdf/PdfStore';
import { AddPayment } from './LeftSidebar/AddPayment';
import { ITaxReturn, IVoucher, ITaxingAuthority, IEFile } from '../../common/TaxReturn';
import { IVoucherState } from '../../../store/ProcessReturn/voucher/VoucherStore';
import { ZeroAmountVoucherRemover } from './LeftSidebar/ZeroAmountVoucherRemover';
import { AddMultiplePayment } from '../../common/ProcessReturnModal/LeftSidebar/AddMultiplePayment'


export interface IBookMarksPaneProps {
    docId: number;
    title: string;
    sections: IBookmarkSection[];
    pdfDocuments: PdfStore.IPdfDocumentDictionary;
    onPageSelect: (pageNo: number, voucherNo: number, isPageSelectionFromBookMark?: boolean, isViewClientInfo?: boolean) => void;
    onPageDelete?: (pageNo: number) => void;
    getTitle?: (pageTitle: string, id: number) => void;
    tabType?: number;
    showAddIcon?: boolean;
    taxReturn?: ITaxReturn;
    onAddVoucher?: (voucher: IVoucher) => void;
    voucherStore?: IVoucherState;
    populateVoucherCard?: (voucher: IVoucher) => void;
    handleDeleteZeroAmountVoucher?: () => void;
    showDeleteZeroAmountVoucherButton?: boolean;
    zeroAmountVouchersCount?: number | 0;
    getAllTaxingAuthorities: ITaxingAuthority[];
    onAddMultipeVouchers?: (vouchers: IVoucher[]) => void;
    onUpdateAddedEfile?: (efile: IEFile, currentEFileClone: IEFile, taxingAuthority: ITaxingAuthority) => void;
    efile?: IEFile;
    showVoucherLabel?: boolean;
    isRevisionProcess?: boolean;
}


export class BookmarksPane extends React.Component<IBookMarksPaneProps, {}> {
    constructor(props: IBookMarksPaneProps) {
        super(props);
    }

    public render() {
        return <div className="bookmarksPane">

            {this.props.sections.map((section, i) => {
                return (
                    <Accordion key={i} defaultActiveKey="0">
                        <Card className="border-radius-0 marB05" style={{ clear: "both" }}>
                                <Card.Header style={{ padding: '10px' }}>
                                <Card.Title as="div" style={{ marginBottom: '0px' }} className="float-left">
                                    <Accordion.Toggle as="h3" eventKey="0">
                                            {section.heading}
                                        </Accordion.Toggle>
                                    </Card.Title>
                                    {
                                        this.props.showAddIcon &&
                                        <AddMultiplePayment
                                            onAddVoucher={this.props.onAddVoucher}
                                            taxReturn={this.props.taxReturn}
                                            voucherStore={this.props.voucherStore}
                                            populateVoucherCard={this.props.populateVoucherCard}
                                            getAllTaxingAuthorities={this.props.getAllTaxingAuthorities}
                                            onAddMultipeVouchers={this.props.onAddMultipeVouchers}
                                        />
                                    }

                                    {
                                        this.props.showDeleteZeroAmountVoucherButton &&
                                        <ZeroAmountVoucherRemover
                                            handleDeleteZeroAmountVoucher={this.props.handleDeleteZeroAmountVoucher}
                                            zeroAmountVouchersCount={this.props.zeroAmountVouchersCount}
                                        />
                                    }
                                </Card.Header>
                            <Accordion.Collapse eventKey="0">
                                <Card.Body style={{ padding: '0px' }}>
                                    <PageListView
                                        pages={section.pages}
                                        onPageSelect={this.props.onPageSelect}
                                        onPageDelete={this.props.onPageDelete}
                                        getTitle={this.props.getTitle}
                                        tabType={this.props.tabType}
                                        getAllTaxingAuthorities={this.props.getAllTaxingAuthorities}
                                        onUpdateAddedEfile={this.props.onUpdateAddedEfile}
                                        efile={this.props.efile}
                                        taxReturn={this.props.taxReturn}
                                        showVoucherLabel={this.props.showVoucherLabel}
                                        isRevisionProcess={this.props.isRevisionProcess}
                                    />
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    </Accordion>
                )
            })}
        </div>
    }
};
