export interface IColumnSettings {
    columnName: string;
    columnOrder: number;
    isActive: number;
}

export interface IColumnSettingsState {
    DeliveredReturnsColumnSettings: IColumnSettings[];
    ArchivedReturnsColumnSettings: IColumnSettings[];
    GroupReturnsColumnSettings: IColumnSettings[];
}

export enum ReportType {
    None = 0,
    MyAssignments = 1,
    AllAssignments = 2,
    Delivered = 3,
    InUseReturn = 4,
    Archived = 5,
    K1Delivery = 6,
    RecycleBin = 7,
    SendGroupedReturn = 8,
    GroupedReturnsReport = 9
}

export const ColumnSettingsInitialState: IColumnSettingsState = {
    DeliveredReturnsColumnSettings: [],
    ArchivedReturnsColumnSettings: [],
    GroupReturnsColumnSettings: []    
}