import { addTask } from 'domain-task';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../';
import { actionTypes } from '../../types/ActionTypes';
import { RequestDeliveredReturnsPagesAction, ReceiveDeliveredReturnsPagesAction } from "./KnownTypes";
import { IDeliveredTaxDocument, IDeliveredTaxReturns } from '../../components/common/DeliveredTaxReturns';
import { DeliveredReturnsState, DeliveredReturnsTableModel, IDeliveredReturnsDictionary } from './DeliveredReturnsState';
import {
    ReceiveTaxDocumentAction, ArchiveTaxDocumentAction, UpdateTaxDocumentCustomColumnValueAction,
    SendReminderTaxDocumentAction, RecallTaxDocumentAction, ResetDeliveredReturnsAction
} from '../common/TaxDocumentStore';
import { StatusType, NotificationAction } from '../common/NotificationStore';
import { handleResponse } from '../Library';
import { API_BASE_URL } from '../../utils/constants';

type KnownAction = RequestDeliveredReturnsPagesAction
    | ReceiveDeliveredReturnsPagesAction | ArchiveTaxDocumentAction | ReceiveTaxDocumentAction
    | RecallTaxDocumentAction | NotificationAction;

type DispatchAction = RequestDeliveredReturnsPagesAction | ResetDeliveredReturnsAction
    | ReceiveDeliveredReturnsPagesAction | ArchiveTaxDocumentAction | ReceiveTaxDocumentAction
    | RecallTaxDocumentAction | UpdateTaxDocumentCustomColumnValueAction | SendReminderTaxDocumentAction;

export const actionCreators = {
    requestDeliveredReturns: (query: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        if (!getState().deliveredReturnsPages[query]) {
            const fetchTask = fetch(API_BASE_URL + 'api/Reports/DeliveredReturns/GetDeliveredReturns' + query, {
                method: 'GET',
                credentials: 'include'
            })
                .then(handleResponse)
                .then(response => response as Promise<DeliveredReturnsTableModel>)
                .then(data => {
                    dispatch({ type: actionTypes.RECEIVE_DELIVERED_RETURNS_PAGES, query: query, table: data, totalRowCount: data.count });
                })
                .catch((error) => {
                    const statusMessage: any = error.statusText?.message ?? error.statusText;
                    if (typeof(statusMessage) === "string") {
                        dispatch({ type: actionTypes.NOTIFICATION, statusMessage: statusMessage, statusType: StatusType.Error });
                    }
                });
            addTask(fetchTask);
            dispatch({ type: actionTypes.REQUEST_DELIVERED_RETURNS_PAGES, query: query });
        }
    }
};

const unloadedState: DeliveredReturnsState = {
    deliveredReturnTableModel: {},
    query: '?',
    loading: false,
    totalRowCount: 0
} as DeliveredReturnsState;

const unloadedDeliveredTaxDocument: IDeliveredTaxDocument = {
    customColumn: '',
    document: {},
    downloadsCount: 0,
    lastReminderOn: undefined,
    retentionPeriod: undefined,
    signedCount: 0,
} as IDeliveredTaxDocument;

export const reducer: Reducer<IDeliveredReturnsDictionary> = (state = { "?": unloadedState }, incomingAction) => {
    const action = incomingAction as DispatchAction;
    switch (action.type) {
        case actionTypes.REQUEST_DELIVERED_RETURNS_PAGES:
            if (!state[action.query]) {
                let added = { ...unloadedState } as DeliveredReturnsState;
                added.loading = true;
                added.query = action.query;
                let item = {} as IDeliveredReturnsDictionary;
                item[action.query] = added;

                return {
                    ...state,
                    ...item
                };
            }
            break;

        case actionTypes.RECEIVE_DELIVERED_RETURNS_PAGES:
            // Only accept the incoming data if it matches the most recent request. This ensures we correctly
            // handle out-of-order responses.
            if (state[action.query]) {
                let changed = state[action.query] as DeliveredReturnsState;
                changed.loading = false;
                changed.deliveredReturnTableModel = action.table;
                changed.totalRowCount = action.totalRowCount;
                let item = {} as IDeliveredReturnsDictionary;
                item[action.query] = changed;
                return {
                    ...state,
                    ...item
                };
            }
            break;

        case actionTypes.ARCHIVE_TAX_DOCUMENT:
        case actionTypes.RECALL_TAX_DOCUMENT:
        case actionTypes.RESET_DELIVERED_RETURNS:
            return clearTaxReturns();

        case actionTypes.RECEIVE_TAX_DOCUMENT:
        case actionTypes.UPDATE_DOCUMENT_CUSTOM_COLUMN_VALUE:
        case actionTypes.SEND_REMINDER_TAX_DOCUMENT:
            return updateState(action.type, state, action);

        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            const exhaustiveCheck: never = action;
    }
    return state;
};

function updateState(type: actionTypes, state: IDeliveredReturnsDictionary, action: DispatchAction): IDeliveredReturnsDictionary {
    let i: number = -1;
    let newState = {} as IDeliveredReturnsDictionary;
    let oldDocument: IDeliveredTaxDocument = unloadedDeliveredTaxDocument;
    let document: IDeliveredTaxReturns = {} as IDeliveredTaxReturns;
    let customColumn: string = "";
    let id: number = 0;
    switch (action.type) {
        case actionTypes.RECEIVE_TAX_DOCUMENT:
            document = action.taxDocument as IDeliveredTaxReturns;
            id = action.id;
            break;
        case actionTypes.UPDATE_DOCUMENT_CUSTOM_COLUMN_VALUE:
            customColumn = action.customColumn;
            id = action.id;
            break;
        case actionTypes.SEND_REMINDER_TAX_DOCUMENT:
            id = action.id;
            break;
    }

    for (var query in state) {
        i = -1;
        if (state[query].deliveredReturnTableModel.documents) {
            state[query].deliveredReturnTableModel.documents.forEach((value, index) => {
                if (value.document.id === id) {
                    i = index;
                    oldDocument = value;
                    return;
                }
            });
        }
        if (i !== -1) {
            let deliveredTaxDocument: IDeliveredTaxDocument = {
                document: action.type == actionTypes.RECEIVE_TAX_DOCUMENT ? document : oldDocument.document,
                customColumn: action.type == actionTypes.UPDATE_DOCUMENT_CUSTOM_COLUMN_VALUE ? customColumn : oldDocument.customColumn,
                downloadsCount: oldDocument.downloadsCount,
                bulkDownloadCount: oldDocument.bulkDownloadCount,
                signedCount: oldDocument.signedCount,
                lastReminderOn: action.type == actionTypes.SEND_REMINDER_TAX_DOCUMENT ? new Date() : oldDocument.lastReminderOn,
                retentionPeriod: oldDocument.retentionPeriod,
                lockType: oldDocument.lockType,
                clientGuid: oldDocument.clientGuid,
                isDocumentLocked: oldDocument.isDocumentLocked,
                documentFilingType: oldDocument.documentFilingType,
                hasRevisionDocumentReady: oldDocument.hasRevisionDocumentReady,
                hasUploadedRevisionDocument: oldDocument.hasUploadedRevisionDocument,
                hasRevisionDocumentMerged: oldDocument.hasRevisionDocumentMerged,
            };

            let documents = [
                ...state[query].deliveredReturnTableModel.documents?.slice(0, i),
                deliveredTaxDocument,
                ...state[query].deliveredReturnTableModel.documents?.slice(i + 1)];
            let deliveredReturnTableModel: DeliveredReturnsTableModel = {
                count: state[query].deliveredReturnTableModel.count,
                documents: documents
            }
            newState[query] = {
                query: query,
                deliveredReturnTableModel: deliveredReturnTableModel,
                totalRowCount: state[query].totalRowCount,
                loading: false
            };
        }
        else {
            newState[query] = {
                query: query,
                deliveredReturnTableModel: state[query].deliveredReturnTableModel,
                totalRowCount: state[query].totalRowCount,
                loading: false
            };
        }
    }
    return newState;
}

function clearTaxReturns(): IDeliveredReturnsDictionary {
    return {} as IDeliveredReturnsDictionary;   //clearing the dictionary on archive/recall
}