import { addTask } from 'domain-task';
import { AppThunkAction } from '../';
import { API_BASE_URL } from '../../utils/constants';
import { handleResponse } from '../Library';
import {IControllerInfo} from "../../store/groupedreturns/GroupedReturnProcessState";
import { actionTypes } from '../../types/ActionTypes';
import { StatusType, NotificationAction } from '../common/NotificationStore';
import { HideLoader } from '../../components/helper/Loader';
import {  openWindowWithPostRequest } from '../../components/helper/HelperFunctions';
import { getRequestVerificationToken } from '../../oidcClient/helpers'
import {  PreviewConstants } from '../../components/helper/Constants';
import { ISignerModel } from '../../components/common/TaxReturn';
type KnownAction =   
    NotificationAction;

export const actionCreators = {
requestControllerInfo: (id: number, callback?: (data:IControllerInfo) => void,resourceId:string=""): AppThunkAction<KnownAction> => (dispatch, getState) => {
    const fetchTask = fetch(API_BASE_URL + 'api/tax-return-action-handler/controller-info?groupId=' + id, {
        method: 'GET',
        credentials: 'include',
        headers:{
            'X-Resource-Id':resourceId,
        }
    })
        .then(handleResponse)
        .then(response => response as IControllerInfo)
        .then((response) => {
            if (callback) {
                callback(response);
            }
        })
        .catch(error => {
            const statusMessage: any = error.statusText?.message ?? error.statusText;
            if (typeof(statusMessage) === "string") {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: statusMessage,
                    statusType: StatusType.Error
                });
            }
        });
    addTask(fetchTask);
},
requestGroupedReturnScreenShareUrl: (clientGuid: string, controllerGuid?: string,resourceId:string=""): AppThunkAction<KnownAction> => (dispatch, getstate) => {
		
    const fetchTask = fetch(API_BASE_URL + 'api/Common/GetGroupedReturnScreenShareUrl/' + clientGuid, {
        method: 'GET',
        credentials: 'include',
        headers:{
            'X-Resource-Id':resourceId,
        }
    })
        .then(response => response.json())
        .then(data => {
            HideLoader();
            openWindowWithPostRequest(data.url, getRequestVerificationToken(), 'CPAToken', PreviewConstants.Scope.ClientView, controllerGuid, getstate().userProfile?.userId);
        })
        .catch((error: any) => {
            console.log(error);
        });

    addTask(fetchTask);
},
requestSignedDetailsForGroupedReturn:
        (id: number, callback?: (data:ISignerModel[]) => void, resourceId: string = ""): AppThunkAction<KnownAction> =>
        (dispatch, getState) => {
            const fetchTask = fetch(API_BASE_URL + "api/TaxDocument/GetSignedDetailsAsync/" + id, {
                method: "GET",
                credentials: "include",
                headers: { "X-Resource-Id": resourceId }
            })
                .then(handleResponse)
                .then((response) => response as Promise<ISignerModel[]>)
                .then((data) => {
                      if (callback) {
                        callback(data);
                    }
                })
                .catch((error) => {
                    
                });
            addTask(fetchTask);
},
}