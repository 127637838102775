import * as React from 'react';
import { Popover, OverlayTrigger, Modal, Button, ListGroup, Row, Col, Table } from 'react-bootstrap';
import { IModalProps } from './ProfileObjects'
import moment from "moment";
import { SignatureStatus, EngagementType, DocumentFilingType, IOfficeLocation } from '../../../components/common/TaxReturn';
import { BulkOperationQuery } from '../../../Core/ViewModels/Common/BulkOperationQuery';
import { ReportFilterType } from '../../reports/Filters';
import * as Helper from '../../helper/HelperFunctions';
import {  SetAccessType } from '../../../store/reports/GroupedReturns/SendGroupedReturnsState';

import {
    DocumentStatus,
    engagementType
} from '../../common/TaxReturn';
import { userSettingConstants } from '../../helper/Constants';



export interface IDownloadedZipFilesModel {
    Id: number;
    JobId: string;
    CreatedBy: number;
    CreatedUser: number;
    RequestFilters: BulkOperationQuery;
    FileName: string;
    CreatedOn: any;
    FileSize: any;
    Status: StatusZipDownload;
    UpdatedOn: any;
    TotalDocumentsCount: number;
    DownloadSuccessDocumentsCount: number;
}

export interface IBulkRequestFilters {
    SearchText?: string;
    TaxYear?: string;
    Name?: string;
    ClientId?: string;
    Partner?: string;
    Status?: string;
    DeliveredOn?: any;
    EngagementType: number;
    CustomColumn?: string;
    DownloadedBy?: string;
    LastReminderOn?: any;
    RetentionDate?: any;
    SentBy?: string;
    IsArchived?: string;
    UnSelectedIds?: any[];
    documentFilingType?: number; 
}

export enum StatusZipDownload {
    None = 0,
    InProgress = 1,
    Ready = 2,
    Error = 3,
    PartialReady = 4
}

const listStyle = { marginBottom: "4px", listStyleType: "none", borderBottom: " 1px dotted lightgrey", marginTop: "16px" };
const listLastLineStyle = { marginBottom: "4px", listStyleType: "none", marginTop: "16px" };


interface MyDownloadsProps extends IModalProps {
    downloadsList: IDownloadedZipFilesModel[],
    downloadReturnFromPopup: (jobId: string, fileName: string, isDownloadNow?: boolean, callback?: (url: string) => void) => void;
    deleteMyDownloads: (jobId: string) => void;
    clearAllMyDownloads: () => void;
    showMyDownloads: boolean;
}

interface MyDownloadsState {
    row: IDownloadedZipFilesModel[];
    collapsibleRowId: number;
    LocationList: any[];
}

export class MyDownload extends React.Component<MyDownloadsProps, MyDownloadsState> {
    state: MyDownloadsState = {
        collapsibleRowId: -1,
        row: [],
        LocationList: []
    }

    UNSAFE_componentWillReceiveProps(newProps: MyDownloadsProps) {
        this.setState({ row: newProps.downloadsList });
    }

    UNSAFE_componentWillMount() {
        Helper.fetchUserOfficeLocation(this.setOfficeLocation);
    }

    rowToggleOpen = (event: any) => {
        if (event.target.nextElementSibling.style['display'] == "none") {
            event.target.style['display'] = "none";
            event.target.nextElementSibling.style['display'] = "block";
            event.target.parentElement.parentElement.nextElementSibling.style['display'] = 'block'
        } else {
            event.target.style['display'] = "block";
            event.target.nextElementSibling.style['display'] = "none";
            event.target.parentElement.parentElement.nextElementSibling.style['display'] = 'none'
        }
    }

    rowToggleClose = (event: any) => {
        if (event.target.previousElementSibling.style['display'] == "none") {
            event.target.style['display'] = "none";
            event.target.previousElementSibling.style['display'] = "block";
            event.target.parentElement.parentElement.nextElementSibling.style['display'] = 'none'
        } else {
            event.target.style['display'] = "block";
            event.target.previousElementSibling.style['display'] = "none";
            event.target.parentElement.parentElement.nextElementSibling.style['display'] = 'block'
        }
    }

    onDownloadClick(jobId: string, fileName: string) {
        this.props.downloadReturnFromPopup(jobId, fileName);
    }


    formatBytes = (bytes: number, decimals: number = 2) => {
        if (bytes === 0) return '0 Bytes';
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    deleteSelectedDownload = (event: any, jobId: string) => {
        let node = event.target.parentElement.previousElementSibling.previousElementSibling;
        if (node.childNodes[0].style["display"] == "none") {
            node.childNodes[1].style["display"] = "none";
            node.childNodes[0].style["display"] = "block";
            node.parentElement.nextElementSibling.style["display"] = "none"
        }
        this.props.deleteMyDownloads(jobId);
    }

    getDownloadList = () => {
        let rowCollection: any = [];
        let filtersApplied: any = [];

        for (var i = 0; i < this.state.row.length; i++) {
            let tmpForm: any = this.state.row[i];


            //{ tmpForm.requestFilters.searchText != "" && filtersApplied.push({ 'filtername': "SearchText", 'value': tmpForm.requestFilters.searchText }) }
            { Helper.checkIfValueExists(tmpForm.requestFilters.name) && filtersApplied.push({ 'filtername': "Name", 'value': decodeURIComponent(tmpForm.requestFilters.name) }) }
            { Helper.checkIfValueExists(tmpForm.requestFilters.clientId) && filtersApplied.push({ 'filtername': "Client Id", 'value': tmpForm.requestFilters.clientId }) }
            { Helper.checkIfValueExists(tmpForm.requestFilters.engagementType) && filtersApplied.push({ 'filtername': "Return Type", 'value': this.getEngagementType(tmpForm.requestFilters.engagementType) }) }
            { Helper.checkIfValueExists(tmpForm.requestFilters.taxYear) && filtersApplied.push({ 'filtername': "Tax Year", 'value': this.getTaxYear(tmpForm.requestFilters.taxYear) }) }
            { Helper.checkIfValueExists(tmpForm.requestFilters.partner) && filtersApplied.push({ 'filtername': "ERO/Signer", 'value': decodeURIComponent(tmpForm.requestFilters.partner) }) }
            { Helper.checkIfValueExists(tmpForm.requestFilters.sentBy) && filtersApplied.push({ 'filtername': "Sent By", 'value': decodeURIComponent(tmpForm.requestFilters.sentBy) }) }
            { Helper.checkIfValueExists(tmpForm.requestFilters.deliveredOn) && filtersApplied.push({ 'filtername': "Delivered Date", 'value': moment(tmpForm.requestFilters.deliveredOn).format("MM/DD/YYYY").toString() + " - " + moment.utc(tmpForm.createdOn).local().format('MM/DD/YYYY').toString() }) }
            { Helper.checkIfValueExists(tmpForm.requestFilters.status) && filtersApplied.push({ 'filtername': "Status", 'value': this.getStatus(tmpForm.requestFilters.status, tmpForm.requestFilters.reportType) }) }
            { Helper.checkIfValueExists(tmpForm.requestFilters.officeLocation) && filtersApplied.push({ 'filtername': "Office Location", 'value': this.getLocation(tmpForm.requestFilters.officeLocation) }) }
            { Helper.checkIfValueExists(tmpForm.requestFilters.lastReminderOn) && filtersApplied.push({ 'filtername': "Last Reminder", 'value': moment(tmpForm.requestFilters.lastReminderOn).format("MM/DD/YYYY").toString() }) }
            { Helper.checkIfValueExists(tmpForm.requestFilters.customColumn) && filtersApplied.push({ 'filtername': "Custom Column", 'value': tmpForm.requestFilters.customColumn }) }
            { Helper.checkIfValueExists(tmpForm.requestFilters.downloadedBy) && filtersApplied.push({ 'filtername': "Downloaded", 'value': this.getDownloadOptions(tmpForm.requestFilters.downloadedBy) }) }
            { Helper.checkIfValueExists(tmpForm.requestFilters.retentionPeriod) && filtersApplied.push({ 'filtername': "Deletion Date", 'value': moment(tmpForm.requestFilters.retentionPeriod).format("MM/DD/YYYY").toString() }) }
            { Helper.checkIfValueExists(tmpForm.requestFilters.uploadedOn) && filtersApplied.push({ 'filtername': "Uploaded On", 'value': moment(tmpForm.requestFilters.uploadedOn).format("MM/DD/YYYY").toString() }) }
            { Helper.checkIfValueExists(tmpForm.requestFilters.assignedTo) && filtersApplied.push({ 'filtername': "Assigned To", 'value': decodeURIComponent(tmpForm.requestFilters.assignedTo) }) }
            { Helper.checkIfValueExists(tmpForm.requestFilters.documentFilingType) && filtersApplied.push({ 'filtername': "Filing Type", 'value': this.getFilingType(tmpForm.requestFilters.documentFilingType) }) }
            { Helper.checkIfValueExists(tmpForm.requestFilters.quickReportStatus) && filtersApplied.push({ 'filtername': this.getQuickReportColumnName(tmpForm.requestFilters.reportType), 'value': this.getQuickReportFilterValues(tmpForm.requestFilters.reportType,tmpForm.requestFilters.quickReportStatus) }) }
            { Helper.checkIfValueExists(tmpForm.requestFilters.groupName) && filtersApplied.push({ 'filtername': "Group Name", 'value': tmpForm.requestFilters.groupName }) }
            { Helper.checkIfValueExists(tmpForm.requestFilters.createdBy) && filtersApplied.push({ 'filtername': "Created By",  'value':  tmpForm.requestFilters.createdBy }) }
            { Helper.checkIfValueExists(tmpForm.requestFilters.createdDate) && filtersApplied.push({ 'filtername': "Created Date", 'value': moment(tmpForm.requestFilters.createdDate).format("MM/DD/YYYY").toString() + " - " + moment.utc(tmpForm.createdOn).local().format('MM/DD/YYYY').toString() }) }
            { Helper.checkIfValueExists(tmpForm.requestFilters.globalSearch) && filtersApplied.push({ 'filtername': "Global Search", 'value': tmpForm.requestFilters.globalSearch }) }
            { Helper.checkIfValueExists(tmpForm.requestFilters.setAccess) && filtersApplied.push({ 'filtername': "Set Access", 'value': SetAccessType[tmpForm.requestFilters.setAccess] }) }
            { Helper.checkIfValueExists(tmpForm.requestFilters.taxSoftware) && filtersApplied.push({ 'filtername': "Tax Software", 'value': tmpForm.requestFilters.taxSoftware }) }

            rowCollection.push(<li
                style={i == (this.state.row.length - 1) ? listLastLineStyle : listStyle}>
                <Row>
                    <Col lg={2} md={2} sm={2}>
                        <span className={this.getFileFormatIcon(tmpForm.fileName)}></span>
                    </Col>
                    <Col lg={5} md={5} sm={5} className="nopadding">
                        <div>
                            {this.getItems(filtersApplied)}
                            {filtersApplied.length > 3 && this.getMouseHoverItems(filtersApplied)}
                        </div>
                    </Col>
                    <Col lg={2} md={2} sm={2}>
                        <span title="Expand"
                            onClick={this.rowToggleOpen}
                            className="fa fa-chevron-down my-download-row-toggle">
                        </span>
                        <span title="Collapse"
                            onClick={this.rowToggleClose}
                            className="fa fa-chevron-up my-download-row-toggle"
                            style={{ display: 'none' }}>
                        </span>
                    </Col>
                    <Col lg={2} md={2} sm={2}>

                        {this.getDownloadStatus(tmpForm)}

                    </Col>
                    <Col lg={1} md={1} sm={1}>
                        {!(tmpForm.status == StatusZipDownload[StatusZipDownload.InProgress]) ?
                            <span className="my-download-delete fa fa-times" aria-hidden="true"
                                onClick={(event) => this.deleteSelectedDownload(event, tmpForm.jobId)}
                                title="Remove from list"
                                data-test-auto="E2A8DF59-2157-41ED-87FF-9A562368C9F9">
                            </span>
                            : null
                        }
                    </Col>
                </Row>
                <Row id={this.state.collapsibleRowId.toString()}
                    className="marT10"
                    style={{ display: 'none' }}>
                    <Col>
                        <div className="my-download-expand">
                            <span className="text-bold"> {tmpForm.fileName} </span>
                            <span style={{ color: "dark-grey" }}> | </span>

                            <span> Date :</span>
                            <span className="text-bold"> {moment.utc(tmpForm.createdOn).local().format('MM/DD/YYYY HH:mm')} </span>

                            <span style={{ color: "dark-grey" }}> | </span>
                            <span> File Size : </span>
                            <span className="text-bold"> {this.formatBytes(tmpForm.fileSize)} </span>
                        </div>
                    </Col>
                </Row>
                <br />
            </li >)

            filtersApplied = [];
        }

        return rowCollection.length > 0 ? rowCollection : <div className="txt-ac"> No data </div>
    }

    private getItems(filters: any) {
        if (filters.length == 0) {
            return (<div style={{ fontSize: "12px" }}><span className="list-sub-title">No Filter Applied </span> <br /></div>);
        }
        else {
            return (
                <div>
                    <Table bordered={false}>
                        <tbody>
                            {filters.map((item: any, _index: any) => {
                                if (_index < 3) {
                                    return (
                                        <tr className="my-download-filter">
                                            <td>
                                                <div className="my-download-filter-row">
                                                    <span>{item.filtername}</span>
                                                    <span title={item.value}
                                                        className="ellipsis text-bold">
                                                        <span> : </span>
                                                        {item.value}
                                                    </span>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                }
                            })
                            }
                        </tbody>
                    </Table>
                </div>
            )
        }

    }

    private getDownloadStatus = (tmpForm: any) => {
        if (tmpForm.status == StatusZipDownload[StatusZipDownload.Ready]) {
            return (
                <span className="my-download-status-icon fa fa-arrow-circle-down"
                    title="Download"
                    onClick={() => this.onDownloadClick(tmpForm.jobId, tmpForm.fileName)}
                    style={{ cursor: "pointer" }}
                />
            );
        }
        else if (tmpForm.status == StatusZipDownload[StatusZipDownload.PartialReady]) {
            const tooltip = `Download complete (${tmpForm.downloadSuccessDocumentsCount} out of ${tmpForm.totalDocumentsCount})`;
            return (
                <span className="my-download-status-icon fa fa-arrow-circle-down"
                    title={tooltip}
                    onClick={() => this.onDownloadClick(tmpForm.jobId, tmpForm.fileName)}
                    style={{ cursor: "pointer", color: "orange" }}
                />
            );
        }
        else if (tmpForm.status == StatusZipDownload[StatusZipDownload.InProgress]) {
            return (
                <span title="Loading"
                    className=" my-download-status-icon fa fa-spinner fa-pulse"
                />
            );
        }
        else {
            return (
                <span title="Download Failed"
                    className="text-danger my-download-status-icon fa fa-exclamation-circle"
                />
            );
        }
    }


    private getMouseHoverItems(filters: any) {
        return (
            <OverlayTrigger trigger={['hover', 'focus']}
                placement="right"
                overlay={(<Popover id="popover-my-download-filters"> {this.popOver(filters)} </Popover >)}>
                <span style={{ color: "#88c656" }}>[Read More...]</span>
            </OverlayTrigger>
        )
    }

    private popOver(filters: any) {
        let result = [];
        for (var i = 3; i < filters.length; i++) {
            let item: any = filters[i];
            if (item) {
                result.push(
                    <div className="my-download-popover">
                        <span className="list-sub-title"> {item.filtername}</span>
                        <span className="text-bold"> : {item.value} </span> <br />
                    </div>
                );
            }
        }
        return result;
    }

    private getLocation(location: any) {
        let filterLocation: any = [];
        let locations: any = location.split(",");
        for (var i = 0; i < locations.length; i++) {
            let array: any[] = this.state.LocationList?.filter(x => x.value === parseInt(locations[i]));
            if (array?.length > 0) {
                filterLocation.push(array[0].label);
            }
        }
        return filterLocation.join(',');
    }
   
    private setOfficeLocation = (LocationList: IOfficeLocation[]) => {
        let list;
        if (LocationList?.length) {
            list = LocationList.map(({ locationName: label, locationId: value }) => ({ label, value }));
        }
        else {
            list = [];
        }
        let blank = { label: 'Blanks', value: -100 };
        list.push(blank);
        this.setState({ LocationList: list });
    }

    private getStatus(status: any, filterType: any) {
        let filterStatus: any = [];
        let statuses: any = status.split(",");
        if (filterType == ReportFilterType[ReportFilterType.AllAssignments]) {
            for (var i = 0; i < statuses.length; i++) {
                let array: any[] = this.DocumentStatusType.filter(x => x.value === parseInt(statuses[i]));
                if (array.length > 0) {
                    filterStatus.push(array[0].label);
                }
                
            }
        }
        else if (filterType == ReportFilterType[ReportFilterType.InUseReturn]) {
            for (var i = 0; i < statuses.length; i++) {
                let array: any[] = this.DocumentStatusTypeInUseReturns.filter(x => x.value === parseInt(statuses[i]));
                if (array.length > 0) {
                    filterStatus.push(array[0].label);
                }
            }
        }
        else {
            for (var i = 0; i < statuses.length; i++) {
                filterStatus.push(this.getStatusText(statuses[i]));
            }
        }
        return filterStatus.join(", ");
    }
  
    private DocumentStatusType =
        [
            { label: 'UPLOADED', value: 1 },
            { label: 'ERROR', value: 2 },
            { label: 'READY', value: 3 },
            { label: 'PROCESSING', value: 4 },
            { label: 'REVIEW', value: 5 },
            { label: 'APPROVED FOR DELIVERY', value: 8 },
            { label: 'PREPARING FOR DELIVERY', value: 9 },
            { label: 'DELIVERY FAILED', value: 10 },
            { label: 'NON SUPPORTED', value: 11 },
            { label: 'RECALLED', value: 12 },
            { label: 'REPROCESSING', value: 14 },
            { label: 'TAXCADDY DELIVERY FAILED', value: 16 },
            { label: 'DUPLICATE', value: 17 },
            { label: 'DOWNLOADING FAILED', value: 19 },
            { label: 'PREPARING FOR PDF DOWNLOAD', value: 20 },
            { label: 'TO BE REPROCESSED', value: 21 }
        ];

    private DocumentStatusTypeInUseReturns =
        [
            { label: 'UPLOADED', value: 1 },
            { label: 'ERROR', value: 2 },
            { label: 'PROCESSING', value: 4 },
            { label: 'REVIEW', value: 5 },
            { label: 'APPROVED FOR DELIVERY', value: 8 },
            { label: 'PREPARING FOR DELIVERY', value: 9 },
            { label: 'DELIVERY FAILED', value: 10 },
            { label: 'NON SUPPORTED', value: 11 },
            { label: 'RECALLED', value: 12 }
        ];
    //TODO: Use Helper function getstatus
    private getStatusText(value: string): string {
        switch (parseInt(value)) {
            case SignatureStatus.ESigned:
                return "E Signed";
            case SignatureStatus.ESignedInOffice:
                return "E-Signed – In Office";
            case SignatureStatus.ManuallySigned:
                return " Manually Signed";
            case SignatureStatus.Uploaded:
                return "Uploaded";
            case SignatureStatus.AwaitingESign:
                return "Awaiting E-Sign";
            case SignatureStatus.AwaitingUpload:
                return "Awaiting Upload";
            case SignatureStatus.Locked:
                return "Locked";
            case SignatureStatus.MailOrFax:
                return "Mail Or Fax";
            case SignatureStatus.SignedAndESigned:
                return "Signed And E-Signed";
            case SignatureStatus.AutoParsed:
                return "Auto Parsed";
            case SignatureStatus.Processing:
                return "Processing";
            case SignatureStatus.Delivering:
                return "Delivering";
            case SignatureStatus.Delivered:
                return "Delivered";
            case SignatureStatus.DeliveryFailed:
                return "Delivery Failed";
            case SignatureStatus.UploadInProgress:
                return "Upload In Progress";
            case SignatureStatus.DeliveredToTaxCaddy:
                return "Delivered To TaxCaddy";
            case SignatureStatus.TaxCaddyDeliveryFailed:
                return "TaxCaddy Delivery Failed";
            case SignatureStatus.AlternateAndUploaded:
                return "Alternate And Uploaded";
            case SignatureStatus.AlternateDelivery:
                return "Alternate Delivery";
            case SignatureStatus.PartiallySigned:
                return "Partially Signed";
        }
        return "";
    }
    

    private getDownloadOptions(downloadOption: any) {
        switch (downloadOption) {
            case "0":
                return "To Be Downloaded";
            case "1":
                return "Downloaded";
        }
    }

    private getEngagementType(engageType: any) {
        return engagementType((EngagementType[engageType] as any) as EngagementType);
    }

    private getFilingType(filingType: any) {
        switch (filingType) {
            case 1:
                return "E-Filed";
            case 2:
                return "E-Filed & Paper Filed";
            default:
                return DocumentFilingType[filingType];
        }
    }

    private getTaxYear(taxYear: string) {
        let taxYearArr: any = taxYear.split(',');
        if (taxYearArr[0] == "0") {
            taxYearArr.shift();
        }
        return taxYearArr.join(", ");
    }

    private getFileFormatIcon(fileName: string) {
        if (fileName.includes('.xlsx')) {
            return "fa fa-file-excel fa-3x my-download-file-icon";
        }
        return "fa fa-file-archive fa-3x my-download-file-icon";
    }

    private isClearAllDisabled = () => {
        //returns true if all files are InProgress
        let isDisabled = this.state.row &&
            this.state.row?.find((m: any) => m.status !== StatusZipDownload[StatusZipDownload.InProgress]) ? false : true;

        return isDisabled;
    }

    private getQuickReportColumnName=(reportType:string)=>{
        if(reportType===ReportFilterType[ReportFilterType.LinkNotAccessed])
        {
            return "Link Status"
        }
        else
        {
            return "KBA Status"
        }
    }
    private LinkStatusList =
        [
            { label: 'None', value: 0 },
            { label: 'Not Accessed by Taxpayer', value: 1 },
            { label: 'Not Accessed by Spouse', value: 2 },
            { label: 'Not Accessed', value: 3 },
        ];
    
    private KBAStatusList =
        [
            { label: 'None', value: 0 },
            { label: 'Taxpayer Failed', value: 1 },
            { label: 'Spouse Failed', value: 2 },
        ];
    private getQuickReportFilterValues=(reportType:string,quickReportFilter:string)=>{
        let filterStatus: any = [];
        let statuses: any = quickReportFilter.split(",");
        if (reportType === ReportFilterType[ReportFilterType.LinkNotAccessed]) {
            for (var i = 0; i < statuses.length; i++) {
                let array: any[] = this.LinkStatusList.filter(x => x.value === parseInt(statuses[i]));
                if (array.length > 0) {
                    filterStatus.push(array[0].label);
                }
                
            }
        }
        else {
            for (var i = 0; i < statuses.length; i++) {
                let array: any[] = this.KBAStatusList.filter(x => x.value === parseInt(statuses[i]));
                if (array.length > 0) {
                    filterStatus.push(array[0].label);
                }
                
            }
        }
        return filterStatus.join(", ");
    }

    public render() {
        return <Modal show={this.props.showMyDownloads} onHide={this.onPopupClose} className="header-my-download">
            <Modal.Header closeButton>
                <Modal.Title className='custom-modal-header'>
                    <span className='fa fa-download my-download-title'></span>
                    My Downloads
                    </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ maxHeight: 'calc(100vh - 210px)', overflowY: 'auto' }}>
                <div>
                    <ListGroup>
                        {
                            this.getDownloadList()
                        }
                    </ListGroup>
                </div>

            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="default"
                    className="popup-btn-white"
                    disabled={this.isClearAllDisabled()}
                    onClick={() => this.props.clearAllMyDownloads()}>
                    <i className='fas fa-trash-alt'></i>
                    Clear All
                </Button>
                <Button
                    variant="info"
                    onClick={this.onPopupClose}>
                    <i className='fas fa-times'></i>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    }

    onPopupClose = () => {
        this.props.onToggle(userSettingConstants.myDownloads);
    }
}
export default MyDownload;
