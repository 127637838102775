import * as React from 'react';
import { ICompanySettings } from '../../../Core/ViewModels/Company/CompanySettingsViewModel';
import { RadioButtonComponent } from '../../common/RadioButtonComponent';
import { ReturnDownloadOptionsConstants } from '../../helper/Constants';

interface IDownloadOption {
    companySettings: ICompanySettings;
    handleIncludeInTaxReturnPDF(event: any): void;
    handleCreateSeperatePDF(event: any): void;
    handleSeperateByQuarterlyDueDates(event: any): void;
    handleGroupInSinglePDF(event: any): void;
}
export class ReturnsDownloadOptions extends React.Component<IDownloadOption, {}>{
    state = {
        IncludeTransmittalInTaxReturnPDF: "IncludeTransmittalInTaxReturnPDF",
        CreateSeparateTransmittalPDF: "CreateSeparateTransmittalPDF",
        SeparateByQuarterlyDueDates: "SeparateByQuarterlyDueDates",
        GroupInSinglePDF: "GroupInSinglePDF",
    }
    public render() {
        return <div id="divReturnsDownloadOptions" className="new-main-content">
            <h3>Download Options</h3>
            <div className="marL20">
                <div className="row div-height">
                    <div className="col-sm-3 col-md-3 col-lg-2">Transmittals:</div>
                    <div className="col-sm-6 col-md-6 col-lg-6">
                        <div id="divTransmittals" className="row">
                            <div className="col-sm-6 col-md-6 col-lg-6">
                                <RadioButtonComponent
                                    id={"IncludeInTheTaxReturnPdf"}
                                    text={ReturnDownloadOptionsConstants.IncludeInTaxReturnPdf}
                                    checked={this.props.companySettings.deliverySettingsModel.transmittalsDownloadOptions == this.state.IncludeTransmittalInTaxReturnPDF}
                                    onChange={this.props.handleIncludeInTaxReturnPDF}
                                    value={this.state.IncludeTransmittalInTaxReturnPDF}
                                    dataTestAuto={"93CF2B83-A6C3-4660-B4D5-5B9DC56E9447"}
                                />
                                
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-6">
                                <RadioButtonComponent
                                    id={"CreateSeperatePdf"}
                                    text={ReturnDownloadOptionsConstants.CreateSeperatePdf}
                                    checked={this.props.companySettings.deliverySettingsModel.transmittalsDownloadOptions == this.state.CreateSeparateTransmittalPDF}
                                    onChange={this.props.handleCreateSeperatePDF}
                                    value={this.state.CreateSeparateTransmittalPDF}
                                    dataTestAuto={"9D537B09-52B0-49DF-9FBD-B86B9575A99C"}
                                />
                                
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-3 col-md-3 col-lg-2">Payment Vouchers:</div>
                    <div className="col-sm-6 col-md-6 col-lg-6">
                        <div id="divVouchers" className="row">
                            <div className="col-sm-6 col-md-6 col-lg-6">
                                <RadioButtonComponent
                                    id={"B51B7D87-A9B5-45EA-A9E6-E4923986ACC0"}
                                    text={ReturnDownloadOptionsConstants.SeparateByQuarterlyDueDates}
                                    checked={this.props.companySettings.deliverySettingsModel.vouchersDownloadOptions == this.state.SeparateByQuarterlyDueDates}
                                    onChange={this.props.handleSeperateByQuarterlyDueDates}
                                    value={this.state.SeparateByQuarterlyDueDates}
                                    dataTestAuto={"62FC3332-678C-47EF-8B98-B0AA47E32390"}
                                />
                                
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-6">
                                <RadioButtonComponent
                                    id={"C6B87B46-591F-493A-9FBE-709639042826"}
                                    text={ReturnDownloadOptionsConstants.GroupInSinglePDF}
                                    checked={this.props.companySettings.deliverySettingsModel.vouchersDownloadOptions == this.state.GroupInSinglePDF}
                                    onChange={this.props.handleGroupInSinglePDF}
                                    value={this.state.GroupInSinglePDF}
                                    dataTestAuto={"CBA2F511-0622-4AA2-B724-65787AE898CE"}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
}

export default ReturnsDownloadOptions;