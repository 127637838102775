import * as React from 'react';
import { Button } from 'react-bootstrap';
import { Modal } from 'react-bootstrap';
import  TinyMCEComponent  from '../../settings/SavedMessage/TinyMCE';
import { VariableList } from '../../settings/ClientInstructions/VariableList';
import { CommonMessages } from '../../../components/helper/Constants';

interface PreparerMessageProps {
    showState: boolean;
    onHide: () => void;
    changeStateTinymceBody: (event: any) => void;
    messageBody: string;
    isAllowEdit: boolean;
    updateButtonClick: (event: any) => void;
    saveButtonClick: (event: any) => void;
    buttonText: string;
    isGroupReturn?: boolean;
}

export class PreparerMessage extends React.Component<PreparerMessageProps, {}>{
    public render() {
        return (
            <Modal className="preparer-message" show={this.props.showState} onHide={this.props.onHide}>
                <Modal.Header closeButton>
                    <Modal.Title>Message From Preparer</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="padLR-2">
                        <TinyMCEComponent
                            changeStateTinymceBody={this.props.changeStateTinymceBody}
                            messageBody={this.props.messageBody}
                            readOnly={this.props.isAllowEdit == true ? 0 : 1}
                            placeholderText={CommonMessages.PlaceholderText}
                        ></TinyMCEComponent>
                    </div>
                    {
                        this.props.isAllowEdit && <div className="marT10">
                            <VariableList isGroupReturn={this.props.isGroupReturn} />
                        </div>
                    }                    
                </Modal.Body>
                <Modal.Footer>
                    <span className="modal-footer-left float-left padT07">
                        <span className="fas fa-asterisk req_star_txt"></span>
                        <span>Required</span>
                    </span>
                    {this.props.buttonText == "Save" ?
                        <Button
                            variant='info'
                            data-test-auto= "3DAECC5E-5429-4508-8B1B-C150C07E57A8"
                            onClick={this.props.saveButtonClick}
                            className={this.props.isAllowEdit == true ? "" : "display-button"}
                        >
                            <i className='fas fa-save'></i> {this.props.buttonText}
                        </Button>
                        :
                        <Button
                            variant='info'
                            data-test-auto= "B3DB7147-F811-43C1-B326-9438DA5B64DF"
                            onClick={this.props.updateButtonClick}
                            className={this.props.isAllowEdit == true ? "" : "display-button"}
                        >
                            <i className='fas fa-save'></i> {this.props.buttonText}
                        </Button>
                    }
                    <Button
                        className="btn-default"
                        variant='default'
                        data-test-auto= "A9187363-A116-49F9-AF59-4D6E635A2B72"
                        onClick={this.props.onHide}
                    >
                        <i className="fas fa-times"></i>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
};
