import * as React from 'react';
import * as Bs from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ToggleSwitch from '../../common/ToggleSwitch';
import { ICompanySettings } from '../../../Core/ViewModels/Company/CompanySettingsViewModel';
import { IHelptextModel } from '../../../Core/ViewModels/Common/IHelptextModel';
import { fetchHelpText } from '../../helper/HelperFunctions';
import { TaxReturnSettingsResources } from '../../helper/ResourceIdConstants';

interface IMaskingSSNProps {
    updateCompanySettings(companySettings: ICompanySettings, resourceId?: string): void;
    companySettings: ICompanySettings;
    helptexts?: IHelptextModel[];
    parentResourceIdentifier: string;
    showLoader: boolean;
    loaderText: string;
}

interface IMaskingSSNStates {
    checked: boolean,
    switched: boolean,
    helptextSwitch: string
}

export class MaskingSSNSetting extends React.Component<IMaskingSSNProps, IMaskingSSNStates>{
    constructor(props: IMaskingSSNProps) {
        super(props);
        this.state = {
            checked: false,
            switched: false,
            helptextSwitch:""
        }
    }

    UNSAFE_componentWillReceiveProps() {
        if (this.props.companySettings && this.props.companySettings.k1Settings) {

            if (this.props.companySettings.k1Settings.isSSNMaskingEnabled) {
                this.setState({ switched: true });
            }
            else {
                this.setState({ switched: false });
            }
        }
        this.setState({ helptextSwitch: fetchHelpText(this.props.helptexts, "F4CD542F-F66B-4722-A868-FCE8B3B3431E") });
    }

    private handleToggleSwitchChange = (event: any) => {
        if (event.currentTarget.checked) {
            this.setState({ switched: true });
        } else {
            this.setState({ switched: false });
        }

        this.props.companySettings.k1Settings.isSSNMaskingEnabled = event.currentTarget.checked;
        this.props.updateCompanySettings(this.props.companySettings, this.props.parentResourceIdentifier);
    };

    public render() {
        return <div className="new-main-content">
            <h3>Mask/Unmask SSN</h3>
            <div className="marL20">
                <Bs.Row>
                    <Bs.Col className="text-left" sm={4} as={Bs.FormLabel} style={{ fontSize: "large", paddingTop: "3px" }}>
                        Mask SSN/EIN on all K1 documents
                    </Bs.Col>
                    <Bs.Col sm={4} md={3} lg={2}
                        data-resource-id = { TaxReturnSettingsResources.K1SettingsAndInstructionsPrefix + TaxReturnSettingsResources.K1MaskUnMask +  TaxReturnSettingsResources.ToggleEnableOrDisable}
                    >
                        <ToggleSwitch 
                            switched={this.state.switched} 
                            handleChange={this.handleToggleSwitchChange}
                            dataTestChecked={this.state.switched}
                            dataTestAuto="3dec726e-4b41-47fd-b6d1-aa4c28194a1e"
                             />

                         <Link to={'#'} className="help-icon marL05" data-placement="right" data-toggle="tooltip" 
                            title={this.state.helptextSwitch}>
                         <i className="fas fa-question-circle"></i>
                         </Link>
                    </Bs.Col>                  
                </Bs.Row>
            </div>           
        </div>
    }
}
