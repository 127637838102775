import moment from "moment";
import * as React from 'react';
import { Button, Col, FormLabel, Row } from 'react-bootstrap';
import { CustomDatePicker } from '../../common/controls/CustomDatePicker';
import { VenusNotifier } from '../../helper/VenusNotifier';
import { VoucherReminderReportPopup } from "./VoucherReminderReportPopup";
import { ReminderManagementResource } from '../../../components/helper/ResourceIdConstants';

interface VoucherReminderReportHeadersProp {
    pageTitle: string;
    filterRecordOnDateRange(fromDate: Date, toDate: Date): void;
    fromDate: Date;
    toDate: Date;
    setFromDate(date: Date): void;
    setToDate(date: Date): void;
    releaseDate: string;
    showReminderInfo: boolean;
    toggleShowReminderInfo(bool: boolean): void;
}


export interface VoucherReminderReportHeaderState {
    minDate: Date | undefined;
    maxDate: Date | undefined;
}

export class VoucherReminderReportHeader extends React.Component<VoucherReminderReportHeadersProp, VoucherReminderReportHeaderState> {
    constructor(props: VoucherReminderReportHeadersProp) {
        super(props);
        
        this.state = {
            minDate: undefined,
            maxDate: undefined
        };

        this.onFilter = this.onFilter.bind(this);
        this.onFromDateChange = this.onFromDateChange.bind(this);
        this.onToDateChange = this.onToDateChange.bind(this);
        this.clearFromDate = this.clearFromDate.bind(this);
        this.clearOnDate = this.clearOnDate.bind(this);
    } 

    private onFromDateChange = (date: any) => {
        
        const fromDateObj = new Date(date);
        const toDateObj = new Date(this.props.toDate);
        const releaseDate = new Date(this.props.releaseDate);
        
        this.props.setFromDate(fromDateObj);
        this.props.toggleShowReminderInfo(false);

        if (fromDateObj < releaseDate) {
            let prevDate = new Date(this.props.releaseDate);
            prevDate.setDate(prevDate.getDate() - 1);

            this.setState({ maxDate: prevDate, minDate: undefined });
            if (toDateObj >= releaseDate) {
                this.props.setToDate(prevDate);
                this.props.toggleShowReminderInfo(true);
            }
        } else if (fromDateObj >= releaseDate) {
            this.setState({ minDate: releaseDate, maxDate: undefined });
            if (toDateObj < releaseDate) {
                let nextDate = new Date(fromDateObj);
                nextDate.setDate(nextDate.getDate() + 1);
                this.props.setToDate(nextDate);
                this.props.toggleShowReminderInfo(true);
            }
        } else {
            this.setState({ minDate: undefined, maxDate: undefined });
        }
    }

    private onToDateChange = (date: any) => {
        let toDateObj = new Date(date);
        this.props.setToDate(toDateObj);

        const fromDateObj = new Date(this.props.fromDate);
        const releaseDate = new Date(this.props.releaseDate);

        if ((fromDateObj < releaseDate && toDateObj < releaseDate) || (fromDateObj >= releaseDate && toDateObj >= releaseDate)) {
            this.props.toggleShowReminderInfo(false);
        }
    }


    private onFilter = () => {

        if (!this.props.fromDate || !moment(this.props.fromDate).isValid()) {
            VenusNotifier.Warning("From Date cannot be empty.", "");
            return;
        }

        if (!this.props.toDate || !moment(this.props.toDate).isValid()) {
            VenusNotifier.Warning("To Date cannot be empty.", "");
            return;
        }

        if (moment(this.props.fromDate) > moment(this.props.toDate)) {
            VenusNotifier.Warning("To Date cannot be less than From Date.", "");
            return;
        }

        const fromDateObj = new Date(this.props.fromDate);
        const toDateObj = new Date(this.props.toDate);
        const releaseDateObj = new Date(this.props.releaseDate);

        if (fromDateObj < releaseDateObj && toDateObj >= releaseDateObj) {
            VenusNotifier.Warning("Invalid dates selected.", "");
            this.props.toggleShowReminderInfo(true);
            return;
        }

        this.props.filterRecordOnDateRange(fromDateObj, toDateObj);
    }

    clearFromDate() {
        this.props.setFromDate(undefined);
    }

    clearOnDate() {
        this.props.setToDate(undefined);
    }


    public render() {
       
        return <div>
            <VoucherReminderReportPopup
                showReminderInfo={this.props.showReminderInfo}
                toggleShowReminderInfo={this.props.toggleShowReminderInfo}
                releaseDate={new Date(this.props.releaseDate)}
            />

            <div className="row"  style={{ marginBottom:5 }}>
                <div className="col-sm-8">
                    <h3>{this.props.pageTitle}</h3>
                </div>
                
            </div>

            <Row>
                <Col sm={4} md={2} lg={2}>
                    <Row className="text-left padL15" as={FormLabel}>
                        From Date
                    </Row>
                    <Row className="padL15 dispBlock">
                        <CustomDatePicker
                            value={this.props.fromDate}
                            onChange={this.onFromDateChange}
                            clearFilter={this.clearFromDate}
                            sideMargin={true}
                        />
                    </Row>
                </Col>

                <Col sm={4} md={2} lg={2}>
                    <Row className="text-left padL15" as={FormLabel}>
                        To Date
                    </Row>
                    <Row className="padL15 dispBlock">
                        <CustomDatePicker
                            value={this.props.toDate}
                            onChange={this.onToDateChange}
                            clearFilter={this.clearOnDate}
                            minDate={this.state.minDate}
                            maxDate={this.state.maxDate}
                        />
                    </Row>
                </Col>

                <Col sm={3} md={2} lg={1}>
                    <Row className="dis-inline"></Row>
                    <Row className="padL15">
                        <Button className="padT06"
                            variant='info'
                            onClick={this.onFilter}
                            disabled={!this.props.releaseDate}
                        > <i className='fas fa-filter'></i>
                            Filter
                        </Button>
                    </Row>
                </Col>
            </Row>

        </div >
    }
}
export default VoucherReminderReportHeader;
