import { AuthenticationProvider, TaxSoftwareType } from '../Company/CompanySettingsViewModel';
import { IUserDefaultSettingsStates, DefaultSenderInfoType, DefaultDownloadInfoType } from '../../../components/navigation/profile/MySettings';
import TaxSoftwareTypes from '../../../components/settings/GeneralSettingsComponents/TaxSoftwareTypes';
import { Moment } from 'moment';

let moment = require('moment');

const defaultDate = moment.utc("0001-01-01");
export interface IUserBaseModel {
	userId: number,
	firstName: string,
	lastName: string,
	email: string
}

export interface IUserModel {
	userId: number;
	firstName: string;
	middleName: string;
	lastName: string;
	phoneNumber: string;
	extension: string;
	ptin: string;
	email: string;
	confirmEmail: string;
	faxNumber: string;
	title: string;
	customUserGroup: IUserGroups[];
	userGroups: IUserGroups[];
	createdOn?: any;
	password?: string;
	authenticationProviders: AuthenticationProvider[];
	metadata: string;
	isDeceased: boolean;
	mobileNumber: string;
	countryCode: string;
	isMobileVerify: boolean;
	userSettings: IUserDefaultSettingsStates;
	lastLogin: Date;
	userReviewStatus: UserReviewStatus,
	revokeStatus: SSORevokeStatus,
	inactive: boolean
}

export interface ISSRUserModel {
    id: number;
    firstName: string;
    lastName: string;
    emailAddress: string;
    inactive: boolean;
}
export interface IParterUserModel {
    id: number;
    firstName: string;
    lastName: string;
    emailAddress: string;
	inactive: boolean
}
export enum UserReviewStatus {
	YetToReview = 0,
	Reviewed = 1,
	NotApplicable = 100
}

export enum SSORevokeStatus {
	None = 0,
	PermanentlyRevoked = 1,
	TemporarilyRevoked = 2,
}

export interface IUserFilterOptions {
	filterName: string,
	filterEmailId: string,
	filterUserGroup: string,
	sortBy?: string,
	sortOrder?: string,
	offset: number
}

export interface IUserGroups {
	id: number;
	// groupCategoryId?: IGroupCategory;
	//customUserGroup?: number,
	name: string;
	userId?: number;
	userGroups?: number;

}

export enum IGroupCategory {
	None,
	Departments = 1,
	Locations,
	Groups
}

export enum IUserGroup {
	None,
	SystemAdmin = 1,
	Staff,
	Partner,
	Signatures,
	Exchange
}

export interface ICustomUserGroup {
	id: number;
	groupCategoryId: number;
	name: string;
}

export enum UserManagementProperty {
	firstName = 0,
	middleName,
	lastName,
	phoneNumber,
	extension,
	ptin,
	email,
	faxNumber,
	title,
	mobileNumber,
	countryCode,
	isMfaEnabled,
	enableSSO
}

export enum IChangePassword {
	No = 0,
	Yes = 1
}
export enum IPasswordChangeBy {
	None,
	IWillCreate = 1,
	UserWillCreate
}

export const initialUserModel: IUserModel = {
	userId: 0,
	firstName: "",
	middleName: "",
	lastName: "",
	phoneNumber: "",
	extension: "",
	ptin: "",
	email: "",
	confirmEmail: "",
	faxNumber: "",
	title: "",
	customUserGroup: [],
	userGroups: [],
	createdOn: "",
	authenticationProviders: [],
	metadata: "",
	isDeceased: false,
	mobileNumber: "",
	countryCode: "",
	isMobileVerify: false,
	userSettings: {
		defaultSettings: {
			manualAddressId: 0,
			contactPerson: 0,
			eroUser: 0,
			notifyUser: 0,
			reportFilter: "",
			sender: {
				senderId: 0,
				senderType: DefaultSenderInfoType.None
			},
			download: {
				downloadType: DefaultDownloadInfoType.PromptMeEveryTime,
				rememberDefaultDownload: false
			},
			enableLastLogin: false,
			customColumnValue: 0,
			showDefaultWarning: false
		},
		notificationSettings: {
			isApprovedForDelivery: false,
			isReturnAssigned: false,
			isReturnReady: false,
			lastPersonNotified: 0,
			paymentNotification: false
		},
		signatureSettings: {
			isAutoPlaceSignature: false,
			isEnableSignatureDelegation: false,
			signaturePath: ""
		},
		enableSignerDeligation: false,
		useSignatureStamp: false,
		userId: 0,
		signatureDelegation: {
			delegate: [],
			userId: 0
		},
		mfaSettings: {
			isMfaEnabled: false
		}
	},
	lastLogin: defaultDate,
	userReviewStatus: UserReviewStatus.NotApplicable,
	revokeStatus: SSORevokeStatus.None,
}

export const initialCustomUserGroup: ICustomUserGroup = {
	id: 0,
	groupCategoryId: 0,
	name: ""
}

export const initialUserBaseModel: IUserBaseModel = {
	userId: 0,
	firstName: '',
	lastName: '',
	email: ''
}

export const initialSSRUserBaseModel: ISSRUserModel = {
    id: 0,
    firstName: '',
    lastName: '',
    emailAddress: '',
    inactive: false
}

export interface IUserMetaData {
	readonlyFields: IUserReadonlyFields
}

export interface IUserReadonlyFields {
	providerData: IExternalAuthenticationProviderFields[]
}

export interface IExternalAuthenticationProviderFields {
	provider: AuthenticationProvider,
	fields: string[],
}

export interface IUserRole {
    userId: number;
    roles: IRole[];
}

export interface IRole {
    roleId: number;
    roleName: string;
    description: string;
    isDefault: boolean;
}
