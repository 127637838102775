import { addTask } from 'domain-task';
import { Reducer } from 'redux';
import { AppThunkAction } from "../..";
import { actionTypes } from '../../../types/ActionTypes';
import { API_BASE_URL } from '../../../utils/constants';
import * as Constants from '../../../components/helper/Constants';
import { ISSRSettings, initialSSRSettings } from '../../../Core/ViewModels/Company/Settings/SSRSettingsViewModel';
import { NotificationAction, StatusType } from '../../common/NotificationStore';
import { handleResponse } from '../../Library';
import { ICompanyData } from '../CompanyStore';
import { validateSSRSettings } from '../../../components/helper/Validations';
const isEqual = require("react-fast-compare");

export interface RequestAllSSRSettings {
    type: actionTypes.REQUEST_SSR_SETTINGS;
    message: string;
}

export interface ReceiveSSRSettingsAction {
    type: actionTypes.RECEIVE_SSR_SETTINGS;
    settings: ISSRSettings;
}

export interface ErrorSSRSettingsAction {
    type: actionTypes.ERROR_SSR_SETTINGS;
    reason: string;
}

export interface UpdateSSRSettingsAction {
    type: actionTypes.UPDATE_SSR_SETTINGS;
    settings: ISSRSettings;
}


type DispatchAction = RequestAllSSRSettings |
    ReceiveSSRSettingsAction |
    ErrorSSRSettingsAction |
    NotificationAction

type KnownAction =
    DispatchAction

export const actionCreators = {
    requestAllSSRSettings: (reload: boolean = false): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let state = getState().companyData;
        if (reload || !state.ssrSettings || isEqual(state.ssrSettings, initialSSRSettings)) {
            const fetchTask = fetch(API_BASE_URL + "api/Company/SSRSettings/GetAllSSRSettings", {
                method: 'GET',
                credentials: 'include'
            })
                .then(handleResponse)
                .then(json => json as Promise<ISSRSettings>)
                .then(data => {
                    if (data) {
                        dispatch({ type: actionTypes.RECEIVE_SSR_SETTINGS, settings: data });
                    }
                })
                .catch(error => {
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: Constants.CompanySettingsConstants.StatusMessage.CompanySettingsError,
                        statusType: StatusType.Error
                    })
                    dispatch({
                        type: actionTypes.ERROR_SSR_SETTINGS,
                        reason: error.message,
                    });

                });
            addTask(fetchTask);
            dispatch({
                type: actionTypes.REQUEST_SSR_SETTINGS,
                message: Constants.CompanySettingsConstants.OverlayMessage.ApplicationLoading
            });
        }
    },

    updateSSRSettings: (ssrSettings: ISSRSettings, callback?: () => void, resourceId: string = ""): AppThunkAction<KnownAction> => (dispatch, getState) => {
        if (!validateSSRSettings(ssrSettings)) {
            dispatch({
                type: actionTypes.NOTIFICATION,
                statusMessage: Constants.CompanySettingsConstants.StatusMessage.UpdateCompanySettingsError,
                statusType: StatusType.Error
            });
        } else {

            const fetchTask = fetch(API_BASE_URL + 'api/Company/SSRSettings/', {
                method: 'PUT',
                credentials: 'include',
                headers: {
                    'Accept': 'application/json, text/plain, *',
                    'X-Resource-Id': resourceId,
                    'Content-Type': 'application/json; charset=utf-8',
                },
                body: JSON.stringify(ssrSettings)
            })
                .then(handleResponse)
                .then((response) => {

                    if (callback) {
                        callback();
                    }

                    dispatch({ type: actionTypes.RECEIVE_SSR_SETTINGS, settings: ssrSettings });

                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: Constants.CompanySettingsConstants.StatusMessage.UpdateCompanySettingsSuccess,
                        statusType: StatusType.Success
                    })
                })
                .catch((error) => {
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: Constants.CompanySettingsConstants.StatusMessage.UpdateCompanySettingsError,
                        statusType: StatusType.Error
                    });
                    dispatch({
                        type: actionTypes.ERROR_SSR_SETTINGS,
                        reason: error.message,
                    });
                })

            addTask(fetchTask);
            dispatch({
                type: actionTypes.REQUEST_SSR_SETTINGS,
                message: Constants.CompanySettingsConstants.OverlayMessage.UpdatingCompanySettings
            });
        }
    }
};
const unloadedState: ICompanyData = {
    ssrSettings: initialSSRSettings
} as ICompanyData;

export const reducer: Reducer<ICompanyData> = (state = unloadedState, incomingAction) => {
    const action = incomingAction as DispatchAction;
    switch (action.type) {
        default: return state;
    }
}
