import { confirm } from 'bootbox';
import { PDFDocumentProxy, PDFJSStatic } from 'pdfjs-dist';
import * as React from 'react';
import { BookmarkTree } from '../../../../components/common/BookmarkTree';
import { Guid } from '../../../../Core/Utilities/Guid';
import { IPdfDocumentFacade, PdfDocumentFacade } from '../../../../Core/Utilities/PdfDocumentFacade';
import { PdfProperties } from '../../../../Core/ViewModels/Common/PdfHelper';
import { SignatureFormSelectionType } from '../../../../Core/ViewModels/Company/CompanySettingsViewModel';
import { IUserGroup } from '../../../../Core/ViewModels/User/UserViewModel';
import * as UserSignatureStore from '../../../../store/common/UserSignatureStore';
import * as CompanyStore from '../../../../store/company/CompanyStore';
import * as PaperFileStore from '../../../../store/ProcessReturn/PaperFileStore';
import * as UserSettingStore from '../../../../store/userManagement/UserSettingStore';
import { PaperFileInfo } from '../../../common/ProcessReturnModal/PaperFile/PaperFileInfo';
import { PaperFileUploadModal } from '../../../common/ProcessReturnModal/PaperFile/PaperFileUploadModal';
import { DocumentGroups, IEROSigner, isIndividual, ITaxingAuthority, ITaxReturn, SignatureControlRole, SignatureControlTypes, SignatureGroupType, SignatureType } from '../../../common/TaxReturn';
import { PaperFile } from '../../../helper/Constants';
import * as Helper from '../../../helper/HelperFunctions';
import { IUserProfile } from '../../../navigation/profile/ProfileObjects';
import { AwesomePdfViewerUrl } from '../AwesomePdfViewerUrl';
import { INVALID_PAGE } from '../CustomPagination/CustomPagination';
import { IOptions, IPaperFileControl, IPaperFileData, IPaperFilePage, PaperFileUploadData } from '../PaperFile/PaperFile';
import {
    PdfPageSignatureControls
} from '../ProcessReturnModels';
import { EROSignatureCard } from '../RightSidebar/EROSignaturePanel';
import { dragStartSignatureControl } from './../SignatureControls/SignatureHelper';
import { logger } from '../../../../routes/LoggedIn';
import { IBlobFile } from '../../../../store/common';
import { PageSize } from 'awesome-pdf-viewer/dist/layout/LayoutBase';
import { IAuthState } from '../../../../store/auth/reducer';

const PDFJS: PDFJSStatic = require('pdfjs-dist');
const NO_INDEX = -1;

export interface IPaperFileProps {
    auth: IAuthState;
    taxReturn: ITaxReturn;
    updateTaxDocument: (taxDocument: ITaxReturn) => void;
    userSettings: UserSettingStore.UserSettings;
    company: CompanyStore.ICompanyData;
    userProfile: IUserProfile;
    signatureFormSelectionType: SignatureFormSelectionType;
    userSignatures: UserSignatureStore.IUserSignatures;
    updateEROSignatureStamp(userId: number): void;
    renderTrigger: string;
    isAssignedToLoggedinUser: boolean;
    reGroupPreview: (pages: number[], destinationGroup: DocumentGroups) => void;
    getPaperFileUploadLink: (url: string, callback?: (data?: IBlobFile) => void) => void;
    getPaperFileDownLoadLink: (url: string, callback?: (data?: IBlobFile) => void, retrycallback?: () => void) => void;
    taxingAuthorities: ITaxingAuthority[];
    addPaperFileData: (paperFileUploadData: PaperFileUploadData[]) => void;
    updatePaperFile: (paperFileUploadData: PaperFileUploadData) => void;
    deleteUploadedPaperFileFromBlob: (documentGuid: string, fileName: string, taxYear: number, callback?: () => void) => void;
    paperFileUploadData: PaperFileUploadData[];
    convertToPdfAsync: (url: string) => void;
}

export interface IPaperFileState {
    showUploadModal: boolean;
    uploadedDocumentData: PaperFileUploadData[];
    selectedPaperFile: PaperFileUploadData;
    isBusy: boolean;
    currentPage: number;
    zoomEnabled: boolean;
    focusedGroup: SignatureGroupType;
    eroSigners: IEROSigner[];
    selectedEROSigner: IEROSigner;
    authority: IOptions[];
    scale: number;
}

export class TabPaperFile extends React.Component<IPaperFileProps, any>{
    private _pdfViewer: any;
    constructor(props: IPaperFileProps) {
        super(props);
        this.state = {
            showUploadModal: false,
            uploadedDocumentData: [],
            selectedPaperFile: {} as PaperFileUploadData,
            isBusy: false,
            currentPage: INVALID_PAGE,
            zoomEnabled: false,
            focusedGroup: SignatureGroupType.None,
            eroSigners: [],
            selectedEROSigner: {
                value: 0,
                label: "",
                isEnableSignatureDelegation: true,
                eroImage: ""
            },
            authority: [],
            scale: PdfProperties.DefaultScale
        }
    }

    UNSAFE_componentWillMount() {
        this.setEroSignerDropdownValues(this.props);
    }

    componentDidMount() {
        this.prepareAuthorityList()

        if (this.props.paperFileUploadData && this.props.paperFileUploadData.length < 1) {
            const filterDeletedPaperFiles: IPaperFileData[] = this.props.taxReturn.paperFiles.filter((item: IPaperFileData) => item.isDeleted === false);
            this.loadSavedPaperFiles(filterDeletedPaperFiles);
        }
        if (this.props.paperFileUploadData && this.props.paperFileUploadData.length > 0) {
            this.setState({
                uploadedDocumentData: this.props.paperFileUploadData,
                selectedPaperFile: this.props.paperFileUploadData[0],
                currentPage: 1,
            }, () => this.loadDocument(this.props.paperFileUploadData[0].sasUrl));
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps: IPaperFileProps) {
        if (this.props.userSignatures !== nextProps.userSignatures) {
            this.setEroSignerDropdownValues(nextProps);
        }
        let uploadedDocumentData = this.sortPaperFile(this.props.paperFileUploadData);
        this.setState({ uploadedDocumentData: uploadedDocumentData });
        //load sasUrl and pdf 
        if (this.props.paperFileUploadData.length != nextProps.paperFileUploadData.length) {
            this.setState({
                uploadedDocumentData: nextProps.paperFileUploadData,
                selectedPaperFile: nextProps.paperFileUploadData[0],
                currentPage: 1,
            }, () => {
                if (this.state.selectedPaperFile &&
                    this.state.selectedPaperFile.pdf === undefined &&
                    this.state.selectedPaperFile.sasUrl == "") {
                    this.getPaperFileLinkAsync(nextProps.taxReturn.documentGuid,
                        nextProps.taxReturn.taxYear, this.state.selectedPaperFile.fileName);
                }
            });
        }
        if (nextProps.paperFileUploadData &&
            nextProps.paperFileUploadData[0] &&
            nextProps.paperFileUploadData[0].pdf == undefined &&
            nextProps.paperFileUploadData[0].sasUrl != undefined &&
            nextProps.paperFileUploadData[0].sasUrl != "" &&
            this.state.selectedPaperFile &&
            this.state.selectedPaperFile.pdf == undefined) {
            this.loadDocument(nextProps.paperFileUploadData[0].sasUrl);
        }
    }

    private sortPaperFile = (paperFile: PaperFileUploadData[]) => {
        let pageItemsFederal: PaperFileUploadData[] = [];
        let pageItemsNonFederal: PaperFileUploadData[] = [];
        paperFile.forEach((item, index) => {
            const authorityName = this.getAuthorityName(item.authorityId);
            if (authorityName == "Federal") {
                pageItemsFederal.push({
                    authorityId: item.authorityId,
                    fileName: item.fileName,
                    sasUrl: "",
                    isPDFloaded: false,
                    pdf: undefined,
                    isDeleted: item.isDeleted,
                    mailingAddress: item.mailingAddress,
                    formName: item.formName,
                    authorityName: authorityName
                } as PaperFileUploadData);
            }
            else {
                pageItemsNonFederal.push({
                    authorityId: item.authorityId,
                    fileName: item.fileName,
                    sasUrl: "",
                    isPDFloaded: false,
                    pdf: undefined,
                    isDeleted: item.isDeleted,
                    mailingAddress: item.mailingAddress,
                    formName: item.formName,
                    authorityName: authorityName
                } as PaperFileUploadData);
            }
        });
        pageItemsFederal = pageItemsFederal.sort((a: PaperFileUploadData, b: PaperFileUploadData) => {
            var result = 0;
            let aTitle = a.authorityName + "_" + a.formName.toLowerCase();
            let bTitle = b.authorityName + "_" + b.formName.toLowerCase();
            if (aTitle < bTitle)
                result = -1;
            else
                result = 1;
            return result;
        });

        pageItemsNonFederal = pageItemsNonFederal.sort((a: PaperFileUploadData, b: PaperFileUploadData) => {
            var result = 0;
            let aTitle = a.authorityName + "_" + a.formName.toLowerCase();
            let bTitle = b.authorityName + "_" + b.formName.toLowerCase();
            if (aTitle < bTitle) {
                result = -1;
            } else
                result = 1;

            return result;
        });

        pageItemsFederal = pageItemsFederal.concat(pageItemsNonFederal);
        return pageItemsFederal;
    }

    private loadSavedPaperFiles = (savedPaperFiles: IPaperFileData[]) => {
        let pageItemsFederal: PaperFileUploadData[] = [];
        let pageItemsNonFederal: PaperFileUploadData[] = [];
        savedPaperFiles.forEach((item, index) => {
            const authorityName = this.getAuthorityName(item.authorityId);
            if (authorityName == "Federal") {
                pageItemsFederal.push({
                    authorityId: item.authorityId,
                    fileName: item.fileName,
                    sasUrl: "",
                    isPDFloaded: false,
                    pdf: undefined,
                    isDeleted: item.isDeleted,
                    mailingAddress: item.mailingAddress,
                    formName: item.formName,
                    authorityName: authorityName
                } as PaperFileUploadData);
            }
            else {
                pageItemsNonFederal.push({
                    authorityId: item.authorityId,
                    fileName: item.fileName,
                    sasUrl: "",
                    isPDFloaded: false,
                    pdf: undefined,
                    isDeleted: item.isDeleted,
                    mailingAddress: item.mailingAddress,
                    formName: item.formName,
                    authorityName: authorityName
                } as PaperFileUploadData);
            }
        });
        pageItemsFederal = pageItemsFederal.sort((a: PaperFileUploadData, b: PaperFileUploadData) => {
            var result = 0;
            let aTitle = a.authorityName.toLowerCase();
            let bTitle = b.authorityName.toLowerCase();
            if (aTitle < bTitle)
                result = -1;
            else
                result = 1;
            return result;
        });

        pageItemsNonFederal = pageItemsNonFederal.sort((a: PaperFileUploadData, b: PaperFileUploadData) => {
            var result = 0;
            let aTitle = a.authorityName.toLowerCase();
            let bTitle = b.authorityName.toLowerCase();
            if (aTitle < bTitle) {
                result = -1;
            } else
                result = 1;

            return result;
        });

        pageItemsFederal = pageItemsFederal.concat(pageItemsNonFederal);
        this.props.addPaperFileData(pageItemsFederal);
    }

    private loadDocument = (sasUrl: string) => {
        let selected: PaperFileUploadData = this.state.selectedPaperFile;
        this.loadPdf(sasUrl)
            .then((data: IPdfDocumentFacade) => {
                selected.isPDFloaded = true;
                selected.pdf = data;
                selected.sasUrl = sasUrl;

                this.setState({ selectedPaperFile: selected, currentPage: 1 });
                this.props.updatePaperFile(selected);
            })
    }

    private prepareAuthorityList = () => {
        let authorities: ITaxingAuthority[] = this.props.taxingAuthorities;
        let authority: IOptions[] = [];
        if (authorities) {
            authorities.map((item, index) => {
                authority.push({
                    label: item.AuthorityName as string, value: item.Id as number
                });
            });
        }
        this.setState({
            authority: authority
        })
    }

    private onPaperFileDocument = () => {
        this.setState({ showUploadModal: true });
    }

    private onCloseModal = () => {
        this.setState({ showUploadModal: false });
    }

    private onSubmit = (newUploadedPaperFileData: PaperFileUploadData[]) => {
        let _self = this;
        let taxReturn = Helper.cloneTaxReturn(_self.props.taxReturn);
        if (taxReturn.paperFiles === undefined) {
            taxReturn.paperFiles = [];
        }

        newUploadedPaperFileData.forEach((item, index) => {
            var fileData: IPaperFileData = {} as IPaperFileData;
            fileData.id = 0;
            fileData.fileName = item.fileName != undefined ? item.fileName : "";
            fileData.uploadedBy = _self.props.userProfile?.userId || 0;
            fileData.uploadedOn = new Date;
            fileData.authorityId = item.authorityId != undefined ? item.authorityId : 0;
            fileData.mailingAddress = item.mailingAddress
            fileData.fileControls = [];
            fileData.isModified = true;
            fileData.formName = item.formName
            taxReturn.paperFiles.push(fileData);
        });

        let uploadedDocumentData: PaperFileUploadData[] = _self.state.uploadedDocumentData;
        if (uploadedDocumentData && uploadedDocumentData.length > 0) {
            uploadedDocumentData = uploadedDocumentData.concat(newUploadedPaperFileData);
        }
        else {
            uploadedDocumentData = newUploadedPaperFileData;
        }
        uploadedDocumentData = this.sortPaperFile(uploadedDocumentData);

        _self.setState({
            selectedPaperFile: newUploadedPaperFileData[0],
            uploadedDocumentData: uploadedDocumentData
        }, () => {
            _self.props.addPaperFileData(uploadedDocumentData);
            _self.props.updateTaxDocument(taxReturn);
            _self.onCloseModal();
        });
    }

    getPaperFileLinkAsync = (documentGuid: string, taxYear: number, fileName: string) => {
        let param = '?documentGuid=' + documentGuid +
            '&fileName=' + escape(fileName) +
            '&taxYear=' + taxYear;
        this.props.getPaperFileDownLoadLink('/api/Download/GetPaperFileLinkAsync' + param,
            (data?: IBlobFile) => {
                try {
                    if (data) {
                        this.loadDocument(data ? data.sas : "");
                    }
                } catch (error) {
                    this.setState({ uploadedDocumentData: this.state.uploadedDocumentData });
                }
            }), () => {
                this.getPaperFileLinkAsync(documentGuid, taxYear, fileName);
            }
    }

    onDocumentClick = (fileName: string) => {
        let selected = this.state.uploadedDocumentData.find((x: PaperFileUploadData) => x.fileName === fileName) as PaperFileUploadData;
        this.setState({
            selectedPaperFile: selected,
            currentPage: 1
        }, () => {
            if (this.state.selectedPaperFile && !this.state.selectedPaperFile.isPDFloaded) {
                if (this.state.selectedPaperFile.sasUrl == "") {
                    this.getPaperFileLinkAsync(this.props.taxReturn.documentGuid,
                        this.props.taxReturn.taxYear, this.state.selectedPaperFile.fileName);
                }
                else {
                    // let taxReturn = Helper.cloneTaxReturn(this.props.taxReturn);
                    this.loadDocument(this.state.selectedPaperFile.sasUrl);
                }
            }
        });
    }

    onPageClick = (pageNo: number) => {
        this.setState({ currentPage: pageNo });
    }

    private loadPdf = async (sasUrl: string) => {
        return await PDFJS.getDocument({ url: sasUrl })
            .then((pdf: PDFDocumentProxy) => {
                let docFacade = new PdfDocumentFacade();
                docFacade.append(pdf, sasUrl);
                return docFacade;
            }, (error: string) => {

            });
    }

    private getEroSignatureControls = (): PdfPageSignatureControls => {
        let documentControls: IPaperFileControl[] = [];
        let signatureType: any = this.props.taxReturn.documentSettings.documentSignatureSetting.signatureFormSelectionType;
        signatureType = (typeof signatureType == "number") ? SignatureType[signatureType] : signatureType;
        documentControls = this.filterControls(signatureType);
        return {
            signatureControls: documentControls,
            eroSigner: { id: this.state.selectedEROSigner.value, signPath: this.state.selectedEROSigner.eroImage },
            signer: { id: 0, role: SignatureControlRole.None },
            focusedGroup: this.state.focusedGroup
        } as PdfPageSignatureControls;
    }

    private filterControls = (signatureType: any): IPaperFileControl[] => {
        let documentControls: IPaperFileControl[] = [];
        let taxReturn = Helper.cloneTaxReturn(this.props.taxReturn);
        const documentIndex = taxReturn.paperFiles.findIndex(x => x.fileName === this.state.selectedPaperFile.fileName);
        if (documentIndex !== Helper.NO_INDEX) {
            const pageIndex = taxReturn.paperFiles[documentIndex].fileControls.findIndex(x => x.pageNo === this.state.currentPage);
            if (pageIndex !== Helper.NO_INDEX) {
                documentControls = taxReturn.paperFiles[documentIndex].fileControls[pageIndex].controls;
                if (documentControls) {
                    if (signatureType === SignatureType[SignatureType.ManualSign] && this.props.userSettings.settings.useSignatureStamp) {
                        documentControls = documentControls.filter(control => control.signatureControlRole === SignatureControlRole.ERO);
                    }
                    else if (signatureType === SignatureType[SignatureType.ManualSign] && !this.props.userSettings.settings.useSignatureStamp) {
                        documentControls = [];
                    }
                    else {
                        if (!this.props.userSettings.settings.useSignatureStamp) {
                            documentControls = documentControls.filter(control => control.signatureControlRole !== SignatureControlRole.ERO);
                        }
                    }
                }
            }
        }
        return documentControls;
    }

    private addSignatureControl = (type: SignatureControlTypes, role: SignatureControlRole, left: number, top: number) => {
        try {
            logger.trackTrace(`TabPaperFile --> addSignatureControl started for document: ${this.props.taxReturn.documentGuid} , SignatureControlTypes: ${type}, SignatureControlRole: ${role}, clientX : ${left}, clientY : ${top}`, { "DocumentId": this.props.taxReturn.id?.toString(), "DocumentGuid": this.props.taxReturn.documentGuid });
            let _self = this;
            let control: IPaperFileControl = {} as IPaperFileControl;
            control.controlGuid = Guid.newGuid().toString();
            control.left = left;
            control.top = top;
            control.type = type;
            control.signatureControlRole = role;
            control.required = true;
            control.tooltip = "";
            control = this.adjustSamePositionControl(control);

            let taxReturn = Helper.cloneTaxReturn(this.props.taxReturn);
            const documentIndex = taxReturn.paperFiles.findIndex(x => x.fileName === _self.state.selectedPaperFile.fileName);
            if (documentIndex !== Helper.NO_INDEX) {
                taxReturn.paperFiles[documentIndex].isModified = true;
                const pageIndex = taxReturn.paperFiles[documentIndex].fileControls.findIndex(x => x.pageNo === _self.state.currentPage);
                if (pageIndex === Helper.NO_INDEX) {
                    let documentControls = { pageNo: this.state.currentPage, controls: [control] } as IPaperFilePage;
                    taxReturn.paperFiles[documentIndex].fileControls.push(documentControls);
                }
                else {
                    taxReturn.paperFiles[documentIndex].fileControls[pageIndex].controls.push(control);
                }
                this.props.updateTaxDocument(taxReturn);
                } logger.trackTrace(`TabPaperFile --> addSignatureControl ended for document: ${this.props.taxReturn.documentGuid}, SignatureControlTypes: ${type}, SignatureControlRole: ${role}, clientX : ${left}, clientY : ${top}`, { "DocumentId": this.props.taxReturn.id?.toString(), "DocumentGuid": this.props.taxReturn.documentGuid });
        } catch(error) {
                logger.trackWarning(`TabPaperFile --> SignatureControlDragandDropError: addSignatureControl throw error for document: ${this.props.taxReturn.documentGuid}, SignatureControlTypes: ${type}, SignatureControlRole: ${role}, clientX : ${left}, clientY : ${top}, error : ${error}`, { "DocumentId": this.props.taxReturn.id?.toString(), "DocumentGuid": this.props.taxReturn.documentGuid });
        }
    }

    private adjustSamePositionControl = (control: IPaperFileControl): IPaperFileControl => {
        let taxReturn = Helper.cloneTaxReturn(this.props.taxReturn);
        const fileIndex = taxReturn.paperFiles.findIndex(x => x.fileName === this.state.selectedPaperFile.fileName);
        if (fileIndex !== Helper.NO_INDEX) {
            const pageIndex = taxReturn.paperFiles[fileIndex].fileControls.findIndex(x => x.pageNo === this.state.currentPage);
            if (pageIndex !== Helper.NO_INDEX) {
                let documentControls = taxReturn.paperFiles[fileIndex].fileControls[pageIndex].controls;
                const index = documentControls.findIndex(x => x.controlGuid === control.controlGuid);
                if (index !== NO_INDEX) {
                    control.left = control.left + 5;
                    control.top = control.top + 5;
                    return this.adjustSamePositionControl(control);
                }
                else {
                    return control;
                }
            }
        }
        return control;
    }

    private dragStartSignatureControl = (event: any, controlType: SignatureControlTypes, signatureRole: SignatureControlRole) => {
        try {
            logger.trackTrace(`TabPaperFile --> dragStartSignatureControl started: ${this.props.taxReturn.documentGuid}, SignatureControlTypes : ${controlType} and SignatureControlRole : ${signatureRole}`, { "DocumentId": this.props.taxReturn.id?.toString(), "DocumentGuid": this.props.taxReturn.documentGuid });
            const pageSize: PageSize = this._pdfViewer.getCurrentPageSize();
            dragStartSignatureControl(
                event, controlType, signatureRole,
                this.state.selectedEROSigner.label,
                this.state.currentPage,
                this.state.scale,
                this.addSignatureControl,
                pageSize.height);
            logger.trackTrace(`TabPaperFile --> dragStartSignatureControl ended: ${this.props.taxReturn.documentGuid}, SignatureControlTypes : ${controlType} and SignatureControlRole : ${signatureRole}`, { "DocumentId": this.props.taxReturn.id?.toString(), "DocumentGuid": this.props.taxReturn.documentGuid });
        } catch (error) {
            logger.trackWarning(`TabPaperFile --> SignatureControlDragandDropError: dragStartSignatureControl throw error: ${this.props.taxReturn.documentGuid}, SignatureControlTypes : ${controlType} and SignatureControlRole : ${signatureRole}, error: ${error}`, { "DocumentId": this.props.taxReturn.id?.toString(), "DocumentGuid": this.props.taxReturn.documentGuid });
        }
    }

    private dragEnd = (event: any) => {
        try	{
			logger.trackTrace(`TabPaperFile --> dragEnd started for document: ${this.props.taxReturn.documentGuid}`, { "DocumentId": this.props.taxReturn.id?.toString(), "DocumentGuid": this.props.taxReturn.documentGuid });
			let element = document.getElementById("dragging-element");
			if (element) {
				element.remove();
			}
			logger.trackTrace(`TabPaperFile --> dragEnd ended for document: ${this.props.taxReturn.documentGuid}`, { "DocumentId": this.props.taxReturn.id?.toString(), "DocumentGuid": this.props.taxReturn.documentGuid });
		} catch(error) {
			logger.trackWarning(`TabPaperFile --> SignatureControlDragandDropError: dragEnd throw error for document: ${this.props.taxReturn.documentGuid}, error : ${error}`, { "DocumentId": this.props.taxReturn.id?.toString(), "DocumentGuid": this.props.taxReturn.documentGuid });
		}
    }

    private onControlUpdate = (oldControl: IPaperFileControl, newControl: IPaperFileControl) => {
        let taxReturn = Helper.cloneTaxReturn(this.props.taxReturn);
        const fileIndex = taxReturn.paperFiles.findIndex(x => x.fileName === this.state.selectedPaperFile.fileName);
        if (fileIndex !== Helper.NO_INDEX) {
            taxReturn.paperFiles[fileIndex].isModified = true;
            const pageIndex = taxReturn.paperFiles[fileIndex].fileControls.findIndex(x => x.pageNo === this.state.currentPage);
            if (pageIndex !== Helper.NO_INDEX) {
                let documentControls = taxReturn.paperFiles[fileIndex].fileControls[pageIndex].controls;
                for (let i = 0; i < documentControls.length; i++) {
                    let documentControl = documentControls[i];
                    if (documentControl.controlGuid === oldControl.controlGuid) {
                        documentControl.left = newControl.left;
                        documentControl.top = newControl.top;
                        documentControl.signatureControlRole = newControl.signatureControlRole;
                        documentControl.type = newControl.type;
                        break;
                    }
                }
                this.props.updateTaxDocument(taxReturn);
            }
        }
    }
    private onControlRemove = (control: IPaperFileControl) => {
        let taxReturn = Helper.cloneTaxReturn(this.props.taxReturn);
        const documentIndex = taxReturn.paperFiles.findIndex(x => x.fileName === this.state.selectedPaperFile.fileName);
        if (documentIndex !== Helper.NO_INDEX) {
            taxReturn.paperFiles[documentIndex].isModified = true;
            const pageIndex = taxReturn.paperFiles[documentIndex].fileControls.findIndex(x => x.pageNo === this.state.currentPage);
            if (pageIndex !== Helper.NO_INDEX) {
                taxReturn.paperFiles[documentIndex].fileControls[pageIndex].controls =
                    taxReturn.paperFiles[documentIndex].fileControls[pageIndex].controls.filter(x => x.controlGuid != control.controlGuid);
                this.props.updateTaxDocument(taxReturn);
                this.forceUpdate();
            }
        }
    }

    private setEroSignerDropdownValues = (nextprops: IPaperFileProps) => {
        let eroSigners: IEROSigner[] = [];
        if (this.props.company != undefined) {
            let companyAsSigner = {
                label: this.props.company.companyProfile.companyInfo.companyName,
                value: 0,
                isEnableSignatureDelegation: true,
                eroImage: this.props.company.signatureUploadLink,
            }
            this.addEroSigner(eroSigners, companyAsSigner);
        }

        if (this.props.auth != undefined
            && this.props.auth.user.profile.role.split(',').includes('Partner')) {
            const currentUser = {
                label: this.props.auth.user.profile.name,
                value: this.props.auth.user.profile.user_id as number,
                isEnableSignatureDelegation: true,
                eroImage: this.props.userSettings.settings.signatureSettings.signaturePath || "",
            }
            this.addEroSigner(eroSigners, currentUser);
        }

        if (this.props.taxReturn.partner != undefined) {
            const partner = this.props.taxReturn.partner;
            const partnerEro = {
                label: partner.firstName + " " + partner.lastName,
                value: partner.userId,
                isEnableSignatureDelegation: true,
                eroImage: this.getEroSignerPath(partner.userId, nextprops)
            }
            this.addEroSigner(eroSigners, partnerEro);
        }

        if (this.props.userSettings.delegatedSigners.length > 0) {
            this.props.userSettings.delegatedSigners.map(eroSigner => {
                let signer = {
                    label: eroSigner.firstName + " " + eroSigner.lastName,
                    value: eroSigner.userId,
                    isEnableSignatureDelegation: eroSigner.isEnableSignatureDelegation,
                    eroImage: eroSigner.signaturePath,
                }
                this.addEroSigner(eroSigners, signer);
            });
        }

        const selectedEROId = this.props.taxReturn.documentSettings.documentSignatureSetting.signatureStampUser ?
            this.props.taxReturn.documentSettings.documentSignatureSetting.signatureStampUser?.userId
            : this.state.selectedEROSigner.value;
        let selectedEROSigner = eroSigners.find(x => x.value == selectedEROId) as IEROSigner;
        selectedEROSigner = selectedEROSigner ? selectedEROSigner :
            eroSigners.find(x => x.value === 0) as IEROSigner;

        this.setState({
            eroSigners: eroSigners,
            selectedEROSigner: selectedEROSigner,
        });
    }

    private addEroSigner = (eroSignersArray: IEROSigner[], eroSigner: IEROSigner) => {
        if (eroSignersArray.filter(e => e.value == eroSigner.value).length === 0) {
            eroSignersArray.push(eroSigner);
        }
    }

    private getEroSignerPath = (userId: number, nextprops: IPaperFileProps) => {
        let eroImagePath = "";
        if (nextprops.userSignatures[userId] && nextprops.userSignatures[userId].signatureDownloadPath) {
            eroImagePath = nextprops.userSignatures[userId].signatureDownloadPath;
        }
        return eroImagePath;
    }

    private handleEROSignerChange = (selectedOption: IEROSigner) => {
        if (selectedOption && selectedOption.eroImage !== "") {
            this.setState({
                selectedEROSigner: {
                    value: selectedOption.value,
                    label: selectedOption.label,
                    isEnableSignatureDelegation: selectedOption.isEnableSignatureDelegation,
                    eroImage: selectedOption.eroImage
                }
            }, () => {
                this.props.updateEROSignatureStamp(selectedOption.value);
            })
        }
    }

    private onSignatureControlFocus = (focusedGroup: SignatureGroupType) => {
        this.setState({
            focusedGroup: focusedGroup
        });
    }

    private getAuthorityName = (authorityId: number) => {
        const { taxingAuthorities = [] } = this.props;
        let taxingAuthority =  Array.isArray(taxingAuthorities) ? taxingAuthorities.find(x => x.Id == authorityId) as ITaxingAuthority : undefined;
        return taxingAuthority ? taxingAuthority.AuthorityName : "";
    }

    onFormNameChange = (event: any) => {
        let paperFile: PaperFileUploadData = { ...this.state.selectedPaperFile }
        paperFile.formName = event.target.value;
        this.setState({ selectedPaperFile: paperFile });
    }

    onAddressChange = (event: any) => {
        let paperFile: PaperFileUploadData = { ...this.state.selectedPaperFile }
        paperFile.mailingAddress = event.target.value;
        this.setState({ selectedPaperFile: paperFile });
    }

    onAuthorityChange = (fileName: string, value: any) => {
        let paperFile: PaperFileUploadData = { ...this.state.selectedPaperFile }
        paperFile.authorityId = value;
        let taxReturn: ITaxReturn = { ...this.props.taxReturn };
        this.setState({ selectedPaperFile: paperFile }, () => {
            taxReturn.paperFiles.map((item, index) => {
                if (item.fileName == fileName) {
                    item.authorityId = value;
                }
            });
            let uploadedDocumentData: PaperFileUploadData[] = this.state.uploadedDocumentData;
            uploadedDocumentData.map((item, index) => {
                if (item.fileName == fileName) {
                    item.authorityId = value;
                }
            });
            uploadedDocumentData = this.sortPaperFile(uploadedDocumentData);
            this.setState({
                uploadedDocumentData: uploadedDocumentData
            }, () => {
                this.props.addPaperFileData(uploadedDocumentData);
                this.props.updateTaxDocument(taxReturn)
            });
        });
    }

    handleBlurAddress = (fileName: string, event: any) => {
        let taxReturn: ITaxReturn = { ...this.props.taxReturn };
        taxReturn.paperFiles.map((item, index) => {
            if (item.fileName == fileName) {
                item.mailingAddress = this.state.selectedPaperFile.mailingAddress;
            }
        });
        this.props.updatePaperFile(this.state.selectedPaperFile);
        this.props.updateTaxDocument(taxReturn)
    }

    handleBlurFormName = (fileName: string, event: any) => {
        let taxReturn: ITaxReturn = { ...this.props.taxReturn };
        taxReturn.paperFiles.map((item, index) => {
            if (item.fileName == fileName) {
                item.formName = this.state.selectedPaperFile.formName;
            }
        });
        this.props.updatePaperFile(this.state.selectedPaperFile);
        this.props.updateTaxDocument(taxReturn)
    }

    private onDeleteDocument = (fileName: string) => {
        confirm({
            message: PaperFile.deleteConfirmMessage,
            buttons: {
                cancel: {
                    label: '<i class="fas fa-times"></i> Cancel',
                    className: 'btn-white btn-default'
                },
                confirm: {
                    label: '<i class="fas fa-check"></i> OK',
                    className: 'btn-info'
                }
            },
            callback: (result: boolean) => {
                if (result) {
                    let uploadedDocumentData: PaperFileUploadData[] = this.state.uploadedDocumentData;
                    uploadedDocumentData = uploadedDocumentData.filter(x => x.fileName != fileName);

                    let taxReturn = Helper.cloneTaxReturn(this.props.taxReturn);
                    taxReturn.paperFiles.map((item, index) => {
                        if (item.fileName == fileName) {
                            item.isDeleted = true;
                            item.isModified = true;
                        }
                        return item;
                    });

                    taxReturn.paperFiles = taxReturn.paperFiles.filter((item, index) => {
                        if (item.id == 0 && item.isDeleted) { }
                        else {
                            return item
                        }
                    })
                    this.props.addPaperFileData(uploadedDocumentData);
                    this.props.updateTaxDocument(taxReturn);
                }
            }
        });
    }

    createLeftPanel = () => {
        var _self = this;
        let paperFileData: PaperFileUploadData[] = this.state.uploadedDocumentData;
        paperFileData = paperFileData && paperFileData.filter((item, index) => item.isDeleted == false);

        return (<BookmarkTree
            sectionTitle="Paper File Forms"
            headerContent={
                <button
                    id="btn-add"
                    title="Add Paper File"
                    onClick={() => { this.onPaperFileDocument(); }}
                    data-test-auto="FB96444E-B275-452B-BBB1-1A27170A0B40"
                    className="btn-plus btn-success fa fa-plus">
                </button>
            }
            bodyContent={ 
                paperFileData && paperFileData.map(function (item: any, index: number) {
                    var formName = _self.getAuthorityName(item.authorityId) + "_" + item.formName;
                    return (<div
                        key={index}
                        className="custom-list-group-item bookmark-item " >
                        <div className="bookmark-row">
                            <i className="bookmark-icon fas fa-pen-nib text-green"></i>
                            <div
                                className="bookmark-name wordbreak ellipsis cursor-pointer"
                                style={{ width: "calc(100% - 27px)" }}
                                data-test-auto="F63341A2-1B6A-443A-BAC4-950DCAF80390"
                                title={formName}
                                onClick={() => { _self.onDocumentClick(item.fileName); }}>
                                {
                                    formName
                                }
                            </div>
                            <div className="cursor-pointer" data-test-auto="6E7DE0DF-CA2A-4B13-AC2D-9C6B104A6E82">
                                <i className={"fas fa-times cursor-pointer text-danger"}
                                    onClick={() => { _self.onDeleteDocument(item.fileName) }}
                                    title={"Delete"} />
                            </div>
                        </div>
                    </div>)
                })
            }
            isNoBodyContent={paperFileData && paperFileData.length > 0 ? false : true}
        />)
    }

    createRightPanel = () => {
        return (<>
            {
                this.props.userSettings.settings.useSignatureStamp &&
                <EROSignatureCard
                    title="ERO Signature Stamp"
                    selectedEROSigner={this.state.selectedEROSigner}
                    eroSigner={this.state.eroSigners}
                    onEROSignerChange={this.handleEROSignerChange}
                    isAssignedToLoggedinUser={this.props.isAssignedToLoggedinUser}
                    onSignatureControlFocus={this.onSignatureControlFocus}
                    dragStart={this.dragStartSignatureControl}
                    dragEnd={this.dragEnd}
                />
            }
            {this.state.selectedPaperFile &&
                this.state.selectedPaperFile.fileName &&
                <PaperFileInfo
                    authority={this.state.authority}
                    updateTaxDocument={this.props.updateTaxDocument}
                    taxReturn={this.props.taxReturn}
                    selectedPaperFile={this.state.selectedPaperFile}
                    onAddressChange={this.onAddressChange}
                    onAuthorityChange={this.onAuthorityChange}
                    handleBlurAddress={this.handleBlurAddress}
                    handleBlurFormName={this.handleBlurFormName}
                    onFormNameChange={this.onFormNameChange}
                />
            }
        </>);
    }

    createPages = () => {
        const pages: number[] = [];
        if (this.state.selectedPaperFile &&
            this.state.selectedPaperFile.pdf &&
            this.state.selectedPaperFile.pdf.getLastPage()) {
            const pageCount = this.state.selectedPaperFile.pdf.getLastPage();
            for (var i = 0; i < pageCount; i++) {
                pages.push(i + 1);
            }
        }
        return pages;
    }

    render() {
        const pages= this.createPages();
        return (<>
            <AwesomePdfViewerUrl ref={(ref: any) => this._pdfViewer = ref}
                leftPanel={this.createLeftPanel()}
                rightPanel={this.createRightPanel()}
                currentPage={this.state.currentPage}
                pages={pages}
                pdfUrl={this.state.selectedPaperFile &&
                    this.state.selectedPaperFile.pdf &&
                    this.state.selectedPaperFile.sasUrl}
                enableMoveTo={false}
                signatureControls={this.state.selectedPaperFile &&
                    this.state.selectedPaperFile.pdf &&
                    this.getEroSignatureControls()
                }
                isAssignedToLoggedinUser={this.props.isAssignedToLoggedinUser}
                isIndividual={isIndividual(this.props.taxReturn)}
                keyPrefix={"paper-file-control-"}
                onControlRemove={this.onControlRemove}
                onControlUpdate={this.onControlUpdate}
                scale={this.state.scale}
                onScaleChange={(s) => this.setState({ scale: s })}
                onCurrentPageChange={(page) => this.setState({ currentPage: page })}
                displayViewPanelText={PaperFile.fileNotFound}
            />
            {
                this.state.showUploadModal == true && <PaperFileUploadModal showUploadModal={this.state.showUploadModal}
                    onCloseModal={this.onCloseModal}
                    taxReturn={this.props.taxReturn}
                    authority={this.state.authority}
                    getUploadLink={this.props.getPaperFileUploadLink}
                    maxFileLimitUpload={30}
                    onSubmit={this.onSubmit}
                    deleteUploadedPaperFileFromBlob={this.props.deleteUploadedPaperFileFromBlob}
                    convertToPdfAsync={this.props.convertToPdfAsync}
                />
            }
        </>)
    }
}
