import { addTask } from 'domain-task';
import { Action, Reducer } from 'redux';
import { actionTypes } from '../../types/ActionTypes';
import { AppThunkAction } from '../../store';
import { IUserModel } from '../../Core/ViewModels/User/UserViewModel';
import { NotificationAction, StatusType } from '../../store/common/NotificationStore';
import { API_BASE_URL } from '../../utils/constants';

export interface ISFUserData {
    users: IUserModel[];
    isLoading: boolean;
}

interface RequestSFUserListAction {
    type: actionTypes.REQUEST_SF_USER_LIST;
}

interface ReceiveSFUserListAction {
    type: actionTypes.RECEIVE_SF_USER_LIST;
    users: IUserModel[];
}

interface ErrorSFUserSettings {
    type: actionTypes.ERROR_SF_USER_MANAGEMENT;
    statusMessage: string;
    statusType: StatusType;
}

type KnownAction =
    DispatchAction |
    NotificationAction;

type DispatchAction =
    RequestSFUserListAction |
    ReceiveSFUserListAction |
    ErrorSFUserSettings ;

export const actionCreators = {
    requestAllSFUsers: (reload: boolean = false): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let state = getState();
        if (reload || state.sfUserList.users.length === 0) {
            const fetchTask = fetch(API_BASE_URL + 'api/UserManagement/GetAllSignatureUsers', { credentials: 'include' })
                .then(response => response.json() as Promise<IUserModel[]>)
                .then(data => {
                    dispatch({
                        type: actionTypes.RECEIVE_SF_USER_LIST, users: data
                    });
                }).catch(function (error) {
                    dispatch({
                        type: actionTypes.ERROR_SF_USER_MANAGEMENT, statusMessage: error,
                        statusType: StatusType.Error
                    });
                });
            addTask(fetchTask);
            dispatch({ type: actionTypes.REQUEST_SF_USER_LIST });
        }
    }
};

const unloadedState: ISFUserData = {
    users: [],
    isLoading: false,   
} as ISFUserData;

export const reducer: Reducer<ISFUserData> = (state = unloadedState, incomingAction) => {
    const action = incomingAction as DispatchAction;
    switch (action.type) {
        case actionTypes.REQUEST_SF_USER_LIST:            
            return {
                users: [...state.users],
                isLoading: false
            } as ISFUserData;
        case actionTypes.RECEIVE_SF_USER_LIST:
            return {
                users: action.users,
                isLoading: false
            } as ISFUserData;        
        case actionTypes.ERROR_SF_USER_MANAGEMENT:
            const newState = { ...state }
            let errorState: ISFUserData = newState;
            errorState.isLoading = false;
            return errorState;
    }

    return state;
};