import * as React from 'react';
import { FormGroup, FormLabel, Col, FormControl } from 'react-bootstrap';
import Select from 'react-select';
import { ITaxingAuthority, IK1ShareHolder, K1Property, SelectOptions } from '../../TaxReturn'

export interface RegroupToK1Props {
    k1Data: IK1ShareHolder,
    shareHolders: SelectOptions[],
    pageNo: number,
    onKOneDataUpdate: (propertyType: K1Property , value: any, pageNumber: number) => void,
}

export class ReGroupToKOne extends React.Component<RegroupToK1Props>
{
    render() {
        return (
            <div className="panel-body pad15">
                <FormGroup className="row">
                    <Col className="text-left" sm={3} as={FormLabel}>
                        Shareholder Name
                        </Col>
                    <Col sm={7}>
                        <Select
                            name="VoucherRefundTaxingAuthority"
                            value={this.props.k1Data.shareHolderId}
                            onChange={(event: any) => { this.props.onKOneDataUpdate(K1Property.shareHolderID, event?.value, this.props.pageNo)}}
                            options={this.props.shareHolders}
                            data-test-auto="FC197391-F39C-4D15-A3F1-0339E2BE7B76"
                            clearable={false}
                        >
                        </Select>
                    </Col>
                </FormGroup>
                <FormGroup className="row">
                    <Col className="text-left" sm={3} as={FormLabel}>
                        SSN
                        </Col>
                    <Col sm={7}>
                        <FormControl
                            type="text"
                            value={this.props.k1Data.ssn}
                            placeholder="SSN"
                            data-test-auto="6905E131-2D90-47AD-B155-C90B6A8D5EF9"
                            readOnly={true}
                        />
                    </Col>
                </FormGroup>
                <FormGroup className="row">
                    <Col className="text-left" sm={3} as={FormLabel}>
                        Form Name
                        </Col>
                    <Col sm={7}>
                        <FormControl
                            type="text"
                            value={this.props.k1Data.formName}
                            placeholder="Form Name"
                            onChange={(event: any) => { this.props.onKOneDataUpdate(K1Property.formName, event.target?.value, this.props.pageNo) }}
                            data-test-auto="1EDD64F5-0795-4F83-A87A-8C3CDF5FBC43"
                        />
                    </Col>
                </FormGroup>
            </div>
        );


    }

}