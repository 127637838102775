import * as React from "react";
import { addTask } from "domain-task";
import { RouteComponentProps } from "react-router";
import * as signalR from "@microsoft/signalr";
import * as CompanyStore from "../store/company/CompanyStore";
import * as CustomColumnStore from "../store/common/CustomColumnStore";
import * as DeliveredReturnsStore from "../store/reports/DeliveredReturnsStore";
import * as ArchivedReturnsStore from "../store/reports/archive/ArchivedReturnsStore";
import * as DeliveredGroupedReturnsStore from "../store/reports/GroupedReturns/DeliveredGroupedReturns/DeliveredGroupedReturnsStore";
import { IUserProfile } from "../components/navigation/profile/ProfileObjects";
import * as TaxDocumentStore from "../store/common/TaxDocumentStore";
import { VenusNotifier } from "../components/helper/VenusNotifier";
import { DocumentStatus, ITaxReturn } from "../components/common/TaxReturn";
import { handleResponse } from "../store/Library";
import { DeliveredReturnsState, DeliveredReturnsTableModel } from "../store/reports/DeliveredReturnsState";
import * as InboxStore from "../store/inbox/InboxStore";
import { IInboxMessage } from "../Core/ViewModels/User/InboxMessage";
import { API_BASE_URL } from "../utils/constants";
import store from "../store";
import { LogoutCause } from "../store/auth/reducer";
import { userPrivilegeChanged } from "../store/auth/actions";
import { logger } from "../routes/LoggedIn";
import { IEventTelemetry } from "@microsoft/applicationinsights-web";

const isEqual = require("react-fast-compare");
//const apiBaseUrl: any = 'http://localhost:7071/';
//const apiBaseUrl: any = 'https://signalrhubapptest.azurewebsites.net/';
const bearerPrefix: string = "Bearer ";

export interface ISignalRState {
    apiBaseUrl: any;
}
type SignalRProps = {
    profile: IUserProfile;
    companyData: CompanyStore.ICompanyData;
    taxDocuments: TaxDocumentStore.ITaxDocumentDictionary;
    deliveredReturns: DeliveredReturnsState;
    customColumn: CustomColumnStore.ICustomColumnState;
    requestTaxDocument: (id: number, force: boolean) => void;
    getMyDownloadsListAsync: () => void;
} & typeof CompanyStore.actionCreators &
    typeof TaxDocumentStore.actionCreators &
    typeof DeliveredReturnsStore.actionCreators &
    typeof CustomColumnStore.actionCreators &
    typeof InboxStore.actionCreators &
    typeof DeliveredGroupedReturnsStore.actionCreators &
    typeof ArchivedReturnsStore.actionCreators &
    RouteComponentProps<{}>;

export interface INotificationMessage {
    documentId: number;
    documentGuid: string;
    companyId: number;
    notifictionType: NotifictionType;
    data: any;
}

export interface INotificationMetaData {
    clientId: string;
}

export interface IUserAutoLogoutNotificationMessage {
    users: number[];
    logoutCause: LogoutCause;
    companyId: number;
}

export enum NotifictionType {
    None = "None",
    DeliverySuccess = "DeliverySuccess",
    DeliveryFailed = "DeliveryFailed",
    RecognizerCompleted = "RecognizerCompleted",
    RecognizerFailed = "RecognizerFailed",
    DocumentRecalled = "DocumentRecalled",
    DocumentDeleted = "DocumentDeleted",
    BulkArchiveSuccess = "BulkArchiveSuccess",
    BulkArchiveError = "BulkArchiveError",
    BulkSendReminderSuccess = "BulkSendReminderSuccess",
    BulkSendReminderError = "BulkSendReminderError",
    BulkSaveCustomColumnSuccess = "BulkSaveCustomColumnSuccess",
    BulkSaveCustomColumnError = "BulkSaveCustomColumnError",
    BulkRemoveCustomColumnSuccess = "BulkRemoveCustomColumnSuccess",
    BulkRemoveCustomColumnError = "BulkRemoveCustomColumnError",
    BulkDownloadEFileSuccess = "DownloadEFileSuccess",
    BulkDownloadEFileError = "DownloadEFileError",
    BulkUpdateReminderSuccess = "BulkUpdateReminderSuccess",
    BulkUpdateReminderError = "BulkUpdateReminderError",
    BulkExportToExcelSuccess = "BulkExportToExcelSuccess",
    BulkExportToExcelError = "BulkExportToExcelError",
    TaxDocumentRevisionRecognitionCompleted = "TaxDocumentRevisionRecognitionCompleted",
    TaxDocumentRevisionRecognitionError = "TaxDocumentRevisionRecognitionError",
    DownloadAllFileSuccess = "DownloadAllFileSuccess",
    DownloadAllFileError = "DownloadAllFileError",
    BulkDocumentOfficeLocationChangeSuccess = "BulkDocumentOfficeLocationChangeSuccess",
    BulkDocumentOfficeLocationChangeFailure = "BulkDocumentOfficeLocationChangeFailure"
    //BulkGroupArchiveSuccess = "BulkGroupArchiveSuccess",
    //BulkGroupArchiveError = "BulkGroupArchiveError"
}
export const NotificationConstants = {
    RecognizerCompleted: "Recognizer Completed",
    RecognizerFailed: "Recognizer Failed",
    DeliverySuccess: "delivered successfully",
    DeliveryFailed: "delivery failed"
};

export class SignalRWebSocket extends React.Component<SignalRProps, ISignalRState> {
    private user: string = "";
    private group: string = "";
    private token: any = "";
    state = {
        apiBaseUrl: ""
    };

    componentDidMount() {
        this.getToken();
    }
    private getToken() {
        const user = store.getState().auth.user;
        this.token = user.access_token;
        this.getWebSocketUrl();
    }
    private getWebSocketGroup = (companyId: number) => {
        var group = "00000000" + companyId;
        return "ssr" + group.substr(group.length - 8);
    };
    shouldComponentUpdate(nextProps: SignalRProps, nextState: ISignalRState) {
        return (
            (nextProps.profile?.userId !== this.props.profile?.userId &&
                nextProps.profile.userId !== 0 &&
                nextProps.companyData.companyProfile.companyInfo?.id !== 0) ||
            !isEqual(this.state, nextState)
        );
    }

    private signalRConnectionInit = () => {
        let fetchTask = fetch(`${this.state.apiBaseUrl}/signalr/negotiate`, {
            method: "POST",
            headers: {
                "x-ms-signalr-userid": this.user,
                Authorization: bearerPrefix + this.token,
                ClientType: "SSR-CPA"
            }
        })
            .then(handleResponse)
            .then((info: any) => {
                info.accessToken = info.accessToken || info.accessKey;
                info.url = info.url || info.endpoint;

                const options = {
                    accessTokenFactory: () => info.accessToken
                };

                const connection = new signalR.HubConnectionBuilder()
                    .withUrl(info.url, options)
                    .withAutomaticReconnect()
                    .configureLogging(signalR.LogLevel.Information)
                    .build();
                console.log(connection);
                connection.on("DocumentStatusUpdated", this.processDocumentData);
                connection.on("InboxMessageCreated", this.inboxMessageReceived);
                connection.on("UserPrivilegesChanged", this.notifyUserPrivilegeChange);

                connection.onclose(() => {
                    console.log("disconnected");
                    this.startConnection(connection);
                });
                this.startConnection(connection, true);
            })
            .catch((reason) => {
                console.log(reason);
            });
        addTask(fetchTask);
    };
    private startConnection = (connection: any, isInitialConnectionCall = false) => {
        const _self: any = this;
        console.log("connecting...");
        connection
            .start()
            .then(function () {
                console.log("connected!");
                _self.logSignalREvent("SignalRConnectionStarted", isInitialConnectionCall);
                _self.addGroup();
                connection.invoke("getConnectionId").then(function (connectionId: any) {
                    // Send the connectionId to controller
                });
            })
            .catch(function (err: any) {
                console.error(err);
                setTimeout(_self.startConnection, 5000, connection);
            });
    };

    private logSignalREvent = (eventName: string, isInitialConnectionCall: boolean = false) => {
        let signalREvent: IEventTelemetry = {
            name: eventName,
            properties: { UserId: this.user, ClientType: "SSR-CPA", IsReconnection: !isInitialConnectionCall }
        } as IEventTelemetry;

        logger.trackEvent(signalREvent);
    };

    private getCookieValue = (key: string) => {
        const b: any = document.cookie.match("(^|[^;]+)\\s*" + key + "\\s*=\\s*([^;]+)");
        return b ? b.pop() : "";
    };
    private addGroup = () => {
        const fetchTask = fetch(`${this.state.apiBaseUrl}/signalr/AddToGroup`, {
            method: "POST",
            headers: {
                "x-ms-signalr-userid": this.user,
                Authorization: bearerPrefix + this.token,
                ClientType: "SSR-CPA"
            },
            body: JSON.stringify({
                recipient: this.user,
                groupname: this.group
            })
        })
            .then((resp: any) => {
                if (resp.status == 200) {
                    console.log("User added to the group successfully");
                }
            })
            .catch((error: any) => {
                console.log(error);
            });
        addTask(fetchTask);
    };

    private removeGroup = () => {
        const fetchTask = fetch(`${this.state.apiBaseUrl}/signalr/RemoveFromGroup`, {
            method: "POST",
            headers: {
                "x-ms-signalr-userid": this.user,
                Authorization: bearerPrefix + this.token,
                ClientType: "SSR-CPA"
            },
            body: JSON.stringify({
                recipient: this.user,
                groupname: this.group
            })
        })
            .then((resp: any) => {
                if (resp.status == 200) {
                    console.log("User removed from group successfully");
                }
            })
            .catch((error: any) => {
                console.log(error);
            });
        addTask(fetchTask);
    };
    private getWebSocketUrl = () => {
        const fetchTask = fetch(API_BASE_URL + "api/WebSocket/GetWebSocketConnectionUrl", {
            method: "GET",
            credentials: "include"
        })
            .then(handleResponse)
            .then((resp: any) => {
                this.setState({ apiBaseUrl: resp });
            })
            .catch((error: any) => {
                console.log(error);
            });
        addTask(fetchTask);
    };
    private processDocumentData = (notificationMessage: INotificationMessage) => {
        //let taxDocument: ITaxReturn;
        let metaData: INotificationMetaData;
        console.log(notificationMessage);
        let errorMessage = "";
        switch (notificationMessage.notifictionType) {
            case NotifictionType.DeliverySuccess:
                //taxDocument = this.props.taxDocuments[notificationMessage.documentId].taxReturn;
                this.props.notifyTaxDocument(notificationMessage.documentId, DocumentStatus.DELIVERED);
                // VenusNotifier.Success(taxDocument.clientId + " " + NotificationConstants.DeliverySuccess  , "Success");
                break;
            case NotifictionType.DeliveryFailed:
                //taxDocument = this.props.taxDocuments[notificationMessage.documentId].taxReturn;
                this.props.notifyTaxDocument(notificationMessage.documentId, DocumentStatus.ERROR);
                // VenusNotifier.Error(taxDocument.clientId + " " + NotificationConstants.DeliveryFailed , "Error");
                break;
            case NotifictionType.RecognizerCompleted:
                this.props.notifyTaxDocument(notificationMessage.documentId, DocumentStatus.READY);
                if (notificationMessage.data) {
                    metaData = JSON.parse(notificationMessage.data);
                    //  VenusNotifier.Success(NotificationConstants.RecognizerCompleted + " " + metaData.clientId, "Success");
                }
                break;
            case NotifictionType.RecognizerFailed:
                this.props.notifyTaxDocument(notificationMessage.documentId, DocumentStatus.ERROR);
                if (notificationMessage.data) {
                    metaData = JSON.parse(notificationMessage.data);
                    // VenusNotifier.Error(NotificationConstants.RecognizerFailed + " " + metaData.clientId, "Error");
                }
                break;

            case NotifictionType.BulkArchiveSuccess:
            case NotifictionType.BulkSendReminderSuccess:
            case NotifictionType.BulkDocumentOfficeLocationChangeSuccess:
                this.props.updateDeliveredReturns();
                break;

            case NotifictionType.BulkSaveCustomColumnSuccess:
            case NotifictionType.BulkRemoveCustomColumnSuccess:
                this.props.updateDeliveredReturns();
                this.props.requestCustomColumn(true);
                break;
            case NotifictionType.BulkUpdateReminderSuccess:
                let successMessage = this.getNotificationMessage(notificationMessage.notifictionType);
                VenusNotifier.Success(successMessage, "Success");
                break;

            case NotifictionType.BulkArchiveError:
            case NotifictionType.BulkSendReminderError:
            case NotifictionType.BulkSaveCustomColumnError:
            case NotifictionType.BulkRemoveCustomColumnError:
            case NotifictionType.BulkDocumentOfficeLocationChangeFailure:
                this.props.updateDeliveredReturns();
                errorMessage = this.getNotificationMessage(notificationMessage.notifictionType);
                VenusNotifier.Error(errorMessage, null);
                break;
            case NotifictionType.BulkUpdateReminderError:
                errorMessage = this.getNotificationMessage(notificationMessage.notifictionType);
                VenusNotifier.Error(errorMessage, null);
                break;
            case NotifictionType.BulkDownloadEFileSuccess:
            case NotifictionType.BulkDownloadEFileError:
            case NotifictionType.BulkExportToExcelSuccess:
            case NotifictionType.BulkExportToExcelError:
            case NotifictionType.DownloadAllFileSuccess:
            case NotifictionType.DownloadAllFileError:
                setTimeout(() => {
                    this.props.getMyDownloadsListAsync();
                }, 3000);
                break;
            case NotifictionType.TaxDocumentRevisionRecognitionCompleted:
            case NotifictionType.TaxDocumentRevisionRecognitionError:
                this.props.updateDeliveredReturnRevisionStatus(notificationMessage.documentGuid, notificationMessage.documentId);
                this.props.updateArchivedReturnsRevisionStatus(notificationMessage.documentGuid, notificationMessage.documentId);
                this.props.updateDeliveredGroupedReturnRevisionStatus(
                    notificationMessage.documentGuid,
                    notificationMessage.documentId
                );
                break;
            //case NotifictionType.BulkGroupArchiveSuccess:
            //    this.props.updateDeliveredGroupedReturns();
            //    let successMsg = this.getNotificationMessage(notificationMessage.notifictionType);
            //    VenusNotifier.Success(successMsg, "Success")
            //    break;
            //case NotifictionType.BulkGroupArchiveError:
            //    this.props.updateDeliveredGroupedReturns();
            //    errorMessage = this.getNotificationMessage(notificationMessage.notifictionType);
            //    VenusNotifier.Error(errorMessage, null);
            //    break;
        }
    };

    getNotificationMessage = (notificationType: NotifictionType): string => {
        switch (notificationType) {
            case NotifictionType.BulkArchiveError:
                //case NotifictionType.BulkGroupArchiveError:
                return "Failed to archive the selected document(s)!";
            //case NotifictionType.BulkGroupArchiveSuccess:
            //    return "Successfully archived the selected document(s)!";
            case NotifictionType.BulkSendReminderError:
                return "Failed to send reminder for the selected document(s)!";
            case NotifictionType.BulkSaveCustomColumnError:
                return "Failed to update custom column for the selected document(s)!";
            case NotifictionType.BulkRemoveCustomColumnError:
                return "Failed to remove custom column for the selected document(s)!";
            case NotifictionType.BulkUpdateReminderError:
                return "Failed to update reminder for the selected document(s)!";
            case NotifictionType.BulkUpdateReminderSuccess:
                return "Successfully updated reminder settings for the selected tax returns";
            default:
                return "";
        }
    };

    inboxMessageReceived = (inboxMessage: IInboxMessage): void => {
        this.props.inboxMessageArrived(inboxMessage);
    };

    notifyUserPrivilegeChange = (notificationMessage: IUserAutoLogoutNotificationMessage): void => {
        logger.trackTrace(
            `User Permission Change Triggered from SignalR with logoutCause: ${JSON.stringify(notificationMessage)}`
        );
        if (
            notificationMessage.users.includes(this.props.profile.userId) &&
            notificationMessage.companyId === this.props.companyData.companyProfile.companyInfo!.id
        ) {
            store.dispatch(userPrivilegeChanged(notificationMessage));
        }
    };

    public render() {
        if (
            this.state.apiBaseUrl != "" &&
            this.props.companyData.companyProfile.companyInfo?.id > 0 &&
            this.props.profile?.userId !== 0
        ) {
            this.user = this.props.companyData.companyProfile.companyInfo.id + "_" + this.props.profile.userId;
            this.group = this.getWebSocketGroup(this.props.companyData.companyProfile.companyInfo.id);
            this.signalRConnectionInit();
        }

        return <div />;
    }
}
export default SignalRWebSocket;
