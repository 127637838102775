import { addTask } from 'domain-task';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../';
import { ITaxReturn } from '../../components/common/TaxReturn';
import { actionTypes } from '../../types/ActionTypes';
import { RequestUserAssignmentsAction, ReceiveUserAssignmentsAction, RequestUserAssignmentsPagesAction, ReceiveUserAssignmentsPagesAction } from "./KnownTypes";
import { ReceiveTaxDocumentAction, DeleteTaxDocumentAction, TaxReturnSource, ResetTaxReturnsAction} from '../common/TaxDocumentStore';
import { IAssignmentsState } from "./AssignmentsState";
import { handleResponse } from '../Library';
import { StatusType, NotificationAction } from '../common/NotificationStore';
import { API_BASE_URL } from '../../utils/constants';

type AllKnownAction =
    RequestUserAssignmentsAction |
    ReceiveUserAssignmentsAction |
    ReceiveTaxDocumentAction |
    RequestUserAssignmentsPagesAction |
    ReceiveUserAssignmentsPagesAction |
    NotificationAction;
   
type DispatchActions =
    RequestUserAssignmentsAction |
    ReceiveUserAssignmentsAction |
	ReceiveTaxDocumentAction |
	ResetTaxReturnsAction |
    DeleteTaxDocumentAction

export const actionCreators = {
    requestUserAssignments: (query: string, reload: boolean = false, callback?: () => void): AppThunkAction<AllKnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        let state = getState();
        if (reload || query !== state.userAssignments.query) {
            if (!reload) {
                dispatch({ type: actionTypes.REQUEST_USER_ASSIGNMENTS, query: query });
                return;
            }
            const fetchTask = fetch(`${API_BASE_URL}api/UserAssignments` + query, {
                method: 'GET',
                credentials: 'include'
            })
                .then(handleResponse)
                .then(response => response as Promise<{ count: number, documents: ITaxReturn[] }>)
                .then(data => {
                    dispatch({ type: actionTypes.RECEIVE_USER_ASSIGNMENTS, query: query, assignments: data.documents, totalRowCount: data.count });
                    dispatch({ type: actionTypes.RECEIVE_USER_ASSIGNMENTS_PAGES, query: query, assignments: data.documents, totalRowCount: data.count });

                    data.documents.forEach((model, i) => {
                        dispatch({ type: actionTypes.RECEIVE_TAX_DOCUMENT, id: model.id, taxDocument: model, source: TaxReturnSource.UserAssignments });
                    });
                    if (callback) {
                        callback();
                    }
                })
                .catch(error => {
                    const statusMessage: any = error.statusText?.message ?? error.statusText;
                    if (typeof(statusMessage) === "string") {
                        dispatch({ type: actionTypes.NOTIFICATION, statusMessage: statusMessage, statusType: StatusType.Error });
                    }
                    if (callback) {
                        callback();
                    }
                });
            addTask(fetchTask);
            dispatch({ type: actionTypes.REQUEST_USER_ASSIGNMENTS, query: query });
            dispatch({ type: actionTypes.REQUEST_USER_ASSIGNMENTS_PAGES, query: query });
        }
    }
};

const unloadedState: IAssignmentsState = {
    assignments: [],
    query: '?',
    isLoading: false,
    totalRowCount: 0
} as IAssignmentsState;

export const reducer: Reducer<IAssignmentsState> = (state = unloadedState, incomingAction) => {
    const action = incomingAction as DispatchActions;
    switch (action.type) {
        case actionTypes.REQUEST_USER_ASSIGNMENTS:
            return ({
                ...unloadedState,
                query: action.query,
                isLoading: true
            }) as IAssignmentsState;

        case actionTypes.RECEIVE_USER_ASSIGNMENTS:
            return {
                query: action.query,
                assignments: action.assignments,
                totalRowCount: action.totalRowCount,
                isLoading: false
            } as IAssignmentsState;

        case actionTypes.RECEIVE_TAX_DOCUMENT:
            if (action.source !== TaxReturnSource.UserAssignments) {//Ignore calls originated from this store
                let newList: ITaxReturn[] = [];
                state.assignments.map((item, i) => {
                    newList.push((item.id === action.id) ? action.taxDocument : item);
                });
                return {
                    query: state.query,
                    assignments: newList,
                    totalRowCount: state.totalRowCount,
                    isLoading: false
                } as IAssignmentsState;
            }
            break;
		case actionTypes.DELETE_TAX_DOCUMENT:
		case actionTypes.RESET_TAX_RETURNS:
            //clearing the dictionary on deletion
            return {
                ...unloadedState,
                query: "",
                isLoading: true
            } as IAssignmentsState;
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            const exhaustiveCheck: never = action; 
    }
    return state;
};
