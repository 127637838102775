import * as React from 'react';
import { Card, Accordion, Popover, OverlayTrigger } from 'react-bootstrap';

import * as TaxDocument from '../../../common/TaxReturn';
import * as Helper from '.././../../helper/HelperFunctions';
import { IRefundInfo, ITaxingAuthority } from '../../../common/TaxReturn';
import * as Constants from '../../../helper/Constants';
import { AddedRefundPopover } from '../AddedRefundPopover';
import { logger } from '../../../../routes/LoggedIn';


export interface IAddedRefundProps {
    taxReturn: TaxDocument.ITaxReturn;
    onAddedRefundDelete: (refundInfo: IRefundInfo) => void;
    onUpdateAddedRefund: (refundInfo: IRefundInfo) => void;
    onAddRefund: (refundInfo: IRefundInfo) => void;
    getAllTaxingAuthorities: ITaxingAuthority[];
}
export interface IAddedRefundState {
    refund: IRefundInfo;
    addedRefundPopoverTitle: string;
    editAddedRefund: boolean;
    refundValue: number;
    showAlert: boolean;
    message: string;
    expandAddedRefundListCard: boolean;
    enableLeftCardScroll: boolean;
}
export const initialRefund: IRefundInfo = {
    overPayment: 0,
    authorityID: 0,
    documentId: 0,
    id: 0,
    overPaymentApplied: 0,
    penalty: 0,
}
export class AddedRefundList extends React.Component<IAddedRefundProps, IAddedRefundState> {
    constructor(props: IAddedRefundProps) {
        super(props);
        this.state = {
            addedRefundPopoverTitle: "",
            refund: initialRefund,
            editAddedRefund: false,
            refundValue: 0,
            showAlert: false,
            message: "",
            expandAddedRefundListCard: true,
            enableLeftCardScroll: true
        }
        this.addedRefundAuthorityChange = this.addedRefundAuthorityChange.bind(this);
        this.onChangeRefundAmount = this.onChangeRefundAmount.bind(this);
        this.handleOverPaymentApplied = this.handleOverPaymentApplied.bind(this);
        this.submitAddedRefundPopover = this.submitAddedRefundPopover.bind(this);
        this.onCancelAddedRefundPopover = this.onCancelAddedRefundPopover.bind(this);
        this.onAddedRefundDelete = this.onAddedRefundDelete.bind(this);
    }


    public closeDeleteConfirmPopHover() {
        document.body.click();
    }
    enableScroll = () => {
        if (!this.state.enableLeftCardScroll) {
            const scrollDiv = (document.querySelector(".scroll div div") as HTMLElement);
            scrollDiv.style.overflow = "scroll";
            scrollDiv.style.marginRight = "-17px";
            ((document.querySelector(".scroll div") as HTMLElement).children[2] as HTMLElement).style.display = "block";
            this.setState({ enableLeftCardScroll: true });
        }

    }
    stopScroll = () => {
        if (this.state.enableLeftCardScroll) {
            const scrollDiv = (document.querySelector(".scroll div div") as HTMLElement);
            const top: number = scrollDiv.scrollTop;
            scrollDiv.style.overflow = "hidden";
            scrollDiv.style.marginRight = "0px";
            scrollDiv.scrollTop = top;
            ((document.querySelector(".scroll div") as HTMLElement).children[2] as HTMLElement).style.display = "none";
            this.setState({ enableLeftCardScroll: false });
        }

    }
    onAddNewRefunds = () => {
        this.setState({
            addedRefundPopoverTitle: "Add Refund",
            refund: initialRefund,
            editAddedRefund: false,
            refundValue: 0,
            showAlert: false,
        })
        logger.trackTrace(`TabClientInfo --> onAddNewRefunds executes`, { 'DocumentId': this.props.taxReturn.id?.toString(), 'DocumentGuid': this.props.taxReturn.documentGuid })
    }

    onSelectAddedRefund(refund: IRefundInfo) {
        this.setState({
            addedRefundPopoverTitle: "Edit Refund",
            refund: refund,
            editAddedRefund: true,
            refundValue: refund.overPayment - refund.overPaymentApplied - refund.penalty,
            showAlert: false,
        })
    }

    getTitle(authorities: ITaxingAuthority[], authorityId: number, amount: number) {
        let authority: ITaxingAuthority = authorities[authorities.findIndex(x => x.Id == authorityId)];
        if (authority) {
            return [
                <div title={authority.AuthorityName} className="bookmark-name wordbreak ellipsis">{authority.AuthorityName}</div>,
                <div className="bookmark-right bookmark-amount" title={'$' + Helper.formatCurency(Number(amount))}>
                    {'$' + Helper.formatCurency(Number(amount))}
                </div>
            ]
        } else {
            return null;
        }
    }

    addedRefundAuthorityChange(event: any) {
        let refund: IRefundInfo = Object.assign({}, this.state.refund);
        refund.authorityID = parseInt(event.target.value);
        this.setState({ refund: refund })
    }

    onChangeRefundAmount(value: number) {
        let refund: IRefundInfo = Object.assign({}, this.state.refund);
        refund.overPayment = value;
        this.setState({
            refund: refund,
            refundValue: refund.overPayment - refund.overPaymentApplied - refund.penalty,
            showAlert: refund.overPayment - refund.overPaymentApplied - refund.penalty < 0 ? true : false,
            message: refund.overPayment - refund.overPaymentApplied - refund.penalty < 0 ?
                Constants.ClientInfoTab.AddedRefund.OverpaymentLessThanWarning : ""
        })
    }
    handleOverPaymentApplied(value: number) {
        let refund: IRefundInfo = { ...this.state.refund };
        refund.overPaymentApplied = value;
        this.setState({
            refund: refund,
            refundValue: refund.overPayment - value - refund.penalty,
            showAlert: refund.overPayment - value - refund.penalty < 0 ? true : false,
            message: refund.overPayment - value - refund.penalty < 0 ?
                Constants.ClientInfoTab.AddedRefund.OverpaymentLessThanWarning : ""
        })
    }
    private onChangePenaltyAmount= (value: number)=> {
        console.log(this.state);
        
        let refund: IRefundInfo = { ...this.state.refund };
        const updateRefund = refund.overPayment - refund.overPaymentApplied - value;
        refund.penalty = value;
        this.setState({
            refund: refund,
            refundValue: updateRefund,
            showAlert: updateRefund < 0 ? true : false,
            message: updateRefund < 0 ?
                Constants.ClientInfoTab.AddedRefund.OverpaymentLessThanWarning : ""
        })
    }
    onCancelAddedRefundPopover() {
        document.body.click();
        this.setState({ showAlert: false });
    }
    submitAddedRefundPopover() {
        if (this.state.refund.authorityID == 0) {
            this.setState({ showAlert: true, message: Constants.ClientInfoTab.AddedRefund.AuthorityWarning });
            return false;
        }
        if (this.state.refund.overPayment == null) {
            this.setState({ showAlert: true, message: Constants.ClientInfoTab.AddedRefund.AmountEmptyWarning })
            return false;
        }
        if (this.state.refund.overPayment - this.state.refund.overPaymentApplied - this.state.refund.penalty < 0) {
            this.setState({ showAlert: true, message: Constants.ClientInfoTab.AddedRefund.OverpaymentLessThanWarning });
            return false;
        }
        if (this.state.editAddedRefund) {
            this.props.onUpdateAddedRefund(this.state.refund)
        }
        else {
            this.props.onAddRefund(this.state.refund)
        }
        this.setState({ refund: initialRefund, expandAddedRefundListCard: true })
        document.body.click();
    }

    onAddedRefundDelete() {
        this.props.onAddedRefundDelete(this.state.refund);
        document.body.click();
        logger.trackTrace(`TabClientInfo --> onAddedRefundDelete executes`, { 'DocumentId': this.props.taxReturn.id?.toString(), 'DocumentGuid': this.props.taxReturn.documentGuid });
    }

    public render() {
        let authorities = this.props.getAllTaxingAuthorities;
        const popover = (<Popover id="popover-refund-delete-confirm">
            <Popover.Title as="h3">{Constants.ClientInfoTab.AddedRefund.DeleteConfirm}</Popover.Title>
            <Popover.Content>
                <button
                className="btn btn-only btn-success"
                onClick={this.onAddedRefundDelete}
                title="Yes"
                data-test-auto="BCDBF6AB-57E0-4AB7-BAB2-580203E088C8"
                style={{ marginLeft: "30px" }}>
                Yes
                </button>
                <button
                onClick={this.closeDeleteConfirmPopHover}
                title="No"
                data-test-auto="8AAB3A64-BD99-411A-9F63-E627D51F1F59"
                className="btn btn-only btn-danger"
                style={{ marginLeft: "5px" }}>
                    No
                </button>
                </Popover.Content>
        </Popover>
        )

        const addedRefundPopover = (<Popover id="popover-added-refund">
            <Popover.Title as="h3">{this.state.addedRefundPopoverTitle}</Popover.Title>
            <Popover.Content>
            <AddedRefundPopover
                usStates={this.props.getAllTaxingAuthorities}
                addedRefund={this.state.refund}
                addedRefundAuthorityChange={this.addedRefundAuthorityChange}
                onChangeRefundAmount={this.onChangeRefundAmount}
                handleOverPaymentApplied={this.handleOverPaymentApplied}
                onChangePenaltyAmount={this.onChangePenaltyAmount}
                onCancelAddedRefundPopover={this.onCancelAddedRefundPopover}
                submitAddedRefundPopover={this.submitAddedRefundPopover}
                refundValue={this.state.refundValue}
                showAlert={this.state.showAlert}
                message={this.state.message}
                isEdit={this.state.editAddedRefund}
                hideAddEditOption={false}
                />
            </Popover.Content>
        </Popover>
        )
        return <div className="bookmarksPane">
            <Accordion
                activeKey={this.state.expandAddedRefundListCard ? '0' : '-1'}
                onSelect={() => this.setState({ expandAddedRefundListCard: !this.state.expandAddedRefundListCard })}
            >
            <Card>                
                        <Card.Header style={{ padding: '10px' }}>
                            <Card.Title className="float-left" as="h3" style={{ marginBottom: '0px' }}>
                                <Accordion.Toggle as="h3" eventKey="0">
                                    Added Refunds
                                </Accordion.Toggle>
                        </Card.Title>
                        <span className="float-right">
                            <OverlayTrigger rootClose trigger="click" placement="right" onEnter={() => document.body.click()} onEntered={() => { this.stopScroll() }} onExit={() => { this.enableScroll() }} overlay={addedRefundPopover}>
                                <button
                                    id="btn-addRefund"
                                    title="Add Refund"
                                    onClick={() => { this.onAddNewRefunds(); }}
                                    data-test-auto="E40061F7-7AAC-4422-98AC-760FAC2C9D66"
                                    className="btn-plus btn-success fa fa-plus">
                                </button>
                            </OverlayTrigger>
                        </span>
                    </Card.Header>
                
                <Accordion.Collapse eventKey="0">
                    <Card.Body style={{ padding: '0px' }}>
                        <div className="pageListView">
                            {
                                this.props.taxReturn.refundInfo.length == 0 ? <div className="textalign-center">No Content</div> :
                                    (this.props.taxReturn.refundInfo.map((refund, i) => {
                                        const penaltyAmount = refund.penalty || 0;
                                        return <div
                                            key={i}
                                            className="custom-list-group-item bookmark-item">
                                            <div className="bookmark-row">
                                                <div style={{ width: "24px" }}>
                                                    <i className="bookmark-icon fas fa-hand-holding-usd text-success"></i>
                                                </div>
                                                <OverlayTrigger rootClose trigger="click" onEnter={() => document.body.click()} onEntered={() => { this.stopScroll() }} onExit={() => { this.enableScroll() }} placement="right" overlay={addedRefundPopover}>
                                                    <div className="cursor-pointer" style={{ display: "inline-flex", width: "calc(100% - 46px)" }}
                                                        onClick={() => { this.onSelectAddedRefund(refund) }}>
                                                        {
                                                            this.getTitle(authorities, refund.authorityID, refund.overPayment - refund.overPaymentApplied - penaltyAmount)
                                                        }
                                                    </div>
                                                </OverlayTrigger>
                                                <OverlayTrigger rootClose trigger="click" onEnter={() => document.body.click()} onEntered={() => { this.stopScroll() }} onExit={() => { this.enableScroll() }} placement="right" overlay={popover}>
                                                    <div style={{ width: "24px" }}>
                                                        <i className="padL8 fas fa-times cursor-pointer text-danger"
                                                            onClick={() => { this.setState({ refund: refund }) }}
                                                            title={"Delete added refund"}>
                                                        </i>
                                                    </div>
                                                </OverlayTrigger>

                                            </div>
                                        </div>
                                    }))
                            }
                        </div>
                    </Card.Body>
                </Accordion.Collapse>
            </Card>
            </Accordion>
        </div>
    }
};
