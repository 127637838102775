import * as Request from 'superagent';
import { getContentType } from '../helper/Validations';
/*  Chunking Start  */
interface IFileToUpload {
    maxBlockSize: number,
    file: number,
    size: number,
    uploadFailed: boolean,
    bytesUploaded: number,
    sasUrl: string,
    completeEvent: number,
    blockIds: number[],
    uploadedIds: number[],
    filecontent: File[],
    blockIdPrefix: string,
    numberOfBlocks: number,
    fileName: string,
    contentType: string
}

export function uploadedFile(fileObject: any, sas: any, fileName: string,callback?:any) {
    let fileToUpload = {
        plid: 0,
        MID: 0,
        maxBlockSize: 0,
        file: 0,
        size: 0,
        ieVersion: 0,
        uploadFailed: false,
        bytesUploaded: 0,
        sasUrl: 0,
        completeEvent: 0,
        blockIds: new Array(),
        uploadedIds: new Array(),
        filecontent: new Array(),
        blockIdPrefix: "",
        numberOfBlocks: 0,
        fileName: "",
        contentType: "",
    };    
    fileToUpload.maxBlockSize = 4096 * 1024;
    fileToUpload.file = fileObject;
    fileToUpload.size = fileObject?.size;
    fileToUpload.uploadFailed = false;
    fileToUpload.bytesUploaded = 0;
    fileToUpload.contentType = getContentType(fileObject);
    if (fileToUpload.size < fileToUpload.maxBlockSize) {
        fileToUpload.maxBlockSize = fileToUpload.size;
    }
    if (fileToUpload.size % fileToUpload.maxBlockSize == 0) {
        fileToUpload.numberOfBlocks = fileToUpload.size / fileToUpload.maxBlockSize;
    } else {
        fileToUpload.numberOfBlocks = parseInt((fileToUpload.size / fileToUpload.maxBlockSize).toString(), 10) + 1;
    }
    fileToUpload.sasUrl = sas;
    fileToUpload.blockIdPrefix = "block-";
    fileToUpload.fileName = fileName;
    uploadFileInBlocks(fileToUpload, callback);
}


export function uploadFileInBlocks(fileToUpload: any, callback?: any) {
    for (let i = 0; i < fileToUpload.numberOfBlocks; i++) {

        let fileContent;
        if ((i + 1) * fileToUpload.maxBlockSize < fileToUpload.file?.size)
            fileContent = fileToUpload.file.slice(i * fileToUpload.maxBlockSize, (i + 1) * fileToUpload.maxBlockSize);
        else
            fileContent = fileToUpload.file.slice(i * fileToUpload.maxBlockSize, fileToUpload.file?.size);

        var blockId = fileToUpload.blockIdPrefix + pad(fileToUpload.blockIds.length, 6);
        fileToUpload.blockIds.push(btoa(blockId));
        fileToUpload.filecontent.push(fileContent);
    }
    var ajaxcaller = 10;
    if (fileToUpload.numberOfBlocks < 10)
        ajaxcaller = fileToUpload.numberOfBlocks;
    for (let i = 0; i < ajaxcaller; i++) {
        fileToUpload.nextItemtoUpload = ajaxcaller;
        initReaderObject(fileToUpload, fileToUpload.blockIds[i], fileToUpload.filecontent[i], 0, callback);
    }
}

    export function pad(number: number, length: number) {
    var str = '' + number;
    while (str.length < length) {
        str = '0' + str;
    }
    return str;
}


export function initReaderObject(fileToUpload: any, blockId: any, fileContent: any, retry: any, callback?: any) {
    return new Promise((resolve: any, reject: any) => {
        let readerObject: FileReader = new FileReader();
        readerObject.onloadend = function (evt: ProgressEvent) {
            if (evt.target && readerObject.result) {
                if (readerObject.readyState == 2) { // DONE == 2
                    var uri = fileToUpload.sasUrl + '&comp=block&blockid=' + blockId;
                    var requestData = new Uint8Array(readerObject.result as ArrayBuffer);


                    Request.put(uri)
                        .set('Access-Control-Allow-Origin', '*')
                        .set("x-ms-blob-type", "BlockBlob")
                        .send(requestData)
                        .end((err: any, res: any) => {
                            fileToUpload.bytesUploaded += requestData.length;
                            fileToUpload.uploadedIds.push(blockId);
                            if (fileToUpload.blockIds[fileToUpload.nextItemtoUpload]) {

                                initReaderObject(fileToUpload, fileToUpload.blockIds[fileToUpload.nextItemtoUpload], fileToUpload.filecontent[fileToUpload.nextItemtoUpload], 0, callback);
                                fileToUpload.nextItemtoUpload = fileToUpload.nextItemtoUpload + 1;
                            } else {
                                if (fileToUpload.uploadedIds.length === fileToUpload.blockIds.length)
                                    commitBlockList(fileToUpload, callback);
                            }
                            resolve();
                        })
                }
            }
        };
        readerObject.readAsArrayBuffer(fileContent);

    });
}

export function commitBlockList(fileToUpload: any, callback?: any) {
    var uri = fileToUpload.sasUrl + '&comp=blocklist';
    var requestBody = '<?xml version="1.0" encoding="utf-8"?><BlockList>';
    for (var i = 0; i < fileToUpload.blockIds.length; i++) {
        requestBody += '<Latest>' + fileToUpload.blockIds[i] + '</Latest>';
    }
    requestBody += '</BlockList>';
    var encryptedFileName = '';
    try {
        encryptedFileName = btoa(fileToUpload.file.name);
    } catch (exp) {
        try {
            encryptedFileName = btoa(encodeURIComponent(fileToUpload.file.name));
        } catch (exp) {
            encryptedFileName = fileToUpload.file.name.replace(/\W/g, '');
            encryptedFileName = btoa(encryptedFileName);
        }
    }

    Request.put(uri)
        .set('Access-Control-Allow-Origin', '*')
        .set('x-ms-blob-content-disposition', 'attachment;filename=' + '"' + encryptedFileName + '"')
        .set('x-ms-meta-filename', fileToUpload.fileName)
        .set('x-ms-meta-filetype', fileToUpload.contentType)       
        .send(requestBody)       
        .end((err: any, res: any) => {
            if (callback != undefined && typeof callback == "function")
                callback();
        })
}

    /*  Chunking End  */

