import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { ArchiveReturnsHeader } from './ArchiveReturns/ArchiveReturnsHeader';
import { ArchiveReturnsTable } from './ArchiveReturns/ArchiveReturnsTable';
import { CustomColumn } from './DeliveredReturns/CustomColumn';
import { IFilters, SortDirections, ReportFilterType } from './Filters';
import { VenusNotifier } from '../helper/VenusNotifier';
import { DeliveredReturnsConstants, ArchiveReturnsConstants, Common, ReportFilterConstants } from '../helper/Constants'
import * as bootbox from 'bootbox';
import { DownloadEfileForm } from '../common/DownloadEfileForms';
import { ClientTrackingModal } from '../common/ClientTracking';
import * as ArchivedReturnsStore from '../../store/reports/archive/ArchivedReturnsStore';
import * as FilterStore from '../../store/reports/FilterStore';
import * as TaxDocumentStore from '../../store/reports/archive/ArchivedTaxDocumentStore';
import * as ArchivedReturnsState from '../../store/reports/archive/ArchivedReturnsState';
import * as UserStore from '../../store/userManagement/UserStore';
import * as UserSettingStore from '../../store/userManagement/UserSettingStore';
import * as CustomColumnStore from '../../store/common/CustomColumnStore';
import { ITaxReturn, SignatureStatus, DocumentGroups, isIndividual, isMutual, isPartnership, getUnformattedClientName, DeliveryMode, PaperReturnDeliveryType  } from '../common/TaxReturn';
import { DownloadReturns } from '../common/DownloadReturns';
import * as CompanyStore from '../../store/company/CompanyStore';
import { DownloadHistory } from '../common/DownloadHistory';
import * as UploadTaxReturnStore from '../../store/common/uploadTaxReturn';
import * as AttachmentsStore from '../../store/ProcessReturn/AttachmentsStore';
import * as AttachmentsState from '../../store/ProcessReturn/AttachmentsState';
import * as BulkOperationsStore from '../../store/common/BulkOperationsStore';
import { SignedDetails } from '../common/SignedDetails';
import * as Helper from '../helper/HelperFunctions';
import { ShowLoader } from '../helper/Loader';
import { initialCompanySettings } from '../../Core/ViewModels/Company/CompanySettingsViewModel';
import * as AdditionalEsignDocumentStore from '../../store/ProcessReturn/AdditionalEsignDocuments/AdditionalEsignDocumentStore';
import { IDocumentData } from '../../components/common/ProcessReturnModal/AdditionalEsignDocuments/AdditionalEsignDocument';
import * as GroupedReturnStore from '../../store/groupedreturns/GroupedReturnStore';
import * as PaperFileStore from '../../store/ProcessReturn/PaperFileStore';
import * as K1AttachmentStore from '../../store/ProcessReturn/K1AttachmentStore';
import * as CRSHelper from '../helper/CRSHelper';

let moment = require('moment');
const pageSize: number = 20;
const NO_INDEX = -1; 

export interface INameValue {
    [index: string]: string
}

const successMsg: INameValue = {
	restoreReturnsState: 'Successfully restored the selected return(s)!',
    customColumnState: "Custom column Value(s) has been successfully updated for the Tax Returns",
    customColumnRemoveState: "Custom column value(s) has been successfully removed for the Tax Returns",
}

const errorMsg: INameValue = {
    restoreReturnsState: 'Failed to restore the selected document(s)!',
    customColumnState: "Failed to update the Custom column!",
    downloadEformFilesState: "Could not find downloadable Efile document(s) !",
    downloadReturnState:"Could not find downloadable return document(s) !"
}

// At runtime, Redux will merge together...
type ArchivedReturnsProps =
    {
        archivedReturns: ArchivedReturnsState.ArchivedReturnsState,     // ... state we've requested from the Redux store
        archiveTaxDocument: TaxDocumentStore.IArchiveTaxDocumentDictionary,
        userStore: UserStore.ISSRUserData,
        reportFilters: FilterStore.IFilterState,
        userSettings: UserSettingStore.UserSettings,
        customColumnData: CustomColumnStore.ICustomColumnState,
        company: CompanyStore.ICompanyData,
        taxDocuments: TaxDocumentStore.IArchiveTaxDocumentDictionary,
        uploadTaxReturnStore: UploadTaxReturnStore.IUploadTaxReturnState,
        attachments: AttachmentsState.AttachmentsState,
        groupedReturnStore: GroupedReturnStore.IGroupInfoState
    }
    & typeof ArchivedReturnsStore.actionCreators   // ... plus action creators we've requested
    & typeof TaxDocumentStore.actionCreators
    & typeof UserStore.actionCreators
    & typeof FilterStore.actionCreators
    & typeof UserSettingStore.actionCreators
    & typeof CustomColumnStore.actionCreators
    & typeof CompanyStore.actionCreators
    & typeof TaxDocumentStore.actionCreators        // ... plus incoming routing parameters
    & typeof UploadTaxReturnStore.actionCreators
    & typeof AttachmentsStore.actionCreators
    & typeof BulkOperationsStore.actionCreators
    & typeof AdditionalEsignDocumentStore.actionCreators
    & typeof GroupedReturnStore.actionCreators
    & typeof PaperFileStore.actionCreators
    & typeof K1AttachmentStore.actionCreators
    & RouteComponentProps<{ page: string }>;        // ... plus incoming routing parameters

export class ArchiveReturns extends React.Component<ArchivedReturnsProps, ArchivedReturnsState.ArchivedReturnsPageState> {
    private proxyFilter = ReportFilterType[ReportFilterType.Archived].toUpperCase() + ReportFilterConstants.DefaultFilterBuilder;
    private reportFilterType = ReportFilterType.Archived;
    constructor(props: ArchivedReturnsProps) {
        super(props);
        this.state = {
            page: 1,
            pageSize: pageSize,
            filter: ({
                name: '',
                searchText: '',
                sort: {
                    column: "",
                    direction: SortDirections.None
                },
                fields: {},
                isDefaultFilter: false,
                filterType: ReportFilterType.Archived,
                isMasterFilter: false,
            }),
            selectedRows: [],
            searchString: "",
            sortName: "",
            sortOrder: "desc",
            selectedTaxYear: new Date().getFullYear() - 1,
            saveFilterShow: false,
            filterClientId: "",
            filterCustomColumn: "",
            filterDeliveredOn: undefined,
            filterDownloadedBy: "",
            filterEngagementType: 0,
            filterLastReminderOn: undefined,
            filterName: "",
            filterPartner: "",
            filterSentBy: "",
            filterStatus: [],
            selectedColumnValue: 0,
            hideCustomCulumnLoader: false,
            defaultGroup: '',

			//Restore Action states
			restoreReturnsState: {
				selected: undefined,
				show: false,
                saveCheckIds: undefined,
                remove: false
			},

			//CustomColumn states
			customColumnState: {
				selected: undefined,
				show: false,
                saveCheckIds: undefined,
                remove: false
			},

			//Client Tracking states
			clientTrackingState:
			{
				model: undefined,
				show: false,
                saveCheckId: undefined
                
			},


            //Download Return State
            downloadReturnState:
            {
                model: undefined,
                show: false,
                saveCheckId: undefined
            },
            //Signed Details State
            signedDetailsState:
            {
                model: undefined,
                show: false,
                saveCheckId: undefined
            },

            //Download History State
            downloadHistoryState:
            {
                model: undefined,
                show: false,
                saveCheckId: undefined
            },
            //Download Efile Forms State
            downloadEformFilesState:
            {
                model: undefined,
                show: false,
                saveCheckId: undefined
            },
        };

        this.onPageReload = this.onPageReload.bind(this);

        this.applyDefaultFilter = this.applyDefaultFilter.bind(this);
        this.onFilterSave = this.onFilterSave.bind(this);
        this.onFilterUpdate = this.onFilterUpdate.bind(this);
        this.onFilterDelete = this.onFilterDelete.bind(this);
        this.onSetDefaultFilter = this.onSetDefaultFilter.bind(this);
        this.onSetDefaultFilterSuccess = this.onSetDefaultFilterSuccess.bind(this);
        this.onRemoveDefaultFilter = this.onRemoveDefaultFilter.bind(this);
        this.onRemoveDefaultFilterSuccess = this.onRemoveDefaultFilterSuccess.bind(this);
        this.onFilterChange = this.onFilterChange.bind(this);
        this.onSaveFilterShow = this.onSaveFilterShow.bind(this);
        this.onSaveFilterHide = this.onSaveFilterHide.bind(this);
        this.onRequestUserSettingsSuccess = this.onRequestUserSettingsSuccess.bind(this);
        //

        //Grid Operation Handlers
        this.onExportToExcel = this.onExportToExcel.bind(this);
        this.onRowSelect = this.onRowSelect.bind(this);
        this.onSelectAll = this.onSelectAll.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.onSortChange = this.onSortChange.bind(this);
        this.onSearchChange = this.onSearchChange.bind(this);
        this.onFilterNameChange = this.onFilterNameChange.bind(this);
        
        this.onRestoreReturnsOpen = this.onRestoreReturnsOpen.bind(this);
        this.onRestoreReturnsSave = this.onRestoreReturnsSave.bind(this);
        this.onRestoreCompletion = this.onRestoreCompletion.bind(this);

        this.onCustomColumnOpen = this.onCustomColumnOpen.bind(this);
        this.onCustomColumnDropdownChange = this.onCustomColumnDropdownChange.bind(this);
        this.onCustomColumnSave = this.onCustomColumnSave.bind(this);
        this.onCustomColumnRemove = this.onCustomColumnRemove.bind(this);
        this.onCustomColumnCancel = this.onCustomColumnCancel.bind(this);

        this.onClientTrackingOpen = this.onClientTrackingOpen.bind(this);
        this.onClientTrackingCancel = this.onClientTrackingCancel.bind(this);

        this.onDownloadReturnOpen = this.onDownloadReturnOpen.bind(this);
        this.onDownloadReturnCancel = this.onDownloadReturnCancel.bind(this);
        this.onDownloadReturnAll = this.onDownloadReturnAll.bind(this);
        this.onDownloadFile = this.onDownloadFile.bind(this);
        this.downloadMergedPaperFileDocument = this.downloadMergedPaperFileDocument.bind(this);
        this.onDownloadHistoryCancel = this.onDownloadHistoryCancel.bind(this);
        this.onDownloadEfileFormsOpen = this.onDownloadEfileFormsOpen.bind(this);
        this.onDownloadEfileFormsCancel = this.onDownloadEfileFormsCancel.bind(this);
        this.onMultipleDownloadEfileForms = this.onMultipleDownloadEfileForms.bind(this);
        this.onDownloadHistoryOpen = this.onDownloadHistoryOpen.bind(this);
        this.onSignedDetailsOpen = this.onSignedDetailsOpen.bind(this);

        //Support Methods
        this.selectedDocumentIds = this.selectedDocumentIds.bind(this);
        this.selectedDocumentIdsAndLoadingStatus = this.selectedDocumentIdsAndLoadingStatus.bind(this);
        this.selectedDocuments = this.selectedDocuments.bind(this);
        this.getStoreItem = this.getStoreItem.bind(this);
        this.onUpdateCompletion = this.onUpdateCompletion.bind(this);
        this.onUpdateCompletionMultiple = this.onUpdateCompletionMultiple.bind(this);
        this.checkForPopupCompletion = this.checkForPopupCompletion.bind(this);
        this.buildQuery = this.buildQuery.bind(this);
        this.fetchArchivedReturns = this.fetchArchivedReturns.bind(this);
        this.loadArchivedReturns = this.loadArchivedReturns.bind(this);

        
    }

    UNSAFE_componentWillMount() {
        this.props.requestAllSSRUsers(true);
        this.props.requestUserSettings(true, this.onRequestUserSettingsSuccess);
        this.props.requestCustomColumn(true);
        this.props.getAllReportFilter(true, this.reportFilterType);
        if (this.props.company.isDocumentStoreDirty) {
            this.props.reloadArchivedReturns();
            this.props.forceRefreshTaxDcoumentStore(false);
            this.onPageReload();
        }
        this.props.requestGroupConfiguration(true);
        this.props.requestGroupAccess(true);
    }

    UNSAFE_componentWillReceiveProps(nextProps: ArchivedReturnsProps) {
        this.checkForPopupCompletion(nextProps.archiveTaxDocument);
    }

    onRequestUserSettingsSuccess() {
        let defaultReportFilter = this.props.userSettings.settings.defaultSettings.reportFilter;
        if (!defaultReportFilter || defaultReportFilter == '') {
            this.loadArchivedReturns();
        }
    }

    //======================Grid Operation Starts here===============================

    private onExportToExcel(onExportToExcelComplete: () => void) {
        let queryString = this.buildQuery(this.state.page, pageSize);
        this.props.exportArchivedReturnsAsExcel(queryString, onExportToExcelComplete);
    }

    private onRowSelect(row: any, isSelected: any, e: React.BaseSyntheticEvent) {
        let newList = [...this.state.selectedRows];
        if (e.target.tagName !== 'BUTTON' && e.target.tagName !== 'I'
            && e.target.tagName !== 'SPAN' && e.target.tagName !== 'A') {
            if (isSelected) {
                newList.push(row.rowIndex);
            } else {
                var index = newList.indexOf(row.rowIndex);
                if (index > -1) {
                    newList.splice(index, 1);
                }
            }
            this.setState({ selectedRows: newList });
        }
        this.forceUpdate();
    }

    private onSelectAll(isSelected: any, rows: any, e: any) {
        let selectedRows: number[] = [];
        if (isSelected) {
            let count = pageSize;
            Array.from(Array(count).keys());
            selectedRows = Array.from(Array(count).keys());
        }
        this.setState({ selectedRows: selectedRows });
    }

    private onPageChange(page: number, sizePerPage: number) {
        this.setState({
            page: page,
            selectedRows: []
        }, this.loadArchivedReturns);
    }

    private onSortChange(sortName: string, sortOrder: string, column: number) {
        this.clearSelection();
        let temp: IFilters = { ...this.state.filter };
        temp.sort.column = sortName;
        temp.sort.direction = sortOrder == "asc" ? SortDirections.Ascending : SortDirections.Descending;
        Helper.SessionStore.set(this.proxyFilter, JSON.stringify(temp));
        this.setState({
            filter: temp,
            sortName: sortName,
            sortOrder: sortOrder == "asc" ? "asc" : "desc"
        }, () => this.fetchArchivedReturns(1, pageSize));
    }
    private clearSelection = () => {
        this.setState({ selectedRows: [] });
    }
    private onSearchChange(searchString: string) {
        let temp: IFilters = { ...this.state.filter };
        temp.searchText = searchString;
        Helper.SessionStore.set(this.proxyFilter, JSON.stringify(temp));
        this.setState({
            searchString: searchString,
            filter: temp

        }, () => this.fetchArchivedReturns(1, pageSize));
    }

    private onFilterNameChange(event: any) {
        let temp: IFilters = { ...this.state.filter };
        temp.name = event.target.value;
        this.setState({
            filter: temp
        })
    }

    
    private onPageReload() {
        let query = this.buildQuery(this.state.page, this.state.pageSize);
        this.props.requestArchivedReturns(query, true);
        this.setState({ selectedRows : [] });
    }

    //======================Grid Operation Ends here=================================


    //======================Filter Operation Starts here=================================

    private applyDefaultFilter(filter: IFilters) {
        this.setState({ filter: filter },
            () => this.fetchArchivedReturns(1, pageSize))
    }

    private onFilterChange(dataField: any, filterType: ReportFilterType) {

        let newState: ArchivedReturnsState.ArchivedReturnsPageState = {
            filterName: "",
            filterClientId: "",
            filterPartner: "",
            filterSentBy: "",
            filterDeliveredOn: undefined,
            filterLastReminderOn: undefined,
            filterEngagementType: 0,
            filterDownloadedBy: "",
            filterCustomColumn: ""
        } as ArchivedReturnsState.ArchivedReturnsPageState;
        let filterStatus: any;
        let isClearFilter = true;
		var dict: { [columnName: string]: string; } = {};
		dict["selectedTaxYear"] = this.state.selectedTaxYear.toString()
		for (let field of Object.keys(dataField)) {
            dict[field.valueOf().toString()] = dataField[field.valueOf()].value ? dataField[field].value : dataField[field];
			switch (field) {
				case 'taxDocumentName':
                    newState.filterName = dataField[field].value ? dataField[field].value : dataField[field];
					break;
				case 'clientId':
                    newState.filterClientId = dataField[field].value ? dataField[field].value : dataField[field];
					break;
				case 'partner':
                    newState.filterPartner = dataField[field].value ? dataField[field].value : dataField[field];
					break;
				case 'deliveredBy':
                    newState.filterSentBy = dataField[field].value ? dataField[field].value : dataField[field];
					break;
				case 'status':
                    filterStatus = dataField[field].value.filterStatus ? dataField[field].value : dataField[field];
                    filterStatus = (filterStatus == "-1") ? "" : filterStatus;
                    this.setState({ filterStatus: filterStatus });
					break;
				case 'deliveredDate':
                    newState.filterDeliveredOn = Helper.GetNumberOfDays(dataField[field].value ? dataField[field].value : dataField[field]);
					break;
				case 'lastReminder':
                    newState.filterLastReminderOn = moment(dataField[field].value ? dataField[field].value : dataField[field]).format('MM/DD/YYYY');
					break;
				case 'engagementType':
                    newState.filterEngagementType = dataField[field].value ? dataField[field].value : dataField[field];;
					break;
				case 'downloaded':
                    newState.filterDownloadedBy = dataField[field].value ? dataField[field].value : dataField[field];;
					break;
				case 'testColumn':
                    newState.filterCustomColumn = dataField[field].value ? dataField[field].value : dataField[field];;
                    break;
			}
		}

        let tempfilter: IFilters = { ...this.state.filter };
        tempfilter.fields = dict;
        tempfilter.filterType = filterType;
        if (isClearFilter) {
            Helper.SessionStore.remove(this.proxyFilter);
        } else {
            Helper.SessionStore.set(this.proxyFilter, JSON.stringify(tempfilter));
        }
        if (this.isFilterChanged(newState, filterStatus)) {
            this.setState({ ...newState, filter: tempfilter },
                () => {
                    let archiveReturnsTable: any = this.refs.ArchiveReturnsTable;
                    if (!archiveReturnsTable.isAppliedFilter)
                    this.fetchArchivedReturns(1, pageSize)
                })
        }
    }

    private isFilterChanged(newState: ArchivedReturnsState.ArchivedReturnsPageState, filterStatus :any): boolean {
        return (
            newState.filterName !== this.state.filterName ||
            newState.filterClientId !== this.state.filterClientId ||
            newState.filterPartner !== this.state.filterPartner ||
            newState.filterSentBy !== this.state.filterSentBy ||
            filterStatus !== this.state.filterStatus ||
            newState.filterDeliveredOn !== this.state.filterDeliveredOn ||
            newState.filterLastReminderOn !== this.state.filterLastReminderOn ||
            newState.filterEngagementType !== this.state.filterEngagementType ||
            newState.filterDownloadedBy !== this.state.filterDownloadedBy ||
            newState.filterCustomColumn !== this.state.filterCustomColumn);
    }

    onFilterSave(onApplyFilter: (filter: IFilters) => void) {
        if (this.state.filter.name.trim() != '') {
            let filter = this.props.reportFilters.filters.find(x => x.name == this.state.filter.name);
            if (filter) {
                VenusNotifier.Warning(DeliveredReturnsConstants.OtherMessage.FilterNameAlreadyExists, null);
            }
            else {
                this.props.addReportFilter(this.state.filter.name, this.state.filter, () => {
                    onApplyFilter(this.state.filter);
                    this.onSaveFilterHide();
                });

            }
        }
        else {
            VenusNotifier.Warning(DeliveredReturnsConstants.OtherMessage.PleaseEnterFilterName, null);
        }
    }

    onFilterUpdate(name: string) {
        let filter = this.props.reportFilters.filters.find(x => x.name == name);
        if (!filter) {
            VenusNotifier.Warning(DeliveredReturnsConstants.OtherMessage.FilterDoesNotExists, null);
        }
        else {
            filter.fields = this.state.filter.fields;
            filter.name = name;
            filter.searchText = this.state.filter.searchText;
            filter.sort = this.state.filter.sort;
            filter.isDefaultFilter = this.state.filter.isDefaultFilter;
            filter.filterType = this.state.filter.filterType;

            this.props.updateReportFilter(name, filter);
            this.onSaveFilterHide();
        }
    }

    onSetDefaultFilterSuccess() {
        VenusNotifier.Success(DeliveredReturnsConstants.StatusMessage.SetDefaultFilterSuccess, null);
    }

    onSetDefaultFilter(name: string, filterType: ReportFilterType) {
        this.props.addDefaultFilter(name, filterType, () => { this.onSetDefaultFilterSuccess(); });
    }

    onRemoveDefaultFilterSuccess() {
        VenusNotifier.Success(DeliveredReturnsConstants.StatusMessage.RemoveDefaultFilterSuccess, null);
    }

    onRemoveDefaultFilter(name: string, filterType: ReportFilterType) {
        this.props.removeDefaultFilter(name, filterType, () => { this.onRemoveDefaultFilterSuccess(); });
        let archiveReturnsTable: any = this.refs.ArchiveReturnsTable;
        archiveReturnsTable.refs.Filters?.setState({ activeFilter: '', appliedFilters: [] });
        this.onPageReload;
    }

    onFilterDelete(filterName: string, filterType: ReportFilterType) {
        this.props.deleteReportFilter(filterName, filterType);
    }

    onSaveFilterHide() {
        this.setState({
            saveFilterShow: false
        });
    }

    onSaveFilterShow() {
        this.setState({
            saveFilterShow: true
        });
    }

    //======================Filter Operation Ends here=================================

    //====================RestoreReturns Starts Here=================================

    private onRestoreReturnsOpen(rowIndex: number = NO_INDEX) {
        let ids = this.onPopupOpen(rowIndex);

        if (ids.length == 0) {
            VenusNotifier.Warning(ArchiveReturnsConstants.PleaseSelectDocumentToRestore, null);
            return;
        }

        let obj = this;
        bootbox.confirm({
            title: '<i className="text-secondary"></i>Restore Tax Return',
            message: ArchiveReturnsConstants.documentRestoreConfirmation,
            buttons: {
                cancel: {
                    label: '<i class="fas fa-times"></i> Cancel',
                    className: 'btn-white btn-default'
                },
                confirm: {
                    label: '<i class="fas fa-check"></i> Confirm',
                    className: 'btn-info'
                }
            },
            callback: (result: boolean) => {
                if (result) {
                    this.onRestoreReturnsSave(ids);
                }
            }
        });
    }

	private onRestoreReturnsSave(ids: number[]) {
		this.props.restoreArchivedTaxDocument(ids, this.onRestoreCompletion);
		this.setState({
			restoreReturnsState: {
				show: false,
				selected: undefined,
                saveCheckIds: ids,
                remove: false
			}
		});
	}

    onRestoreCompletion() {
        this.setState({ selectedRows: [] }, this.onPageReload);
        let archiveReturnsTable: any = this.refs.ArchiveReturnsTable;
        archiveReturnsTable.refs.Filters.setState({ activeFilter: '', appliedFilters: [] });
    }

    //====================RestoreReturns Ends Here=================================

    //====================CustomColumn Starts Here=================================

    private onCustomColumnOpen(rowIndex: number = NO_INDEX) {
        let selectedDocumentIds = this.selectedDocumentIds();
        if (selectedDocumentIds.length == 0) {
            VenusNotifier.Error(ArchiveReturnsConstants.PleaseSelectOneOrMoreDocuments, null);
        }
        else {
            selectedDocumentIds.map((id, i) => {
                if (this.props.archiveTaxDocument[id].isFullyLoaded == undefined || !this.props.archiveTaxDocument[id].isFullyLoaded) {
                    this.props.requestTaxDocument(id, true);
                }
            });
            this.setState({
                customColumnState: {
                    show: true,
                    selected: undefined,
                    saveCheckIds: undefined,
                    remove: false
                },
                hideCustomCulumnLoader:false,
                selectedColumnValue: this.getSelectedCustomColumnValue(selectedDocumentIds),
            });
        }
    }

    getSelectedCustomColumnValue(selectedDocumentIds: number[]) { // method to fetch selected document custom column value
        if (selectedDocumentIds.length == 1) {
            let customColumn = this.props.archivedReturns.archivedReturnTableModel.documents.filter(x => x.document.id == selectedDocumentIds[0])[0].customColumn;
            return (customColumn === undefined || customColumn === "") ? 0 :
                this.props.customColumnData.customColumn.customColumnValues.filter(x => x.value === customColumn)[0]?.id;
        }
        else {
            let columnValue: string[] = [];
            selectedDocumentIds.map((id, i) => {
                let customColumn = this.props.archivedReturns.archivedReturnTableModel.documents.filter(x => x.document.id == id)[0].customColumn;
                columnValue.push(customColumn);
            });

            let isSame: boolean = false;
            let tempValue: string = "";
            isSame = columnValue.every(x => x == columnValue[0]);

            if (isSame) {
                tempValue = this.props.archivedReturns.archivedReturnTableModel.documents.filter(x => x.document.id == selectedDocumentIds[0])[0].customColumn;
                 return (tempValue === undefined || tempValue === "") ? 0 :
                    this.props.customColumnData.customColumn.customColumnValues.filter(x => x.value === tempValue)[0]?.id;
            }
            else return 0;
        }
    }

    private onCustomColumnSave() {
        if (this.state.selectedColumnValue == 0) {
            VenusNotifier.Warning(DeliveredReturnsConstants.OtherMessage.SelectCustomColumnValue, null)
            return false;
        }

        let ids = this.selectedDocumentIds();
        let customColumn = this.props.customColumnData.customColumn.customColumnValues.filter((i) => i.id == this.state.selectedColumnValue);
        if (customColumn && customColumn.length > 0) {
            ids.forEach((id, i) => {
                let taxDocument = this.props.archiveTaxDocument[id]?.taxReturn;
                let currentCustomColumnId: number = 0;
                if (taxDocument.documentSettings.documentDisplaySetting.customColumn != null) {
                    currentCustomColumnId = taxDocument.documentSettings.documentDisplaySetting.customColumn.id;
                    let currentCustomColumn = this.props.customColumnData.customColumn.customColumnValues.filter((i) => i.id == currentCustomColumnId);
                    if (currentCustomColumn.length > 0) {
                        currentCustomColumn[0].inUseCount--;
                        this.props.updateCustomColumnValue(currentCustomColumn[0], false);
                    }
                }
                taxDocument.documentSettings.documentDisplaySetting.customColumn = customColumn[0];
                taxDocument.documentSettings.isModified = true;
                customColumn[0].inUseCount++;
                this.props.saveTaxDocument(taxDocument, customColumn[0]);
                this.props.updateTaxDocumentCustomColumnValue(id, customColumn[0].value);
            });

			this.setState({
				customColumnState: {
					show: true,
					selected: undefined,
                    saveCheckIds: ids,
                    remove: false
				}
			});
		}
    }

    private onCustomColumnDropdownChange(value: any) {
        this.setState({ selectedColumnValue: value });
    }

    private onCustomColumnRemove() {

        let ids = this.selectedDocumentIds();
        let customColumn = this.props.customColumnData.customColumn.customColumnValues.filter((i) => i.id == this.state.selectedColumnValue);
        if (customColumn && customColumn.length > 0) {
            let temp = this.selectedDocuments().filter(p => p.customColumn == customColumn[0].value)
            if (temp.length != ids.length) {
                VenusNotifier.Warning(ArchiveReturnsConstants.OneOrMoreItemsWereNotRemovedAsThierValuesDidNotMatchTheSelectedValue, null);
                this.setState({ hideCustomCulumnLoader: true });
            }
            temp.forEach((value, i) => {
                let taxDocument = this.props.archiveTaxDocument[value.document.id]?.taxReturn;
                taxDocument.documentSettings.documentDisplaySetting.customColumn = {
                    id: 0,
                    value: "",
                    customColumn: 0,
                    inUseCount: 0,
                };
                taxDocument.documentSettings.isModified = true;
                customColumn[0].inUseCount--;
                this.props.saveTaxDocument(taxDocument, customColumn[0]);
                this.props.updateTaxDocumentCustomColumnValue(value.document.id, "");
            });

			this.setState({
				customColumnState: {
					show: true,
					selected: undefined,
                    saveCheckIds: ids,
                    remove: true
				}
			});
		}
	}

	private onCustomColumnCancel() {
		this.setState({
			customColumnState: {
				show: false,
				selected: undefined,
                saveCheckIds: undefined,
                remove: false
			}
		});
	}

    //====================CustomColumn Ends Here=================================	

    //====================Client Tracking Starts Here===============================

    private onClientTrackingOpen(rowIndex: number = NO_INDEX) {

        let ids = this.onPopupOpen(rowIndex);
        ids.map((id, i) => {
            this.props.requestTaxDocumentClientTracking(id);
        });

        this.setState({
            clientTrackingState: {
                show: true,
                model: this.getStoreItem(rowIndex)?.taxReturn,
                saveCheckId: undefined
            }
        });
    }

    private onClientTrackingCancel() {
        this.setState({
            clientTrackingState: {
                show: false,
                model: undefined,
                saveCheckId: undefined
            }
        });
    }
    //=====================Client Tracking Ends Here================================

    //====================Download Returns Starts Here===============================

    private onDownloadReturnOpen(rowIndex: number = NO_INDEX) {
        let ids = this.onPopupOpen(rowIndex);
        ids.map((id, i) => {
            this.props.requestTaxDocument(id, true, () => {
                this.props.requestDownloadableReturns(id);
                this.setState({
                    downloadReturnState: {
                        show: true,
                        model: this.getStoreItem(rowIndex)?.taxReturn,
                        saveCheckId: undefined
                    }
                });
            });
        });

    }

    private onDownloadReturnAll(documentGuid: string, documentId: number, taxYear: number, isEfileForm: boolean) {
        ShowLoader(Common.OverlayMessages.Downloading);
        const model = this.props.archiveTaxDocument[documentId]?.taxReturn;
        
        this.props.downloadAllReturn(documentGuid, documentId, taxYear, isEfileForm, model.clientId,
            model.engagementType, getUnformattedClientName(model));
    }

    private onDownloadFile(documentGuid: string, documentId: number, taxYear: number, fileName: string, groupId: number, signedDocumentId: number, isDownloadReturn: boolean = false) {
        let isUpdateDocTransaction: boolean = false;
        if (groupId == DocumentGroups.EFile && !isDownloadReturn) {
            isUpdateDocTransaction = true;
        }
        const model = this.props.archiveTaxDocument[documentId]?.taxReturn;
        let isSinglePrinted: boolean = false;
        if ( model.documentSettings)
        {
            if(model.documentSettings.deliverySettings)
            {
                let deliveryMode: string = model.documentSettings.deliverySettings.deliveryMode.toString();
                if(deliveryMode === DeliveryMode[DeliveryMode.PaperFiled] || deliveryMode === DeliveryMode.PaperFiled.toString())
                {
                    let deliveryType: string = model.documentSettings.deliverySettings.paperReturnSettings.deliveryType.toString();
                    if(deliveryType === PaperReturnDeliveryType[PaperReturnDeliveryType.SingleFile] || deliveryType === PaperReturnDeliveryType.SingleFile.toString())
                    {
                        isSinglePrinted = true;
                    }
                }
            }
        }
        this.props.downloadReturn(documentGuid, documentId, taxYear, fileName, groupId, 
            signedDocumentId, model.clientId, getUnformattedClientName(model),model.engagementType, 
            isSinglePrinted, this.onPageReload, isUpdateDocTransaction);
    }

    private onDownloadAdditionalDocuments = (documentGuid: string, documentId: number, taxYear: number, clientId: string,
        additionalEsignDocuments: IDocumentData[], isSigned?: boolean) => {
        let name: any;
        let taxReturn: any = this.props.taxDocuments[documentId]?.taxReturn;
        if (isPartnership(taxReturn)) {
            name = taxReturn.partnership.name;
        }
        else {
            if (isIndividual(taxReturn) || isMutual(taxReturn)) {
                name = taxReturn.taxpayer.name;
            }
        }
        this.props.downloadAdditionalEsignDocuments(documentGuid, documentId, taxYear, clientId, name, taxReturn.engagementType,
            additionalEsignDocuments, isSigned);
    }
    private downloadMergedPaperFileDocument(documentGuid: string, documentId: number, taxYear: number, clientId: string) {
        const model = this.props.taxDocuments[documentId]?.taxReturn;
        this.props.downloadMergedPaperFileDocument(documentGuid, taxYear, clientId, getUnformattedClientName(model));
    }
    private onDownloadReturnCancel() {
        this.setState({
            downloadReturnState: {
                show: false,
                model: undefined,
                saveCheckId: undefined
            }
        });
    }

    //====================Download Returns End Here===============================

    //====================Download Efile Forms Starts Here===============================

    private onDownloadEfileFormsOpen(rowIndex: number = NO_INDEX) {

        let ids = this.onPopupOpen(rowIndex);
        ids.map((id, i) => {
            this.props.requestDownloadableEfileForms(id);
        });

        this.setState({
            downloadEformFilesState: {
                show: true,
                model: this.getStoreItem(rowIndex)?.taxReturn,
                saveCheckId: ids[0]
            }
        });
    }

    private onMultipleDownloadEfileForms() {
        let selectedDocuments = this.selectedDocuments();
        let ids = this.selectedDocumentIds();
        if (ids.length == 0) {
            VenusNotifier.Warning(ArchiveReturnsConstants.PleaseSelectOneOrMoreDocuments, null);
            return;
        }
        else {
            let isValid: boolean = true;
            let i: number = 0;
            for (i = 0; i < selectedDocuments.length; i++) {
                let signatureStatus: string = selectedDocuments[i].document.signatureStatus.toString();
                if (signatureStatus === SignatureStatus[SignatureStatus.ManuallySigned]
                    || signatureStatus === SignatureStatus[SignatureStatus.AwaitingESign]
                    || signatureStatus === SignatureStatus[SignatureStatus.AwaitingUpload]
                    || signatureStatus === SignatureStatus[SignatureStatus.PartiallySigned]) {
                    isValid = false;
                    break;
                }
            }

            if (!isValid) {
                VenusNotifier.Error(ArchiveReturnsConstants.UploadedESignedEfileFormsCanOnlyDownloadable, null)
            }
            else {
                this.props.downloadEfileForm(ids);
            }
        }
    }

    private onDownloadEfileFormsCancel() {
        this.setState({
            downloadEformFilesState: {
                show: false,
                model: undefined,
                saveCheckId: undefined
            }
        });
    }

    private onSignedDetailsOpen(rowIndex: number = NO_INDEX) {
        let ids = this.onPopupOpen(rowIndex);
        ids.map((id, i) => {
            this.props.requestArchivedSignedDetails(id);
        });

        this.setState({
            signedDetailsState: {
                show: true,
                model: this.getStoreItem(rowIndex)?.taxReturn,
                saveCheckId: undefined
            }
        });
    }

    private onSignedDetailsCancel = () => {
        this.setState({
            signedDetailsState: {
                show: false,
                model: undefined,
                saveCheckId: undefined
            }
        });
    }

    //====================Download Efile Forms End Here===============================

    //====================Download History Starts Here=============================

    private onDownloadHistoryOpen(rowIndex: number = NO_INDEX) {
        let ids = this.onPopupOpen(rowIndex);
        ids.map((id, i) => {
            this.props.requestTaxDocumentDownloadHistory(id);
        });

        this.setState({
            downloadHistoryState: {
                show: true,
                model: this.getStoreItem(rowIndex)?.taxReturn,
                saveCheckId: undefined
            }
        });
    }

    private onDownloadHistoryCancel() {
        this.setState({
            downloadHistoryState: {
                show: false,
                model: undefined,
                saveCheckId: undefined
            }
        });
    }

    //====================Download History Ends Here===============================
    buildQuery(page: number, pageSize: number) {
        return '?PageNo=' + page
            + '&SortBy=' + this.state.sortName
            + '&SortOrder=' + this.state.sortOrder
            + '&SearchString=' + this.state.searchString
            + '&selectedTaxYear=' + this.state.selectedTaxYear
            + '&PageSize=' + pageSize
            + '&filterName=' + this.state.filterName
            + '&filterClientId=' + this.state.filterClientId
            + '&filterPartner=' + this.state.filterPartner
            + '&filterStatus=' + this.state.filterStatus
            + '&filterDeliveredOn=' + (this.state.filterDeliveredOn || "")
            + '&filterEngagementType=' + this.state.filterEngagementType
            + '&filterCustomColumn=' + this.state.filterCustomColumn
            + '&filterDownloadedBy=' + this.state.filterDownloadedBy
            + '&filterLastReminderOn=' + (this.state.filterLastReminderOn || "")
            + '&filterSentBy=' + this.state.filterSentBy;
    }

    loadArchivedReturns() {
        let queryString = this.buildQuery(this.state.page, this.state.pageSize);
        this.props.requestArchivedReturns(queryString);
    }

    fetchArchivedReturns(page: number, sizePerPage: number) {
        let queryString = this.buildQuery(page, sizePerPage);
        this.setState({ page: page, pageSize: sizePerPage }, () => { this.props.requestArchivedReturns(queryString) });
    }

    private selectedDocumentIds() {
        let ids: number[] = [];
        for (var i in this.state.selectedRows) {
            let row = this.state.selectedRows[i];
            let selectedDocument = this.props.archivedReturns.archivedReturnTableModel.documents[row];
            if (selectedDocument) {
                ids.push(selectedDocument.document.id);
            }
        }
        return ids;
    }

    private selectedDocumentIdsAndLoadingStatus(): ArchivedReturnsState.ISelectedDocument[] {
        let selectedDocuments: ArchivedReturnsState.ISelectedDocument[] = [];
        let ids = this.selectedDocumentIds();
        ids.map((id, index) => {
            selectedDocuments.push({ id: id, isfullyLoaded: this.props.archiveTaxDocument[id].isFullyLoaded ? true : false });
        });
        return selectedDocuments;
    }

    private selectedDocuments() {
        let docs:ArchivedReturnsState.IArchivedTaxDocument[] = [];
        for (var i in this.state.selectedRows) {
            let row = this.state.selectedRows[i];
            let selectedDocument = this.props.archivedReturns.archivedReturnTableModel.documents[row];
            if (selectedDocument) {
                docs.push(selectedDocument);
            }
        }
        return docs;
    }


    private isLoaded(allReturns: TaxDocumentStore.IArchiveTaxDocumentDictionary, ids: number[]) {
        for (var i in ids) {
            if (allReturns[ids[i]] && allReturns[ids[i]].isLoading) {
                return false;
            }
        }
        return true;
    }

    private onPopupOpen(rowIndex: number) {
        let ids = [rowIndex];
        if (rowIndex !== NO_INDEX) {
            return [this.props.archivedReturns.archivedReturnTableModel.documents[rowIndex].document.id];
        }
        if (this.state.selectedRows.length == 0) {

            return [];
        }
        return this.selectedDocumentIds();
    }

    private getStoreItem(index: number) {
        let id = this.props.archivedReturns.archivedReturnTableModel.documents[index].document.id;
        return this.props.archiveTaxDocument[id];
    }

    private onUpdateCompletionError(taxDocuments: TaxDocumentStore.IArchiveTaxDocumentDictionary, stateKey: string, state: ArchivedReturnsState.IPopupStateSingle) {
        if (state.saveCheckId &&
            this.isLoaded(taxDocuments, [state.saveCheckId])) {
            if (taxDocuments[state.saveCheckId] && taxDocuments[state.saveCheckId].error) {
                VenusNotifier.Error(errorMsg[stateKey], stateKey);
                //this.props.requestTaxDocument(state.saveCheckId, true);// TODO: need to implement

                this.setState((prevState) => ({
                    ...prevState,
                    [stateKey]: {
                        show: false,
                        model: undefined,
                        saveCheckId: undefined
                    }
                }));
            }
        }
    }

    private onUpdateCompletion(taxDocuments: TaxDocumentStore.IArchiveTaxDocumentDictionary, stateKey: string, state: ArchivedReturnsState.IPopupStateSingle) {
        if (state.saveCheckId &&
            this.isLoaded(taxDocuments, [state.saveCheckId])) {
            if (taxDocuments[state.saveCheckId] && taxDocuments[state.saveCheckId].error) {
                VenusNotifier.Error(errorMsg[stateKey], stateKey);
                this.props.requestTaxDocument(state.saveCheckId, true);
            } else {
                VenusNotifier.Success(successMsg[stateKey], stateKey);
                this.setState({ selectedRows: [] });
            }
            this.setState((prevState) => ({
                ...prevState,
                [stateKey]: {
                    show: false,
                    model: undefined,
                    saveCheckId: undefined
                }
            }));
        }
    }

	private onUpdateCompletionMultiple(taxDocuments: TaxDocumentStore.IArchiveTaxDocumentDictionary, stateKey: string, state: ArchivedReturnsState.IPopupStateMultiple) {
        if (state.saveCheckIds &&
            this.isLoaded(taxDocuments, state.saveCheckIds)) {
            let err = false;
            state.saveCheckIds.map((id, i) => {
                if (taxDocuments[id] && taxDocuments[id].error) {
                    this.props.requestTaxDocument(id, true);
                    err = true;
                }
            });
            if (err) {
                VenusNotifier.Error(errorMsg[stateKey], stateKey);
            } else if (state.remove) {
                VenusNotifier.Success(successMsg["customColumnRemoveState"], stateKey);
                this.setState({ selectedRows: [] });
            } else {
                VenusNotifier.Success(successMsg[stateKey], stateKey);
                this.setState({ selectedRows: [] });
            }
                this.setState((prevState) => ({
                    ...prevState,
                    [stateKey]: {
                        show: false,
                        model: undefined,
                        saveCheckId: undefined
                    }
                }));
            }
        }

    private checkForPopupCompletion(taxDocuments: TaxDocumentStore.IArchiveTaxDocumentDictionary) {
        this.onUpdateCompletionMultiple(taxDocuments, "customColumnState", this.state.customColumnState);
        this.onUpdateCompletionMultiple(taxDocuments, "restoreReturnsState", this.state.restoreReturnsState);
        this.onUpdateCompletionError(taxDocuments, "downloadEformFilesState", this.state.downloadEformFilesState);
        this.onUpdateCompletionError(taxDocuments, "downloadReturnState", this.state.downloadReturnState);
    }

    private getDefaultFilter = (filters: IFilters[]): string | undefined => {

        let existsMasterFilter = filters.findIndex(x => x.isMasterFilter) > -1 ? true : false;
        let existsDefaultFilter = filters.findIndex(x => x.isDefaultFilter) > -1 ? true : false;

        if (existsMasterFilter) {
            let mName = filters.find(x => x.isMasterFilter);
            return mName ? mName.name : undefined;
        }
        else if (existsDefaultFilter) {
            let dName = filters.find(x => x.isDefaultFilter);
            return dName ? dName.name : undefined;
        }
        return undefined;
    }

    public render() {

        const customColumnHeader = this.props.customColumnData.customColumn ? this.props.customColumnData.customColumn.header : "";

        return <div className='user-assignment-content'>

            <ArchiveReturnsHeader
                pageTitle="Archive Returns"
                onRestoreReturnOpen={this.onRestoreReturnsOpen}
                onCustomColumnOpen={this.onCustomColumnOpen}
                onMultipleDownloadEfileForms={this.onMultipleDownloadEfileForms}
                selectedDocumentCount={this.selectedDocumentIds().length}
                taxYear={this.state.selectedTaxYear}
                customColumnTitle={this.props.customColumnData.customColumn ? this.props.customColumnData.customColumn.header : ""}
                users={this.props.userStore.users}
                onPageReload={this.onPageReload}
                company={this.props.company}
                selectedERO={this.props.userSettings.settings.defaultSettings ? this.props.userSettings.settings.defaultSettings.eroUser : 0}
                uploadTaxReturn={this.props.uploadTaxReturnStore}
                getUploadLink={this.props.getUploadLink}
                processTaxReturn={this.props.processTaxReturn}
				submitTaxReturn={this.props.submitTaxReturn}
				companySettings={this.props.company.companySettings ? this.props.company.companySettings : initialCompanySettings}
                userDefaultSettings={this.props.userSettings.settings}
                defaultGroup={this.state.defaultGroup ? this.state.defaultGroup : ''}
                groupInfo={this.props.groupedReturnStore.groupInfo}
                groupAccess={this.props.groupedReturnStore.groupAccess}
                updateGroupInfoState={this.props.requestGroupConfiguration}
            />
            <br />
            <ArchiveReturnsTable
                ref='ArchiveReturnsTable'
                onPageChange={this.onPageChange}
                onSortChange={this.onSortChange}
                onSearchChange={this.onSearchChange}
                onFilterChange={this.onFilterChange}
                pageNo={this.state.page}
                onExportToExcel={this.onExportToExcel}
                onFilterNameChange={this.onFilterNameChange}
                onFilterSave={this.onFilterSave}
                onFilterUpdate={this.onFilterUpdate}
                onFilterDelete={this.onFilterDelete}
                filterList={this.props.reportFilters.filters}
                currentFilter={this.state.filter}
                onSetDefaultFilter={this.onSetDefaultFilter}
                onRemoveDefaultFilter={this.onRemoveDefaultFilter}
                saveFilterShow={this.state.saveFilterShow}
                onSaveFilterHide={this.onSaveFilterHide}
                onSaveFilterShow={this.onSaveFilterShow}
                totalRows={this.props.archivedReturns.totalRowCount}
                onClientTrackingOpen={this.onClientTrackingOpen}
                onDownloadEfileFormsOpen={this.onDownloadEfileFormsOpen}
                onDownloadReturnOpen={this.onDownloadReturnOpen}
                onRestoreReturnOpen={this.onRestoreReturnsOpen}
                isLoading={this.props.archivedReturns.loading}
                pageSize={pageSize}
                customColumnHeader={customColumnHeader}
                onRowSelect={this.onRowSelect}
                onSelectAll={this.onSelectAll}
                selectedRows={this.state.selectedRows}
                archivedReturns={this.props.archivedReturns}
                defaultFilter={this.getDefaultFilter(this.props.reportFilters.filters)}
                loadGrid={this.loadArchivedReturns}
                onDownloadHistoryOpen={this.onDownloadHistoryOpen}
                onSignedDetailsOpen={this.onSignedDetailsOpen}
				proxyFilter={this.proxyFilter}
				onPageReload={this.onPageReload}
            />

            <CustomColumn
                show={this.state.customColumnState.show}
                onCancel={this.onCustomColumnCancel}
                onCustomColumnSave={this.onCustomColumnSave}
                onCustomColumnRemove={this.onCustomColumnRemove}
                customColumnDropdownValues={this.props.customColumnData.customColumn}
                selectedDocuments={this.selectedDocumentIdsAndLoadingStatus()}
                selectedColumnValue={this.state.selectedColumnValue}
                onCustomColumnDropdownChange={this.onCustomColumnDropdownChange}
                hideLoader={this.state.hideCustomCulumnLoader}
            />

            <DownloadReturns
                show={this.state.downloadReturnState.show}
                onCancel={this.onDownloadReturnCancel}
                taxReturn={this.state.downloadReturnState.model as ITaxReturn}
                onDownloadIndividual={this.onDownloadFile}
                onDownloadAll={this.onDownloadReturnAll}
                onDownloadAttachments={this.props.downloadAttachments}
                onDownloadAllAttachments={this.props.downloadAllAttachments}
                isArchivedReturn={true}
                onDownloadAdditionalEsignDocuments={this.onDownloadAdditionalDocuments}
                onDownloadMergedPaperFileDocument={this.downloadMergedPaperFileDocument}
                onDownloadK1Attachments={this.props.downloadK1Attachments}
                onDownloadAllK1Attachments={this.props.downloadAllK1Attachments}
            />
            <DownloadHistory
                show={this.state.downloadHistoryState.show}
                onCancel={this.onDownloadHistoryCancel}
                taxReturn={this.state.downloadHistoryState.model as ITaxReturn}
            />
            <DownloadEfileForm
                show={this.state.downloadEformFilesState.show}
                onCancel={this.onDownloadEfileFormsCancel}
                taxReturn={this.state.downloadEformFilesState.model as ITaxReturn}
                downloadIndividual={this.onDownloadFile}
                downloadAll={this.onDownloadReturnAll}
                downloadAdditionalEsignDocuments={this.onDownloadAdditionalDocuments}
            />

            <ClientTrackingModal
                show={this.state.clientTrackingState.show}
                onCancel={this.onClientTrackingCancel}
                taxReturn={this.state.clientTrackingState.model as ITaxReturn}
            />

            <SignedDetails
                taxReturn={this.state.signedDetailsState.model as ITaxReturn}
                onCancel={this.onSignedDetailsCancel}
                show={this.state.signedDetailsState.show} />
        </div>;
    }
};