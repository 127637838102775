import { CheckBoxComponent } from "../../common/CheckBoxComponent";
import { FC, useEffect, useState } from "react";
import React from "react";
import { Alert } from "react-bootstrap";
import { ITaxReturn, SignatureStatus } from "../../common/TaxReturn";

interface ISendReminderNowProps {
    isAutomatedSigningReminderEnabled: boolean;
    signingReminder: boolean;
    setSigningReminder: React.Dispatch<React.SetStateAction<boolean>>;
    totalCount: number;
    models: ITaxReturn[];
    isGroupedReturnExists: boolean;
}

const SendReminderNow: FC<ISendReminderNowProps> = ({
    isAutomatedSigningReminderEnabled,
    signingReminder,
    setSigningReminder,
    totalCount,
    models,
    isGroupedReturnExists
}) => {
    const isMultiple = totalCount > 1;
    const [signingReminderDisabled, setSigningReminderDisabled] = useState<boolean>(false);
    const isGroupedReturnsOnly = models.every((x) => x.groupId > 0);

    useEffect(() => {
        if (totalCount === 1) {
            let taxReturn = models[0];
            setSigningReminderDisabled(
                taxReturn.signatureStatus?.toString() === SignatureStatus[SignatureStatus.Uploaded] ||
                    taxReturn.signatureStatus?.toString() === SignatureStatus[SignatureStatus.ESigned] ||
                    taxReturn.signatureStatus?.toString() === SignatureStatus[SignatureStatus.ManuallySigned] ||
                    taxReturn.signatureStatus?.toString() === SignatureStatus[SignatureStatus.DeliveredToTaxCaddy] ||
                    taxReturn.signatureStatus?.toString() === SignatureStatus[SignatureStatus.AlternateDelivery] ||
                    taxReturn.signatureStatus?.toString() === SignatureStatus[SignatureStatus.AlternateAndUploaded] ||
                    taxReturn.signatureStatus?.toString() === SignatureStatus[SignatureStatus.SignatureStampingFailed] ||
                    taxReturn.signatureStatus?.toString() === SignatureStatus[SignatureStatus.SignedAndESigned] ||
                    taxReturn.signatureStatus?.toString() === SignatureStatus[SignatureStatus.ESignedInOffice]
            );
        }
    }, []);

    return (
        <div className="py-2">
            {isMultiple && (
                <Alert variant="primary" className={isMultiple ? "mb-3" : ""}>
                    <i className="fas fa-info-circle mr-2"></i>
                    {totalCount} Returns selected.
                </Alert>
            )}
            <div className={isMultiple ? "px-3" : "mt-2 px-3"}>
                <div>
                    <CheckBoxComponent
                        id="signing_reminder"
                        text="Send Signing Reminder"
                        checked={signingReminder}
                        onChange={() => setSigningReminder((prev) => !prev)}
                        disabled={signingReminderDisabled}
                    />
                </div>
                {!isMultiple && isAutomatedSigningReminderEnabled && !signingReminderDisabled && (
                    <div className="text-danger mt-2" style={{ paddingLeft: "5px" }}>
                        {isGroupedReturnExists && isGroupedReturnsOnly
                            ? `Automatic reminders are enabled for the selected Group!`
                            : `Automatic reminders are enabled for the selected Return!`}
                    </div>
                )}
                {isMultiple && (
                    <div className="mt-3" style={{ paddingLeft: "5px" }}>
                        <div className="text-danger mb-3">Please Note!</div>
                        <div>
                            Signing Reminder will be sent for statuses: “Awaiting E-Sign”, “Awaiting Upload”, “Partially Signed”.
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default SendReminderNow;
