import * as React from 'react'
import Autocomplete from 'react-autocomplete'

export interface IAutoSuggestTextboxProps {
    id: string;
    selectedValue: any;
    options: any;
    onChange: (selectedValue: any) => void;
    formMenuClass: string;
    disabled?: boolean;
}

export class AutoSuggestTextbox extends React.Component<IAutoSuggestTextboxProps, {}> {

    public render() {
        return (
            <Autocomplete
                value={this.props.selectedValue}
                inputProps={{ id: this.props.id + '-autocomplete', className: this.props.disabled ? "form-control autoComplete-input-disable" : 'form-control', 'maxLength':100 }}
                items={this.props.options}
                getItemValue={(item) => item.label}
                onChange={(event, value) => this.props.onChange(value)}
                onSelect={value => this.props.onChange(value)}
                wrapperStyle={{ display: 'block'}}
                renderMenu={children => (
                    <div className={this.props.formMenuClass}>
                        {children}
                    </div>
                )}
                renderItem={(item, isHighlighted) => (
                    <div
                        className={`item ${isHighlighted ? 'item-highlighted' : ''}`}
                        key={item.value}
                    >{item.label}</div>
                )}
            />
        )
    }
}