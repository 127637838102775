import * as React from 'react';
import { Modal, Col, Button, FormControl, FormGroup, Alert, Row } from 'react-bootstrap';
import { LoadingOverlay, Loader } from 'react-overlay-loader';
import {
    ITaxReturn, isIndividual, isMutual, isPartnership,
    EngagementType
} from './TaxReturn';
import { VenusNotifier } from '../helper/VenusNotifier';
import { ReportProblemConstants, UploadtaxReturnConstants, ClientInfoValidation, ValidationContants } from '../helper/Constants';
import { DropdownComponent } from './../common/controls/DropdownComponent';
import Select from 'react-select';
import {
    splitName, PrepareTaxYear, getTaxSoftwares, isClientInfoLoaded, getProductTypes, PrepareSFTaxYear
} from '../helper/HelperFunctions';
import {
    IReportProblemDetails, ReportProblemProperty, ReportProblemTypes, SignatureStatusOptionsList, SFSignatureStatusOptionsList,
    initialState, ProductTypes, TaxreturnType, ReportedStep, DocumentStatusType, SelectOptions
} from './ReportProblem/ReportProblemModel';
import { isValidEmailAddress, getFileExtension, GetSafeFilename } from '../helper/Validations';
import { TaxpayerDetails } from './ReportProblem/TaxpayerDetails';
import { SpouseDetails } from './ReportProblem/SpouseDetails';
import { PartnerDetails } from './ReportProblem/PartnerDetails';
import { IUserProfile } from './../navigation/profile/ProfileObjects';
import { ISuiteTaxSoftwareSetting } from "../../Core/ViewModels/Company/CompanySettingsViewModel";
import { PhoneNumberComponent } from '../common/PhoneNumberComponent';
import { ISignatureFlowReportDocument, SignatureFlowReportSignatureStatus } from '../../SignatureFlow/models/SignatureFlowReportState';
import { IUserModel } from '../../Core/ViewModels/User/UserViewModel';
import { uploadedFile } from '../common/CommonUpload';
import { API_BASE_URL } from '../../utils/constants';
import { logger } from '../../routes/LoggedIn';

interface IValue {
    value: string;
    label: string;
}

interface IReportProbemProps {
    show: boolean;
    onCancel: () => void;
    model?: ITaxReturn;
    sfModel?: ISignatureFlowReportDocument;
    onReportProblemSave: (id: number, problemDetails: IReportProblemDetails, callback?: () => void) => void;
    loggedInCPA: IUserProfile;
    companyName: string;
    reportProblemType: ReportProblemTypes;
    problemStep?: ReportedStep;
    taxSoftwareSetting?: ISuiteTaxSoftwareSetting;
    requestSignatureUsers?: (reload?: boolean) => void;
    sfUsers?: IUserModel[];
    requestDocumentTypes?: () => void;
    resourceId?: string;
}

interface state {
    saving: boolean;
    showLoader: boolean;
    problemState: IReportProblemDetails;
    file: any;
    isAttachedUploaded: boolean;
    fileName: string;
}

export class ReportProblemModal extends React.Component<IReportProbemProps, state> {
    inputElement: any;
    constructor(props: IReportProbemProps) {
        super(props);
        this.inputElement = React.createRef();
        this.state = {
            saving: false,
            showLoader: false,
            problemState: initialState,
            file: {},
            isAttachedUploaded: false,
            fileName: ''
        }

        this.onSubmit = this.onSubmit.bind(this);
        this.addAttachmentClick = this.addAttachmentClick.bind(this);
        this.onAttachmentUpload = this.onAttachmentUpload.bind(this);
    }


    componentDidMount() {
        if (this.props.requestDocumentTypes) {
            this.props.requestDocumentTypes();
        }
        if (this.props.requestSignatureUsers) {
            this.props.requestSignatureUsers(true);
        }

    }

    UNSAFE_componentWillReceiveProps(nextProps: IReportProbemProps) {
        let reportProblem = { ...this.state.problemState };
        reportProblem.cpaPhoneNumber = !this.props.loggedInCPA || !this.props.loggedInCPA.phoneNumber ? "" : this.props.loggedInCPA.phoneNumber;
        reportProblem.cpaEmail = !this.props.loggedInCPA ? "" : this.props.loggedInCPA.email;
        reportProblem.productType = ProductTypes[1];

        this.setState({
            saving: false,
            showLoader: !(nextProps.model || nextProps.sfModel) ? true : false,
            problemState: reportProblem
        });
        if (nextProps.model && nextProps.reportProblemType == ReportProblemTypes.ReturnSpecific) {
            reportProblem.productType = ProductTypes[2];
            reportProblem.documentId = nextProps.model.id;
            reportProblem.taxYear = nextProps.model.taxYear;
            reportProblem.taxSoftware = nextProps.model.taxSoftware;
            reportProblem.clientId = nextProps.model.clientId;
            reportProblem.returnType = nextProps.model.engagementType.toString();
            reportProblem.status = this.props.problemStep == ReportedStep.Assignments ?
                nextProps.model.documentStatus.toString() : nextProps.model.signatureStatus.toString();

            this.setState({
                problemState: reportProblem
            });

            if (isMutual(nextProps.model)) {
                reportProblem.taxreturnType = TaxreturnType.MarriedJointTaxReturn;
                reportProblem.taxpayerName = !nextProps.model.taxpayer ? "" : nextProps.model.taxpayer.name;
                reportProblem.taxpayerEmail = !nextProps.model.taxpayer ? "" : nextProps.model.taxpayer.email;
                reportProblem.spouseName = !nextProps.model.spouse ? "" : nextProps.model.spouse.name;
                reportProblem.spouseEmail = !nextProps.model.spouse ? "" : nextProps.model.spouse.email;
                this.setState({
                    problemState: reportProblem
                });
            } else if (isIndividual(nextProps.model)) {
                reportProblem.taxreturnType = TaxreturnType.IndividualTaxReturn;
                reportProblem.taxpayerName = !nextProps.model.taxpayer ? "" : nextProps.model.taxpayer.name;
                reportProblem.taxpayerEmail = !nextProps.model.taxpayer ? "" : nextProps.model.taxpayer.email;
                this.setState({
                    problemState: reportProblem
                });
            }
            else if (isPartnership(nextProps.model)) {
                reportProblem.taxreturnType = TaxreturnType.CorporateTaxReturn;
                reportProblem.partnershipName = !nextProps.model.partnership ? "" : nextProps.model.partnership.name;
                reportProblem.partnershipEmail = !nextProps.model.partnership ? "" : nextProps.model.partnership.email;
                this.setState({
                    problemState: reportProblem
                });
            }
        }

        if (nextProps.sfModel && nextProps.reportProblemType == ReportProblemTypes.SignatureSpecific) {
            reportProblem.productType = ProductTypes[3];
            reportProblem.documentId = nextProps.sfModel.signingId;
            reportProblem.taxYear = nextProps.sfModel.taxYear;
            reportProblem.status = SignatureFlowReportSignatureStatus[nextProps.sfModel.signatureStatus];
            reportProblem.sentBy = nextProps.sfModel.senderName;
            reportProblem.clientName = nextProps.sfModel.recipientName;
            reportProblem.documentName = nextProps.sfModel.documentName;

            this.setState({
                problemState: reportProblem
            });
        }
    }


    setContactDropdown = (userList: IUserModel[] | undefined) => {
        var _contactPerson: IValue[] = []
        if (userList) {
            for (let i = 0; i < userList.length; i++) {
                let userId = userList[i]?.userId ? userList[i].userId : 0;
                let firstName: string = userList[i].firstName ? userList[i].firstName : "";
                let lastName: string = userList[i].lastName ? userList[i].lastName : "";

                _contactPerson.push({
                    value: firstName + " " + lastName,
                    label: firstName + " " + lastName
                });
            }
        }
        if (this.props.sfModel) {
            _contactPerson.push({
                value: this.props.sfModel.senderName,
                label: this.props.sfModel.senderName
            });
        }

        return _contactPerson;

    }

    onAttachmentUpload = (e: React.BaseSyntheticEvent) => {
        e?.preventDefault();
        if (this.validateFile(getFileExtension(e.target.files[0]?.name)) &&
            this.validateFileSize(e.target.files[0])) {
            this.setState({
                file: e.target.files[0],
                isAttachedUploaded: true,
                fileName: e.target.files[0]?.name
            });
        }
    }

    addAttachmentClick = (e: React.BaseSyntheticEvent) => {
        e?.preventDefault();
        this.inputElement.current.click();
    }

    validateFileSize = (file: any) => {
        if (file?.size > ReportProblemConstants.MaximumFileSize) {
            VenusNotifier.Error(`${file.name} cannot be uploaded since the size is greater than 15 MB.`, "Report a Problem");
            return false;
        }
        return true;
    }

    validateFile = (fileExtension: string) => {
        if (fileExtension.toLowerCase() === "pdf" ||
            fileExtension.toLowerCase() === "png" ||
            fileExtension.toLowerCase() === "jpg" ||
            fileExtension.toLowerCase() === "jpeg") {
            return true;
        }
        VenusNotifier.Warning("Unsupported file type attached. We support PDF, PNG and JPEG file types.", "Report a Problem")
        return false;
    }

    onRemoveAttachment = () => {
        this.setState({
            file: {},
            isAttachedUploaded: false,
            fileName: ''
        });
    }
    handleFieldChange = (item: ReportProblemProperty, value: any) => {
        let reportProblem = { ...this.state.problemState };
        switch (item) {
            case ReportProblemProperty.productType:
                reportProblem.productType = value;
                if (this.props.problemStep == undefined && this.props.taxSoftwareSetting &&
                    this.props.taxSoftwareSetting.taxSoftware.length > 0) {
                    reportProblem.taxSoftware = this.props.taxSoftwareSetting.taxSoftware[0];
                }

                this.setState({
                    problemState: reportProblem
                });
                break;
            case ReportProblemProperty.clientId:
                reportProblem.clientId = value;
                this.setState({
                    problemState: reportProblem
                });
                break;
            case ReportProblemProperty.returnType:
                reportProblem.returnType = value;
                this.setState({
                    problemState: reportProblem
                });
                break;
            case ReportProblemProperty.taxYear:
                reportProblem.taxYear = value;
                this.setState({
                    problemState: reportProblem
                });
                break;
            case ReportProblemProperty.taxSoftware:
                reportProblem.taxSoftware = value;
                this.setState({
                    problemState: reportProblem
                });
                break;
            case ReportProblemProperty.cpaEmail:

                reportProblem.cpaEmail = value;
                this.setState({
                    problemState: reportProblem
                });
                break;
            case ReportProblemProperty.cpaPhoneNumber:
                reportProblem.cpaPhoneNumber = value;
                this.setState({
                    problemState: reportProblem
                });
                break;
            case ReportProblemProperty.status:
                reportProblem.status = value;
                this.setState({
                    problemState: reportProblem
                });
                break;
            case ReportProblemProperty.partnerName:
                reportProblem.partnershipName = value;
                this.setState({
                    problemState: reportProblem
                });
                break;
            case ReportProblemProperty.partnerEmail: {
                reportProblem.partnershipEmail = value;
                this.setState({
                    problemState: reportProblem
                });
                break;
            }
            case ReportProblemProperty.taxpayerName:
                reportProblem.taxpayerName = value
                this.setState({
                    problemState: reportProblem
                });
                break;
            case ReportProblemProperty.taxpayerEmail: {
                reportProblem.taxpayerEmail = value
                this.setState({
                    problemState: reportProblem
                });
                break;
            }
            case ReportProblemProperty.spouseName:
                reportProblem.spouseName = value;
                this.setState({
                    problemState: reportProblem
                });
                break;
            case ReportProblemProperty.spouseEmail: {
                reportProblem.spouseEmail = value;
                this.setState({
                    problemState: reportProblem
                });
                break;
            }
            case ReportProblemProperty.description:
                reportProblem.description = value;
                this.setState({
                    problemState: reportProblem
                });
                break;
            case ReportProblemProperty.clientName:
                reportProblem.clientName = value;
                this.setState({
                    problemState: reportProblem
                });
                break;
            case ReportProblemProperty.documentName:
                reportProblem.documentName = value;
                this.setState({
                    problemState: reportProblem
                });
                break;
            case ReportProblemProperty.sentBy:
                reportProblem.sentBy = value;
                this.setState({
                    problemState: reportProblem
                });
                break;

        }
    }

    handleClose = () => {
        this.setState({
            problemState: initialState,
            file: {},
            isAttachedUploaded: false,
            fileName: ''
        }, () => { this.props.onCancel() });
    }

    private uploadAttachment(callback?: any) {
        let fileName = this.getValidFileName(this.state.fileName);
        fetch(API_BASE_URL + "/api/SupportIssue/GetUploadAttachmentsLinkAsync?fileName=" +
            fileName, { credentials: 'include', headers: { 'X-Resource-Id': this.props.resourceId } }).then((resp) => resp.json())
            .then(url => {
                let file: File = this.state.file as File;
                Object.defineProperty(file, 'name', {
                    writable: true,
                    value: fileName
                });
                uploadedFile(file, url.sas, fileName, callback);
                logger.trackTrace(`attachment uploaded successfully for report a problem with file name: ${fileName}`);

            }).catch(error => {
                VenusNotifier.Error("Unable to upload Attachment", "Report a Problem")
            });
    }

    private getValidFileName(fileName: string) {
        let fileExtension = getFileExtension(fileName);
        fileName = fileName.replace("." + fileExtension, '');
        fileName = GetSafeFilename(fileName);
        fileName = fileName + "." + fileExtension;
        return fileName;
    }


    public render() {

        const isDataLoaded = (this.props.reportProblemType == ReportProblemTypes.ReturnSpecific && isClientInfoLoaded(this.props.model))
            || (this.props.reportProblemType == ReportProblemTypes.SignatureSpecific && (this.props.sfModel ? true : false));
        let loadingMessage: string = !isDataLoaded ? UploadtaxReturnConstants.OverlayMessage.ApplicationLoading : "";

        let engType: IValue[] = [];
        for (let type in EngagementType) {
            if (isNaN(Number(type))) {
                if ((type !== EngagementType[EngagementType.None]))
                    engType.push({ value: type?.toString(), label: type?.slice(1) });
            }
        }

        const docStatus: SelectOptions[] = DocumentStatusType;
        const sigStatus: SelectOptions[] = SignatureStatusOptionsList;
        const sfSignStatus: SelectOptions[] = SFSignatureStatusOptionsList


        return <div>
            <Modal className="report-problem-modal" show={this.props.show} onHide={this.handleClose}>
                <LoadingOverlay>
                    <Modal.Header closeButton>
                        <Modal.Title className='custom-modal-header'>
                            <span className="fas fa-bullhorn" style={{ color: 'grey', marginRight: '7px',marginTop:"1px" }} />
                            Report a Problem{this.props.sfModel ? "" : getTaxClientName(this.props.model)}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.props.model && this.props.model.id ? <Alert variant='info' >
                            <i className='fas fa-file' style={{ marginRight: '5px' }}>
                            </i><b>{this.props.model.clientId}</b>
                        </Alert> : false}
                        {this.props.sfModel && this.props.sfModel.signingId ? <Alert variant='info' >
                            <i className='glyphicon glyphicon-file' style={{ marginRight: '5px' }}>
                            </i><b>{this.props.sfModel.recipientName}</b>
                        </Alert> : false}

                        <Row className="report-problem-field">
                            <Col sm={2} >
                                <div className="font700">Product Type</div>
                            </Col>
                            <Col sm={4}>
                                <Select
                                    name="form-field-prodType"
                                    value={this.state.problemState.productType}
                                    placeholder="Select Product Type..."
                                    onChange={(event: any) => { this.handleFieldChange(ReportProblemProperty.productType, event?.value) }}
                                    options={getProductTypes()}
                                    disabled={this.props.reportProblemType == ReportProblemTypes.ReturnSpecific ? true : false}
                                    clearable={false}
                                />
                            </Col>

                        </Row>

                        <Row className="report-problem-field">
                            <Col sm={2} >
                                <div className="font700">CPA Firm</div>
                            </Col>
                            <Col sm={4}>
                                <FormControl
                                    type="text"
                                    placeholder="Enter Company Name"
                                    value={this.props.companyName}
                                    disabled={true}
                                />
                            </Col>

                            <Col sm={2}>
                                <div className="font700">Logged-In User</div>
                            </Col>
                            <Col sm={4}>
                                <FormControl
                                    type="text"
                                    placeholder="Enter Logged-In CPA Username"
                                    value={this.props.loggedInCPA != undefined ?
                                        this.props.loggedInCPA.firstName + " " + this.props.loggedInCPA.lastName : ""}
                                    disabled={true}
                                />
                            </Col>
                        </Row>
                        {(this.props.reportProblemType == ReportProblemTypes.ReturnSpecific ||
                            this.state.problemState.productType == ProductTypes[2]) &&
                            <Row className="report-problem-field">
                                <div style={{ display: 'contents' }}>
                                    <Col sm={2} >
                                        <div className="font700 ">Tax Software</div>
                                    </Col>
                                    <Col sm={4}>
                                        <DropdownComponent
                                            id="form-field-taxSoftware"
                                            className=""
                                            selectedValue={this.state.problemState.taxSoftware}
                                            customPlaceHolder="Select Tax Software..."
                                            onChange={(event: any) => { this.handleFieldChange(ReportProblemProperty.taxSoftware, event) }}
                                            options={getTaxSoftwares()}
                                            disabled={this.props.reportProblemType == ReportProblemTypes.ReturnSpecific ? true : false}
                                            clearable={true}
                                        />
                                    </Col>
                                </div>
                            </Row>
                        }
                        <Row className="report-problem-field">

                            <Col sm={2} >
                                <div className="font700">Phone</div>
                            </Col>
                            <Col sm={4}>
                                <PhoneNumberComponent
                                    phoneNumber={this.state.problemState.cpaPhoneNumber}
                                    handleChangePhoneNumber={(value: string) => { this.handleFieldChange(ReportProblemProperty.cpaPhoneNumber, value) }}
                                    disabled={false}
                                />
                            </Col>

                            <Col sm={2} >
                                <div className="font700">Email</div>
                            </Col>
                            <Col sm={4}>
                                <FormControl
                                    type="text"
                                    placeholder="Enter Logged-In CPA Email"
                                    value={this.state.problemState.cpaEmail}
                                    onChange={(event: any) => { this.handleFieldChange(ReportProblemProperty.cpaEmail, event.target?.value) }}
                                />
                            </Col>
                        </Row>
                        {(this.props.reportProblemType == ReportProblemTypes.SignatureSpecific ||
                            this.state.problemState.productType == ProductTypes[3]) &&
                            <div>
                                <Row className="report-problem-field">

                                    <Col sm={2} >
                                        <div className="font700">Tax year</div>
                                    </Col>
                                    <Col sm={4}>
                                        <DropdownComponent
                                            id="form-field-taxYear"
                                            selectedValue={this.state.problemState.taxYear}
                                            customPlaceHolder="Select Tax year..."
                                            onChange={(event: any) => { this.handleFieldChange(ReportProblemProperty.taxYear, event) }}
                                            options={PrepareSFTaxYear()}
                                            disabled={this.props.reportProblemType == ReportProblemTypes.SignatureSpecific ? true : false}
                                            clearable={true}
                                        />
                                    </Col>

                                    <Col sm={2} >
                                        <div className="font700">Client Name</div>
                                    </Col>
                                    <Col sm={4}>
                                        <FormControl
                                            type="text"
                                            value={this.state.problemState.clientName}
                                            placeholder="Enter Client Name"
                                            onChange={(event: any) => { this.handleFieldChange(ReportProblemProperty.clientName, event.target?.value) }}
                                            onBlur={(event: any) => { this.handleFieldChange(ReportProblemProperty.clientName, event.target?.value) }}
                                            disabled={this.props.reportProblemType == ReportProblemTypes.SignatureSpecific ? true : false}
                                        />
                                    </Col>

                                </Row>

                                <Row className="report-problem-field">

                                    <Col sm={2} >
                                        <div className="font700">Status</div>
                                    </Col>
                                    <Col sm={4}>
                                        <DropdownComponent
                                            id="form-field-returnType"
                                            selectedValue={this.state.problemState.status}
                                            customPlaceHolder="Select Status..."
                                            onChange={(event: any) => { this.handleFieldChange(ReportProblemProperty.status, event) }}
                                            options={sfSignStatus}
                                            disabled={this.props.reportProblemType == ReportProblemTypes.SignatureSpecific ? true : false}
                                            clearable={true}
                                        />
                                    </Col>

                                    <Col sm={2} >
                                        <div className="font700">Sent By</div>
                                    </Col>
                                    <Col sm={4}>
                                        <DropdownComponent
                                            id="form-field-taxYear"
                                            selectedValue={this.state.problemState.sentBy}
                                            customPlaceHolder="Select Sent By..."
                                            onChange={(event: any) => { this.handleFieldChange(ReportProblemProperty.sentBy, event) }}
                                            options={this.setContactDropdown(this.props.sfUsers)}
                                            disabled={this.props.reportProblemType == ReportProblemTypes.SignatureSpecific ? true : false}
                                            clearable={true}
                                        />
                                    </Col>

                                </Row>

                                <Row className="report-problem-field">

                                    <Col sm={2} >
                                        <div className="font700">Document Name</div>
                                    </Col>
                                    <Col sm={4}>
                                        <FormControl
                                            type="text"
                                            value={this.state.problemState.documentName}
                                            placeholder="Enter Document Name"
                                            onChange={(event: any) => { this.handleFieldChange(ReportProblemProperty.documentName, event.target?.value) }}
                                            onBlur={(event: any) => { this.handleFieldChange(ReportProblemProperty.documentName, event.target?.value) }}
                                            disabled={this.props.reportProblemType == ReportProblemTypes.SignatureSpecific ? true : false}
                                        />
                                    </Col>

                                </Row>
                            </div>
                        }


                        {(this.props.reportProblemType == ReportProblemTypes.ReturnSpecific ||
                            this.state.problemState.productType == ProductTypes[2]) &&
                            <div>
                                <Row className="report-problem-field">

                                    <Col sm={2} >
                                        <div className="font700">Return Type</div>
                                    </Col>
                                    <Col sm={4}>
                                        <DropdownComponent
                                            id="form-field-returnType"
                                            selectedValue={this.state.problemState.returnType}
                                            customPlaceHolder="Select Return Type..."
                                            onChange={(event: any) => { this.handleFieldChange(ReportProblemProperty.returnType, event) }}
                                            options={engType}
                                            disabled={this.props.reportProblemType == ReportProblemTypes.ReturnSpecific ? true : false}
                                            clearable={true}
                                        />
                                    </Col>

                                    <Col sm={2} >
                                        <div className="font700">Tax year</div>
                                    </Col>
                                    <Col sm={4}>
                                        <DropdownComponent
                                            id="form-field-taxYear"
                                            selectedValue={this.state.problemState.taxYear}
                                            customPlaceHolder="Select Tax year..."
                                            onChange={(event: any) => { this.handleFieldChange(ReportProblemProperty.taxYear, event) }}
                                            options={PrepareTaxYear()}
                                            disabled={this.props.reportProblemType == ReportProblemTypes.ReturnSpecific ? true : false}
                                            clearable={true}
                                        />
                                    </Col>
                                </Row>

                                <Row className="report-problem-field">

                                    <Col sm={2} >
                                        <div className="font700">Client Id</div>
                                    </Col>
                                    <Col sm={4}>
                                        <FormControl
                                            type="text"
                                            value={this.state.problemState.clientId}
                                            placeholder="Enter Client Id"
                                            onChange={(event: any) => { this.handleFieldChange(ReportProblemProperty.clientId, event.target?.value) }}
                                            onBlur={(event: any) => { this.handleFieldChange(ReportProblemProperty.clientId, event.target?.value) }}
                                            disabled={this.props.reportProblemType == ReportProblemTypes.ReturnSpecific ? true : false}
                                        />
                                    </Col>

                                    <Col sm={2} >
                                        <div className="font700">Status</div>
                                    </Col>
                                    <Col sm={4}>
                                        {this.props.reportProblemType == ReportProblemTypes.ReturnSpecific ?
                                            <DropdownComponent
                                                id="form-field-status"
                                                selectedValue={this.state.problemState.status}
                                                onChange={(event: any) => { this.handleFieldChange(ReportProblemProperty.status, event) }}
                                                customPlaceHolder="Select Status..."
                                                options={this.props.problemStep == ReportedStep.Assignments ? docStatus : sigStatus}
                                                disabled={this.props.reportProblemType == ReportProblemTypes.ReturnSpecific ? true : false}
                                                clearable={true}
                                            />
                                            :
                                            <select className="form-control Select-control report-problem-status"
                                                value={this.state.problemState.status}
                                                onChange={(event: any) => {
                                                    this.handleFieldChange(ReportProblemProperty.status,
                                                        event.target?.value)
                                                }}
                                                placeholder="Select Status..."
                                            >
                                                <option value="" disabled={true} hidden={true}>{"Select Status..."}</option>
                                                <optgroup label="Document Status">
                                                    {docStatus.map((status: SelectOptions, index) => {
                                                        return <option key={index} value={status.value}>{status.label}</option>
                                                    })}
                                                </optgroup>

                                                <optgroup label="Signature Status">
                                                    {sigStatus.map((status: SelectOptions, index) => {
                                                        return <option key={index} value={status.value}>{status.label}</option>
                                                    })}
                                                </optgroup>
                                            </select>
                                        }
                                    </Col>
                                </Row>
                            </div>
                        }
                        {this.props.reportProblemType == ReportProblemTypes.ReturnSpecific
                            && this.props.model && isMutual(this.props.model) ?
                            (<div>
                                <TaxpayerDetails
                                    taxpayerName={this.state.problemState.taxpayerName}
                                    taxpayerEmail={this.state.problemState.taxpayerEmail}
                                    handleValueChange={this.handleFieldChange}
                                    disabled={this.props.reportProblemType == ReportProblemTypes.ReturnSpecific ? true : false}
                                />

                                <SpouseDetails
                                    spouseName={this.state.problemState.spouseName}
                                    spouseEmail={this.state.problemState.spouseEmail}
                                    handleValueChange={this.handleFieldChange}
                                    disabled={this.props.reportProblemType == ReportProblemTypes.ReturnSpecific ? true : false}
                                />
                            </div>)
                            : this.props.model && isIndividual(this.props.model) ?
                                (<div>
                                    <TaxpayerDetails
                                        taxpayerName={this.state.problemState.taxpayerName}
                                        taxpayerEmail={this.state.problemState.taxpayerEmail}
                                        handleValueChange={this.handleFieldChange}
                                        disabled={this.props.reportProblemType == ReportProblemTypes.ReturnSpecific ? true : false}
                                    />
                                </div>)
                                : this.props.model && isPartnership(this.props.model) &&
                                (<div>
                                    <PartnerDetails
                                        partnerName={this.state.problemState.partnershipName}
                                        partnerEmail={this.state.problemState.partnershipEmail}
                                        handleValueChange={this.handleFieldChange}
                                        disabled={this.props.reportProblemType == ReportProblemTypes.ReturnSpecific ? true : false}
                                    />
                                </div>)
                        }

                        {(this.state.problemState.productType == ProductTypes[2]
                            && this.props.reportProblemType == ReportProblemTypes.Generic
                            && (this.state.problemState.returnType != "" && this.state.problemState.returnType != null
                                && this.state.problemState.returnType != EngagementType[EngagementType.E1040]
                                && this.state.problemState.returnType != EngagementType[EngagementType.E1040NR])) &&
                            (<div>
                                <PartnerDetails
                                    partnerName={this.state.problemState.partnershipName}
                                    partnerEmail={this.state.problemState.partnershipEmail}
                                    handleValueChange={this.handleFieldChange}
                                    disabled={false}
                                />
                            </div>)
                        }

                        {(this.state.problemState.productType == ProductTypes[2]
                            && this.props.reportProblemType == ReportProblemTypes.Generic
                            && this.state.problemState.returnType == EngagementType[EngagementType.E1040]) &&
                            (<div>
                                <TaxpayerDetails
                                    taxpayerName={this.state.problemState.taxpayerName}
                                    taxpayerEmail={this.state.problemState.taxpayerEmail}
                                    handleValueChange={this.handleFieldChange}
                                    disabled={false}
                                />

                                <SpouseDetails
                                    spouseName={this.state.problemState.spouseName}
                                    spouseEmail={this.state.problemState.spouseEmail}
                                    handleValueChange={this.handleFieldChange}
                                    disabled={false}
                                />
                            </div>)
                        }

                        {(this.state.problemState.productType == ProductTypes[2]
                            && this.props.reportProblemType == ReportProblemTypes.Generic
                            && this.state.problemState.returnType == EngagementType[EngagementType.E1040NR]) &&
                            (<div>
                                <TaxpayerDetails
                                    taxpayerName={this.state.problemState.taxpayerName}
                                    taxpayerEmail={this.state.problemState.taxpayerEmail}
                                    handleValueChange={this.handleFieldChange}
                                    disabled={false}
                                />
                            </div>)
                        }

                        <Row className="report-problem-field" style={{ display: 'flow-root' }}>

                            <Col sm={2} >
                                <div className="font700">Description</div>
                            </Col>

                            <FormGroup>
                                <Col sm={12}>
                                    <FormControl
                                        value={this.state.problemState.description}
                                        id="report-problem-text"
                                        style={{ height: '150px', marginTop: '10px' }}
                                        as="textarea"
                                        onChange={(event: any) => { this.handleFieldChange(ReportProblemProperty.description, event.target?.value) }} />
                                </Col>
                            </FormGroup>
                        </Row>
                        <Row className="report-problem-field">
                            {
                                !this.state.isAttachedUploaded ?
                                    <div style={{ paddingLeft: '15px' }}>
                                        <a href="" style={{ textDecoration: 'none' }} onClick={this.addAttachmentClick}>
                                            <i className="fa-paperclip fas"></i> Add an Attachment
                                        </a>
                                        <input type="file"
                                            ref={this.inputElement}
                                            accept=".jpg,.png,.pdf,.jpeg"
                                            onChange={this.onAttachmentUpload}
                                            style={{ opacity: '0%', position: 'absolute' }}
                                            data-test-auto="880B0953-0C0E-4969-8B53-363AEB4E7550" />
                                    </div> :
                                    <div style={{ display: '-webkit-inline-flex', paddingLeft: '15px' }}>
                                        <h4 className="text-primary">{this.state.fileName} </h4>
                                        <button title={"Delete Attachment"} type="button"
                                            className="btn-white"
                                            onClick={this.onRemoveAttachment}
                                            data-test-auto="F732B8FC-2CA6-4071-984A-8F297477887A">
                                            <i className="text-danger glyphicon glyphicon-remove" />
                                        </button>
                                    </div>
                            }
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant='default'
                            data-test-auto='1137347C-947C-4D8A-ABA7-0DE9D70DB526'
                            onClick={this.handleClose}
                            className="btn-white" >
                            <i className='fas fa-times'></i>Cancel
                        </Button>
                        <Button
                            data-test-auto='E0F1216B-0B82-456E-8EEA-57302D25A818'
                            onClick={this.onSubmit}
                            variant="info">
                            <i className='fas fa-check'></i>Submit
                        </Button>
                    </Modal.Footer>
                    <Loader loading={this.state.showLoader
                        || (this.props.reportProblemType == ReportProblemTypes.ReturnSpecific && !isDataLoaded)
                        || (this.props.reportProblemType == ReportProblemTypes.SignatureSpecific && !isDataLoaded)}
                        text={!isDataLoaded ?
                            loadingMessage : ReportProblemConstants.OverlayMessage.SubmittingIssue} />
                </LoadingOverlay>
            </Modal>
        </div>
    }

    private onSubmit() {
        if (this.state.problemState.description.trim() === '') {
            VenusNotifier.Warning("Please enter problem description to report.", "Report a Problem");
            return;
        }
        else if (!isValidEmailAddress(this.state.problemState.cpaEmail)) {
            VenusNotifier.Warning(ClientInfoValidation.inCorrectEmail, "");
            return;
        }
        else if (this.state.problemState.cpaPhoneNumber.trim() != "") {
            if (this.state.problemState.cpaPhoneNumber.length < 10 || /^[0-9]{1,10}$/.test(this.state.problemState.cpaPhoneNumber) == false) {
                VenusNotifier.Warning(ValidationContants.PhoneNumberLengthWarning, "");
                return;
            }
        }

        if (!this.state.saving) {
            this.setState({ saving: true, showLoader: true },
                () => {
                    if (this.state.isAttachedUploaded) {
                        let problemState = { ...this.state.problemState };
                        problemState.isAttachmentPresent = true;
                        problemState.fileName = this.getValidFileName(this.state.fileName);;
                        this.uploadAttachment(() => {
                            this.props.onReportProblemSave(problemState.documentId, problemState,
                                () => { this.setState({ problemState: initialState, file: {}, fileName: '', isAttachedUploaded: false }); })
                        });
                    }
                    else {
                        this.props.onReportProblemSave(this.state.problemState.documentId, this.state.problemState,
                            () => { this.setState({ problemState: initialState }); })
                    }
                });
        }

    }
};

function getTaxClientName(model: ITaxReturn | undefined) {
    if (!model) {
        return " ~ No Tax-Return!";
    }
    if (isIndividual(model)) {
        return " with " + splitName(model.taxpayer.name);
    }
    if (isMutual(model)) {
        return " with " + splitName(model.taxpayer.name);
    }
    if (isPartnership(model)) {
        return " with " + model.partnership.name;
    }
    return "";
}
