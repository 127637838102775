import * as React from "react";
import { Modal, Alert, Button } from "react-bootstrap";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import { FaLink } from "react-icons/fa";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { VenusNotifier } from "../../helper/VenusNotifier";
import { Col, Row } from "react-bootstrap";
import { IDeliveredTaxReturns } from "../DeliveredTaxReturns";

interface IGroupedReturnResendAccessLinkProps {
    show: boolean;
    onCancel: () => void;
    model: IDeliveredTaxReturns;
    onResendAccessLink: (documentId: number) => void;
}
interface IGroupedReturnResendAccessLinkState {
    message: string;
}

const msg = {
    saving: "Sending Access Link...",
    loading: "Loading, please wait..."
};

export class GroupedReturnResendAccessLinkModal extends React.Component<
    IGroupedReturnResendAccessLinkProps,
    IGroupedReturnResendAccessLinkState
> {
    constructor(props: IGroupedReturnResendAccessLinkProps) {
        super(props);
        this.state = {
            message: msg.loading
        };
    }
    UNSAFE_componentWillReceiveProps(nextProps: IGroupedReturnResendAccessLinkProps) {
        if (!nextProps.show) {
            this.setState({ message: msg.loading });
        }
    }

    private onCopy = () => {
        console.log("Link has been copied");
        VenusNotifier.Success("Access Link has been copied", null);
    };
    private onResendAccessLink = () => {
        this.props.onResendAccessLink(this.props.model.groupId);
    };

    public render() {
        return (
            <Modal className="grouped-resend-access-link" show={this.props.show} onHide={this.props.onCancel}>
                <Modal.Header closeButton>
                    <Modal.Title className="custom-modal-header">
                        <FaLink className="modalIcon" />
                        Resend Access Link
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <LoadingOverlay style={{ minHeight: 100 }}>
                        <Row>
                            <Col sm={12}>
                                <Alert variant="warning">
                                    <i className="fas fa-exclamation-triangle" style={{ marginRight: "5px", color: "#666" }}></i>
                                    The changes made will impact all the returns belonging to{" "}
                                    {this.props.model && this.props.model.groupName ? this.props.model.groupName : ""}
                                </Alert>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={10}>
                                {this.props.model && this.props.model.controllerViewModel
                                    ? this.props.model.controllerViewModel.name +
                                      " (" +
                                      this.props.model.controllerViewModel.emailAddress +
                                      ")"
                                    : ""}
                            </Col>
                            <Col sm={1}>
                                <a
                                    onClick={() => this.onResendAccessLink()}
                                    title="Resend"
                                    style={{ marginLeft: "7px" }}
                                    className="fas fa-share-square"
                                    href="javascript:void(0)"
                                />
                            </Col>
                            <Col sm={1}>
                                <CopyToClipboard
                                    text={
                                        this.props.model && this.props.model.controllerViewModel
                                            ? this.props.model.controllerViewModel.accessLink
                                            : ""
                                    }
                                >
                                    <a
                                        title="Copy Link Address"
                                        onClick={() => this.onCopy()}
                                        style={{ marginLeft: "7px" }}
                                        className="far fa-copy"
                                        href="javascript:void(0)"
                                    />
                                </CopyToClipboard>
                            </Col>
                        </Row>
                    </LoadingOverlay>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        onClick={this.props.onCancel}
                        variant="default"
                        className="btn btn-white"
                        data-test-auto="BC3C6FF2-75C2-45AD-8552-8915E4C8EDF0"
                    >
                        <i className="fas fa-times"></i>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}
