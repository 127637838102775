import * as React from 'react';
import { Button } from 'react-bootstrap';
import { Nav } from 'react-bootstrap';
import { QuickReportType } from '../Filters';
import { ReportFilterConstants } from '../../helper/Constants';

interface QuickReportToolbarProp {
    onPageReload: () => void;
    refreshDelay?: boolean;
    onSelectQuickReports: (quickReportType: QuickReportType) => any;
    selectedQuickReport: QuickReportType;
    onExportToExcel(selectedQuickReport: QuickReportType, ExportToComplete: () => void): void;
    closeQuickReport: () => void;
}

interface QuickReportToolbarState {
    exportingState: boolean;
    showQuickReport: boolean;
}

export class QuickReportToolbar extends React.Component<QuickReportToolbarProp, QuickReportToolbarState>{
    state: QuickReportToolbarState = {
        exportingState: false,
        showQuickReport: false
    }

    onExportToComplete = () => {
        this.setState({ exportingState: false });
    }

    public render() {
        let selectedReport = this.props.selectedQuickReport === QuickReportType.LinkNotAccessed ? "Link Not Accessed" : "KBA Failed";

        return <div className="filters">
            <div className="btn-group marR05">
                <Button
                    variant="default"
                    onClick={this.props.onPageReload}
                    title="Refresh"
                    disabled={this.props.refreshDelay}
                    className="btn-white btn-only marr10"
                    style={{ paddingLeft: 12 }}
                    data-test-auto="11bc25b0-f325-4170-afa9-598384364972">
                    <span className={(this.props.refreshDelay ? "fa-spin " : " ") + 'fas fa-redo-alt fa-btn-icon'}
                        style={{ padding: '2px 0', textShadow: '0px 1px 0px rgba(255,255,255,.5)', fontSize: '12px' }}>
                    </span>
                </Button>
            </div>

            <div className={`btn-group marR05 ${this.state.showQuickReport ? "show" : ""}`}>
                <span className="quick-report-selection">  <i className="fas fa-file-alt marR03" ></i>
                    <span className="marLR03" style={{ backgroundColor: "white" }}> {selectedReport} &nbsp; </span>
                </span>
                <button className="btn btn-default btn-filter" type="button"
                    title="Clear Quick Report"
                    data-test-auto="021FC6E9-DF57-4E8D-A1BE-FA31EC11DBFC"
                    onClick={this.props.closeQuickReport}>
                    <span className="fas fa-times-circle" > </span>
                </button>
                <button
                    className="btn btn-default btn-filter"
                    type="button"
                    data-toggle="dropdown"
                    data-test-auto="42EAFC59-D8D5-40B3-9983-57AF9BC2591C"
                    onClick={() => this.setState({ showQuickReport: !this.state.showQuickReport })}
                >
                    <span className="fas fa-angle-down" ></span>
                </button>
                <ul className={`dropdown-menu dropdown-menu-right ${this.state.showQuickReport ? "show" : ""}`} style={{ width: '160px' }} role="menu" aria-labelledby="dropdownMenu">
                    <li>
                        <Nav.Link href='javascript:'
                            onClick={() => { this.props.onSelectQuickReports && this.props.onSelectQuickReports(QuickReportType.LinkNotAccessed) }}
                            data-test-auto="5C118443-2375-4DEE-9DCB-A9890947BABF">
                            <span>{ReportFilterConstants.QuickReports.LinkNotAccessed}</span>
                        </Nav.Link>
                    </li>
                    <li>
                        <Nav.Link href='javascript:'
                            onClick={() => { this.props.onSelectQuickReports && this.props.onSelectQuickReports(QuickReportType.KBAFailed) }}
                            data-test-auto="5E8A756C-FF81-45FB-9A05-7C1101D682A3">
                            <span>{ReportFilterConstants.QuickReports.KBAFailed}</span>
                        </Nav.Link>
                    </li>
                </ul>
            </div>

            <Button variant="default" className="btn-only marR05" type="button" disabled={true} title="Column Options">
                <span className="fas fa-sliders-h fa-lg"></span>
            </Button>
            <div className="btn-group marR05">
                <button disabled className="btn btn-default btn-filter" type="button" title={ReportFilterConstants.ControlsTitle.ApplyMostRecentFilter}>
                    <span className="fas fa-filter"></span>
                </button>
                <button disabled className="btn btn-default btn-filter" type="button" data-toggle="dropdown" data-test-auto="411EE0E9-14BE-48EB-BC78-F846E7FEB4BA">
                    <span className="fas fa-angle-down" title={ReportFilterConstants.ControlsTitle.ToggleDropdownMenu}></span>
                </button>
            </div>
            <Button
                variant="default"
                className="btn-only marR05"
                title={ReportFilterConstants.ControlsLabel.ExportToExcel}
                onClick={() => { this.setState({ exportingState: true }, () => { this.props.onExportToExcel(this.props.selectedQuickReport, this.onExportToComplete); }) }}
                disabled={this.state.exportingState}
                data-test-auto="D14CBB8C-9B9E-4EDF-B80E-1D63665DF959">
                <span className={this.state.exportingState ? 'fas fa-spinner fa-spin text-info' : 'fas fa-file-export'} ></span>
            </Button> 
        </div>
    }
}

export default QuickReportToolbar;