import * as React from 'react'
import { Modal, Button, Col, Row, Container } from 'react-bootstrap';
import * as UserSettingStore from '../store/userManagement/UserSettingStore';

type ChangeDefaultProps =
    {
        userSettings: UserSettingStore.UserSettings,
    }
    & typeof UserSettingStore.actionCreators


interface ChangeDefaultState {
    openModal: boolean;
}

export class ChangeDefault extends React.Component<ChangeDefaultProps, ChangeDefaultState>{
    state: ChangeDefaultState = { openModal: false }

    componentWillUpdate() {

        if (this.props.userSettings.settings != undefined && this.props.userSettings.settings.defaultSettings !== undefined && this.state.openModal !== this.props.userSettings.settings.defaultSettings.showDefaultWarning)
            this.setState({ openModal: this.props.userSettings.settings.defaultSettings.showDefaultWarning })

    }

    private onCloseModal = (): void => {
        // e.preventDefault();
        let settings = { ...this.props.userSettings.settings }

        this.setState({ openModal: !this.state.openModal })
        this.props.ChangeNotificationStatus(this.props.userSettings.settings?.userId);
        settings.defaultSettings.showDefaultWarning = false;
        this.props.updateUserSettings(settings)
    }

    render() {

        return (
            <div className="margin-tbl-20">
                <Modal className='change-default-warning-modal' show={this.state.openModal} onHide={this.onCloseModal} >
                    <Modal.Header closeButton>
                        <div><Modal.Title>Default User Satus Changed</Modal.Title></div>  
                    </Modal.Header>
                    <Modal.Body>
                        <Container className='popup_grid_container'>
                            <div>Your default user has been modified or deleted. To select a new one, please go to 'My Settings'.</div>
                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            className="btn-primary"
                            variant='default'
                            onClick={this.onCloseModal}
                        >                            
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

