import * as React from 'react';
import { Button } from 'react-bootstrap';
import { ICompanySettings } from '../../../../Core/ViewModels/Company/CompanySettingsViewModel';
import { MessageDetails, initialMessageDetails } from '../../../../Core/ViewModels/CompanySettings/SavedMessageViewModel';
import * as PaperFiledMessageStore from '../../../../store/settings/PaperFiledMessageStore';
import { PaperFiledPopUp } from './PaperFiledPopUp';
import { VenusNotifier } from '../../../../components/helper/VenusNotifier';
import { SavedMessageConstants } from '../../../../components/helper/Constants';
import * as bootbox from 'bootbox';
import { ReplaceHelperVariable } from '../../../helper/HelperFunctions';
import { container } from '../../../../Startup/inversify.config';
import { TYPES } from "../../../../Startup/types";
import { IHtmlUtilities } from '../../../../Core/Utilities/HtmlUtilities';
import { IHelptextModel } from '../../../../Core/ViewModels/Common/IHelptextModel';
import { ISSRSettings, SSRSettings } from '../../../../Core/ViewModels/Company/Settings/SSRSettingsViewModel';
import { InstructionsActionButtonResources } from '../../../../components/helper/ResourceIdConstants';

var htmlencode = require('htmlencode');

const htmlUtilities = container.get<IHtmlUtilities>(TYPES.IHtmlUtilities);
const isEqual = require("react-fast-compare");

export interface IPaperFiledProps {
    paperFiledMessages: PaperFiledMessageStore.IPaperFiledMessageDictionary;
    companySettings: ICompanySettings;
    ssrSettings: ISSRSettings;
    saveMessage: (message: MessageDetails, companySettings: ICompanySettings, isSetDefault: boolean, resourceId?: string) => void;
    updateMessage: (message: MessageDetails, resourceId?: string) => void;
    deleteMessage: (message: MessageDetails, resourceId?: string) => void;
    updateCompanySettings: (companySettings: ICompanySettings) => void;
    updateSSRSettings: (ssrSettings: ISSRSettings, callback?: () => void,resourceId?: string) => void;
    helptexts?: IHelptextModel[];
    parentResourceIdentifier:string;
}

export interface IPaperFiledState {
    showPaperFiledPopUp: boolean;
    isUpdate: boolean;
    selectedMessage: number;
    buttonText: string;
    title: string;
}

export class PaperFiled extends React.Component<IPaperFiledProps, IPaperFiledState> {
    state = {
        showPaperFiledPopUp: false,
        isUpdate: false,
        selectedMessage: 0,
        buttonText: '',
        title: ''
    };

    componentDidMount() {
        if (this.props.ssrSettings) {
            this.setState({
                selectedMessage: this.props.ssrSettings.defaultMessageSettings.paperFiledMessage
            });
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps: IPaperFiledProps) {
        if (!isEqual(this.props, nextProps)) {
            this.setState({
                selectedMessage: nextProps.ssrSettings ? nextProps.ssrSettings.defaultMessageSettings.paperFiledMessage : 0
            });
        }
    }

    onAddButtonClick = () => {
        this.setState({
            showPaperFiledPopUp: true,
            isUpdate: false,
            buttonText: 'Add',
            title: 'Add Paper Filed Instruction'
        });
    }

    onEditButtonClick = () => {
        this.setState({
            showPaperFiledPopUp: true,
            isUpdate: true,
            buttonText: 'Save',
            title: 'Edit Paper Filed Instruction'
        });
    }

    onDeleteButtonClick = () => {
        if (this.state.selectedMessage == this.props.ssrSettings.defaultMessageSettings.paperFiledMessage) {
            bootbox.alert({
                message: SavedMessageConstants.DeleteMessageWarning,
                size: 'small',
                buttons: {
                    ok: {
                        label: '<i class="fas fa-check"></i> OK',
                        className: 'btn btn-success'
                    }
                }
            });
        }
        else {
            let _currentObject = this
            bootbox.confirm({
                title: 'Delete Paper Filed Instruction',
                message: 'Are you sure you want to delete this message?',
                buttons: {
                    cancel: {
                        label: '<i class="fas fa-times"></i> Cancel',
                        className: 'btn-white btn-default'
                    },
                    confirm: {
                        label: '<i class="fas fa-check"></i> Delete',
                        className: 'btn-info'
                    }
                },
                callback: (result: boolean) => {
                    if (result == true) {
                        _currentObject.props.deleteMessage(_currentObject.props.paperFiledMessages[_currentObject.state.selectedMessage].messageDetails,
                            this.props.parentResourceIdentifier + InstructionsActionButtonResources.ButtonDelete);
                        _currentObject.setState({ selectedMessage: _currentObject.props.ssrSettings.defaultMessageSettings.paperFiledMessage });
                    }
                }
            });
        }
    }

    onListItemClick = (id: number) => {
        this.setState({
            selectedMessage: id
        });
    }

    onCancelButtonClick = () => {
        this.setState({
            showPaperFiledPopUp: false,
            isUpdate: false
        });
    }

    addWelcomeMessage = (message: MessageDetails, isSetDefault: boolean) => {
        let companySettings = this.props.companySettings;
        if (companySettings) {
            this.props.saveMessage(message, companySettings, isSetDefault, this.props.parentResourceIdentifier + InstructionsActionButtonResources.ButtonAdd);
            this.setState({
                showPaperFiledPopUp: false,
                isUpdate: false
            })
        } else {
            VenusNotifier.Error(SavedMessageConstants.SaveMessageError, null);
        }
    }

    editWelcomeMessage = (message: MessageDetails, isSetDefault: boolean) => {
        let ssrSettings = new SSRSettings(this.props.ssrSettings.defaultMessageSettings);
        if (ssrSettings) {
            if (ssrSettings.defaultMessageSettings && message.id == ssrSettings.defaultMessageSettings.paperFiledMessage && !isSetDefault) {//Default message shouldnot be turned off
                VenusNotifier.Warning(SavedMessageConstants.SelectDefaultMessageWarning, null);
                return false;
            }
            else {
                if (ssrSettings.defaultMessageSettings &&  isSetDefault && message.id != ssrSettings.defaultMessageSettings.paperFiledMessage) {
                    ssrSettings.defaultMessageSettings.paperFiledMessage = message.id;
                    this.props.updateSSRSettings(ssrSettings, undefined, this.props.parentResourceIdentifier + InstructionsActionButtonResources.ButtonEdit);
                }

                this.props.updateMessage(message, this.props.parentResourceIdentifier + InstructionsActionButtonResources.ButtonEdit);
                this.setState({
                    showPaperFiledPopUp: false,
                    isUpdate: false
                });
            }
        }
        else {
            VenusNotifier.Error(SavedMessageConstants.UpdateMessageError, null);
        }
    }

    public render() {
        const messageList = this.props.paperFiledMessages ? this.props.paperFiledMessages : [];
        const currentMessage = this.props.paperFiledMessages && this.props.paperFiledMessages[this.state.selectedMessage]
            ? this.props.paperFiledMessages[this.state.selectedMessage]?.messageDetails
            : initialMessageDetails;
        const defaultMessage = this.props.ssrSettings ? this.props.ssrSettings.defaultMessageSettings.paperFiledMessage : 0;

        return <div className="new-main-content">
            <h3>Paper Filed Instructions</h3>
            <hr />
            <div className="row" style={{ position: "relative" }}>
                <div className="col-sm-6 col-lg-4" style={{ marginLeft: 17 }}>
                    <div className="row dispFR">
                        <div className="float-right">
                            <Button
                                data-test-auto="BA87F3EA-A5C1-49E7-8AAE-02D3699E1019"
                                data-resource-id = { this.props.parentResourceIdentifier + InstructionsActionButtonResources.ButtonAdd}
                                variant="info"
                                style={{ padding: 12, minWidth: 40 }}
                                onClick={this.onAddButtonClick}
                                title="Add Message">
                                <span className="fas fa-plus" />
                            </Button>
                        </div>
                    </div>
                    <div className="row" style={{ marginTop: 10, overflowY: 'auto', height: 230, border: 'rgb(221, 221, 221) 1px solid' }}>
                        <div className="width100">
                            <ul className="list-group">
                                {
                                    Object.keys(messageList).map((key, index) => (
                                        this.props.paperFiledMessages[parseInt(key)] &&
                                        this.props.paperFiledMessages[parseInt(key)].messageDetails &&
                                        <li
                                            key={this.props.paperFiledMessages[parseInt(key)].messageDetails.id}
                                            className={this.props.paperFiledMessages[parseInt(key)].messageDetails.id == this.state.selectedMessage ? "custom-list-group-item-singleline custom-active" : "custom-list-group-item-singleline"}
                                            title={this.props.paperFiledMessages[parseInt(key)].messageDetails.name}
                                            onClick={() => this.onListItemClick(parseInt(key))}
                                        >
                                            <div className="width100 customFlexProperty">
                                                <div style={{ textOverflow: 'ellipsis' }} className={this.props.paperFiledMessages[parseInt(key)].messageDetails.id == defaultMessage ? "nopadding overflowHidden" : "overflowHidden"}>
                                                    {this.props.paperFiledMessages[parseInt(key)].messageDetails.name}
                                                </div>
                                                {
                                                    this.props.paperFiledMessages[parseInt(key)].messageDetails.id == defaultMessage ?
                                                        <div style={{ padding: '0' }}>
                                                            <span className={"badge"} style={{ marginLeft: 10, float: 'initial' }}>Default</span>
                                                        </div>
                                                        : <span></span>
                                                }
                                            </div>
                                        </li>
                                    ))
                                }
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="col-sm-6 col-lg-5" style={{ marginLeft: 32 }}>
                    <div className="row dispFR">
                        <div className="float-right">
                            <Button
                                data-test-auto="669B5F0C-CAF4-4AD9-85D9-BC55318F02C6"
                                data-resource-id = { this.props.parentResourceIdentifier + InstructionsActionButtonResources.ButtonEdit}
                                variant="info"
                                bsPrefix="btn btn-icon-default btn-lg fas fa-edit"
                                style={{ padding: 12, minWidth: 40 }}
                                title="Edit Message"
                                onClick={this.onEditButtonClick}
                            >
                            </Button>
                            <Button
                                data-test-auto="12E908D9-F2B4-4E85-BE5A-030688205707"
                                data-resource-id = { this.props.parentResourceIdentifier + InstructionsActionButtonResources.ButtonDelete}
                                variant="info"
                                bsPrefix="btn btn-icon-default btn-lg fas fa-trash-alt"
                                title="Delete Message"
                                style={{ marginLeft: 5, padding: 12, minWidth: 40 }}
                                onClick={this.onDeleteButtonClick}
                            >
                            </Button>
                        </div>
                    </div>
                    <div className="row" style={{ paddingTop: 10, height: 265 }}>
                        <div className="width100">
                            <div className="form-group">
                                <label>Message Name{currentMessage?.id == defaultMessage ?
                                    <span className="badge" style={{ marginLeft: 10, marginTop: -1 }}>Default</span> : <span></span>}
                                </label>
                                <div className="form-control" style={{ backgroundColor: '#f1f1f1' }}>{currentMessage.name}</div>
                            </div>
                            <div className="form-group">
                                <label >Message Body</label>
                                <div className="form-control" style={{ overflowY: 'auto', height: 100, backgroundColor: '#f1f1f1' }}
                                    dangerouslySetInnerHTML={{ __html: htmlUtilities.sanitize(ReplaceHelperVariable(htmlencode.htmlDecode(currentMessage.body))) }}>
                                </div>
                            </div>
                            {currentMessage.isAllowToEdit ? <div className="form-group custom-control">
                                <i className="fas fa-check" style={{ color: '#337ab7' }}></i>
                                <label className="custom-control-label" style={{ marginLeft: 5 }} >Allow users to edit before sending</label>
                            </div> : ""}
                        </div>
                    </div>
                </div>
            </div>

            <PaperFiledPopUp
                paperFiledMessages={this.props.paperFiledMessages}
                selectedMessage={this.state.isUpdate ? currentMessage : initialMessageDetails}
                show={this.state.showPaperFiledPopUp}
                onHide={this.onCancelButtonClick}
                submitButtonClick={this.state.isUpdate ? this.editWelcomeMessage : this.addWelcomeMessage}
                buttonText={this.state.buttonText}
                title={this.state.title}
                defaultMessageId={defaultMessage}
                isUpdate={this.state.isUpdate}
                helptexts={this.props.helptexts}
            />

        </div>;
    }
}

export default PaperFiled;
