import * as React from 'react'
import { Modal, Button, Container } from 'react-bootstrap';
import { container } from './../Startup/inversify.config';
import { ISessionLocalStore } from '../Core/Utilities/SessionStore';
import { TYPES } from '../Startup/types';
import { signoutRedirect } from '../oidcClient/userService';
import { LogoutCause } from '../store/auth/reducer';
import { UserAutoLogoutPopupConstants } from './helper/Constants';
import { resetUserPrivilegeChangeState } from '../store/auth/actions';
import store from '../store';
import { resetUserCache } from '../store/auth/auth.apis';
import { clearAllTemporaryFilter } from './reports/Filters';
const SessionStore = container.get<ISessionLocalStore>(TYPES.ISessionStore);

interface UserAutoLogoutModalProps {
    show: boolean;
    closeUserPermissionChangePopup: () => void;
    logoutCause: LogoutCause;
}

export const UserAutoLogoutModal: React.FC<UserAutoLogoutModalProps> = props => {

    const { show, closeUserPermissionChangePopup } = props;

    React.useEffect(() => {
        if (props.show && props.logoutCause != LogoutCause.None) {
            resetUserCache();
            const d = new Date();
            d.setTime(d.getTime() + (4 * 60 * 1000));
            const expires = "expires=" + d.toUTCString();
            document.cookie = "UserPermissionChanged=true;" + expires + ";path=/";
        }
        else { logOutUserOnReloadIfPermissionsChanged() }
    }, [props.show, props.logoutCause]);

    const logOutUserOnReloadIfPermissionsChanged = () => {
        if (CheckIfUserPermissionsChanged()) {
            closeUserPermissionChangePopup();
            clearAllTemporaryFilter();
            SessionStore.remove("isVisited");
            store.dispatch(resetUserPrivilegeChangeState());
            document.cookie = "UserPermissionChanged=false;"
            signoutRedirect();
        }
    }

    const CheckIfUserPermissionsChanged = (): boolean => {
        const name = "UserPermissionChanged=";
        const ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length) == 'true';
            }
        }
        return false;
    }

    const onCloseModal = (): void => {
        closeUserPermissionChangePopup();
        clearAllTemporaryFilter();
        SessionStore.remove("isVisited");
        store.dispatch(resetUserPrivilegeChangeState());
        document.cookie = "UserPermissionChanged=false;"
        resetUserCache(() => {
            signoutRedirect();
        });
    }

    return (
        <div className="margin-tbl-20">
            <Modal show={show} className="user-auto-logout-modal">
                <Modal.Header>
                    <div><Modal.Title>{UserAutoLogoutPopupConstants.Header}</Modal.Title></div>
                </Modal.Header>
                <Modal.Body>
                    <Container className='popup_grid_container'>
                        {UserAutoLogoutPopupConstants.Message}
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        className="btn-primary"
                        variant='default'
                        onClick={onCloseModal}>
                        Login
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};


