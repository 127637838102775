import { addTask } from 'domain-task';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../../';
import { RecycleTaxReturns } from '../../../components/common/RecycleTaxReturns';
import { actionTypes } from '../../../types/ActionTypes';
import {
    ReceiveRecycleReturnsAction,
    RequestRecycleReturnsAction,
    ReceiveRecycleReturnsPagesAction,
    RequestRecycleReturnsPagesAction
} from "./../KnownTypes";
import { RecycleReturnsState, RecycleReturnsTableModel } from './RecycleReturnsState';
import { StatusType, NotificationAction } from '../../common/NotificationStore';
import { handleResponse } from '../../Library';
import { RecyleReturnsConstants, RestoreReturnConstants } from '../../../components/helper/Constants';
import { API_BASE_URL } from '../../../utils/constants';
import { HideLoader } from '../../../components/helper/Loader';
import { ITaxDocumentViewModel } from '@components/common/TaxReturn';

type KnownAction = RequestRecycleReturnsAction |
    ReceiveRecycleReturnsAction |
    RequestRecycleReturnsPagesAction |
    ReceiveRecycleReturnsPagesAction;

type AllKnownAction =
    RequestRecycleReturnsAction |
    ReceiveRecycleReturnsAction |
    RequestRecycleReturnsPagesAction |
    ReceiveRecycleReturnsPagesAction |
    NotificationAction;


export const actionCreators = {

    requestRecycleReturns: (query: string, reload: boolean = false, callback?: () => void): AppThunkAction<AllKnownAction> => (dispatch, getState) => {
        let state = getState();
        if (reload || query !== state.recycleReturns.query) {

            let page = state.recycleReturnsPages[query];
            if (!reload && page) {

                dispatch({ type: actionTypes.REQUEST_RECYCLE_RETURNS, query: query });

                dispatch({
                    type: actionTypes.RECEIVE_RECYCLE_RETURNS,
                    query: query,
                    table: page.recycleReturnTableModel                   
                });

                return;
            }

            const fetchTask = fetch(API_BASE_URL + 'api/Reports/RecycleDocuments' + query, {
                method: 'GET',
                credentials: 'include'
            })
                .then(response => response.json() as Promise<RecycleReturnsTableModel>)
                .then(data => {
                    dispatch({ type: actionTypes.RECEIVE_RECYCLE_RETURNS, query: query, table: data });
                    dispatch({
                        type: actionTypes.RECEIVE_RECYCLE_RETURNS_PAGES,
                        query: query,
                        table: data.documents,
                        totalRowCount: data.count
                    });
                    if (callback) {
                        callback();
                    }
                })
                .catch(function (error) {
                    if (callback) {
                        callback();
                    }
                });
            addTask(fetchTask);
            dispatch({ type: actionTypes.REQUEST_RECYCLE_RETURNS, query: query });
            dispatch({ type: actionTypes.REQUEST_RECYCLE_RETURNS_PAGES, query: query });
        }
    },


    deleteDocuments: (documentid: ITaxDocumentViewModel[], resourceId: string, callback?: () => void): AppThunkAction<AllKnownAction> => (dispatch, getState) => {

        const fetchTask = fetch(API_BASE_URL + 'api/tax-return-action-handler/', {
            method: 'DELETE',
            credentials: 'include',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'X-Resource-Id':resourceId,
                'Content-Type': 'application/json; charset=utf-8',
                'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
            },
            body: JSON.stringify(documentid)
           })
            .then(handleResponse)
            .then(() => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: RecyleReturnsConstants.SuccessMessages.Delete,
                    statusType: StatusType.Success
                });

                if (callback) {
                    callback();
                }
            })
            .catch((error) => {
                const statusMessage: any = error.statusText?.message ?? error.statusText;
                if (typeof(statusMessage) === "string") {
                    dispatch({ type: actionTypes.NOTIFICATION, statusMessage: statusMessage, statusType: StatusType.Error });
                }
            });
        addTask(fetchTask);
    },

    restoreDocuments: (documentids: number[], resourceId:string, callback?: (deletionPendingDocuments: number[]) => void): AppThunkAction<AllKnownAction> => (dispatch, getState) => {
        let fetchTask = fetch(API_BASE_URL + 'api/Reports/RecycleDocuments/RestoreReturn', {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Accept': 'application/json, text/plain,*/*',
                'X-Resource-Id': resourceId,
                'Content-Type': 'application/json',
                'traditional': 'true',
                'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
            },
            body: JSON.stringify(documentids)
        }).then(handleResponse)
            .then((deletionPendingDocuments) => {
                if (callback) {
                    callback(deletionPendingDocuments);
                }
                else {
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: RestoreReturnConstants.SuccessMessages.Restore,
                        statusType: StatusType.Success
                    });
                }
            })
            .catch((error) => {
                HideLoader();
                const statusMessage: any = error.statusText?.message ?? error.statusText;
                if (typeof(statusMessage) === "string") {
                    dispatch({ type: actionTypes.NOTIFICATION, statusMessage: statusMessage, statusType: StatusType.Error });
                }
            });
        addTask(fetchTask);
    }
};


const unloadedState: RecycleReturnsState = {
    recycleReturnTableModel: {
        documents: [],
        count: 0
    } as RecycleReturnsTableModel,
    loading: true,
    query: '',
    totalRowCount: 0
} as RecycleReturnsState;

export const reducer: Reducer<RecycleReturnsState> = (state = unloadedState, incomingAction) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case actionTypes.REQUEST_RECYCLE_RETURNS:
            return ({
                ...unloadedState,
                query: action.query,
                loading: true
            }) as RecycleReturnsState;
        case actionTypes.RECEIVE_RECYCLE_RETURNS:
            return {
                query: action.query,
                recycleReturnTableModel: action.table,
                totalRowCount: action.table.count,
                loading: false
            } as RecycleReturnsState;
    }

    return state;
};

