import { formatEIN, formatSSN } from "../../../components/helper/HelperFunctions";
import {
    CMClientType,
    IClientInfoViewModel,
    ITaxReturn,
    initialClientInfoViewModel,
    isIndividual,
    isMutual,
    isPartnership
} from "../TaxReturn";

export enum IClientInfoMismatchType {
    NoDataMismatch = 0,
    DataMismatch = 1,
    SSNMismatch = 2,
    ClientIdMismatch = 3,
    NewClient = 4
}

export const mismatchModalTitle = {
    reviewAndConfirm: "Review and Confirm Client Information",
    newClient: "Add New Client Information"
};

export const mismatchModalHeader = {
    noDataMismatch: "No Data Mismatch",
    dataMismatch:
        "You have changed some information that differs from Client Management. <br>Would you like us to update Client Management with this new data?",
    ssnMismatch:
        "We noticed the <strong>Social Security Number (SSN)</strong> from the Tax Return differs from the Client Management Record. You can update the Client Management record or cancel and adjust the Tax Return.",
    einMismatch:
        "We noticed the <strong>Employee Identification Number (EIN)</strong> from the Tax Return differs from the Client Management Record. You can update the Client Management record or cancel and adjust the Tax Return.",
    clientIdWithSameSSNMismatch:
        "We have detected another Client with a different <strong>Client ID</strong> but the same Social Security Number (SSN). You can update the Client Management record or cancel and adjust the <br>Tax Return.",
    clientIdWithSameEINMismatch:
        "We have detected another Client with a different <strong>Client ID</strong> but the same Employee Identification Number (EIN). You can update the Client Management record or cancel and adjust the Tax Return.",
    newClient: "Do you wish to add this data into your Client Management records?"
};

export interface IComparisonMismatchViewModel {
    hadDifference: boolean;
    taxReturnValue?: string;
    clientManagementValue?: string;
}

export interface IClientInfoMismatchViewModel {
    clientId: IComparisonMismatchViewModel;
    name: IComparisonMismatchViewModel;
    uid?: IComparisonMismatchViewModel;
    emailAddress?: IComparisonMismatchViewModel;
    countryCode?: IComparisonMismatchViewModel;
    mobileNumber?: IComparisonMismatchViewModel;
    ero?: IComparisonMismatchViewModel;
    isDeceased?: IComparisonMismatchViewModel;
    dob?: IComparisonMismatchViewModel;
    spouseSSN?: IComparisonMismatchViewModel;
    spouseName?: IComparisonMismatchViewModel;
    spouseEmailAddress?: IComparisonMismatchViewModel;
    spouseCountryCode?: IComparisonMismatchViewModel;
    spouseMobileNumber?: IComparisonMismatchViewModel;
    spouseDOB?: IComparisonMismatchViewModel;
    spouseIsDeceased?: IComparisonMismatchViewModel;
    locationName?: IComparisonMismatchViewModel;
}

export interface IClientInfoComparisonResult {
    comparisonStatus: IClientInfoMismatchType;
    clientInfoResultData: IClientInfoMismatchViewModel;
}

export const initialClientInfoComparisonResult: IClientInfoComparisonResult = {
    comparisonStatus: 0,
    clientInfoResultData: {
        clientId: {
            hadDifference: false
        },
        name: {
            hadDifference: false
        }
    }
};

export const columnNames = {
    clientInformation: "Client Information",
    taxReturn: "Tax Return",
    clientManagement: "Client Management"
};

export const clientRows = {
    clientId: "Client ID",
    name: "Name",
    locationName: "Office",
    emailAddress: "Email Address",
    dob: "DOB",
    countryCode: "Country Code",
    mobileNumber: "Mobile Number",
    isDeceased: "Deceased",
    uid: "SSN",
    ero: "ERO",
    spouseName: "Spouse Name",
    spouseEmailAddress: "Spouse Email Address",
    spouseSSN: "Spouse SSN",
    spouseDOB: "Spouse DOB",
    spouseCountryCode: "Spouse Country Code",
    spouseMobileNumber: "Spouse Mobile Number",
    spouseIsDeceased: "Spouse Deceased"
};

export const getNewClientInfo = (taxReturn: ITaxReturn, clientBasicInfoId: number, clientInfoFromCM: IClientInfoViewModel,
    locationName?:string) => {
    let clientInfo: IClientInfoViewModel;

    if (isPartnership(taxReturn)) {
        const partnership = taxReturn.partnership;
        clientInfo = {
            clientBasicInfoId: clientBasicInfoId ?? 0,
            clientId: taxReturn.clientId,
            name: partnership.name,
            emailAddress: partnership.email,
            countryCode: partnership.countryCode,
            mobileNumber: partnership.mobileNumber,
            type: CMClientType.EIN,
            uid: formatEIN(partnership.ssn),
            ero: `${taxReturn.partnerId}`,
            dob: null,
            isDeceased: false,
            spouseName: "",
            spouseSsn: "",
            spouseEmailAddress: "",
            spouseDob: null,
            spouseCountryCode: "",
            spouseMobileNumber: "",
            spouseIsDeceased: false,
            mfj: false,
            defaultFirstSigner: false,
            totalCount: 0,
            eroFirstName: "",
            eroLastName: "",  
            locationName:locationName?locationName:"",
            locationId :taxReturn.locationId        
        };
    } else if (isIndividual(taxReturn)) {
        const taxpayer = taxReturn.taxpayer;
        clientInfo = {
            clientBasicInfoId: clientBasicInfoId ?? 0,
            clientId: taxReturn.clientId,
            name: taxpayer.name,
            emailAddress: taxpayer.email,
            countryCode: taxpayer.countryCode,
            mobileNumber: taxpayer.mobileNumber,
            type: CMClientType.SSN,
            uid: formatSSN(taxpayer.ssn),
            ero: `${taxReturn.partnerId}`,
            dob: taxpayer.dob,
            isDeceased: taxpayer.isDeceased,
            spouseName: "",
            spouseSsn: "",
            spouseEmailAddress: "",
            spouseDob: null,
            spouseCountryCode: "",
            spouseMobileNumber: "",
            spouseIsDeceased: false,
            mfj: false,
            defaultFirstSigner: false,
            totalCount: 0,
            eroFirstName: "",
            eroLastName: "",
            locationName:locationName?locationName:"",
            locationId :taxReturn.locationId  
        };
    } else if (isMutual(taxReturn)) {
        const taxpayer = taxReturn.taxpayer;
        const spouse = taxReturn.spouse;
        clientInfo = {
            clientBasicInfoId: clientBasicInfoId ?? 0,
            clientId: taxReturn.clientId,
            name: taxpayer.name,
            emailAddress: taxpayer.email,
            countryCode: taxpayer.countryCode,
            mobileNumber: taxpayer.mobileNumber,
            type: CMClientType.SSN,
            uid: formatSSN(taxpayer.ssn),
            ero: `${taxReturn.partnerId}`,
            dob: taxpayer.dob,
            isDeceased: taxpayer.isDeceased,
            spouseName: spouse.name,
            spouseSsn: formatSSN(spouse.ssn),
            spouseEmailAddress: spouse.email,
            spouseDob: spouse.dob,
            spouseCountryCode: spouse.countryCode,
            spouseMobileNumber: spouse.mobileNumber,
            spouseIsDeceased: spouse.isDeceased,
            mfj: true,
            defaultFirstSigner: clientInfoFromCM.defaultFirstSigner,
            totalCount: 0,
            eroFirstName: "",
            eroLastName: "",
            locationName:locationName?locationName:"",
            locationId :taxReturn.locationId  
        };
    } else {
        clientInfo = initialClientInfoViewModel;
    }
    return clientInfo;
};

export const setNewClientInfoComparisonResult = (clientInfo: IClientInfoViewModel) => {
    const data: IClientInfoComparisonResult = {
        comparisonStatus: IClientInfoMismatchType.NewClient,
        clientInfoResultData: {
            clientId: {
                hadDifference: clientInfo.clientId ? true : false,
                taxReturnValue: clientInfo.clientId
            },
            name: {
                hadDifference: clientInfo.name ? true : false,
                taxReturnValue: clientInfo.name
            },
            uid: {
                hadDifference: clientInfo.uid ? true : false,
                taxReturnValue: clientInfo.uid
            },
            emailAddress: {
                hadDifference: clientInfo.emailAddress ? true : false,
                taxReturnValue: clientInfo.emailAddress
            },
            countryCode: {
                hadDifference: clientInfo.countryCode ? true : false,
                taxReturnValue: clientInfo.countryCode
            },
            mobileNumber: {
                hadDifference: clientInfo.mobileNumber ? true : false,
                taxReturnValue: clientInfo.mobileNumber
            },
            ero: {
                hadDifference: clientInfo.ero ? true : false,
                taxReturnValue: clientInfo.ero
            },
            isDeceased: {
                hadDifference: clientInfo.isDeceased ? true : false,
                taxReturnValue: `${clientInfo.isDeceased}`
            },
            dob: {
                hadDifference: clientInfo.dob ? true : false,
                taxReturnValue: `${clientInfo.dob}`
            },
            spouseSSN: {
                hadDifference: clientInfo.spouseSsn ? true : false,
                taxReturnValue: clientInfo.spouseSsn
            },
            spouseName: {
                hadDifference: clientInfo.spouseName ? true : false,
                taxReturnValue: clientInfo.spouseName
            },
            spouseEmailAddress: {
                hadDifference: clientInfo.spouseEmailAddress ? true : false,
                taxReturnValue: clientInfo.spouseEmailAddress
            },
            spouseCountryCode: {
                hadDifference: clientInfo.spouseCountryCode ? true : false,
                taxReturnValue: clientInfo.spouseCountryCode
            },
            spouseMobileNumber: {
                hadDifference: clientInfo.spouseMobileNumber ? true : false,
                taxReturnValue: clientInfo.spouseMobileNumber
            },
            spouseDOB: {
                hadDifference: clientInfo.spouseDob ? true : false,
                taxReturnValue: `${clientInfo.spouseDob}`
            },
            spouseIsDeceased: {
                hadDifference: clientInfo.spouseIsDeceased ? true : false,
                taxReturnValue: `${clientInfo.spouseIsDeceased}`
            },
            locationName: {
                hadDifference: clientInfo.locationName ? true : false,
                taxReturnValue: clientInfo.locationName
            }
        }
    };
    return data;
};

export const defaultOrder = [
    "clientId",
    "name",
    "locationName",
    "emailAddress",
    "countryCode",
    "mobileNumber",
    "dob",
    "uid",
    "ero",
    "isDeceased",
    "spouseName",
    "spouseEmailAddress",
    "spouseCountryCode",
    "spouseMobileNumber",
    "spouseDOB",
    "spouseSSN",
    "spouseIsDeceased"
];

export const customOrder = [
    "clientId",
    "name",
    "locationName",
    "uid",
    "emailAddress",
    "countryCode",
    "mobileNumber",
    "dob",
    "ero",
    "isDeceased",
    "spouseName",
    "spouseEmailAddress",
    "spouseCountryCode",
    "spouseMobileNumber",
    "spouseDOB",
    "spouseSSN",
    "spouseIsDeceased"
];

export const sortRows = (rows: string[], mismatchType: IClientInfoMismatchType) => {
    const { ClientIdMismatch, SSNMismatch } = IClientInfoMismatchType;
    const order = [ClientIdMismatch, SSNMismatch].includes(mismatchType) ? customOrder : defaultOrder;
    const orderedArr = order.filter((x) => rows.includes(x));
    return orderedArr;
};
