import * as React from 'react';
import { OverlayTrigger, Card, Popover, Accordion } from 'react-bootstrap';
import { IVoucherState } from '../../../../store/ProcessReturn/voucher/VoucherStore';
import { CustomType, DocumentGroups, getAllStateImages, getVoucherTypes, IFormBase, ITaxingAuthority, ITaxReturn, IVoucher, StateUSA, VoucherNo, VoucherMode, VoucherTypes, TaxFormType } from '../../../common/TaxReturn';
import { VoucherTabConstants } from '../../../helper/Constants';
import { formatCurency } from '.././../../helper/HelperFunctions';
import { AddMultiplePayment } from '../../../common/ProcessReturnModal/LeftSidebar/AddMultiplePayment';
import { VoucherPayment, VoucherEstimatedPayment } from '../../../svg/IconCollection';
import { VoucherSort } from "../Voucher/VoucherSort";
import { IPageItem } from "../ProcessReturnModels";
import { DueDateType } from "../../../../Core/ViewModels/Company/CompanySettingsViewModel";
import { logger } from '../../../../routes/LoggedIn';


export interface IAddedVoucherProps {
    taxReturn: ITaxReturn;
    onAddVoucher: (voucher: IVoucher) => void;
    populateVoucherCard: (voucher: IVoucher) => void;
    voucherStore: IVoucherState;
    getAllTaxingAuthorities: ITaxingAuthority[];
    onVoucherDelete: (voucher: IVoucher) => void;
    addedVoucherList: IVoucher[];
    onAddMultipeVouchers?: (vouchers: IVoucher[]) => void;
    isRevisionProcess?: boolean;
}
export interface IAddedVoucherState {
    voucher: IVoucher;
    showAddVoucherView: boolean;
    popoverTarget: any;
    showAlert: boolean;
    message: string;
    expandAddedVoucherListCard: boolean;
    enableLeftCardScroll: boolean;
}

const initialVoucher: Readonly<IVoucher> = {
    authorityID: 0,
    dueDate: undefined,
    amount: 0,
    voucherNo: VoucherNo.None,
    paymentType: VoucherTypes.None,
    customType: CustomType.None,
    onlinePaymentUri: "",
    customText: "",
    authorityImage: "",
    authorityName: "",
    groupId: undefined,
    formName: "",
    bookmark: "",
    pageNo: [],
    isMasterPaymentUrl: false,
    vocherMode: VoucherMode.None,
	isFormNameUpdated: false,
    formType: TaxFormType.Voucher,
    voucherDueDateType: DueDateType.SSRDefault,
    printedDueDate: new Date()
}

export class AddedVoucherList extends React.Component<IAddedVoucherProps, IAddedVoucherState>
{
    state: IAddedVoucherState = {
        voucher: initialVoucher,
        showAddVoucherView: false,
        popoverTarget: {},
        showAlert: false,
        message: '',
        expandAddedVoucherListCard: true,
        enableLeftCardScroll: true
    };

    public closeDeleteConfirmPopHover = () => {
        document.body.click();
    }

    getTitle(authorities: ITaxingAuthority[], authorityId: number, amount: number, formName: string) {
        let authority: ITaxingAuthority = authorities[authorities.findIndex(x => x.Id == authorityId)];
       
        if (authority && formName) {
            return [
                <div title={authority.AuthorityName + " " + formName} className="bookmark-name wordbreak ellipsis">{authority.AuthorityName} {formName}</div>,
                <div className="bookmark-right client-info-payments" title={'$' + formatCurency(Number(amount))}
                    style={{
                        fontFamily: "'Lucida Console', 'Monaco', 'monospace'",
                        color: '#ff0000'
                    }}>
                    {'($' + formatCurency(Number(amount)) + ')'}
                </div>
            ]
        } else {
            return null;
        }
    }

    private getVoucherClass = (voucher: IVoucher) => {
        let voucherClass: string = "payment-voucher";
        if (getVoucherTypes(voucher.voucherNo) ===
            VoucherTypes.EstimatedVoucher) {
            voucherClass = "estimated-voucher";
        }
        return voucherClass;
    }

    onDeleteVoucher = () => {
        this.props.onVoucherDelete(this.state.voucher);
        document.body.click();
        logger.trackTrace(`TabTransmittals --> onDeleteVoucher executes`, { 'DocumentId': this.props.taxReturn.id?.toString(), 'DocumentGuid': this.props.taxReturn.documentGuid })
    }


    enableScroll = () => {
        if (!this.state.enableLeftCardScroll) {
            const scrollDiv = (document.querySelector(".scroll div div") as HTMLElement);
            scrollDiv.style.overflow = "scroll";
            scrollDiv.style.marginRight = "-17px";
            ((document.querySelector(".scroll div") as HTMLElement).children[2] as HTMLElement).style.display = "block";
            this.setState({ enableLeftCardScroll: true });
        }

    }

    stopScroll = () => {
        if (this.state.enableLeftCardScroll) {
            const scrollDiv = (document.querySelector(".scroll div div") as HTMLElement);
            const top: number = scrollDiv.scrollTop;
            scrollDiv.style.overflow = "hidden";
            scrollDiv.style.marginRight = "0px";
            scrollDiv.scrollTop = top;
            ((document.querySelector(".scroll div") as HTMLElement).children[2] as HTMLElement).style.display = "none";
            this.setState({ enableLeftCardScroll: false });
        }

    }

    private getVoucherByPageNo = (vouchers: IVoucher[], pageNo: number) => {
        let voucher = {} as IVoucher;
        if (vouchers && vouchers.length > 0) {
            vouchers.map((value, index) => {
                if (value.pageNo[0] && value.pageNo[0] === pageNo) {
                    voucher = value;
                    return;
                }
            })
        }
        return voucher;
    }

    private getCustomVoucherMode = () => {
		let voucherModes:VoucherMode[]=[];
		voucherModes.push(VoucherMode.CustomVoucher);
		return voucherModes;
	}

    public render() {
        let authorities: ITaxingAuthority[] = this.props.getAllTaxingAuthorities;
        let voucherSort = new VoucherSort(this.props.taxReturn, authorities, this.getCustomVoucherMode());
        let bookmarks = voucherSort.getSortedBookmarks(this.props.isRevisionProcess);
        let addedVouchers = voucherSort.getVouchers();

        const deletePopover = (<Popover id="popover-voucher-delete-confirm">
            <Popover.Title as="h3">{VoucherTabConstants.AddedVoucher.DeleteConfirm}</Popover.Title>
            <Popover.Content>
                <button
                className="btn btn-only btn-success"
                onClick={this.onDeleteVoucher}
                title="Yes"
                style={{ marginLeft: "5px" }}
                data-test-auto="8198C695-5D0C-47CD-9DC3-5A44235743EF">
                Yes
                </button>
                <button
                onClick={this.closeDeleteConfirmPopHover}
                title="No"
                className="btn btn-only btn-danger"
                style={{ marginLeft: "5px" }}
                data-test-auto="6A358AC4-7DDB-4DA2-819B-182E41B7F7CF">
                    No
                </button>
                </Popover.Content>
        </Popover>
        )

        return <div className="bookmarksPane">
            <Accordion
                activeKey={this.state.expandAddedVoucherListCard ? '0' : ''}
                onSelect={() => this.setState({ expandAddedVoucherListCard: !this.state.expandAddedVoucherListCard })}
            >
            <Card>                
                    <Card.Header style={{ padding: '7px' }}>
                        <Card.Title className="float-left" as="h3" style={{ marginBottom: '0px' }}>
                            <Accordion.Toggle as="h3" eventKey="0">
                                Manually Added Vouchers
                            </Accordion.Toggle>
                    </Card.Title>
                    <span className="float-right">
                        <div>
                            <AddMultiplePayment
                                onAddVoucher={this.props.onAddVoucher}
                                taxReturn={this.props.taxReturn}
                                voucherStore={this.props.voucherStore}
                                populateVoucherCard={this.props.populateVoucherCard}
                                getAllTaxingAuthorities={this.props.getAllTaxingAuthorities}
                                onAddMultipeVouchers={this.props.onAddMultipeVouchers}
                            />
                        </div>
                    </span>
                    <div className="clearfix"></div>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                    <Card.Body style={{ padding: '0px' }}>
                        <div className="pageListView">
                            {
                                addedVouchers.length == 0 ? <div className="textalign-center">No Content</div> :

                                    (bookmarks && bookmarks.length == 1 && bookmarks[0].pages.map((value: IPageItem, index: number) => {
                                        let voucher = this.getVoucherByPageNo(addedVouchers, value.pageNo);
                                        return voucher && voucher.authorityID > 0 && (<div
                                            key={index}
                                            className="custom-list-group-item bookmark-item " >
                                            <div className="bookmark-row">
                                                <div style={{ width: "24px" }}>

                                                    {(this.getVoucherClass(voucher) == "estimated-voucher") ?
                                                        <VoucherEstimatedPayment className={"bookmark-icon-image"} /> :
                                                        <VoucherPayment className={"bookmark-icon-image"} />}
                                                </div>
                                                <div className="cursor-pointer" style={{ display: "inline-flex", width: "calc(100% - 46px)" }}
                                                    onClick={() => { this.props.populateVoucherCard(voucher); }}>
                                                    {
                                                        this.getTitle(authorities, voucher.authorityID, voucher.amount, voucher.formName)
                                                    }
                                                </div>

                                                <OverlayTrigger rootClose trigger="click" onEnter={() => document.body.click()} onEntered={() => { this.stopScroll() }} onExit={() => { this.enableScroll() }} placement="right" overlay={deletePopover}>
                                                    <div style={{ width: "24px" }}>
                                                        <i className="padL8 fas fa-times cursor-pointer text-danger"
                                                            onClick={() => { this.setState({ voucher: voucher }) }}
                                                            title={"Delete Voucher"}>
                                                        </i>
                                                    </div>
                                                </OverlayTrigger>
                                            </div>
                                        </div>)
                                    }))
                            }
                        </div>
                    </Card.Body>
                </Accordion.Collapse>
            </Card>
            </Accordion>
        </div>
    }
};
