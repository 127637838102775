import { Reducer } from 'redux';
import { AppThunkAction } from '../';
import { API_BASE_URL } from '../../utils/constants';
import { actionTypes } from '../../types/ActionTypes';
import { addTask } from 'domain-task';
import { EntityType, IK1SSNDetails } from '../../components/common/TaxReturn';
import { StatusType, NotificationAction } from '../common/NotificationStore';
import { forEach } from 'lodash';

export interface RequestK1SSNDetailsAction {
    type: actionTypes.REQUEST_K1_SSN_DETAILS;
    documentId: number;
}

export interface ReceiveK1SSNDetailsAction {
    type: actionTypes.RECEIVE_K1_SSN_DETAILS;
    documentId: number;
    ssnDetails: IK1SSNDetails[];
}

export interface UpdateK1SSNDetailsAction {
    type: actionTypes.UPDATE_K1_SSN_DETAILS;
    documentId: number;
}

export interface RequestPageWiseK1SSNDetailsAction {
    type: actionTypes.REQUEST_PAGEWISE_K1_SSN_DETAILS;
    documentId: number;
    pageNum: number;
}

export interface ReceivePageWiseK1SSNDetailsAction {
    type: actionTypes.RECEIVE_PAGEWISE_K1_SSN_DETAILS;
    documentId: number;
    pageNum: number;
    ssnDetails: IK1SSNDetails[];
}

export interface RemovePageWiseK1SSNDetailsAction {
    type: actionTypes.REMOVE_PAGEWISE_K1_SSN_DETAILS;
    documentId: number;
    pageNums: number[];
}

type DispatchAction = RequestK1SSNDetailsAction |
    ReceiveK1SSNDetailsAction |
    UpdateK1SSNDetailsAction |
    RequestPageWiseK1SSNDetailsAction |
    ReceivePageWiseK1SSNDetailsAction |
    RemovePageWiseK1SSNDetailsAction|
    NotificationAction;

type KnownAction = RequestK1SSNDetailsAction |
    ReceiveK1SSNDetailsAction |
    UpdateK1SSNDetailsAction;

type PageWiseSSNAction = RequestPageWiseK1SSNDetailsAction |
    ReceivePageWiseK1SSNDetailsAction |
    RemovePageWiseK1SSNDetailsAction;

export const actionCreators = {
    getK1SSNDetails: (documentId: number): AppThunkAction<DispatchAction> => (dispatch, getState) => {
        if (!getState().k1SSNDetails[documentId] || getState().k1SSNDetails[documentId].length == 0) {
            const fetchTask = fetch(API_BASE_URL + 'api/TaxDocument/GetK1ShareholderSSNPlacementAsync/' + documentId, { credentials: 'include' }).then((resp) => resp.json())
                .then(function (data) {

                    dispatch({ type: actionTypes.RECEIVE_K1_SSN_DETAILS, documentId: documentId, ssnDetails: data });

                }).catch(error => {
                    const statusMessage: any = error.statusText?.message ?? error.statusText;
                    if (typeof (statusMessage) === "string") {
                        dispatch({ type: actionTypes.NOTIFICATION, statusMessage: statusMessage, statusType: StatusType.Error });
                    }
                });
            addTask(fetchTask);
            dispatch({ type: actionTypes.REQUEST_K1_SSN_DETAILS, documentId: documentId });
        }
    },
    getK1SSNDetailsForPage: (documentId: number, ssn: string, pageNum: number, entityType: EntityType,
        callBack: (ssnPlacements: IK1SSNDetails[]) => void): AppThunkAction<DispatchAction> => (dispatch, getState) => {
        if (getState().pageWiseSSNDetails[documentId] == undefined || getState().pageWiseSSNDetails[documentId][pageNum] == undefined) {
            const fetchTask = fetch(API_BASE_URL + 'api/TaxDocument/GetK1ShareholderSSNPlacementForPageAsync/' + documentId + '/' + ssn + '/' + pageNum + '/' + entityType
                , { credentials: 'include' }).then((resp) => resp.json())
                .then(function (data: IK1SSNDetails[]) {
                    dispatch({ type: actionTypes.RECEIVE_PAGEWISE_K1_SSN_DETAILS, documentId: documentId, pageNum: pageNum, ssnDetails: data });
                    callBack(data);
                }).catch(error => {
                    const statusMessage: any = error.statusText?.message ?? error.statusText;
                    if (typeof (statusMessage) === "string") {
                        dispatch({ type: actionTypes.NOTIFICATION, statusMessage: statusMessage, statusType: StatusType.Error });
                    }
                });
            addTask(fetchTask);
            dispatch({ type: actionTypes.REQUEST_PAGEWISE_K1_SSN_DETAILS, documentId: documentId, pageNum: pageNum });
        }
    },
    resetK1SSNState: (documentId: number): AppThunkAction<DispatchAction> => (dispatch, getState) => {
        dispatch({ type: actionTypes.UPDATE_K1_SSN_DETAILS, documentId: documentId });
    },
    resetPageWiseK1SSNState: (documentId: number, pages: number[]): AppThunkAction<DispatchAction> => (dispatch, getState) => {
        dispatch({ type: actionTypes.REMOVE_PAGEWISE_K1_SSN_DETAILS, documentId: documentId, pageNums:pages });
    }

}

export interface IK1SSNDictionary {
    [index: number]: IK1SSNDetails[];
}

export interface IPageWiseK1SSNDictionary {
    [documentId: number]: {
        [index: number]: IK1SSNDetails[];
    }
}

export const unloadedState: IK1SSNDetails[] = [];

export const reducer: Reducer<IK1SSNDictionary> = (state = { 0: unloadedState }, incomingAction) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case actionTypes.REQUEST_K1_SSN_DETAILS:
            if (!state[action.documentId]) {
                state[action.documentId] = unloadedState;
                return {
                    ...state,
                };
            }
            break;
        case actionTypes.RECEIVE_K1_SSN_DETAILS:
            if (state[action.documentId]) {
                state[action.documentId] = action.ssnDetails;
                return {
                    ...state,
                };
            }
            break;

        case actionTypes.UPDATE_K1_SSN_DETAILS:
            if (state[action.documentId]) {
                state[action.documentId] = [];
                return {
                    ...state,
                };
            }
            break;
    }
    return state;
}


export const pageWiseSSNReducer: Reducer<IPageWiseK1SSNDictionary> = (state = [], incomingAction) => {
    const action = incomingAction as PageWiseSSNAction;
    switch (action.type) {
        case actionTypes.REQUEST_PAGEWISE_K1_SSN_DETAILS:
            if (!state[action.documentId]) {
                state[action.documentId] = [];
                return {
                    ...state,
                };
            }
            break;
        case actionTypes.RECEIVE_PAGEWISE_K1_SSN_DETAILS:
            if (state[action.documentId]) {
                if (!state[action.documentId][action.pageNum]) {
                    state[action.documentId][action.pageNum] = action.ssnDetails;
                    return {
                        ...state,
                    };
                }
            }
            break;

        case actionTypes.REMOVE_PAGEWISE_K1_SSN_DETAILS:
            if (state[action.documentId]) {
                const updatedPages = { ...state[action.documentId] };

                action.pageNums.forEach((page) => {
                    if (updatedPages[page]) {
                        delete updatedPages[page];
                    }
                });

                return {
                    ...state,
                    [action.documentId]: updatedPages,
                };
            }
            break;
    }
    return state;
}
