import * as React from 'react';
import { Modal, Alert, ListGroup, Form, Button } from 'react-bootstrap';
import { LoadingOverlay, Loader } from 'react-overlay-loader';
import { FaFileDownload } from 'react-icons/fa';
import { IK1Attachment } from '../K1Attachments/IK1Attachment';
import { GetSafeFilename } from '../../../helper/Validations';

interface DownloadK1AttachmentsProps {
    show: boolean;
    onCancel(): void;
    k1Attachments: IK1Attachment[];
    onDownloadIndividualK1Attachment(documentId: number, fileName: string, k1AttachmentId: number): void;
    onDownloadAll(): void;
}

export const DownloadK1Attachments: React.FC<DownloadK1AttachmentsProps> = props => {
    const { k1Attachments } = props;

    return (
        <Modal className="download-attachments-modal" show={props.show} onHide={props.onCancel}>
            <Modal.Header closeButton onClick={props.onCancel} data-test-auto="21A89508-8B69-4DBE-90C7-9BFF099BF22A">
                <Modal.Title className='custom-modal-header'>
                    <FaFileDownload className="modalIcon" />
                    Download K1 Attachments
                </Modal.Title>
            </Modal.Header>
            <Form>
                <Modal.Body>
                    <LoadingOverlay style={{ minHeight: 100 }}>
                        {k1Attachments.length > 0 ? (
                            <div>
                                <Alert variant="info">
                                    <i className="fa fa-info-circle" style={{ marginRight: '5px' }}></i>Click Attachment
                                    to download
                                </Alert>
                                <ListGroup data-test-auto="CED45DD7-A1CE-49A3-A273-15784D059456">
                                    {k1Attachments.map((value, i) => {
                                        return (
                                            <li
                                                className="delivered-return-download-link"
                                                onClick={() => {
                                                    props.onDownloadIndividualK1Attachment(
                                                        value.documentId,
                                                        value.fileName,
                                                        value.id
                                                    );
                                                }}
                                            >
                                                {GetSafeFilename(value.fileName)}
                                            </li>
                                        );
                                    })}
                                </ListGroup>
                            </div>
                        ) : (
                            k1Attachments !== undefined && (
                                <Alert variant="warning">
                                    <i
                                        className="fas fa-exclamation-triangle"
                                        style={{ marginRight: '5px', color: '#666' }}
                                    ></i>
                                    No returns found!
                                </Alert>
                            )
                        )}
                        <Loader loading={k1Attachments === undefined} />
                    </LoadingOverlay>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        onClick={props.onCancel}
                        variant="default"
                        className="btn btn-white"
                        data-test-auto="E588CADB-D99C-4D1F-8746-12818897B5EE"
                    >
                        <i className="fas fa-times"></i>
                        Cancel
                    </Button>
                    <Button
                        onClick={() => {
                            props.onDownloadAll();
                        }}
                        variant="info"
                        data-test-auto="1A2129B7-CDA1-4EFC-848B-C21A40104F01"
                    >
                        <i className="fas fa-download"></i>
                        Download All
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};
