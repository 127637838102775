import { addTask } from 'domain-task';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../';

import { actionTypes } from '../../types/ActionTypes';
import { StatusType, NotificationAction } from '../common/NotificationStore';
import { GetMyDownloadsList } from '../company/CompanyStore';
import { handleResponse } from '../Library';
import { DeliveredReturnsConstants, RestoreReturnConstants } from '../../components/helper/Constants';
import { BulkOperationQuery, RecycleBulkOperationQuery, QuickReportBulkOperationQuery } from '../../Core/ViewModels/Common/BulkOperationQuery';
import { IColumnValues } from '../../components/settings/GeneralSettings';
import { IDownloadedZipFilesModel } from '../../components/navigation/profile/MyDownload';
import { DefaultDownloadInfoType } from '../../components/navigation/profile/MySettings';
import * as Helper from '../../components/helper/HelperFunctions';
import { API_BASE_URL } from '../../utils/constants';
import { logger } from '../../routes/LoggedIn';

type AllKnownAction =
    GetMyDownloadsList |
    NotificationAction;

export const actionCreators = {

    sendRemindersBulk: (query: BulkOperationQuery, callback?: () => void, resourceId: string = ""): AppThunkAction<AllKnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(API_BASE_URL + 'api/BulkOperations/SendReminderAsync', {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(query),
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8',
                'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value,
                'X-Resource-Id': resourceId
            },
        })
            .then(handleResponse)
            .then(() => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: DeliveredReturnsConstants.BulkOperationMessage.SendReminderWaitProcessing,
                    statusType: StatusType.Success
                });
                if (callback) {
                    callback();
                }
            })
            .catch((error) => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: DeliveredReturnsConstants.BulkOperationMessage.SendReminderError,
                    statusType: StatusType.Error
                });
                if (callback) {
                    callback();
                }
            });
        addTask(fetchTask);
    },

    customcolumnUpdateBulk: (query: BulkOperationQuery, customColumn: IColumnValues, apiEndPoint: string, callback?: () => void, resourceId: string = ""): AppThunkAction<AllKnownAction> => (dispatch, getState) => {

        let endPoint: string = API_BASE_URL + 'api/BulkOperations/' + apiEndPoint;
        const fetchTask = fetch(endPoint, {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify({
                query: query,
                columnValue: customColumn
            }),
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8',
                'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value,
                'X-Resource-Id': resourceId
            },
        })
            .then(handleResponse)
            .then(() => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: DeliveredReturnsConstants.BulkOperationMessage.Processing,
                    statusType: StatusType.Success
                });
                if (callback) {
                    callback();
                }
            })
            .catch((error) => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: DeliveredReturnsConstants.BulkOperationMessage.CustomColumnError,
                    statusType: StatusType.Error
                });
                if (callback) {
                    callback();
                }
            });
        addTask(fetchTask);
    },

    archiveDocumentsBulk: (query: BulkOperationQuery, callback?: () => void, resourceId: string = ""): AppThunkAction<AllKnownAction> => (dispatch, getState) => {

        const fetchTask = fetch(API_BASE_URL + 'api/BulkOperations/ArchiveDocumentsAsync', {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(query),
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8',
                'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value,
                'X-Resource-Id': resourceId
            },
        })
            .then(handleResponse)
            .then(() => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: DeliveredReturnsConstants.BulkOperationMessage.Processing,
                    statusType: StatusType.Success
                });
                if (callback) {
                    callback();
                }
            })
            .catch((error) => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: DeliveredReturnsConstants.BulkOperationMessage.ArchiveError,
                    statusType: StatusType.Error
                });
                if (callback) {
                    callback();
                }
            });
        addTask(fetchTask);

    },

    deleteDocumentsBulk: (query: BulkOperationQuery, callback?: () => void,
        resourceId: string = ""): AppThunkAction<AllKnownAction> => (dispatch, getState) => {

            let fetchTask = fetch(API_BASE_URL + 'api/BulkOperations/DeleteDocumentsAsync', {
                method: 'DELETE',
                credentials: 'include',
                body: JSON.stringify(query),
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json; charset=utf-8',
                    'X-Resource-Id': resourceId,
                    'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
                },
            })
                .then(handleResponse)
                .then(() => {
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: DeliveredReturnsConstants.BulkOperationMessage.Processing,
                        statusType: StatusType.Success
                    });
                    if (callback) {
                        callback();
                    }
                })
                .catch((error) => {
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: DeliveredReturnsConstants.BulkOperationMessage.DeleteReturnsError,
                        statusType: StatusType.Error
                    });
                    if (callback) {
                        callback();
                    }
                });
            addTask(fetchTask);

        },

    bulkReturnRestore: (query: RecycleBulkOperationQuery, callback?: () => void): AppThunkAction<AllKnownAction> => (dispatch, getState) => {
        let fetchTask = fetch(API_BASE_URL + 'api/BulkOperations/BulkRestoreDocumentsAsync', {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(query),
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8',
                'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
            },
        })
            .then(handleResponse)
            .then(() => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: RestoreReturnConstants.BulkOperationMessage.Processing,
                    statusType: StatusType.Success
                });
                if (callback) {
                    callback();
                }
            })
            .catch((error) => {
                if (error.statusText && error.statusText.trim() == "One or more return(s) expired") {
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: RestoreReturnConstants.ReturnExpired.Restore,
                        statusType: StatusType.Warning
                    });
                    if (callback) {
                        callback();
                    }
                }
                else {
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: RestoreReturnConstants.BulkOperationMessage.Error,
                        statusType: StatusType.Error
                    });
                    if (callback) {
                        callback();
                    }
                }
            });
        addTask(fetchTask);

    },

    downloadEFileFormsBulk: (documentIds: number[], isBulkSelectionEnabled: boolean, query: BulkOperationQuery, selectedDownloadType: DefaultDownloadInfoType,
        callback?: () => void, resourceId: string=""): AppThunkAction<AllKnownAction> => (dispatch, getState) => {
            let state = getState();
            const d = new Date();
            const todayDateTime = d.getDate().toString().padStart(2, "0") + '-' + (d.getMonth() + 1).toString().padStart(2, "0") + '-' + d.getFullYear() + ' ' + d.getHours().toString().padStart(2, "0") + ':' + d.getMinutes().toString().padStart(2, "0");
            let apiEndPoint = isBulkSelectionEnabled ? API_BASE_URL + 'api/BulkOperations/DownloadEFileDocumentsWithBulkAsync' : API_BASE_URL + 'api/BulkOperations/DownloadEFileDocumentsAsync';
            let body = isBulkSelectionEnabled ? JSON.stringify({ query: query, localDate: todayDateTime }) : JSON.stringify({ query: query, documentIds: documentIds, localDate: todayDateTime });

            let fetchTask = fetch(apiEndPoint, {
                method: 'POST',
                credentials: 'include',
                body: body,
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json; charset=utf-8',
                    'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value,
                    'X-Resource-Id': resourceId
                },
            })
                .then(handleResponse)
                .then((response: any) => {

                    if (response.id == undefined) {
                        dispatch({
                            type: actionTypes.NOTIFICATION,
                            statusMessage: DeliveredReturnsConstants.ErrorMessage.UploadedESignedEfileFormsCanOnlyDownloadable,
                            statusType: StatusType.Error
                        });
                    }
                    else {
                        if (state.userSettings.settings.defaultSettings.download.rememberDefaultDownload == true) {
                            selectedDownloadType = state.userSettings.settings.defaultSettings.download.downloadType;
                        }
                        if (selectedDownloadType == DefaultDownloadInfoType.DownloadNow) {
                            if (Helper.SessionStore.isExists("DownloadNowIds")) {
                                let DownloadNowIds = Helper.SessionStore.get("DownloadNowIds");
                                var ids = JSON.parse(DownloadNowIds) as string[];
                                ids.push(response.id)
                                Helper.SessionStore.set("DownloadNowIds", JSON.stringify(ids));
                            }
                            else {
                                let ids = [response.id];
                                Helper.SessionStore.set("DownloadNowIds", JSON.stringify(ids));
                            }
                            dispatch({
                                type: actionTypes.NOTIFICATION,
                                statusMessage: DeliveredReturnsConstants.BulkOperationMessage.downloadEFileNowWaitProcessing,
                                statusType: StatusType.Success
                            });
                        }

                        else {
                            dispatch({
                                type: actionTypes.NOTIFICATION,
                                statusMessage: DeliveredReturnsConstants.BulkOperationMessage.downloadEFileWaitProcessing,
                                statusType: StatusType.Success
                            });
                        }
                        let data = state.companyData.myDownloads;
                        data.unshift(response);
                        dispatch({ type: actionTypes.RECEIVE_MY_DOWNLOADS_LIST, myDownloadsList: data })

                    }
                    if (callback) {
                        callback();
                    }
                })
                .catch((error) => {
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: DeliveredReturnsConstants.BulkOperationMessage.downloadEFileError,
                        statusType: StatusType.Error
                    });
                    if (callback) {
                        callback();
                    }
                });
            addTask(fetchTask);
        },

    downloadGroupedReturnEFileFormsBulk: (documentIds: number[], isBulkSelectionEnabled: boolean,
        query: BulkOperationQuery, selectedDownloadType: DefaultDownloadInfoType, callback?: () => void,
        resourceId: string = ""
    ): AppThunkAction<AllKnownAction> => (dispatch, getState) => {
        let state = getState();
        const d = new Date();
        const todayDateTime = d.getDate().toString().padStart(2, "0") + '-' + (d.getMonth() + 1).toString().padStart(2, "0") + '-' + d.getFullYear() + ' ' + d.getHours().toString().padStart(2, "0") + ':' + d.getMinutes().toString().padStart(2, "0");
        let apiEndPoint = isBulkSelectionEnabled ? API_BASE_URL + 'api/BulkOperations/DownloadGroupReturnEFileDocumentsWithBulkAsync' : API_BASE_URL + 'api/BulkOperations/DownloadGroupedReturnEFileDocumentsAsync';
        let body = isBulkSelectionEnabled ? JSON.stringify({ query: query, localDate: todayDateTime }) : JSON.stringify({ query: query, documentIds: documentIds, localDate: todayDateTime  });
        let fetchTask = fetch(apiEndPoint, {
            method: 'POST',
            credentials: 'include',
            body: body,
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8',
                'X-Resource-Id': resourceId,
                'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
            },
        })
            .then(handleResponse)
            .then((response: any) => {

                if (response.id == undefined) {
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: DeliveredReturnsConstants.ErrorMessage.UploadedESignedEfileFormsCanOnlyDownloadable,
                        statusType: StatusType.Error
                    });
                }
                else {
                    if (state.userSettings.settings.defaultSettings.download.rememberDefaultDownload == true) {
                        selectedDownloadType = state.userSettings.settings.defaultSettings.download.downloadType;
                    }
                    if (selectedDownloadType == DefaultDownloadInfoType.DownloadNow) {
                        if (Helper.SessionStore.isExists("DownloadNowIds")) {
                            let DownloadNowIds = Helper.SessionStore.get("DownloadNowIds");
                            var ids = JSON.parse(DownloadNowIds) as string[];
                            ids.push(response.id)
                            Helper.SessionStore.set("DownloadNowIds", JSON.stringify(ids));
                        }
                        else {
                            let ids = [response.id];
                            Helper.SessionStore.set("DownloadNowIds", JSON.stringify(ids));
                        }
                        dispatch({
                            type: actionTypes.NOTIFICATION,
                            statusMessage: DeliveredReturnsConstants.BulkOperationMessage.downloadEFileNowWaitProcessing,
                            statusType: StatusType.Success
                        });
                    }

                    else {
                        dispatch({
                            type: actionTypes.NOTIFICATION,
                            statusMessage: DeliveredReturnsConstants.BulkOperationMessage.downloadEFileWaitProcessing,
                            statusType: StatusType.Success
                        });
                    }
                    let data = state.companyData.myDownloads;
                    data.unshift(response);
                    dispatch({ type: actionTypes.RECEIVE_MY_DOWNLOADS_LIST, myDownloadsList: data })

                }
                if (callback) {
                    callback();
                }
            })
            .catch((error) => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: DeliveredReturnsConstants.BulkOperationMessage.downloadEFileError,
                    statusType: StatusType.Error
                });
                if (callback) {
                    callback();
                }
            });
        addTask(fetchTask);
    },

    downloadGroupedReturnEFileForms: (groupId: number, groupName: string, query: BulkOperationQuery,
        callback?: () => void, resourceId: string = ""): AppThunkAction<AllKnownAction> => (dispatch, getState) => {
            let state = getState();
            const d = new Date();
            const todayDateTime = d.getDate().toString().padStart(2, "0") + '-' + (d.getMonth() + 1).toString().padStart(2, "0") + '-' + d.getFullYear() + ' ' + d.getHours().toString().padStart(2, "0") + ':' + d.getMinutes().toString().padStart(2, "0");
            let apiEndPoint = API_BASE_URL + 'api/GroupLevelActions/DownloadEFileDocumentsAsync';
            let body = JSON.stringify({
                groupId: groupId,
                groupName: groupName,
                query: query,
                localDate: todayDateTime
            });

            let fetchTask = fetch(apiEndPoint, {
                method: 'POST',
                credentials: 'include',
                body: body,
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json; charset=utf-8',
                    'X-Resource-Id': resourceId,
                    'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
                },
            })
                .then(handleResponse)
                .then((response: any) => {

                    if (response.id == undefined) {
                        dispatch({
                            type: actionTypes.NOTIFICATION,
                            statusMessage: DeliveredReturnsConstants.ErrorMessage.UploadedESignedEfileFormsCanOnlyDownloadable,
                            statusType: StatusType.Error
                        });
                    }
                    else {
                        let data = state.companyData.myDownloads;
                        data.unshift(response);
                        dispatch({ type: actionTypes.RECEIVE_MY_DOWNLOADS_LIST, myDownloadsList: data })
                        dispatch({
                            type: actionTypes.NOTIFICATION,
                            statusMessage: DeliveredReturnsConstants.BulkOperationMessage.downloadEFileWaitProcessing,
                            statusType: StatusType.Success
                        });
                    }
                    if (callback) {
                        callback();
                    }
                })
                .catch((error) => {
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: DeliveredReturnsConstants.BulkOperationMessage.downloadEFileError,
                        statusType: StatusType.Error
                    });
                    if (callback) {
                        callback();
                    }
                });
            addTask(fetchTask);
        },

    downloadGroupedReturnsDocumentsBulk: (groupId: number, groupName: string, query:
        BulkOperationQuery, callback?: () => void, resourceId: string = ""): AppThunkAction<AllKnownAction> => (dispatch, getState) => {
            let state = getState();

            let apiEndPoint = API_BASE_URL + 'api/GroupLevelActions/DownloadGroupedReturnsDocumentsAsync';
            let body = JSON.stringify({ groupId: groupId, groupName: groupName, query: query });

            let fetchTask = fetch(apiEndPoint, {
                method: 'POST',
                credentials: 'include',
                body: body,
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json; charset=utf-8',
                    'X-Resource-Id': resourceId,
                    'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
                },
            })
                .then(handleResponse)
                .then((response: any) => {

                    if (response.id == undefined) {
                        dispatch({
                            type: actionTypes.NOTIFICATION,
                            statusMessage: DeliveredReturnsConstants.ErrorMessage.UploadedESignedEfileFormsCanOnlyDownloadable,
                            statusType: StatusType.Error
                        });
                    }
                    else {
                        let data = state.companyData.myDownloads;
                        data.unshift(response);
                        dispatch({ type: actionTypes.RECEIVE_MY_DOWNLOADS_LIST, myDownloadsList: data })
                        dispatch({
                            type: actionTypes.NOTIFICATION,
                            statusMessage: DeliveredReturnsConstants.BulkOperationMessage.downloadEFileWaitProcessing,
                            statusType: StatusType.Success
                        });
                    }
                    if (callback) {
                        callback();
                    }
                })
                .catch((error) => {
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: DeliveredReturnsConstants.BulkOperationMessage.downloadEFileError,
                        statusType: StatusType.Error
                    });
                    if (callback) {
                        callback();
                    }
                });
            addTask(fetchTask);
        },

    archiveGroupTaxDocument: (documentIds: number[], callback?: () => void,
        resourceId: string = ""): AppThunkAction<AllKnownAction> => (dispatch, getState) => {
            const fetchTask = fetch(API_BASE_URL + 'api/GroupLevelActions/ArchiveGroupAsync', {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json; charset=utf-8',
                    'X-Resource-Id': resourceId,
                    'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
                },
                body: JSON.stringify(documentIds)
            })
                .then(handleResponse)
                .then(() => {
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: DeliveredReturnsConstants.BulkOperationMessage.Processing,
                        statusType: StatusType.Success
                    });
                    if (callback) {
                        callback();
                    }
                })
                .catch((error) => {
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: DeliveredReturnsConstants.BulkOperationMessage.ArchiveError,
                        statusType: StatusType.Error
                    });
                    if (callback) {
                        callback();
                    }
                });
            addTask(fetchTask);
        },

    restoreGroupTaxDocument: (documentIds: number[], callback?: () => void,
        resourceId: string = ""): AppThunkAction<AllKnownAction> => (dispatch, getState) => {
            const fetchTask = fetch(API_BASE_URL + 'api/GroupLevelActions/RestoreGroupAsync', {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json; charset=utf-8',
                    'X-Resource-Id': resourceId,
                    'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
                },
                body: JSON.stringify(documentIds)
            })
                .then(handleResponse)
                .then(() => {
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: RestoreReturnConstants.BulkOperationMessage.Processing,
                        statusType: StatusType.Success
                    });
                    if (callback) {
                        callback();
                    }
                })
                .catch((error) => {
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: RestoreReturnConstants.BulkOperationMessage.Error,
                        statusType: StatusType.Error
                    });
                    if (callback) {
                        callback();
                    }
                });
            addTask(fetchTask);
        },

        changeOfficeLocationBulk: (query: BulkOperationQuery,locationId:number, contactPerson:number, onSuccess: () => void,onError: () => void, resourceId: string = ""): AppThunkAction<KnownAction> => (dispatch, getState) => {
            const fetchTask = fetch(API_BASE_URL + 'api/BulkOperations/change-office-location', {
                method: 'PUT',
                credentials: 'include',
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json; charset=utf-8',
                    'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value,
                    'X-Resource-Id': resourceId,
                },
                body: JSON.stringify({
                    query: query,
                    locationId:locationId,
                    contactPerson:contactPerson
                })
            })
                .then(handleResponse)
                .then(() => {                
                    onSuccess();
                })
                .catch(error => {
                    onError();
                });
            addTask(fetchTask);
        },
        

    updateVoucherReminderBulk: (ids: number[], isBulkSelectionEnabled: boolean, query: BulkOperationQuery, allowVoucherReminder: boolean,
        voucherReminderInDays: number, allowSigningReminder: boolean, signingReminderInDays: number, callback?: () => void, resourceId: string=""): AppThunkAction<AllKnownAction> => (dispatch, getState) => {
            let apiEndPoint = isBulkSelectionEnabled ? API_BASE_URL + 'api/BulkOperations/BulkReminderUpdateAsync' : API_BASE_URL + 'api/BulkOperations/ReminderUpdateAsync';
            const fetchTask = fetch(apiEndPoint, {
                method: 'POST',
                credentials: 'include',
                body: isBulkSelectionEnabled ? JSON.stringify({ query: query, allowVoucherReminder: allowVoucherReminder, voucherReminderInDays: voucherReminderInDays, allowSigningReminder: allowSigningReminder, signingReminderInDays: signingReminderInDays })
                    : JSON.stringify({ ids: ids, allowVoucherReminder: allowVoucherReminder, voucherReminderInDays: voucherReminderInDays, allowSigningReminder: allowSigningReminder, signingReminderInDays: signingReminderInDays }),
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json; charset=utf-8',
                    'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value,
                    'X-Resource-Id': resourceId
                },
            })
                .then(handleResponse)
                .then(() => {
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: DeliveredReturnsConstants.BulkOperationMessage.ReminderUpdateProcessing,
                        statusType: StatusType.Success
                    });
                    if (callback) {
                        callback();
                    }
                    logger.trackTrace(`updateVoucherReminderBulk for documentIds: ${ids?.toString()}`);
                })
                .catch((error) => {
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: DeliveredReturnsConstants.BulkOperationMessage.UpdateRemindersError,
                        statusType: StatusType.Error
                    });
                    if (callback) {
                        callback();
                    }
                });
            addTask(fetchTask);
        },
    archiveQuickReportDocumentsBulk: (query: QuickReportBulkOperationQuery, callback?: () => void, resourceId:string=""): AppThunkAction<AllKnownAction> => (dispatch, getState) => {

        const fetchTask = fetch(API_BASE_URL + 'api/BulkOperations/ArchiveDocumentsForQuickReportAsync', {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(query),
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'X-Resource-Id': resourceId,
                'Content-Type': 'application/json; charset=utf-8',
                'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
            },
        })
            .then(handleResponse)
            .then(() => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: DeliveredReturnsConstants.BulkOperationMessage.Processing,
                    statusType: StatusType.Success
                });
                if (callback) {
                    callback();
                }
            })
            .catch((error) => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: DeliveredReturnsConstants.BulkOperationMessage.ArchiveError,
                    statusType: StatusType.Error
                });
                if (callback) {
                    callback();
                }
            });
        addTask(fetchTask);

    },

    deleteQuickReportDocumentsBulk: (query: QuickReportBulkOperationQuery, callback?: () => void, resourcedId:string=""): AppThunkAction<AllKnownAction> => (dispatch, getState) => {

        let fetchTask = fetch(API_BASE_URL + 'api/BulkOperations/DeleteQuickReportDocumentsAsync', {
            method: 'DELETE',
            credentials: 'include',
            body: JSON.stringify(query),
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'X-Resource-Id': resourcedId,
                'Content-Type': 'application/json; charset=utf-8',
                'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
            },
        })
            .then(handleResponse)
            .then(() => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: DeliveredReturnsConstants.BulkOperationMessage.Processing,
                    statusType: StatusType.Success
                });
                if (callback) {
                    callback();
                }
            })
            .catch((error) => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: DeliveredReturnsConstants.BulkOperationMessage.DeleteReturnsError,
                    statusType: StatusType.Error
                });
                if (callback) {
                    callback();
                }
            });
        addTask(fetchTask);

    },
    sendRemindersForQuickReportBulk: (query: QuickReportBulkOperationQuery, callback?: () => void,resourceId:string=""): AppThunkAction<AllKnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(API_BASE_URL + 'api/BulkOperations/SendReminderForQuickReportAsync', {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(query),
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'X-Resource-Id': resourceId,
                'Content-Type': 'application/json; charset=utf-8',
                'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
            },
        })
            .then(handleResponse)
            .then(() => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: DeliveredReturnsConstants.BulkOperationMessage.SendReminderWaitProcessing,
                    statusType: StatusType.Success
                });
                if (callback) {
                    callback();
                }
            })
            .catch((error) => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: DeliveredReturnsConstants.BulkOperationMessage.SendReminderError,
                    statusType: StatusType.Error
                });
                if (callback) {
                    callback();
                }
            });
        addTask(fetchTask);
    },
    updateVoucherReminderForQuickReportBulk: (ids: number[], isBulkSelectionEnabled: boolean, query: QuickReportBulkOperationQuery, allowVoucherReminder: boolean,
        voucherReminderInDays: number, allowSigningReminder: boolean, signingReminderInDays: number, callback?: () => void, resourceId: string=""): AppThunkAction<AllKnownAction> => (dispatch, getState) => {
            let apiEndPoint = isBulkSelectionEnabled ? API_BASE_URL + 'api/BulkOperations/BulkReminderUpdateForQuickReportAsync' : API_BASE_URL + 'api/BulkOperations/ReminderUpdateAsync';
            const fetchTask = fetch(apiEndPoint, {
                method: 'POST',
                credentials: 'include',
                body: isBulkSelectionEnabled ? JSON.stringify({ query: query, allowVoucherReminder: allowVoucherReminder, voucherReminderInDays: voucherReminderInDays, allowSigningReminder: allowSigningReminder, signingReminderInDays: signingReminderInDays })
                    : JSON.stringify({ ids: ids, allowVoucherReminder: allowVoucherReminder, voucherReminderInDays: voucherReminderInDays, allowSigningReminder: allowSigningReminder, signingReminderInDays: signingReminderInDays }),
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json; charset=utf-8',
                    'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value,
                    'X-Resource-Id': resourceId
                },
            })
                .then(handleResponse)
                .then(() => {
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: DeliveredReturnsConstants.BulkOperationMessage.ReminderUpdateProcessing,
                        statusType: StatusType.Success
                    });
                    if (callback) {
                        callback();
                    }
                })
                .catch((error) => {
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: DeliveredReturnsConstants.BulkOperationMessage.UpdateRemindersError,
                        statusType: StatusType.Error
                    });
                    if (callback) {
                        callback();
                    }
                });
            addTask(fetchTask);
        },
    exportToExcelBulk: (query: BulkOperationQuery, callback?: any, resourceId: string =""): AppThunkAction<AllKnownAction> => (dispatch, getState) => {
        let fetchTask = fetch(API_BASE_URL + 'api/BulkOperations/ExportToExcelAsync', {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(query),
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8',
                'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value,
                'X-Resource-Id': resourceId
            },
        })
            .then(handleResponse)
            .then(() => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: DeliveredReturnsConstants.BulkOperationMessage.Processing,
                    statusType: StatusType.Success
                });
                if (callback) {
                    callback();
                }
            })
            .catch((error) => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: DeliveredReturnsConstants.BulkOperationMessage.ExportToExcelError,
                    statusType: StatusType.Error
                });
                if (callback) {
                    callback();
                }
            });
        addTask(fetchTask);
    },
};
