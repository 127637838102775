import { addTask } from 'domain-task';
import { Reducer } from 'redux';
import { AppThunkAction } from '../';
import { handleResponse } from '../Library';
import { PaperFile, UploadtaxReturnConstants } from '../../components/helper/Constants';
import { actionTypes } from '../../types/ActionTypes';
import { NotificationAction, StatusType } from '../../store/common/NotificationStore';
import { DisplayDownloadFile } from '../../components/common/DisplayDownloadFile';
import { API_BASE_URL } from '../../utils/constants';
import { logger } from '../../routes/LoggedIn';
import { IBlobFile } from '../common';

export interface RequestPaperFileAction {
    type: actionTypes.REQUEST_PAPER_FILE_DOCUMENT;
    message: string;
}

export interface ReceivePaperFileUploadLinkAction {
    type: actionTypes.RECEIVE_PAPER_FILE_DOCUMENT_LINK;
    sasUrl: string;
    documentGuid: string;
}

const unloadedState: IBlobFile =
{
    sas: '',
    guid: '',
};

type DispatchAction = RequestPaperFileAction |
    ReceivePaperFileUploadLinkAction;

type KnownAction = DispatchAction | NotificationAction;

export const actionCreators = {
    getPaperFileUploadLink: (url: string, callback?: (data?: any) => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(API_BASE_URL + url, { credentials: 'include' }).then((resp) => resp.json())
            .then(function (data) {
                if (callback) {
                    data ? callback(data) : callback();
                }
                dispatch({ type: actionTypes.RECEIVE_PAPER_FILE_DOCUMENT_LINK, sasUrl: data.sas, documentGuid: data.guid });
            }).catch(error => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: PaperFile.fetchDocumentError,
                    statusType: StatusType.Error
                })
            });
        addTask(fetchTask);
        dispatch({ type: actionTypes.REQUEST_PAPER_FILE_DOCUMENT, message: UploadtaxReturnConstants.OverlayMessage.ApplicationLoading });
    },
    getPaperFileDownLoadLink: (url: string, callback?: (data?: any) => void, retrycallback?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(API_BASE_URL + url, { credentials: 'include' }).then((resp) => resp.json())
            .then(function (data) {
                if (callback) {
                    data ? callback(data) : callback();
                }
                dispatch({ type: actionTypes.RECEIVE_PAPER_FILE_DOCUMENT_LINK, sasUrl: data.sas, documentGuid: data.guid });
            }).catch(error => {
                retrycallback && retrycallback();
            });
        addTask(fetchTask);
        dispatch({ type: actionTypes.REQUEST_PAPER_FILE_DOCUMENT, message: UploadtaxReturnConstants.OverlayMessage.ApplicationLoading });
    },
    deleteUploadedPaperFileFromBlob: (documentGuid: string, fileName: string, taxYear: number, callback?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(API_BASE_URL + 'api/Upload/DeletePaperFileFromBlobAsync/' + documentGuid + '/' + fileName + '/' + taxYear, {
            method: 'DELETE',
            credentials: 'include',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8',
                'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
            }
        })
            .then(handleResponse)
            .then((data) => {
                if (callback) {
                    callback();
                }
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: PaperFile.deleteDocument,
                    statusType: StatusType.Success
                });
            }).catch(error => {
                const statusMessage: any = error.statusText;
                if (typeof(statusMessage) === "string") {
                    dispatch({
                        type: actionTypes.NOTIFICATION, statusMessage: statusMessage,
                        statusType: StatusType.Error
                    });
                }
            });
        addTask(fetchTask);
    },

    downloadMergedPaperFileDocument: (documentGuid: string, taxYear: number, clientId: string, clientName: string,
        onPageReload?: () => void, resourceId:string=""): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let fileName: string = "";
        fetch(API_BASE_URL + 'api/Download/DownloadMergedPaperFiledDocumentAsync', {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'X-Resource-Id': resourceId,
                'Content-Type': 'application/json',
                'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value, 
            },
            body: JSON.stringify({
                documentGuid: documentGuid,
                taxYear: taxYear,
                clientId: clientId,
                clientName: clientName
            })
        }).then(response => {
            const contentDisposition = response.headers.get("content-disposition");
            const fileNameMatch = contentDisposition ? /filename="?([^"]*)"?;/g.exec(contentDisposition) : undefined;
            if (fileNameMatch && fileNameMatch.length > 1) {
                fileName = fileNameMatch[1];
            }
            return response.blob();
        }).then((data) => {
            let displayDownloadFile = new DisplayDownloadFile();
            data && displayDownloadFile.showFile(data, fileName);
            logger.trackTrace(`Paper file document downloaded successfully for clientId: ${clientId} and documentGuid: ${documentGuid}`, { "ClientId": clientId, "DocumentGuid": documentGuid });
            if (onPageReload) {
                onPageReload();
            }
        }).catch(error => {
            dispatch({
                type: actionTypes.NOTIFICATION, statusMessage: PaperFile.fetchDocumentError,
                statusType: StatusType.Error
            });
            if (onPageReload) {
                onPageReload();
            }
        });
    },
    convertToPdfAsync: (url: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(API_BASE_URL + url, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8',
                'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
            }
        })
            .then(handleResponse)
            .catch(error => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: PaperFile.fetchDocumentError,
                    statusType: StatusType.Error
                })
            });
        addTask(fetchTask);
    },
};

export const reducer: Reducer<IBlobFile> = (state = unloadedState, incomingAction) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case actionTypes.REQUEST_PAPER_FILE_DOCUMENT:
            return {
                ...unloadedState
            } as IBlobFile;
        case actionTypes.RECEIVE_PAPER_FILE_DOCUMENT_LINK:
            var received = { ...state };
            received.sas = action.sasUrl;
            received.guid = action.documentGuid;
            return received;
    }
    return state;
}
