import './Core/Services/FetchInterceptorService'
import React from 'react'
import ReactDOM from 'react-dom'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'jquery'
import 'bootstrap'
import './assets/styles/index.css'
import './assets/scripts/polyfill.min.js'
import App from './App'
import reportWebVitals from './reportWebVitals'
import './assets/styles/all.css'

ReactDOM.render(<App />, document.getElementById('root'))

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
