export interface ITaxClient {
    clientGuid: string;
    id: number,
    name: string,
    ssn: string,
    email: string,
    mobileNumber: string,
    countryCode: string,
    isModified: boolean,
    additionalInfo: ITaxClientAdditionalInfo
}
export interface ITaxpayer extends ITaxClient {
    address: string,
    city: string,
    state: string,
    zip: string,
    dob: Date,
    isDeceased: boolean,
    mobileNumber: string,
    countryCode: string
}
export interface ITaxClientAdditionalInfo {
    isAttested: boolean
}

export enum TaxClientFileds {
    //Taxpayer
    taxpayerName,
    taxpayerEmail,
    //Spouse
    spouseName,
    spouseEmail,
    //Partner
    partnerName,
    partnerEmail
}

export const initiaITaxpayerState: Readonly<ITaxpayer> = {
    clientGuid: '',
    id: 0,
    name: '',
    ssn: '',
    email: '',
    mobileNumber: '',
    countryCode: '',
    isModified: false,
    additionalInfo: {} as ITaxClientAdditionalInfo,
    address: '',
    city: '',
    state: '',
    zip: '',
    dob: new Date(),
    isDeceased: false
}
