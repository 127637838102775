import * as React from "react";

interface CheckboxProps {
    textCheckbox: string;
    onChangeCheckbox: any;
    dataTestAuto: string;
    checkedCheckbox: boolean;
    disabledCheckbox?: boolean;
    id: string;
}
export class Checkbox extends React.Component<CheckboxProps, {}> {
    public render() {
        let checked = "checkbox checkbox-replace";
        if (this.props.checkedCheckbox) {
            checked += " checked";
        }

        return (
            <div className={checked + " " + (this.props.disabledCheckbox == true ? "disabled" : "")}>
                <label className="cb-wrapper">
                    <input type="checkbox"
                        id={this.props.id}
                        checked={this.props.checkedCheckbox}
                        onChange={this.props.onChangeCheckbox}
                        data-test-auto={this.props.dataTestAuto}
                        disabled={this.props.disabledCheckbox} />
                    <div className="checked"></div>
                </label>
                <label htmlFor={this.props.id} dangerouslySetInnerHTML={{ __html: this.props.textCheckbox }} />
            </div>
        );
    }
}
export default Checkbox;