import * as React from "react";
import { Partnership } from "./Partnership";
import { Spouse } from "./Spouse";
import { Taxpayer } from "./Taxpayer";
import { ClientTypesNumber, ISignerModel, isIndividual, isMutual, isPartnership, ITaxReturn, SignatureStatus } from "./TaxReturn";
import { IDropdown } from "../../Core/Common/Dropdown";
import { ISMSNotificationDetails } from "../../store/common/SMSNotificationDetailsStore";
import { ClientInfoProperty } from "./ClientInfoEnum";
import { isValidEmailAddress } from "../helper/Validations";
import { Guid } from "../../Core/Utilities/Guid";
import { ICountryState } from "../../Core/ViewModels/Company/CompanyViewModel";

export interface IClientInfoProps {
    model: any; //ITaxReturn, IDeliveredTaxRetunrs
    isDeliveredReturn: boolean;
    locationDropdown: IDropdown[];
    isGroupedReturn: boolean;
    showSMSTextNotifications: boolean;
    isTPOpted: boolean;
    isSpouseOpted: boolean;
    smsNotificationDetails: ISMSNotificationDetails;
    updateTPOpted: (tpOpted: boolean) => void;
    states: ICountryState[];
    nextSignerDetails?: ISignerModel;
    updateSpouseOpted: (spouseOpted: boolean) => void;
    updateTaxDocument: (taxDocument: ITaxReturn) => void;
    mobileNumberChanged?: () => void;
    markSpouseEmailUpdateEligibility: (clientGuid: string) => void;
}

export interface IClientInfoState {
    loadingMessage: string | undefined;
    clientGUIDs: string[];
}

export class EditClientInfo extends React.Component<IClientInfoProps, IClientInfoState> {
    constructor(props: IClientInfoProps) {
        super(props);
        this.state = {
            clientGUIDs: [],
            loadingMessage: undefined
        };
    }

    private handleFieldChange = (item: ClientInfoProperty, value: any) => {
        if (item === ClientInfoProperty.clientId) {
            this.props.model.isModified = true;
            this.props.model.clientId = value;
            return this.props.updateTaxDocument(this.props.model);
        }
        if (item === ClientInfoProperty.officeLocation) {
            this.props.model.isModified = true;
            this.props.model.locationId = value == 0 ? null : value;
            return this.props.updateTaxDocument(this.props.model);
        }
        if (isPartnership(this.props.model)) {
            this.props.model.partnership.isModified = true;
            switch (item) {
                case ClientInfoProperty.partnerName:
                    this.props.model.partnership.name = value;
                    break;
                case ClientInfoProperty.partnerEmail: {
                    this.props.model.partnership.email = value || "";
                    if (isValidEmailAddress(value)) {
                        this.props.model.partnership.clientGuid = Guid.newGuid().toString();
                    }
                    break;
                }
                case ClientInfoProperty.partnerEin:
                    this.props.model.partnership.ssn = value;
                    break;
                case ClientInfoProperty.partnerCountryCode: {
                    this.props.model.partnership.countryCode = value;
                    this.props.mobileNumberChanged && this.props.mobileNumberChanged();
                    break;
                }
                case ClientInfoProperty.partnerMobileNumber: {
                    this.props.model.partnership.mobileNumber = value;
                    this.props.mobileNumberChanged && this.props.mobileNumberChanged();
                    break;
                }
            }
            return this.props.updateTaxDocument(this.props.model);
        }

        if (isMutual(this.props.model)) {
            this.props.model.taxpayer.isModified = true;
            this.props.model.spouse.isModified = true;
            switch (item) {
                case ClientInfoProperty.taxpayerName:
                    this.props.model.taxpayer.name = value;
                    break;
                case ClientInfoProperty.taxpayerEmail: {
                    this.props.model.taxpayer.email = value || "";
                    if (isValidEmailAddress(value)) {
                        this.props.model.taxpayer.clientGuid = Guid.newGuid().toString();
                    }
                    break;
                }
                case ClientInfoProperty.taxpayerDateOfBirth:
                    this.props.model.taxpayer.dob = value;
                    break;
                case ClientInfoProperty.taxpayerAddress:
                    this.props.model.taxpayer.address = value;
                    break;
                case ClientInfoProperty.taxpayerCity:
                    this.props.model.taxpayer.city = value;
                    break;
                case ClientInfoProperty.taxpayerState:
                    this.props.model.taxpayer.state = value;
                    break;
                case ClientInfoProperty.taxpayerZip:
                    this.props.model.taxpayer.zip = value;
                    break;
                case ClientInfoProperty.taxpayerSSN:
                    this.props.model.taxpayer.ssn = value;
                    break;
                case ClientInfoProperty.taxpayerCountryCode: {
                    this.props.model.taxpayer.countryCode = value;
                    this.props.mobileNumberChanged && this.props.mobileNumberChanged();
                    break;
                }
                case ClientInfoProperty.taxpayerMobileNumber: {
                    this.props.model.taxpayer.mobileNumber = value;
                    this.props.mobileNumberChanged && this.props.mobileNumberChanged();
                    break;
                }
                //Spouse
                case ClientInfoProperty.spouseName:
                    this.props.model.spouse.name = value;
                    break;
                case ClientInfoProperty.spouseEmail: {
                    this.props.model.spouse.email = value || "";
                    if (isValidEmailAddress(value)) {
                        this.props.model.spouse.clientGuid = Guid.newGuid().toString();
                    }
                    this.setGUIDState(ClientTypesNumber.Spouse, this.props.model.spouse.clientGuid);
                    break;
                }
                case ClientInfoProperty.spouseDateOfBirth:
                    this.props.model.spouse.dob = value;
                    break;
                case ClientInfoProperty.spouseAddress:
                    this.props.model.spouse.address = value;
                    break;
                case ClientInfoProperty.spouseCity:
                    this.props.model.spouse.city = value;
                    break;
                case ClientInfoProperty.spouseState:
                    this.props.model.spouse.state = value;
                    break;
                case ClientInfoProperty.spouseZip:
                    this.props.model.spouse.zip = value;
                    break;
                case ClientInfoProperty.spouseSSN:
                    this.props.model.spouse.ssn = value;
                    break;
                case ClientInfoProperty.spouseCountryCode: {
                    this.props.model.spouse.countryCode = value;
                    this.props.mobileNumberChanged && this.props.mobileNumberChanged();
                    break;
                }
                case ClientInfoProperty.spouseMobileNumber: {
                    this.props.model.spouse.mobileNumber = value;
                    this.props.mobileNumberChanged && this.props.mobileNumberChanged();
                    break;
                }
            }
            return this.props.updateTaxDocument(this.props.model);
        }

        if (isIndividual(this.props.model)) {
            this.props.model.taxpayer.isModified = true;
            switch (item) {
                case ClientInfoProperty.taxpayerName:
                    this.props.model.taxpayer.name = value;
                    break;
                case ClientInfoProperty.taxpayerEmail: {
                    this.props.model.taxpayer.email = value || "";
                    if (isValidEmailAddress(value)) {
                        this.props.model.taxpayer.clientGuid = Guid.newGuid().toString();
                    }
                    break;
                }
                case ClientInfoProperty.taxpayerDateOfBirth:
                    this.props.model.taxpayer.dob = value;
                    break;
                case ClientInfoProperty.taxpayerAddress:
                    this.props.model.taxpayer.address = value;
                    break;
                case ClientInfoProperty.taxpayerCity:
                    this.props.model.taxpayer.city = value;
                    break;
                case ClientInfoProperty.taxpayerState:
                    this.props.model.taxpayer.state = value;
                    break;
                case ClientInfoProperty.taxpayerZip:
                    this.props.model.taxpayer.zip = value;
                    break;
                case ClientInfoProperty.taxpayerSSN:
                    this.props.model.taxpayer.ssn = value;
                    break;
                case ClientInfoProperty.taxpayerCountryCode: {
                    this.props.model.taxpayer.countryCode = value;
                    this.props.mobileNumberChanged && this.props.mobileNumberChanged();
                    break;
                }
                case ClientInfoProperty.taxpayerMobileNumber: {
                    this.props.model.taxpayer.mobileNumber = value;
                    this.props.mobileNumberChanged && this.props.mobileNumberChanged();
                    break;
                }
            }
            return this.props.updateTaxDocument(this.props.model);
        }
    };

    private filterClientGUID = (clientGUID: string) => {
        let tempClientGUIDs: string[] = Object.assign([], this.state.clientGUIDs);
        if (this.state.clientGUIDs.length > 0) {
            if (tempClientGUIDs.indexOf(clientGUID) > -1) {
                tempClientGUIDs.splice(tempClientGUIDs.indexOf(clientGUID), 1);
            }
        }
        tempClientGUIDs.push(clientGUID);
        return tempClientGUIDs;
    };

    private setGUIDState = (signerType: ClientTypesNumber, clientGuid: string) => {
        const taxReturn: any = this.props.model;
        if (
            taxReturn.signatureStatus == SignatureStatus[SignatureStatus.AwaitingESign] ||
            taxReturn.signatureStatus == SignatureStatus[SignatureStatus.AwaitingUpload]
        ) {
            this.props.nextSignerDetails &&
                Number(this.props.nextSignerDetails.signerType) == signerType &&
                this.props.markSpouseEmailUpdateEligibility(clientGuid);
        } else if (taxReturn.signatureStatus == SignatureStatus[SignatureStatus.SignedAndESigned]) {
            this.props.nextSignerDetails &&
                Number(this.props.nextSignerDetails.signerType) == signerType &&
                this.setState({
                    clientGUIDs: this.filterClientGUID(clientGuid)
                });
            this.props.markSpouseEmailUpdateEligibility(clientGuid);
        } else if (
            taxReturn.signatureStatus == SignatureStatus[SignatureStatus.ESigned] ||
            taxReturn.signatureStatus == SignatureStatus[SignatureStatus.Uploaded] ||
            taxReturn.signatureStatus == SignatureStatus[SignatureStatus.ManuallySigned]
        ) {
            this.setState({
                clientGUIDs: this.filterClientGUID(clientGuid)
            });
            this.props.markSpouseEmailUpdateEligibility(clientGuid);
        } else if (taxReturn.signatureStatus == SignatureStatus[SignatureStatus.PartiallySigned]) {
            if (this.props.nextSignerDetails && Number(this.props.nextSignerDetails.signerType) == signerType) {
                this.props.markSpouseEmailUpdateEligibility(clientGuid);
            }
        }
    };

    public render() {
        return (
            <div className="tab-content-width">
                {this.props.model && isMutual(this.props.model) ? (
                    <div className="row">
                        <Taxpayer
                            clientId={this.props.model.clientId}
                            model={this.props.model.taxpayer}
                            handleValueChange={this.handleFieldChange}
                            states={this.props.states}
                            taxReturn={this.props.model}
                            isDeliveredReturn={this.props.isDeliveredReturn}
                            locationDropdown={this.props.locationDropdown}
                            isGroupedReturn={this.props.isGroupedReturn}
                            isMandatory={!this.props.model.taxpayer?.isDeceased}
                            showSMSTextNotifications={this.props.showSMSTextNotifications}
                            isTPOpted={this.props.isTPOpted}
                            smsNotificationDetails={this.props.smsNotificationDetails}
                            updateTPOpted={this.props.updateTPOpted}
                        />
                        <Spouse
                            model={this.props.model.spouse}
                            states={this.props.states}
                            handleValueChange={this.handleFieldChange}
                            taxReturn={this.props.model}
                            isMandatory={!this.props.model.spouse?.isDeceased}
                            showSMSTextNotifications={this.props.showSMSTextNotifications}
                            isSpouseOpted={this.props.isSpouseOpted}
                            smsNotificationDetails={this.props.smsNotificationDetails}
                            updateSpouseOpted={this.props.updateSpouseOpted}
                        />
                    </div>
                ) : this.props.model && isIndividual(this.props.model) ? (
                    <div className="row">
                        <Taxpayer
                            clientId={this.props.model.clientId}
                            model={this.props.model.taxpayer}
                            handleValueChange={this.handleFieldChange}
                            states={this.props.states}
                            taxReturn={this.props.model}
                            isDeliveredReturn={this.props.isDeliveredReturn}
                            locationDropdown={this.props.locationDropdown}
                            isGroupedReturn={this.props.isGroupedReturn}
                            isMandatory={true}
                            showSMSTextNotifications={this.props.showSMSTextNotifications}
                            smsNotificationDetails={this.props.smsNotificationDetails}
                            isTPOpted={this.props.isTPOpted}
                            updateTPOpted={this.props.updateTPOpted}
                        />
                    </div>
                ) : (
                    this.props.model &&
                    isPartnership(this.props.model) && (
                        <div className="row">
                            <Partnership
                                clientId={this.props.model.clientId}
                                model={this.props.model.partnership}
                                handleValueChange={this.handleFieldChange}
                                taxReturn={this.props.model}
                                isDeliveredReturn={this.props.isDeliveredReturn}
                                locationDropdown={this.props.locationDropdown}
                                isGroupedReturn={this.props.isGroupedReturn}
                                engagementType={this.props.model.engagementType}
                                showSMSTextNotifications={this.props.showSMSTextNotifications}
                                smsNotificationDetails={this.props.smsNotificationDetails}
                                isTPOpted={this.props.isTPOpted}
                                updateTPOpted={this.props.updateTPOpted}
                            />
                        </div>
                    )
                )}
            </div>
        );
    }
}
