import { addTask } from 'domain-task';
import { AppThunkAction } from '../';
import { actionTypes } from '../../types/ActionTypes';
import { Reducer, Action } from 'redux';

import { BusinessReturnInstruction } from '../../Core/ViewModels/Common/BusinessReturnInstructionViewModel';
import { BusinessReturnConstants } from '../../components/helper/Constants';
import { StatusType, NotificationAction } from '../common/NotificationStore';
import { handleResponse } from '../Library';
import { EngagementType } from '../../components/common/TaxReturn';
import { DisplayDownloadFile } from '../../components/common/DisplayDownloadFile';
import { API_BASE_URL } from '../../utils/constants';

var equal = require('fast-deep-equal');

export interface IBusinessReturnsState {
    instructions: BusinessReturnInstruction[];
    isLoading: boolean;
    loaderMessage: string;
}

interface RequestBusinessReturnInstructionListAction {
    type: actionTypes.REQUEST_BUSINESS_RETURN_INSTRUCTIONS;
}

interface ReceiveBusinessReturnInstructionListAction {
    type: actionTypes.RECEIVE_BUSINESS_RETURN_INSTRUCTIONS;
    instructions: BusinessReturnInstruction[];
}

interface ReplaceBusinessReturnStartAction {
    type: actionTypes.REPLACE_BUSINESS_RETURN_INSTRUCTION_START;
}

interface ReplaceBusinessReturnStopAction {
    type: actionTypes.REPLACE_BUSINESS_RETURN_INSTRUCTION_FINISH;
    instruction: BusinessReturnInstruction;
    fileName: string;
}

type DispatchAction = RequestBusinessReturnInstructionListAction |
    ReceiveBusinessReturnInstructionListAction |
    ReplaceBusinessReturnStartAction |
    ReplaceBusinessReturnStopAction

type KnownAction = DispatchAction |
    NotificationAction

export const actionCreators = {
    requestBusinessReturnInstructions: (reload: boolean = false): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let state = getState().businessReturnInstructions;
        if (reload || state.instructions.length === 0) {
            const fetchTask = fetch(API_BASE_URL + 'api/Common/GetBusinessReturnInstructionsAsync', { credentials: 'include' })
                .then(handleResponse)
                .then((resp) => resp as Promise<BusinessReturnInstruction[]>)
                .then((data) => {
                    if (data) {
                        dispatch({
                            type: actionTypes.RECEIVE_BUSINESS_RETURN_INSTRUCTIONS,
                            instructions: data
                        });
                    }
                }).catch(function (error) {
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: BusinessReturnConstants.FetchInstructionsErrorMessage,
                        statusType: StatusType.Error
                    });
                });
            addTask(fetchTask);
            dispatch({ type: actionTypes.REQUEST_BUSINESS_RETURN_INSTRUCTIONS });
        }
    },
    requestPreviewBusinessInstructionLink: (type: string, taxYear: number, callback: (url: string) => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(API_BASE_URL + '/api/Download/GetBusinessInstructionLinkAsync/?type=' + type + '&taxYear=' + taxYear, { credentials: 'include' })
            .then(handleResponse)
            .then((data) => {
                callback(data);
            }).catch(function (error) {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: BusinessReturnConstants.FetchInstructionErrorMessage,
                    statusType: StatusType.Error
                });
            });
        addTask(fetchTask);
    },
    requestBusinessInstructionLink: (type: string, taxYear: number, fileName: string, callback?: (url: Blob) => void, resourceId: string = ""): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(API_BASE_URL + '/api/Download/DownloadBusinessInstructionLinkAsync', {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-Resource-Id': resourceId,
                'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
            },
            body: JSON.stringify({
                type: type,
                taxYear: taxYear
            })
        }).then(response => {
            const contentDisposition = response.headers.get("content-disposition");
            const fileNameMatch = contentDisposition ? /filename="?([^"]*)"?;/g.exec(contentDisposition) : undefined;
            if (fileNameMatch && fileNameMatch.length > 1) {
                fileName = fileNameMatch[1];
            }
            return response.blob();
        }).then((data) => {
            if (callback) {
                callback(data);
            }
            else {
                let displayDownloadFile = new DisplayDownloadFile();
                displayDownloadFile.showFile(data, fileName);
            }
        }).catch((error: any) => {
            console.log(error);
        });
        addTask(fetchTask);
    },

    replaceBusinessReturnInstruction: (file: FileList, instruction: BusinessReturnInstruction): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(API_BASE_URL + '/api/Upload/GetBusinessInstructionUploadLinkAsync/?type=' +
            EngagementType[instruction.engagementType].toString() + '&taxYear=' + instruction.taxYear, { credentials: 'include' })
            .then(handleResponse)
            .then((url) => {
                const formData = new FormData();
                formData.append('myFile', file[0]);
                fetch(url, {
                    method: 'PUT',   
                    headers: new Headers({
                        'Access-Control-Allow-Origin': '*',
                        "x-ms-blob-type": "BlockBlob",
                        'x-ms-meta-filename': file[0].name,
                        'x-ms-meta-filetype': 'application/pdf',
                        'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
                    }),
                    body: formData
                }).then(response => {
                    instruction.fileName = file[0].name;
                    let options: any = {
                        method: 'PUT',
                        credentials: 'include',
                        headers: {
                            'Accept': 'application/json, text/plain, */*',
                            'Content-Type': 'application/json; charset=utf-8',
                            'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
                        },
                        body: JSON.stringify({
                            TaxYear: instruction.taxYear,
                            EngagementType: instruction.engagementType,
                            FileName: instruction.fileName
                        })
                    };

                    fetch(API_BASE_URL + 'api/Common/UpdateBusinessReturnInstructionAsync', options)
                        .then(response => {
                            dispatch({
                                type: actionTypes.REPLACE_BUSINESS_RETURN_INSTRUCTION_FINISH,
                                instruction: instruction,
                                fileName: file[0].name
                            });
                            dispatch({
                                type: actionTypes.NOTIFICATION,
                                statusMessage: BusinessReturnConstants.DocumentReplacedSuccessMessage,
                                statusType: StatusType.Success
                            });
                        }).catch(function (error) {
                            dispatch({
                                type: actionTypes.NOTIFICATION,
                                statusMessage: BusinessReturnConstants.FetchInstructionErrorMessage,
                                statusType: StatusType.Error
                            });
                        });
                });
            }).catch(function (error) {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: BusinessReturnConstants.FetchInstructionErrorMessage,
                    statusType: StatusType.Error
                });
            });
        addTask(fetchTask);
        dispatch({ type: actionTypes.REPLACE_BUSINESS_RETURN_INSTRUCTION_START });

    },

    updateBusinessReturnInstruction: (file: FileList, instruction: BusinessReturnInstruction): AppThunkAction<KnownAction> => (dispatch, getState) => {
        instruction.fileName = file[0].name;
        let options: any = {
            method: 'PUT',
            credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
            },
            body: JSON.stringify({
                TaxYear: instruction.taxYear,
                EngagementType: instruction.engagementType,
                FileName: instruction.fileName
            })
        };

        const fetchTask = fetch(API_BASE_URL + 'api/Common/UpdateBusinessReturnInstructionAsync', options)
                        .then(response => {
                            dispatch({
                                type: actionTypes.REPLACE_BUSINESS_RETURN_INSTRUCTION_FINISH,
                                instruction: instruction,
                                fileName: file[0].name
                            });
                            dispatch({
                                type: actionTypes.NOTIFICATION,
                                statusMessage: BusinessReturnConstants.DocumentReplacedSuccessMessage,
                                statusType: StatusType.Success
                            });
                        }).catch(function (error) {
                            dispatch({
                                type: actionTypes.NOTIFICATION,
                                statusMessage: BusinessReturnConstants.FetchInstructionErrorMessage,
                                statusType: StatusType.Error
                            });
                        });

                        addTask(fetchTask);
                        dispatch({ type: actionTypes.REPLACE_BUSINESS_RETURN_INSTRUCTION_START });
    },
}

const unloadedState: IBusinessReturnsState = {
    instructions: [],
    isLoading: false,
    loaderMessage: "Loading..."
}

export const reducer: Reducer<IBusinessReturnsState> = (prevState: IBusinessReturnsState = unloadedState,
    incomingAction: Action) => {
    const action = incomingAction as DispatchAction;
    const newState = { ...prevState };
    switch (action.type) {
        case actionTypes.REQUEST_BUSINESS_RETURN_INSTRUCTIONS:
            newState.isLoading = true;
            newState.loaderMessage = unloadedState.loaderMessage;
            break;
        case actionTypes.RECEIVE_BUSINESS_RETURN_INSTRUCTIONS:
            newState.instructions = action.instructions;
            newState.isLoading = false;
            break;
        case actionTypes.REPLACE_BUSINESS_RETURN_INSTRUCTION_START:
            newState.isLoading = true;
            newState.loaderMessage = BusinessReturnConstants.ReplaceDocumentLoaderMessage;
            break;
        case actionTypes.REPLACE_BUSINESS_RETURN_INSTRUCTION_FINISH:
            newState.instructions.map((ins) => {
                if (equal(action.instruction, ins)) {
                    ins.fileName = action.fileName;
                    return false;
                }
            });
            newState.isLoading = false;
            break;
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            const exhaustiveCheck: never = action;
    }
    return newState;
}
