let moment = require("moment");

import * as React from "react";
import {
    BootstrapTable,
    TextFilter,
    SelectFilter,
    CustomFilter,
    TableHeaderColumn,
    CustomSelectProps
} from "react-bootstrap-table";
import "react-bootstrap-table/css/react-bootstrap-table.css";
import { CustomMultiSelect } from "../../common/MultipleSelectComponent";
import {
    DaysRangeOptionsList,
    getClientName,
    KBAStatus,
    LinkStatus,
    SignatureStatus,
    DocumentFilingType,
    IOfficeLocation
} from "../../common/TaxReturn";
import { Loader, LoadingOverlay } from "react-overlay-loader";
import { DeliveredReturnsTableConstants } from "../../helper/Constants";
import { QuickReportToolbar } from "./QuickReportToolbar";
import { QuickReportType } from "../Filters";
import * as Helper from "../../helper/HelperFunctions";
import { QuickReportState } from "./QuickReportState";
import { CheckBoxComponent, CheckBoxSize } from "../../common/CheckBoxComponent";
import { Alert } from "react-bootstrap";
import { Link } from "react-router-dom";
import { DocumentFilingTypeList, DocumentFilingTypeOptionsList } from "../../common/ProcessReturnModal/PaperFile/PaperFile";

const LinkStatusList = {
    None: "None",
    NotAccessedByTaxpayer: "Not Accessed by Taxpayer",
    NotAccessedBySpouse: "Not Accessed by Spouse",
    NotAccessed: "Not Accessed"
};

const KBAStatusList = {
    None: "None",
    TaxpayerFailed: "Taxpayer Failed",
    SpouseFailed: "Spouse Failed"
};

const SignatureStatusList = {
    None: "None",
    ManuallySigned: "Manually Signed",
    AwaitingESign: "Awaiting E-Sign",
    AwaitingUpload: "Awaiting Upload",
    PartiallySigned: "Partially Signed"
};

interface QuickReportsTableProp {
    quickReports: QuickReportState;
    onPageChange: any;
    onSortChange: any;
    totalRows: number;
    pageSize: number;
    onSearchChange: any;
    onFilterChange: any;
    pageNo: number;
    isLoading: boolean;
    onExportToExcel(quickReportType: QuickReportType, onExportToComplete: () => void): void;
    onPageReload: () => void;
    refreshDelay?: boolean;
    onSelectQuickReports: (quickReportType: QuickReportType) => any;
    selectedQuickReport: QuickReportType;
    closeQuickReport: () => void;
    onRowSelect: (row: any, isSelected: any, e: any) => void;
    onSelectAll: (isSelected: any, rows: any, e: any) => void;
    selectedRows: number[];
    unSelectedRows: number[];
    showBulkSelectionMessage: boolean;
    onBulkSelectionChange: (isEnabled: boolean) => void;
    isArchived: boolean;
    onClearFilter: (callback?: () => any) => void;
    isPaperFiledEnabled: boolean;
    onSignedDetailsOpen: (rowIndex: number) => void;
}
interface QuickReportsTableState {
    officeLocationList: any[];
}

export class QuickReportsTable extends React.Component<QuickReportsTableProp, QuickReportsTableState> {
    private customTaxYearMultiSelect: any;
    private taxYearList: any[] = [];
    private customStatusMultiSelect: any;
    private signatureStatusMultiSelect: any;
    private filterChanged: boolean = false;
    private customOfficeMultiSelect: any;
    constructor(props: QuickReportsTableProp) {
        super(props);
        this.state = {
            officeLocationList: []
        };
        this.onClearFilter = this.onClearFilter.bind(this);
        this.onFilterChange = this.onFilterChange.bind(this);
    }

    UNSAFE_componentWillMount() {
        this.taxYearList = Helper.PrepareTaxYear();
        Helper.fetchUserOfficeLocation(this.setOfficeLocation);
    }

    private setOfficeLocation = (officeLocationList: IOfficeLocation[]) => {
        let list;
        if (officeLocationList?.length) {
            list = officeLocationList.map(({ locationName: label, locationId: value }) => ({ label, value }));
        } else {
            list = [];
        }
        this.setState({ officeLocationList: list });
    };

    defaultType(cell: any, row: any) {
        return (
            <div title={cell} className="ellipsis">
                {cell}
            </div>
        );
    }

    getYearMultiSelectDropDown = (filterHandler: any, customFilterParameters: any) => {
        const options = customFilterParameters.options;
        const enableAllFilter = customFilterParameters.enableAllFilter;
        const placeholder = customFilterParameters.placeholder;
        return (
            <CustomMultiSelect
                onRef={(ref: any) => (this.customTaxYearMultiSelect = ref)}
                filterHandler={filterHandler}
                options={options}
                enableAllFilter={enableAllFilter}
                placeholder={placeholder}
            />
        );
    };
    getOfficeSelectDropDown = (filterHandler: any, customFilterParameters: any) => {
        const options = customFilterParameters.options;
        const enableAllFilter = customFilterParameters.enableAllFilter;
        const placeholder = customFilterParameters.placeholder;
        return (
            <CustomMultiSelect
                onRef={(ref: any) => (this.customOfficeMultiSelect = ref)}
                filterHandler={filterHandler}
                options={options}
                enableAllFilter={enableAllFilter}
                placeholder={placeholder}
            />
        );
    };

    linkStatusIdType = (cell: any, row: any) => {
        let status: any = row.linkStatusIdType;
        let statusType: "None" | "NotAccessedByTaxpayer" | "NotAccessedBySpouse" | "NotAccessed" =
            typeof status == "number" ? LinkStatus[status] : status;

        return <span>{LinkStatusList[statusType]}</span>;
    };

    kbaStatusIdType = (cell: any, row: any) => {
        let status: any = row.kbaStatusIdType;
        let statusType: "None" | "TaxpayerFailed" | "SpouseFailed" = typeof status == "number" ? KBAStatus[status] : status;

        return <span>{KBAStatusList[statusType]}</span>;
    };

    signatureStatusIdType = (cell: any, row: any) => {
        let status: any = row.signatureStatusIdType;
        let signatureType: "None" | "ManuallySigned" | "AwaitingESign" | "AwaitingUpload" | "PartiallySigned" =
            typeof status == "number" ? SignatureStatus[status] : status;
        if (row.signedCount > 0 && row.signatureStatusIdType === SignatureStatus[SignatureStatus.PartiallySigned]) {
            return (
                <Link to={"#"} onClick={() => this.props.onSignedDetailsOpen(row.rowIndex)}>
                    {SignatureStatusList[signatureType].toUpperCase()}
                </Link>
            );
        } else if (row.signedCount > 0 && row.signatureStatusIdType === SignatureStatus[SignatureStatus.AwaitingUpload]) {
            return (
                <Link to={"#"} onClick={() => this.props.onSignedDetailsOpen(row.rowIndex)}>
                    {SignatureStatusList[signatureType].toUpperCase()}
                </Link>
            );
        }
        return <span>{SignatureStatusList[signatureType].toUpperCase()}</span>;
    };

    customStatusTooltip = (cell: any, row: any) => {
        const status: "None" | "NotAccessedByTaxpayer" | "NotAccessedBySpouse" | "NotAccessed" =
            typeof row.linkStatusIdType === "number" ? LinkStatus[row.linkStatusIdType] : row.linkStatusIdType;
        return `${LinkStatusList[status]}`;
    };

    kbaCustomStatusTooltip = (cell: any, row: any) => {
        const status: "None" | "TaxpayerFailed" | "SpouseFailed" =
            typeof row.kbaStatusIdType === "number" ? KBAStatus[row.kbaStatusIdType] : row.kbaStatusIdType;
        return `${KBAStatusList[status]}`;
    };

    getStatusMultiSelectDropDown = (filterHandler: any, customFilterParameters: any) => {
        const options = customFilterParameters.options;
        const enableAllFilter = customFilterParameters.enableAllFilter;
        const placeholder = customFilterParameters.placeholder;
        return (
            <CustomMultiSelect
                onRef={(ref: any) => (this.customStatusMultiSelect = ref)}
                filterHandler={filterHandler}
                options={options}
                enableAllFilter={enableAllFilter}
                placeholder={placeholder}
            />
        );
    };

    getSignatureStatusMultiSelectDropDown = (filterHandler: any, customFilterParameters: any) => {
        const options = customFilterParameters.options;
        const enableAllFilter = customFilterParameters.enableAllFilter;
        const placeholder = customFilterParameters.placeholder;
        return (
            <CustomMultiSelect
                onRef={(ref: any) => (this.signatureStatusMultiSelect = ref)}
                filterHandler={filterHandler}
                options={options}
                enableAllFilter={enableAllFilter}
                placeholder={placeholder}
            />
        );
    };

    LinkStatusOptionsList = [
        { label: "Not Accessed By Taxpayer ", value: 1 },
        { label: "Not Accessed By Spouse", value: 2 },
        { label: "Not Accessed", value: 3 }
    ];

    KBAStatusOptionsList = [
        { label: "Taxpayer Failed ", value: 1 },
        { label: "Spouse Failed", value: 2 }
    ];
    private SignatureStatusOptionsList = [
        { label: "MANUALLY SIGNED", value: 2 },
        { label: "AWAITING E-SIGN", value: 4 },
        { label: "AWAITING UPLOAD", value: 5 },
        { label: "PARTIALLY SIGNED", value: 20 }
    ];

    customSignatureStatusTooltip = (cell: any, row: any) => {
        const status: "None" | "ManuallySigned" | "AwaitingESign" | "AwaitingUpload" | "PartiallySigned" =
            typeof row.signatureStatusIdType === "number"
                ? SignatureStatus[row.signatureStatusIdType]
                : row.signatureStatusIdType;
        return `${SignatureStatusList[status]}`;
    };

    private customFilingTypeTooltip = (filingType: DocumentFilingType) => {
        let datafilingType: any = filingType;
        let filingTypeName: "None" | "EFiled" | "Both" =
            typeof datafilingType == "number" ? DocumentFilingType[datafilingType] : datafilingType;
        return `${filingTypeName}`;
    };

    customFilingType = (filingType: DocumentFilingType) => {
        let datafilingType: any = filingType;
        let filingTypeName: "None" | "EFiled" | "Both" =
            typeof datafilingType == "number" ? DocumentFilingType[datafilingType] : datafilingType;
        return `${DocumentFilingTypeList[filingTypeName]}`;
    };

    getColumns = () => {
        return [
            {
                header: "",
                key: "index",
                isKey: true,
                dataFormat: this.defaultType,
                columnClassName: "",
                dataSort: true,
                toolTip: false,
                isHidden: true,
                width: "auto",
                filter: { type: "TextFilter", placeholder: "index", style: { font: "bold" } } as TextFilter
            },
            {
                header: "Name",
                key: "taxDocumentName",
                isKey: false,
                dataFormat: this.defaultType,
                columnClassName: "",
                dataSort: true,
                toolTip: false,
                isHidden: false,
                width: "200px",
                filter: { type: "TextFilter", placeholder: "NAME", style: { font: "bold" } } as TextFilter
            },
            {
                header: "Client ID",
                key: "clientId",
                isKey: false,
                dataFormat: this.defaultType,
                columnClassName: "",
                dataSort: true,
                toolTip: false,
                isHidden: false,
                width: "auto",
                filter: { type: "TextFilter", placeholder: "Client ID", style: { font: "bold" } } as TextFilter
            },
            {
                header: "Tax Year",
                key: "taxYear",
                isKey: false,
                dataFormat: this.defaultType,
                columnClassName: "",
                dataSort: true,
                toolTip: true,
                isHidden: false,
                width: "85px",
                filter: {
                    type: "CustomFilter",
                    getElement: this.getYearMultiSelectDropDown,
                    customFilterParameters: {
                        options: this.taxYearList,
                        enableAllFilter: true,
                        placeholder: "Select Year..."
                    } as any
                } as CustomFilter
            },
            {
                header: "ERO / Signer",
                key: "partner",
                isKey: false,
                dataFormat: this.defaultType,
                columnClassName: "",
                dataSort: true,
                toolTip: false,
                isHidden: false,
                width: "auto",
                filter: { type: "TextFilter", placeholder: "ERO / Signer", style: { font: "bold" } } as TextFilter
            },
            {
                header: "Office Location",
                key: "locationName",
                isKey: false,
                dataFormat: this.defaultType,
                columnClassName: "",
                dataSort: true,
                toolTip: false,
                isHidden: false,
                width: "130px",
                filter: {
                    type: "CustomFilter",
                    getElement: this.getOfficeSelectDropDown,
                    customFilterParameters: {
                        options: this.state.officeLocationList,
                        enableAllFilter: false,
                        placeholder: "Select Office Location..."
                    } as any
                } as CustomFilter
            },
            {
                header: "Sent By",
                key: "deliveredBy",
                isKey: false,
                dataFormat: this.defaultType,
                columnClassName: "",
                dataSort: true,
                toolTip: false,
                isHidden: false,
                width: "auto",
                filter: { type: "TextFilter", placeholder: "Sent By", style: { font: "bold" } } as TextFilter
            },
            {
                header: "Delivered Date",
                key: "deliveredDate",
                isKey: false,
                dataFormat: this.defaultType,
                columnClassName: "",
                dataSort: true,
                toolTip: false,
                isHidden: false,
                width: "auto",
                filter: {
                    type: "SelectFilter",
                    placeholder: "Select Delivered Date Limit",
                    options: DaysRangeOptionsList
                } as SelectFilter
            },
            {
                header: "Status",
                key: "status",
                isKey: false,
                dataFormat: this.signatureStatusIdType,
                columnClassName: "",
                dataSort: true,
                toolTip: this.customSignatureStatusTooltip,
                isHidden: false,
                width: "auto",
                Placeholder: "Status",
                filter: {
                    type: "CustomFilter",
                    getElement: this.getSignatureStatusMultiSelectDropDown,
                    customFilterParameters: {
                        options: this.SignatureStatusOptionsList,
                        enableAllFilter: false,
                        placeholder: "Select Status..."
                    } as any
                } as CustomFilter
            },
            {
                header: "Filing Type",
                key: "documentFilingType",
                isKey: false,
                dataFormat: this.defaultType,
                columnClassName: "",
                dataSort: true,
                toolTip: this.customFilingTypeTooltip,
                isHidden: this.props.isPaperFiledEnabled ? false : true,
                width: "auto",
                Placeholder: "Filing Type",
                filter: {
                    type: "SelectFilter",
                    placeholder: "Select Filing Type...",
                    options: DocumentFilingTypeOptionsList
                } as SelectFilter
            },
            this.getStatusColumn()
        ];
    };

    getStatusColumn = () => {
        switch (this.props.selectedQuickReport) {
            case QuickReportType.LinkNotAccessed:
                return {
                    header: "Link Status",
                    key: "linkStatus",
                    isKey: false,
                    dataFormat: this.linkStatusIdType,
                    columnClassName: "",
                    dataSort: true,
                    toolTip: this.customStatusTooltip,
                    isHidden: false,
                    width: "190px",
                    Placeholder: "Link Status",
                    filter: {
                        type: "CustomFilter",
                        getElement: this.getStatusMultiSelectDropDown,
                        customFilterParameters: {
                            options: this.LinkStatusOptionsList,
                            enableAllFilter: false,
                            placeholder: "Select Status..."
                        } as any
                    } as CustomFilter
                };
            case QuickReportType.KBAFailed:
                return {
                    header: "KBA Status",
                    key: "kbaStatus",
                    isKey: false,
                    dataFormat: this.kbaStatusIdType,
                    columnClassName: "",
                    dataSort: true,
                    toolTip: this.kbaCustomStatusTooltip,
                    isHidden: false,
                    width: "190px",
                    Placeholder: "KBA Status",
                    filter: {
                        type: "CustomFilter",
                        getElement: this.getStatusMultiSelectDropDown,
                        customFilterParameters: {
                            options: this.KBAStatusOptionsList,
                            enableAllFilter: false,
                            placeholder: "Select Status..."
                        } as any
                    } as CustomFilter
                };
        }
    };
    renderShowsTotal(start: number, to: number, total: number) {
        return (
            <p>
                Showing {start} to {to} of {total} entries
            </p>
        );
    }

    onFilterChange = (dataField: any) => {
        if (!this.filterChanged) {
            this.filterChanged = true;
            this.props.onFilterChange(dataField);
            this.filterChanged = false;
        }
    };

    onClearFilter(callback?: () => any) {
        this.filterChanged = true;
        this.props.onClearFilter();
        let date = new Date().getFullYear() - 1;
        (this.refs.taxDocumentName as TableHeaderColumn)?.cleanFiltered();
        (this.refs.clientId as TableHeaderColumn)?.cleanFiltered();
        (this.refs.partner as TableHeaderColumn)?.cleanFiltered();
        (this.refs.deliveredBy as TableHeaderColumn)?.cleanFiltered();
        (this.refs.deliveredDate as TableHeaderColumn)?.cleanFiltered();
        (this.customStatusMultiSelect as CustomMultiSelect)?.cleanFilteredNoNotify();
        (this.customOfficeMultiSelect as CustomMultiSelect)?.cleanFilteredNoNotify();
        (this.customTaxYearMultiSelect as CustomMultiSelect)?.applyFilterNoNotify(date);
        (this.signatureStatusMultiSelect as CustomMultiSelect)?.cleanFilteredNoNotify();
        (this.refs.documentFilingType as TableHeaderColumn)?.cleanFiltered();
        this.filterChanged = false;
        callback && callback();
    }

    onSelectQuickReports = (quickReportType: QuickReportType) => {
        this.onClearFilter(() => {
            this.props.onSelectQuickReports(quickReportType);
        });
    };

    setNoContent = () => {
        if (this.props.isLoading) {
            return (
                <LoadingOverlay style={{ height: "400px" }}>
                    <Loader loading={this.props.isLoading} />
                </LoadingOverlay>
            );
        } else {
            return DeliveredReturnsTableConstants.OtherMessage.NoReturnsFound;
        }
    };

    private createCustomToolBar = () => {
        return (
            <div className="col-sm-12 col-md-12 col-lg-12" style={{ height: 33, position: "unset" }}>
                <QuickReportToolbar
                    onPageReload={this.props.onPageReload}
                    refreshDelay={this.props.refreshDelay}
                    onExportToExcel={this.props.onExportToExcel}
                    onSelectQuickReports={this.onSelectQuickReports}
                    selectedQuickReport={this.props.selectedQuickReport}
                    closeQuickReport={this.props.closeQuickReport}
                />
            </div>
        );
    };
    createCustomCheckbox = (props: CustomSelectProps): any => {
        return (
            <CheckBoxComponent
                size={CheckBoxSize.sm}
                id={"quick-reports-returns-checkbox-" + props.rowIndex}
                indeterminate={props.indeterminate}
                checked={props.checked}
                disabled={props.disabled}
                onChange={(e: any) => props.onChange(e, props.rowIndex)}
                ref={(input: any) => {
                    if (input) {
                        input.indeterminate = props.indeterminate;
                    }
                }}
                text={""}
            />
        );
    };

    private showBulkAlertMessage() {
        let documentsInPage = this.props.quickReports.quickReportTableModel.documents.length;
        if (!this.props.isArchived && this.props.totalRows > 20 && this.props.showBulkSelectionMessage)
            return (
                <div className="font14" style={{ position: "absolute", display: "inline-block", zIndex: 10, padding: "0px 0px" }}>
                    <Alert variant="warning" style={{ padding: "6px", width: "fit-content", margin: "auto" }}>
                        <span className="fa fa-exclamation-triangle" style={{ marginRight: "5px", paddingLeft: "5px" }}></span>{" "}
                        All {documentsInPage} records on this page are selected. To select the remaining{" "}
                        {this.props.totalRows - documentsInPage} filtered records,
                        <Link to={"#"} onClick={() => this.props.onBulkSelectionChange(true)}>
                            {" "}
                            click here
                        </Link>
                    </Alert>
                </div>
            );
    }
    public render() {
        let columns = this.getColumns();
        const options = {
            onSortChange: this.props.onSortChange,
            onPageChange: this.props.onPageChange,
            sizePerPage: this.props.pageSize,
            onSearchChange: this.props.onSearchChange,
            page: this.props.pageNo,
            paginationShowsTotal: this.renderShowsTotal,
            onFilterChange: this.onFilterChange,
            noDataText: this.setNoContent(),
            toolBar: this.createCustomToolBar,
            clearSearch: true,
            nextPage: <span className="fa fa-angle-right" />,
            prePage: <span className="fa fa-angle-left" />,
            firstPage: <span className="fa fa-angle-double-left" />,
            lastPage: <span className="fa fa-angle-double-right" />
        };

        let quickReportType = this.props.quickReports.quickReportTableModel.quickReportType;
        const data = this.props.quickReports.quickReportTableModel.documents.map((model, index) => {
            return {
                taxDocumentName: getClientName(model.document),
                clientId: model.document.clientId,
                partner: model.document.partner.lastName + ", " + model.document.partner.firstName,
                locationName: model.document.location?.locationName,
                deliveredBy:
                    model.document.deliveredByUser == undefined
                        ? ""
                        : model.document.deliveredByUser.lastName + ", " + model.document.deliveredByUser.firstName,
                deliveredDate: moment(model.document.deliveredOn).format("MM/DD/YYYY"),
                linkStatusIdType: model.linkStatus,
                kbaStatusIdType: model.kbaStatus,
                index: (this.props.pageNo - 1) * this.props.pageSize + index,
                id: model.document.id,
                documentKey: model.document.documentGuid,
                taxYear: model.document.taxYear,
                signatureStatusIdType: model.document.signatureStatus,
                rowIndex: index,
                documentFilingType: this.customFilingType(model.documentFilingType),
                signedCount: model.signedCount
            };
        });
        let selected: number[] = [];

        if (this.props.selectedRows.length > 0) {
            for (var i in this.props.selectedRows) {
                let rowIndex = this.props.selectedRows[i];
                selected.push((this.props.pageNo - 1) * this.props.pageSize + rowIndex);
            }
        }
        let selectRowProp: any = {
            mode: "checkbox",
            clickToSelect: true,
            onSelect: this.props.onRowSelect,
            onSelectAll: this.props.onSelectAll,
            selected: selected,
            customComponent: this.createCustomCheckbox,
            className: "row-selected"
        };
        return (
            <div className="pos-relative" data-test-auto="F2F09091-BC4E-4E0C-B82D-059263D25338">
                {this.showBulkAlertMessage()}
                <BootstrapTable
                    ref="table"
                    data={data}
                    options={options}
                    remote={true}
                    striped
                    hover={true}
                    pagination={true}
                    fetchInfo={{ dataTotalSize: this.props.totalRows }}
                    trStyle={{ height: "30px" }}
                    selectRow={selectRowProp}
                >
                    {columns.map((value: any, index: any) => {
                        return (
                            <TableHeaderColumn
                                key={index}
                                ref={value.key}
                                isKey={value.isKey}
                                dataField={value.key}
                                hidden={value.isHidden}
                                width={value.width}
                                dataFormat={value.dataFormat}
                                columnClassName={value.columnClassName}
                                columnTitle={value.toolTip}
                                filter={value.filter}
                                dataSort={value.dataSort}
                            >
                                {" "}
                                {value.width === "auto" ? (
                                    <span title={value.header} className="table-text-sort">
                                        {value.header}
                                    </span>
                                ) : (
                                    value.header
                                )}
                            </TableHeaderColumn>
                        );
                    })}
                </BootstrapTable>
            </div>
        );
    }
}

export default QuickReportsTable;
