import * as React from 'react';
import Select from 'react-select';
import * as Helper from '../helper/HelperFunctions';

interface DropdownData {
	value: number;
	label: string;
}

const defaultSelection = (new Date().getFullYear() - 1).toString();

export class CustomMultiSelect extends React.Component<any, any>{
    constructor(props: any) {
        super(props);
        this.state = {
            selectedOptions: (this.props.isSignatureReports === undefined ? (this.props.selectedOptions != undefined ? this.props.selectedOptions : (new Date().getFullYear() - 1).toString()) :
                (this.props.isSignatureReports === "True" ? this.props.selectedOptions != undefined ? this.props.selectedOptions : (new Date().getFullYear()).toString() :
                    this.props.selectedOptions != undefined ? this.props.selectedOptions : (new Date().getFullYear() - 1).toString())),
            options: []
		}

		this.applyFilter = this.applyFilter.bind(this);
		this.waitSet = this.waitSet.bind(this);
		this.waitClean = this.waitClean.bind(this);
    }

    componentDidMount() {
        this.dropDownDataCreation();
    }

    // method prevents the value of Tax year dropdown from getting over ridden during update --- change if problem arises
    componentDidUpdate(prevProps: any) {
        if (this.props.options !== prevProps.options) {
            this.dropDownDataCreation();
        }
	}

	componentWillReceiveProps(nextProps: any) {
		var dropDownData: any = [];
		var data = { value: 0, label: "All" }
		if (nextProps.enableAllFilter) {
			dropDownData.push(data);
		}
		nextProps.options.map((data: any) => {
			dropDownData.push(data);
		});

		var selectedOption = this.state.selectedOptions;
		if (nextProps.selectedOptions != this.props.selectedOptions || (selectedOption == defaultSelection && nextProps.selectedOptions && nextProps.selectedOptions.length > 0)) {
			selectedOption = nextProps.selectedOptions;
		}

		this.setState({ options: dropDownData, selectedOptions: selectedOption });
	}

    // Common method segrigation for componentDidMount and componentDidMount
    private dropDownDataCreation = () => {
        this.props.onRef(this);
        var dropDownData: any = [];
        var data = { value: 0, label: "All" }
        if (this.props.enableAllFilter) {
            dropDownData.push(data);
        }
        this.props.options.map((data: any) => {
            dropDownData.push(data);
        });
        this.setState({ options: dropDownData });
    }

	waitClean() {
		return new Promise((res) => {
			this.setState({
				selectedOptions: []
			}, () => {

				this.props.filterHandler("-1");
				res('done');
			});


		})

	}


	public cleanFiltered = async (clearAll?: boolean) => {
    //temporary fix to resolve setState implementation by promises--55813
		if (clearAll !== undefined) {
			await this.waitClean()
			this.setState({
				selectedOptions: []
			}, () => { this.props.filterHandler("-1"); });

		}
		else {
			this.setState({
				selectedOptions: ""
			}, () => { this.props.filterHandler("-1"); });

		}



	}

	waitSet(value: any) {
		return new Promise((res) => {
			this.setState({
				selectedOptions: value
			}, () => {
				res("done");
				this.props.filterHandler(value);
			});
		})

	}


	
	async applyFilter(value: any) {
		//temporary fix to resolve setState implementation by promises--55813
		if (this.props.filterType && this.props.filterType === "TaxYear" && (value !== undefined && value.length>1)) {
			let tempTaxYear = value;
			tempTaxYear=tempTaxYear.split(",");

			let lastYear = new Date().getFullYear() - 1;
			if (tempTaxYear.includes("0") && tempTaxYear.length > 1 && !tempTaxYear.includes('' + lastYear)) {
				value = Helper.AllTaxYearSelectedString();
				this.props.onFilterUpdate && this.props.onFilterUpdate();	
			}
        }
		

		await this.waitSet(value);
		this.setState({
			selectedOptions: value
		}, () => { this.props.filterHandler(value); });

		
		
	}

    private handleAllSelected = (value: any): string => {
        var checkedValues = value.split(',');

		if (this.props.enableAllFilter) {
			if (checkedValues.includes('0')) {
				checkedValues = this.selectAll();
			}
			else if (this.state.selectedOptions.length > 0) {
				if (this.state.selectedOptions.split(',').includes('0'))
					checkedValues = "";
				else if (checkedValues.length == this.props.options.length) {
					checkedValues = this.selectAll();
				}
			}

		}
		return checkedValues.toString();
	}

    private selectAll = (): string => {
        var all = "0";
        var output: string = this.props.options.reduce((output: string, option: any) => {
            output = output + "," + option.value;
            return output
        }, "")
        output = all + output;
        return output;
    }

	private handleSelectChange(value: any) {

		var selectedValue = this.handleAllSelected(value);
		this.setState({ selectedOptions: selectedValue }, () => {
			if (!value || value.length === 0) {
				if (this.props.setClearFilter !== undefined) {
					this.props.setClearFilter();
				}
				this.props.filterHandler("-1");//if the dropdrop is cleared let the table know something has triggered,table filter will not trigger if the value is empty
			}
			else {
				this.props.filterHandler(this.state.selectedOptions);

			}

		});

	}



	public disableOptions(option: any): boolean {
		var isDisabled: boolean = false;
		if (this.state.selectedOptions.length > 0) {
			if (this.state.selectedOptions.split(',').includes('0') && option != "All") {
				isDisabled = true;
			}

		}
		return isDisabled;
	}

	public cleanFilteredNoNotify = () => {
		this.setState({
			selectedOptions: []
		});

	}

	public applyFilterNoNotify = (value: any) => {
		this.setState({
			selectedOptions: value
		});

	}

	private createCustomOption = (props: any, handleTextOverflow: boolean) => {
		return (
			<div
				style={{ paddingLeft: '5px', verticalAlign: 'middle' }}
				className={ handleTextOverflow ? "checkbox-text-container select-menu-overflowtext" : "checkbox-text-container"}
				onMouseDown={(e) => {
					e?.preventDefault();
					e?.stopPropagation();
					props.onSelect(props.option, e);
				}}
				onMouseEnter={(e) => { props.onFocus(props.option, e); }}
				onMouseMove={(e) => {
					if (props.isFocused) return;
					props.onFocus(props.option, e);
				}}
			>
				<input type="checkbox" checked={(props.isSelected) ? true : false} disabled={this.disableOptions(props.children)} />
				<span className="custom-multiselect-status" title={props.children}>
					{props.children}
				</span>
			</div>
		)
	}
	private createCustomTag = (props: any) => {
		const { selectedOptions } = this.state;
		let nbValues = (selectedOptions).split(',').includes('0') ? 'All' : (selectedOptions).split(',').length;
		return (
			<span>
				{`[${nbValues}]`}
			</span>
		);
	}

	render() {
		const self = this;
		return (
			<Select ref='OptionsSelect'
			className={(this.props.isMultiSelectCheckbox) ? "filter select-filter multi-select-control" : this.props.enableAllFilter ? "filter select-filter multi-select-width100" : "filter select-filter multi-select-widthauto"}
				options={this.state.options}
				onChange={(e: any) => { this.handleSelectChange(e); }}
				value={this.state.selectedOptions}
				optionComponent={(options: any) => { return this.createCustomOption(options,self.props.handleTextOverflow); }}
				valueComponent={this.createCustomTag}
				removeSelected={false}
				multi
				simpleValue
				searchable={false}
				backspaceRemoves={false}
				placeholder={<span className="filter select-filter placeholder-selected">{this.props.placeholder}</span>}
			/>
		)
	}
}
