import * as React from 'react';
import { Card, Accordion } from 'react-bootstrap';
import { INotificationSettings } from './MySettings';
import { CheckBoxComponent } from '../../common/CheckBoxComponent';
import { Link } from 'react-router-dom';
import { fetchHelpText } from '../../helper/HelperFunctions';
import { IHelptextModel } from '../../../Core/ViewModels/Common/IHelptextModel';
import { IsOnlySignatureUser } from './../../../oidcClient/userRoles';
import { IAuthState } from './../../../store/auth/reducer';

interface Props {
    auth: IAuthState
    NotificationSettings: INotificationSettings;
    onChangeReadyForProcessing(event: any): void;
    onChangeRAssigneToMe(event: any): void;
    onChangeApprovedForDelivery(event: any): void;
    onChangePaymentNotification(event: any): void;
    isCompanyPaymentNotificationEnabled: boolean;
    helptexts?: IHelptextModel[];
}
interface States {
    open: boolean;
    helptextPaymentNotification: string;
}

export class MyNotifications extends React.Component<Props, States> {
    isSignatureOnlyUser = IsOnlySignatureUser(this.props.auth.user);
    state = {
        open: false,
        helptextPaymentNotification: ''
    };

    UNSAFE_componentWillMount() {
        this.setState({
            helptextPaymentNotification: fetchHelpText(this.props.helptexts, "678C3825-F8A9-4CB2-9B5C-DE4E09332A26")
        });
    }

    public render() {
        return (
            <Accordion defaultActiveKey="0">
                <Card id="collapsible-panel-my-notifications" className="marB20">
                    <Accordion.Toggle eventKey="0">
                        <Card.Header style={{ paddingTop: '8px', paddingBottom: '5px' }}>
                            <Card.Title>
                                <i className='fa fa-bell'></i>My Notifications
                </Card.Title>
                        </Card.Header>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                        <Card.Body>
                            <div className="marB10">
                                <span>Notifications I Receive:</span>
                                <a href="#" className="help-icon messages-help"
                                    data-toggle="tooltip"
                                    data-placement="right"
                                    title="You will send an email notification when any of the selected events occur."
                                    >
                                    <i className="fas fa-question-circle" />
                                </a>
                            </div>
                            <div className="row">
                                <div className="col-sm-6 col-md-5 col-xl-5">
                                    <div className={`marB10 ${(this.isSignatureOnlyUser) ? "disabled" : ""}`}>
                                        <CheckBoxComponent
                                            id={"ReturnReady"}
                                            text={"Upload is ready for Processing"}
                                            onChange={this.props.onChangeReadyForProcessing}
                                            checked={this.props.NotificationSettings.isReturnReady}
                                            datatestAuto={"9E525B09-42B3-4676-8360-DD98528F9ED9"}
                                            disabled={this.isSignatureOnlyUser}
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-6 col-md-5 col-xl-5">
                                    <div className={`marB10 ${(this.isSignatureOnlyUser) ? "disabled" : ""}`}>
                                        <CheckBoxComponent
                                            id={"ReturnAssigned"}
                                            text={"Return Assigned to Me"}
                                            onChange={this.props.onChangeRAssigneToMe}
                                            checked={this.props.NotificationSettings.isReturnAssigned}
                                            datatestAuto={"B51DA090-1BF9-4E99-9CCC-B73EED6D6BD3"}
                                            disabled={this.isSignatureOnlyUser}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-6 col-md-5 col-xl-5">
                                    <div className={`marB10 ${(this.isSignatureOnlyUser) ? "disabled" : ""}`}>
                                        <CheckBoxComponent
                                            id={"ApprovedForDelivery"}
                                            text={"Approved for Delivery"}
                                            onChange={this.props.onChangeApprovedForDelivery}
                                            checked={this.props.NotificationSettings.isApprovedForDelivery}
                                            datatestAuto={"754C4129-39A1-4D29-B01A-1539873CB4D3"}
                                            disabled={this.isSignatureOnlyUser}
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-6 col-md-5 col-xl-5">
                                    <div className="marB10">
                                        <div style={{ display: "flex" }}>
                                            <CheckBoxComponent
                                                id={"UserPaymentNotification"}
                                                text={"When client adds payment information"}
                                                onChange={this.props.onChangePaymentNotification}
                                                checked={this.props.NotificationSettings.paymentNotification}
                                                disabled={!this.props.isCompanyPaymentNotificationEnabled}
                                            />
                                            <a href="#" className="help-icon upload-help"
                                                data-toggle="tooltip"
                                                data-placement="right"
                                                title={this.state.helptextPaymentNotification}>
                                                <i className="fas fa-question-circle" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        );
    }
}

export default MyNotifications;