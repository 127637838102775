import * as React from "react";
import { Modal, Row, Col, Button, Form, Alert } from "react-bootstrap";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { Link } from "react-router-dom";
let moment = require("moment");
import { OverlayLoader } from "../../helper/OverlayLoader";
import { IDeliveredTaxReturns } from "../DeliveredTaxReturns";

interface IGroupedReturnViewAccessCodeProps {
    model: IDeliveredTaxReturns;
    onCancel(): void;
    show: boolean;
    onGenerateOTP(groupId: number, documentId: number, clientGUID: string, callback: () => void): void;
}

interface ColumnNames {
    header: string;
    key: string;
    isKey: boolean;
    dataFormat: any;
    width: string;
    toolTip: boolean;
}
interface IColumnValues {
    signersName: string;
    createdOn: Date;
    OTP: string;
    status: string;
    groupId: number;
    clientGUID: string;
    date: Date;
    documentId: number;
}

const msg = {
    saving: "Generating OTP...",
    loading: "Loading, please wait..."
};

interface IGroupedReturnViewAccessCodeState {
    message: string;
    isOtpGenerated: boolean;
    alert: string;
    hideAlert: boolean;
    alertMessage: string;
}

const successMsg = {
    generatedAccessCode: "Access code generated successfully"
};

export class GroupedReturnViewAccessCode extends React.Component<
    IGroupedReturnViewAccessCodeProps,
    IGroupedReturnViewAccessCodeState
> {
    constructor(props: IGroupedReturnViewAccessCodeProps) {
        super(props);
        this.state = {
            message: msg.loading,
            isOtpGenerated: false,
            alert: "success",
            hideAlert: true,
            alertMessage: ""
        };
        this.defaultType = this.defaultType.bind(this);
        this.generateOTPButton = this.generateOTPButton.bind(this);
        this.onGenerateOTP = this.onGenerateOTP.bind(this);
        this.getDifferenceInDate = this.getDifferenceInDate.bind(this);
    }
    UNSAFE_componentWillReceiveProps(props: IGroupedReturnViewAccessCodeProps) {
        if (!props.show) {
            this.setState({ message: msg.loading });
        }
    }
    UNSAFE_componentWillUnmount() {
        this.setState({ isOtpGenerated: false, hideAlert: true });
    }

    private defaultType(cell: any, row: IColumnValues) {
        return cell;
    }

    private accessCodeDataFormat(cell: any, row: IColumnValues) {
        if (row.OTP != "NA") {
            return this.getDifferenceInDate(row.createdOn) > 20 ? "NA" : row.OTP;
        } else {
            return "NA";
        }
    }

    private statusDataFormatType = (cell: any, row: IColumnValues) => {
        if (row.OTP != "NA") {
            return this.getDifferenceInDate(row.createdOn) > 20 ? "Expired" : "Active";
        }
        return "NA";
    };

    private createdOnDataFormat = (cell: any, row: IColumnValues) => {
        return !row.createdOn || this.getDifferenceInDate(row.createdOn) > 20
            ? "NA"
            : moment.utc(row.createdOn).local().format("MM/DD/YYYY hh:mm:ss A");
    };
    private generateOTPButton(cell: any, row: IColumnValues) {
        return (
            <Link
                to={"#"}
                onClick={(event) => {
                    this.onGenerateOTP(row);
                }}
            >
                {" "}
                {"Generate Access Code"}
            </Link>
        );
    }
    _toolTip = (cell: any, row: any) => {
        let accessAction;
        this.props.model.groupedReturnOTPViewModel.map((value, index) => {
            accessAction = this.getDifferenceInDate(value.createdOn) > 20 ? "Generate Access Code" : "";
        });
        return `${accessAction}`;
    };
    private onGenerateOTP(row: IColumnValues) {
        this.setState({ message: msg.saving, isOtpGenerated: true, hideAlert: true }, () => {
            this.props.onGenerateOTP(row.groupId, row.documentId, row.clientGUID, this.triggerAlert);
        });
    }

    private getDifferenceInDate(createdDate: Date) {
        let date1: any = new Date();
        let date2: any = moment.utc(createdDate).toDate();
        let minute = (date1.getTime() - date2.getTime()) / 60000;
        return minute;
    }
    private onCancel = () => {
        this.setState({ isOtpGenerated: false, hideAlert: true });
        this.props.onCancel();
    };
    private triggerAlert = () => {
        var self = this;
        this.setState({ hideAlert: false, alertMessage: successMsg.generatedAccessCode, alert: "success" });
        //To reflect the generated OTP in UI
        setTimeout(function () {
            self.setState({
                hideAlert: false
            });
        }, 2000);
    };

    public render() {
        let viewAccessCodeData: any[] = [];
        const viewAccessCodeColumn = [
            {
                header: "Signer's Name",
                key: "signersName",
                isKey: false,
                dataFormat: this.defaultType,
                toolTip: true,
                columnClassName: "word-Visible"
            },
            {
                header: "Date",
                key: "createdOn",
                isKey: true,
                dataFormat: this.createdOnDataFormat,
                toolTip: true,
                columnClassName: ""
            },
            {
                header: "Access Code",
                key: "OTP",
                isKey: false,
                dataFormat: this.accessCodeDataFormat.bind(this),
                toolTip: true,
                columnClassName: ""
            },
            {
                header: "Status",
                key: "status",
                isKey: false,
                dataFormat: this.statusDataFormatType,
                toolTip: true,
                columnClassName: ""
            },
            {
                header: "Action",
                key: "action",
                isKey: false,
                dataFormat: this.generateOTPButton.bind(this),
                toolTip: this._toolTip,
                columnClassName: ""
            }
        ];

        let minDate = new Date();
        minDate.setFullYear(1, 0, 1);
        minDate.setHours(0, 0, 0, 0);

        if (this.props.model && this.props.model) {
            viewAccessCodeData = this.props.model.groupedReturnOTPViewModel.map((value, index) => {
                return {
                    signersName: value.name,
                    createdOn:
                        Date.parse(new Date(value.createdOn).toString()) == Date.parse(minDate.toString())
                            ? null
                            : value.createdOn,
                    OTP: !value.otp ? "NA" : value.otp,
                    groupId: value.groupId,
                    clientGUID: value.clientGuid,
                    date: value.createdOn,
                    documentId: this.props.model.id
                };
            });
        }
        let modalBody =
            this.props.model && this.props.model ? (
                <BootstrapTable headerStyle={{ background: "lightgray" }} data={viewAccessCodeData}>
                    {viewAccessCodeColumn.map((value, index) => {
                        return (
                            <TableHeaderColumn
                                key={index}
                                isKey={value.isKey}
                                dataField={value.key}
                                dataFormat={value.dataFormat}
                                columnClassName={value.columnClassName}
                                columnTitle={value.toolTip}
                            >
                                {value.header}
                            </TableHeaderColumn>
                        );
                    })}
                </BootstrapTable>
            ) : (
                <OverlayLoader show={this.props.show} text={this.state.message} width="94%" />
            );
        return (
            <div>
                <Modal
                    className="view-access-code-modal"
                    show={this.state.isOtpGenerated ? true : this.props.show}
                    onHide={this.onCancel}
                >
                    <Modal.Header closeButton onClick={this.onCancel} data-test-auto="C98D7824-8C63-4A74-95E6-4D3C73B08677">
                        <Modal.Title className="custom-modal-header">
                            <span className="text-secondary fas fa-clock" style={{ color: "grey", marginRight: "5px" }}></span>
                            Access Code
                        </Modal.Title>
                    </Modal.Header>
                    <Form>
                        <Modal.Body>
                            <Alert variant={this.state.alert} hidden={this.state.hideAlert}>
                                <i className="fas fa-exclamation-triangle" style={{ marginRight: "5px" }}></i>
                                {this.state.alertMessage}
                            </Alert>
                            {modalBody}
                        </Modal.Body>
                    </Form>
                    <Modal.Footer>
                        <Row style={{ display: "contents" }}>
                            <Col className="access-code-note" sm={8}>
                                Note: Generating a new code will not send a new email
                            </Col>
                            <Col sm={4}>
                                <Button
                                    style={{ float: "right" }}
                                    variant="default"
                                    data-test-auto="DAE73B5C-9114-46EB-BDE7-0EC8BA0D4B0D"
                                    onClick={this.onCancel}
                                >
                                    <i className="fas fa-times"></i>Cancel
                                </Button>
                            </Col>
                        </Row>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

export default GroupedReturnViewAccessCode;
