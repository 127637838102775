import * as React from 'react';
import { Link } from 'react-router-dom';
import { ICompanySettings } from '../../../Core/ViewModels/Company/CompanySettingsViewModel';
import { CheckBoxComponent } from '../../common/CheckBoxComponent';
import { IHelptextModel } from '../../../Core/ViewModels/Common/IHelptextModel';
import { fetchHelpText } from '../../helper/HelperFunctions';

interface IDeliveryOptions {
    companySettings: ICompanySettings;
    handleAllowAnyUsertoSendDelivery(event: any): void;
    handleAllowUserToDistributeK1(event: any): void;
    helptexts?: IHelptextModel[];
}
export class ReturnsDeliveryOptions extends React.Component<IDeliveryOptions>{
    
    public render() {
        return <div id="divReturnsDeliveryOptions" className="new-main-content txt-normal">
            <h3>Delivery Options</h3>
            <div className="marL20" style={{ display:"flex" }}>
                <CheckBoxComponent
                    id={"AllowSendForDelivery"}
                    text={"Allow the Staff user group to deliver Tax Returns"}
                    checked={this.props.companySettings.userPrivilegeSettingsModel.allowAnyUsertoSendDelivary}
                    onChange={this.props.handleAllowAnyUsertoSendDelivery}
                    datatestAuto={"754F3E87-4F74-47C9-B5AD-DE48519E1A43"}
                />

                <Link to={'#'} className="help-icon marL05" data-placement="right" data-toggle="tooltip" title={fetchHelpText(this.props.helptexts, "9CC3AF90-127A-43DD-A514-16C14380FF84")}>
                    <i className="fas fa-question-circle"></i>
                </Link>
            </div>
            <div className="marL20" style={{ display: "flex" }}>
                <CheckBoxComponent
                    id={"AllowuserToDistributeK1"}
                    text={"Allow any internal user to Distribute K1's"}
                    checked={this.props.companySettings.userPrivilegeSettingsModel.allowUserToDistributeK1}
                    onChange={this.props.handleAllowUserToDistributeK1}
                    datatestAuto={"5AB11751-F100-41B2-AD17-F90362E6EC9D"}
                />

                <Link to={'#'} className="help-icon marL05" data-placement="right" data-toggle="tooltip" title={fetchHelpText(this.props.helptexts, "6FCD3155-6258-4ECB-A799-B0A8B20C4E84")}>
                    <i className="fas fa-question-circle"></i>
                </Link>
            </div>
        </div>
    }
}

export default ReturnsDeliveryOptions;