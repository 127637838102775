import { connect } from 'react-redux';
import { ApplicationState } from '../../../store';
import * as VoucherReminderManagementStore from '../../../store/reports/VoucherReminderManagement/VoucherReminderManagementStore';
import * as UserStore from '../../../store/userManagement/UserStore';
import * as Component from './VoucherReminderReport';

export default connect(
    (state: ApplicationState) => ({
        voucherReminderReport: state.voucherReminderReport,
        userResources: state.userResources
    }),
    {
        ...VoucherReminderManagementStore.actionCreators,
        ...UserStore.actionCreators
    }
)(Component.VoucherReminderReport as any);

