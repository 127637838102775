import { addTask } from 'domain-task';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../../';
import { EngagementType } from '../../../components/common/TaxReturn';
import { handleResponse, extractDocumentIds, handleBlob } from '../../Library';
import * as Constants from '../../../components/helper/Constants';
import { actionTypes } from '../../../types/ActionTypes';
import { NotificationAction, StatusType } from '../../../store/common/NotificationStore';
import { IDocumentData } from '../../../components/common/ProcessReturnModal/AdditionalEsignDocuments/AdditionalEsignDocument';
import { DisplayDownloadFile } from '../../../components/common/DisplayDownloadFile';
import { API_BASE_URL } from '../../../utils/constants';
import { logger } from '../../../routes/LoggedIn';
import { IBlobFile } from '../../common';

export interface RequestAdditionalEsignDocumentAction {
    type: actionTypes.REQUEST_ADDITIONAL_ESIGN_DOCUMENT;
    message: string;
}

export interface ReceiveAdditionalEsignDocumentUploadLinkAction {
    type: actionTypes.RECEIVE_ADDITIONAL_ESIGN_DOCUMENT_LINK;
    sasUrl: string;
    documentGuid: string;
}

const unloadedState: IBlobFile =
    {
        sas: '',
        guid: '',
    };

type DispatchAction = RequestAdditionalEsignDocumentAction |
    ReceiveAdditionalEsignDocumentUploadLinkAction;

type KnownAction = DispatchAction | NotificationAction;

export const actionCreators = {
    getAddtionalEsignDocumentUploadLink: (url: string, callback?: (data?: any) => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(API_BASE_URL + url, { credentials: 'include' }).then((resp) => resp.json())
            .then(function (data) {
                if (callback) {
                    data ? callback(data) : callback();
                }
                dispatch({ type: actionTypes.RECEIVE_ADDITIONAL_ESIGN_DOCUMENT_LINK, sasUrl: data.sas, documentGuid: data.guid });
                logger.trackTrace(`Get additional esign document upload link successfully for the url : ${url}`, { "DocumentGuid": data.guid });
            }).catch(error => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: Constants.AdditionalEsignDocuments.fetchDocumentError,
                    statusType: StatusType.Error
                })
                logger.trackWarning(`Error occured while getting AddtionalEsignDocumentUploadLink for the url : ${url}, error: ${error}`);
            });
        addTask(fetchTask);
        dispatch({ type: actionTypes.REQUEST_ADDITIONAL_ESIGN_DOCUMENT, message: Constants.UploadtaxReturnConstants.OverlayMessage.ApplicationLoading });
    },
    getAddtionalEsignDocumentDownLoadLink: (url: string, callback?: (data?: any) => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(API_BASE_URL + url, { credentials: 'include' }).then((resp) => resp.json())
            .then(function (data) {
                if (callback) {
                    data ? callback(data) : callback();
                }
                dispatch({ type: actionTypes.RECEIVE_ADDITIONAL_ESIGN_DOCUMENT_LINK, sasUrl: data.sas, documentGuid: data.guid });
                logger.trackTrace(`Get additional esign document download link successfully for the url : ${url}`, { "DocumentGuid": data.guid });
            }).catch(error => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: Constants.AdditionalEsignDocuments.fetchDocumentError,
                    statusType: StatusType.Error
                })
                logger.trackWarning(`Error occured while getting AddtionalEsignDocumentDownLoadLink for the url : ${url}, error: ${error}`);
            });
        addTask(fetchTask);
        dispatch({ type: actionTypes.REQUEST_ADDITIONAL_ESIGN_DOCUMENT, message: Constants.UploadtaxReturnConstants.OverlayMessage.ApplicationLoading });
    },
    convertDocToPdfArtifactAsync: (url: string, callback?: (data?: any) => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(API_BASE_URL + url, { credentials: 'include' }).then((resp) => resp.json())
            .then(function (data) {
                if (callback) {
                    data ? callback(data) : callback();
                }
                dispatch({ type: actionTypes.RECEIVE_ADDITIONAL_ESIGN_DOCUMENT_LINK, sasUrl: data.sas, documentGuid: data.guid });
                logger.trackTrace(`Additional esign document converted successfully from doc to pdf for the url : ${url}`, { "DocumentGuid": data.guid });
            }).catch(error => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: Constants.AdditionalEsignDocuments.fetchDocumentError,
                    statusType: StatusType.Error
                })
                logger.trackWarning(`Error occured while converting from doc to pdf for the url : ${url}, error: ${error}`);
            });
        addTask(fetchTask);
    },
    deleteUploadedAdditionalDocumentBlobFile: (documentGuid: string, fileName: string, taxYear: number, callback?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(API_BASE_URL + 'api/Upload/DeleteAdditionalEsignDocumentBlobFileAsync/' + documentGuid + '/' + fileName + '/' + taxYear, {
            method: 'DELETE',
            credentials: 'include',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8',
                'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
            }
        })
            .then(handleResponse)
            .then((data) => {
                if (callback) {
                    callback();
                }
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: Constants.AdditionalEsignDocuments.deleteDocument,
                    statusType: StatusType.Success
                });
                logger.trackTrace(`Additional esign document deleted successfully from blob`, { "DocumentGuid": documentGuid, "FileName": fileName, "TaxYear": taxYear?.toString() });
            }).catch(error => {
                const statusMessage: any = error.statusText;
                if (typeof(statusMessage) === "string") {
                    dispatch({
                        type: actionTypes.NOTIFICATION, statusMessage: statusMessage,
                        statusType: StatusType.Error
                    });
                }
                logger.trackWarning(`Error occured while deleting additional esign document from blob, error : ${error}`, { "DocumentGuid": documentGuid, "FileName": fileName, "TaxYear": taxYear?.toString() });
            });
        addTask(fetchTask);
    },

    deleteAdditionalDocument: (documentId: number, fileName: string, callback?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(API_BASE_URL + 'api/Upload/DeleteAdditionalEsignDocumentAsync/' + documentId + '/' + fileName, {
            method: 'DELETE',
            credentials: 'include',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8',
                'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
            }
        })
            .then(handleResponse)
            .then((data) => {
                if (callback) {
                    callback();
                }
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: Constants.AdditionalEsignDocuments.deleteDocument,
                    statusType: StatusType.Success
                });
                logger.trackTrace(`Additional esign document deleted successfully`, { "DocumentId": documentId?.toString(), "FileName": fileName });
            }).catch(error => {
                const statusMessage: any = error.statusText;
                if (typeof(statusMessage) === "string") {
                    dispatch({
                        type: actionTypes.NOTIFICATION, statusMessage: statusMessage,
                        statusType: StatusType.Error
                    });
                }
                logger.trackWarning(`Error occured while deleting additional esign document, error : ${error}` , { "DocumentId": documentId?.toString(), "FileName": fileName });
            });
        addTask(fetchTask);
    },

    downloadAdditionalEsignDocuments: (documentGuid: string, documentId: number, taxYear: number, clientId: string, taxPayerName: string,
        engagementType: EngagementType, additionalEsignDocuments: IDocumentData[],
        isSigned?: boolean, onPageReload?: () => void,
        resourceId:string=""): AppThunkAction<KnownAction> => (dispatch, getState) => {
            let fileName: string = "";
            fetch(API_BASE_URL + 'api/Download/DownloadAdditionalEsignDocumentsAsync', {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'X-Resource-Id':resourceId,
                    'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
                },
                body: JSON.stringify({
                    documentGuid: documentGuid,
                    taxYear: taxYear,
                    id: documentId,
                    clientId: clientId,
                    taxPayerName: taxPayerName,
                    engagementType: engagementType,
                    additionalEsignDocuments: additionalEsignDocuments,
                    isSigned: isSigned
                })
            }).then(response => {
                if (additionalEsignDocuments && additionalEsignDocuments.length > 1) {
                    const contentDisposition = response.headers.get("content-disposition");
                    const fileNameMatch = contentDisposition ? /filename="?([^"]*)"?;/g.exec(contentDisposition) : undefined;
                    if (fileNameMatch && fileNameMatch.length > 1) {
                        fileName = fileNameMatch[1];
                    }
                    return handleBlob(response)
                }
                else {
                    const contentDisposition = response.headers.get("content-disposition");
                    const fileNameMatch = contentDisposition ? /filename="?([^"]*)"?;/g.exec(contentDisposition) : undefined;
                    if (fileNameMatch && fileNameMatch.length > 1) {
                        fileName = fileNameMatch[1];
                    }
                    return response.blob();
                }
            }).then((data) => {
                let displayDownloadFile = new DisplayDownloadFile();
                displayDownloadFile.showFile(data, fileName);
                logger.trackTrace(`Additional esign document downloaded successfully for documentId: ${documentId} and documentGuid: ${documentGuid}`, { "DocumentId": documentId?.toString(), "DocumentGuid": documentGuid });
            }).catch((error: any) => {
                console.log(error);
            });
        }
    };

export const reducer: Reducer<IBlobFile> = (state = unloadedState, incomingAction) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case actionTypes.REQUEST_ADDITIONAL_ESIGN_DOCUMENT:
            return {
                ...unloadedState
            } as IBlobFile;
        case actionTypes.RECEIVE_ADDITIONAL_ESIGN_DOCUMENT_LINK:
            var received = { ...state };
            received.sas = action.sasUrl;
            received.guid = action.documentGuid;
            return received;
    }
    return state;
}