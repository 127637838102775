import * as React from "react";
import {
    CCHReturn,
    CCHReturnState,
    ImportReturns,
    ImportReturnDetails,
    SyncReturnModel,
    PullReturnsFilter
} from "@store/reports/CCHReturns/CCHReturnsState";
import * as CCHReturnStore from "../../store/reports/CCHReturns/CCHReturnStore";
import { VenusNotifier } from "../helper/VenusNotifier";
import { Header } from "./CCHReturns/Header";
import { ImportCCHAxcess } from "../helper/Constants";
import { ReturnsTable } from "./CCHReturns/ReturnsTable";
import { ReturnsTableHeader } from "./CCHReturns/ReturnsTableHeader";
import { SyncConsentModal } from "./CCHReturns/SyncConsentModal";
import { FiltersModal } from "./CCHReturns/FiltersModal";

type CCHReturnProps = {
    returnState: CCHReturnState;
} & typeof CCHReturnStore.actionCreators;

interface CCHReturnComponentState {
    pageNo: number;
    pageSize: number;
    sortBy: string;
    sortOrder: string;
    filter: {
        returnId: string;
        taxYear: string;
        clientId: string;
        clientName: string;
        version: string;
        returnStatus: string;
        importStatus: string;
        returnType: string;
    };
    selectedRows: number[];
    deSelectedRows: string[];
    areEntireReturnsSelected: boolean;
    showBulkSelectionMessage: boolean;
    isImportCompleted: boolean;
    isSyncCompleted: boolean;
    showSyncConsentModal: boolean;
    rowId: number;
    showFiltersModal: boolean;
}

const pageSize: number = 20;
export class CCHReturns extends React.Component<CCHReturnProps, CCHReturnComponentState> {
    constructor(props: CCHReturnProps) {
        super(props);
        this.state = {
            pageNo: 1,
            pageSize: pageSize,
            sortBy: "",
            sortOrder: "",
            filter: {
                returnId: "",
                taxYear: `${new Date().getFullYear() - 1}`,
                clientId: "",
                clientName: "",
                version: "",
                returnStatus: "",
                importStatus: "",
                returnType: ""
            },
            selectedRows: [],
            deSelectedRows: [],
            areEntireReturnsSelected: false,
            showBulkSelectionMessage: false,
            isImportCompleted: true,
            isSyncCompleted: false,
            showSyncConsentModal: false,
            rowId: 0,
            showFiltersModal: false
        };
    }

    UNSAFE_componentWillMount() {
        this.props.getJobStatus();
        this.props.getReturns(this.buildQuery());
        this.props.getConnectionStatus(); 
    }

    componentWillReceiveProps(nextProps: CCHReturnProps) {
        if (this.state.areEntireReturnsSelected) {
            let selectedRows: number[] = [];
            let returns = nextProps.returnState.returns;
            for (let i = 0; i < returns.length; i++) {
                if (returns[i].importStatus === ImportCCHAxcess.Table.statusNew) {
                    if (this.state.deSelectedRows.indexOf(returns[i].returnId) === -1) {
                        selectedRows.push(i);
                    }
                }
            }
            this.setState({
                selectedRows: selectedRows
            });
        }
    } 

    onGetCCHReturns = () => {
        this.setState({
            showFiltersModal: true
        });
    };

    onImport = () => {
        this.setState({
            isImportCompleted: false
        });

        let { selectedRows, areEntireReturnsSelected, deSelectedRows, filter, pageNo } = this.state;
        let { returns } = this.props.returnState;

        let importReturnDetails: ImportReturnDetails[] = [];
        if (!areEntireReturnsSelected) {
            importReturnDetails = selectedRows.map((rowIndex, i) => {
                return {
                    returnId: returns[rowIndex].returnId,
                    taxYear: returns[rowIndex].taxYear
                };
            });
        }

        let importReturns: ImportReturns = {
            isImportAll: areEntireReturnsSelected,
            returnsToImport: importReturnDetails,
            clientId: filter.clientId,
            clientName: filter.clientName,
            returnId: filter.returnId,
            returnType: filter.returnType,
            returnStatus: filter.returnStatus,
            taxYear: filter.taxYear,
            version: filter.version,
            importStatus: filter.importStatus,
            deselectedReturns: deSelectedRows
        };

        this.props.importReturns(importReturns, () => {
            this.setState({
                isImportCompleted: true
            });
            this.onRefresh();
        });
    };

    OnSyncReturn = (rowId: number) => {
        this.setState({
            showSyncConsentModal: true,
            rowId: rowId
        });
    };

    onSyncProceed = () => {
        let returns = this.props.returnState.returns;
        let newRowIndex = this.state.rowId - (this.state.pageNo - 1) * pageSize;

        let syncReturnModel: SyncReturnModel = {
            returnId: returns[newRowIndex].returnId,
            taxYear: returns[newRowIndex].taxYear,
            clientId: returns[newRowIndex].clientId
        };
        this.props.syncReturn(syncReturnModel, () => {
            VenusNotifier.Success(ImportCCHAxcess.TableHeader.importReturnsMsg, null);
            this.setState({
                showSyncConsentModal: false,
                rowId: 0
            });
            this.onRefresh();
        });
    };

    onSyncCancel = () => {
        this.setState({
            showSyncConsentModal: false,
            rowId: 0
        });
    };

    onRefresh = () => {
        this.setState({
            selectedRows: [],
            showBulkSelectionMessage: false,
            areEntireReturnsSelected: false,
            deSelectedRows: []
        });
        this.props.getReturns(this.buildQuery());
        if (!this.props.returnState.isGetCCHReturnsCompleted) this.props.getJobStatus();
    };  

    onSortChange = (sortName: string, sortOrder: string) => {
        let order = sortOrder == "asc" ? "desc" : "asc";
        this.setState(
            {
                sortBy: sortName,
                sortOrder: order,
                selectedRows: [],
                showBulkSelectionMessage: false,
                areEntireReturnsSelected: false,
                deSelectedRows: []
            },
            () => this.props.getReturns(this.buildQuery())
        );
    };

    onPageChange = (page: number, sizePerPage: number) => {
        let selectedRows: number[] = this.state.areEntireReturnsSelected ? this.state.selectedRows : [];
        this.setState(
            {
                pageNo: page,
                selectedRows: selectedRows,
                showBulkSelectionMessage: false
            },
            () => this.props.getReturns(this.buildQuery())
        );
    };

    onFilterChange = (dataField: Record<string, any>) => {
        let newFilterState = {
            clientId: "",
            clientName: "",
            returnId: "",
            returnStatus: "",
            returnType: "",
            version: "",
            importStatus: "",
            taxYear: ""
        };

        let importStatus = "";
        let taxYear = "";
        var dict: { [columnName: string]: string } = {};
        for (let field of Object.keys(dataField)) {
            var data = dataField[field.valueOf()].value ? dataField[field.valueOf()].value : dataField[field.valueOf()];
            dict[field.valueOf().toString()] = data;
            switch (field) {
                case "clientId":
                    newFilterState.clientId = dataField[field].value ? dataField[field].value : dataField[field];
                    break;
                case "clientName":
                    newFilterState.clientName = dataField[field].value ? dataField[field].value : dataField[field];
                    break;
                case "returnId":
                    newFilterState.returnId = dataField[field].value ? dataField[field].value : dataField[field];
                    break;
                case "returnType":
                    newFilterState.returnType = dataField[field].value ? dataField[field].value : dataField[field];
                    break;
                case "returnStatus":
                    newFilterState.returnStatus = dataField[field].value ? dataField[field].value : dataField[field];
                    break;
                case "version":
                    newFilterState.version = dataField[field].value ? dataField[field].value : dataField[field];
                    break;
                case "importStatus":
                    importStatus = dataField[field].value ? dataField[field].value : dataField[field];
                    newFilterState.importStatus = importStatus == "-1" ? "" : importStatus;
                    break;
                case "taxYear":
                    taxYear = dataField[field].value ? dataField[field].value : dataField[field];
                    newFilterState.taxYear = taxYear == "-1" ? "" : taxYear;
                    break;
            }
        }

        if (this.isFilterChanged(newFilterState)) {
            this.setState(
                {
                    filter: newFilterState,
                    pageNo: 1,
                    pageSize: pageSize,
                    selectedRows: [],
                    showBulkSelectionMessage: false,
                    areEntireReturnsSelected: false,
                    deSelectedRows: []
                },
                () => {
                    this.props.getReturns(this.buildQuery());
                }
            );
        }
    };

    isFilterChanged(newFilterState: any): boolean {
        return (
            newFilterState.clientId !== this.state.filter.clientId ||
            newFilterState.clientName !== this.state.filter.clientName ||
            newFilterState.taxYear !== this.state.filter.taxYear ||
            newFilterState.returnId !== this.state.filter.returnId ||
            newFilterState.returnType !== this.state.filter.returnType ||
            newFilterState.returnStatus !== this.state.filter.returnStatus ||
            newFilterState.version !== this.state.filter.version ||
            newFilterState.importStatus !== this.state.filter.importStatus
        );
    }

    buildQuery() {
        return (
            "?PageNo=" +
            this.state.pageNo +
            "&PageSize=" +
            this.state.pageSize +
            "&SortBy=" +
            this.state.sortBy +
            "&SortByDir=" +
            this.state.sortOrder +
            "&TaxYear=" +
            this.state.filter.taxYear +
            "&ClientName=" +
            encodeURIComponent(this.state.filter.clientName) +
            "&ClientId=" +
            encodeURIComponent(this.state.filter.clientId) +
            "&ReturnId=" +
            encodeURIComponent(this.state.filter.returnId) +
            "&ReturnStatus=" +
            encodeURIComponent(this.state.filter.returnStatus) +
            "&ImportStatus=" +
            this.state.filter.importStatus +
            "&Version=" +
            encodeURIComponent(this.state.filter.version) +
            "&ReturnType=" +
            encodeURIComponent(this.state.filter.returnType)
        );
    }

    onRowSelect = (row: CCHReturn, isSelected: boolean, e: any) => {
        if (
            e.target.tagName !== "BUTTON" &&
            e.target.tagName !== "I" &&
            e.target.tagName !== "SPAN" &&
            e.target.tagName !== "A"
        ) {
            let selectedRows = this.state.selectedRows;
            let deSelectedRows: string[] = this.state.deSelectedRows;
            if (isSelected) {
                if (row.importStatus !== ImportCCHAxcess.Table.statusNew) return false;
                selectedRows.push(row.index);

                if (this.state.areEntireReturnsSelected) {
                    index = deSelectedRows.indexOf(row.returnId);
                    deSelectedRows.splice(index, 1);
                }
            } else {
                var index = selectedRows.indexOf(row.index);
                if (index > -1) {
                    selectedRows.splice(index, 1);
                }

                if (this.state.areEntireReturnsSelected) {
                    deSelectedRows.push(row.returnId);
                }
            }
            this.setState({ selectedRows: selectedRows, deSelectedRows: deSelectedRows });
        }
    };

    onSelectAll = (isSelected: boolean, rows: CCHReturn[]) => {
        let selectedRows: number[] = [];
        let showBulkSelectionMessage: boolean = false;

        if (isSelected) {
            selectedRows = rows.reduce((accumulator: number[], row) => {
                if (row.importStatus === "New") {
                    accumulator.push(row.index);
                }
                return accumulator;
            }, []);

            if (this.state.filter.importStatus == "1") {
                showBulkSelectionMessage = true;
            }
        } else {
            showBulkSelectionMessage = false;
        }
        this.setState({
            selectedRows: selectedRows,
            deSelectedRows: [],
            showBulkSelectionMessage: showBulkSelectionMessage,
            areEntireReturnsSelected: false
        });

        return true;
    };

    onBulkSelectionChange = (isEnabled: boolean) => {
        this.setState({
            showBulkSelectionMessage: false,
            areEntireReturnsSelected: isEnabled
        });
    };

    onApplyFilters = (filter: PullReturnsFilter) => {
        let queryString = new URLSearchParams();

        if (filter.clientId) {
            queryString.append("ClientId", filter.clientId);
        }

        if (filter.taxYear && Array.isArray(filter.taxYear)) {
            filter.taxYear.forEach((year) => {
                queryString.append("TaxYear", year.toString());
            });
        }

        if (filter.returnStatus) {
            queryString.append("ReturnStatus", filter.returnStatus);
        }

        if (filter.returnType) {
            queryString.append("ReturnType", filter.returnType);
        }

        if (filter.version) {
            queryString.append("Version", filter.version);
        }

        this.props.pullReturns(queryString.toString());

        this.setState({
            showFiltersModal: false
        });
    };

    onClearAllFilters = () => {};

    onCancelFilters = () => {
        this.setState({
            showFiltersModal: false
        });
    };

    public render() {
        const isLoggedIn =
            this.props.returnState.connectionDetail != null ? this.props.returnState.connectionDetail.status : false;
        const loggedInUserName =
            this.props.returnState.connectionDetail != null ? this.props.returnState.connectionDetail.userName : "";
        return (
            <div className="user-assignment-content" id="divCCHAxcess">
                <Header
                    isLoading={this.props.returnState.headerLoadingIcon}
                    isLoggedIn={isLoggedIn}
                    loggedInUsername={loggedInUserName} 
                ></Header>
                <ReturnsTableHeader
                    isLoading={this.props.returnState.isLoading}
                    selectedRows={this.state.selectedRows}
                    returnsData={this.props.returnState.returns}
                    totalReturns={this.props.returnState.totalReturns}
                    showBulkSelectionMessage={this.state.showBulkSelectionMessage}
                    isLoggedIn={isLoggedIn}
                    isGetCCHReturnsCompleted={this.props.returnState.isGetCCHReturnsCompleted}
                    isImportCompleted={this.state.isImportCompleted}
                    onBulkSelectionChange={this.onBulkSelectionChange}
                    onImport={this.onImport}
                    onGetCCHReturns={this.onGetCCHReturns}
                    onRefresh={this.onRefresh}
                ></ReturnsTableHeader>
                <ReturnsTable
                    onRowSelect={this.onRowSelect}
                    onSelectAll={this.onSelectAll}
                    onFilterChange={this.onFilterChange}
                    currentPageNo={this.state.pageNo}
                    onPageChange={this.onPageChange}
                    onSortChange={this.onSortChange}
                    returnsData={this.props.returnState.returns}
                    totalReturns={this.props.returnState.totalReturns}
                    pageSize={pageSize}
                    pageNo={this.state.pageNo}
                    isLoggedIn={isLoggedIn}
                    isLoading={this.props.returnState.isLoading}
                    areEntireReturnsSelected={this.state.areEntireReturnsSelected}
                    selectedRows={this.state.selectedRows}
                    onSyncReturn={this.OnSyncReturn}
                ></ReturnsTable>
                <SyncConsentModal
                    show={this.state.showSyncConsentModal}
                    onSync={this.onSyncProceed}
                    onCancel={this.onSyncCancel}
                ></SyncConsentModal>
                <FiltersModal
                    show={this.state.showFiltersModal}
                    onApply={this.onApplyFilters}
                    onClearAll={this.onClearAllFilters}
                    onCancel={this.onCancelFilters}
                ></FiltersModal>
            </div>
        );
    }
}
