import { SendTaxReturnResources } from "../../helper/ResourceIdConstants";
import { API_BASE_URL } from "src/utils/constants";
import { ISetAccessData } from "./SetAccessCommonModal.model";

export function getInitialListOfDataFromDB(callback: (setAccessData?: ISetAccessData) => void, isGroupedReturn?: boolean) {
    fetch(API_BASE_URL + 'api/document-access/upload-screen', {
        method: 'GET',
        credentials: 'include',
        headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json; charset=utf-8'            
        }
    }).then((resp) => resp.json()).
        then(data => {
            callback(data);
        })
        .catch(() => {
            callback();
        });
}
