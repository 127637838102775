import * as React from 'react';
import { Modal, Form, Button, Alert, Nav } from 'react-bootstrap';
import { LoadingOverlay, Loader } from 'react-overlay-loader';
import { FaFileSignature, FaDownload } from 'react-icons/fa';
import { GetFormatedDateTime, getDocumentEntityorIndividualName, displayFileName } from '../helper/HelperFunctions';
import { IDownloadableDocuments, ITaxReturn, DocumentStatus, SignatureStatus } from '../common/TaxReturn';
import { IDocumentData } from '../common/ProcessReturnModal/AdditionalEsignDocuments/AdditionalEsignDocument';
import { getFileExtension, GetSafeFilename } from '../helper/Validations';

interface IDownloadEfileFormProps {
    show: boolean;
    onCancel(): void;
    taxReturn: ITaxReturn;
    downloadIndividual(documentGuid: string, documentId: number, taxYear: number, fileName: string, groupId: number, signedDocumentId: number, isDownloadReturn: boolean, clientId: string): void;
    downloadAll(documentGuid: string, documentId: number, taxYear: number, isEfileForm: boolean, resourceId?:string): void;
    downloadAdditionalEsignDocuments: (documentGuid: string, documentId: number, taxYear: number, clientId: string, additionalEsignDocuments: IDocumentData[], isSigned?: boolean, resourceId?: string) => void;
    resourceId?: string;
}

export class DownloadEfileForm extends React.Component<IDownloadEfileFormProps, {}> {
    private getFormattedFileName() {
        const { taxReturn } = this.props;
        let name = getDocumentEntityorIndividualName(taxReturn);
        let fileName = taxReturn.clientId + "_" + name + "_" + taxReturn.taxYear + "_" + taxReturn.engagementType.toString().replace('E', '') + "_" + "Signature Documents.pdf";
        return fileName;
    }
    
    private getFormattedFileNames(value: any) {
        const { taxReturn } = this.props;
        let name = getDocumentEntityorIndividualName(taxReturn);
        if (value == "Signature_Audit_Report.pdf") {
            let fileName = taxReturn.clientId + "_" + name + "_" + taxReturn.taxYear + "_" + taxReturn.engagementType.toString().replace('E', '') + "_" + "Signature Audit Report.pdf";
            return GetSafeFilename(fileName);
        }
        else {
            let fileName = taxReturn.clientId + "_" + name + "_" + taxReturn.taxYear + "_" + taxReturn.engagementType.toString().replace('E', '') + "_" + "Signature Documents." + getFileExtension(value) ;
            return GetSafeFilename(fileName);
        }
    }

    private getAdditionalFormattedFileName() {
        const { taxReturn } = this.props;
        let name = getDocumentEntityorIndividualName(taxReturn);
        let fileName = taxReturn.clientId + "_" + name + "_" + taxReturn.taxYear + "_" + taxReturn.engagementType.toString().replace('E', '') + (this.isReturnSigned() ? "_Other Signed Document.pdf": "_Other UnSigned Document.pdf");
        return GetSafeFilename(fileName);
    }

    private isReturnSigned = () : boolean => {
        return this.props.taxReturn.documentStatus.toString() === DocumentStatus[DocumentStatus.USERSIGNED] || 
            (this.props.taxReturn.documentStatus === DocumentStatus[DocumentStatus.DOWNLOADPDF]
                && this.props.taxReturn.signatureStatus.toString() === SignatureStatus[SignatureStatus.AlternateAndUploaded]);
    }

    public render() {
        const { taxReturn } = this.props;
        const eFileFound = taxReturn !== undefined && taxReturn.downloadableEfileForms !== undefined;
        const additionalEsignDocumentFound = taxReturn !== undefined &&
            taxReturn.additionalEsignDocuments &&
            taxReturn.additionalEsignDocuments.length > 0 ? true : false;
        return (<Modal className="grouped-uploaded-documents" show={this.props.show} onHide={this.props.onCancel}>
            <Modal.Header closeButton onClick={this.props.onCancel} data-test-auto="8A258655-8C8D-43FC-8685-0E4793776C2D">
                <Modal.Title className='custom-modal-header'>
                    <FaFileSignature className='modalIcon' />
                    Uploaded Documents
                </Modal.Title>
            </Modal.Header>
            <Form>
                <Modal.Body>
                    <LoadingOverlay style={{ minHeight: 100 }}>
                        {
                            eFileFound ? <div style={{ marginTop: 10 }}>
                                <table style={{ fontFamily: 'arial, sans-serif', borderCollapse: 'collapse', width: '100%' }}>
                                    <tr><td className="new-main-content">E-sign Forms</td></tr>
                                    {taxReturn.downloadableEfileForms.map((efile, index) => {
                                        let formattedName = this.getFormattedFileNames(efile.fileName);
                                        return (<tr style={{ backgroundColor: '#dddddd' }}>
                                            <td style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px' }} width="310px" className="word-Visible" title={formattedName} >{displayFileName(formattedName, 40, 40)}</td>
                                            <td style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px' }} >{GetFormatedDateTime(efile.createdOn)}</td>
                                            <td style={{ border: '1px solid #dddddd', textAlign: 'left' }} >
                                                <Nav.Link href='javascript:' onClick={() => {
                                                    this.props.downloadIndividual(efile.documentGuid, efile.documentId, efile.taxYear, efile.fileName, efile.groupId, efile.signedDocumentId,
                                                        false, taxReturn.clientId, this.props.resourceId)
                                                }} data-test-auto="18C06943-6C1E-4EBF-9CEB-34840CB572D7">
                                                    <FaDownload className="marR05" /><span style={{ color: '#337ab7' }}>Download</span>
                                                </Nav.Link>
                                            </td>
                                        </tr>)
                                    })}
                                </table>
                            </div> : (taxReturn !== undefined) && (taxReturn.downloadableEfileForms !== undefined) && <Alert variant='warning' >
                                <i className='fas fa-exclamation-triangle' style={{ marginRight: '5px', color: '#666' }}>
                                </i>No e-File forms found!
                                </Alert>
                        }
                        {
                            additionalEsignDocumentFound &&
                            <div style={{ marginTop: 10 }}>
                                <table style={{ fontFamily: 'arial, sans-serif', borderCollapse: 'collapse', width: '100%' }}>
                                    <tr><td className="new-main-content">Additional E-signed Documents</td></tr>
                                    {taxReturn.additionalEsignDocuments.map((additionalDocument, index) => {
                                        let formattedName = this.getAdditionalFormattedFileName();
                                        return (<tr style={{ backgroundColor: '#dddddd' }}>
                                            <td style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px' }} width="310px" className="word-Visible" title={formattedName} >{displayFileName(formattedName, 40, 40)}</td>
                                            <td style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px' }} >{GetFormatedDateTime(additionalDocument.uploadedOn)}</td>
                                            <td style={{ border: '1px solid #dddddd', textAlign: 'left' }} >

                                                <Nav.Link href='javascript:'
                                                    onClick={
                                                        () => {
                                                            this.props.downloadAdditionalEsignDocuments(taxReturn.documentGuid, taxReturn.id,
                                                                taxReturn.taxYear, taxReturn.clientId, [additionalDocument] as IDocumentData[], true, this.props.resourceId)
                                                        }} data-test-auto="18C06943-6C1E-4EBF-9CEB-34840CB572D7">
                                                    <FaDownload className="marR05" /><span style={{ color: '#337ab7'}}>Download</span>
                                                </Nav.Link>
                                            </td>
                                        </tr>)
                                    })}
                                </table>
                            </div>
                        }
                        <Loader loading={(taxReturn === undefined) || (taxReturn.downloadableEfileForms === undefined)} />
                    </LoadingOverlay>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.props.onCancel}
                        variant="default"
                        className="btn btn-white"
                        data-test-auto="BC3C6FF2-75C2-45AD-8552-8915E4C8EDF0">
                        <i className='fas fa-times'></i>Cancel
                    </Button>
                    <Button onClick={() => { this.props.downloadAll(this.props.taxReturn.documentGuid, this.props.taxReturn.id, this.props.taxReturn.taxYear, true, this.props.resourceId); }}
                        variant="info"
                        disabled={!eFileFound}
                        data-test-auto="BC7DCC23-8799-4DD0-BDC6-30597C7804F1">
                        <i className='fas fa-download'></i>Download All
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>)
    }
}
