import * as React from 'react';
import { ListGroup, Col, Row, Table, Popover, OverlayTrigger } from 'react-bootstrap';

import { IFormBase, ITaxingAuthority, IEFile, ITaxReturn, DocumentGroups, IVoucher, VoucherMode, VoucherStatus, TransmittalStatus } from '../../common/TaxReturn';
import { PdfPage } from './PdfPage';
import { IPageItem, ProcessReturnTabs, IVoucherPageItem } from './ProcessReturnModels';
import { AddAuthority } from '../../common/ProcessReturnModal/AddAuthority';
import * as Constants from '../../helper/Constants';
import { BookmarkIcon } from '../BookmarkIcon';
import { K1AttachmentPopover } from '../ProcessReturnModal/K1Attachments/K1AttachmentPopover';

export interface IPageListViewProps {
	pages: IPageItem[];
	onPageSelect: (pageNo: number, voucherNo: number, isPageSelectionFromBookMark?: boolean, isViewClientInfo?: boolean) => void;
	onPageDelete?: (pageNo: number) => void;
	getTitle?: (pageTitle: string, id: number) => void;
	tabType?: number;
	getAllTaxingAuthorities: ITaxingAuthority[];
	onUpdateAddedEfile?: (efile: IEFile, currentEFileClone: IEFile, taxingAuthority: ITaxingAuthority) => void;
	efile?: IEFile;
	taxReturn?: ITaxReturn;
	showVoucherLabel?: boolean;
	isRevisionProcess?: boolean;
}

export interface IPagesState {
	pageNo: number;
	enableLeftPanelScroll: boolean;
}

export class PageListView extends React.Component<IPageListViewProps, IPagesState> {
	constructor(props: IPageListViewProps) {
		super(props);
		this.state = {
			pageNo: -1,
			enableLeftPanelScroll: true
		}

		this.onPageDelete = this.onPageDelete.bind(this);
	}

	public closeDeleteConfirmPopHover() {
		document.body.click();
	}

	enableScroll = () => {
		if (!this.state.enableLeftPanelScroll) {
			const scrollDiv = (document.querySelector(".scroll div div") as HTMLElement);
			scrollDiv.style.overflow = "scroll";
			scrollDiv.style.marginRight = "-17px";
			((document.querySelector(".scroll div") as HTMLElement).children[2] as HTMLElement).style.display = "block";
			this.setState({ enableLeftPanelScroll: true });
		}

	}
	stopScroll = () => {
		if (this.state.enableLeftPanelScroll) {
			const scrollDiv = (document.querySelector(".scroll div div") as HTMLElement);
			const top: number = scrollDiv.scrollTop;
			scrollDiv.style.overflow = "hidden";
			scrollDiv.style.marginRight = "0px";
			scrollDiv.scrollTop = top;
			((document.querySelector(".scroll div") as HTMLElement).children[2] as HTMLElement).style.display = "none";
			this.setState({ enableLeftPanelScroll: false });
		}

	}
	onPageDelete() {
		this.props.onPageDelete ? this.props.onPageDelete(this.state.pageNo) : undefined
		document.body.click();
	}

	removeDuplicateMultipartRefunds = (pageItems: IPageItem[]) => {
		let uniquePageItems: IPageItem[] = [];
		if (pageItems.filter(f => f.isMultipart == true).length > 0) {
			let multipartItems: IPageItem[] = pageItems.filter(f => f.isMultipart === true);
			uniquePageItems = pageItems.filter(f => f.isMultipart == undefined || f.isMultipart === false);
			multipartItems.forEach(form => {
				let formIndex: number = uniquePageItems.findIndex(x => x.pageTitle === form.pageTitle);
				if (formIndex === -1) {
					uniquePageItems.push(form);
				}
				else {
					let item = uniquePageItems[formIndex];
					if (item && item.pageNo > form.pageNo) {
						uniquePageItems.splice(formIndex, 1);
						uniquePageItems.push(form);
					}
				}
			});
			return uniquePageItems;
		}
		uniquePageItems = [...pageItems];
		return uniquePageItems;
	}

	removeExistingRevisedFormData = (pageItems: IPageItem[]) => {
		let pageData: IPageItem[] = [];
		if (this.props.isRevisionProcess) {
			if (this.props.tabType == ProcessReturnTabs.Vouchers) {
				pageItems.map((form, index) => {
					if (form.voucherStatus != VoucherStatus.Existing) {
						pageData.push(form);
					}
				});
			}

			if (this.props.tabType == ProcessReturnTabs.Transmittals) {
				pageItems.map((form, index) => {
					if (form.transmittalStatus != TransmittalStatus.Existing) {
						pageData.push(form);
					}
				});
			}

			return pageData;
		}
		return pageItems;
	}

	getAuthorityName = (PageNo: number) => {
		let authorityName: any;
		if (this.props.tabType == ProcessReturnTabs.Vouchers) {
			let voucher: IVoucher[];
			if (this.props.taxReturn != undefined) {
				let voucherindex = this.props.taxReturn.formGroups.findIndex(x => x.group == DocumentGroups.Vouchers);
				if (voucherindex > - 1) {
					voucher = this.props.taxReturn.formGroups[voucherindex]?.forms as IVoucher[];
					if (voucher) {
						let result = voucher.find(x => x.pageNo[0] == PageNo && x.vocherMode != VoucherMode.CustomVoucher);
						authorityName = this.props.getAllTaxingAuthorities.find(x => x.Id == (result && result.authorityID))
						return authorityName ? authorityName.AuthorityName + " " : "";
					}
				}
			}
		}
		else {
			return "";
		}
	}

	getPaperclipContent = (formId: number) => {
		let paperclipContent: string[] = [];
		if (this.props.taxReturn) {
			this.props.taxReturn.k1Attachments.forEach((x: any) => {
				const found = x.k1AttachmentDetails.filter((x: any) => x.shareholderId === formId);
				found.length && x.isDeleted === false && paperclipContent.push(x.fileName);
			})
		}
		
		return paperclipContent;
	}

	public render() {
		const popover = (<Popover id="popover-refund-delete-confirm" >
			<Popover.Title as="h3">{Constants.ClientInfoTab.AddedRefund.DeleteConfirm}</Popover.Title>
			<Popover.Content>
				<button
					className="btn btn-only btn-success"
					onClick={this.onPageDelete}
					title="Yes"
					data-test-auto="644432DD-2D8A-4597-B7DE-E01EAF0C67B1"
					style={{ marginLeft: "30px" }}>
					Yes
                </button>
				<button
					onClick={this.closeDeleteConfirmPopHover}
					title="No"
					data-test-auto="9138EE23-BDCF-4D94-8264-6E707800C214"
					className="btn btn-only btn-danger"
					style={{ marginLeft: "5px" }}>
					No
                </button>
			</Popover.Content>
		</Popover>);

		return <div className="pageListView">
			{this.props.pages.length === 0 ? <div className="textalign-center">No Content</div> :
				(this.removeExistingRevisedFormData(this.removeDuplicateMultipartRefunds(this.props.pages)).map((form, i) => {
					const rowId = "deletePage" + form.pageNo;
					const parts = form.pageTitle ? form.pageTitle.split("###") : [];
					const authorityName = this.getAuthorityName(form.pageNo);
					const title = authorityName && parts[0].startsWith(authorityName) ? parts[0] : authorityName + parts[0];
					let isAuthorityPresent = true;

					if (this.props.tabType == ProcessReturnTabs.EFile) {
						let efiles: IEFile[];
						if (this.props.taxReturn != undefined) {
							let efileindex = this.props.taxReturn.formGroups.findIndex(x => x.group == DocumentGroups.EFile);
							efiles = this.props.taxReturn.formGroups[efileindex]?.forms as IEFile[];
							if (efiles.find(x => x.authorityID == 0 && parts[0].startsWith(x.formName))) {

								isAuthorityPresent = false;
							}
						};
					}


					return <div
						key={i}
						className="custom-list-group-item bookmark-item" >
						<div className="bookmark-row" id={rowId}>
							<BookmarkIcon {...form} />
							<div title={title}
								className={"bookmark-name wordbreak ellipsis cursor-pointer " + (form.className || "")}
								onClick={() => {
									if (this.props.tabType == ProcessReturnTabs.Vouchers) {
										let f: IVoucherPageItem = form as IVoucherPageItem;
										this.props.onPageSelect(f.pageNo, f.voucherNo, true);
									}
									else {
										let f = form as IPageItem;
										this.props.onPageSelect(f.pageNo, -1, true, f.isClientInfo);
									}
									this.props.getTitle ? this.props.getTitle(form.pageTitle, form.id) : undefined;
								}}>
								{title}</div>
							<div className="bookmark-right">
								{
									this.props.tabType == ProcessReturnTabs.EFile &&
									<span className="float-right" onClick={() => {
										let f = form as IPageItem;
										this.props.onPageSelect(f.pageNo, -1, true, f.isClientInfo);
									}}>
										{isAuthorityPresent ? (
											<AddAuthority
												pages={this.props.pages}
												getAllTaxingAuthorities={this.props.getAllTaxingAuthorities}
												efile={this.props.efile}
												onUpdateAddedEfile={this.props.onUpdateAddedEfile}
												className={"fas fa-edit linkColor"}
												title={"Edit Authority"}
											/>)
											:
											(<AddAuthority
												pages={this.props.pages}
												getAllTaxingAuthorities={this.props.getAllTaxingAuthorities}
												efile={this.props.efile}
												onUpdateAddedEfile={this.props.onUpdateAddedEfile}
												className={"fas fa-exclamation-triangle"}
												title={"Add Authority"}
												style={{ color: "#a94442" }}
											/>)
										}
									</span>
								}
								{parts.length === 2 &&
									<span title={parts[1]}
										className={(form.className || "")}>{parts[1]}</span>
								}
								{form.isDeleteEnabled &&

									<OverlayTrigger rootClose trigger="click" onEnter={() => document.body.click()} onEntered={() => { this.stopScroll() }} onExit={() => { this.enableScroll() }} placement="right" overlay={popover} >
										<i className={"fas fa-times cursor-pointer text-danger"}
											onClick={() => { this.setState({ pageNo: form.pageNo }) }}
											title={"Move Page " + form.pageNo + " to bin"} />
									</OverlayTrigger>
								}
								{
									this.props.tabType == ProcessReturnTabs.Vouchers && this.props.showVoucherLabel &&
									<span className="badge">{form.voucherStatus == VoucherStatus.New ? "New" : form.voucherStatus == VoucherStatus.ExistingUpdated ? "Updated" : ""}</span>
								}
								{
									this.props.taxReturn && this.getPaperclipContent(form.id).length ?
									<K1AttachmentPopover
										k1Attachments={this.getPaperclipContent(form.id)}
									/> :
									<></>
								}
							</div>
						</div>
					</div>
				}))
			}
		</div>
	}
};
