import * as React from "react";
import { AccessDenied } from "./svg/CustomGraphics";
import { logger } from "src/routes/LoggedIn";

export class Unauthorized extends React.Component {
    componentDidMount(): void {
        logger && logger.trackTrace("Unauthorized Access", { 
			"ProductType": "1",
            "IsAllMenuAccessDenied": "False" });
    }
    public render() {
        return (
            <div className="access-denied-wrapper">
                <div className="access-denied">
                    <div>
                        <AccessDenied width={200} height={200} />
                    </div>
                    <br />
                    <div className="access-denied-text">
                        <h5>Access Denied!</h5>
                        <div>
                            Please consult your system <br />
                            administrator for assistance.
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
