import { createStore, applyMiddleware, combineReducers } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";

import * as AuthReducer from "./auth/reducer";
import * as UserProfileStore from "./userManagement/UserProfileStore";
import * as MyAccount from "./userManagement/MyAccountStore";
import * as UserSettingStore from "./userManagement/UserSettingStore";
import * as TaxDocumentStore from "./common/TaxDocumentStore";
import * as UserStore from "./userManagement/UserStore";

import * as DeliveredReturnsStore from "./reports/DeliveredReturnsStore";

import * as UserAssignmentsStore from "./assignments/UserAssignmentsStore";
import * as CompanyAssignmentsStore from "./assignments/CompanyAssignmentsStore";
import * as UserAssignmentsPagesStore from "./assignments/UserAssignmentsPagesStore";
import * as CompanyAssignmentsPagesStore from "./assignments/CompanyAssignmentsPagesStore";
import * as DeliveredReturnsPagesStore from "./reports/DeliveredReturnsPagesStore";
import * as FilterStore from "./reports/FilterStore";
import * as PdfStore from "./pdf/PdfStore";
import * as CompanyStore from "./company/CompanyStore";
import * as SavedMessageStore from "./settings/SavedMessageStore";
import * as ClientMessageStore from "./settings/ClientMessageStore";
import * as WelcomeMessageStore from "./settings/WelcomeMessageStore";
import * as EmailMessageStore from "./settings/EmailMessageStore";
import * as AttachmentInstructionStore from "./settings/AttachmentInstructionStore";

import * as MailingReturnAddressStore from "../store/common/MailingReturnAddressStore";
import * as NotificationStore from "../store/common/NotificationStore";
import * as CustomColumnStore from "./common/CustomColumnStore";
import * as AdditionalDocumentTypeStore from "./common/AdditionalDocumentTypeStore";

import * as InUseReturnsStore from "./reports/InUseReturnsStore";
import * as InUseReturnsPagesStore from "./reports/InUseReturnsPagesStore";

import * as RecycleReturnsStore from "./reports/RecycleReturns/RecycleReturnsStore";
import * as RecycleReturnsPagesStore from "./reports/RecycleReturns//RecycleReturnsPagesStore";

import * as VoucherReminderManagementStore from "./reports/VoucherReminderManagement/VoucherReminderManagementStore";
import * as VoucherReminderManagementPagesStore from "./reports/VoucherReminderManagement/VoucherReminderManagementPagesStore";

import * as ArchivedReturnsStore from "./reports/archive/ArchivedReturnsStore";
import * as ArchivedReturnsPagesStore from "./reports/archive/ArchivedReturnsPagesStore";
import * as ArchivedTaxDocumentStore from "./reports/archive/ArchivedTaxDocumentStore";
import * as AttachmentsStore from "../store/ProcessReturn/AttachmentsStore";

import * as Upload from "../store/common/Upload";
import * as Download from "../store/common/Download";
import * as UserSignatureStore from "./common/UserSignatureStore";
import * as ReminderStore from "./common/ReminderStore";
import * as VoucherStore from "./ProcessReturn/voucher/VoucherStore";
import * as BusinessReturnStore from "./settings/BusinessReturnsStore";
import * as UploadTaxReturn from "../store/common/uploadTaxReturn";
import * as UserLoginHistory from "../store/userManagement/UserLoginHistoryStore";
import * as SecurityContainerStore from "../store/company/SecurityContainerStore";
import * as K1DeliveredStatusStore from "./../store/reports/K1DeliveredStatusReport/K1DeliveredStatusStore";
import * as ColumnSettingsStore from "./../store/reports/ColumnSettings/ColumnSettingsStore";
import * as InboxStore from "./../store/inbox/InboxStore";

//SF
import * as SFUsersStore from "./../SignatureFlow/store/SFUserStore";
//

import * as AdditionalEsignDocumentStore from "./../store/ProcessReturn/AdditionalEsignDocuments/AdditionalEsignDocumentStore";

import * as OneTimePasswordStore from "../store/common/OneTimePasswordStore";
import * as PurchaseStore from "./purchase/PurchaseStore";

import * as WatermarkStore from "../store/settings/WatermarkStore";
import * as GroupedReturnStore from "../store/groupedreturns/GroupedReturnStore";
import * as SendGroupedReturnsStore from "../store/reports/GroupedReturns/SendGroupedReturnsStore";
import * as SendGroupedReturnsPagesStore from "../store/reports/GroupedReturns/SendGroupedReturnsPagesStore";
import * as GroupedReturnProcessStore from "../store/groupedreturns/GroupedReturnProcessStore";
import * as GroupedTaxDocumentStore from "../store/groupedreturns/GroupedTaxDocumentStore";
import * as QuickReportStore from "./reports/QuickReportStore";

import * as DeliveredGroupedReturnsStore from "../store/reports/GroupedReturns/DeliveredGroupedReturns/DeliveredGroupedReturnsStore";
import * as DeliveredGroupedReturnsPagesStore from "../store/reports/GroupedReturns/DeliveredGroupedReturns/DeliveredGroupedReturnsPagesStore";
import * as DeliveredGroupedReturnsHeaderStore from "../store/reports/GroupedReturns/DeliveredGroupedReturns/DeliveredGroupedReturnsHeaderStore";
import * as RecycleGroupedReturnsStore from "../store/reports/GroupedReturns/RecycleGroupedReturns/RecycleGroupedReturnsStore";
import * as RecycleGroupedReturnsPagesStore from "../store/reports/GroupedReturns/RecycleGroupedReturns/RecycleGroupedReturnsPagesStore";
import * as InUseGroupedReturnsStore from "../store/reports/GroupedReturns/InUseGroupedReturns/InUseGroupedReturnsStore";
import * as InUseGroupedReturnsPagesStore from "../store/reports/GroupedReturns/InUseGroupedReturns/InUseGroupedReturnsPagesStore";
import * as PaymentAccountStore from "../store/PaymentAccount/PaymentAccountStore";
import * as ApplicationHelptextStore from "../store/common/ApplicationHelptextStore";
import * as PaperStore from "../store/ProcessReturn/PaperFileStore";
import * as PaperFiledMessageStore from "./settings/PaperFiledMessageStore";
import { ApplicationState } from "./types";
import * as K1AttachmentStore from "../store/ProcessReturn/K1AttachmentStore";
import * as SSRSettingsStore from "../store/company/settings/SSRSettingsStore";
import * as LayoutStore from "../store/Navigation/LayoutStore";
import * as SuiteMFASettingStore from "../store/company/SuiteMFASettingStore";
import * as SuiteTaxSoftwareSettingStore from "../store/company/SuiteTaxSoftwareSettingStore";
import * as OfficeLocationStore from "./common/OfficeLocationStore";
import * as cchReturnStore from "./reports/CCHReturns/CCHReturnStore";
import * as SuiteNotificationSettingStore from "../store/company/SuiteNotificationSettingStore";
import * as ClientManagementSettingStore from '../store/company/SuiteClientManagementSettingStore';
import * as K1SSNStore from '../store/ProcessReturn/K1SSNStore';
import * as EFileSettingsStore from '../store/company/settings/EFileSettingsStore'
export * from "./types";

export const reducers = {
    userDetails: MyAccount.reducer,
    ssrUsers: UserStore.SSRUserListReducer,
    auth: AuthReducer.reducer,
    userProfile: UserProfileStore.reducer,
    userSettings: UserSettingStore.reducer,
    deliveredReturns: DeliveredReturnsStore.reducer,
    nextSignerDetails: DeliveredReturnsStore.nextSignerReducer,
    deliveredReturnsPages: DeliveredReturnsPagesStore.reducer,
    archiveTaxDocument: ArchivedTaxDocumentStore.reducer,
    archivedReturns: ArchivedReturnsStore.reducer,
    archivedReturnsPages: ArchivedReturnsPagesStore.reducer,
    userAssignments: UserAssignmentsStore.reducer,
    companyAssignments: CompanyAssignmentsStore.reducer,
    userAssignmentsPages: UserAssignmentsPagesStore.reducer,
    companyAssignmentsPages: CompanyAssignmentsPagesStore.reducer,
    taxDocuments: TaxDocumentStore.reducer,
    usersList: UserStore.reducer,
    partnerUsersList: UserStore.partnerUsersReducer,
    userResources: UserStore.resourceListReducer,
    dependentUserList: UserStore.customReducer,
    reportFilters: FilterStore.reducer,
    pdfDocuments: PdfStore.reducer,
    companyData: CompanyStore.reducer,
    inUseReturns: InUseReturnsStore.reducer,
    InUseReturnsPages: InUseReturnsPagesStore.reducer,

    recycleReturns: RecycleReturnsStore.reducer,
    recycleReturnsPages: RecycleReturnsPagesStore.reducer,

    voucherReminderReport: VoucherReminderManagementStore.reducer,
    voucherReminderStatusPages: VoucherReminderManagementPagesStore.reducer,

    mailingReturnAddress: MailingReturnAddressStore.reducer,
    notification: NotificationStore.reducer,
    savedMessage: SavedMessageStore.reducer,
    clientMessage: ClientMessageStore.reducer,
    welcomeMessage: WelcomeMessageStore.reducer,
    emailMessage: EmailMessageStore.reducer,
    attachmentInstruction: AttachmentInstructionStore.reducer,
    customColumn: CustomColumnStore.reducer,
    additionalDocumentType: AdditionalDocumentTypeStore.reducer,
    attachments: AttachmentsStore.reducer,
    UploadStore: Upload.reducer,
    DownloadStore: Download.reducer,
    userSignatures: UserSignatureStore.reducer,
    reminderData: ReminderStore.reducer,
    voucherStore: VoucherStore.reducer,
    businessReturnInstructions: BusinessReturnStore.reducer,
    UploadTaxReturnStore: UploadTaxReturn.reducer,
    userLoginHistory: UserLoginHistory.reducer,
    securityContainerStore: SecurityContainerStore.reducer,
    k1DeliveredStatusState: K1DeliveredStatusStore.reducer,
    columnSettingsState: ColumnSettingsStore.reducer,
    inboxState: InboxStore.reducer,

    sfUserList: SFUsersStore.reducer,
    ssrUserList: UserStore.SSRUserReducer,
    additionalEsignDocument: AdditionalEsignDocumentStore.reducer,

    oneTimePasswordStore: OneTimePasswordStore.reducer,
    watermarkStore: WatermarkStore.reducer,

    groupedReturnStore: GroupedReturnStore.reducer,

    sendGroupedReturns: SendGroupedReturnsStore.reducer,
    groupedReturnProcessState: GroupedReturnProcessStore.reducer,
    sendGroupedReturnsPages: SendGroupedReturnsPagesStore.reducer,
    groupedTaxDocuments: GroupedTaxDocumentStore.reducer,

    deliveredGroupedReturns: DeliveredGroupedReturnsStore.reducer,
    deliveredGroupedReturnsPages: DeliveredGroupedReturnsPagesStore.reducer,
    quickReports: QuickReportStore.reducer,
    quickReportPages: QuickReportStore.quickReportPageReducer,

    recycleGroupedReturns: RecycleGroupedReturnsStore.reducer,
    recycleGroupedReturnsPages: RecycleGroupedReturnsPagesStore.reducer,

    purchaseData: PurchaseStore.reducer,
    paymentAccountStore: PaymentAccountStore.reducer,

    inUseGroupedReturns: InUseGroupedReturnsStore.reducer,
    inUseGroupedReturnsPages: InUseGroupedReturnsPagesStore.reducer,
    Helptexts: ApplicationHelptextStore.reducer,
    paperFile: PaperStore.reducer,

    paperFiledMessage: PaperFiledMessageStore.reducer,
    k1Attachment: K1AttachmentStore.reducer,

    ssrSettings: SSRSettingsStore.reducer,
    LeftMenu: LayoutStore.reducer,
    AccessibleProductData: LayoutStore.productsReducer,
    userPrivilege: AuthReducer.userPrivilegeReducer,
    mfaSetting: SuiteMFASettingStore.reducer,
    taxSoftwareSetting: SuiteTaxSoftwareSettingStore.reducer,
    officeLocation: OfficeLocationStore.reducer,
    taxReturnsToNotifyRecognizedEvent: TaxDocumentStore.recognizedTaxReturnReducer,
    cchReturns: cchReturnStore.reducer,
    suiteNotificationSetting: SuiteNotificationSettingStore.reducer,
   
    clientManagementSetting: ClientManagementSettingStore.reducer,
    k1SSNDetails: K1SSNStore.reducer,
    pageWiseSSNDetails: K1SSNStore.pageWiseSSNReducer,
    userAssignDeliveryPermission: UserStore.UserAssignDeliverPermissionReducer,
    eFileSettings: EFileSettingsStore.reducer
};

export interface AppThunkAction<TAction> {
    (dispatch: (action: TAction | AppThunkAction<TAction>) => void, getState: () => ApplicationState): void;
}

export function configureStore(initialState?: ApplicationState) {
    // Build middleware. These are functions that can process the actions before they reach the store.
    // If devTools is installed, connect to it
    const createStoreWithMiddleware = composeWithDevTools(applyMiddleware(thunk))(createStore);

    // Combine all reducers and instantiate the app-wide store instance
    const allReducers = combineReducers(reducers);
    const store = createStoreWithMiddleware(allReducers, initialState);
    return store;
}

const store = configureStore();

export default store;
