import * as React from 'react';
import { Button, Modal, Col, Row } from 'react-bootstrap';
import { clearAllTemporaryFilter } from './reports/Filters';

interface IMultiConcurrentUserProps {
    showModal: boolean;
}

export class MultiConcurrentUser extends React.Component<IMultiConcurrentUserProps, {}> {
    constructor(props: IMultiConcurrentUserProps) {
        super(props);
    }
    public render() {
        return <Modal 
            className="session-timeout-modal"
            show={this.props.showModal}
            onHide={this.onLogout}>          
            <Modal.Header closeButton>
                <Modal.Title className='custom-modal-header'>
                    <span className='modalIcon text-secondary fas fa-user-clock'>
                    </span>Concurrent Login
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>                  
                    <Col lg={12}>
                        <div style={{ display: "inline-flex" }}>You have already logged in another session. Please click on "Refresh" to continue with the existing session
                        </div>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="info"
                    onClick={this.onLogout}
                ><i className='fas fa-redo-alt'></i>Refresh</Button>
            </Modal.Footer>
        </Modal>
    } 

    private onLogout = () => {
        clearAllTemporaryFilter();
        window.location.reload();
    }

}


