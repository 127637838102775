import * as React from 'react';
import * as Bs from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { K1TabConstants } from '../../../helper/Constants';
import ToggleSwitch from '../../ToggleSwitch';

interface IProps {
    updateSSNMaskSetting: (value: boolean) => void;
    isDocumentK1SSNMaskingEnabled: boolean;
}

export class K1SSNMaskSetting extends React.Component<IProps, {}> {

    private handleToggleSwitchChange = (event: any) => {
        this.props.updateSSNMaskSetting(event.target.checked);
    };

    public render() {
        return <div className="card">
            <div className="marL20">
                <Bs.Row style={{ padding: "20px 0px" }}>
                    <Bs.Col className="text-left" sm={4} as={Bs.FormLabel} style={{ paddingTop: "3px" }}>
                        Mask SSN/EIN
                    </Bs.Col>
                    <Bs.Col sm={8} md={8} lg={8}>
                        <ToggleSwitch
                            switched={this.props.isDocumentK1SSNMaskingEnabled}
                            handleChange={this.handleToggleSwitchChange} />

                        <Link to={'#'} className="help-icon marL05" data-placement="right" data-toggle="tooltip"
                            title={K1TabConstants.ssnMaskHelpText}>
                            <i className="fas fa-question-circle"></i>
                        </Link>
                    </Bs.Col>
                </Bs.Row>
            </div>
        </div>

    }
}

export default K1SSNMaskSetting;
