import * as React from 'react';
import { Button } from 'react-bootstrap';
import { ICompanySettings } from '../../../../Core/ViewModels/Company/CompanySettingsViewModel';
import { IAttachmentInstruction, initialAttachmentInstruction } from '../../../../Core/ViewModels/CompanySettings/ClientInstructionViewModel';
import * as AttachmentInstructionStore from '../../../../store/settings/AttachmentInstructionStore';
import { AttachmentInstructionPopUp } from './AttachmentInstructionPopUp';
import { VenusNotifier } from '../../../../components/helper/VenusNotifier';
import { AttachmentInstructionsConstants } from '../../../../components/helper/Constants';
import * as bootbox from 'bootbox';
import { ReplaceHelperVariable } from '../../../helper/HelperFunctions';
import { container } from '../../../../Startup/inversify.config';
import { TYPES } from "../../../../Startup/types";
import { IHtmlUtilities } from '../../../../Core/Utilities/HtmlUtilities';
import { IHelptextModel } from '../../../../Core/ViewModels/Common/IHelptextModel';
import { ISSRSettings, SSRSettings } from '../../../../Core/ViewModels/Company/Settings/SSRSettingsViewModel';
import { InstructionsActionButtonResources } from '../../../../components/helper/ResourceIdConstants';


var htmlencode = require('htmlencode');

const htmlUtilities = container.get<IHtmlUtilities>(TYPES.IHtmlUtilities);
const isEqual = require("react-fast-compare");

export interface IAttachmentInstructionProps {
    attachmentInstructions: AttachmentInstructionStore.IAttachmentInstructionDictionary;
    companySettings: ICompanySettings;
    ssrSettings: ISSRSettings
    saveAttachmentInstruction: (attachmentInstruction: IAttachmentInstruction, ssrSettings: ISSRSettings, isSetDefault: boolean, resourceId?: string) => void;
    updateAttachmentInstruction: (attachmentInstruction: IAttachmentInstruction, resourceId?: string) => void;
    deleteAttachmentInstruction: (attachmentInstruction: IAttachmentInstruction, resourceId?: string) => void;
    updateCompanySettings: (companySettings: ICompanySettings) => void;
    updateSSRSettings: (ssrSettings: ISSRSettings, callback?: () => void, resourceId?: string) => void;
    helptexts?: IHelptextModel[];
    parentResourceIdentifier:string;
}

export interface IAttachmentInstructionState {
    showAttachmentInstructionPopUp: boolean;
    isUpdate: boolean;
    selectedInstruction: number;
    buttonText: string;
    title: string;
}

export class AttachmentInstruction extends React.Component<IAttachmentInstructionProps, IAttachmentInstructionState> {
    state = {
        showAttachmentInstructionPopUp: false,
        isUpdate: false,
        selectedInstruction: 0,
        buttonText: '',
        title: ''
    };

    componentDidMount() {
        if (this.props.ssrSettings) {
            this.setState({
                selectedInstruction: this.props.ssrSettings.defaultMessageSettings.attachmentInstruction
            });
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps: IAttachmentInstructionProps) {
        if (!isEqual(this.props, nextProps)) {
            this.setState({
                selectedInstruction: nextProps.ssrSettings ? nextProps.ssrSettings.defaultMessageSettings.attachmentInstruction : 0
            });
        }
    }

    onAddButtonClick = () => {
        this.setState({
            showAttachmentInstructionPopUp: true,
            isUpdate: false,
            buttonText: 'Add',
            title: 'Add Attachment Instruction'
        });
    }

    onEditButtonClick = () => {
        this.setState({
            showAttachmentInstructionPopUp: true,
            isUpdate: true,
            buttonText: 'Save',
            title: 'Edit Attachment Instruction'
        });
    }

    onDeleteButtonClick = () => {
        if (this.state.selectedInstruction == this.props.ssrSettings.defaultMessageSettings.attachmentInstruction) {
            bootbox.alert({
                message: AttachmentInstructionsConstants.DeleteWarning,
                size: 'small',
                buttons: {
                    ok: {
                        label: '<i class="fas fa-check"></i> OK',
                        className: 'btn btn-success'
                    }
                }
            });
        }
        else {
            let _currentObject = this
            bootbox.confirm({
                title: 'Delete Attachment Instruction',
                message: 'Are you sure you want to delete this instruction?',
                buttons: {
                    cancel: {
                        label: '<i class="fas fa-times"></i> Cancel',
                        className: 'btn-white btn-default'
                    },
                    confirm: {
                        label: '<i class="fas fa-check"></i> Delete',
                        className: 'btn-info'
                    }
                },
                callback: (result: boolean) => {
                    if (result == true) {
                        _currentObject.props.deleteAttachmentInstruction(_currentObject.props.attachmentInstructions[_currentObject.state.selectedInstruction].attachmentInstruction,
                            this.props.parentResourceIdentifier + InstructionsActionButtonResources.ButtonDelete);
                        _currentObject.setState({ selectedInstruction: _currentObject.props.ssrSettings.defaultMessageSettings.attachmentInstruction });
                    }
                }
            });
        }
    }

    onListItemClick = (id: number) => {
        this.setState({
            selectedInstruction: id
        });
    }

    onCancelButtonClick = () => {
        this.setState({
            showAttachmentInstructionPopUp: false,
            isUpdate: false
        });
    }

    addWelcomeMessage = (attachmentInstruction: IAttachmentInstruction, isSetDefault: boolean) => {
        let ssrSettings = new SSRSettings(this.props.ssrSettings.defaultMessageSettings);
        if (ssrSettings) {
            this.props.saveAttachmentInstruction(attachmentInstruction, ssrSettings, isSetDefault, this.props.parentResourceIdentifier + InstructionsActionButtonResources.ButtonAdd);
            this.setState({
                showAttachmentInstructionPopUp: false,
                isUpdate: false
            })
        } else {
            VenusNotifier.Error(AttachmentInstructionsConstants.SaveError, null);
        }
    }

    editWelcomeMessage = (attachmentInstruction: IAttachmentInstruction, isSetDefault: boolean) => {
        let ssrSettings = new SSRSettings(this.props.ssrSettings.defaultMessageSettings);
        if (ssrSettings) {
            if (ssrSettings.defaultMessageSettings && attachmentInstruction.id == ssrSettings.defaultMessageSettings.attachmentInstruction && !isSetDefault) {//Default message shouldnot be turned off
                VenusNotifier.Warning(AttachmentInstructionsConstants.SelectDefaultWarning, null);
                return false;
            }
            else {
                if (isSetDefault && ssrSettings.defaultMessageSettings && attachmentInstruction.id != ssrSettings.defaultMessageSettings.attachmentInstruction) {
                    ssrSettings.defaultMessageSettings.attachmentInstruction = attachmentInstruction.id;
                    this.props.updateSSRSettings(ssrSettings, undefined, this.props.parentResourceIdentifier + InstructionsActionButtonResources.ButtonEdit);
                }

                this.props.updateAttachmentInstruction(attachmentInstruction, this.props.parentResourceIdentifier + InstructionsActionButtonResources.ButtonEdit);
                this.setState({
                    showAttachmentInstructionPopUp: false,
                    isUpdate: false
                });
            }
        }
        else {
            VenusNotifier.Error(AttachmentInstructionsConstants.UpdateError, null);
        }
    }

    public render() {
        const messageList = this.props.attachmentInstructions ? this.props.attachmentInstructions : [];
        const currentMessage = this.props.attachmentInstructions && this.props.attachmentInstructions[this.state.selectedInstruction]
            ? this.props.attachmentInstructions[this.state.selectedInstruction].attachmentInstruction
            : initialAttachmentInstruction;
        const defaultMessage = this.props.ssrSettings ? this.props.ssrSettings.defaultMessageSettings.attachmentInstruction : 0;

        return <div style={{ marginTop: 25 }} className="new-main-content">
            <h3>Attachment Instructions</h3>
            <hr />
            <div className="row" style={{ position: "relative" }}>
                <div className="col-sm-6 col-lg-4" style={{ marginLeft: 17 }}>
                    <div className="row dispFR">
                        <div className="float-right">
                            <Button
                                data-test-auto="01354C39-6B1B-4BBD-93BF-AA0F060D441E"
                                data-resource-id = { this.props.parentResourceIdentifier + InstructionsActionButtonResources.ButtonAdd}
                                variant="info"
                                style={{ padding: 12, minWidth: 40 }}
                                onClick={this.onAddButtonClick}
                                title="Add Message">
                                <span className="fas fa-plus" />
                            </Button>
                        </div>
                    </div>
                    <div className="row" style={{ marginTop: 10, overflowY: 'auto', height: 230, border: 'rgb(221, 221, 221) 1px solid' }}>
                        <div className="width100">
                            <ul className="list-group">
                                {
                                    Object.keys(messageList).map((key, index) => (
                                        <li
                                            key={this.props.attachmentInstructions[parseInt(key)].attachmentInstruction.id}
                                            className={this.props.attachmentInstructions[parseInt(key)].attachmentInstruction.id == this.state.selectedInstruction ? "custom-list-group-item-singleline custom-active" : "custom-list-group-item-singleline"}
                                            title={this.props.attachmentInstructions[parseInt(key)].attachmentInstruction.name}
                                            onClick={() => this.onListItemClick(parseInt(key))}
                                        >
                                            <div className="width100 customFlexProperty">
                                                <div style={{ textOverflow: 'ellipsis' }} className={this.props.attachmentInstructions[parseInt(key)].attachmentInstruction.id == defaultMessage ? "nopadding overflowHidden" : "overflowHidden"}>
                                                    {this.props.attachmentInstructions[parseInt(key)].attachmentInstruction.name}
                                                </div>
                                                {
                                                    this.props.attachmentInstructions[parseInt(key)].attachmentInstruction.id == defaultMessage ?
                                                        <div style={{ padding: '0' }}>
                                                            <span className={"badge"} style={{ marginLeft: 10, float: 'initial' }}>Default</span>
                                                        </div>
                                                        : <span></span>
                                                }
                                            </div>
                                        </li>
                                    ))
                                }
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="col-sm-6 col-lg-5" style={{ marginLeft: 32 }}>
                    <div className="row dispFR">
                        <div className="float-right">
                            <Button
                                variant="info"
                                data-resource-id = { this.props.parentResourceIdentifier + InstructionsActionButtonResources.ButtonAdd}
                                data-test-auto="C7D673C4-5E79-4185-8CEF-72E05313FD82"
                                bsPrefix="btn btn-icon-default btn-lg fas fa-edit"
                                style={{ padding: 12, minWidth: 40 }}
                                title="Edit Message"
                                onClick={this.onEditButtonClick}
                            >
                            </Button>
                            <Button
                                variant="info"
                                data-resource-id = { this.props.parentResourceIdentifier + InstructionsActionButtonResources.ButtonAdd}
                                data-test-auto="73194033-00F4-43F4-8875-14B9D36F5235"
                                bsPrefix="btn btn-icon-default btn-lg fas fa-trash-alt"
                                title="Delete Message"
                                style={{ marginLeft: 5, padding: 12, minWidth: 40 }}
                                onClick={this.onDeleteButtonClick}
                            >
                            </Button>
                        </div>
                    </div>
                    <div className="row" style={{ paddingTop: 10, height: 265 }}>
                        <div className="width100">
                            <div className="form-group">
                                <label>Message Name{currentMessage.id == defaultMessage ?
                                    <span className="badge" style={{ marginLeft: 10, marginTop: -1 }}>Default</span> : <span></span>}
                                </label>
                                <div className="form-control" style={{ backgroundColor: '#f1f1f1' }}>{currentMessage.name}</div>
                            </div>
                            <div className="form-group">
                                <label >Message Body</label>
                                <div className="form-control" style={{ overflowY: 'auto', height: 100, backgroundColor: '#f1f1f1' }}
                                    dangerouslySetInnerHTML={{ __html: htmlUtilities.sanitize(ReplaceHelperVariable(htmlencode.htmlDecode(currentMessage.text))) }}>
                                </div>
                            </div>
                            {currentMessage.isEditable ? <div className="form-group custom-control">
                                <i className="fas fa-check" style={{ color: '#337ab7' }}></i>
                                <label className="custom-control-label" style={{ marginLeft: 5 }} >Allow users to edit before sending</label>
                            </div> : ""}
                        </div>
                    </div>
                </div>
            </div>

            <AttachmentInstructionPopUp
                attachmentInstructions={this.props.attachmentInstructions}
                selectedInstruction={this.state.isUpdate ? currentMessage : initialAttachmentInstruction}
                show={this.state.showAttachmentInstructionPopUp}
                onHide={this.onCancelButtonClick}
                submitButtonClick={this.state.isUpdate ? this.editWelcomeMessage : this.addWelcomeMessage}
                buttonText={this.state.buttonText}
                title={this.state.title}
                defaultInstructionId={defaultMessage}
                isUpdate={this.state.isUpdate}
                helptexts={this.props.helptexts}
            />
        
        </div>;
    }
}

export default AttachmentInstruction;
