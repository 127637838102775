import * as React from 'react';
import { Alert, Col, Modal, Form, Button, FormGroup } from 'react-bootstrap';
import { OverlayLoader } from '../../../helper/OverlayLoader';
import { DropdownComponent } from '../../../common/controls/DropdownComponent';
import { VenusNotifier } from '../../../helper/VenusNotifier';
import { IOptions } from '../../../../components/common/ProcessReturnModal/AdditionalEsignDocuments/AdditionalEsignDocument';
import { AdditionalEsignDocuments } from '../../../../components/helper/Constants';

interface IEditDocumentTypeProps {
    show: boolean;
    selectedDocumentType: number;
    onSave: (documentType: number) => void;
    documentTypes: IOptions[];
    onCloseModal: () => void;
    onDelete: (documentType: number) => void;
}

export class EditAdditionalEsignDocument extends React.Component<IEditDocumentTypeProps, any>{
    state = {
        selectedType: 0
    }

    componentDidMount() {
        this.setState({ selectedType: this.props.selectedDocumentType });
    }

    private onChange = (value: any) => {
        this.setState({ selectedType: value });
    }

    private onCancel = () => {
        this.props.onCloseModal();
    }

    private onHide = () => {
        this.props.onCloseModal();
    }

    public render() {
        return <Modal className="report-change-status-modal" show={this.props.show} onHide={this.onHide}>
            <Modal.Header closeButton>
                <Modal.Title className='custom-modal-header'>
                    <span className='text-secondary fas fa-edit' style={{ color: 'grey', marginRight: '5px' }}></span>
                    Modify Added Document
                </Modal.Title>
            </Modal.Header>
            <Form>
                <Modal.Body>
                    <div className="row">
                        <Col sm={1}/>
                        <Col sm={3} className="text-right padT07">
                            Document Type:
                        </Col>
                        <Col sm={7} className="text-left">
                                <DropdownComponent
                                    id="ddlDocumentType"
                                    selectedValue={this.state.selectedType}
                                    onChange={this.onChange}
                                    options={this.props.documentTypes}
                                />
                        </Col>
                        <Col sm={1} />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="info" data-test-auto="53AEB091-8D93-4CCF-8D3D-291A4B9F0B44"
                        onClick={this.props.onDelete.bind(this, this.state.selectedType)}>
                        <i className='fas fa-trash-alt'></i>Delete
                    </Button>
                    <Button variant="default" data-test-auto="1EF69BC9-F8E8-496A-B5C8-24A8D2D3CC33" onClick={this.onCancel}>
                        <i className='fas fa-times'></i>Cancel
                    </Button>
                    <Button variant="info" data-test-auto="F6D28C0A-BB16-4254-8B45-24905270330A"
                        onClick={this.props.onSave.bind(this, this.state.selectedType)}>
                        <i className='fas fa-save'></i>Save
                    </Button>
                </Modal.Footer>
            </Form >
        </Modal >;
    }
}