import * as React from "react";
import { Nav } from "react-bootstrap";
import * as Constants from "../../../components/helper/Constants";
import { ITaxReturn, EngagementType } from "../../../components/common/TaxReturn";
import { DeliverToClient } from "./DeliverToClient";
import { DeliverToCompany } from "../../common/ProcessReturnModal/FinishProcessReturn/DeliverToCompany";
import { DeliverToController } from "./DeliverToController";
import { isIndividualEnagagementType } from "../../../components/helper/HelperFunctions";
import { IGroupInfo } from "../../../store/groupedreturns/GroupedReturnProcessState";

export interface FinishPopoverProps {
    taxreturn: ITaxReturn;
    deliverToClient: () => void;
    groupInfo?: IGroupInfo;
}
export interface FinishPopoverState {
    activeMenuItem: string;
}
export const FinishPopover: React.FunctionComponent<FinishPopoverProps> = ({ taxreturn, deliverToClient, groupInfo }) => {
    const [activeMenuItem, setActiveMenuItem] = React.useState<string>("");
    const [engagementType, setEngagementType] = React.useState<number>(0);

    React.useEffect(() => {
        if (groupInfo) {
            setActiveMenuItem(Constants.FinishProcessReturn.MenuItems.DeliverToController);
        } else if (isIndividualEnagagementType(taxreturn.engagementType)) {
            setActiveMenuItem(Constants.FinishProcessReturn.MenuItems.DeliverToClient);
        } else {
            setActiveMenuItem(Constants.FinishProcessReturn.MenuItems.DeliverToCompany);
        }

        setEngagementType(Number(EngagementType[taxreturn.engagementType]));
    }, [activeMenuItem]);

    const cancelFinish = () => {
        document.body.click();
        setActiveMenuItem("");
    };

    const menuToggleOpen = (event: any) => {
        if (event.target.nextElementSibling.style["display"] == "none") {
            event.target.style["display"] = "none";
            event.target.nextElementSibling.style["display"] = "block";
            event.target.parentElement.nextElementSibling.style["display"] = "block";
        } else {
            event.target.style["display"] = "block";
            event.target.nextElementSibling.style["display"] = "none";
            event.target.parentElement.nextElementSibling.style["display"] = "none";
        }
    };

    const menuToggleClose = (event: any) => {
        if (event.target.previousElementSibling.style["display"] == "none") {
            event.target.style["display"] = "none";
            event.target.previousElementSibling.style["display"] = "block";
            event.target.parentElement.nextElementSibling.style["display"] = "none";
        } else {
            event.target.style["display"] = "block";
            event.target.previousElementSibling.style["display"] = "none";
            event.target.parentElement.nextElementSibling.style["display"] = "block";
        }
    };

    const onMenuClick = (menuName: string) => {
        setActiveMenuItem(menuName);
    };

    const getMenuContent = () => {
        switch (activeMenuItem) {
            case Constants.FinishProcessReturn.MenuItems.DeliverToClient:
                return (
                    <div>
                        <DeliverToClient taxreturn={taxreturn} cancelFinish={cancelFinish} deliverToClient={deliverToClient} />
                    </div>
                );
            case Constants.FinishProcessReturn.MenuItems.DeliverToCompany:
                return (
                    <div className="height-inherit">
                        <DeliverToCompany taxreturn={taxreturn} cancelFinish={cancelFinish} deliverToClient={deliverToClient} />
                    </div>
                );
            case Constants.FinishProcessReturn.MenuItems.DeliverToController:
                return (
                    <div>
                        <DeliverToController
                            cancelFinish={cancelFinish}
                            deliverToController={deliverToClient}
                            groupInfo={groupInfo}
                        />
                    </div>
                );
        }
    };

    return (
        <div className="full-height">
            <div className="row height-inherit">
                <div className="col-lg-12 height-inherit dispFlex">
                    <div className="col-lg-4" style={{ paddingLeft: "0px", height: "98%" }}>
                        <div className="nav nav-tabs tabs-left height-inherit" style={{ height: "100%" }}>
                            <li className={"nav-item Delivery-dropdown-Menu width100"}>
                                <span
                                    className=" fa nav-link dropdown-toggle"
                                    role="button"
                                    style={{
                                        backgroundColor: "#f7f7f7",
                                        marginLeft: "-15px",
                                        paddingBottom: "10px",
                                        paddingTop: "10px",
                                        display: "block"
                                    }}
                                    data-toggle="Delivery"
                                    data-target="#Delivery-dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                >
                                    <span
                                        onClick={menuToggleOpen}
                                        className="fa fa-angle-right"
                                        style={{ display: "none", float: "right", paddingLeft: "20px" }}
                                    ></span>
                                    <span
                                        onClick={menuToggleClose}
                                        className="fa fa-angle-down"
                                        style={{ color: "grey", float: "right", paddingLeft: "20px" }}
                                    ></span>
                                    <span style={{ color: "#337ab7", fontSize: "16px" }}>Delivery</span>
                                </span>

                                <div className="Delivery nav nav-tabs tabs-left" id="Delivery-dropdown">
                                    {groupInfo && groupInfo.controllerInfo ? (
                                        <li
                                            className={
                                                activeMenuItem == Constants.FinishProcessReturn.MenuItems.DeliverToController
                                                    ? "active"
                                                    : ""
                                            }
                                        >
                                            <Nav.Link
                                                href="javascript:"
                                                style={{ border: "none", color: "#337ab7" }}
                                                onClick={() => {
                                                    onMenuClick(Constants.FinishProcessReturn.MenuItems.DeliverToController);
                                                }}
                                            >
                                                <i className="fa fa-paper-plane" style={{ paddingRight: "10px" }}></i>
                                                {Constants.FinishProcessReturn.MenuItems.DeliverToController}
                                            </Nav.Link>
                                        </li>
                                    ) : isIndividualEnagagementType(engagementType) ? (
                                        <li
                                            className={
                                                activeMenuItem == Constants.FinishProcessReturn.MenuItems.DeliverToClient
                                                    ? "active"
                                                    : ""
                                            }
                                        >
                                            <Nav.Link
                                                href="javascript:"
                                                style={{ border: "none", color: "#337ab7" }}
                                                onClick={() => {
                                                    onMenuClick(Constants.FinishProcessReturn.MenuItems.DeliverToClient);
                                                }}
                                            >
                                                <i className="fa fa-paper-plane" style={{ paddingRight: "10px" }}></i>
                                                {Constants.FinishProcessReturn.MenuItems.DeliverToClient}
                                            </Nav.Link>
                                        </li>
                                    ) : (
                                        <li
                                            className={
                                                activeMenuItem == Constants.FinishProcessReturn.MenuItems.DeliverToCompany
                                                    ? "active"
                                                    : ""
                                            }
                                        >
                                            <Nav.Link
                                                href="javascript:"
                                                style={{ border: "none", color: "#337ab7" }}
                                                onClick={() => {
                                                    onMenuClick(Constants.FinishProcessReturn.MenuItems.DeliverToCompany);
                                                }}
                                            >
                                                <i className="fa fa-paper-plane" style={{ paddingRight: "10px" }}></i>
                                                {Constants.FinishProcessReturn.MenuItems.DeliverToCompany}
                                            </Nav.Link>
                                        </li>
                                    )}
                                </div>
                            </li>
                        </div>
                    </div>
                    <div className="col-lg-8 height-inherit">{getMenuContent()}</div>
                </div>
            </div>
        </div>
    );
};
