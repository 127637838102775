import * as React from "react";
import { Modal, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { IModalProps } from "./ProfileObjects";
import { MyLoginHistoryTable } from "./MyLoginHistoryTable";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import * as UserLoginHistory from "../../../store/userManagement/UserLoginHistoryStore";
import { CheckBoxComponent } from "../../common/CheckBoxComponent";
import * as UserSettingStore from "../../../store/userManagement/UserSettingStore";
import { IUserDefaultSettingsStates } from "./MySettings";
import { IHelptextModel } from "../../../Core/ViewModels/Common/IHelptextModel";
import { fetchHelpText } from "../../helper/HelperFunctions";
import { userSettingConstants } from "../../helper/Constants";

interface MyLoginHistoryProps extends IModalProps {
    requestUserLoginHistory: (pageNo: number) => void;
    userLoginHistory: UserLoginHistory.IUserLoginHistoryState;
    userSettings: UserSettingStore.UserSettings;
    saveSettings: (settings: IUserDefaultSettingsStates, showUserSettingsNotification: boolean) => void;
    requestHelptexts?: (reload: boolean) => void;
    helptexts?: IHelptextModel[];
    showLoginHistory: boolean;
}

interface MyLoginHistoryState {
    pageNo: number;
    enableLastLogin: boolean;
    helptextLastLogin: string;
}
export class MyLoginHistory extends React.Component<MyLoginHistoryProps, MyLoginHistoryState> {
    constructor(props: MyLoginHistoryProps) {
        super(props);
        this.state = {
            pageNo: 1,
            enableLastLogin: true,
            helptextLastLogin: ""
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps: MyLoginHistoryProps) {
        this.setState({
            enableLastLogin:
                this.props.userSettings.settings !== undefined && this.props.userSettings.settings.defaultSettings !== undefined
                    ? this.props.userSettings.settings.defaultSettings.enableLastLogin
                    : true,
            helptextLastLogin: fetchHelpText(nextProps.helptexts, "37CC1680-2ABB-4749-AB02-A1EE246EDDBF")
        });
    }

    onPopupClose = () => {
        this.props.onToggle(userSettingConstants.loginHistory);
    };

    onPageChange = (page: number, pageSize: number) => {
        this.setState(
            {
                pageNo: page
            },
            this.fetchUserLoginHistory
        );
    };

    fetchUserLoginHistory() {
        const { pageNo } = this.state;
        this.props.requestUserLoginHistory(pageNo);
    }

    private changeLastLogin = (event: any) => {
        let settings = this.props.userSettings.settings;
        settings.defaultSettings.enableLastLogin = event.currentTarget.checked;
        this.props.saveSettings(settings, true);
        this.setState({
            enableLastLogin: event.currentTarget.checked
        });
    };

    render() {
        return (
            <Modal show={this.props.showLoginHistory} onHide={this.onPopupClose} className="my-login-history" size="lg">
                <Modal.Header closeButton>
                    <Modal.Title className="custom-modal-header">
                        <span className="fa fa-history" style={{ color: "grey", marginRight: "5px" }}></span>
                        My Login History
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {(() => {
                        if (!this.props.userLoginHistory) {
                            return (
                                <div>
                                    <h3>Loading...</h3>
                                </div>
                            );
                        } else if (this.props.userLoginHistory.deviceId == "")
                            //For logged in users before this feature, there exists no device_id.
                            return (
                                <div>
                                    <h3>Please Logout and Login again to see your Login History</h3>
                                </div>
                            );
                        else
                            return (
                                <div>
                                    <div className="textalign-right">
                                        <Button
                                            variant="default"
                                            onClick={() => this.props.requestUserLoginHistory(this.state.pageNo)}
                                            className="btn-white btn-only marR10"
                                            style={{ paddingLeft: "12px" }}
                                        >
                                            <span
                                                className="fas fa-redo-alt"
                                                style={{
                                                    padding: "4px 0",
                                                    textShadow: "0px 1px 0px rgba(255,255,255,.5)",
                                                    fontSize: "12px"
                                                }}
                                            ></span>
                                        </Button>
                                    </div>
                                    <LoadingOverlay>
                                        <MyLoginHistoryTable
                                            userLoginHistory={this.props.userLoginHistory}
                                            requestUserLoginHistory={this.props.requestUserLoginHistory}
                                            pageNo={this.state.pageNo}
                                            onPageChange={this.onPageChange}
                                        />
                                        <Loader loading={this.props.userLoginHistory.isLoginHistoryLoading} text={"Loading"} />
                                    </LoadingOverlay>
                                </div>
                            );
                    })()}
                </Modal.Body>
                <Modal.Footer>
                    <div className="modal-footer-left float-left padT07 div-flex">
                        <CheckBoxComponent
                            id={"lastLoginEnable"}
                            text={"Enable Last Login Prompt"}
                            checked={this.state.enableLastLogin}
                            onChange={this.changeLastLogin}
                            datatestAuto={"989E572E-F52B-466D-B675-D99B6193D66B"}
                        />
                        <Link
                            to={"#"}
                            className="help-icon marL05"
                            data-placement="right"
                            data-toggle="tooltip"
                            title={this.state.helptextLastLogin}
                        >
                            <i className="fas fa-question-circle"></i>
                        </Link>
                    </div>
                    <Button className="btn-white" variant="default" onClick={this.onPopupClose}>
                        <i className="fas fa-times"></i>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default MyLoginHistory;
