import { connect } from 'react-redux';
import * as SavedMessageStore from '../../../store/settings/SavedMessageStore';
import * as CompanyStore from '../../../store/company/CompanyStore';
import * as Component from '../SavedMessages';
import { ApplicationState } from '../../../store';
import * as UserSettingsStore from '../../../store/userManagement/UserSettingStore';
import * as AttachmentInstructionStore from '../../../store/settings/AttachmentInstructionStore';
import * as ApplicationHelptextStore from '../../../store/common/ApplicationHelptextStore';
import * as PaperFiledMessageStore from '../../../store/settings/PaperFiledMessageStore';
import * as SSRSettingsStore from '../../../store/company/settings/SSRSettingsStore';

export default connect(
    (state: ApplicationState) => ({
        savedMessageList: state.savedMessage,
        companyData: state.companyData,
        attachmentInstructionList: state.attachmentInstruction,
        helptexts: state.Helptexts,
        paperFiledMessageList: state.paperFiledMessage,
    }),
    {
        ...SavedMessageStore.actionCreators,
        ...CompanyStore.actionCreators,
        ...UserSettingsStore.actionCreators,
        ...AttachmentInstructionStore.actionCreators,
        ...ApplicationHelptextStore.actionCreators,
        ...PaperFiledMessageStore.actionCreators,
        ...SSRSettingsStore.actionCreators,
    }
)(Component.SavedMessages as any);