import * as React from 'react';
import { ICompanySettings } from '../../../Core/ViewModels/Company/CompanySettingsViewModel';
import { CheckBoxComponent } from '../../common/CheckBoxComponent';
import Select from 'react-select';
import { Link } from 'react-router-dom';
import { IHelptextModel } from '../../../Core/ViewModels/Common/IHelptextModel';
import { fetchHelpText } from '../../../components/helper/HelperFunctions';

interface IPaymentNotification {
    companySettings: ICompanySettings
    handlePaymentVoucherReminder(event: any): void;
    handleSettingVoucherReminderDays(event: any): void;
    handleVoucherPaymentNotification(event: any): void;
    helptexts?: IHelptextModel[];
}

export class PaymentVoucherNotification extends React.Component<IPaymentNotification, {}>{
    buildOptions() {
        var arr = [];
        for (let i = 1; i <= 30; i++) {
            if (i == 1) {
                arr.push({
                    value: i,
                    label: i + " day"
                })
            }
            else {
                arr.push({
                    value: i,
                    label: i + " days"
                })
            }
        }

        return arr;
    }


    public render() {

        return <div id="divPaymentVoucherNotifications" className="new-main-content">
            <h3>Payment Vouchers</h3>
            <div className="marL20">
                <CheckBoxComponent
                    id={"EnablePaymentVoucherReminder"}
                    text={"Enable payment voucher reminders"}
                    checked={this.props.companySettings.notificationSettingsModel.paymentVoucherNotificationSettings.enablePaymentVoucherReminder}
                    onChange={this.props.handlePaymentVoucherReminder}
                    datatestAuto={"8BAE0132-3B3C-492A-8399-B26C6F7582E8"}
                />
                <div className="row marT20" style={{ marginLeft: '0px' }}>
                    <div className="padT07 voucher-value">Set default reminder to</div>
                    <div className="select-reminder-control" style={{ width: "90px !important" }}>
                        <Select
                            name="form-field-name"
                            value={this.props.companySettings.notificationSettingsModel.paymentVoucherNotificationSettings.noOfDaysForVoucherReminder}
                            onChange={this.props.handleSettingVoucherReminderDays}
                            options={this.buildOptions()}
                            data-test-auto="52A69016-2B50-4B88-84B4-3E82ED02586A"
                            clearable={false}
                        />
                    </div>
                    <div className="padT07 padL10">before due date</div>
                </div>
                <div className="marT20" style={{ display: "flex" }}>
                    <CheckBoxComponent
                        id={"EnableVoucherPaymentNotification"}
                        text={"Receive an email notification when your client adds payment information"}
                        onChange={this.props.handleVoucherPaymentNotification}
                        checked={this.props.companySettings.notificationSettingsModel.paymentVoucherNotificationSettings.paymentNotification}
                    />
                    <Link to={'#'}
                        className="help-icon upload-help"
                        data-placement="right"
                        data-toggle="tooltip"
                        title={fetchHelpText(this.props.helptexts, "073B0152-E788-4810-86F4-9A879B6E3E36")}>
                        <i className="fas fa-question-circle" />
                    </Link>
                </div>
            </div>
        </div>;
    }
}

export default PaymentVoucherNotification;