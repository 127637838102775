export enum TaxCodeType {
    None = 0,
    SSR = 1,
    Signature = 2,
    SSO = 3
}
export enum PaymentFlowType {
    None = 0,
    PayNow = 1,
    PayasYouGo = 2
}

export enum ReportPaymentFlowType {
    None = 0,
    PayNow = 1,
    PayasYouGo = 2,
    ManuallyPaid = 3,
}

export enum ProductType {
    None = 0,
    SSR = 1,
    Signature = 2,
    Organizers = 3,
    Extensions = 4,
    Exchange = 5,
}

export enum PaymentOption {
    None = 0,
    AttachedURL = 1,
    Stripe = 2,
    ExternalSystem = 3
}

export enum ReportingEngineType {
    TaxCaddy = "TaxCaddy"
}