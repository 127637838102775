import { addTask } from 'domain-task';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../';
import { actionTypes } from '../../types/ActionTypes';
import { MessageDetails } from '../../Core/ViewModels/CompanySettings/SavedMessageViewModel';
import * as Constants from '../../components/helper/Constants';
import { handleResponse } from '../Library';
import { StatusType, NotificationAction } from '../common/NotificationStore';
import * as SSRSettingsStore from '../company/settings/SSRSettingsStore';
import { API_BASE_URL } from '../../utils/constants';
import { ISSRSettings, SSRSettings } from '../../Core/ViewModels/Company/Settings/SSRSettingsViewModel';
import * as CRSHelper from '../../components/helper/CRSHelper';
import { SSRGetResourceIds } from "../../components/helper/ResourceIdConstants";

export interface IPaperFiledMessageDictionary {
    [index: number]: PaperFiledMessageState;
}

export interface PaperFiledMessageStoreState {
    data: IPaperFiledMessageDictionary;
    isLoading: boolean;
}

export interface PaperFiledMessageState {
    messageDetails: MessageDetails;
}

interface RequestMessasgeListAction {
    type: actionTypes.REQUEST_PAPERFILED_MESSAGES;
}

interface ReceiveMessageListAction {
    type: actionTypes.RECEIVE_PAPERFILED_MESSAGES;
    messageDetails: MessageDetails;
    messageId: number;
}

interface DeleteMessageDetail {
    type: actionTypes.DELETE_PAPERFILED_MESSAGE;
    messageDetail: MessageDetails;
}

type KnownAction =
    RequestMessasgeListAction |
    ReceiveMessageListAction |
    DeleteMessageDetail |
    NotificationAction

export const actionCreators = {
    requestPaperFiledMessages: (reload: boolean = false): AppThunkAction<KnownAction> => (dispatch, getState) => {
        if (reload || !Object.keys(getState().paperFiledMessage.data).length) {
            const fetchTask = fetch(API_BASE_URL + 'api/PaperFiledMessage/GetAllMessages', {
                method: 'GET',
                credentials: 'include',
                headers : new Headers( {'X-Resource-Id' : SSRGetResourceIds.PaperFileInstructionsAddControl} )
            })
                .then(response => response.json() as Promise<MessageDetails[]>)
                .then((data) => {
                    if (data) {
                        data.map((value, index) => {
                            dispatch({
                                type: actionTypes.RECEIVE_PAPERFILED_MESSAGES, messageDetails: value,
                                messageId: value.id
                            });
                        });
                    }
                }).catch(function (error) {
                    const statusMessage: any = error.statusText?.message ?? error.statusText;
                    if (typeof(statusMessage) === "string") {
                        dispatch({
                            type: actionTypes.NOTIFICATION, statusMessage: statusMessage, statusType: StatusType.Error
                        });
                    }
                }); addTask(fetchTask);

            dispatch({ type: actionTypes.REQUEST_PAPERFILED_MESSAGES });
        }
    },

    savePaperFiledMessageDetail: (message: MessageDetails, ssrSettings: ISSRSettings, isSetDefault: boolean, resourceId: string = ""): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(API_BASE_URL + 'api/PaperFiledMessage', {
            method: 'POST',
            credentials: 'include',
            body: CRSHelper.encodeSpecialCharactersOnly(JSON.stringify(message)),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-Resource-Id': resourceId,
                'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
            }
        })
            .then(handleResponse)
            .then((newId) => {
                let messageDetail = Object.assign({}, message);
                messageDetail.id = newId
                ssrSettings = new SSRSettings(ssrSettings.defaultMessageSettings);
                if (isSetDefault && ssrSettings.defaultMessageSettings) {
                    ssrSettings.defaultMessageSettings.paperFiledMessage = newId;
                    let action: any = SSRSettingsStore.actionCreators.updateSSRSettings(ssrSettings, undefined, resourceId)
                    dispatch(action);
                    dispatch({
                        type: actionTypes.RECEIVE_PAPERFILED_MESSAGES, messageDetails: messageDetail, messageId: newId
                    });
                }
                else {
                    dispatch({
                        type: actionTypes.RECEIVE_PAPERFILED_MESSAGES, messageDetails: messageDetail, messageId: newId
                    });
                }
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: Constants.SavedMessageConstants.SaveMessageSuccess, statusType: StatusType.Success
                });
            }).catch(error => {
                const statusMessage: any = error.statusText?.message ?? error.statusText;
                if (typeof(statusMessage) === "string") {
                    dispatch({
                        type: actionTypes.NOTIFICATION, statusMessage: statusMessage, statusType: StatusType.Error
                    });
                }
            });
        addTask(fetchTask);
    },

    updatePaperFiledMessageDetail: (message: MessageDetails, resourceId: string = ""): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(API_BASE_URL + 'api/PaperFiledMessage', {
            method: 'PUT',
            credentials: 'include',
            body: CRSHelper.encodeSpecialCharactersOnly(JSON.stringify(message)),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-Resource-Id': resourceId,
                'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
            }
        })
            .then(handleResponse)
            .then(() => {
                dispatch({
                    type: actionTypes.RECEIVE_PAPERFILED_MESSAGES, messageDetails: message, messageId: message.id
                });
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: Constants.SavedMessageConstants.UpdateMessageSuccess,
                    statusType: StatusType.Success
                });
            }).catch(error => {
                const statusMessage: any = error.statusText?.message ?? error.statusText;
                if (typeof(statusMessage) === "string") {
                    dispatch({
                        type: actionTypes.NOTIFICATION, statusMessage: statusMessage, statusType: StatusType.Error
                    });
                }
            });
        addTask(fetchTask);
    },

    deletePaperFiledMessageDetail: (message: MessageDetails, resourceId: string = ""): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(API_BASE_URL + 'api/PaperFiledMessage', {
            method: 'DELETE',
            credentials: 'include',
            body: CRSHelper.encodeSpecialCharactersOnly(JSON.stringify(message)),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-Resource-Id': resourceId,
                'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
            }
        })
            .then(handleResponse)
            .then(() => {
                dispatch({
                    type: actionTypes.DELETE_PAPERFILED_MESSAGE, messageDetail: message
                });
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: Constants.SavedMessageConstants.DeleteMessageSuccess,
                    statusType: StatusType.Success
                });
            }).catch(error => {
                const statusMessage: any = error.statusText?.message ?? error.statusText;
                if (typeof(statusMessage) === "string") {
                    dispatch({
                        type: actionTypes.NOTIFICATION, statusMessage: statusMessage, statusType: StatusType.Error
                    });
                }
            });
        addTask(fetchTask);
    }
}

const savedMessageDic: IPaperFiledMessageDictionary = [];

const unloadedState: PaperFiledMessageStoreState =
    {
        data: { ...savedMessageDic },
        isLoading: true
    } as PaperFiledMessageStoreState;

export const reducer: Reducer<PaperFiledMessageStoreState> = (state = unloadedState, incomingAction) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case actionTypes.REQUEST_PAPERFILED_MESSAGES:
            return {
                ...unloadedState
            } as PaperFiledMessageStoreState;
        case actionTypes.RECEIVE_PAPERFILED_MESSAGES:
            let received: PaperFiledMessageStoreState = { ...state };
            received.data[action.messageId] = { messageDetails: action.messageDetails }
            received.isLoading = false;
            return received;
        case actionTypes.DELETE_PAPERFILED_MESSAGE:
            let deleteMessage: PaperFiledMessageStoreState = { ...state }
            if (deleteMessage.data[action.messageDetail.id]) {
                delete deleteMessage.data[action.messageDetail.id];
                deleteMessage.isLoading = false;
            }
            return deleteMessage;
    }
    return state;
};
