import * as React from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import ToggleSwitch from '../../common/ToggleSwitch';
import { IUserNotificationEventsViewModel,NotificationType } from '../../../Core/ViewModels/User/NotificationSettingModel';

export interface IUserNotificationEventsProps {
    onChangeNotificationEvents(notificationViewModel: IUserNotificationEventsViewModel): void;
    notificationEvent: IUserNotificationEventsViewModel[]
}

interface IUserNotificationEventState {
    notificationEvent: IUserNotificationEventsViewModel[]
}


export class UserNotificationEvents extends React.Component<IUserNotificationEventsProps, IUserNotificationEventState>{
    state: IUserNotificationEventState = {
        notificationEvent: []
    }

    UNSAFE_componentWillMount() {
        this.setState(
            { notificationEvent: { ... this.props.notificationEvent } }
        );
    }

    handleToggleSwitchChange = (type: NotificationType, notificationId: number, event: any) => {
        const toggleValue = event.currentTarget.checked;
        const index = notificationId - 1;
        let tempNotification = Object.assign({}, this.state.notificationEvent);
        if (type == NotificationType.Application)
            tempNotification[index].appNotification = toggleValue
        else
            tempNotification[index].mailNotification = toggleValue
        this.setState({
            notificationEvent: tempNotification
        }, () => this.props.onChangeNotificationEvents(this.state.notificationEvent[index]));
    }

    render() {
        return <Card className="settingsPanel">
                <Card.Body >
                    <Row>
                        <Col className="text-left" sm={6} >
                            <h4>Notify me when</h4>
                        </Col>
                        <Col className="text-center" sm={6} >
                            <h4>Notify me on</h4>
                        </Col>
                    </Row>
                    <Row className="marB15">
                        <Col className="text-right padT04" sm={8} >
                            <h5>In-App</h5>
                        </Col>
                        <Col className="text-right" sm={3} >
                            <h5>Email</h5>
                        </Col>
                    </Row>
                    <Row className="marB15">
                        <Col className="text-left padT04 border-right" sm={6} >
                            <h5>Return Assigned to me</h5>
                        </Col>
                        <Col className="padT04" >
                            <ToggleSwitch
                                data-test-auto="bb080790-d227-11ea-87d0-0242ac130003"
                                switched={this.state.notificationEvent[0].appNotification}
                                handleChange={
                                    (e) => this.handleToggleSwitchChange(NotificationType.Application,
                                        this.state.notificationEvent[0].eventId, e)
                                }
                            />
                            <ToggleSwitch
                                data-test-auto="bb0806d2-d227-11ea-87d0-0242ac130003"
                                switched={this.state.notificationEvent[0].mailNotification}
                                handleChange={(e) => this.handleToggleSwitchChange(NotificationType.Email,
                                    this.state.notificationEvent[0].eventId, e)}
                            />
                        </Col>
                    </Row>
                    <Row className="marB15">
                        <Col className="text-left padT04" sm={6} >
                            <h5>Return ready for processing</h5>
                        </Col>
                        <Col className="padT04" >
                            <ToggleSwitch
                                data-test-auto="bb08060a-d227-11ea-87d0-0242ac130003"
                                switched={this.state.notificationEvent[1].appNotification}
                                handleChange={(e) => this.handleToggleSwitchChange(NotificationType.Application,
                                      this.state.notificationEvent[1].eventId, e)}
                            />
                            <ToggleSwitch
                                data-test-auto="bb080434-d227-11ea-87d0-0242ac130003"
                                switched={this.state.notificationEvent[1].mailNotification}
                                handleChange={(e) => this.handleToggleSwitchChange(NotificationType.Email,
                                    this.state.notificationEvent[1].eventId, e)}
                            />
                        </Col>
                    </Row>
                    <Row className="marB15">
                        <Col className="text-left padT04" sm={6} >
                            <h5>Approve for delivery</h5>
                        </Col>
                        <Col className="padT04" >
                            <ToggleSwitch
                                data-test-auto="bb080380-d227-11ea-87d0-0242ac130003"
                                switched={this.state.notificationEvent[2].appNotification}
                                handleChange={(e) => this.handleToggleSwitchChange(NotificationType.Application,
                                    this.state.notificationEvent[2].eventId, e)}
                            />
                            <ToggleSwitch
                                data-test-auto="bb0802c2-d227-11ea-87d0-0242ac130003"
                                switched={this.state.notificationEvent[2].mailNotification}
                                handleChange={(e) => this.handleToggleSwitchChange(NotificationType.Email,
                                    this.state.notificationEvent[2].eventId, e)}
                            />
                        </Col>
                    </Row>
                    <Row className="marB15">
                        <Col className="text-left padT04" sm={6} >
                            <h5>Reminders for emailing</h5>
                        </Col>
                        <Col className="padT04" >
                            <ToggleSwitch
                                data-test-auto="bb080204-d227-11ea-87d0-0242ac130003"
                                switched={this.state.notificationEvent[3].appNotification}
                                handleChange={(e) => this.handleToggleSwitchChange(NotificationType.Application,
                                    this.state.notificationEvent[3].eventId, e)}
                            />
                            <ToggleSwitch
                                data-test-auto="bb080146-d227-11ea-87d0-0242ac130003"
                                switched={this.state.notificationEvent[3].mailNotification}
                                handleChange={(e) => this.handleToggleSwitchChange(NotificationType.Email,
                                    this.state.notificationEvent[3].eventId, e)}
                            />
                        </Col>
                    </Row>
                    <Row className="marB15">
                        <Col className="text-left padT04" sm={6} >
                            <h5>Ready to print and download</h5>
                        </Col>
                        <Col className="padT04" >
                            <ToggleSwitch
                                data-test-auto="bb08006a-d227-11ea-87d0-0242ac130003"
                                switched={this.state.notificationEvent[4].appNotification}
                                handleChange={(e) => this.handleToggleSwitchChange(NotificationType.Application,
                                    this.state.notificationEvent[4].eventId, e)}
                            />
                            <ToggleSwitch
                                data-test-auto="bb07fe3a-d227-11ea-87d0-0242ac130003"
                                switched={this.state.notificationEvent[4].mailNotification}
                                handleChange={(e) => this.handleToggleSwitchChange(NotificationType.Email,
                                    this.state.notificationEvent[4].eventId, e)}
                            />
                        </Col>
                    </Row>
                    <Row className="marB15">
                        <Col className="text-left padT04" sm={6} >
                            <h5>Return cancelled for correction</h5>
                        </Col>
                        <Col className="padT04" >
                            <ToggleSwitch
                                data-test-auto="bb07fd72-d227-11ea-87d0-0242ac130003"
                                switched={this.state.notificationEvent[5].appNotification}
                                handleChange={(e) => this.handleToggleSwitchChange(NotificationType.Application,
                                    this.state.notificationEvent[5].eventId, e)}
                            />
                            <ToggleSwitch
                                data-test-auto="bb07fca0-d227-11ea-87d0-0242ac130003"
                                switched={this.state.notificationEvent[5].mailNotification}
                                handleChange={(e) => this.handleToggleSwitchChange(NotificationType.Email,
                                    this.state.notificationEvent[5].eventId, e)}
                            />
                        </Col>
                    </Row>
                    <Row className="marB15">
                        <Col className="text-left padT04" sm={6} >
                            <h5>Signer uploaded signed files</h5>
                        </Col>
                        <Col className="padT04" >
                            <ToggleSwitch
                                data-test-auto="bb07fbb0-d227-11ea-87d0-0242ac130003"
                                switched={this.state.notificationEvent[6].appNotification}
                                handleChange={(e) => this.handleToggleSwitchChange(NotificationType.Application,
                                    this.state.notificationEvent[6].eventId, e)}
                            />
                            <ToggleSwitch
                                data-test-auto="bb07f99e-d227-11ea-87d0-0242ac130003"
                                switched={this.state.notificationEvent[6].mailNotification}
                                handleChange={(e) => this.handleToggleSwitchChange(NotificationType.Email,
                                    this.state.notificationEvent[6].eventId, e)}
                            />
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
    }

}
