import * as React from "react";
import "react-bootstrap-table/css/react-bootstrap-table.css";
import { TaxSoftwareType } from "../../../Core/ViewModels/Company/CompanySettingsViewModel";
import { ISSRUserModel, IParterUserModel } from "../../../Core/ViewModels/User/UserViewModel";
import { IDocumentAccess } from "../../common/TaxReturn";
import * as UserHelper from "../../helper/UserHelperFunctions";
import { GoSystemUploadRecalledDocumentModal } from "./TaxSoftwareTypeUpload/GoSystem/GoSystemUploadRecalledDocumentModal";
import { ProSystemUploadRecalledDocumentModal } from "./TaxSoftwareTypeUpload/ProSystemUploadRecalledDocumentModal";
import { UltraTaxUploadRecalledDocumentModal } from "./TaxSoftwareTypeUpload/UltraTaxUploadRecalledDocumentModal";
import { LacerteUploadRecalledDocumentModal } from "./TaxSoftwareTypeUpload/Lacerte/LacerteUploadRecalledDocumentModal";
import { IReturnAccessSettingsModel } from "src/Core/ViewModels/Company/CompanySettingsViewModel";
import { IClientManagementSetting } from "../../../store/company/SuiteClientManagementSettingStore";

interface UploadModalProps {
    taxSoftwareType: TaxSoftwareType;
    documentKey: string;
    onShow: boolean;
    onHide(): void;
    userData?: any;
    selectedERO: number;
    getUploadLink: (url: string, callback?: () => void, resourceId?: string) => void;
    processTaxReturn: (url: string, callback: () => void) => void;
    submitTaxReturn: (url: string, taxData: string, callback: () => void, resourceId?: string) => void;
    uploadConfirmation: (documentKey: string, clientId: string) => void;
    isRecalled?: boolean;
    parentResourceIdentifier?: string;
    ssrUsers: ISSRUserModel[];
    returnAccessSettings?: IReturnAccessSettingsModel;
    isGroupedReturn: boolean;
    clientManagementSetting: IClientManagementSetting;
    requestClientManagementSetting: () => void;
}
export interface UploadModelstate {
    EROs: IParterUserModel[];
    defaultERO: number;
    availableUsers: ISSRUserModel[];
}

export interface TaxReturnUploadData {
    number?: number;
    clientName?: string;
    name?: string;
    progressBar?: number;
    size?: string;
    status?: string;
    clientId?: string;
    partnerId?: number;
    setAccess?: string;
    sasGuid?: string;
    gridRowDisable: boolean;
    documentAccess: IDocumentAccess;
}

export class UploadRecalledDocumentModal extends React.Component<UploadModalProps, UploadModelstate> {
    constructor(props: UploadModalProps) {
        super(props);
        this.state = {
            defaultERO: 0,
            EROs: [],
            availableUsers: []
        };
        this.handleClose = this.handleClose.bind(this);
    }

    UNSAFE_componentWillReceiveProps(nextProps: UploadModalProps) {
        if (nextProps.selectedERO) {
            let partners = UserHelper.getPartnerUsers();
            this.setState({ EROs: partners, defaultERO: this.props.selectedERO, availableUsers: this.props.ssrUsers });
        }
    }

    componentDidMount() {
        this.getAvailableUsers();
    }

    getAvailableUsers() {
        this.setState({
            availableUsers: this.props.ssrUsers
        });
    }

    private handleClose() {
        this.props.onHide();
    }

    public render() {
        return (
            <div>
                <ProSystemUploadRecalledDocumentModal
                    onShow={this.props.onShow && this.props.taxSoftwareType == TaxSoftwareType.ProSystems}
                    onHide={this.handleClose}
                    selectedERO={this.state.defaultERO}
                    users={this.state.availableUsers}
                    documentKey={this.props.documentKey}
                    EROs={this.state.EROs}
                    getUploadLink={this.props.getUploadLink}
                    processTaxReturn={this.props.processTaxReturn}
                    submitTaxReturn={this.props.submitTaxReturn}
                    uploadConfirmation={this.props.uploadConfirmation}
                    isRecalled={this.props.isRecalled}
                    parentResourceIdentifier={this.props.parentResourceIdentifier}
                    ssravailableUsers={this.props.ssrUsers}
                    returnAccessSettings={this.props.returnAccessSettings}
                    isGroupedReturn={this.props.isGroupedReturn}
                    clientManagementSetting={this.props.clientManagementSetting}
                    requestClientManagementSetting={this.props.requestClientManagementSetting}
                />

                <UltraTaxUploadRecalledDocumentModal
                    onShow={
                        this.props.onShow &&
                        (this.props.taxSoftwareType === TaxSoftwareType.UltraTax ||
                            this.props.taxSoftwareType === TaxSoftwareType.UltraTaxM18)
                    }
                    onHide={this.handleClose}
                    defaultERO={this.state.defaultERO}
                    availableUsers={this.state.availableUsers}
                    documentKey={this.props.documentKey}
                    getUploadLink={this.props.getUploadLink}
                    submitTaxReturn={this.props.submitTaxReturn}
                    uploadConfirmation={this.props.uploadConfirmation}
                    isRecalled={this.props.isRecalled}
                    parentResourceIdentifier={this.props.parentResourceIdentifier}
                    ssravailableUsers={this.props.ssrUsers}
                    returnAccessSettings={this.props.returnAccessSettings}
                    isGroupedReturn={this.props.isGroupedReturn}
                />

                <GoSystemUploadRecalledDocumentModal
                    onShow={this.props.onShow && this.props.taxSoftwareType == TaxSoftwareType.GoSystem}
                    onHide={this.handleClose}
                    defaultERO={this.state.defaultERO}
                    EROs={this.state.EROs}
                    availableUsers={this.state.availableUsers}
                    documentKey={this.props.documentKey}
                    getUploadLink={this.props.getUploadLink}
                    submitTaxReturn={this.props.submitTaxReturn}
                    uploadConfirmation={this.props.uploadConfirmation}
                    isRecalled={this.props.isRecalled}
                    parentResourceIdentifier={this.props.parentResourceIdentifier}
                    ssravailableUsers={this.props.ssrUsers}
                    returnAccessSettings={this.props.returnAccessSettings}
                    isGroupedReturn={this.props.isGroupedReturn}
                />

                <LacerteUploadRecalledDocumentModal
                    onShow={this.props.onShow && this.props.taxSoftwareType == TaxSoftwareType.Lacerte}
                    onHide={this.handleClose}
                    defaultERO={this.state.defaultERO}
                    availableUsers={this.state.availableUsers}
                    documentKey={this.props.documentKey}
                    getUploadLink={this.props.getUploadLink}
                    submitTaxReturn={this.props.submitTaxReturn}
                    uploadConfirmation={this.props.uploadConfirmation}
                    isRecalled={this.props.isRecalled}
                    parentResourceIdentifier={this.props.parentResourceIdentifier}
                    ssravailableUsers={this.props.ssrUsers}
                    returnAccessSettings={this.props.returnAccessSettings}
                    isGroupedReturn={this.props.isGroupedReturn}
                />
            </div>
        );
    }
}
export default UploadRecalledDocumentModal;
