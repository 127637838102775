import * as React from 'react';
import * as ReacrDOM from 'react-dom';
import { Link } from 'react-router-dom';
import { IHelptextModel } from '../../../Core/ViewModels/Common/IHelptextModel';
import { fetchHelpText } from '../../helper/HelperFunctions';

export class ClientInstructionHeader extends React.Component<any, {}> {
    public render() {
        return (
            <div>
                <h3>Client Instructions</h3>
                <hr />
                <div>
                    <div className="marB15 ">Below are the instructions for each step of the process. Default instructions are provided, which you may change to more effectively communicate with your clients.
                    <Link to={'#'} className="help-icon" data-placement="right" data-toggle="tooltip"
                            title={fetchHelpText(this.props.helptexts, "F6A9DCFD-9EF9-4489-897D-56EC82C60BB7")}>
                            <i className="fas fa-question-circle"></i>
                        </Link>
                    </div>
                </div>
            </div>
        )
    }
}