import * as React from 'react';
import { Col, FormControl, Row } from 'react-bootstrap';
import { ReportProblemProperty } from './ReportProblemModel';
import { PhoneNumberComponent } from '../PhoneNumberComponent';

interface ISpouseProps {
    spouseName: string,
    spouseEmail: string;
    handleValueChange(item: ReportProblemProperty, value: string): void;
    disabled: boolean;
}

export const SpouseDetails = (props: ISpouseProps) => {
    return (
        <div>
            <Row className="report-problem-field">
                <div style={{ display: 'contents' }}>

                    <Col sm={2} >
                        <div className="font700">Spouse Name</div>
                    </Col>
                    <Col sm={4}>
                        <FormControl
                            type="text"
                            value={props.spouseName}
                            placeholder="Enter Spouse Name"
                            onChange={(event: any) => { props.handleValueChange(ReportProblemProperty.spouseName, event.target?.value) }}
                            onBlur={(event: any) => { props.handleValueChange(ReportProblemProperty.spouseName, event.target?.value) }}
                            disabled={props.disabled}
                        />
                    </Col>

                    <Col sm={2} >
                        <div className="font700">Spouse Email</div>
                    </Col>
                    <Col sm={4}>
                        <FormControl
                            type="text"
                            value={props.spouseEmail}
                            placeholder="Enter Spouse Email"
                            onChange={(event: any) => { props.handleValueChange(ReportProblemProperty.spouseEmail, event.target?.value) }}
                            onBlur={(event: any) => { props.handleValueChange(ReportProblemProperty.spouseEmail, event.target?.value) }}
                            disabled={props.disabled}
                        />
                    </Col>
                </div>
            </Row>
        </div>
    );
} 