

export interface IAccountViewModel {
    businessType: string,
    address: IAddress,
    name: string,
    phone: string,
    defaultCurrency: string,
    email: string,
    created?: Date,
    payoutsEnabled: boolean,
    id: string
}

export interface IAddress {
    line1: string,
    line2: string,
    city: string,
    country: string,
    state: string,
    postalCode: string
}

export const intialAccountDetailsViewModel: IAccountViewModel =
{
    businessType: "",
    address: {
        line1: "",
        line2: "",
        city: "",
        country: "",
        state: "",
        postalCode: ""
    },
    name: "",
    phone: "",
    defaultCurrency: "",
    email: "",
    created: new Date(),
    payoutsEnabled: false,
    id: ""
}