import React, { useEffect, useState } from "react";
import DraggableList from "../DraggableList";
import { IDragAndDropData } from "../DragAndDrop.model";
import InfoIcon from "src/assets/images/InfoIcon";

interface SourceProps {
    destinationData: IDragAndDropData[];
    sourceData: IDragAndDropData[];
    handleDropChange: (destination: IDragAndDropData[], source: IDragAndDropData[], usersRemoved?: IDragAndDropData[]) => void;
    sourceHeading?: string;
    sourceHeadingInfoText?: string;
    sourceWidth?: string;
    sourceHeight?: string;
    dndUniqueID?: string;
    selectedList: any;
    setSelectedList: (selectedList: any) => void;
    disableDragAndDrop?: boolean;
}

function Source(props: SourceProps) {
    const {
        destinationData = [],
        sourceData = [],
        handleDropChange = () => null,
        sourceHeading,
        sourceHeadingInfoText,
        sourceWidth,
        sourceHeight,
        disableDragAndDrop
    } = props;

    const [source, setSource] = useState<IDragAndDropData[]>([]);

    useEffect(() => {
        setSource(sourceData);
    }, [sourceData]);

    const dragOverHandler = (event: any) => {
        event.preventDefault();
    };

    const dropHandler = (event: any) => {
        const data = JSON.parse(event.dataTransfer.getData("text"));
        const dndUniqueID = event.dataTransfer.getData("dndUniqueID");
        if (dndUniqueID === props.dndUniqueID)
            if (
                sourceData.some((ele: any) => data.findIndex((datum: any) => datum.value === ele.value) === -1) ||
                !sourceData.length
            ) {
                const newSourceList = [...data, ...sourceData];
                const newDestinationList = destinationData.filter(
                    (ele: any) => !data.some((eachData: any) => eachData.value === ele.value)
                );
                handleDropChange(newDestinationList, newSourceList, data);
            }
    };

    return (
        <article className="sourceDataContainer" style={sourceWidth ? { width: sourceWidth } : null}>
            {sourceHeading && (
                <div className="sourceDataHeaderDiv">
                    <span className="sourceDataHeader">{sourceHeading}</span>
                    {sourceHeadingInfoText && (
                        <span className="sourceDataInfo" title={sourceHeadingInfoText}>
                            <InfoIcon />
                        </span>
                    )}
                </div>
            )}
            {disableDragAndDrop ? (
                <ul
                    className="list_container"
                    style={sourceHeight ? { height: sourceHeight } : null}
                    data-dnduniqueid={props.dndUniqueID}
                >
                    <DraggableList
                        listData={source}
                        dndUniqueID={props.dndUniqueID}
                        selectedList={props.selectedList}
                        setSelectedList={props.setSelectedList}
                        disableDragAndDrop={disableDragAndDrop}
                    />
                </ul>
            ) : (
                <ul
                    className="list_container"
                    onDragOver={(event) => dragOverHandler(event)}
                    onDrop={(event) => dropHandler(event)}
                    style={sourceHeight ? { height: sourceHeight } : null}
                    data-dnduniqueid={props.dndUniqueID}
                >
                    <DraggableList
                        listData={source}
                        dndUniqueID={props.dndUniqueID}
                        selectedList={props.selectedList}
                        setSelectedList={props.setSelectedList}
                        disableDragAndDrop={disableDragAndDrop}
                    />
                </ul>
            )}
        </article>
    );
}
export default Source;
