import React from "react";

export const SuccessTickIcon: React.FC = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_9881_2459)">
                <rect width="16" height="16" fill="white" fillOpacity="0.01" />
                <g clipPath="url(#clip1_9881_2459)">
                    <path
                        d="M16 7.99918C16 10.1209 15.1571 12.1557 13.6569 13.656C12.1566 15.1563 10.1217 15.9992 8 15.9992C5.87827 15.9992 3.84344 15.1563 2.34315 13.656C0.842855 12.1557 0 10.1209 0 7.99918C0 5.87744 0.842855 3.84261 2.34315 2.34232C3.84344 0.842031 5.87827 -0.000823975 8 -0.000823975C10.1217 -0.000823975 12.1566 0.842031 13.6569 2.34232C15.1571 3.84261 16 5.87744 16 7.99918ZM12.03 4.96918C11.9586 4.89799 11.8735 4.84195 11.7799 4.80439C11.6863 4.76683 11.5861 4.74853 11.4853 4.75059C11.3845 4.75264 11.2851 4.77501 11.1932 4.81634C11.1012 4.85768 11.0185 4.91714 10.95 4.99118L7.477 9.41618L5.384 7.32218C5.24183 7.1897 5.05378 7.11757 4.85948 7.121C4.66518 7.12443 4.47979 7.20314 4.34238 7.34055C4.20497 7.47797 4.12625 7.66335 4.12283 7.85765C4.1194 8.05195 4.19152 8.24 4.324 8.38218L6.97 11.0292C7.04128 11.1003 7.12616 11.1564 7.21958 11.194C7.313 11.2317 7.41305 11.2501 7.51375 11.2482C7.61444 11.2464 7.71374 11.2242 7.8057 11.1832C7.89766 11.1421 7.9804 11.0829 8.049 11.0092L12.041 6.01918C12.1771 5.87767 12.2523 5.68845 12.2504 5.49213C12.2485 5.29582 12.1698 5.10806 12.031 4.96918H12.03Z"
                        fill="#1F8747"
                    />
                </g>
            </g>
            <defs>
                <clipPath id="clip0_9881_2459">
                    <rect width="16" height="16" fill="white" />
                </clipPath>
                <clipPath id="clip1_9881_2459">
                    <rect width="16" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};
