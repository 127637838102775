import { addTask } from 'domain-task';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../';
import { IUserModel, SSORevokeStatus, IParterUserModel, ISSRUserModel } from '../../Core/ViewModels/User/UserViewModel';
import { actionTypes } from '../../types/ActionTypes';
import { handleResponse } from '../Library';
import { SSOAccessRequestConstants, UserManagementConstants } from '../../components/helper/Constants';
import { StatusType, NotificationAction } from '../common/NotificationStore';
import * as UserProfileStore from '../userManagement/UserProfileStore';
import { API_BASE_URL, SSSUITE_API_BASE_URL } from '../../utils/constants';
import { IFileUtilities } from '../../Core/Utilities/File/FileUtilities';
import { container } from '../../Startup/inversify.config';
import { TYPES } from '../../Startup/types';
import { logger } from '../../routes/LoggedIn';
import { ProductType } from '../../Core/ViewModels/Company/CompanyViewModel';
import { ResourceValidator } from '@sssuite-component-ui/resource-id-validator';
import { CommonMessages} from '../../components/helper/Constants';
import {TaxReturnScreen} from '../../components/common/TaxReturn';
import {VenusNotifier} from '../../components/helper/VenusNotifier';


const fileUtilities = container.get<IFileUtilities>(TYPES.IFileUtilities);


export interface IUserData {
	users: IUserModel[];
	isLoading: boolean;
}
export interface IPartnerUserData {
	partnerUsers: IParterUserModel[];
	isLoading: boolean;
}
export interface ISSRUserData {
	users: IUserModel[];
	isLoading: boolean;
}

export interface IUserResources {
	resourceIds: string[];
	isLoading: boolean;
}

export interface IDependentUserData {
	users: IUserModel[];
}

export interface UserState {
	user: IUserModel;
}
export interface ISSRUserListData {
    isLoading: boolean;
    userslist: ISSRUserModel[];
    alluserlist: ISSRUserModel[]; 
}

export interface IUserAssignDeliveryPermission {
   isDeliveryPermissionAssigned: boolean;
}

interface RequestUserListAction {
	type: actionTypes.REQUEST_USER_LIST;
}

interface ReceiveUserListAction {
	type: actionTypes.RECEIVE_USER_LIST;
	users: IUserModel[];
}

interface ReceiveUserResourceListAction {
	type: actionTypes.RECEIVE_USER_RESOURCEID_LIST;
	resourceIds: string[];
}

interface ReceiveDeliveryPermissionAction {
    type: actionTypes.RECEIVE_USER_ASSIGN_DELIVERY_PERMISSION;
    userAssignDeliveryPermission: boolean;
}

interface RequestUserResourceListAction {
	type: actionTypes.REQUEST_USER_RESOURCEID_LIST;
}

interface ReceiveDependentUserListAction {
	type: actionTypes.RECEIVE_DEPENDENT_USER_LIST;
	users: IUserModel[];
}

interface SaveUserAction {
	type: actionTypes.SAVE_USER;
	user: IUserModel;
}

interface DeleteUserAction {
	type: actionTypes.DELETE_USER;
	user: IUserModel;
}

interface UpdateUserAction {
	type: actionTypes.UPDATE_USER;
	user: IUserModel;
}

interface UpdateDefaultContactAction {
	type: actionTypes.UPDATE_DEFAULT_CONTACT;
}

interface ErrorUserSettings {
	type: actionTypes.ERROR_USER_MANAGEMENT;
	statusMessage: string;
	statusType: StatusType;
}
interface ReceiveSSRUserListAction {
	type: actionTypes.RECEIVE_SSR_USER_LIST;
	users: IUserModel[];
}
interface ReceiveSSRUserAction {
    type: actionTypes.RECEIVE_SSR_USERS;
    userslist: ISSRUserModel[]; 
}
 
type KnownAction =
	DispatchAction |
	NotificationAction;

type DispatchAction =
	RequestUserListAction |
	ReceiveUserListAction |
	DeleteUserAction |
	SaveUserAction |
	UpdateUserAction |
	ErrorUserSettings |
	UpdateDefaultContactAction;

type ExtendedAction = KnownAction | ReceiveDependentUserListAction;

type SSRDisplatchAction = KnownAction | ReceiveSSRUserListAction;

type SSRUserDispatchAction = KnownAction | ReceiveSSRUserAction;

type UserResourceIdDisplatchAction = KnownAction | RequestUserResourceListAction | ReceiveUserResourceListAction;

type DeliveryPermissionDispatchAction = KnownAction | ReceiveDeliveryPermissionAction;

export const actionCreators = {
	requestAllUsers: (reload: boolean = false): AppThunkAction<KnownAction> => (dispatch, getState) => {
		let state = getState();
		if (reload || state.usersList.users.length === 0) {
			const fetchTask = fetch(API_BASE_URL + 'api/UserManagement/GetAllUsers', { credentials: 'include' })
				.then(response => response.json() as Promise<IUserModel[]>)
                .then(data => {
                    if (data) {
                        dispatch({
                            type: actionTypes.RECEIVE_USER_LIST, users: data
                        });
                    }
				}).catch(function (error) {
					dispatch({
						type: actionTypes.ERROR_USER_MANAGEMENT, statusMessage: error,
						statusType: StatusType.Error
					});
				});
			addTask(fetchTask);
			dispatch({ type: actionTypes.REQUEST_USER_LIST });
		}
	},
	requestPartnerUser: (reload: boolean = false, callback?: () => void ) => (dispatch, getState) => {
		let state = getState();
		if (reload || state.partnerUsersList.partnerUsers.length === 0) {
			const fetchTask = fetch(SSSUITE_API_BASE_URL + 'api/users/partners/true', { credentials: 'include' })
			.then((response) => response.json() as Promise<IParterUserModel[]>)
			.then((data) => {
				dispatch({
					type: actionTypes.REQUEST_PARTNER_USER_LIST,
					partnerUsers: data 
				});
				if(callback) {
					callback();
				}
			})
			.catch(function (error) {
				dispatch({
					type: actionTypes.ERROR_USER_MANAGEMENT,
					statusMessage: error,
					statusType: StatusType.Error
				});
			});
			addTask(fetchTask);
		}
	},
	requestAllSSRUsers: (reload: boolean = false): AppThunkAction<SSRDisplatchAction> => (dispatch, getState) => {
		let state = getState();
		if (reload || state.ssrUserList.users.length === 0) {
			const fetchTask = fetch(API_BASE_URL + 'api/UserManagement/GetAllSSRUsers', { credentials: 'include' })
				.then(response => response.json() as Promise<IUserModel[]>)
                .then(data => {
                    if (data) {
                        dispatch({
                            type: actionTypes.RECEIVE_SSR_USER_LIST, users: data
                        });
                    }
				}).catch(function (error) {
					dispatch({
						type: actionTypes.ERROR_USER_MANAGEMENT, statusMessage: error,
						statusType: StatusType.Error
					});
				});
			addTask(fetchTask);
		}
    },
	requestSSRChangeStatusUsers: (locationId: number, taxReturnScreen: TaxReturnScreen, callback?: (response) => void) => () => {
			const fetchTask = fetch(API_BASE_URL + 'api/UserManagement/GetChangeStatusUsers/?locationId=' + locationId + '&taxReturnScreen=' + taxReturnScreen, { credentials: 'include' })
			.then(response => response.json() as Promise<IUserModel[]>)
			.then(data =>{
				if(callback){
					callback(data);
				}
			})
			.catch((error: any) => {
				VenusNotifier.Error(error.message, null);
			});

			addTask(fetchTask);
	},

    requestSSRUsers: (reload = false): AppThunkAction<SSRUserDispatchAction> => (dispatch, getState) => {
        let state = getState();
        if (reload || state.ssrUsers.userslist.length === 0) {
            const fetchTask = fetch(SSSUITE_API_BASE_URL + 'api/users/' + ProductType.Returns + '/true', { credentials: 'include' })
                .then(response => response.json() as Promise<ISSRUserModel[]>)
                .then(data => {
                    dispatch({
                        type: actionTypes.RECEIVE_SSR_USERS, userslist: data
                    });
                }).catch(function (error) {
                    dispatch({
                        type: actionTypes.ERROR_USER_MANAGEMENT, statusMessage: error,
                        statusType: StatusType.Error
                    });
                });
            addTask(fetchTask);
        }
    },
     
	requestUserById: (id: number, callback: (user: IUserModel) => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
		const fetchTask = fetch(API_BASE_URL + 'api/UserManagement/' + id, {
			method: 'GET',
			credentials: 'include',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
			}
		})
			.then(response => response.json() as Promise<IUserModel>)
			.then(data => {
				callback(data);
			}).catch(function (error) {
				dispatch({
					type: actionTypes.ERROR_USER_MANAGEMENT, statusMessage: error,
					statusType: StatusType.Error
				});
			});
		addTask(fetchTask);
	},

	saveUserInfo: (userInfo: IUserModel): AppThunkAction<KnownAction> => (dispatch, getState) => {
		const fetchTask = fetch(API_BASE_URL + 'api/UserManagement', {
			method: 'POST',
			credentials: 'include',
			body: JSON.stringify(userInfo),
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
			}
		})
			.then(handleResponse)
			.then((userinfo_ret: IUserModel) => {
				let user = Object.assign({}, userinfo_ret);
				//user.userId = newUserId
				dispatch({ type: actionTypes.SAVE_USER, user: user });
				dispatch({
					type: actionTypes.NOTIFICATION, statusMessage: UserManagementConstants.SaveUserSuccess,
					statusType: StatusType.Success
				});
			}).catch(error => {
				let message = error.statusText ? error.statusText : UserManagementConstants.SavingUserError;
				if (typeof(message) === "string") {
					dispatch({
						type: actionTypes.NOTIFICATION, statusMessage: message,
						statusType: StatusType.Error
					});
				} else {
                    logger.trackError(`message is not of type string with error: ${JSON.stringify(error)}`);
                }
				dispatch({
					type: actionTypes.ERROR_USER_MANAGEMENT, statusMessage: error,
					statusType: StatusType.Error
				});
			});
		addTask(fetchTask);
		dispatch({ type: actionTypes.REQUEST_USER_LIST });
	},

	updateUserInfo: (userInfo: IUserModel, oldEmailID: string, emailPasswordOnSave: boolean, isPasswordChanged: boolean,
		changedByUser: boolean, updateType: string, callback?: (dataPresent: boolean) => void): AppThunkAction<ExtendedAction> => (dispatch, getState) => {

			const fetchTask = fetch(API_BASE_URL + 'api/UserManagement/' + oldEmailID + '/' + emailPasswordOnSave + '/' + isPasswordChanged + '/' + updateType + '/' +
				changedByUser, {
				method: 'PUT',
				credentials: 'include',
				body: JSON.stringify(userInfo),
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json',
					'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
				}
			})
				.then(handleResponse)
				.then(data => {
					if (data == true) {
						if (callback) {
							callback(true);
						}
					}
					else {
						let message = isPasswordChanged ? UserManagementConstants.UserPasswordSuccess : UserManagementConstants.UserUpdationSuccess
						dispatch({
							type: actionTypes.UPDATE_USER, user: userInfo
						});
						dispatch({
							type: actionTypes.NOTIFICATION, statusMessage: message,
							statusType: StatusType.Success
						});
						let action: any = UserProfileStore.actionCreators.requestUserProfile(true);
						dispatch(action);
					}

					let action1: any = actionCreators.requestAllUsers(true);
					dispatch(action1);


				}).catch(error => {
					let action = actionCreators.requestAllUsers();
					dispatch(action);
					let message = error.statusText ? error.statusText : UserManagementConstants.UserUpdationError;
					if (typeof(message) === "string") {
						dispatch({
							type: actionTypes.NOTIFICATION, statusMessage: message,
							statusType: StatusType.Error
						});
					} else {
						logger.trackError(`message is not of type string with error: ${JSON.stringify(error)}`);
					}
					dispatch({
						type: actionTypes.ERROR_USER_MANAGEMENT, statusMessage: error,
						statusType: StatusType.Error
					});
				});
			addTask(fetchTask);
			dispatch({ type: actionTypes.REQUEST_USER_LIST });
		},

	changeUserPassword: (userInfo: IUserModel, callback?: (dataPresent: boolean) => void): AppThunkAction<ExtendedAction> => (dispatch, getState) => {

		const fetchTask = fetch(API_BASE_URL + 'api/UserManagement/ChangePassword', {
			method: 'PUT',
			credentials: 'include',
			body: JSON.stringify(userInfo),
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
			}
		})
			.then(handleResponse)
			.then(data => {
				if (data) {
					let message = userInfo.lastLogin < userInfo.createdOn ?
						UserManagementConstants.TemporaryPasswordMailConfirmation :
						UserManagementConstants.ResetPasswordMailConfirmation;

					dispatch({
						type: actionTypes.NOTIFICATION, statusMessage: message.replace("%USEREMAIL%", userInfo.email),
						statusType: StatusType.Success
					});
				}


			}).catch(error => {
				let action = actionCreators.requestAllUsers();
				dispatch(action);
				let message = error.statusText ? error.statusText : UserManagementConstants.UserUpdationError;
				if (typeof(message) === "string") {
					dispatch({
						type: actionTypes.NOTIFICATION, statusMessage: message,
						statusType: StatusType.Error
					});
				} else {
                    logger.trackError(`message is not of type string with error: ${JSON.stringify(error)}`);
                }
				dispatch({
					type: actionTypes.ERROR_USER_MANAGEMENT, statusMessage: error,
					statusType: StatusType.Error
				});
			});
		addTask(fetchTask);
		dispatch({ type: actionTypes.REQUEST_USER_LIST });
	},

	resetUserCookie: (): AppThunkAction<ExtendedAction> => (dispatch, getState) => {
		const fetchTask = fetch(API_BASE_URL + 'api/UserManagement/ResetUserCookie', {
			method: 'GET',
			credentials: 'include',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			}
		})
			.then(handleResponse)
			.then(data => {
			}).catch(error => {
				dispatch({
					type: actionTypes.ERROR_USER_MANAGEMENT, statusMessage: error,
					statusType: StatusType.Error
				});
			});
		addTask(fetchTask);
	},
	UpdateCPNDeleteUser: (userId: number, oldUserId: number, userInfo: IUserModel): AppThunkAction<KnownAction> => (dispatch, getState) => {

		const fetchTask = fetch(API_BASE_URL + 'api/UserManagement/UpdateCPNDeleteUser/' + userId + '/' + oldUserId, {
			method: 'PUT',
			credentials: 'include',
			body: JSON.stringify(userInfo),
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
			}
		})
			.then(handleResponse)
			.then(data => {

				dispatch({
					type: actionTypes.DELETE_USER, user: userInfo
				});
				dispatch({
					type: actionTypes.NOTIFICATION, statusMessage: UserManagementConstants.DeleteUserSuccess,
					statusType: StatusType.Success
				});

			}).catch(error => {
				const statusMessage: any = error.statusText;
                if (typeof(statusMessage) === "string") {
					dispatch({
						type: actionTypes.NOTIFICATION, statusMessage: statusMessage,
						statusType: StatusType.Error
					});
				}
				dispatch({ type: actionTypes.RECEIVE_USER_LIST, users: getState().usersList.users });
			});
		addTask(fetchTask);
		dispatch({ type: actionTypes.REQUEST_USER_LIST });
	},
	UpdateMembershipContactPerson: (userId: number, userInfo: IUserModel): AppThunkAction<KnownAction> => (dispatch, getState) => {

		const fetchTask = fetch(API_BASE_URL + 'api/UserManagement/UpdateMembership/' + userId, {
			method: 'PUT',
			credentials: 'include',
			body: JSON.stringify(userInfo),
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
			}
		})
			.then(handleResponse)
			.then(() => {
				let message = UserManagementConstants.UserUpdationSuccess;
				dispatch({
					type: actionTypes.UPDATE_USER, user: userInfo
				});
				dispatch({
					type: actionTypes.NOTIFICATION, statusMessage: message,
					statusType: StatusType.Success
				});
				let action: any = UserProfileStore.actionCreators.requestUserProfile(true);
				dispatch(action);

			}).catch(error => {
				let message = error.statusText ? error.statusText : UserManagementConstants.UserUpdationError;
				if (typeof(message) === "string") {
					dispatch({
						type: actionTypes.NOTIFICATION, statusMessage: message,
						statusType: StatusType.Error
					});
				} else {
                    logger.trackError(`message is not of type string with error: ${JSON.stringify(error)}`);
                }
				dispatch({
					type: actionTypes.ERROR_USER_MANAGEMENT, statusMessage: error,
					statusType: StatusType.Error
				});
			});
		addTask(fetchTask);
		dispatch({ type: actionTypes.REQUEST_USER_LIST });
	},
	exportUserDetailsAsExcel: (query: string, callback?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
		const fetchTask = fetch(API_BASE_URL + 'api/ExportToExcel/ExportExcelUserDetails' + query, { credentials: 'include' })
			.then(response => response.blob())
			.then(blob => {
				const url = window.URL.createObjectURL(new Blob([blob]));
				fileUtilities.download(url, "UserManagement.xlsx");
				if (callback) {
					callback();
				}
			}).catch((error) => {
				const statusMessage: any = error.statusText?.message ?? error.statusText;
                if (typeof(statusMessage) === "string") {
					dispatch({ type: actionTypes.NOTIFICATION, statusMessage: statusMessage, statusType: StatusType.Error });
				}
			});
		addTask(fetchTask);
	},

	deleteUser: (userInfo: IUserModel, currentUser: number | undefined, callback: (dataPresent: boolean) => void): AppThunkAction<ExtendedAction> => (dispatch, getState) => {
		const fetchTask = fetch(API_BASE_URL + 'api/UserManagement/' + currentUser, {
			method: 'DELETE',
			credentials: 'include',
			body: JSON.stringify(userInfo),
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
			}
		})
			.then(handleResponse)
			.then(response => response as Promise<IUserModel[]>)
			.then(data => {
				if (typeof data === "object") {
					dispatch({ type: actionTypes.RECEIVE_DEPENDENT_USER_LIST, users: data });
					callback(true);
				}
				else {
					dispatch({
						type: actionTypes.DELETE_USER, user: userInfo
					});
					dispatch({
						type: actionTypes.NOTIFICATION, statusMessage: UserManagementConstants.DeleteUserSuccess,
						statusType: StatusType.Success
					});
					callback(false);
				}
			}).catch(error => {
				const statusMessage: any = error.statusText;
                if (typeof(statusMessage) === "string") {
					dispatch({
						type: actionTypes.NOTIFICATION, statusMessage: statusMessage,
						statusType: StatusType.Error
					});
				}
				dispatch({ type: actionTypes.RECEIVE_USER_LIST, users: getState().usersList.users });
			});
		addTask(fetchTask);
		dispatch({ type: actionTypes.REQUEST_USER_LIST });
	},
	addSignaturesGroup: (userIds: number[]): AppThunkAction<KnownAction> => (dispatch, getState) => {
		const fetchTask = fetch(API_BASE_URL + 'api/UserManagement/AddSignaturesGroupAsync', {
			method: 'POST',
			credentials: 'include',
			body: JSON.stringify(userIds),
			headers: {
				'Accept': 'application/json, text/plain, */*',
				'Content-Type': 'application/json; charset=utf-8',
				'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
			}
		})
			.then(handleResponse)
			.then(() => {
				dispatch({
					type: actionTypes.NOTIFICATION, statusMessage: UserManagementConstants.UserUpdationSuccess,
					statusType: StatusType.Success
				});
				let action = actionCreators.requestAllUsers(true);
				dispatch(action);
			}).catch(error => {
				let message = error.statusText ? error.statusText : UserManagementConstants.SavingUserError;
				if (typeof(message) === "string") {
					dispatch({
						type: actionTypes.NOTIFICATION, statusMessage: message,
						statusType: StatusType.Error
					});
				} else {
                    logger.trackError(`message is not of type string with error: ${JSON.stringify(error)}`);
                }
				dispatch({
					type: actionTypes.ERROR_USER_MANAGEMENT, statusMessage: error,
					statusType: StatusType.Error
				});
			});
		addTask(fetchTask);
		dispatch({ type: actionTypes.REQUEST_USER_LIST });
	},

	revokeSSOAccessRequest: (user: IUserModel, revokeStatus: SSORevokeStatus, callback?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
		const fetchTask = fetch(API_BASE_URL +'api/SSOAccessRequest/RevokeAsync?emailAddress=' + user.email + '&revokeStatus=' + revokeStatus, {
			method: 'POST',
			credentials: 'include'
		})
			.then(response => response.json() as Promise<boolean>)
			.then(data => {
				if (data) {
					dispatch({
						type: actionTypes.NOTIFICATION,
						statusMessage: SSOAccessRequestConstants.StatusMessage.RevokedSSOAccessRequestSuccess,
						statusType: StatusType.Success
					});

					dispatch({
						type: actionTypes.UPDATE_USER, user: user
					});

					if (callback) {
						callback();
					}
				}
				else {
					dispatch({
						type: actionTypes.NOTIFICATION,
						statusMessage: SSOAccessRequestConstants.StatusMessage.RevokedSSOAccessRequestError,
						statusType: StatusType.Error
					});
				}
			}).catch(function (error) {
				dispatch({
					type: actionTypes.NOTIFICATION,
					statusMessage: SSOAccessRequestConstants.StatusMessage.RevokedSSOAccessRequestError,
					statusType: StatusType.Error
				});
			});
		addTask(fetchTask);
	},
	requestUserResourceIds: (reload: boolean = false): AppThunkAction<UserResourceIdDisplatchAction> => (dispatch, getState) => {
		let state = getState();
		if (reload || state.userResources.resourceIds.length === 0) {
			const fetchTask = fetch(SSSUITE_API_BASE_URL + `api/resource/${ProductType.Returns}`, {
			method: 'GET',
			credentials: 'include',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				"Access-Control-Allow-Origin": '*'
			}
			})
			.then(response => response.json() as Promise<string[]>)
			.then(data => {
				const validator = new ResourceValidator(data,'disable','click',	CommonMessages.UnAuthorized);
				validator.start();
				let resources= validator.getResources();
				dispatch({
					type: actionTypes.RECEIVE_USER_RESOURCEID_LIST, resourceIds: resources
				});
			}).catch(function (error) {
				dispatch({
					type: actionTypes.ERROR_USER_MANAGEMENT, statusMessage: error,
					statusType: StatusType.Error
				});
			});
			addTask(fetchTask);
		}
    },

    requestUserAssignDeliveryPermission: (): AppThunkAction<DeliveryPermissionDispatchAction> => (dispatch) => {        
        const fetchTask = fetch(SSSUITE_API_BASE_URL + `api/user-rbac/is-user-assign-delivery-permission`, {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json' 
                }
        }) 
            .then(handleResponse)
            .then(data => {
                    dispatch({
                        type: actionTypes.RECEIVE_USER_ASSIGN_DELIVERY_PERMISSION, userAssignDeliveryPermission: data
                    });
                }).catch(function (error) {
                    dispatch({
                        type: actionTypes.NOTIFICATION, statusMessage: error,
                        statusType: StatusType.Error
                    });
                });
            addTask(fetchTask);
        } 
};

const unloadedState: IUserData = {
	users: [],
	isLoading: false,
} as IUserData;

const partnerUserState: IPartnerUserData = {
	partnerUsers: [],
	isLoading: false,
} as IPartnerUserData;

const unloadedCustomState: IDependentUserData = {
	users: []
} as IDependentUserData;

const unloadedSSRState: ISSRUserData = {
	users: [],
	isLoading: false,
} as ISSRUserData;

const unloadedSSRUserListState: ISSRUserListData = {
    isLoading: false,
    userslist: [],
    alluserlist: []
} as ISSRUserListData;

const unloadedUserResourceState: IUserResources = {
	resourceIds: [],
	isLoading: false
} as IUserResources;

const unloadedDeliveryPermissionState: IUserAssignDeliveryPermission = {
    isDeliveryPermissionAssigned: false
} as IUserAssignDeliveryPermission;

export const reducer: Reducer<IUserData> = (state = unloadedState, incomingAction) => {
	const action = incomingAction as DispatchAction;
	switch (action.type) {
		case actionTypes.REQUEST_USER_LIST:
			return {
				users: [...state.users],
				isLoading: true
			} as IUserData;
		case actionTypes.RECEIVE_USER_LIST:
			return {
				...state,
				users: action.users,
				isLoading: false
			} as IUserData;
		case actionTypes.DELETE_USER:
			var userList = Object.assign({}, state);
			var index = userList.users.findIndex(user => user.userId == action.user?.userId);
			userList.users.splice(index, 1);
			return {
				...state,
				users: userList.users,
				isLoading: false
			} as IUserData;
		case actionTypes.SAVE_USER:
			var userList = Object.assign({}, state);
			userList.users.push(action.user)
			userList.users = userList.users.sort((userOne: IUserModel, userTwo: IUserModel) => {
				if (userOne.firstName.toLocaleLowerCase() > userTwo.firstName.toLocaleLowerCase()) {
					return 1;
				}
				if (userOne.firstName.toLocaleLowerCase() < userTwo.firstName.toLocaleLowerCase()) {
					return -1;
				}
				return 0;
			});
			return {
				...state,
				users: userList.users,
				isLoading: false
			} as IUserData;
		case actionTypes.UPDATE_USER:
			var userList = Object.assign({}, state);
			var index = userList.users.findIndex(user => user.userId == action.user?.userId);
			userList.users.splice(index, 1, action.user);
			return {
				...state,
				users: userList.users,
				isLoading: false,
			} as IUserData;
		case actionTypes.UPDATE_DEFAULT_CONTACT:
			var userList = Object.assign({}, state);
			return {
				...state,
				users: userList.users,
				isLoading: false,
			} as IUserData;
		case actionTypes.ERROR_USER_MANAGEMENT:
			let errorState: IUserData = Object.assign({}, state);
			errorState.isLoading = false;
			return errorState;
		default:
			const exhaustiveCheck: never = action;
	}

	return state;
};

export const partnerUsersReducer: Reducer<IPartnerUserData> = (state = partnerUserState, incomingAction) => {
	const action = incomingAction;
	switch (action.type) {
		case actionTypes.REQUEST_PARTNER_USER_LIST:
			return {
				...state,
				partnerUsers: action.partnerUsers,
				isLoading: true
			} as IPartnerUserData;
	}
	return state;
};

export const customReducer: Reducer<IDependentUserData> = (state = unloadedCustomState, incomingAction) => {
	const action = incomingAction as ExtendedAction;
	switch (action.type) {
		case actionTypes.RECEIVE_DEPENDENT_USER_LIST:
			var userList = Object.assign({}, state);
			userList.users = action.users;
			return {
				users: userList.users
			} as IDependentUserData;
	}

	return state;
};

export const SSRUserReducer: Reducer<ISSRUserData> = (state = unloadedSSRState, incomingAction) => {
	const action = incomingAction as SSRDisplatchAction;
	switch (action.type) {
		case actionTypes.RECEIVE_SSR_USER_LIST:
			var ssrUserList = Object.assign({}, state);
			ssrUserList.users = action.users;
			return {
				users: ssrUserList.users,
				isLoading: true
			} as ISSRUserData;
	}

	return state;
};

export const SSRUserListReducer: Reducer<ISSRUserListData> = (state = unloadedSSRUserListState, incomingAction) => {
    const action = incomingAction as SSRUserDispatchAction;
    switch (action.type) {
        case actionTypes.RECEIVE_SSR_USERS:
            var ssrUsers = Object.assign({}, state); 
                ssrUsers.userslist = action.userslist.filter(x => x.inactive == false);            
                ssrUsers.alluserlist = action.userslist; 
            return {
                isLoading: true,
                userslist: ssrUsers.userslist,
                alluserlist: ssrUsers.alluserlist
            } as ISSRUserListData; 
    }
    return state;
}; 
 
export const resourceListReducer: Reducer<IUserResources> = (state = unloadedUserResourceState, incomingAction) => {
	const action = incomingAction as UserResourceIdDisplatchAction;
	switch (action.type) {
		case actionTypes.REQUEST_USER_RESOURCEID_LIST:
			return {
				resourceIds: [...state.resourceIds],
				isLoading: true
			} as IUserResources;
		case actionTypes.RECEIVE_USER_RESOURCEID_LIST:
			return {
				resourceIds: action.resourceIds,
				isLoading: false
			} as IUserResources;
	}
	return state;
};

export const UserAssignDeliverPermissionReducer: Reducer<IUserAssignDeliveryPermission> = (state = unloadedDeliveryPermissionState, incomingAction) => {
    const action = incomingAction as DeliveryPermissionDispatchAction;
    switch (action.type) {
        case actionTypes.RECEIVE_USER_ASSIGN_DELIVERY_PERMISSION:
            return {
                isDeliveryPermissionAssigned: action.userAssignDeliveryPermission
            } as IUserAssignDeliveryPermission;
    }
    return state;
};
