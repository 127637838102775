import { EngagementType } from "../common/TaxReturn";

export const Common = {
    ButtonLabel: {
        Cancel: "Cancel",
        Ok: "Ok",
        Save: "Save",
        Delete: "Delete"
    },
    OverlayMessages: {
        Downloading: "Downloading...Please wait..",
        Processing: "Processing...Please wait",
        Deleting: "Deleting...Please wait",
        ScreenSharing: "Starting screen share...Please wait",
        Recalling: "Recalling...Please wait",
        Archiving: "Archiving...Please wait",
        Restoring: "Restoring...Please wait"
    },
    Images: {
        ContactIcon: require("../../assets/images/icon-contact-header.svg")
    },
    DialogBox: {
        ConfirmationTitle: "Confirmation",
        ConfirmationMessage: "Are you sure ?",
        WarningTitle: "Warning",
        Cancel: "Cancel",
        Confrim: "Confirm",
        Yes: "Yes",
        No: "No"
    }
};

export const UserGroupsConstants = {
    Admin: "Admin",
    Staff: "Staff",
    Partner: "Partner"
};

export const CommonMessages = {
    UnAuthorized:
        "You do not have permission to perform this operation,\nplease contact your system administrator for more\ninformation.",
    Error: {
        DashboardLinkFetchFailed: "Unable to fetch Dashboard Link",
        CompanyDisabled: "Company is disabled/inactive",
        ClearingCacheFailed: "Clearing user accessible products cache failed!"
    },
    PlaceholderText: "Please enter instruction text"
};

export const UserManagementConstants = {
    SelectUserGroupWarning: "Please select atleast one user role in Memberships.",
    LoadingUserError: "An Error occured when loading users.Please Try Again.",
    SavingUserError: "Saving user details failed, please re-login",
    UserUpdationError: "Updating user details failed, please re-login",
    EditUserLoadingError: "User Loading failed, please re-login.",
    DeletingUserError: "Deleting user failed, please re-login",
    SelectUserWarning: "Please select a user from the list.",
    SelectGroupWarning: "Please select a group from the list.",
    SaveUserSuccess: "User added successfully",
    DeleteUserSuccess: "Selected user has been deleted",
    UserUpdationSuccess: "User details has been updated.",
    DeleteUserConfirmation: "Are you sure you want to delete this user?",
    AddUserDetails: "Add User Details",
    SetPAssword: "Set Password",
    PasswordChangeOnNextLogin: "User must change password on next login",
    ManageMembership: "User Group",
    UserManagement: "User Management",
    EditUserDetails: "Edit User Details",
    ChangePassword: "Change Password",
    UserExists: "User already exists.",
    UserPasswordSuccess: "Password changed successfully",
    PasswordMustBeEightCharacters: "Your password must be at least 8 characters.",
    DeleteLoggedInUser: "You cannot delete the logged in user",
    EditLoggedInUser: "You cannot Edit the logged in user",
    DependentUsers:
        "Warning:  You are trying to delete a user that has been selected as the Contact Person for returns that have already been delivered (i.e. the person the taxpayer should contact if they have questions).  Please select the contact person you would like to replace the deleted user.",
    DependentDocument:
        "Warning:  You are trying to remove the role for the user who has been selected as the Contact Person for returns that have already been delivered (i.e. the person the taxpayer should contact if they have questions).  Please select the contact person you would like to replace the modified user role.",
    SignatureFlowDisabledWarning:
        "Signature service  is currently disabled for your company. Please enable from company settings.",
    ExportToExcel: "Export to Excel",
    MobileNumberEmpty: "Mobile Number can't be empty",
    ResetPasswordHeading: "Send Reset Password Link",
    ResetPasswordBodyText: `An email with a reset password link will be sent to the user's registered email address. Do you wish to continue?`,
    ResendTemporaryPasswordHeading: "Send Temporary Password",
    ResendTemporaryPasswordBody: `An email with a temporary password will be sent to the user's registered email address. Do you wish to continue?`,
    TemporaryPasswordMailConfirmation: "Temporary password mail is successfully sent to %USEREMAIL%",
    ResetPasswordMailConfirmation: "Reset password mail is successfully sent to %USEREMAIL%",
    SSONote: "The highlighted users are added through the Office 365 and the user profile need to be reviewed and completed.",
    RevokeO365Access: "Revoke Office 365 Access",
    RevokeO365AccessHeader: "You are trying to revoke the Office 365 access for the selected user.",
    TemporarilyRevokeO365Access: "Remove the Access temporarily",
    TemporarilyRevokeO365AccessHelp: "The user will have ability to request Office 365 access again.",
    PermanentRevokeO365Access: "Remove the Access permanently",
    PermanentRevokeO365AccessHelp: "The user will be blocked from requesting Office 365 access.",
    GroupAndRoleFetchFailed: "Unable to fetch Group and Role Settings",
    RoleSettingUpdatedSuccessFully: "Role Settings Updated SuccessFully",
    RoleSettingUpdationFailed: "Role Setting Updation Failed",
    GroupSettingUpdationFailed: "Group Updation Failed",
    GroupSettingUpdatedSuccessFully: "Group Updated SuccessFully",
    AllPermissionFetchFailed: "Unable to fetch Permissions",
    GroupAddedSuccessFully: "New Group Added Successfully",
    GroupAdditionFailed: "Unable to Add a new Group",
    RoleUpdatedSuccessFully: "Role updated Successfully",
    RoleUpdationFailed: "Unable to Update Role",
    RoleAddedSuccessFully: "Role Added Successfully",
    RoleAdditionFailed: "Unable to Add Role",
    RoleDeleteSuccessFully: "Role deleted Successfully",
    RoleDeletionFailed: "Unable to delete the Role",
    GroupDeleteSuccessFully: "Group deleted Successfully",
    GroupDeletionFailed: "Unable to delete Group",
    SelectPermissionWarning: "Please select a permission from the list.",
    RoleNameRequired: "Role Name is Required",
    GroupNameRequired: "Group Name is Required",
    AtleastOneGroupNeeded: "Atleast one Group is Required",
    AtleastOneRoleNeeded: "Atleast one Role is Required",
    AtleastOnePermissionNeeded: "Atleast one Permission is Required",
    ApprovePasswordError: "Approve password action failed!"
};

export const ValidationContants = {
    FirstNameWarning: "Please enter first name.",
    LastNameWarning: "Please enter last name.",
    EmailAdrressWarning: "Please enter email address.",
    ValidEmailAddressWarning: "Please enter valid  email address.",
    ValidIdentityServerEmailAddressWarning:
        "Only letters (a-z), numbers (0-9), symbol (@), dash (-), underscore (_), period (.) and apostrophe (') are allowed.",
    EmailNotMatching: "Email and Confirm Email did not match",
    PhoneNumberWarning: "Please enter the phone number.",
    PhoneNumberLengthWarning: "Please enter a 10 digit phone number.",
    MobileNumberWarning: "Please enter the mobile number.",
    MobileNumberLengthWarning: "Please enter a valid mobile number, according to the country code selected",
    CountryCodeWarning: "Please select the country code.",
    PTINWarning: "PTIN should start with P followed by 8 digit number.",
    EnterPasswordWarning: "Please enter password and confirm password.",
    PasswordNotMatchedWarning: "Password and confirmed password did not match.",
    NewPasswordWarning: "Please enter new password.",
    OldPasswordWarning: "Please enter old password",
    ConfirmPasswordWarning: "Please enter confirm password",
    PasswordLengthWarning: "Password must be 8 characters.",
    PasswordWithoutSpaceWarning: "Please enter password without space.",
    ZipWarning: "Please enter zip.",
    ZipLengthWarning: "Please enter a 5 digit zip.",
    ExtensionWarning: "Please enter less than 7 digit extension number.",
    FaxWarning: "Please enter a 10 digit fax number.",
    StateWarning: "Please select state.",
    CityWarning: "Please enter city.",
    ValidCityWarning: "Please enter valid city name.",
    PdfFileValidation: "Please upload Pdf files only.",
    PaymentURLValidation: "Please enter a valid payment URL.",
    FirmKeyWarning: "Please enter valid firm key.",
    AzureGroupIdWarning: "Please enter valid Azure Group Id.",
    AzureTenantIdWarning: "Please enter valid Azure Tenant Id.",
    PasswordWarning: "Please enter password.",
    K1InstructionNotAVailable: "K1 instruction is not available in the uploaded file",
    NoContentAvailable: "No content available",
    EINWarning: "Please enter valid EIN.",
    FileFormatNotSupported: "Unsupported file type attached. We support PDF, DOC, DOCX, Excel, PNG and JPEG file types.",
    UpperCaseValidationError: "Password should contain at least one uppercase character.",
    LowerCaseValidationError: "Password should contain at least one lowercase character.",
    NumbersValidationError: "Password should contain at least one number.",
    SpecialCharactersValidationError: "Password should contain at least one special character.",
    CsvFileValidation: "Please upload Csv files only.",
    SameEmailValidation: "Email Already selected.",
    PTINExists: "PTIN already exist for another user.",
    AddressLengthWarning: "Address must not exceed 200 characters"
};

export const CustomColumnConstants = {
    ValidationMessages: {
        CustomColumnValueWarning: "Please enter column value.",
        CustomColumnValueAlreadyExist: "Custom column value already exists.",
        CustomColumnLength: "Please Enter Maximum of 20 characters.",
        CustomColumnAddValidation: "Unable to add values unless a column name is specified first.",
        CustomColumnSaveValidation: "Unable to add values unless a column name is saved/updated.",
        CustomColumnValueUpdateWarning: "Please Enter Maximum of 100 characters"
    },
    StatusMessage: {
        CustomColumnValueAddSuccess: "Custom column value added successfully.",
        CustomColumnValueAddError: "Error occured while adding custom column value.",
        CustomColumnValueLength: "Please Enter Maximum of 100 characters.",
        CustomColumnValueUpdateSuccess: "Custom column value updated successfully.",
        CustomColumnValueDeleteSuccess: "Custom column value deleted successfully.",
        CustomColumnValueDeleteError: "Error occured while deleting custom column value.",
        CustomColumnNameUpdateSuccess: "Custom column name updated successfully.",
        CustomColumnNameUpdateError: "Error occured while updating custom column name.",
        CustomColumnHeaderDeleteSuccess: "Custom column header deleted successfully.",
        CustomColumnHeaderDeleteError: "Error occured while deleting custom column header."
    },
    OverlayMessage: {
        UpdatingCustomColumnName: "Updating custom column name, please wait...",
        AddingCustomColumnValue: "Adding custom column value, Please wait...",
        UpdatingCustomColumnValue: "Updating custom column values, Please wait...",
        DeletingCustomColumnValue: "Deleting custom column values, Please wait..."
    },
    WarningMessage: {
        DeletingCustomColumnHeader:
            "The Custom Column you are trying to delete will delete all its corresponding values and it cannot be recovered. Are you sure you want to delete it?"
    }
};
export const DocumentTypeConstants = {
    ValidationMessages: {
        DocumentTypeValueWarning: "Please enter column value.",
        DocumentTypeAlreadyExist: "Document type already exists.",
        DocumentTypeValueLength: "Please Enter Maximum of 40 characters."
    },
    StatusMessage: {
        DocumentTypeAddSuccess: "Document type value added successfully.",
        DocumentTypeValueAddError: "Error occured while adding Document type value.",
        DocumentTypeUpdateSuccess: "Document type value updated successfully.",
        DocumentTypeValueUpdateError: "Error occured while updating Document type value.",
        DocumentTypeValueDeleteSuccess: "Document type value deleted successfully.",
        DocumentTypeValueDeleteError: "Error occured while deleting Document type value."
    },
    OverlayMessage: {
        AddingDocumentTypeValue: "Adding document type value, Please wait...",
        UpdatingDocumentTypeValue: "Updating document type value, Please wait...",
        DeletingDocumentTypeValue: "Deleting document type value, Please wait..."
    }
};

export const CompanySettingsConstants = {
    StatusMessage: {
        CompanySettingsError: "Error occured while fetching company settings",
        CompanyProfileError: "Error while loading company profile",
        CompanyLogoError: "Error while loading company logo",
        UpdateProductstatus: "Error occured while updating product status",
        UpdateCompanySettingsSuccess: "Company settings updated successfully.",
        UpdateCompanySettingsError: "Error occured while updating company settings",
        UpdateRetentionPeriodError: "Error occured while applying retention period for all documents",
        UpdateCompanyProfileSuccess: "Company Information saved",
        UpdateRetentionForAllDocuments: "Retention Settings applied successfully",
        UpdateCompanyLogoError: "Unable to update logo, Please try again",
        AdminProfileError: "Error while loading company profile",
        ADAzureExist: "Group ID has already been added on a different Account. Please enter a different Group ID",
        StripeEditURLError: "Error occured while redirecting to Stripe",
        DisconnectStripeAccountError: "Error occured while disconnecting Stripe Account"
    },
    OverlayMessage: {
        UpdatingCompanySettings: "Updating company settings...",
        ApplicationLoading: "Loading, please wait..."
    },
    Validate: {
        CompanyNameWarning: "Please enter company name.",
        AddressWarning: "Please enter street address 1.",
        StateWarning: "Please select state.",
        WebsiteWarning: "Please enter valid website.",
        ImageWarning: "Please upload only image.",
        ImageDimensionWarning: "Max dimensions are 300x100px.",
        ImageValidation: "Image validation failed! try again...",
        TrustedDeviceExpiryDays: "Please enter the days between 1 to 60.",
        ClientIdWarning: "Please enter Client ID.",
        GroupIdWarning: "Please add at least one Group Id",
        TenantIdWarning: "Please add at least one Tenant Id",
        DeleteWatermarkWarning: "You cannot delete the default watermark."
    },
    HeaderName: {
        UploadCompanyLogo: "Upload Company Logo",
        CompanyLogoMessage:
            "Your selected logo will be used on all web pages, emails and notifications (Company Logo Dimension should be less than 300x100px)",
        CompanyAccountDetailMessage: "To update your current subscription please contact your Customer Success Representative."
    },
    MFASettings: {
        HelpText:
            "When mobile number is provided in Client info, access code will be sent through text/SMS. If the mobile number is not provided then access code will be sent through email."
    }
};

export const ReportFilterConstants = {
    StatusMessage: {
        SavedSuccessMessage: "Filter saved successfully.",
        SavedErrorMessage: "Failed to save the filter!",
        FilterAlreadyPresentMessage: "Filter name already exists!",
        UpdateSuccessMessage: "Filter updated successfully.",
        UpdateErrorMessage: "Failed to update the filter!",
        DeleteSuccessMessage: "Filter deleted successfully.",
        DeleteErrorMessage: "Failed to delete the filter!",
        GetAllErrorMessage: "Failed to fetch the filters!"
    },
    ValidationMessage: {
        NoRecentFilters: "There is no recent filter to apply!"
    },
    ControlsLabel: {
        ApplyRecentFilterBtn: "Apply recent filter",
        SaveCurrentFilterBtn: "Save current filter",
        SetAsDefaultFilter: "Set as Default Filter",
        DeleteFilter: "Delete Filter",
        ExportToExcel: "Export to Excel",
        ClearFilter: "Clear Filter",
        RemoveDefault: "Remove Default",
        PleaseEnterTheFilterName: "Please enter the filter name",
        Apply: "Apply",
        SetDefault: "Set Default",
        Delete: "Delete",
        AppliedFilter: "Applied Filter"
    },
    OtherMessage: {
        DeleteFilter: "Are you sure you want to delete this filter?"
    },
    ControlsTitle: {
        ApplyMostRecentFilter: "Apply Most Recent Filter",
        ToggleDropdownMenu: "Toggle dropdown menu"
    },
    DefaultFilterBuilder: "_D_FILTER", //This suffix is trimmed to maintain total length to 25,
    QuickReports: {
        LinkNotAccessed: "Link Not Accessed",
        KBAFailed: "KBA Failed"
    }
};

export const DeliveredReturnsTableConstants = {
    Title: {
        FilterSaveUpdate: "Save/Update Filter"
    },
    OtherMessage: {
        DoYouWantToCreateNewFilterOrUpdateTheCurrent: "Do you want to create New Filter or Update the Current?",
        NoReturnsFound: "No returns found"
    },
    FormLabel: {
        YesCreateNew: "Yes, Create New",
        NoUpdateTheCurrent: "No, Update The Current"
    }
};

export const SendGroupedReturnsTableConstants = {
    Title: {
        FilterSaveUpdate: "Save/Update Filter"
    },
    OtherMessage: {
        DoYouWantToCreateNewFilterOrUpdateTheCurrent: "Do you want to create New Filter or Update the Current?",
        NoReturnsFound: "No returns found"
    },
    FormLabel: {
        YesCreateNew: "Yes, Create New",
        NoUpdateTheCurrent: "No, Update The Current"
    }
};

export const DeliveredGroupedReturnsTableConstants = {
    Title: {
        FilterSaveUpdate: "Save/Update Filter"
    },
    OtherMessage: {
        DoYouWantToCreateNewFilterOrUpdateTheCurrent: "Do you want to create New Filter or Update the Current?",
        NoReturnsFound: "No returns found"
    },
    FormLabel: {
        YesCreateNew: "Yes, Create New",
        NoUpdateTheCurrent: "No, Update The Current"
    },
    GroupSettingsUpate: {
        groupSettingsSaveSuccess: "Group retention settings updated successfully.",
        groupSettingsSaveFailure: "Error occured while saving Group retention settings.",
        resendGroupedReturnAccessLinkFailure: "Error occured while sending access Link",
        groupSettingsRequestFailure: "Error receiving group level settings."
    }
};

export const InUseGroupedReturnsTableConstants = {
    Title: {
        FilterSaveUpdate: "Save/Update Filter"
    },
    OtherMessage: {
        DoYouWantToCreateNewFilterOrUpdateTheCurrent: "Do you want to create New Filter or Update the Current?",
        NoReturnsFound: "No returns found"
    },
    FormLabel: {
        YesCreateNew: "Yes, Create New",
        NoUpdateTheCurrent: "No, Update The Current"
    }
};

export const VoucherReminderManagementConstants = {
    OtherMessage: {
        NoDataFound: "No records found"
    },
    ControlsLabel: {
        ExportToExcel: "Export to Excel"
    }
};

export const SendGroupedReturnsConstants = {
    OtherMessage: {
        SelectCustomColumnValue: "Please select column value",
        FilterNameAlreadyExists: "Filter name already exists.",
        FilterFieldsAreEmpty: "Filter fields are Empty! Please provide at least one.",
        PleaseEnterFilterName: "Please enter filter name",
        FilterDoesNotExists: "Filter doesn't exists.",
        DeleteFilter: "Are you sure you want to delete this filter?",
        SaveColumnSettingsSuccess: "Column Settings saved successfully!"
    },
    StatusMessage: {
        SetDefaultFilterSuccess: "Default filter has been changed successfully!.",
        RemoveDefaultFilterSuccess: "Default filter has been Removed successfully!.",
        UnlockDocumentSuccess: "Document have been unlocked successfully!."
    },
    HeaderActions: {
        UnGroup: {
            Title: "Ungroup Return",
            ConfirmationMessage: "You are about to Ungroup the selected document(s). Do you want to proceed?"
        },
        Delete: {
            Title: "Delete Group(s) and/or Return(s)",
            ConfirmationMessage:
                "You are about to delete the selected group(s) and/or return(s).This cannot be undone.\n Do you want to proceed?"
        }
    }
};

export const DeliveredGroupedReturnsConstants = {
    OtherMessage: {
        SelectCustomColumnValue: "Please select column value",
        FilterNameAlreadyExists: "Filter name already exists.",
        FilterFieldsAreEmpty: "Filter fields are Empty! Please provide at least one.",
        PleaseEnterFilterName: "Please enter filter name",
        FilterDoesNotExists: "Filter doesn't exists.",
        DeleteFilter: "Are you sure you want to delete this filter?",
        SaveColumnSettingsSuccess: "Column Settings saved successfully!"
    },
    StatusMessage: {
        SetDefaultFilterSuccess: "Default filter has been changed successfully!.",
        RemoveDefaultFilterSuccess: "Default filter has been Removed successfully!.",
        UnlockDocumentSuccess: "Document have been unlocked successfully!.",
        DeleteSuccess: "Successfully deleted the selected group"
    },
    HeaderActions: {
        UnGroup: {
            Title: "Ungroup Return",
            ConfirmationMessage: "You are about to Ungroup the selected document(s). Do you want to proceed?"
        },
        Delete: {
            Title: "Delete Group(s) and/or Return(s)",
            ConfirmationMessage:
                "You are about to delete the selected group(s) and/or return(s).This cannot be undone.\n Do you want to proceed?"
        }
    }
};

export const InUseGroupedReturnsConstants = {
    OtherMessage: {
        SelectCustomColumnValue: "Please select column value",
        FilterNameAlreadyExists: "Filter name already exists.",
        FilterFieldsAreEmpty: "Filter fields are Empty! Please provide at least one.",
        PleaseEnterFilterName: "Please enter filter name",
        FilterDoesNotExists: "Filter doesn't exists.",
        DeleteFilter: "Are you sure you want to delete this filter?",
        SaveColumnSettingsSuccess: "Column Settings saved successfully!"
    },
    StatusMessage: {
        SetDefaultFilterSuccess: "Default filter has been changed successfully!.",
        RemoveDefaultFilterSuccess: "Default filter has been Removed successfully!.",
        UnlockDocumentSuccess: "Document have been unlocked successfully!."
    }
};

export const DeliveredReturnsConstants = {
    OtherMessage: {
        SelectCustomColumnValue: "Please select column value",
        FilterNameAlreadyExists: "Filter name already exists.",
        FilterFieldsAreEmpty: "Filter fields are Empty! Please provide at least one.",
        PleaseEnterFilterName: "Please enter filter name",
        FilterDoesNotExists: "Filter doesn't exists.",
        DeleteFilter: "Are you sure you want to delete this filter?",
        SaveColumnSettingsSuccess: "Column Settings saved successfully!",
        InvalidGroupDocumentSelection: "Error: Please select one or more groups/documents",
        GroupReturnSetAccessMessage: "Changes to one Return in a group affect all the Returns in the group."
    },
    StatusMessage: {
        SetDefaultFilterSuccess: "Default filter has been changed successfully!.",
        RemoveDefaultFilterSuccess: "Default filter has been Removed successfully!.",
        UnlockDocumentSuccess: "Document have been unlocked successfully!.",
        ChangeOfficeLocationSuccess: "Office Location update may take some time. Thank you for your patience.",
        ChangeOfficeLocationOnlyGroupedReturns: "The Selection involves only grouped returns. Please Check your selection",
        UnlockOneHubSuccess: "OneHub have been unlocked successfully!."
    },
    WarningMessage: {
        SendReminderWarning: "For the selected returns, Automatic reminders has been enabled, do you want to send the reminder?"
    },
    BulkOperationMessage: {
        SendReminderWarning: `Signing Reminder can be sent for the returns which are in 'Awaiting Upload' and 'Awaiting Esign' status. 
                               \nAlso, Automatic reminder has been enabled for the selected returns. 
                               \nDo you still want to continue ?`,
        SendReminderWaitProcessing: "We are processing the reminders for emailing. This may take several minutes",
        SendReminderError: "We are unable to send reminder for these documents.",

        Processing: "This may take several minutes. Thank you for your patience.",
        CustomColumnError: "We are unable to change custom column for these documents.",

        ArchiveError: "We are unable to archive the selected documents.",
        UpdateRemindersError: "We are unable to update the Reminder for selected documents.",
        downloadEFileWarning: `E-File forms can be downloaded for the returns which are in "Esigned", "Uploaded", "Signed and Esigned" and "Alternate and Uploaded" Status.
                               \nDo you want to continue?`,
        downloadEFileWaitProcessing:
            'Download process may take some time , the downloaded Efile forms will be available in "My Downloads"',
        downloadEFileNowWaitProcessing: "Please wait as we are preparing your files",
        downloadEFileError: "Download Failed - An error occurred while downloading the selected records. Please try again",
        DeleteReturnsError: "We are unable to delete the selected documents.",
        ReminderUpdateProcessing: "This may take a while. We will notify you once the update is done.",
        ExportToExcelError: "We are unable to complete the operation."
    },
    ErrorMessage: {
        UploadedESignedEfileFormsCanOnlyDownloadable:
            "The E-file forms in the following status - 'Uploaded', 'E-signed', 'Signed & E-signed', 'Alternate & Uploaded', can be downloaded.",
        ChangeOfficeLocationError: "Failed to change the office location."
    }
};

export const InUseReturnsConstants = {
    ValidationMessage: {
        NoDocumentSelectedWarning: "Please select document(s) to Make available!"
    }
};

export const RecyleReturnsConstants = {
    SelectDocumentWarning: "Please select document(s) to delete!",
    SuccessMessages: {
        Delete: "Deletion in progress, Please refresh after sometime."
    },
    ConfirmMessages: {
        Delete: `Are you sure you want to delete the selected return(s)? On Delete, System will
                \npermanently delete all the return(s) from the SafeSend Returns application and they
                \nwill be no longer available to anyone and the Taxpayer's access link will be
                \ndisabled permanently.`
    },
    Title: {
        RecyleReturns: "<i class='text-danger fas fa-trash-alt'/> Permanent Delete"
    }
};

export const RestoreReturnConstants = {
    SelectDocumentWarning: "Please select document(s) to restore!",
    Progress: "Restore in progress...",
    RestoreNotAllowedMessage: `Restoring isn't possible right now. Please wait a few minutes, refresh the page, and try again.`,
    SuccessMessages: {
        Restore: `These documents may take some time to be restored, and they may not appear in delivered/archived reports immediately.`,
        GroupedReturnRestore: `These documents may take some time to be restored, and they may not appear in group returns reports immediately.`
    },
    ConfirmMessages: {
        Restore: `Are you sure you want to restore the selected return(s)?`
    },
    Title: {
        Restore: "<i class='fas fa-undo'/> Restore"
    },
    ReturnExpired: {
        Restore: "You have selected one or more expired returns. You can't restore the expired return."
    },
    BulkOperationMessage: {
        Processing: "This may take several minutes. Thank you for your patience.",
        Error: "We are unable to restore the selected documents."
    }
};

export const SavedMessageConstants = {
    SaveMessageSuccess: "Message added successfully",
    SaveMessageNameError: "Name already exists",
    SaveMessageError: "Failed to save message",
    UpdateMessageError: "Failed to update message",
    UpdateMessageSuccess: "Message updated successfully",
    DeleteMessageSuccess: "Message deleted successfully",
    ValidateName: "Please enter a message name.",
    ValidateSubject: "Please enter a message subject.",
    ValidateBody: "Please enter a message body.",
    DeleteMessageWarning: "You cannot delete the default message",
    SavedMessageWarning: "Message details",
    SetDefaultMessageWarning: "This message will be set as default message",
    RemoveDefaultMessageWarning: "This message will be removed as default message",
    SelectDefaultMessageWarning: "Please select a default message first"
};

export const ClientInstructionConstants = {
    SaveMessageSuccess: "Instruction added successfully",
    UpdateMessageSuccess: "Instruction Updated successfully",
    DeleteMessageSuccess: "Instruction deleted successfully",
    ValidateName: "Please enter instruction name.",
    ValidateHeader: "Please enter instruction header.",
    ValidateText: "Please enter instruction text.",
    ValidateSubject: "Please enter instruction subject.",
    DeleteInstructionWarning: "You cannot delete the default client instruction.",
    SetDefaultMessageWarning: "This message will be set as default message",
    RemoveDefaultMessageWarning: "This message will be removed as default message",
    SaveMessageError: "Failed to save client instruction",
    UpdateMessageError: "Failed to update client instruction",
    DeleteMessageError: "Failed to delete client instruction",
    FetchMessageError: "Failed to fetch client instruction",
    TextSizeExceedsLimit: "Your email content exceeds 50,000 bytes, preventing saving. Please reduce its size to proceed.",
    MaximumTextSize: 50000
};

export const AttachmentInstructionsConstants = {
    Success: "Attachment instruction added successfully",
    NameError: "Attachment instruction name already exists",
    SaveError: "Failed to save attachment instruction",
    UpdateError: "Failed to update attachment instruction",
    UpdateSuccess: "Attachment instruction updated successfully",
    DeleteSuccess: "Attachment instruction deleted successfully",
    DeleteError: "Unable to delete attachment instruction",
    ValidateName: "Please enter attachment instruction name.",
    ValidateText: "Please enter attachment instruction text.",
    DeleteWarning: "You cannot delete the default attachment instruction",
    SetDefaultWarning: "This message will be set as default attachment instruction",
    RemoveDefaultWarning: "This message will be removed as default attachment instruction",
    SelectDefaultWarning: "Please select a default attachment instruction first"
};

export const UserSettings = {
    fetchUserSettings: "Failed to fetch User Settings",
    saveUserSettingsSuccess: "User Settings saved successfully",
    saveUserSettingsError: "Failed to save User Settings",
    saveSignatureDelegations: "Failed to save delegates",
    fetchUserNotificationSettingsError: "Failed to fetch User Notification Settings",
    saveUserNotificationSettingsError: "Failed to save User Notification Settings",
    saveUserNotificationSettingsSuccess: "User Notification Settings saved successfully"
};
export const ArchiveReturnsConstants = {
    downloadEformFilesState: "Could not find downloadable Efile document(s).",
    downloadDocument: "Could not find downloadable document(s).",
    PleaseSelectDocumentToRestore: "Please select document(s) to restore!",
    PleaseSelectOneOrMoreDocuments: "Please select one or more documents",
    UploadedESignedEfileFormsCanOnlyDownloadable: "Error: Only Uploaded & E-Signed e-file forms can be downloaded",
    OneOrMoreItemsWereNotRemovedAsThierValuesDidNotMatchTheSelectedValue:
        "One or more items were not removed as their values did not match the selected value.",
    documentRestoreConfirmation: "Are you sure you want to restore selected return(s)?"
};

export const RestoreSignatureReturnsConstants = {
    PleaseSelectDocumentToRestore: "Please select document(s) to restore!",
    PleaseSelectOneOrMoreDocuments: "Please select one or more documents",
    documentRestoreConfirmation: "Are you sure you want to restore selected document(s)?",
    SuccessMessage: "Successfully restored the selected document(s)!",
    DocumentExpired: "You have selected one or more expired documents. You can't restore the expired document."
};

export const ReportProblemConstants = {
    OverlayMessage: {
        SubmittingIssue: "Submitting your issue, Please wait..."
    },
    SuccessMessage: "Problem reported successfully. Your Tracking ID is ",
    ErrorMessage: "Failed to report the problem!",
    MaximumFileSize: "15728640"
};
export const AttachmentConstants = {
    FilesizeWarning: "File size limit should not exceed to 1 GB.",
    validDocument: "Please select a valid document, currently selected file is empty.",
    AddAttachments: "Add Attachments",
    UploadedFiles: "Uploaded Files",
    MaxFileSize: "1091900799",
    UploadingIncomplete: "Please wait for uploading to complete before adding instructions"
};

export const UploadtaxReturnConstants = {
    SetEROSignerWarning: "Please select a ERO / Signer to submit.",
    SubmitTaxReturnSuccess: "The following return was successfully submitted",
    SubmitTaxReturnFailed: "Failed to submit the following return, please try again",
    AbortingUploadWarning: "Aborting upload cannot be processed in between , please wait.",
    CloseConfirmationMessage: "Closing this will delete the files, do you want to proceed?",
    UploadingFailedError: "Uploading failed, please try again",
    DeleteFileMessage: "Are you sure you want to delete this file?",
    Recall: {
        FileAddingWarning: "one file is already added.",
        MultipleFileAddingWarning: "only one file can be uploaded."
    },
    ReturnSubmitSuccess: "The following return(s) were successfully submitted",
    EmptyFileListMessage: "Files list will appear here.",
    OverlayMessage: {
        ProcessTaxReturn: "Processing tax return...",
        ApplicationLoading: "Loading, please wait..."
    },
    StatusMessage: {
        TaxReturnLinkError: "Error while fetching tax return link.",
        ProcessTaxReturnError: "Error occured while processing tax return",
        UpdateTaxReturnError: "Unable to update tax return, Please try again"
    },
    GroupedReturnSubmitSuccess: "The following return(s) were successfully submitted",
    GroupedReturnNote:
        "Please note: If you are delivering a 1040 tax return via Grouped Returns, you may need to confirm a completed POA is on file or signer delegation is being used to complete the e-signature step.",
    PdfDocumentUploadWarning: "Do not upload encrypted, password-protected, or locked documents as they cannot be signed.",
    PdfDocumentPasswordProtectedError: " file is encrypted and signature controls can't be placed on it.",
    PdfDocumentGroupedReturnWarning:
        " If you are delivering a 1040 tax return via Grouped Returns, you may need to confirm a completed POA is on file or signer delegation is being used to complete the e-signature step."
};

export const AdditionalEsignDocuments = {
    uploadAdditionalSignFileWarning: "Please upload .doc, .docx or .pdf files only.",
    deleteConfirmMessage: "Are you sure, you want to delete this document?",
    deleteDocument: "Document deleted successfully.",
    documentRequired: "Please upload document(s).",
    documentTypeRequired: "Please select a document(s) type.",
    emptyFileListMessage: "File(s) list will appear here.",
    fetchDocumentError: "Error while fetching document link.",
    uploadInProgress: "File(s) upload in progress",
    abortingUploadWarning: "Aborting upload cannot be processed in between, please wait."
};

export const MyAccountConstants = {
    //API response
    APIResponse: {
        UserUpdateSuccess: "User updated successfully."
    },
    UserUpdateFailedMessage: "Updating user details failed, please re-login"
};

export const ChangeSecurityConstants = {
    SecurityChangeLogoutWarning: "Changing your Password will require you to login again. Make the change?",
    SecurityUpdateSuccess: "Password updated successfully.",
    SecurityUpdateFail: "Updating password failed, please re-login"
};

export const SaveandCloseProcessReturn = {
    StatusMessage: {
        Success: "Your changes are saved successfully",
        Fail: "Error occured while saving your changes."
    }
};

export const SMSTextNotifications = {
    StatusMessage: {
        UpdateSuccess: "SMS Text Notifications saved successfully",
        UpdateError: "Error occured while updating SMS Text Notifications.",
        GetError: "Error occured while fetching SMS Text Notifications."
    }
};

export const FinishProcessReturn = {
    MenuItems: {
        SendForReview: "Send for Review",
        SendToEROSigner: "Send to ERO / Signer",
        ApproveForDelivery: "Approve for Delivery",
        DeliverToClient: "Deliver to Client",
        DeliverToCompany: "Deliver to Company",
        ReturnToProcessor: "Return to Processor",
        DeliverToTaxCaddy: "Deliver to TaxCaddy",
        DeliverWithTaxCaddy: "Deliver with TaxCaddy",
        RetentionPeriod: "Modify Retention Period",
        PaperDelivery: "Download PDF",
        Empty: "",
        DeliverToController: "Deliver to Controller"
    },
    StatusMessage: {
        SendToEROSuccess: "Sent to ERO successfully.",
        SendForReviewSuccess: "Sent for review successfully.",
        ApproveForDeliverySuccess: "Sent to approve for delivery successfully.",
        DeliverToClientSuccess: "Delivered to client successfully.",
        SendToEROError: "Error occured while sending to ERO.",
        SendForReviewError: "Error occured while sending for review.",
        ApproveForDeliveryError: "Error occured while sent to approve for delivery.",
        EROError: "The ERO User you have selected has been modified or deleted. Choose another ERO User.",
        ContactPersonError: "The Contact Person you have selected has been modified or deleted. Choose another Contact Person.",
        NotifiError: "The Notify User you have selected has been modified or deleted. Choose another Notify User.",
        DeliverToClientError: "Error occured while delivering to client.",
        GenerateTaxpayerViewSuccess: "Loaded taxpayer view successfully.",
        GenerateTaxpayerViewError: "Error occured while loading the taxpayer view",
        DeliverToClientWithoutInvoiceError:
            "You are required to include an invoice and amount due with this Tax Return. Please return to the Invoice Step and ensure the invoice is included and the amount is entered in the proper field.",
        SelectApprover: "Please select a user to approve for delivery.",
        SelectDeactivatedDeletedAuthority: "Please Select Alternate Authority for Deleted/DeActivated Authority.",
        TaxCaddyLookupError: "Unable to communicate to TaxCaddy server, please contact support.",
        RetentionValidationMessage: "Please specify a valid retention period.",
        AttachmentValidationMessage: "Attachments status are either uploading or failed to upload!",
        FetchRevisedTaxDocumentError: "Error occured while loading revised tax document(s).",
        DiscardRevisedTaxDocumentSuccess: "Discarded revised tax document(s) successfully.",
        DiscardRevisedTaxDocumentError: "Error occured while discarding revised tax document(s)",
        MarkAsDeliverWithoutEfileError: "Set as Ready For Delivery not allowed without Efile document(s)",
        MarkAsDeliverNotAllowedError: "Return is not allowed to mark as ready for delivery",
        DisableExtensionRemindersConfirmation:
            "<b>Extension Reminder</b><br/><br/>We noticed that the <<taxyear>> extension package had been delivered for this client.<br/>Would you like to turn off all voucher reminders?",
        MailingAddressMissingError: " Mailing address is mandatory.",
        DataIssueMessage: "There is some error occured. Please reload the page."
    },
    InfoMessage: {
        AttchmentNotDeliverToTaxCaddy: "Attachments will not be delivered to TaxCaddy",
        TaxCaddyApprove: "On Accept, this return will be delivered to TaxCaddy",
        TaxCaddyReject: "On Reject, this return will not be delivered to TaxCaddy",
        NotDeliveredToTaxcaddy: " This return will not be delivered to TaxCaddy"
    },
    ValidationMessage: {
        SelectReviewer: "Please select a reviewer to send for review",
        SelectERO: "Please select an ERO / Signer from drop- down"
    }
};

export const PredefinedFileName = {
    K1InstructionBlob: "6c1525a7-69d4-4ca2-b67b-495049f1d132",
    FileNotFound: "FileNotFound"
};

export const K1InstructionFileName = {
    E1065_2015: "Partner`s Instructions for Schedule K-1 (Form 1065)(2015).pdf",
    E1065_2016: "Partner`s Instructions for Schedule K-1 (Form 1065)(2016).pdf",
    E1065_2017: "Partner`s Instructions for Schedule K-1 (Form 1065)(2017).pdf",
    E1120S_2015: "Shareholder`s Instructions for Schedule K-1 (Form 1120S)(2015).pdf",
    E1120S_2016: "Shareholder`s Instructions for Schedule K-1 (Form 1120S)(2016).pdf",
    E1120S_2017: "Shareholder`s Instructions for Schedule K-1 (Form 1120S)(2017).pdf"
};

export const InvoiceReplaceConstants = {
    //API response
    InvoiceAmountWarning: "Please enter a invoice amount.",
    BookmarkTextWarning: "Please enter bookmark text.",
    NonPdfFileWarning: "Please upload PDF file only.",
    MultipleFileWarning: "Please upload one file.",
    InvoiceAmount: "Invoice amount cannot be more than $999,999.99"
};

export const Assignment = {
    Description: "DESCRIPTION"
};

export const AssignUserConstants = {
    SelectDocumentWarning: "Please select document(s) to assign to another user!",
    RecalledReturnWarning: "Return(s) cannot be assigned when their status is RECALLED.",
    IncorrectStatusWarning: "Return(s) cannot be assigned due to status already changed."
};

export const SetAccessConstants = {
    SelectDocumentWarning: "Please select document(s) to set access for other users!",
    RecalledReturnWarning: "Access cannot be set when status is RECALLED.",
    AccessRetricted: "You don't have the permission to assign to another user!",
    CannotProcessReturn: "You don't have the access to process this return."
};

export const DeliverGroupConstants = {
    Title: "Please ensure all selected returns belong to the same group in Ready for Delivery to proceed"
};

export const ChangeStatusConstants = {
    SelectedStatus: "Please select status",
    Title: "Change Status",
    Error: "Cannot change status of the selected document!",
    changeStatusConfirmation: "You are about to change the status of the document manually. Do you want to proceed?",
    ChangeDeliveredReturnStatus:
        'Warning: This document has not been E-Signed by all parties. Changing the status to "Signed & ESigned" indicates that you have obtained the remaining signatures manually (i.e. fax, email, mail etc.). The audit trail for this document will only include those signers who have E-Signed.',
    EmptyReason: "Please enter a reason for changing status"
};

export const UploadCorrectedReturnWarning = {
    message: "If you upload a different return type from the recalled one, it will count towards your usage."
};

export const DeleteTaxReturnConstants = {
    Title: "Delete Tax Return",
    SelectDocumentWarning: "Please select document(s) to delete!",
    InUseReturnWarning: "Warning: Locked returns are not allowed to be deleted!",
    ConfirmDeletion: "You are about to delete the selected document(s). This cannot be undone.\nDo you want to proceed?",
    ConfirmDeletionWithoutUndone: "You are about to delete the selected return(s). \nDo you want to proceed?"
};

export const DisconnectStripe = {
    Title: "Disconnect Stripe Account",
    ConfirmDisconnect: "You are about to disconnect the stripe account. Are you sure?"
};
export const DeleteSignatureConstants = {
    Title: "Delete Tax Return",
    SelectDocumentWarning: "Please select document(s) to delete!",
    InUseReturnWarning: "Warning: Locked returns are not allowed to be deleted!",
    ConfirmDeletion: "You are about to delete the selected document(s). \nDo you want to proceed?",
    SuccessMessage: "Successfully deleted the selected document(s)!",
    ConfirmRecycleDeletion: `Are you sure you want to delete the selected document(s)? On Delete, System will
                \npermanently delete all the document(s) from the application and they
                \nwill be no longer available to anyone and the signer's access link will be
                \ndisabled permanently.`
};
export const ArchiveSignatureConstants = {
    SuccessMessage: "Successfully archived the selected document(s)!"
};
export const DeleteGroupedTaxReturnConstants = {
    Title: "Delete Grouped Tax Return",
    SelectReturnsWarning: "Please select returns(s) to delete!",
    ConfirmDeletion: "You are about to delete the selected returns(s). This cannot be undone.\nDo you want to proceed?",
    ConfirmDeletionWithoutUndone: "You are about to delete the selected returns(s). \nDo you want to proceed?"
};
export const InUseGroupedTaxReturnConstants = {
    Title: "Unlock Tax Return",
    SelectDocumentWarning: "Please select document(s) to unlock!",
    ConfirmSingleUnlock: "Please ensure <<firstName>> is not currently working on these returns before unlocking.",
    ConfirmMultipleUnlock: "Please ensure all users are not currently working on these returns before unlocking."
};
export const BusinessReturnConstants = {
    WarningNonPdfFile: "Please upload PDF file",
    DocumentReplacedSuccessMessage: "Document replaced successfully",
    ReplaceDocumentLoaderMessage: "Replacing Document...",
    getEngagementTypeDisplayName: function (engagementType: EngagementType): string {
        let type: string = "";
        switch (engagementType) {
            case EngagementType.E1065:
                type = "1065";
                break;
            case EngagementType.E1120S:
                type = "1120S";
                break;
            case EngagementType.E1041:
                type = "1041";
                break;
        }
        return type;
    },
    FetchInstructionsErrorMessage: "Failed to fetch Business Return Instructions.",
    FetchInstructionErrorMessage: "Failed to fetch Business Return Instruction."
};

export const CustomWindowConstants = {
    closePopoverMessage: "Your changes will be discarded, Are you sure you want to close the return?"
};

export const CompanySettingConstants = {
    closePopoverMessage:
        "Your proposed change will move %COUNT% delivered returns to recycle bin on %DATE%.  Please confirm you would like to apply this change."
};

export const ClientInstructionMessageVariables = {
    CompanyName: "The listed Name of your company on your account",
    TaxPayerName: "The listed Taxpayer on the Tax Return",
    SpouseName: "The listed Spouse on the Tax Return",
    HelpContact: "The name of the user designated for customer support",
    HelpPhone: "The phone number of the user designated for customer support",
    HelpEmail: "The email address of the designated help contact",
    PrepareName: "The listed Preparer on the Tax Return",
    DueDate: "The due date of the filing",
    NumberOfays: "Number of days set for voucher reminder",
    Recipient: "The person name for which the email is being sent",
    TaxYear: "The Taxyear on the Tax Return",
    EROName: "The name of the assigned ERO Signer",
    SenderName: "The name of the user selected as the 'Sender' of the return",
    GroupName: "The name of particular group",
    ControllerName: "Controller name of the group"
};

export const Version = {
    VersionNumber: "V 4.0"
};

export const ClientInfoConstants = {
    emailUpdatedConfirmation:
        "You have updated the email address on file, would you like to resend the Return to the new email address?"
};

export const ReGroupConstants = {
    PageMoveSuccess: "Page moved successfully",
    DeletedPageMoveSuccess: "Page moved to Deleted successfully",
    SameSourceAndDestinationError: "Unable to move selected forms as source and destination groups are same",
    PageDeleteNotAllowed: "Action Not Allowed: There must be at least one page existing in this group.",
    RevisionEstimatePageDeleteSuccess: "Page deleted successfully",
    PaidVoucherDeleteWarning: "You cannot delete paid voucher."
};

export const PageToolBarConstants = {
    moveToDeleteConfirmation: "Are you sure you want to move this page to deleted?",
    deleteRevisedEstimatePageConfirmation: "Are you sure you want to delete this page?"
};

export const ReturnDownloadOptionsConstants = {
    IncludeInTaxReturnPdf: "Include in the Tax Return PDF",
    CreateSeperatePdf: "Create a Separate PDF",
    SeparateByQuarterlyDueDates: "Separate by Quarterly Due Dates",
    GroupInSinglePDF: "Group in Single PDF"
};

export const ProcessReturnConstants = {
    ReturnAlreadyOpenedMessage: "Return already opened !!",
    ReturnInUse: "Return In-Use",
    ReturnNotAssignedToLoggedInUser:
        "This return is assigned to another user. Please make sure the return is assigned to you in order to make any changes.",
    ProcessReturnOpenMessage: "Opening Return ...",
    DuplicateReturnMessage:
        "<br/>We have detected that this return has already been delivered. Please review the Delivered Returns or Archived Returns reports to ensure you are not delivering a duplicate tax return <br/><br/><br/> Please press <b>OK</b> to continue and <b>Cancel</b> to go back",
    ReturnAlreadyProcessed: "Return already processed"
};

export const ReProcessReturnConstants = {
    ReProcessInitiatedModalLoader: "Reprocessing initiated..."
};

export const EsignHelpTextConstant = {
    ClientSignature: "Drag and drop to place client's signature",
    SignatureDate: "Drag and drop to place date of signature",
    NameAndTitle: "Drag and drop to place Name and Title",
    EroSignatureStamp: "Drag and drop to place ERO signature stamp",
    Initial: "Drag and drop to place Initials control",
    Text: "Drag and drop to place Text control",
    Company: "Drag and drop to place Company control",
    Name: "Drag and drop to place Name control",
    Title: "Drag and drop to place Title control",
    PrintName: "Drag and drop to place Print Name control",
    Checkbox: "Drag and drop to place Checkbox control",
    RadioButton: "Drag and drop to place Radio Button control"
};

export const SecurityPolicyConstants = {
    NoChanges: "There are no changes to save.",
    MinimumCharacter: "Minimum numbers of characters should be greater than 8.",
    MinimumOption: "Select at least two options in character type."
};

export const SignatureFlowReportConstants = {
    DocumentNote: {
        SaveSuccess: "Successfully saved document note.",
        UpdateSuccess: "Successfully updated document note.",
        DeleteSuccess: "Successfully deleted document note.",
        Error: "Error occured while saving document note."
    },
    CancelDocument: {
        Description:
            "By canceling this document, recipients can no longer view it or sign enclosed documents. Recipients will receive an email notification, which includes your reason for canceling the document",
        CanceledTitle: "Canceled Document",
        CancelTitle: "Cancel Document",
        DeclinedTitle: "Declined Document",
        CanceledReasonTilte: "Reason for canceling this document.",
        DeclinedReasonTitle: "Reason for declining this document.",
        UpdateSuccess: "Successfully updated your changes.",
        Error: "Error occured while saving your changes.",
        EmptyDescriptionWarningMsg: "Please enter reason for canceling this document"
    },
    SendReminder: {
        Success: "Reminder has been sent successfully for the selected document(s)",
        Error: "Failed to send reminder for the selected document(s)!"
    },
    DeleteReturn: {
        Success: "Successfully deleted the selected return(s)!",
        Error: "Failed to delete the selected document(s)!"
    },
    SetAccess: {
        Success: "Document Access set successfully for all selected returns",
        Error: "Setting document access for the users failed!"
    },
    ResendAccessLink: {
        Success: "Access Link has been sent successfully for the selected returns",
        Error: "Failed to resend access link for the selected document(s)!"
    },
    AccessCode: {
        Success: "Access Code generated successfully",
        Error: "Failed to generated OTP for the return"
    },
    CancelDocumentMail: {
        Success: "Cancel document notification has been sent successfully for the selected document signers",
        Error: "Failed to send cancel document notification for the selected document(s)!"
    },
    DeleteDocument: {
        Success: "Successfully deleted the selected signature report",
        Error: "Failed to deleted the selected signature report"
    },
    DownloadHistory: {
        Error: "Failed to fetch download history for the selected document."
    },
    DownloadDocument: {
        Success: "Document(s) downloaded successfully.",
        Error: "Failed to download the selected document(s).",
        ModalTitle: "Download Documents",
        ModalMessage: "Click the below links to download",
        DocumentNotSignedError: "Document(s) with 'E-SIGNED' status only can be downloaded."
    },
    SignatureSigners: {
        Error: "Error occured while fetching signature signers"
    },
    UpdateSignatureSigners: {
        Error: "Error occured while updating signature signers",
        Success: "Updated the signature signer(s) details successfully"
    }
};

export const SignatureFlowReportCustomColumnConstants = {
    StatusMessage: {
        ErrorFetchingCustomColumns: "Error occured while fetching signature report custom columns.",
        ErrorUpdatingCustomColumns: "Error occured while updating signature report custom columns.",
        ErrorSavingCustomColumns: "Error occured while saving signature report custom columns.",
        UpdatingCustomColumnsSuccess: "Successfully updated signature report custom columns.",
        SavingCustomColumnsSuccess: "Successfully saved signature report custom columns."
    }
};

export const SignatureFlowReportFilterConstants = {
    StatusMessage: {
        SavedSuccessMessage: "Filter saved successfully.",
        SavedErrorMessage: "Failed to save the filter!",
        UpdateSuccessMessage: "Default filter has been changed successfully!.",
        UpdateErrorMessage: "Failed to update the filter!",
        DeleteSuccessMessage: "Filter deleted successfully.",
        DeleteErrorMessage: "Failed to delete the filter!",
        GetAllErrorMessage: "Failed to fetch the filters!"
    },
    ValidationMessage: {
        NoRecentFilters: "There is no recent filter to apply!",
        EmptyDocumentNote: "Please enter a document note."
    },
    ControlsLabel: {
        ApplyRecentFilterBtn: "Apply recent filter",
        SaveCurrentFilterBtn: "Save current filter",
        SetAsDefaultFilter: "Set as Default Filter",
        DeleteFilter: "Delete Filter",
        ExportToExcel: "Export to Excel",
        ClearFilter: "Clear Filter",
        RemoveDefault: "Remove Default",
        PleaseEnterTheFilterName: "Please enter the filter name",
        Apply: "Apply",
        SetDefault: "Set Default",
        Delete: "Delete",
        AppliedFilter: "Applied Filter"
    },
    OtherMessage: {
        FilterNameAlreadyExists: "Filter name already exists.",
        FilterFieldsAreEmpty: "Filter fields are Empty! Please provide at least one.",
        PleaseEnterFilterName: "Please enter filter name",
        FilterDoesNotExists: "Filter doesn't exists.",
        DeleteFilter: "Are you sure you want to delete this filter?",
        CreateOrUpdateFilter: "Do you want to create New Filter or Update the Current?",
        NoReturnsFound: "No returns found"
    },
    FilterSuccessMessage: {
        RemoveDefaultFilterSuccess: "Default filter has been Removed successfully!."
    },

    FilterConfirmationModalTitle: {
        FilterSaveUpdate: "Save/Update Filter"
    },
    FormLabel: {
        YesCreateNew: "Yes, Create New",
        NoUpdateTheCurrent: "No, Update The Current"
    }
};

/////////////////////////////////////////Tab Constants Start/////////////////////////////////////////////////
export const K1TabConstants = {
    K1UploadConfirmation: "Document replaced successfully.",
    ssnWarning: "Please enter valid SSN/EIN.",
    partnerTypeWarning: "Please select Partner Type.",
    ssnMaskHelpText: "If enabled, SSN will be masked on all shareholder Documents"
};

export const TabEFileConstants = {
    Info: {
        EROStampInfoMessage: "The ERO stamp will be applied in all pages",
        DoNotInsertDates: "Do not insert dates on the E-File forms",
        DoNotInsertNameAndTitle: "Do not insert Name and Title on the Entity E-File Forms",
        DoNotInsertDatesMessage:
            "Date fields have been disabled according to firm settings. They can be enabled by disabling the toggle or by having a system administrator update the settings."
    },
    SignatureControlWarning: {
        Taxpayer: "There should be at least one signature control for the taxpayer to sign.",
        Partner: "There should be at least one signature control for the partner to sign.",
        Spouse: "There should be at least one signature control for the spouse to sign.",
        EroStamp: "There should be at least one ERO signature stamp.",
        ManualSign: "There should be at least one E-File."
    },
    EfileForm: {
        Authority: "Please select a taxing authority "
    }
};

export const ClientInfoTab = {
    Page: {
        DeleteConfirm: "Are you sure you want to delete this page?"
    },
    AddedRefund: {
        DeleteConfirm: "Are you sure to delete?",
        AuthorityWarning: "Please select a taxing authority to add.",
        AmountEmptyWarning: "Please enter valid amount.",
        OverpaymentLessThanWarning: "Overpayment amount should be greater than the sum of the overpayment applied and penalty.",
        DOBWarning: "Please enter valid date of birth."
    },
    UpdateRefund: {
        RefundUpdateSuccess: "Refunds & payments Due updated successfully"
    },
    DeleteRefund: {
        RefundDeleteSuccess: "The refund amount has been successfully removed."
    }
};

export const TabAdditionalEsignDocumentConstants = {
    Info: {
        EROStampInfoMessage: "The ERO stamp will be applied in all pages"
    },
    SignatureControlWarning: {
        WarningMessage:
            "A signature control is necessary for any Additional E-Sign document(s). Please make sure to include a signature control for each uploaded document.",
        EroStamp: "There should be at least one ERO signature stamp in all the additional document."
    }
};

export const VoucherTabConstants = {
    AddedVoucher: {
        DeleteConfirm: "Are you sure you want to delete this Voucher?",
        AmountEmptyWarning: "Please enter valid amount.",
        AuthorityWarning: "Please select a taxing authority.",
        PaymentTypeWarning: "Please select a payment type.",
        DueDateWarning: "Please select the due date.",
        FormNameWarning: "Please enter form name",
        OnlinePaymentUrlWarning: "Please enter payment url.",
        OnlinePaymentValidUrlWarning: "Please enter valid payment url.",
        CustomTypeWarning: "Please select the watermark option.",
        CustomTextWarning: "Please enter a custom watermark.",
        OneVoucherWarning: "You must create at least one Voucher by entering the related Amount ($).",
        AddMultiplePaymentDuplicateVoucherWarning:
            "You cannot add duplicate voucher(s). Voucher(s) with Authority: <Authority> and Estimated Payment Type for: <PaymentType> already exists!.",
        DuplicateVoucherWarning: "You cannot add duplicate voucher. Voucher with same authority and payment type already exists!."
    },
    AddVoucher: {
        Authority: "Authority",
        PaymentType: "Payment Type",
        DueDate: "DueDate",
        Amount: "Amount",
        FormName: "Form Name",
        AddVoucher: "Add Payment",
        PaymentUrl: "Payment Url",
        VoucherAmount: "Voucher Amount",
        voucherNumbers: [
            { value: "0", label: "Select" },
            { value: "1", label: "Payment Voucher" },
            { value: "2", label: "Q1 Estimated Payment" },
            { value: "3", label: "Q2 Estimated Payment" },
            { value: "4", label: "Q3 Estimated Payment" },
            { value: "5", label: "Q4 Estimated Payment" }
        ],
        paymentMethods: [
            { value: "1", label: "Select Watermark" },
            { value: "2", label: "DO NOT PAY:  Scheduled for automatic withdrawal" },
            { value: "3", label: "Online payment required" },
            { value: "4", label: "Add Custom" }
        ],
        voucherPdfPrepareMessageFailed: "Preparing voucher failed",
        voucherDeleteFailed: "Cannot Delete Vouchers apart from Manually added",
        voucherEditWarning: "No Fields Updated",
        btnAddVoucherText: "Add",
        voucherDueDateUpdate: "Voucher DueDate Update Success",
        voucherAddSuccess: "Data updated successfully for "
    },
    DatabaseValues: {
        FederalAuthorityName: "Federal"
    },
    VoucherSortBookmarkWordMatchCount: 1,
    DeleteZeroAmountVoucher_1: "Are you sure you want to delete ",
    DeleteZeroAmountVoucher_2: " '$0' vouchers?",
    VoucherUpdateSuccess: "Voucher details updated successfully",
    VoucherDueDateInvalid: "Please update the due date for voucher ",
    VoucherPaymentType: "Please update the Payment Type for voucher ",
    VoucherAuthorityWarning: "Please Update the Authority for voucher ",
    PaymentMethods: [
        { value: "0", label: "Payment Voucher" },
        { value: "1", label: "Q1 Estimated Payment" },
        { value: "2", label: "Q2 Estimated Payment" },
        { value: "3", label: "Q3 Estimated Payment" },
        { value: "4", label: "Q4 Estimated Payment" }
    ],
    voucherDueDateTypes: [
        { value: "1", label: "SSR Default Due date" },
        { value: "2", label: "Voucher Form" }
    ],
    voucherDueDateTypesSettings: [
        { value: "1", label: "Sherlock default due dates" },
        { value: "2", label: "Printd Due Date on Voucher" }
    ],
    SelectDueDate: "Select Due date from",
    NoPrintedDueDate: "Selected Voucher(s) do not have printed due dates.",
    PaymentStatusTitle: "Payment Status",
    EditingPaidVoucherDisabled: "You cannot edit this voucher since the payment has been completed."
};

export const EfileTabConstats = {
    UpdatedAuthority: {
        AuthorityUpdateSuccess: "Authority successfully updated"
    },
    SignerDelegation: {
        EROSignatureControlDisabled: "ERO Stamp delegation is disabled"
    }
};

export const GroupTabConstants = {
    singlePageDragGhostText: "1 Page.",
    Page: {
        DeleteConfirm: "Are you sure you want to delete this page?",
        WarningValueNotMatch: " One or more items were not removed as their values did not match the selected value."
    }
};

export const ClientInfoValidation = {
    inCorrectEmail: "Please enter valid email address.",
    inValidSSN: "Please enter valid SSN.",
    inCorrectTaxpayerEmail: "Please enter valid taxpayer email address.",
    inCorrectSpouseEmail: "Please enter valid spouse email address.",
    inCorrectCompanyEmail: "Please enter valid company email address.",
    inCorrectK1Email: "Please enter valid shareholder email address.",
    inValidTaxpayerSSN: "Please enter valid taxpayer SSN.",
    inValidSpouseSSN: "Please enter valid spouse SSN.",
    inCorrectPartner: "Please select ERO / Signer",
    inCorrectERO: "Please select an ERO / Signer from drop-down",
    inValidTaxPayerName: "Please enter valid taxpayer name.",
    inValidSpouseName: "Please enter valid spouse name.",
    inCorrectControllerEmail: "Please enter valid controller Email address.",
    invalidCountryCodeTaxpayer: "Please select a country code for taxpayer's mobile number",
    invalidCountryCodeSpouse: "Please select a country code for spouse's mobile number",
    invalidCountryCodeCompany: "Please select a country code for company's mobile number",
    invalidMobileNumberTaxpayer: "Please enter mobile number for taxpayer",
    invalidMobileNumberSpouse: "Please enter mobile number for spouse",
    invalidMobileNumberCompany: "Please enter mobile number for company",
    mobileNumberLengthWarningTaxpayer: "Please enter a valid mobile number for taxpayer, according to the country code selected",
    mobileNumberLengthWarningSpouse: "Please enter a valid mobile number for spouse, according to the country code selected",
    mobileNumberLengthWarningCompany: "Please enter a valid mobile number for company, according to the country code selected",
    inCorrectLocation: "Please select office location.",
    ssnDuplicateWarningMessage: "SSNs for the taxpayer and spouse are the same. Please update the SSNs to avoid duplicates.",
    invalidState: "Please select State."
};

export const ControllerInfoValidation = {
    inCorrectName: "Please enter valid Controller Name.",
    inValidSSN: "Please enter valid SSN.",
    inCorrectControllerEmail: "Please enter valid controller Email Id.",
    nameIsMandatory: "Please enter Controller Name.",
    emailIsMandatory: "Please enter Controller Email."
};

export const DeliveryOptionsValidation = {
    InCorrectContactPerson: "Please select a Contact Person.",
    InValidMessageFromPreparer: "Please select a Preparer Message.",
    InValidSenderName: "Please select a Sender Name.",
    InValidNotifyAboutSigningsEvents: "Please select a contact to Notify About Signing Events.",
    InvalidNumberofDaysVoucherReminder: "Please select a number of days for the Voucher Reminders.",
    InvalidNumberofDaysSigningReminder: "Please select a number of days for the Signing Reminder.",
    InvalidNotifyAboutPaymentEvents: "Please select a contact to Notify About Payment Events."
};

export enum SignatureStatusIds {
    None,
    ESigned,
    ManuallySigned,
    Uploaded,
    AwaitingESign,
    AwaitingUpload,
    Locked,
    MailOrFax,
    SignedAndESigned,
    PartiallySigned
}

export const FileConstants = {
    MaximumFileSize: "536870912",
    FileSizeForEncryptionValidation: "20971520"
};

export const FileSizeExceedsLimit = (fileName: string) => `${fileName} cannot be uploaded since the size is greater than 512 MB.`;

/////////////////////////////////////////Tab Constants End/////////////////////////////////////////////////

export const K1SettingsConstants = {
    K1MailCycleOptions: [
        { value: 1, label: "Before" },
        { value: 2, label: "After" }
    ]
};

export const DownloadEfileZip = (date: string) => `efile_download_${date}.zip`;

export const DownloadGroupedReturnEfileZip = (date: string, groupName: string) =>
    groupName + "_signed documents_download_" + `${date}.zip`;

export const DownloadReturnsZip = (clientId: string, taxYear: number) => `${clientId}_Tax Returns_${taxYear}.zip`;

export const DownloadUnsignedReturnsZip = (clientId: string, taxYear: number) => `${clientId}_Tax Returns-Copy_${taxYear}.zip`;

export const DownloadAttachmentsZip = () => `Attachments.zip`;

export const DownloadAdditionalDocuments = (clientId: string, taxYear: number, engagementType: EngagementType) =>
    `${clientId}_${taxYear}_${engagementType}_Other unsigned Documents.zip`;

export const DownloadSignedAdditionalDocuments = (clientId: string, taxYear: number, engagementType: EngagementType) =>
    `${clientId}_${taxYear}_${engagementType}_Other signed Documents.zip`;

export const ClientTrackingEvents = {
    InitialEmail: "Initial Email - ",
    VoucherReminderEmail: "Voucher Reminder Email - ",
    AccessCodeEmail: "Access Code Email - ",
    TaxPayerKBASuccess: "Taxpayer KBA Successful",
    SpouseKBASuccess: "Spouse KBA Successful ",
    DelegateeKBASuccess: "Delegatee KBA Successful",
    TaxPayerKBAFailed: "Taxpayer KBA Failed",
    SpouseKBAFailed: "Spouse KBA Failed",
    DelegateeKBAFailed: "Delegatee KBA Failed",
    SigningReminderEmail: "Signing Reminder Email - ",
    StatusChangeToSignedAndEsigned: "Status changed to Signed and E-Signed - ",
    RevisionInitialEmail: "Voucher Revision Email - "
};

export const ConsentTrackingEvents = {
    ConsentReceived: "Received Consent (Text Msg.)",
    ConsentDeclined: "No Consent (Text Msg.)",
    OptedOutSMSNotification: "Withdrew Consent (Text Msg.)",
    OptedInSMSNotification: "Re-Opted In (Text Msg.)",
    ConsentExpired: "Consent Expired (Text Msg.)"
};

export enum DocumentEventValue {
    "None",
    "Created" = 1,
    "Document Assign To" = 2,
    "Document ERO Stamp Decline" = 3,
    "ERO Sign Decline" = 4,
    "Document Delivery Approved" = 5,
    "Emailed" = 6,
    "Document Change Status Manual" = 7,
    "Tax Return Accessed" = 8,
    "Document Authenticated" = 9,
    "Authentication Failed" = 10,
    "SSN Authentication Failed" = 11,
    "OTP Authentication Failed" = 12,
    "OTP Access Locked" = 13,
    "Tax Return Reviewed" = 14,
    "Viewed" = 15,
    "Efile Forms Downloaded" = 16,
    "Efile Forms Uploaded" = 17,
    "Taxpayer Esigned - eFile Forms" = 18,
    "Spouse Esigned - eFile Forms" = 19,
    "Vouchers Reviewed" = 20,
    "Voucher Reminder Disabled" = 21,
    "Voucher Reminder Enabled" = 22,
    "Attachment Downloaded" = 23,
    "All Attachment Downloaded" = 24,
    "All K1 Document Downloaded" = 25,
    "Document Attachments Reviewed" = 26,
    "Payment System Accessed" = 27,
    "Invoice Reviewed" = 28,
    "Finished" = 29,
    "Access Link Emailed - " = 30,
    "Transmittal Reviewed" = 31,
    "Document TaxSaved" = 32,
    "K-1 Distributed To" = 33,
    "Document Download All" = 34,
    "Document Filing Instructions Downloaded" = 35,
    "Document Tax Return Downloaded" = 36,
    "Document Vouchers Downloaded" = 37,
    "Document Invoice Downloaded" = 38,
    "Document Signed Efile Downloaded" = 39,
    "KBA Passed" = 40,
    "KBA Failed" = 41,
    "Document KBA Success" = 42,
    "Document KBA Failed" = 43,
    "DOB Changed" = 44,
    "Document Opt Out ESign Option Selected" = 45,
    "Document Signed" = 46,
    "Signed Document Reviewed" = 47,
    "Voucher Reminder Updated" = 48,
    "Deleted" = 49,
    "Efile Forms Deleted" = 50,
    "Shareholder Consent Received" = 51,
    "Shareholder Consent Declined" = 52,
    "K-1 Document Downloaded" = 53,
    "K-1 Instruction Document Downloaded" = 54,
    "Document Mail Status" = 55,
    "Tax Return - Recalled" = 56,
    "Voucher Remainder Mail Status" = 57,
    "Document Request Recall Return" = 58,
    "Document Request Delete Return" = 59,
    "Shared" = 60,
    "Cancelled" = 61,
    "Filed" = 62,
    "Document Rejected" = 63,
    "Access Code Email" = 64,
    "Partnership Esigned - eFile Forms" = 65,
    "Document Signed Efile Downloaded By CPA" = 66,
    "Reminder Sent For Signing" = 67,
    "Download Link Sent" = 68,
    "Payment Completed" = 69,
    "Not Defined" = 75,
    "Delivered To TaxCaddy" = 77,
    "Taxcaddy Delivery Failed" = 78,
    "Return Sent For Signing" = 83,
    "Return - Archived" = 84,
    "Return - Restored From Archive" = 85,
    "Return Status - Changed To Manually Signed" = 86,
    "Access link Sent" = 87,
    "Additional Document Downloaded" = 89,
    "All Additional Document Downloaded" = 90,
    "Taxpayer Esigned - Other Signature Documents " = 91,
    "Spouse Esigned - Other Signature Documents " = 92,
    "Partnership Esigned - Other Signature Documents" = 93,
    "Documents Forwarded To CC Recipient(s)" = 94,
    "Return Restored" = 95,
    "Tax Return - Uploaded New Return" = 96,
    "Tax Return - Reprocessed" = 97,
    "Payment Details Added for" = 100,
    "Payment Details Updated for" = 101,
    "TP Disabled Voucher Reminder" = 102,
    "Document Signing Delegated" = 103,
    "Delegatee Updated" = 104,
    "Delegation Cancelled" = 105,
    "Delegatee Declined Signing" = 106,
    "Delegatee Esigned - eFile Forms" = 107,
    "Delegatee Esigned - Other Signature Documents " = 109,
    "Controller Esigned - eFile Forms on behalf of <Taxpayer>(<TaxpayerEmail>)" = 111,
    "Controller Esigned - Other Signature Documents on behalf of <Taxpayer>(<TaxpayerEmail>)" = 112,
    "Invoice Payment Detail Added for Document" = 113,
    "Invoice Payment Detail Updated for Document" = 114,
    "Invoice Payment Detail Cleared for Document" = 115,
    "Paper File Returns Consented" = 116,
    "Paper File Returns Downloaded" = 117,
    "All Paper File Returns Downloaded" = 118,
    "Paper File Returns Printed" = 119,
    "All Paper File Returns Printed" = 120,
    "Signature Stamping Failed" = 123,
    "Signature Reminder Text -" = 139,
    "Signed Doc. Received Text -" = 140,
    "Taxpayer E-Signed – In Office - eFile Forms" = 141,
    "Spouse E-Signed – In Office - eFile Forms" = 142,
    "Partnership E-Signed – In Office - eFile Forms" = 143,
    "Controller E-Signed – In Office - eFile Forms" = 144,
    "OneHub Esigned - eFile Forms" = 146,
    "OneHub Esigned - Other Signature Documents " = 147,
    "OneHub Document Signing Delegated" = 148,
    "OneHub Delegatee Updated" = 149,
    "OneHub Delegation Cancelled" = 150,
    "OneHub Documents Forwarded To CC Recipient(s)" = 151
}

export enum UserEventValue {
    "None",
    "Upload" = 1,
    "Save & Close" = 2,
    "Sent for Review" = 3,
    "Sent for ERO/Signer" = 4,
    "Sent for Approve for Delivery" = 5,
    "Deliver to Company" = 6,
    "Deliver to Taxpayer" = 7,
    "Deliver to Spouse" = 8,
    "Download PDF" = 9,
    "E-File Forms Downloaded" = 10,
    "Recalled Return" = 11,
    "Reprocessed Return" = 12,
    "Moved Document to Archived" = 13,
    "Deleted Document" = 14,
    "Return sent with KBA disabled" = 15,
    "Archive and Restore of return" = 16,
    "Deleted Document Restored" = 17,
    "Mark Ready For Delivery" = 18,
    "Return Delivered To Controller" =19
}

export const DownloadReturnsConstants = {
    downloadReturn: "Click on the links below to download"
};

export const InboxConstants = {
    InboxFetchError: "Unable to fetch notifications.",
    InboxMessageUpdateError: "Unable to update notification.",
    InboxMessageClearError: "Unable to clear notifications."
};

export const ScreenShareConstants = {
    screenShareInfo: "Taxpayer should enter the given below code to start screen sharing",
    controllerScreenShareInfo: "Controller should enter the given below code to start screen sharing"
};
// SF Constants

export const SFDocument = {
    sfUploadFileWarning: "Please upload .doc, .docx or .pdf files only.",
    sfUploadUnsupportedFileWarning: "Failed to Upload : File format not supported.",
    deleteConfirmMessage: "Are you sure, you want to delete this document?",
    deleteDocument: "Document deleted successfully.",
    documentRequired: "Please upload document(s).",
    documentTypeRequired: "Please select a document(s) type.",
    emptyFileListMessage: "Files list will appear here.",
    documentExpireOnRequired: "Please select expire(s) on.",
    documentExpireOnLesser: "Document expiry date should be on or after ",
    fetchDocumentError: "Error while fetching document link.",
    uploadInProgress: "File(s) upload in progress",
    signatureControlRequired: "Document(s) must have one signature control placement.",
    BackgroundProcessInProgress: "Background process is in progress, Please wait...",
    outForSignature: "Document(s) successfully out for signature.",
    documentCoversionError: "Error while coverting the document.",
    senderSignatureControlRequired: "Document(s) must have one signature control placement for sender.",
    signerControlRequired: "Document(s) must have atleast one control placement for each recipient.",
    clientNameRequired: "Please enter Client Name.",
    taxYearRequired: "Please select Tax Year."
};

export const SFMessageConstants = {
    MessageRequiredWarning: "Message cannot be empty."
};

export const SigFlowCompanySettingsConstants = {
    DocumentType: {
        DocumentTypeAddSuccess: "Document Type added successfully.",
        DocumentTypeAddError: "Error occured while adding Document Type.",
        DocumentTypeUpdateSuccess: "Document Type updated successfully.",
        DocumentTypeUpdateError: "Error occured while updating Document Type.",
        DocumentTypeFetchError: "Error occured while fetching Document Types.",
        DocumentTypeEditAlert: "The change in the setting will not impact already delivered documents."
    }
};

export const SignatureFlowConstants = {
    ContactsFetchError: "Error occured while fetching Contacts.",
    SendForSignatureFailed: "Unable to Send for Signature, Please try again",
    MailMergeFailed: "Mail Merge failed, Please try again",
    OutForMailMerge: "Document successfully out for Mail Merge.",
    MailMergeValidateBtnInfo: "Click validate to verify your data",
    MailMergeInvalidCsvWarning: "Columns mismatch, please refer template.",
    MailMergeInvalidEmailAddressText: "Please enter valid Email Address",
    MailMergeRequiredEmailAddressText: "This is a required field, Please enter valid Email Address.",
    MailMergeCustomFieldText: "Value upto 80 characters can be entered.",
    SendingForSignatureOverlayMessage: "Sending for Signature...",
    MailMergeMaxCharsExceededErrorMsg: "Value of custom fields cannot exceed 80 characters",
    SignatureFieldNotPlacedWarningMsg: "Signature not placed for <Recipient Mail ID> do you want to proceed any way? ",
    SignatureFieldNotPlacedWarningMsgPlaceholder: "<Recipient Mail ID>",
    SignatureFlowSettingsEnableNote: "Note: You will need to re-login to see the changes take effect.",
    SignatureFlowSettingsDownloadNote: "Click to download the SS Mail merge setup file."
};

export const OrganizerConstants = {
    SettingsEnableNote: "Note: You will need to re-login to see the changes take effect.",
    EnableWarning: "Additional charges are incurred for enabling this service.",
    GatherSourceDocuments: "Enable gathering for taxpayer source documents"
};

export const ExtensionConstants = {
    SettingsEnableNote: "Note: You will need to re-login to see the changes take effect.",
    EnableWarning: "Additional charges are incurred for enabling this service."
};

export const ExchangeConstants = {
    ExchangeWebAddInDownloadNote: "Click to download the Exchange Web add-in setup file."
};

export const MailMergeDocument = {
    CsvUploadFileWarning: "Please upload .csv file only.",
    FileInfoMessage: "File info will appear here.",
    MailMergeTemplateFileName: "MailMergeTemplate.csv",
    DownloadSampleTemplate: "Download Template",
    HeadingUploadCsv: "Upload CSV",
    HeadingValidationResult: "Validation Result",
    SendingForMailMergeOverlayMessage: "Sending for Mail Merge...",
    DownloadExcelBtnText: "Download"
};

export enum SignatureReportClientTrackingEvents {
    "None" = 0,
    "Created",
    "Document Mailed",
    "Document Viewed",
    "Document E-Signed",
    "Document Signed and Filed ",
    "Shared ",
    "SendForSignature",
    "Reminder Sent For Signing",
    "Document Downloaded By CPA",
    "All Documents Downloaded By CPA",
    "Cancelled Document",
    "Signed Document Downloaded By CPA",
    "All Signed Documents Downloaded By CPA",
    "Access Code Email",
    "Declined Email",
    "Download Signed Document(s) Mailed",
    "Signed Document Downloaded",
    "All Signed Documents Downloaded",
    "Access Link Emailed",
    "Document Mailed - Delegated To",
    "Partially Signed Document Downloaded ",
    "Delegation Cancelled",
    "Document Signed And Downloaded",
    "Delegatee Updated",
    "Signing Completion Mailed",
    "Document - Archived",
    "Document - Recycled",
    "Document - Restored From Archive",
    "Document - Restored"
}

export const IPAddressConstant = {
    FromIPWarning: "Please enter from IP address.",
    ToIPWarning: "Please enter to IP address.",
    ValidFromIPWarning: "Please enter a valid from IP address.",
    ValidToIPWarning: "Please enter a valid to IP address.",
    InvalidIPRangeWarning: "Invalid IP address range!",
    CloseEditingWarning: "Close current editing.",
    DeleteIpAddress: "Are you sure, you want to delete?",
    SaveSuccessfully: "IP Address saved successfully",
    UpdateSuccessfully: "IP Address updated successfully",
    DeleteSuccessfully: "IP Address deleted successfully"
};

export const OTPVerify = {
    InvalidOTP: "Invalid one-time access code",
    validOTP: "One-time access code verified successfully",
    NotVerified: "One-time access code verification failed"
};

export const BrandingConstants = {
    StatusMessage: {
        UpdateCompanyWhiteLogoError: "Unable to update white logo, Please try again",
        DeleteWhiteLogoConfirmation: "Are sure you want to delete the Uploaded Company White Logo?",
        CompanyWhiteLogoDeleted: "Company White Logo has been deleted Successfully",
        DeleteCompanyPrimaryLogoConfirmation:
            "Are sure you want to delete the Uploaded Company Primary Logo and Set Safesend Returns Logo as Company Logo?"
    },
    OverlayMessage: {
        UpdatingCompanySettings: "Updating company settings...",
        ApplicationLoading: "Loading, please wait..."
    },
    Validate: {
        ImageWarning: "Please upload only image.",
        ImageDimensionWarning: "Max dimensions are 300x100px.",
        ImageValidation: "Image validation failed! try again..."
    },
    HeaderName: {
        Branding: "Branding Section",
        CompanyWhiteLogo: "Company White Logo",
        ColorPalette: "Color Palette",
        CompanyWhiteLogoMessage:
            "Your selected white logo will be used in all areas that contain a colored background (Company White Logo Dimension should be less than 300x100px)",
        UploadCompanyWhiteLogo: "Upload Company White Logo",
        ColorPaletteMessage: "Customize Taxpayer Page colors and Email format colors ",
        ChangeColors: "Change Colors"
    },
    ContentName: {
        HeaderBgColor: "Header Background Color",
        HeaderFontColor: "Header Font Color",
        LandingPageFolderBgColor: "Folder Background Color",
        LandingPageFolderFontColor: "Folder Font Color"
    }
};

export const customAuthority = {
    customAuthorityId: 100001
};

export const watermarkConstants = {
    NameWarning: "Please enter name",
    TextWarning: "Please enter text",
    FontSizeWarning: "Please enter font size",
    FontSizeValueWarning: "Please enter the font size between 1 to 72",
    FontFamilyWarning: "Please select font",
    OpacityWarning: "Please select opacity",
    DeleteWatermark: "Are you sure, you want to delete?",
    SaveSuccessfully: "Watermark saved successfully",
    UpdateSuccessfully: "Watermark updated successfully",
    DeleteSuccessfully: "Watermark deleted successfully",
    SetDefaultWatermarkWarning: "This watermark will be set as default watermark",
    RemoveDefaultWatermarkWarning: "This watermark will be removed as default watermark"
};

export const MyDownloadsConstants = {
    StatusMessage: 'Download process may take some time, the downloaded <FILENAME> will be available in "Downloads"',
    DeleteMyDownload: "Selected File has been deleted",
    ClearAllMyDownloads: "All Downloaded files has been deleted",
    DeleteSingleFileWarning: "File cannot be deleted, since Download is in progress.",
    DialogBox: {
        ConfirmDeletionSingle: "Are you sure you want to delete this file?",
        ConfirmDeletionAll: "Are you sure you want to clear all files?",
        Confirm: "Yes",
        Cancel: "Cancel",
        Title: "Confirm Deletion"
    }
};
export const BulkPurchaseDiscountConstant = {
    ErrorMessage: "Failed to load discount details for bulk purchase"
};
export const PreviewConstants = {
    Scope: {
        ClientPreview: "client_preview",
        ClientView: "client_view",
        ControllerView: "controller_view"
    }
};

export const GeneralSettingsConstants = {
    NoTaxSoftwareMessage: "Please select atleast one Tax Software."
};

export const GroupReturnConstants = {
    Title: "Group Configuration",
    SelectDocumentWarning: "Please select document(s) to group return!",
    ConfirmGroup: "You are about to move the selected document(s) to group. Do you want to proceed?",
    GroupProcessReturnOpenMessage: "Opening Group Return ...",
    GroupProcessReturnEROSignerText: "Please select ERO/Signer",
    StatusMessage: {
        GroupConfigurationAddSuccess: "Group added successfully.",
        GroupConfigurationAddError: "Error occured while adding group.",
        GroupConfigurationUpdateSuccess: "Group name updated successfully.",
        GroupConfigurationUpdateError: "Error occured while updating group.",
        MapReturnToGroupSuccess: "Return(s) moved to group successfully.",
        MapReturnToGroupError: "Error occured while moving the return(s) to group.",
        GroupConfigurationAndMapReturnToGroupSuccess: "Group added successfully and return(s) moved to group successfully.",
        SendForReviewSuccess: "Sent for review successfully.",
        ApproveForDeliverySuccess: "Sent to approve for delivery successfully.",
        DeliverToClientSuccess: "Delivered to controller successfully.",
        SendForReviewError: "Error occured while sending for review.",
        ApproveForDeliveryError: "Error occured while sent to approve for delivery.",
        DeliverToClientError: "Error occured while delivering to controller.",
        SendToEROSuccess: "Sent to ERO successfully.",
        SendToEROError: "Error occured while sending to ERO.",
        FinishError: "Please select the return(s) to perform the action."
    },
    ValidationMessage: {
        GroupConfigurationWarning: "Please enter group name.",
        GroupConfigurationAlreadyExist: "Group name already exists.",
        GroupConfigDropDownWarning: "Please select a group."
    }
};

export const GroupProcessReturnsConstants = {
    ControllerInfoTab: {
        NameRequired: "Name is mandatory",
        EmailRequired: "Email is mandatory"
    },
    DisableExtensionRemindersForGroupedReturnsConfirmation:
        "<b>Extension Reminder</b><br/><br/>We noticed that one or more extension packages had been delivered for this client.<br/>Would you like to turn off all voucher reminders?"
};

export const SendGroupedReturnsHeaderConstants = {
    StatusMessage: {
        UnMapReturnToGroupSuccess: "Return(s) removed from group successfully.",
        UnMapReturnToGroupError: "Error occured while removing the return(s) from group.",
        GroupAccessRequestError: "Error occured while fetching group access details.",
        UpdateGroupAccessSuccess: "Group Access set successfully for all groups",
        UpdateGroupAccessError: "Setting Group access for the users failed!",
        AssignUserSuccess: "Successfully assigned the document(s) to selected user",
        AssignUserError: "Failed to assign the document(s) to user!",
        DeleteSuccess: "Successfully deleted the selected group(s) and/or return(s)",
        DeleteError: "Failed to delete the selected group(s) and/or document(s)!",
        UpdateSingleGroupAccessSuccess: "Group Access set successfully for the group"
    }
};

export const GroupedTaxDocumentConstants = {
    StatusMessage: {
        MarkAsReadyForDeliverySuccess: "Return marked as Ready for Delivery successfully.",
        MarkAsReadyForDeliveryError: "Failed to marked as Ready for Delivery!"
    }
};

export const DeliveredGroupedReturnsHeaderConstants = {
    StatusMessage: {
        UnMapReturnToGroupSuccess: "Return(s) removed from group successfully.",
        UnMapReturnToGroupError: "Error occured while removing the return(s) from group.",
        GroupAccessRequestError: "Error occured while fetching group access details.",
        UpdateGroupAccessSuccess: "Group Access set successfully for all groups",
        UpdateGroupAccessError: "Setting Group access for the users failed!",
        AssignUserSuccess: "Successfully assigned the document(s) to selected user",
        AssignUserError: "Failed to assign the document(s) to user!",
        DeleteSuccess: "Successfully deleted the selected group(s) and/or return(s)",
        DeleteError: "Failed to delete the selected group(s) and/or document(s)!",
        CustomCulumnValueUpdateSuccess: "Custom column Value(s) has been successfully updated for the Tax Returns",
        CustomCulumnValueUpdateError: "Failed to update the Custom column!",
        CustomCulumnValueRemoveSuccess: "Custom column value(s) has been successfully removed for the Tax Returns",
        CustomCulumnValueRemoveError: "Failed to update the Custom column!",
        SendSignReminderSuccess: "Reminder has been sent successfully for the selected Group(s)",
        SendSignReminderError: "Failed to send reminder for the selected Group(s)!"
    }
};
export const RecyleGroupedReturnsConstants = {
    SelectDocumentWarning: "Please select document(s) to delete!",
    SuccessMessages: {
        Delete: "Deletion in progress, Please refresh after sometime."
    },
    ConfirmMessages: {
        Delete: `Are you sure you want to delete the selected return(s)? On Delete, System will
                \npermanently delete all the return(s) from the SafeSend Returns application and they
                \nwill be no longer available to anyone and the Taxpayer's access link will be
                \ndisabled permanently.`
    },
    Title: {
        RecyleReturns: "<i class='text-danger fas fa-trash-alt'/> Permanent Delete"
    }
};

export const SignatureControlConstants = {
    ControlIdPrefix: "control_"
};

export const SendGroupedReturnsGridActionConstants = {
    StatusMessage: {
        DeleteReturnSuccess: "Successfully deleted the selected return(s)",
        DeleteReturnError: "Failed to delete the selected return(s)!"
    }
};
export const SendControllerInfoMailConstants = {
    StatusMessage: {
        SendControllerMailError: "Error occured while sending mail to Controller."
    }
};

export const PurchaseReturn = {
    PurchaseReturnLimit: "You can't purchase more than 50,000 returns in an order. Please adjust the quantity.",
    FailurefetchPurchseSummary: "Error occured while loading purchase details",
    SavePurchase: "Purchase saved successfully.",
    FailureSavePurchase: "There was an error while processing your purchase. Please try again later.",
    FailureGenerateToken: "There was an error while adding the card. Please try again later.",
    Validation: {
        NoItemInCartValidation: "Please add items to purchase.",
        SafeSendReturnCountValidation: "Please enter valid SafeSend return quantity to purchase.",
        SignatureCountValidation: "Please enter valid Signature quantity to purchase.",
        OrganizerCountValidation: "Please enter valid SafeSend Organizer quantity to purchase."
    }
};

export const StripeAccountConstants = {
    accountFetchingFailed: "Error occurred while fetching the account details!",
    accountRegistrationFailed: "Error occurred while registering stripe account!"
};
export const UpdateType = {
    Membership: "membership",
    UserDetail: "userdetail"
};

export enum VoucherPaymentConstants {
    "None" = 0,
    "Payment Voucher" = 1,
    "Q1 Estimated Payment" = 2,
    "Q2 Estimated Payment" = 3,
    "Q3 Estimated Payment" = 4,
    "Q4 Estimated Payment" = 5
}
export const GroupNameInfoConstants = {
    GroupNameInfo: "Select an existing group or create a new group to add this return to it. This will disable the Finish button."
};

export const PaperFile = {
    uploadPaperFileWarning: "Please upload .doc, .docx, .xlsx or .pdf files only.",
    deleteConfirmMessage: "Are you sure, you want to delete this document?",
    deleteDocument: "Document deleted successfully.",
    documentRequired: "Please upload document(s).",
    authorityRequired: "Please select authority.",
    mailingAddressRequired: "Please enter mailing address for <authorityName>_<formName>",
    emptyFileListMessage: "File(s) list will appear here.",
    fetchDocumentError: "Error while fetching document link.",
    uploadInProgress: "File(s) upload in progress",
    formNameRequired: "Please enter form name",
    abortingUploadWarning: "Aborting upload cannot be processed in between, please wait.",
    fileNotFound: "No Paper File Returns have been Uploaded !!"
};
export const staffReturn = {
    AssignmentChange: "Assignment to this return has been changed, Please refresh.",
    ServerError: "Internal server error."
};

export const RevisionDocumentsConstant = {
    uploadRevisionDocumentExtensionWarning: "Please upload .pdf files only.",
    deleteConfirmMessage: "Are you sure, you want to delete this document?",
    deleteDocument: "Document deleted successfully.",
    documentRequired: "Please upload document(s).",
    emptyFileListMessage: "File(s) list will appear here.",
    fetchDocumentLinkError: "Error while fetching document link.",
    uploadInProgress: "File(s) upload in progress",
    abortingUploadWarning: "Aborting upload cannot be processed in between, please wait.",
    OverlayMessage: {
        ProcessRevisedDocument: "Processing revised document...",
        ApplicationLoading: "Loading, please wait..."
    },
    submitRevisionDocumentError: "Unable to submit estimates, Please try again",
    submitRevisionDocumentSuccess: "Estimated documents uploaded successfully",
    CloseConfirmationMessage: "Closing this will delete the files, do you want to proceed?",
    DiscardTitle: "Discard Uploaded Estimates",
    DiscardConfirmatiomMessage: "Are you sure. Do you want to discard the revised document(s)?",
    RevisionProcessModalConfirmation:
        "Recognition Failed: <RecognizedForms> out of <TotalForms> forms got recognized. <NonRecognizedForms> forms failed to recognize. If you proceed with processing the forms the unrecognized forms will be unavailable.",
    NoChangesInRevisedEstimate: "No changes were made in revised estimate(s).",
    NoRevisedEstimatesFound:
        "Duplicate Estimates found: You are trying to upload duplicate estimate form(s). The system will not display duplicate forms when processing the uploaded forms. Do you want to continue?",
    ReturnInUse: "Return In-Use by <UserName>. Any modifications done by <UserName> will be discarded. Do you want to proceed?",
    DueDateSelectionToolTip:
        "On Choosing 'Printed Due date on Voucher Form' System will pull the Due date from Voucher Forms else system will pick the SSR Default Due date.If the chosen due date is expired then system will not display those voucher forms which has expired due dates."
};

export const EulaConstant = {
    eulaStatusfetchError: "Unable to fetch Eula Status",
    eulaStatusUpdationFailed: "Unable to update the Eula Status",
    eulaAccepted: "Eula accepted Successfully",
    eulaDeclined: "Eula Declined",
    eulaPdfLinkfailed: "Unable to fetch Eula file",
    eulaNotAgreed: "Select the checkbox before accepting"
};

export const SSOAccessRequestConstants = {
    StatusMessage: {
        SSOAccessRequestError: "Error occured while fetching SSO Access Requests",
        GrantSSOAccessRequestSuccess: "Access request to Safesend Suite via Office365 is granted successfully.",
        GrantSSOAccessRequestError: "Failed to grant access request.",
        DenySSOAccessRequestSuccess: "Access request to Safesend Suite via Office365 is Denied.",
        DenySSOAccessRequestError: "Failed to deny access request.",
        RevokedSSOAccessRequestSuccess: "Office 365 access revoked successfully.",
        RevokedSSOAccessRequestError: "Failed to revoke Office 365 access.",
        AlreadyGranted: "Access request has been already approved.",
        AlreadyDenied: "Access request has been already rejected."
    }
};

//temporary data to simulate Groups and Role
export interface IUserGroupModel {
    groupId: number;
    groupName: string;
    groupDescription: string;
    isDefault: boolean;
    roleDetails: IUserRoleModel[];
    userIds: number[];
}

export interface IUserRoleModel {
    roleId: number;
    roleName: string;
    roleDescription: string;
    isDefault: boolean;
    moduleAccess: IModule[];
}

export interface IModule {
    moduleId: number;
    moduleName: string;
    moduleDescription: string;
}

export const initialUserRBACModel: IUserGroupModel[] = [
    {
        groupId: 1,
        groupName: "SystemAdmin",
        groupDescription: "System Admin",
        isDefault: true,
        userIds: [1, 2],
        roleDetails: [
            {
                roleId: 1,
                roleName: "SuperUser",
                roleDescription: "Super User",
                isDefault: false,
                moduleAccess: [
                    {
                        moduleId: 5,
                        moduleName: "DeleteDocument",
                        moduleDescription: "Delete Document"
                    },
                    {
                        moduleId: 6,
                        moduleName: "Recall",
                        moduleDescription: "Recall"
                    }
                ]
            }
        ]
    },
    {
        groupId: 2,
        groupName: "TaxAdmin",
        groupDescription: "Tax Admin",
        isDefault: true,
        userIds: [1, 2],
        roleDetails: [
            {
                roleId: 2,
                roleName: "Processor",
                roleDescription: "Processor",
                isDefault: true,
                moduleAccess: [
                    {
                        moduleId: 7,
                        moduleName: "",
                        moduleDescription: ""
                    }
                ]
            },
            {
                roleId: 5,
                roleName: "ReadOnly",
                roleDescription: "Read Only",
                isDefault: true,
                moduleAccess: []
            }
        ]
    },
    {
        groupId: 3,
        groupName: "Staff",
        groupDescription: "Staff",
        isDefault: true,
        userIds: [1, 2],
        roleDetails: [
            {
                roleId: 3,
                roleName: "Preparer",
                roleDescription: "Preparer",
                isDefault: true,
                moduleAccess: []
            }
        ]
    },
    {
        groupId: 4,
        groupName: "Temp",
        groupDescription: "Temp",
        isDefault: true,
        userIds: [1, 2],
        roleDetails: [
            {
                roleId: 2,
                roleName: "Processor",
                roleDescription: "Processor",
                isDefault: true,
                moduleAccess: []
            },
            {
                roleId: 4,
                roleName: "Signer",
                roleDescription: "Signer",
                isDefault: true,
                moduleAccess: [
                    {
                        moduleId: 1,
                        moduleName: "Upload",
                        moduleDescription: "Upload"
                    },
                    {
                        moduleId: 2,
                        moduleName: "Process",
                        moduleDescription: "Process"
                    },
                    {
                        moduleId: 3,
                        moduleName: "Assign",
                        moduleDescription: "Assign"
                    },
                    {
                        moduleId: 4,
                        moduleName: "Deliver",
                        moduleDescription: "Deliver"
                    }
                ]
            }
        ]
    },
    {
        groupId: 5,
        groupName: "Intern",
        groupDescription: "Intern",
        isDefault: true,
        userIds: [1, 2],
        roleDetails: [
            {
                roleId: 5,
                roleName: "ReadOnly",
                roleDescription: "Read Only",
                isDefault: true,
                moduleAccess: []
            }
        ]
    },
    {
        groupId: 6,
        groupName: "Partner",
        groupDescription: "Partner",
        isDefault: true,
        userIds: [1, 2],
        roleDetails: [
            {
                roleId: 3,
                roleName: "Preparer",
                roleDescription: "Preparer",
                isDefault: true,
                moduleAccess: []
            }
        ]
    },
    {
        groupId: 7,
        groupName: "Manager",
        groupDescription: "Manager",
        isDefault: true,
        userIds: [1, 2],
        roleDetails: [
            {
                roleId: 4,
                roleName: "Signer",
                roleDescription: "Signer",
                isDefault: true,
                moduleAccess: [
                    {
                        moduleId: 1,
                        moduleName: "Upload",
                        moduleDescription: "Upload"
                    },
                    {
                        moduleId: 2,
                        moduleName: "Process",
                        moduleDescription: "Process"
                    },
                    {
                        moduleId: 3,
                        moduleName: "Assign",
                        moduleDescription: "Assign"
                    },
                    {
                        moduleId: 4,
                        moduleName: "Deliver",
                        moduleDescription: "Deliver"
                    }
                ]
            },
            {
                roleId: 3,
                roleName: "Preparer",
                roleDescription: "Preparer",
                isDefault: false,
                moduleAccess: []
            }
        ]
    },
    //-------------------------------------------------

    {
        groupId: 6,
        groupName: "Partner",
        groupDescription: "Partner",
        isDefault: true,
        userIds: [1, 2],
        roleDetails: [
            {
                roleId: 3,
                roleName: "Preparer",
                roleDescription: "Preparer",
                isDefault: true,
                moduleAccess: [
                    {
                        moduleId: 1,
                        moduleName: "Upload",
                        moduleDescription: "Upload"
                    },
                    {
                        moduleId: 2,
                        moduleName: "Process",
                        moduleDescription: "Process"
                    }
                ]
            }
        ]
    }
];

export const initialModules: IModule[] = [
    {
        moduleId: 1,
        moduleName: "Upload",
        moduleDescription: "Upload"
    },
    {
        moduleId: 2,
        moduleName: "Process",
        moduleDescription: "Process"
    },
    {
        moduleId: 3,
        moduleName: "Assign",
        moduleDescription: "Assign"
    },
    {
        moduleId: 4,
        moduleName: "Deliver",
        moduleDescription: "Deliver"
    },
    {
        moduleId: 5,
        moduleName: "DeleteDocument",
        moduleDescription: "Delete Document"
    },
    {
        moduleId: 6,
        moduleName: "Recall",
        moduleDescription: "Recall"
    },
    {
        moduleId: 7,
        moduleName: "Archive",
        moduleDescription: "Archive"
    },
    {
        moduleId: 8,
        moduleName: "Restore",
        moduleDescription: "Restore"
    },
    {
        moduleId: 9,
        moduleName: "EROSignatures",
        moduleDescription: "ERO Signatures"
    },
    {
        moduleId: 10,
        moduleName: "Review",
        moduleDescription: "Review"
    },
    {
        moduleId: 11,
        moduleName: "Viewreports",
        moduleDescription: "View reports"
    },
    {
        moduleId: 12,
        moduleName: "AccountManagement ",
        moduleDescription: "Account Management "
    },
    {
        moduleId: 13,
        moduleName: "Settings ",
        moduleDescription: "Settings"
    }
];

export const K1Attachments = {
    deleteConfirmMessage: "Are you sure, you want to delete this document?",
    deleteDocument: "Document deleted successfully.",
    documentRequired: "Please upload document(s).",
    emptyFileListMessage: "File(s) list will appear here.",
    fetchDocumentError: "Error while fetching document link.",
    uploadInProgress: "File(s) upload in progress",
    abortingUploadWarning: "Aborting upload cannot be processed in between, please wait.",
    noRecipientSelected: "Recipient(s) not selected for one of the document(s)"
};

export const AwesomePdfViewerText = {
    NoFormPageAvailable: "No forms found !!"
};

export const ReminderReportUrl = {
    getOldReminderReport: "GetOldReminderReport",
    getReminderReport: "GetReminderReport"
};

export const ExportVoucherReminderReportUrl = {
    exportOldVoucherReminderStatus: "ExportExcelOldVoucherReminderStatus",
    exportVoucherReminderStatus: "ExportExcelVoucherReminderStatus"
};

export const userSettingConstants = {
    profile: "Profile",
    loginHistory: "Login History",
    mySettings: "My Settings",
    myDownloads: "My Downloads",
    logout: "Logout",
    support: "Support"
};

export const UserAutoLogoutPopupConstants = {
    Header: "Permission Changed",
    Message: "Your SafeSend permissions have been changed. You must login again for the changes to take effect."
};
export const SSRProductType = 1;

export const hideHeaderAndSideMenu = ["/restricted-access"];

export const ImportCCHAxcess = {
    Header: {
        login: "Login",
        disconnect: "Disconnect",
        connected: "Connected",
        notConnected: "Not Connected",
        title: "Import from CCH Axcess",
        disconnectText:
            "This action will terminate the connection with CCH Axcess Tax, requiring you to reauthenticate. Please note that any ongoing uploads from CCH may be affected and might not be completed.",
        proceed: "Proceed",
        cancel: "Cancel",
        loginTitle: "Login and connect with your credentials to get your Tax Returns",
        loggedInAs: "Logged in as ",
        loginAlertText:
            "Sorry, the connection is associated with a different account. To switch to another account, please disconnect and log in again.",
        disconnectAlertText: "The connection has already been disconnected.",
        disconnectSuccess: "The connection has been successfully disconnected."
    },
    TableHeader: {
        refreshTooltip: "Refresh",
        getReturns: "Get CCH Returns",
        getReturnsTooltip: "Retrieves returns from CCH.",
        import: "Import to Safesend",
        importTooltip: "Select return(s) with import status as 'New'.",
        pullReturnsMsg: "Returns are being retrieved. Refresh after a while.",
        importReturnsMsg: "Import is in progress! On completion, your tax return will be ready to process in “Send Tax Return”",
        bulkSelectionMsg:
            "All {rowsInCurrentPage} records on this page are selected. To select the remaining {remainingRecords} filtered records,",
        clickHere: "click here",
        resetSuccessMsg: "Reset Return completed successfully.",
        importDisabledTooltip: "Login and connect to CCH to import into Safesend.",
        importInProgress: "Import in progress...",
        getReturnsDisabledTooltip: "Login and connect to CCH to retrieve returns."
    },
    Table: {
        clientId: "Client ID",
        clientName: "Client Name",
        returnId: "Return ID",
        returnType: "Return Type",
        returnStatus: "CCH Return Status",
        version: "Version",
        importStatus: "Import Status",
        action: "Action",
        taxYear: "Tax Year",
        resetTooltip: "Reset return",
        resetDisableTooltip: "Reset is allowed only for import status 'Failed' or 'Completed.'",
        pagerText: "Showing {start} to {to} of {total} entries",
        statusFailed: "Failed",
        statusCompleted: "Completed",
        statusInProgress: "InProgress",
        statusNew: "New",
        syncTitle: "Sync Return",
        syncText:
            "Please note, by resyncing return we will pull the latest tax return from CCH Axcess and immediately upload it into SafeSend Returns. Do you wish to proceed?",
        syncDisableTooltip: "Sync is allowed only for import status 'Failed' or 'Completed'",
        syncWhenNotLoggedIn: "Login and connect to CCH to sync return.",
        confirm: "Confirm"
    },
    Filters: {
        Title: "Get CCH Returns",
        Description: 'Apply filters based off of columns present in the "Import CCH Axcess" Report',
        When: "When",
        And: "and",
        AddNewFilter: "Add New Filter",
        Apply: "Apply",
        ClearAll: "Clear All",
        ClearAllTooltip: "Clear all filters except for tax year",
        Clear: "Clear filter",
        SelectFilter: "Select filter",
        SelectVersion: "Select version",
        SelectReturnType: "Select Return Type",
        EnterReturnStatus: "Enter CCH Return Status",
        EnterClientId: "Enter Client ID",
        All: "All",
        ApplyDisableTooltip: "Please select filter options and values to enable the apply button."
    }
};

export const AwesomePdfViewerDefaultScale = 1.3;

export const KNOWN_EXCEPTION_MESSAGES = [
    "ResizeObserver loop limit exceeded",
    "ResizeObserver loop completed with undelivered notifications.",
    "ErrorEvent:",
    "ErrorEvent: Script error."
];

export const AccessCodeConstants = {
    AccessCode: {
        Success: "Access Code generated successfully.",
        Info: "Please note that generating a new code will not result in the sending of a new email.",
        GenerateAccessCodeToolTip: "Generate Access Code"
    }
};

export const ReleaseEFileConstants = {
    Title: "E-File Release",
    ConfirmEFileRelease:
        "You are about to release the e-file candidate and e-file it to the IRS. Please ensure that all necessary checks have been performed to ensure the tax return is ready to be released. <br/><br/>You <b>cannot reverse</b> the release from Safesend. Are you sure you want to continue?"
};
