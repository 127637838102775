import * as React from "react";
import { RouteComponentProps } from "react-router";
import { PaymentVoucherNotification } from "./VoucherAndInvoiceComponent/PaymentVoucherNotification";
import { InvoiceSettings } from "./VoucherAndInvoiceComponent/InvoiceSettings";
import { ICompanySettings, initialCompanySettings, PaymentOption } from "../../Core/ViewModels/Company/CompanySettingsViewModel";
import { isAttachedURLValid } from "../helper/Validations";
import { VenusNotifier } from "../helper/VenusNotifier";
import { SettingsHeader } from "../common/settings/SettingsHeader";
import * as CompanyStore from "../../store/company/CompanyStore";
import * as PaymentAccountStore from "../../store/PaymentAccount/PaymentAccountStore";
import * as Constants from "../helper/Constants";
import { OverlayLoader } from "../helper/OverlayLoader";
import * as UserSettingsStore from "../../store/userManagement/UserSettingStore";
import { VoucherDueDateSetting } from "./VoucherAndInvoiceComponent/VoucherDueDateSetting";
import * as ApplicationHelptextStore from "../../store/common/ApplicationHelptextStore";
import { CustomRouterPrompt } from "./CustomRouterPrompt";
import { TaxReturnSettingsResources } from "../../components/helper/ResourceIdConstants";
interface IVoucherAndInvoice {
    companySettings: ICompanySettings;
    showStripeIntegrationSettingsPopup: boolean;
    showAccountDetailsPopup: boolean;
    showCustomPrompt: boolean;
}

type CompanySettingsProps = {
    companyData: CompanyStore.ICompanyData;
    paymentAccountData: PaymentAccountStore.IAccountModel;
    helptexts: ApplicationHelptextStore.IHelptextState;
} & typeof CompanyStore.actionCreators &
    typeof UserSettingsStore.actionCreators &
    typeof PaymentAccountStore.actionCreators &
    typeof ApplicationHelptextStore.actionCreators &
    RouteComponentProps<{}>;

export class VouchersAndInvoices extends React.Component<CompanySettingsProps, IVoucherAndInvoice> {
    constructor(props: CompanySettingsProps) {
        super(props);

        ////////////////////Settings Header//////////////////////////
        this.handleApplyButton = this.handleApplyButton.bind(this);
        this.handleCancelButton = this.handleCancelButton.bind(this);

        //////////////////////Online Payment Setting /////////////////////////////////////
        this.handlePaymentUrl = this.handlePaymentUrl.bind(this);

        ////////////////////// Invoice Settings ////////////////////////////////////////
        this.handleEnableInvoice = this.handleEnableInvoice.bind(this);
        this.handleAllowUserToReplaceInvoice = this.handleAllowUserToReplaceInvoice.bind(this);
        this.handleAllowUserToSendwithoutInvoice = this.handleAllowUserToSendwithoutInvoice.bind(this);

        ////////////////////////// Payment Voucher Notification start //////////////////////////////
        this.handlePaymentVoucherReminder = this.handlePaymentVoucherReminder.bind(this);
        this.handleSettingVoucherReminderDays = this.handleSettingVoucherReminderDays.bind(this);
        this.handleVoucherPaymentNotification = this.handleVoucherPaymentNotification.bind(this);

        ////////////////////// Stripe Settings ////////////////////////////////////////

        this.handleEnablePriorToReview = this.handleEnablePriorToReview.bind(this);
        this.handleAllowUserToSendReturnWithoutPayment = this.handleAllowUserToSendReturnWithoutPayment.bind(this);
        this.handleShowAccountDetails = this.handleShowAccountDetails.bind(this);
        this.handleDisconnect=this.handleDisconnect.bind(this);

        this.state = {
            companySettings: initialCompanySettings,
            showStripeIntegrationSettingsPopup: false,
            showAccountDetailsPopup: false,
            showCustomPrompt: false
        };
    }
    componentDidMount() {
        const element = document.getElementById("content-wrapper");
        element && element.scrollTo(0, 0);
    }
    UNSAFE_componentWillMount() {
        this.props.requestCompanySettings(true);
        this.props.requestUserSettings(true);

        if (
            this.props.companyData.companySettings &&
            this.props.companyData.companySettings.taxpayerPaymentSettings &&
            this.props.companyData.companySettings.taxpayerPaymentSettings.companyConnectedAccountId
        )
            this.props.requestAccountDetails(
                this.props.companyData.companySettings.taxpayerPaymentSettings.companyConnectedAccountId
            );

        this.props.requestHelptextsForApplication(true);
    }

    private enableCustomRouterPrompt = () => {
        this.setState({ showCustomPrompt: true });
    };

    private disableCustomRouterPrompt = () => {
        this.setState({ showCustomPrompt: false });
    };

    onHideStripePopup = () => {
        let temp = this.props.companyData.companySettings as ICompanySettings;
        temp.taxpayerPaymentSettings.payOption = PaymentOption.AttachedURL;

        this.setState({
            showStripeIntegrationSettingsPopup: false,
            companySettings: temp
        });
    };

    onHideAccountDetailsPopup = () => {
        this.setState({
            showAccountDetailsPopup: false
        });
    };

    ////////////////////Settings Header start//////////////////////////
    handleApplyButton(event: any, resourceId?: string) {
        let companySettings = this.props.companyData.companySettings as ICompanySettings;
        if (companySettings.taxpayerPaymentSettings.payOption != PaymentOption.AttachedURL) {
            companySettings.displaySettingsModel.onlinePaymentUrl = "";
        }
        let onlinePaymentUrl =
            companySettings.displaySettingsModel.onlinePaymentUrl != undefined
                ? companySettings.displaySettingsModel.onlinePaymentUrl
                : "";

        if (companySettings.displaySettingsModel.isEnableInvoice && 
             !isAttachedURLValid(companySettings.taxpayerPaymentSettings.payOption, onlinePaymentUrl)) {
                VenusNotifier.Warning(Constants.ValidationContants.PaymentURLValidation, null);
                event?.preventDefault();
        } else {
            companySettings.displaySettingsModel.onlinePaymentUrl =
                !isAttachedURLValid(companySettings.taxpayerPaymentSettings.payOption,
                    companySettings.displaySettingsModel.onlinePaymentUrl) ? "" : companySettings.displaySettingsModel.onlinePaymentUrl;
            this.props.updateCompanySettings(companySettings, undefined, resourceId);
            this.disableCustomRouterPrompt();
        }
    }

    handleCancelButton() {
        VenusNotifier.Error("Not yet implemented", null);
    }
    ////////////////////Settings Header start//////////////////////////

    //////////////////////Online Payment Setting start /////////////////////////////////////
    handlePaymentUrl(event: any) {
        let temp = this.props.companyData.companySettings as ICompanySettings;
        temp.displaySettingsModel.onlinePaymentUrl = event.target.value;
        this.setState({
            companySettings: temp
        });
        this.enableCustomRouterPrompt();
    }
    //////////////////////Online Payment Setting end /////////////////////////////////////

    ////////////////////// Invoice Settings start ////////////////////////////////////////
    handleEnableInvoice(event: any) {
        let temp = this.props.companyData.companySettings as ICompanySettings;
        if (event.target.checked == false) {
            temp.displaySettingsModel.isEnableInvoice = event.target.checked;
            temp.userPrivilegeSettingsModel.allowUserToSendwithoutInvoice = false;
            temp.userPrivilegeSettingsModel.allowUserToReplaceInvoice = false;
        }
        temp.displaySettingsModel.isEnableInvoice = event.target.checked;
        this.setState({
            companySettings: temp
        });
        this.enableCustomRouterPrompt();
    }

    handleAllowUserToReplaceInvoice(event: any) {
        let temp = this.props.companyData.companySettings as ICompanySettings;
        if (temp.displaySettingsModel.isEnableInvoice == false) {
            temp.displaySettingsModel.isEnableInvoice = true;
        }
        temp.userPrivilegeSettingsModel.allowUserToReplaceInvoice = event.target.checked;
        this.setState({
            companySettings: temp
        });
        this.enableCustomRouterPrompt();
    }

    handleAllowUserToSendwithoutInvoice(event: any) {
        let temp = this.props.companyData.companySettings as ICompanySettings;
        if (temp.displaySettingsModel.isEnableInvoice == false) {
            temp.displaySettingsModel.isEnableInvoice = true;
        }
        temp.userPrivilegeSettingsModel.allowUserToSendwithoutInvoice = event.target.checked;
        this.setState({
            companySettings: temp
        });
        this.enableCustomRouterPrompt();
    }
    ////////////////////// Invoice Settings end ////////////////////////////////////////

    ////////////////////////// Payment Voucher Notification start //////////////////////////////
    handlePaymentVoucherReminder(event: any) {
        let temp = this.props.companyData.companySettings as ICompanySettings;
        temp.notificationSettingsModel.paymentVoucherNotificationSettings.enablePaymentVoucherReminder = event.target.checked;
        this.setState({
            companySettings: temp
        });
        this.enableCustomRouterPrompt();
    }

    handleSettingVoucherReminderDays(event: any) {
        if (event && event.value > 0) {
            let temp = this.props.companyData.companySettings as ICompanySettings;
            temp.notificationSettingsModel.paymentVoucherNotificationSettings.noOfDaysForVoucherReminder = parseInt(event.value);
            this.setState({
                companySettings: temp
            });
            this.enableCustomRouterPrompt();
        }
    }

    handleVoucherPaymentNotification(event: any) {
        let temp = this.props.companyData.companySettings as ICompanySettings;
        temp.notificationSettingsModel.paymentVoucherNotificationSettings.paymentNotification = event.target.checked;
        this.setState({
            companySettings: temp
        });
        this.enableCustomRouterPrompt();
    }
    ////////////////////////// Payment Voucher Notification end //////////////////////////////

    ////////////////////// Invoice Settings start ////////////////////////////////////////

    handleEnablePriorToReview(event: any) {
        let temp = this.props.companyData.companySettings as ICompanySettings;
        if (event.target.checked == false) {
            temp.taxpayerPaymentSettings.priortoReview = event.target.checked;
            temp.taxpayerPaymentSettings.allowUserToSendReturnWithoutPayment = false;
        }
        temp.taxpayerPaymentSettings.priortoReview = event.target.checked;
        this.setState({
            companySettings: temp
        });
        this.enableCustomRouterPrompt();
    }

    handleAllowUserToSendReturnWithoutPayment(event: any) {
        let temp = this.props.companyData.companySettings as ICompanySettings;
        if (event.target.checked == false) {
            temp.taxpayerPaymentSettings.allowUserToSendReturnWithoutPayment = event.target.checked;
        }
        temp.taxpayerPaymentSettings.allowUserToSendReturnWithoutPayment = event.target.checked;
        this.setState({
            companySettings: temp
        });
        this.enableCustomRouterPrompt();
    }

    handlePayOptionChange = (payOption: PaymentOption) => {
        let popupStatus: boolean = false;
        let temp = this.props.companyData.companySettings as ICompanySettings;
        temp.taxpayerPaymentSettings.payOption = payOption;

        if (payOption === PaymentOption.Stripe) {
            temp.taxpayerPaymentSettings.allowUserToSendReturnWithoutPayment = false;
            if (!temp.taxpayerPaymentSettings.companyConnectedAccountId) {
                popupStatus = true;
            }
        } else {
            temp.taxpayerPaymentSettings.allowUserToSendReturnWithoutPayment = false;
        }
        this.setState({
            companySettings: temp,
            showStripeIntegrationSettingsPopup: popupStatus
        });
        this.enableCustomRouterPrompt();
    };

    handleShowAccountDetails(event: any) {
        let temp = this.props.companyData.companySettings as ICompanySettings;
        let popupStatus: boolean = false;
        if (temp.taxpayerPaymentSettings.companyConnectedAccountId) {
            popupStatus = true;
            this.props.requestAccountDetails(temp.taxpayerPaymentSettings.companyConnectedAccountId);
        }
        this.setState({
            showAccountDetailsPopup: popupStatus
        });
    }
    handleDisconnect(event:any)
    {
        this.props.handleDisconnect()
        this.setState({
            showAccountDetailsPopup:false
       })
    }
    handleVoucherDueDateChange = (event: any) => {
        let temp = this.props.companyData.companySettings as ICompanySettings;
        temp.displaySettingsModel.voucherDueDateType = parseInt(event.value);
        this.setState({
            companySettings: temp
        });
        this.enableCustomRouterPrompt();
    };
   
    ////////////////////// Invoice Settings End ////////////////////////////////////////

    public render() {
        return (
            <div id="divPaymentAndVoucher" className="company-settings font14">
                <CustomRouterPrompt enabled={this.state.showCustomPrompt} history={this.props.history} />
                <SettingsHeader
                    handleApplyButton={this.handleApplyButton}
                    header="Vouchers"
                    showPopOver={false}
                    parentResourceIdentifier={
                        TaxReturnSettingsResources.VoucherAndInvoicesPrefix + TaxReturnSettingsResources.HeaderPrefix
                    }
                />
                <OverlayLoader show={this.props.companyData.isLoading} text={this.props.companyData.message} />
                {this.props.companyData.companySettings != undefined ? (
                    <div>
                        <PaymentVoucherNotification
                            companySettings={this.props.companyData.companySettings as ICompanySettings}
                            handlePaymentVoucherReminder={this.handlePaymentVoucherReminder}
                            handleSettingVoucherReminderDays={this.handleSettingVoucherReminderDays}
                            handleVoucherPaymentNotification={this.handleVoucherPaymentNotification}
                            helptexts={this.props.helptexts.helpTexts}
                        />
                        <hr />

                        <VoucherDueDateSetting
                            companySettings={this.props.companyData.companySettings as ICompanySettings}
                            handleVoucherDueDateChange={this.handleVoucherDueDateChange}
                            helptexts={this.props.helptexts.helpTexts}
                        />
                        <hr />
                        <InvoiceSettings
                            companySettings={this.props.companyData.companySettings as ICompanySettings}
                            handleEnableInvoice={this.handleEnableInvoice}
                            handleAllowUserToReplaceInvoice={this.handleAllowUserToReplaceInvoice}
                            handleAllowUserToSendwithoutInvoice={this.handleAllowUserToSendwithoutInvoice}
                            handleEnablePriorToReview={this.handleEnablePriorToReview}
                            handleAllowUserToSendReturnWithoutPayment={this.handleAllowUserToSendReturnWithoutPayment}
                            handlePaymentUrl={this.handlePaymentUrl}
                            requestPaymentRegisterUrl={this.props.requestPaymentRegisterUrl}
                            showStripeIntegrationSettingsPopup={this.state.showStripeIntegrationSettingsPopup}
                            onHideStripePopup={this.onHideStripePopup}
                            handleShowAccountDetails={this.handleShowAccountDetails}
                            showAccountDetailsPopup={this.state.showAccountDetailsPopup}
                            onHideAccountDetailsPopup={this.onHideAccountDetailsPopup}
                            paymentAccountDetails={this.props.paymentAccountData}
                            helptexts={this.props.helptexts.helpTexts}
                            requestStripeEditURL={this.props.requestStripeEditURL}
                            handleDisconnect={this.handleDisconnect}
                            handlePayOptionChange={this.handlePayOptionChange}
                        />
                        <br />
                    </div>
                ) : (
                    ""
                )}
            </div>
        );
    }
}
