import { IBookmarkSection, IVoucherPageItem, SEPARATOR } from "../ProcessReturnModels"
import {
	ITaxReturn, DocumentGroups,
	IVoucher, VoucherNo, VoucherTypes, ITaxingAuthority, CustomType, VoucherMode, IFormBase, VoucherStatus
} from '../../../common/TaxReturn'
import { VoucherTabConstants } from '../../../helper/Constants';
import * as Helper from '../../../helper/HelperFunctions';

export interface IVoucherSort {
	getSortedBookmarks(): IBookmarkSection[];
	isVouchersEqual(preVouchers: IVoucher[]): boolean;
	getVouchers(): IVoucher[];
}

export class VoucherSort implements IVoucherSort {
	private voucherBookmark: IBookmarkSection;
	private taxReturn: ITaxReturn;
	private authorities: ITaxingAuthority[];
	private voucherNumberSortOrder: VoucherNo[] = [];
	private vouchers: IVoucher[] = [];

	constructor(taxReturn: ITaxReturn, authorities: ITaxingAuthority[], voucherMode?: VoucherMode[]) {
		this.taxReturn = taxReturn;
		this.authorities = authorities;
		this.voucherBookmark = {
			heading: 'Vouchers',
			pages: [],
		}
		this.voucherNumberSortOrder = [VoucherNo.PaymentVoucher, VoucherNo.Q1, VoucherNo.Q2, VoucherNo.Q3, VoucherNo.Q4, VoucherNo.None];
		this.init(voucherMode);
	}

	private init(voucherModes?: VoucherMode[]) {
		
		this.vouchers = [];

		this.authorities = this.authorities.sort((x, y) => {
			if (typeof x == 'undefined' || typeof y == 'undefined')
				return 0
			if (x.AuthorityName < y.AuthorityName) {
				return -1
			} else if (x.AuthorityName > y.AuthorityName) {
				return 1
			} else {
				return 0
			}
		});

		const federalIndex = this.authorities.findIndex(x => (typeof x != 'undefined' && x.AuthorityName == VoucherTabConstants.DatabaseValues.FederalAuthorityName));
		if (federalIndex) {
			this.authorities.unshift(this.authorities.splice(federalIndex, 1)[0]);
		}
		
		const voucherIndex = this.taxReturn.formGroups.findIndex(x => x.group == DocumentGroups.Vouchers);

		if (voucherModes) {
			if (this.taxReturn.formGroups[voucherIndex]) {
				voucherModes.forEach(voucherMode => {
					let vouchers = this.taxReturn.formGroups[voucherIndex].forms.filter((value: IFormBase, index: number) => {
						return ((value as IVoucher).vocherMode === voucherMode)
					});
					vouchers.forEach(voucher => {
						this.vouchers.push(voucher as IVoucher);
					});
				});
			}
			else {
				this.vouchers = [];
			}
		}
		else {

			this.vouchers = this.taxReturn.formGroups[voucherIndex] ?
				[...this.taxReturn.formGroups[voucherIndex].forms as IVoucher[]] : [];
		}
	}

	public getVouchers(): IVoucher[] {
		return this.vouchers;
	}


	public getSortedBookmarks(isRevisionProcess?: boolean): IBookmarkSection[] {
		this.createSortedBookmarks(isRevisionProcess);
		return [this.voucherBookmark];
	}

	private createSortedBookmarks(isRevisionProcess?: boolean) {
		//sorting bookmarks by authority
		let FedralAuth = this.authorities && this.authorities.filter(x => x.AuthorityName.startsWith("Federal"));
		let NonFedralAuth = this.authorities && this.authorities.filter(x => !(x.AuthorityName.startsWith("Federal")));
		let sortedNonFedralAuth = NonFedralAuth && NonFedralAuth.sort((x, y) => {
			if (typeof x == 'undefined' || typeof y == 'undefined')
				return 0
			if (x.AuthorityName.toLowerCase() < y.AuthorityName.toLowerCase()) {
				return -1
			} else if (x.AuthorityName.toLowerCase() > y.AuthorityName.toLowerCase()) {
				return 1
			} else {
				return 0
			}
		});
		let allAuthorities = FedralAuth && FedralAuth.concat(sortedNonFedralAuth);
		allAuthorities && allAuthorities.map((authority, i) => {
			let authorityVouchers = this.vouchers.filter(x => x.authorityID == authority.Id);
			if (authorityVouchers && authorityVouchers.length > 0) {
				authorityVouchers = this.sortVouchers(authorityVouchers);
				this.addToBookmarks(authorityVouchers, isRevisionProcess);
			}
		});

		const authorities = this.authorities ? this.authorities.map(x => x.Id) : [];
		const unknownVouchers = this.vouchers.filter(x => authorities.indexOf(x.authorityID) == -1);
		if (unknownVouchers && unknownVouchers.length) {
			const unknownaAuthorities = Array.from(new Set(unknownVouchers.map((v) => v.authorityID)));
			unknownaAuthorities.map((authority, i) => {
				let authorityVouchers = unknownVouchers.filter(x => x.authorityID == authority);
				if (authorityVouchers && authorityVouchers.length > 0) {
					authorityVouchers = this.sortVouchers(authorityVouchers);
					this.addToBookmarks(authorityVouchers, isRevisionProcess);
				}
			});
		}
	}



	private addToBookmarks(vouchers: IVoucher[], isRevisionProcess?: boolean) {
		vouchers.map((v, i) => {
			v.pageNo.map((page, pageIndex) => {
				let formName;
				if (this.voucherBookmark) {
					if (v.vocherMode === VoucherMode.CustomVoucher) {
						formName = v.customType == CustomType.None ? v.formName : this.getAddedVoucherFormName(v.authorityID, v.formName);
					}
					else
						formName = v.formName;
					let pageTitle = v.amount === 0 ? formName + SEPARATOR + '$' + Helper.formatCurency(v.amount) : formName;

					if (isRevisionProcess) {
						if (v.voucherStatus != VoucherStatus.Existing) {
							this.voucherBookmark.pages.push({
								pageNo: page,
								pageTitle: pageTitle,
								icon: VoucherTypes[v.paymentType],
								displayIconAsImage: true,
								voucherNo: v.voucherNo,
								voucherStatus: v.voucherStatus
							} as IVoucherPageItem);
						}
					}
					else {
						this.voucherBookmark.pages.push({
							pageNo: page,
							pageTitle: pageTitle,
							icon: VoucherTypes[v.paymentType],
							displayIconAsImage: true,
							voucherNo: v.voucherNo,
							voucherStatus: v.voucherStatus
						} as IVoucherPageItem);
					}
				}
			});
		});
	}

	public getAddedVoucherFormName = (authorityID: number, formName: string) => {
		for (let i in this.authorities) {
			if (this.authorities[i].Id === authorityID) {
				return this.authorities[i].AuthorityName + ' ' + formName;
			}
		}
		return formName;
	}

	private sortVouchers(vouchers: IVoucher[]): IVoucher[] {
		let sortedVouchers: IVoucher[] = [];

		let paymentVouchers = this.sortVoucherByBookmark(vouchers.filter(x => x.paymentType == VoucherTypes.PaymentVoucher));
		let otherVouchers = vouchers.filter(x => x.paymentType != VoucherTypes.PaymentVoucher);
		//create a multidimention array of other vouchers and percentage of match on bookmark name for each payment voucher
		let otherVouchersBookmarkMatchData = this.CreateBookmarkMatchData(paymentVouchers, otherVouchers);
		if (paymentVouchers) {
			sortedVouchers = paymentVouchers;
			paymentVouchers.map((voucher, pIndex) => {
				const estimatedPaymentVouchers = this.GetEstimatedPaymentVouchers(otherVouchers, pIndex, otherVouchersBookmarkMatchData);
				if (estimatedPaymentVouchers) {
					sortedVouchers = sortedVouchers.concat(estimatedPaymentVouchers);
				}
			});
		}
		sortedVouchers.map((voucher, index) => {
			const vIndex = otherVouchers.indexOf(voucher);
			if (vIndex != -1) {
				otherVouchers.splice(vIndex, 1);
			}
		});

		//sort remaing Estimated voucher by Voucher no first following by bookmark name
		this.voucherNumberSortOrder.map((vnum) => {
			let voucherGroup = otherVouchers.filter(x => x.voucherNo == vnum);
			if (voucherGroup) {
				sortedVouchers = sortedVouchers.concat(this.sortVoucherByBookmark(voucherGroup));
			}
		});

		return sortedVouchers;
	}

	private CreateBookmarkMatchData(paymentVouchers: IVoucher[], otherVouchers: IVoucher[]): any {

		let matchData: number[][] = [];
		otherVouchers.map((eVoucher, eIndex) => {
			matchData[eIndex] = [];
			paymentVouchers.map((pVoucher, pIndex) => {
				matchData[eIndex][pIndex] = this.matchBookmark(pVoucher.formName, eVoucher.formName);
			});

		});

		return matchData;
	}


	private GetEstimatedPaymentVouchers(otherVouchers: IVoucher[], pIndex: number, otherVouchersBookmarkMatchData: number[][]): IVoucher[] {

		let estimatedPaymentVouchers: IVoucher[] = [];
		otherVouchers.map((voucher, eIndex) => {
			const maxMatchValue = Math.max(...otherVouchersBookmarkMatchData[eIndex]);
			if (maxMatchValue > -1 && otherVouchersBookmarkMatchData[eIndex].findIndex(x => x == maxMatchValue) == pIndex) {
				estimatedPaymentVouchers.push(voucher);
			}
		});

		//sort the identified estimated payment voucher based on voucher num
		let sortedVouchers: IVoucher[] = [];
		this.voucherNumberSortOrder.map((vnum) => {
			let voucherGroup = estimatedPaymentVouchers.filter(x => x.voucherNo == vnum);
			if (voucherGroup) {
				sortedVouchers = sortedVouchers.concat(this.sortVoucherByBookmark(voucherGroup));
			}
		});
		return sortedVouchers;
	}

	private matchBookmark(bookmark1: string, bookmark2: string) {

		bookmark1 = bookmark1.trim();
		bookmark2 = bookmark2.trim();

		const bookmark1Words = bookmark1.split(/[ ]|-/).filter(x => x != "");
		const bookmark2Words = bookmark2.split(/[ ]|-/).filter(x => x != "");
		const minLength = Math.min(bookmark1Words.length, bookmark2Words.length);
		let wordMatch = 0;
		for (let i = 0; i < minLength; i++) {
			if (bookmark1Words[i].toLowerCase() != bookmark2Words[i].toLowerCase()) {
				break;
			}
			wordMatch++;
		}

		if (wordMatch < VoucherTabConstants.VoucherSortBookmarkWordMatchCount) {
			return -1;
		}
		const startCharMatchWordIndex = VoucherTabConstants.VoucherSortBookmarkWordMatchCount;
		let points = 0;
		for (let i = startCharMatchWordIndex; i < minLength; i++) {
			if (bookmark1Words[i].toLowerCase() == bookmark2Words[i].toLowerCase()) {
				points += bookmark1Words[i].length;
			} else {
				for (let j = 0; j < Math.min(bookmark1Words[i].length, bookmark2Words[i].length); j++) {
					if (bookmark1Words[i][j].toLowerCase() != bookmark2Words[i][j]) {
						break;
					}
					points++;
				}
			}
		}

		return points;
	}

	private sortVoucherByBookmark(vouchers: IVoucher[]): IVoucher[] {

		return vouchers.sort((x, y) => {
			let xtitle = x.authorityName + ' ' + x.formName;
			let yTitle = y.authorityName + ' ' + y.formName;
			if (xtitle.toLowerCase() < yTitle.toLowerCase()) {
				return -1
			} else if (xtitle.toLowerCase() > yTitle.toLowerCase()) {
				return 1
			} else {
				return 0
			}
		})
	}

	public isVouchersEqual(preVouchers: IVoucher[]): boolean {
		if (preVouchers) {
			const voucherIndex = this.taxReturn.formGroups.findIndex(x => x.group == DocumentGroups.Vouchers);
			if (!this.taxReturn.formGroups[voucherIndex]) {
				return true;
			}
			const currentVouchers = this.taxReturn.formGroups[voucherIndex].forms as IVoucher[];

			if (currentVouchers.length != preVouchers.length) {
				return false;
			}

			return preVouchers.every((v) => {
				return currentVouchers
					.findIndex(x => x.bookmark === v.bookmark &&
						x.authorityID === v.authorityID &&
						x.pageNo === v.pageNo &&
						x.paymentType === v.paymentType &&
						x.voucherNo === v.voucherNo) !== -1;
			});
		} else {
			return false;
		}
	}
}