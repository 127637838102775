export interface IOnehubAccessCodeModel {
    clientName: string;
    clientEmail: string;
    otpDate: Date;
    accessCode: string;
    accessCodeStatus: OnehubAccessCodeStatus;
}

export enum OnehubAccessCodeStatus {
    Active = 0,
    NotRequested = 1,
    Expired = 2,
    Authenticated = 3
}
