import {  addTask } from 'domain-task';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../';
import { InUseReturnsState, InUseReturnsTableModel } from './InUseReturnsState';
import { IInUseTaxReturns } from '../../components/common/InUseTaxReturns';
import {
    RequestTaxDocumentAction, ReceiveTaxDocumentAction,
    MakeAvailableTaxDocumentAction, DeleteTaxDocumentAction
} from '../common/TaxDocumentStore';
import { actionTypes } from '../../types/ActionTypes';
import {
    RequestInUseReturnsAction, ReceiveInUseReturnsAction,
    ReceiveInUseReturnsPagesAction, RequestInUseReturnsPagesAction
} from "./KnownTypes";
import { API_BASE_URL } from '../../utils/constants';
import { handleBlobResponse, handleResponse } from '../Library';
import { StatusType, NotificationAction } from '../../store/common/NotificationStore';

type KnownAction = RequestInUseReturnsAction | ReceiveInUseReturnsAction
    | ReceiveTaxDocumentAction | MakeAvailableTaxDocumentAction | DeleteTaxDocumentAction;

type AllKnownAction =
    RequestInUseReturnsAction |
    ReceiveInUseReturnsAction |
    ReceiveTaxDocumentAction |
    RequestTaxDocumentAction |
    ReceiveInUseReturnsPagesAction |
    RequestInUseReturnsPagesAction |
    MakeAvailableTaxDocumentAction |
    NotificationAction |
    DeleteTaxDocumentAction;


export const actionCreators = {

    requestInUseReturns: (query: string, reload: boolean = false, callback?: () => void): AppThunkAction<AllKnownAction> => (dispatch, getState) => {
        let state = getState();
        if (reload || query !== state.inUseReturns.query) {

            let page = state.InUseReturnsPages[query];
            if (!reload && page) {
                dispatch({ type: actionTypes.REQUEST_INUSE_RETURNS, query: query });
                dispatch({ type: actionTypes.RECEIVE_INUSE_RETURNS, query: query, table: page.InUseReturnTableModel });
                return;
            }

            const fetchTask = fetch(API_BASE_URL + 'api/Reports/InUseReturns/GetInUseReturns' + query, {
                method: 'GET',
                credentials: 'include'
            })
                .then(response => response.json() as Promise<InUseReturnsTableModel>)
                .then(data => {
                    dispatch({ type: actionTypes.RECEIVE_INUSE_RETURNS, query: query, table: data });
                    dispatch({ type: actionTypes.RECEIVE_INUSE_RETURNS_PAGES, query: query, table: data, totalRowCount: data.count });
                    if (callback) {
                        callback();
                    }
                })
                .catch(function (error) {
                    if (callback) {
                        callback();
                    }
                });
            addTask(fetchTask);
            dispatch({ type: actionTypes.REQUEST_INUSE_RETURNS, query: query });
            dispatch({ type: actionTypes.REQUEST_INUSE_RETURNS_PAGES, query: query });
        }
    },

    exportInUseReturnsAsExcel: (query: string, callback?: () => void, resourceId:string=""): AppThunkAction<AllKnownAction> => (dispatch, getState) => {
		const fetchTask = fetch(API_BASE_URL + 'api/ExportToExcel/ExportExcelInUseReturnsAsync' + query, { credentials: 'include', headers:{'X-Resource-Id':resourceId} })
			.then(handleBlobResponse)
			.then(blob => {
				const url = window.URL.createObjectURL(new Blob([blob]));
				const link = document.createElement('a');
				link.href = url;
				const fileName = 'LockedReturns.xlsx';
				link.setAttribute('download', fileName);
				link.id = "download_link";
				link.target = '_blank';
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
				if (callback) {
					callback();
				}
			})
			.catch((error) => {
				const statusMessage: any = error.statusText?.message ?? error.statusText;
                if (typeof(statusMessage) === "string") {
					dispatch({ type: actionTypes.NOTIFICATION, statusMessage: statusMessage, statusType: StatusType.Error });
				}
				if (callback) {
					callback();
				}
			});
		addTask(fetchTask);
	},

};


const unloadedState: InUseReturnsState = {
    InUseReturnTableModel: {
        inUseReturnModel: [],
        count: 0
    } as InUseReturnsTableModel,
    loading: true,
    query: '',
    totalRowCount: 0
} as InUseReturnsState;

const unloadedInUseTaxDocument: IInUseTaxReturns = {} as IInUseTaxReturns;

export const reducer: Reducer<InUseReturnsState> = (state = unloadedState, incomingAction) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case actionTypes.REQUEST_INUSE_RETURNS:
            return ({
                ...unloadedState,
                query: action.query,
                loading: true
            }) as InUseReturnsState;
        case actionTypes.RECEIVE_INUSE_RETURNS:
            return {
                query: action.query,
                InUseReturnTableModel: action.table,
                totalRowCount: action.table.count,
                loading: false
            } as InUseReturnsState;
        case actionTypes.RECEIVE_TAX_DOCUMENT:
            return updateState(action.type, state, action);
        case actionTypes.MAKE_AVAILABLE_INUSE_TAX_DOCUMENT:            
        case actionTypes.DELETE_TAX_DOCUMENT:
            return clearTaxReturns(state);
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            const exhaustiveCheck: never = action;
    }

    return state;
};

function updateState(type: actionTypes, state: InUseReturnsState, action: KnownAction): InUseReturnsState {
    let i: number = -1;
    let oldDocument: IInUseTaxReturns = unloadedInUseTaxDocument;
    let document: IInUseTaxReturns = {} as IInUseTaxReturns;
    let customColumn: string = "";
    let id: number = 0;
    switch (action.type) {
        case actionTypes.RECEIVE_TAX_DOCUMENT:
            document = action.taxDocument as IInUseTaxReturns;
            id = action.id;
    }
    if (state.InUseReturnTableModel.inUseReturnModel) {
        state.InUseReturnTableModel.inUseReturnModel.forEach((value, index) => {
            if (value.id === id) {
                i = index;
                oldDocument = value;
                return;
            }
        });
    }
    if (i !== -1) {
        let InUseTaxDocument: IInUseTaxReturns = action.type == actionTypes.RECEIVE_TAX_DOCUMENT ? document : oldDocument;

        let documents = [
            ...state.InUseReturnTableModel.inUseReturnModel?.slice(0, i),
            InUseTaxDocument,
            ...state.InUseReturnTableModel.inUseReturnModel?.slice(i + 1)];
        let InUseReturnTableModel: InUseReturnsTableModel = {
            count: state.InUseReturnTableModel.count,
            inUseReturnModel: documents
        }

        return {
            query: state.query,
            InUseReturnTableModel: InUseReturnTableModel,
            totalRowCount: state.totalRowCount,
            loading: false
        } as InUseReturnsState;
    }
    return state;
}

function clearTaxReturns(state: InUseReturnsState): InUseReturnsState {
    return {
        ...unloadedState,
        query: state.query,
        isLoading: true
    } as InUseReturnsState;
}

