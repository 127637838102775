import * as React from 'react';
import { ICompanySettings } from '../../../Core/ViewModels/Company/CompanySettingsViewModel';
import { CheckBoxComponent } from '../../common/CheckBoxComponent';
import { TabEFileConstants } from '../../helper/Constants'

interface IEfileFormSettings {
    companySettings: ICompanySettings
    onToggleEfileDateSettings(event: any): void;
    onToggleEfileNameAndTitleSettings(event: any): void;
}
export class EfileFormSettings extends React.Component<IEfileFormSettings> {
    public render() {
        return <div className="new-main-content">
            <h3>E-File Forms Settings</h3>
            <div className="marL20">
                <div>
                <CheckBoxComponent
                    id={"EfileDateControl"}
                    text={TabEFileConstants.Info.DoNotInsertDates}
                    onChange={this.props.onToggleEfileDateSettings}
                    checked={this.props.companySettings.signatureSettingsModel ?
                        this.props.companySettings.signatureSettingsModel.isDoNotDisplayDatesEnabled : true}
                    datatestAuto={"B80BF7B7-DF1E-4E3E-8A4A-FA940DC0693F"}
                />
                </div>
                <div>
                <CheckBoxComponent
                    id={"EfileNameControl"}
                    text={TabEFileConstants.Info.DoNotInsertNameAndTitle}
                    onChange={this.props.onToggleEfileNameAndTitleSettings}
                    checked={this.props.companySettings.signatureSettingsModel ?
                        this.props.companySettings.signatureSettingsModel.isDoNotDisplayNameAndTitleEnabled : true}
                    datatestAuto={"16EFC359-1FCD-4385-B10F-C9B481F4EA47"}
                />
                </div>
                
            </div>
        </div>;
    }
}

export default EfileFormSettings