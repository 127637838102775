import * as React from 'react';
import TagsInput from 'react-tagsinput';

interface ITagProps {
	listOfTagsInput: string[];
	onChangeTagValue(event: any, firmIds: string[]): void;
	placeHolder?: string;
	onValidate(key: string[]): void;
	maxTags?: number;
}

export class Tag extends React.Component<ITagProps, {}> {
	public render() {
		return (
			<div>
				<TagsInput addOnBlur={true} inputProps={{
					className: 'react-tagsinput-input',
					placeholder: this.props.placeHolder != undefined ? this.props.placeHolder : ""
				}} value={this.props.listOfTagsInput} onChange={this.props.onChangeTagValue.bind(this, this.props.listOfTagsInput)}
					validationRegex={/^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/}
					onValidationReject={this.props.onValidate} maxTags={this.props.maxTags} />
			</div>
		)
	}
};