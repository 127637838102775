import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import * as BusinesReturnsStore from '../../store/settings/BusinessReturnsStore';
import { BusinessReturns } from '../../components/settings/BusinessReturns';
import * as CompanyStore from '../../store/company/CompanyStore';
import * as WelcomeMessageStore from '../../store/settings/WelcomeMessageStore';
import * as EmailMessageStore from '../../store/settings/EmailMessageStore';
import * as UserSettingsStore from '../../store/userManagement/UserSettingStore';
import * as ReminderStore from '../../store/common/ReminderStore';
import * as SSRSettingsStore from '../../store/company/settings/SSRSettingsStore'
import * as ApplicationHelptextStore from '../../store/common/ApplicationHelptextStore';

export default connect(
    (state: ApplicationState) => ({
        businessReturnInstructions: state.businessReturnInstructions,
        welcomeMessageList: state.welcomeMessage,
        emailMessageList: state.emailMessage,
        companyData: state.companyData,
        helptexts: state.Helptexts,
    }),
    {
        ...BusinesReturnsStore.actionCreators,
        ...WelcomeMessageStore.ActionCreators,
        ...EmailMessageStore.ActionCreators,
        ...CompanyStore.actionCreators,
        ...UserSettingsStore.actionCreators,
        ...ReminderStore.actionCreators,
        ...SSRSettingsStore.actionCreators,
        ...ApplicationHelptextStore.actionCreators
    }
)(BusinessReturns as any);