import * as React from 'react';
import * as BootStrap from 'react-bootstrap';
import { Nav } from 'react-bootstrap';
import { PriceTag } from '../../common/PriceTag';
import { BusinessReturnInstruction } from '../../../Core/ViewModels/Common/BusinessReturnInstructionViewModel';
import { BusinessReturnConstants } from '../../helper/Constants';
import { EngagementType } from '../../common/TaxReturn';
import { getFileExtension } from '../../helper/Validations';
import { TaxReturnSettingsResources } from '../../../components/helper/ResourceIdConstants';

interface Props {
    name: string;
    businessReturnInstruction: BusinessReturnInstruction;
    downloadInstruction: (type: string, taxYear: number, resourceId?: string) => void;
    showFilePreview: (type: string, taxYear: number) => void;    
    replaceInstruction: any
    parentResourceIdentifier: string;
}

interface State {
    businessReturnInstruction: BusinessReturnInstruction
}


export class BusinessReturnInstructions extends React.Component<Props, State>{
    constructor(props: Props) {
        super(props);
        this.state = {
            businessReturnInstruction: this.props.businessReturnInstruction
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps: Props) {
        this.setState({ businessReturnInstruction: nextProps.businessReturnInstruction });
    }

    private handleReplaceDocument = (file: FileList | null) => {
        if (file) {
            this.props.replaceInstruction(file, this.state.businessReturnInstruction, this.props.parentResourceIdentifier + TaxReturnSettingsResources.ButtonReplace);
        }
    }

    private getTagColor = (): string => {
        let color: string = '';
        switch (this.props.businessReturnInstruction.engagementType) {
            case EngagementType.E1065:
                color = "#147089";
                break;
            case EngagementType.E1120S:
                color = "#E86650";
                break;
        }
        return color;
    }

    public render() {
        const engagementType: string = BusinessReturnConstants
            .getEngagementTypeDisplayName(this.state.businessReturnInstruction.engagementType);
        let fileName = this.state.businessReturnInstruction.fileName;
        let fileExtension = getFileExtension(fileName);
        fileName = fileName.replace("." + fileExtension, '');
        fileName = fileName + "." + fileExtension.toLowerCase();
        return <div className="new-main-content">
            
            <div className="row marB15">
                <div className="col-sm-6">
                    <div className="dispFlex padT05">
                        <PriceTag name={engagementType} direction="right" color={this.getTagColor()} />
                        <Nav.Link href="javascript:;" className="hypControl" style={{ color: '#007bff !important' }}
                            onClick={() => this.props.showFilePreview(
                                EngagementType[this.state.businessReturnInstruction.engagementType].toString(),
                                this.state.businessReturnInstruction.taxYear)} >
                            {fileName}
                        </Nav.Link>
                    </div>
                </div>
                <div className="col-sm-4">
                    <button type="button" className="btn btn-icon-default btn-file btn-download-bri"
                        data-test-auto="AE822E79-1E27-4EF4-8C9F-ADFC1F842576"
                        data-resource-id= { this.props.parentResourceIdentifier + TaxReturnSettingsResources.ButtonDownload }
                        onClick={(event) => {
                            this.props.downloadInstruction(EngagementType[this.state.businessReturnInstruction.engagementType]
                                .toString(),
                                this.state.businessReturnInstruction.taxYear,
                                this.props.parentResourceIdentifier + TaxReturnSettingsResources.ButtonDownload);
                        }}>
                        <span className="fa fa-download" style={{ color: "#333" }}></span>
                    </button>
                    <a className='btn btn-default' href="javascript:;">
                        Replace
                        <input type="file"
                            accept="application/pdf"
                            data-resource-id= { this.props.parentResourceIdentifier + TaxReturnSettingsResources.ButtonReplace }
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                this.handleReplaceDocument(e.target.files)
                            }}
                            className='btnuploadscancopyw100'
                            data-test-auto="AE822E79-1E27-4EF4-8C9F-ADFC1F842576" />
                        <i className="fa-upload fas"></i>
                    </a>
                </div>
            </div>
        </div >
    }
}

export default BusinessReturnInstructions;