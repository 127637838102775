import React, { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { ImportCCHAxcess } from "../../helper/Constants";
import { LoadingOverlay } from "react-overlay-loader";
import Select, { Option, Options} from "react-select";
import { Guid } from "guid-typescript";
import { AddIcon, RemoveIcon } from "./Icons";
import * as Helper from "../../helper/HelperFunctions";
import { PullReturnsFilter } from "@store/reports/CCHReturns/CCHReturnsState";

interface FiltersModalProps {
    show: boolean;
    onApply(filter: PullReturnsFilter): void;
    onCancel(): void;
}

type FilterRow = {
    filterName: string;
    filterValue: string;
    id: Guid;
    filterOptions: any[];
};

type OptionType = {
    label: string;
    value: string;
};

export const FiltersModal: React.FC<FiltersModalProps> = ({ show, onApply, onCancel }) => {
    const filterConstants = ImportCCHAxcess.Filters;
    const tableConstants = ImportCCHAxcess.Table;

    const [rows, setRows] = useState<FilterRow[]>([]);
    const [showAddFilter, setShowAddFilter] = useState(true);
    const [taxYear, settaxYear] = useState(filterConstants.All);
    const [isAppyBtnEnabled, setIsApplyBtnEnabled] = useState(true);

    useEffect(() => {
        setShowAddFilter(rows.length < 4);
        setIsApplyBtnEnabled(rows.filter((r) => r.filterName === "" || r.filterValue === "").length === 0);
    }, [rows]);

    const onApplyBtnClick = () => {
        let filter: PullReturnsFilter = {
            clientId: rows.find((r) => r.filterName === tableConstants.clientId)?.filterValue || null,
            returnStatus: rows.find((r) => r.filterName === tableConstants.returnStatus)?.filterValue || null,
            taxYear:
                taxYear === filterConstants.All
                    ? [new Date().getFullYear() - 1, new Date().getFullYear() - 2]
                    : [parseInt(taxYear)],
            returnType: rows.find((r) => r.filterName === tableConstants.returnType)?.filterValue || null,
            version: rows.find((r) => r.filterName === tableConstants.version)?.filterValue || null
        };
        console.log(filter);
        onApply(filter);
    };

    const onClearAllBtnClick = () => {
        setRows([]);
        settaxYear(filterConstants.All);
    };

    const onCancelBtnClick = () => {
        onCancel();
    };

    const addNewFilter = () => {
        const newRow: FilterRow = {
            filterName: "",
            filterValue: "",
            id: Guid.create(),
            filterOptions: additionalFilterOptions.filter(
                (option) => rows.filter((r) => r.filterName === option.value).length === 0
            )
        };
        setRows([...rows, newRow]);
    };

    const deleteFilter = (event: React.MouseEvent<HTMLDivElement>, row: FilterRow) => {
        const filteredRows = rows.filter((r) => r.id !== row.id);
        setRows(filteredRows);
    };

    const handleChange = (selectedOption : OptionType) => {        
            settaxYear(selectedOption.value);
    };

    const onAdditionalFilterOptChnage = (event: OptionType, row: FilterRow) => {
        
        setRows((currentRows) => {
            const rowIndex = currentRows.findIndex((r) => r.id === row.id);
            if (rowIndex === -1) return currentRows;

            const updatedRows = [...currentRows];
            updatedRows[rowIndex] = {
                ...updatedRows[rowIndex],
                filterName: event.value
            };

            updatedRows.forEach((row, i) => {
                if (row.filterName !== "") {
                    row.filterOptions = additionalFilterOptions.filter((option) => option.value === row.filterName);
                } else {
                    row.filterOptions = additionalFilterOptions.filter(
                        (option) => !updatedRows.some((r) => r.filterName === option.value)
                    );
                }
            });
            return updatedRows;
        });
    };

    const onAdditionalFilterValChnage = (event: OptionType, row: FilterRow) => {
        let filtererdRow = rows.filter((r) => r.id === row.id);
        filtererdRow[0].filterValue = event.value;
        setRows([...rows]);
    };

    const onInputChange = (event: React.ChangeEvent<HTMLInputElement>, row: FilterRow) => {
        let filtererdRow = rows.filter((r) => r.id === row.id);
        filtererdRow[0].filterValue = event.target.value;
        setRows([...rows]);
    };

    const taxYearOptions = Helper.PrepareTaxYear(new Date().getFullYear() - 2);
    taxYearOptions.unshift({ value: filterConstants.All, label: filterConstants.All });

    const versionOptions = Array.from({ length: 9 }, (_, i) => ({ value: `${i + 1}`, label: `${i + 1}` }));
    const returnTypeOptions = [
        { value: "I", label: "Individual" },
        { value: "P", label: "Partnership" },
        { value: "C", label: "Corporation" },
        { value: "S", label: "S Corporation" },
        { value: "F", label: "Fiduciary" },
        { value: "Y", label: "Estate & Gift" },
        { value: "K", label: "Employee Benefit Plan" },
        { value: "X", label: "Exempt Organization" }
    ];

    const additionalFilterOptions = [
        { label: tableConstants.clientId, value: tableConstants.clientId },
        { label: tableConstants.returnType, value: tableConstants.returnType },
        { label: tableConstants.returnStatus, value: tableConstants.returnStatus },
        { label: tableConstants.version, value: tableConstants.version }
    ];

    return (
        <>
            <Modal className="filters-modal" show={show} onHide={onCancelBtnClick}>
                <Modal.Header closeButton>
                    <Modal.Title>{filterConstants.Title}</Modal.Title>
                </Modal.Header>
                <LoadingOverlay>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-xl-12">
                                <div className="row">
                                    <p> {filterConstants.Description}</p>
                                </div>
                            </div>
                        </div>
                        <div className="row filter-row">
                            <div className="filter-operator">{filterConstants.When}</div>
                            <div>
                                <Select
                                    disabled={true}
                                    id="ddlTaxYear"
                                    name="ddlTaxYear"
                                    value={tableConstants.taxYear}
                                    clearable={false}
                                    options={[{ label: tableConstants.taxYear, value: tableConstants.taxYear }]}
                                ></Select>
                            </div>
                            <div className="spacer"></div>
                            <div>
                                <Select
                                    id="ddlTaxYearValues"
                                    name="ddlTaxYearValues"
                                    clearable={false}
                                    value={taxYear}
                                    onChange={handleChange}
                                    options={taxYearOptions}
                                />
                            </div>
                        </div>
                        {rows.length > 0 &&
                            rows.map((row, index) => (
                                <div className="row filter-row">
                                    <div className="filter-operator">{filterConstants.And}</div>
                                    <div>
                                        <Select
                                            disabled={row.filterName !== ""}
                                            id={`ddlOption_${index}`}
                                            name={`ddlOption_${index}`}
                                            value={row.filterName}
                                            clearable={false}
                                            options={row.filterOptions}
                                            onChange={(event: OptionType) => onAdditionalFilterOptChnage(event, row)}
                                            placeholder={filterConstants.SelectFilter}
                                        ></Select>
                                    </div>
                                    <div className="spacer"></div>
                                    <div>
                                        {row.filterName === tableConstants.version ? (
                                            <Select
                                                disabled={row.filterName === ""}
                                                id={`ddlValue_${index}`}
                                                name={`ddlValue_${index}`}
                                                value={row.filterValue}
                                                options={versionOptions}
                                                clearable={false}
                                                onChange={(event: OptionType) => onAdditionalFilterValChnage(event, row)}
                                                placeholder={filterConstants.SelectVersion}
                                            />
                                        ) : row.filterName === tableConstants.returnType ? (
                                            <Select
                                                disabled={row.filterName === ""}
                                                id={`ddlValue_${index}`}
                                                name={`ddlValue_${index}`}
                                                value={row.filterValue}
                                                options={returnTypeOptions}
                                                clearable={false}
                                                onChange={(event:OptionType) => onAdditionalFilterValChnage(event, row)}
                                                placeholder={filterConstants.SelectReturnType}
                                            />
                                        ) : (
                                            <input
                                                maxLength={500}
                                                disabled={row.filterName === ""}
                                                className="filter-input form-control"
                                                type="text"
                                                id={`txtValue_${index}`}
                                                name={`txtValue_${index}`}
                                                value={row.filterValue}
                                                onChange={(event) => onInputChange(event, row)}
                                                placeholder={
                                                    row.filterName === ""
                                                        ? ""
                                                        : row.filterName === tableConstants.clientId
                                                        ? filterConstants.EnterClientId
                                                        : filterConstants.EnterReturnStatus
                                                }
                                            />
                                        )}
                                    </div>
                                    <div className="spacer"></div>
                                    <div
                                        className="icon-remove"
                                        onClick={(event) => deleteFilter(event, row)}
                                        title={filterConstants.Clear}
                                    >
                                        <RemoveIcon></RemoveIcon>
                                    </div>
                                </div>
                            ))}
                        {showAddFilter && (
                            <div className="row filter-row">
                                <div className="filter-operator"></div>
                                <div>
                                    <button className="btnAdd" onClick={addNewFilter}>
                                        <AddIcon></AddIcon>
                                        {filterConstants.AddNewFilter}
                                    </button>
                                </div>
                            </div>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            title={filterConstants.ClearAllTooltip}
                            variant="default"
                            className="btn btn-only btn-white btn-default"
                            onClick={onClearAllBtnClick}
                            data-test-auto="D4D4E151-755C-4992-AE6C-6A0662BBD463"
                        >
                            {filterConstants.ClearAll}
                        </Button>
                        <Button
                            disabled={!isAppyBtnEnabled}
                            title={isAppyBtnEnabled ? filterConstants.Apply : filterConstants.ApplyDisableTooltip}
                            className={`btn btn-only btn-info`}
                            variant={isAppyBtnEnabled ? "primary" : "default"}
                            onClick={onApplyBtnClick}
                            data-test-auto="BA504366-A1F5-4FCB-80E4-15BD5E39AA25"
                        >
                            {filterConstants.Apply}
                        </Button>
                    </Modal.Footer>
                </LoadingOverlay>
            </Modal>
        </>
    );
};
