import {
	IFormBase, ITaxReturn, DocumentGroups,
	IVoucher, VoucherTypes, IRefund, IEFile,
	IGroup, ISignatureControl, SignatureControlRole, ITaxingAuthority,
	SignatureGroupType, SignatureType, VoucherNo, EntityType, ITaxReturnGroup, ITransmittal, ConsentStatus
} from '../../common/TaxReturn';
import { IAuthorityDictionary, ICompanyData } from '../../../store/company/CompanyStore';
import * as PdfStore from '../../../store/pdf/PdfStore';
import { SignatureFormSelectionType, IconColor } from '../../../Core/ViewModels/Company/CompanySettingsViewModel';
import * as Helper from '../../helper/HelperFunctions';
import { IUserProfile } from '../../navigation/profile/ProfileObjects';
import { UserSettings } from '../../../store/userManagement/UserSettingStore';
import { IUserGroup } from '../../../Core/ViewModels/User/UserViewModel';
import { IUserSignatures } from '../../../store/common/UserSignatureStore';
export const NO_INDEX = -1;

export interface IFormGroupViewProps {	
	pages: number[];	
}

export interface IFormGroupViewState {
	currentPage: number;
	actionPanelShow: boolean;
	zoomEnabled: boolean;
}

export function toPageItem(this: IFormBase) {
	return {
		pageNo: this.pageNo[0],
		pageTitle: this.formName,
		icon: ''
	} as IPageItem;
}

export interface IPageItem {
	pageNo: number;
	pageTitle: string;
	icon: string;
	color?: string;
	className?: string;
	id: number;
	isDeleteEnabled: boolean;
	displayIconAsImage: boolean;
	isClientInfo?: boolean;
}

export interface IVoucherPageItem extends IPageItem {
	voucherNo: VoucherNo;
}

export interface IBookmarkSection {
	heading: string;
	pages: IPageItem[];
}

export enum GroupProcessReturnTabs {
    ControllerInfo = 1,   
    DeliveryOptions,
    Finish
}

export interface IShareHolder {
    k1StatusId: number,
    name: string;
    ssn: string;
    id: number;
    emailAddress: string;
	k1SendDate: string;
	consentStatus: ConsentStatus;
    k1DownloadDate: string;
    partnerAddress: string;
    entityType: EntityType;
    financialYearEnd: Date | undefined;
    countryCode: string;
    mobileNumber: string;
}


export const ICONS = {
	clientInfo: 'fa fa-id-card-alt',
	refund: 'fas fa-hand-holding-usd',
	efile: 'fas fa-pen-nib',
	transmittal: 'fas fa-list-alt',
	taxreturn: 'fas fa-copy',
	k1: 'fas fa-id-card',
	invoice: 'file-invoice-dollar'
}

export const ClientInfoIcons = {
	clientInfoPayments: 'client-info-payments',
	clientInfoRefunds: 'client-info-refunds'
}

export interface ISigner {
	role: SignatureControlRole,
	id: number,
}

export interface IEROSigner {
	id: number,
	signPath: string,
}

export interface PdfPageSignatureControls {
	signatureControls: ISignatureControl[],
	signer: ISigner,
	eroSigner: IEROSigner,
	focusedGroup: SignatureGroupType
}

export const processReturnActionEndPoints = {
	sendForReviewAsync: 'SendForReviewAsync',
	approveForDeliveryAsync: 'ApproveForDeliveryAsync',
	sendToEROAsync: 'SendToEROAsync',
	returnToProcessorAsync: 'ReturnToProcessorAsync',
	deliverAsync: 'DeliverAsync',
	generateTaxpayerViewAsync: 'GenerateTaxpayerViewAsync'
}

export const SEPARATOR = "###";
export const INVALID_PAGE = -1;

export class ControllerInfoView {	

	private pageTitle = (authority: string, form: any) => {
		return authority + SEPARATOR + '$' + Helper.formatCurency(form.amount);
	}
}

export interface ITaxReturnBookMarksView {
	bookmarks: string[];
	pageNo: number[];
}



