import { DeliveryMode } from "../../../components/common/TaxReturn";
import { PaymentFlowType } from "../Company/CompanySettingsViewModel";
import { IBaseTaxLineInfo,ISaleTaxItem } from "../Tax/TaxModels";


export enum PaymentType {
    None = 0,
    Card = 1
}
export enum PaymentGateway {
    None = 0,
    Stripe = 1
}
export enum Origin {
    None = 0,
    CPA = 1,
    Taxpayer = 2,
    ShareHolder = 3
}
export enum PaymentState {
    Initialized = 0,
    Success = 1,
    Incomplete,
    Failed,
    Other
}
export enum PurchaseMode {
    None = 0,
    Payment = 1,
    Adjustment = 4
}

export enum ApplicationType {
    None,
    CPA = 1,
    TaxPayer,
    RecognizerService,
    DeliveryService
}

export interface IPurchaseDetails {
    ssrPurchaseDetails: ISSRPurchaseDetails;
    signaturePurchaseDetails: ISignaturePurchaseDetails;
    ssoPurchaseDetails: ISSOPurchaseDetails;
    applicationFees: number;
    ssePurchaseDetails: ISSEPurchaseDetails;
}
export interface IPaymentReceiptInfo {
    totalLineAmount: number 
    totalAmount: number
    taxAmount: number
    lineItems: IPaymentReceiptLine[]
    invoiceNumber: number  
}
export interface IPaymentReceiptLine {
    name: string;
    quntity: number;
    costPerItem: number;
    total: number;
    docType: string;
}

export interface ISSRPurchaseDetails {
    pricePerReturn: number,
    deliveryMode: DeliveryMode,
    currentBalance: number
}
export interface ISignaturePurchaseDetails {
    pricePerDocument: number,
    currentBalance: number
}
export interface ISSOPurchaseDetails {
    pricePerDocument: number,
    currentBalance: number
}
export interface ISSEPurchaseDetails extends ISSRPurchaseDetails { }

export const initialPurchaseDetails: IPurchaseDetails =
{
    ssrPurchaseDetails: {
        deliveryMode: DeliveryMode.ElectronicFiled,
        pricePerReturn: 0,
        currentBalance: 0
    },

    signaturePurchaseDetails: {
        pricePerDocument: 0,
        currentBalance: 0
    },

    ssoPurchaseDetails: {
        pricePerDocument: 0,
        currentBalance: 0
    },

    applicationFees: 0,

    ssePurchaseDetails: {
        currentBalance: 0,
        deliveryMode: DeliveryMode.ElectronicFiled,
        pricePerReturn: 0,
    },
};

export interface IPaymentViewModel {
    //items
    ssrPurchase: ISSRPurchase|undefined,
    signaturePurchase: ISignaturePurchase | undefined,
    ssoPurchase: ISSOPurchase | undefined,
    purchaseMode: number,
    checkNumber: string,
    checkDate?: Date,
    emailReceiptTo: string,
    createdBy: string,
    createdOn?: Date,

    paymentSuccessPath: string,
    paymentCancelPath: string,
    appllicationFees:number,
    amountTotal: number,
    amountTotalWithFee: number,

    transactionId: string,
    paymentSessionId: string,
    paymentGatewayType: PaymentGateway,
    originApp: ApplicationType,
    paymentStatus: PaymentState,
    message: string,
    details: string,
    receiptPath: string,
    paymentOption: PaymentType,
    paymentFlow: PaymentFlowType,
    taxItems?: ISaleTaxItem[];
}

export interface ProductPurchase {
    numberOfDocuments: number,
    pricePerDocument: number,
    remarks: string,
    amount: number
}

export interface ISSRPurchase extends ProductPurchase  {
    //adjustmentNumberOfReturns: number,
    //adjustmentRemarks: string,
    //adjustmentDate?: Date,
    remainingReturns: number,
    deliveryMode: DeliveryMode,
}

export interface ISignaturePurchase extends ProductPurchase {
    //adjustmentNumberOfDocuments: number,
    //adjustmentRemarks: string,
    //adjustmentDate?: Date,
    remainingDocuments: number,
}

export interface ISSOPurchase extends ProductPurchase {
    remainingReturns: number
}

export const intialPurchaseViewModel: IPaymentViewModel =
{
    ssrPurchase:  {
        numberOfDocuments: 0,
        pricePerDocument: 0,
        amount: 0,
        remarks: "",
        remainingReturns: 0,
        //adjustmentNumberOfReturns: 0,
        //adjustmentRemarks: "",
        deliveryMode: DeliveryMode.ElectronicFiled,
    },
    signaturePurchase: {
        numberOfDocuments: 0,
        pricePerDocument: 0,
        remarks: "",
        //adjustmentNumberOfDocuments: 0,
        //adjustmentRemarks: "",
        remainingDocuments: 0,
        amount: 0
    },
    ssoPurchase: {
        amount: 0,
        numberOfDocuments: 0,
        pricePerDocument: 0,
        remainingReturns: 0,
        remarks: ""
    },
    purchaseMode: 1,
    checkNumber: "",
    emailReceiptTo: "",
    createdBy: "",

    paymentSuccessPath: "",
    paymentCancelPath: "",
    appllicationFees:0,
    amountTotal: 0,
    amountTotalWithFee: 0,

    transactionId: "",
    paymentSessionId: "",
    paymentGatewayType: PaymentGateway.Stripe,
    originApp: ApplicationType.CPA,
    paymentStatus: PaymentState.Initialized,
    message: "",
    details: "",
    receiptPath: "",
    paymentOption: PaymentType.Card,
    paymentFlow: PaymentFlowType.PayNow,
    taxItems: [],
    checkDate: new Date(),
    createdOn: new Date()
}

