let moment = require('moment');
import * as React from 'react';
import * as Bootstrap from 'react-bootstrap';
import { BootstrapTable, CustomFilter, SelectFilter, TableHeaderColumn, TextFilter } from 'react-bootstrap-table';
import "react-bootstrap-table/css/react-bootstrap-table.css";
import * as ReactDom from 'react-dom';
import { Loader, LoadingOverlay } from 'react-overlay-loader';
import * as VoucherReminderManagementState from '../../../store/reports/VoucherReminderManagement/VoucherReminderManagementState';
import { CustomMultiSelect } from '../../common/MultipleSelectComponent';
import { VoucherReminderManagementConstants } from '../../helper/Constants';
import * as Helper from '../../helper/HelperFunctions';
import { CustomDateFilter } from '../DeliveredReturns/CustomDateFilter';
import { CommonResources, ReminderManagementResource } from '../../../components/helper/ResourceIdConstants';
import { IOfficeLocation } from '../../common/TaxReturn';

interface TableData {
    voucherReminderReport: VoucherReminderManagementState.VoucherReminderReportState,
    onPageChange: any,
    onSortChange: any,
    onFilterChange: any,
    pageNo: number,
    loadGrid(): void;
    totalRows: number;
    isLoading: boolean,
    pageSize: number,
    onExportToExcel(onExportToExcelComplete: () => void): void;
    verifySelectedDates(): boolean;
    parentResourceIdentifier: string;
}



const ReminderStatusOptionList = {
    0: "All",
    1: "Scheduled",
    2: "Delivered",
    3: "Processed",
    4: "ScheduleFailed",
    5: "Failed"
}


export interface IVoucherReminderStatustate {
    exportingState: boolean,
    officeLocationList: any[];
}

export class VoucherReminderReportTable extends React.Component<TableData, IVoucherReminderStatustate> {

    private filterChanged: boolean = false;
    private customMultiSelect: any;
    public isAppiedFilter: boolean = false;
    private taxYearList: any[] = [];
    private customOfficeSelect: any;
    private customTaxYearMultiSelect: any;

    constructor(props: TableData) {
        super(props);
        this.state = {
            exportingState: false,
            officeLocationList: []
        };
        this.onFilterChange = this.onFilterChange.bind(this);
        this.CustomDateFilter = this.CustomDateFilter.bind(this);
        this.defaultType = this.defaultType.bind(this);
        this.onExportToComplete = this.onExportToComplete.bind(this);
    }

    UNSAFE_componentWillMount() {
        this.taxYearList = Helper.PrepareTaxYear();
        Helper.fetchUserOfficeLocation(this.setOfficeLocation);
    }

    private onExportToComplete = () => {
        this.setState({ exportingState: false });
    }

    private onClickExportToExcel = () => {
        const verifyDates: boolean = this.props.verifySelectedDates();
        if (verifyDates) {
            this.setState({ exportingState: true }, () => this.props.onExportToExcel(this.onExportToComplete));
        }
    }

    private getYearMultiSelectDropDown = (filterHandler: any, customFilterParameters: any) => {
        const options = customFilterParameters.options;
        const enableAllFilter = customFilterParameters.enableAllFilter;
        const placeholder = customFilterParameters.placeholder;
        return (
            <CustomMultiSelect onRef={(ref: any) => (this.customTaxYearMultiSelect = ref)} filterHandler={filterHandler} options={options} enableAllFilter={enableAllFilter} placeholder={placeholder} />
        );
    }

    private getOfficeMultiSelectDropDown = (filterHandler: any, customFilterParameters: any) => {
        const options = customFilterParameters.options;
        const placeholder = customFilterParameters.placeholder;
        return (
            <CustomMultiSelect onRef={(ref: any) => (this.customOfficeSelect = ref)} 
                filterHandler={filterHandler} 
                options={options}  
                placeholder={placeholder} 
                handleTextOverflow ={true}
            />
        );
    }

    renderShowsTotal(start: number, to: number, total: number) {
        return (
            <p>
                Showing {start} to {to} of {total} entries
            </p>
        );
    }

    private setNoContent() {
        if (this.props.isLoading) {
            (this.refs.table as BootstrapTable) && (this.refs.table as BootstrapTable).cleanSelected();
            return (<LoadingOverlay style={{ height: '400px' }}>
                <Loader loading={this.props.isLoading} />
            </LoadingOverlay>)
        } else {
            return VoucherReminderManagementConstants.OtherMessage.NoDataFound
        }
    }

    private onFilterChange(dataField: any) {
        if (!this.filterChanged) {
            this.filterChanged = true;
            this.props.onFilterChange(dataField);
            this.filterChanged = false;
        }
    }

    private CustomDateFilter = (filterHandler: any, customFilterParameters: any) => {
        return (<CustomDateFilter onRef={(ref: any) => (this.customMultiSelect = ref)} filterHandler={filterHandler} customFilterParameters={customFilterParameters} calendarContainer={ReactDom.findDOMNode(this.refs.table)} />);
    }

    private defaultType(cell: any, row: any) {
        return <div title={cell} className="ellipsis">{cell}</div>;
    }

    private getReminderStatusfromEmailStatusRowFormatter = (cell: any, row: any): string => {

        switch (row.reminderStatus) {
            case 0:
                return "Scheduled";
            case 1:
            case 2:
            case 3:
                return "Delivered";
            case 4:
                return "Processed";
            case 5:
            case 6:
            case 7:
            case 8:
                return "Failed";
            case 9:
                return "ScheduleFailed";
            default:
                return '';

        }
    }

    private setOfficeLocation = (officeLocationList: IOfficeLocation[]) => {
        var list;
        if (officeLocationList?.length) {
            list = officeLocationList.map(({ locationName: label, locationId: value }) => ({ label, value }));
        }
        else {
            list = [];
        }
        this.setState({ officeLocationList: list });
    }

    public render() {

        const options = {
            onSortChange: this.props.onSortChange,
            onPageChange: this.props.onPageChange,
            sizePerPage: this.props.pageSize,
            page: this.props.pageNo,
            paginationShowsTotal: this.renderShowsTotal,
            onRowDoubleClick: function (row: any) {
            },
            onFilterChange: this.onFilterChange,
            noDataText: this.setNoContent(),
            nextPage: <span className="fa fa-angle-right" />,
            prePage: <span className="fa fa-angle-left" />,
            firstPage: <span className="fa fa-angle-double-left" />,
            lastPage: <span className="fa fa-angle-double-right" />
        };

        const columns = [
            {
                header: 'Reminder Guid',
                key: 'reminderGuid',
                isKey: true,
                dataFormat: this.defaultType,
                columnClassName: '',
                dataSort: true,
                toolTip: false,
                isHidden: true,
                width: 'auto',
                filter: { type: 'TextFilter', placeholder: 'index', style: { font: 'bold' }, delay: 2500} as TextFilter
            },
            {
                header: 'Client ID',
                key: 'clientId',
                isKey: false,
                dataFormat: this.defaultType,
                columnClassName: '',
                dataSort: true,
                toolTip: false,
                isHidden: false,
                width: 'auto',
                filter: { type: 'TextFilter', placeholder: 'Client ID', style: { font: 'bold' }, delay: 2500 } as TextFilter
            },
            {
                header: 'Client Name',
                key: 'clientName',
                isKey: false,
                dataFormat: this.defaultType,
                columnClassName: '',
                dataSort: true,
                toolTip: false,
                isHidden: false,
                width: 'auto',
                filter: { type: 'TextFilter', placeholder: 'Client Name', style: { font: 'bold' }, delay: 2500 } as TextFilter
            },
            {
                header: 'Authority Name',
                key: 'reminderData',
                isKey: false,
                dataFormat: this.defaultType,
                columnClassName: '',
                dataSort: true,
                toolTip: false,
                isHidden: false,
                width: '175px',
                filter: { type: 'TextFilter', placeholder: 'Authority Name', style: { font: 'bold' }, delay: 2500 } as TextFilter
            },
            {
                header: 'Tax Year',
                key: 'taxYear',
                isKey: false,
                dataFormat: this.defaultType,
                columnClassName: '',
                dataSort: true,
                toolTip: false,
                isHidden: false,
                width:'100px',
                filter: {
                    type: 'CustomFilter', getElement: this.getYearMultiSelectDropDown,
                    customFilterParameters: { options: this.taxYearList, enableAllFilter: true, placeholder: 'Select Year...' } as any
                } as CustomFilter
            },
            {
                header: 'Office Location',
                key: 'locationName',
                isKey: false,
                dataFormat: this.defaultType,
                columnClassName: '',
                dataSort: true,
                toolTip: true,
                isHidden: false,
                width: '130px',
                filter: {
                    type: 'CustomFilter', getElement: this.getOfficeMultiSelectDropDown,
                    customFilterParameters: { options: this.state.officeLocationList, enableAllFilter: false, placeholder: 'Select Office Location...' } as any
                } as CustomFilter
            },
            {
                header: 'Due Date',
                key: 'dueDate',
                isKey: false,
                dataFormat: this.defaultType,
                columnClassName: '',
                dataSort: true,
                toolTip: false,
                isHidden: false,
                width: 'auto',
                filter: {
                    type: 'CustomFilter',
                    getElement: this.CustomDateFilter

                } as CustomFilter
            },
            {
                header: 'Reminder Date',
                key: 'reminderDate',
                isKey: false,
                dataFormat: this.defaultType,
                columnClassName: '',
                dataSort: true,
                toolTip: false,
                isHidden: false,
                width: 'auto',
                filter: {
                    type: 'CustomFilter',
                    getElement: this.CustomDateFilter

                } as CustomFilter
            },
            {
                header: 'Reminder Status',
                key: 'reminderStatus',
                isKey: false,
                dataSort: true,
                width: 'auto',
                dataFormat: this.getReminderStatusfromEmailStatusRowFormatter,
                toolTip: this.getReminderStatusfromEmailStatusRowFormatter,
                columnClassName: '',
                isHidden: false,
                filter: {
                    type: 'SelectFilter', placeholder: 'All', options: ReminderStatusOptionList, withoutEmptyOption: true
                } as SelectFilter
            },
            {
                header: 'Status Updated Date',
                key: 'updatedOn',
                isKey: false,
                dataFormat: this.defaultType,
                columnClassName: '',
                dataSort: true,
                toolTip: false,
                isHidden: false,
                width: 'auto',
                filter: {
                    type: 'CustomFilter',
                    getElement: this.CustomDateFilter

                } as CustomFilter
            },
            {
                header: 'Recipient Details',
                key: 'recipient',
                isKey: false,
                dataFormat: this.defaultType,
                columnClassName: '',
                dataSort: true,
                toolTip: false,
                isHidden: false,
                width: 'auto',
                filter: { type: 'TextFilter', placeholder: 'Recipient Details', style: { font: 'bold' }, delay: 2500 } as TextFilter
            }
        ];

        let data: any = [];

        if (this.props.voucherReminderReport.voucherReminderReportTableModel.reminders) {
            data = this.props.voucherReminderReport.voucherReminderReportTableModel.reminders.map((model, index) => {
                return {
                    clientId: model.clientId,
                    clientName: model.clientName,
                    reminderData: model.reminderData,
                    taxYear: model.taxYear,
                    dueDate: moment(model.dueDate).format('MM/DD/YYYY'),
                    reminderDate: moment(model.reminderDate).format('MM/DD/YYYY'),
                    reminderStatus: model.reminderStatus,
                    updatedOn: moment(model.updatedOn).format('MM/DD/YYYY'),
                    recipient: model.recipient,
                    index: ((this.props.pageNo - 1) * this.props.pageSize) + index,
                    reminderGuid: model.reminderGuid,
                    locationName: model.locationName,
                }
            });

        }

       

        return <div className="pos-relative">
            <div className="txt-ar">
                <Bootstrap.Button
                    data-resource-id={`${this.props.parentResourceIdentifier + CommonResources.ExportToExcel}`} 
                    variant="default"
                    className="btn-only marR05"
                    title={VoucherReminderManagementConstants.ControlsLabel.ExportToExcel}
                    onClick={this.onClickExportToExcel}
                    disabled={!this.props.totalRows}
                    data-test-auto="D04B9868-CF8B-4832-B0F3-E36BE8F836F4">
                    <span className={this.state.exportingState ? 'fas fa-spinner fa-spin text-info' : 'fas fa-file-export'} ></span>
                </Bootstrap.Button>
            </div>

            <br />

            <div className="voucherReminderReport-table" data-test-auto="B510184C-5041-464B-9904-107F0B3C6F60">
                <BootstrapTable                    
                    ref='table'
                    data={data}
                    remote={true}
                    fetchInfo={{ dataTotalSize: this.props.totalRows }}
                    options={options}
                    striped
                    hover={true}
                    pagination={true}>
                    {columns.map((value, index) => {
                        return <TableHeaderColumn
                            key={index}
                            width={value.width}
                            ref={value.key}
                            tdStyle={{ padding: '5px' }}
                            isKey={value.isKey}
                            dataField={value.key}
                            hidden={value.isHidden}
                            dataFormat={value.dataFormat}
                            columnClassName={value.columnClassName}
                            columnTitle={value.toolTip}
                            filter={value.filter}
                            dataSort={value.dataSort}>{
								(value.width === 'auto') ?
									<span title={value.header} className="table-text-sort">{value.header}</span> :
									value.header
							}
                                                       
                        </TableHeaderColumn>;
                    })}
                </BootstrapTable>
            </div>
        </div >
    }
}

export default VoucherReminderReportTable
