import * as React from "react";
import { MyAccount } from "./MyAccount";
import { MySettings, IUserDefaultSettingsStates } from "./MySettings";
import { MyLoginHistory } from "./MyLoginHistory";
import Logout from "./Logout";
import { IUserProfile, IOTPState, IUserProfileModel, IPasswordUpdateModel, IPasswordPolicySettings } from "./ProfileObjects";
import { ISSRUserModel, IUserModel } from "../../../Core/ViewModels/User/UserViewModel";
import * as UserSettingsStore from "../../../store/userManagement/UserSettingStore";
import { MyDownload } from "./MyDownload";
import * as MailingReturnAddressStore from "../../../store/common/MailingReturnAddressStore";
import * as UserLoginHistory from "../../../store/userManagement/UserLoginHistoryStore";
import { ICompanySettings, ISuiteMFASetting } from "../../../Core/ViewModels/Company/CompanySettingsViewModel";
import { IDownloadedZipFilesModel } from "../../../components/navigation/profile/MyDownload";
import { ICustomColumn } from "../../../components/settings/GeneralSettings";
import { IHelptextModel } from "../../../Core/ViewModels/Common/IHelptextModel";
import { IAuthState } from "./../../../store/auth/reducer";
import { IDropdownMenuItem } from "../AppHeader";

interface IProfileProps {
    auth: IAuthState;
    users: IUserModel[];
    ssrUsers: ISSRUserModel[];
    fullName: string;
    menuItems: IDropdownMenuItem[];
    profile: IUserProfile;
    userDetails: IUserProfileModel;
    settings: UserSettingsStore.UserSettings;
    manualAddresList: MailingReturnAddressStore.IMailingReturnAddressDictionary;
    requestUserSettings: (reload: boolean) => void;
    updateUserSettings: (settings: IUserDefaultSettingsStates) => void;
    saveUserSettings: (
        settings: IUserDefaultSettingsStates,
        showUserSettingsNotification: boolean,
        callback?: () => void
    ) => void;
    saveSignatureDelegates: (delegates: number[]) => void;
    requestUserProfile: (reload: boolean) => void;
    saveUserProfile: (userInfo: IUserProfileModel, onResponse: (response: any, error: any) => void) => void;
    updatePassword: (passwordUpdateModel: IPasswordUpdateModel, onResponse: (response: any, error: any) => void) => void;
    getPasswordPolicySetting: (callback: (response: IPasswordPolicySettings) => void) => void;
    requestAllUsers: (reload: boolean) => void;
    requestSSRUser: (reload: boolean) => void;
    requestUserLoginHistory: (pageNo: number) => void;
    userLoginHistory: UserLoginHistory.IUserLoginHistoryState;
    companySettings: ICompanySettings;
    saveOneTimePassword: (mobileNumber: string, countryCode: string) => void;
    otpData: IOTPState;
    validateOTP: (otp: string, mobileNumber: string, callback: () => void) => void;
    verifyLoading: boolean;
    getAllMyDownloads: (callback: (result: any[]) => void) => void;
    downloadReturnFromPopup: (jobId: string, fileName: string, isDownloadNow?: boolean, callback?: (url: string) => void) => void;
    deleteMyDownloads: (jobId: string) => void;
    clearAllMyDownloads: () => void;
    myDownloads: IDownloadedZipFilesModel[];
    customColumnData: ICustomColumn;
    requestHelptexts?: (reload: boolean) => void;
    helptexts?: IHelptextModel[];
    showModal: {
        showProfile: boolean;
        showLoginHistory: boolean;
        showMySettings: boolean;
        showMyDownloads: boolean;
        showLogout: boolean;
    };
    mfaSetting: ISuiteMFASetting;
    allssrUsers: ISSRUserModel[];
}

interface IState {
    activeModal: string;
    myDownloadsList: any[];
}
export class ProfileDropdown extends React.Component<IProfileProps, IState> {
    constructor(props: IProfileProps) {
        super(props);

        this.state = {
            activeModal: "myAccount",
            myDownloadsList: []
        };
    }

    UNSAFE_componentWillMount() {
        this.props.getAllMyDownloads((result) => {
            this.setState({
                myDownloadsList: result
            });
        });
    }

    public render() {
        return (
            <div>
                <MyAccount
                    {...this.props.menuItems[0]}
                    showProfile={this.props.showModal.showProfile}
                    profile={this.props.profile}
                    userDetails={this.props.userDetails}
                    ssrUsers={this.props.ssrUsers}
                    saveUserProfile={this.props.saveUserProfile}
                    updatePassword={this.props.updatePassword}
                    getPasswordPolicySetting={this.props.getPasswordPolicySetting}
                    requestSSRUser={this.props.requestSSRUser}
                    companySettings={this.props.companySettings}
                    saveOneTimePassword={this.props.saveOneTimePassword}
                    otpData={this.props.otpData}
                    validateOTP={this.props.validateOTP}
                    verifyLoading={this.props.verifyLoading}
                    requestHelptexts={this.props.requestHelptexts}
                    helptexts={this.props.helptexts}
                    mfaSetting={this.props.mfaSetting} 
                />
                <MyLoginHistory
                    {...this.props.menuItems[1]}
                    showLoginHistory={this.props.showModal.showLoginHistory}
                    requestUserLoginHistory={this.props.requestUserLoginHistory}
                    userLoginHistory={this.props.userLoginHistory}
                    userSettings={this.props.settings}
                    saveSettings={this.props.saveUserSettings}
                    requestHelptexts={this.props.requestHelptexts}
                    helptexts={this.props.helptexts}
                />
                <MySettings
                    {...this.props.menuItems[2]}
                    showMySettings={this.props.showModal.showMySettings}
                    auth={this.props.auth}
                    ssrUsers={this.props.ssrUsers}
                    my={this.props.settings}
                    mailingReturnAddressList={this.props.manualAddresList}
                    updateSettings={this.props.updateUserSettings}
                    saveSettings={this.props.saveUserSettings}
                    saveSignatureDelegates={this.props.saveSignatureDelegates}
                    companySettings={this.props.companySettings}
                    customColumnData={this.props.customColumnData}
                    requestHelptexts={this.props.requestHelptexts}
                    helptexts={this.props.helptexts}
                    requestUserSettings={this.props.requestUserSettings}
                    allssrUsers={this.props.allssrUsers}
                />
                <MyDownload
                    {...this.props.menuItems[3]}
                    showMyDownloads={this.props.showModal.showMyDownloads}
                    downloadsList={this.props.myDownloads}
                    downloadReturnFromPopup={this.props.downloadReturnFromPopup}
                    clearAllMyDownloads={this.props.clearAllMyDownloads}
                    deleteMyDownloads={this.props.deleteMyDownloads}
                />
                <Logout {...this.props.menuItems[4]} showLogout={this.props.showModal.showLogout} />
            </div>
        );
    }
}
