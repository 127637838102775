import * as React from "react";
import { Card } from "react-bootstrap";
import { ClientInfoProperty } from "./ClientInfoEnum";
import { ITaxpayer } from "./TaxClient";
import { ITaxReturn, SignatureStatus, ClientTypesNumber, DocumentStatus } from "./TaxReturn";
import Select from "react-select";
import { prepareStateDropdown, FilterSSN } from "../helper/HelperFunctions";
import { CustomDatePicker } from "./controls/CustomDatePicker";
import { ICountryState } from "../../Core/ViewModels/Company/CompanyViewModel";
import { TaxIdentificationNumber } from "../common/controls/TaxIdentificationNumber";
import { VenusNotifier } from "../helper/VenusNotifier";
import { ClientInfoTab } from "../helper/Constants";
import { IDropdown } from "../../Core/Common/Dropdown";
import ToggleSwitch from "../common/ToggleSwitch";
import { ISMSNotificationDetails } from "../../store/common/SMSNotificationDetailsStore";
import { Link } from "react-router-dom";
import { getUsersByLocation } from "../common/ChangeOfficeLocationModal/ChangeOfficeLocationModal.api";
import { Loader, LoadingOverlay } from "react-overlay-loader";
import "@sssuite-component-ui/react-phone-input-2/lib/style.css";
import PhoneInput, { CountryData } from "@sssuite-component-ui/react-phone-input-2";

let dateMoment = require("moment");

export interface ITaxpayerProp {
    clientId: string;
    model: ITaxpayer;
    handleValueChange(item: ClientInfoProperty, value: string): void;
    taxReturn: ITaxReturn;
    states: ICountryState[];
    isDeliveredReturn: boolean;
    locationDropdown: IDropdown[];
    isGroupedReturn: boolean;
    isMandatory: boolean;
    showSMSTextNotifications: boolean;
    isTPOpted: boolean;
    smsNotificationDetails: ISMSNotificationDetails;
    updateTPOpted: (tpOpted: boolean) => void;
}

export interface ITaxPayerState {
    isDisabled: boolean;
    isDeliveredToTaxCaddy: boolean;
    isLoading: boolean;
    contactPersonList: IDropdown[];
    mobileNumberFullValue?: string;
}

export class Taxpayer extends React.Component<ITaxpayerProp, ITaxPayerState> {
    state = {
        isDisabled: false,
        isDeliveredToTaxCaddy: false,
        isLoading: false,
        contactPersonList: [],
        mobileNumberFullValue: undefined
    };

    componentDidMount() {
        if (this.props.taxReturn.locationId) {
            this.loadContactPersons(this.props.taxReturn.locationId);
        }
    }

    UNSAFE_componentWillReceiveProps(nextprops: ITaxpayerProp) {
        if (nextprops.taxReturn.signedDetails != undefined) {
            const taxReturnStatus = parseInt(SignatureStatus[this.props.taxReturn.signatureStatus]);
            let isDisabled: boolean =
                (taxReturnStatus === SignatureStatus.PartiallySigned &&
                this.props.taxReturn.signedDetails != undefined &&
                this.props.taxReturn.signedDetails.filter((x) => x.signerType === ClientTypesNumber.Taxpayer)[0] != undefined
                    ? this.props.taxReturn.signedDetails.filter((x) => x.signerType === ClientTypesNumber.Taxpayer)[0].isSigned
                    : false) ||
                taxReturnStatus === SignatureStatus.ESigned ||
                taxReturnStatus === SignatureStatus.ESignedInOffice;

            let isDeliveredToTaxCaddy: boolean = nextprops.taxReturn.documentStatus === DocumentStatus.DELIVEREDTOTAXCADDY;
            this.setState({ isDisabled: isDisabled, isDeliveredToTaxCaddy: isDeliveredToTaxCaddy });
        }
    }

    private handleChangeDoB = (event: any) => {
        let now = new Date();
        if (dateMoment(event) > now) {
            VenusNotifier.Warning(ClientInfoTab.AddedRefund.DOBWarning, null);
        } else this.props.handleValueChange(ClientInfoProperty.taxpayerDateOfBirth, event);
    };

    onMobileChange = (fullValue: string, countryObject: CountryData): void => {
        if (
            fullValue &&
            fullValue.length >= 4 &&
            fullValue.substring(0, countryObject.dialCode.length) !== countryObject.dialCode
        ) {
            fullValue = `${countryObject.dialCode}${fullValue}`;
        }
        this.props.handleValueChange(ClientInfoProperty.taxpayerCountryCode, fullValue ? `+${countryObject.dialCode}` : "");
        this.props.handleValueChange(ClientInfoProperty.taxpayerMobileNumber, fullValue.slice(countryObject.dialCode.length));
        this.setState({ mobileNumberFullValue: fullValue });
    };

    onOfficeLocationChange = (event: any) => {
        const selectedValue = event ? event.value.toString() : 0;
        this.props.handleValueChange(ClientInfoProperty.officeLocation, selectedValue);
        this.loadContactPersons(selectedValue);
    };

    loadContactPersons = (locationId: number) => {
        this.setState({ isLoading: true });
        if (locationId > 0) {
            getUsersByLocation(locationId, (data) => {
                this.setState({ contactPersonList: data, isLoading: false }, () => {
                    this.changeContactPersonOnOfficeChange();
                });
            });
        } else {
            this.setState({ contactPersonList: [], isLoading: false });
        }
    };

    changeContactPersonOnOfficeChange = () => {
        const contactPersonEvent = {
            value: this.state.contactPersonList?.some(
                (item: IDropdown) => item.value == this.props.taxReturn.documentSettings.deliverySettings.contactPerson
            )
                ? this.props.taxReturn.documentSettings.deliverySettings.contactPerson
                : 0
        };
        this.onContactPersonChange(contactPersonEvent);
    };

    onContactPersonChange = (event: any) => {
        const selectedValue = event ? event.value.toString() : 0;
        this.props.handleValueChange(ClientInfoProperty.contactPerson, selectedValue);
    };

    prepareLocation = () => {
        return this.props.locationDropdown?.map((item) => {
            return {
                value: item.value.toString(),
                label: item.name
            };
        });
    };

    prepareContactPersonDropDown = () => {
        return this.state.contactPersonList?.map((item: IDropdown) => {
            return {
                value: item.value.toString(),
                label: item.name
            };
        });
    };

    private handleTaxpayerToggleSwitchChange = (event: any) => {
        this.props.updateTPOpted(event.target.checked);
    };

    public render() {
        return (
            <div className="col-12">
                <Card style={{ border: "none" }}>
                    <Card.Header style={{ border: "1px solid rgba(0,0,0,.125)" }}>
                        <strong>Taxpayer</strong>
                    </Card.Header>
                    <Card.Body>
                        <LoadingOverlay style={{ height: "100%" }}>
                            <div className="row marL0">
                                <div className="col-sm-12 col-md-12 col-xl-12">
                                    <div className="row">
                                        <div className="col-sm-2 col-md-2 col-xl-2 padT07 tpLabelFont">
                                            Client ID<span className="fas fa-asterisk compulsory-star"></span>
                                        </div>
                                        <div className="col-sm-10 col-md-10 col-xl-10 marB10 padR25">
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={this.props.clientId}
                                                onChange={(event: any) => {
                                                    this.props.handleValueChange(
                                                        ClientInfoProperty.clientId,
                                                        event.target?.value
                                                    );
                                                }}
                                                placeholder="Client ID"
                                                data-test-auto="FC1A0182-46DA-40B2-8439-ACED6B19D759"
                                                disabled={this.state.isDeliveredToTaxCaddy}
                                            ></input>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row marL0">
                                <div className="col-sm-12 col-md-12 col-xl-12">
                                    <div className="row">
                                        <div className="col-sm-2 col-md-2 col-xl-2 padT07 tpLabelFont">
                                            Name<span className="fas fa-asterisk compulsory-star"></span>
                                        </div>
                                        <div className="col-sm-10 col-md-10 col-xl-10 marB10 padR25">
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={this.props.model.name}
                                                onChange={(event: any) => {
                                                    this.props.handleValueChange(
                                                        ClientInfoProperty.taxpayerName,
                                                        event.target?.value
                                                    );
                                                }}
                                                placeholder="First Name"
                                                data-test-auto="FC1A0182-46DA-40B2-8439-ACED6B19D759"
                                                disabled={this.state.isDisabled || this.state.isDeliveredToTaxCaddy}
                                            ></input>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row marL0">
                                <div className="col-sm-12 col-md-12 col-xl-12">
                                    <div className="row">
                                        <div className="col-sm-2 col-md-2 col-xl-2 padT07 tpLabelFont">
                                            Email
                                            <span
                                                className={this.props.isMandatory ? "fas fa-asterisk compulsory-star" : ""}
                                            ></span>
                                        </div>
                                        <div className="col-sm-10 col-md-10 col-xl-10 marB10 padR25">
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={this.props.model.email || ""}
                                                onChange={(event: any) => {
                                                    this.props.handleValueChange(
                                                        ClientInfoProperty.taxpayerEmail,
                                                        event.target?.value
                                                    );
                                                }}
                                                placeholder="Email"
                                                data-test-auto="FC1A0182-46DA-40B2-8439-ACED6B19D759"
                                                disabled={this.state.isDeliveredToTaxCaddy}
                                            ></input>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {!this.props.isGroupedReturn && this.props.isDeliveredReturn ? (
                                <div className="row marL0">
                                    <div className="col-sm-12 col-md-12 col-xl-12">
                                        <div className="row">
                                            <div className="col-sm-2 col-md-2 col-xl-2 padT07 tpLabelFont">
                                                Office<span className="fas fa-asterisk compulsory-star"></span>
                                            </div>
                                            <div className="col-sm-10 col-md-10 col-xl-10 marB10 padR25">
                                                <Select
                                                    name="form-field-edit-info-state-taxpayer"
                                                    className="editClientInfo_dropdown"
                                                    value={this.props.taxReturn?.locationId}
                                                    onChange={this.onOfficeLocationChange}
                                                    options={this.prepareLocation()}
                                                    data-test-auto="894C3CEB-4671-4529-A835-ECA0CCC9DD91"
                                                    clearable={false}
                                                    placeholder="Select..."
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div></div>
                            )}
                            {!this.props.isGroupedReturn && this.props.isDeliveredReturn ? (
                                <div className="row marL0">
                                    <div className="col-sm-12 col-md-12 col-xl-12">
                                        <div className="row">
                                            <div className="col-sm-2 col-md-2 col-xl-2 tpLabelFont">
                                                Contact Person <span className="fas fa-asterisk compulsory-star"></span>
                                            </div>
                                            <div className="col-sm-10 col-md-10 col-xl-10 marB10 padR25">
                                                <Select
                                                    name="form-field-edit-info-state-taxpayer"
                                                    className="editClientInfo_dropdown"
                                                    value={this.props.taxReturn.documentSettings.deliverySettings.contactPerson}
                                                    onChange={this.onContactPersonChange}
                                                    options={this.prepareContactPersonDropDown()}
                                                    data-test-auto="894C3CEB-4671-4529-A835-ECA0CCC9DD91"
                                                    clearable={false}
                                                    placeholder="Select..."
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div></div>
                            )}

                            <div className="row marL0">
                                <div className="col-sm-12 col-md-12 col-xl-12">
                                    <div className="row">
                                        <div className="col-sm-2 col-md-2 col-xl-2 padT07 tpLabelFont">Address</div>
                                        <div className="col-sm-10 col-md-10 col-xl-10 marB10 padR25">
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={this.props.model.address}
                                                onChange={(event: any) => {
                                                    this.props.handleValueChange(
                                                        ClientInfoProperty.taxpayerAddress,
                                                        event.target?.value
                                                    );
                                                }}
                                                placeholder="Address"
                                                data-test-auto="FC1A0182-46DA-40B2-8439-ACED6B19D759"
                                                disabled={this.state.isDisabled || this.state.isDeliveredToTaxCaddy}
                                            ></input>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row marL0">
                                <div className="col-sm-6 col-md-6 col-xl-6">
                                    <div className="row">
                                        <div className="col-sm-4 col-md-4 col-xl-4 padT07 tpLabelFont">City</div>
                                        <div className="col-sm-8 col-md-8 col-xl-8 marB10">
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={this.props.model.city}
                                                onChange={(event: any) => {
                                                    this.props.handleValueChange(
                                                        ClientInfoProperty.taxpayerCity,
                                                        event.target?.value
                                                    );
                                                }}
                                                placeholder="City"
                                                data-test-auto="74469676-1B78-4E82-B9C2-6C0A43D0A98D"
                                                disabled={this.state.isDisabled || this.state.isDeliveredToTaxCaddy}
                                            ></input>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-md-6 col-xl-6">
                                    <div className="row">
                                        <div className="col-sm-4 col-md-4 col-xl-4 padT07 tpLabelFont">State</div>
                                        <div className="col-sm-8 col-md-8 col-xl-8 marB10 padR25">
                                            <Select
                                                name="form-field-edit-info-state-taxpayer"
                                                className="editClientInfo_dropdown"
                                                value={this.props.model.state}
                                                onChange={(event: any) => {
                                                    this.props.handleValueChange(ClientInfoProperty.taxpayerState, event?.value);
                                                }}
                                                options={prepareStateDropdown(this.props.states)}
                                                data-test-auto="1F29729F-075F-422F-92F9-EC51033A44F5"
                                                clearable={false}
                                                disabled={this.state.isDisabled || this.state.isDeliveredToTaxCaddy}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row marL0">
                                <div className="col-sm-6 col-md-6 col-xl-6">
                                    <div className="row">
                                        <div className="col-sm-4 col-md-4 col-xl-4 padT07 tpLabelFont">Zip</div>
                                        <div className="col-sm-8 col-md-8 col-xl-8 marB10">
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={this.props.model.zip}
                                                onChange={(event: any) => {
                                                    this.props.handleValueChange(
                                                        ClientInfoProperty.taxpayerZip,
                                                        event.target?.value
                                                    );
                                                }}
                                                maxLength={5}
                                                placeholder="Zip"
                                                data-test-auto="F8EC44D1-A576-4B71-BE4B-C1A844D44E62"
                                                disabled={this.state.isDisabled || this.state.isDeliveredToTaxCaddy}
                                            ></input>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-md-6 col-xl-6">
                                    <div className="row">
                                        <div className="col-sm-4 col-md-4 col-xl-4 padT07 tpLabelFont">DOB</div>
                                        <div className="col-sm-8 col-md-8 col-xl-8 marB10 padR25">
                                            <CustomDatePicker
                                                value={this.props.model.dob}
                                                onChange={this.handleChangeDoB}
                                                disabled={this.state.isDisabled || this.state.isDeliveredToTaxCaddy}
                                                maxDate={new Date()}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row marL0">
                                <div className="col-sm-12 col-md-12 col-xl-12">
                                    <div className="row">
                                        <div className="col-sm-2 col-md-2 col-xl-2 padT07 tpLabelFont">Mobile</div>
                                        <div className="col-sm-10 col-md-10 col-xl-10 marB10 padR25">
                                            <PhoneInput
                                                specialLabel=""
                                                country={"us"}
                                                autoFormat={true}
                                                value={
                                                    this.state.mobileNumberFullValue ??
                                                    `${this.props.model.countryCode}${this.props.model.mobileNumber}`
                                                }
                                                onChange={this.onMobileChange}
                                                disabled={this.state.isDisabled || this.state.isDeliveredToTaxCaddy}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row marL0">
                                <div className="col-sm-6 col-md-6 col-xl-6">
                                    <div className="row">
                                        <div className="col-sm-4 col-md-4 col-xl-4 padT07 tpLabelFont">SSN</div>
                                        <div className="col-sm-8 col-md-8 col-xl-8 marB10">
                                            <TaxIdentificationNumber
                                                value={this.props.model ? FilterSSN(this.props.model.ssn) : ""}
                                                onChange={(value: string) => {
                                                    this.props.handleValueChange(ClientInfoProperty.taxpayerSSN, value);
                                                }}
                                                placeholder="SSN"
                                                mask="000-00-0000"
                                                disabled={this.state.isDisabled || this.state.isDeliveredToTaxCaddy}
                                            />
                                        </div>
                                    </div>
                                </div>
                                {this.props.showSMSTextNotifications && (
                                    <div className="col-sm-6 col-md-6 col-xl-6">
                                        <div className="row">
                                            <ToggleSwitch
                                                switched={this.props.isTPOpted}
                                                handleChange={this.handleTaxpayerToggleSwitchChange}
                                                disabled={
                                                    !this.props.smsNotificationDetails.isSuiteSMSNotificationSetting ||
                                                    !this.props.smsNotificationDetails.isTwilioTaxPayerSMSOptedIn
                                                }
                                                className={
                                                    !this.props.smsNotificationDetails.isSuiteSMSNotificationSetting ||
                                                    !this.props.smsNotificationDetails.isTwilioTaxPayerSMSOptedIn
                                                        ? "disable"
                                                        : ""
                                                }
                                                dataTestChecked={this.props.isTPOpted}
                                                dataTestAuto="50c5db7e-44a6-4fa1-a0c0-d8feaec73767"
                                            />
                                            <div
                                                className="col-sm-4 col-md-4 col-xl-7 padT07 tpLabelFont"
                                                style={{ padding: "6px 0px 0px 14px" }}
                                            >
                                                SMS Text Notification
                                            </div>
                                            {(!this.props.smsNotificationDetails.isSuiteSMSNotificationSetting ||
                                                !this.props.smsNotificationDetails.isTwilioTaxPayerSMSOptedIn) && (
                                                <Link
                                                    to={"#"}
                                                    style={{ marginLeft: "-27px", marginTop: "3px" }}
                                                    className="help-icon marL05 col-sm-1"
                                                    data-placement="right"
                                                    data-toggle="tooltip"
                                                    title={
                                                        !this.props.smsNotificationDetails.isSuiteSMSNotificationSetting
                                                            ? "To change, enable SMS notification at the Suite level."
                                                            : !this.props.smsNotificationDetails.isTwilioTaxPayerSMSOptedIn
                                                            ? "Client can opt-in again by replying START."
                                                            : ""
                                                    }
                                                >
                                                    <i className="fas fa-question-circle"></i>
                                                </Link>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </div>
                            <Loader loading={this.state.isLoading} text="loading....." />
                        </LoadingOverlay>
                    </Card.Body>
                </Card>
            </div>
        );
    }
}
export default Taxpayer;
