import * as React from 'react'
import { K1DeliveredStatusResources } from '../../helper/ResourceIdConstants';


interface K1DeliveredStatusActionsProps{
    title: any;
    rowIndex: number;
    onOpenPartnerModal: (rowIndex: number) => void;
    onTaxpayerView: (rowIndex: number) => void;
    parentResourceId: string;
}

interface K1DeliveredStatusActionsState {

}

export default class K1DeliveredStatusActions extends React.Component<K1DeliveredStatusActionsProps, K1DeliveredStatusActionsState>{

    private createPrimaryActions = () => {
        const { rowIndex, onTaxpayerView, onOpenPartnerModal } = this.props;
        return (
            <div>
                <button title={"Client View"}
                    data-resource-id={this.props.parentResourceId + K1DeliveredStatusResources.ClientView}
                    type="button"
                    className="btn-white"
                    onClick={() => { onTaxpayerView(rowIndex) }}
                >
                    <i className='fas fa-eye icon-color'></i>
                </button>
                <button title={"K1 Partner Updates"}
                    data-resource-id={this.props.parentResourceId + K1DeliveredStatusResources.K1PartnerUpdate}
                    type="button"
                    className="btn-white"
                    onClick={() => { onOpenPartnerModal(rowIndex) }}
                >
                    <i className='fas fa-users-cog text-success'></i>
                </button>
            </div>);
    }
    

    render() {
        return (
            <div className="button-group">
                {this.createPrimaryActions()}
                </div>
            )
    }
}
