import * as React from 'react';
import { Guid } from '../../../../Core/Utilities/Guid';
import { IPdfDocumentFacade } from '../../../../Core/Utilities/PdfDocumentFacade';
import { PdfProperties } from '../../../../Core/ViewModels/Common/PdfHelper';
import * as UserSignatureStore from '../../../../store/common/UserSignatureStore';
import * as CompanyStore from '../../../../store/company/CompanyStore';
import * as PdfStore from '../../../../store/pdf/PdfStore';
import * as UserSettingStore from '../../../../store/userManagement/UserSettingStore';
import * as TaxDocument from '../../../common/TaxReturn';
import { DocumentGroups, SignatureControlRole, SignatureControlTypes, SignatureGroupType } from '../../../common/TaxReturn';
import { IUserProfile } from '../../../navigation/profile/ProfileObjects';
import { AwesomePdfViewer } from '../AwesomePdfViewer';
import { BookmarksPane } from '../BookmarksPane';
import { INVALID_PAGE } from '../CustomPagination/CustomPagination';
import {
	getAllEroSigners, IBookmarkSection, ISignatureControlsDictionary,
	PdfPageSignatureControls
} from '../ProcessReturnModels';
import { EROSignatureCard } from '../RightSidebar/EROSignaturePanel';
import { PageMoveTo } from './../PageMoveTo';
import { dragStartSignatureControl } from './../SignatureControls/SignatureHelper';
import { IEROSigner } from './TabEFile';
import { logger } from '../../../../routes/LoggedIn';
import { CheckBoxComponent } from '../../CheckBoxComponent';
import { IAuthState } from '../../../../store/auth/reducer';

const NO_INDEX = -1;

export interface ITabTaxReturnProps {
    auth: IAuthState;
	bookmarks: IBookmarkSection[];
	formGroup: TaxDocument.IGroup;
	pdf?: IPdfDocumentFacade;
	reGroupPreview: (pages: number[], destinationGroup: TaxDocument.DocumentGroups) => void;
	taxReturn: TaxDocument.ITaxReturn;
	docId: number;
	pdfDocuments: PdfStore.IPdfDocumentDictionary;
	deletePages: (pages: number[]) => void;
	renderTrigger: string;
	isAssignedToLoggedinUser: boolean;
	getAllTaxingAuthorities: TaxDocument.ITaxingAuthority[];
	isEnableInvoice: boolean;

	removeSignatureControl(control: TaxDocument.ISignatureControl, pageNo: number): void;
	addSignatureControl(control: TaxDocument.ISignatureControl, pageNo: number, group: DocumentGroups): void;
	updateEROSignatureStamp(userId: number): void;
	replaceSignatureControl(oldControl: TaxDocument.ISignatureControl, newControl: TaxDocument.ISignatureControl, pageNo: number): void;
	signatureControls: ISignatureControlsDictionary;
	userSettings: UserSettingStore.UserSettings;
	company: CompanyStore.ICompanyData;
	userProfile: IUserProfile;
	userSignatures: UserSignatureStore.IUserSignatures;
	pages: number[];

	updateTaxDocument: (taxDocument: TaxDocument.ITaxReturn) => void;

}

interface ITabTaxReturnState {
	pages: number[];
	currentPage: number;
	currentPageIxdex: number;
	zoomEnabled: boolean;

	eroSigners: IEROSigner[];
	selectedEROSigner: IEROSigner;
	focusedGroup: SignatureGroupType;
	watermarkSettings: TaxDocument.IWatermarkSetting;
	scale: number;
}

const isEqual = require("react-fast-compare");
export class TabTaxReturn extends React.Component<ITabTaxReturnProps, ITabTaxReturnState>{
	constructor(props: ITabTaxReturnProps) {
		super(props);
		this.state = {
			pages: [],
			currentPage: INVALID_PAGE,
			currentPageIxdex: 0,
			zoomEnabled: false,

			eroSigners: [],
			selectedEROSigner: {
				value: 0,
				label: "",
				isEnableSignatureDelegation: true,
				eroImage: ""
			},
			focusedGroup: SignatureGroupType.None,
			watermarkSettings: {
				includedPages: [],
				excludedPages: []
			},
			scale: PdfProperties.DefaultScale
		};
		this.onReGroupPreview = this.onReGroupPreview.bind(this);

		this.onPageDelete = this.onPageDelete.bind(this);
	}

	UNSAFE_componentWillReceiveProps(nextProps: ITabTaxReturnProps) {

		if (this.props.userSignatures !== nextProps.userSignatures)
			this.setEroSignerDropdownValues(nextProps);

		if (nextProps.pages && nextProps.pages.length != this.props.pages.length) {
			this.onPageSelect(nextProps.pages[this.state.currentPageIxdex]);
		}
		if (nextProps.pages && nextProps.pages.length == this.state.currentPageIxdex) {
			this.onPageSelect(nextProps.pages[this.state.currentPageIxdex - 1]);
		}

	}

	componentDidMount() {
		this.props && this.props.pages && this.setState({ currentPage: this.props.pages[0] });
		
		const watermarkDisabledPages: number[] = this.props.taxReturn.documentSettings.watermarkSettings.excludedPages;
		
		this.setState({
			watermarkSettings: {
				includedPages: [],
				excludedPages: watermarkDisabledPages
			}
		});
	}

	shouldComponentUpdate(nextProps: ITabTaxReturnProps, nextState: ITabTaxReturnState) {

		return ((nextProps.pages !== this.props.pages) ||
			(this.state.currentPage === INVALID_PAGE) ||
			(!isEqual(this.state, nextState)));
	}

	UNSAFE_componentWillMount() {
		this.setEroSignerDropdownValues(this.props);
	}

	onReGroupPreview(pages: number[], destinationGroup: TaxDocument.DocumentGroups) {
		this.setState({ currentPage: INVALID_PAGE }, () => { this.props.reGroupPreview(pages, destinationGroup) });
	}

	checkWatermarkSettingAndWatermarkId = () => {
		const { isWatermarkEnabled } = this.props.company.companySettings.displaySettingsModel;
		const { watermarkId } = this.props.taxReturn.documentSettings.deliverySettings;

		if (isWatermarkEnabled) {
			return watermarkId === 0 ? false : true;
		} else {
			return false;
		}
	}

	isCurrentPageWatermarkCheckboxChecked = (currentPage: number) => {
		const { excludedPages } = this.props.taxReturn.documentSettings.watermarkSettings;

		return excludedPages.includes(currentPage) ? true : false;
	}

	isWatermarkCheckboxChecked = (currentPage: number) => {
		return this.checkWatermarkSettingAndWatermarkId() && this.isCurrentPageWatermarkCheckboxChecked(currentPage);
	}

	handleChangeWatermarkStatus = (event: any) => {
		const checked: boolean = event.target.checked;
		const watermarkDisabledPages: number[] = this.props.taxReturn.documentSettings.watermarkSettings.excludedPages;
		const { watermarkSettings: { excludedPages }, currentPage } = this.state;

		if (checked) {
			this.setState({
				watermarkSettings: {
					includedPages: [],
					excludedPages: [...excludedPages, currentPage]
				}
			});
			watermarkDisabledPages.push(currentPage);
		} else {
			this.setState({
				watermarkSettings: {
					includedPages: [],
					excludedPages: excludedPages.filter((x: number) => x !== currentPage)
				}
			});
			const index = watermarkDisabledPages.indexOf(currentPage);
			index > -1 && watermarkDisabledPages.splice(index, 1);
		}
		this.props.updateTaxDocument(this.props.taxReturn);
		logger.trackTrace(`Watermark status change for page no.: ${currentPage}, document: ${this.props.taxReturn.documentGuid}`, { "DocumentId": this.props.taxReturn.id?.toString(), "DocumentGuid": this.props.taxReturn.documentGuid });
	}

	showTooltipContent = () => {
		return !this.checkWatermarkSettingAndWatermarkId() &&
			<i className="fa fa-question-circle" style={{ color: '#DAA520', marginLeft: '10px' }} title={"No watermarks added to return from delivery options tab."}></i>
	}

	createLeftPanel = () => {
		return (<BookmarksPane
			title="Pages"
			docId={this.props.docId}
			pdfDocuments={this.props.pdfDocuments}
			sections={this.props.bookmarks}
			onPageDelete={this.onPageDelete}
			onPageSelect={this.onPageSelect}
			getAllTaxingAuthorities={this.props.getAllTaxingAuthorities} />);
	}

	createRightPanel = () => {
		return (<>
			{(this.props.userSettings.settings.useSignatureStamp) &&
				<div>
					<EROSignatureCard
						title="ERO Signature Stamp"
						selectedEROSigner={this.state.selectedEROSigner}
						eroSigner={this.state.eroSigners}
						onEROSignerChange={this.handleEROSignerChange}
						isAssignedToLoggedinUser={this.props.isAssignedToLoggedinUser}
						onSignatureControlFocus={this.onSignatureControlFocus}
						dragStart={this.dragStartSignatureControl}
						dragEnd={this.dragEnd}
					/>
				</div>
			}
			<div className='row' style={{ marginLeft: "0", marginRight: "0" }}>
				<CheckBoxComponent
					id={`${this.state.currentPage}`}
					text='Do not add watermark to this page'
					onChange={(event: any) => this.handleChangeWatermarkStatus(event)}
					checked={this.isWatermarkCheckboxChecked(this.state.currentPage)}
					disabled={!this.checkWatermarkSettingAndWatermarkId()}
				/>
				{this.showTooltipContent()}
			</div>
		</>)
	}

	createMoveTo = () => {
		return <PageMoveTo
			documentGroup={TaxDocument.DocumentGroups.TaxReturns}
			reGroupPreview={this.reGroupPreview}
			engagementType={this.props.taxReturn.engagementType}
			arrangement={this.props.taxReturn.arrangement}
			isEnableInvoice={this.props.isEnableInvoice}
		/>
	}

	reGroupPreview = (destinationGroup: TaxDocument.DocumentGroups) => {
		let pages: number[] = [];
		this.props.formGroup && this.props.formGroup.forms.map((form, i) => {
			pages.push(...form.pageNo);
		});
		let indexOfCurrentPage: number = this.props.pages.indexOf(this.state.currentPage);
		this.props.reGroupPreview([this.state.currentPage], destinationGroup);
		if (destinationGroup == TaxDocument.DocumentGroups.Deleted) {
			if ((this.props.pages.length - 1) == indexOfCurrentPage) {
				this.setState({
					currentPage: this.props.pages.indexOf(indexOfCurrentPage),
					currentPageIxdex: indexOfCurrentPage - 1
				});
			}
			else {
				this.setState({
					currentPage: this.props.pages.indexOf(indexOfCurrentPage),
					currentPageIxdex: pages.indexOf(this.state.currentPage)
				});
			}
		}
		else {
			this.setState({
				currentPage: pages[0]
			});
		}
	}

	public render() {
		return (<>
			<AwesomePdfViewer
				pdfDocument={this.props.pdfDocuments[this.props.docId].document as IPdfDocumentFacade}
				leftPanel={this.createLeftPanel()}
				rightPanel={this.createRightPanel()}
				currentPage={this.state.currentPage}
				pages={this.props.pages}
				enableMoveTo={true}
				moveToElement={this.createMoveTo()}
				signatureControls={this.getEroSignatureControls()}
				isAssignedToLoggedinUser={this.props.isAssignedToLoggedinUser}
				isIndividual={TaxDocument.isIndividual(this.props.taxReturn)}
				keyPrefix={"tax-return-control-"}
				onControlRemove={this.onControlRemove}
				onControlUpdate={this.onControlUpdate}
				scale={this.state.scale}
				onScaleChange={(s) => this.setState({ scale: s })}
				onCurrentPageChange={(page) => this.setState({ currentPage: page })}
			/>
		</>)
	}

	private onPageSelect = (page: number, voucherNo?: number, isPageSelectionFromBookMark?: boolean) => {
		// page is the page number in the document
		this.setState({ currentPage: page });

		if (isPageSelectionFromBookMark) {
			this.setState({
				currentPageIxdex: this.props.pages.indexOf(page)
			});
		};

		logger.trackTrace(`TabTaxReturn --> page no. ${page} selected, document: ${this.props.taxReturn.documentGuid}`, { "DocumentId": this.props.taxReturn.id?.toString(), "DocumentGuid": this.props.taxReturn.documentGuid });
	}

	private onPageDelete = (page: number) => {
		this.props.deletePages([page]);
	}

	private onControlRemove = (control: TaxDocument.ISignatureControl) => {
		this.props.removeSignatureControl(control, this.state.currentPage);
	}

	private onControlUpdate = (oldControl: TaxDocument.ISignatureControl, newControl: TaxDocument.ISignatureControl) => {
		this.props.replaceSignatureControl(oldControl, newControl, this.state.currentPage);
	}

	private adjustSamePositionControl = (control: TaxDocument.ISignatureControl): TaxDocument.ISignatureControl => {
		const signatureControls = this.props.signatureControls[this.state.currentPage];
		if (signatureControls) {
			const index = signatureControls.findIndex(x => x.controlGuid === control.controlGuid);
			if (index !== NO_INDEX) {
				control.left = control.left + 5;
				control.top = control.top + 5;
				return this.adjustSamePositionControl(control);
			}
			else {
				return control;
			}
		}
		return control;
	}

	private addSignatureControl = (type: TaxDocument.SignatureControlTypes, role: TaxDocument.SignatureControlRole, left: number, top: number) => {
		try {
			logger.trackTrace(`TabTaxReturn --> addSignatureControl started for document: ${this.props.taxReturn.documentGuid} , SignatureControlTypes: ${type}, SignatureControlRole: ${role}, clientX : ${left}, clientY : ${top}`, { "DocumentId": this.props.taxReturn.id?.toString(), "DocumentGuid": this.props.taxReturn.documentGuid });
			let _self = this;
			const { currentPage } = this.state;
			let control: TaxDocument.ISignatureControl = {} as TaxDocument.ISignatureControl;
			control.controlGuid = Guid.newGuid().toString();
			control.left = left;
			control.top = top;
			control.type = type;
			control.signatureControlRole = role;
			_self.props.addSignatureControl(_self.adjustSamePositionControl(control), currentPage, DocumentGroups.TaxReturns);
			logger.trackTrace(`TabTaxReturn --> addSignatureControl ended for document: ${this.props.taxReturn.documentGuid}, SignatureControlTypes: ${type}, SignatureControlRole: ${role}, clientX : ${left}, clientY : ${top}`, { "DocumentId": this.props.taxReturn.id?.toString(), "DocumentGuid": this.props.taxReturn.documentGuid });
		} catch (error) {
			logger.trackWarning(`TabTaxReturn --> SignatureControlDragandDropError: addSignatureControl throw error for document: ${this.props.taxReturn.documentGuid}, SignatureControlTypes: ${type}, SignatureControlRole: ${role}, clientX : ${left}, clientY : ${top}, error : ${error}`, { "DocumentId": this.props.taxReturn.id?.toString(), "DocumentGuid": this.props.taxReturn.documentGuid });
		}
	}

	private filterSignatureControls = (signatureControls: TaxDocument.ISignatureControl[], signatureType: any):
		TaxDocument.ISignatureControl[] => {
		let signControls: TaxDocument.ISignatureControl[] = signatureControls;
		if (signControls) {
			if (signatureType === TaxDocument.SignatureType[TaxDocument.SignatureType.ManualSign] && this.props.userSettings.settings.useSignatureStamp) {
				signControls = signControls.filter(control => control.signatureControlRole === SignatureControlRole.ERO);
			}
			else if (signatureType === TaxDocument.SignatureType[TaxDocument.SignatureType.ManualSign] && !this.props.userSettings.settings.useSignatureStamp) {
				signControls = [];
			}
			else {
				if (!this.props.userSettings.settings.useSignatureStamp) {
					signControls = signControls.filter(control => control.signatureControlRole !== SignatureControlRole.ERO);
				}
			}
		}

		return signControls;
	}

	private getEroSignatureControls = (): PdfPageSignatureControls => {
		let signatureControls: TaxDocument.ISignatureControl[] = [];
		let signatureType: any = this.props.taxReturn.documentSettings.documentSignatureSetting.signatureFormSelectionType;
		signatureType = (typeof signatureType == "number") ? TaxDocument.SignatureType[signatureType] : signatureType;
		signatureControls = this.filterSignatureControls(this.props.signatureControls[this.state.currentPage], signatureType);
		return {
			signatureControls: signatureControls,
			eroSigner: { id: this.state.selectedEROSigner.value, signPath: this.state.selectedEROSigner.eroImage },
			signer: {},
			focusedGroup: SignatureGroupType.Stamp
		} as PdfPageSignatureControls;
	}

	private dragStartSignatureControl = (event: any, controlType: SignatureControlTypes, signatureRole: SignatureControlRole) => {
		try	{
			logger.trackTrace(`TabTaxReturn --> dragStartSignatureControl started: ${this.props.taxReturn.documentGuid}, SignatureControlTypes : ${controlType} and SignatureControlRole : ${signatureRole}`, { "DocumentId": this.props.taxReturn.id?.toString(), "DocumentGuid": this.props.taxReturn.documentGuid });
			dragStartSignatureControl(
				event, controlType, signatureRole,
				this.state.selectedEROSigner.label,
				this.state.currentPage,
				this.state.scale,
				this.addSignatureControl);
			logger.trackTrace(`TabTaxReturn --> dragStartSignatureControl ended: ${this.props.taxReturn.documentGuid}, SignatureControlTypes : ${controlType} and SignatureControlRole : ${signatureRole}`, { "DocumentId": this.props.taxReturn.id?.toString(), "DocumentGuid": this.props.taxReturn.documentGuid });
		} catch(error) {
			logger.trackWarning(`TabTaxReturn --> SignatureControlDragandDropError: dragStartSignatureControl throw error: ${this.props.taxReturn.documentGuid}, SignatureControlTypes : ${controlType} and SignatureControlRole : ${signatureRole}, error: ${error}`, { "DocumentId": this.props.taxReturn.id?.toString(), "DocumentGuid": this.props.taxReturn.documentGuid });
		}
	}

	private dragEnd = (event: any) => {
		try	{
			logger.trackTrace(`TabTaxReturn --> dragEnd started for document: ${this.props.taxReturn.documentGuid}`, { "DocumentId": this.props.taxReturn.id?.toString(), "DocumentGuid": this.props.taxReturn.documentGuid });
			let element = document.getElementById("dragging-element");
			if (element) {
				element.remove();
			}
			logger.trackTrace(`TabTaxReturn --> dragEnd ended for document: ${this.props.taxReturn.documentGuid}`, { "DocumentId": this.props.taxReturn.id?.toString(), "DocumentGuid": this.props.taxReturn.documentGuid });
		} catch(error) {
			logger.trackWarning(`TabTaxReturn --> SignatureControlDragandDropError: dragEnd throw error for document: ${this.props.taxReturn.documentGuid}, error : ${error}`, { "DocumentId": this.props.taxReturn.id?.toString(), "DocumentGuid": this.props.taxReturn.documentGuid });
		}
	}


	private handleEROSignerChange = (selectedOption: IEROSigner) => {
		if (selectedOption && selectedOption.eroImage !== "") {
			this.setState({
				selectedEROSigner: {
					value: selectedOption.value,
					label: selectedOption.label,
					isEnableSignatureDelegation: selectedOption.isEnableSignatureDelegation,
					eroImage: selectedOption.eroImage
				}
			}, () => {
				this.props.updateEROSignatureStamp(selectedOption.value);
			})
		}
	}

	private onSignatureControlFocus = (focusedGroup: SignatureGroupType) => {
		this.setState({
			focusedGroup: focusedGroup
		});
	}

	private setEroSignerDropdownValues = (nextprops: ITabTaxReturnProps) => {
		const eroSigners: IEROSigner[] = getAllEroSigners(this.props.company,
            this.props.auth.user,
			this.props.taxReturn,
			this.props.userSettings,
			nextprops.userSignatures
		);

		const selectedEROId = this.props.taxReturn.documentSettings.documentSignatureSetting.signatureStampUser ?
			this.props.taxReturn.documentSettings.documentSignatureSetting.signatureStampUser?.userId
			: this.state.selectedEROSigner.value;
		let selectedEROSigner = eroSigners.find(x => x.value == selectedEROId) as IEROSigner;
		selectedEROSigner = selectedEROSigner ? selectedEROSigner : eroSigners.find(x => x.value === 0) as IEROSigner;

		this.setState({
			eroSigners: eroSigners,
			selectedEROSigner: selectedEROSigner,
		});
	}
};
//=============================================================================
