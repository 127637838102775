import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import * as Bs from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Modal, Button, Alert, Col, FormGroup, FormControl, FormLabel } from 'react-bootstrap';
import { ISSRUserModel, IUserModel } from '../../../Core/ViewModels/User/UserViewModel';
import { ISignatureDelegation } from './MySettings';
import { VenusNotifier } from '../../helper/VenusNotifier';

import { OverlayLoader } from '../../helper/OverlayLoader';
import * as Helper from '../../helper/HelperFunctions';

interface Props {
    show: boolean;
    onCancel(): void;
    onReset(event: any): void;
    users: ISSRUserModel[];
    delegates: ISSRUserModel[];
    onSelectFromLeft: (selected: ISSRUserModel, event: any) => void;
    onSelectFromRight: (selected: ISSRUserModel, event: any) => void;
    onDoubleClickLeft: (selected: ISSRUserModel, callback: any) => void;
    onDoubleClickRight: (selected: ISSRUserModel, callback: any) => void;
    moveRight(event: any): void;
    moveLeft(event: any): void;
    onSaveSignatureDelegates(event: any): void;
}

export class SignatureDelegation extends React.Component<Props, {}> {
    public render() {
        return <Modal className="overlayPopup ero-signature-delegation marB20" show={this.props.show} onHide={this.props.onCancel}>
            <Modal.Header closeButton>
                <Modal.Title className='custom-modal-header'>
                    <span className='fa fa-share-square' style={{ color: 'grey', marginRight: '5px' }}></span>
                    Signature Delegation
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <Col sm={12} style={{ display: 'inline-flex' }}>
                        <Col sm={5}>
                            <FormGroup style={{ display: 'block' }}>
                                <FormLabel style={{ fontWeight: 'bold' }}>Available User:</FormLabel>
                                    <FormControl
                                        id="current-users"
                                        style={{ height: '200px' }}
                                        as="select"
                                        multiple >
                                        {this.props.users
                                        .sort((a, b) => (a.firstName + a.lastName).toLowerCase().localeCompare(b.firstName + b.lastName))
                                        .map((user, index) => {
                                            return <option
                                                key={index}
                                                value={user?.id}
                                                onClick={this.props.onSelectFromLeft.bind(this, user)}
                                                onDoubleClick={() => { this.props.onDoubleClickLeft(user, this.props.moveRight); }}
                                            >{Helper.fullSSRName(user)}</option>;
                                        })}
                                    </FormControl>
                                </FormGroup>
                            </Col>
                            <Col sm={2} className="nav-button" style={{ margin: 'auto', padding: '35px' }}>
                                <Link to={'#'} onClick={this.props.moveRight}>
                                    <span className="fas fa-chevron-right" style={{ fontSize: '26px', left:'37px', top:'89px' }}></span>
                                </Link>
                                <br /><br />
                                <Link to={'#'} onClick={this.props.moveLeft}>
                                    <span className="fas fa-chevron-left" style={{ fontSize: '26px', left: '37px', top: '100px' }}></span>
                                </Link>
                            </Col>
                            <Col sm={5}>
                                <FormGroup style={{ display: 'block' }}>
                                    <FormLabel style={{ fontWeight: 'bold' }}>Signature Delegates:</FormLabel>
                                    <FormControl id="selected-users" style={{ height: '200px' }} as="select" multiple >
                                        {
                                            this.props.delegates
                                            .sort((a, b) => (a.firstName + a.lastName).toLowerCase().localeCompare(b.firstName + b.lastName))
                                            .map((user, index) => {
                                                return <option
                                                    key={index}
                                                    value={user?.id}
                                                    onClick={this.props.onSelectFromRight.bind(this, user)}
                                                    onDoubleClick={() => { this.props.onDoubleClickRight(user, this.props.moveLeft); }}
                                                >{Helper.fullSSRName(user)}</option>
                                            })
                                        }
                                    </FormControl>
                                </FormGroup>
                            </Col>
                        </Col>
                    </div>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="default"
                    className="btn-white"
                    onClick={this.props.onCancel}
                ><i className='fas fa-times'></i>Cancel</Button>
                <Button
                    variant="default"
                    className="btn-white"
                    onClick={this.props.onReset}><i className='fas fa-sync'></i>Reset
                    </Button>
                <Button
                    variant="info"
                    onClick={this.props.onSaveSignatureDelegates}
                ><i className='fas fa-save'></i>Save</Button>                   
            </Modal.Footer>
            
        </Modal>
    }
}

export default SignatureDelegation;
