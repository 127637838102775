import { addTask } from 'domain-task';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../';
import { actionTypes } from '../../types/ActionTypes';
import { RequestInUseReturnsPagesAction, ReceiveInUseReturnsPagesAction } from './KnownTypes'
import { IInUseTaxReturns } from '../../components/common/InUseTaxReturns';
import { InUseReturnsState, InUseReturnsTableModel, IInUseReturnsDictionary } from './InUseReturnsState';
import { ReceiveTaxDocumentAction, MakeAvailableTaxDocumentAction } from '../common/TaxDocumentStore';
import { API_BASE_URL } from '../../utils/constants';

type KnownAction = RequestInUseReturnsPagesAction
    | ReceiveInUseReturnsPagesAction | MakeAvailableTaxDocumentAction | ReceiveTaxDocumentAction ;

type DispatchAction = KnownAction;

export const actionCreators = {
    requestInUseReturns: (query: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        if (!getState().InUseReturnsPages[query]) {
            const fetchTask = fetch(API_BASE_URL + 'api/Reports/InUseReturns/GetInUseReturns' + query, {
                method: 'GET',
                credentials: 'include'
            })
                .then(response => response.json() as Promise<InUseReturnsTableModel>)
                .then(data => {
                    dispatch({ type: actionTypes.RECEIVE_INUSE_RETURNS_PAGES, query: query, table: data, totalRowCount: data.count });
                })
                .catch((error: any) => {
                    console.log(error);
                });
            addTask(fetchTask); // Ensure server-side prerendering waits for this to complete
            dispatch({ type: actionTypes.REQUEST_INUSE_RETURNS_PAGES, query: query });
        }
    }
};


const unloadedState: InUseReturnsState = {
    InUseReturnTableModel: {},
    query: '?',
    loading: false,
    totalRowCount: 0
} as InUseReturnsState;

const unloadedInUseTaxDocument: IInUseTaxReturns = {
    
} as IInUseTaxReturns;

export const reducer: Reducer<IInUseReturnsDictionary> = (state = { "?": unloadedState }, incomingAction) => {
    const action = incomingAction as DispatchAction;
    switch (action.type) {
        case actionTypes.REQUEST_INUSE_RETURNS_PAGES:
            if (!state[action.query]) {
                let added = { ...unloadedState } as InUseReturnsState;
                added.loading = true;
                added.query = action.query;
                let item = {} as IInUseReturnsDictionary;
                item[action.query] = added;

                return {
                    ...state,
                    ...item
                };
            } 
            break;
        case actionTypes.RECEIVE_INUSE_RETURNS_PAGES:  
            // Only accept the incoming data if it matches the most recent request. This ensures we correctly
            // handle out-of-order responses.
            if (state[action.query]) {
                let changed = state[action.query] as InUseReturnsState;
                changed.loading = false;
                changed.InUseReturnTableModel = action.table;
                changed.totalRowCount = action.totalRowCount;
                let item = {} as IInUseReturnsDictionary;
                item[action.query] = changed;
                return {
                    ...state,
                    ...item
                };
            }
            break;
        case actionTypes.MAKE_AVAILABLE_INUSE_TAX_DOCUMENT:
            return clearTaxReturns();
        case actionTypes.RECEIVE_TAX_DOCUMENT:
            return updateState(action.type, state, action);
        default:
    }
    return state;
};

function updateState(type: actionTypes, state: IInUseReturnsDictionary, action: DispatchAction): IInUseReturnsDictionary {
    let i: number = -1;
    let newState = {} as IInUseReturnsDictionary;
    let oldDocument: IInUseTaxReturns = unloadedInUseTaxDocument;
    let document: IInUseTaxReturns = {} as IInUseTaxReturns;
    let customColumn: string = "";
    let id: number = 0;
    switch (action.type) {
        case actionTypes.RECEIVE_TAX_DOCUMENT:
            document = action.taxDocument as IInUseTaxReturns;
            id = action.id;
            break;
    }

    for (var query in state) {
        i = -1;
        if (state[query].InUseReturnTableModel.inUseReturnModel) {
            state[query].InUseReturnTableModel.inUseReturnModel.forEach((value, index) => {
                if (value.id === id) {
                    i = index;
                    oldDocument = value;
                    return;
                }
            });
        }
        if (i !== -1) {
            let InUseTaxDocument: IInUseTaxReturns = action.type == actionTypes.RECEIVE_TAX_DOCUMENT ? document : oldDocument;

            let documents = [
                ...state[query].InUseReturnTableModel.inUseReturnModel?.slice(0, i),
                InUseTaxDocument,
                ...state[query].InUseReturnTableModel.inUseReturnModel?.slice(i + 1)];
            let InUseReturnTableModel: InUseReturnsTableModel = {
                count: state[query].InUseReturnTableModel.count,
                inUseReturnModel: documents
            }
            newState[query] = {
                query: query,
                InUseReturnTableModel: InUseReturnTableModel,
                totalRowCount: state[query].totalRowCount,
                loading: false
            };
        }
        else {
            newState[query] = {
                query: query,
                InUseReturnTableModel: state[query].InUseReturnTableModel,
                totalRowCount: state[query].totalRowCount,
                loading: false
            };
        }
    }
    return newState;
}

function clearTaxReturns(): IInUseReturnsDictionary {
    return {} as IInUseReturnsDictionary;   //clearing the dictionary on archive/recall
}
