import * as React from 'react';
import * as  ReactDOM from 'react-dom';
import * as Bootstrap from 'react-bootstrap';
import * as IEmailMessageStoreState from '../../../../store/settings/EmailMessageStore';

interface IEmailMessageListProps {
    emailMessages: IEmailMessageStoreState.IEmailMessageDictionary;
    selectedMessage: number;
    defaultMessage: number;
    onListItemClick: (messageId: number) => void;
}

export class EmailMessageList extends React.Component<IEmailMessageListProps, {}>{
    public render() {
        let messageId: number = this.props.selectedMessage == 0 ? parseInt(Object.keys(this.props.emailMessages)[0]) : this.props.selectedMessage
        return (
            <div>
                <ul className="list-group" >
                    {
                        Object.keys(this.props.emailMessages).map((key, index) => (
                            this.props.emailMessages[parseInt(key)]?.emailMessage &&
                            <li
                                className={this.props.emailMessages[parseInt(key)].emailMessage.id == messageId ? "custom-list-group-item-singleline custom-active" : "custom-list-group-item-singleline"}
                                title={this.props.emailMessages[parseInt(key)].emailMessage.name}
                                onClick={() => this.props.onListItemClick(parseInt(key))}
                            >
                                <div className="width100 customFlexProperty">
                                    <div style={{ textOverflow: 'ellipsis' }} className={this.props.emailMessages[parseInt(key)].emailMessage.id == this.props.defaultMessage ? "nopadding overflowHidden" : "overflowHidden"}>
                                        {this.props.emailMessages[parseInt(key)].emailMessage.name}
                                    </div>
                                    {
                                        this.props.emailMessages[parseInt(key)].emailMessage.id == this.props.defaultMessage ?
                                            <div style={{ padding: '0' }}>
                                                <span className={"badge"} style={{ marginLeft: 10, float: 'initial' }}>Default</span>
                                            </div>
                                            : <span></span>
                                    }
                                    </div>
                            </li>
                        ))
                    }
                </ul>
            </div>
        )
    }
}