import * as React from "react";
import { Card, FormGroup, Col, Button, FormLabel, Accordion } from "react-bootstrap";
import { ITaxReturn, DocumentStatus } from "../../TaxReturn";
import { InvoiceReplaceModal } from "../Invoice/InvoiceReplaceModal";
import { IPdfDocumentDictionary } from "../../../../store/pdf/PdfStore";
import { ICompanySettings } from "../../../../Core/ViewModels/Company/CompanySettingsViewModel";
import { CheckBoxComponent } from "../../../common/CheckBoxComponent";
import { IUserProfile } from "../../../navigation/profile/ProfileObjects";
import { CurrencyTextBox } from "../../../common/controls/CurrencyTextBox";
import { IBookmarkSection } from "../ProcessReturnModels";
import { IAuthState } from "../../../../store/auth/reducer";

export interface InvoiceSidebarProps {
    docId: number;
    invoiceAmount: number;
    companySettings: ICompanySettings;
    taxReturn: ITaxReturn;
    updateTaxDocument: (taxDocument: ITaxReturn) => void;
    pdfDocuments: IPdfDocumentDictionary;
    userBasicProfile: IUserProfile;
    recognisedBookmarks: IBookmarkSection[];
    auth: IAuthState;
}

export interface InvoiceSidebarStates {
    bookmark: string;
    newInvoiceAmount: number;
    open: boolean;
    actionCardShow: boolean;
    invoiceAmount: number;
    showInvoiceUpdateCard: boolean;
    isSendWithoutInvoice: boolean;
    priorToReview: boolean;
}

export class InvoiceSideBar extends React.Component<InvoiceSidebarProps, InvoiceSidebarStates> {
    constructor(props: InvoiceSidebarProps) {
        super(props);
        this.state = {
            invoiceAmount: 0,
            bookmark: "",
            newInvoiceAmount: 0,
            open: true,
            actionCardShow: false,
            showInvoiceUpdateCard: false,
            isSendWithoutInvoice: false,
            priorToReview: false
        };

        this.onInvoiceReplaceHide = this.onInvoiceReplaceHide.bind(this);
        this.onCheckboxChange = this.onCheckboxChange.bind(this);
        this.updateSendWithoutInvoice = this.updateSendWithoutInvoice.bind(this);
        this.onPriorToReviewChange = this.onPriorToReviewChange.bind(this);
    }

    onInvoiceAmountBlur = (value: number) => {
        let tempTaxReturn: ITaxReturn = { ...this.props.taxReturn };
        tempTaxReturn.invoiceAmount = value;
        this.props.updateTaxDocument(tempTaxReturn);
    };

    onCheckboxChange(event: any) {
        this.setState({ isSendWithoutInvoice: !this.state.isSendWithoutInvoice }, () => {
            this.props.taxReturn.documentSettings.documentDisplaySetting.isSendWithoutInvoice = this.state.isSendWithoutInvoice;
        });
    }

    onPriorToReviewChange(event: any) {
        let temp = this.props.taxReturn as ITaxReturn;
        this.setState({ priorToReview: !this.state.priorToReview }, () => {
            this.props.taxReturn.documentSettings.documentDisplaySetting.priorToReview = this.state.priorToReview;
        });
    }

    updateSendWithoutInvoice() {
        this.setState({ isSendWithoutInvoice: false }, () => {
            let taxDocument: ITaxReturn = { ...this.props.taxReturn };
            taxDocument.documentSettings.documentDisplaySetting.isSendWithoutInvoice = false;
            this.props.updateTaxDocument(taxDocument);
        });
    }

    showInvoiceUpdateModal = (event: any) => {
        this.setState({ showInvoiceUpdateCard: true });
    };

    onInvoiceReplaceHide() {
        this.setState({ showInvoiceUpdateCard: false });
    }

    UNSAFE_componentWillReceiveProps(nextProps: InvoiceSidebarProps) {
        if (nextProps) {
            this.setState({ invoiceAmount: nextProps.invoiceAmount });
            if (
                this.props.taxReturn.documentStatus != DocumentStatus.DELIVERED &&
                this.props.taxReturn.documentStatus != DocumentStatus.USERSIGNED &&
                this.props.taxReturn.documentSettings
            ) {
                this.setState({
                    isSendWithoutInvoice: this.props.taxReturn.documentSettings.documentDisplaySetting.isSendWithoutInvoice,
                    priorToReview: this.props.taxReturn.documentSettings.documentDisplaySetting.priorToReview
                });
            }
        }
    }

    private checkLoggedInStaffUserCanReplaceInvoice = () => {
        const user = this.props.auth?.user;
        const staffuser = user?.profile?.role?.split(",")?.find((element) => element === "Staff") ? true : false;
        const adminUser = user?.profile?.role?.split(",")?.find((element) => element === "Admin") ? true : false;
        if (staffuser && !adminUser && !this.props.companySettings.userPrivilegeSettingsModel.allowUserToReplaceInvoice) {
            return true;
        }
        return false;
    };

    public render() {
        const isLoggedInStaffUserCanReplaceInvoice = this.checkLoggedInStaffUserCanReplaceInvoice();
        const icon = this.state.open ? "fas fa-minus" : "fas fa-plus";
        return (
            <div className="right-panel">
                <Accordion defaultActiveKey="0" onSelect={() => this.setState({ open: !this.state.open })}>
                    <Card>
                        <Accordion.Toggle eventKey="0">
                            <Card.Header
                                style={{ padding: "10px" }}
                                onClick={() => {
                                    this.setState({ actionCardShow: !this.state.actionCardShow });
                                }}
                            >
                                <Card.Title as="h3">
                                    <i className={icon}></i>Action
                                </Card.Title>
                            </Card.Header>
                        </Accordion.Toggle>
                        <InvoiceReplaceModal
                            docId={this.props.docId}
                            onShow={this.state.showInvoiceUpdateCard}
                            onHide={this.onInvoiceReplaceHide}
                            taxReturn={this.props.taxReturn}
                            updateTaxDocument={this.props.updateTaxDocument}
                            invoiceAmount={this.props.invoiceAmount}
                            pdfDocuments={this.props.pdfDocuments}
                            updateSendWithoutInvoice={this.updateSendWithoutInvoice}
                        />
                        <Accordion.Collapse eventKey="0">
                            <Card.Body>
                                <FormGroup className="row">
                                    <Col className="text-left" sm={3} as={FormLabel}>
                                        Invoice Amount
                                    </Col>
                                    <Col sm={9} className="zeroborderRadius">
                                        <CurrencyTextBox
                                            value={this.state.invoiceAmount}
                                            placeholder="Enter Amount"
                                            onBlur={this.onInvoiceAmountBlur.bind(this)}
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup className="row">
                                    <Col className="text-left" sm={12}>
                                        <CheckBoxComponent
                                            id={"AA2BE6FF-A2C0-4A3B-B704-3DC00EDE9D38"}
                                            text={"Do not send an invoice with this return"}
                                            checked={this.state.isSendWithoutInvoice}
                                            datatestAuto={"1189D6C6-93FB-4846-8D37-859AC9D833E5"}
                                            onChange={this.onCheckboxChange}
                                            disabled={
                                                !this.props.taxReturn?.documentSettings?.documentDisplaySetting
                                                    .isEnableSendWithoutInvoice
                                            }
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup className="row">
                                    <Col className="text-left" sm={12}>
                                        <CheckBoxComponent
                                            id={"E5D2243E-6464-4FBC-93C2-497213766727"}
                                            text={"Require taxpayer to pay invoice prior to reviewing their tax return"}
                                            checked={this.state.priorToReview}
                                            datatestAuto={"FBD31CA5-2A45-4487-9330-AC5CFF3D3B59"}
                                            onChange={this.onPriorToReviewChange}
                                            disabled={
                                                !this.props.companySettings.taxpayerPaymentSettings
                                                    .allowUserToSendReturnWithoutPayment
                                            }
                                        />
                                    </Col>
                                </FormGroup>
                                {this.props.taxReturn?.documentSettings &&
                                this.props.taxReturn.documentSettings.documentDisplaySetting.isEnableInvoice ? (
                                    <FormGroup className="row">
                                        <Col className="text-left" sm={3} as={FormLabel}></Col>
                                        <Col sm={9}>
                                            <Button
                                                variant="info"
                                                onClick={this.showInvoiceUpdateModal}
                                                disabled={isLoggedInStaffUserCanReplaceInvoice}
                                            >
                                                <i className="fa fa-upload"></i> Replace Invoice
                                            </Button>
                                        </Col>
                                    </FormGroup>
                                ) : (
                                    <div></div>
                                )}
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
            </div>
        );
    }
}
