import * as React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { ICustomColumn } from '../../settings/GeneralSettings';
import * as DeliveredReturnsState from '../../../store/reports/DeliveredReturnsState';
import { LoadingOverlay, Loader } from 'react-overlay-loader';
import Select from 'react-select';
import { prepareCustomColumnValues } from '../../helper/HelperFunctions';
import { DeliveredReturnsTable } from '../DeliveredReturns/DeliveredReturnsTable';
import { DeliveredReturnsConstants, DeliveredReturnsTableConstants, SignatureStatusIds } from '../../helper/Constants';


export class StatusFilter extends React.Component<any, any> {
    statusFilter: any;
    state = {
        statusDropdownClass: 'filter select-filter form-control placeholder-selected'
    }

    setStatusFilterRef = (stausFilterRef: any) => {
        this.statusFilter = stausFilterRef;
    };

    cleanFiltered() {
        this.statusFilter.selectedIndex = 0;
        this.props.filterHandler();
    }

    private onStatusChange = (event: any) => {
        this.props.filterHandler(event.target.value, 'CustomFilter');
        this.setState({
            statusDropdownClass: event.target.value === '0' ?
                'filter select-filter form-control placeholder-selected' :
                'filter select-filter form-control'
        });
    }


    public render() {
        return (<select className={this.state.statusDropdownClass}
            ref={(instance: any) => { this.setStatusFilterRef(instance); }} onChange={this.onStatusChange}>
            <option value={SignatureStatusIds.None} style={{ color: 'lightgrey', fontStyle: 'italic' }}>Select Status...</option>
            <option value={SignatureStatusIds.AwaitingESign}>AWAITING E-SIGN</option>
            <option value={SignatureStatusIds.AwaitingUpload}>AWAITING UPLOAD</option>
            <option value={SignatureStatusIds.ESigned}>E-SIGNED</option>
            <option value={SignatureStatusIds.ManuallySigned}>MANUALLY SIGNED</option>
            <option value={SignatureStatusIds.SignedAndESigned}>SIGNED AND E-SIGNED</option>
            <option value={SignatureStatusIds.Uploaded}>UPLOADED</option>
        </select>);
    }
}













