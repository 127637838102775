import * as React from "react";

interface PriceTagProps {
    name: string;
    direction: string;
    color: string
}

export class PriceTag extends React.Component<PriceTagProps>{
    render() {
        let className = "right-tag";
        if (this.props.direction === "left") {
            className = "left-tag";
        }
        return (<span className={"tags " + className + " " + this.props.color}>{this.props.name}</span>)
    }
}