import { fetch, addTask } from 'domain-task';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../';
import { actionTypes } from '../../types/ActionTypes';
import { IAccountViewModel, intialAccountDetailsViewModel } from '../../Core/ViewModels/PaymentAccount/PaymentAccountViewModel'
import { handleResponse } from '../Library';
import { StatusType, NotificationAction } from '../common/NotificationStore';
import { StripeAccountConstants } from '../../components/helper/Constants';
import { API_BASE_URL } from '../../utils/constants';

export interface IAccountModel {
    accountDetails: IAccountViewModel
};

export interface RequestAccountDetails {
    type: actionTypes.REQUEST_ACCOUNT_DETAILS;
}

export interface ResponseAccountDetails {
    type: actionTypes.RESPONSE_ACCOUNT_DETAILS;
    accountDetails: IAccountViewModel
}

type KnownAction =
    RequestAccountDetails |
    ResponseAccountDetails |
    NotificationAction
    ;

export const unloadedState: IAccountModel =
{
    accountDetails: intialAccountDetailsViewModel
};

export const actionCreators = {

    onRegisterationSuccess: (code: string, state: string, callback: (isSuccess: boolean) => void) => (dispatch, getState) => {
        let fetchTask = fetch(API_BASE_URL + 'StatusUpdate/RegisterationSuccess?code=' + code + '&state=' + state,
            { credentials: 'include', method: 'GET' })
            .then(handleResponse)
            .then(response => response as Promise<boolean>)
            .then(data => {
                callback(data);
            }).catch(function (error) {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: StripeAccountConstants.accountRegistrationFailed,
                    statusType: StatusType.Error
                });
            });
        addTask(fetchTask);
    },

    requestAccountDetails: (id: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let state = getState();
        let fetchTask = fetch(API_BASE_URL + 'api/AccountDetails/GetAccountDetails/' + id, { credentials: 'include', method: 'GET' })
            .then(handleResponse)
            .then(response => response as Promise<IAccountViewModel>)
            .then(data => {
                dispatch({
                    type: actionTypes.RESPONSE_ACCOUNT_DETAILS, accountDetails: data
                });
            }).catch(function (error) {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: StripeAccountConstants.accountFetchingFailed,
                    statusType: StatusType.Error
                });
            });
        addTask(fetchTask);
    },
    getStore: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
    }

}

export const reducer: Reducer<IAccountModel> = (state: IAccountModel = unloadedState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;

    switch (action.type) {
        case actionTypes.RESPONSE_ACCOUNT_DETAILS:
            return {
                accountDetails: action.accountDetails
            } as IAccountModel;
        default:
            return unloadedState;
    }
}