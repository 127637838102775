import { Guid } from '../../Core/Utilities/Guid';

import {
    ClientTypes, SignatureType, ITaxReturn, IPaperReturnSettings, KBAMode,
    DeliveryMode, PaperReturnDeliveryType, DocumentGroups, initialTaxReturnState
} from '../../components/common/TaxReturn';
import { IUserModel, IUserBaseModel } from '../../Core/ViewModels/User/UserViewModel';
import { DefaultSenderInfo } from '../../components/navigation/profile/MySettings';
import { MessageDetails } from '../../Core/ViewModels/CompanySettings/SavedMessageViewModel';
import { IPaymentVoucherNotificationSettings, ISigningReminderNotificationSettings, IK1InfoSettings } from '../../Core/ViewModels/Company/CompanySettingsViewModel'

export interface IGroupLevelReturnDictionary {
    [index: number]: {
        groupLevelReturnsData: IGroupedReturnsModel,
        isLoading: boolean,
        error: boolean,
        message: string
    }
}
export interface IGroupedReturnsModel {
    groupInfo: IGroupInfo,
    taxReturns: ITaxDocument[]
}

export interface IGroupedReturnsClientInfoModel {
    controllerInfo: IControllerInfo;
    taxDocument: ITaxReturn;
    isEditDeliveredClientInfoRequest: boolean;
    canSendControllerEmailUpdateMail: boolean;
}

export interface IPrintForPaperDeliveryViewModel {
    groupedReturnsModel: IGroupedReturnsModel,
    paperReturnDeliveryType: PaperReturnDeliveryType;
    orderList: DocumentGroups[];
    isNotifyUserEnabled: boolean;
    isSaveAsDefaultOrder: boolean;
}

export interface IGroupDeliverySettings {
    deliverTo: ClientTypes,
    contactPerson: number,
    preparerMessage: MessageDetails,
    sender: DefaultSenderInfo,
    notifyUser: number,
    manualAddressId: number,
    integrationSettings: string[],
    reportingEngine: string,
    allowUserToDistributeK1: boolean,
    isDirectDeliveryToTaxCaddy: boolean,
    isByPassManualVerification: boolean,
    deliveryMode: DeliveryMode,
    paperReturnSettings: IPaperReturnSettings,
    watermarkId: number,
    selectedTaxDocumentId: number[],
    voucherPaymentNotifyUser: number,
    paperFilePreparerMessage: MessageDetails,
}

export interface IGroupSignatureSettings {
    signatureFormSelectionType: SignatureType,
    knowledgeBasedAuthentication: boolean,
    signatureStampUser: IUserBaseModel,
    kbaMode: KBAMode
}

export interface IGroupNotificationSettings {
    paymentVoucherNotificationSettingsModel: IPaymentVoucherNotificationSettings,
    signingReminderNotificationSettingsModel: ISigningReminderNotificationSettings,
    notifyUser: IUserBaseModel,
    paymentNotifyUser: IUserBaseModel;
}

export interface IGroupRetentionSettings {
    retentionPeriod: number;
    allowOverride: boolean;
}
export interface IGroupK1Settings {
    k1PartnerInfoSettings: IK1InfoSettings;
    k1ClientInfoSettings: IK1InfoSettings;
}

export interface IGroupSettingsModel {
    groupId: number,
    groupDeliverySettings: IGroupDeliverySettings,
    groupSignatureSettings: IGroupSignatureSettings,
    groupNotificationSettings: IGroupNotificationSettings,
    groupRetentionSettings: IGroupRetentionSettings,
    groupK1Settings: IGroupK1Settings
}

export interface IGroupAccessViewModel {
}

export interface ITaxDocument {
}

export interface IGroupInfo {
    id: number,
    name: string,
    createdOn: Date,
    createdBy: number,
    isDeleted: boolean,
    groupSettings: IGroupSettingsModel,
    controllerInfo: IControllerInfo,
    groupAccess: IGroupAccessViewModel,
    isModified?: boolean
}

export interface IControllerInfo {
    groupId: number;
    name: string;
    emailAddress: string;
    ssn: string;
    clientGuid: Guid;
    mobileNumber: string;
    countryCode: string;
}

export interface DeliverOptionModel {
}
export interface SelectOptions {
    value: any,
    label: string
}

export const initialGroupRetrunClientInfo: IGroupedReturnsClientInfoModel = {

    controllerInfo: {
        clientGuid: Guid.newGuid(),
        countryCode: "",
        emailAddress: "",
        groupId: 0,
        mobileNumber: "",
        name: "",
        ssn: ""
    },
    isEditDeliveredClientInfoRequest: false,
    taxDocument: initialTaxReturnState
}

export const initialControllerInfo: IControllerInfo = {
    clientGuid: new Guid(Guid.empty),
    countryCode: '',
    emailAddress: '',
    groupId: 0,
    mobileNumber: '',
    name: '',
    ssn:''
};
