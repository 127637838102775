import * as React from 'react';
import { Col, FormLabel, FormControl, Card, Row, Accordion, Button } from 'react-bootstrap';
import Select from 'react-select';
import { ITaxReturn, EngagementType, isJointFilingAllowed } from '../../TaxReturn';
import * as TaxDocument from '../../../common/TaxReturn';
import { IBusinessReturnsState } from '../../../../store/settings/BusinessReturnsStore';
import * as Constants from '../../../helper/Constants';
import { GetFileMagicNumber, GetCountryCode } from '../../../helper/HelperFunctions';
import { getFileExtension, isValidSSN, validatePdfFile } from '../../../helper/Validations';
import { CompanySettingsConstants, ValidationContants } from '../../../helper/Constants';
import { VenusNotifier } from '../../../helper/VenusNotifier';
import { CheckBoxComponent } from '../../CheckBoxComponent';
import { logger } from '../../../../routes/LoggedIn';

export interface IK1ActionProps {
    previewDocument: () => void,
    getK1InstructionFileName: (guid: string, taxYear: number, documentId: number, engagementType: TaxDocument.EngagementType) => void;
    fileName: string;
    taxReturn: TaxDocument.ITaxReturn;
    restoreK1Instruction: (fileName: string, documentId: number) => void;
    K1InstructionModified: (verifyK1Replaced: boolean, isK1Restored: boolean, isK1Replaced?: boolean) => void;
    businessInstructionStore: IBusinessReturnsState;
    disableSaveClose: (option: boolean) => void;
    uploadK1Instruction: (engagementType: TaxDocument.EngagementType, documentGuid: string, taxYear: number, fileName: string, documentId: number, callback?: () => void) => void;
    handleK1ReminderActionChange: (event: any) => void;
    isK1RemindersAllChecked: boolean;
    isDefaultK1Instruction: boolean;
}

export interface IK1ActionStates {
    
    open: boolean;
    fileName: string;
    readyToRestore: boolean;
    cachedPrevProp: IK1ActionStates;
    files: any;
    isK1RemindersEnabled: boolean;
  

}
const isEqual = require("react-fast-compare");


export class K1Actions extends React.Component<IK1ActionProps, IK1ActionStates>
{
    constructor(props: IK1ActionProps) {
        super(props);
        this.state  = {
            open: true,
            fileName: "",
            readyToRestore: false,
            cachedPrevProp: {} as IK1ActionStates,
            files: {},
            isK1RemindersEnabled: true
        };
        this.fileInput = null;
    }
    fileInput: any;
    componentDidMount() {
        if (this.props.fileName == undefined || this.props.fileName == "") {
            this.props.getK1InstructionFileName(this.props.taxReturn.documentGuid, this.props.taxReturn.taxYear, this.props.taxReturn.id, this.props.taxReturn.engagementType)
        }
        if (this.state.fileName == ValidationContants.NoContentAvailable) {
            VenusNotifier.Warning(ValidationContants.K1InstructionNotAVailable, "Warning");
        }
    }
    static getDerivedStateFromProps(nextProps: IK1ActionProps, prevState: IK1ActionStates) {
       
        let readyToRestore = prevState.readyToRestore;
        let fileName = prevState.fileName;
        let prevProp = prevState.cachedPrevProp;

        if ((nextProps.fileName != "" && (prevState.fileName == "" || prevState.fileName == undefined))) {

            let instructionFileName = "";
            if (!nextProps.businessInstructionStore.isLoading && nextProps.businessInstructionStore.instructions) {
                const instruction = nextProps.businessInstructionStore.instructions.find(x => x.taxYear == nextProps.taxReturn.taxYear && EngagementType[x.engagementType] == nextProps.taxReturn.engagementType.toString());
                if (instruction) {
                    instructionFileName = instruction.fileName;
                }
            }

            if (nextProps.fileName == Constants.PredefinedFileName.FileNotFound) {
                fileName = instructionFileName;
                let isK1Restored: boolean = true, verifyK1Replaced: boolean = false;
                nextProps.K1InstructionModified(verifyK1Replaced, isK1Restored);
                readyToRestore = false;
            }
            else {
                fileName = nextProps.fileName;
                readyToRestore = !nextProps.isDefaultK1Instruction;
            }
        }

        if (!isEqual(prevProp, nextProps)) {
            return {
                fileName: fileName,
                readyToRestore: readyToRestore,
                cachedPrevProp: nextProps
            }
        }

        return {
            fileName: fileName,
            cachedPrevProp: nextProps,
            readyToRestore: readyToRestore
        }

    }
    private handelChangeFile = (input: any) => {
        this.fileInput = input;
    }
    getBusinessInstructionFileName(year: number, type: EngagementType) {

        if (!this.props.businessInstructionStore.isLoading && this.props.businessInstructionStore.instructions) {
            const instruction = this.props.businessInstructionStore.instructions.find(x => x.taxYear == year && EngagementType[x.engagementType] == type.toString());
            if (instruction) {
                return instruction.fileName;
            }
        }
        return "";

    }

    private restoreK1Instruction = (type: EngagementType, taxYear: number) => {
        this.setState({
            fileName: this.getBusinessInstructionFileName(taxYear, type),
            readyToRestore: false
        }, () => this.props.restoreK1Instruction(this.state.fileName, this.props.taxReturn.id));
        let isK1Restored: boolean = true, verifyK1Replaced: boolean = false, isK1Replaced: boolean = false;
        this.props.K1InstructionModified(verifyK1Replaced, isK1Restored, isK1Replaced);
        this.fileInput.value = null;
        logger.trackTrace(`TabK1 --> restoreK1Instruction executes`, { 'DocumentId': this.props.taxReturn.id?.toString(), 'DocumentGuid': this.props.taxReturn.documentGuid });
    }
    private disableSaveClose = () => {
        this.props.disableSaveClose(false);
    }
    private uploadK1Instruction = (files: any) => {
        GetFileMagicNumber(files[0]).then((result) => {
            if (validatePdfFile(getFileExtension(files[0].name), result)) {
                this.props.disableSaveClose(true);
                this.setState({
                    fileName: files[0].name,
                    files: files, readyToRestore: true
                }, () => {
                    let isK1Restored: boolean = false, verifyK1Replaced: boolean = true, isK1Replaced: boolean = true;
                    this.props.K1InstructionModified(verifyK1Replaced, isK1Restored, isK1Replaced)
                    this.props.uploadK1Instruction(this.props.taxReturn.engagementType,
                        this.props.taxReturn.documentGuid,
                        this.props.taxReturn.taxYear, files[0], this.props.taxReturn.id, this.disableSaveClose);
                    logger.trackTrace(`TabK1 --> uploadK1Instruction executes`, { 'DocumentId': this.props.taxReturn.id?.toString(), 'DocumentGuid': this.props.taxReturn.documentGuid })
                })

            }
        });
    }
    

    public render() {
        
        let icon = this.state.open ? "fas fa-minus" : "fas fa-plus";

        return (
            <Accordion defaultActiveKey="0" onSelect={() => this.setState({ open: !this.state.open })}>
                <Card>
                    <Accordion.Toggle eventKey="0">
                        <Card.Header style={{ padding: "10px" }}>
                            <Card.Title as="h3" style={{ marginBottom: '0px' }}>
                                <i className={icon}></i>Actions (All K-1s with the Tax Return)
							</Card.Title>
                        </Card.Header>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                        <Card.Body>
                            <div>
                                <div className="col-sm-12 marB15">
                                    <a className="cursor-pointer" style={{ textDecoration: 'underline' }}
                                        onClick={this.props.previewDocument}> {this.state.fileName}</a>
                                </div>
                            </div>
                            <div>
                                <input
                                    id="files"
                                    type="file"
                                    style={{ visibility: "hidden" }}
                                    ref={(input) => { this.handelChangeFile(input); }}
                                    onChange={(e) => this.uploadK1Instruction(e.target.files)}
                                    data-test-auto="5AE0B7AE-20B1-4286-959A-B0057A334988"
                                />
                                {(this.state.readyToRestore) ?
                                    <div className="col-sm-12 padLR-0" style={{ float: 'left', paddingBottom: '10px' }}>
                                        <Button variant="default"
                                            className="btn btn-white" onClick={() => document.getElementById('files')!.click()}>
                                            <i className='fas fa-upload'></i>Replace</Button>
                                        <Button variant="default"
                                            className="btn btn-white" style={{ paddingRight: '6px', float: 'right' }}
                                            onClick={() => this.restoreK1Instruction(this.props.taxReturn.engagementType, this.props.taxReturn.taxYear)}>
                                            <i className='fas fa-redo-alt'></i>Restore Default
                                                </Button>
                                    </div> :
                                    <div className="col-sm-12 padLR-0" style={{ float: 'left', textAlign: 'center', paddingBottom: '10px' }}>
                                        <Button variant="default"
                                            className="btn btn-white" onClick={() => document.getElementById('files')!.click()}
                                            >
                                            <i className='fas fa-upload'></i>Replace</Button>
                                    </div>
                                }
                                {/* Commented as a part US: 103985
                                 <div className="text-left marB5">
                                    <CheckBoxComponent
                                        text="Do not send Partner Information Update Email"
                                        id={"id"}
                                        checked={!this.props.isK1RemindersAllChecked}
                                        onChange={(e: any) => this.props.handleK1ReminderActionChange(e)}
                                    />
                                </div> */}
                            </div>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        );
    }
}
