import * as  React from "react";



interface UploadDocumentModalWarningProps{
    warningMessages: string[];
}




const UploadDocumentModalWarning: React.FC<UploadDocumentModalWarningProps> = ({warningMessages}) => {


    return (<>
        <div className="uploadpopupinfo" style={{ marginTop: "6px" }}>
            <i className="fas fa-info-circle"></i> Please Note:
                            <ul style={{ marginLeft: "40px", marginBottom:"0px" }}>
                {warningMessages.map(message => {

                    return <li>{message}</li>
                })}
           
            </ul>

        </div>
    </>)
}


export default UploadDocumentModalWarning;
