import * as React from 'react';
import { ICompanySettings } from '../../../Core/ViewModels/Company/CompanySettingsViewModel';
import { CheckBoxComponent } from '../../common/CheckBoxComponent';

interface IEsignatureForBusinessReturn {
    companySettings: ICompanySettings
    handleEsignatureForBusinessReturn(event: any): void;
    disableESignature: boolean;
}
export class EsignatureForBusinessReturns extends React.Component<IEsignatureForBusinessReturn> {
    public render() {
        let businessReturn: any = <span>Enable E-Signatures for Business & Trust Returns:<b> IMPORTANT NOTE </b> : The IRS currently does not allow the use of E-Signatures on entity or trust e-file authorization forms.</span>
        return <div id="divKnowledgeBasedAuthentication" className="new-main-content">
            <h3>Business & Trust Returns</h3>
            <div className="marL20">
                <CheckBoxComponent
                    id={"businessReturn"}
                    text={businessReturn}
                    onChange={this.props.handleEsignatureForBusinessReturn}
                    checked={this.props.companySettings.signatureSettingsModel ?
                        this.props.companySettings.signatureSettingsModel.enableEsignatureForBusinessReturns : false}
                    disabled={this.props.disableESignature}
                    datatestAuto={"E6FA4253-FFB8-4B4A-83C6-05390E055504"}
                />
                
            </div>
        </div>;
    }
}

export default EsignatureForBusinessReturns