import { VoucherBuilder } from './VoucherBuilder';
import { VoucherProxy } from './VoucherServerProxy';
import { IVoucher, ITaxReturn } from '../../../common/TaxReturn';

export class VoucherScript {

    private builder: VoucherBuilder = new VoucherBuilder();
    private proxy: VoucherProxy = new VoucherProxy();
    private _voucherData: IVoucher;
    private _taxReturn: ITaxReturn;

    constructor(voucherData: IVoucher, taxReturnData: ITaxReturn) {
        this._voucherData = voucherData;
        this._taxReturn = taxReturnData;
    }

    public saveCustomVoucher = (isAdd: boolean): Promise<boolean> => {
        return new Promise((resolve, reject) => {
            this.prepareVoucher(this._voucherData).then((PDFStream: any) => {
                this.proxy.saveCustomVoucher(this._voucherData, this._taxReturn, PDFStream, isAdd).then((result) => { resolve(result) });
            }, function (err) { });
        });
    }

    public prepareVoucher = (voucherData: IVoucher) => {
        return new Promise((resolve, reject) => {
            this.builder.GetPdf(voucherData).then(function (data: any) {
                resolve(data);
            }, function (err: any) {
                reject(err);
            });
        });
    }
}
