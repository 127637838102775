import { IVoucherTaxFilingDate } from "@Core/ViewModels/Voucher/VoucherDefaultDateViewModel";
import { VoucherTypes } from "../common/TaxReturn";

export const filterTaxFilingDates = (taxFilingDates: IVoucherTaxFilingDate[], formName: string, paymentType: VoucherTypes): IVoucherTaxFilingDate | undefined => {
    const voucherName = (taxFilingDate: IVoucherTaxFilingDate) => {
        if (paymentType == VoucherTypes.EstimatedVoucher) return taxFilingDate.esVoucherName
        return taxFilingDate.voucherName
    }

    if (!taxFilingDates || !taxFilingDates.length) return undefined

    const matchingFormNames = taxFilingDates.filter(x => voucherName(x) == formName);
    const noFormNames = taxFilingDates.filter(x => !voucherName(x))
    
    if (matchingFormNames.length == 1) {
        return matchingFormNames[0]
    }
    if (noFormNames.length > 0) {
        return noFormNames[0]
    }

    console.warn(`No tax filing date found for ${formName}`)
    return undefined
}