import { ApplicationState } from "../../../store";
import * as CompanyStore from '../../../store/company/CompanyStore';
import * as CustomColumnStore from '../../../store/common/CustomColumnStore';
import { GeneralSettings } from "../GeneralSettings";
import { connect } from 'react-redux';
import { stat } from "fs";
import * as UserSettingsStore from '../../../store/userManagement/UserSettingStore';
import * as UserStore from '../../../store/userManagement/UserStore';
import * as WatermarkStore from '../../../store/settings/WatermarkStore';
import * as ApplicationHelptextStore from '../../../store/common/ApplicationHelptextStore';



export default connect(
    (state: ApplicationState) => ({
        userStore: state.usersList,
        companyData: state.companyData,
        customColumnData: state.customColumn,
        watermarkData: state.watermarkStore,
        helptexts: state.Helptexts,
    }),
    {
        ...UserStore.actionCreators,
        ...CompanyStore.actionCreators,
        ...CustomColumnStore.actionCreators,
        ...UserSettingsStore.actionCreators,
        ...WatermarkStore.actionCreators,
        ...ApplicationHelptextStore.actionCreators
    }
)(GeneralSettings as any);