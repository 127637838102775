import * as React from 'react';
import * as Bs from 'react-bootstrap';
import { ICompanySettings } from '../../../Core/ViewModels/Company/CompanySettingsViewModel';
import ToggleSwitch from '../../common/ToggleSwitch';


interface SignerDelegationProps {
    companySettings: ICompanySettings;
    handleEntityReturnSignerDelegation(event: any): void;
    handleIndividualReturnSignerDelegation(event: any): void;
}

export class SignerDelegation extends React.Component<SignerDelegationProps, {}>{
    private _entityReturnRef: any;
    private _individualReturnRef: any;

    public render() {
        return <div className="new-main-content">
            <h3>Signer Delegation</h3>

            <div className="marL20">
                <Bs.Row>
                    <Bs.Col className="text-left" sm={2} as={Bs.FormLabel} style={{ width: "17.7%", paddingTop: "3px" }}>
                        Individual (1040) Returns
                    </Bs.Col>
                    <Bs.Col sm={4} md={3} lg={2}>
                        <ToggleSwitch ref={(ref: any) => { this._individualReturnRef = ref }}
                            switched={this.props.companySettings.signatureSettingsModel.enableIndividualReturnSignerDeligation}
                            handleChange={this.props.handleIndividualReturnSignerDelegation} />
                    </Bs.Col>
                    <Bs.Col sm={6} md={7} lg={8}>
                    </Bs.Col>
                </Bs.Row>

                <Bs.Row>
                    <Bs.Col className="text-left" sm={2} as={Bs.FormLabel} style={{ width: "17.7%", paddingTop: "3px" }}>
                        Entity (1041, 1065, 1120, 1120S, 990) Returns
                    </Bs.Col>
                    <Bs.Col sm={4} md={3} lg={2}>
                        <ToggleSwitch ref={(ref: any) => { this._entityReturnRef = ref }}
                            switched={this.props.companySettings.signatureSettingsModel.enableEntityReturnSignerDeligation}
                            handleChange={this.props.handleEntityReturnSignerDelegation} />
                    </Bs.Col>
                    <Bs.Col sm={6} md={7} lg={8}>
                    </Bs.Col>
                </Bs.Row>
            </div>
        </div>
    }
}
