import { connect } from 'react-redux';
import { ApplicationState } from '../../../store';
import { RegistrationInfo } from './RegistrationInfo';
import * as PaymentAccountStore from '../../../store/PaymentAccount/PaymentAccountStore';

export default connect(
    (state: ApplicationState) => ({ }),
    {
        ...PaymentAccountStore.actionCreators,
    }
)(RegistrationInfo as any);