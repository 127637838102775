import React from "react";
import { IDragAndDropData } from "../DragAndDrop.model";
import GripVertical from "src/assets/images/GripVertical";
import styles from "./DraggableList.module.scss";

interface DraggableListProps {
    listData: IDragAndDropData[];
    displayError?: boolean;
    errorText?: string;
    disabledTitle?: string;
    dndUniqueID?: string;
    selectedList: any;
    setSelectedList: (selectedList: any) => void;
    disableDragAndDrop?: boolean;
    disableDestinationItems?: number[];
}

function DraggableList(props: DraggableListProps) {
    const {
        listData = [],
        displayError = false,
        errorText = "",
        disabledTitle = "",
        dndUniqueID,
        selectedList,
        setSelectedList,
        disableDragAndDrop,
        disableDestinationItems
    } = props;

    const dragStart = (e: any, data: any) => {
        const newSelectedList = [...selectedList, data];
        setSelectedList([...newSelectedList]);
        e.dataTransfer.setData("text/plain", JSON.stringify(newSelectedList));
        e.dataTransfer.setData("dndUniqueID", dndUniqueID);
    };

    const dragEnd = () => {
        setSelectedList([]);
    };

    const handleClick = (data: any) => {
        let newSelectedList: any = [];
        if (selectedList.findIndex((listDatum: any) => listDatum.value === data.value) === -1) {
            newSelectedList = [...selectedList, data];
        } else {
            newSelectedList = selectedList.filter((datum: any) => datum.value !== data.value);
        }
        setSelectedList([...newSelectedList]);
    };

    const sortAlphabetically = (a: IDragAndDropData, b: IDragAndDropData) => {
        if (a.title.toLowerCase() < b.title.toLowerCase()) return -1;
        if (a.title.toLowerCase() > b.title.toLowerCase()) return 1;
        return 0;
    };

    const putEveryoneOrAllAtTop = (data: IDragAndDropData[]) => {
        let indexOfEveryoneOrAll = data.findIndex((each: any) => {
            return each.value === 0;
        });
        if (indexOfEveryoneOrAll > 0) {
            const everyoneOrAll = data[indexOfEveryoneOrAll];
            data.splice(indexOfEveryoneOrAll, 1);
            data.splice(0, 0, everyoneOrAll);
        }
        return data;
    };

    const checkDisablity = (datum: IDragAndDropData) => disableDestinationItems?.includes(Number(datum.value));

    return (
        <div className={styles.draggableListContainer}>
            {displayError ? (
                <div className={styles.error_div}>{errorText}</div>
            ) : (
                putEveryoneOrAllAtTop(listData.sort(sortAlphabetically)).map((datum: IDragAndDropData, index: number) => {
                    const isDisabled = checkDisablity(datum) || disableDragAndDrop;
                    return (
                        <div
                            className={`${styles.draggableListCard} ${
                                selectedList.findIndex((listItem: any) => datum.value === listItem.value) !== -1
                                    ? styles.selectedCard
                                    : ""
                            } ${isDisabled ? styles.disabled : ""} `}
                            onDragStart={(e) => dragStart(e, datum)}
                            onDragEnd={() => dragEnd()}
                            onClick={() => (isDisabled ? null : handleClick(datum))}
                            data-test-id={`${datum.title}-${datum.value}`}
                            key={`${datum.value}${index}`}
                            draggable={isDisabled ? false : true}
                            title={isDisabled ? disabledTitle : datum.title}
                            data-dnduniqueid={dndUniqueID}
                        >
                            <div className={styles.gripContainer}>
                                <GripVertical />
                            </div>
                            <div className={styles.contentContainer} dangerouslySetInnerHTML={{ __html: datum.name }} />
                        </div>
                    );
                })
            )}
        </div>
    );
}

export default DraggableList;
