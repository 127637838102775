import { addTask } from 'domain-task';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../';
import { IUserProfile, initialUserProfile } from '../../components/navigation/profile/ProfileObjects';
import { actionTypes } from '../../types/ActionTypes';
import { handleResponse } from '../Library';
import { UserManagementConstants } from '../../components/helper/Constants';
import { StatusType, NotificationAction } from '../common/NotificationStore';
import { container } from '../../Startup/inversify.config';
import { ILocalStore } from '../../Core/Utilities/LocalStore';
import { TYPES } from '../../Startup/types';
import { API_BASE_URL } from '../../utils/constants';

export interface IUserProfileData {
    user: IUserProfile;
    isLoading: boolean;
}

export interface UserProfileState {
    user: IUserProfile;
}

interface RequestUserProfileAction {
    type: actionTypes.REQUEST_USER_PROFILE;
}

export interface ReceiveUserProfileAction {
    type: actionTypes.RECEIVE_USER_PROFILE;
    user: IUserProfile;
}

interface UpdateUserProfileAction {
    type: actionTypes.UPDATE_USER_PROFILE;
    userDetails: IUserProfile;
}


type KnownAction = RequestUserProfileAction | ReceiveUserProfileAction | UpdateUserProfileAction | NotificationAction;

type DispatchActions = RequestUserProfileAction | ReceiveUserProfileAction | UpdateUserProfileAction ;

const localStorage = container.get<ILocalStore>(TYPES.ILocalStore);

export const actionCreators = {
    requestUserProfile: (reload: boolean = false): AppThunkAction<KnownAction> => (dispatch, getState) => {
        if (reload || !getState().userProfile.email) {
            const fetchTask = fetch(API_BASE_URL + 'api/MyAccount/MyAccountDetails', {
                method: 'GET',
                credentials: 'include'
            })
                .then(handleResponse)
                .then(response => response as Promise<IUserProfile>)
                .then(data => {
                    if (data) {
                        dispatch({ type: actionTypes.RECEIVE_USER_PROFILE, user: data });
                    }
                })
                .catch(function (error) {
                });
            addTask(fetchTask); // Ensure server-side prerendering waits for this to complete
            dispatch({ type: actionTypes.REQUEST_USER_PROFILE });
        }
    },

    saveMyAccount: (userInfo: IUserProfile, existingEmailAddress: string, oldPassword: string, notification: boolean,
        onResponse: (response: any, error: any) => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
         
        let options: any = {
            method: 'PUT',
            credentials: "include",
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8',
                'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
            },
            body: '{profile:' + JSON.stringify(userInfo) +
                ',existingEmailAddress:' + JSON.stringify(existingEmailAddress) +
                ',oldPassword:' + JSON.stringify(oldPassword) + '}'
        };

        const fetchTask = fetch(API_BASE_URL + 'api/MyAccount/', options)
            .then(response => response.json())
            .then(response => {
                dispatch({
                    type: actionTypes.UPDATE_USER_PROFILE, userDetails: userInfo
                });
                if (onResponse) {
                    onResponse(response,null);
                }
                if (notification) {
                    dispatch({
                        type: actionTypes.NOTIFICATION, statusMessage: UserManagementConstants.UserUpdationSuccess,
                        statusType: StatusType.Success
                    });
                }
            }).catch(error => {
                if (onResponse) {
                    onResponse(null, error);
                }
                if (notification) {
                    dispatch({
                        type: actionTypes.NOTIFICATION, statusMessage: UserManagementConstants.SavingUserError,
                        statusType: StatusType.Error
                    });
                }
            });
        addTask(fetchTask);
        dispatch({ type: actionTypes.REQUEST_USER_PROFILE });
        }
};

const unloadedState: IUserProfile = initialUserProfile;

export const reducer: Reducer<IUserProfile> = (state = unloadedState, incomingAction) => {
    const action = incomingAction as DispatchActions;
    switch (action.type) {
        case actionTypes.REQUEST_USER_PROFILE:
            return ({
                ...unloadedState                
            }) as IUserProfile;
        case actionTypes.RECEIVE_USER_PROFILE:
            // Only accept the incoming data if it matches the most recent request. This ensures we correctly
            // handle out-of-order responses.
            if (action.user?.userId === state.userId || state.firstName === '') {
                return {
                    ...action.user,
                    fullName() { return action.user.firstName + ' ' + action.user.lastName; }
                } as IUserProfile;
            }
            else {
                return {
                    ...state,
                    fullName() { return this.firstName + ' ' + this.lastName; }
                } as IUserProfile;
            }
        case actionTypes.UPDATE_USER_PROFILE:
            let received: IUserProfile = { ...state };
            received = action.userDetails
            return received;
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            const exhaustiveCheck: never = action;
    }

    return state;
};
