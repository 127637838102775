import * as React from 'react';
import { SupportInbox } from './SupportInbox';
import { IInboxMessage, MessageType, MessageStatus } from '../../Core/ViewModels/User/InboxMessage';

export interface ITabSupportInboxProps {
    inboxMessages: IInboxMessage[];
    markAsRead: (messageId: number) => void;
    clearMessage: (event: any,messageId: number) => void;
    onClearAllSelection: (messageType: MessageType) => void;
    isLoading: boolean;
}

export interface ITabSupportInboxState {
    activeType: MessageType;
    inboxMessages: IInboxMessage[];
}

export class TabSupportInbox extends React.Component<ITabSupportInboxProps, ITabSupportInboxState>{
    state: ITabSupportInboxState = {
        activeType: MessageType.Others,
        inboxMessages: this.props.inboxMessages !== undefined
            ? this.props.inboxMessages.filter(message => message.messageType == MessageType.Others)
            : []
    };

    UNSAFE_componentWillReceiveProps(nextProps: ITabSupportInboxProps) {
        this.setState({
            inboxMessages: nextProps.inboxMessages.filter(message => message.messageType == this.state.activeType)
        });
    }

    private onActiveTypeChange = (messageType: MessageType) => {
        this.setState({
            activeType: messageType,
            inboxMessages: this.props.inboxMessages.filter(message => message.messageType == messageType)
        });
    }

    private getTitle = (messageType: MessageType): string => {
        switch (messageType) {
            case MessageType.KnownIssues:
                return 'Known issues';
            case MessageType.ReleaseUpdates:
                return 'Release';
            case MessageType.UnderMaintenance:
                return 'Scheduled maintenance';
            case MessageType.Others:
                return 'General';
            default:
                return '';
        }
    }

    private getIcon = (messageType: MessageType) => {
        switch (messageType) {
            case MessageType.ReleaseUpdates:
                return <span id={"support-inbox" + messageType}
                    className="fas fa-certificate fa-lg support-inbox-releaseupdate" />
            case MessageType.KnownIssues:
                return <span id={"support-inbox" + messageType}
                    className="fas fa-bug fa-lg support-inbox-knownissue" />
            case MessageType.UnderMaintenance:
                return <span id={"support-inbox" + messageType}
                    className="fas fa-wrench fa-lg" />
            case MessageType.Others:
                return <span className="fa-stack support-inbox-others">
                    <i className="fas fa-comment-alt fa-stack-2x"></i>
                    <i className="fas fa-info fa-stack-1x fa-inverse" style={{ lineHeight: '12px' }}></i>
                </span>
            default:
                return "";
        }
    }

    private getTab = (messageType: MessageType) => {
        const unreadMessageCount = this.props.inboxMessages.filter(message => message.messageType == messageType && message.messageStatus == MessageStatus.Unread).length;

        return <li className={messageType == this.state.activeType ? "support-inbox-active-tab" : ""}
            title={this.getTitle(messageType)}
            onClick={() => this.onActiveTypeChange(messageType)} >
            {this.getIcon(messageType)}
            {
                unreadMessageCount ? <span className="badge inbox-count support-inbox-count-tabs">{unreadMessageCount}</span> : ""
            }
        </li>
    }

    render() {
        return <div className="support-inbox-tabs">
            <div>
                <ul className="support-inbox-tab-title">
                    {this.getTab(MessageType.Others)}
                    {this.getTab(MessageType.ReleaseUpdates)}
                    {this.getTab(MessageType.KnownIssues)}
                    {this.getTab(MessageType.UnderMaintenance)}
                </ul>
            </div>

            <SupportInbox
                title={this.getTitle(this.state.activeType)}
                inboxMessages={this.state.inboxMessages}
                markAsRead={this.props.markAsRead}
                clearMessage={this.props.clearMessage}
                onClearAllSelection={this.props.onClearAllSelection}
                isLoading={this.props.isLoading}
            />
        </div>
    }
}
