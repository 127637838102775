import React from 'react';
import moment from 'moment';
import { ReactComponent as WarningIcon } from '../../../assets/images/alert-outline.svg';
import { ReactComponent as CloseIcon } from '../../../assets/images/close.svg';

interface VoucherReminderReportPopupProps {
    showReminderInfo: boolean;
    toggleShowReminderInfo(bool: boolean): void;
    releaseDate: Date;
}

export const VoucherReminderReportPopup = (props: VoucherReminderReportPopupProps) => {
    const { showReminderInfo, toggleShowReminderInfo, releaseDate } = props;

    const closePopup = () => {
        toggleShowReminderInfo(false);
    }

    return (
        <div style={{ display: `${showReminderInfo ? 'block' : 'none'}` }}>
            <div className="reminder-popup">
                <WarningIcon className="reminder-popup-warningIcon" width={30} fill="#DFB053" />
                <div className="reminder-popup-msg">
                    Due to database improvements made on <strong>{moment(releaseDate).format('MMMM Do, YYYY')}</strong>.
                    The range date you selected is not available at this time. To generate a report within that date or
                    after, please create two different date ranges. Once before{' '}
                    <strong>{moment(releaseDate.setDate(releaseDate.getDate() - 1)).format('MMMM Do, YYYY')}</strong>,
                    and another after that date.
                </div>
                <CloseIcon className="reminder-popup-closeIcon" width={28} fill="#1C2B36" onClick={closePopup} />
            </div>
        </div>
    )
};
