import * as React from 'react'
import { Button } from 'react-bootstrap'
import { K1DeliveredStatusResources } from '../../../helper/ResourceIdConstants';

interface K1PartnersHeaderProps {
    pageTitle: string;
    onExportExcelPartners: any;
    onSendReminder: any;
    selectedPartnerCount: number;
    parentResourceId: string;
}
interface K1PartnersHeaderState {

}

export default class K1PartnersHeader extends React.Component<K1PartnersHeaderProps, K1PartnersHeaderState>{
    render() {
        return (
            <div>
                <div className="row" style={{ marginBottom: 5 }}>
                    <div className="col-sm-8">
                        <h3>{this.props.pageTitle}</h3>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-10">
                        <Button
                            data-resource-id={this.props.parentResourceId + K1DeliveredStatusResources.DownloadCSV}
                            variant='default'
                            className='btn btn-white marL10'
                            onClick={() => this.props.onExportExcelPartners()}>
                            <i className='fas fa-download'></i>Download CSV
                        </Button>
                        <Button
                            data-resource-id={this.props.parentResourceId + K1DeliveredStatusResources.SendReminder}
                            variant='default'
                            className='btn btn-white marL10'
                            disabled={this.props.selectedPartnerCount === 0}
                            onClick={() => this.props.onSendReminder()} >
                            <i className='fas fa-paper-plane'></i> Send Reminder
                    </Button>
                    </div>
                </div>
            </div>
        );
    }
}
