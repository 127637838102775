import { actionTypes } from "../../types/ActionTypes";
import { SSSUITE_API_BASE_URL } from "../../utils/constants";
import { initialSuiteNotificationSetting, ISuiteNotificationSetting } from "../../Core/ViewModels/Company/CompanySettingsViewModel";
import { handleResponse } from "../Library";
import { NotificationAction, StatusType } from '../common/NotificationStore';
import { Reducer } from 'redux';
import { AppThunkAction } from "../index";
import { addTask } from 'domain-task';

export interface RequestSuiteNotificationSettingAction {
    type: actionTypes.REQUEST_NOTIFICATION_SETTING;
}

export interface ReceiveSuiteNotificationSettingAction {
    type: actionTypes.RECEIVE_NOTIFICATION_SETTING;
    notificationSetting: ISuiteNotificationSetting;
}

type DispatchAction =
RequestSuiteNotificationSettingAction |
ReceiveSuiteNotificationSettingAction |
    NotificationAction

type KnownAction =
    DispatchAction

    export const actionCreators = {
        requestSuiteNotificationSetting: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
    
            const fetchTask = fetch(SSSUITE_API_BASE_URL + '/api/notification-setting', {
                method: 'GET',
                credentials: 'include'
            })
                .then(handleResponse)
                .then(data => {
                    dispatch({ type: actionTypes.RECEIVE_NOTIFICATION_SETTING, notificationSetting: data });
                })
                .catch(error => {                    
                });
            addTask(fetchTask);
            dispatch({ type: actionTypes.REQUEST_NOTIFICATION_SETTING });
        },
    }
    
    export const reducer: Reducer<ISuiteNotificationSetting> = (state = initialSuiteNotificationSetting, incomingAction) => {
        const action = incomingAction as DispatchAction;
        switch (action.type) {
            case actionTypes.REQUEST_NOTIFICATION_SETTING:
                return state;
            case actionTypes.RECEIVE_NOTIFICATION_SETTING:
                let notificationSetting = { ...state };
                notificationSetting = action.notificationSetting;
                return notificationSetting;
    
            default: return state;
        }
    }