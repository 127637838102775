import React from "react";
import {
    ClientTypes,
    ClientTypesNumber,
    DocumentEvent,
    EngagementType,
    isMutual,
    ITaxReturn,
    SignatureStatus
} from "../../TaxReturn";
import { Link } from "react-router-dom";
import moment from "moment";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { OverlayLoader } from "../../../helper/OverlayLoader";
import { Alert } from "react-bootstrap";
import { AccessCodeConstants } from "../../../helper/Constants";
import { SuccessTickIcon } from "src/assets/images/viewAccessCode/SuccessTickIcon";
import { InfoIcon } from "src/assets/images/viewAccessCode/InfoIcon";

interface IDirectDocumentAccessCodeProps {
    taxReturn: ITaxReturn;
    show: boolean;
    onGenerateOTP(documentId: number, clientType: ClientTypes, clientGUID: string, callback: () => void): void;
    getDifferenceInDate(createdOn: Date): number;
}

interface IDirectDocumentAccessCodeState {
    message: string;
    hideInfo: boolean;
}

interface IColumnValues {
    signersName: string;
    clientId: string;
    recipientEmail: string;
    createdOn: Date;
    OTP: string;
    documentId: number;
    clientType: ClientTypes;
    clientGUID: string;
}

const msg = {
    saving: "Generating OTP...",
    loading: "Loading, please wait..."
};

const NO_INDEX = -1;

export class DirectDocumentAccessCode extends React.Component<IDirectDocumentAccessCodeProps, IDirectDocumentAccessCodeState> {
    constructor(props: IDirectDocumentAccessCodeProps) {
        super(props);
        this.state = {
            message: msg.loading,
            hideInfo: false
        };
        this.generateOTPButton = this.generateOTPButton.bind(this);
        this.onGenerateOTP = this.onGenerateOTP.bind(this);
    }
    UNSAFE_componentWillReceiveProps(props: IDirectDocumentAccessCodeProps) {
        if (!props.show) {
            this.setState({ message: msg.loading });
        }
    }
    componentWillUnmount() {
        this.setState({ hideInfo: false });
    }

    private ellipsisType(cell: any, row: IColumnValues) {
        return (
            <div title={cell} className="custom-ellipsis">
                {cell}
            </div>
        );
    }

    private generateOTPButton(cell: any, row: IColumnValues) {
        return (
            <Link
                to={"#"}
                onClick={() => {
                    this.onGenerateOTP(row);
                }}
            >
                {" "}
                {"Generate Access Code"}
            </Link>
        );
    }

    private onGenerateOTP(row: IColumnValues) {
        this.setState({ message: msg.saving, hideInfo: true }, () => {
            this.props.onGenerateOTP(row.documentId, row.clientType, row.clientGUID, this.triggerAlert);
        });
    }

    private triggerAlert = () => {
        this.setState({ hideInfo: true });
    };

    private createdOnDataFormat = (cell: any, row: IColumnValues) => {
        return !row.createdOn || this.props.getDifferenceInDate(row.createdOn) > 20
            ? "NA"
            : moment.utc(row.createdOn).local().format("MM/DD/YYYY");
    };

    private statusDataFormatType = (cell: any, row: IColumnValues) => {
        if (row.OTP != "NA") {
            return this.props.getDifferenceInDate(row.createdOn) > 20 ? (
                <>
                    <span className="expired" title="Expired">
                        <span className="status-text">Expired</span>
                    </span>
                </>
            ) : (
                <>
                    <span className="active" title="Active">
                        <span className="status-text">Active</span>
                    </span>
                </>
            );
        } else if (
            row.OTP == "NA" &&
            this.props.taxReturn.accessCode.clientEvents.find((x) => x.eventId == DocumentEvent.TaxReturnAccessed) != undefined &&
            this.props.taxReturn.accessCode.clientEvents.some(
                (x) => x.eventId == DocumentEvent.TaxReturnAccessed && x.actedBy.firstName == row.signersName
            )
        ) {
            return (
                <>
                    <span className="authenticated" title="Authenticated">
                        <span className="status-text">Authenticated</span>
                    </span>
                </>
            );
        } else if (
            (row.OTP == "NA" &&
                (this.props.taxReturn.accessCode.clientEvents.length == 0 ||
                    this.props.taxReturn.accessCode.clientEvents.find((x) => x.eventId == DocumentEvent.AccessCodeEmail) ==
                        undefined)) ||
            this.props.taxReturn.accessCode.clientEvents.find(
                (x) => x.eventId == DocumentEvent.AccessCodeEmail && x.actedBy.firstName == row.signersName
            ) == undefined
        ) {
            return (
                <>
                    <span className="notRequested" title="Not Requested">
                        <span className="status-text">Not Requested</span>
                    </span>
                </>
            );
        }
    };

    private getAccessCodeInfo = (taxReturn: ITaxReturn): ITaxReturn => {
        if (taxReturn.engagementType.toString() == EngagementType[EngagementType.E1040]) {
            let index = NO_INDEX;
            if (
                taxReturn.signatureStatus.toString() != SignatureStatus[SignatureStatus.ESigned] &&
                taxReturn.signatureStatus.toString() != SignatureStatus[SignatureStatus.ESignedInOffice] &&
                taxReturn.signatureStatus.toString() != SignatureStatus[SignatureStatus.Uploaded] &&
                taxReturn.signatureStatus.toString() != SignatureStatus[SignatureStatus.SignedAndESigned] &&
                taxReturn.signatureStatus.toString() != SignatureStatus[SignatureStatus.ManuallySigned]
            ) {
                if (
                    taxReturn.documentSettings.deliverySettings &&
                    taxReturn.accessCode.clientEvents &&
                    taxReturn.signedDetails &&
                    !taxReturn.signedDetails.filter((x) => x.signerType == ClientTypesNumber.Taxpayer)[0].isSigned &&
                    taxReturn.documentSettings.deliverySettings.deliverTo == ClientTypes.Taxpayer
                ) {
                    index = taxReturn.accessCode.accessCodeDetails.findIndex((x) => x.clientType == ClientTypes.Spouse);
                    if (index > NO_INDEX) {
                        taxReturn.accessCode.accessCodeDetails.splice(index, 1);
                    }
                } else if (
                    taxReturn.documentSettings.deliverySettings &&
                    taxReturn.accessCode.clientEvents &&
                    taxReturn.signedDetails &&
                    !taxReturn.signedDetails.filter((x) => x.signerType == ClientTypesNumber.Spouse)[0].isSigned &&
                    taxReturn.documentSettings.deliverySettings.deliverTo == ClientTypes.Spouse
                ) {
                    index = taxReturn.accessCode.accessCodeDetails.findIndex((x) => x.clientType == ClientTypes.Taxpayer);
                    if (index > NO_INDEX) {
                        taxReturn.accessCode.accessCodeDetails.splice(index, 1);
                    }
                }
            } else {
                if (
                    taxReturn.documentSettings.deliverySettings &&
                    taxReturn.documentSettings.deliverySettings.deliverTo == ClientTypes.Taxpayer
                ) {
                    if (isMutual(taxReturn)) {
                        if (taxReturn.spouse.isDeceased) {
                            index = taxReturn.accessCode.accessCodeDetails.findIndex((x) => x.clientType == ClientTypes.Spouse);
                            if (index > NO_INDEX) {
                                taxReturn.accessCode.accessCodeDetails.splice(index, 1);
                            }
                        }
                    }
                    return taxReturn;
                } else {
                    index = taxReturn.accessCode.accessCodeDetails.findIndex((x) => x.clientType == ClientTypes.Spouse);
                    if (index > NO_INDEX) {
                        taxReturn.accessCode.accessCodeDetails.splice(
                            0,
                            0,
                            taxReturn.accessCode.accessCodeDetails.splice(index, 1)[0]
                        );
                    }
                    if (isMutual(taxReturn)) {
                        if (taxReturn.taxpayer.isDeceased) {
                            index = taxReturn.accessCode.accessCodeDetails.findIndex((x) => x.clientType == ClientTypes.Taxpayer);
                            if (index > NO_INDEX) {
                                taxReturn.accessCode.accessCodeDetails.splice(index, 1);
                            }
                        }
                    }
                }
            }
        }
        return taxReturn;
    };

    private generateAccessCodeToolTip = () => {
        return "Generate Access Code";
    };

    private getAccessCodeDataType = (cell: any, row: IColumnValues) => {
        if (row.OTP != "NA") {
            return this.props.getDifferenceInDate(row.createdOn) > 20 ? "NA" : row.OTP;
        } else {
            return "NA";
        }
    };

    public render() {
        let viewAccessCodeData: any[] = [];
        const viewAccessCodeColumn = [
            {
                header: "Signer Name",
                key: "signersName",
                isKey: false,
                dataFormat: this.ellipsisType,
                toolTip: true,
                width: "100px",
                columnClassName: ""
            },
            {
                header: "Recipient Details",
                key: "recipientEmail",
                isKey: false,
                dataFormat: this.ellipsisType,
                toolTip: true,
                width: "150px",
                columnClassName: "emailClass"
            },
            {
                header: "Client ID",
                key: "clientId",
                isKey: false,
                dataFormat: this.ellipsisType,
                toolTip: true,
                width: "120px",
                columnClassName: ""
            },
            {
                header: "Date",
                key: "createdOn",
                isKey: true,
                dataFormat: this.createdOnDataFormat,
                toolTip: true,
                width: "100px",
                columnClassName: ""
            },
            {
                header: "Access Code",
                key: "OTP",
                isKey: false,
                dataFormat: this.getAccessCodeDataType,
                toolTip: true,
                width: "120px",
                columnClassName: ""
            },
            {
                header: "Status",
                key: "status",
                isKey: false,
                dataFormat: this.statusDataFormatType,
                toolTip: true,
                width: "130px",
                columnClassName: ""
            },
            {
                header: "Action",
                key: "action",
                isKey: false,
                dataFormat: this.generateOTPButton.bind(this),
                toolTip: this.generateAccessCodeToolTip,
                width: "170px",
                columnClassName: ""
            }
        ];

        let minDate = new Date();
        minDate.setFullYear(1, 0, 1);
        minDate.setHours(0, 0, 0, 0);

        if (
            this.props.taxReturn &&
            this.props.taxReturn.accessCode &&
            this.props.taxReturn.accessCode.accessCodeDetails.length > 0
        ) {
            viewAccessCodeData = this.getAccessCodeInfo(this.props.taxReturn).accessCode.accessCodeDetails.map((value, index) => {
                return {
                    signersName: value.name,
                    clientId: this.props.taxReturn.clientId,
                    recipientEmail: value.recipientEmail,
                    createdOn:
                        Date.parse(new Date(value.createdOn).toString()) == Date.parse(minDate.toString())
                            ? null
                            : value.createdOn,
                    OTP: !value.otp ? "NA" : value.otp,
                    documentId: value.documentId,
                    clientType: value.clientType,
                    clientGUID: value.clientGuid
                };
            });
        }
        return this.props.taxReturn && this.props.taxReturn.accessCode ? (
            <>
                <Alert variant="success" hidden={!this.state.hideInfo}>
                    <SuccessTickIcon />
                    <span className="toaster-message">{AccessCodeConstants.AccessCode.Success}</span>
                </Alert>

                <Alert variant="info" hidden={this.state.hideInfo}>
                    <InfoIcon />
                    <span className="toaster-message">{AccessCodeConstants.AccessCode.Info}</span>
                </Alert>

                <div className="direct-document-table" style={{ marginTop: "25px" }}>
                    <BootstrapTable headerStyle={{ background: "lightgray" }} data={viewAccessCodeData}>
                        {viewAccessCodeColumn.map((value, index) => {
                            return (
                                <TableHeaderColumn
                                    key={index}
                                    isKey={value.isKey}
                                    dataField={value.key}
                                    dataFormat={value.dataFormat}
                                    className={value.columnClassName}
                                    columnTitle={value.toolTip}
                                    width={value.width}
                                >
                                    {value.header}
                                </TableHeaderColumn>
                            );
                        })}
                    </BootstrapTable>
                </div>
            </>
        ) : (
            <OverlayLoader show={this.props.show} text={this.state.message} width="94%" />
        );
    }
}

export default DirectDocumentAccessCode;
