import * as React from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { LoadingOverlay, Loader } from 'react-overlay-loader';

interface TabContentContainerMiddleCardProps {
    isContentEmpty?: boolean;
    onAction?: any;
    disableScroll?: boolean;
    formNotFound?: string;
}

interface TabContentContainerProps {
    isBusy?: boolean;
    enableScroll?: boolean;
}

interface TabContentContainerRightCardProps {
    disableScroll?: boolean;
}

export class TabContentContainer extends React.Component<TabContentContainerProps, {}> {
    private createMainContent = () => {
        if (this.props.enableScroll) {
            return (<Scrollbars renderTrackHorizontal={this.renderTrackHorizontal}>
                {this.props.children}
            </Scrollbars>);
        }
        else {
            return (this.props.children);
        }
    }

    renderTrackHorizontal() {
        return <div style={{ display: 'none' }}  />
    
    }

    public render() {
        return (<LoadingOverlay style={{ height: "100%" }}>
            <div className="tab-content-container row h100">
                {this.createMainContent()}
            </div>
            <Loader loading={this.props.isBusy} />
        </LoadingOverlay>);
    }
}

export class TabContentContainerLeftCard extends React.Component<{}, {}> {
    public render() {
        return (<div className="tab-content-container-left-card col-lg-2 col-sm-12 h100">
            <div className={"pr-right-pannel"}>
                <div className={"scroll"}>
                    <Scrollbars>
                        {this.props.children}
                    </Scrollbars>
                </div>
            </div>
        </div>);
    }
}

export class TabContentContainerMiddleCard extends React.Component<TabContentContainerMiddleCardProps, {}> {
    private createContent = () => {
        if (this.props.isContentEmpty) {
            return (<div className="tab-content-container-middle-card-no-content col-lg-7 col-sm-12 h100">
                <div className="clearfix tab-no-content-message">
                    <h1 className="float-left">
                        {this.props.formNotFound ? this.props.formNotFound: "No forms found !!"}</h1>
                </div>
            </div>);
        }
        else {


            if (this.props.disableScroll) {
                return (<div className="tab-content-container-middle-card col-lg-7 col-sm-12 h100">
                    <div className={"pr-center-pannel"}>
                        <div className={"scroll"} style={{ overflow: "hidden" }}>
                                {this.props.children}
                        </div>
                    </div>
                </div>);
            }
            else {
                return (<div className="tab-content-container-middle-card col-lg-7 col-sm-12 h100">
                    <div className={"pr-center-pannel"}>
                        <div className={"scroll"}>
                            <Scrollbars>
                                {this.props.children}
                            </Scrollbars>
                        </div>
                    </div>
                </div>);
            }         
        }
    }

    public render() {
        return this.createContent();
    }
}

export class TabContentContainerRightCard extends React.Component<TabContentContainerRightCardProps, {}> {
    public render() {
        return (<div className="tab-content-container-right-card col-lg-3 col-sm-12 h100">
            <div className={"pr-left-pannel"}>
                <div className={"scroll"}>
                    <Scrollbars>
                        {this.props.children}
                    </Scrollbars>
                </div>
            </div>
        </div>);
    }
}
