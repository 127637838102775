//import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights, DistributedTracingModes, ICustomProperties, IEventTelemetry, ITelemetryItem, SeverityLevel, Util } from '@microsoft/applicationinsights-web'
import { handleResponse } from '../store/Library'
import { ILogger } from './Logger'
import { API_BASE_URL } from '../utils/constants'
import { KNOWN_EXCEPTION_MESSAGES } from '../components/helper/Constants';

let profileData: any

export class TelemetryLogger implements ILogger {
    private static instance: TelemetryLogger
    private static appInsights: ApplicationInsights
    private static pageViewRefTime: any

    private constructor() {
        TelemetryLogger.appInsights = configureAppInsights()
    }

    public static getInstance(): TelemetryLogger {
        if (!TelemetryLogger.instance) {
            this.instance = new TelemetryLogger()
        }
        return TelemetryLogger.instance
    }

    public trackPageView = (pageName: string, customProperties?: ICustomProperties) => {
        TelemetryLogger.appInsights.context.telemetryTrace.name = pageName
        TelemetryLogger.appInsights.context.telemetryTrace.traceID = Util.generateW3CId()
        TelemetryLogger.appInsights.trackPageView({
            name: pageName,
            properties: { duration: new Date().getTime() - TelemetryLogger.pageViewRefTime, ...customProperties },
        })
        TelemetryLogger.pageViewRefTime = new Date().getTime()
    }

    public trackTrace = (message: string, customProperties?: ICustomProperties) => {
        TelemetryLogger.appInsights.trackTrace({
            message: message,
            severityLevel: SeverityLevel.Information,
        }, customProperties)
    }

    public trackWarning = (message: string, customProperties?: ICustomProperties) => {
        TelemetryLogger.appInsights.trackTrace({
            message: message,
            severityLevel: SeverityLevel.Warning
        }, customProperties)
    }

    public trackError = (message: string, customProperties?: ICustomProperties) => {
        TelemetryLogger.appInsights.trackException({
            exception: new Error(message),
        }, customProperties)
    }

    public trackEvent = (traceEvent: IEventTelemetry, customProperties?: ICustomProperties) => {
        TelemetryLogger.appInsights.trackEvent(traceEvent, customProperties);
    }
}

function configureAppInsights() {
    const appInsights = new ApplicationInsights({
        config: {
            instrumentationKey: '',
            disableInstrumentationKeyValidation: true,
            disableFetchTracking: false,
            disableAjaxTracking: false,
            enableCorsCorrelation: true,
            distributedTracingMode: DistributedTracingModes.W3C,
            enableRequestHeaderTracking: true,
            enableResponseHeaderTracking: true,
            disableCorrelationHeaders: false,
            autoTrackPageVisitTime: true,
            //extensions: [reactPlugin],
            //extensionConfig: {
            //    [reactPlugin.identifier]: { history: createBrowserHistory({ basename: '' }) }
            // }
        },
    })
    appInsights.loadAppInsights()

    //Note: Middleware will be executed for every track event (PageView/Dependency/Trace/Exception/etc)
    appInsights.addTelemetryInitializer((envelope: ITelemetryItem) => {
        envelope.tags = envelope.tags || []
        envelope.tags.push({ 'ai.cloud.role': 'SSR-CPA-UI' })

        if (envelope.baseType === 'PageviewData') {
            console.log('pageViewData middleware')
            console.log(envelope)
        }

        if (profileData !== undefined) {
            envelope.tags.push({ 'ai.user.accountId': profileData.companyId })
            envelope.tags.push({ 'ai.user.id': profileData.userId })
        }

        var telemetryItem = envelope.baseData;
        if (envelope.baseType === "ExceptionData") {
            const hasExceptions = telemetryItem && telemetryItem.exceptions && telemetryItem.exceptions.length > 0;
            const exceptionMessage = hasExceptions ? telemetryItem?.exceptions[0].message : '';
        
            const isErrorExists = exceptionMessage && KNOWN_EXCEPTION_MESSAGES.some(msg => exceptionMessage.includes(msg));
        
            if (isErrorExists) {
                return false;
            }
        }

        if (telemetryItem !== undefined && profileData !== undefined) {
            telemetryItem.properties = telemetryItem.properties || {}
            telemetryItem.properties['UserId'] = profileData.userId
            telemetryItem.properties['CompanyId'] = profileData.companyId
            telemetryItem.properties['CompanyName'] = profileData.companyName
            telemetryItem.properties['Origin'] = 'SSR-CPA-UI'
        }
    })

    getDataForAppInsigts()
        .then(response => {
            if (response) {
                profileData = response
                appInsights.config.instrumentationKey = profileData.instrumentationKey
            }
        })
        .catch(err => console.error(err))

    return appInsights
}

const getDataForAppInsigts = async () => {
    const response = await fetch(API_BASE_URL + 'api/ApplicationInsights', {
        credentials: 'include',
    })
    if (response) {
        return handleResponse(response)
    }
    return null
}
