import * as React from 'react';
import { DropzoneComponent } from 'react-dropzone-component';

interface IDropzoneComponent {
    componentConfig: any;
    eventHandlers: any;
    djsConfig: any;
    autoTestId?: string;
    isDisable?: boolean;
}
export class DocumentUploadDropzoneComponent extends React.Component<IDropzoneComponent, {}>{
    public render() {
        var isDisable = this.props.isDisable == true ? "divDocumentUpload disable-link" : "divDocumentUpload";
        return <div className={isDisable} data-test-auto={this.props.autoTestId}> 
                <DropzoneComponent config={this.props.componentConfig}
                    eventHandlers={this.props.eventHandlers}
                    djsConfig={this.props.djsConfig}
                >
                </DropzoneComponent> 
        </div>
    }
}

export default DocumentUploadDropzoneComponent;
