import * as React from 'react';
import * as ReactDom from 'react-dom';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import { Modal, Alert, Button } from 'react-bootstrap';
import { LoadingOverlay, Loader } from 'react-overlay-loader';
import { FaLink } from 'react-icons/fa';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { ITaxReturn, ISignerModel, SignatureStatus, DocumentEvent } from './TaxReturn';
import { VenusNotifier } from '../helper/VenusNotifier';
import { Col, Row } from 'react-bootstrap';

interface IResendAccessLinkProps {
	show: boolean;
	onCancel: () => void;
	model: ITaxReturn;
	onResendAccessLink: (documentId: number, model: ISignerModel) => void;
}
interface IResendAccessLinkState {
	message: string
}

const msg = {
	saving: 'Sending Access Link...',
	loading: 'Loading, please wait...'
}

export class ResendAccessLinkModal extends React.Component<IResendAccessLinkProps, IResendAccessLinkState> {
	constructor(props: IResendAccessLinkProps) {
		super(props);
		this.state = {
			message: msg.loading
		}
		this.onResendAccessLink = this.onResendAccessLink.bind(this);
	}
	UNSAFE_componentWillReceiveProps(nextProps: IResendAccessLinkProps) {
		if (!nextProps.show) {
			this.setState({ message: msg.loading });
		}
    }
    private isIntialEmailSent(signerModel: ISignerModel) {
        if (this.props.model.clientTracking !== undefined) {
            var DocumentMailstaus = this.props.model.clientTracking.filter(
                x => x.eventId == DocumentEvent.DocumentMailStatus &&
                    x.eventData.recipientInfo !== undefined &&
                    x.eventData.recipientInfo !== null &&
                    x.eventData.recipientInfo.emailAddress !== undefined &&
                    x.eventData.recipientInfo.emailAddress !== null &&
                    x.eventData.recipientInfo.emailAddress == signerModel.signer);

            return DocumentMailstaus.length > 0;
        }
        return false;
    }
    private onCopy(signerObj: any) {
        const signatureStatus: string = this.props.model.signatureStatus.toString();
        
        let model = this.props.model.signedDetails.find(x => x.signer == signerObj.signer && x.signerType == signerObj.signerType);
        
        if (((model && model.isSigned === true) || signatureStatus === SignatureStatus[SignatureStatus.ManuallySigned])) {
            console.log("Link has been copied");
            VenusNotifier.Success("Access Link has been copied",null);
        }
        else {
            VenusNotifier.Warning("Copy Access Link is only available for recipients who have received Initial mail. If the intended recipient is grayed out and has not yet received mail, please use Send Reminder", null)
        }
    }
	private onResendAccessLink(signerObj: any) {
		const signatureStatus: string = this.props.model.signatureStatus.toString();

        let model = this.props.model.signedDetails.find(x => x.signer == signerObj.signer && x.signerType == signerObj.signerType);
        //Bug 36420 since signer signed or manually signed cpa can send resend access link
            if ( ((model && model.isSigned === true) || signatureStatus === SignatureStatus[SignatureStatus.ManuallySigned])) {
                this.setState({ message: msg.saving },
                    () => {
                        if (model) { this.props.onResendAccessLink(model.documentId, model); }
                    }
                );
            }
            else {
                VenusNotifier.Warning("Resend Access Link is only available for recipients who have received Initial mail. If the intended recipient is grayed out and has not yet received mail, please use Send Reminder", null)
            }
        
	}

	public render() {
		const { model } = this.props
		const signedDetails = this.props.model !== undefined && this.props.model.signedDetails && this.props.model.signedDetails.length > 0;
        const signatureStatus: string = this.props.model ? this.props.model.signatureStatus.toString() : SignatureStatus.None.toString();

        return <Modal className='delivered-resend-access-link' show={this.props.show} onHide={this.props.onCancel}>
			<Modal.Header closeButton>
				<Modal.Title className='custom-modal-header'>
					<FaLink className='modalIcon' />
					Resend Access Link
                </Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<LoadingOverlay style={{ minHeight: 100 }}>
					{signedDetails ?
                        <ul style={{ padding: 10,listStyleType:'none' }}>
                            {this.props.model.signedDetails.map((i, index) =>
                                <Row key={index}>
                                    <Col sm={10} className={i.isSigned === true || signatureStatus === SignatureStatus[SignatureStatus.ManuallySigned] ?
                                        "" : "list-disabled"}>
                                        {i.name}  ({i.signer})
                                    </Col>
                                    <Col sm={1}>
                                        <a onClick={() => this.onResendAccessLink(i)} title="Resend"
                                            style={{ marginLeft: '7px' }} className='fas fa-share-square' href="javascript:void(0)" />
                                    </Col>
                                    <Col sm={1}>
                                        <CopyToClipboard text={i.isSigned === true || signatureStatus === SignatureStatus[SignatureStatus.ManuallySigned] ? i.clientAccessLink : ''} >
                                            <a title="Copy Link Address" onClick={() => this.onCopy(i)}
                                                style={{ marginLeft: '7px' }} className='far fa-copy' href="javascript:void(0)" />
                                        </CopyToClipboard>
                                    </Col>
                                </Row>
                            )}
						</ul> : 						
                    <Loader loading={(model === undefined) || (model.signedDetails === undefined) || model.clientTracking === undefined} />}
				</LoadingOverlay>
			</Modal.Body>
			<Modal.Footer>
                <Button onClick={this.props.onCancel}
                    variant="default"
					className="btn btn-white"
					data-test-auto="BC3C6FF2-75C2-45AD-8552-8915E4C8EDF0">
                    <i className='fas fa-times'></i>
					Cancel
                </Button>
			</Modal.Footer>
		</Modal>
	}
}
