import { injectable } from "inversify";
const sanitizeHtml = require('sanitize-html');

export interface IHtmlUtilities {
    sanitize(content: string): string;
}

@injectable()
export class HtmlUtilities implements IHtmlUtilities {
    
    sanitize(content: string): string {
        return sanitizeHtml(content, {
            allowedTags: ['h3', 'h4', 'h5', 'h6', 'blockquote', 'p','span', 'a', 'ul', 'ol',
                'nl', 'li', 'b', 'i', 'strong', 'em', 'strike', 'code', 'hr', 'br', 'div',
                'table', 'thead', 'caption', 'tbody', 'tr', 'th', 'td', 'pre'],
            allowedAttributes: {
                '*': ['href', 'target', 'style']
            }
        });
    }
}