import * as React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { VenusNotifier } from '../../helper/VenusNotifier';
import { CheckBoxComponent } from '../../../components/common/CheckBoxComponent';
import * as TaxDocument from '../../common/TaxReturn';

interface ISignerTooltipProps {
    control: any;
    initialControl: any;
    showState: boolean;
    onHide: () => void;
    submitButtonClick: (control: any) => void;
    content?: {
        header: string,
        title: string,
        tooltipContent: string
    };
    signatureControlType?: number;
}

interface ISignerTooltipState {
    control: any;
}

export class SignerTooltipPopup extends React.Component<ISignerTooltipProps, ISignerTooltipState>{
    constructor(props: ISignerTooltipProps) {
        super(props);
        this.state = {
            control: props.initialControl
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps: ISignerTooltipProps) {
        this.setState({
            control: nextProps.control
        });
    }

    private onCancelButtonClick = () => {
        this.props.onHide();
        this.setState({
            control: this.props.initialControl
        });
    }

    private onSaveButtonClick = () => {
        const { control: { tooltip } } = this.state;
        if (this.props.signatureControlType && this.props.signatureControlType !== TaxDocument.SignatureControlTypes.Text) {
            if (this.props.signatureControlType == TaxDocument.SignatureControlTypes.CheckBox && 
                    tooltip >= 1 && tooltip <=5 && tooltip % 1 === 0) {
                this.props.submitButtonClick(this.state.control);
            }
            else if (this.props.signatureControlType == TaxDocument.SignatureControlTypes.RadioButton){ 
                if(tooltip >= 2 && tooltip <=5 && tooltip % 1 === 0) {
                this.props.submitButtonClick(this.state.control);
                }
                else {
                    VenusNotifier.Error("Please select number between 2 and 5", null);
                }
            }
            else {
                VenusNotifier.Error("Please select number between 1 and 5", null);
            }
        } else {
            this.props.submitButtonClick(this.state.control);
        }
    }

    private onToolTipChange = (event: any) => {
        let tempControl = Object.assign({}, this.state.control);
        tempControl.tooltip = event.target.value;
        this.setState({ control: tempControl });
    }

    private onInputNumberChange = (event: any) => {
        let tempControl = Object.assign({}, this.state.control);
        tempControl.tooltip = event.target.value;
        this.setState({ control: tempControl });
    }

    private onRequiredChange = (event: any) => {
        let tempControl = Object.assign({}, this.state.control);
        tempControl.required = event.target.checked;
        this.setState({ control: tempControl });
    }

    private getInputBox = () => {
        if (this.props.signatureControlType && this.props.signatureControlType !== TaxDocument.SignatureControlTypes.Text) {
            return (
                <input type="number"
                    data-test-auto="F64CD7BB-408E-4194-ABC4-C2A55B4EF4DA"
                    className="form-control"
                    placeholder="Enter a number"
                    min={1}
                    max={5}
                    value={this.state.control.tooltip ? this.state.control.tooltip : ""}
                    onChange={this.onInputNumberChange}>
                </input>
            )
        } else {
            return (
                <input type="text"
                    data-test-auto="F64CD7BB-408E-4194-ABC4-C2A55B4EF4DA"
                    className="form-control"
                    placeholder="Tooltip text"
                    maxLength={200}
                    value={this.state.control.tooltip ? this.state.control.tooltip : ""}
                    onChange={this.onToolTipChange}>
                </input>
            )
        }
    }

    public render() {
        return (
            <div style={{ position: 'relative' }}>
                <Modal show={this.props.showState} onHide={this.onCancelButtonClick}>
                    <Modal.Header closeButton>
                        <Modal.Title className='custom-modal-header'>
                            <span
                                className='fas fa-edit'
                                style={{ color: 'grey', marginRight: '5px' }}>
                            </span>
                            { this.props.content ? this.props.content.header : "Tool Tip" }
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="form-group">
                            <label>{ this.props.content ? this.props.content.title : "Add Text" }</label>
                            <i className="fa fa-question-circle" style={{ color: '#DAA520', marginLeft: '10px' }} title={this.props.content ? this.props.content.tooltipContent : "This text will help the signer to enter the necessary details"}></i>
                        </div>
                        <div style={{ marginBottom: ".5rem" }} className="form-group">
                            { this.getInputBox() }
                        </div>
                        <div className="form-group">
                            <CheckBoxComponent
                                id={"isToolTipRequired"}
                                text={"Required"}
                                checked={this.state.control.required}
                                onChange={this.onRequiredChange}
                            />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            data-test-auto="7A73152B-7425-4B5A-852F-7126A10F200D"
                            variant='default'
                            onClick={this.onCancelButtonClick} >
                            <i className='fas fa-times'></i>Cancel
                        </Button>
                        <Button
                            variant='info'
                            onClick={this.onSaveButtonClick}
                            data-test-auto="58D8BFE5-4906-4314-BBF3-C9E173A2A4B2">
                            <i className='fas fa-save'></i>Save
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}