import * as React from 'react';
import { PDFPageProxy, PDFRenderTask, PDFPageViewport, PDFJSStatic } from 'pdfjs-dist';
import { LoadingOverlay, Loader } from 'react-overlay-loader';

import { IPdfDocumentFacade } from '../../../../Core/Utilities/PdfDocumentFacade';
import * as PdfStore from '../../../../store/pdf/PdfStore';

export const AutoScale = -1;
const PDFJS: PDFJSStatic = require('pdfjs-dist');

export interface PdfRegroupPageViewerProps {
    documentId: number;
    pdfDocuments: PdfStore.IPdfDocumentDictionary;
    pageNo: number;
    showText?: boolean;
};
interface PdfRegroupPageViewerState {
    page: PDFPageProxy | undefined;
    requested: boolean;
    isBusy: boolean;
}
export class PDFRegroupPageViewer extends React.Component<PdfRegroupPageViewerProps, PdfRegroupPageViewerState> {
    canvas: any; //HTMLCanvasElement;	
    renderTask?: PDFRenderTask;
    textLayer: any;
    constructor(props: PdfRegroupPageViewerProps) {
        super(props);
        this.canvas = undefined;
        this.renderTask = undefined;
        this.state = {
            page: undefined,
            requested: false,
            isBusy: true,
        };
        this.setCanvasRef = this.setCanvasRef.bind(this);
        this.renderError = this.renderError.bind(this);
    }

    UNSAFE_componentWillReceiveProps(nextProps: PdfRegroupPageViewerProps) {
        if (nextProps.pageNo !== this.props.pageNo) {
            this.setState({ page: undefined, requested: false },
                this.renderPage
            );
        } 
    }

    componentDidMount() {
        this.renderPage();
    }

    public render() {
        let zIndex = 1;
        let canvasStyle: React.CSSProperties = ({
            zIndex: zIndex
        })

        zIndex++;
        return (<LoadingOverlay style={{ height: '100%' }}>
            <canvas
                id={'pdfpage' + this.props.pageNo}
                ref={(instance: any) => { this.setCanvasRef(instance); }}
                style={{ width: '100%' }}
            >
            </canvas>
            {
                this.props.showText && <div style={{ zIndex: zIndex }}
                    className="pdfTextLayer"
                    ref={(instance: any) => { this.textLayer = instance as HTMLElement; }} />
            }
            <Loader loading={this.state.isBusy} />
        </LoadingOverlay>)
    }

    private renderPage = () => {

        if (this.state.isBusy && this.renderTask) {
            this.renderTask.cancel();
        }

        const pdf = this.props.pdfDocuments[this.props.documentId].document;
        if (pdf) {
            if (!this.state.page) {
                this.setState({ isBusy: true });
                if (pdf && !this.state.requested) {
                    const document = pdf as IPdfDocumentFacade;
                    document.getPage(this.props.pageNo).then((page: PDFPageProxy) => {
                        this.setState({ page: page, isBusy: true },
                            () => this.renderPdf(page));
                    }, (err: string) => {
                        console.warn(err);
                        this.setState({ isBusy: false },
                            () => this.renderError()
                        );
                    });
                    this.setState({ requested: true });
                }
            }
            else {
                this.setState({ isBusy: true },
                    () => { this.renderPdf(this.state.page as PDFPageProxy); });

            }
        }
    }

    private renderError = () => {
        var ctx = this.canvas.getContext("2d");
        ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
        ctx.font = '80px FontAwesome';
        var m = ctx.measureText('\uf071;');
        let l = (this.canvas.width - m.width) / 2;
        let t = (this.canvas.height - m.height) / 2;
        ctx.fillText('\uF047', l, t);
    }

    private renderPdf = (page: PDFPageProxy) => {
        let viewport = page.getViewport(1);
        var context = this.canvas.getContext('2d');
        if (context) {
            this.canvas.height = viewport.height;
            this.canvas.width = viewport.width;
            this.renderTask = page.render({ canvasContext: context, viewport: viewport });
            this.renderTask.then(() => {
                this.setState({ isBusy: false });
            });
            if (this.props.showText) {
                this.textLayer.height = viewport.height;
                this.textLayer.width = viewport.width;
                this.renderTextlayer(page, viewport);
            }
        }
    }

    private renderTextlayer = (page: PDFPageProxy, viewport: PDFPageViewport) => {
        page.getTextContent().then((textContent) => {

            this.textLayer.innerHTML = null; //Cleanup the previous render
            var texts = (PDFJS as any).renderTextLayer({
                textContent: textContent,
                container: this.textLayer,
                viewport: viewport,
                textDivs: []
            });
        });
    }

    private setCanvasRef(instance: any) {
        this.canvas = instance as HTMLCanvasElement;
    }
};